import React from 'react';

import './styles.less';
import App from './../../index';

export default function Modal({type, params, state}) {
    const {content, wrapper = true, animationClass = 'item-down', zIndex = 20, onClose} = params;
    const close = () => {
        App.Sounds.playSound('click');
        onClose?.(type) || App.Modal.remove(type);
    };

    return (
        <div className={`modal ${type}`} onClick={close} style={{zIndex}}>
            <div
                className={`content content-${wrapper ? 'bordered' : 'fullscreen'} ${animationClass}`}
                onClick={event => wrapper && event.stopPropagation()}
            >
                {content?.(state, close)}
                {wrapper && <>
                    <img src={App.getUrl(`/img/registration/border.png`)} className='border border-styles left' alt=''/>
                    <img src={App.getUrl(`/img/registration/border.png`)} className='border border-styles right'
                        alt=''/>
                </>}
            </div>
        </div>
    );
}
