import React from 'react';
import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../../../index';
import GameMegajack from './../../megajack/game';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Text = PIXI.Text,
    Container = PIXI.Container;

export default class SlotOPol extends GameMegajack {
    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 28px Arial';
        ctx.fillStyle = '#a67800';
        ctx.textAlign = 'center';

        switch (page) {
            case 1:
                ctx.fillText(bet * this.symbols[9].payment[5], 60, 87);     // 'e'
                ctx.fillText(bet * this.symbols[9].payment[4], 60, 124);
                ctx.fillText(bet * this.symbols[9].payment[3], 60, 162);
                ctx.fillText(bet * this.symbols[9].payment[2], 60, 198);

                ctx.fillText(bet * this.symbols[7].payment[5], 60, 260);    // island
                ctx.fillText(bet * this.symbols[7].payment[4], 60, 296);
                ctx.fillText(bet * this.symbols[7].payment[3], 60, 335);
                ctx.fillText(bet * this.symbols[7].payment[2], 60, 371);

                ctx.fillText(bet * this.symbols[5].payment[5], 60, 438);     // lemon
                ctx.fillText(bet * this.symbols[5].payment[4], 60, 475);
                ctx.fillText(bet * this.symbols[5].payment[3], 60, 511);

                ctx.fillText(bet * this.symbols[8].payment[5], 454, 89);     // ship
                ctx.fillText(bet * this.symbols[8].payment[4], 454, 125);
                ctx.fillText(bet * this.symbols[8].payment[3], 454, 164);
                ctx.fillText(bet * this.symbols[8].payment[2], 454, 200);

                ctx.fillText(bet * this.symbols[6].payment[5], 454, 260);    // cocktail
                ctx.fillText(bet * this.symbols[6].payment[4], 454, 296);
                ctx.fillText(bet * this.symbols[6].payment[3], 454, 335);

                ctx.fillText(bet * this.symbols[4].payment[5], 454, 438);    // pineapple
                ctx.fillText(bet * this.symbols[4].payment[4], 454, 475);
                ctx.fillText(bet * this.symbols[4].payment[3], 454, 511);
                break;
            case 2:
                ctx.fillText(bet * this.symbols[3].payment[5], 60, 45);      // grapes
                ctx.fillText(bet * this.symbols[3].payment[4], 60, 81);
                ctx.fillText(bet * this.symbols[3].payment[3], 60, 117);

                ctx.fillText(bet * this.symbols[1].payment[5], 60, 184);     // plum
                ctx.fillText(bet * this.symbols[1].payment[4], 60, 220);
                ctx.fillText(bet * this.symbols[1].payment[3], 60, 256);
                ctx.fillText(bet * this.symbols[1].payment[2], 60, 292);

                ctx.fillText(bet * this.symbols[2].payment[5], 454, 45);     // watermelon
                ctx.fillText(bet * this.symbols[2].payment[4], 454, 81);
                ctx.fillText(bet * this.symbols[2].payment[3], 454, 117);

                ctx.fillText(bet * this.symbols[0].payment[5], 454, 184);    // cherry
                ctx.fillText(bet * this.symbols[0].payment[4], 454, 220);
                ctx.fillText(bet * this.symbols[0].payment[3], 454, 256);
                ctx.fillText(bet * this.symbols[0].payment[2], 454, 292);

                ctx.fillText(bet * nLines * this.symbols[12].payment[5], 60, 386);    // '$'
                ctx.fillText(bet * nLines * this.symbols[12].payment[4], 60, 422);    // '$'
                ctx.fillText(bet * nLines * this.symbols[12].payment[3], 60, 458);    // '$'
                ctx.fillText(bet * nLines * this.symbols[12].payment[2], 60, 494);    // '$'
                break;
        }
    }

    /**
     * Функция отображения предыдущего выбора или супербонуса
     */
    showChosedItem(parentContainer) {
        let x = this.dicesMap[this.chipPos].x, y = this.dicesMap[this.chipPos].y;
        parentContainer.removeChildren();
        parentContainer.interactiveChildren = true;

        const sprite = new Sprite(this.getTexture('button'));
        sprite.name = 'button';
        sprite.position.set(x, y);
        parentContainer.addChild(sprite);

        const multiplier = this.screenMultiplier.win[this.choosingStep];
        const richText = new Text(multiplier, this.textBonus);

        x = this.choosing[0].textPosition.x;
        y = this.choosing[0].textPosition.y;
        richText.position.set(x, y);
        richText.name = 'choiceMultiplier';
        parentContainer.addChild(richText);

        this.screenMultiplier.win.forEach((multiplier, choiceIndex) => {
            if (multiplier === 0) {     // нет віигрыша
            }
            if (multiplier > 0) {    // если не -1 значит выбор был сделан- отключаем кнопку, отображеем выигрыш и сумму
                x = this.choosing[choiceIndex].x;
                y = this.choosing[choiceIndex].y;
                const sprite = new Sprite(this.getTexture('boxUsed'));
                sprite.name = 'choiceIndex' + (choiceIndex + 5);
                sprite.position.set(x, y);
                sprite.interactive = true;
                sprite.buttonMode = true;
                parentContainer.addChild(sprite);
            }
            if (multiplier === -1) {    // выбор еще не сделан, // отображаем первый кадр анимации                        }
                x = this.choosing[choiceIndex].x;
                y = this.choosing[choiceIndex].y;
                const sprite = new Sprite(this.getTexture('box'));
                sprite.name = 'choiceIndex' + (choiceIndex + 5);
                sprite.position.set(x, y);
                sprite.interactive = true;
                sprite.buttonMode = true;
                sprite.on('pointerdown', () => this.newChoice());       // call next choice
                parentContainer.addChild(sprite);
            }
        });

        // total win
        const richText1 = new Text(this.choosingWin, this.textBonus);
        richText1.position.set(390, 285 + 80);
        parentContainer.removeChild(parentContainer.getChildByName('total'));
        richText1.name = 'total';
        parentContainer.addChild(richText1);

        App.updateButton('start', {
            disabled: false,
            title: 'start',
            handler: () => this.newChoice()
        });
    }

    /**
     * Функция отображения предыдущего выбора или супербонуса
     */
    showChosedItemSpin(parentContainer) {
        const response = this.choosingResponse;
        this.choosingStep = response != null ? response.step + 1 : 0;
        const winX = this.choosing2[0].textPosition.x;
        const winY = this.choosing2[0].textPosition.y;

        // Attempts
        parentContainer.removeChild(parentContainer.getChildByName('attempts'));
        const richText = new Text(this.attempts, this.textBonus);
        richText.position.set(winX, winY);
        richText.name = 'attempts';
        parentContainer.addChild(richText);
        const totalX = this.totalwin2.x;
        const totalY = this.totalwin2.y;
        const richText1 = new Text(this.choosingWin, this.textBonus);
        richText1.position.set(totalX, totalY);
        parentContainer.removeChild(parentContainer.getChildByName('total'));
        richText1.name = 'total';
        parentContainer.addChild(richText1);

        const pressButtonLeft = this.createChoosingElement(parentContainer, 'pressButton',  // dice sprite
            800, 34, // w h
            0, 80, 0, 2, 1, true, true);
        pressButtonLeft.loop = true;
        pressButtonLeft.animationSpeed = 0.05;
        pressButtonLeft.name = 'pressButtonLeft';
        parentContainer.addChild(pressButtonLeft);

        App.updateButton('start', {
            disabled: false,
            title: 'start',
            handler: () => this.newChoice()
        });
    }

    /**
     * Фукнция отображения выбора Чузинга на ответ сервера
     */
    showChoice(response) {
        const parentContainer = this.getStageChild('bonusContainer');
        this.setState('CHOOSING_ANIMATION');

        if (this.choosingGame === 11) {  // dice choosing
            App.Sounds.playSound('diceDrop');
            this.showChoiceAnimation(response);
        } else {  // spin choosing
            parentContainer.removeChild(parentContainer.getChildByName('pressButtonLeft'));
            const position = this.getRouletePos(response);      // spin animation 10 symbol  // can be more then one choosing by each line
            parentContainer.getChildByName('button').visible = false; // make last hand unvisible
            this.attempts--;
            parentContainer.removeChild(parentContainer.getChildByName('attempts'));
            const richText = new Text(this.attempts, this.textBonus);
            const winX = this.choosing2[0].textPosition.x;
            const winY = this.choosing2[0].textPosition.y;
            richText.position.set(winX, winY);
            richText.name = 'attempts';
            parentContainer.addChild(richText);
            this.showChoiceAnimationSpin(response, position);
        }
    }

    creteChosingStageSpin() {
        const parentContainer = this.getStageChild('bonusContainer');

        const roulette = this.createChoosingElement(parentContainer, 'animation2',  // dice sprite
            388, 388, // w h
            this.rouleteMap[0].x, this.rouleteMap[0].y, 1, 20, 10, false, true);
        roulette.name = 'roulette';
        parentContainer.addChild(roulette);

        const spriteButton = new Sprite(this.getTexture('button'));
        spriteButton.name = 'button';
        spriteButton.position.set(this.rouleteMap[0].xb, this.rouleteMap[0].yb);
        parentContainer.addChild(spriteButton);
    }

    /**
     * Функция анимации самого выбора в чузинге
     */
    showChoiceAnimation(response) {
        const parentContainer = this.getStageChild('bonusContainer');
        this.setState('CHOOSING_ANIMATION');
        parentContainer.interactiveChildren = false;
        const choice = response.choice;
        const multiplier = response.multiplier;
        const buttonStep = (response.extension.offset + this.chipPos) % 20;
        const button = parentContainer.getChildByName('button');

        const frameCount = this.choosing[0].frameCount;
        const colCount = this.choosing[0].colCount;
        const imageSprite = 'chosingWin';
        const sound = 'chosingWin';

        parentContainer.getChildByName('choiceIndex11').visible = false; // make last hand unvisible

        const sprite1 = this.createChoosingElement(parentContainer, 'dices',  // dice sprite
            82, 84, // w h
            580, 300, 0, 6, 6, false, false);
        parentContainer.addChild(sprite1);

        const sprite = this.createChoosingElement(parentContainer, imageSprite,  // hand sprite
            this.choosing[choice].w, 201, // w h
            this.choosing[6].x, this.choosing[6].y, 0, frameCount, colCount, true);

        sprite.onFrameChange = frame => {
            if (frame === frameCount - 1) {
                sprite.visible = false;
                sprite.stop();
                sprite1.visible = true;
                sprite1.gotoAndStop(response.extension.offset - 1);
                this.waitingAnimationFrame = setInterval(() => {
                    if (this.chipPos !== buttonStep) {
                        App.Sounds.playSound('spinRoll');
                        this.chipPos = (this.chipPos + 1) % 20;
                        button.position.set(this.dicesMap[this.chipPos].x, this.dicesMap[this.chipPos].y);  // move chip according to dice
                    }
                }, 300);

                setTimeout(() => {
                    if (this.chipPos !== response.extension.position) {
                        this.chipPos = response.extension.position;
                        App.Sounds.playSound('spinRoll');
                        button.position.set(this.dicesMap[this.chipPos].x, this.dicesMap[this.chipPos].y);  // move chip proper position // back up etc.
                    }
                    clearInterval(this.waitingAnimationFrame);
                }, 1800);

                setTimeout(() => {
                    parentContainer.removeChild(parentContainer.getChildByName('choiceMultiplier'));  // delete ald text add new text
                    App.Sounds.playSound(sound);
                    sprite.visible = false;
                    sprite.stop();
                    const x = this.choosing[choice].textPosition.x;
                    const y = this.choosing[choice].textPosition.y;
                    const richText = new Text(multiplier * this.gameSettings.getBetLineCredit(), this.textBonus);  // multiplier with bet !
                    this.screenMultiplier.win[choice] = multiplier * this.gameSettings.getBetLineCredit();
                    this.screenMultiplier.mult[choice] = multiplier * this.gameSettings.getBetLineCredit();
                    richText.name = 'choiceMultiplier';
                    richText.position.set(x, y);
                    parentContainer.addChild(richText);
                }, 2500);

                setTimeout(() => {
                    this.animateMultiplier(response);
                }, 3000);
            }
        };
    }

    /**
     * Функция отображения чузинга
     */
    showChoiceAnimationSpin(response, position, animationStarted, iStep = 0) {
        if (typeof animationStarted === 'undefined' || animationStarted == null) animationStarted = new Date();
        const timeNow = Date.now();
        const timeSpent = timeNow - animationStarted; // time from last action

        const rouletteSteps = 19 + (19 - this.chipPos) + position; // One round + steps to 0 field + response position

        if (timeSpent < 150) {
            requestAnimationFrame(this.showChoiceAnimationSpin.bind(this, response, position, animationStarted, iStep));
            return;
        }
        iStep++;
        animationStarted = timeNow;
        if (iStep < rouletteSteps + 10) {               // start roulette animation +10 steps blinking
            this.rouleteStep(position, this.chipPos + iStep, rouletteSteps - (19 - this.chipPos));
        }

        if ((this.chipPos + iStep) > rouletteSteps - (19 - this.chipPos) + 15) {              // just multipler without bet !
            this.chipPos = position;
            this.screenMultiplier.win[0] = response.multiplier;
            this.screenMultiplier.mult[0] = response.multiplier;
        }

        if ((this.chipPos + iStep) > rouletteSteps - (19 - this.chipPos) + 20) {  // запрускаем анимации перечисления кредитов
            this.animateMultiplier(response, null, 0);
            return;
        }
        requestAnimationFrame(this.showChoiceAnimationSpin.bind(this, response, position, animationStarted, iStep));
    }

    /**
     * Функция анимации прокрута трех барабанов.
     * position - помера элеметов который нужно показать в рутетке
     * iStep шаг анимации
     **/
    rouleteStep(position, iStep, rouletteSteps) {
        const parentContainer = this.getStageChild('bonusContainer');
        const roulette = parentContainer.getChildByName('roulette');
        if (iStep < rouletteSteps) {                              // 2 rounds of roulette animation
            App.Sounds.stopSound('choosingSelect');
            App.Sounds.playSound('choosingSelect');
            roulette.gotoAndStop(iStep % 19);
        }
        if (iStep >= rouletteSteps) {  // stop roulette animation, blinking
            roulette.gotoAndStop(position);
        }

        if (iStep === rouletteSteps) {
            App.Sounds.playSound('spinStop');
        }
        if (iStep > rouletteSteps + 5) {  // stop roulette animation, blinking
            roulette.visible = !roulette.visible;
            const button = parentContainer.getChildByName('button');
            button.position.set(this.rouleteMap[position].xb, this.rouleteMap[position].yb);
            button.visible = true;
        }

        if (iStep >= rouletteSteps + 12) {  // stop roulette animation, blinking
            const button = parentContainer.getChildByName('button');
            roulette.visible = true;
            button.visible = true;
        }
    }

    /**
     * Функция востановления выбора после чузинга
     */
    restoreChoosingScreen(response) {
        App.Sounds.stopSound('bonusGameStart');
        this.hideBoxes();
        this.Legends.clearStatus();
        this.Legends.showWinFeatures();
        this.disableChooseButtons();
        this.choosingWin = response.win;
        this.choosingStep = response.step;
        this.Legends.setText('win', {text: 'win', value: this.choosingWin});

        const featureStart = this.choosingQueue.shift();
        this.choosingGame = featureStart.symbol;

        if (this.choosingGame === 11) {  // 11 dice choosing with extension
            this.setState('CHOOSING_IDLE');
            this.screenMultiplier = {win: [-1, -1, -1, -1, -1, -1, -1], mult: [-1, -1, -1, -1, -1, -1, -1]};  // 7 dice posible
            this.screenMultiplier.win = this.getMultiplierWin(response);
            this.screenMultiplier.mult = this.getMultiplier(response);
            this.setBackground('bonusArea');
            this.chipPos = this.choosingResponse.extension.position;
        } else {
            this.setState('CHOOSING_IDLE');// 10 spin game
            this.screenMultiplier = {win: [-1], mult: [-1]};  // spin posible
            this.attempts = featureStart.attempts - response.step - 1;
            this.creteChosingStageSpin();
            this.setBackground('bonusArea2');
        }

        if (response.stop) {
            this.finishChoosing(response);      // stop finish shoosing take win
        } else {
            setTimeout(() => this.choosingGame === 11 ?
                this.nextChoosing(response) :
                this.nextChoosingSpin(response), 500);        // go next choosing step
        }
    }

    /**
     * Функция окончания чузинга и выхода
     */
    finishChoosing(response) {
        if (this.choosingQueue.length) {
            this.choosingResponse = null;
            this.getStageChild('bonusContainer').removeChildren();
            this.bonusEntrance();      // go next choosing
            return;
        }

        this.setBackground('mainArea');
        this.showBoxes();
        this.getStageChild('bonusContainer').removeChildren();
        App.updateState('buttons', {visualization: App.buttonsType});
        this.Buttons.setDefaultGameButtons();
        this.choosingResponse = null;
        this.win = response.win;
        this.latestResponse = {payment: this.win, features: []};
        this.choosingWin = 0;
        this.choosingStep = -1;
        this.gameFlag.bonusStart = false;
        this.screenMultiplier = {win: [-1, -1, -1, -1, -1, -1, -1], mult: [-1, -1, -1, -1, -1, -1, -1]};
        this.superScreenMultiplier = {win: [-1, -1], mult: [-1, -1]};
        this.win ?
            this.takeWin() :
            this.roundFinished();
    }

    drawBonusFrame(first, last, parentContainer, coordinates) {
        parentContainer.removeChildren();
        App.Sounds.stopSound('bonus-background');

        if (first) {
            App.Sounds.playSound('bookFlash');
            this.setBackground('bonusArea');
            App.updateButton('start', {
                disabled: false,
                title: 'start',
                handler: () => this.startBonusAnimation(parentContainer)
            });
        }
        if (last) {
            parentContainer.removeChildren();
            this.setRegularSprite();
            App.updateButton('start', {disabled: true});
            this.setBackground('mainArea');
            this.playEndBonusGameSound();
            setTimeout(() => this.endBonus(), 5000);
        }
        if (!first && !last) {
            App.updateButton('start', {
                disabled: false,
                title: 'start',
                handler: () => this.drawBonusStep(this.latestResponse.features)
            });
        }
    }

    // logic from fruit coictail 1 multiplier, animate multiplier before next step

    nextChoosing(response) {
        this.superBonusStep <= response.step ?
            this.animateMultiplier(response) :
            this.showChosedItem(this.getStageChild('bonusContainer'));
    }

    nextChoosingSpin() {
        this.showChosedItemSpin(this.getStageChild('bonusContainer'));
    }

    /**
     * Функция анимации зачисления кредитов в простой бонусной игре (5 множителей)
     */
    animateBonusMultiplier(choosing, response, animationStarted = Date.now(), lastTime = animationStarted) {
        const time = Date.now();
        let totalX, totalY;
        const timeDiff = time - lastTime;
        const animateTimeout = 10;   // частота вызова функции зависит от первой и второй скорости
        if (timeDiff < animateTimeout) {
            requestAnimationFrame(this.animateBonusMultiplier.bind(this, choosing, response, animationStarted, lastTime));
            return;
        }
        let choiceIndex;
        const parentContainer = this.getStageChild('bonusContainer');

        if (this.choosingGame === 11) {                     // dice
            totalX = this.totalwin.x;
            totalY = this.totalwin.y;
            choiceIndex = this.choosingStep;
        } else {                                          // spin
            totalX = this.totalwin2.x;
            totalY = this.totalwin2.y;
            choiceIndex = 0;
        }
        const multiplier = this.screenMultiplier.mult[choiceIndex];

        parentContainer.removeChild(parentContainer.getChildByName('choiceMultiplier'));
        parentContainer.removeChild(parentContainer.getChildByName('total'));

        if (this.choosingWin === 0) {
            this.choosingWin = this.latestResponse.payment;
        }

        this.choosingWin = this.choosingGame === 11 ?
            this.choosingWin + multiplier :
            this.choosingWin + this.gameSettings.getBetLineCredit() * multiplier;

        // total win
        parentContainer.removeChild(parentContainer.getChildByName('total'));
        const richText1 = new Text(this.choosingWin, this.textBonus);
        richText1.position.set(totalX, totalY);
        richText1.name = 'total';
        parentContainer.addChild(richText1);
        this.Legends.setText('win', {text: 'win', value: this.choosingWin});
        this.Legends.setStatus('creditsWon', this.choosingWin);

        this.roundWin = this.choosingWin;
        if (response.stop) {
            setTimeout(() => this.finishChoosing(response), 2000); // Close bonus results after 2 sec.
        } else {
            this.choosingGame === 11 ?   // 11 dice
                this.nextChoosing(response) : this.nextChoosingSpin(response);
        }
    }

    /**
     * Take new wish and send new choice
     * Stop all animations and disable buttons
     */
    newChoice(step = ++this.choosingStep) {
        JL().debug(`-- New choice: ${step}`);
        this.getStageChild('bonusContainer').interactiveChildren = false;
        this.disableChooseButtons();
        App.Socket.send(JSON.stringify({uc: 'MAKE-CHOOSING', choice: step}));
    }

    /**
     * Start scatter animations
     * @param feature
     */
    bonusEntrance(feature) {
        this.setBackground('mainArea');
        this.setState('SCATTER_FEATURE');
        this.playBonusGameSound();
        this.stopAnimateFeature();
        this.disableChooseButtons();

        const featureStart = this.choosingQueue.pop();
        this.choosingWin = featureStart.win;
        this.choosingGame = featureStart.symbol;
        this.chipPos = 0;

        this.stopAnimateFeature();
        const parentContainer = this.getStageChild('bonusContainer');

        if (this.choosingGame === 11) { // 11 dice choosing
            this.drawTopAnimation(this.getStageChild('bonusContainer'));
            setTimeout(() => { // call after scatter played
                this.setState('CHOOSING_IDLE');
                this.hideBoxes();
                App.Sounds.playSound('diceBonus');
                this.setBackground('bonusArea');
                this.showChosedItem(this.getStageChild('bonusContainer'));
                this.screenMultiplier = {win: [-1, -1, -1, -1, -1, -1, -1], mult: [-1, -1, -1, -1, -1, -1, -1]};
                parentContainer.removeChild(parentContainer.getChildByName('blinking'));
            }, 2000);
        } else {                                   // spin choosing
            this.drawTopAnimation(this.getStageChild('bonusContainer'));
            setTimeout(() => { // call after scatter played
                this.setState('CHOOSING_IDLE');
                this.hideBoxes();
                this.attempts = featureStart.attempts;
                // App.Sounds.playSound('spinBonus');
                this.screenMultiplier = {win: [-1], mult: [-1]};
                this.creteChosingStageSpin();
                this.setBackground('bonusArea2');
                this.showChosedItemSpin(this.getStageChild('bonusContainer'));
                parentContainer.removeChild(parentContainer.getChildByName('blinking'));
            }, 2000);
        }
    }

    /**
     * Функция получения позиции рулетки
     */
    getRouletePos(response) {
        let x = 0;

        // позитивный выигрыш
        do {
            x = Math.floor((Math.random() * 19));          // получаем произвольную позицию выигрышного елемнета
        }
        while (this.rouleteMap[x].picture !== response.multiplier);
        return x;
    }

    /**
     * Фунция анимации зачисления кредитов в чузинге
     */
    animateMultiplier(response) {
        this.setState('ANIMATE_MULTIPLIER');
        this.animateBonusMultiplier(this.choosing, response, Date.now());
    }

    /**
     * Static animation.
     * Play unique additional sounds
     * It plays once in 5 seconds if not Animate feature
     */
    showWaitingAnimation() {
        this.waitingTimeout = setTimeout(() => {
            this.stopWaitingAnimation();
            this.reelMatrix.forEach((reel, reelIndex) => {
                reel.forEach((symbolObj, rowIndex) => {
                    if ((symbolObj.symbol === 9 || symbolObj.symbol === 10) && this.getState() === 'IDLE') {
                        const symbolObj = this.reelMatrix[reelIndex][rowIndex];
                        symbolObj.image = 'additional';
                        symbolObj.loop = false;
                        symbolObj.animationSpeed = 0.10;
                        this.Roll.updateSymbolSprite(symbolObj);
                        symbolObj.sprite.play();
                    }
                });
            });
        }, 5000);
    }

    stopWaitingAnimation() {
        if (this.waitingTimeout) {
            clearTimeout(this.waitingTimeout);
        }
    }

    setRegularShortSprite(clipMatrix, reelIndex, textures) {
        for (let rowIndex = 1; rowIndex < this.reelRows + 1; rowIndex++) {
            const symbolObj = clipMatrix[reelIndex][rowIndex];
            if (symbolObj.symbol === 10) {
                symbolObj.image = 'additional';
                symbolObj.loop = true;
                symbolObj.animationSpeed = 0.10;
                symbolObj.sprite.play();
            }
        }
    }

    /**
     * Called to show round results once animation is finished
     */
    rotationDone() {
        this.createReelMatrix(this.getStageChild('reelsStage'));
        this.onRotationDone();
        this.reelMatrix.forEach((reel, reelIndex) => {
            reel.forEach((symbolObj, rowIndex) => {
                if (symbolObj.symbol === 10) {
                    const symbolObj = this.reelMatrix[reelIndex][rowIndex];
                    symbolObj.image = 'additional';
                    symbolObj.loop = false;
                    symbolObj.animationSpeed = 0.10;
                    this.Roll.updateSymbolSprite(symbolObj);
                    symbolObj.sprite.play();
                }
            });
        });
        App.System.statistics.currentSpinNumber < 3 && App.System.collectFps();
    }

    /**
     * End feature animation
     * @returns {boolean} - new animation features circle
     */
    endAnimateFeature() {
        this.stopAnimateFeature();

        this.reelMatrix.forEach((reel, reelIndex) => {
            reel.forEach((symbolObj, rowIndex) => {
                if (symbolObj.symbol === 10 || symbolObj.symbol === 9) {
                    const symbolObj = this.reelMatrix[reelIndex][rowIndex];
                    symbolObj.image = 'additional';
                    symbolObj.loop = true;
                    symbolObj.animationSpeed = 0.1;
                    this.Roll.updateSymbolSprite(symbolObj);
                    symbolObj.sprite.play();
                }
            });
        });

        if (this.getState() === 'SHOW_WIN_LINES' && this.latestResponse.payment) {
            this.isAutoStart ?
                this.takeWin() :
                this.latestResponse.payment > 0 ? // если есть анимация скатера, например, без выигрыша анимируем, а потом выходит без зачисления
                    this.gambleOrTakeWin() :
                    this.roundFinished();
        } else { // no win, just feature without payment
            this.roundFinished();
        }
        return true;
    }

    drawTopAnimation(parentContainer) {
        const x = (this.choosingGame === 10) ? 390 : 200;
        const sprite = this.createChoosingElement(parentContainer, 'label',  // monkey sprite
            188, 25, // w h                                                                       //223 -50
            x, 42, 0, 2, 2, true, true);
        sprite.loop = true;
        sprite.name = 'blinking';
    }

    getJokerFormLine(feature) {
        let joker = false;
        if (feature.uc === 'WIN_LINE' && feature.symbol < 9) {  // donot multiple 9,10,11,12 symbol
            feature.reels.forEach((symbolObj, reelIndex) => {
                const {coordinates} = this.Lines.lines[feature.number];
                if (this.reelMatrix[reelIndex][coordinates[reelIndex]].symbol === 9) joker = true;
            });
        }
        return joker;
    }

    createFeatureInfo(feature, container) {
        const {number, symbol, reels, positions, payment, uc} = feature; // get current feature params
        const ucReels = uc === 'WIN_LINE' ? reels.length : positions.length; // check 'SCATTER' feature

        const textProps = {
            font: 'Arial',
            fontSize: 16,
            fontWeight: 600,
            fill: '#f0e7cb'
        };
        const winSymbolsContainer = new Container();
        winSymbolsContainer.position.set(-100, -170);
        winSymbolsContainer.zIndex = 200;
        winSymbolsContainer.name = 'winSymbolsContainer';
        container.addChild(winSymbolsContainer);
        const statusLine = new Text(
            `${uc === 'WIN_LINE' ? App.language.line.toUpperCase() : 'SCATTERED'} ${uc === 'WIN_LINE' ? number + 1 : ''}: `,
            textProps
        );
        statusLine.position.set(240, 468);
        this.getJokerFormLine(feature);

        const x2 = (this.gameFlag.bonusStart && uc === 'WIN_LINE' &&
            !((symbol === 9 && reels.length === 5) || (symbol === 11 && reels.length === 5))) || this.getJokerFormLine(feature) ?
            'x2' : '';
        const statusPayment = new Text(
            x2 + ` = ${payment} `,
            textProps
        );
        statusPayment.position.set(480, 468);

        for (let i = 0; i <= ucReels - 1; i++) {
            const minimizeSymbol = new Sprite(this.getSpriteTextures({
                image: 'minimizeSymbols',
                fromFrame: symbol,
                colCount: this.symbols.length,
                width: 25,
                height: 23
            })[0]);

            minimizeSymbol.position.x = 450 + (25 * i);
            minimizeSymbol.position.y = 636;
            winSymbolsContainer.addChild(minimizeSymbol);
        }

        container.addChild(statusLine, statusPayment);
    }

    getMultiplierWin(response) {
        const multiplier = [];
        for (let i = 0; i <= this.superBonusStep; i++) {
            multiplier.push(-1);
        }
        response.prevChoosings.forEach(choosing => {
            multiplier[parseInt(choosing.choice, 10)] = choosing.multiplier * this.gameSettings.getBetLineCredit();
        });
        if (response.multiplier !== undefined && parseInt(response.choice, 10) <= this.superBonusStep) {
            multiplier[parseInt(response.choice, 10)] = response.multiplier * this.gameSettings.getBetLineCredit();
        }
        return (multiplier);
    }
}
