import {JL} from 'jsnlog';

import Lines from './../../igrosoft/lines9';

export default class Lines9 extends Lines {
    constructor() {
        super();
        this.imageResources = {
            lines: '/../../img/lines/Lines9_igrosoft/keks/lines.png',
            winLines: '/../../img/lines/Lines9_igrosoft/keks/winlines.png',
            boxes: '/../../img/lines/Lines9_igrosoft/boxes.png'
        };
        this.lineBoxWidth = 28; // width of winline boxes, magic number for each game
        this.lineBoxHeight = 33; // height of winline boxes, magic number for each game
        this.lineImageWidth = 722; // width of line image in sprite, magic number for each game
        this.lineImageHeight = 110; // height of line image in sprite, magic number for each game

        this.lines = {
            0: {
                coordinates: [1, 1, 1, 1, 1],
                boxes: [{x: 23, y: 204}, {x: 748, y: 204}],
                path: [{x: 41, y: 214}],
                width: 16,
                spriteY: 0
            },
            1: {
                coordinates: [0, 0, 0, 0, 0],
                boxes: [{x: 23, y: 44}, {x: 748, y: 44}],
                path: [{x: 41, y: 56}],
                width: 16,
                spriteY: 16
            },
            2: {
                coordinates: [2, 2, 2, 2, 2],
                boxes: [{x: 23, y: 364}, {x: 748, y: 364}],
                path: [{x: 41, y: 370}],
                width: 16,
                spriteY: 32
            },
            3: {
                coordinates: [0, 1, 2, 1, 0],
                boxes: [{x: 23, y: 122}, {x: 748, y: 122}],
                path: [{x: 41, y: 130}],
                width: 215,
                spriteY: 48
            },
            4: {
                coordinates: [2, 1, 0, 1, 2],
                boxes: [{x: 23, y: 286}, {x: 748, y: 286}],
                path: [{x: 41, y: 95}],
                width: 215,
                spriteY: 263
            },
            5: {
                coordinates: [0, 0, 1, 0, 0],
                boxes: [{x: 23, y: 84}, {x: 748, y: 84}],
                path: [{x: 41, y: 94}],
                width: 93,
                spriteY: 478
            },
            6: {
                coordinates: [2, 2, 1, 2, 2],
                boxes: [{x: 23, y: 324}, {x: 748, y: 324}],
                path: [{x: 41, y: 257}],
                width: 93,
                spriteY: 571
            },
            7: {
                coordinates: [1, 2, 2, 2, 1],
                boxes: [{x: 23, y: 242}, {x: 748, y: 242}],
                path: [{x: 41, y: 244}],
                width: 74,
                spriteY: 664
            },
            8: {
                coordinates: [1, 0, 0, 0, 1],
                boxes: [{x: 23, y: 164}, {x: 748, y: 164}],
                path: [{x: 41, y: 122}],
                width: 74,
                spriteY: 738
            }
        };
        JL().debug('-- KeksLines9 initialized');
    }
}
