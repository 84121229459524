import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import BookGame from './../../deluxe/bookGame';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';
import Lines20 from './../../deluxe/lines20';

/* PIXI aliases */
const Container = PIXI.Container,
    Sprite = PIXI.Sprite,
    AnimatedSprite = PIXI.AnimatedSprite,
    Text = PIXI.Text;

export default class PharaohsRing extends BookGame {
    constructor() {
        super();
        this.id = 'pharaohs-ring';
        this.name = 'Pharaoh`s ring';
        this.symbolEffects = true;
        this.buttonsPanelShadow = 'mid';
        this.scatter = [9, 10];
        this.reelFilter = [[10], [], [10], [], [10]];

        this.extraBet = true; // existing ExtraBet feature in game
        this.extraBetActive = false; // current enabled state

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 55, y: 77},
            bonusInBonusFrame: {x: 55, y: 77},
            endBonusFrame: {x: 55, y: 77}
        };

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 0, 5, 30, 100]},       // 0 - цифра 10
            {regularDelay: 100, payment: [0, 0, 0, 5, 30, 100]},       // 1 - буква J
            {regularDelay: 100, payment: [0, 0, 0, 5, 30, 100]},       // 2 - буква Q
            {regularDelay: 100, payment: [0, 0, 0, 10, 40, 150]},      // 3 - буква K
            {regularDelay: 100, payment: [0, 0, 0, 10, 40, 150]},      // 4 - буква A
            {regularDelay: 100, payment: [0, 0, 5, 30, 100, 750]},     // 5 - жук скарабей
            {regularDelay: 100, payment: [0, 0, 5, 30, 100, 750]},     // 6 - девушка
            {regularDelay: 100, payment: [0, 0, 5, 40, 400, 2000]},    // 7 - синий кот
            {regularDelay: 100, payment: [0, 0, 10, 100, 1000, 5000]}, // 8 - фараон
            {regularDelay: 100, payment: [0, 0, 0, 2, 20, 200]},       // 9 - книга
            {regularDelay: 100, payment: [0, 0, 0, 0, 0, 0]}           // 10 - кольцо
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                ex_deactivated: 'bonus/ex-deactivated.png',
                ex_activated: 'bonus/ex-activated.png'
            }),
            atlas: this.mergePath([
                'staticSymbols.json',
                'bonus/additionalSymbols.json'
            ])
        };
        this.additionalResources = {
            main: this.mergePath({
                bonusArea: 'area/bonus.png',
                bonusFrame: 'bonus/bonusFrame.png',
                frame: 'bonus/frame.png',
                book: 'bonus/book.png',
                bookAnim: 'bonus/book-anim.png',
                bookFlash: 'bonus/book_flash.png',
                openedBook: 'bonus/opened-book.png',
                symbolsAnim: 'bonus/symbolsAnim.png',
                numbers1: 'bonus/numbers1.png',
                numbers2: 'bonus/numbers2.png'
            }),
            atlas: this.mergePath([
                'bonus/bonusSymbols.json',
                'bonus/bonusSymbols2.json',
                'bonus/scatterSymbols.json'
            ])
        };

        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'bonus-game-end', alias: 'bonusGameEnd'},
                {name: 'bonus-background', loop: true},
                {name: 'book-flip-pages', alias: 'bookFlipPages', loop: true},
                {name: 'book-selected-symbol', alias: 'bookSelectedSymbol'},
                {name: 'extra_bet_activation', path: '/audio/deluxe/'},
                {name: 'extra_bet_deactivation', path: '/audio/deluxe/'},
                {name: 'bonus-win-symbols-show', alias: 'bonusWinSymbols'}
            ],
            path: `/game/games/${this.id}/audio/`
        };

        this.Lines = new Lines20();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            inactiveBlack: 'gamble/black-inactive.png',
            smallCard: 'gamble/card-small.png',
            activeRed: 'gamble/red-active.png',
            inactiveRed: 'gamble/red-inactive.png',
            activeBlack: 'gamble/black-active.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 4}, {
            info1_deactive: 'area/info1-screen-deactivated.jpg'
        }); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines - current lines count
     * @param bet - current bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '14pt Arial';
        ctx.fillStyle = '#0b0350';
        ctx.strokeStyle = '#000';
        ctx.lineWidth = 3;
        ctx.textAlign = 'center';

        const textProps = {
            fontHeight: 20,
            gradientColor: {from: '#f89800', to: '#f8fc00'}
        };

        switch (page) {
            case 1:
                const image = this.getAdditionalImage(this.extraBetActive ? 'info1' : 'info1_deactive');
                ctx.drawImage(image, -(image.width - this.gameFieldWidth) / 2, 0);

                // фараон
                ctx.fillText(bet * this.symbols[8].payment[5], 215, 156);
                ctx.fillText(bet * this.symbols[8].payment[4], 215, 173);
                ctx.fillText(bet * this.symbols[8].payment[3], 215, 190);
                ctx.fillText(bet * this.symbols[8].payment[2], 215, 207);
                // котан
                ctx.fillText(bet * this.symbols[7].payment[5], 595, 156);
                ctx.fillText(bet * this.symbols[7].payment[4], 595, 173);
                ctx.fillText(bet * this.symbols[7].payment[3], 595, 190);
                ctx.fillText(bet * this.symbols[7].payment[2], 595, 207);
                // чувіха
                ctx.fillText(bet * this.symbols[6].payment[5], 65, 157);
                ctx.fillText(bet * this.symbols[6].payment[4], 65, 174);
                ctx.fillText(bet * this.symbols[6].payment[3], 65, 191);
                ctx.fillText(bet * this.symbols[6].payment[2], 65, 208);
                // жук
                ctx.fillText(bet * this.symbols[5].payment[5], 730, 157);
                ctx.fillText(bet * this.symbols[5].payment[4], 730, 174);
                ctx.fillText(bet * this.symbols[5].payment[3], 730, 191);
                ctx.fillText(bet * this.symbols[5].payment[2], 730, 208);
                // AK
                ctx.fillText(bet * this.symbols[4].payment[5], 65, 323);
                ctx.fillText(bet * this.symbols[4].payment[4], 65, 340);
                ctx.fillText(bet * this.symbols[4].payment[3], 65, 357);
                // QJ10
                ctx.fillText(bet * this.symbols[2].payment[5], 730, 323);
                ctx.fillText(bet * this.symbols[2].payment[4], 730, 340);
                ctx.fillText(bet * this.symbols[2].payment[3], 730, 357);

                // scatter
                ctx.font = '15pt Arial';
                this.drawGradientFont(ctx, bet * nLines * this.symbols[9].payment[5], 250, 291, textProps);
                this.drawGradientFont(ctx, bet * nLines * this.symbols[9].payment[4], 250, 310, textProps);
                this.drawGradientFont(ctx, bet * nLines * this.symbols[9].payment[3], 250, 329, textProps);
                this.drawGradientFont(ctx, 'SUBSTITUTES for', 539, 280, textProps);
                this.drawGradientFont(ctx, 'All symbols', 539, 300, textProps);
                this.drawGradientFont(ctx, 'EXCEPT', 539, 330, textProps);
                this.drawGradientFont(ctx, 'with', 400, 150, textProps);
                this.drawGradientFont(ctx, 'SPECIAL EXPANDING', 400, 180, textProps);
                this.drawGradientFont(ctx, 'SYMBOL', 400, 210, textProps);

                ctx.font = 'bold 20pt Arial';
                this.drawGradientFont(ctx, '10 FREE GAMES', 400, 130, textProps);

                if (this.extraBetActive) {
                    ctx.fillStyle = '#ecf7e7';
                    ctx.font = '20pt Arial';
                    ctx.shadowColor = 'black';
                    ctx.shadowOffsetX = 1;
                    ctx.shadowOffsetY = 1;
                    ctx.shadowBlur = 3;
                    ctx.lineWidth = 1;
                    ctx.fillText('activated', 530, 448);
                    ctx.font = '13pt Arial';
                    ctx.fillText('SUBSTITUTES for ALL SYMBOLS including', 280, 497);
                    ctx.fillText('and', 515, 497);
                    this.drawGradientFont(ctx, 'SPECIAL EXPANDING SYMBOL', 665, 497, textProps);
                } else {
                    ctx.shadowColor = 'black';
                    ctx.shadowOffsetX = 2;
                    ctx.shadowOffsetY = 2;
                    ctx.shadowBlur = 3;
                    ctx.font = '20pt Arial';
                    ctx.fillStyle = '#ecf7e7';
                    ctx.fillText('Play', 220, 445);
                    ctx.fillText('to turn', 505, 445);
                    ctx.fillText('into', 613, 445);

                    ctx.font = '13pt Arial';
                    ctx.fillText('SUBSTITUTES for ALL SYMBOLS including', 280, 497);
                    ctx.fillText('and', 515, 497);
                    this.drawGradientFont(ctx, 'SPECIAL EXPANDING SYMBOL', 665, 497, textProps);
                }
                break;
            case 2:
                ctx.font = '15pt Arial';
                ctx.strokeStyle = '#161f65';
                ctx.lineWidth = 3;
                this.drawGradientFont(ctx, '3 or more scatters on the screen trigger', 540, 200, textProps);

                ctx.font = ' 25pt Times New Roman';
                this.drawGradientFont(ctx, '10 FREE GAMES', 535, 243, textProps);

                ctx.font = '13pt Arial';
                this.drawGradientFont(ctx, 'with special expanding symbol.', 540, 275, textProps);
                this.drawGradientFont(ctx, 'One special expanding symbol is randomly ', 540, 390, textProps);
                this.drawGradientFont(ctx, 'selected at the start of the Free Games. During', 540, 410, textProps);
                this.drawGradientFont(ctx, 'Free Games the special symbol expands to', 540, 430, textProps);
                this.drawGradientFont(ctx, 'cover 3 positions on the reel and pays in any', 540, 450, textProps);
                this.drawGradientFont(ctx, 'position on lines played.', 540, 470, textProps);
                break;

            case 3:
                ctx.font = '22pt Arial';
                ctx.fillStyle = '#ecf7e7';
                ctx.fillText('Play', 150, 180);
                ctx.fillText('to activate', 435, 180);
                ctx.fillText('on Reels', 620, 180);
                ctx.fillText('2 and 4', 400, 220);
                ctx.fillText('SUBSTITUTES for ALL SYMBOLS', 450, 320);
                ctx.fillText('including', 185, 380);
                ctx.fillText('and', 340, 380);

                ctx.font = ' bold 22pt Times New Roman';
                this.drawGradientFont(ctx, 'EXTRA BET', 270, 180, textProps);
                this.drawGradientFont(ctx, 'SPECIAL EXPANDING', 535, 380, textProps);
                this.drawGradientFont(ctx, 'SYMBOL', 400, 420, textProps);
                break;

            case 4:
                ctx.font = '22pt Arial';
                ctx.strokeStyle = '#161f65';
                this.drawGradientFont(ctx, 'RULES', 400, 220, textProps);

                ctx.font = '13pt Arial';
                this.drawGradientFont(ctx, 'All prizes are for combinations of a kind. All prizes are for combinations', 400, 250, textProps);
                this.drawGradientFont(ctx, 'left to right, except scatters. All prizes are on selected lines, except scatters.', 400, 275, textProps);
                this.drawGradientFont(ctx, 'Scatter symbols pay at any position on screen. Highest win only paid per', 400, 300, textProps);
                this.drawGradientFont(ctx, 'selected line. Scatter wins are added to line wins. Free Games are played at', 400, 325, textProps);
                this.drawGradientFont(ctx, 'trigger bet and lines. Free Games can be won again during the Free Games.', 400, 350, textProps);
                this.drawGradientFont(ctx, 'Scatters are evaluated before the expansion of the special expanding', 400, 375, textProps);
                this.drawGradientFont(ctx, 'symbol. All prizes shown in credits. Malfunction voids all pays and plays.', 400, 400, textProps);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    /**
     * Process reels response from server.
     * @param response - Socket response 'ROLL'
     */
    processReelResponse(response) {
        this.latestResponse = response;
        this.checkRingPositions(this.latestResponse.features);
        this.setState('RESPONSE_RECEIVED');
        this.setBonusRollSymbol(); // for bonus game roll symbol
        this.prepareToRollAnimation(response);
    }

    /**
     * If extra bet is activated, function is checking positions of ring, when it replaces scatter and push coordinates of this position in latestResponse.
     * @param features
     */
    checkRingPositions(features) {
        let scatterFeatureIndex = null;
        features.forEach((feature, featureIndex) => {
            if (feature.uc === 'SCATTER') {
                scatterFeatureIndex = featureIndex;
            }

            feature.uc === 'FREE_ROLL' && this.extraBetActive &&
            this.latestResponse.screen.forEach((screen, reelIndex) => {
                screen.forEach((symbolIndex, rowIndex) => {
                    symbolIndex === 10 &&
                    this.latestResponse.features[scatterFeatureIndex].positions.push({
                        row: rowIndex,
                        reel: reelIndex
                    });
                });
            });
        });
    }

    /**
     * Check symbol image
     * Can be redefined to 'regular'/'bonus'/'additional'
     * Call for Roll.initReels() and Roll.updateFullClipMatrix()
     */
    getSymbolImageType = symbolIndex =>
        [this.bonusRollSymbol, ...this.scatters].includes(symbolIndex) &&
        this.gameFlag.bonusStarted ?
            'bonus' : !this.extraBetActive && symbolIndex === 10 ? 'additional' : 'static';

    /**
     * Function call after extraBet toggle
     * Unique changes for each game
     * @param interactive - enable button
     */
    updateExtraBetButtons(interactive = true) {
        const container = this.getStageChild('extraBetContainer');
        const button = container.getChildByName('extraBetButton');

        button.texture = this.getTexture(this.extraBetActive ? 'ex_activated' : 'ex_deactivated');
        button.interactive = interactive;

        // update 'ring' symbol image
        this.reelMatrix.forEach(reel => {
            reel.forEach(symbolObj => {
                if (symbolObj.symbol === 10) {
                    symbolObj.image = this.extraBetActive ? 'bonus' : 'additional';
                    this.Roll.updateSymbolSprite(symbolObj);
                }
            });
        });
    }

    /**
     * Show bonus message with bonus symbol or with bonus game win.
     * @param isFirstBonus {boolean} TRUE if this is the message for starting bonus game.
     */
    drawBonusAskButton(isFirstBonus = false) {
        let isLast = !isFirstBonus && this.bonusStatus && this.bonusStatus.remain === 0;

        this.stopAnimateFeature();
        setTimeout(() => {
            this.drawBonusFrame(isFirstBonus, isLast, this.getStageChild('bonusContainer'), this.coordinatesBonusFrame);
        }, 1000);

        this.gameFlag.bonusStart = true;
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});

        isLast = this.bonusStatus && this.bonusStatus.remain === 0;
    }

    drawBonusFrame(first, last, parentContainer, coordinates) {
        App.Sounds.stopSound('bonus-background');

        const textStyle = {
            align: 'center',
            fontFamily: 'Times New Roman',
            fontSize: 45,
            fontWeight: 'bold',
            fill: ['#f8fc00', '#f89800'], // gradient
            stroke: '#15206d',
            strokeThickness: 3,
            lineJoin: 'round',
            lineHeight: 75
        };

        const {startBonusFrame, bonusInBonusFrame, endBonusFrame} = coordinates;
        if (first) {
            this.setBackground('bonusArea');
            this.drawFrameAnimation(parentContainer, startBonusFrame, textStyle);
        }

        if (last) {
            this.setRegularSprite();
            App.updateButton('start', {disabled: true});
            this.setBackground('mainArea');
            this.showEndBonusFrame(parentContainer, textStyle, endBonusFrame, this.bonusStatus);
            this.playEndBonusGameSound();
            setTimeout(() => this.endBonus(), 5000);
        }

        if (!first && !last) {
            this.showBonusInBonusFrame(parentContainer, textStyle, bonusInBonusFrame);
            App.updateButton('start', {
                disabled: false,
                title: 'start',
                handler: () => this.drawBonusStep(this.latestResponse.features)
            });
        }
    }

    setRegularSprite() {
        this.reelMatrix.forEach(reel => {
            reel.forEach(symbolObj => {
                symbolObj.symbol !== 10 && !this.extraBetActive && (symbolObj.image =
                    this.symbolAnimation ? 'regular' : 'static');
                this.Roll.updateSymbolSprite(symbolObj);
            });
        });
    }

    /**
     * drawFrameAnimation
     */
    drawFrameAnimation(parentContainer, {x, y}, textStyle) {
        const sprite = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 15, image: 'bonusFrame',
            width: 700, height: 399,
            colCount: 2, rowCount: 8,
            direction: 'vertical'
        }));
        sprite.name = 'frame';
        sprite.position.set(x, y);
        sprite.animationSpeed = 0.2;
        sprite.loop = false;
        sprite.play();
        sprite.onComplete = () => {
            this.drawBookFlashAnimation(parentContainer);
            const richText = new Text('10 FREE GAMES ', textStyle);
            richText.x = 235;
            richText.y = 148;
            textStyle.fontSize = 20;

            const richText2 = new Text('with', textStyle);
            richText2.x = 393;
            richText2.y = 200;
            textStyle.fontSize = 30;
            const richText3 = new Text('special expanding symbol', textStyle);
            richText3.x = 248;
            richText3.y = 225;
            parentContainer.addChild(richText, richText2, richText3);
        };

        parentContainer.addChild(sprite);
    }

    showBonusInBonusFrame(parentContainer, textStyle, {x, y}) {
        this.showBonusFrame(parentContainer, x, y);

        const richText = new Text('10 MORE \nFREE GAMES', textStyle);
        richText.x = 253;
        richText.y = 208;
        parentContainer.addChild(richText);
    }

    showEndBonusFrame(parentContainer, textStyle, {x, y}, {win, total}) {
        this.showBonusFrame(parentContainer, x, y);
        textStyle.lineHeight = 55;

        const richText = new Text(`FEATURE WIN\n${win} CREDITS`, textStyle);
        richText.x = 235;
        richText.y = 213;

        parentContainer.addChild(richText);
    }

    /**
     * Play flash animation after show bonus frame
     * @param parentContainer
     */
    drawBookFlashAnimation(parentContainer) {
        const bookContainer = new Container();
        bookContainer.name = 'bookContainer';
        bookContainer.sortableChildren = true;  // this option for zIndex turn on
        parentContainer.addChild(bookContainer);

        const bookSprite = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 7, image: 'bookFlash',
            width: 259, height: 122
        }));
        bookSprite.name = 'book';
        bookSprite.position.set(277, 270);
        bookSprite.animationSpeed = 0.15;
        bookSprite.loop = false;
        bookSprite._zIndex = 3;

        bookSprite.play();
        bookSprite.onComplete = () => {
            this.showPressAnyButton(false);
            App.updateButton('start', {
                disabled: false,
                title: 'start',
                handler: () => this.startBonusAnimation(bookContainer)
            });
        };
        bookContainer.addChild(bookSprite);
    }

    /**
     * Draw all bonus enter animations
     * @param bookContainer
     * @param bonusSymbol - current bonus symbol in extension
     */
    drawBonusAnimation(bookContainer, bonusSymbol) {
        const bookSprite = this.getStageChild('bonusContainer').getChildByName('bookContainer').getChildByName('book');
        let firstSymbolSprite = null;
        bookSprite.textures = this.getSpriteTextures({
            toFrame: 9, image: 'bookAnim',
            width: 259, height: 122,
            colCount: 3
        });

        bookSprite.zIndex = 0;
        bookSprite.animationSpeed = 0.5;
        bookSprite.play();
        bookSprite.onComplete = () => {
            let symbolsArray = [0, 1, 2, 3, 4, 5, 6, 7, 8];
            const bonusSymbol = symbolsArray.indexOf(this.bonusRollSymbol);
            symbolsArray.splice(bonusSymbol, 1); // cut our bonusSymbol from array of symbols
            this.shuffle(symbolsArray);
            symbolsArray = symbolsArray.slice(0, 5);  // decrease array of symbols to 6 elements
            symbolsArray.push(this.bonusRollSymbol);  // push our bonusSymbol in the end of array of symbols to stop on it

            const emptyPageSprite = new AnimatedSprite(this.getSpriteTextures({  // create emptyPageSprite at the top of bookSprite
                fromFrame: 9,
                toFrame: 18,
                image: 'bookAnim',
                width: 259,
                height: 122,
                colCount: 3
            }));
            emptyPageSprite.name = 'emptyPageSprite';
            emptyPageSprite.position.set(277, 270);
            emptyPageSprite.loop = false;
            emptyPageSprite.animationSpeed = 0.5;
            emptyPageSprite.zIndex = 3;
            emptyPageSprite.play();
            bookContainer.addChild(emptyPageSprite);
            App.Sounds.playSound('bookFlipPages');

            emptyPageSprite.onFrameChange = (frame) => {
                if (frame >= 4 && !bookContainer.getChildByName(`symbol0`)) {
                    firstSymbolSprite = createSprites(bookContainer, symbolsArray, 0); // created sprite of first Symbol under emptyPageSprite
                    bookSprite.textures = [this.getTexture('openedBook')];
                }
            };
            emptyPageSprite.onComplete = () => {
                firstSymbolSprite && firstSymbolSprite.play();
                emptyPageSprite.destroy();
            };
        };

        /*
           this function creates two sprites:
           1. currentSprite - for book symbol flip;
           2. newSprite - next symbol page. it is created
              on 4 frame of currentSprite and it plays when
              currentSprite playing is completed.
        */
        const createSprites = (bookContainer, symbolsArray, symbolIndex) => {
            if (symbolIndex < 6) {
                let newSprite = null;
                const currentSprite = new AnimatedSprite(this.getSpriteTextures({
                    fromFrame: symbolsArray[symbolIndex] * 9,  // take number of symbol from symbolsArray
                    toFrame: symbolsArray[symbolIndex] * 9 + 9,  // take 9 frames of symbol in symbolsAnim image
                    image: 'symbolsAnim',
                    width: 259,
                    height: 122,
                    colCount: 9
                }));
                currentSprite.name = `symbol${symbolIndex}`;
                currentSprite.zIndex = 2;
                currentSprite.position.set(277, 270);
                currentSprite.loop = false;
                currentSprite.animationSpeed = 0.5;

                currentSprite.onFrameChange = frame => {
                    if (frame >= 4) {
                        newSprite = createSprites(bookContainer, symbolsArray, symbolIndex);
                        newSprite.zIndex = 1;
                        currentSprite.zIndex = 2;
                    }
                };

                currentSprite.onComplete = () => {
                    symbolIndex !== 5 && newSprite.play();
                    if (symbolIndex === 5) {
                        this.drawPaymentsOnBook(bookContainer, 301, 291);
                        App.Sounds.stopSound('bookFlipPages');
                        App.Sounds.playSound('bookSelectedSymbol');
                        setTimeout(() => this.bonusRoll(), 2000);
                    }
                    currentSprite.destroy();
                };

                bookContainer.addChild(currentSprite);
                symbolIndex += 1; // it for choose next symbol from symbolsArray

                return currentSprite;
            }
        };
    };

    drawPaymentsOnBook(parentContainer, x, y) {
        const image = this.symbols[this.bonusRollSymbol].payment[2] === 0 ?
            'numbers1' : 'numbers2';
        const paymentsImage = new Sprite(this.getTexture(image));
        paymentsImage.position.set(x, y);
        paymentsImage.name = 'paymentsImage';
        parentContainer.addChild(paymentsImage);

        let payTable = '';
        const bet = this.gameSettings.getBetLineCredit();
        this.symbols[this.bonusRollSymbol].payment.forEach((pay) => {
            pay *= bet;
            pay !== 0 && (payTable += pay + '\n' + ' ');
        });
        payTable = payTable.split(' ').reverse().join('');

        const textPayments = new Text(payTable, {
            align: 'center',
            fontFamily: 'Times New Roman',
            fontSize: 18,
            fontWeight: 'bold',
            fill: '#161f65',
            lineJoin: 'round',
            lineHeight: 18
        });
        textPayments.name = 'textPayments';
        textPayments.x = 349;
        textPayments.y = this.symbols[this.bonusRollSymbol].payment[2] === 0 ? 306 : 298;

        parentContainer.addChild(textPayments);
    }

    shuffle = a => {  // function for shuffle elements of arrays
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    };

    /**
     * Check is current symbol is bonusSymbol || ring
     * @param reelIndex
     * @param symbol
     * @returns {boolean}
     */
    isBonusSymbol = (reelIndex, symbol) =>
        symbol === this.bonusRollSymbol || (symbol === 10 && this.extraBetActive);
}
