import {JL} from 'jsnlog';
import App from './../index';

export default class Money {
    constructor() {
        this.denominations = [1];
        this.rouletteDenominations = [1];
        this.posDenomination = 0;
        this.posRouletteDenomination = 0;
        this.minBet = null;
        this.jackpot = {};
        this.countUpFrames = []; // save for reset
    }

    /**
     * Установить копейки
     */
    setCents(response) {
        this.cents = response;
    }

    /**
     * Установить страховку (у валюте)
     */
    setInsurance(response) {
        this.insurance = response / 100;
    }

    /**
     * Установить валюту
     */
    setCurrency(response) {
        this.currency = response;
    }

    /**
     * Set jackpot type
     * @param type - gold/silver/bronze
     * @param response - value
     */
    setJackpot(type, response) {
        this.jackpot[type] = response;
    }

    /**
     * Получить копейки
     */
    getCents() {
        return this.cents || 0;
    }

    /**
     * Получить кредиты ***для отображения в игре***
     */
    getCredit() {
        return Math.floor(this.getCents() / this.getCurrentDenomination());
    }

    /**
     * Получить кредиты рулетки ***для отображения в игре***
     */
    getRouletteCredit() {
        return Math.floor(this.getCents() / this.getCurrentRouletteDenomination());
    }

    /**
     * Получить реальные деньги ***для отображения в игре***
     */
    getMoney() {
        return (this.getCents() / 100);
    }

    /**
     * Получить страховку
     */
    getInsurance() {
        return this.insurance || 0;
    }

    /**
     * Получить валюту
     */
    getCurrency() {
        return this.currency;
    }

    /**
     * Снятие общей Ставки за один прокрут
     */
    withDraw(bet) {
        return this.setCents(this.getCents() - bet);
    }

    /**
     * Получить текущею деноминацию
     */
    getCurrentDenomination() {
        return this.denominations[this.posDenomination];
    }

    /**
     * Получить текущею деноминацию рулетки
     */
    getCurrentRouletteDenomination() {
        return this.rouletteDenominations[this.posRouletteDenomination];
    }

    /**
     * Получить текущею деноминацию
     */
    getCurrentDenominationPos() {
        return this.posDenomination;
    };

    /**
     * Установить массив деноминаций
     */
    setDenominations(response) {
        this.denominations = response;
    }

    /**
     * Установить массив деноминаций для рулетки
     */
    setRouletteDenominations(response) {
        this.rouletteDenominations = response;
    }

    /**
     * Установить позицию текущей деноминации в массиве деноминаций*
     */
    setPosDenominationValue(index) {
        this.posDenomination = this.denominations.indexOf(index, 0);
    }

    /**
     * Установить позицию текущей деноминации в массиве деноминаций*
     */
    setPosRouletteDenominationValue(index) {
        this.posRouletteDenomination = this.rouletteDenominations.indexOf(index, 0);
    }

    /**
     * Установить позицию текущей деноминации в массиве деноминаций
     */
    setPosDenominationIndex = index => {
        this.posDenomination = index < this.denominations.length ? index : 0;

        App.View.setState(prevState => ({
            buttons: {
                ...prevState.buttons,
                denomination: {
                    ...prevState.buttons.denomination,
                    value: App.Money.getCurrentDenomination() / 100
                }
            },
            moneyParams: {
                ...prevState.moneyParams,
                credits: App.Money.getCredit(),
                money: App.Money.getMoney()
            }
        }));
    };

    /**
     * Установить позицию текущей деноминации в массиве деноминаций рулетки
     */
    setPosRouletteDenominationIndex = index => {
        this.posRouletteDenomination = index < this.rouletteDenominations.length ? index : 0;

        JL().debug(`-- Change denomination to ${App.Money.getCurrentRouletteDenomination() / 100}`);
        App.View.setState(prevState => ({
            buttons: {
                ...prevState.buttons,
                denomination: {
                    ...prevState.buttons.denomination,
                    value: App.Money.getCurrentRouletteDenomination() / 100
                }
            },
            moneyParams: {
                ...prevState.moneyParams,
                credits: App.Money.getRouletteCredit(),
                money: App.Money.getMoney()
            }
        }));
    };

    setMinBet = bet => this.minBet = bet;

    getMinBet = () => this.minBet;
}
