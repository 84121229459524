import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import GameAmatic from './../../amatic/game';
import Lines from './lines';
import GambleAmatic from './../../amatic/gamble';
import InfoScreen from '../../infoScreen';

/* PIXI aliases */
const Text = PIXI.Text,
    Sprite = PIXI.Sprite,
    Container = PIXI.Container;

export default class WildSeven extends GameAmatic {
    constructor() {
        super();
        this.id = 'wild-seven';
        this.name = 'Wild Seven';
        this.buttonsPanelShadow = 'mid no-blur';
        this.reelTop = 90;

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 95, y: 113},
            bonusInBonusFrame: {x: 95, y: 113},
            endBonusFrame: {x: 95, y: 113}
        };

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 0, 5, 10, 50]},     // 0 - cherry
            {regularDelay: 100, payment: [0, 0, 0, 5, 20, 100]},    // 1 - lemon
            {regularDelay: 100, payment: [0, 0, 0, 5, 20, 100]},    // 2 - orange
            {regularDelay: 100, payment: [0, 0, 0, 10, 40, 150]},   // 3 - plum
            {regularDelay: 100, payment: [0, 0, 0, 10, 40, 150]},   // 4 - grape
            {regularDelay: 100, payment: [0, 0, 0, 20, 50, 200]},   // 5 - melon
            {regularDelay: 100, payment: [0, 0, 0, 50, 250, 1000]}, // 6 - 7 (wild)
            {regularDelay: 100, payment: [0, 0, 0, 2, 10, 50]}      // 7 - star scatter
        ];
        this.imageResources = {
            main: this.mergePath({mainArea: 'area/main.png'}),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({minimizeSymbols: 'minimizeSymbols.png'}),
            atlas: this.mergePath(['additionalSymbols.json'])
        };
        this.gameSounds = {
            soundClass: 'amatic',
            path: `/game/games/${this.id}/audio/`
        };

        this.Lines = new Lines(this.mergePath({boxes: 'lines/boxes.png'}));
        this.Gamble = new GambleAmatic(this.mergePath({gambleArea: 'gamble/gamble-area.png'}));
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 18pt Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#fffe00';
        ctx.shadowColor = 'black';
        ctx.shadowOffsetX = ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 5;

        switch (page) {
            case 1:
                // cherry
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 710, 315);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 710, 340);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 710, 365);

                // plum, grape
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 165, 310);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 165, 340);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 165, 370);

                // scatter
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5] * nLines, 450, 315);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4] * nLines, 450, 340);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3] * nLines, 450, 365);

                // WILD
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 450, 165);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 450, 190);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 450, 215);

                // melon
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 165, 140);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 165, 165);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 165, 190);

                // orange, lemon
                this.strokeFillText(ctx, bet * this.symbols[1].payment[5], 710, 145);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[4], 710, 170);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[3], 710, 195);

                ctx.font = 'bold 12pt Arial';
                this.strokeFillText(ctx, 'SEVEN substitutes for all symbols except', 470, 415);
                this.strokeFillText(ctx, 'SCATTER and DOUBLES prize when ', 470, 437);
                this.strokeFillText(ctx, 'substituting', 470, 460);

                ctx.fillStyle = '#fff';
                this.strokeFillText(ctx, 'MALFUNCTION VOIDS ALL PAYS AND PLAYS', 400, 530);
                break;
            case 2:
                this.strokeFillText(ctx, 'Wins pay only from left to right!', 385, 460);
                break;
            case 3:
                this.strokeFillText(ctx, 'SEVEN substitutes for all symbols', 550, 140);
                this.strokeFillText(ctx, 'except SCATTER and DOUBLES prize', 550, 170);
                this.strokeFillText(ctx, 'when substituting', 550, 200);

                this.strokeFillText(ctx, 'SCATTER symbol pays on any position.', 550, 380);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    getSymbolImageInLine = (symbolObj, feature) =>
        symbolObj.symbol === 6 && feature.symbol !== 6 && symbolObj.sprite.playing ?
            'additional' : 'regular'; // check 'wild' symbol in win-line

    createFeatureInfo(feature, container) {
        const {number, symbol, reels, positions, payment, uc} = feature; // get current feature params
        const ucReels = uc === 'WIN_LINE' ? reels : positions; // check 'SCATTER' feature
        let multiplier = '';

        const textProps = {
            font: 'Arial',
            fontSize: 16,
            fontWeight: 600,
            fill: '#f0e7cb'
        };
        const winSymbolsContainer = new Container();
        winSymbolsContainer.position.set(-100, -170);
        winSymbolsContainer.zIndex = 200;
        winSymbolsContainer.name = 'winSymbolsContainer';
        container.addChild(winSymbolsContainer);

        // check if wild plays in line
        uc === 'WIN_LINE' && this.Lines.lines[number].coordinates.forEach((rowIndex, reelIndex) => {
            const symbolObj = this.reelMatrix[reelIndex][rowIndex];
            if (symbolObj.symbol === 6 && ucReels.includes(reelIndex) && symbol !== 6) {
                multiplier = 'x 2';
            }
        });

        const statusLine = new Text(
            `${uc === 'WIN_LINE' ? App.language.line.toUpperCase() : 'SCATTERED'} ${uc === 'WIN_LINE' ? number + 1 : ''}: `,
            textProps
        );

        const statusPaymentText = multiplier ?
            `${'win'.toUpperCase()}: ${payment / 2} ${multiplier}` :
            `${'win'.toUpperCase()}: ${payment} ${multiplier}`;

        const statusPayment = new Text(statusPaymentText, textProps);

        statusLine.position.set(240, 460);
        statusPayment.position.set(500, 460);

        for (let i = 0; i <= ucReels.length - 1; i++) {
            const minimizeSymbol = new Sprite(this.getSpriteTextures({
                image: 'minimizeSymbols',
                fromFrame: symbol,
                colCount: this.symbols.length,
                width: 22,
                height: 19
            })[0]);

            minimizeSymbol.position.x = 450 + (25 * i);
            minimizeSymbol.position.y = 630;
            winSymbolsContainer.addChild(minimizeSymbol);
        }

        container.addChild(statusLine, statusPayment);
        uc === 'WIN_LINE' && this.Lines.drawLineImages([number], reels, container, true, payment);
        this.Lines.drawBoxes(this.getStageChild('boxesContainer'), number);
    }
}
