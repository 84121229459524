import BigWin from '../../bigWin';
import App from '../../../index';

export default class BigWinUnique extends BigWin {
    finish = () => {
        const rootPayment = App.Game.latestResponse.payment;
        const {features, paymentSpecialSymbol: payment = rootPayment} = App.Game.latestResponse;
        const bigWinContainer = App.Game.getStageChild('bonusContainer').getChildByName('bigWinContainer');
        const ticker = App.Game.app.ticker;

        this.resetCoinsAnimation();
        bigWinContainer.alpha -= 0.05;
        if (bigWinContainer.alpha <= 0) {
            bigWinContainer.destroy();

            // restore previous bonus win
            App.Game.roundWin = payment;
            App.Game.bonusWin += payment;

            if (!App.Game.isBonusSymbolWin(features)) {
                App.Game.setState(App.Game.isBonus() ? 'SHOW_BIG_WIN_LINES' : 'SHOW_WIN_LINES');
                !App.Game.isBonus() && App.Game.endAnimateFeature();
            }
            App.Game.animateFeature(features);

            ticker.remove(this.finish);
            ticker.remove(this.rotateLight);
        }
    };
}
