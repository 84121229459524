export default {
    en: {
        scattersTrigger: 'on reel 1, 3, 5 trigger one of 3 kinds of bonus game.',
        archerBonus: 'In the bonus with archer, after each spin, there is the possibility of replacing a simple symbol with WILD.',
        swordBonus: 'In the sword gladiator bonus  there is  x2 multiplier. If the gladiator falls on 3 reel, this multiplier increases by one.',
        shieldBonus: 'In the shield gladiator bonus there is a chance to win additional free spin. If the gladiator falls on the 5th reel, one free roll is added.',
        rules: 'Rules',
        pays: 'Сredits play 243 ways. All wins multiplied by the bet. Highest win paid per winning combination. Coinciding wins are added.',
        combination: 'Winning combinations can occur anywhere on all reels. Each symbol can be used only once per winning combination.',
        position: 'Only positions containing the winning symbol are used in determining the win for that symbol, except int the case where a winning combination of WILDS occurs followed by a SCATTER on the next adjacent reel. In this case the winning combination of WILDS is paid.',
        leftToRight: ' All wins begin with leftmost reel and pay left to right only on adjacent reels, except SCATTER which pay any. ',
        wild: 'WILD substitutes for all symbols except SCATTER. ',
        malfunction: 'Malfunction voids all pays and plays.'
    },
    uk: {
        scattersTrigger: 'на барабані 1, 3, 5 викликають один із трьох видів бонуса.',
        archerBonus: 'У бонусі з лучницею, після кожного прокруту, є імовірність заміни простого символу на Wild.',
        swordBonus: 'У бонусі з гладіатором з мечем існує множник х2. Якщо гладіатор випадає на 3 барабані цей множник збільшується на одиницю.',
        shieldBonus: 'У бонусі з гладіатором з щитом існує шанс виграшу додаткових безкоштовних прокрутів. Якщо гладіатор випадає на 5 барабані додається один безкоштовний прокрут.',
        rules: 'Правила',
        pays: ' Кредити грають на 243 ways. Всі виграші множаться на ставку. За виграшну комбінацію виплачується найвищий виграш. Виграші, що збігаються, на різних лініях ставок додаються до вашого підсумку. ',
        combination: 'Виграшні комбінації можуть випадати на всіх барабанах. Кожен символ може бути використаний тільки один раз за виграшну комбінацію.',
        position: 'Тільки позиції, що вміщують виграшний символ використовуються щоб визначити виграш для даного символу, окрім випадків коли випадає WILDS, що супроводжується SCATTER на сусідньому барабані. В цьому випадку WILDS комбінація виграє. ',
        leftToRight: 'Усі виграшні комбінації починаються з лівого барабана до правого і виплачуються тим же чином. Винятком є SCATTER, який виплачується в будь-якому напрямку.',
        wild: 'WILD замінює всі символи крім SCATTER. ',
        malfunction: 'Несправність анулює всі платежі та ігри.'
    },
    ru: {
        scattersTrigger: 'на барабане 1, 3, 5 вызывают один из трех видов бонуса. ',
        archerBonus: 'В бонусе с лучницей, после каждого прокрута, есть вероятность замены простого символа на Wild.',
        swordBonus: 'В бонусе с гладиатором с мечом существует множитель х2. Если гладиатор выпадает на 3 барабане этот множитель увеличивается на единицу. ',
        shieldBonus: 'В бонусе с гладиатором со щитом существует шанс выигрыша дополнительных бесплатных прокрутов. Если гладиатор выпадает на 5 барабане добавляется один бесплатный прокрут. ',
        rules: 'Правила',
        pays: ' Кредиты играют на 243 ways. Все выигрыши умножаются на ставку. За выигрышную комбинацию выплачивается самый высокий выигрыш. Совпадающие выигрыши на разных линиях ставок добавляются к вашему итогу. ',
        combination: 'Выигрышные комбинации могут выпадать на всех барабанах. Каждый символ может быть использован только один раз за выигрышную комбинацию. ',
        position: 'Только позиции, содержащие выигрышный символ используются для определения выигрыша для данного символа, кроме случаев, когда выпадает WILDS, что сопровождается SCATTER на соседнем барабане. В этом случае WILDS комбинация выигрывает. ',
        leftToRight: ' Все выигрышные комбинации начинаются с левого барабана к правому и выплачиваются тем же образом. Исключением является SCATTER, который выплачивается в любом направлении. ',
        wild: 'WILD заменяет все символы кроме SCATTER. ',
        malfunction: 'Неисправность аннулирует все платежи и игры.'
    },
    de: {
        scattersTrigger: 'auf Rolle 1, 3, 5 wird eine von drei Arten von Bonusspielen ausgelöst.',
        archerBonus: 'Im Bonus mit Bogenschütze besteht nach jeder Drehung die Möglichkeit, ein einfaches Symbol durch WILD zu ersetzen.',
        swordBonus: 'Im Schwertgladiator-Bonus gibt es einen x2-Multiplikator. Wenn der Gladiator auf 3 Walzen fällt, erhöht sich dieser Multiplikator um eins.',
        shieldBonus: 'Im Schildgladiator-Bonus besteht die Möglichkeit, zusätzlichen Freispiel zu gewinnen. Wenn der Gladiator auf die 5. Rolle fällt, wird eine freie Rolle hinzugefügt.',
        rules: 'Regeln',
        pays: ' Credits spielen auf 243 Arten. Alle Gewinne multipliziert mit der Wette. Höchster Gewinn pro Gewinnkombination. Übereinstimmende Gewinne werden hinzugefügt.',
        combination: 'Gewinnkombinationen können überall auf allen Walzen auftreten. Jedes Symbol kann nur einmal pro Gewinnkombination verwendet werden.',
        position: 'Nur Positionen, die das Gewinnsymbol enthalten, werden zur Bestimmung des Gewinns für dieses Symbol verwendet, außer in dem Fall, in dem eine Gewinnkombination von WILDS auftritt, gefolgt von einem SCATTER auf der nächsten benachbarten Rolle. In diesem Fall wird die Gewinnkombination von WILDS ausgezahlt.',
        leftToRight: 'Alle Gewinne beginnen mit der Rolle ganz links und werden nur auf benachbarten Walzen von links nach rechts ausgezahlt, mit Ausnahme von SCATTER, die keine zahlen. ',
        wild: 'WILD ersetzt alle Symbole außer SCATTER. ',
        malfunction: 'Eine Fehlfunktion macht alle Zahlungen und Spiele ungültig.'
    },
    es: {
        scattersTrigger: 'en el carrete 1, 3, 5 activa uno de los 3 tipos de juego de bonificación.',
        archerBonus: 'En la bonificación con arquero, después de cada giro, existe la posibilidad de reemplazar un símbolo simple con SALVAJE.',
        swordBonus: 'En el bono de gladiador de espada hay un multiplicador x2. Si el gladiador cae en 3 carretes, este multiplicador aumenta en uno.',
        shieldBonus: 'En el bono de gladiador de escudo, existe la posibilidad de ganar giros gratis adicionales. Si el gladiador cae en el 5 ° carrete, se agrega un tiro libre.',
        rules: 'Reglas',
        pays: ' Créditos juega 243 maneras. Todas las ganancias multiplicadas por la apuesta. Mayor ganancia pagada por combinación ganadora. Se agregan victorias coincidentes.',
        combination: 'Las combinaciones ganadoras pueden ocurrir en cualquier lugar en todos los carretes. Cada símbolo se puede usar solo una vez por combinación ganadora.',
        position: 'Solo las posiciones que contienen el símbolo ganador se usan para determinar la ganancia para ese símbolo, excepto en el caso en que se produce una combinación ganadora de WILDS seguida de un SCATTER en el siguiente carrete adyacente. En este caso se paga la combinación ganadora de WILDS.',
        leftToRight: ' Todas las ganancias comienzan con el carrete más a la izquierda y pagan de izquierda a derecha solo en los carretes adyacentes, excepto SCATTER, que paga. ',
        wild: 'WILD sustituye a todos los símbolos excepto SCATTER. ',
        malfunction: 'El mal funcionamiento anula todos los pagos y jugadas.'
    },
    fr: {
        scattersTrigger: 'sur la bobine 1, 3, 5, déclenchez l\'un des 3 types de jeu bonus.',
        archerBonus: 'Dans le bonus avec archer, après chaque rotation, il y a la possibilité de remplacer un simple symbole par WILD.',
        swordBonus: 'Dans le bonus gladiateur à lépée, il y a un multiplicateur x2. Si un gladiateur tombe sur 3 rouleaux, ce multiplicateur augmente dune unité.',
        shieldBonus: 'Dans le bonus de gladiateur bouclier, il y a une chance de gagner des tours gratuits supplémentaires. Si le gladiateur tombe sur la 5ème bobine, un rouleau gratuit est ajouté.',
        rules: 'Règles',
        pays: ' Crédits joue 243 façons. Tous les gains multipliés par la mise. Plus haut gain payé par combinaison gagnante. Des gains coïncidents sont ajoutés.',
        combination: 'Les combinaisons gagnantes peuvent se produire n importe où sur tous les rouleaux. Chaque symbole ne peut être utilisé qu une seule fois par combinaison gagnante.',
        position: 'Seules les positions contenant le symbole gagnant sont utilisées pour déterminer le gain pour ce symbole, sauf dans le cas où une combinaison gagnante de WILDS se produit suivie d un SCATTER sur la bobine adjacente suivante. Dans ce cas, la combinaison gagnante de WILDS est payée.',
        leftToRight: 'Tous les gains commencent avec la bobine la plus à gauche et ne paient de gauche à droite que sur les rouleaux adjacents, sauf SCATTER qui paie. ',
        wild: 'WILD remplace tous les symboles sauf SCATTER. ',
        malfunction: 'Un dysfonctionnement annule tous les paiements et les jeux.'
    },
    el: {
        scattersTrigger: 'στον κύλινδρο 1, 3, 5 ενεργοποιεί ένα από τα 3 είδη παιχνιδιών μπόνους.',
        archerBonus: 'Στο μπόνους με τοξότη, μετά από κάθε γύρισμα, υπάρχει η δυνατότητα αντικατάστασης ενός απλού συμβόλου με WILD.',
        swordBonus: 'Στο μπόνους σπαθί μονομάχων υπάρχει πολλαπλασιαστής x2. Εάν ο μονομάχος πέσει σε 3 τροχούς, αυτός ο πολλαπλασιαστής αυξάνεται κατά ένα.',
        shieldBonus: 'Στο μπόνους μονομάχου ασπίδας, υπάρχει η πιθανότητα να κερδίσετε επιπλέον δωρεάν περιστροφή. Εάν ο μονομάχος πέσει στον 5ο κύλινδρο, προστίθεται ένα δωρεάν ρολό.',
        rules: 'Κανόνες',
        pays: ' πιστώσεις παίζει 243 τρόπους. Όλες οι νίκες πολλαπλασιάζονται με το στοίχημα. Η υψηλότερη νίκη που καταβάλλεται ανά συνδυασμό νίκης. Προστίθενται συμπίπτουσες νίκες.',
        combination: 'Οι νικηφόροι συνδυασμοί μπορούν να εμφανιστούν οπουδήποτε σε όλους τους τροχούς. Κάθε σύμβολο μπορεί να χρησιμοποιηθεί μόνο μία φορά ανά νικηφόρο συνδυασμό.',
        position: 'Μόνο οι θέσεις που περιέχουν το σύμβολο νίκης χρησιμοποιούνται για τον καθορισμό της νίκης για αυτό το σύμβολο, εκτός από την περίπτωση όπου ένας νικητής συνδυασμός WILDS ακολουθείται από ένα SCATTER στον επόμενο παρακείμενο κύλινδρο. Σε αυτήν την περίπτωση πληρώνεται ο νικητήριος συνδυασμός WILDS.',
        leftToRight: ' Όλες οι νίκες ξεκινούν με τον αριστερότερο κύλινδρο και πληρώνουν από αριστερά προς τα δεξιά μόνο σε παρακείμενους τροχούς, εκτός από το SCATTER που πληρώνει οποιοδήποτε. ',
        wild: 'Το WILD αντικαθιστά όλα τα σύμβολα εκτός από το SCATTER. ',
        malfunction: 'Η δυσλειτουργία ακυρώνει όλες τις πληρωμές και τα παιχνίδια.'
    },
    it: {
        scattersTrigger: 'sul rullo 1, 3, 5 attiva uno dei 3 tipi di gioco bonus.',
        archerBonus: 'Nel bonus con l`arciere, dopo ogni giro, c`è la possibilità di sostituire un semplice simbolo con WILD.',
        swordBonus: 'Nel bonus gladiatore spada c`è un moltiplicatore x2. Se il gladiatore cade su 3 rulli, questo moltiplicatore aumenta di uno.',
        shieldBonus: 'Nel bonus gladiatore scudo, c`è la possibilità di vincere ulteriori giri gratuiti. Se il gladiatore cade sul 5 ° rullo, viene aggiunto un tiro libero.',
        rules: 'Regole',
        pays: 'Crediti gioca 243 modi. Tutte le vincite moltiplicate per la scommessa. La più alta vincita pagata per combinazione vincente. Le vincite coincidenti vengono aggiunte. ',
        combination: 'Le combinazioni vincenti possono verificarsi ovunque su tutti i rulli. Ogni simbolo può essere usato solo una volta per combinazione vincente.',
        position: 'Solo le posizioni contenenti il simbolo vincente vengono utilizzate per determinare la vincita per quel simbolo, tranne nel caso in cui si verifichi una combinazione vincente di WILDS seguita da uno SCATTER sul successivo rullo adiacente. In questo caso viene pagata la combinazione vincente di WILDS.',
        leftToRight: ' Tutte le vincite iniziano con il rullo più a sinistra e pagano da sinistra a destra solo sui rulli adiacenti, tranne SCATTER che paga qualsiasi. ',
        wild: 'WILD sostituisce tutti i simboli tranne SCATTER. ',
        malfunction: 'Il malfunzionamento annulla tutti i pagamenti e le giocate.'
    }
};
