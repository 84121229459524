import BigWin from '../../bigWin';
import App from '../../../index';

import * as PIXI from 'pixi.js-legacy';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Container = PIXI.Container,
    Graphics = PIXI.Graphics;

export default class BigWinUnique extends BigWin {
    /**
     * @param payment
     * @param features
     * start logic of big win animation
     */
    goToBigWin(payment, features) {
        App.Sounds.playSound('big-win-start');
        const bonusContainer = App.Game.getStageChild('bonusContainer');
        const ticker = App.Game.app.ticker;

        const bigWinContainer = new Container();
        bigWinContainer.name = 'bigWinContainer';
        bigWinContainer.sortableChildren = true;

        const bigWinImages = new Container();
        bigWinImages.name = 'bigWinImages';
        bigWinImages.zIndex = 2;
        bigWinImages.sortableChildren = true;
        bigWinImages.position.set(App.Game.gameFieldWidth / 2, App.Game.gameFieldHeight / 2);
        bigWinContainer.addChild(bigWinImages);
        const x2Sprite = App.Game.getStageChild('bonusContainer').getChildByName('x2');

        const decreaseAlpha = () => {
            if (x2Sprite.alpha >= 0) {
                x2Sprite.alpha -= 0.03;
            } else {
                App.Game.app.ticker.remove(decreaseAlpha);
                x2Sprite.destroy();
            }
        };

        bonusContainer.addChild(bigWinContainer);
        App.Game.prepareToAnimateFeature(features);

        // go to big win animation after 2 sec delay
        App.Game.tickerTimeout(() => {
            App.System.sendMetric({param: 'bigWin.count'});
            App.System.sendMetric({param: 'bigWin.payment', value: payment});
            App.System.sendMetric({param: 'bigWin.coefficient', value: payment / App.Game.gameSettings.getBetCredit()});
            const bigWinSprite = new Sprite(App.Game.getTexture('bigWin'));
            bigWinSprite.name = 'bigWin';
            bigWinSprite.position.y = -94;
            bigWinSprite.anchor.set(0.5);

            const lightSprite1 = new Sprite(App.Game.getTexture('light'));
            lightSprite1.name = 'lightSprite1';
            lightSprite1.anchor.set(0.5);
            lightSprite1.position.set(-144, -87);
            lightSprite1.scale.set(0.8);

            const lightSprite2 = new Sprite(lightSprite1.texture);
            lightSprite2.name = 'lightSprite2';
            lightSprite2.anchor.set(0.5);
            lightSprite2.position.set(198, -87);
            lightSprite2.scale.set(0.8);
            bigWinImages.addChild(lightSprite1, lightSprite2);

            ticker.add(this.rotateLight);
            bigWinImages.addChild(bigWinSprite);

            this.drawText(payment, features);

            const rect = new Graphics()
                .beginFill(0x000000, 0.5)
                .drawRect(0, 0, App.Game.gameWidth, App.Game.gameHeight);
            rect.zIndex = 0;
            rect.position.set(-(App.Game.gameWidth - App.Game.gameFieldWidth) / 2, 0);
            bigWinContainer.addChild(rect);
            x2Sprite && App.Game.app.ticker.add(decreaseAlpha);
        }, 2000);
    }
}
