import * as PIXI from 'pixi.js-legacy';

import './styles.less';
import App from './../../../index';
import GameDeluxe from './../../deluxe/game';
import Lines from './lines';
import Gamble from './gamble';
import InfoScreen from '../../infoScreen';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Container = PIXI.Container,
    Text = PIXI.Text;

export default class NightCity extends GameDeluxe {
    constructor() {
        super();
        this.id = 'night-city';
        this.name = 'Night City';
        this.buttonsPanelShadow = 'mid';
        this.defaultFeatureDelay = 1400;
        this.containersLayers = {
            reelsStage: 1,
            mainContainer: 0,
            linesContainer: 2,
            boxesContainer: 3,
            extraBetContainer: 4,
            bonusContainer: 5,
            symbolInfo: 6,
            infoContainer: 7
        };

        this.BigWin.enabled = true;
        this.reelTop = 81;
        this.reelXCoordinates = [44, 226, 406, 588, 770];

        this.offsetReelMask = {
            offsetX: 0, offsetY: 10,
            offsetWidth: 0, offsetHeight: -22
        };

        // reel properties
        this.reelRows = 4; // number of rows per reel
        this.symbolHeight = 125; // height of aa single symbol
        this.symbolWidth = 145; // height of aa single symbol
        this.gameFieldWidth = 960;
        this.gameWidth = App.System.resolution === '4x3' ? this.gameFieldWidth : 1280;
        this.gameFieldHeight = 720;
        this.gameHeight = App.configs.doubleScreen ? 1440 : 720;

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 120, y: 90},
            bonusInBonusFrame: {x: 120, y: 150},
            endBonusFrame: {x: 120, y: 150}
        };
        this.SymbolInfo.enabled = true;

        this.symbols = [
            {regularDelay: 50, payment: [0, 0, 0, 10, 20, 100]},   // 0 - cocktail
            {regularDelay: 50, payment: [0, 0, 0, 10, 20, 100]},   // 1 - beer
            {regularDelay: 50, payment: [0, 0, 0, 10, 20, 100]},   // 2 - coffee
            {regularDelay: 50, payment: [0, 0, 0, 20, 40, 200]},   // 3 - cherry
            {regularDelay: 50, payment: [0, 0, 0, 20, 40, 200]},   // 4 - microphone
            {regularDelay: 50, payment: [0, 0, 0, 20, 80, 400]},   // 5 - seven
            {regularDelay: 50, payment: [0, 0, 0, 40, 400, 1000]}, // 6 - joker
            {regularDelay: 50, payment: [0, 0, 0, 5, 20, 500]}     // 7 - night city
        ];

        this.imageResources = {
            main: this.mergePath({
                background: 'area/background.png',
                logo: 'logo.png'
            }),
            atlas: this.mergePath(['staticSymbols.json']),
            video: this.mergePath({main: 'area/main.mp4'}),
            fonts: this.mergePath({NeonFont: 'font/NeonFont.otf'})
        };
        this.additionalResources = {
            main: this.mergePath({
                symbolBorder: 'area/symbol-border.png',
                paymentBorder: 'area/payment-border.png'
            })
        };

        this.gameSounds = {soundClass: 'prefergames'};
        this.Lines = new Lines(this.mergePath({winLinesBox: 'lines/winBox.png'}));
        this.Gamble = new Gamble(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            activeBlack: 'gamble/black-active.png',
            inactiveBlack: 'gamble/black-inactive.png',
            activeRed: 'gamble/red-active.png',
            inactiveRed: 'gamble/red-inactive.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));

        this.InfoScreen = new InfoScreen({pages: 2}); // number of game info states
        this.InfoScreen.format = 'png';
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     * @param lang
     */
    drawInfoPage(ctx, page, nLines, bet, lang) {
        ctx.textAlign = 'center';
        ctx.fillStyle = '#19abff';
        ctx.shadowColor = '00ff12';
        ctx.shadowOffsetX = ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 5;
        const {
            rules,
            combinationOfKind, combinationLeftToRight, prizesOnSelectedLines,
            HighestWinPaid, ScatterWinsAddedToLineWins, prizesShownInCredits,
            MalfunctionVoidsAllPays
        } = App.languageCollection[lang];

        switch (page) {
            case 1:
                ctx.font = 'bold 22pt NeonFont';

                // cocktail, beer, coffee
                ctx.fillStyle = '#f3ff00';
                ctx.fillText(`${bet * this.symbols[0].payment[5]}`, 470, 481);
                ctx.fillText(`${bet * this.symbols[0].payment[4]}`, 470, 519);
                ctx.fillText(`${bet * this.symbols[0].payment[3]}`, 470, 556);

                // cherry, microphone
                ctx.fillStyle = '#ffb0f6';
                ctx.fillText(`${bet * this.symbols[3].payment[5]}`, 760, 420);
                ctx.fillText(`${bet * this.symbols[3].payment[4]}`, 760, 458);
                ctx.fillText(`${bet * this.symbols[3].payment[3]}`, 760, 496);

                // seven
                ctx.fillStyle = '#00ffc7';
                ctx.fillText(`${bet * this.symbols[5].payment[5]}`, 200, 406);
                ctx.fillText(`${bet * this.symbols[5].payment[4]}`, 200, 441);
                ctx.fillText(`${bet * this.symbols[5].payment[3]}`, 200, 478);

                // joker
                ctx.fillStyle = '#00f600';
                ctx.fillText(`${bet * this.symbols[6].payment[5]}`, 650, 201);
                ctx.fillText(`${bet * this.symbols[6].payment[4]}`, 650, 246);
                ctx.fillText(`${bet * this.symbols[6].payment[3]}`, 650, 291);

                // scatter
                ctx.fillStyle = '#00f600';
                ctx.fillText(`${bet * nLines * this.symbols[7].payment[5]}`, 400, 201);
                ctx.fillText(`${bet * nLines * this.symbols[7].payment[4]}`, 400, 246);
                ctx.fillText(`${bet * nLines * this.symbols[7].payment[3]}`, 400, 291);
                break;
            case 2:
                ctx.fillStyle = '#00ffc7';

                ctx.font = '40pt NeonFont';
                ctx.fillText(rules, 480, 180);

                ctx.font = 'bold 20pt NeonFont';

                const rulesText = combinationOfKind +
                    combinationLeftToRight +
                    prizesOnSelectedLines +
                    HighestWinPaid +
                    ScatterWinsAddedToLineWins +
                    prizesShownInCredits +
                    MalfunctionVoidsAllPays;
                this.drawSplitText(ctx, rulesText, 480, 220, 700, {lineHeight: 35});
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    /**
     * Create PIXI.Container for game background
     * Add additional sprites to container
     * @param parentContainer
     */
    createMainContainer(parentContainer) {
        const container = new Container();
        container.name = 'mainContainer';
        container.zIndex = this.containersLayers[container.name];

        const sprite = Sprite.from(this.resources.main);
        sprite.name = 'mainAreaVideo';
        sprite.anchor.set(0.5);
        sprite.position.set(this.gameFieldWidth / 2, this.gameFieldHeight / 2);
        container.addChild(sprite);
        parentContainer.addChild(container);

        const logoSprite = new Sprite(this.getTexture('logo'));
        logoSprite.name = 'logo';
        logoSprite.position.set(197, -49);
        logoSprite.scale.set(0.8);
        container.addChild(logoSprite);

        const background = new Sprite(this.getTexture('background'));
        background.position.set(12, 60);
        background.name = 'background';

        container.addChild(background);
    }

    onInfoStartOpen() {
        this.hideReels();
    }

    onInfoStartClose() {
        this.showReels();
    }

    startGambleAnimation() {
        this.hideReels();
    }

    endGambleAnimation() {
        this.showReels();
    }

    hideReels() {
        const reelsStage = this.getStageChild('reelsStage');
        this.showAnimation({
            duration: 500, animations: [
                {sprite: reelsStage, timeline: [{to: {y: 740}}]},
                {
                    sprite: this.getStageChild('linesContainer'),
                    timeline: [{to: {y: this.symbolHeight * this.reelRows}}]
                }, {
                    sprite: this.getStageChild('mainContainer').getChildByName('background'),
                    timeline: [{to: {y: 720}}]
                }
            ]
        });
    }

    showReels() {
        const reelsStage = this.getStageChild('reelsStage');
        reelsStage.interactiveChildren = false;

        this.showAnimation({
            duration: 500, animations: [
                {sprite: reelsStage, timeline: [{to: {y: this.reelTop}}]},
                {sprite: this.getStageChild('linesContainer'), timeline: [{to: {y: 0}}]},
                {
                    sprite: this.getStageChild('mainContainer').getChildByName('background'),
                    timeline: [{to: {y: 60}}]
                }
            ], onComplete: () => {
                reelsStage.interactiveChildren = true;
                this.createAdditionalSprites(); // revert reelsStage mask
            }
        });
    }

    /**
     * Create texts for paymentBorder table
     * @param parentContainer
     * @param payTable
     * @param direction
     */
    drawSymbolInfoPayments(parentContainer, payTable, direction) {
        const bet = this.gameSettings.getBetLineCredit();
        const props = {
            fill: '#00ff07',
            stroke: '#007318',
            align: 'left',
            fontFamily: 'NeonFont',
            fontSize: 22,
            strokeThickness: 3,
            lineJoin: 'round'
        };

        payTable.forEach((pay, index) => {
            const quantity = new Text(`x${pay[0]}`, props);
            quantity.position.x = direction === 'left' ? -65 : -45;
            quantity.position.y = 15 + (payTable.length === 1 ? -30 : -30 * index);

            const payment = new Text(` ${bet * pay[1]}`, {...props, fill: '#6bf2ff', stroke: '#1149ab'});
            payment.position.x = direction === 'left' ? -34 : -15;
            payment.position.y = 15 + (payTable.length === 1 ? -30 : -30 * index);

            parentContainer.addChild(quantity, payment);
        });
    }

    /**
     * Function to show line for special feature
     * @param currentFeature
     */
    showFeatureLine(currentFeature) {
        const {number, reels, uc} = currentFeature; // get current feature params

        const container = this.getStageChild('linesContainer');
        uc !== 'SPECIAL_SYMBOL' && container.removeChildren(); // don't clear lines before special symbol (bookGame fill)
        uc === 'WIN_LINE' && this.Lines.drawWinBoxes(number, reels, container);
    }
}
