const availableGames = [
    // amatic
    {name: 'admiral-nelson', categories: ['AMATIC']},
    {name: 'book-of-fortune', categories: ['AMATIC'], status: 'TOP'},
    {name: 'hot-scatter', categories: ['AMATIC', 'FRUITS']},
    {name: 'hot-seven', categories: ['AMATIC', 'FRUITS']},
    {name: 'wild-seven', categories: ['AMATIC', 'FRUITS'], status: 'TOP'},

    // champion
    {name: 'always-cherry', categories: ['CHAMPION', 'FRUITS']},
    {name: 'fire-rage-plus', categories: ['CHAMPION', 'FRUITS']},
    {name: 'hit-jewels', categories: ['CHAMPION']},
    {name: 'hot-sevens', categories: ['CHAMPION', 'FRUITS']},
    {name: 'hot-slot', categories: ['CHAMPION', 'FRUITS']},
    {name: 'sevens-on-fire', categories: ['CHAMPION', 'FRUITS']},
    {name: 'sevens-on-fire-plus', categories: ['CHAMPION', 'FRUITS']},
    {name: 'tropical-fruit', categories: ['CHAMPION', 'FRUITS']},
    {name: 'ultra-7-hot', categories: ['CHAMPION', 'FRUITS']},

    // novomatic
    {name: 'age-of-pharaohs', categories: ['NOVOMATIC', 'MULTILINES']},
    {name: 'always-american-17', categories: ['NOVOMATIC', 'FRUITS']},
    {name: 'always-hot', categories: ['NOVOMATIC', 'FRUITS']},
    {name: 'always-hot-deluxe-17', categories: ['NOVOMATIC', 'FRUITS']},
    {name: 'bee-wild', categories: ['NOVOMATIC', 'MULTILINES']},
    {name: 'book-of-maya', categories: ['NOVOMATIC'], status: 'TOP'},
    {name: 'big-chief', categories: ['NOVOMATIC']},
    {name: 'book-of-ra', categories: ['NOVOMATIC'], status: 'TOP'},
    {name: 'book-of-ra-6', categories: ['NOVOMATIC'], status: 'TOP'},
    {name: 'book-of-ra-deluxe', categories: ['NOVOMATIC']},
    {name: 'buffalo-thunder', categories: ['NOVOMATIC', 'MULTILINES']},
    {name: 'columbus-deluxe', categories: ['NOVOMATIC']},
    {name: 'el-toro', categories: ['NOVOMATIC', 'MULTILINES']},
    {name: 'fruit-king', categories: ['NOVOMATIC', 'FRUITS'], status: 'TOP'},
    {name: 'gate-of-ra', categories: ['NOVOMATIC']},
    {name: 'gems-27', categories: ['NOVOMATIC']},
    {name: 'golden-ark', categories: ['NOVOMATIC']},
    {name: 'hot-cherry', categories: ['NOVOMATIC', 'FRUITS']},
    {name: 'hot-chip-runner', categories: ['NOVOMATIC', 'FRUITS']},
    {name: 'jewels-divine', categories: ['NOVOMATIC', 'MULTILINES']},
    {name: 'joker-fruits', categories: ['NOVOMATIC', 'FRUITS']},
    {name: 'jolly-pays', categories: ['NOVOMATIC', 'FRUITS']},
    {name: 'lions', categories: ['NOVOMATIC', 'MULTILINES']},
    {name: 'lord-of-the-ocean', categories: ['NOVOMATIC']},
    {name: 'lucky-lady', categories: ['NOVOMATIC']},
    {name: 'magic-joker', categories: ['NOVOMATIC']},
    {name: 'northern-light', categories: ['NOVOMATIC', 'MULTILINES']},
    {name: 'pharaohs-tomb', categories: ['NOVOMATIC']},
    {name: 'pharaohs-ring', categories: ['NOVOMATIC']},
    {name: 'plenty-of-fruit', categories: ['NOVOMATIC', 'FRUITS']},
    {name: 'plenty-on-twenty-hot', categories: ['NOVOMATIC', 'FRUITS']},
    {name: 'power-stars', categories: ['NOVOMATIC', 'FRUITS']},
    {name: 'rapa-nui', categories: ['NOVOMATIC']},
    {name: 'roaring-forties', categories: ['NOVOMATIC', 'FRUITS', 'MULTILINES']},
    {name: 'sizzling-gems-20', categories: ['NOVOMATIC']},
    {name: 'sizzling-gold', categories: ['NOVOMATIC', 'FRUITS']},
    {name: 'sizzling-6', categories: ['NOVOMATIC', 'FRUITS']},
    {name: 'sizzling-hot-deluxe-20', categories: ['NOVOMATIC', 'FRUITS']},
    {name: 'the-big-catch', categories: ['NOVOMATIC', 'MULTILINES']},
    {name: 'threee-deluxe-27', categories: ['NOVOMATIC', 'FRUITS']},
    {name: 'tiki-island', categories: ['NOVOMATIC', 'MULTILINES']},
    {name: 'treasure-gate', categories: ['NOVOMATIC']},
    {name: 'ultra-hot', categories: ['NOVOMATIC', 'FRUITS']},
    {name: 'ultra-star', categories: ['NOVOMATIC']},

    // prefergames
    {name: 'book-of-fire', categories: ['PREFERGAMES']},
    {name: 'european-roulette', categories: ['PREFERGAMES'], status: 'NEW', animatedLogo: true},
    {name: 'gems-mania', categories: ['PREFERGAMES', 'MULTILINES']},
    {name: 'gladiators-rising', categories: ['PREFERGAMES'], status: 'NEW'},
    {name: 'lucky-ranch', categories: ['PREFERGAMES'], status: 'NEW'},
    {name: 'neon-city', categories: ['PREFERGAMES', 'MULTILINES'], status: 'TOP', animatedLogo: true},
    {name: 'night-city', categories: ['PREFERGAMES', 'MULTILINES'], status: 'TOP'},
    {name: 'night-city-sevens', categories: ['PREFERGAMES'], status: 'NEW'},
    {name: 'sea-treasure', categories: ['PREFERGAMES'], status: 'NEW'},
    {name: 'sky-riders', categories: ['PREFERGAMES'], status: 'NEW'},
    {name: 'sparky-fruits', categories: ['PREFERGAMES', 'MULTILINES', 'FRUITS'], status: 'TOP'},
    {name: 'sparky-hot', categories: ['PREFERGAMES', 'FRUITS'], status: 'NEW'},
    {name: 'sparky-seven', categories: ['PREFERGAMES', 'FRUITS'], status: 'NEW'},
    {name: 'witch-hunters', categories: ['PREFERGAMES'], status: 'NEW'},

    // igrosoft
    {name: 'keks-1', categories: ['IGROSOFT']},
    {name: 'crazy-monkey-1', categories: ['IGROSOFT']},
    {name: 'fruit-cocktail-1', categories: ['IGROSOFT', 'FRUITS']},

    // EGT
    {name: '20-super-hot', categories: ['EGT', 'FRUITS']},
    {name: '40-super-hot', categories: ['EGT', 'FRUITS', 'MULTILINES'], status: 'TOP'},
    {name: 'amazons-battle', categories: ['EGT']},
    {name: 'blue-heart', categories: ['EGT', 'MULTILINES']},
    {name: 'burning-hot', categories: ['EGT', 'FRUITS']},
    {name: 'caramel-hot', categories: ['EGT', 'FRUITS', 'MULTILINES']},
    {name: 'flaming-hot', categories: ['EGT', 'FRUITS', 'MULTILINES']},
    {name: 'supreme-hot', categories: ['EGT', 'FRUITS']},
    {name: 'ultimate-hot', categories: ['EGT', 'FRUITS']},
    {name: 'zodiac-wheel', categories: ['EGT']},

    // Other
    {name: 'aztec-gold', categories: ['OTHER']}, // megajack
    {name: 'champagne', categories: ['OTHER', 'FRUITS']},
    {name: 'slot-o-pol', categories: ['OTHER', 'FRUITS']},
    {name: 'spin', categories: ['OTHER', 'FRUITS']},

    {name: 'star-burst', categories: ['OTHER'], status: 'NEW'}, // NetEnt
    {name: 'zeus', categories: ['OTHER'], status: 'NEW'}, // SG Williams

    {name: 'gold-rush', categories: ['OTHER'], status: 'NEW'}, // Pragmatic
    {name: 'hercules-son-of-zeus', categories: ['OTHER'], status: 'NEW'},
    {name: 'vegas-magic', categories: ['OTHER'], status: 'NEW'},
    {name: 'super-7s', categories: ['OTHER'], status: 'NEW'},
    {name: 'three-star-fortune', categories: ['OTHER'], status: 'NEW'}
];

module.exports = availableGames;
