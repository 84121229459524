import {JL} from 'jsnlog';

import LinesEgt from './../egt/lines';

export default class Lines20 extends LinesEgt {
    constructor() {
        super();
        this.boxSettings.firstRightBox = 20;

        this.lines = {
            0: {
                coordinates: [1, 1, 1, 1, 1],
                lineDeltaY: 0,
                color: '#ffd8ff'
            },
            1: {
                coordinates: [0, 0, 0, 0, 0],
                lineDeltaY: 0,
                color: '#de1800'
            },
            2: {
                coordinates: [2, 2, 2, 2, 2],
                lineDeltaY: 0,
                color: '#009200'
            },
            3: {
                coordinates: [0, 1, 2, 1, 0],
                lineDeltaY: 20,
                color: '#ce8f63'
            },
            4: {
                coordinates: [2, 1, 0, 1, 2],
                lineDeltaY: -20,
                color: '#647100'
            },
            5: {
                coordinates: [0, 0, 1, 2, 2],
                lineDeltaY: 10,
                color: '#ff24ff'
            },
            6: {
                coordinates: [2, 2, 1, 0, 0],
                lineDeltaY: -10,
                color: '#ffffad'
            },
            7: {
                coordinates: [1, 2, 2, 2, 1],
                lineDeltaY: -20,
                color: '#0096bd'
            },
            8: {
                coordinates: [1, 0, 0, 0, 1],
                lineDeltaY: 20,
                color: '#7321bd'
            },
            9: {
                coordinates: [0, 1, 1, 1, 0],
                lineDeltaY: 30,
                color: '#00ffff'
            },
            10: {
                coordinates: [2, 1, 1, 1, 2],
                lineDeltaY: -30,
                color: '#6345ff'
            },
            11: {
                coordinates: [1, 2, 1, 0, 1],
                lineDeltaY: -10,
                color: '#d6fb06'
            },
            12: {
                coordinates: [1, 0, 1, 2, 1],
                lineDeltaY: 10,
                color: '#a56e00'
            },
            13: {
                coordinates: [0, 1, 0, 1, 0],
                lineDeltaY: -20,
                color: '#949294'
            },
            14: {
                coordinates: [2, 1, 2, 1, 2],
                lineDeltaY: 20,
                color: '#de2064'
            },
            15: {
                coordinates: [1, 1, 2, 1, 1],
                lineDeltaY: 0,
                color: '#007100'
            },
            16: {
                coordinates: [1, 1, 0, 1, 1],
                lineDeltaY: 0,
                color: '#adcab5'
            },
            17: {
                coordinates: [0, 2, 0, 2, 0],
                lineDeltaY: -10,
                color: '#ff6d00'
            },
            18: {
                coordinates: [2, 0, 2, 0, 2],
                lineDeltaY: 10,
                color: '#ffff00'
            },
            19: {
                coordinates: [1, 0, 2, 0, 1],
                lineDeltaY: 0,
                color: '#00cbbe'
            }
        };

        this.lineMap = [...Array(20)].map((item, i) => i);

        JL().debug('-- Lines20 initialized');
    }

    getBoxesStyle = () => ({
        activeBoxStyle: {
            fill: '#fbf100',
            fontSize: 12,
            stroke: '#bf0000',
            strokeThickness: 5,
            lineJoin: 'round'
        },

        inactiveBoxStyle: {
            dropShadowDistance: 1,
            fill: 'white',
            fontSize: 19,
            strokeThickness: 2,
            lineJoin: 'round'
        },
        colLines: [1, 5, 10, 15, 20],
        coordinates: {
            0: {x: 24, y: 408},
            1: {x: 773, y: 408}
        }
    })
}
