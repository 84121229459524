import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../../../index';
import BookGame from './../../deluxe/bookGame';
import Lines10 from './../../deluxe/lines10';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    AnimatedSprite = PIXI.AnimatedSprite,
    Text = PIXI.Text;

export default class BookOfMaya extends BookGame {
    constructor() {
        super();
        this.id = 'book-of-maya';
        this.name = 'Book of Maya';
        this.scatter = 9;
        this.buttonsPanelShadow = 'strong';
        this.reelSettings = [14, 5, 30]; // 0 - start symbol amount, 1 - reel increase regular roll, 2 - reel increase long roll

        // frame index for each symbol in book open animation
        this.openedSymbolsFrames = [66, 60, 54, 48, 42, 36, 30, 24, 18];
        this.extraBet = true; // existing ExtraBet feature in game
        this.extraBetActive = false; // current enabled state
        this.freezedReels = [];
        this.respinFlag = false;

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 22, y: 60},
            bonusInBonusFrame: {x: 22, y: 60},
            endBonusFrame: {x: 22, y: 60}
        };

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 0, 5, 25, 100]},       // 0 - 10
            {regularDelay: 100, payment: [0, 0, 0, 5, 25, 100]},       // 1 - J
            {regularDelay: 100, payment: [0, 0, 0, 5, 25, 100]},       // 2 - Q
            {regularDelay: 100, payment: [0, 0, 0, 5, 40, 150]},       // 3 - K
            {regularDelay: 100, payment: [0, 0, 0, 5, 40, 150]},       // 4 - A
            {regularDelay: 100, payment: [0, 0, 5, 30, 100, 750]},     // 5 - dragon
            {regularDelay: 100, payment: [0, 0, 5, 30, 100, 750]},     // 6 - gold statue
            {regularDelay: 100, payment: [0, 0, 5, 40, 400, 2000]},    // 7 - green maya
            {regularDelay: 100, payment: [0, 0, 10, 100, 1000, 5000]}, // 8 - men
            {regularDelay: 100, payment: [0, 0, 0, 2, 20, 200]}        // 9 - book (scatter)
        ];
        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png',
                ex_deactivated: 'bonus/ex-deactivated.png',
                ex_activated: 'bonus/ex-activated.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                bonusArea: 'area/bonus.png',
                frame: 'bonus/frame.png',
                bookFlash: 'bonus/book_flash.png',
                bookAnim: 'bonus/book_anim.png',
                bookSelected: 'bonus/book_selected.png'
            }),
            atlas: this.mergePath([
                'bonus/bonusSymbols.json',
                'bonus/scatterSymbols.json',
                'bonus/additionalSymbols.json',
                'regularLongSymbols.json'
            ])
        };
        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'bonus-game-end', alias: 'bonusGameEnd'},
                {name: 'bonus-background', loop: true},
                {name: 'book-open', alias: 'bookOpen'},
                {name: 'book-flip-pages', alias: 'bookFlipPages', loop: true},
                {name: 'book-flip-page', alias: 'bookFlipPage', loop: true},
                {name: 'book-selected-symbol', alias: 'bookSelectedSymbol'},
                {name: 'bonus-win-symbols-show', alias: 'bonusWinSymbols'},
                {name: 'book-flash', alias: 'bookFlash'},
                {name: 'long1', alias: 'respin', loop: true}
            ],
            path: `/game/games/${this.id}/audio/`
        };

        this.Lines = new Lines10();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            activeRed: 'gamble/red-active.png',
            inactiveRed: 'gamble/red-inactive.png',
            activeBlack: 'gamble/black-active.png',
            inactiveBlack: 'gamble/black-inactive.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 3}, {
            info1_deactive: 'area/info1-screen-deactivated.jpg'
        }); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines - current lines count
     * @param bet - current bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 15pt Times New Roman';
        ctx.fillStyle = '#000';
        ctx.strokeStyle = '#000';
        ctx.lineWidth = 3;
        ctx.textAlign = 'center';

        const textProps = {
            fontHeight: 20,
            gradientColor: {from: '#f8c800', to: '#f86c00'}
        };

        switch (page) {
            case 1:
                const image = this.getAdditionalImage(this.extraBetActive ? 'info1' : 'info1_deactive');
                ctx.drawImage(image, -(image.width - this.gameFieldWidth) / 2, 0);

                ctx.fillText('Substitutes for all symbols', 400, 255);

                // scatter
                ctx.fillText(bet * nLines * this.symbols[9].payment[5], 470, 148);
                ctx.fillText(bet * nLines * this.symbols[9].payment[4], 470, 172);
                ctx.fillText(bet * nLines * this.symbols[9].payment[3], 470, 196);
                // men
                ctx.fillText(bet * this.symbols[8].payment[5], 170, 135);
                ctx.fillText(bet * this.symbols[8].payment[4], 170, 156);
                ctx.fillText(bet * this.symbols[8].payment[3], 170, 177);
                ctx.fillText(bet * this.symbols[8].payment[2], 170, 198);
                // green maya
                ctx.fillText(bet * this.symbols[7].payment[5], 650, 135);
                ctx.fillText(bet * this.symbols[7].payment[4], 650, 156);
                ctx.fillText(bet * this.symbols[7].payment[3], 650, 177);
                ctx.fillText(bet * this.symbols[7].payment[2], 650, 198);
                // gold statue
                ctx.fillText(bet * this.symbols[6].payment[5], 170, 260);
                ctx.fillText(bet * this.symbols[6].payment[4], 170, 281);
                ctx.fillText(bet * this.symbols[6].payment[3], 170, 302);
                ctx.fillText(bet * this.symbols[6].payment[2], 170, 323);
                // dragon
                ctx.fillText(bet * this.symbols[5].payment[5], 650, 260);
                ctx.fillText(bet * this.symbols[5].payment[4], 650, 281);
                ctx.fillText(bet * this.symbols[5].payment[3], 650, 302);
                ctx.fillText(bet * this.symbols[5].payment[2], 650, 323);
                // AK
                ctx.fillText(bet * this.symbols[4].payment[5], 170, 385);
                ctx.fillText(bet * this.symbols[4].payment[4], 170, 406);
                ctx.fillText(bet * this.symbols[4].payment[3], 170, 427);
                // QJ10
                ctx.fillText(bet * this.symbols[2].payment[5], 650, 385);
                ctx.fillText(bet * this.symbols[2].payment[4], 650, 406);
                ctx.fillText(bet * this.symbols[2].payment[3], 650, 427);

                if (this.extraBetActive) {
                    ctx.fillText('FEATURE RESPINS are', 155, 505);
                    ctx.fillText('active. Improve your special', 155, 525);
                    ctx.fillText('expanding symbol wins', 155, 545);
                    ctx.fillText('during Free Games.', 155, 565);
                    ctx.fillStyle = '#fff600';
                    this.strokeFillText(ctx, 'ACTIVATED', 645, 545);
                } else {
                    ctx.fillText('Play EXTRA BET to activate', 155, 505);
                    ctx.fillText('RESPINS to improve your', 155, 525);
                    ctx.fillText('special expanding symbol', 155, 545);
                    ctx.fillText('wins during Free Games.', 155, 565);
                    ctx.fillStyle = '#cd0300';
                    this.strokeFillText(ctx, 'DEACTIVATED', 645, 545);
                }
                this.strokeFillText(ctx, 'EXTRA BET is currently', 645, 520);

                ctx.font = `bold ${textProps.fontHeight}pt Times New Roman`;
                ctx.lineWidth = 4;
                this.drawGradientFont(ctx, '10 FREE GAMES', 400, 290, textProps);
                this.drawGradientFont(ctx, '+', 400, 320, textProps);
                break;
            case 2:
                ctx.font = 'bold 14pt Times New Roman';
                ctx.textAlign = 'left';
                ctx.fillText('One special expanding symbol is randomly selected at the start of the', 230, 90);
                ctx.fillText('Free Games. During Free Games the special symbol expand to cover', 230, 115);
                ctx.fillText('3 positions on the reel and pays in any position on lines played.', 230, 140);

                ctx.fillText('If Extra Bet activated, bonus respins are awarded during the Free Games. Each time a', 40, 240);
                ctx.fillText('paying combination of the special expanding symbol has expanded in an initial spin, the reels', 40, 265);
                ctx.fillText('that are not showing this symbol are respin once for a chance to improve your special', 40, 290);
                ctx.fillText('expanding symbol wins.', 40, 315);

                textProps.fontHeight = 30;
                ctx.font = `bold ${textProps.fontHeight}pt Times New Roman`;
                ctx.lineWidth = 4;
                ctx.textAlign = 'center';
                this.drawGradientFont(ctx, 'FREE GAMES', 400, 40, textProps);
                this.drawGradientFont(ctx, 'EXTRA BET', 400, 200, textProps);
                break;
            case 3:
                ctx.fillText('All prizes are for combinations of a kind. All prizes are for', 400, 260);
                ctx.fillText('combinations left to right, except scatters. All prizes are on selected', 400, 285);
                ctx.fillText('lines, except scatters. Scatter symbols pay at any position on', 400, 310);
                ctx.fillText('screen. Highest win only paid per selected line. Scatter wins are', 400, 335);
                ctx.fillText('added to line wins. Free Games are played at trigger bet and lines.', 400, 360);
                ctx.fillText('Free Games can be won again during the Free Games. All prizes', 400, 385);
                ctx.fillText('shown in credits. Malfunction voids all pays and plays.', 400, 410);

                textProps.fontHeight = 30;
                ctx.font = `bold ${textProps.fontHeight}pt Times New Roman`;
                ctx.lineWidth = 4;
                this.drawGradientFont(ctx, 'RULES', 400, 200, textProps);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    addText = (parentContainer, title, x, y, props = {}) => {
        const text = new Text(title, props);
        text.name = title;
        text.position.set(x, y);
        parentContainer.addChild(text);
        return text;
    };

    /**
     * Отрисовка таблички бонусной игры
     */
    showStartBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y);

        const textProps = {
            align: 'center',
            fontFamily: 'Times New Roman',
            fontSize: 40,
            fontWeight: 'bold',
            fill: ['#f8c800', '#f86c00'], // gradient
            stroke: '#282828',
            strokeThickness: 4,
            lineJoin: 'round'
        };

        this.addText(parentContainer, '10 FREE GAMES', 245, 200, textProps);

        textProps.fontSize = 25;
        textProps.align = 'left';
        textProps.strokeThickness = 3;
        this.addText(parentContainer, 'Special', 190, 285, textProps);
        this.addText(parentContainer, 'expanding', 190, 320, textProps);
        this.addText(parentContainer, 'symbol', 190, 354, textProps);
    }

    /**
     * Отрисовка таблички бонус в бонусе
     */
    showBonusInBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y);

        const textProps = {
            align: 'center',
            fontFamily: 'Times New Roman',
            fontSize: 40,
            fontWeight: 'bold',
            fill: ['#f8c800', '#f86c00'], // gradient
            stroke: '#282828',
            strokeThickness: 4,
            lineJoin: 'round'
        };
        this.addText(parentContainer, '10 MORE FREE GAMES', 170, 270, textProps);
    }

    /**
     * Отрисовка таблички окончания бонусной игры
     */
    showEndBonusFrame(parentContainer, {x, y}, {win, total}) {
        this.showBonusFrame(parentContainer, x, y);

        const textProps = {
            align: 'center',
            fontFamily: 'Times New Roman',
            fontSize: 40,
            fontWeight: 'bold',
            fill: ['#f8c800', '#f86c00'], // gradient
            stroke: '#282828',
            strokeThickness: 4,
            lineJoin: 'round'
        };
        const text1 = this.addText(parentContainer, 'FEATURE WIN', 400, 255, textProps);
        text1.anchor.set(0.5, 0.5);
        const text2 = this.addText(parentContainer, `${win} CREDITS`, 400, 300, textProps);
        text2.anchor.set(0.5, 0.5);

        textProps.fontSize = 25;
        const text3 = this.addText(parentContainer, `${total} FREE GAMES PLAYED`, 400, 350, textProps);
        text3.anchor.set(0.5, 0.5);
    }

    //
    // -------------- Special book animations ---------------------
    //

    drawBonusFrame(first, last, parentContainer, coordinates) {
        App.Sounds.stopSound('bonus-background');

        const {startBonusFrame, bonusInBonusFrame, endBonusFrame} = coordinates;
        if (first) {
            this.setBackground('bonusArea');
            this.showStartBonusFrame(parentContainer, startBonusFrame);

            App.Sounds.playSound('bookFlash');
            setTimeout(() => this.drawBookFlashAnimation(parentContainer), 500);
        }
        if (last) {
            this.setRegularSprite();
            App.updateButton('start', {disabled: true});
            this.setBackground('mainArea');
            this.showEndBonusFrame(parentContainer, endBonusFrame, this.bonusStatus);
            this.playEndBonusGameSound();
            setTimeout(() => this.endBonus(), 7000);
        }
        if (!first && !last) {
            this.showBonusInBonusFrame(parentContainer, bonusInBonusFrame);
            App.updateButton('start', {
                disabled: false,
                title: 'start',
                handler: () => this.drawBonusStep(this.latestResponse.features)
            });
        }
    }

    /**
     * Show bonus message with bonus symbol or with bonus game win.
     * @param isFirstBonus {boolean} TRUE if this is the message for starting bonus game.
     */
    drawBonusAskButton(isFirstBonus = false) {
        let isLast = !isFirstBonus && this.bonusStatus && this.bonusStatus.remain === 0;

        this.stopAnimateFeature();
        setTimeout(() =>
            this.drawBonusFrame(isFirstBonus, isLast, this.getStageChild('bonusContainer'), this.coordinatesBonusFrame), 1000);

        this.gameFlag.bonusStart = true;
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});

        isLast = this.bonusStatus && this.bonusStatus.remain === 0;
    }

    /**
     * Play flash animation after show bonus frame
     * Create sprite for all book animations
     * @param parentContainer
     */
    drawBookFlashAnimation(parentContainer) {
        const sprite = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 5, image: 'bookFlash',
            width: 230, height: 163
        }));
        sprite.name = 'book';
        sprite.position.set(400, 240);
        sprite.animationSpeed = 0.15;
        sprite.loop = false;
        sprite.play();
        sprite.onComplete = () => {
            this.showPressAnyButton(false);
            App.updateButton('start', {
                disabled: false,
                title: 'start',
                handler: () => this.startBonusAnimation(parentContainer)
            });
        };
        parentContainer.addChild(sprite);
    }

    /**
     * Draw all bonus animation after bonus 'press any button'
     * @param parentContainer
     * @param bonusSymbol - current bonus symbol in extension
     */
    drawBonusAnimation(parentContainer, bonusSymbol) {
        JL().debug(`-- Draw bonus enter animation. Bonus symbol: ${bonusSymbol}`);
        App.Sounds.stopSound('bonusGameStart');
        const totalBookFrames = 72; // frames count in sprites

        const bonusSymbolFrame = this.openedSymbolsFrames[bonusSymbol];
        const sprite = parentContainer.getChildByName('book');
        const props = {image: 'bookAnim', width: 230, height: 163, colCount: 8};

        sprite.textures = [
            ...this.getSpriteTextures({...props, toFrame: totalBookFrames}), // first book open animation circle
            // repeat open book from frame 18
            ...this.getSpriteTextures({...props, fromFrame: 18, toFrame: bonusSymbolFrame}),
            ...[...Array(10)].map((item, index) => // book flipping animation during bonus symbol selected
                index % 2 === 0 && index < 7 ?
                    this.getSpriteTextures({...props, fromFrame: bonusSymbolFrame})[0] :
                    this.getSpriteTextures({...props, fromFrame: 8 - bonusSymbol, image: 'bookSelected'})[0]
            )
        ];
        sprite.animationSpeed = 0.4;
        sprite.play();
        App.Sounds.playSound('bookOpen');

        sprite.onFrameChange = frame => {
            const selectedAnimationFrame = totalBookFrames + bonusSymbolFrame - 20;

            switch (frame) {
                case 12:
                    App.Sounds.playSound('bookFlipPages');
                    break;
                case selectedAnimationFrame - 20: // slow down animation speed before 20 frames to bonus symbol
                    sprite.animationSpeed = 0.3;
                    App.Sounds.stopSound('bookFlipPages');
                    App.Sounds.playSound('bookFlipPage');
                    break;
                case selectedAnimationFrame:
                    sprite.animationSpeed = 0.1;
                    App.Sounds.stopSound('bookFlipPage');
                    App.Sounds.playSound('bookSelectedSymbol');
                    break;
            }
        };
        sprite.onComplete = () => this.bonusRoll();
    }

    /**
     * Draw open book at logo
     * @param parentContainer
     */
    drawBookTop(parentContainer) {
        const sprite = new Sprite(this.getSpriteTextures({
            fromFrame: this.openedSymbolsFrames[this.bonusRollSymbol], image: 'bookAnim',
            width: 230, height: 163, colCount: 8
        })[0]);
        sprite.name = 'bookTop';
        sprite.scale.set(0.7);
        sprite.anchor.set(0.5);
        sprite.position.set(400, 40);
        parentContainer.addChild(sprite);
    }

    isReelFreezed = reel => this.freezedReels.includes(reel);

    getSymbolImageType = (symbolIndex, reelIndex) =>
        [this.bonusRollSymbol, ...this.scatters].includes(symbolIndex) && this.gameFlag.bonusStarted ?
            'bonus' :
            this.freezedReels.length && !this.freezedReels.includes(reelIndex) ?
                'additional' : 'static';

    /**
     * Function to start logic of Animate feature first step
     */
    startAnimateBonusFeature(bonusSymbol) {
        const {extension} = this.latestResponse;
        const winReels = this.getBonusWinReels();

        // check respin screen
        if (extension && extension.screen && winReels.length !== this.reels) {
            App.Sounds.stopSound('bonus-background');
            App.Sounds.playSound('respin');
            this.allowLongRoll = true;
            this.reelSettings = [14, 30, 0];

            this.freezedReels = winReels;
            this.freezedReels.forEach(reelIndex => {
                extension.screen[reelIndex] =
                    extension.screen[reelIndex].map(() => bonusSymbol);
            });
            this.latestResponse.screen = extension.screen;
            // leave only SPECIAL_SYMBOL in latestResponse.features for respin
            this.latestResponse.features = [this.latestResponse.features.find(({uc}) => uc === 'SPECIAL_SYMBOL')];
            this.prepareToRollAnimation(extension);
            this.latestResponse.extension = {...extension, screen: null};
        } else {
            this.freezedReels = [];
            this.setState('SHOW_BONUS_WIN_LINES');
            this.prepareBonusFeatures(winReels, bonusSymbol);
            this.prepareBonusSymbols(winReels);
            this.startAnimationBook(winReels);

            const {features} = this.latestResponse;
            JL().debug(`-- Start animate bonus feature: ${JSON.stringify(features)}`);
            this.animateFeature(features);
        }
    }

    /**
     * Long roll decider return reel from with long roll started
     *  -1 no long roll
     *  0 1 2 3 4 5 number of reel
     */
    getLongRoll() {
        const reelMap = [0, 0, 0, 0, 0, 0];
        this.freezedReels.forEach(arr => {
            this.freezedReels.length !== 0 && (reelMap[arr] = 1);
        });
        this.reelLong = reelMap;
        return this.reelLong; // get possible long roll type
    }

    /**
     * Set long roll parameters
     *  -1 no long roll
     *  0 1 2 3 4 5 number of reel
     */
    setReelSymbol() {
        const longRoll = this.getLongRoll();
        const [symbolAmount, regularIncrease, longIncrease] = this.reelSettings;

        longRoll.forEach((symbol, reelIndex) => {
            const diff = symbol === 1 ? longIncrease : regularIncrease;
            if (this.allowLongRoll) {
                this.reelSymbol[reelIndex] = reelIndex === 0 ?
                    diff : this.reelSymbol[reelIndex - 1] + diff;
            } else {
                this.reelSettings = [14, 5, 30];
                this.reelSymbol[reelIndex] = reelIndex === 0 ?
                    symbolAmount : this.reelSymbol[reelIndex - 1] + diff;
            }
        });
    }

    onRotationDone() {
        JL().debug(`-- Rotation done (fps: ${App.System.statistics.fps})`);
        this.freezedReels = [];
        App.Sounds.stopSound('respin');
        this.reelSettings = [14, 5, 30];
        this.allowLongRoll = false;

        const {features, payment} = this.latestResponse;
        App.updateButton('start', {disabled: true});
        this.roundWin = 0;
        if (payment > 0 || this.isFreeRoll(features) || features.length) {
            // There is a win
            this.setState('SHOW_WIN_LINES');
            this.startAnimateFeature(features);
        } else {
            if (this.isBonus()) {
                if (this.bonusStatus && this.bonusStatus.remain > 0) {
                    this.roundFinished(false);
                } else {
                    this.Legends.setRoundFinText();
                    this.finishBonus();
                }
            } else {
                this.roundFinished();
                this.Legends.setRoundFinText();
            }
        }
    }
}
