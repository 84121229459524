import * as PIXI from 'pixi.js-legacy';
import React from 'react';
import {JL} from 'jsnlog';

import App from './../../index';
import GameMegajack from './../megajack/game';

/* PIXI aliases */
const Text = PIXI.Text,
    Texture = PIXI.Texture;

export default class GameIgrosoft extends GameMegajack {
    constructor() {
        super();
        this.superBonusStep = 4;      // шаг чузинга поле которого начинается супер бонус
        this.choosingWin = 0;         // выигырш за чузинг
        this.choosingStep = 0;
        this.ProtectionMinBet = 80;  // минимальный бет с которого начинается протекция
        this.lineTimout = null;
        this.lineAnimateInterval = null;
        this.protection = '';

        // reel properties
        this.reelTop = 80; // magic number - where reel images starts
        this.reelXCoordinates = [62, 201, 341, 480, 619]; // magic numbers - x coordinates where reels starts
        this.symbolHeight = 136; // height of a single symbol
        this.symbolWidth = 119; // width of a single symbol

        // roll properties
        this.reelSettings = [12, 3, 3]; // 0 - start symbol amount, 1 - reel increase regular roll, 2 - reel increase long roll
        this.rollProperties = {
            reelSpeed: 1.6,
            springDown: 0.2,
            springUp: 0.1
        };
        this.screenMultiplier = {win: [-1, -1, -1, -1, -1], mult: [-1, -1, -1, -1, -1]};
        this.superScreenMultiplier = {win: [-1, -1], mult: [-1, -1]};

        this.textBonus = {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 26,
            fontWeight: 'bold',
            fill: '#FFFF00',
            stroke: '#282828',
            strokeThickness: 5,
            lineJoin: 'round'
        };

        this.textSuperBonus = {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 40,
            fontWeight: 'bold',
            fill: '#D30000',
            stroke: '#282828',
            strokeThickness: 5,
            lineJoin: 'round'
        };
    }

    getProtection(response) {
        return response.protections !== undefined ?
            response.protections > 0 :
            this.gameSettings.getBetCredit() > this.ProtectionMinBet;
    }

    /**
     * Функция востановления выбора после чузинга
     */
    restoreChoosingScreen(response) {
        App.Sounds.stopSound('bonusGameStart');
        this.hideBoxes();
        this.setBackground('bonusArea');
        this.Legends.clearStatus();
        this.Legends.showWinFeatures();
        this.disableChooseButtons();
        this.choosingWin = this.latestResponse.payment;
        this.screenMultiplier.win = this.getMultiplier(response);
        this.screenMultiplier.mult = this.getMultiplier(response);
        this.choosingStep = response.step;
        this.Legends.setText('win', {text: 'win', value: this.choosingWin});
        this.Legends.setStatus('creditsWon', this.choosingWin);
        if (response.stop) {
            this.finishChoosing(response);      // stop finish shoosing take win
        } else {
            response.step >= this.superBonusStep ?
                this.startSuperBonus(response) :
                setTimeout(() => this.nextChoosing(response), 500);        // go next choosing step
            App.updateState('buttons', {visualization: 'choosing'});
        }
    }

    nextChoosing(response) {
        this.setState('CHOOSING_IDLE');
        if (this.superBonusStep <= response.step || response.stop) {
            this.showChosedItem(this.getStageChild('bonusContainer'), false);
            this.animateMultiplier(response);
        } else {
            this.showChosedItem(this.getStageChild('bonusContainer'));
        }
    }

    /**
     * Show bonus message with bonus symbol or with bonus game win.
     * @param isFirstBonus {boolean} TRUE if this is the message for starting bonus game.
     */
    drawBonusAskButton(isFirstBonus) {
        this.setState('CHOOSING_IDLE');
        App.Sounds.stopSound('bonusGameStart');
        this.hideBoxes();
        App.updateState('buttons', {visualization: 'choosing'});
        this.disableChooseButtons();
        this.setBackground('bonusArea');
        this.stopAnimateFeature();
        if (isFirstBonus) {
            this.choosingWin = 0;
        }
        this.screenMultiplier = {win: [-1, -1, -1, -1, -1], mult: [-1, -1, -1, -1, -1]};
        this.choosingWin = this.latestResponse.payment;
        this.showChosedItem(this.getStageChild('bonusContainer'));
    }

    /**
     * Take new wish and send new choice
     * Stop all animations and disable buttons
     */
    newChoice(step = ++this.choosingStep) {
        JL().debug(`-- New choice: ${step}`);
        this.getStageChild('bonusContainer').interactiveChildren = false;
        this.disableChooseButtons();
        App.Socket.send(JSON.stringify({uc: 'MAKE-CHOOSING', choice: step}));
    }

    /**
     * Функция анимации зачисления кредитов в Супер бонусной игре (1 множитель)
     */
    animateSuperBonusMultiplier(choosing, response, animationStarted = Date.now(), lastTime = animationStarted) {
        const time = Date.now();
        const timeDiff = time - lastTime;
        const animateTimeout = this.isSpeedUp ? 20 : 50;   // частота вызова функции зависит от первой и второй скорости
        if (timeDiff < animateTimeout) {
            requestAnimationFrame(this.animateSuperBonusMultiplier.bind(this, choosing, response, animationStarted, lastTime));
            return;
        }
        lastTime = time;
        let iStep = 1;
        const parentContainer = this.getStageChild('bonusContainer');
        App.Sounds.playSound('add-credit1');
        this.superScreenMultiplier.mult.forEach((multiplier, choiceIndex) => {
            parentContainer.removeChild(parentContainer.getChildByName('choiceMultiplier' + choiceIndex));
            if (multiplier > 0 && iStep !== 0) {
                this.choosingWin = this.choosingWin + this.gameSettings.getBetCredit() * iStep;
                this.superScreenMultiplier.mult[choiceIndex] -= iStep;
                iStep = 0;
            }
            if (multiplier > 0) {
                multiplier = this.superScreenMultiplier.mult[choiceIndex];
                const x = this.superChoosing[choiceIndex].textPosition.x;
                const y = this.superChoosing[choiceIndex].textPosition.y;
                const richText = new Text(multiplier, this.textSuperBonus);
                richText.position.set(x, y);
                richText.name = 'choiceMultiplier' + choiceIndex;
                parentContainer.addChild(richText);
            }
        });

        this.Legends.setText('win', {text: 'win', value: this.choosingWin});
        this.Legends.setStatus('creditsWon', this.choosingWin);

        const sumScreenMultiplier = this.superScreenMultiplier.mult.reduce((sum, x) => {
            return sum + (x === -1 ? 0 : x);
        }, 0);

        if (sumScreenMultiplier > 0) {
            requestAnimationFrame(this.animateSuperBonusMultiplier.bind(this, choosing, response, animationStarted, lastTime));
        } else {
            setTimeout(() => this.finishChoosing(response), 2000); // Close bonus results after 2 sec.
        }
    }

    /**
     * Фукнция отображения выбора Чузинга на ответ сервера
     */
    showChoice(response) {
        this.setState('CHOOSING_ANIMATION');
        this.superBonusStep < response.step ?    // супер бонус игра
            this.showSuperChoiceAnimation(response) :
            this.showChoiceAnimation(response);
    }

    /**
     * Функция окончания чузинга и выхода
     */
    finishChoosing(response) {
        this.setBackground('mainArea');
        this.showBoxes();
        this.getStageChild('bonusContainer').removeChildren();
        App.updateState('buttons', {visualization: App.buttonsType});
        this.Buttons.setDefaultGameButtons();

        this.choosingResponse = [];
        this.win = response.win;
        this.latestResponse = {payment: this.win, features: []};
        this.choosingWin = 0;
        this.gameFlag.bonusStart = false;
        this.screenMultiplier = {win: [-1, -1, -1, -1, -1], mult: [-1, -1, -1, -1, -1]};
        this.superScreenMultiplier = {win: [-1, -1], mult: [-1, -1]};
        this.win ?
            this.takeWin(true, true) :
            this.roundFinished();
    }

    /**
     * Функция отображения предыдущего выбора или супербонуса
     */
    showChosedItemSuperBonus(ctx, choosing, response, animationStarted) {

    }

    showAdditionalBonusImage() {

    }

    /**
     * Start scatter animations
     * @param features
     */
    bonusEntrance(features) {
        this.setState('SCATTER_FEATURE');
        this.playBonusGameSound();
        this.drawTopAnimation(this.getStageChild('bonusContainer'));
        App.updateButton('start', {disabled: true});

        setTimeout(() => { // call after scatter played
            this.stopAnimateFeature();
            this.drawBonusAskButton(this.isFreeRoll(features) && !this.bonusStatus);
        }, 4000);
    }

    /**
     * Фунция отрисовки Супер Бонуса
     */
    startSuperBonus(response) {
        this.choosingWin = this.choosingResponse.win;
        this.Legends.setText('win', {text: 'win', value: this.choosingResponse.win});
        this.Legends.setStatus('creditsWon', this.choosingResponse.win);
        this.setState('SUPER_BONUS_IDLE');
        App.Sounds.playSound('superWaiting');
        this.setBackground('superBonusArea');
        this.showChosedItemSuperBonus(this.getStageChild('bonusContainer'));
    }

    /**
     * Set global state for Game
     * @param state
     */
    setState(state) {
        this.state = state;
        this.app && this.addWaitingAnimationSprites(this.getStageChild('waitingContainer'));
    }

    getSpriteTextures = (
        {
            fromFrame = 0, toFrame = fromFrame + 1,
            image, width, height,
            colCount = toFrame - fromFrame,
            spriteRow = 0
        }) =>
        // create array, calc length
        [...Array(toFrame - fromFrame)].map((item, index) => {
            index += fromFrame; // set start frame
            const colIndex = index % colCount;
            const rowIndex = Math.floor(index / colCount);

            return new Texture(this.getTexture(image), {
                x: colIndex * width,
                y: (spriteRow + rowIndex) * height,
                width, height
            });
        });

    /**
     * Additional handler for keyboard keys press
     * @param event
     * @param buttons
     * @returns {{soundChange: boolean, handler: boolean}}
     */
    keyboardPress(event, buttons) {
        let soundChange = true;
        let handler = null;

        if (!(this.getState() === 'SUPER_BONUS_IDLE' || this.getState() === 'CHOOSING_IDLE')) {
            return {soundChange, handler};
        }

        const container = this.getStageChild('bonusContainer');
        let multiplier = this.screenMultiplier.win;

        // choosing buttons
        if (this.getState() === 'CHOOSING_IDLE' && container && container.interactiveChildren) {
            switch (event.keyCode) {
                case 49: // 'digit 1'
                case 97: // 'num 1'
                case 'b04': // 'h1'
                    soundChange = false; // pressed same button, disable additional handler
                    multiplier[0] === -1 && (handler = () => this.newChoice(0));
                    break;
                case 50: // 'digit 2'
                case 98: // 'num 2'
                case 'b05': // 'h2'
                    multiplier[1] === -1 && (handler = () => this.newChoice(1));
                    break;
                case 51: // 'digit 3'
                case 99: // 'num 3'
                case 'b07': // 'h3'
                    multiplier[2] === -1 && (handler = () => this.newChoice(2));
                    break;
                case 52: // 'digit 4'
                case 100: // 'num 4'
                case 'b06': // 'h4'
                    multiplier[3] === -1 && (handler = () => this.newChoice(3));
                    break;
                case 53: // 'digit 5'
                case 101: // 'num 5'
                case 'b03': // 'h5'
                    multiplier[4] === -1 && (handler = () => this.newChoice(4));
                    break;
            }
        }

        // super bonus buttons
        multiplier = this.superScreenMultiplier.win;
        if (this.getState() === 'SUPER_BONUS_IDLE' && container && container.interactiveChildren) {
            switch (event.keyCode) {
                case 50: // 'digit 2'
                case 98: // 'num 2'
                case 'b05': // 'h2'
                    multiplier[0] === -1 && (handler = () => this.newChoice(5));
                    break;
                case 52: // 'digit 4'
                case 100: // 'num 4'
                case 'b06': // 'h4'
                    multiplier[1] === -1 && (handler = () => this.newChoice(6));
                    break;
            }
        }

        return {soundChange, handler};
    }

    onRotationDone() {
        JL().debug(`-- Rotation done (fps: ${App.System.statistics.fps})`);
        const {features, payment} = this.latestResponse;
        App.updateButton('start', {disabled: true});
        this.roundWin = 0;
        if (payment > 0 || this.isFreeRoll(features) || features.length) {
            // There is a win
            this.setState('SHOW_WIN_LINES');
            this.startAnimateFeature(features);
        } else {
            if (this.isBonus()) {
                if (this.bonusStatus && this.bonusStatus.remain > 0) {
                    this.roundFinished(false);
                } else {
                    this.Legends.setRoundFinText();
                    this.finishBonus();
                }
            } else {
                this.roundFinished();
                this.Legends.setRoundFinText();
                this.lineTimout = setTimeout(() =>
                    // go next choosing step
                    this.getState() === 'IDLE' && this.drawLines(this.gameSettings.posLine), 1000);
            }
        }
    }

    startRoll() {
        clearTimeout(this.lineTimout);
        this.getState() === 'IDLE' && this.start();
        this.getState() === 'IDLE_BONUS' && this.startBonusRoll();
    }

    startGamble() {
        this.setState('ASK_GAMBLE');
        const parentContainer = this.getStageChild('boxesContainer');
        const winLines = this.getWinLines(this.latestResponse.features);
        this.stopBoxAnimation();

        this.lineAnimateInterval = setInterval(() =>
            this.Lines.animateBoxes(parentContainer, winLines), 500);

        this.winLineFeatureDelay = this.defaultFeatureDelay;
        this.Gamble.prizeWin = this.latestResponse.payment;
        this.isBonus() && this.stopAnimateFeature();
        App.Sounds.playSound('gamble-wait');
        App.updateButton('start', {
            disabled: false,
            title: 'collect',
            handler: this.finishGamble
        });
        App.updateButton('select', {
            disabled: false,
            title: 'gamble',
            handler: this.Gamble.goToGamble
        });
        this.Legends.setText('win', {text: 'win', value: this.latestResponse.payment});
        this.setGambleUpStatus();
    }

    getWinLines(features) {
        const mas = [];
        features.forEach(({uc, number}) => uc === 'WIN_LINE' && mas.push(number));
        return mas;
    }

    stopWaitingAnimation() {
        this.app && this.stopBoxAnimation();
        cancelAnimationFrame(this.waitingAnimationFrame);
        this.waitingAnimationFrame = null;
    }

    /**
     * Restore game in TRANSFER state
     **/
    restoreTransfer() {
        this.latestResponse.features = [];
        this.isSpeedUp = 1;
        this.Legends.showWinFeatures();
        this.takeWin();
    }

    stopBoxAnimation = () => {
        clearInterval(this.lineAnimateInterval);
        clearTimeout(this.lineTimout);
        this.Lines.stopAnimateBoxes(this.getStageChild('boxesContainer'));
    };

    /**
     * Function to show line for special feature
     * @param currentFeature
     */
    showFeatureLine(currentFeature) {
        const {number, reels, uc, payment} = currentFeature; // get current feature params
        const container = this.getStageChild('linesContainer');
        uc !== 'SPECIAL_SYMBOL' && container.removeChildren(); // don't clear lines before special symbol (bookGame fill)
        uc === 'WIN_LINE' && this.Lines.drawLineImages([number], reels, container, true, payment);
    }
}
