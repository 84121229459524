const bonusFont = {
    imgWidth: 3663,
    fontWidthArray: [
        123, 120, 110, 117, 117, 115, 115, 127, 85, 80, 128,
        107, 140, 117, 130, 107, 135, 107, 117, 117, 117, 127,
        130, 133, 113, 110, 107, 107, 107, 105, 103, 90, 105,
        110, 90, 85, 117, 87, 130, 125, 100, 120, 110, 95,
        110, 90, 125, 105, 125, 110, 110, 105, 105, 110, 110,
        105, 105, 110, 110, 105, 110, 110, 60
    ],
    fontHeight: 125,
    symbolsOrder: [
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K',
        'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V',
        'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g',
        'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r',
        's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '1', '2', '3',
        '4', '5', '6', '7', '8', '9', '0', ' '],
    imageResource: 'font/bonusFont.png',
    letterIndex: 31
};

export default bonusFont;
