import * as PIXI from 'pixi.js-legacy';

import App from './../index';
import CountUp from './../modules/countUp';
import bigWinFont from './../img/bigWin/font/bigWinFont';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    AnimatedSprite = PIXI.AnimatedSprite,
    Container = PIXI.Container,
    Graphics = PIXI.Graphics;

export default class BigWin {
    constructor() {
        this.enabled = false;
    }

    /**
     * Check available BIG WIN
     * @param payment
     * @returns {boolean}
     */
    isBigWin = payment => this.enabled &&
        App.Game.gameSettings.getBetCredit() * App.Game.bigWinCoef.bigWin <= payment;

    /**
     * transition to big win scenario
     * @param payment
     * @param features
     * start logic of big win animation
     */
    goToBigWin(payment, features) {
        App.Sounds.playSound('big-win-start');
        const bonusContainer = App.Game.getStageChild('bonusContainer');
        const ticker = App.Game.app.ticker;

        const bigWinContainer = new Container();
        bigWinContainer.name = 'bigWinContainer';
        bigWinContainer.sortableChildren = true;

        const bigWinImages = new Container();
        bigWinImages.name = 'bigWinImages';
        bigWinImages.zIndex = 2;
        bigWinImages.sortableChildren = true;
        bigWinImages.position.set(App.Game.gameFieldWidth / 2, App.Game.gameFieldHeight / 2);
        bigWinContainer.addChild(bigWinImages);

        bonusContainer.addChild(bigWinContainer);
        App.Game.prepareToAnimateFeature(features);

        // go to big win animation after 2 sec delay
        App.Game.tickerTimeout(() => {
            App.System.sendMetric({param: 'bigWin.count'});
            App.System.sendMetric({param: 'bigWin.payment', value: payment});
            App.System.sendMetric({param: 'bigWin.coefficient', value: payment / App.Game.gameSettings.getBetCredit()});
            const bigWinSprite = new Sprite(App.Game.getTexture('bigWin'));
            bigWinSprite.name = 'bigWin';
            bigWinSprite.position.y = -94;
            bigWinSprite.anchor.set(0.5);

            const lightSprite1 = new Sprite(App.Game.getTexture('light'));
            lightSprite1.name = 'lightSprite1';
            lightSprite1.anchor.set(0.5);
            lightSprite1.position.set(-144, -87);
            lightSprite1.scale.set(0.8);

            const lightSprite2 = new Sprite(lightSprite1.texture);
            lightSprite2.name = 'lightSprite2';
            lightSprite2.anchor.set(0.5);
            lightSprite2.position.set(198, -87);
            lightSprite2.scale.set(0.8);
            bigWinImages.addChild(lightSprite1, lightSprite2);

            ticker.add(this.rotateLight);
            bigWinImages.addChild(bigWinSprite);

            this.drawText(payment, features);

            const rect = new Graphics()
                .beginFill(0x000000, 0.5)
                .drawRect(0, 0, App.Game.gameWidth, App.Game.gameHeight);
            rect.zIndex = 0;
            rect.position.set(-(App.Game.gameWidth - App.Game.gameFieldWidth) / 2, 0);
            bigWinContainer.addChild(rect);
        }, 2000);
    };

    rotateLight = (animatedStarted = Date.now()) => {
        const time = Date.now() - animatedStarted;

        const bigWinContainer = App.Game.getStageChild('bonusContainer').getChildByName('bigWinContainer');
        const bigWinImages = bigWinContainer.getChildByName('bigWinImages');
        const lightSprite1 = bigWinImages.getChildByName('lightSprite1');
        const lightSprite2 = bigWinImages.getChildByName('lightSprite2');

        lightSprite1.angle += 1;
        lightSprite2.angle -= 1;

        bigWinImages.children.forEach((child, index) => {
            index !== 2 ? child.scale.set(0.3 * Math.sin(time * 0.006) + 1) :
                child.scale.set(0.1 * Math.sin(time * 0.006) + 1);
        });
    };

    /**
     * @param payment
     * @param features
     */
    drawText(payment, features) {
        const bigWinContainer = App.Game.getStageChild('bonusContainer').getChildByName('bigWinContainer');

        const bigWinImages = bigWinContainer.getChildByName('bigWinImages');
        const bigWinSprite = bigWinImages.getChildByName('bigWin');
        const lightSprite1 = bigWinImages.getChildByName('lightSprite1');
        const lightSprite2 = bigWinImages.getChildByName('lightSprite2');
        const coinsContainer = new Container();
        const ticker = App.Game.app.ticker;
        const numbersContainer = new Container();

        coinsContainer.zIndex = 1;
        coinsContainer.name = 'coinsContainer';
        bigWinContainer.addChild(coinsContainer);

        this.drawCoinsAnimation(coinsContainer);

        numbersContainer.name = 'numbersContainer';
        numbersContainer.position.set(App.Game.gameFieldWidth / 2, App.Game.gameFieldHeight / 2 + 30);
        numbersContainer.zIndex = 3;
        bigWinContainer.addChild(numbersContainer);

        const textProps = {
            parentContainer: numbersContainer,
            fontImageName: 'bigWinFont',
            map: bigWinFont,
            align: 'center',
            scale: 0.25,
            fontInterval: -40 // px between symbols
        };
        const betCredit = App.Game.gameSettings.getBetCredit();
        const megaWin = betCredit * App.Game.bigWinCoef.megaWin;
        const hugeWin = betCredit * App.Game.bigWinCoef.hugeWin;

        // timeCountUp - duration of numbers animation. Depends on kind of big win.
        const timeCountUp = payment < megaWin ? 5000 : payment < hugeWin ? 7000 : 9000;

        let won = 0;
        const options = {
            values: {won: {start: 0, end: payment, current: 0}},
            duration: timeCountUp
        };

        // hang forceStopBigWin function on start button
        App.Game.tickerTimeout(() => App.updateButton('start', {
            handler: () => this.forceStop(countUp, textProps, payment),
            disabled: false
        }), 500);
        const countUp = new CountUp(options);

        countUp.onTick(values => {
            numbersContainer.removeChildren();
            won = +values.won.current.toFixed(0);

            if (won > megaWin && won < hugeWin) {
                bigWinSprite.name === 'bigWin' && App.Sounds.playSound('change-type-big-win');
                lightSprite1.position.set(-140, -178);
                lightSprite2.position.set(114, -66);
                numbersContainer.position.y = App.Game.gameFieldHeight / 2 + 40;
                bigWinSprite.name = 'megaWin';
                textProps.scale = 0.3;
                bigWinSprite.texture = App.Game.getTexture('megaWin');
            } else if (won > hugeWin) {
                bigWinSprite.name === 'megaWin' && App.Sounds.playSound('change-type-big-win');
                lightSprite1.position.set(-139, -172);
                lightSprite2.position.set(92, -66);
                numbersContainer.position.y = App.Game.gameFieldHeight / 2 + 30;

                bigWinSprite.name = 'hugeWin';
                textProps.scale = 0.4;
                bigWinSprite.texture = App.Game.getTexture('hugeWin');
            }

            App.Game.drawCustomFont(won, 0, 0, textProps);
            App.Game.Legends.showWinFeatures();
            App.Game.Legends.setStatus('creditsWon', won);
            App.Game.Legends.setText('win', {
                text: 'win',
                value: App.Game.isBonus() ? won + App.Game.bonusWin : won
            });

            if (won >= payment) {
                App.updateButton('start', {disabled: true});
                App.Game.tickerTimeout(() => {
                    this.resetCoinsAnimation();
                    ticker.add(this.finishCoinsAnim);
                }, 1500);
            }
        });

        countUp.start();
        App.Game.bigWinCounter = countUp;
    };

    /**
     * @param countUp
     * @param textProps
     * @param payment
     */
    forceStop(countUp, textProps, payment) {
        App.updateButton('start', {disabled: true});

        const ticker = App.Game.app.ticker;
        const bigWinContainer = App.Game.getStageChild('bonusContainer').getChildByName('bigWinContainer');
        const numbersContainer = bigWinContainer.getChildByName('numbersContainer');
        const betCredit = App.Game.gameSettings.getBetCredit();
        const megaWin = betCredit * App.Game.bigWinCoef.megaWin;
        const hugeWin = betCredit * App.Game.bigWinCoef.hugeWin;
        const bigWinSprite = bigWinContainer.getChildByName('bigWinImages').children[2];
        const lightSprite1 = bigWinContainer.getChildByName('bigWinImages').getChildByName('lightSprite1');
        const lightSprite2 = bigWinContainer.getChildByName('bigWinImages').getChildByName('lightSprite2');
        if (payment > megaWin && payment < hugeWin) {
            bigWinSprite.name === 'bigWin' && App.Sounds.playSound('change-type-big-win');
            lightSprite1.position.set(-140, -178);
            lightSprite2.position.set(114, -66);
            numbersContainer.position.y = App.Game.gameFieldHeight / 2 + 40;
            bigWinSprite.name = 'megaWin';
            textProps.scale = 0.3;
            bigWinSprite.texture = App.Game.getTexture('megaWin');
        } else if (payment > hugeWin) {
            bigWinSprite.name === 'megaWin' && App.Sounds.playSound('change-type-big-win');
            lightSprite1.position.set(-139, -172);
            lightSprite2.position.set(92, -66);
            numbersContainer.position.y = App.Game.gameFieldHeight / 2 + 30;
            bigWinSprite.name = 'hugeWin';
            textProps.scale = 0.4;
            bigWinSprite.texture = App.Game.getTexture('hugeWin');
        }

        App.Game.Legends.showWinFeatures();
        App.Game.Legends.setStatus('creditsWon', payment);
        App.Game.Legends.setText('win', {
            text: 'win',
            value: App.Game.isBonus() ? payment + App.Game.bonusWin : payment
        });

        this.resetCoinsAnimation();
        ticker.add(this.finishCoinsAnim);
        countUp.reset();
        numbersContainer.removeChildren();
        App.Game.drawCustomFont(payment, 0, 0, textProps);
    };

    /**
     * finishing animation of coins
     */
    finishCoinsAnim = () => {
        const coinsContainer = App.Game.getStageChild('bonusContainer').getChildByName('bigWinContainer').getChildByName('coinsContainer');
        const ticker = App.Game.app.ticker;

        coinsContainer.children.forEach(coin => {
            coin.position.y += 10;
            coin.position.y > App.Game.gameFieldHeight + 50 && coin.destroy();
            if (coinsContainer.children.length === 0) {
                ticker.remove(this.finishCoinsAnim);
                App.Game.tickerTimeout(() => ticker.add(this.finish), 500);
            }
        });
    };

    finish = () => {
        const {features, payment} = App.Game.latestResponse;
        const bigWinContainer = App.Game.getStageChild('bonusContainer').getChildByName('bigWinContainer');
        const ticker = App.Game.app.ticker;

        this.resetCoinsAnimation();
        bigWinContainer.alpha -= 0.05;
        if (bigWinContainer.alpha <= 0) {
            bigWinContainer.destroy();

            // restore previous bonus win
            App.Game.roundWin = payment;
            App.Game.bonusWin += payment;

            App.Game.setState(App.Game.isBonus() ? 'SHOW_BIG_WIN_LINES' : 'SHOW_WIN_LINES');
            !App.Game.isBonus() && App.Game.endAnimateFeature();
            App.Game.animateFeature(features);

            ticker.remove(this.finish);
            ticker.remove(this.rotateLight);
        }
    };

    /**
     * @param coinsContainer
     * @param lastTime
     */
    drawCoinsAnimation(coinsContainer, lastTime = Date.now()) {
        const parentContainer = App.Game.getStageChild('bonusContainer').getChildByName('bigWinContainer').getChildByName('bigWinImages');

        // allowable number of coins on screen
        const colCoins = parentContainer.getChildByName('bigWin') ? 30 : parentContainer.getChildByName('megaWin') ? 45 : 70;
        const animateTimeout = 300;
        const timeDiff = Date.now() - lastTime;

        if (timeDiff > animateTimeout) {
            lastTime = Date.now();
            const createCoin = params => {
                const coin = new AnimatedSprite(App.Game.getSpriteTextures({
                    image: 'coinsSprite', width: 186, height: 186, colCount: 11, ...params
                }));
                coin.position.set(Math.floor(Math.random() * App.Game.gameFieldWidth - 10) + 10, -100);
                coin.scale.set(0.5);
                coin.angle = Math.floor(Math.random() * 180);
                coin.animationSpeed = 0.3;
                coin.play();
                return coin;
            };
            const coin1 = createCoin({toFrame: 14});
            const coin2 = createCoin({fromFrame: 15, toFrame: 29});
            const coin3 = createCoin({fromFrame: 30, toFrame: 41});

            coinsContainer.children.length < colCoins && coinsContainer.addChild(coin1, coin2, coin3);
        }

        coinsContainer.children.forEach(coin => {
            coin.position.y += 10;
            coin.position.y > App.Game.gameFieldHeight && (
                coinsContainer.children.length >= colCoins ?
                    coin.destroy() :
                    coin.position.y = -100);
        });
        App.Game.idAnimation = requestAnimationFrame(this.drawCoinsAnimation.bind(this, coinsContainer, lastTime));
    };

    resetCoinsAnimation() {
        App.Game.bigWinCounter && App.Game.bigWinCounter.reset();
        App.Game.bigWinCounter = null;
        cancelAnimationFrame(App.Game.idAnimation);
        App.Game.idAnimation = null;
    }
}
