import React from 'react';

import './styles.less';
import App from './../../index';
import ControlButtons from './../controlButtons';

export default function GameSettings(props) {
    const {lang, buttons, moneyParams} = props;

    return (
        <div className='game-settings'>
            <div className='control-container' onClick={event => event.stopPropagation()}>
                <ControlButtons buttons={buttons}/>
            </div>

            <div className='game-settings-buttons' onClick={event => event.stopPropagation()}>
                <div className='set-lines'>
                    <div className='set-lines-title red-color red-shadow'>
                        <span>
                            {lang[buttons.lines.title]}
                        </span>
                    </div>
                    <button
                        className='set-lines-increase'
                        onClick={() => App.Game.changeLinesNumbers('increase')}
                        style={{backgroundImage: `url(${App.getUrl('/img/mobile/settings-arrow.png')})`}}
                    />
                    <div className='set-lines-value red-shadow red-color'>{buttons.lines.value}</div>
                    <button
                        className='set-lines-decrease'
                        onClick={() => App.Game.changeLinesNumbers('decrease')}
                        style={{backgroundImage: `url(${App.getUrl('/img/mobile/settings-arrow.png')})`}}
                    />
                </div>
                <div className='set-bet'>
                    <div className='set-bet-title red-shadow red-color'>
                        <span>
                            {lang[buttons.bet.title]}
                        </span>
                    </div>
                    <button
                        className='set-bet-increase'
                        onClick={() => App.Game.changeBetMobile('increase')}
                        style={{backgroundImage: `url(${App.getUrl('/img/mobile/settings-arrow.png')})`}}
                    />
                    <div className='set-bet-value red-shadow red-color'>{buttons.bet.value}</div>
                    <button
                        className='set-bet-decrease'
                        onClick={() => App.Game.changeBetMobile('decrease')}
                        style={{backgroundImage: `url(${App.getUrl('/img/mobile/settings-arrow.png')})`}}
                    />
                </div>
                <div className={`set-denom ${App.Money.denominations.length === 1 ? 'hidden' : ''}`}>
                    <div className='set-denom-title red-shadow red-color'>
                        <span>
                            {lang[buttons.denomination.title]}
                        </span>
                    </div>
                    <button
                        className='set-denom-increase'
                        onClick={() => App.Game.changeDenomMobile('increase')}
                        style={{backgroundImage: `url(${App.getUrl('/img/mobile/settings-arrow.png')})`}}
                    >
                    </button>
                    <div className='set-denom-value red-shadow red-color'>{buttons.denomination.value}</div>
                    <button
                        className='set-denom-decrease'
                        onClick={() => App.Game.changeDenomMobile('decrease')}
                        style={{backgroundImage: `url(${App.getUrl('/img/mobile/settings-arrow.png')})`}}
                    >
                    </button>
                </div>
                <div className='extra-box'>
                    {App.Game.extraBet &&
                    <button
                        onClick={App.Game.extraBetClick}
                        style={{backgroundImage: `url(${App.getUrl('/img/ExtraBet_')}${App.Game.extraBetActive ? 'active' : 'disabled'}.png)`}}
                    />}
                </div>
            </div>
            <div className='score'>
                <div className='money'>
                    <div className='title blue-shadow blue-color'>{lang.balance}: </div>
                    <div className='value red-shadow red-color'>{moneyParams.money.toFixed(2)} {moneyParams.currency}</div>
                </div>
                {+moneyParams.insurance !== 0 && <div className='insurance'>
                    <div className='title blue-shadow blue-color'>{lang.insurance}: </div>
                    <div className='value red-shadow red-color'>{moneyParams.insurance}</div>
                </div>}
            </div>
        </div>
    );
}
