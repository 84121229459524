/**
 *
 * @param catchFunc - failed fullscreen request
 */
export default function toggleFullScreen(catchFunc) {
    if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
        if (document.documentElement.requestFullscreen) {
            document.body.requestFullscreen({navigationUI: 'hide'}).then({}).catch(catchFunc);
        } else if (document.documentElement.mozRequestFullScreen) {
            document.body.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
            document.body.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
    } else {
        if (document.cancelFullScreen) {
            document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
        }
    }
}
