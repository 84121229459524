import App from './../../../index';
import GameDeluxe from './../../deluxe/game';
import Lines20 from './../../deluxe/lines20';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';

export default class SizzlingGems20 extends GameDeluxe {
    constructor() {
        super();
        this.id = 'sizzling-gems-20';
        this.name = 'Sizzling Gems';
        this.transparentBackground = true;
        this.buttonsPanelShadow = 'mid';

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 5, 20, 50, 200]},     // 0 - вишни
            {regularDelay: 100, payment: [0, 0, 0, 20, 50, 200]},     // 1 - лимон
            {regularDelay: 100, payment: [0, 0, 0, 20, 50, 200]},     // 2 - апельсин
            {regularDelay: 100, payment: [0, 0, 0, 20, 50, 200]},     // 3 - слива
            {regularDelay: 100, payment: [0, 0, 0, 50, 200, 500]},    // 4 - виноград
            {regularDelay: 100, payment: [0, 0, 0, 50, 200, 500]},    // 5 - арбуз
            {regularDelay: 100, payment: [0, 0, 0, 100, 1000, 5000]}, // 6 - цифра '7'
            {regularDelay: 100, payment: [0, 0, 0, 2, 10, 50]}        // 7 - звезда
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {atlas: this.mergePath(['regularLongSymbols.json'])};

        this.gameSounds = {soundClass: 'deluxe'};
        this.Lines = new Lines20();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png',
            activeBlack: 'gamble/black-active.png',
            activeRed: 'gamble/red-active.png',
            inactiveRed: 'gamble/red-inactive.png',
            inactiveBlack: 'gamble/black-inactive.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 1}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 17pt Arial';
        ctx.strokeStyle = '#000';
        ctx.fillStyle = '#f7c354';
        ctx.lineWidth = 3;
        ctx.textAlign = 'center';

        // watermelon
        this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 131, 151);
        this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 131, 186);
        this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 131, 220);
        // grape
        this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 680, 151);
        this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 680, 186);
        this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 680, 220);
        // 7
        this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 405, 299);
        this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 405, 340);
        this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 405, 380);
        // plum
        this.strokeFillText(ctx, bet * this.symbols[3].payment[5], 131, 309);
        this.strokeFillText(ctx, bet * this.symbols[3].payment[4], 131, 344);
        this.strokeFillText(ctx, bet * this.symbols[3].payment[3], 131, 376);
        // orange
        this.strokeFillText(ctx, bet * this.symbols[2].payment[5], 680, 309);
        this.strokeFillText(ctx, bet * this.symbols[2].payment[4], 680, 344);
        this.strokeFillText(ctx, bet * this.symbols[2].payment[3], 680, 376);
        // chery
        this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 680, 472);
        this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 680, 505);
        this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 680, 540);
        this.strokeFillText(ctx, bet * this.symbols[0].payment[2], 680, 573);
        // Lemon
        this.strokeFillText(ctx, bet * this.symbols[1].payment[5], 131, 473);
        this.strokeFillText(ctx, bet * this.symbols[1].payment[4], 131, 508);
        this.strokeFillText(ctx, bet * this.symbols[1].payment[3], 131, 543);
        // star
        this.strokeFillText(ctx, bet * nLines * this.symbols[7].payment[5], 440, 499);
        this.strokeFillText(ctx, bet * nLines * this.symbols[7].payment[4], 440, 531);
        this.strokeFillText(ctx, bet * nLines * this.symbols[7].payment[3], 440, 567);

        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }
}
