import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../index';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Container = PIXI.Container,
    Graphics = PIXI.Graphics;

export default class InfoScreen {
    constructor({pages}, resources = []) {
        this.currentPage = 1;
        this.pages = pages;
        this.resources = resources;
        this.format = 'jpg'; // info-screen image format
    }

    /**
     * Prepare img collection to load
     */
    getResources = () => {
        const resources = {};

        for (let i = 1; i <= this.pages; i++) {
            resources[`info${i}`] = `area/info${i}-screen.${this.format}`;
        }

        return App.Game.mergePath({...resources, ...this.resources});
    };

    /**
     * Prepare Game and open info
     */
    show = () => {
        if (App.configs.mode !== 'info') {
            App.Sounds.playSound('click');
            App.Game.stopWaitingAnimation();
            App.Game.stopAnimateFeature();
            App.Game.Legends.setRoundFinText();
            App.Game.Legends.showJackpot();
            App.Game.Buttons.closeWrap();
            App.Game.disableInfoSymbols();
        }

        // check connected kiosk info
        if (App.configs.doubleScreen || App.System.broadcast.connected) {
            this.update({page: this.currentPage % this.pages + 1});
            App.Game.updateKioskInfo(true, false, this.currentPage);
        } else {
            this.update({timeout: false, page: 1});
            App.Game.startInfoAnimation();
            App.Game.Buttons.disableAllButtons();
            App.updateButton('start', {disabled: true});
            App.updateButton('autoStart', {disabled: true});
            App.updateButton('gameSettings', {status: false, additionalClass: ''});
            App.Modal.reset();
            this.checkInfoButtons();
            App.System.platform === 'mobile' && App.updateButton('info', {
                disabled: false, handler: this.close,
                additionalClass: 'active-button-mobile'
            });
        }
    };

    /**
     * Update info page in game
     */
    update({timeout = true, page = this.currentPage} = {}) {
        this.currentPage = page;
        const infoContainer = App.Game.getStageChild('infoContainer');
        if (infoContainer) {
            infoContainer.removeChildren();
            this.createInfoPage(this.currentPage);
        }

        clearTimeout(this.nextPageTimeout);
        timeout && App.configs.doubleScreen &&
        (this.nextPageTimeout = setTimeout(() => // next page
            this.update({page: this.currentPage % this.pages + 1}), 20000));
    }

    /**
     * Create PIXI.Container and draw current infoScreen
     * @param index
     * @returns {PIXI.Container}
     */
    createInfoPage = index => {
        const parentContainer = App.Game.getStageChild('infoContainer');
        const container = new Container();
        container.name = `page-${index}`;
        container.sortableChildren = true;
        parentContainer.addChild(container);

        let startSwipePos = null;
        const background = new Graphics()
            .beginFill(0x000000)
            .drawRect(0, 0, App.Game.gameWidth, App.Game.gameFieldHeight)
            .endFill();
        background.alpha = 0;
        background.name = 'background';
        background.zIndex = 0;
        background.interactive = true;
        background
            .on('touchstart', ({data}) => startSwipePos = {x: data.global.x, y: data.global.y})
            .on('touchmove', ({data}) => {
                if (startSwipePos) {
                    const {x, y} = data.global;
                    const {nextInfo, prevInfo} = App.View.state.buttons;
                    const diffPxX = x - startSwipePos.x; // swipe horizontal length in px
                    const diffPxY = y - startSwipePos.y; // swipe vertical length in px

                    // swipe if length more than 100px
                    if (Math.abs(diffPxX) > 100) {
                        diffPxX < 0 ?
                            !nextInfo.disabled && this.slide('next') :
                            !prevInfo.disabled && this.slide('prev');
                        startSwipePos = null; // reset swipe
                    }

                    // close if length more than 100px
                    if (diffPxY < -100) {
                        this.close();
                        startSwipePos = null; // reset swipe
                    }
                }
            })
            .on('touchend', () => startSwipePos = null);
        container.addChild(background);

        const canvas = document.createElement('canvas');
        canvas.width = App.Game.gameWidth;
        canvas.height = App.Game.gameFieldHeight;
        const ctx = canvas.getContext('2d');
        ctx.lineJoin = 'round';

        const lines = App.Game.gameSettings.getLinesNumber();
        const bet = App.Game.gameSettings.getBetLineCredit();
        const {currentLanguage} = App.settings;

        const image = App.Game.getAdditionalImage(`info${index}`);
        image && ctx.drawImage(image, -(image.width - App.Game.gameWidth) / 2, 0);
        ctx.translate((App.Game.gameWidth - App.Game.gameFieldWidth) / 2, 0);
        App.Game.drawInfoPage(ctx, index, lines, bet, currentLanguage);

        const infoScreen = Sprite.from(canvas);
        infoScreen.name = 'infoScreen';
        infoScreen.zIndex = 1;
        container.addChild(infoScreen);
        canvas.remove();

        return container;
    };

    /**
     * Show close animation and remove from DOM after it
     */
    close = () => {
        App.Sounds.playSound('click');
        App.Game.clearInfoAnimation();
        App.Game.Buttons.disableAllButtons();
        App.Game.setState('IDLE');
        App.Game.closeInfoAnimation();
    };

    /**
     * Clear context and remove <InfoScreen>
     * Enable buttons
     */
    reset() {
        clearTimeout(this.nextPageTimeout);
    }

    /**
     * Slide info pages
     * @param direction - 'next' / 'prev'
     */
    slide(direction) {
        if (App.Game.getState() !== 'INFO') {
            return;
        }
        const {[direction]: {pageOffset, nextPagePos}} = {
            next: {pageOffset: 1, nextPagePos: App.Game.gameWidth},
            prev: {pageOffset: -1, nextPagePos: -App.Game.gameWidth}
        };

        App.Sounds.playSound('click');
        this.checkInfoButtons(true);

        const prevPage = App.Game.getStageChild('infoContainer').children[0];
        const newPageIndex = this.normalizeIndex(this.currentPage + pageOffset);
        const newPage = this.createInfoPage(newPageIndex);
        newPage.position.set(nextPagePos, 0);

        App.Game.setState('INFO-SLIDE');
        App.Game.showAnimation({
            duration: 500,
            animations: [
                {sprite: prevPage, timeline: [{to: {x: -nextPagePos}}]},
                {sprite: newPage, timeline: [{to: {x: 0}}]}
            ],
            onComplete: () => {
                JL().debug(`-- InfoScreen slide end: ${direction}`);
                App.Game.setState('INFO');
                prevPage.destroy();
                this.currentPage = newPageIndex;
                this.checkInfoButtons();
            }
        });
    }

    normalizeIndex = page => (page - 1 + this.pages) % this.pages + 1;

    /**
     * Control info buttons
     * Check pages length
     * @param disabled - all buttons state
     */
    checkInfoButtons(disabled = false) {
        if (App.configs.mode === 'info') {
            App.updateButton('closeInfo', {visible: false, handler: null});
            App.updateButton('prevInfo', {visible: false, handler: null});
            App.updateButton('nextInfo', {visible: false, handler: null});
        } else {
            App.updateButton('closeInfo', {disabled, handler: this.close});
            App.updateButton('prevInfo', {
                disabled: disabled || this.pages === 1,
                handler: () => this.slide('prev')
            });
            App.updateButton('nextInfo', {
                disabled: disabled || this.pages === 1,
                handler: () => this.slide('next')
            });
        }
    }
}
