import {JL} from 'jsnlog';

import LinesEgt from './../egt/lines';

export default class Lines100 extends LinesEgt {
    constructor() {
        super();
        this.boxSettings.firstRightBox = 100;
        this.linesSettings = {
            ...this.linesSettings,
            lineThickness: 3,
            borderThickness: 4
        };
        this.lines = {
            0: {coordinates: [1, 1, 1, 1, 1], lineDeltaY: 0, color: '#ffd8ff'},
            1: {coordinates: [2, 2, 2, 2, 2], lineDeltaY: 0, color: '#de1800'},
            2: {coordinates: [0, 0, 0, 0, 0], lineDeltaY: 0, color: '#009200'},
            3: {coordinates: [3, 3, 3, 3, 3], lineDeltaY: 0, color: '#ce8f63'},
            4: {coordinates: [2, 3, 3, 3, 2], lineDeltaY: 4, color: '#647100'},
            5: {coordinates: [1, 0, 0, 0, 1], lineDeltaY: 4, color: '#ff24ff'},
            6: {coordinates: [0, 1, 1, 1, 0], lineDeltaY: -4, color: '#ffffad'},
            7: {coordinates: [3, 2, 2, 2, 3], lineDeltaY: 4, color: '#0096bd'},
            8: {coordinates: [2, 3, 2, 3, 2], lineDeltaY: -4, color: '#7321bd'},
            9: {coordinates: [1, 0, 1, 0, 1], lineDeltaY: 12, color: '#00ffff'},
            10: {coordinates: [0, 1, 0, 1, 0], lineDeltaY: 4, color: '#6345ff'},
            11: {coordinates: [3, 2, 3, 2, 3], lineDeltaY: -4, color: '#d6fb06'},
            12: {coordinates: [1, 3, 1, 3, 1], lineDeltaY: -8, color: '#a56e00'},
            13: {coordinates: [2, 0, 2, 0, 2], lineDeltaY: 8, color: '#949294'},
            14: {coordinates: [0, 2, 0, 2, 0], lineDeltaY: -8, color: '#de2064'},
            15: {coordinates: [3, 1, 3, 1, 3], lineDeltaY: 8, color: '#007100'},
            16: {coordinates: [0, 0, 1, 0, 0], lineDeltaY: -12, color: '#adcab5'},
            17: {coordinates: [3, 3, 2, 3, 3], lineDeltaY: 12, color: '#ff6d00'},
            18: {coordinates: [2, 2, 3, 2, 2], lineDeltaY: 12, color: '#ffff00'},
            19: {coordinates: [1, 1, 0, 1, 1], lineDeltaY: -12, color: '#00cbbe'},
            20: {coordinates: [0, 3, 0, 3, 0], lineDeltaY: 8, color: '#00cbbe'},
            21: {coordinates: [3, 0, 3, 0, 3], lineDeltaY: -8, color: '#efc704'},
            22: {coordinates: [1, 2, 1, 2, 1], lineDeltaY: 8, color: '#0045ff'},
            23: {coordinates: [2, 1, 2, 1, 2], lineDeltaY: -8, color: '#ff0000'},
            24: {coordinates: [0, 1, 2, 2, 3], lineDeltaY: -12, color: '#aeaeb5'},
            25: {coordinates: [3, 2, 1, 1, 0], lineDeltaY: 16, color: '#00cb62'},
            26: {coordinates: [1, 3, 3, 3, 1], lineDeltaY: -16, color: '#d641ff'},
            27: {coordinates: [2, 0, 0, 0, 2], lineDeltaY: -12, color: '#f792b6'},
            28: {coordinates: [0, 2, 3, 1, 0], lineDeltaY: 16, color: '#ffbf04'},
            29: {coordinates: [3, 1, 0, 2, 3], lineDeltaY: -12, color: '#a535ff'},
            30: {coordinates: [1, 3, 2, 0, 2], lineDeltaY: -16, color: '#2039bd'},
            31: {coordinates: [2, 0, 1, 3, 1], lineDeltaY: 12, color: '#7bffff'},
            32: {coordinates: [0, 1, 2, 1, 0], lineDeltaY: -16, color: '#ae4e05'},
            33: {coordinates: [3, 2, 1, 2, 3], lineDeltaY: 16, color: '#ff50b7'},
            34: {coordinates: [2, 3, 0, 3, 1], lineDeltaY: 20, color: '#ffa200'},
            35: {coordinates: [1, 0, 3, 0, 2], lineDeltaY: 16, color: '#6345ff'},
            36: {coordinates: [1, 2, 2, 3, 0], lineDeltaY: -20, color: '#e6cab6'},
            37: {coordinates: [2, 1, 1, 0, 3], lineDeltaY: 24, color: '#d631bd'},
            38: {coordinates: [0, 0, 3, 1, 2], lineDeltaY: -20, color: '#ff6d63'},
            39: {coordinates: [3, 3, 0, 2, 1], lineDeltaY: 20, color: '#33e363'},
            40: {coordinates: [1, 1, 3, 0, 2], lineDeltaY: 20, color: '#ffd8ff'},
            41: {coordinates: [2, 2, 0, 3, 1], lineDeltaY: 28, color: '#de1800'},
            42: {coordinates: [0, 0, 2, 1, 3], lineDeltaY: -28, color: '#009200'},
            43: {coordinates: [3, 3, 1, 2, 0], lineDeltaY: -16, color: '#ce8f63'},
            44: {coordinates: [1, 2, 0, 1, 3], lineDeltaY: -4, color: '#647100'},
            45: {coordinates: [2, 1, 3, 2, 0], lineDeltaY: 32, color: '#ff24ff'},
            46: {coordinates: [0, 3, 2, 3, 1], lineDeltaY: -24, color: '#ffffad'},
            47: {coordinates: [3, 0, 1, 0, 2], lineDeltaY: 20, color: '#0096bd'},
            48: {coordinates: [1, 1, 2, 3, 0], lineDeltaY: -24, color: '#7321bd'},
            49: {coordinates: [2, 2, 1, 0, 3], lineDeltaY: 36, color: '#00ffff'},
            50: {coordinates: [0, 3, 3, 2, 1], lineDeltaY: 12, color: '#6345ff'},
            51: {coordinates: [3, 0, 0, 1, 2], lineDeltaY: -20, color: '#d6fb06'},
            52: {coordinates: [2, 3, 1, 2, 0], lineDeltaY: -16, color: '#a56e00'},
            53: {coordinates: [1, 0, 2, 1, 3], lineDeltaY: 24, color: '#949294'},
            54: {coordinates: [0, 2, 0, 3, 1], lineDeltaY: 16, color: '#de2064'},
            55: {coordinates: [3, 1, 3, 0, 2], lineDeltaY: 24, color: '#007100'},
            56: {coordinates: [0, 0, 1, 2, 3], lineDeltaY: -32, color: '#adcab5'},
            57: {coordinates: [3, 3, 2, 1, 0], lineDeltaY: 28, color: '#ff6d00'},
            58: {coordinates: [1, 2, 3, 0, 1], lineDeltaY: 28, color: '#ffff00'},
            59: {coordinates: [2, 1, 0, 3, 2], lineDeltaY: -24, color: '#00cbbe'},
            60: {coordinates: [1, 1, 2, 0, 3], lineDeltaY: 32, color: '#00cbbe'},
            61: {coordinates: [2, 2, 1, 3, 0], lineDeltaY: -28, color: '#efc704'},
            62: {coordinates: [1, 1, 3, 2, 0], lineDeltaY: 36, color: '#0045ff'},
            63: {coordinates: [2, 2, 0, 1, 3], lineDeltaY: -32, color: '#ff0000'},
            64: {coordinates: [0, 1, 2, 3, 3], lineDeltaY: -36, color: '#aeaeb5'},
            65: {coordinates: [3, 2, 1, 0, 0], lineDeltaY: 32, color: '#00cb62'},
            66: {coordinates: [2, 3, 0, 1, 2], lineDeltaY: -36, color: '#d641ff'},
            67: {coordinates: [1, 0, 3, 2, 1], lineDeltaY: -28, color: '#f792b6'},
            68: {coordinates: [2, 3, 2, 1, 0], lineDeltaY: 40, color: '#ffbf04'},
            69: {coordinates: [1, 0, 1, 2, 3], lineDeltaY: -32, color: '#a535ff'},
            70: {coordinates: [0, 0, 2, 3, 1], lineDeltaY: -40, color: '#2039bd'},
            71: {coordinates: [3, 3, 1, 0, 2], lineDeltaY: -24, color: '#7bffff'},
            72: {coordinates: [0, 1, 3, 2, 0], lineDeltaY: -44, color: '#ae4e05'},
            73: {coordinates: [3, 2, 0, 1, 3], lineDeltaY: -28, color: '#ff50b7'},
            74: {coordinates: [0, 2, 1, 3, 1], lineDeltaY: 20, color: '#ffa200'},
            75: {coordinates: [3, 1, 2, 0, 2], lineDeltaY: -32, color: '#6345ff'},
            76: {coordinates: [0, 2, 2, 3, 1], lineDeltaY: 40, color: '#e6cab6'},
            77: {coordinates: [3, 1, 1, 0, 2], lineDeltaY: -36, color: '#d631bd'},
            78: {coordinates: [2, 3, 3, 1, 0], lineDeltaY: -16, color: '#ff6d63'},
            79: {coordinates: [1, 0, 0, 2, 3], lineDeltaY: 40, color: '#00e236'},
            80: {coordinates: [0, 2, 3, 3, 1], lineDeltaY: 20, color: '#00e099'},
            81: {coordinates: [3, 1, 0, 0, 2], lineDeltaY: 36, color: '#04e020'},
            82: {coordinates: [0, 3, 0, 2, 1], lineDeltaY: 24, color: '#01e300'},
            83: {coordinates: [3, 0, 3, 1, 2], lineDeltaY: 40, color: '#00e260'},
            84: {coordinates: [1, 3, 0, 2, 0], lineDeltaY: 44, color: '#22e133'},
            85: {coordinates: [2, 0, 3, 1, 3], lineDeltaY: 44, color: '#cd00cd'},
            86: {coordinates: [1, 3, 1, 0, 2], lineDeltaY: -36, color: '#f4a460'},
            87: {coordinates: [2, 0, 2, 3, 1], lineDeltaY: 16, color: '#98ff98'},
            88: {coordinates: [0, 3, 1, 2, 1], lineDeltaY: 28, color: '#c0c0c0'},
            89: {coordinates: [3, 0, 2, 1, 2], lineDeltaY: 44, color: '#00e180'},
            90: {coordinates: [1, 2, 3, 3, 0], lineDeltaY: -40, color: '#986960'},
            91: {coordinates: [2, 1, 0, 0, 3], lineDeltaY: -40, color: '#00e100'},
            92: {coordinates: [1, 2, 1, 0, 3], lineDeltaY: -44, color: '#c8a2c8'},
            93: {coordinates: [2, 1, 2, 3, 0], lineDeltaY: -44, color: '#423189'},
            94: {coordinates: [0, 3, 3, 1, 2], lineDeltaY: 32, color: '#808080'},
            95: {coordinates: [3, 0, 0, 2, 1], lineDeltaY: -44, color: '#c3b091'},
            96: {coordinates: [0, 3, 2, 1, 2], lineDeltaY: 36, color: '#7fffd4'},
            97: {coordinates: [3, 0, 1, 2, 1], lineDeltaY: -40, color: '#c71585'},
            98: {coordinates: [1, 2, 0, 0, 3], lineDeltaY: 48, color: '#f2f3f4'},
            99: {coordinates: [2, 1, 3, 3, 0], lineDeltaY: -20, color: '#f4a460'}
        };
        this.lineMap = [...Array(100)].map((item, i) => i);

        JL().debug('-- Lines100 initialized');
    }

    getBoxesStyle = () => ({
        activeBoxStyle: {
            dropShadow: true,
            dropShadowAlpha: 8,
            dropShadowBlur: 8,
            dropShadowColor: '#37040b',
            dropShadowDistance: 1,
            fill: '#63dc47',
            fontSize: 13,
            stroke: '#37040b',
            strokeThickness: 5
        },
        inactiveBoxStyle: {
            dropShadowColor: '#05050f',
            dropShadowDistance: 1,
            fill: '#4495ef',
            fontSize: 19,
            stroke: '#0a060f',
            strokeThickness: 5
        },
        colLines: [1, 20, 30, 50, 100],
        coordinates: {
            0: {x: 27, y: 408},
            1: {x: 776, y: 408}
        }
    })
}
