import rouletteNumbers from './rouletteNumbers';

const Track = [];

[...Array(41)].forEach((item, index) => {
    const w = 3, h = 2;
    let obj = {
        typeOfBet: 'NEIGHBORS',
        limit: 'number',
        numbers: [...Array(5)].map((obj, i) =>
            rouletteNumbers[(i - 2 + index + rouletteNumbers.length) % rouletteNumbers.length]),
        size: {w, h},
        pos: {x: 0, y: 0}
    };

    if (index === 0) obj = {...obj, pos: {x: 0, y: 35}, size: {w: w * 1.5, h: h * 2 + 0.1}};
    if (rouletteNumbers[index] === 26) obj = {...obj, pos: {x: 4.5, y: 36}, size: {w, h: 3.1}};
    if (rouletteNumbers[index] === 3) obj = {...obj, pos: {x: w * 2, y: 35}, size: {w, h: 3}};
    if (rouletteNumbers[index] === 8) obj = {...obj, pos: {x: 0, y: 1.5}, size: {w, h: 3.5}};
    if (rouletteNumbers[index] === 23) obj = {...obj, pos: {x: w / 2, y: 0}, size: {w, h: 3.5}};
    if (rouletteNumbers[index] === 10) obj = {...obj, pos: {x: w * 1.5, y: 0}, size: {w, h: 3.5}};
    if (rouletteNumbers[index] === 5) obj = {...obj, pos: {x: w * 2, y: 1.5}, size: {w, h: 3.5}};

    if (index > 0 && index < 16) {
        obj = {...obj, pos: {x: 0, y: (15 - index) * h + 5}};
    }

    if (index > 19 && index < 35) {
        obj = {...obj, pos: {x: w * 2, y: (index - 20) * h + 5}};
    }

    if (index === 37) {
        obj = {
            ...obj,
            typeOfBet: 'TIER',
            numbers: [125, 103, 133, 112, 147, 153],
            pos: {x: w, y: 3},
            size: {w, h: h * 6}
        };
    }

    if (index === 38) {
        obj = {
            ...obj,
            typeOfBet: 'ORPHELINS',
            numbers: [1, 126, 134, 137, 151],
            pos: {x: w, y: 3 + h * 6},
            size: {w, h: h * 3}
        };
    }

    if (index === 39) {
        obj = {
            ...obj,
            typeOfBet: 'VOISINS',
            numbers: [96, 132, 124, 138, 139, 65, 152],
            pos: {x: w, y: 3 + h * 5 + h * 4},
            size: {w, h: h * 5}
        };
    }

    if (index === 40) {
        obj = {
            ...obj,
            typeOfBet: 'ZERO',
            numbers: [26, 152, 132, 156],
            pos: {x: w, y: 3 + h * 5 + h * 4 + h * 5 - 1.1},
            size: {w, h: h * 3 + 0.05}
        };
    }

    Track.push(obj);
});

export default Track;
