import React from 'react';
import App from '../../index';
import './styles.less';

export default class PasswordField extends React.Component {
    state = {
        type: 'password'
    };

    handleClick = () => this.setState(({type}) => ({
        type: type === 'text' ? 'password' : 'text'
    }));

    render() {
        const {isCode = false, inputName, placeholder = '', autoComplete = 'on'} = this.props;
        const {type} = this.state;
        return (
            <div className='input-container'>
                <input
                    type={type}
                    name={inputName}
                    className='form-field no-clear-focus'
                    autoComplete={autoComplete}
                    placeholder={placeholder}
                    maxLength={20}
                    style={{opacity: isCode ? '0' : '1', pointerEvents: isCode ? 'none' : 'auto'}}
                />
                <button
                    type='button'
                    className={`toggle-visible ${type}`}
                    onClick={this.handleClick}
                    tabIndex={-1} // disable button for keyboard navigation
                    style={{
                        opacity: isCode ? '0' : '1',
                        pointerEvents: isCode ? 'none' : 'auto',
                        backgroundImage: `url(${App.getUrl('/img/registration/eye.png')})`
                    }}
                />
            </div>
        );
    }
}
