import App from './../../../index';
import GameDeluxe from './../../deluxe/game';
import Lines10 from './../../deluxe/lines10';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';

export default class JokerFruits extends GameDeluxe {
    constructor() {
        super();
        this.id = 'joker-fruits';
        this.name = 'Joker Fruits';
        this.reelFilter = [[9, 7, 8], [9, 7, 8], [8, 9], [9, 7, 8], [9, 7, 8]];
        this.buttonsPanelShadow = 'mid';

        this.symbols = [
            {regularDelay: 100, skipSteps: 3, payment: [0, 0, 0, 5, 25, 100]},    // 0 - cherry
            {regularDelay: 100, skipSteps: 3, payment: [0, 0, 0, 5, 25, 100]},    // 1 - lemon
            {regularDelay: 100, skipSteps: 3, payment: [0, 0, 0, 10, 50, 200]},   // 2 - orange
            {regularDelay: 100, skipSteps: 3, payment: [0, 0, 0, 10, 50, 200]},   // 3 - plum
            {regularDelay: 100, skipSteps: 3, payment: [0, 0, 0, 20, 100, 500]},  // 4 - grape
            {regularDelay: 100, skipSteps: 3, payment: [0, 0, 0, 20, 100, 500]},  // 5 - melon
            {regularDelay: 100, skipSteps: 3, payment: [0, 0, 0, 50, 500, 2500]}, // 6 - seven
            {regularDelay: 100, payment: [0, 0, 0, 0, 0, 0]},                     // 7 - joker x1
            {regularDelay: 100, payment: [0, 0, 0, 0, 0, 0]},                     // 8 - joker x3
            {regularDelay: 100, payment: [0, 0, 0, 0, 0, 0]}                      // 9 - joker x7
        ];
        this.imageResources = {
            main: this.mergePath({mainArea: 'area/main.png'}),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.gameSounds = {soundClass: 'deluxe'};
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png'
        }));
        this.Lines = new Lines10();
        this.InfoScreen = new InfoScreen({pages: 1}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '18pt sans-serif';
        ctx.textAlign = 'left';
        ctx.fillStyle = '#e8eaf7';
        ctx.shadowColor = 'black';
        ctx.shadowOffsetX = 3;
        ctx.shadowOffsetY = 3;
        ctx.shadowBlur = 5;

        // 6 - цифра '7'
        this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 395, 274);
        this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 395, 307);
        this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 395, 344);

        // 4 - виноград
        this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 660, 184);
        this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 660, 217);
        this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 660, 250);

        // 5 - арбуз
        this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 130, 187);
        this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 130, 220);
        this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 130, 253);

        // 3 - сливат, апельсин
        this.strokeFillText(ctx, bet * this.symbols[3].payment[5], 130, 312);
        this.strokeFillText(ctx, bet * this.symbols[3].payment[4], 130, 346);
        this.strokeFillText(ctx, bet * this.symbols[3].payment[3], 130, 380);

        // 0 - вишня, лимон
        this.strokeFillText(ctx, bet * this.symbols[1].payment[5], 660, 312);
        this.strokeFillText(ctx, bet * this.symbols[1].payment[4], 660, 346);
        this.strokeFillText(ctx, bet * this.symbols[1].payment[3], 660, 380);

        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    /**
     * Process reels response from server. Redefined from game.js
     * @param response {object} Server response.
     */
    processReelResponse(response) {
        this.latestResponse = response;
        this.setState('RESPONSE_RECEIVED');
        this.setBonusRollSymbol(); // for bonus game roll symbol

        // ------------------ Add multipliers ------------------
        let multiply = [1, 1, 1];
        if (response.extension && response.extension.multipliers) {
            multiply = response.extension.multipliers;
        }

        for (let reelIndex = 0; reelIndex < this.reels; reelIndex++) {
            for (let rowIndex = 0; rowIndex < this.reelRows; rowIndex++) {
                if (response.screen[reelIndex][rowIndex] === 7) {
                    if (multiply[rowIndex] === 3) {
                        response.screen[reelIndex][rowIndex] = 8; // x3
                        this.reelMatrix[reelIndex][rowIndex].symbol = 8;
                    } else if (multiply[rowIndex] === 7) {
                        response.screen[reelIndex][rowIndex] = 9; // x7
                        this.reelMatrix[reelIndex][rowIndex].symbol = 9;
                    }
                }
            }
        }
        // ---------------------------------------------

        this.prepareToRollAnimation(response);
    }
}
