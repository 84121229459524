import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../../../index';
import GameDeluxe from './../../deluxe/game';
import Lines from './lines';
import Gamble from './gamble';
import InfoScreen from '../../infoScreen';
import langsBonus from './langs';
import SymbolInfoCustom from './symbolInfo';
import './styles.less';

const AnimatedSprite = PIXI.AnimatedSprite,
    Container = PIXI.Container,
    Sprite = PIXI.Sprite,
    Text = PIXI.Text;

export default class SeaTreasure extends GameDeluxe {
    constructor() {
        super();
        this.id = 'sea-treasure';
        this.name = 'Sea Treasure';
        this.scatter = 11;
        this.gameFieldWidth = 960;
        this.gameWidth = App.System.resolution === '4x3' ? this.gameFieldWidth : 1280;
        this.gameFieldHeight = 720;
        this.gameHeight = App.configs.doubleScreen ? 1440 : 720;
        this.symbolWidth = 154;
        this.symbolHeight = 154;
        this.defaultFeatureDelay = 1500;
        this.BigWin.enabled = true;
        this.buttonsPanelShadow = 'mid';
        this.reelTop = 112;
        this.transparentBackground = true;
        this.reelXCoordinates = [77, 245, 402, 562, 725];
        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 310, y: 250},
            bonusInBonusFrame: {x: 310, y: 250},
            endBonusFrame: {x: 310, y: 250}
        };
        this.symbolBackground = true;

        this.symbols = [
            {regularDelay: 50, payment: [0, 0, 0, 5, 25, 100]},       // 0 - fish clown
            {regularDelay: 50, payment: [0, 0, 0, 5, 25, 100]},       // 1 - fish butterfly
            {regularDelay: 50, payment: [0, 0, 0, 5, 25, 100]},       // 2 - fish surgeon
            {regularDelay: 50, payment: [0, 0, 0, 10, 50, 125]},      // 3 - crab
            {regularDelay: 50, payment: [0, 0, 0, 10, 50, 125]},      // 4 - turtle
            {regularDelay: 50, payment: [0, 0, 0, 15, 75, 250]},      // 5 - jellyfish
            {regularDelay: 50, payment: [0, 0, 0, 15, 75, 250]},      // 6 - fish lion
            {regularDelay: 50, payment: [0, 0, 0, 20, 100, 400]},     // 7 - squid
            {regularDelay: 50, payment: [0, 0, 2, 25, 125, 750]},     // 8 - sea horse
            {regularDelay: 50, payment: [0, 0, 2, 25, 125, 750]},     // 09 - anchor
            {regularDelay: 50, payment: [0, 0, 10, 250, 2500, 9000]}, // 10 - amphora
            {regularDelay: 50, payment: [0, 0, 2, 5, 20, 500]}        // 11 - treasure
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: `area/${App.System.resolution}/main.png`,
                bonusArea: `area/${App.System.resolution}/bonus.png`,
                background: 'area/background.png',
                bonusBackground: 'area/bonus-background.png',
                secondScreenBg: 'area/second-screen-bg.png'
            }),
            jsonAnimations: this.mergePath({
                borderSymbols: 'borderSymbols.json',
                line: 'lines/Line.json',
                bubble: 'area/Bubble.json'
            }),
            atlas: this.mergePath(['staticSymbols.json']),
            fonts: this.mergePath({numberFont: 'font/numbers.ttf'})
        };
        this.additionalResources = {
            main: this.mergePath({
                additionalArea: `area/${App.System.resolution}/additional.png`,
                frame: 'bonus/frame.png',
                symbolBorder: 'area/symbol-border.png',
                paymentBorder: 'area/symbol-border.png'
            }),
            atlas: this.mergePath(['bonus/scatterSymbols.json'])
        };
        this.gameSounds = {soundClass: 'prefergames'};
        this.Gamble = new Gamble(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            diamonds: 'gamble/fish-clown.png',
            hearts: 'gamble/fish-surgeon.png',
            clubs: 'gamble/fish-butterfly.png',
            spades: 'gamble/fish-lion.png',
            aceOfDiamonds: 'gamble/fish-clown.png',
            aceOfClubs: 'gamble/fish-butterfly.png',
            aceOfHearts: 'gamble/fish-surgeon.png',
            aceOfSpades: 'gamble/fish-lion.png',
            smallCard: 'gamble/card-small.png',
            bigCard: 'gamble/card-big.png',
            inactiveBlack: 'gamble/black-inactive.png',
            activeBlack: 'gamble/black-inactive.png',
            inactiveRed: 'gamble/red-inactive.png',
            activeRed: 'gamble/red-inactive.png',
            invert: 'gamble/invert.png'
        }));

        this.Lines = new Lines();
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
        this.InfoScreen.format = 'png';
        this.SymbolInfo = new SymbolInfoCustom();
        this.SymbolInfo.enabled = true;
    }

    createMainContainer(parentContainer) {
        const container = new Container();
        container.name = 'mainContainer';
        container.zIndex = this.containersLayers[container.name];
        container.sortableChildren = true;

        for (let i = 0; i <= 1; i++) {
            const spineBubble = new window.PIXI.spine.Spine(App.Game.getSpineData('bubble'));
            spineBubble.name = 'bubbleSpine';
            spineBubble.position.set(i === 0 ? 480 : 480, 247);
            i === 0 && spineBubble.scale.set(-1, 1);
            spineBubble.state.setAnimation(0, 'start', true);
            spineBubble.zIndex = 1;
            container.addChild(spineBubble);
        }

        const sprite = new Sprite(this.getTexture('mainArea'));
        sprite.name = 'mainArea';
        sprite.position.set(-(this.gameWidth - this.gameFieldWidth) / 2, 0);
        container.addChild(sprite);
        parentContainer.addChild(container);
    }

    drawInfoPage(ctx, page, nLines, bet, lang) {
        ctx.font = '17pt franklinFont';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#0fc8c6';
        ctx.strokeStyle = '#fff';
        ctx.lineWidth = 6;
        const {bonusRules} = langsBonus[lang];
        const {
            combinationOfKind,
            combinationLeftToRight,
            prizesOnSelectedLines,
            ScatterPayAtAnyPosition,
            HighestWinPaid,
            ScatterWinsAddedToLineWins,
            prizesShownInCredits,
            MalfunctionVoidsAllPays
        } = App.languageCollection[lang];
        const {rules} = App.languageCollection[lang];
        const textProps = {
            font: 'bold 18pt Franklin Gothic Medium',
            textAlign: 'center',
            fillStyle: '#ffa30f',
            strokeStyle: '#000',
            lineWidth: 3,
            shadowColor: '#000',
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 2,
            lineHeight: 20
        };

        switch (page) {
            case 1:
                ctx.font = '15pt numberFont';
                const symbol1 = {x: 380, y: 310};
                const symbol2 = {x: 810, y: 130};
                const symbol3 = {x: 760, y: 320};
                const symbol4 = {x: 225, y: 500};
                const symbol5 = {x: 540, y: 500};
                const symbol6 = {x: 850, y: 500};
                const symbol7 = {x: 105, y: 130};

                // sea horse & anchor
                this.strokeFillText(ctx, bet * this.symbols[8].payment[5], symbol1.x, symbol1.y);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[4], symbol1.x, symbol1.y + 30);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[3], symbol1.x, symbol1.y + 60);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[2], symbol1.x, symbol1.y + 90);

                // treasures
                this.strokeFillText(ctx, nLines * bet * this.symbols[11].payment[5], symbol2.x, symbol2.y);
                this.strokeFillText(ctx, nLines * bet * this.symbols[11].payment[4], symbol2.x, symbol2.y + 32);
                this.strokeFillText(ctx, nLines * bet * this.symbols[11].payment[3], symbol2.x, symbol2.y + 64);
                this.strokeFillText(ctx, nLines * bet * this.symbols[11].payment[2], symbol2.x, symbol2.y + 96);

                // squid
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], symbol3.x, symbol3.y);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], symbol3.x, symbol3.y + 30);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], symbol3.x, symbol3.y + 60);

                // lion fish & jellyfish
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], symbol4.x, symbol4.y);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], symbol4.x, symbol4.y + 30);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], symbol4.x, symbol4.y + 60);

                // crab & turtle
                this.strokeFillText(ctx, bet * this.symbols[3].payment[5], symbol5.x, symbol5.y);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[4], symbol5.x, symbol5.y + 30);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[3], symbol5.x, symbol5.y + 60);

                // 3 fishes
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], symbol6.x, symbol6.y);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], symbol6.x, symbol6.y + 30);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], symbol6.x, symbol6.y + 60);

                // amphora (wild)
                this.strokeFillText(ctx, bet * this.symbols[10].payment[5], symbol7.x, symbol7.y);
                this.strokeFillText(ctx, bet * this.symbols[10].payment[4], symbol7.x, symbol7.y + 32);
                this.strokeFillText(ctx, bet * this.symbols[10].payment[3], symbol7.x, symbol7.y + 64);
                this.strokeFillText(ctx, bet * this.symbols[10].payment[2], symbol7.x, symbol7.y + 90);

                // x2 payments with wild
                ctx.fillStyle = '#FFC0CB';
                ctx.strokeStyle = '#8B00FF';
                this.strokeFillText(ctx, bet * this.symbols[8].payment[5] * 2, symbol1.x + 70, symbol1.y);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[4] * 2, symbol1.x + 70, symbol1.y + 30);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[3] * 2, symbol1.x + 70, symbol1.y + 60);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[2] * 2, symbol1.x + 70, symbol1.y + 90);

                this.strokeFillText(ctx, bet * this.symbols[7].payment[5] * 2, symbol3.x + 70, symbol3.y);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4] * 2, symbol3.x + 70, symbol3.y + 30);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3] * 2, symbol3.x + 70, symbol3.y + 60);

                this.strokeFillText(ctx, bet * this.symbols[5].payment[5] * 2, symbol4.x + 70, symbol4.y);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4] * 2, symbol4.x + 70, symbol4.y + 30);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3] * 2, symbol4.x + 70, symbol4.y + 60);

                this.strokeFillText(ctx, bet * this.symbols[3].payment[5] * 2, symbol5.x + 70, symbol5.y);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[4] * 2, symbol5.x + 70, symbol5.y + 30);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[3] * 2, symbol5.x + 70, symbol5.y + 60);

                this.strokeFillText(ctx, bet * this.symbols[0].payment[5] * 2, symbol6.x + 70, symbol6.y);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4] * 2, symbol6.x + 70, symbol6.y + 30);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3] * 2, symbol6.x + 70, symbol6.y + 60);
                break;
            case 2:
                ctx.font = 'italic bold 30pt Arial';
                ctx.strokeStyle = '#000';
                ctx.fillStyle = '#ff0000';
                ctx.lineWidth = 3;
                ctx.textAlign = 'center';
                this.strokeFillText(ctx, rules, 480, 180, 500);
                this.drawSplitText(ctx, bonusRules, 480, 280, 500, {
                    ...textProps,
                    fillStyle: '#fff',
                    lineHeight: 40,
                    textAlign: 'center'
                });
                break;
            case 3:
                ctx.font = 'italic bold 30pt Arial';
                ctx.strokeStyle = '#000';
                ctx.fillStyle = '#ff0000';
                ctx.lineWidth = 3;
                ctx.textAlign = 'center';
                const rulesText = combinationOfKind +
                    combinationLeftToRight +
                    prizesOnSelectedLines +
                    ScatterPayAtAnyPosition +
                    HighestWinPaid +
                    ScatterWinsAddedToLineWins +
                    prizesShownInCredits +
                    MalfunctionVoidsAllPays;
                this.strokeFillText(ctx, rules, 480, 180, 500);
                this.drawSplitText(ctx, rulesText, 480, 225, 665, {...textProps, fillStyle: '#fff', lineHeight: 40});
        }
    }

    finishGamble = () => {
        this.showBoxes();
        this.showLines();
        this.Gamble.resetInverting();
        this.Gamble.ctx = null;
        this.Gamble.currentGambleMultiplier = 1;
        this.Gamble.disableSuitsButtons();
        this.Gamble.animateGambleAreaCompleted = false;

        App.updateState('buttons', {visualization: App.buttonsType});
        App.updateButton('select', {additionalClass: ''});
        App.updateButton('info', {additionalClass: ''});
        App.updateButton('redCard', {disabled: true, handler: null});
        App.updateButton('blackCard', {disabled: true, handler: null});

        cancelAnimationFrame(this.Gamble.gambleTimeout);
        this.latestResponse.payment = this.Gamble.prizeWin;
        const {features} = this.latestResponse;

        this.endGambleAnimation();
        if (this.Gamble.prizeWin === 0) {
            App.View.setState({activeGamble: false});
            this.Legends.setStatus('gambleEnd');
            this.Legends.showJackpot();
            JL().debug('-- Loose gamble');
            this.roundFinished();
        } else {
            JL().debug(`-- Win gamble. Prize: ${this.Gamble.prizeWin}`);
            App.updateButton('select', {title: 'select'});
            App.updateButton('info', {disabled: true, title: 'paytable'});
            App.View.setState({activeGamble: false});

            // restore animate feature
            features.length && this.getState() === 'GAMBLE' &&
            this.startAnimateFeature(features);

            this.takeWin();
        }
    };

    /**
     * Change mainContainer and <Buttons> background
     * @param type
     */
    setBackground(type) {
        const mainArea = this.getStageChild('mainContainer').getChildByName('mainArea');
        const reelsBackground = this.getStageChild('reelsBackground');

        [mainArea, reelsBackground].forEach(sprite => {
            const texture = sprite.name === 'mainArea' ? type :
                type === 'mainArea' ? 'background' : 'bonusBackground'; // for reels background
            const transition = new Sprite(this.getTexture(texture));
            transition.name = 'transition';
            transition.alpha = 0;
            transition.position.set(sprite.position.x, sprite.position.y);
            sprite.parent.addChildAt(transition, 0);

            this.showAnimation({
                duration: 500,
                animations: [
                    {sprite: transition, timeline: [{to: {alpha: 1}}]},
                    {sprite: sprite, timeline: [{to: {alpha: 0}}]}
                ],
                onComplete: () => {
                    transition.destroy();
                    sprite.texture = this.getTexture(texture);
                    sprite.alpha = 1;
                }
            });
        });
    }

    restoreBonusGame() {
        this.getTexture('bonusArea') && this.setBackground('bonusArea');
        this.bonusWin = this.bonusStatus.win - this.latestResponse.payment;
        // Fill WIN data
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});
        this.Legends.showWinFeatures();
        this.setBonusStatusText();

        this.Buttons.disableAllButtons();
        this.gameFlag.bonusStart = true;
        this.gameFlag.bonusStarted = true;

        this.processReelResponse(this.latestResponse);
        this.showAdditionalBonusImage(this.getStageChild('bonusContainer'));
    }

    startGambleAnimation() {
        this.hideReels();
        this.setBackground('additionalArea');
    }

    endGambleAnimation() {
        this.showReels();
        this.getState() !== 'ASK_GAMBLE' && this.setBackground('mainArea');
    }

    addSymbolBackground(symbolContainer, x, y) {
        const symbolBackgroundAnimation = new AnimatedSprite(this.getJsonTextures('borderSymbols'));
        symbolBackgroundAnimation.name = 'SymbolBackground';
        symbolBackgroundAnimation.position.set(x, y);
        symbolBackgroundAnimation.animationSpeed = 0.3;
        symbolBackgroundAnimation.zIndex = -1;
        symbolBackgroundAnimation.scale.set(1.25);
        symbolBackgroundAnimation.anchor.set(0.5);
        symbolBackgroundAnimation.blendMode = 3;

        symbolContainer.addChild(symbolBackgroundAnimation);
        return symbolBackgroundAnimation;
    }

    hideReels() {
        this.showAnimation({
            duration: 500, animations: [
                {
                    sprite: this.getStageChild('reelsStage'),
                    timeline: [{to: {alpha: 0}}]
                }, {
                    sprite: this.getStageChild('linesContainer'),
                    timeline: [{from: {alpha: 1}, to: {alpha: 0}}]
                }
            ]
        });
    }

    showReels() {
        const reelsStage = this.getStageChild('reelsStage');
        reelsStage.interactiveChildren = false;

        this.showAnimation({
            duration: 500, animations: [
                {sprite: reelsStage, timeline: [{to: {alpha: 1}}]},
                {sprite: this.getStageChild('linesContainer'), timeline: [{from: {alpha: 0}, to: {alpha: 1}}]}
            ], onComplete: () => {
                reelsStage.interactiveChildren = true;
            }
        });
    }

    /**
     * Отрисовка таблички бонусной игры
     */
    showStartBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y);
        this.showPressAnyButton(false);
        const youWonFreeGames = new Text(`${App.language.youWon}\n 15 ${App.language.freeGames}`, {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 26,
            fontWeight: 'bold',
            fill: '#fff',
            lineJoin: 'round',
            stroke: '#000',
            strokeThickness: 3
        });
        youWonFreeGames.x = x + 145;
        youWonFreeGames.name = 'youWon';
        youWonFreeGames.y = y + 55;
        youWonFreeGames.anchor.set(0.5);
        const multiplier = new Text(`x3 ${App.language.multiplier}`, {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 22,
            fontWeight: 'bold',
            fill: '#fff',
            lineJoin: 'round',
            stroke: '#000',
            strokeThickness: 3
        });
        multiplier.name = 'multiplier';
        multiplier.x = x + 145;
        multiplier.y = y + 100;
        multiplier.anchor.set(0.5);

        parentContainer.addChild(youWonFreeGames, multiplier);
    }

    /**
     * Отрисовка таблички бонус в бонусе
     */

    showBonusInBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y, 'frame');
        this.tickerTimeout(() => {
            App.updateButton('start', {
                disabled: false,
                title: 'start',
                handler: () => this.drawBonusStep(this.latestResponse.features)
            });
        }, 5300);

        const moreFreeGames = new Text(`${App.language.youWon} \n ${App.language.more} 15 \n ${App.language.freeGames}`, {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 28,
            fontWeight: 'bold',
            fill: '#fff',
            lineJoin: 'round',
            stroke: '#000',
            strokeThickness: 3
        });
        moreFreeGames.name = 'moreFreeGames';
        moreFreeGames.anchor.set(0.5);
        moreFreeGames.x = x + 160;
        moreFreeGames.y = y + 65;

        parentContainer.addChild(moreFreeGames);
    }

    showEndBonusFrame(parentContainer, {x, y}, {win, total}) {
        this.showBonusFrame(parentContainer, x, y, 'frame');
        const totalWin = new Text(`${App.language.youWon}\n \n ${App.language.credits}`, {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 30,
            fontWeight: 'bold',
            fill: '#fff',
            lineJoin: 'round'
        });
        totalWin.name = 'totalWin';
        totalWin.anchor.set(0.5);
        totalWin.x = x + 160;
        totalWin.y = y + 65;

        const totalWinAmount = new Text(total, {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 30,
            fontWeight: 'bold',
            fill: '#fff',
            lineJoin: 'round'
        });
        totalWinAmount.anchor.set(0.5);
        totalWinAmount.x = x + 160;
        totalWinAmount.y = y + 65;

        parentContainer.addChild(totalWin, totalWinAmount);
    }

    onInfoStartOpen() {
        this.setBackground('additionalArea');
        this.hideReels();
    };

    onInfoStartClose() {
        this.showReels();
    }

    /**
     * Create texts for paymentBorder table
     * @param parentContainer
     * @param payTable
     * @param direction
     */
    drawSymbolInfoPayments(parentContainer, payTable, direction) {
        const bet = this.gameSettings.getBetLineCredit();
        const props = {
            fill: '#00ff07',
            stroke: '#007318',
            align: 'left',
            fontFamily: 'numberFont',
            fontSize: 22,
            strokeThickness: 3,
            lineJoin: 'round'
        };

        if (payTable.length) {
            payTable.forEach((pay, index) => {
                const quantity = new Text(`x${pay[0]}`, props);
                quantity.position.x = direction === 'left' ? -65 : -45;
                quantity.position.y = 28 + (payTable.length === 1 ? -30 : -30 * index);

                const payment = new Text(` ${bet * pay[1]}`, {...props, fill: '#6bf2ff', stroke: '#1149ab'});
                payment.position.x = direction === 'left' ? -25 : -15;
                payment.position.y = 28 + (payTable.length === 1 ? -30 : -30 * index);

                parentContainer.addChild(quantity, payment);
            });
        } else {
            const wild = new Text('WILD', {...props, fontSize: 30});
            wild.position.x = direction === 'left' ? -50 : -25;
            wild.position.y = 30;
            parentContainer.addChild(wild);
        }
    }

    /**
     * Update PIXI stage after language change
     * @param language - current language collection
     */
    translateStage(language) {
        const frame = this.getStageChild('bonusContainer').getChildByName('frame');

        if (frame) {
            const youWon = this.getStageChild('bonusContainer').getChildByName('youWon');
            youWon && (youWon.text = `${language.youWon}\n 15 ${language.freeGames}`);

            const multiplier = this.getStageChild('bonusContainer').getChildByName('multiplier');
            multiplier && (multiplier.text = `x3 ${language.multiplier}`);

            const moreFreeGames = this.getStageChild('bonusContainer').getChildByName('moreFreeGames');
            moreFreeGames && (moreFreeGames.text = `${App.language.youWon} \n ${App.language.more} 15 \n ${App.language.freeGames}`);

            const totalWin = this.getStageChild('bonusContainer').getChildByName('totalWin');
            totalWin && (totalWin.text = `${App.language.youWon}\n \n ${App.language.credits}`);
        }
    }

    /**
     * Create PIXI.Container for info background
     * @param parentContainer
     */
    createSecondScreenContainer(parentContainer) {
        const container = new Container();
        container.name = 'secondScreen';
        container.position.set(0, -this.gameFieldHeight);
        parentContainer.addChild(container);

        if (this.Loader.resources['secondScreenBg']) {
            const sprite = new Sprite(this.getTexture('secondScreenBg'));
            sprite.name = 'secondScreenBg';
            sprite.position.set(-(this.gameWidth - this.gameFieldWidth) / 2, 0);
            container.addChild(sprite);
        }

        this.createInfoContainer(parentContainer);
    }
}
