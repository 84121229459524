import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../../../index';
import GameEgt from './../../egt/game';
import Lines from './../../egt/lines100';
import GambleEgt from './../../egt/gamble';
import InfoScreen from '../../infoScreen';

// PIXI aliases
const Sprite = PIXI.Sprite,
    Text = PIXI.Text,
    AnimatedSprite = PIXI.AnimatedSprite,
    Container = PIXI.Container;

export default class BlueHeart extends GameEgt {
    constructor() {
        super();
        this.id = 'blue-heart';
        this.name = 'Blue Heart';
        this.buttonsPanelShadow = 'mid';
        this.reelXCoordinates = [62, 200, 338, 477, 614]; // magic numbers - x coordinates where reels start
        this.defaultFeatureDelay = 900; // ms for show win line
        this.winLineFeatureDelay = 600;
        this.doublingFilter = [11];

        // reel properties
        this.reelRows = 4; // number of rows per reel
        this.symbolHeight = 95; // height of aa single symbol
        this.transparentBackground = true; // use transparent symbols background or not
        this.reelFilter = [[11], [], [], [], [11]];

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 100, y: 110},
            bonusInBonusFrame: {x: 100, y: 110},
            endBonusFrame: {x: 100, y: 110}
        };

        this.symbols = [
            {regularDelay: 110, payment: [0, 0, 0, 5, 25, 100]},    // 0 - jolly
            {regularDelay: 110, payment: [0, 0, 0, 5, 25, 100]},    // 1 - queen
            {regularDelay: 110, payment: [0, 0, 0, 5, 50, 125]},    // 2 - king
            {regularDelay: 110, payment: [0, 0, 0, 5, 50, 125]},    // 3 - ace
            {regularDelay: 90, payment: [0, 0, 0, 10, 100, 200]},   // 4 - yellow
            {regularDelay: 90, payment: [0, 0, 0, 10, 100, 200]},   // 5 - pink
            {regularDelay: 90, payment: [0, 0, 0, 25, 150, 350]},   // 6 - green
            {regularDelay: 90, payment: [0, 0, 0, 25, 150, 400]},   // 7 - red cristal
            {regularDelay: 60, payment: [0, 0, 0, 50, 200, 600]},   // 8 - women
            {regularDelay: 60, payment: [0, 0, 0, 50, 200, 600]},   // 9 - men
            {regularDelay: 60, payment: [0, 0, 0, 100, 225, 1000]}, // 10 - blue heart
            {regularDelay: 60, payment: [0, 0, 0, 2, 0, 0]}         // 11 - shop with cristal
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                minimizeSymbols: 'minimizeSymbols.png',
                gambleArea: 'area/gamble.png',
                frame1: 'bonus/frame1.png',
                frame2: 'bonus/frame2.png',
                frame3: 'bonus/frame3.png',
                start: 'bonus/start.png'
            })
        };

        this.gameSounds = {
            soundClass: 'egt',
            sounds: [
                {name: 'scatterStop'},  // map stop sound
                {name: 'wait-start', alias: 'waitStart', loop: true},  // map stop sound
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'bonus-game-end', alias: 'bonusGameEnd'}
            ],
            path: `/game/games/${this.id}/audio/`
        };
        this.Lines = new Lines(this.mergePath({boxes: 'lines/boxes.png'}));
        this.Gamble = new GambleEgt();
        this.InfoScreen = new InfoScreen({pages: 4}); // number of game info states
        this.InfoScreen.format = 'png';
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 19pt Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = 'white';
        ctx.shadowColor = 'black';
        ctx.shadowOffsetX = ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 5;

        switch (page) {
            case 1:
                ctx.font = 'bold 12pt Arial';

                // jolly, queen
                this.strokeFillText(ctx, bet * this.symbols[3].payment[5], 193, 387);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[4], 193, 407);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[3], 193, 427);

                // king, ace
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 626, 390);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 626, 410);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 626, 430);

                // jolly, queen
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 200, 265);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 200, 285);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 200, 305);

                // yellow and pink cristall
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 605, 265);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 605, 285);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 605, 305);

                // green cristall
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 200, 265);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 200, 285);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 200, 305);

                // red cristal
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], 605, 168);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], 605, 188);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 605, 208);

                // men girl
                this.strokeFillText(ctx, bet * this.symbols[8].payment[5], 225, 165);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[4], 225, 188);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[3], 225, 208);

                // blue heart
                this.strokeFillText(ctx, bet * this.symbols[10].payment[5], 455, 125);
                this.strokeFillText(ctx, bet * this.symbols[10].payment[4], 455, 142);
                this.strokeFillText(ctx, bet * this.symbols[10].payment[3], 455, 159);

                // Grape
                this.strokeFillText(ctx, bet * nLines * this.symbols[11].payment[3], 455, 262);

                break;
            case 3:
                ctx.font = 'bold 14pt Arial';
                ctx.textAlign = 'left';
                // gamble limit
                this.strokeFillText(ctx, (this.Gamble.limit * 100) / App.Money.getCurrentDenomination(), 520, 229);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    getSymbolImageType = () => 'static';

    /**
     * Process reels response from server.
     * @param response - Socket response 'ROLL'
     */
    processReelResponse(response) {
        this.latestResponse = response;
        this.setState('RESPONSE_RECEIVED');
        this.prepareToRollAnimation(response);
    }

    startBonusAnimation = () => {
        this.clearPressAnyButton();
        this.bonusRoll();
    };

    /**
     * Отрисовка таблички окончания бонусной игры
     */
    showEndBonusFrame(parentContainer, {x, y}, {win, total}) {
        this.showBonusFrame(parentContainer, x, y, 'frame3');

        const richText = new Text(win, {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 60,
            fontWeight: 'bold',
            fill: '#dfdf0a',
            stroke: '#8f6628',
            strokeThickness: 5,
            lineJoin: 'round'
        });
        richText.name = 'wintext';
        richText.position.set(340, 320);
        parentContainer.addChild(richText);
    }

    /**
     * Отрисовка таблички окончания бонусной игры
     */
    showBonusInBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y, 'frame2');
        const sprite = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 2, fromFrame: 0, image: 'start',
            width: 409, height: 25, colCount: 2
        }));
        sprite.animationSpeed = 0.02;
        sprite.loop = false;
        sprite.position.set(190, 365);
        sprite.play();
        sprite.loop = true;
        parentContainer.addChild(sprite);
    }

    /**
     * Отрисовка таблички окончания бонусной игры
     */
    showStartBonusFrame(parentContainer, {x, y}) {
        App.Sounds.playSound('waitStart');
        this.showBonusFrame(parentContainer, x, y, 'frame1');
        const sprite = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 2, fromFrame: 0, image: 'start',
            width: 409, height: 25, colCount: 2
        }));
        sprite.animationSpeed = 0.02;
        sprite.loop = false;
        sprite.position.set(190, 365);
        sprite.play();
        sprite.loop = true;
        parentContainer.addChild(sprite);
    }

    setRegularShortSprite(clipMatrix, reelIndex, textures) {
        let scatter1 = 0;
        let scatter2 = 0;
        let scatter3 = 0;
        for (let reel = 0; reel <= reelIndex; reel++) {
            for (let rowIndex = 0; rowIndex < this.reelRows; rowIndex++) {
                if (reel === 1 && clipMatrix[reel][rowIndex + 1].symbol === 11) {
                    scatter1++;
                }
                if (reel === 2 && clipMatrix[reel][rowIndex + 1].symbol === 11) {
                    scatter2++;
                }
                if (reel === 3 && clipMatrix[reel][rowIndex + 1].symbol === 11) {
                    scatter3++;
                }
            }
        }
        if ((reelIndex === 1 && scatter1) || (reelIndex === 2 && scatter1 && scatter2) || (reelIndex === 3 && scatter1 && scatter2 && scatter3)) {
            App.Sounds.playSound('scatterStop');
        }
    }

    /**
     * Start scatter animations
     * @param features
     */
    bonusEntrance(features) {
        this.playBonusGameSound();
        this.stopAnimateFeature();
        this.drawTopAnimation(this.getStageChild('bonusContainer'));
        App.updateButton('start', {disabled: true});

        const scatterFeature = features.find(({uc}) => uc === 'SCATTER');
        this.createFeatureInfo(scatterFeature, this.getStageChild('linesContainer'));
        this.playScatterAnimation(scatterFeature, () => // call after scatter played
            this.drawBonusAskButton(this.isFreeRoll(features) && !this.bonusStatus));
    }

    createFeatureInfo(feature, container) {
        const {number, symbol, reels, positions, payment, uc} = feature, // get current feature params
            ucReels = uc === 'WIN_LINE' ? reels.length : positions.length, // check 'SCATTER' feature
            textProps = {
                font: 'Arial',
                fontSize: 14,
                fontWeight: 600,
                fill: '#f0e7cb'
            },
            winSymbolsContainer = new Container();
        winSymbolsContainer.position.set(-100, -170);
        winSymbolsContainer.zIndex = 200;
        winSymbolsContainer.name = 'winSymbolsContainer';
        container.addChild(winSymbolsContainer);
        const statusLine = new Text(
            `${uc === 'WIN_LINE' ? App.language.line.toUpperCase() : 'SCATTERED'} ${uc === 'WIN_LINE' ? number + 1 : ''}: `,
            textProps
        );
        const statusPayment = new Text(
            `: ${payment} `,
            textProps
        );
        statusLine.position.set(240, 470);
        statusPayment.position.set(500, 470);

        for (let i = 0; i <= ucReels - 1; i++) {
            const minimizeSymbol = new Sprite(this.getSpriteTextures({
                image: 'minimizeSymbols',
                fromFrame: symbol,
                colCount: this.symbols.length,
                width: 22,
                height: 19
            })[0]);
            minimizeSymbol.position.x = 450 + (22 * i);
            minimizeSymbol.position.y = 639;
            winSymbolsContainer.addChild(minimizeSymbol);
        }
        container.addChild(statusLine, statusPayment);
    }

    /**
     * Draw long scatter animation before bonus
     * @param scatterFeature
     * @param callback
     */
    playScatterAnimation(scatterFeature, callback) {
        this.setScatterSprite(scatterFeature);
        setTimeout(() => callback(), 8000);
    }

    setScatterSprite(scatterFeature) {
        scatterFeature.positions.forEach(position => {
            const {reel, row} = position;
            const symbolObj = this.reelMatrix[reel][row];
            symbolObj.loop = false;
            this.Roll.updateSymbolSprite(symbolObj);
            symbolObj.sprite.play();
        });
    }

    /**
     * Call after all book animation ended
     */
    bonusRoll() {
        App.Sounds.stopSound('banner-win');
        App.Sounds.stopSound('waitStart');
        const container = this.getStageChild('bonusContainer');
        container.removeChildren();
        this.showAdditionalBonusImage(container);

        App.Sounds.playSound('bonus-background');
        this.setState('IDLE_BONUS');
        this.startBonusRoll();
    }

    /**
     * Function to start logic of Animate feature first step
     * @param features
     */
    startAnimateFeature(features) {
        this.winLineFeatureDelay = 600;
        JL().debug('-- Start animate feature');
        this.Legends.showWinFeatures();
        App.Sounds.pauseSound('bonus-background');
        this.prepareToAnimateFeature(features);
        this.animateFeature(features);

        // change handler to stop animation win line
        !this.isBonus() && App.updateButton('start', {
            disabled: false, title: 'stop',
            handler: this.speedUpWinLineAnimation
        });
    }
}
