import {JL} from 'jsnlog';

import CustomLines from './../../CustomLines';

export default class Lines extends CustomLines {
    constructor() {
        super();
        this.imageResources = {};

        this.boxSettings = {
            ...this.boxSettings,
            firstRightBox: 10,
            lineBoxWidth: 0,
            lineBoxHeight: 0,
            horizontalLineLength: 50
        };

        this.winBoxes = false;

        this.lines = {
            0: {coordinates: [1, 1, 1, 1, 1], boxes: [{x: 70, y: 330}]},
            1: {coordinates: [0, 0, 0, 0, 0], boxes: [{x: 70, y: 170}]},
            2: {coordinates: [2, 2, 2, 2, 2], boxes: [{x: 70, y: 490}]},
            3: {coordinates: [0, 1, 2, 1, 0], boxes: [{x: 70, y: 150}]},
            4: {coordinates: [2, 1, 0, 1, 2], boxes: [{x: 70, y: 510}]},
            5: {coordinates: [1, 2, 2, 2, 1], boxes: [{x: 875, y: 310}]},
            6: {coordinates: [1, 0, 0, 0, 1], boxes: [{x: 875, y: 350}]},
            7: {coordinates: [2, 2, 1, 0, 0], boxes: [{x: 875, y: 220}]},
            8: {coordinates: [0, 0, 1, 2, 2], boxes: [{x: 875, y: 440}]},
            9: {coordinates: [2, 1, 1, 1, 0], boxes: [{x: 875, y: 130}]},
            10: {coordinates: [0, 1, 1, 1, 2], boxes: [{x: 875, y: 530}]},
            11: {coordinates: [1, 2, 1, 0, 1], boxes: [{x: 70, y: 270}]},
            12: {coordinates: [1, 0, 1, 2, 1], boxes: [{x: 70, y: 390}]},
            13: {coordinates: [0, 1, 0, 1, 0], boxes: [{x: 70, y: 140}]},
            14: {coordinates: [2, 1, 2, 1, 2], boxes: [{x: 70, y: 520}]},
            15: {coordinates: [1, 1, 0, 1, 1], boxes: [{x: 875, y: 340}]},
            16: {coordinates: [1, 1, 2, 1, 1], boxes: [{x: 875, y: 320}]},
            17: {coordinates: [0, 2, 0, 2, 0], boxes: [{x: 875, y: 200}]},
            18: {coordinates: [2, 0, 2, 0, 2], boxes: [{x: 70, y: 460}]},
            19: {coordinates: [2, 0, 1, 0, 2], boxes: [{x: 875, y: 498}]}
        };

        this.lineMap = [
            3, 13, 1, 11, 0, 12, 2, 14, 4, 18,
            9, 17, 7, 15, 5, 16, 6, 10, 8, 19
        ];

        this.linesColor = {};
        Object.keys(this.lines).forEach(index => this.linesColor[index] = 0x0f5532);

        JL().debug('-- Lines20 initialized');
    }

    drawBoxes() {

    }
}
