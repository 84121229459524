import React from 'react';

import './styles.less';
import App from './../../index';

export default function ControlButtons(props) {
    const {buttons} = props;
    const langButtonState = buttons.langBtn.disabled ? 'disabled' : '';

    const toggleBurger = () => {
        const {controlButtons} = App.View.state.buttons;
        App.updateButton('controlButtons', {disabled: !controlButtons.disabled});
    };

    return (
        <div
            className={`control-buttons ${buttons.visualization} ${buttons.animation} animation-${buttons.controlButtons.disabled ? 'show' : 'hide'}`}
            onTouchStart={event => event.stopPropagation()}
        >
            {App.System.platform === 'mobile' &&
            <button
                id='collapse'
                className={`burger animation-${buttons.controlButtons.disabled ? 'expand' : 'collapse'}`}
                onClick={toggleBurger}
            >
                <span/><span/><span/>
            </button>}

            {buttons.installPWA.visible &&
            <button
                id='installPWA-button'
                disabled={!buttons.installPWA.handler}
                onClick={buttons.installPWA.handler}
            >
                <div
                    className='bg-image'
                    style={{background: `url(${App.getUrl('/img/controls.png')}) 50% 97% / 300%`}}
                />
            </button>}

            {App.View.state.currentState === 'GAME' &&
            <button
                id='info-button'
                className={buttons.visualization}
                onClick={buttons.info.handler}
                disabled={buttons.info.disabled}>
                <div
                    className='bg-image'
                    style={{background: `url(${App.getUrl('/img/controls.png')}) 98% 66.7% / 300% no-repeat`}}
                />
            </button>}

            {buttons.message.visible &&
            <button
                id='message-button'
                onClick={buttons.message.handler}
                disabled={buttons.message.disabled}>
                <div
                    className='bg-image'
                    style={{background: `url(${App.getUrl('/img/controls.png')}) 100% 100% / 300%`}}
                />

                {App.User.isMessageWarning() && <div
                    className='warning'
                    style={{backgroundImage: `url(${App.getUrl('/img/registration/mailWarning.png')})`}}>
                    {App.User.getMessageWarning()}
                </div>}
            </button>}

            {buttons.fullScreen.visible && App.configs.mode !== 'info' &&
            <button id='full-screen-button' onClick={buttons.fullScreen.handler}>
                <div
                    className='bg-image'
                    style={{
                        background: `url(${App.getUrl('/img/controls.png')})`,
                        backgroundPosition: buttons.fullScreen.img,
                        backgroundSize: '300%'
                    }}
                />
            </button>}

            {buttons.soundVolume.visible && App.configs.mode !== 'info' &&
            <button
                id='sound-container'
                onClick={buttons.soundVolume.handler}
                disabled={buttons.soundVolume.disabled}
            >
                <div
                    className={`bg-image ${buttons.soundVolume.soundSpinner}`}
                    style={{background: `${buttons.soundVolume.imgSrc}`}}
                />
                <div className='range-container' onClick={event => event.stopPropagation()}>
                    <div className='range'>
                        <input
                            type='range' id='range' min='0' max='1' step='.05'
                            onChange={event => App.soundChange(event.target.value, false)}
                            value={buttons.soundVolume.defaultVolume}/>
                    </div>
                </div>
            </button>}

            {buttons.langBtn.visible && App.configs.mode !== 'info' &&
            <div id='language-button' className={langButtonState}>
                <div
                    className='current'
                    onClick={App.changeLanguageMobile}
                    style={{backgroundImage: `url(${App.getUrl(`/img/languages/${App.settings.currentLanguage}.svg`)})`}}
                />
                <div className='languages-list'>
                    {Object.keys(App.languageCollection).map((lang, index) =>
                        <button
                            key={index} onClick={() => App.setLang(lang)}
                            style={{backgroundImage: `url(${App.getUrl(`/img/languages/${lang}.svg`)})`}}>
                        </button>
                    )}
                </div>
            </div>}

            {buttons.close.visible && App.configs.mode !== 'info' &&
            <button
                id='close-button'
                onClick={buttons.close.handler}
                disabled={buttons.close.disabled}>
                <div
                    className='bg-image'
                    style={{background: `url(${App.getUrl('/img/controls.png')}) 1% 66.7% / 300%`}}
                />
            </button>}

            {buttons.user.visible &&
            <button
                id='user-button'
                onClick={buttons.user.handler}
                disabled={buttons.user.disabled}>
                <div
                    className='bg-image'
                    style={{background: `url(${App.getUrl('/img/controls.png')}) 1% 100% / 300%`}}
                />
                {(App.User.phoneWarning() === 'warning' || App.User.emailWarning() === 'warning') && <div
                    className='warning'
                    style={{backgroundImage: `url(${App.getUrl('/img/registration/warning.png')})`}}/>}

                {(App.User.phoneWarning() === 'confirmation' || App.User.emailWarning() === 'confirmation') && App.User.phoneWarning() !== 'warning' && App.User.emailWarning() !== 'warning' && <div
                    className='warning'
                    style={{backgroundImage: `url(${App.getUrl('/img/registration/confirmation.png')})`}}/>}
            </button>}
        </div>
    );
}
