import React from 'react';
import './styles.less';

import App from './../../index';

export default function ErrorMessage(props) {
    const {lang, error: {message, timer, iFrame, icon}} = props;

    return (
        <div className='error red-shadow red-color'>
            {icon && <img
                src={App.getUrl(`/img/${icon}.png`)}
                className='error-image' alt='' draggable={false}/>
            }
            <div className='error-text'>{lang[message] || message}</div>
            {timer && <div className='timer'>{`${lang.exitAfter}: ${timer}`}</div>}
            {iFrame && <iframe src={iFrame}/>}
        </div>
    );
}
