import React from 'react';

import './styles.less';

export default function Logo(props) {
    const {mode, name} = props;
    const nameParts = name.toUpperCase().split(' ');

    return mode !== 'cosmolot' ? ( // remove logo for 'cosmolot'
        <div className='logo-company'>
            <div className='logo-company-subtitle'>
                {nameParts[1].split('').map((letter, index) =>
                    <span key={index}>{letter}</span>)}
            </div>
            <div className='logo-company-title'>
                <div>{nameParts[0]}</div>
            </div>
        </div>
    ) : <></>; // paste empty element
}
