module.exports = {
    // socket
    connectionServer: 'Σύνδεση με το διακομιστή',
    initializingGame: 'Φόρτωση παιχνιδιού',
    initializingMenu: 'Μενού φόρτωσης',
    loadingImages: 'Φόρτωση εικόνων',
    reload: 'Φορτώνω πάλι',
    redirect: 'Ανακατευθύνετε',
    exitAndReload: 'Βγείτε από το μενού και φορτώστε ξανά τη σελίδα',
    exit: 'Έξοδο',
    exitAfter: 'Έξοδος από το σύστημα σε',
    serverNotResponded: 'Ο διακομιστής δεν αποκρίνεται',
    pluginNotResponded: 'Καμία σύνδεση με τον ελεγκτή',
    codeAlreadyConnected: 'Το πρόγραμμα αναπαραγωγής με αυτόν τον κωδικό είναι ήδη συνδεδεμένο',
    connectionLost: 'Η σύνδεση χάνεται',
    gameNotFound: 'Το παιχνίδι δεν βρέθηκε',
    codeNotFound: 'Ο κώδικας δεν βρέθηκε',
    cardNotFound: 'Η κάρτα δεν βρέθηκε',
    terminalNotFound: 'Ο κωδικός τερματικού δεν βρέθηκε ή το τερματικό δεν είναι διαθέσιμο',

    // login
    login: 'ΣΥΝΔΕΘΕΙΤΕ',
    loginCredentials: 'Ονομα χρήστη, e-mail ή κωδικός',
    codeCredentials: 'Κωδικός πρόσβασης λογαριασμού',
    username: 'όνομα χρήστη',
    enterCode: 'Εισάγετε τον κωδικό',
    password: 'Κωδικός πρόσβασης',
    email: 'e-mail',
    signIn: 'Συνδεθείτε',
    signUp: 'Εγγραφή',
    registration: 'Εγγραφή',
    oneClick: 'Ένα κλικ',
    demo: 'Demo',
    createAccount: 'Δημιουργία λογαριασμού',
    logout: 'Αποσύνδεση',
    restorePasswordWithEmail: 'Εισαγάγετε email για επαναφορά κωδικού πρόσβασης',
    sendNewPassword: 'Αποστολή νέου κωδικού πρόσβασης',
    restorePassword: 'Επαναφορά κωδικού πρόσβασης',
    restoringPassword: 'Κωδικός πρόσβασης επαναφέρω',
    successRegistration: 'Επιτυχής εγγραφή',
    incorrectUsername: 'Εσφαλμένο όνομα χρήστη ή κωδικός πρόσβασης',
    notUniqueName: 'Το όνομα πρέπει να είναι μοναδικό',
    notUniqueEmail: 'Το email πρέπει να είναι μοναδικό',
    notUniquePhone: 'Ο αριθμός τηλεφώνου πρέπει να είναι μοναδικός',
    missedField: 'Δεν υπάρχει υποχρεωτικό πεδίο',
    wrongOldPassword: 'Ο παλιός κωδικός πρόσβασης δεν καταχωρήθηκε σωστά',
    wrongPassword: 'Ο κωδικός πρόσβασης δεν καταχωρίστηκε σωστά',
    emailChanged: 'Η επιβεβαίωση έχει σταλεί στο email σας',
    passwordResetError: 'Λανθασμένη σύνδεση ή email',
    newPasswordSent: 'Νέος κωδικός πρόσβασης έχει σταλεί στο email σας',
    invalidEmail: 'Ακυρη διεύθυνση ηλεκτρονικού ταχυδρομείου',
    invalidPhone: 'Λάθος αριθμός τηλεφώνου \n ' +
        '+38 YYY XXX XX XX',
    enterPassword: 'Εισάγετε τον κωδικό πρόσβασης',
    differentPasswords: 'Αναντιστοιχία κωδικού πρόσβασης',
    tooLongPassword: 'Ο κωδικός πρόσβασης είναι πολύ μεγάλος (μέγ. Χαρακτήρες: 20)',
    tooShortPassword: 'Πολύ σύντομος κωδικός πρόσβασης (ελάχιστοι χαρακτήρες: 8)',
    passwordChanged: 'Ο κωδικός άλλαξε',
    enterOldPassword: 'Εισαγάγετε τον παλιό κωδικό πρόσβασης',
    enterNewPassword: 'Eισάγετε το νέο κωδικό πρόσβασης',
    samePasswords: 'Ο παλιός και νέος κωδικός δεν μπορεί να είναι πανομοιότυπος',
    copied: 'Αντιγράφηκε',
    copy: 'αντίγραφο',

    // profile
    save: 'Εξοικονομήστε',
    confirmation: 'Επιβεβαιώστε',
    saveAs: 'Αποθήκευση ως',
    cancel: 'Ματαίωση',
    changingEmail: 'Αλλαγή email',
    changeEmail: 'Αλλαγή email',
    changingPhone: 'Αλλαγή phone',
    changePhone: 'Αλλαγή phone',
    changingPassword: 'αλλαγή κωδικού πρόσβασης',
    changePassword: 'Αλλαξε κωδικό',
    confirmCode: 'κωδικός επιβεβαίωσης',
    phoneConfirmed: 'Το τηλέφωνο επιβεβαιώθηκε',
    oldPassword: 'Παλιός κωδικός πρόσβασης',
    newPassword: 'Νέος Κωδικός',
    confirmPassword: 'Επιβεβαίωση Κωδικού',
    connectWithUs: 'Συνδεθείτε μαζί μας',
    profile: 'Προφίλ',
    deposit: 'Kατάθεση',
    withdraw: 'Αποσύρω',
    support: 'Υποστήριξη',
    date: 'Ημερομηνία',
    type: 'Τύπος',
    amount: 'Ποσό',
    status: 'Κατάσταση',
    transactionsHistory: 'Ιστορικό συναλλαγών',
    exchange: 'Ανταλλαγή συναλλάγματος',
    noData: 'χωρίς δεδομένα',
    transactionsBonus: 'Ιστορικό μηνυμάτων',
    message: 'μήνυμα',
    ok: 'Ok',

    currencySelect: 'Νόμισμα',
    paymentSelect: 'Μέθοδος πληρωμής',
    amountDepositSelect: 'Ποσό κατάθεσης',
    finishOperation: 'Ολοκληρώστε τη λειτουργία',

    enterCurrency: 'Επιλέξτε νόμισμα',
    enterCardNumber: 'Εισάγετε τον αριθμό κάρτας',
    cardNumber: 'Αριθμός κάρτας',
    paymentAccept: 'Επιβεβαίωση πληρωμής',
    payoutAmount: 'Ποσό ανάληψης',
    paymentType: 'Μέθοδος πληρωμής',
    enterDepositWay: 'Επιλέξτε έναν τρόπο πληρωμής',
    enterDepositAmount: 'Εισαγάγετε το ποσό για ανάληψη',
    enterWithdrawalWay: 'Επιλέξτε μέθοδο ανάληψης',
    enterPayoutAmount: 'Εισαγάγετε το ποσό ανάληψης',
    successfulPayment: 'Η πληρωμή προστέθηκε με επιτυχία για επεξεργασία',
    successOperation: 'Επιτυχής πληρωμή',
    errorOperation: 'Σφάλμα πληρωμής',
    confirm: 'Επιβεβαιώνω',
    country: 'Χώρα',
    currency: 'Νόμισμα',
    invalidCountry: 'Μη έγκυρη χώρα',
    invalidCurrency: 'Μη έγκυρο νόμισμα',
    successfullyChangedTo: 'Η επιτυχία άλλαξε σε',
    lowBalance: 'Δεν υπάρχουν αρκετά χρήματα στο υπόλοιπο',

    accept: 'Αποδέχομαι',
    decline: 'Πτώση',
    currencyExchange: 'Ανταλλαγή συναλλάγματος',
    currentBalance: 'Τωρινή ισσοροπία',
    from: 'από',
    to: 'προς το',
    exchangePercent: 'ανταλλαγή τοις εκατό',
    newBalance: 'νέα ισορροπία',
    coefficient: 'Λειτουργία',
    currencyConversion: 'Conversion μετατροπή νομισμάτων',
    wrongInputData: 'Λάθος δεδομένα εισαγωγής',
    wrongAmountData: 'Λάθος ποσό',
    wrongCreditCard: 'Λάθος αριθμός πιστωτικής κάρτας',
    noRedirectReff: 'Δεν υπάρχει αναφορά στην ανακατεύθυνση',
    deserializationIssue: 'Πρόβλημα με την αποεστεροποίηση',
    currencyPairNotExist: 'Το ζεύγος νομισμάτων δεν υπάρχει',
    authorizationMandatory: 'Η εξουσιοδότηση είναι υποχρεωτική',
    birthdayFormat: 'Τα γενέθλια πρέπει να είναι DD MM YYYY',
    tooShort: 'Λάθος αριθμός κάρτας',
    infoPayment: 'Η επεξεργασία της πρώτης πληρωμής σε 24 ώρες είναι δωρεάν. Θα χρεωθεί προμήθεια για κάθε επόμενη πληρωμή.',
    time: 'χρόνος',
    period: 'Έως 3 ημέρες',
    image: 'Εικόνα',
    text: 'Κείμενο',

    // status
    balance: 'Ισορροπία',
    insurance: 'ΑΣΦΑΛΙΣΗ',
    credits: 'Πιστώσεις',
    creditsWon: 'κέρδισε πιστώσεις',
    winnerPaid: 'Νικητής που πληρώνεται',
    gotNoMoney: 'Δεν υπάρχουν αρκετά χρήματα',
    minBet: 'Ελάχιστο συνολικό στοίχημα',
    gambleUp: 'Στοιχηματίστε ή κερδίστε',
    gambleUp5x: 'Στοιχηματίστε έως και 5 φορές ή κερδίστε',
    gambleEnd: 'Η προσπάθεια ολοκληρώθηκε',
    pressAnyButton: 'Πατήστε έναρξη για να ξεκινήσετε',
    freeGame: 'Δωρεάν παιχνίδι',
    of: 'από',
    freeSpinsRemaining: 'ΔΩΡΕΑΝ ΠΕΡΙΣΤΡΟΦΕΙΣ ΠΟΥ ΑΠΟΜΈΝΕΙ ',
    freeGames: 'δωρεάν παιχνίδια',
    more: 'πρόσθετος ',
    freeSpinsLeft: 'ΔΩΡΕΑΝ ΠΕΡΙΣΤΡΟΦΕΙΣ ΑΡΙΣΤΕΡΑ ',
    additionalRoll: 'Απάντηση',
    selectCard: 'Επιλέξτε την κάρτα σας',
    selectColor: 'Επιλογή κόκκινου ή μαύρου',
    selectColorOrSuit: 'Επιλέξτε χρώμα (x2) ή κοστούμι (x4)',
    gameOverPlaceYourBet: 'Παρακαλώ τοποθετήστε ένα στοίχημα',
    auto: 'Αυτόματη',
    linePays: 'Γραμμή',
    scatterPays: 'Διασποράπληρώνει',
    win: 'Νίκη',
    line: 'Γραμμή',
    youWon: 'Κέρδισες.',
    lowMinBet: 'Ελάχιστο στοίχημα σε ',
    highMaxBet: 'Μέγιστο στοίχημα ',
    unSupport: 'Αυτό το παιχνίδι δεν υποστηρίζεται στη συσκευή σας',
    urlNotFound: 'Η σελίδα δεν βρέθηκε',
    categories: 'Κατηγορίες',
    chooseSide: 'Επιλέξτε μια πλευρά',
    makeChoice: 'Επιλέγω',
    multiplier: 'Πολλαπλασιαστής',

    // buttons
    lines: 'Γραμμές',
    bet: 'Στοίχημα',
    total: 'Σύνολο',
    denom: 'Denom',
    gamble: 'Κίνδυνος',
    paytable: 'Πληροφορίες',
    bonus: 'Δώρο',
    start: 'Αρχή',
    stop: 'σταματήσει',
    autoplay: 'Αυτομάτως',
    select: 'Επιλέγω',
    collect: 'Συλλέγω',
    red: 'Κόκκινο',
    black: 'Μαύρος',
    return: 'ΕΠΙΣΤΡΟΦΗ',
    half: 'τα μισα',
    payout: 'Πληρωμή',
    swipeToStart: 'Σύρετε προς τα πάνω για να ξεκινήσετε',
    tapToStart: 'Πατήστε για να ξεκινήσετε',

    // roulette buttons
    repeatAndStart: 'Επανάληψη στοιχήματος και Έναρξη',
    double: 'Διπλό',
    doubleAndSpin: 'Διπλό και περιστροφή',
    undo: 'Ματαίωση',
    repeatBet: 'Επαναλάβετε την προσφορά',
    removeBets: 'Κατάργηση στοιχημάτων',
    results: 'Αποτελέσματα',
    cover: 'Κάλυμμα',

    // gamble
    gambleAmount: 'Ποσό τυχερού παιχνιδιού',
    gambleToWin: 'Στοίχημα για να κερδίσει',
    gambleAmountAmatic: 'Ποσό τυχερού παιχνιδιού',
    gambleToWinAmatic: 'Κόκκινο / Μαύρο Στοίχημα για να κερδίσει',
    suitGambleToWinAmatic: 'Κοστούμι στοίχημα για να κερδίσει',
    previousCards: 'Προηγούμενες κάρτες',
    gambleAttemptsLeft: 'Προσπάθειες ρίσκου αριστερά',
    history: 'Ιστορία',

    // wrap
    selectLine: 'Αριθμός γραμμές',
    selectBet: 'Στοίχημα ανά γραμμές',
    selectDenomination: 'Ονομασία',

    // info
    rules: 'Κανόνες',
    substitutesForAllSymbols: 'Υποκατάστατα για όλα τα σύμβολα εκτός από τα scatter',
    combinationOfKind: 'Όλα τα βραβεία είναι για συνδυασμούς του είδους. ',
    combinationLeftToRight: 'Όλα τα έπαθλα είναι για συνδυασμούς από αριστερά προς τα δεξιά, εκτός από τους σκορ. ',
    prizesOnSelectedLines: 'Όλα τα βραβεία είναι σε επιλεγμένες γραμμές, εκτός από τα scatter. ',
    ScatterPayAtAnyPosition: 'Τα σύμβολα διασποράς πληρώνουν σε οποιαδήποτε θέση στην οθόνη. ',
    HighestWinPaid: 'Η υψηλότερη νίκη πληρώνεται μόνο ανά επιλεγμένη γραμμή. ',
    ScatterWinsAddedToLineWins: 'Τα κέρδη Scatter προστέθηκαν στις νίκες γραμμής. ',
    prizesShownInCredits: 'Η υψηλότερη νίκη πληρώνεται μόνο ανά επιλεγμένη γραμμή. ',
    MalfunctionVoidsAllPays: 'Η δυσλειτουργία ακυρώνει όλες τις πληρωμές και τα παιχνίδια. ',
    onTheScreen: 'δώσει το έναυσμα για',
    bonusGame: 'ΠΑΙΧΝΙΔΙ ΜΠΟΝΟΥΣ',
    bonusGameGive: 'Δίνει με ρουλέτα',
    freeSpins: 'ΔΩΡΕΑΝ ΓΥΝΑΙΚΕΣ',
    featureBonus: 'Στο παιχνίδι μπόνους ο πρώτος κύλινδρος θα παγώσει και θα γεμίσει από',
    goodLuck: 'Καλή τύχη!',

    // instruction IOS
    howToInstall: 'πώς να εγκαταστήσετε την εφαρμογή',
    pressTheButton: 'Κάντε κλικ στο κουμπί κοινής χρήσης',
    goToHomeScreen: 'Κάντε κλικ ',
    confirmInstallation: 'Επιβεβαίωση εγκατάστασης',

    // bonuses
    congratulation: 'Συγχαρητήρια',
    registration_bonus: {
        title: 'για επιβεβαίωση τηλεφώνου!',
        description: {
            punkt1: '- Για να ενεργοποιήσετε το μπόνους, πρέπει να επιβεβαιώσετε το email και τον αριθμό τηλεφώνου σας.',
            punkt2: '- Κάντε μια κατάθεση στον κύριο λογαριασμό.',
            punkt3: '- Για να πιστώσετε το μπόνους στον κύριο λογαριασμό, πρέπει να τοποθετήσετε στοιχήματα στο ποσό 15 φορές περισσότερο από το άθροισμα του μπόνους.'
        },
        accept: 'Το μπόνους σας για εγγραφή κατατέθηκε!'
    },

    week_bonus: {
        title: 'Εβδομαδιαίο μπόνους!',
        description: 'εβδομαδιαίος',
        accept: 'Το εβδομαδιαίο μπόνους σας κατατέθηκε!'
    }
};
