import React from 'react';

import './styles.less';
import App from './../../index';

export default function MobileButtons(props) {
    const {lang, buttons} = props;
    const pressedButtons = {
        lines: buttons.lines.pressed ? 'active' : '',
        bet: buttons.bet.pressed ? 'active' : '',
        denomination: buttons.denomination.pressed ? 'active' : '',
        autoStart: buttons.autoStart.pressed ? 'active-button-mobile' : ''
    };

    const buttonsPanelShadow = App.Game.buttonsPanelShadow ?
        `shadow-${App.Game.buttonsPanelShadow}` : '';

    const homeBackground = {
        select: `url(${App.getUrl('/img/mobile/home.png')})`,
        collect: `url(${App.getUrl('/img/mobile/collect.png')})`
    };

    return (
        <div className={`mobile-buttons ${buttons.animation}`}>
            <div className={`mobile-buttons-left ${buttonsPanelShadow}`}>
                {buttons.info.title === 'paytable' ?
                    <button
                        className={`btn-info ${buttons.info.additionalClass} ${buttons.info.title} icon-styles`}
                        onClick={buttons.info.handler}
                        disabled={buttons.info.disabled}
                        style={{backgroundImage: `url(${App.getUrl('/img/mobile/info.png')})`}}
                    >
                        <span>{lang[buttons.info.title]}</span>
                    </button> :
                    <button
                        className={`btn-info ${buttons.info.additionalClass} ${buttons.info.title}`}
                        onClick={buttons.info.handler}
                        disabled={buttons.info.disabled}
                    >
                        <span>{lang[buttons.info.title]}</span>
                    </button>}
                <button
                    className={`btn-settings parameters icon-styles ${buttons.gameSettings.status ? 'active-button-mobile' : ''}`}
                    disabled={buttons.gameSettings.disabled}
                    onClick={buttons.gameSettings.handler}
                    style={{backgroundImage: `url(${App.getUrl('/img/mobile/settings.png')})`}}
                >
                </button>
            </div>
            <div className={`mobile-buttons-right ${buttonsPanelShadow}`}>
                <button
                    className={`btn-choice ${buttons.select.additionalClass} btn-${buttons.select.title} icon-styles`}
                    onClick={buttons.select.handler}
                    disabled={buttons.select.disabled}
                    style={{backgroundImage: homeBackground[buttons.select.title]}}
                >
                    {!homeBackground[buttons.select.title] && <span>{lang[buttons.select.title]}</span>}
                </button>

                <button
                    className={`btn-start ${buttons.start.title} ${pressedButtons.autoStart && `no-transition`} icon-styles`}
                    onClick={buttons.start.handler}
                    disabled={buttons.start.disabled}
                    style={{backgroundImage: `url(${App.getUrl(`/img/mobile/${buttons.start.title}.png`)})`}}
                >
                </button>
                <button
                    className={`btn-autogame ${pressedButtons.autoStart} ${buttons.autoStart.title} icon-styles`}
                    onClick={buttons.autoStart.handler}
                    disabled={buttons.autoStart.disabled}
                    style={{backgroundImage: `url(${App.getUrl(`/img/mobile/auto-start.png`)})`}}
                >
                </button>
            </div>
        </div>
    );
}
