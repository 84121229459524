import React from 'react';

import App from './../../index';

export default function Message({profile, lang}) {
    const {bonus} = profile;
    // reset history and request first page
    const refresh = () => {
        App.updateState('profile', {bonus: null});
        App.User.updateMessage();
    };

    // convert server UTC time to local
    const timeZoneOffset = new Date().getTimezoneOffset() / 60; // in hours
    const getLocalTime = serverTime => {
        const [date, time] = serverTime.split(' ');
        const [day, month, year] = date.split('-');
        const [hour, minute, second] = time.split(':');
        const localTime = new Date(`${month}/${day}/${year} ${hour}:${minute}:${second} UTC`);
        localTime.setHours(localTime.getHours() - timeZoneOffset);

        return localTime.toISOString().slice(0, -5).split('T').join(' ');
    };

    const checkScrollPos = ({target}) => {
        const {scrollHeight, offsetHeight, scrollTop} = target;
        if (offsetHeight + scrollTop >= scrollHeight && App.mesPageLimit <= App.User.get().bonus.currentPage + 1) {
            App.User.updateMessage(App.User.get().bonus.currentPage + 1);
        }
    };
    return (
        <div className='history'>
            <h2>{lang.transactionsBonus}</h2>
            <button className='refresh blue-button' onClick={refresh}>
                <span>&#x21bb;</span>
            </button>

            {!bonus ?
                <div className='history-waiting'>
                    <img src={'./../img/loader.svg'} alt=''/>
                </div> :
                bonus.items.length ?
                    <div className='table-bonus-wrapper' onScroll={(event) => checkScrollPos(event)}>
                        <table cellSpacing='0' cellPadding='0'>
                            <thead>
                                <tr>
                                    <th className='blue-shadow blue-color' width='10px'>{'Date'}</th>
                                    <th className='blue-shadow blue-color' width='30px'>{'Message'}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bonus.items.map(({_id, created, status, subject, text}, index) =>
                                    <tr key={index}>
                                        <td> {created}
                                        </td>
                                        <td onClick={ function() { App.Modal.showMessage(text, _id, status); }}>
                                            {subject}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div> : <div className='history-waiting'>
                        <span className='no-data'>{lang.noData}</span>
                    </div>}
        </div>
    );
}
