import React from 'react';

import './styles.less';
import App from './../../index';
import Wrap from './../wrap';

export default function Buttons(props) {
    const {lang, moneyParams: {credits, money, currency, insurance, bonus, bonusTotal}, buttons, legends, wrap} = props;
    const styles = {
        buttonsPanelShadow: App.Game.buttonsPanelShadow ? `shadow-${App.Game.buttonsPanelShadow}` : '',
        oneDenom: App.Game.getDenominations().length === 1 ? 'oneDenom' : '',
        lines: buttons.lines.pressed ? 'active' : '',
        bet: buttons.bet.pressed ? 'active' : '',
        denomination: buttons.denomination.pressed ? 'active' : '',
        autoStart: buttons.autoStart.pressed ? 'active-auto-start' : '',
        double: buttons.double.disabled ? 'hidden' : '',
        start: buttons.start.disabled ? 'hidden' : '',
        cancel: buttons.cancel.disabled ? 'hidden' : '',
        clear: buttons.clear.disabled ? 'hidden' : ''
    };

    const rouletteButtonSprite = {
        backgroundImage: `url(${App.getUrl('/game/games/european-roulette/img/button.png')})`,
        backgroundSize: '13em'
    };

    const onAnimationEnd = ({animationName}) =>
        animationName === 'buttons-show-panel' && App.updateState('buttons', {animation: ''});

    const visualization = {
        base: additional =>
            <div
                className={`buttons ${buttons.visualization} ${buttons.animation} ${styles.oneDenom} ${styles.buttonsPanelShadow}`}
                onAnimationEnd={onAnimationEnd}
            >
                {App.System.platform !== 'mobile' && wrap.active && <Wrap lang={lang} wrap={wrap}/>}
                {App.System.platform !== 'mobile' &&
                <div className='score'>
                    <div className='title blue-shadow blue-color'>{lang.balance.toUpperCase()}</div>
                    <div className='credits red-shadow red-color'>{credits}</div>
                    <div className='currency red-shadow red-color'>{money.toFixed(2)} {currency}</div>

                    {App.optionsPage === 1 && <div className='bonus'>
                        <div className='title blue-shadow blue-color'>{lang.bonus}</div>
                        <div className='credits red-shadow red-color'>{bonus}</div>
                        <div className='wayger red-shadow red-color'> {bonusTotal}</div>
                    </div>}

                    {App.optionsPage === 0 && <div className='insurance'>
                        <div className='title blue-shadow blue-color'>{lang.insurance}</div>
                        <div className='credits red-shadow red-color'>{insurance}</div>
                    </div>}
                </div>}

                {App.System.platform === 'mobile' &&
                <div className='score'>
                    <div className='title blue-shadow blue-color'>{lang.balance.toUpperCase()}</div>
                    <div className='credits red-shadow red-color'>{credits}</div>
                    <div className='currency red-shadow red-color'>{money.toFixed(2)} {currency}</div>
                </div>}

                {App.System.platform === 'mobile' &&
                <div className='score-bonus'>
                    {App.optionsPage === 1 && <div className='bonus'>
                        <div className='title blue-shadow blue-color'>{lang.bonus}</div>
                        <div className='credits red-shadow red-color'>{bonus}</div>
                        <div className='wayger red-shadow red-color'> {bonusTotal}</div>
                    </div>}

                    {App.optionsPage === 0 && <div className='insurance'>
                        <div className='title blue-shadow blue-color'>{lang.insurance}</div>
                        <div className='credits red-shadow red-color'>{insurance}</div>
                    </div>}
                </div>}

                {App.configs.mode === 'login' && <button id='options-button' onClick={buttons.options.handler}>
                    <div
                        className='bg-image'
                        style={{background: `url(${App.getUrl('/img/controls.png')}) 100% 0% / 300%`}}
                    />
                </button>}
                <div className='status-bar'>
                    <div
                        className='status-text red-color red-shadow'>{App.Game.Legends.getStatusText(legends.status)}</div>
                    <div className='status-bar-settings'>
                        <button
                            className={`lines disabled-styles ${styles.lines}`}
                            onClick={event => {
                                App.Sounds.playSound('click');
                                App.Game.Buttons.toggleWrap(event, 'lines');
                            }}
                            disabled={buttons.lines.disabled}
                        >
                            <span className='title blue-shadow blue-color'>{lang[buttons.lines.title]}</span>
                            <span className='value red-shadow red-color'>{buttons.lines.value || 0}</span>
                        </button>
                        <button
                            className={`bet disabled-styles ${styles.bet}`}
                            onClick={event => {
                                App.Sounds.playSound('click');
                                App.Game.Buttons.toggleWrap(event, 'bet');
                            }}
                            disabled={buttons.bet.disabled}
                        >
                            <span className='title blue-shadow blue-color'>{lang[buttons.bet.title]}</span>
                            <span className='value red-shadow red-color'>{buttons.bet.value || 0}</span>
                        </button>
                        <button className='total disabled-styles' disabled={buttons.total.disabled}>
                            <span className='title blue-shadow blue-color'>{lang[buttons.total.title]}</span>
                            <span className='value red-shadow red-color'>{buttons.total.value || 0}</span>
                            <span className='currency red-shadow red-color'>
                                {(buttons.total.value * App.Money.getCurrentDenomination() / 100).toFixed(2)} {currency}
                            </span>
                        </button>
                        <button
                            className={`denom disabled-styles ${styles.denomination}`}
                            onClick={event => {
                                App.Sounds.playSound('click');
                                App.Game.Buttons.toggleWrap(event, 'denomination');
                            }}
                            disabled={buttons.denomination.disabled}
                        >
                            <span
                                className='title blue-shadow blue-color disabled-styles'>{lang[buttons.denomination.title]}</span>
                            <span
                                className='value red-shadow red-color disabled-styles'>{buttons.denomination.value || 0}</span>
                        </button>
                    </div>
                </div>
                <div className='buttons-container'>
                    <button
                        className={`btn-choice red-shadow red-color disabled-styles ${buttons.select.additionalClass}`}
                        onClick={buttons.select.handler}
                        disabled={buttons.select.disabled}
                    >
                        {lang[buttons.select.title]}
                        <span>{buttons.select.subTitle}</span>
                    </button>
                    <button
                        className={`btn-info red-shadow red-color disabled-styles ${buttons.info.additionalClass}`}
                        onClick={buttons.info.handler}
                        disabled={buttons.info.disabled}
                    >{lang[buttons.info.title]}</button>
                    <div className='buttons-container-play'>
                        <button
                            className={`btn-start red-shadow red-color disabled-styles ${buttons.start.title}`}
                            disabled={buttons.start.disabled}
                            onClick={buttons.start.handler}>
                            <span>{lang[buttons.start.title]}</span>
                        </button>
                        <button
                            className={`btn-autogame red-shadow red-color disabled-styles ${styles.autoStart}`}
                            onClick={buttons.autoStart.handler}
                            disabled={buttons.autoStart.disabled}
                        >{lang[buttons.autoStart.title]}</button>
                    </div>
                </div>
                <div className='info-screen-buttons'>
                    <button
                        className='close red-shadow red-color'
                        onClick={buttons.closeInfo.handler}
                        disabled={buttons.closeInfo.disabled}
                    >
                        <div
                            className='closeInfo-img'
                            style={{background: `url(${App.getUrl('/img/controls.png) 50% 67% / 300% no-repeat')}`}}
                        />
                    </button>
                    {App.Game.InfoScreen.pages !== 1 && <div className='left-right'>
                        <button
                            className='prevBtnInfo'
                            style={{background: `url(${App.getUrl('/img/Menu/navigation-button.png) center center / contain no-repeat')}`}}
                            onClick={buttons.prevInfo.handler}
                            disabled={buttons.prevInfo.disabled}
                        >
                        </button>
                        <button
                            className='nextBtnInfo'
                            style={{background: `url(${App.getUrl('/img/Menu/navigation-button.png) center center / contain no-repeat')}`}}
                            onClick={buttons.nextInfo.handler}
                            disabled={buttons.nextInfo.disabled}
                        >
                        </button>
                    </div>}
                </div>
                {additional}
            </div>,

        roulette: () => visualization.base( // use game wrapper
            <div
                className='roulette-buttons'
                style={{background: `url(${App.getUrl('/game/games/european-roulette/img/buttons-background.png')}) 0 0 /100% no-repeat`}}
            >
                <div
                    className='background'
                    style={{background: `url(${App.getUrl(`/game/games/european-roulette/img/settings-background${styles.oneDenom}.png`)}) 0 0 /100% no-repeat`}}
                >
                </div>
                <button
                    className={`btn-remove ${styles.clear}`}
                    onClick={buttons.clear.handler}
                    style={rouletteButtonSprite}
                >
                    <div
                        className='background'
                        style={{backgroundImage: `url(${App.getUrl('/game/games/european-roulette/img/remove.png')})`}}/>
                    <div className='title'>{lang[buttons.clear.title]}</div>
                </button>

                <button
                    className={`btn-undo ${styles.cancel} ${App.settings.currentLanguage}`}
                    onClick={buttons.cancel.handler}
                    style={rouletteButtonSprite}
                >
                    <div
                        className='background'
                        style={{backgroundImage: `url(${App.getUrl('/game/games/european-roulette/img/undo.png')})`}}/>
                    <div className='title'>{lang[buttons.cancel.title]}</div>
                </button>
                <button
                    className={`btn-start ${styles.start}`}
                    onClick={buttons.start.handler}
                    style={rouletteButtonSprite}
                >
                    <div
                        className='background'
                        style={{backgroundImage: `url(${App.getUrl('/game/games/european-roulette/img/respin.png')})`}}/>
                    <div className='title'>{lang[buttons.start.title]}</div>
                </button>
                <button
                    className={`btn-double ${styles.double} ${App.settings.currentLanguage}`}
                    onClick={buttons.double.handler}
                    style={rouletteButtonSprite}
                >
                    <span className='x2'><span className='x'>х</span>2</span>
                    <div className='title'>{lang[buttons.double.title]}</div>
                </button>
            </div>
        ),

        choosing: () =>
            <div className='buttons choosing'>
                <div className='score'>
                    <div className='title blue-shadow blue-color'>{lang.balance.toUpperCase()}</div>
                    <div className='credits red-color red-shadow'>{credits}</div>
                </div>

                <div className='status-bar'>
                    <div
                        className='status-text red-color red-shadow'>{App.Game.Legends.getStatusText(legends.status)}</div>
                </div>
                <div className='buttons-container'>
                    <button
                        className={`btn-choice red-shadow red-color disabled-styles ${buttons.select.additionalClass}`}
                        onClick={buttons.select.handler}
                        disabled={buttons.select.disabled}
                    >{lang[buttons.select.title]}</button>
                    <div className='buttons-container-play'>
                        <button
                            className='btn-start red-shadow red-color disabled-styles'
                            disabled={buttons.start.disabled}
                            onClick={buttons.start.handler}>
                            {lang[buttons.start.title]}
                        </button>
                    </div>
                </div>
            </div>,

        kiosk: () => visualization.base()
    };

    return visualization[buttons.visualization]();
}
