import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import Lines from './../lines';
import App from './../../index';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Texture = PIXI.Texture;

export default class Lines5 extends Lines {
    constructor() {
        super();
        this.imageResources = {
            lines: '/img/lines/Lines5/novomatic/lines.png',
            boxes: '/img/lines/Lines5/novomatic/boxes.png'
        };
        this.lineImageWidth = 587; // width of line image in sprite, magic number for each game
        this.lineImageHeight = 110; // height of line image in sprite, magic number for each game

        this.lines = {
            0: {
                coordinates: [1, 1, 1, 1, 1],
                boxes: [{x: 1, y: 263}],
                path: [{x: 2, y: 264}],
                width: 41,
                spriteY: 0
            },
            1: {
                coordinates: [0, 0, 0, 0, 0],
                boxes: [{x: 1, y: 137}],
                path: [{x: 2, y: 137}],
                width: 41,
                spriteY: 41
            },
            2: {
                coordinates: [2, 2, 2, 2, 2],
                boxes: [{x: 1, y: 389}],
                path: [{x: 2, y: 389}],
                width: 41,
                spriteY: 82
            },
            3: {
                coordinates: [0, 1, 2, 1, 0],
                boxes: [{x: 1, y: 79}],
                path: [{x: 2, y: 77}],
                width: 410,
                spriteY: 123
            },
            4: {
                coordinates: [0, 1, 0, 1, 2],
                boxes: [{x: 1, y: 444}],
                path: [{x: 2, y: 80}],
                width: 410,
                spriteY: 532
            }
        };

        JL().debug('-- Lines5_chip_runner initialized');
    }

    drawLineImages(lines, winReels, parentContainer, winLine, payment) {
        lines.forEach(lineIndex => {
            const line = this.lines[lineIndex];
            line.coordinates.forEach(() => {
                const image = 'lines';
                const spritePosX = 0;
                const spritePosY = line.spriteY;

                // create line sprite
                const sprite = new Sprite(new Texture(App.Game.getTexture(image), {
                    x: spritePosX,
                    y: spritePosY,
                    width: this.lineImageWidth,
                    height: line['width']
                }));
                sprite.position.set(line.path[0].x, line.path[0].y);
                sprite.name = lineIndex;
                parentContainer.addChild(sprite);
            });
        });
    }

    drawBoxes(parentContainer) {
        parentContainer.removeChildren(); // clear all previous boxes

        const currentLines = App.Game.gameSettings.getCurrentLineMas();
        const boxWidth = 41; // width of winline boxes
        const boxHeight = 42; // height of winline boxes

        Object.keys(this.lines).forEach(lineIndex => {
            this.lines[lineIndex].boxes.forEach(box => {
                const activeLine = currentLines.includes(+lineIndex + 1);

                // create box sprite
                const sprite = new Sprite(new Texture(App.Game.getTexture('boxes'), {
                    x: activeLine ? boxWidth : 0,
                    y: lineIndex * boxHeight,
                    width: boxWidth,
                    height: boxHeight
                }));
                sprite.position.set(box.x, box.y);
                sprite.name = lineIndex;
                activeLine && this.addBoxEvents(sprite);
                parentContainer.addChild(sprite);
            });
        });
    }
}
