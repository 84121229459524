import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../../../index';
import BookGame from './../../deluxe/bookGame';
import GambleDeluxe from './../../deluxe/gamble';
import Lines10 from './../../deluxe/lines10';
import InfoScreen from '../../infoScreen';
import bonusFont from './img/font/bonusFont';

/* PIXI aliases */
const Texture = PIXI.Texture,
    Sprite = PIXI.Sprite,
    AnimatedSprite = PIXI.AnimatedSprite;

export default class BookOfRaDeluxe extends BookGame {
    constructor() {
        super();
        this.id = 'book-of-ra-deluxe';
        this.name = 'Book of Ra Deluxe';
        this.scatter = 9;
        this.transparentBackground = true;
        this.buttonsPanelShadow = 'mid';

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 60, y: 85},
            bonusInBonusFrame: {x: 60, y: 85},
            endBonusFrame: {x: 60, y: 85}
        };

        this.symbols = [
            {regularDelay: 200, regularLongDelay: 100, payment: [0, 0, 0, 5, 25, 100]}, // 0 - 10
            {regularDelay: 200, regularLongDelay: 100, payment: [0, 0, 0, 5, 25, 100]}, // 1 - J
            {regularDelay: 200, regularLongDelay: 100, payment: [0, 0, 0, 5, 25, 100]}, // 2 - Q
            {regularDelay: 200, regularLongDelay: 100, payment: [0, 0, 0, 5, 40, 150]}, // 3 - K
            {regularDelay: 200, regularLongDelay: 100, payment: [0, 0, 0, 5, 40, 150]}, // 4 - A
            {regularDelay: 100, payment: [0, 0, 5, 30, 100, 750]},                      // 5 - scarab
            {regularDelay: 100, payment: [0, 0, 5, 30, 100, 750]},                      // 6 - statue
            {regularDelay: 100, payment: [0, 0, 5, 40, 400, 2000]},                     // 7 - pharaoh
            {regularDelay: 100, payment: [0, 0, 10, 100, 1000, 5000]},                  // 8 - men
            {regularDelay: 100, scatterDelay: 120, payment: [0, 0, 0, 2, 20, 200]}      // 9 - book (scatter)
        ];
        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                bonusArea: 'area/bonus.png',
                frame: 'bonus/frame.png',
                bookFlash: 'bonus/bookFlash.png',
                bookAnim1: 'bonus/bookAnim1.png',
                bookAnim2: 'bonus/bookAnim2.png',
                bookAnim3: 'bonus/bookAnim3.png',
                bookSelected: 'bonus/bookSelected.png',
                book: 'bonus/book.png',
                symbolBook: 'bonus/symbolBook.png',
                bonusFont: bonusFont['imageResource']
            }),
            atlas: this.mergePath([
                'bonus/bonusSymbols.json',
                'bonus/scatterSymbols.json',
                'bonus/additionalSymbols.json',
                'regularLongSymbols.json',
                'regularLongSymbols2.json',
                'regularLongSymbols3.json'
            ])
        };
        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'bonus-game-end', alias: 'bonusGameEnd'},
                {name: 'bonus-background', loop: true},
                {name: 'book-open', alias: 'bookOpen'},
                {name: 'book-flip-pages', alias: 'bookFlipPages', loop: true},
                {name: 'book-flip-page', alias: 'bookFlipPage'},
                {name: 'book-selected-symbol', alias: 'bookSelectedSymbol'},
                {name: 'bonus-win-symbols-show', alias: 'bonusWinSymbols'},
                {name: 'book-flash', alias: 'bookFlash'},
                {name: 'long1', alias: 'long1'}
            ],
            path: `/game/games/${this.id}/audio/`
        };

        this.Lines = new Lines10();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines - current lines count
     * @param bet - current bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        switch (page) {
            case 1:
                ctx.font = '15pt Times New Roman';
                ctx.strokeStyle = '#ff0000';
                ctx.fillStyle = '#fbff98';
                ctx.lineWidth = 2;
                ctx.textAlign = 'left';

                // scatter
                this.strokeFillText(ctx, bet * nLines * this.symbols[9].payment[5], 250, 55);
                this.strokeFillText(ctx, bet * nLines * this.symbols[9].payment[4], 250, 78);
                this.strokeFillText(ctx, bet * nLines * this.symbols[9].payment[3], 250, 101);
                // men
                this.strokeFillText(ctx, bet * this.symbols[8].payment[5], 150, 193);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[4], 150, 216);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[3], 150, 239);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[2], 150, 262);
                // pharaoh
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], 600, 193);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], 600, 216);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 600, 239);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[2], 600, 262);
                // statue
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 140, 344);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 140, 367);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 140, 390);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[2], 140, 413);
                // scarab
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 615, 344);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 615, 367);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 615, 390);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[2], 615, 413);
                // AK
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 245, 507);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 245, 530);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 245, 553);
                // QJ10
                this.strokeFillText(ctx, bet * this.symbols[2].payment[5], 505, 507);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[4], 505, 530);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[3], 505, 553);
                break;
            case 2:
                ctx.font = 'bold 18pt Times New Roman';
                ctx.fillStyle = '#fff';
                ctx.shadowBlur = 3;
                ctx.shadowColor = '#000';
                ctx.textAlign = 'left';

                ctx.fillText('3 or more scatters on the screen trigger 10 Free', 250, 110);
                ctx.fillText('Games with special expanding symbol.', 250, 140);

                ctx.fillText('One special expanding symbol is', 370, 380);
                ctx.fillText('randomly selected at the start of the', 370, 410);
                ctx.fillText('Free Games. During Free Games the', 370, 440);
                ctx.fillText('special symbol expand to cover 3', 370, 470);
                ctx.fillText('positions on the reel and pays in', 370, 500);
                ctx.fillText('any position on lines played.', 370, 530);
                break;
            case 3:
                ctx.font = 'bold 15pt Times New Roman';
                ctx.fillStyle = '#fff';
                ctx.shadowBlur = 3;
                ctx.shadowColor = '#000';
                ctx.textAlign = 'center';

                ctx.fillText('All prizes are for combinations of a kind. All prizes are for', 400, 260);
                ctx.fillText('combinations left to right, except scatters. All prizes are on', 400, 285);
                ctx.fillText('selected lines, except scatters. Scatter symbols pay at any', 400, 310);
                ctx.fillText('position on screen. Highest win only paid per selected line.', 400, 335);
                ctx.fillText('Scatter wins are added to line wins. Free Games can be won', 400, 360);
                ctx.fillText('again during the Free Games. Free Games are played at trigger', 400, 385);
                ctx.fillText('bet and lines. All prizes shown in credits. Malfunction voids', 400, 410);
                ctx.fillText('all pays and plays.', 400, 435);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    /**
     * Draw bonus enter win frame
     * @param parentContainer
     * @param x
     * @param y
     */
    showStartBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y);

        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'left',
            scale: 1.5,
            fontInterval: -3 // px between symbols
        };
        this.drawCustomFont('10 Free Games', 200, 130, textProps);

        textProps.scale = 1;
        this.drawCustomFont('Special', 200, 255, textProps);
        this.drawCustomFont('expanding', 200, 295, textProps);
        this.drawCustomFont('symbol', 200, 335, textProps);
    }

    /**
     * Draw bonus in bonus win frame
     * @param parentContainer
     * @param x
     * @param y
     */
    showBonusInBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y);
        this.showPressAnyButton(false);

        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 1.1,
            fontInterval: -3 // px between symbols
        };
        this.drawCustomFont('10 MORE FREE GAMES', 400, 250, textProps);
    }

    /**
     * Draw bonus end frame
     * @param parentContainer
     * @param x
     * @param y
     * @param win
     * @param total
     */
    showEndBonusFrame(parentContainer, {x, y}, {win, total}) {
        this.showBonusFrame(parentContainer, x, y);

        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 1.3,
            fontInterval: -3 // px between symbols
        };
        this.drawCustomFont(`FEATURE WIN ${win}`, 400, 170, textProps);
        this.drawCustomFont('CREDITS', 400, 220, textProps);
        this.drawCustomFont(`${total} FREE GAMES`, 400, 290, textProps);
        this.drawCustomFont('PLAYED', 400, 340, textProps);
    }

    //
    // -------------- Special book animations ---------------------
    //

    drawBonusFrame(first, last, parentContainer, coordinates) {
        App.Sounds.stopSound('bonus-background');

        const {startBonusFrame, bonusInBonusFrame, endBonusFrame} = coordinates;
        if (first) {
            this.setBackground('bonusArea');
            this.showStartBonusFrame(parentContainer, startBonusFrame);

            App.Sounds.playSound('bookFlash');
            setTimeout(() => this.drawBookFlashAnimation(parentContainer), 500);
        }
        if (last) {
            this.setRegularSprite();
            App.updateButton('start', {disabled: true});
            this.setBackground('mainArea');
            this.showEndBonusFrame(parentContainer, endBonusFrame, this.bonusStatus);
            this.playEndBonusGameSound();
            setTimeout(() => this.endBonus(), 7000);
        }
        if (!first && !last) {
            this.showBonusInBonusFrame(parentContainer, bonusInBonusFrame);
            App.updateButton('start', {
                disabled: false,
                title: 'start',
                handler: () => this.drawBonusStep(this.latestResponse.features)
            });
        }
    }

    /**
     * Show bonus message with bonus symbol or with bonus game win.
     * @param isFirstBonus {boolean} TRUE if this is the message for starting bonus game.
     */
    drawBonusAskButton(isFirstBonus = false) {
        let isLast = !isFirstBonus && this.bonusStatus && this.bonusStatus.remain === 0;

        this.stopAnimateFeature();
        setTimeout(() =>
            this.drawBonusFrame(isFirstBonus, isLast, this.getStageChild('bonusContainer'), this.coordinatesBonusFrame), 1000);

        this.gameFlag.bonusStart = true;
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});

        isLast = this.bonusStatus && this.bonusStatus.remain === 0;
    }

    /**
     * Play flash animation after show bonus frame
     * Create sprite for all book animations
     * @param parentContainer
     */
    drawBookFlashAnimation(parentContainer) {
        const sprite = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 5, image: 'bookFlash',
            width: 360, height: 224
        }));
        sprite.name = 'book';
        sprite.position.set(340, 210);
        sprite.animationSpeed = 0.15;
        sprite.loop = false;
        sprite.play();
        sprite.onComplete = () => {
            this.showPressAnyButton(false);
            App.updateButton('start', {
                disabled: false,
                title: 'start',
                handler: () => this.startBonusAnimation(parentContainer)
            });
        };
        parentContainer.addChild(sprite);
    }

    /**
     * Draw all bonus animation after bonus 'press any button'
     * @param parentContainer
     * @param bonusSymbol - current bonus symbol in extension
     */
    drawBonusAnimation(parentContainer, bonusSymbol) {
        JL().debug(`-- Draw bonus enter animation. Bonus symbol: ${bonusSymbol}`);
        App.Sounds.stopSound('bonusGameStart');
        const totalBookFrames = 93; // frames count in sprites
        const slowFlipFrames = 20; // frames count before bonus symbol during slowing speed

        // frame index for each symbol in book open animation
        const openedSymbolsFrames = [84, 76, 68, 60, 52, 44, 36, 28, 92];
        const bonusSymbolFrame = openedSymbolsFrames[bonusSymbol];
        const sprite = parentContainer.getChildByName('book');
        const props = {width: 360, height: 224, colCount: 5};

        const getBookTextures = (fromFrame, toFrame) => {
            const textures = [];
            for (let i = fromFrame; i < toFrame; i++) {
                textures.push(...this.getSpriteTextures({
                    ...props, image: `bookAnim${Math.floor(i / 45) + 1}`,
                    fromFrame: i % 45
                }));
            }
            return textures;
        };

        sprite.textures = [
            ...getBookTextures(0, totalBookFrames), // first book open animation circle
            ...getBookTextures(20, bonusSymbolFrame), // repeat open book from frame 20
            ...[...Array(10)].map((item, index) => // book flipping animation during bonus symbol selected
                index % 2 === 0 && index < 7 ?
                    getBookTextures(bonusSymbolFrame, bonusSymbolFrame + 1)[0] :
                    this.getSpriteTextures({...props, fromFrame: bonusSymbol, image: 'bookSelected', colCount: 1})[0]
            )
        ];
        sprite.animationSpeed = 0.35;
        sprite.play();
        App.Sounds.playSound('bookOpen');
        sprite.onFrameChange = frame => {
            const selectedAnimationFrame = totalBookFrames + bonusSymbolFrame - slowFlipFrames;

            switch (frame) {
                case 12:
                    App.Sounds.playSound('bookFlipPages');
                    break;
                case selectedAnimationFrame - 20: // slow down animation speed before 20 frames to bonus symbol
                    sprite.animationSpeed = 0.2;
                    App.Sounds.stopSound('bookFlipPages');
                    App.Sounds.playSound('bookFlipPage');
                    break;
                case selectedAnimationFrame - 12:
                    App.Sounds.playSound('bookFlipPage');
                    break;
                case selectedAnimationFrame - 4:
                    App.Sounds.playSound('bookFlipPage');
                    break;
                case selectedAnimationFrame:
                    sprite.animationSpeed = 0.05;
                    App.Sounds.playSound('bookSelectedSymbol');
                    break;
            }
        };
        sprite.onComplete = () => this.bonusRoll();
    }

    /**
     * Draw open book at logo
     * @param parentContainer
     */
    drawBookTop(parentContainer) {
        const x = 280, y = -25;

        const sprite = new Sprite(this.getTexture('book'));
        sprite.name = 'bookTop';
        sprite.scale.set(1 / 1.6);
        sprite.position.set(x, y);
        parentContainer.addChild(sprite);

        const width = 100, height = 100;
        const symbolSprite = new Sprite(new Texture(this.getTexture('symbolBook'), {
            x: this.bonusRollSymbol * width, y: 0,
            width, height
        }));
        symbolSprite.name = 'symbolBookTop';
        symbolSprite.scale.set(1 / 2.1);
        symbolSprite.position.set(x + 62, y + 45);
        parentContainer.addChild(symbolSprite);
    }
}
