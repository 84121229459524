import {JL} from 'jsnlog';

import App from './../../../index';
import ChampionLines from './../../champion/lines17';

export default class Lines extends ChampionLines {
    constructor(imageResources) {
        super(imageResources);
        this.imageResources = {...imageResources};

        this.boxSettings = {
            ...this.boxSettings,
            lineBoxWidth: 48,
            lineBoxHeight: 34,
            typeBoxes: 'OneBox',
            boxFontSize: 20
        };
        this.winBoxes = false;
        this.linesSettings = {
            ...this.linesSettings,
            horizontalLineLength: 110,
            borderColor: '#b8f4fc',
            lineColor: '#0082bc'
        };
        this.lineMap = [
            3, 1, 5, 7, 0, 8, 6, 2, 4,
            14, 12, 16, 10, 9, 15, 11, 13
        ];

        Object.keys(this.lines).forEach((box, index) =>
            this.lines[box].boxes.forEach(box =>
                box.x = this.isRightBox(index) ? 865 : 40));

        JL().debug('-- Lines17 initialized');
    }

    getPointPosY = rowIndex => App.Game.reelTop + (App.Game.symbolHeight + 15) * (rowIndex + 0.5);

    getPointPosX = reelIndex => reelIndex === 0 ? 266 : reelIndex === 1 ? 482 : 698;
}
