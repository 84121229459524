export default {
    en: {
        infoTitle: 'SUBSTITUTES for ALL symbols EXCEPT',
        infoRules: 'substituting multiplies line win',
        feature: 'Feature',
        scatterRules: '3 features scattered on the screen trigger 10 free spins, ' +
            '4 features - 15 free spins and ' +
            '5 features trigger 20 free spins. ' +
            'Free games can be won again during the feature. ' +
            'Free games are played at trigger bet and lines.'
    },
    uk: {
        infoTitle: 'Є ЗАМІНОЮ ВСІМ символам ОКРІМ',
        infoRules: 'підстановка множини виграшу на рядки',
        feature: 'Символ',
        scatterRules: '3 символи на барабані дають 10 безкоштовних прокрутів , ' +
            '4 символи - 15 безкоштовних прокрутів, ' +
            '5 символів викликають 20 безкоштовних прокрутів. ' +
            'Безкоштовні ігри можуть бути виграні знову в бонусі. ' +
            'Безкоштовні ігри прокручуються на визначеній раніше ставці та кількості ліній.'
    },
    ru: {
        infoTitle: 'ЗАМЕНЯЕТ ВСЕ символы КРОМЕ',
        infoRules: 'подстановка умножения выигрыша строк',
        feature: 'Символ',
        scatterRules: '3 символа на барабане дают 10 бесплатных прокрутов, ' +
            '4 символа - 15 бесплатных прокрутов и ' +
            '5 символов дают 20 бесплатных прокрутов. ' +
            'Бесплатные игры выигриваются снова в бонусе. ' +
            'Бесплатные игры играют на заданой ранее ставке и количестве линий.'
    },
    de: {
        infoTitle: 'ERSATZTEILE FUR ALLE Symbole AUSSER',
        infoRules: 'Ersetzen von Multiplikationen Liniengewinn',
        feature: 'das Symbol',
        scatterRules: '3 auf dem Bildschirm verstreute Funktionen lösen 10 Freispiele aus, ' +
            '4 Funktionen - 15 Freispiele und ' +
            '5 Funktionen losen 20 Freispiele aus. ' +
            'Wahrend des Features konnen wieder kostenlose Spiele gewonnen werden. ' +
            'Kostenlose Spiele werden bei Trigger-Wetten und Linien gespielt.'
    },
    es: {
        infoTitle: 'SUSTITUTOS para TODOS los simbolos EXCEPTO',
        infoRules: 'sustituyendo la linea multiplica la victoria',
        feature: 'Simbolo',
        scatterRules: '3 caracteristicas dispersas en la pantalla activan 10 giros gratis, ' +
            '4 caracteristicas: 15 giros gratis y ' +
            '5 caracteristicas activan 20 giros gratis. ' +
            'Se pueden volver a ganar juegos gratis durante la funcion. ' +
            'Se juegan juegos gratis en la apuesta de disparo y en las líneas.'
    },
    fr: {
        infoTitle: 'SUBSTITUTS pour TOUS les symboles SAUF',
        infoRules: 'substituant multiplie la victoire de ligne',
        feature: 'symbole',
        scatterRules: '3 fonctionnalités dispersées sur l\'écran déclenchent 10 tours gratuits, ' +
            '4 fonctionnalités - 15 tours gratuits et ' +
            '5 fonctionnalités déclenchent 20 tours gratuits. ' +
            'Les jeux gratuits peuvent être gagnés à nouveau pendant la fonctionnalité. ' +
            'Les jeux gratuits sont joués au pari et aux lignes de déclenchement.'
    },
    el: {
        infoTitle: 'ΥΠΟΘΕΣΕΙΣ για ΟΛΑ τα σύμβολα ΕΚΤΟΣ',
        infoRules: 'αντικαθιστώντας τη νίκη πολλαπλών γραμμών',
        feature: 'Σύμβολο',
        scatterRules: '3 χαρακτηριστικά διάσπαρτα στην οθόνη ενεργοποιούν 10 δωρεάν περιστροφές, ' +
            '4 δυνατότητες - 15 δωρεάν περιστροφές και ' +
            '5 δυνατότητες ενεργοποιούν 20 δωρεάν περιστροφές. ' +
            'Μπορείτε να κερδίσετε ξανά δωρεάν παιχνίδια κατά τη διάρκεια της λειτουργίας. ' +
            'Τα δωρεάν παιχνίδια παίζονται στο trigger bet και στις γραμμές.'
    },
    it: {
        infoTitle: 'SOSTITUTI TUTTI simboli TRANNE',
        infoRules: 'sostituendo moltiplica la linea vincente',
        feature: ' il Simbolo',
        scatterRules: '3 funzioni sparse sullo schermo attivano 10 giri gratuiti, ' +
            '4 funzioni - 15 giri gratuiti e ' +
            '5 funzioni attivano 20 giri gratuiti. ' +
            'I giochi gratuiti possono essere vinti di nuovo durante la funzione. ' +
            'Le partite gratis si giocano con la puntata e le linee del trigger.'
    }
};
