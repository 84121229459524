import App from './../index';

export default class Legends {
    value; // additional text for localization

    setText = (field, {text, value, number = ''}) =>
        App.updateState('legends', {[field]: {text, value, number}});

    clearText = field =>
        App.updateState('legends', {[field]: {text: '', value: 0}});

    setStatus = (status, value = '') => {
        this.value = value;
        App.updateState('legends', {status});
    };

    getStatusText = key => {
        const {lang} = App.View.state;
        const value = this.value;
        const texts = {
            creditsWon: () => `${value} ${lang[key]}`,
            winnerPaid: () => `${lang[key]}: ${value}`,
            additionalRoll: () => `${lang[key]} ${value}`,
            freeGame: () => `${lang[key]} ${value.current} ${lang.of} ${value.totalGames}`,
            freeSpinsRemaining: () => `${lang[key]} ${value.remain}`,
            freeSpinsLeft: () => `${lang[key]} ${value.remain}`,
            minBet: () => `${lang[key]}: ${value.minPrice}`,
            highMaxBet: () => `${lang[key]} ${value.typeOfBet} - ${value.max}`,
            lowMinBet: () => `${lang[key]} ${value.typeOfBet} - ${value.min}`,
            prizeWin: () => `${value.statusText}: ${value.payment} ${lang.credits}`
        };

        return texts[key] && texts[key]() || lang[key];
    };

    clearStatus = () =>
        App.updateState('legends', {status: ''});

    showWinFeatures = () =>
        App.updateState('moneyParams', {showWinFeatures: true});

    showJackpot = () => {
        App.updateState('moneyParams', {showWinFeatures: false});
        this.clearText('features');
        this.clearText('win');
    };

    /**
     * Выводит текст в окно статуса после прокрута
     */
    setRoundFinText = () => {
        App.updateState('legends', {
            status: 'gameOverPlaceYourBet',
            features: '',
            win: ''
        });
    };
}
