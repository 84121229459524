import {JL} from 'jsnlog';
import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import GameDeluxe from './../../deluxe/game';
import InfoScreen from './../../infoScreen';
import Gamble from './../sea-treasure/gamble';
import Lines from './lines';
import numFont from './img/font/numFont';
import langs from './langs';
import bigWinFont from './../../../img/bigWin/font/bigWinFont';
import CountUp from './../../../modules/countUp';
import SymbolInfoCustom from './symbolInfo';

const AnimatedSprite = PIXI.AnimatedSprite,
    Container = PIXI.Container,
    Sprite = PIXI.Sprite,
    Text = PIXI.Text,
    Graphics = PIXI.Graphics;

export default class LuckyRanch extends GameDeluxe {
    constructor() {
        super();
        this.id = 'lucky-ranch';
        this.name = 'Lucky Ranch';
        this.gameFieldWidth = 960;
        this.gameWidth = App.System.resolution === '4x3' ? this.gameFieldWidth : 1280;
        this.gameFieldHeight = 720;
        this.gameHeight = App.configs.doubleScreen ? 1440 : 720;
        this.symbolEffects = false; // symbol shadow
        this.defaultFeatureDelay = 900;
        this.scatter = 12;

        this.reelTop = 105;
        this.symbolWidth = 154;
        this.symbolHeight = 154;

        this.SymbolInfo = new SymbolInfoCustom();
        this.SymbolInfo.enabled = true;
        this.SymbolInfo.settings = {
            symbolBorderOffset: {x: 0, y: 0},
            paymentBorderOffset: {left: 50, right: 70} // end animation offset
        };

        this.symbolToRefill = [...Array(5)].map(item => []);
        this.deletedSymbols = [...Array(5)].map(item => [...Array(3)]);
        this.bigWin = true;
        this.buttonsPanelShadow = 'mid';

        this.containersLayers = {
            reelsStage: 1,
            borderContainer: 3,
            mainContainer: 0,
            linesContainer: 6,
            boxesContainer: 4,
            extraBetContainer: 5,
            infoContainer: 11,
            bonusContainer: 7,
            symbolInfo: 8,
            waitingContainer: 10
        };
        this.rollProperties = {
            reelSpeed: 1.7,
            springDown: 0.4,
            springUp: 0.3
        };

        this.offsetReelMask = {
            offsetX: 8, offsetY: -16,
            offsetWidth: 70, offsetHeight: 20
        };

        this.reelXCoordinates = [77, 241, 403, 567, 728];

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 482, y: 346},
            bonusInBonusFrame: {x: 310, y: 250},
            endBonusFrame: {x: 482, y: 346}
        };
        this.symbols = [
            {regularDelay: 50, payment: [0, 0, 0, 3, 10, 50]},     // 0 - wheat
            {regularDelay: 50, payment: [0, 0, 0, 3, 10, 50]},     // 1 - corn
            {regularDelay: 50, payment: [0, 0, 0, 3, 10, 50]},     // 2 - pumpkin
            {regularDelay: 50, payment: [0, 0, 0, 3, 10, 50]},     // 3 - hay

            {regularDelay: 50, payment: [0, 0, 0, 5, 20, 100]},    // 4 - rooster
            {regularDelay: 50, payment: [0, 0, 0, 5, 20, 100]},    // 5 - sheep
            {regularDelay: 50, payment: [0, 0, 0, 5, 20, 100]},    // 6 - pig

            {regularDelay: 50, payment: [0, 0, 0, 15, 50, 500]},   // 7 - horse
            {regularDelay: 50, payment: [0, 0, 0, 15, 50, 500]},   // 8 - cow

            {regularDelay: 50, payment: [0, 0, 0, 50, 250, 2500]}, // 9 - mill
            {regularDelay: 50, payment: [0, 0, 0, 20, 100, 1000]}, // 10 - tractor

            {regularDelay: 50, payment: [0, 0, 0, 0, 0, 0]},       // 11 - scarecrow
            {regularDelay: 50, payment: [0, 0, 0, 0, 0, 0]}        // 12 - granary
        ];

        this.imageResources = {
            main: this.mergePath({
                numFont: numFont['imageResource']
            }),
            jsonAnimations: this.mergePath({
                bang: `bang.json`,
                line: 'lines/Line.json',
                skeleton: 'bonus/skeleton.json',
                multiplier: 'area/Multiplier.json',
                borderSpine: 'area/Frame.json',
                backgroundSpine: 'area/FON.json'
            }),
            atlas: this.mergePath([
                'staticSymbols.json',
                'scatterSymbols.json'
            ])
        };
        this.Gamble = new Gamble();
        this.gameSounds = {soundClass: 'prefergames'};
        this.Lines = new Lines();
        this.BigWin.enabled = true;

        this.additionalResources = {
            main: this.mergePath({
                symbolBorder: 'area/symbol-border.png',
                paymentBorder: 'area/payment-border.png'
            })
        };

        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
        this.InfoScreen.format = 'png';
        this.InfoScreen.getResources = () => this.mergePath({
            info1: 'area/info1-screen.png'
        });
    };

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     * @param lang
     */
    drawInfoPage(ctx, page, nLines, bet, lang) {
        ctx.strokeStyle = '#000';
        ctx.fillStyle = '#ffce71';
        ctx.lineWidth = 3;
        ctx.textAlign = 'center';

        const {
            avalancheMultipliers, infoRules, avalanche,
            multiplier, freeFallsMultiplier, firstAvalanche,
            secondAvalanche, thirdAvalanche, fourthAvalanche
        } = langs[lang];
        const {
            combinationOfKind,
            combinationLeftToRight,
            prizesOnSelectedLines,
            ScatterPayAtAnyPosition,
            HighestWinPaid,
            ScatterWinsAddedToLineWins,
            prizesShownInCredits,
            MalfunctionVoidsAllPays
        } = App.languageCollection[lang];
        const {rules} = App.languageCollection[lang];

        const textProps = {
            font: '15pt Trebuchet MS',
            textAlign: 'center',
            fillStyle: '#ffce71',
            strokeStyle: '#000000',
            lineWidth: 3,
            lineHeight: 23,
            shadowColor: '#000',
            shadowOffsetX: 3,
            shadowOffsetY: 1,
            shadowBlur: 2
        };

        switch (page) {
            case 1:
                ctx.font = '15pt Trebuchet MS';
                ctx.fillStyle = '#92e534';
                ctx.shadowColor = '#000';
                ctx.shadowOffsetX = 3;
                ctx.shadowOffsetY = 1;
                ctx.shadowBlur = 2;

                // horse, cow
                this.strokeFillText(ctx, bet * this.symbols[8].payment[3], 555, 208);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[4], 555, 241);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[5], 555, 274);
                // mill
                this.strokeFillText(ctx, bet * this.symbols[9].payment[3], 279, 258);
                this.strokeFillText(ctx, bet * this.symbols[9].payment[4], 279, 288);
                this.strokeFillText(ctx, bet * this.symbols[9].payment[5], 279, 318);
                // tractor
                this.strokeFillText(ctx, bet * this.symbols[10].payment[3], 760, 253);
                this.strokeFillText(ctx, bet * this.symbols[10].payment[4], 760, 285);
                this.strokeFillText(ctx, bet * this.symbols[10].payment[5], 760, 317);
                // rooster, sheep, pig
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 395, 400);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 395, 432);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 395, 464);
                // wheat, corn, pumpkin, hay
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 660, 400);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 660, 432);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 660, 466);

                break;
            case 2:
                this.drawSplitText(ctx, avalancheMultipliers, 500, 75, 550, {
                    ...textProps,
                    fillStyle: '#92e534',
                    font: '20pt Trebuchet MS'
                });
                this.drawSplitText(ctx, infoRules, 495, 110, 700, {
                    ...textProps,
                    font: '16pt Trebuchet MS'
                });
                this.drawSplitText(ctx, avalanche, 210, 195, 310, {
                    ...textProps,
                    fillStyle: '#92e534',
                    font: '18pt Trebuchet MS'
                });
                this.drawSplitText(ctx, multiplier, 460, 195, 310, {
                    ...textProps,
                    fillStyle: '#92e534',
                    font: '18pt Trebuchet MS'
                });
                this.drawSplitText(ctx, freeFallsMultiplier, 720, 195, 330, {
                    ...textProps,
                    fillStyle: '#92e534',
                    font: '18pt Trebuchet MS'
                });
                this.drawSplitText(ctx, firstAvalanche, 210, 248, 290, {
                    ...textProps,
                    shadowOffsetX: 2
                });
                this.drawSplitText(ctx, secondAvalanche, 210, 293, 290, {
                    ...textProps,
                    shadowOffsetX: 2
                });
                this.drawSplitText(ctx, thirdAvalanche, 210, 337, 290, {
                    ...textProps,
                    shadowOffsetX: 2
                });
                this.drawSplitText(ctx, fourthAvalanche, 210, 380, 240, {
                    ...textProps,
                    shadowOffsetX: 2
                });
                const textProps2 = {
                    parentContainer: ctx,
                    fontImageName: 'numFont',
                    map: numFont,
                    align: 'center',
                    scale: 0.30,
                    fontInterval: -15 // px between symbols
                };
                [1, 2, 3, 5].forEach((multiplier, index) =>
                    this.drawCustomFont(`x${multiplier}`, 455, 225 + index * 45, textProps2));
                [3, 6, 9, 15].forEach((multiplier, index) =>
                    this.drawCustomFont(`x${multiplier}`, 710, 225 + index * 45, textProps2));
                break;
            case 3:
                ctx.font = '30pt Trebuchet MS';
                ctx.lineWidth = 3;
                ctx.textAlign = 'center';
                ctx.shadowColor = '#000';
                ctx.shadowOffsetX = 3;
                ctx.shadowOffsetY = 1;
                ctx.shadowBlur = 2;
                const rulesText = combinationOfKind +
                    combinationLeftToRight +
                    prizesOnSelectedLines +
                    ScatterPayAtAnyPosition +
                    HighestWinPaid +
                    ScatterWinsAddedToLineWins +
                    prizesShownInCredits +
                    MalfunctionVoidsAllPays;
                this.strokeFillText(ctx, rules, 480, 115, 500);
                this.drawSplitText(ctx, rulesText, 490, 160, 665, {
                    ...textProps,
                    font: '20pt Trebuchet MS',
                    lineHeight: 30
                });
                break;
        }
    }

    /**
     * Create PIXI.Container for game background
     * Add additional sprites to container
     * @param parentContainer
     */
    createMainContainer(parentContainer) {
        const container = new Container();
        container.name = 'mainContainer';
        container.zIndex = this.containersLayers[container.name];
        parentContainer.addChild(container);

        const backgroundSpine = new window.PIXI.spine.Spine(this.getJsonTextures('backgroundSpine'));
        backgroundSpine.name = 'backgroundSpine';
        backgroundSpine.position.set(-160, 0);
        backgroundSpine.state.setAnimation(1, 'animation', true);

        const borderSpine = new window.PIXI.spine.Spine(this.getJsonTextures('borderSpine'));
        borderSpine.name = 'borderSpine';
        borderSpine.position.set(-160, 0);
        borderSpine.zIndex = 2;
        const darkRectangle = new Graphics()
            .beginFill(0x000000)
            .drawRect(0, 0, this.symbolWidth * 5 + 50, this.symbolHeight * 3 + 170)
            .endFill();
        darkRectangle.position.set(this.reelXCoordinates[0] - 10, this.reelTop - 25);
        darkRectangle.name = 'darkRectangle';
        darkRectangle.alpha = 0.5;
        container.addChild(backgroundSpine, darkRectangle);

        const borderContainer = new Container();
        borderContainer.name = 'borderContainer';
        borderContainer.zIndex = this.containersLayers['borderContainer'];
        this.getStage().addChild(borderContainer);

        borderContainer.addChild(borderSpine);

        const bangContainer = new Container();
        bangContainer.name = 'bangContainer';
        bangContainer.zIndex = 16;
        this.getStage().addChild(bangContainer);
    }

    addWaitingAnimationSprites(parentContainer) {
        // prevent secondary enter in this function
        if (!parentContainer.getChildByName('multiplier')) {
            const multiplier = new window.PIXI.spine.Spine(this.getJsonTextures('multiplier'));
            multiplier.name = 'multiplier';
            multiplier.position.set(905, 109);
            parentContainer.addChild(multiplier);

            const textContainer = new Container();
            textContainer.name = 'textContainer';
            multiplier.addChild(textContainer);

            this.drawMultiplierText(1);

            const numberBone = multiplier.skeleton.findBone('Multiplier_NUM');
            const multiplierBone = multiplier.skeleton.findBone('Multiplier');

            this.app.ticker.add(() => {
                textContainer.position.set(numberBone.worldX, numberBone.worldY);
                textContainer.rotation = (-multiplierBone.arotation - 90) * 0.02;
                textContainer.scale.set(numberBone.scaleX, numberBone.scaleY);
            });
        }
    }

    /**
     * Start game animations
     */
    showGameIntroduction() {
        const reelsContainer = this.getStageChild('reelsStage');
        reelsContainer.mask = this.getReelsMask();
        this.getReelsMask().zIndex = 9;
        this.setState('GAME_INTRO');
        this.playIntroSound();
        this.Buttons.disableAllButtons();
        App.updateButton('autoStart', {disabled: true});
        App.updateState('buttons', {animation: 'hide'});

        const mainContainer = this.getStageChild('mainContainer');
        const backgroundSpine = mainContainer.getChildByName('backgroundSpine');
        const darkRectangle = mainContainer.getChildByName('darkRectangle');
        darkRectangle.alpha = 0;
        const borderContainer = this.getStageChild('borderContainer');
        const borderSpine = borderContainer.getChildByName('borderSpine');
        borderSpine.state.setAnimation(0, 'INTRO', false);
        backgroundSpine.state.setAnimation(0, 'INTRO', false);
        backgroundSpine.state.setAnimation(1, 'animation', true);
        backgroundSpine.state.addListener({
            complete: () => {
                this.roundFinished();
                App.updateState('buttons', {animation: 'show'});
                backgroundSpine.state.clearListeners();
            }
        });

        borderContainer.addChild(borderSpine);
        const waitingContainer = this.getStageChild('waitingContainer');
        waitingContainer.alpha = 0;
        reelsContainer.position.y = this.reelTop - this.symbolHeight * this.reelRows - 50;

        this.tickerTimeout(() => {
            this.showAnimation({
                duration: 550,
                animations: [
                    {sprite: reelsContainer, timeline: [{to: {y: this.reelTop}}]},
                    {sprite: darkRectangle, timeline: [{from: {alpha: 0}, to: {alpha: 0.5}}]},
                    {sprite: darkRectangle, timeline: [{from: {alpha: 0}, to: {alpha: 0.5}}]},
                    {sprite: waitingContainer, timeline: [{from: {alpha: 0}, to: {alpha: 1}}]}
                ]
            });
        }, 1600);
    }

    /**
     * End feature animation / take win without prompt
     * @returns {boolean} - new animation features circle
     */
    endAnimateFeature() {
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        App.Sounds.stopSound('win-line');
        App.updateButton('start', {disabled: true});

        this.getState() === 'SHOW_WIN_LINES' && this.latestResponse.payment ?
            this.refill() :
            this.roundFinished(); // no win, just feature without payment
        return false;
    }

    additionalPreparingToAnimateFeature() {
        const borderContainer = this.getStageChild('borderContainer');
        const borderSpine = borderContainer.getChildByName('borderSpine');
        borderSpine.state.setAnimation(0, 'Win', false);
    }

    processReelResponse(response) {
        this.latestResponse = response;
        this.setState('RESPONSE_RECEIVED');
        this.setBonusRollSymbol(); // for bonus game roll symbol
        response.extension && this.getSymbolsToRefill();
        this.prepareToRollAnimation(response);
        this.checkBonusFeatures();
    }

    /**
     * Comparing root screen and refill screen and define symbols that have to refill
     * this.symbolToRefill - here we store symbols to refill
     * this.deletedSymbols - marking symbols that have to be destroyed by -1
     */
    getSymbolsToRefill() {
        this.getStageChild('reelsStage').mask = this.getReelsMask();
        const {features, extension} = this.latestResponse;
        let screen = this.latestResponse.screen.map(reel => reel.map(item => item));

        const newScreen = extension.refill.screen;

        features.forEach(feature => {
            if (feature.uc === 'WIN_LINE') {
                feature.reels.forEach(reelIndex => {
                    const rowIndex = this.Lines.lines[feature.number].coordinates[reelIndex];
                    screen[reelIndex][rowIndex] = -1;
                    this.deletedSymbols[reelIndex][rowIndex] = -1;
                });
            }
        });

        screen = screen.map(reel => reel.filter(item => item !== -1));
        screen.forEach((reel, reelIndex) => {
            const screenLength = screen[reelIndex].length;
            const newScreenLength = newScreen[reelIndex].length;
            const different = newScreenLength - screenLength;

            for (let i = different - 1; i >= 0; i--) {
                screen[reelIndex].push(newScreen[reelIndex][i]);
                this.symbolToRefill[reelIndex].push(newScreen[reelIndex][i]);
            }
        });
    }

    refill() {
        this.deletedSymbols.forEach((reel, reelIndex) => {
            reel.forEach((symbol, rowIndex) => {
                if (symbol === -1) {
                    const symbolObj = this.reelMatrix[reelIndex][rowIndex];
                    const {symbolContainer, sprite} = symbolObj;
                    // create bang animations
                    const bangSprite = new AnimatedSprite(this.getJsonTextures('bang'));
                    bangSprite.name = symbolContainer.name;
                    bangSprite.blendMode = 1;
                    bangSprite.position.set(
                        this.reelXCoordinates[reelIndex] - this.reelXCoordinates[0] - 20,
                        this.symbolHeight * rowIndex
                    );
                    bangSprite.zIndex = 3;
                    bangSprite.animationSpeed = 0.7;
                    bangSprite.loop = false;
                    bangSprite.play();
                    symbolContainer.zIndex = 16;
                    bangSprite.onComplete = () => {
                        bangSprite.destroy();
                        symbolContainer.destroy();
                    };

                    this.getStageChild('bangContainer').addChild(bangSprite);
                    // let symbol disappear

                    this.showAnimation({
                        duration: 500, animations: [
                            {sprite, timeline: [{to: {alpha: 0}}]}
                        ]
                    });
                }
            });
        });

        // deleting win symbols from this.reelMatrix
        this.reelMatrix = this.reelMatrix.map((reel, reelIndex) =>
            reel.filter((item, rowIndex) => this.deletedSymbols[reelIndex][rowIndex] !== -1));
        this.prepareNewSprites(this.symbolToRefill);

        this.tickerTimeout(() => this.letSymbolDown(), 800);
    }

    /**
     * @param symbolsToReplace
     */
    prepareNewSprites(symbolsToReplace) {
        const reelsStage = this.getStageChild('reelsStage');
        symbolsToReplace.forEach((reel, reelIndex) => {
            reel.forEach((symbol, rowIndex) => {
                if (symbol !== undefined) {
                    const symbolObj = this.createStageContainers(reelsStage, symbol, rowIndex, reelIndex);
                    symbolObj.symbol = symbol;
                    symbolObj.position = {
                        x: this.reelXCoordinates[reelIndex] - this.reelXCoordinates[0],
                        y: -this.symbolHeight * (rowIndex + 1) - 18
                    };

                    this.reelMatrix[reelIndex].unshift(symbolObj);

                    this.Roll.updateSymbolSprite(symbolObj);
                }
            });
        });
    }

    letSymbolDown() {
        const movedSprites = [];
        this.reelMatrix.forEach((reel, reelIndex) => {
            reel.forEach((symbolObj, rowIndex) => {
                symbolObj.symbolContainer.y !== rowIndex * this.symbolHeight && movedSprites.push({
                    symbolObj,
                    rowIndex
                });
            });
        });
        movedSprites.forEach((props, i) => {
            const {symbolObj} = props;
            this.showAnimation({
                duration: 300, animations: [
                    {
                        sprite: symbolObj.symbolContainer,
                        timeline: [{to: {y: props.rowIndex * this.symbolHeight}}]
                    }
                ],
                onComplete: () => {
                    symbolObj.position.y = props.rowIndex * this.symbolHeight;
                    if (movedSprites.length - 1 === i) {
                        this.bounceSprites(movedSprites);
                    }
                }
            });
        });
    }

    bounceSprites(movedSprites, animationStarted = Date.now()) {
        let angel = 5;
        const ticker = this.app.ticker;
        const bounce = () => {
            movedSprites.forEach((props, i) => {
                const {symbolObj} = props;

                const timeCoef = Date.now() - animationStarted;

                const y = symbolObj.symbolContainer.y;
                symbolObj.symbolContainer.y = y - (angel * (Math.sin((timeCoef) * 0.015) - 0.1));
                angel = angel - 0.017;

                // angel <= 0 && ticker.remove(bounce);
                if (timeCoef > 550) {
                    ticker.remove(bounce);
                    this.showAnimation({
                        duration: 200,
                        animations: [{
                            sprite: symbolObj.symbolContainer,
                            timeline: [{to: {y: symbolObj.position.y}}]
                        }],
                        onComplete: () => {
                            if (i === movedSprites.length - 1) {
                                // reset to default refill settings
                                this.symbolToRefill = [...Array(5)].map(() => []);
                                this.deletedSymbols = [...Array(5)].map(() => [...Array(3)]);
                                this.afterRefillActions();
                            }
                        }
                    });
                }
            });
        };
        ticker.add(bounce);
    }

    afterRefillActions() {
        const multiplierSpine = this.getStageChild('waitingContainer').getChildByName('multiplier');

        // if extension exist redefine latestResponse
        if (this.latestResponse.extension) {
            this.latestResponse.extension.refill.payment = this.latestResponse.payment;
            this.latestResponse = this.latestResponse.extension.refill;
            this.lastScreen = this.latestResponse.screen;
        }

        const isWinLine = this.latestResponse.features?.some(features => features.uc === 'WIN_LINE');

        if (isWinLine) {
            const {features, extension: {multiplier}} = this.latestResponse;

            this.getSymbolsToRefill();
            this.setState('SHOW_WIN_LINES');
            this.startAnimateFeature(features);

            this.drawMultiplierText(multiplier);
            this.drawMultiplierAnimation(multiplierSpine, 'start');
        } else {
            multiplierSpine.state.tracks.length &&
            this.drawMultiplierAnimation(multiplierSpine, 'stop');
            const {payment} = this.latestResponse;

            // check action when there no winline
            if (this.BigWin.isBigWin(payment)) {
                this.BigWin.goToBigWin(payment);
            } else if (this.isFreeRoll(this.latestResponse.features)) {
                this.bonusEntrance(this.latestResponse.features);
            } else if (this.isBonus()) {
                if (this.bonusStatus && this.bonusStatus.remain > 0) {
                    this.roundFinished(false);
                } else {
                    this.Legends.setRoundFinText();
                    this.finishBonus();
                }
            } else {
                this.takeWin();
            }
        }
    }

    drawMultiplierAnimation(multiplierSpine, type) {
        switch (type) {
            case 'start':
                multiplierSpine.state.setAnimation(0, 'start', false);
                multiplierSpine.state.getCurrent(0).mixDuration = 0.25;

                multiplierSpine.state.addListener({
                    complete: () => {
                        multiplierSpine.state.setAnimation(0, 'static', true);
                    }
                });
                break;

            case 'stop':
                multiplierSpine.state.setAnimation(0, 'fin', false);
                multiplierSpine.state.getCurrent(0).mixDuration = 0.25;

                multiplierSpine.state.addListener({
                    complete: () => {
                        multiplierSpine.state.clearListeners();
                        multiplierSpine.state.clearTracks();
                        this.drawMultiplierText(1);
                        multiplierSpine.state.setAnimation(0, 'start', false);
                        multiplierSpine.state.addListener({
                            complete: () => {
                                multiplierSpine.state.clearListeners();
                                multiplierSpine.state.clearTracks();
                            }
                        });
                    }
                });

                break;
        }
    }

    checkBonusFeatures(response = this.latestResponse) {
        const isBonusFeature = response.features.some(features => features.uc === 'SCATTER');
        let bonusFeatures = [];
        if (isBonusFeature) {
            const indexes = [];
            bonusFeatures = response.features.filter((feature, i) => {
                if (feature.uc === 'SCATTER' || feature.uc === 'FREE_ROLL') {
                    indexes.push(i);
                    return true;
                }
            });

            indexes.reverse().forEach(value => response.features.splice(value, 1));

            return response.extension ? this.checkBonusFeatures(response.extension.refill) :
                bonusFeatures.forEach(feature => response.features.push(feature));
        }
    }

    hideTopSymbols(reelIndex) {
        const container = this.getStageChild('reelsStage').getChildByName(`reel${reelIndex}`);
        container.children.forEach(sprite => {
            if (sprite.position.y < -34 && sprite.visible) {
                sprite.visible = false;
            }
        });
    };

    hideBottomSymbols(reelIndex) {
        const container = this.getStageChild('reelsStage').getChildByName(`reel${reelIndex}`);
        container.children.forEach(sprite => {
            if (sprite.position.y > 320 && sprite.visible) {
                sprite.visible = false;
            }
        });
    };

    drawMultiplierText(multiplier) {
        const multiplierSpine = this.getStageChild('waitingContainer').getChildByName('multiplier');
        const textContainer = multiplierSpine.getChildByName('textContainer');
        textContainer.removeChildren();
        const textProps = {
            parentContainer: textContainer,
            fontImageName: 'numFont',
            map: numFont,
            align: 'center',
            scale: 0.6,
            fontInterval: -25 // px between symbols
        };

        this.drawCustomFont(`x${multiplier}`, 0, 0, textProps);

        textContainer.children.forEach(sprite => {
            sprite.anchor.y = 0.5;
            sprite.anchor.x = 0.2;
        });
    }

    /**
     * Create texts for paymentBorder table
     * @param parentContainer
     * @param payTable
     * @param direction
     */
    drawSymbolInfoPayments(parentContainer, payTable, direction, symbolIndex) {
        const bet = this.gameSettings.getBetLineCredit();
        const props = {
            fill: '#f2d300',
            stroke: '#fecf1a',
            align: 'left',
            fontFamily: 'Trebuchet MS',
            fontSize: 19,
            strokeThickness: 1,
            lineJoin: 'round'
        };
        if (payTable.length) {
            payTable.forEach((pay, index) => {
                const quantity = new Text(`x${pay[0]}`, props);
                quantity.position.x = direction === 'left' ? -65 : -45;
                quantity.position.y = 15 + (payTable.length === 1 ? -30 : -30 * index);

                const payment = new Text(` ${bet * pay[1]}`, {...props, fill: '#92e534', stroke: '#47840d  '});
                payment.position.x = direction === 'left' ? -34 : -15;
                payment.position.y = 15 + (payTable.length === 1 ? -30 : -30 * index);

                parentContainer.addChild(quantity, payment);
            });
        } else {
            const symbolType = symbolIndex === this.scatter ? 'SCATTER' : 'WILD';
            const symbol = new Text(symbolType, {...props, fontSize: 20});
            symbol.position.x = direction === 'left' ? -50 : -25;
            symbol.position.y = -17;
            parentContainer.addChild(symbol);
        }
    }

    /**
     * используем чтобы поменять символы скатеров на соответствующих рилах
     * only latest response screen
     */
    additionalFilter = reelMas => {
        reelMas.forEach((reel, reelIndex) => {
            reel.forEach((symbol, rowIndex) => {
                if (rowIndex === 0) {
                    this.symbolToRefill[reelIndex][rowIndex] &&
                    (reelMas[reelIndex][rowIndex] = this.symbolToRefill[reelIndex][rowIndex]);
                }
            });
        });

        return reelMas;
    };

    /**
     * Function to stop Animate Feature animation
     */
    stopAnimateFeature() {
        this.stopFeatureTimeout();
        this.getStageChild('linesContainer').removeChildren();
        this.resetSymbolAnimation();
        this.Legends.clearText('features');

        !this.isBonus() && this.Legends.clearText('win');

        if (this.getState() !== 'AFTER_BONUS') {
            this.Legends.setText('win', {text: 'win', value: this.bonusWin});
            this.Legends.setStatus('creditsWon', this.roundWin);
        }
    }

    fade(sprite, {from, to}, callback) {
        this.showAnimation({
            duration: 1000,
            animations: [{sprite, timeline: [{from: {alpha: from}, to: {alpha: to}}]}],
            onComplete: () => callback?.()
        });
    }

    /**
     * Show bonus message with bonus symbol or with bonus game win.
     * @param isFirstBonus {boolean} TRUE if this is the message for starting bonus game.
     */
    drawBonusAskButton(isFirstBonus = false) {
        let isLast = !isFirstBonus && this.bonusStatus && this.bonusStatus.remain === 0;

        this.stopAnimateFeature();
        this.tickerTimeout(() => {
            this.drawBonusFrame(isFirstBonus, isLast, this.getStageChild('bonusContainer'), this.coordinatesBonusFrame);
        }, 1000);

        this.gameFlag.bonusStart = true;
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});

        isLast = this.bonusStatus && this.bonusStatus.remain === 0;
    }

    /**
     * Отрисовка таблички бонусной игры
     */
    showStartBonusFrame(parentContainer, {x, y}) {
        const ticker = this.app.ticker;
        const frame = new window.PIXI.spine.Spine(this.getJsonTextures('skeleton'));
        frame.position.set(x, y);
        frame.update(0);
        frame.autoUpdate = false;
        parentContainer.addChild(frame);
        const updateAnimation = () => frame.update(0.01666666666667); // HARDCODED FRAMERATE!
        ticker.add(updateAnimation);

        const textContainer = new Container();
        textContainer.name = 'textContainer';
        textContainer.alpha = 0;
        parentContainer.addChild(textContainer);

        let frameAnimation = frame.state.setAnimation(0, 'Start_Bonus', false);
        frameAnimation.mixDuration = 0.25;
        frame.state.addListener({
            complete: () => {
                frame.state.clearListeners();
                this.showPressAnyButton(false);

                frameAnimation = frame.state.setAnimation(0, 'Static_Bonus', true);
                frameAnimation.mixDuration = 0.25;

                const props = {
                    align: 'center',
                    fontFamily: 'Trebuchet MS',
                    fontSize: 40,
                    stroke: '#fff',
                    fill: '#106e00',
                    strokeThickness: 5,
                    lineJoin: 'round',
                    letterSpacing: 2,
                    fontWeight: 'bold',
                    shadowColor: '#000',
                    shadowOffsetX: 3,
                    shadowOffsetY: 2,
                    shadowBlur: 2
                };

                const textProps = {
                    parentContainer: textContainer,
                    fontImageName: 'numFont',
                    map: numFont,
                    align: 'center',
                    scale: 0.85,
                    fontInterval: -15 // px between symbols
                };

                this.drawCustomFont(10, 472, 304, textProps);

                const youWonText = new Text(`${App.language.youWon}`, props);
                youWonText.name = 'youWon';
                youWonText.anchor.set(0.5);
                youWonText.position.set(485, 275);
                const freeSpinsText = new Text(`${App.language.freeGames}`, props);
                freeSpinsText.name = 'freeSpins';
                freeSpinsText.anchor.set(0.5);
                freeSpinsText.position.set(483, 415);
                textContainer.addChild(freeSpinsText, youWonText);
                this.fade(textContainer, {from: 0, to: 1}, () => {
                    App.updateButton('start', {
                        disabled: false,
                        title: 'start',
                        handler: () => {
                            App.updateButton('start', {disabled: true});
                            frameAnimation = frame.state.setAnimation(0, 'Finish_Bonus', false);
                            frameAnimation.mixDuration = 0.25;
                            this.fade(textContainer, {from: 1, to: 0});
                            frame.state.addListener({
                                complete: () => {
                                    ticker.remove(updateAnimation);
                                    frame.destroy();
                                    this.startBonusAnimation();
                                    App.updateButton('start', {
                                        disabled: false,
                                        title: 'start',
                                        handler: () => this.startBonusAnimation(parentContainer)
                                    });
                                }
                            });
                        }
                    });
                });
            }
        });
    }

    showEndBonusFrame(parentContainer, {x, y}, {win, total}) {
        const ticker = this.app.ticker;
        const frame = new window.PIXI.spine.Spine(this.getJsonTextures('skeleton'));
        frame.name = 'spine';
        frame.position.set(x, y);
        frame.update(0);
        frame.autoUpdate = false;
        parentContainer.addChild(frame);
        const updateAnimation = () => frame.update(0.01666666666667); // HARDCODED FRAMERATE!
        ticker.add(updateAnimation);

        const props = {
            align: 'center',
            fontFamily: 'Trebuchet MS',
            fontSize: 45,
            stroke: '#fff',
            fill: '#106e00',
            strokeThickness: 5,
            lineJoin: 'round',
            letterSpacing: 2,
            fontWeight: 'bold',
            shadowColor: '#000',
            shadowOffsetX: 3,
            shadowOffsetY: 2,
            shadowBlur: 2
        };

        const textContainer = new Container();
        textContainer.name = 'textContainer';
        textContainer.alpha = 0;
        parentContainer.addChild(textContainer);

        const textProps = {
            parentContainer: textContainer,
            fontImageName: 'numFont',
            map: numFont,
            align: 'center',
            scale: 0.70,
            fontInterval: -15 // px between symbols
        };

        this.drawCustomFont(win, 475, 311, textProps);

        const youWonEndText = new Text(`${App.language.youWon}`, props);
        youWonEndText.name = 'youWonEnd';
        youWonEndText.anchor.set(0.5);
        youWonEndText.position.set(485, 275);
        const creditsWonText = new Text(`${App.language.credits}`, props);
        creditsWonText.name = 'creditsWon';
        creditsWonText.anchor.set(0.5);
        creditsWonText.position.set(484, 415);
        textContainer.addChild(youWonEndText, creditsWonText);

        let frameAnimation = frame.state.setAnimation(0, 'Start_Bonus', false);
        frameAnimation.mixDuration = 0.25;
        frame.state.addListener({
            complete: () => {
                frame.state.clearListeners();
                frameAnimation = frame.state.setAnimation(0, 'Static_Bonus', true);
                frameAnimation.mixDuration = 0.25;
            }
        });

        this.fade(textContainer, {from: 0, to: 1}, () => {
            this.tickerTimeout(() => {
                frameAnimation = frame.state.setAnimation(0, 'Finish_Bonus', false);
                frameAnimation.mixDuration = 0.25;
                this.fade(textContainer, {from: 1, to: 0});
                frame.state.addListener({
                    complete: () => {
                        this.endBonus();
                        ticker.remove(updateAnimation);
                        frame.destroy();
                    }
                });
            }, 5000);
        });
    }

    /**
     * Update PIXI stage after language change
     * @param language - current language collection
     */
    translateStage(language) {
        this.InfoScreen.update();
        App.View.state.activeGamble && this.Gamble.draw(true);
        const frame = this.getStageChild('bonusContainer').getChildByName('textContainer');
        if (frame) {
            const youWonText = frame.getChildByName('youWon');
            const freeSpinsText = frame.getChildByName('freeSpins');
            const youWonEndText = frame.getChildByName('youWonEnd');
            const creditsWonText = frame.getChildByName('creditsWon');
            if (youWonText) {
                youWonText.text = `${language.youWon}`;
            }
            if (freeSpinsText) {
                freeSpinsText.text = `${language.freeGames}`;
            }
            if (youWonEndText) {
                youWonEndText.text = `${App.language.youWon}`;
            }
            if (creditsWonText) {
                creditsWonText.text = `${App.language.credits}`;
            }
        }
    }

    drawBonusFrame(first, last, parentContainer, coordinates) {
        parentContainer.removeChildren();
        App.Sounds.stopSound('bonus-background');

        const {startBonusFrame, bonusInBonusFrame, endBonusFrame} = coordinates;
        if (first) {
            App.Sounds.playSound('bookFlash');
            this.getTexture('bonusArea') && this.setBackground('bonusArea');
            this.showStartBonusFrame(parentContainer, startBonusFrame);
        }
        if (last) {
            parentContainer.removeChildren();
            this.setRegularSprite();
            App.updateButton('start', {disabled: true});
            this.setBackground('mainArea');
            this.showEndBonusFrame(parentContainer, endBonusFrame, this.bonusStatus);
            this.playEndBonusGameSound();
        }
        if (!first && !last) {
            this.showBonusInBonusFrame(parentContainer, bonusInBonusFrame);
            App.updateButton('start', {
                disabled: false,
                title: 'start',
                handler: () => this.drawBonusStep(this.latestResponse.features)
            });
        }
    }

    finishBonusAnimation() {
        this.stopAnimateFeature();
        this.bonusStatus.remain === 0 && !this.latestResponse.payment ?
            this.finishBonus() :
            this.getState() === 'SHOW_WIN_LINES' && this.latestResponse.payment ?
                this.refill() :
                this.bonusRoll();
    }

    onInfoStartOpen() {
        const borderSpine = this.getStageChild('borderContainer').getChildByName('borderSpine');
        borderSpine.state.setAnimation(0, 'INFO_OPEN', false);
        this.hideReels();
    }

    onInfoStartClose() {
        const borderSpine = this.getStageChild('borderContainer').getChildByName('borderSpine');
        borderSpine.state.setAnimation(0, 'INFO_CLOSED', false);
        this.showReels();
    }

    hideReels() {
        const destinationY = -372;
        this.showAnimation({
            duration: 400, animations: [
                {
                    sprite: this.getStageChild('reelsStage'),
                    timeline: [{to: {y: destinationY}}]
                }, {
                    sprite: this.getStageChild('linesContainer'),
                    timeline: [{to: {y: this.symbolHeight * this.reelRows}}]
                }
            ]
        });
    }

    showReels() {
        const reelsStage = this.getStageChild('reelsStage');
        reelsStage.interactiveChildren = false;

        this.showAnimation({
            duration: 500, animations: [
                {sprite: reelsStage, timeline: [{to: {y: this.reelTop}}]},
                {sprite: this.getStageChild('linesContainer'), timeline: [{to: {y: 0}}]}
            ], onComplete: () => {
                reelsStage.interactiveChildren = true;
                this.getStageChild('infoMask').destroy();
            }
        });
    }

    /**
     * Animate infoContainer, slide down and update state
     */
    startInfoAnimation() {
        const reelsContainer = this.getStageChild('reelsStage');
        reelsContainer.mask = this.getReelsMask();
        const open = () => {
            this.createInfoContainer(this.getStage());
            const infoContainer = this.getStageChild('infoContainer');
            infoContainer.x = -175;
            const infoMask = this.getReelsMask('infoMask');
            infoContainer.mask = infoMask;
            infoMask.scale.set(1.01, 1.35);
            infoMask.y = -40;
            this.showAnimation({
                duration: 500,
                animations: [{sprite: infoContainer, timeline: [{from: {y: 900}, to: {y: 92}}]}],
                onComplete: () => {
                    JL().debug('-- InfoScreen opened');
                    this.setState('INFO');
                    this.InfoScreen.checkInfoButtons();
                    App.updateButton('close', {disabled: false, handler: this.InfoScreen.close});
                }
            });
            App.updateState('buttons', {animation: 'hide-panel'});
            this.onInfoStartOpen();
        };

        this.checkLoadedResources(open);
    }

    closeInfoAnimation() {
        const infoContainer = this.getStageChild('infoContainer');
        const destinationY = 900;
        this.showAnimation({
            duration: 500,
            animations: [{sprite: infoContainer, timeline: [{to: {y: destinationY}}]}],
            onComplete: () => {
                JL().debug('-- InfoScreen closed');
                this.InfoScreen.reset();
                this.goIdle();
                this.setBackground('mainArea');
                infoContainer.destroy();
            }
        });
        App.updateState('buttons', {animation: 'show-panel'});
        this.onInfoStartClose();
    }

    onRotationDone() {
        JL().debug(`-- Rotation done (fps: ${App.System.statistics.fps})`);
        const {features, payment} = this.latestResponse;
        App.updateButton('start', {disabled: true});
        this.roundWin = 0;
        if (payment > 0 || this.isFreeRoll(features) || features.length) {
            // There is a win

            this.setState('SHOW_WIN_LINES');
            this.startAnimateFeature(features);
        } else {
            if (this.isBonus()) {
                if (this.bonusStatus && this.bonusStatus.remain > 0) {
                    this.roundFinished(false);
                } else {
                    this.Legends.setRoundFinText();
                    this.finishBonus();
                }
            } else {
                this.roundFinished();
                this.Legends.setRoundFinText();
            }
        }
    }

    /**
     * @param payment
     * start logic of big win animation
     */
    goToBigWin(payment) {
        App.Sounds.playSound('big-win-start');
        const stage = this.getStage();
        const ticker = this.app.ticker;

        const bigWinContainer = new Container();
        bigWinContainer.name = 'bigWinContainer';
        bigWinContainer.sortableChildren = true;
        bigWinContainer.zIndex = 8;

        const bigWinImages = new Container();
        bigWinImages.name = 'bigWinImages';
        bigWinImages.zIndex = 2;
        bigWinImages.sortableChildren = true;
        bigWinImages.position.set(this.gameFieldWidth / 2, this.gameFieldHeight / 2);
        bigWinContainer.addChild(bigWinImages);

        stage.addChild(bigWinContainer);

        App.System.sendMetric({param: 'bigWin.count'});
        App.System.sendMetric({param: 'bigWin.payment', value: payment});
        App.System.sendMetric({param: 'bigWin.coefficient', value: payment / this.gameSettings.getBetCredit()});
        const bigWinSprite = new Sprite(this.getTexture('bigWin'));
        bigWinSprite.name = 'bigWin';
        bigWinSprite.position.y = -94;
        bigWinSprite.anchor.set(0.5);

        const lightSprite1 = new Sprite(this.getTexture('light'));
        lightSprite1.name = 'lightSprite1';
        lightSprite1.anchor.set(0.5);
        lightSprite1.position.set(-144, -87);
        lightSprite1.scale.set(0.8);

        const lightSprite2 = new Sprite(lightSprite1.texture);
        lightSprite2.name = 'lightSprite2';
        lightSprite2.anchor.set(0.5);
        lightSprite2.position.set(198, -87);
        lightSprite2.scale.set(0.8);
        bigWinImages.addChild(lightSprite1, lightSprite2);

        ticker.add(this.rotateLight);
        bigWinImages.addChild(bigWinSprite);

        this.drawBigWinText(payment);

        const rect = new Graphics()
            .beginFill(0x000000, 0.5)
            .drawRect(0, 0, this.gameWidth, this.gameHeight);
        rect.zIndex = 0;
        rect.position.set(-(this.gameWidth - this.gameFieldWidth) / 2, 0);
        bigWinContainer.addChild(rect);
    }

    /**
     * @param payment
     */
    drawBigWinText(payment) {
        const bigWinContainer = this.getStageChild('bigWinContainer');

        const bigWinImages = bigWinContainer.getChildByName('bigWinImages');
        const bigWinSprite = bigWinImages.getChildByName('bigWin');
        const lightSprite1 = bigWinImages.getChildByName('lightSprite1');
        const lightSprite2 = bigWinImages.getChildByName('lightSprite2');
        const coinsContainer = new Container();
        const ticker = this.app.ticker;
        const numbersContainer = new Container();

        coinsContainer.zIndex = 1;
        coinsContainer.name = 'coinsContainer';
        bigWinContainer.addChild(coinsContainer);

        this.drawCoinsAnimation(coinsContainer);

        numbersContainer.name = 'numbersContainer';
        numbersContainer.position.set(this.gameFieldWidth / 2, this.gameFieldHeight / 2 + 30);
        numbersContainer.zIndex = 3;
        bigWinContainer.addChild(numbersContainer);

        const textProps = {
            parentContainer: numbersContainer,
            fontImageName: 'bigWinFont',
            map: bigWinFont,
            align: 'center',
            scale: 0.25,
            fontInterval: -40 // px between symbols
        };
        const betCredit = this.gameSettings.getBetCredit();
        const megaWin = betCredit * this.bigWinCoef.megaWin;
        const hugeWin = betCredit * this.bigWinCoef.hugeWin;

        // timeCountUp - duration of numbers animation. Depends on kind of big win.
        const timeCountUp = payment < megaWin ? 5000 : payment < hugeWin ? 7000 : 9000;

        let won = 0;
        const options = {
            values: {won: {start: 0, end: payment, current: 0}},
            duration: timeCountUp
        };

        // hang forceStopBigWin function on start button
        this.tickerTimeout(() => App.updateButton('start', {
            handler: () => this.forceStopBigWin(countUp, textProps, payment),
            disabled: false
        }), 500);
        const countUp = new CountUp(options);

        countUp.onTick(values => {
            numbersContainer.removeChildren();
            won = +values.won.current.toFixed(0);

            if (won > megaWin && won < hugeWin) {
                bigWinSprite.name === 'bigWin' && App.Sounds.playSound('change-type-big-win');
                lightSprite1.position.set(-140, -178);
                lightSprite2.position.set(114, -66);
                numbersContainer.position.y = this.gameFieldHeight / 2 + 40;
                bigWinSprite.name = 'megaWin';
                textProps.scale = 0.3;
                bigWinSprite.texture = this.getTexture('megaWin');
            } else if (won > hugeWin) {
                bigWinSprite.name === 'megaWin' && App.Sounds.playSound('change-type-big-win');
                lightSprite1.position.set(-139, -172);
                lightSprite2.position.set(92, -66);
                numbersContainer.position.y = this.gameFieldHeight / 2 + 30;

                bigWinSprite.name = 'hugeWin';
                textProps.scale = 0.4;
                bigWinSprite.texture = this.getTexture('hugeWin');
            }

            this.drawCustomFont(won, 0, 0, textProps);
            this.Legends.showWinFeatures();
            this.Legends.setStatus('creditsWon', won);

            if (won >= payment) {
                App.updateButton('start', {disabled: true});
                this.tickerTimeout(() => {
                    this.resetCoinsAnimation();
                    ticker.add(this.finishCoinsAnim);
                }, 1500);
            }
        });

        countUp.start();
        this.bigWinCounter = countUp;
    }

    /**
     * @param countUp
     * @param textProps
     * @param payment
     */
    forceStopBigWin(countUp, textProps, payment) {
        App.updateButton('start', {disabled: true});

        const ticker = this.app.ticker;
        const bigWinContainer = this.getStageChild('bigWinContainer');

        const numbersContainer = bigWinContainer.getChildByName('numbersContainer');
        const betCredit = this.gameSettings.getBetCredit();
        const megaWin = betCredit * this.bigWinCoef.megaWin;
        const hugeWin = betCredit * this.bigWinCoef.hugeWin;
        const bigWinSprite = bigWinContainer.getChildByName('bigWinImages').children[2];
        const lightSprite1 = bigWinContainer.getChildByName('bigWinImages').getChildByName('lightSprite1');
        const lightSprite2 = bigWinContainer.getChildByName('bigWinImages').getChildByName('lightSprite2');
        if (payment > megaWin && payment < hugeWin) {
            bigWinSprite.name === 'bigWin' && App.Sounds.playSound('change-type-big-win');
            lightSprite1.position.set(-140, -178);
            lightSprite2.position.set(114, -66);
            numbersContainer.position.y = this.gameFieldHeight / 2 + 40;
            bigWinSprite.name = 'megaWin';
            textProps.scale = 0.3;
            bigWinSprite.texture = this.getTexture('megaWin');
        } else if (payment > hugeWin) {
            bigWinSprite.name === 'megaWin' && App.Sounds.playSound('change-type-big-win');
            lightSprite1.position.set(-139, -172);
            lightSprite2.position.set(92, -66);
            numbersContainer.position.y = this.gameFieldHeight / 2 + 30;
            bigWinSprite.name = 'hugeWin';
            textProps.scale = 0.4;
            bigWinSprite.texture = this.getTexture('hugeWin');
        }

        this.Legends.showWinFeatures();
        this.Legends.setStatus('creditsWon', payment);
        this.resetCoinsAnimation();
        ticker.add(this.finishCoinsAnim);
        countUp.reset();
        numbersContainer.removeChildren();
        this.drawCustomFont(payment, 0, 0, textProps);
    };

    finishBigWin = () => {
        const {features, payment} = this.latestResponse;
        const bigWinContainer = this.getStageChild('bigWinContainer');

        const ticker = this.app.ticker;

        this.resetCoinsAnimation();
        bigWinContainer.alpha -= 0.05;
        if (bigWinContainer.alpha <= 0) {
            bigWinContainer.destroy();
            this.stopAnimateFeature();

            // restore previous bonus win
            this.roundWin = payment;
            this.bonusWin = this.bonusStatus ? this.bonusStatus.win : payment;
            this.setState(this.isBonus() ? 'SHOW_BIG_WIN_LINES' : 'SHOW_WIN_LINES');

            if (this.isFreeRoll(features)) {
                this.bonusEntrance(features);
            } else {
                !this.isBonus() ? this.takeWin() :
                    this.drawBonusStep(features);
            }

            ticker.remove(this.finishBigWin);
            ticker.remove(this.rotateLight);
        }
    };

    /**
     * Function to decide next step after bonus
     * lose and idle
     */
    afterBonusAction() {
        const {payment} = this.latestResponse;
        this.bonusWin = payment;
        if (payment > 0 || this.bonusWin) {
            this.Legends.setText('win', {text: 'win', value: payment});
            this.takeWin();
        } else {
            this.Legends.showJackpot();
            this.Legends.setRoundFinText();
            this.roundFinished();
        }
    }
}
