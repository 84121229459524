import React from 'react';

import './styles.less';
import App from './../../index';
import Tabs from './../tabs';

import Info from './info';
import PaymentMethod from './paymentMethod';
import History from './history';
import Message from './message';
import SupportRules from './supportRules';
import Bonus from './bonus';

export default function Profile({profile, lang, moneyParams}) {
    const active = App.User.isRegisteredUser();
    const bonuses = App.User.get().bonuses;
    const tabs = [
        {title: 'paytable', layout: <Info lang={lang}/>},
        {title: 'deposit', layout: <PaymentMethod type={'deposit'} lang={lang}/>, active, onSelect: () => App.User.uniqueCurrencies.clear()},
        {title: 'withdraw', layout: <PaymentMethod type={'withdraw'} lang={lang}/>, active, onSelect: App.User.uniqueCurrencies.clear()},
        {title: 'history', layout: <History profile={profile} lang={lang}/>, active, onSelect: App.User.updateHistory},
        {title: 'message', layout: <Message profile={profile} lang={lang}/>, active, onSelect: App.User.updateMessage},
        {title: 'bonus', layout: <Bonus bonuses={bonuses} lang={lang}/>, active, onSelect: App.User.updateBonus},
        {title: 'support', layout: <SupportRules lang={lang}/>},
        {title: 'logout', onSelect: App.Modal.showLogOut}
    ];

    return (
        <div
            className='profile'
            onTouchStart={event => event.stopPropagation()}
            onClick={() => App.updateButton('controlButtons', {disabled: true})}
        >
            <div className='content'>
                <header>
                    <button
                        className='close red-color red-shadow'
                        onClick={App.Modal.hideProfile}>←
                    </button>
                    <h1 className='blue-text blue-shadow'>{lang.profile}</h1>
                    <div className='balance blue-text blue-shadow'>
                        <div className='money'>
                            <div className='title'>{lang.balance}: </div>
                            <div className='value'>{moneyParams.money.toFixed(2)} {moneyParams.currency}</div>
                        </div>
                        <div className='insurance'>
                            <div className='title'>{lang.insurance}: </div>
                            <div className='value'> {moneyParams.insurance}</div>
                        </div>
                    </div>
                </header>
                <Tabs tabs={tabs} type='vertical' lang={lang} active={active}/>
            </div>
        </div>
    );
}
