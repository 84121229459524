const bonusFont = {
    fontWidthArray: [
        56, 32.5, 43.94, 48, 30, 27.5, 46, 48, 13,
        23, 41, 29, 74, 51, 54, 34, 56, 39,
        30, 52, 49, 54, 74, 48, 51, 46,

        52, 33, 40, 43, 28, 28, 42, 43, 13,
        21.6, 39, 29, 65, 46, 50, 32, 52, 37,

        29, 45, 44, 49, 66, 45, 45, 43,

        26, 39, 32, 43, 32, 36, 37, 37, 36,
        49, 17, 15, 23
    ],
    fontHeight: 81,
    symbolsOrder: [
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I',
        'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R',
        'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',

        'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i',
        'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r',

        's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
        '1', '2', '3', '4', '5', '6', '7', '8', '9',
        '0', ',', '.', ' '
    ],
    imageResource: 'font/bonusFont.png'
};

export default bonusFont;
