import React from 'react';

import App from './../../index';

export default function History({profile, lang}) {
    const {history} = profile;
    // reset history and request first page
    const refresh = () => {
        App.updateState('profile', {history: null});
        App.User.updateHistory();
    };

    // convert server UTC time to local
    const timeZoneOffset = new Date().getTimezoneOffset() / 60; // in hours
    const getLocalTime = serverTime => {
        const [date, time] = serverTime.split(' ');
        const [day, month, year] = date.split('-');
        const [hour, minute, second] = time.split(':');
        const localTime = new Date(`${month}/${day}/${year} ${hour}:${minute}:${second} UTC`);
        localTime.setHours(localTime.getHours() - timeZoneOffset);

        return localTime.toISOString().slice(0, -5).split('T').join(' ');
    };

    const checkScrollPos = ({target}) => {
        const {scrollHeight, offsetHeight, scrollTop} = target;
        if (offsetHeight + scrollTop >= scrollHeight && App.historyPageLimit <= App.User.get().history.currentPage + 1) {
            App.User.updateHistory(App.User.get().history.currentPage + 1);
        }
    };
    return (
        <div className='history'>
            <h2>{lang.transactionsHistory}</h2>
            <button className='refresh blue-button' onClick={refresh}>
                <span>&#x21bb;</span>
            </button>

            {!history ?
                <div className='history-waiting'>
                    <img src={'./../img/loader.svg'} alt=''/>
                </div> :
                history.payments.length ?
                    <div className='table-wrapper' onScroll={(event) => checkScrollPos(event)}>
                        <table cellSpacing='0' cellPadding='0'>
                            <thead>
                                <tr>
                                    <th className='blue-shadow blue-color' width='100px'>ID</th>
                                    <th className='blue-shadow blue-color' width='140px'>{lang.date}</th>
                                    <th className='blue-shadow blue-color' width='100px'>{lang.type}</th>
                                    <th className='blue-shadow blue-color' width='100px'>{lang.amount}</th>
                                    <th className='blue-shadow blue-color' width='125px'>{lang.balance}</th>
                                    <th className='blue-shadow blue-color' width='110px'>{lang.status}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {history.payments.map(({amount, createdAt, shopOrder, status, type, balance, balanceCurrency, amountCurrency}, index) =>
                                    <tr key={index}>
                                        <td
                                            className='shop-order'
                                            onClick={({target}) => App.copyText(target.innerHTML)}>
                                            {shopOrder}
                                        </td>
                                        <td>{getLocalTime(createdAt)}</td>
                                        <td>{lang[type.toLowerCase()]}</td>
                                        <td>{amount} {amountCurrency}</td>
                                        <td>{balance} {balanceCurrency}</td>
                                        <td><span className={`status ${status.toLowerCase()}`}>{status}</span></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div> :
                    <div className='history-waiting'>
                        <span className='no-data'>{lang.noData}</span>
                    </div>}
        </div>
    );
}
