import * as PIXI from 'pixi.js-legacy';
import React from 'react';
import {JL} from 'jsnlog';

import App from './../../index';
import Game from './../Game';

/* PIXI aliases */
const Text = PIXI.Text,
    Container = PIXI.Container,
    Sprite = PIXI.Sprite,
    AnimatedSprite = PIXI.AnimatedSprite;

export default class GameMegajack extends Game {
    constructor() {
        super();

        // reel properties
        this.reelTop = 114; // magic number - where reel images starts
        this.reelXCoordinates = [87, 215, 343, 470, 598]; // magic numbers - x coordinates where reels starts
        this.symbolHeight = 110; // height of a single symbol
        this.symbolWidth = 115; // width of a single symbol
        this.stopOneReel = true;
        this.choosingWin = 0;
        this.waitingType = '';

        // roll properties
        this.reelSettings = [12, 5, 5]; // 0 - start symbol amount, 1 - reel increase regular roll, 2 - reel increase long roll
        this.rollProperties = {
            reelSpeed: 1.7,
            springDown: 0.2,
            springUp: 0.1
        };
        this.textBonus = {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 26,
            fontWeight: 'bold',
            fill: '#FFFF00',
            stroke: '#282828',
            strokeThickness: 5,
            lineJoin: 'round'
        };

        this.DOMComponents.gambleComponent = buttons => <div className='gamble open'>
            {App.Game.DOMComponents.gambleCanvas}
            <div
                className='gamble-cards'
                style={{
                    top: `${this.Gamble.gamble_cards.top}%`,
                    left: `${this.Gamble.gamble_cards.left}%`,
                    height: `${this.Gamble.gamble_cards.height}%`
                }}>
                {[0, 1, 2, 3].map(item => <div
                    key={item}
                    className='gamble-cards-item'
                    style={{
                        margin: `${this.Gamble.gamble_cards_item.margin_left}em`,
                        width: `${this.Gamble.gamble_cards_item.width}%`
                    }}
                    onClick={() => buttons.cardIndex.handler && buttons.cardIndex.handler(item)}
                />)}
            </div>
        </div>;
    }

    /**
     * Update PIXI stage after language change
     * @param language - current language collection
     */
    translateStage(language) {
        this.InfoScreen.update();
    }

    /**
     * Start scatter animations
     * @param features
     */
    bonusEntrance(features) {
        this.setState('SCATTER_FEATURE');
        this.playBonusGameSound();
        this.stopAnimateFeature();
        this.drawTopAnimation(this.getStageChild('bonusContainer'));
        App.updateButton('start', {disabled: true});

        switch (App.restoreGameState) {
            case 'FREE_ROLL': // if restore win lines -> show roll
                setTimeout(() => // call after scatter played
                    this.drawBonusAskButton(this.isFreeRoll(features) && !this.bonusStatus), 2000);
                break;
            case 'CHOOSING': // if restore win lines -> show roll
                this.setState('CHOOSING_IDLE');
                this.hideBoxes();
                this.setBackground('bonusArea');
                this.stopAnimateFeature();
                this.choosingWin = this.latestResponse.payment;
                setTimeout(() => // call after scatter played
                    this.showChosedItem(this.getStageChild('bonusContainer')), 2000);
                break;
        }
    }

    setGambleUpStatus() {
        this.Legends.setStatus('gambleUp');
    }

    /**
     * Возвращает true, если уже идет бонус или выпал вход в бонус в прокруте
     */
    getBonusType = () => {
        let bonusType;

        if (this.latestResponse?.features.some(({uc}) => ['FREE_ROLL'].includes(uc))) {
            bonusType = 'FREE_ROLL';
        }

        if (this.latestResponse?.features.some(({uc}) => ['CHOOSING'].includes(uc))) {
            bonusType = 'CHOOSING';
        }

        return bonusType;
    };

    /**
     * Функция востановления выбора после чузинга
     */
    restoreChoosingScreen(response) {
        JL().debug(`-- Restore choosing: ${JSON.stringify(response)}`);
        this.setState('CHOOSING_IDLE');
        this.Legends.clearStatus();
        this.hideBoxes();
        this.Legends.showWinFeatures();
        this.disableChooseButtons();
        const container = this.getStageChild('bonusContainer');

        response.stop ?
            this.finishChoosing(response, container) :
            response.extension ?
                this.startSuperBonus(response, container) :
                this.restoreChoosing(response, container);
    }

    /**
     * Функция окончания чузинга и выхода
     */
    finishChoosing(response, parentContainer) {
        parentContainer.removeChildren();
        this.setBackground('mainArea');
        this.showBoxes();
        this.getStageChild('bonusContainer').removeChildren();
        App.updateState('buttons', {visualization: App.buttonsType});
        this.Buttons.setDefaultGameButtons();

        this.choosingResponse = [];
        // redefine only for take win, prevent roll after choosing
        this.latestResponse = {payment: response.win, features: []};
        this.gameFlag.bonusStart = false;
        App.updateButton('autoStart', {disabled: false});

        response.win ?
            this.takeWin() :
            this.roundFinished();
    }

    /**
     * Фукнция отображения выбора Чузинга на ответ сервера
     */
    showChoice(response) {
        this.setState('CHOOSING_ANIMATION');
        this.superBonusStep < response.step ? // супер бонус игра
            this.showSuperChoiceAnimation(response) :
            this.showChoiceAnimation(response);
    }

    /**
     * Функция отрисовки Супер Бонуса
     */
    startSuperBonus(response, parentContainer) {
        JL().debug('-- Start super bonus');
        this.setState('SUPER_BONUS_IDLE');
        this.initSuperBonusSprites(response, parentContainer);
        this.Legends.setText('win', {text: 'win', value: response.win});
        this.Legends.setStatus('creditsWon', response.win);
    }

    sendGamblePacket = () => App.Socket.send(JSON.stringify({uc: 'DOUBLING-SEED'}));

    /**
     * Take new wish and send new choice
     * Stop all animations and disable buttons
     */
    newChoice(step) {
        JL().debug(`-- New choice: ${step}`);
        this.disableChooseButtons();
        App.Socket.send(JSON.stringify({uc: 'MAKE-CHOOSING', choice: step}));
    };

    /**
     * Set global state for Game
     * @param state
     */
    setState(state) {
        this.state = state;
        this.app && this.addWaitingAnimationSprites(this.getStageChild('waitingContainer'));
    }

    /**
     * Фунция анимации зачисления кредитов в чузинге
     */
    animateMultiplier(response) {
        this.setState('ANIMATE_MULTIPLIER');
        this.screenMultiplier.mult = this.getMultiplier(response);
        this.superBonusStep < response.step ?
            this.animateSuperBonusMultiplier(this.superChoosing, response, Date.now()) :
            this.animateBonusMultiplier(this.choosing, response, Date.now());
    }

    getMultiplier(response) {
        const multiplier = [];
        for (let i = 0; i <= this.superBonusStep; i++) {
            multiplier.push(-1);
        }
        response.prevChoosings.forEach(choosing => {
            multiplier[parseInt(choosing.choice, 10)] = choosing.multiplier;
        });
        if (response.multiplier !== undefined && parseInt(response.choice, 10) <= this.superBonusStep) {
            multiplier[parseInt(response.choice, 10)] = response.multiplier;
        }
        return (multiplier);
    }

    /**
     * Функция анимации зачисления кредитов в простой бонусной игре (5 множителей)
     */
    animateBonusMultiplier(choosing, response, animationStarted = Date.now(), lastTime = animationStarted) {
        const time = Date.now();
        const timeDiff = time - lastTime;
        const animateTimeout = this.isSpeedUp ? 20 : 50;   // частота вызова функции зависит от первой и второй скорости
        if (timeDiff < animateTimeout) {
            requestAnimationFrame(this.animateBonusMultiplier.bind(this, choosing, response, animationStarted, lastTime));
            return;
        }
        lastTime = time;
        let iStep = 1;
        const parentContainer = this.getStageChild('bonusContainer');
        App.Sounds.playSound('add-credit1');

        this.screenMultiplier.mult.forEach((multiplier, choiceIndex) => {
            parentContainer.removeChild(parentContainer.getChildByName('choiceMultiplier' + choiceIndex));

            if (multiplier > 0 && iStep !== 0) {
                this.choosingWin = this.choosingWin + this.gameSettings.getBetCredit() * iStep;
                this.screenMultiplier.mult[choiceIndex] -= iStep;
                iStep = 0;
            }
            if (multiplier >= 0) {
                multiplier = this.screenMultiplier.mult[choiceIndex];
                const x = this.choosing[choiceIndex].textPosition.x - (multiplier.toString().length - 1) * 10;
                const y = this.choosing[choiceIndex].textPosition.y;
                const richText = new Text(multiplier, this.textBonus);
                richText.position.set(x, y);
                richText.name = 'choiceMultiplier' + choiceIndex;
                parentContainer.addChild(richText);
            }
        });

        this.Legends.setText('win', {text: 'win', value: this.choosingWin});
        this.Legends.setStatus('creditsWon', this.choosingWin);

        const sumScreenMultiplier = this.screenMultiplier.mult.reduce((sum, x) => {
            return sum + (x === -1 || x === -2 ? 0 : x);
        }, 0);

        if (sumScreenMultiplier > 0) {
            requestAnimationFrame(this.animateBonusMultiplier.bind(this, choosing, response, animationStarted, lastTime));
        } else {
            this.roundWin = this.choosingWin;
            setTimeout(() => response.stop ?
                this.finishChoosing(response) :
                this.startSuperBonus(response), 2000); // Close bonus results after 2 sec.
        }
    }

    createChoosingElement(parentContainer, image, width, height, posX, posY, spriteRow, frameCount, colCount, animate = false, visible = true) {
        const waiting = new AnimatedSprite(this.getSpriteTextures({
            width, height,
            image,
            spriteRow, colCount,
            toFrame: frameCount
        }));
        waiting.name = 'waiting';
        waiting.animationSpeed = 0.1;
        waiting.position.set(posX, posY);
        waiting.loop = false;
        waiting.visible = visible;
        animate && waiting.play();
        parentContainer.addChild(waiting);
        return waiting;
    };

    /**
     * Start animate status text 'press any button'
     */
    showPressAnyButton(isLast) {
        // don't show status text on last frame
        clearInterval(this.pressAnyButtonInterval);
        !isLast && (this.pressAnyButtonInterval = setInterval(() => {
            this.Legends.setStatus('pressAnyButton');
        }, 500));
    }

    showFeatureLine(currentFeature) {
        const {number, reels, payment, uc} = currentFeature; // get current feature params

        const container = this.getStageChild('linesContainer');
        uc !== 'SPECIAL_SYMBOL' && container.removeChildren(); // don't clear lines before special symbol (bookGame fill)

        this.reelMatrix.forEach(reel =>
            reel.forEach(symbolObj => symbolObj.sprite.gotoAndStop(0)));

        if (['WIN_LINE', 'SCATTER'].includes(uc)) {
            uc === 'WIN_LINE' && this.Lines.drawLineImages([number], reels, container, true, payment);
            this.Lines.drawBoxes(this.getStageChild('boxesContainer'), number);
            this.getState() !== 'SHOW_BONUS_WIN_LINES' && this.createFeatureInfo(currentFeature, container);
            this.animateSymbolsInLine(currentFeature);
        }
    }

    createFeatureInfo(feature, container) {
        const {number, symbol, reels, positions, payment, uc} = feature; // get current feature params
        const ucReels = uc === 'WIN_LINE' ? reels.length : positions.length; // check 'SCATTER' feature

        const textProps = {
            font: 'Arial',
            fontSize: 16,
            fontWeight: 600,
            fill: '#f0e7cb'
        };
        const winSymbolsContainer = new Container();
        winSymbolsContainer.position.set(-100, -170);
        winSymbolsContainer.zIndex = 200;
        winSymbolsContainer.name = 'winSymbolsContainer';
        container.addChild(winSymbolsContainer);
        const statusLine = new Text(
            `${uc === 'WIN_LINE' ? App.language.line.toUpperCase() : 'SCATTERED'} ${uc === 'WIN_LINE' ? number + 1 : ''}: `,
            textProps
        );
        statusLine.position.set(240, 468);

        const x2 = (this.gameFlag.bonusStart && uc === 'WIN_LINE' &&
            !((symbol === 9 && reels.length === 5) || (symbol === 11 && reels.length === 5))) ?
            'x2' : '';
        const statusPayment = new Text(
            x2 + ` = ${payment} `,
            textProps
        );
        statusPayment.position.set(480, 468);

        for (let i = 0; i <= ucReels - 1; i++) {
            const minimizeSymbol = new Sprite(this.getSpriteTextures({
                image: 'minimizeSymbols',
                fromFrame: symbol,
                colCount: this.symbols.length,
                width: 25,
                height: 23
            })[0]);

            minimizeSymbol.position.x = 450 + (25 * i);
            minimizeSymbol.position.y = 636;
            winSymbolsContainer.addChild(minimizeSymbol);
        }

        container.addChild(statusLine, statusPayment);
    }

    getFeatureDelay(currentFeature, features) {
        let delay = 0;

        switch (currentFeature.uc) {
            case 'WIN_LINE':
            case 'CHOOSING':
                delay = this.winLineFeatureDelay;
                break;
            case 'SCATTER':
                delay = this.winLineFeatureDelay + 500;
                break;
        }

        return delay;
    }

    /**
     * Draw long scatter animation before bonus
     * @param scatterFeature
     * @param callback
     */
    playScatterAnimation(scatterFeature, callback) {
        this.setScatterSprite(scatterFeature);

        // get first scatter position
        const {reel, row} = scatterFeature.positions[0];
        const symbolObj = this.reelMatrix[reel][row];

        // call after first scatter played
        symbolObj.sprite.onComplete = () => {
            symbolObj.sprite.onComplete = null; // clear event
            callback();
        };
    }

    /**
     * Create additional sprites and animations
     * Call once when game loaded
     * @param parentContainer
     */
    addWaitingAnimationSprites(parentContainer) {
        const waitingType = this.getWaitingState();
        let sprite;

        if (this.waitingType === waitingType) {
            return;
        }

        parentContainer.removeChildren();
        switch (waitingType) {
            case 'DOUBLING':
                break;
            case 'IDLE':
            default:  // idle response resived
                sprite = new AnimatedSprite(this.getSpriteTextures({
                    toFrame: 3, image: 'waiting_roll1',
                    width: 167, height: 30
                }));
                sprite.name = 'waiting_roll1';
                sprite.position.set(55, 66);
                sprite.animationSpeed = 0.15;
                sprite.play();
                parentContainer.addChild(sprite);

                sprite = new AnimatedSprite(this.getSpriteTextures({
                    toFrame: 3, image: 'waiting_roll2',
                    width: 167, height: 30
                }));
                sprite.name = 'waiting_roll2';
                sprite.position.set(610, 66);
                sprite.animationSpeed = 0.15;
                sprite.play();
                parentContainer.addChild(sprite);
                break;
        }
        this.waitingType = waitingType;
    }

    /**
     * Get current Game state
     * @returns {string|*}s
     */
    getWaitingState() {
        let state = this.getState();
        switch (this.state) {
            case 'CHOOSING_IDLE':
            case 'CHOOSING_ANIMATION':
            case 'ANIMATE_MULTIPLIER':
            case 'DOUBLING':
                state = 'DOUBLING';  // no aniamtion
                break;
            case 'RESPONSE_RECEIVED':
            case 'IDLE':
            case 'SHOW_WIN_LINES':
            case 'ASK_GAMBLE':
            default:
                state = 'IDLE';  // idle main animation
                break;
        }
        return state;
    }

    /**
     * Функция отключения кнопок в чузинге
     */
    disableChooseButtons() {
        App.updateButton('start', {disabled: true});
        App.updateButton('autoStart', {disabled: true});
        this.Buttons.disableAllButtons();
    }

    /**
     * End feature animation
     * @returns {boolean} - new animation features circle
     */
    endAnimateFeature() {
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        if (this.getState() === 'SHOW_WIN_LINES' && this.latestResponse.payment) {
            this.isAutoStart ?
                this.takeWin() :
                this.latestResponse.payment > 0 ? // если есть анимация скатера, например, без выигрыша анимируем, а потом выходит без зачисления
                    this.gambleOrTakeWin() :
                    this.roundFinished();
        } else { // no win, just feature without payment
            this.roundFinished();
        }
        return true;
    }
}
