import CustomLines from './../witch-hunters/lines';
import App from './../../../index';

export default class Lines extends CustomLines {
    constructor() {
        super();
        this.imageResources = {};
        this.linesSettings = {
            ...this.linesSettings,
            borderColor: '#000000',
            lineColor: '#0f5532',
            lineThickness: 3,
            borderThickness: 5
        };
        this.lines = {
            0: {coordinates: [1, 1, 1, 1, 1], boxes: [{x: 85, y: -10}]},
            1: {coordinates: [0, 0, 0, 0, 0], boxes: [{x: 85, y: -4}]},
            2: {coordinates: [2, 2, 2, 2, 2], boxes: [{x: 85, y: 1}]},
            3: {
                coordinates: [0, 1, 2, 1, 0], boxes: [{x: 85, y: 9}],
                points: {2: -45, 4: 45}
            },
            4: {
                coordinates: [2, 1, 0, 1, 2], boxes: [{x: 85, y: 8}],
                points: {2: 45, 4: -45}
            },
            5: {coordinates: [1, 2, 2, 2, 1], boxes: [{x: 875, y: -19}]},
            6: {coordinates: [1, 0, 0, 0, 1], boxes: [{x: 875, y: -29}]},
            7: {coordinates: [2, 2, 1, 0, 0], boxes: [{x: 875, y: 32}],
                points: {3: 50}
            },
            8: {coordinates: [0, 0, 1, 2, 2], boxes: [{x: 875, y: 17}],
                points: {3: -50}
            },
            9: {coordinates: [2, 1, 1, 1, 0], boxes: [{x: 875, y: 45}]},
            10: {coordinates: [0, 1, 1, 1, 2], boxes: [{x: 875, y: -30}]},
            11: {coordinates: [1, 2, 1, 0, 1], boxes: [{x: 85, y: -41}],
                points: {3: 50}
            },
            12: {coordinates: [1, 0, 1, 2, 1], boxes: [{x: 85, y: 31}],
                points: {3: -50}
            },
            13: {coordinates: [0, 1, 0, 1, 0], boxes: [{x: 85, y: 61}]},
            14: {coordinates: [2, 1, 2, 1, 2], boxes: [{x: 85, y: -45}]},
            15: {coordinates: [1, 1, 0, 1, 1], boxes: [{x: 875, y: 19}]},
            16: {coordinates: [1, 1, 2, 1, 1], boxes: [{x: 875, y: 53}]},
            17: {coordinates: [0, 2, 0, 2, 0], boxes: [{x: 875, y: -23}]},
            18: {coordinates: [2, 0, 2, 0, 2], boxes: [{x: 85, y: 38}]},
            19: {coordinates: [2, 0, 1, 0, 2], boxes: [{x: 875, y: -16}]}
        };

        this.lineMap = [
            3, 13, 1, 11, 0, 12, 2, 14, 4, 18,
            9, 17, 7, 15, 5, 16, 6, 10, 8, 19
        ];
    }

    drawBoxes(parentContainer) {

    }

    /**
     * Create virtual <canvas> for draw lines
     * Convert canvas to sprite
     * @param lines
     * @param winReels
     * @param parentContainer
     * @param winLine
     * @param payment
     */
    drawLineImages(lines, winReels, parentContainer, winLine, payment) {
        parentContainer.removeChildren();
        this.linesCtx.clearRect(0, 0, this.linesCanvas.width, this.linesCanvas.height);

        lines.forEach(lineIndex => {
            parentContainer.sortableChildren = true;

            const line = this.lines[lineIndex];

            const spineLine = new window.PIXI.spine.Spine(App.Game.getSpineData('line'));
            spineLine.name = lineIndex;

            const typeOfAnimation = winLine ? 'animation' : 'static';
            spineLine.position.set(85, typeOfAnimation === 'animation' ? 0 : line.boxes[0].y);

            spineLine.state.setAnimation(0, typeOfAnimation, true);

            for (let i = 1; i <= 5; i++) {
                const bone = spineLine.skeleton.findBone(`LinePoint${i}`);
                bone.y = -(App.Game.reelTop + App.Game.symbolHeight * (line.coordinates[i - 1] + 0.5));
            }

            line.points && Object.keys(line.points).forEach(point => {
                const bone = spineLine.skeleton.findBone(`LinePoint${point}`);
                bone.rotation = line.points[point];
            });

            parentContainer.addChild(spineLine);
        });
    }
}
