const bonusFont = {
    fontWidthArray: [
        38, 34, 34, 36, 32, 32, 36, 36, 16,
        30, 36, 30, 42, 36, 40, 32, 42, 35,
        34, 34, 35, 38, 47, 38, 35, 33, 28,

        30, 28, 30, 27, 22, 30, 30, 16, 26,
        31, 16, 44, 30, 30, 30, 30, 22, 28,
        21, 29, 29, 40, 30, 29, 25, 23, 31,

        30, 33, 31, 32, 30, 31, 31, 32, 8
    ],
    fontHeight: 49,
    symbolsOrder: [
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I',
        'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R',
        'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',

        'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i',
        'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r',
        's', 't', 'u', 'v', 'w', 'x', 'y', 'z',

        '1', '2', '3', '4', '5', '6', '7', '8', '9',
        '0', ' '
    ],
    imageResource: 'font/bonusFont.png'
};
export default bonusFont;
