import {JL} from 'jsnlog';
import Lines from './../../lines';

export default class Lines20 extends Lines {
    constructor(imageResources) {
        super();
        this.imageResources = imageResources;

        this.lines = {
            0: {coordinates: [1, 1, 1, 1, 1], boxes: [{x: 1, y: 94}]},
            1: {coordinates: [0, 0, 0, 0, 0], boxes: [{x: 1, y: 213}]},
            2: {coordinates: [2, 2, 2, 2, 2], boxes: [{x: 1, y: 335}]},
            3: {coordinates: [0, 1, 2, 1, 0], boxes: [{x: 1, y: 459}]},
            4: {coordinates: [2, 1, 0, 1, 2], boxes: [{x: 772, y: 138}]},
            5: {coordinates: [0, 0, 1, 2, 2], boxes: [{x: 772, y: 246}]},
            6: {coordinates: [2, 2, 1, 0, 0], boxes: [{x: 772, y: 308}]},
            7: {coordinates: [1, 2, 2, 2, 1], boxes: [{x: 772, y: 418}]},
            8: {coordinates: [1, 0, 0, 0, 1], boxes: [{x: 1, y: 108}]},
            9: {coordinates: [0, 1, 1, 1, 0], boxes: [{x: 1, y: 168}]},
            10: {coordinates: [2, 1, 1, 1, 2], boxes: [{x: 1, y: 381}]},
            11: {coordinates: [1, 2, 1, 0, 1], boxes: [{x: 1, y: 443}]},
            12: {coordinates: [1, 0, 1, 2, 1], boxes: [{x: 772, y: 183}]},
            13: {coordinates: [0, 1, 0, 1, 0], boxes: [{x: 1, y: 227}]},
            14: {coordinates: [2, 1, 2, 1, 2], boxes: [{x: 1, y: 320}]},
            15: {coordinates: [1, 1, 2, 1, 1], boxes: [{x: 772, y: 368}]},
            16: {coordinates: [1, 1, 0, 1, 1], boxes: [{x: 772, y: 93}]},
            17: {coordinates: [0, 2, 0, 2, 0], boxes: [{x: 772, y: 338}]},
            18: {coordinates: [2, 0, 2, 0, 2], boxes: [{x: 772, y: 214}]},
            19: {coordinates: [1, 0, 2, 0, 1], boxes: [{x: 772, y: 461}]}
        };

        JL().debug('-- Lines20 initialized');
    }

    drawWinBox = (parentContainer, reelIndex, rowIndex) => {

    };
}
