import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import GameAmatic from './../../amatic/game';
import GambleAmatic from './../../amatic/gamble';
import Lines from './lines';
import InfoScreen from '../../infoScreen';
import CountUp from './../../../modules/countUp';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Text = PIXI.Text,
    Container = PIXI.Container,
    AnimatedSprite = PIXI.AnimatedSprite;

export default class BookOfFortune extends GameAmatic {
    constructor() {
        super();
        this.id = 'book-of-fortune';
        this.name = 'Book of Fortune';
        this.buttonsPanelShadow = 'none no-blur';
        this.symbolWidth = 136;
        this.symbolHeight = 120;
        this.transparentBackground = true;
        this.reelXCoordinates = [38, 185, 332, 478, 624]; // magic numbers - x coordinates where reels starts
        this.scatter = 9;

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 90, y: 88},
            bonusInBonusFrame: {x: 90, y: 105},
            endBonusFrame: {x: 90, y: 105}
        };

        this.symbols = [
            {regularDelay: 80, bonusDelay: 200, payment: [0, 0, 0, 5, 25, 100]},        // 0 - цифра 10
            {regularDelay: 80, bonusDelay: 200, payment: [0, 0, 0, 5, 25, 100]},        // 1 - буква J
            {regularDelay: 80, bonusDelay: 200, payment: [0, 0, 0, 5, 25, 100]},        // 2 - буква Q
            {regularDelay: 80, bonusDelay: 200, payment: [0, 0, 0, 5, 40, 150]},        // 3 - буква K
            {regularDelay: 100, bonusDelay: 200, payment: [0, 0, 0, 5, 40, 150]},       // 4 - буква A
            {regularDelay: 100, bonusDelay: 200, payment: [0, 0, 5, 30, 100, 750]},     // 5 - старик
            {regularDelay: 100, bonusDelay: 200, payment: [0, 0, 5, 30, 100, 750]},     // 6 - сова
            {regularDelay: 100, bonusDelay: 200, payment: [0, 0, 5, 40, 400, 2000]},    // 7 - дворец
            {regularDelay: 100, bonusDelay: 200, payment: [0, 0, 10, 100, 1000, 5000]}, // 8 - девушка
            {regularDelay: 100, scatterDelay: 100, payment: [0, 0, 0, 2, 20, 200]}      // 9 - книга
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png'
            }),
            atlas: this.mergePath([
                'staticSymbols.json',
                'regularShortSymbols.json'
            ])
        };
        this.additionalResources = {
            main: this.mergePath({
                bonusArea: 'area/bonus.png',
                frame: 'bonus/frame.png',
                frame2: 'bonus/frame2.png',
                frame3: 'bonus/frame3.png',
                book: 'bonus/book.png',
                paymentBook: 'bonus/paymentBook.png',
                bookAnim: 'bonus/bookAnim.png',
                minimizeSymbols: 'minimizeSymbols.png',
                paymentAnimation: 'bonus/paymentAnimation.png'
            }),
            atlas: this.mergePath([
                'bonus/scatterSymbols.json',
                'bonus/bonusSymbols.json'
            ])
        };
        this.gameSounds = {
            soundClass: 'amatic',
            sounds: [
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'book-open', alias: 'bookOpen'},
                {name: 'book-flip-page', alias: 'bookFlipPage'},
                {name: 'book-selected-symbol', alias: 'bookSelectedSymbol'},
                {name: 'bonus-win-symbols-show', alias: 'bonusWinSymbols'},
                {name: 'bonus-game-end', alias: 'bonusGameEnd'},
                {name: 'free-spins-end', alias: 'endFreeSpins'},
                // {name: 'bonus-game-end', alias: 'bonusGameStart'},
                {name: 'bonus-background', loop: true},
                {name: 'add-free-spin', alias: 'addFreeSpin', loop: true, path: '/audio/amatic/'}
            ],
            path: `/game/games/${this.id}/audio/`
        };

        this.Lines = new Lines(this.mergePath({boxes: 'lines/boxes.png'}));
        this.Gamble = new GambleAmatic(this.mergePath({gambleArea: 'gamble/gamble-area.png'}));
        this.InfoScreen = new InfoScreen({pages: 4}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines - current lines count
     * @param bet - current bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        switch (page) {
            case 1:
                ctx.font = 'bold 18pt Times New Roman';
                ctx.fillStyle = '#fff710';
                ctx.lineWidth = 2;
                ctx.textAlign = 'center';

                // книга
                ctx.fillText(bet * nLines * this.symbols[9].payment[5], 400, 180);
                ctx.fillText(bet * nLines * this.symbols[9].payment[4], 400, 207);
                ctx.fillText(bet * nLines * this.symbols[9].payment[3], 400, 234);
                // девушка
                ctx.fillText(bet * this.symbols[8].payment[5], 200, 70);
                ctx.fillText(bet * this.symbols[8].payment[4], 200, 94);
                ctx.fillText(bet * this.symbols[8].payment[3], 200, 118);
                ctx.fillText(bet * this.symbols[8].payment[2], 200, 142);
                // дворец
                ctx.fillText(bet * this.symbols[7].payment[5], 700, 70);
                ctx.fillText(bet * this.symbols[7].payment[4], 700, 94);
                ctx.fillText(bet * this.symbols[7].payment[3], 700, 118);
                ctx.fillText(bet * this.symbols[7].payment[2], 700, 142);
                // сова
                ctx.fillText(bet * this.symbols[6].payment[5], 200, 195);
                ctx.fillText(bet * this.symbols[6].payment[4], 200, 219);
                ctx.fillText(bet * this.symbols[6].payment[3], 200, 243);
                ctx.fillText(bet * this.symbols[6].payment[2], 200, 267);
                // старик
                ctx.fillText(bet * this.symbols[5].payment[5], 700, 195);
                ctx.fillText(bet * this.symbols[5].payment[4], 700, 219);
                ctx.fillText(bet * this.symbols[5].payment[3], 700, 243);
                ctx.fillText(bet * this.symbols[5].payment[2], 700, 267);
                // AK
                ctx.fillText(bet * this.symbols[4].payment[5], 300, 330);
                ctx.fillText(bet * this.symbols[4].payment[4], 300, 354);
                ctx.fillText(bet * this.symbols[4].payment[3], 300, 378);
                // QJ10
                ctx.fillText(bet * this.symbols[2].payment[5], 630, 330);
                ctx.fillText(bet * this.symbols[2].payment[4], 630, 354);
                ctx.fillText(bet * this.symbols[2].payment[3], 630, 378);

                ctx.font = 'bold 14pt Times New Roman';
                ctx.fillText('3 or more BOOK symbols on', 265, 425);
                ctx.fillText('any position win 10', 265, 450);
                ctx.fillText('BONUSSPINS', 265, 475);
                ctx.fillText('BOOK substitutes for all', 622, 425);
                ctx.fillText('symbols', 622, 475);

                ctx.font = 'bold 12pt Times New Roman';
                ctx.fillStyle = 'white';
                ctx.fillText('MALFUNCTION VOIDS ALL PAYS AND PLAYS.', 400, 535);
                break;
            case 2:
                ctx.font = 'bold 18pt Times New Roman';
                ctx.fillStyle = '#fff710';
                ctx.fillText('Wins pay only from left to right!', 400, 490);

                break;

            case 3:
                ctx.font = 'bold 18pt Times New Roman';

                ctx.fillText('BOOK substitutes for all symbols.', 325, 120);
                ctx.fillText('3, 4 or 5 BOOK symbols pay on any', 325, 245);
                ctx.fillText('position.', 325, 300);
                ctx.fillText('3 or more BOOK symbols on any', 325, 390);
                ctx.fillText('position win 10 BONUSSPINS', 325, 445);
                break;

            case 4:
                ctx.font = 'bold 20pt Times New Roman';

                ctx.fillText('BOOK substitutes for all symbols. 3 or', 295, 50);
                ctx.fillText('more BOOK symbols on any position win', 295, 85);
                ctx.fillText('10 BONUSSPINS.', 295, 120);
                ctx.fillText('At the beginning of the BONUSSPINS the', 295, 185);
                ctx.fillText('expanding symbol is randomly selected;', 295, 215);
                ctx.fillText('it could be any except BOOK. The', 295, 245);
                ctx.fillText('selected symbol will pay accordingly to', 295, 275);
                ctx.fillText('the paytable, in all active lines, no matter', 295, 305);
                ctx.fillText('whether the symbols of the winning', 295, 335);
                ctx.fillText('combination are consecutive or not.', 295, 362);
                ctx.fillText('Play the Bonus until you have no', 295, 400);
                ctx.fillText('BONUSSPINS left. BONUSSPINS can be', 295, 435);
                ctx.fillText('re-triggered during the Bonus.', 295, 470);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    drawBonusFrame(first, last, parentContainer, coordinates) {
        parentContainer.removeChildren();
        App.Sounds.stopSound('bonus-background');

        const {startBonusFrame, bonusInBonusFrame, endBonusFrame} = coordinates;
        if (first) {
            App.Sounds.playSound('bookFlash');
            this.showStartBonusFrame(parentContainer, startBonusFrame);
            App.updateButton('start', {
                disabled: false,
                title: 'start',
                handler: () => this.startBonusAnimation(parentContainer)
            });
        }
        if (last) {
            parentContainer.removeChildren();
            this.setRegularSprite();
            App.updateButton('start', {disabled: true});
            this.setBackground('mainArea');
            this.showEndBonusFrame(parentContainer, endBonusFrame, this.bonusStatus);
            this.playEndBonusGameSound();
            setTimeout(() => this.endBonus(), 5000);
        }
        if (!first && !last) {
            this.showBonusInBonusFrame(parentContainer, bonusInBonusFrame);
        }
    }

    /**
     * Draw bonus enter win frame
     * @param parentContainer
     * @param x
     * @param y
     */
    showStartBonusFrame(parentContainer, {x, y}) {
        App.Sounds.playSound('bonusGameStart');
        this.showBonusFrame(parentContainer, x, y);
        this.showPressAnyButton(false);

        const props = {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 30,
            fontWeight: 'bold',
            fill: 'white',
            lineJoin: 'round'
        };
        const richText = new Text('CONGRATULATIONS YOU WIN!', props);
        props.fontSize = 59;
        const richText2 = new Text('10 BONUSSPINS', props);
        props.fontSize = 17;
        const richText3 = new Text('Chosen symbol expands and wins\non every winline', props);
        richText.x = 175;
        richText.y = 176;
        richText2.x = 163;
        richText2.y = 224;
        richText3.x = 351;
        richText3.y = 309;
        parentContainer.addChild(richText, richText2, richText3);
    }

    /**
     * Отрисовка таблички бонус в бонусе
     */
    showBonusInBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y, 'frame3');
        setTimeout(() => {
            App.updateButton('start', {
                disabled: false,
                title: 'start',
                handler: () => this.drawBonusStep(this.latestResponse.features)
            });
            App.Sounds.stopSound('addFreeSpin');

            this.showPressAnyButton(false);
        }, 5100);

        const props = {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 50,
            fontWeight: 'bold',
            fill: 'white',
            lineJoin: 'round'
        };
        const won = new Text(`${10}`, props);
        won.position.set(270, 285);
        const total = new Text(`${this.bonusStatus.total - 10}`, props);
        total.position.set(493, 285);
        parentContainer.addChild(won, total);

        const options = {
            values: {
                total: {start: 10, end: 0, current: 10},
                won: {
                    start: this.bonusStatus.total - 10,
                    end: this.bonusStatus.total,
                    current: this.bonusStatus.total - 10
                }
            },
            duration: 4000
        };

        setTimeout(() => {
            App.Sounds.playSound('addFreeSpin');
            const countUp = new CountUp(options);
            countUp.onTick(values => {
                won.text = values.total.current.toFixed(0);
                total.text = values.won.current.toFixed(0);
            });
            countUp.start();
        }, 1000);
    }

    /**
     * Draw bonus end frame
     * @param parentContainer
     * @param x
     * @param y
     * @param win
     * @param total
     */
    showEndBonusFrame(parentContainer, {x, y}, {win, total}) {
        App.Sounds.playSound('endFreeSpins');
        this.showBonusFrame(parentContainer, x, y, 'frame2');

        const props = {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 30,
            fontWeight: 'bold',
            fill: 'white',
            lineJoin: 'round'
        };
        const richText = new Text('CONGRATULATIONS YOU WIN!', props);
        richText.position.set(178, 177);
        props.fontSize = 100;
        props.align = 'left';
        const richText2 = new Text(`${win}`, props);
        richText2.position.set(317, 210);
        props.fontSize = 25;

        const richText3 = new Text(`BONUSSPINS PLAYED: ${total}`, props);
        richText3.position.set(241, 327);
        parentContainer.addChild(richText, richText2, richText3);
    }

    drawBonusAnimation(parentContainer, bonusSymbol) {
        App.Sounds.stopSound('bonusGameStart');

        parentContainer.removeChildren();
        App.Sounds.playSound('bookOpen');

        const bookSprite = new Sprite(this.getTexture('book'));
        bookSprite.name = 'bookSprite';
        parentContainer.addChild(bookSprite);
        setTimeout(() => {
            const getTextures = (bonusSymbol) => {
                const frames = 11;
                const textures = [];
                const symbol = [];
                [...Array(frames)].map((item, index) => {
                    symbol[index] = Math.floor(Math.random() * 9);

                    if (index !== 0) {
                        while (symbol[index] === symbol[index - 1]) {
                            symbol[index] = Math.floor(Math.random() * 9);
                        }
                    }

                    textures.push(...this.getSpriteTextures({
                        image: 'bookAnim',
                        width: 136,
                        height: 120,
                        fromFrame: index === 10 ? bonusSymbol : symbol[index],
                        colCount: 9
                    }));
                });
                return textures;
            };

            const textures = getTextures(bonusSymbol);
            const symbolsSprite = new AnimatedSprite(textures);

            symbolsSprite.position.set(218, 206);
            symbolsSprite.animationSpeed = 0.05;
            symbolsSprite.loop = false;
            symbolsSprite.onFrameChange = (frame) => {
                frame > 5 && (symbolsSprite.animationSpeed -= 0.007);
                frame !== textures.length - 1 && App.Sounds.playSound('bookFlipPage');
                frame === textures.length - 1 && App.Sounds.playSound('bookSelectedSymbol');
            };

            symbolsSprite.onComplete = () => {
                App.Sounds.stopSound('bookSelectedSymbol');
                setTimeout(() => {
                    this.bonusRoll();
                    this.setBackground('bonusArea');
                    this.drawBookTop(parentContainer, bonusSymbol);
                }, 1000);
            };

            symbolsSprite.play();
            parentContainer.addChild(symbolsSprite);
        }, 1000);
    }

    drawBookTop(parentContainer) {
        const bookContainer = new Container();
        bookContainer.name = 'bookContainer';
        parentContainer.addChild(bookContainer);
        const bookSprite = new Sprite(this.getTexture('paymentBook'));
        bookSprite.name = 'bookSprite';
        bookSprite.position.x = 264;
        bookSprite.scale.set(0.7);
        bookContainer.addChild(bookSprite);

        const paymentAnimation = new AnimatedSprite(this.getSpriteTextures({
            image: 'paymentAnimation',
            width: 83,
            height: 62,
            fromFrame: this.bonusRollSymbol * 2,
            toFrame: (this.bonusRollSymbol * 2) + 2,
            colCount: 2
        }));
        paymentAnimation.name = 'paymentAnimation';
        paymentAnimation.loop = false;
        paymentAnimation.scale.set(0.8);

        paymentAnimation.position.set(308, 10);
        paymentAnimation.animationSpeed = 0.1;
        bookContainer.addChild(paymentAnimation);
        this.drawPaymentsOnBook(bookContainer);
    }

    drawPaymentsOnBook(parentContainer, x = 436, y = 44) {
        const paymentText = new Container();
        paymentText.name = 'paymentText';
        parentContainer.addChild(paymentText);
        let i = 0;
        const payTable = [];
        const bet = this.gameSettings.getBetLineCredit();
        this.symbols[this.bonusRollSymbol].payment.forEach((pay, key) => {
            pay !== 0 && payTable.push([key, pay]);
        });

        payTable.forEach(pay => {
            const textPayments = new Text(`${pay[0]}   -    ${bet * pay[1]}`, {
                align: 'left',
                fontFamily: 'Georgia',
                fontSize: 13,
                fontWeight: 'bold',
                fill: '#fff710',
                lineJoin: 'round'
            });
            textPayments.name = pay[0];
            textPayments.x = x;
            textPayments.y = this.symbols[this.bonusRollSymbol].payment[2] === 0 ?
                (y - 4) - 17 * i :
                y - 13 * i;
            i++;
            paymentText.addChild(textPayments);
        });
    }

    /**
     * function to show dark symbols before fill reels with bonus symbols
     */
    prepareReelsWithAdditionalSymbol() {
        const parentContainer = this.getStageChild('bonusContainer').getChildByName('bookContainer');
        const paymentTextContainer = parentContainer.getChildByName('paymentText');

        const {features} = this.latestResponse;
        let count = null;
        features.forEach(obj => {
            obj.uc === 'SPECIAL_SYMBOL' && (count = obj.count);
        });

        paymentTextContainer.children.forEach(item => {
            item.name !== count && (item.style.fill = 'grey');
        });
    }

    setRegularShortSprite(clipMatrix, reelIndex, textures) {
        let scatterCount = 0;

        for (let i = 0; i < reelIndex; i++) {
            for (let j = 0; j < this.reelRows; j++) {
                const symbolObj = clipMatrix[i][j + 1];
                symbolObj.symbol === this.scatter && scatterCount++;
            }
        }

        for (let i = 0; i < this.reelRows; i++) {
            const symbolObj = clipMatrix[reelIndex][i + 1];
            if (symbolObj.symbol === this.scatter) {
                symbolObj.image = 'regularShort';
                symbolObj.sprite.loop = false;
                symbolObj.sprite.textures = textures[symbolObj.image][this.scatter];

                const reelsRemain = this.reels - 1 - reelIndex;
                // play scatter only in possible free rolls
                !(
                    (reelsRemain === 1 && scatterCount < 1) ||
                    (reelsRemain === 0 && scatterCount < 2)
                ) && symbolObj.sprite.play();
            }
        }
    }

    /**
     * Show bonus message with bonus symbol or with bonus game win.
     * @param isFirstBonus {boolean} TRUE if this is the message for starting bonus game.
     */
    drawBonusAskButton(isFirstBonus = false) {
        let isLast = !isFirstBonus && this.bonusStatus && this.bonusStatus.remain === 0;

        this.stopAnimateFeature();
        setTimeout(() => {
            this.drawBonusFrame(isFirstBonus, isLast, this.getStageChild('bonusContainer'), this.coordinatesBonusFrame);
        }, 1000);

        this.gameFlag.bonusStart = true;
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});

        isLast = this.bonusStatus && this.bonusStatus.remain === 0;
    }

    getSymbolImageInLine = symbolObj =>
        this.gameFlag.bonusStarted && symbolObj.symbol === this.bonusRollSymbol ? 'bonus' : 'regular';
}
