import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import BookGame from './../../deluxe/bookGame';
import Lines20 from './../../deluxe/lines20';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';

/* PIXI aliases */
const AnimatedSprite = PIXI.AnimatedSprite,
    Text = PIXI.Text;

export default class TreasureGate extends BookGame {
    constructor() {
        super();
        this.id = 'treasure-gate';
        this.name = 'Treasure Gate';
        this.transparentBackground = true;
        this.buttonsPanelShadow = 'mid';

        // roll properties
        this.reelSettings = [14, 5, 30]; // 0 - start symbol amount, 1 - reel increase regular roll, 2 - reel increase long roll
        this.reelFilter = [[], [10], [], [10], []];
        this.scatter = 10;
        this.allowLongRoll = true;
        this.longRollSettings = [4, 4];  // long roll from reelIndex to reelIndex

        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 125, y: 95},
            bonusInBonusFrame: {x: 125, y: 95},
            endBonusFrame: {x: 125, y: 95}
        };

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 0, 10, 30, 100]},     // 0 - 9
            {regularDelay: 100, payment: [0, 0, 0, 10, 30, 100]},     // 1 - 10
            {regularDelay: 100, payment: [0, 0, 0, 10, 30, 100]},     // 2 - J
            {regularDelay: 100, payment: [0, 0, 0, 10, 40, 150]},     // 3 - Q
            {regularDelay: 100, payment: [0, 0, 0, 10, 40, 150]},     // 4 - K
            {regularDelay: 100, payment: [0, 0, 0, 10, 40, 150]},     // 5 - A
            {regularDelay: 100, payment: [0, 0, 0, 30, 100, 500]},    // 6 - sword
            {regularDelay: 100, payment: [0, 0, 0, 30, 100, 500]},    // 7 - bottle
            {regularDelay: 100, payment: [0, 0, 0, 50, 500, 2000]},   // 8 - dragon
            {regularDelay: 100, payment: [0, 0, 0, 100, 1000, 5000]}, // 9 - woman
            {regularDelay: 100, payment: [0, 0, 0, 0, 0, 0]}          // 10 - gate (scatter)
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                bonusArea: 'area/bonus.png',
                frame: 'bonus/frame.png'
            }),
            atlas: this.mergePath([
                'bonus/bonusSymbols.json',
                'bonus/scatterSymbols.json',
                'regularLongSymbols.json'
            ])
        };

        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'bonus-game-end', alias: 'bonusGameEnd'},
                {name: 'bonus-background', loop: true},
                {name: 'bonus-win-symbols-show', alias: 'bonusWinSymbols'},
                {name: 'long1'}
            ],
            path: `/game/games/${this.id}/audio/`
        };

        this.Lines = new Lines20();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        const textProps2 = {fontSize: 20};

        switch (page) {
            case 1:
                ctx.textAlign = 'left';
                const textProps = {fontSize: 18};

                // woman
                this.drawCustomWhiteText(ctx, bet * this.symbols[9].payment[5], 295, 52, textProps);
                this.drawCustomWhiteText(ctx, bet * this.symbols[9].payment[4], 295, 80, textProps);
                this.drawCustomWhiteText(ctx, bet * this.symbols[9].payment[3], 295, 108, textProps);

                // dragon
                this.drawCustomWhiteText(ctx, bet * this.symbols[8].payment[5], 185, 211, textProps);
                this.drawCustomWhiteText(ctx, bet * this.symbols[8].payment[4], 185, 237, textProps);
                this.drawCustomWhiteText(ctx, bet * this.symbols[8].payment[3], 185, 263, textProps);

                // sword bottle
                this.drawCustomWhiteText(ctx, bet * this.symbols[7].payment[5], 600, 211, textProps);
                this.drawCustomWhiteText(ctx, bet * this.symbols[7].payment[4], 600, 237, textProps);
                this.drawCustomWhiteText(ctx, bet * this.symbols[7].payment[3], 600, 263, textProps);

                // Q K A
                this.drawCustomWhiteText(ctx, bet * this.symbols[5].payment[5], 140, 356, textProps);
                this.drawCustomWhiteText(ctx, bet * this.symbols[5].payment[4], 140, 382, textProps);
                this.drawCustomWhiteText(ctx, bet * this.symbols[5].payment[3], 140, 408, textProps);

                // 9 10 J
                this.drawCustomWhiteText(ctx, bet * this.symbols[2].payment[5], 565, 356, textProps);
                this.drawCustomWhiteText(ctx, bet * this.symbols[2].payment[4], 565, 382, textProps);
                this.drawCustomWhiteText(ctx, bet * this.symbols[2].payment[3], 565, 408, textProps);

                ctx.textAlign = 'center';
                this.drawCustomWhiteText(ctx, 'substitutes for', 565, 40, textProps);

                this.drawCustomWhiteText(ctx, 'randomly', 535, 490, textProps);
                this.drawCustomWhiteText(ctx, 'selected', 535, 513, textProps);
                this.drawCustomWhiteText(ctx, 'additional', 535, 536, textProps);
                this.drawCustomWhiteText(ctx, 'substitute', 535, 559, textProps);

                this.drawCustomRedText(ctx, '15', 330, 510, {fontSize: 36});
                this.drawCustomRedText(ctx, 'Free Games', 330, 545, {fontSize: 18});
                break;
            case 2:
                ctx.textAlign = 'center';
                this.drawCustomRedText(ctx, 'One symbol is randomly selected at the start', 400, 400, textProps2);
                this.drawCustomRedText(ctx, 'of the Free Games which substitutes for all', 400, 430, textProps2);
                this.drawCustomRedText(ctx, 'symbols except Scatters during the Free', 400, 460, textProps2);
                this.drawCustomRedText(ctx, 'Games. The selected symbol is stacked on', 400, 490, textProps2);
                this.drawCustomRedText(ctx, 'all reels.', 400, 520, textProps2);
                break;
            case 3:
                ctx.textAlign = 'center';
                this.drawCustomRedText(ctx, 'RULES', 400, 200, {fontSize: 48});
                this.drawCustomRedText(ctx, 'All prizes are for combinations of a kind. All prizes', 400, 250, textProps2);
                this.drawCustomRedText(ctx, 'are for combinations left to right, except scatters. All', 400, 280, textProps2);
                this.drawCustomRedText(ctx, 'prizes are on selected lines, except scatters. Scatter', 400, 310, textProps2);
                this.drawCustomRedText(ctx, 'symbols pay at any position on screen. Highest win', 400, 340, textProps2);
                this.drawCustomRedText(ctx, 'only paid per selected line. Scatter wins are added to', 400, 370, textProps2);
                this.drawCustomRedText(ctx, 'line wins. Free Games can be won again during the', 400, 400, textProps2);
                this.drawCustomRedText(ctx, 'Free Games. Free Games are played at trigger bet', 400, 430, textProps2);
                this.drawCustomRedText(ctx, 'and lines. All prizes shown in credits. Malfunction', 400, 460, textProps2);
                this.drawCustomRedText(ctx, 'voids all pays and plays.', 400, 490, textProps2);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    drawCustomWhiteText = (ctx, text, x, y, props) => {
        const {fontSize} = props;

        ctx.font = `${fontSize}pt Times New Roman`;
        ctx.lineWidth = 4;
        ctx.strokeStyle = '#ffd600';
        ctx.strokeText(text, x, y);
        ctx.lineWidth = 2;
        ctx.strokeStyle = '#000';
        ctx.strokeText(text, x, y);
        ctx.fillStyle = '#fff';
        ctx.fillText(text, x, y);
    };

    drawCustomRedText = (ctx, text, x, y, props) => {
        const {fontSize} = props;

        ctx.font = `bold ${fontSize}pt Times New Roman`;
        ctx.lineWidth = 4;
        ctx.strokeStyle = '#ffd600';
        ctx.strokeText(text, x, y);
        ctx.lineWidth = 2;
        ctx.strokeStyle = '#000';
        ctx.strokeText(text, x, y);
        ctx.fillStyle = '#ff0000';
        ctx.fillText(text, x, y);
    };

    getSymbolImageType = symbolIndex =>
        (symbolIndex === this.bonusRollSymbol || this.scatters.includes(symbolIndex)) &&
        this.gameFlag.bonusStarted ?
            'bonus' : 'static';

    drawBonusFrame(first, last, parentContainer, coordinates) {
        App.Sounds.stopSound('bonus-background');
        const {startBonusFrame, bonusInBonusFrame, endBonusFrame} = coordinates;
        const textStyle = {
            align: 'center',
            fontFamily: 'Times New Roman',
            fontWeight: 'bold',
            lineJoin: 'round',
            fontSize: 42,
            fill: 'red',
            stroke: '#f5f70e',
            strokeThickness: 6,
            lineHeight: 60
        };

        if (first) {
            this.setBackground('bonusArea');
            this.showStartBonusFrame(parentContainer, startBonusFrame, textStyle);

            App.updateButton('start', {
                disabled: false,
                title: 'start',
                handler: () => this.startBonusAnimation(parentContainer)
            });
        }
        if (last) {
            this.setRegularSprite();
            App.updateButton('start', {disabled: true});
            this.setBackground('mainArea');
            this.showEndBonusFrame(parentContainer, endBonusFrame, this.bonusStatus, textStyle);
            this.playEndBonusGameSound();
            setTimeout(() => this.endBonus(), 5000);
        }
        if (!first && !last) {
            this.showBonusInBonusFrame(parentContainer, bonusInBonusFrame, textStyle);
            App.updateButton('start', {
                disabled: false,
                title: 'start',
                handler: () => this.drawBonusStep(this.latestResponse.features)
            });
        }
    }

    /**
     * Prepare game behaviour after bonus 'press any button' message
     */
    startBonusAnimation = parentContainer => {
        this.clearPressAnyButton();
        this.Buttons.disableAllButtons();
        App.updateButton('start', {disabled: true});
        this.gameFlag.bonusStarted = true;

        const bonusSymbol = this.bonusRollSymbol;
        ['book_base', `book_${bonusSymbol}`].forEach(key => {
            !this.Loader.resources[key] && this.resourcesSettings.total++ &&
            this.Loader.add(key, App.getUrl(this.mergePath({img: `bonus/${key}.png`}).img));
        });

        this.Loader.load(() => this.drawBonusAnimation(parentContainer, bonusSymbol));
    };

    /**
     * Отрисовка таблички бонусной игры
     */
    showStartBonusFrame(parentContainer, {x, y}, textStyle) {
        this.showBonusFrame(parentContainer, x, y);
        textStyle.letterSpacing = 2;
        const richText2 = new Text('Free Games won', textStyle);
        richText2.x = 237;
        richText2.y = 316;

        textStyle.letterSpacing = 0;
        textStyle.fontSize = 70;
        const richText3 = new Text('15', textStyle);
        richText3.x = 360;
        richText3.y = 241;

        textStyle.fontSize = 30;
        textStyle.fill = '#f5f70e';
        textStyle.stroke = null;
        const richText = new Text('Congratulation', textStyle);
        richText.x = 301;
        richText.y = 196;

        parentContainer.addChild(richText, richText2, richText3);
    }

    /**
     * Отрисовка таблички бонус в бонусе
     */
    showBonusInBonusFrame(parentContainer, {x, y}, textStyle) {
        this.showBonusFrame(parentContainer, x, y);
        textStyle.fontSize = 50;
        textStyle.letterSpacing = 6;
        const richText = new Text('15 more \nFree Games', textStyle);
        richText.x = 244;
        richText.y = 224;
        parentContainer.addChild(richText);
    }

    /**
     * Отрисовка таблички окончания бонусной игры
     */
    showEndBonusFrame(parentContainer, {x, y}, {win, total}, textStyle) {
        this.showBonusFrame(parentContainer, x, y);
        textStyle.letterSpacing = 4;
        textStyle.fontSize = 51;
        const richText = new Text(`Feature Win\n${win} Credits`, textStyle);
        richText.x = 241;
        richText.y = 224;
        parentContainer.addChild(richText);
    }

    /**
     * drawBonusAnimation
     */
    drawBonusAnimation(parentContainer) {
        App.Sounds.playSound('bonusWinSymbols');
        const props = {
            toFrame: 20,
            width: 341, height: 268,
            colCount: 5
        };
        const sprite = new AnimatedSprite([
            ...this.getSpriteTextures({...props, image: 'book_base'}),
            ...this.getSpriteTextures({...props, image: `book_${this.bonusRollSymbol}`, colCount: 6, toFrame: 28})
        ]);

        sprite.position.set(231, 155);
        sprite.animationSpeed = 0.17;
        sprite.loop = false;
        sprite.play();
        sprite.onComplete = () => this.bonusRoll();
        parentContainer.addChild(sprite);
    }
}
