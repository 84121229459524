import React from 'react';
import {JL} from 'jsnlog';

import './styles.less';
import App from './../../index';
import Buttons from './../buttons';
import ControlButtons from './../controlButtons';
import Prizes from './../prizes';
import Gamble from './../gamble';
import PrizeWin from './../prizeWin';
import Statistic from './../statistic';
import Waiting from './../waiting';
import MobileButtons from './../mobileButtons';

export default class Game extends React.Component {
    componentDidMount() {
        JL().debug('-- Game: componentDidMount');
        setTimeout(() => App.Game?.drawGame(), 10); // async for preventing component errors
        App.loadSounds('readyGameSounds');
        App.updateButton('user', {visible: false});
        App.updateButton('message', {visible: false});
        setTimeout(() => !App.View.state.error.message &&
            App.updateState('loader', {
                fade: 'out',
                fadeEndCallback: () => {
                    !App.View.state.error.message && App.resetLoader();

                    // enable close button in idle
                    App.Game && App.Game.getState() === App.Game.getIdleState() &&
                    App.updateButton('close', {disabled: false});
                },
                activeControlButtons: false
            }), 700); // async for wait PIXI.Application init
    }

    render() {
        const {state: {lang, moneyParams, buttons, legends, wrap, waiting, activeGamble, activePrizes, activePrizeWin}} = this.props;

        return (
            <div
                id='Game'
                className={`game-${App.Game.id}`}
                onClick={App.Game.Buttons.closeWrap}
            >
                <img className='background' draggable={false} src={App.getUrl('/img/Menu/menu-bg.png')} alt=''/>
                {activePrizes && <Prizes lang={lang} buttons={buttons} moneyParams={moneyParams} legends={legends}/>}
                {App.configs.subMode === 'test' && <Statistic/> /* render component only in '/test/' mode */}
                {buttons.autoStart.pressed && ['kiosk', 'kiosk2'].includes(App.configs.subMode) &&
                <span className='blue-shadow blue-color'>
                    {lang.autoplay}
                </span>}

                <canvas id='main-canvas' ref={App.Game.mainCanvas}/>
                {activeGamble && <Gamble buttons={buttons}/>}
                {activePrizeWin && <PrizeWin prize={App.Game.prizeResponse}/>}

                <Buttons
                    lang={lang}
                    moneyParams={moneyParams}
                    buttons={buttons}
                    legends={legends}
                    wrap={wrap}
                />

                {App.System.platform === 'mobile' ?
                    <MobileButtons lang={lang} buttons={buttons}/> :
                    <ControlButtons buttons={buttons}/>}

                {waiting.active && <Waiting progress={waiting.progress}/>}
            </div>
        );
    }
}
