import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../../../index';
import GameDeluxe from './../../deluxe/game';
import Lines9 from './../../novomatic/lines9';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';
import bonusFont from './img/font/fontBonus';

/* PIXI aliases */
const AnimatedSprite = PIXI.AnimatedSprite;

export default class LuckyLady extends GameDeluxe {
    constructor() {
        super();
        this.id = 'lucky-lady';
        this.name = 'Lucky Lady\'s Charm Deluxe';
        this.scatter = 12;
        this.reelFilter = [[], [12], [], [12], []];
        this.hideBonusReelsSprites = [];
        this.buttonsPanelShadow = 'strong';
        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 197, y: 100},
            bonusInBonusFrame: {x: 197, y: 100},
            endBonusFrame: {x: 197, y: 100}
        };

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 2, 5, 25, 100]},       // 0 - цифра 9
            {regularDelay: 100, payment: [0, 0, 0, 5, 25, 100]},       // 1 - цифра 10
            {regularDelay: 100, payment: [0, 0, 0, 5, 25, 100]},       // 2 - буква J
            {regularDelay: 100, payment: [0, 0, 0, 5, 25, 100]},       // 3 - буква Q
            {regularDelay: 100, payment: [0, 0, 0, 10, 50, 125]},      // 4 - буква K
            {regularDelay: 100, payment: [0, 0, 0, 10, 50, 125]},      // 5 - буква A
            {regularDelay: 100, payment: [0, 0, 0, 15, 75, 250]},      // 6 - монета
            {regularDelay: 100, payment: [0, 0, 0, 15, 75, 250]},      // 7 - клевер
            {regularDelay: 100, payment: [0, 0, 0, 20, 100, 400]},     // 8 - подкова
            {regularDelay: 100, payment: [0, 0, 2, 25, 125, 750]},     // 9 - божья коровка
            {regularDelay: 100, payment: [0, 0, 2, 25, 125, 750]},     // 10 - духи
            {regularDelay: 100, payment: [0, 0, 10, 250, 2500, 9000]}, // 11 - девушка
            {regularDelay: 100, payment: [0, 0, 2, 5, 20, 500]}        // 12 - светящийся шар в руках
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: `area/${App.System.resolution}/main.png`,
                bonusFont: bonusFont['imageResource']
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                bonusArea: `area/${App.System.resolution}/bonus.png`,
                bonusFrame: 'bonus/bonusFrame.png',
                bonus: 'bonus/bonus.png',
                bonusSpin: 'bonus/bonusSpin.png'
            }),
            atlas: this.mergePath([
                'regularLongSymbols.json',
                'bonus/scatterSymbols.json'
            ])
        };

        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'bonus-game-end', alias: 'bonusGameEnd'},
                {name: 'lucky-lady-bonus-game-symbols-apear', alias: 'bonus_reels'},
                {name: 'bonus-background', loop: true}
            ],
            path: `/game/games/${this.id}/audio/`
        };

        this.Lines = new Lines9();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 18pt Garamond';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#e7f4f7';

        switch (page) {
            case 1:
                // Columbus
                ctx.fillText(bet * nLines * this.symbols[12].payment[5], 460, 310);
                ctx.fillText(bet * nLines * this.symbols[12].payment[4], 460, 333);
                ctx.fillText(bet * nLines * this.symbols[12].payment[3], 460, 356);
                ctx.fillText(bet * nLines * this.symbols[12].payment[2], 460, 383);
                // Girl
                ctx.fillText(bet * this.symbols[11].payment[5], 270, 35);
                ctx.fillText(bet * this.symbols[11].payment[4], 270, 55);
                ctx.fillText(bet * this.symbols[11].payment[3], 270, 75);
                ctx.fillText(bet * this.symbols[11].payment[2], 270, 95);
                // божья коровка, духи
                ctx.fillText(bet * this.symbols[9].payment[5], 695, 255);
                ctx.fillText(bet * this.symbols[9].payment[4], 695, 275);
                ctx.fillText(bet * this.symbols[9].payment[3], 695, 298);
                ctx.fillText(bet * this.symbols[9].payment[2], 695, 318);
                //  подкова
                ctx.fillText(bet * this.symbols[8].payment[5], 640, 389);
                ctx.fillText(bet * this.symbols[8].payment[4], 640, 416);
                ctx.fillText(bet * this.symbols[8].payment[3], 640, 443);
                //  coin clever
                ctx.fillText(bet * this.symbols[6].payment[5], 115, 260);
                ctx.fillText(bet * this.symbols[6].payment[4], 115, 285);
                ctx.fillText(bet * this.symbols[6].payment[3], 115, 310);
                // A K
                ctx.fillText(bet * this.symbols[4].payment[5], 165, 389);
                ctx.fillText(bet * this.symbols[4].payment[4], 165, 416);
                ctx.fillText(bet * this.symbols[4].payment[3], 165, 443);
                // 10 J Q
                ctx.fillText(bet * this.symbols[1].payment[5], 300, 518);
                ctx.fillText(bet * this.symbols[1].payment[4], 300, 546);
                ctx.fillText(bet * this.symbols[1].payment[3], 300, 576);
                // 9
                ctx.fillText(bet * this.symbols[0].payment[5], 530, 518);
                ctx.fillText(bet * this.symbols[0].payment[4], 530, 538);
                ctx.fillText(bet * this.symbols[0].payment[3], 530, 561);
                ctx.fillText(bet * this.symbols[0].payment[2], 530, 581);

                const textProps = {
                    parentContainer: ctx,
                    fontImageName: 'bonusFont',
                    map: bonusFont,
                    align: 'center',
                    scale: 0.4,
                    fontInterval: -7 // px between symbols
                };
                this.drawCustomFont('15 FREE GAMES +', 395, 415, textProps);
                this.drawCustomFont('x3 MULTIPLIER', 395, 440, textProps);

                ctx.font = 'bold 11pt Garamond';
                ctx.textAlign = 'center';
                ctx.fillStyle = '#f5f70e';
                ctx.fillText('SUBSTITUTES FOR ALL', 560, 35);
                ctx.fillText('SYMBOLS EXCEPT', 560, 50);
                ctx.fillText('SCATTERS.', 560, 65);
                ctx.fillText('DOUBLES WIN', 560, 80);
                ctx.fillText('WHEN SUBSTITUTING', 560, 95);
                break;

            case 2:
                ctx.font = 'bold 15pt Garamond';
                ctx.fillStyle = '#f5f70e';
                ctx.textAlign = 'center';
                ctx.strokeStyle = '#000';
                ctx.shadowColor = 'black';
                ctx.shadowOffsetX = 1;
                ctx.shadowOffsetY = 3;
                ctx.shadowBlur = 5;

                const textProps2 = {
                    parentContainer: ctx,
                    fontImageName: 'bonusFont',
                    map: bonusFont,
                    align: 'center',
                    scale: 0.7,
                    fontInterval: -7 // px between symbols
                };

                this.strokeFillText(ctx, 'SUBSTITUTES for ALL symbols except scatters.', 440, 80);
                this.strokeFillText(ctx, 'One or more', 255, 235);
                this.strokeFillText(ctx, 'substituting in win combination,', 570, 235);
                this.strokeFillText(ctx, 'DOUBLES the prize', 450, 260);
                this.drawCustomFont('FREE GAMES', 400, 280, textProps2);

                ctx.textAlign = 'right';
                this.strokeFillText(ctx, '3 or more scatters on the screen trigger', 530, 380);
                this.strokeFillText(ctx, '15 Free Games.', 330, 400);
                this.strokeFillText(ctx, 'During Free Games, prizes are multiplied by x3. Free Games can be won', 710, 470);
                this.strokeFillText(ctx, 'again during the Free Games. Free Games are played at trigger bet and', 700, 500);
                this.strokeFillText(ctx, 'lines.', 143, 530);
                break;

            case 3:
                ctx.font = 'bold 17pt Garamond';
                ctx.fillStyle = '#f5f70e';
                ctx.textAlign = 'center';
                ctx.strokeStyle = '#000';
                ctx.shadowColor = 'black';
                ctx.shadowOffsetX = 1;
                ctx.shadowOffsetY = 3;
                ctx.shadowBlur = 5;

                const textProps3 = {
                    parentContainer: ctx,
                    fontImageName: 'bonusFont',
                    map: bonusFont,
                    align: 'center',
                    scale: 0.7,
                    fontInterval: -7 // px between symbols
                };
                this.drawCustomFont('RULES', 400, 150, textProps3);
                this.strokeFillText(ctx, 'All prizes are for combinations of a kind. All', 400, 220);
                this.strokeFillText(ctx, 'prizes are for combinations left to right, except', 400, 240);
                this.strokeFillText(ctx, 'scatters. All prizes are on selected lines, except', 400, 260);
                this.strokeFillText(ctx, 'scatters. Scatter symbols pay at any position on', 400, 280);
                this.strokeFillText(ctx, 'screen. Highest win only paid per selected line.', 400, 300);
                this.strokeFillText(ctx, 'Scatter wins are added to line wins. All prizes', 400, 320);
                this.strokeFillText(ctx, 'shown in credits. Malfunction voids all pays and', 400, 340);
                this.strokeFillText(ctx, 'plays', 400, 360);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    /**
     * Show bonus message with bonus symbol or with bonus game win.
     * @param isFirstBonus {boolean} TRUE if this is the message for starting bonus game.
     */
    drawBonusAskButton(isFirstBonus = false) {
        let isLast = !isFirstBonus && this.bonusStatus && this.bonusStatus.remain === 0;

        this.stopAnimateFeature();
        setTimeout(() => {
            this.drawBonusFrame(isFirstBonus, isLast, this.getStageChild('bonusContainer'), this.coordinatesBonusFrame);
        }, 1000);

        this.gameFlag.bonusStart = true;
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});

        isLast = this.bonusStatus && this.bonusStatus.remain === 0;
    }

    createBonusSpinSprites(parentContainer) {
        const arrayOfSprites = [];
        this.reelMatrix.forEach((reel, reelIndex) => {
            reel.forEach((row, rowIndex) => {
                const sprite = new AnimatedSprite(this.getSpriteTextures({
                    colCount: 11,
                    toFrame: 11,
                    image: 'bonusSpin',
                    width: this.symbolWidth,
                    height: this.symbolHeight
                }));
                sprite.animationSpeed = 0.2;
                sprite.loop = false;
                sprite.position.x = this.reelXCoordinates[reelIndex];
                sprite.position.y = (rowIndex * this.symbolHeight) + this.reelTop;
                arrayOfSprites.push(sprite);
                parentContainer.addChild(sprite);
            });
        });
        return arrayOfSprites;
    }

    drawBonusFrame(first, last, parentContainer, coordinates) {
        App.Sounds.stopSound('bonus-background');
        const {startBonusFrame, bonusInBonusFrame, endBonusFrame} = coordinates;

        if (first) {
            this.showStartBonusFrame(parentContainer, startBonusFrame, last);
            this.setBackground('bonusArea');
        }
        if (last) {
            this.setRegularSprite();
            App.updateButton('start', {disabled: true});
            this.showEndBonusFrame(parentContainer, endBonusFrame, this.bonusStatus, last);
            this.playEndBonusGameSound();
            setTimeout(() => this.endBonus(), 3000);
        }
        if (!first && !last) {
            this.showBonusInBonusFrame(parentContainer, bonusInBonusFrame, last);
        }
    }

    showBonusFrame(parentContainer, x, y, last) {
        const bonusFrame = new AnimatedSprite(this.getSpriteTextures({
            colCount: 6, toFrame: 6,
            width: 410, height: 388,
            image: 'bonusFrame'
        }));
        bonusFrame.name = 'bonusFrame';
        bonusFrame.animationSpeed = 0.17;
        bonusFrame.position.set(x, y);
        bonusFrame.loop = false;
        bonusFrame.play();
        bonusFrame.onComplete = () => {
            this.enableStartButton();
            this.showPressAnyButton(last);
        };
        parentContainer.addChild(bonusFrame);
    }

    /**
     * Отрисовка таблички бонусной игры
     */
    showStartBonusFrame(parentContainer, {x, y}, last) {
        this.showBonusFrame(parentContainer, x, y, last);

        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 0.5,
            fontInterval: -7 // px between symbols
        };
        this.drawCustomFont('15 FREE GAMES', 400, 240, textProps);
        this.drawCustomFont('All prizes x3', 400, 270, textProps);
    }

    showBonusInBonusFrame(parentContainer, {x, y}, last) {
        this.showBonusFrame(parentContainer, x, y, last);

        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 0.5,
            fontInterval: -7 // px between symbols
        };
        this.drawCustomFont('15 MORE', 400, 240, textProps);
        this.drawCustomFont('FREE GAMES', 400, 270, textProps);
    }

    showEndBonusFrame(parentContainer, {x, y}, {win}, last) {
        this.showBonusFrame(parentContainer, x, y, last);
        const frameSprite = parentContainer.getChildByName('bonusFrame');
        frameSprite.onComplete = null;
        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 0.5,
            fontInterval: -7 // px between symbols
        };
        this.drawCustomFont('FEATURE WIN', 400, 240, textProps);
        this.drawCustomFont(`${win} CREDITS`, 400, 270, textProps);
    }

    /**
     * Call after all book animation ended
     */
    bonusRoll() {
        const container = this.getStageChild('bonusContainer');
        container.removeChildren();
        this.showAdditionalBonusImage(container);

        App.Sounds.playSound('bonus-background');
        this.setState('IDLE_BONUS');
        this.startBonusRoll();
        this.clearPressAnyButton();
    }

    enableStartButton() {
        App.updateButton('start', {
            disabled: false,
            title: 'start',
            handler: () => this.bonusRoll()
        });
    }

    /**
     *  Section for special bonus roll function
     */
    prepareToRollAnimation(response) {
        this.Buttons.disableAllButtons();
        App.updateButton('start', {
            disabled: false,
            title: 'stop',
            handler: () => { // change handler to stop reels animation
                JL().debug('-- Stop roll');
                this.Roll.stopReels = [1, 1, 1, 1, 1, 1];
                App.updateButton('start', {disabled: true});
            }
        });

        this.extraBet && this.updateExtraBetButtons(false);
        this.gameFlag.bonusStart || App.restoreGameState === 'BONUS' ?
            this.bonusReelAnimation(response) : // custom reel animation
            this.Roll.startReelAnimation(response); // primary roll
    }

    /**
     * Специфическая функция прокрута в бонусе (окртытие карт, кристалы, сдвиг елементов и тд)
     */
    bonusReelAnimation(response) {
        App.updateButton('start', {disabled: true});
        const parentContainer = this.getStageChild('bonusContainer');

        this.createReelMatrix(this.getStageChild('reelsStage'), response.screen);
        const arrayOfSprites = this.createBonusSpinSprites(parentContainer);
        this.hideBonusReelsSprites.forEach(sprite => this.Roll.app.stage.removeChild(sprite));

        for (let i = 0; i < this.reels * this.reelRows; i++) {
            arrayOfSprites[i].onComplete = () => {
                parentContainer.removeChild(arrayOfSprites[i]);
                i === arrayOfSprites.length - 1 ?
                    App.Socket.webSocket && this.onRotationDone() :
                    App.Sounds.playSound('bonusSpin');
            };

            setTimeout(() => arrayOfSprites[i].play(), 300 * i);
        }
        App.Sounds.playSound('bonusSpin');
    }
}
