import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../../../index';
import GameDeluxe from './../../deluxe/game';
import Lines10 from './../../deluxe/lines10';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';
import bonusFont from './img/font/bonusFont';

/* PIXI aliases */
const Sprite = PIXI.Sprite;

export default class FruitKing extends GameDeluxe {
    constructor() {
        super();
        this.id = 'fruit-king';
        this.name = 'Fruit King';
        this.scatter = 12;
        this.freezedReels = [];
        this.buttonsPanelShadow = 'mid';

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 95, y: 113},
            bonusInBonusFrame: {x: 95, y: 113},
            endBonusFrame: {x: 95, y: 113}
        };

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 2, 5, 25, 100]},                   // 0 - cherry
            {regularDelay: 100, payment: [0, 0, 2, 5, 25, 100]},                   // 1 - lemon
            {regularDelay: 100, payment: [0, 0, 0, 5, 25, 100]},                   // 2 - plum
            {regularDelay: 100, payment: [0, 0, 0, 5, 25, 100]},                   // 3 - orange
            {regularDelay: 100, payment: [0, 0, 0, 10, 50, 125]},                  // 4 - grape
            {regularDelay: 100, payment: [0, 0, 0, 10, 50, 125]},                  // 5 - melon
            {regularDelay: 100, payment: [0, 0, 0, 15, 75, 250]},                  // 6 - BAR blue
            {regularDelay: 100, payment: [0, 0, 0, 15, 75, 250]},                  // 7 - BAR green
            {regularDelay: 100, payment: [0, 0, 0, 20, 100, 400]},                 // 8 - bell
            {regularDelay: 100, payment: [0, 0, 5, 25, 125, 750]},                 // 9 - 7 blue
            {regularDelay: 100, payment: [0, 0, 5, 25, 125, 750]},                 // 10 - 7 green
            {regularDelay: 100, payment: [0, 0, 10, 250, 2500, 9000], offsetY: 3}, // 11 - king
            {regularDelay: 100, payment: [0, 0, 2, 5, 75, 750]}                    // 12 - Fruit King Bonus
        ];
        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                bonusFont: bonusFont['imageResource']
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                bonusArea: 'area/bonus.png',
                frame: 'bonus/frame.png',
                symbolFrame: 'bonus/symbolFrame.png',
                scatterMultiplier: 'bonus/scatterMultiplier.png'
            }),
            atlas: this.mergePath([
                'bonus/additionalSymbols.json',
                'bonus/bonusSymbols1.json',
                'bonus/bonusSymbols2.json',
                'bonus/scatterSymbols.json'
            ])
        };

        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [
                {name: 'reels'},
                {name: 'bonus-game-start', alias: 'bonusGameStart'},
                {name: 'bonus-game-end', alias: 'bonusGameEnd'},
                {name: 'bonus-symbol-choice', alias: 'bonusSymbolChoice'},
                {name: 'bonus-background', loop: true},
                {name: 'multiplier1'},
                {name: 'multiplier2'},
                {name: 'multiplier3'},
                {name: 'multiplier4'},
                {name: 'multiplier5'},
                {name: 'multiplier6'},
                {name: 'banner'},
                {name: 'banner-win'}
            ],
            path: `/game/games/${this.id}/audio/`
        };

        this.Lines = new Lines10();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            activeBlack: 'gamble/black-active.png',
            activeRed: 'gamble/red-active.png',
            inactiveBlack: 'gamble/black-inactive.png',
            inactiveRed: 'gamble/red-inactive.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 18pt Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#e8eaf7';
        ctx.shadowColor = 'black';
        ctx.shadowOffsetX = ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 5;

        switch (page) {
            case 1:
                // Fruit King Bonus
                this.strokeFillText(ctx, bet * nLines * this.symbols[12].payment[5], 705, 39);
                this.strokeFillText(ctx, bet * nLines * this.symbols[12].payment[4], 705, 64);
                this.strokeFillText(ctx, bet * nLines * this.symbols[12].payment[3], 705, 89);
                this.strokeFillText(ctx, bet * nLines * this.symbols[12].payment[2], 705, 114);

                // king
                this.strokeFillText(ctx, bet * this.symbols[11].payment[5], 240, 73);
                this.strokeFillText(ctx, bet * this.symbols[11].payment[4], 240, 98);
                this.strokeFillText(ctx, bet * this.symbols[11].payment[3], 240, 123);
                this.strokeFillText(ctx, bet * this.symbols[11].payment[2], 240, 148);

                // 7 blue, 7 green
                this.strokeFillText(ctx, bet * this.symbols[10].payment[5], 195, 277);
                this.strokeFillText(ctx, bet * this.symbols[10].payment[4], 195, 302);
                this.strokeFillText(ctx, bet * this.symbols[10].payment[3], 195, 327);
                this.strokeFillText(ctx, bet * this.symbols[10].payment[2], 195, 352);

                // bell
                this.strokeFillText(ctx, bet * this.symbols[8].payment[5], 450, 360);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[4], 450, 385);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[3], 450, 410);

                // BAR blue, BAR green
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], 675, 316);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], 675, 341);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 675, 366);

                // grape, melon
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 195, 490);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 195, 515);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 195, 540);

                // plum, orange
                this.strokeFillText(ctx, bet * this.symbols[3].payment[5], 450, 503);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[4], 450, 528);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[3], 450, 553);

                // cherry, lemon
                this.strokeFillText(ctx, bet * this.symbols[1].payment[5], 710, 465);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[4], 710, 490);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[3], 710, 515);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[2], 710, 540);

                const textProps1 = {
                    parentContainer: ctx,
                    fontImageName: 'bonusFont',
                    map: bonusFont,
                    align: 'left',
                    scale: 0.3,
                    fontInterval: -40 // px between symbols
                };

                this.drawCustomFont('substitutes for', 317, 50, textProps1);

                this.drawCustomFont('3 scatter', 500, 134, textProps1);
                this.drawCustomFont('4 scatter', 500, 153, textProps1);
                this.drawCustomFont('5 scatter', 500, 172, textProps1);
                this.drawCustomFont('5-12 Free Games', 610, 134, textProps1);
                this.drawCustomFont('7-15 Free Games', 610, 153, textProps1);
                this.drawCustomFont('9-17 Free Games', 610, 172, textProps1);

                this.drawCustomFont('During the Free Games', 540, 193, textProps1);
                this.drawCustomFont('every game wins', 560, 207, textProps1);
                break;
            case 2:
                const textProps2 = {
                    parentContainer: ctx,
                    fontImageName: 'bonusFont',
                    map: bonusFont,
                    align: 'center',
                    scale: 0.4,
                    fontInterval: -40 // px between symbols
                };
                this.drawCustomFont('In every Free Game, one symbol is', 400, 120, textProps2);
                this.drawCustomFont('randomly selected which expands and', 400, 150, textProps2);
                this.drawCustomFont('awards a guaranteed win on all lines', 400, 180, textProps2);
                this.drawCustomFont('played, except scatters.', 400, 210, textProps2);
                this.drawCustomFont('During the Free Games every game wins', 400, 480, textProps2);
                break;
            case 3:
                const textProps3 = {
                    parentContainer: ctx,
                    fontImageName: 'bonusFont',
                    map: bonusFont,
                    align: 'center',
                    scale: 1,
                    fontInterval: -40 // px between symbols
                };
                this.drawCustomFont('RULES', 400, 150, textProps3);
                textProps3.scale = 0.35;
                this.drawCustomFont('All prizes are for combinations of a kind. All prizes', 400, 220, textProps3);
                this.drawCustomFont('are for combinations left to right, except scatters. All', 400, 245, textProps3);
                this.drawCustomFont('prizes are on selected lines, except scatters. Scatter', 400, 270, textProps3);
                this.drawCustomFont('symbols pay at any position on screen. Highest win', 400, 295, textProps3);
                this.drawCustomFont('only paid per selected line. Scatter wins are added to', 400, 320, textProps3);
                this.drawCustomFont('line wins. Free Games can be won again during the', 400, 345, textProps3);
                this.drawCustomFont('Free Games. Free Games are played at trigger bet', 400, 370, textProps3);
                this.drawCustomFont('and lines. All prizes shown in credits. Malfunction', 400, 395, textProps3);
                this.drawCustomFont('voids all pays and plays.', 400, 420, textProps3);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    /**
     * Отрисовка таблички бонусной игры
     */
    showStartBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y);

        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 0.9,
            fontInterval: -40 // px between symbols
        };
        this.drawCustomFont('FREE GAMES WON', 400, 250, textProps);
        textProps.scale = 0.5;
        this.drawCustomFont('During the Free Games every', 400, 350, textProps);
        this.drawCustomFont('game wins', 400, 380, textProps);
    }

    /**
     * Отрисовка таблички бонус в бонусе
     */
    showBonusInBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y);

        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 0.9,
            fontInterval: -40 // px between symbols
        };
        this.drawCustomFont('more Free Games', 400, 280, textProps);
    }

    /**
     * Отрисовка таблички окончания бонусной игры
     */
    showEndBonusFrame(parentContainer, {x, y}, {win, total}) {
        this.showBonusFrame(parentContainer, x, y);

        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 0.9,
            fontInterval: -40 // px between symbols
        };
        this.drawCustomFont(`${total} Free Games`, 400, 150, textProps);
        this.drawCustomFont('played', 400, 200, textProps);
        this.drawCustomFont(`${win} CREDITS WON`, 400, 320, textProps);

        // reset invisible symbols
        this.reelMatrix.forEach(reel => reel.forEach(symbolObj => (symbolObj.sprite.visible = true)));
    }

    /**
     * Draw long scatter animation before bonus
     * @param scatterFeature
     * @param callback
     */
    playScatterAnimation(scatterFeature, callback) {
        this.setScatterSprite(scatterFeature);

        // get first scatter position
        const {reel, row} = scatterFeature.positions[scatterFeature.positions.length - 1];
        const symbolObj = this.reelMatrix[reel][row];

        // call after first scatter played
        symbolObj.sprite.onComplete = () => {
            symbolObj.sprite.onComplete = null; // clear event
            callback();
        };

        this.Legends.setText('features', {text: 'scatterPays', value: scatterFeature.payment});
    }

    setScatterSprite(scatterFeature) {
        scatterFeature.positions.forEach(position => {
            const {reel, row} = position;
            const symbolObj = this.reelMatrix[reel][row];
            if (symbolObj.image !== 'bonus' || this.bonusRollSymbol === this.scatter) {
                symbolObj.image = 'scatter';
                symbolObj.loop = false;
                this.Roll.updateSymbolSprite(symbolObj);
                symbolObj.sprite.play();
            }
        });
    }

    /**
     * Show bonus message with bonus symbol or with bonus game win.
     * @param isFirstBonus {boolean} TRUE if this is the message for starting bonus game.
     */
    drawBonusAskButton(isFirstBonus) {
        let isLast = !isFirstBonus && this.bonusStatus && this.bonusStatus.remain === 0;
        const container = this.getStageChild('bonusContainer');

        this.stopAnimateFeature();
        !isLast ?
            this.showScatterMultiplierAnimation(container) :
            setTimeout(() => this.drawBonusFrame(isFirstBonus, isLast, container, this.coordinatesBonusFrame), 1000);

        this.gameFlag.bonusStart = true;
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});

        isLast = this.bonusStatus && this.bonusStatus.remain === 0;
    }

    /**
     * Draw multiplier animations on scatter positions
     */
    showScatterMultiplierAnimation(parentContainer) {
        const scatterPositions = [];
        const {amountBonusGame} = this.latestResponse.extension;
        JL().debug(`-- Show multiplier animation [${amountBonusGame}]`);

        // check scatters reel/row positions
        this.latestResponse.features.forEach(feature => {
            if (feature.uc === 'SCATTER') {
                feature.positions.forEach(pos => {
                    if (!this.freezedReels.includes(pos.reel) || pos.row === 1) { // don't use scatter in freeze reels
                        scatterPositions.push(pos);
                    }
                });
            }
        });

        scatterPositions.forEach((pos, index) => {
            const {reel, row} = pos;
            const frames = 16;

            const symbolObj = this.reelMatrix[reel][row];
            symbolObj.sprite.animationSpeed = 0.2;
            symbolObj.sprite.loop = false;
            symbolObj.sprite.onComplete = null;

            setTimeout(() => {
                let multiplier = amountBonusGame[index] - 1;
                symbolObj.sprite.textures = this.getSpriteTextures({
                    fromFrame: multiplier * frames, toFrame: ++multiplier * frames,
                    image: 'scatterMultiplier', width: this.symbolWidth, height: this.symbolHeight
                });
                symbolObj.sprite.play();
            }, 700 * index);

            // callback for end sprite playing
            symbolObj.sprite.onComplete = () => {
                App.Sounds.playSound(`multiplier${index + 1}`);
                if (index === scatterPositions.length - 1) { // if it's last played sprite
                    App.Sounds.playSound('banner');
                    setTimeout(() => {
                        this.setBackground('bonusArea');
                        JL().debug('-- Show frame animation');
                        this.showFrameAnimation(parentContainer);
                    }, 4000);
                }
            };
        });
    }

    /**
     * Show special addition animation for total games count
     * @param parentContainer
     * @param animationStarted
     * @param Step
     */
    showFrameAnimation(parentContainer, animationStarted = Date.now(), Step = 0) {
        parentContainer.removeChildren();
        const animationSpeed = 100;
        const {startBonusFrame} = this.coordinatesBonusFrame;
        const {amountBonusGame} = this.latestResponse.extension;

        // animation settings and steps
        const animationSteps = {
            addition: amountBonusGame.length * 10,
            flickering: amountBonusGame.length * 10 + 20,
            end: amountBonusGame.length * 10 + 25
        };

        // check new step
        if (Date.now() - animationStarted > animationSpeed) {
            animationStarted = Date.now();
            this.playBonusSounds(Step, amountBonusGame.length);
            Step++;
        }

        // check current addition step, increment by each 10 frames
        let additionStep = Math.floor(Step / 10);
        if (additionStep > amountBonusGame.length - 1) {
            additionStep = amountBonusGame.length - 1;
        }

        // calc total games by current step
        let totalGames = amountBonusGame.reduce((count, current, index) => {
            if (index <= additionStep) {
                count += current;
            }
            return count;
        });

        // create addition string
        let sumStr = amountBonusGame[0].toString();
        for (let i = 1; i < additionStep + 1; i++) {
            sumStr += ` + ${amountBonusGame[i]}`;
        }

        // total games flickering after addition
        if (Step > animationSteps.addition + 5 && Step < animationSteps.flickering) {
            totalGames = Step % 2 ? '' : totalGames;
        }

        const isFirstBonus = this.isFreeRoll(this.latestResponse.features) && !this.bonusStatus;
        isFirstBonus ?
            this.showStartBonusFrame(parentContainer, startBonusFrame) :
            this.showBonusInBonusFrame(parentContainer, startBonusFrame);

        // draw texts
        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'right',
            scale: 0.9,
            fontInterval: -40 // px between symbols
        };
        const x = 520 + (amountBonusGame.length - 3) * 40; // text positions
        const y = isFirstBonus ? 180 : 210;
        this.drawCustomFont(`${sumStr} = `, x, y, textProps);
        textProps.align = 'left';
        this.drawCustomFont(totalGames.toString(), x, y, textProps);

        // animation ending
        if (Step === animationSteps.end) {
            this.showPressAnyButton(false);
            App.Sounds.playSound('banner-win');
            App.updateButton('start', {
                disabled: false,
                title: 'start',
                handler: () => this.startBonusAnimation(parentContainer)
            });
            return;
        }

        requestAnimationFrame(this.showFrameAnimation.bind(this, parentContainer, animationStarted, Step));
    }

    /**
     * Process reels response from server.
     * @param response - Socket response 'ROLL'
     */
    processReelResponse(response) {
        this.latestResponse = response;
        this.setState('RESPONSE_RECEIVED');
        this.freezedReels = [];
        this.prepareToRollAnimation(response);
        this.gameFlag.bonusStarted && this.symbolChoice(this.getStageChild('bonusContainer'));
    }

    /**
     * Возвращает true если барабан "заморожен" и не крутится
     */
    isReelFreezed = reel => this.freezedReels.includes(reel);

    /**
     * Special animation for bonus symbol choice
     * Hide all reels, except first, and draw choice frame
     */
    symbolChoice(parentContainer) {
        JL().debug('-- Symbol choice animation');
        App.Sounds.playSound('bonusSymbolChoice');
        this.freezedReels = [1, 2, 3, 4];
        this.freezedReels.forEach(reelIndex =>
            this.Roll.clipMatrix[reelIndex].forEach(symbolObj => (symbolObj.symbolContainer.visible = false)));

        const sprite = new Sprite(this.getSpriteTextures({
            image: 'symbolFrame',
            width: 199, height: 390
        })[0]);
        sprite.name = 'symbolFrame';
        sprite.position.set(17, this.reelTop);
        parentContainer.addChild(sprite);
    }

    onRotationDone() {
        JL().debug(`-- Rotation done (fps: ${App.System.statistics.fps})`);
        const {features, payment} = this.latestResponse;
        App.updateButton('start', {disabled: true});
        this.roundWin = 0;
        if (payment > 0 || this.isFreeRoll(features) || features.length) {
            // Additional bonus logic
            if (this.gameFlag.bonusStarted) {
                App.updateButton('start', {disabled: true});
                if (!Number.isInteger(this.bonusRollSymbol)) {
                    this.fillReelsWithBonusSymbol();
                } else {
                    this.setState('SHOW_WIN_LINES');
                    this.startAnimateFeature(features);
                }
            } else {
                this.setState('SHOW_WIN_LINES');
                this.startAnimateFeature(features);
            }
        } else {
            if (this.isBonus()) {
                if (this.bonusStatus && this.bonusStatus.remain > 0) {
                    this.roundFinished(false);
                } else {
                    this.Legends.setRoundFinText();
                    this.finishBonus();
                }
            } else {
                this.roundFinished();
                this.Legends.setRoundFinText();
            }
        }
    }

    /**
     * Change symbols to additional for each animate reel
     */
    fillReelsWithBonusSymbol() {
        this.setBonusRollSymbol(); // for bonus game roll symbol
        JL().debug(`-- Fill reels (bonusRollSymbol: ${this.bonusRollSymbol})`);
        this.getStageChild('bonusContainer').removeChildren();

        // check reels for fill special symbol
        const reelsLength = this.symbols[this.bonusRollSymbol].payment.findIndex(payment => payment > 0);
        this.freezedReels = [...Array(reelsLength)].map((item, index) => index);

        // hide all symbols
        this.reelMatrix.forEach(reel => reel.forEach(symbolObj => (symbolObj.sprite.visible = false)));

        // change reels symbols to additional by step
        this.freezedReels.forEach((reelIndex, index) => {
            setTimeout(() => {
                for (let i = 0; i < this.reelRows; i++) {
                    const row = this.bonusRollSymbol === this.scatter ? 1 : i; // fill scatters only on second row

                    const symbolObj = this.reelMatrix[reelIndex][row];
                    symbolObj.symbol = this.bonusRollSymbol;
                    symbolObj.image = 'additional';
                    symbolObj.loop = false;
                    this.Roll.updateSymbolSprite(symbolObj);
                    symbolObj.sprite.visible = true;
                    symbolObj.sprite.onComplete = null;
                    symbolObj.sprite.play();
                    this.latestResponse.screen[reelIndex][row] = this.bonusRollSymbol;
                }

                index === this.freezedReels.length - 1 &&
                setTimeout(() => this.continueRoll(), 1000);
            }, 200 * index);
        });
    }

    /**
     * Set bonusRollSymbol from frame image on reels
     */
    setBonusRollSymbol() {
        this.bonusRollSymbol = this.reelMatrix[0][1].symbol;
    }

    /**
     * Next step of roll animation
     * Hide other reels
     */
    continueRoll() {
        JL().debug('-- Continue bonus roll');
        this.prepareToRollAnimation(this.latestResponse);

        this.Roll.clipMatrix.forEach((reel, reelIndex) => {
            if (this.freezedReels.includes(reelIndex)) {
                reel.forEach((symbolObj, rowIndex) => {
                    // hide symbols if it's scatter feature
                    symbolObj.sprite.visible = this.bonusRollSymbol === this.scatter ?
                        rowIndex - 1 === 1 : true;
                });
            } else {
                // hide elements during roll on the last reels
                const reelLength = this.reelSymbol[reelIndex];
                reel[reelLength - 2].sprite.visible = false;
                reel[reelLength - 3].sprite.visible = false;
                reel[reelLength - 4].sprite.visible = false;
            }
        });
    }

    getSymbolImageType = symbolIndex =>
        symbolIndex === this.bonusRollSymbol && this.gameFlag.bonusStarted ?
            'bonus' : 'static';

    /**
     * Hide symbols in freeze reels during scatter bonus symbol
     */
    additionalPreparingToAnimateFeature() {
        if (this.isBonus()) {
            this.freezedReels.forEach(reelIndex =>
                this.reelMatrix[reelIndex].forEach((symbolObj, rowIndex) =>
                    symbolObj.sprite.visible = this.gameFlag.bonusStarted && this.bonusRollSymbol === this.scatter ?
                        rowIndex === 1 : true));
        }
    }

    /**
     * Prepare game behaviour after bonus 'press any button' message
     */
    startBonusAnimation = parentContainer => {
        this.setState('IDLE_BONUS');
        parentContainer.removeChildren();
        App.Sounds.stopSound('banner-win');
        App.Sounds.playSound('bonus-background');
        this.gameFlag.bonusStarted = true;
        this.clearPressAnyButton();
        this.Buttons.disableAllButtons();
        App.updateButton('start', {disabled: true});
        this.bonusRollSymbol = null; // reset bonus symbol
        this.startBonusRoll();
    };

    finishBonusAnimation() {
        if (this.bonusStatus.remain === 0) {
            this.freezedReels = []; // reset stopped reels
            this.finishBonus();
        } else {
            this.bonusRollSymbol = null; // reset bonus symbol
            this.bonusRoll();
        }
    }

    /**
     * Function to play bonus sounds
     */
    playBonusSounds = (Step, length) => {
        const additionStep = Step % 10;
        const multiplierStep = Math.round(Step / 10) + 1;

        if (additionStep === 0 && multiplierStep <= length) {
            App.Sounds.playSound(`multiplier${multiplierStep}`);
        }
    };
}
