import Lines17 from './../../champion/lines17';

export default class Lines extends Lines17 {
    constructor() {
        super();
        this.imageResources = {
            boxes: '/img/lines/champion_boxes17_fireRagePlus.png'
        };

        this.boxSettings = {
            ...this.boxSettings,
            lineBoxWidth: 29,
            lineBoxHeight: 29,
            textOnBox: true,
            textType: 'bet',
            typeBoxes: 'TwoBoxes',
            boxFontSize: 12,
            boxTextColor: '#fff'
        };
        this.linesSettings = {
            ...this.linesSettings,
            borderThickness: 5,
            borderColor: '#fdf6f6',
            horizontalLineLength: 100
        };

        Object.keys(this.lines).forEach(lineIndex =>
            this.lines[lineIndex].boxes[0].x = lineIndex >= 9 ? 700 : 70);
    }
}
