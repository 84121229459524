import React from 'react';

import './styles.less';
import App from './../../index';

export default function BottomPanel(props) {
    const {lang, moneyParams: {money, currency, insurance, bonus, bonusTotal}} = props;
    const {code, devices} = App.LocalSocket;
    const billAcceptor = devices['BILL-ACCEPTOR-DEVICE'];

    return (
        <div className={`bottom-panel ${billAcceptor && code ? 'payout' : ''}`}>
            <div className='info'>
                <button className='jackpots' onClick={App.Modal.showJackpots}
                    disabled={!Object.keys(App.Money.jackpot).length}>
                    <div className='title'>Jackpot</div>
                </button>

                <button className='categories categories-background' onClick={App.Modal.showCategories}>
                    <div className='categories-label'>{lang.categories}</div>
                    <div className='categories-current'>{App.settings.categoryName}</div>
                </button>
            </div>
            <div id='balance' className='balance'>
                <span className='balance-label'><span>{lang.balance.toUpperCase()}</span></span>
                <span className='currency'>{money.toFixed(2)} {currency}</span>
                <span className='insurance'>
                    <div className='title'>{lang.insurance + '   ' + insurance}</div>
                </span>
                { App.configs.mode === 'login' && <span className='bonus'>
                    <div className='title'>{lang.bonus + '  ' + bonus}</div>
                </span>}
                {billAcceptor && code &&
                <button className='payout red-shadow red-color' onClick={App.Modal.showPayout}>{lang.payout}</button>}
            </div>
        </div>
    );
}
