import * as PIXI from 'pixi.js-legacy';

import './../../igrosoft/styles.less';
import App from './../../../index';
import GameIgrosoft from './../../igrosoft/game';
import Lines9 from './lines';
import GambleIgrosoft from './../../igrosoft/gamble';
import InfoScreen from '../../infoScreen';

/* PIXI aliases */
const AnimatedSprite = PIXI.AnimatedSprite,
    Text = PIXI.Text,
    Sprite = PIXI.Sprite;

export default class Keks extends GameIgrosoft {
    constructor() {
        super();
        this.id = 'keks-1';
        this.name = 'Keks';
        this.buttonsPanelShadow = 'none';
        this.superBonusStep = 4; // шаг чузинга поле которого начинается супер бонус
        this.scatter = 8;
        this.choosingResponse = []; // последний пакет чузинга
        this.reelTop = 40; // magic number - where reel images starts

        this.reelXCoordinates = [62, 201, 341, 480, 619]; // magic numbers - x coordinates where reels starts

        this.symbolHeight = 120; // height of a single symbol
        this.symbolWidth = 119; // width of a single symbol

        // choosing image size, box coordinates for animation
        this.choosing = [
            {x: 60, y: 160, w: 257, h: 316, textPosition: {x: 110, y: 220}, frameCount: 23, colCount: 8}, // 0 hold
            {x: 200, y: 180, w: 257, h: 316, textPosition: {x: 250, y: 240}, frameCount: 23, colCount: 8}, // 1 hold
            {x: 340, y: 160, w: 257, h: 316, textPosition: {x: 390, y: 220}, frameCount: 23, colCount: 8}, // 2 hold
            {x: 480, y: 180, w: 257, h: 316, textPosition: {x: 530, y: 240}, frameCount: 23, colCount: 8}, // 3 hold
            {x: 620, y: 160, w: 257, h: 316, textPosition: {x: 670, y: 220}, frameCount: 23, colCount: 8}, // 4 hold
            {x: 60, y: 160, w: 121, h: 316, textPosition: {x: 130, y: 220}, frameCount: 23, colCount: 8}, // 5
            {x: 60, y: 160, w: 121, h: 316, textPosition: {x: 130, y: 220}, frameCount: 23, colCount: 8}, // 6
            {w: 121, h: 180, frameCount: 30, colCount: 8}, // win
            {w: 121, h: 180, frameCount: 32, colCount: 8}  // lose
        ];
        // super choosing image size, box coordinates for animation
        this.superChoosing = [
            {x: 170, y: 330, w: 451, h: 555, textPosition: {x: 220, y: 200}, frameCount: 18, colCount: 5}, // win
            {x: 530, y: 330, w: 451, h: 555, textPosition: {x: 600, y: 200}, frameCount: 25, colCount: 5} // lose
        ];
        // waiting image size, box coordinates for animation
        this.waitingSettings = {
            IDLE: {w: 179, h: 220, x: 40, y: 380, frameCount: 56, animationSpeed: 150, colCount: 8},
            RESPONSE_RECEIVED: {w: 179, h: 220, x: 40, y: 380, frameCount: 56, animationSpeed: 150, colCount: 8},
            DOUBLING: {w: 179, h: 220, x: 40, y: 380, frameCount: 56, animationSpeed: 150, colCount: 8},
            CHOOSING_IDLE: {w: 257, h: 316, x: 330, y: 284, frameCount: 24, animationSpeed: 30, colCount: 8},
            SUPER_BONUS_IDLE: {w: 257, h: 316, x: 0, y: 284, frameCount: 14, animationSpeed: 70, colCount: 8}
        };

        this.symbols = [
            {regularDelay: 500, payment: [0, 0, 0, 2, 3, 10]},        // 0 - тесто
            {regularDelay: 500, payment: [0, 0, 0, 3, 5, 20]},        // 1 - гармонь
            {regularDelay: 500, payment: [0, 0, 0, 5, 10, 50]},       // 2 - бублики
            {regularDelay: 500, payment: [0, 0, 0, 10, 30, 100]},     // 3 - балалайка
            {regularDelay: 500, payment: [0, 0, 0, 20, 50, 200]},     // 4 - самовар
            {regularDelay: 500, payment: [0, 0, 0, 30, 100, 500]},    // 5 - казанок
            {regularDelay: 500, payment: [0, 0, 0, 200, 1000, 5000]}, // 6 - черный котэ
            {regularDelay: 500, payment: [0, 0, 0, 100, 500, 2000]},  // 7 - колобок
            {regularDelay: 300, payment: [0, 0, 0, 0, 0, 0]}          // 8 - печка
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                waitingRoll: 'area/waiting_roll.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                bonusArea: 'area/bonus.png',
                superBonusArea: 'area/super_bonus.png',
                gambleArea: 'area/gamble.png',

                // waiting
                waitingDoubling: 'gamble/waiting_doubling.png',
                waitingChoosing: 'bonus/waiting_choosing.png',
                waitingSuperChoosing: 'bonus/waiting_super_choosing.png',

                choosingWin: 'bonus/choosing_win.png',
                choosingLose: 'bonus/choosing_lose.png',
                superChoosingWin: 'bonus/super_choosing_win.png',
                superChoosingLose: 'bonus/super_choosing_lose.png',
                catWaiting: 'bonus/kat_waiting.png',
                catLose: 'bonus/kat_lose.png',

                // bonus
                choosedItems: 'bonus/choosed_items.png',
                box: 'bonus/box.png',
                arrow: 'bonus/arrow.png',
                roupe: 'bonus/roupe.png',
                choosing: 'bonus/choosing.png',

                // gamble
                cloudWin: 'gamble/win.png', // usually redefined
                cloudLose: 'gamble/lose.png', // usually redefined
                cloudForward: 'gamble/forward.png' // usually redefined
            })
        };

        this.Gamble = new GambleUnique(this.mergePath({
            cards: 'gamble/cards.png',
            win: 'gamble/win.png',
            lose: 'gamble/lose.png',
            forward: 'gamble/forward.png',
            dealer: 'gamble/dealer.png',
            pick: 'gamble/pick.png'
        }));
        this.Gamble.riskStepX = 460;
        this.Gamble.riskStepY = 102;
        this.InfoScreen = new InfoScreen({pages: 5}); // number of game info states
        this.Lines = new Lines9();

        this.gameSounds = {
            soundClass: 'igrosoft',
            sounds: [
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'choosing_lose', alias: 'choosingLose'},
                {name: 'choosing_win', alias: 'choosingWin'},
                {name: 'superchoosing_waiting', loop: true, alias: 'superWaiting'}
            ],
            path: `/game/games/${this.id}/audio/`
        };
    }

    /**
     * Функция отображения предыдущего выбора или супербонуса
     */
    showChosedItemSuperBonus(parentContainer) {
        let x, y, sprite, box;
        parentContainer.removeChildren();
        parentContainer.interactiveChildren = true;
        this.superScreenMultiplier.win.forEach((multiplier, choiceIndex) => {
            if (multiplier > 0) {    // если не -1 значит выбор был сделан- отключаем кнопку, отображеем выигрыш и сумму
                x = this.superChoosing[choiceIndex].textPosition.x;
                y = this.superChoosing[choiceIndex].textPosition.y;
                const richText = new Text(multiplier, this.textSuperBonus);
                richText.name = 'choiceIndex' + (choiceIndex + 5);
                richText.position.set(x, y);
                parentContainer.addChild(richText);
            }
            if (multiplier === -1) {    // выбор еще не сделан, // отображаем первый кадр анимации                        }
                x = this.superChoosing[choiceIndex].x;
                y = this.superChoosing[choiceIndex].y;
                const image = 'box';

                box = this.createChoosingElement(parentContainer, image,  // wolf sprite
                    200, 160, // dw, dh // sw, sh
                    this.superChoosing[choiceIndex].x, this.superChoosing[choiceIndex].y,
                    0, 23, 13, false, true);
                box.name = 'choiceIndex' + (choiceIndex + 5);
                box.position.set(x, y);
                box.interactive = true;
                box.buttonMode = true;
                box.loop = true;

                sprite = this.createChoosingElement(parentContainer, 'arrow',  // arrow sprite
                    29, 30, // dw, dh // sw, sh
                    this.superChoosing[choiceIndex].x, this.superChoosing[choiceIndex].y, 0, 2, 2, false, true);
                sprite.gotoAndPlay(choiceIndex);
                sprite.name = 'arrow' + (choiceIndex + 5);
                sprite.animationSpeed = 0.05;
                sprite.position.set(x + 30, y - 150);
                sprite.interactive = true;
                sprite.buttonMode = true;
                sprite.loop = true;
                parentContainer.addChild(sprite);

                choiceIndex === 0 ?
                    box.play() :
                    setTimeout(() => box.play(), 1500);

                box.on('pointerdown', () => this.newChoice(choiceIndex + 5));
            }
        });

        if (App.configs.subMode === 'kiosk') {
            let x = this.superChoosing[0].x;
            let y = this.superChoosing[0].y + 170;
            const richText = new Text('Press HOLD 2', this.textBonus);
            richText.name = 'Press2';
            richText.position.set(x, y);
            parentContainer.addChild(richText);

            x = this.superChoosing[1].x;
            y = this.superChoosing[1].y + 170;
            const richText2 = new Text('Press HOLD 4', this.textBonus);
            richText2.name = 'Press4';
            richText2.position.set(x, y);
            parentContainer.addChild(richText2);
        }
    }

    /**
     * Функция анимации самого выбора в чузинге
     */
    showChoiceAnimation(response) {
        const parentContainer = this.getStageChild('bonusContainer');
        this.setState('CHOOSING_ANIMATION');
        parentContainer.interactiveChildren = false;
        const choice = response.choice;
        const multiplier = response.multiplier;
        let frameCount, imageSprite, colCount;
        const spriteRow = this.getWinPicture(multiplier);
        let sound, image2, catFrame;

        const fruitSprite = 'choosing';
        if (response.stop || response.multiplier === 0) {               // lose animatin
            frameCount = this.choosing[8].frameCount;
            colCount = this.choosing[8].colCount;
            imageSprite = 'choosingLose';
            sound = 'choosingLose';
            image2 = 'catLose';
            catFrame = 12;
        } else {
            frameCount = this.choosing[7].frameCount;                 // win animation
            colCount = this.choosing[7].colCount;
            imageSprite = 'choosingWin';
            sound = 'choosingWin';
            image2 = 'catWaiting';
            catFrame = 20;
        }

        const cat = new AnimatedSprite(this.getSpriteTextures({
            width: 72,
            height: 90,
            image: image2,
            colCount: 20,
            toFrame: catFrame
        }));
        cat.name = 'cat';
        cat.animationSpeed = 0.08;
        cat.position.set(240, 60);
        cat.loop = true;
        cat.visible = true;
        parentContainer.addChild(cat);

        const sprite2 = this.createChoosingElement(parentContainer, fruitSprite,  // oven sprite
            121, 180, // dw, dh // sw, sh
            this.choosing[choice].x, this.choosing[choice].y, spriteRow, 15, 15, false, false);
        sprite2.onFrameChange = frame => {
            sprite2.position.set(this.choosing[choice].x, this.choosing[choice].y);
            if (frame === 10) {
                cat.play();
            }
            if (frame === 13) {
                sprite2.stop();
                App.Sounds.playSound(sound);
                if (multiplier > 0) {
                    const x = this.choosing[choice].textPosition.x;
                    const y = this.choosing[choice].textPosition.y;
                    const richText = new Text(multiplier, this.textBonus);
                    richText.name = 'choiceMultiplier' + choice;
                    richText.position.set(x, y);
                    parentContainer.addChild(richText);
                }
            }
        };
        const sprite = this.createChoosingElement(parentContainer, imageSprite,  // granny sprite
            this.choosing[choice].w, this.choosing[choice].h, // w h
            this.choosing[choice].x - 50, 284, 0, frameCount, colCount, true);
        sprite.onFrameChange = frame => {
            if (frame === 9) {
                sprite2.visible = true;
                sprite2.play();
            }
            if (frame === frameCount - 1) {
                this.screenMultiplier.win[choice] = multiplier;
                this.screenMultiplier.mult[choice] = multiplier;
                this.nextChoosing(response);
                sprite2.visible = false;
                sprite2.stop();
                sprite.visible = false;
                sprite.stop();
            }
        };
    }

    /**
     * Функция анимации вібора в супер бонусе
     */
    showSuperChoiceAnimation(response) {
        const parentContainer = this.getStageChild('bonusContainer');

        const boxSprite1 = parentContainer.getChildByName('choiceIndex5');
        boxSprite1.stop();
        boxSprite1.visible = false;

        const boxSprite2 = parentContainer.getChildByName('choiceIndex6');
        boxSprite2.stop();
        boxSprite2.visible = false;

        const arrow1 = parentContainer.getChildByName('arrow5');
        arrow1.stop();
        arrow1.visible = false;

        const arrow2 = parentContainer.getChildByName('arrow6');
        arrow2.stop();
        arrow2.visible = false;

        App.Sounds.stopSound('superWaiting');
        this.setState('CHOOSING_ANIMATION');
        parentContainer.interactiveChildren = false;
        const choice = response.choice - this.superBonusStep - 1;
        const multiplier = response.multiplier;
        let frameCount, imageSprite, colCount;
        this.superScreenMultiplier.win[choice] = -2;

        if (response.multiplier === 0) {
            frameCount = this.superChoosing[1].frameCount;
            colCount = this.superChoosing[1].colCount;
            imageSprite = 'superChoosingLose';
        } else {
            frameCount = this.superChoosing[0].frameCount;
            colCount = this.superChoosing[0].colCount;
            imageSprite = 'superChoosingWin';
        }

        parentContainer.getChildByName('choiceIndex' + response.choice).visible = false;
        const sprite = this.createChoosingElement(parentContainer, imageSprite,
            this.superChoosing[choice].w, this.superChoosing[choice].h, // w h
            this.superChoosing[choice].x - 200, 102, 0, frameCount, colCount, true);

        sprite.onFrameChange = frame => {
            if (frame === sprite.textures.length - 1) {
                this.superScreenMultiplier.win[choice] = multiplier;
                this.superScreenMultiplier.mult[choice] = multiplier;
                sprite.visible = true;
                sprite.stop();
                const x = this.superChoosing[choice].textPosition.x;
                const y = this.superChoosing[choice].textPosition.y;
                if (multiplier > 0) {
                    const richText = new Text(multiplier, this.textSuperBonus);
                    richText.name = 'choiceMultiplier' + choice;
                    richText.position.set(x, y);
                    parentContainer.addChild(richText);
                }
                setTimeout(() => this.animateMultiplier(response), 1000);
            }
        };
    }

    showChosedItem(parentContainer, interactive = true) {
        parentContainer.removeChildren();
        parentContainer.interactiveChildren = interactive;
        let x, y, sy;
        this.screenMultiplier.win.forEach((multiplier, choiceIndex) => {
            if (multiplier === 0) {     // нет віигрыша
                x = this.choosing[choiceIndex].x;
                y = this.choosing[choiceIndex].y;

                const baseTexture = new PIXI.BaseTexture(this.getImage('choosedItems'));
                const texture = new PIXI.Texture(baseTexture, new PIXI.Rectangle(0, 0, 121, 180));
                const sprite = PIXI.Sprite.from(texture);

                sprite.name = 'choiceIndex' + choiceIndex + 'lose';
                sprite.position.set(x, y);
                sprite.interactive = true;
                sprite.buttonMode = true;
                parentContainer.addChild(sprite);

                x = this.choosing[choiceIndex].textPosition.x;
                x = this.choosing[choiceIndex].textPosition.x;
                y = this.choosing[choiceIndex].textPosition.y;
                const richText = new Text(multiplier, this.textBonus);
                richText.name = 'choiceMultiplier' + choiceIndex;
                richText.position.set(x, y);
                parentContainer.addChild(richText);
            }
            if (multiplier > 0) {    // если не -1 значит выбор был сделан- отключаем кнопку, отображеем выигрыш и сумму
                x = this.choosing[choiceIndex].x;
                y = this.choosing[choiceIndex].y;
                sy = this.getWinPicture(multiplier) * 180;

                const baseTexture = new PIXI.BaseTexture(this.getImage('choosedItems'));
                const texture = new PIXI.Texture(baseTexture, new PIXI.Rectangle(0, sy, 121, 180));
                const sprite = PIXI.Sprite.from(texture);

                sprite.name = 'choiceIndex' + choiceIndex + 'lose';
                sprite.position.set(x, y);
                sprite.interactive = true;
                sprite.buttonMode = true;

                parentContainer.addChild(sprite);
                x = this.choosing[choiceIndex].textPosition.x;
                y = this.choosing[choiceIndex].textPosition.y;
                const richText = new Text(multiplier, this.textBonus);
                richText.name = 'choiceMultiplier' + choiceIndex;
                richText.position.set(x, y);
                parentContainer.addChild(richText);
            }
            if (multiplier === -2) {    // если не -1 значит выбор был сделан- отключаем кнопку, отображеем выигрыш и сумму
                x = this.choosing[choiceIndex].x;
                y = this.choosing[choiceIndex].y;
                sy = this.getWinPicture(multiplier) * 180;

                const baseTexture = new PIXI.BaseTexture(this.getImage('choosedItems'));
                const texture = new PIXI.Texture(baseTexture, new PIXI.Rectangle(0, sy, 121, 180));
                const sprite = PIXI.Sprite.from(texture);

                sprite.name = 'choiceIndex' + choiceIndex + 'lose';
                sprite.position.set(x, y);
                sprite.interactive = true;
                sprite.buttonMode = true;

                parentContainer.addChild(sprite);
            }

            if (multiplier === -1) {    // выбор еще не сделан, // отображаем первый кадр анимации
                x = this.choosing[choiceIndex].x;
                y = this.choosing[choiceIndex].y + 40;
                const sprite = new Sprite(this.getTexture('roupe')); // закрітая печка
                sprite.name = 'choiceIndex' + choiceIndex;
                sprite.position.set(x, y);
                sprite.interactive = true;
                sprite.buttonMode = true;
                sprite.on('pointerdown', () => this.newChoice(choiceIndex));
                parentContainer.addChild(sprite);
            }
        });
    };

    getProtection(response) {
        return false;
    }

    getWinPicture = multiplier => {
        let picture = 0;
        if (multiplier > 0 && multiplier <= 10) { // fish
            picture = 2;
        }
        if (multiplier > 10 && multiplier <= 20) { // dish
            picture = 3;
        }
        if (multiplier > 20 && multiplier <= 30) { // cake
            picture = 1;
        }
        if (multiplier > 30 && multiplier <= 40) { // chicken
            picture = 4;
        }
        if (multiplier > 40 && multiplier <= 50) { // pig
            picture = 5;
        }
        if (multiplier === -1) {
            picture = -1;
        }
        if (multiplier === -2) {
            picture = 6;
        }

        return picture;
    };

    /**
     * Функция востановления выбора после чузинга
     */
    restoreChoosingScreen(response) {
        App.Sounds.stopSound('bonusGameStart');
        this.hideBoxes();
        this.setBackground('bonusArea');
        this.Legends.clearStatus();
        this.Legends.showWinFeatures();
        this.disableChooseButtons();
        this.Buttons.disableAllButtons();
        this.choosingWin = this.latestResponse.payment;
        this.screenMultiplier.win = this.getMultiplier(response);
        this.screenMultiplier.mult = this.getMultiplier(response);
        this.choosingStep = response.step;
        this.Legends.setText('win', {text: 'win', value: this.choosingWin});
        this.Legends.setStatus('creditsWon', this.choosingWin);
        if (response.stop) {
            this.finishChoosing(response);      // stop finish shoosing take win
        } else {
            response.step >= this.superBonusStep || this.getSuperBonus(response) ?
                this.startSuperBonus(response) :
                setTimeout(() => this.nextChoosing(response), 500);        // go next choosing step
            App.updateState('buttons', {visualization: 'choosing'});
        }
    }

    nextChoosing(response) {
        this.setState('CHOOSING_IDLE');
        if (this.superBonusStep <= response.step || response.stop || this.getSuperBonus(response) || response.multiplier === -2) {
            this.showChosedItem(this.getStageChild('bonusContainer'), false);
            this.animateMultiplier(response);
        } else {
            this.showChosedItem(this.getStageChild('bonusContainer'));
        }
    }

    getSuperBonus(response) {
        let isSuperBonus = false;
        response.prevChoosings.forEach((choosing) => {
            if (choosing.multiplier === -2) {
                isSuperBonus = true;
            }
        });
        return isSuperBonus;
    }

    /**
     * Фукнция отображения выбора Чузинга на ответ сервера
     */
    showChoice(response) {
        this.setState('CHOOSING_ANIMATION');
        this.superBonusStep < response.step || this.getSuperBonus(response) ?    // супер бонус игра
            this.showSuperChoiceAnimation(response) :
            this.showChoiceAnimation(response);
    }

    /**
     * Create additional sprites and animations
     * Call once when game loaded
     * @param parentContainer
     */
    addWaitingAnimationSprites(parentContainer) {
        let waitingType = this.getWaitingState();
        if (this.waitingType === waitingType) {
            return;
        }

        parentContainer.removeChildren();
        let image, image2;

        switch (waitingType) {
            case 'RESPONSE_RECEIVED':
                image = 'waitingRoll'; // no protectins for this game
                image2 = null;
                break;
            case 'CHOOSING_IDLE':
                image = 'waitingChoosing';
                image2 = 'catWaiting';
                break;
            case 'DOUBLING':
                image = 'waitingDoubling';
                image2 = null;
                break;
            case 'SUPER_BONUS_IDLE':
                image = 'waitingSuperChoosing';
                image2 = null;
                break;
            case 'GAMBLE_ANIMATION':
                this.waitingType = waitingType;
                return;
            case 'IDLE':
                waitingType = 'IDLE';
                image = 'waitingRoll';
                image2 = null;
                break;
        }
        let posX = typeof this.choosingResponse.choice !== 'undefined' ?
            this.choosing[this.choosingResponse.choice].x - 50 : this.waitingSettings[waitingType].x;
        posX = (this.choosingResponse.step >= this.superBonusStep || waitingType === 'SUPER_BONUS_IDLE') ?
            this.waitingSettings['SUPER_BONUS_IDLE'].x : posX;
        const posY = this.waitingSettings[waitingType].y;
        const width = this.waitingSettings[waitingType].w;
        const height = this.waitingSettings[waitingType].h;
        const frameCount = this.waitingSettings[waitingType].frameCount;
        const colCount = this.waitingSettings[waitingType].colCount;

        const waiting = new AnimatedSprite(this.getSpriteTextures({
            width: width,
            height: height,
            image: image,
            colCount: colCount,
            toFrame: frameCount
        }));

        if (image2 != null) {
            const cat = new AnimatedSprite(this.getSpriteTextures({
                width: 72,
                height: 90,
                image: image2,
                colCount: 20,
                toFrame: 40
            }));
            cat.animationSpeed = 0.05;
            cat.position.set(240, 60);
            cat.play();
            parentContainer.addChild(cat);
        }

        waiting.name = 'waiting';
        waiting.animationSpeed = 0.07;
        waiting.position.set(posX, posY);
        waiting.loop = true;
        waiting.visible = true;
        waiting.play();
        parentContainer.addChild(waiting);
        this.waitingType = waitingType;
    }

    /**
     * Get current Game state
     * @returns {string|*}s
     */
    getWaitingState() {
        let state = this.getState();
        switch (state) {
            case 'RESPONSE_RECEIVED':
                break;
            case 'CHOOSING_IDLE':
                break;
            case 'DOUBLING':
                break;
            case 'SUPER_BONUS_IDLE':
                break;
            case 'DRAW_GAME':
            case 'GAMBLE_ANIMATION':
            case 'CHOOSING_ANIMATION':
            case 'ANIMATE_MULTIPLIER':
                state = 'GAMBLE_ANIMATION';
                break;
            default:  // idle response resived
                state = 'IDLE';
                break;
        }
        return state;
    }
}

class GambleUnique extends GambleIgrosoft {
    constructor(imageResources) {
        super(imageResources);
        this.cloudx = 240;
        this.cloudy = 350;
        this.riskStepX = 468;
        this.riskStepY = 58;
        this.cardWeigth = 134;
        this.cardHeight = 203;
        this.cardPositions = [
            [38, 119],
            [198, 119],
            [339, 119],
            [479, 119],
            [620, 119]
        ];
        this.dealerY = 203;
    }

    drawGambleTexts = ctx => {
        ctx.fillStyle = '#ffff00';
        ctx.strokeStyle = '#000';
        ctx.lineWidth = 2;
        ctx.textAlign = 'center';
        ctx.font = '30px Arial';
        ctx.strokeText(this.step + 1, 470, 90);
        ctx.fillText(this.step + 1, 470, 90);
    };

    showStatus = (state) => {
        App.Game.setState('GAMBLE_ANIMATION');
        let imageMonkey, imageCloud, sound;
        switch (state) {
            case 'w':
                sound = 'risk-win';
                imageMonkey = 'gambleWin';
                imageCloud = 'cloudWin';
                break;
            case 'l':
                sound = 'risk-lose';
                imageMonkey = 'gambleLose';
                imageCloud = 'cloudLose';
                break;
            case 'f':
                sound = 'risk-forward';
                imageMonkey = 'gambleForward';
                imageCloud = 'cloudForward';
                break;
        }
        imageMonkey = 'waitingDoubling';

        App.Sounds.playSound(sound);
        const parentContainer = App.Game.getStageChild('bonusContainer');
        const sprite1 = App.Game.createChoosingElement(parentContainer, imageMonkey,                             // monkey
            App.Game.waitingSettings['DOUBLING'].w, App.Game.waitingSettings['DOUBLING'].h, // dw, dh // sw, sh
            App.Game.waitingSettings['DOUBLING'].x, App.Game.waitingSettings['DOUBLING'].y, 0, 4, 4, true, true);
        parentContainer.addChild(sprite1);

        const sprite2 = App.Game.createChoosingElement(parentContainer, imageCloud,                             // cloud
            220, 103, // dw, dh // sw, sh
            this.cloudx, this.cloudy, 0, 3, 3, true, true);
        parentContainer.addChild(sprite2);
    };
}
