import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import Lines from './../lines';
import App from './../../index';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Texture = PIXI.Texture,
    Text = PIXI.Text;

export default class Lines20 extends Lines {
    constructor() {
        super();
        this.imageResources = {
            lines: '/img/lines/Lines20/lines.png',
            winLines: '/img/lines/Lines20/winlines.png',
            winLinesBox: '/img/lines/Lines20/winLinesBox.png',
            boxes: '/img/lines/Lines20/boxes.png'
        };
        this.lineImageWidth = 142; // width of line image in sprite, magic number for each game
        this.lineImageHeight = 147; // height of line image in sprite, magic number for each game

        this.lines = {
            0: {
                coordinates: [1, 1, 1, 1, 1],
                boxes: [{x: 0, y: 248}],
                path: [
                    {x: 35, y: 210},
                    {x: 187, y: 210},
                    {x: 329, y: 210},
                    {x: 471, y: 210},
                    {x: 613, y: 210}
                ],
                text: [
                    {x: 120, y: 342},
                    {x: 263, y: 343},
                    {x: 405, y: 343}
                ]
            },
            1: {
                coordinates: [0, 0, 0, 0, 0],
                boxes: [{x: 0, y: 168}],
                path: [
                    {x: 35, y: 80},
                    {x: 187, y: 80},
                    {x: 329, y: 80},
                    {x: 471, y: 80},
                    {x: 613, y: 80}
                ],
                text: [
                    {x: 120, y: 212},
                    {x: 263, y: 213},
                    {x: 405, y: 213}
                ]
            },
            2: {
                coordinates: [2, 2, 2, 2, 2],
                boxes: [{x: 0, y: 328}],
                path: [
                    {x: 35, y: 340},
                    {x: 187, y: 340},
                    {x: 329, y: 340},
                    {x: 471, y: 340},
                    {x: 613, y: 340}

                ],
                text: [
                    {x: 120, y: 472},
                    {x: 263, y: 473},
                    {x: 405, y: 473}
                ]
            },
            3: {
                coordinates: [0, 1, 2, 1, 0],
                boxes: [{x: 0, y: 90}],
                path: [
                    {x: 35, y: 89},
                    {x: 187, y: 210},
                    {x: 329, y: 349},
                    {x: 471, y: 210},
                    {x: 613, y: 89}
                ],
                text: [
                    {x: 120, y: 212},
                    {x: 263, y: 343},
                    {x: 405, y: 473}
                ]
            },
            4: {
                coordinates: [2, 1, 0, 1, 2],
                boxes: [{x: 0, y: 408}],
                path: [
                    {x: 35, y: 337},
                    {x: 187, y: 207},
                    {x: 329, y: 77},
                    {x: 471, y: 207},
                    {x: 613, y: 337}
                ],
                text: [
                    {x: 120, y: 473},
                    {x: 263, y: 343},
                    {x: 405, y: 213}
                ]
            },
            5: {
                coordinates: [1, 2, 2, 2, 1],
                boxes: [{x: 765, y: 248}],
                path: [
                    {x: 35, y: 213},
                    {x: 187, y: 343},
                    {x: 329, y: 343},
                    {x: 471, y: 343},
                    {x: 613, y: 213}
                ],
                text: [
                    {x: 120, y: 342},
                    {x: 263, y: 473},
                    {x: 405, y: 473}
                ]
            },
            6: {
                coordinates: [1, 0, 0, 0, 1],
                boxes: [{x: 765, y: 328}],
                path: [
                    {x: 35, y: 208},
                    {x: 187, y: 79},
                    {x: 329, y: 74},
                    {x: 471, y: 79},
                    {x: 613, y: 206}
                ],
                text: [
                    {x: 120, y: 342},
                    {x: 263, y: 213},
                    {x: 405, y: 213}
                ]
            },
            7: {
                coordinates: [2, 2, 1, 0, 0],
                boxes: [{x: 765, y: 168}],
                path: [
                    {x: 35, y: 349},
                    {x: 187, y: 349},
                    {x: 329, y: 210},
                    {x: 471, y: 71},
                    {x: 613, y: 71}
                ],
                text: [
                    {x: 120, y: 472},
                    {x: 263, y: 473},
                    {x: 405, y: 343}
                ]
            },
            8: {
                coordinates: [0, 0, 1, 2, 2],
                boxes: [{x: 765, y: 408}],
                path: [
                    {x: 35, y: 71},
                    {x: 187, y: 71},
                    {x: 329, y: 210},
                    {x: 471, y: 349},
                    {x: 613, y: 349}
                ],
                text: [
                    {x: 120, y: 212},
                    {x: 263, y: 213},
                    {x: 405, y: 343}
                ]
            },
            9: {
                coordinates: [2, 1, 1, 1, 0],
                boxes: [{x: 765, y: 88}],
                path: [
                    {x: 35, y: 331},
                    {x: 187, y: 214},
                    {x: 329, y: 214},
                    {x: 471, y: 214},
                    {x: 613, y: 89}
                ],
                text: [
                    {x: 120, y: 472},
                    {x: 263, y: 343},
                    {x: 405, y: 343}
                ]
            },
            10: {
                coordinates: [0, 1, 1, 1, 2],
                boxes: [{x: 765, y: 368}],
                path: [
                    {x: 35, y: 79},
                    {x: 187, y: 209},
                    {x: 329, y: 209},
                    {x: 471, y: 209},
                    {x: 613, y: 339}
                ],
                text: [
                    {x: 120, y: 213},
                    {x: 263, y: 346},
                    {x: 405, y: 346}
                ]
            },
            11: {
                coordinates: [1, 2, 1, 0, 1],
                boxes: [{x: 0, y: 208}],
                path: [
                    {x: 35, y: 204},
                    {x: 187, y: 334},
                    {x: 329, y: 204},
                    {x: 471, y: 74},
                    {x: 613, y: 204}
                ],
                text: [
                    {x: 120, y: 342},
                    {x: 263, y: 477},
                    {x: 405, y: 338}
                ]
            },
            12: {
                coordinates: [1, 0, 1, 2, 1],
                boxes: [{x: 765, y: 208}],
                path: [
                    {x: 35, y: 210},
                    {x: 187, y: 82},
                    {x: 329, y: 215},
                    {x: 471, y: 340},
                    {x: 613, y: 212}
                ],
                text: [
                    {x: 120, y: 342},
                    {x: 263, y: 213},
                    {x: 405, y: 348}
                ]
            },
            13: {
                coordinates: [0, 1, 0, 1, 0],
                boxes: [{x: 0, y: 128}],
                path: [
                    {x: 35, y: 73},
                    {x: 187, y: 203},
                    {x: 329, y: 73},
                    {x: 471, y: 203},
                    {x: 613, y: 73}
                ],
                text: [
                    {x: 120, y: 212},
                    {x: 263, y: 338},
                    {x: 405, y: 213}
                ]
            },
            14: {
                coordinates: [2, 1, 2, 1, 2],
                boxes: [{x: 0, y: 368}],
                path: [
                    {x: 35, y: 339},
                    {x: 187, y: 209},
                    {x: 329, y: 339},
                    {x: 471, y: 209},
                    {x: 613, y: 339}
                ],
                text: [
                    {x: 120, y: 473},
                    {x: 263, y: 340},
                    {x: 405, y: 474}
                ]
            },
            15: {
                coordinates: [1, 1, 0, 1, 1],
                boxes: [{x: 0, y: 288}],
                path: [
                    {x: 35, y: 211},
                    {x: 187, y: 211},
                    {x: 329, y: 77},
                    {x: 471, y: 200},
                    {x: 613, y: 200}
                ],
                text: [
                    {x: 120, y: 348},
                    {x: 263, y: 348},
                    {x: 405, y: 213}
                ]
            },
            16: {
                coordinates: [1, 1, 2, 1, 1],
                boxes: [{x: 765, y: 288}],
                path: [
                    {x: 35, y: 213},
                    {x: 187, y: 213},
                    {x: 329, y: 346},
                    {x: 471, y: 224},
                    {x: 613, y: 224}
                ],
                text: [
                    {x: 120, y: 338},
                    {x: 263, y: 338},
                    {x: 405, y: 473}
                ]
            },
            17: {
                coordinates: [0, 2, 0, 2, 0],
                boxes: [{x: 765, y: 128}],
                path: [
                    {x: 27, y: 70},
                    {x: 177, y: 220},
                    {x: 316, y: 80},
                    {x: 464, y: 220},
                    {x: 606, y: 72}
                ],
                text: [
                    {x: 120, y: 212},
                    {x: 263, y: 477},
                    {x: 405, y: 213}
                ]
            },
            18: {
                coordinates: [2, 0, 2, 0, 2],
                boxes: [{x: 0, y: 448}],
                path: [
                    {x: 35, y: 212},
                    {x: 178, y: 50},
                    {x: 319, y: 212},
                    {x: 461, y: 50},
                    {x: 602, y: 212}
                ],
                text: [
                    {x: 120, y: 472},
                    {x: 263, y: 212},
                    {x: 405, y: 473}
                ]
            },
            19: {
                coordinates: [2, 0, 1, 0, 2],
                boxes: [{x: 765, y: 448}],
                path: [
                    {x: 39, y: 272},
                    {x: 178, y: 76},
                    {x: 319, y: 76},
                    {x: 461, y: 76},
                    {x: 605, y: 272}
                ],
                text: [
                    {x: 120, y: 473},
                    {x: 263, y: 213},
                    {x: 405, y: 341}
                ]
            }
        };

        JL().debug('-- Lines20 initialized');
    }

    drawLineImages(lines, winReels, parentContainer, winLine, payment) {
        lines.forEach(lineIndex => {
            const line = this.lines[lineIndex];
            const winStartPos = (winReels === []) ? 0 : winReels[0];
            line.coordinates.forEach((rowIndex, reelIndex) => {
                const image = winReels.includes(reelIndex) ?
                    winStartPos === reelIndex ?
                        'winLinesBox' : 'winLines' :
                    'lines';

                this.lineImageWidth = 142; // width of line image in sprite, magic number for each game
                this.lineImageHeight = 148; // height of line image in sprite, magic number for each game
                let spritePosX = this.lineImageWidth * reelIndex;
                let spritePosY = this.lineImageHeight * lineIndex;

                if (reelIndex === 0) {
                    this.lineImageWidth = 152;
                    spritePosX = 35;
                }

                if (reelIndex === 4) {
                    this.lineImageWidth = 158;
                    spritePosX += 45;
                }

                if (reelIndex !== 4 && reelIndex !== 0) {
                    this.lineImageWidth = 142;
                    spritePosX += 45;
                }

                if (lineIndex === 17) {
                    spritePosX = 160 * reelIndex;
                    spritePosY = 2520;
                    this.lineImageHeight = 270;
                    this.lineImageWidth = 160;
                }
                if (lineIndex === 18) {
                    spritePosX = 160 * reelIndex;
                    spritePosY = 2790;
                    this.lineImageHeight = 270;
                    this.lineImageWidth = 160;
                }
                if (lineIndex === 19) {
                    spritePosX = 160 * reelIndex;
                    spritePosY = 3072;
                    this.lineImageHeight = 270;
                    this.lineImageWidth = 160;
                }

                // create line sprite
                const sprite = new Sprite(new Texture(App.Game.getTexture(image), {
                    x: spritePosX,
                    y: spritePosY,
                    width: this.lineImageWidth,
                    height: this.lineImageHeight
                }));
                sprite.position.set(line.path[reelIndex].x, line.path[reelIndex].y);
                sprite.name = lineIndex;
                parentContainer.addChild(sprite);

                if (winLine && winStartPos < 3) {
                    this.addLineWin(parentContainer, payment, line.text[winStartPos].x, line.text[winStartPos].y);
                }
            });
        });
    }

    drawBoxes(parentContainer) {
        parentContainer.removeChildren(); // clear all previous boxes

        const currentLines = App.Game.gameSettings.getCurrentLineMas();
        const boxWidth = 35; // width of winline boxes
        const boxHeight = 34; // height of winline boxes

        Object.keys(this.lines).forEach(lineIndex => {
            this.lines[lineIndex].boxes.forEach(box => {
                const activeLine = currentLines.includes(+lineIndex + 1);

                // create box sprite
                const sprite = new Sprite(new Texture(App.Game.getTexture('boxes'), {
                    x: 0,
                    y: activeLine ? boxHeight * lineIndex : // if line enabled
                        box.x === 0 ? boxHeight * 21 : boxHeight * 20, // check left/right box
                    width: boxWidth,
                    height: boxHeight
                }));
                sprite.position.set(box.x, box.y);
                sprite.name = lineIndex;
                activeLine && this.addBoxEvents(sprite);
                parentContainer.addChild(sprite);

                // add text to active box
                if (activeLine) {
                    const text = new Text(+lineIndex + 1, {
                        fontFamily: 'Arial',
                        fontSize: 19,
                        fill: '#000',
                        lineJoin: 'round'
                    });
                    text.anchor.set(0.5);
                    text.position.set(box.x + boxWidth / 2, box.y + boxHeight / 2);
                    parentContainer.addChild(text);
                }
            });
        });
    }
}
