import * as PIXI from 'pixi.js-legacy';

import './../../igrosoft/styles.less';
import App from './../../../index';
import GameIgrosoft from './../../igrosoft/game';
import Lines9 from './../../igrosoft/lines9';
import GambleIgrosoft from './../../igrosoft/gamble';
import InfoScreen from '../../infoScreen';

/* PIXI aliases */
const AnimatedSprite = PIXI.AnimatedSprite,
    Text = PIXI.Text,
    Sprite = PIXI.Sprite;

export default class CrazyMonkey extends GameIgrosoft {
    constructor() {
        super();
        this.id = 'crazy-monkey-1';
        this.name = 'Crazy Monkey';
        this.buttonsPanelShadow = 'none';
        this.superBonusStep = 4; // шаг чузинга поле которого начинается супер бонус
        this.scatter = 8;
        this.choosingResponse = []; // последний пакет чузинга

        // choosing image size, box coordinates for animation
        this.choosing = [      // choosing image size, box coordinates for animation
            {w: 278, h: 350, x: 0, y: 250, textPosition: {x: 150, y: 270}, frameCount: 32, colCount: 6},  // 0 hold
            {w: 278, h: 350, x: 100, y: 250, textPosition: {x: 250, y: 270}, frameCount: 32, colCount: 6}, // 1 hold
            {w: 278, h: 350, x: 200, y: 250, textPosition: {x: 350, y: 270}, frameCount: 32, colCount: 6}, // 2 hold
            {w: 278, h: 350, x: 300, y: 250, textPosition: {x: 450, y: 270}, frameCount: 32, colCount: 6}, // 3 hold
            {w: 278, h: 350, x: 400, y: 250, textPosition: {x: 550, y: 270}, frameCount: 32, colCount: 6}, // 4 hold
            {w: 173, h: 169, x: 95, y: 330, textPosition: {x: 200, y: 300}, frameCount: 8, colCount: 6}, // 5
            {w: 173, h: 169, x: 534, y: 330, textPosition: {x: 640, y: 300}, frameCount: 8, colCount: 6}, // 6
            {w: 172, h: 233, frameCount: 32, colCount: 6}, // 7 win
            {w: 278, h: 550, frameCount: 36, colCount: 6}  // 8 lose
        ];
        // super choosing image size, box coordinates for animation
        this.superChoosing = [
            {w: 173, h: 167, x: 95, y: 325, textPosition: {x: 150, y: 350}, frameCount: 8, colCount: 9}, // left
            {w: 173, h: 167, x: 534, y: 325, textPosition: {x: 590, y: 350}, frameCount: 8, colCount: 9} // right
        ];
        // waiting image size, box coordinates for animation
        this.waitingSettings = {
            IDLE: {w: 260, h: 140, x: 200, y: 460, frameCount: 26, animationSpeed: 150, colCount: 9},
            RESPONSE_RECEIVED: {w: 260, h: 140, x: 200, y: 460, frameCount: 6, animationSpeed: 150, colCount: 6},
            SHOW_WIN_LINES: {w: 260, h: 140, x: 200, y: 460, frameCount: 2, animationSpeed: 150, colCount: 2},
            SCATTER_FEATURE: {w: 260, h: 140, x: 200, y: 460, frameCount: 4, animationSpeed: 150, colCount: 4},
            DOUBLING: {w: 208, h: 240, x: 19, y: 375, frameCount: 20, animationSpeed: 150, colCount: 10},
            CHOOSING_IDLE: {w: 172, h: 233, x: 0, y: 367, frameCount: 33, animationSpeed: 150, colCount: 11},
            SUPER_BONUS_IDLE: {w: 217, h: 227, x: 290, y: 367, frameCount: 2, animationSpeed: 20, colCount: 2}
        };

        this.symbols = [
            {regularDelay: 500, payment: [0, 0, 0, 2, 3, 10]},        // 0 - buterfly
            {regularDelay: 500, payment: [0, 0, 0, 3, 5, 20]},        // 1 - banana
            {regularDelay: 500, payment: [0, 0, 0, 5, 10, 50]},       // 2 - snake
            {regularDelay: 500, payment: [0, 0, 0, 10, 30, 100]},     // 3 - anvil
            {regularDelay: 500, payment: [0, 0, 0, 20, 50, 200]},     // 4 - pineapple
            {regularDelay: 500, payment: [0, 0, 0, 30, 100, 500]},    // 5 - lion
            {regularDelay: 500, payment: [0, 0, 0, 200, 1000, 5000]}, // 6 - crazy monkey symbol
            {regularDelay: 500, payment: [0, 0, 0, 100, 500, 2000]},  // 7 - mask
            {regularDelay: 500, payment: [0, 0, 0, 0, 0, 0]}          // 8 - monkey
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                waitingRoll: 'area/waiting_roll.png',
                waitingRollProtection: 'area/waiting_roll_protection.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                bonusArea: 'area/bonus.png',
                superBonusArea: 'area/super_bonus.png',
                gambleArea: 'area/gamble.png',

                // waiting
                waitingDoubling: 'gamble/waiting_doubling.png',
                waitingDoublingProtection: 'gamble/waiting_doubling_protection.png',
                waitingChoosing: 'bonus/waiting_choosing.png',
                waitingChoosingProtection: 'bonus/waiting_choosing_protection.png',
                waitingSuperChoosing: 'bonus/super_choosing_waiting.png',
                waitingSuperChoosingProtection: 'bonus/super_choosing_waiting_protection.png',

                // win animation
                win: 'area/win.png',
                winProtection: 'area/win_protection.png',
                winScatter: 'area/win_scatter.png',
                winScatterProtection: 'area/win_scatter_protection.png',

                // gamble win lose
                gambleWin: 'gamble/doubling_win.png',
                gambleWinProt: 'gamble/doubling_win_prot.png',
                gambleLose: 'gamble/doubling_lose.png',
                gambleLoseProt: 'gamble/doubling_lose_prot.png',
                gambleForward: 'gamble/doubling_forward.png',
                gambleForwardProt: 'gamble/doubling_forward_prot.png',

                cloudWin: 'gamble/win.png', // usually redefined
                cloudLose: 'gamble/lose.png', // usually redefined
                cloudForward: 'gamble/forward.png', // usually redefined

                // roll animation
                roll: 'area/roll.png',
                rollProtection: 'area/roll_protection.png',

                // chosing animations
                choosingWin: 'bonus/choosing_win.png',
                choosingWinProtection: 'bonus/choosing_win_protection.png',
                choosingLose: 'bonus/choosing_lose.png',
                choosingLoseProtection: 'bonus/choosing_lose_protection.png',
                superChoosingWin: 'bonus/super_choosing_win.png',
                superChoosingLose: 'bonus/super_choosing_lose.png',

                superChoosingWinMonkey: 'bonus/super_choosing1_win.png',
                superChoosingWinProtMonkey: 'bonus/super_choosing1_protection_win.png',
                superChoosingLoseMonkey: 'bonus/super_choosing1_lose.png',
                superChoosingLoseProtMonkey: 'bonus/super_choosing1_protection_lose.png',

                // choosing elements
                box: 'bonus/box.png',
                roupe: 'bonus/roupe.png',
                choosing: 'bonus/choosing.png'
            })
        };

        this.Gamble = new GambleUnique(this.mergePath({
            cards: 'gamble/cards.png',
            dealer: 'gamble/dealer.png',
            pick: 'gamble/pick.png'
        }));
        this.Gamble.riskStepX = 460;
        this.Gamble.riskStepY = 102;
        this.InfoScreen = new InfoScreen({pages: 5}); // number of game info states
        this.Lines = new Lines9();

        this.gameSounds = {
            soundClass: 'igrosoft',
            sounds: [
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'choosing_lose', alias: 'choosingLose'},
                {name: 'choosing_win', alias: 'choosingWin'},
                {name: 'choosing_multiplier', alias: 'choosingMultiplier'},
                {name: 'superchoosing_waiting', loop: true, alias: 'superWaiting'}
            ],
            path: `/game/games/${this.id}/audio/`
        };
    }

    /**
     * Функция отображения предыдущего выбора или супербонуса
     */
    showChosedItemSuperBonus(parentContainer) {
        let x, y;
        parentContainer.removeChildren();
        parentContainer.interactiveChildren = true;
        this.superScreenMultiplier.win.forEach((multiplier, choiceIndex) => {
            if (multiplier > 0) {    // если не -1 значит выбор был сделан- отключаем кнопку, отображеем выигрыш и сумму
                x = this.superChoosing[choiceIndex].textPosition.x;
                y = this.superChoosing[choiceIndex].textPosition.y;
                const richText = new Text(multiplier, this.textSuperBonus);
                richText.name = 'choiceMultiplier' + choiceIndex;
                richText.position.set(x, y);
                parentContainer.addChild(richText);
            }
            if (multiplier === -1) {    // выбор еще не сделан, // отображаем первый кадр анимации                        }
                x = this.superChoosing[choiceIndex].x;
                y = this.superChoosing[choiceIndex].y;
                const sprite = new Sprite(this.getTexture('box'));
                sprite.name = 'choiceIndex' + (choiceIndex + 5);
                sprite.position.set(x, y);
                sprite.interactive = true;
                sprite.buttonMode = true;
                sprite.on('pointerdown', () => this.newChoice(choiceIndex + 5));
                parentContainer.addChild(sprite);
            }
        });
        if (App.configs.subMode === 'kiosk') {
            let x = this.superChoosing[0].x;
            let y = this.superChoosing[0].y + 170;
            const richText = new Text('Press HOLD 2', this.textBonus);
            richText.name = 'Press2';
            richText.position.set(x, y);
            parentContainer.addChild(richText);

            x = this.superChoosing[1].x;
            y = this.superChoosing[1].y + 170;
            const richText2 = new Text('Press HOLD 4', this.textBonus);
            richText2.name = 'Press4';
            richText2.position.set(x, y);
            parentContainer.addChild(richText2);
        }
    }

    /**
     * Функция анимации самого выбора в чузинге
     */
    showChoiceAnimation(response) {
        const parentContainer = this.getStageChild('bonusContainer');
        this.setState('CHOOSING_ANIMATION');
        parentContainer.interactiveChildren = false;
        const choice = response.choice;
        const multiplier = response.multiplier;
        let frameCount, imageSprite, colCount;
        const spriteRow = this.getWinPicture(multiplier);
        let sound;

        const fruitSprite = 'choosing';
        if (response.stop || response.multiplier === 0) {               // lose animatin
            frameCount = this.choosing[8].frameCount;
            colCount = this.choosing[8].colCount;
            imageSprite = (this.getProtection(response) || (response.stop === false && response.multiplier === 0)) ? 'choosingLoseProtection' : 'choosingLose';
            sound = 'choosingLose';
        } else {
            frameCount = this.choosing[7].frameCount;                 // win animation
            colCount = this.choosing[7].colCount;
            imageSprite = (this.getProtection(response) || (response.stop === false && response.multiplier === 0)) ? 'choosingWinProtection' : 'choosingWin';
            sound = 'choosingWin';
        }

        const sprite2 = this.createChoosingElement(parentContainer, fruitSprite,  // fruit sprite
            this.choosing[choice].w, 200, // dw, dh // sw, sh
            this.choosing[choice].x, this.choosing[choice].y - 200, spriteRow, 7, 10, false, false);
        sprite2.onFrameChange = frame => {
            sprite2.position.set(this.choosing[choice].x, this.choosing[choice].y - 200 + frame * 29);
            if (frame === 6) {
                App.Sounds.playSound(sound);
                sprite2.visible = false;
                sprite2.stop();
                const x = this.choosing[choice].textPosition.x;
                const y = this.choosing[choice].textPosition.y;
                const richText = new Text(multiplier, this.textBonus);
                richText.name = 'choiceMultiplier' + choice;
                richText.position.set(x, y);
                parentContainer.addChild(richText);
            }
        };

        const sprite = this.createChoosingElement(parentContainer, imageSprite,  // monkey sprite
            this.choosing[choice].w, this.choosing[choice].h, // w h
            this.choosing[choice].x - 7, this.choosing[choice].y, 0, frameCount, colCount, true);
        sprite.onFrameChange = frame => {
            if (frame === 15) {
                sprite2.visible = true;
                sprite2.play();
            }
            if (frame === frameCount - 1) {
                this.screenMultiplier.win[choice] = multiplier;
                this.screenMultiplier.mult[choice] = multiplier;
                this.nextChoosing(response);
                sprite2.visible = false;
                sprite2.stop();
                sprite.visible = false;
                sprite.stop();
            }
        };
    }

    /**
     * Функция анимации вібора в супер бонусе
     */
    showSuperChoiceAnimation(response) {
        const parentContainer = this.getStageChild('bonusContainer');
        App.Sounds.stopSound('superWaiting');
        this.setState('CHOOSING_ANIMATION');
        parentContainer.interactiveChildren = false;
        const choice = response.choice - this.superBonusStep - 1;
        const multiplier = response.multiplier;
        const spriteRow = 0;
        let frameCount, imageSprite, colCount;
        this.superScreenMultiplier.win[choice] = -2;
        let imageMonkey, monkeyFrameCount;

        if (response.multiplier === 0) {
            imageMonkey = this.getProtection(this.choosingResponse) ? 'superChoosingLoseProtMonkey' : 'superChoosingLoseMonkey';
            monkeyFrameCount = 6;
            frameCount = this.superChoosing[0].frameCount;
            colCount = this.superChoosing[0].colCount;
            imageSprite = 'superChoosingLose';
        } else {
            imageMonkey = this.getProtection(this.choosingResponse) ? 'superChoosingWinProtMonkey' : 'superChoosingWinMonkey';
            monkeyFrameCount = 6;
            frameCount = this.superChoosing[1].frameCount;
            colCount = this.superChoosing[1].colCount;
            imageSprite = 'superChoosingWin';
        }

        const sprite1 = this.createChoosingElement(parentContainer, imageMonkey,                    // add mokey sprite
            this.choosing[0].w, this.choosing[0].h, // w h
            this.waitingSettings['SUPER_BONUS_IDLE'].x, this.waitingSettings['SUPER_BONUS_IDLE'].y - 110, spriteRow, monkeyFrameCount, monkeyFrameCount, false, true);
        sprite1.loop = false;

        sprite1.onFrameChange = frame => {
            if (frame === 5) {
                sprite1.stop();
                setTimeout(() => this.animateMultiplier(response), 1000);
            }
        };

        parentContainer.getChildByName('choiceIndex' + response.choice).visible = false;

        const sprite = this.createChoosingElement(parentContainer, imageSprite,                               // add box sprite
            this.superChoosing[choice].w, this.superChoosing[choice].h, // w h
            this.superChoosing[choice].x, this.superChoosing[choice].y, spriteRow, frameCount, colCount, true);

        sprite.onFrameChange = frame => {
            if (frame === 7) {
                this.superScreenMultiplier.win[choice] = multiplier;
                this.superScreenMultiplier.mult[choice] = multiplier;
                sprite.visible = true;
                sprite.stop();
                const x = this.superChoosing[choice].textPosition.x;
                const y = this.superChoosing[choice].textPosition.y;
                if (multiplier > 0) {
                    const richText = new Text(multiplier, this.textSuperBonus);
                    richText.name = 'choiceMultiplier' + choice;
                    richText.position.set(x, y);
                    parentContainer.addChild(richText);
                }
                sprite1.visible = true;
                sprite1.play();
            }
        };
    }

    showChosedItem(parentContainer, interactive = true) {
        parentContainer.removeChildren();
        parentContainer.interactiveChildren = interactive;
        let x, y;
        this.screenMultiplier.win.forEach((multiplier, choiceIndex) => {
            if (multiplier > 0) {    // если не -1 значит выбор был сделан- отключаем кнопку, отображеем выигрыш и сумму
                x = this.choosing[choiceIndex].textPosition.x;
                y = this.choosing[choiceIndex].textPosition.y;
                const richText = new Text(multiplier, this.textBonus);
                richText.name = 'choiceMultiplier' + choiceIndex;
                richText.position.set(x, y);
                parentContainer.addChild(richText);
            }
            if (multiplier === -1) {    // выбор еще не сделан, // отображаем первый кадр анимации
                x = this.choosing[choiceIndex].x + 178;
                y = this.choosing[choiceIndex].y - 200;
                const sprite = new Sprite(this.getTexture('roupe'));
                sprite.name = 'choiceIndex' + choiceIndex;
                sprite.position.set(x, y);
                sprite.interactive = true;
                sprite.buttonMode = true;
                sprite.on('pointerdown', () => this.newChoice(choiceIndex));
                parentContainer.addChild(sprite);
            }
        });
    };

    /**
     * Create additional sprites and animations
     * Call once when game loaded
     * @param parentContainer
     */
    addWaitingAnimationSprites(parentContainer) {
        let waitingType = this.getWaitingState();
        const protection = this.getProtection(this.choosingResponse);
        if (this.waitingType === waitingType && this.protection === protection) {
            return;
        }
        parentContainer.removeChildren();
        let image;
        switch (waitingType) {
            case 'CHOOSING_IDLE':
                image = protection ? 'waitingChoosingProtection' : 'waitingChoosing';
                break;
            case 'DOUBLING':
                image = protection ? 'waitingDoublingProtection' : 'waitingDoubling';
                break;
            case 'SUPER_BONUS_IDLE':
                image = protection ? 'waitingSuperChoosingProtection' : 'waitingSuperChoosing';
                break;
            case 'SHOW_WIN_LINES':
                image = protection ? 'winProtection' : 'win';
                break;
            case 'SCATTER_FEATURE':
                image = protection ? 'winScatterProtection' : 'winScatter';
                break;
            case 'RESPONSE_RECEIVED':
                image = protection ? 'rollProtection' : 'roll';
                break;
            case 'GAMBLE_ANIMATION':
                this.waitingType = waitingType;
                this.protection = protection;
                return;
            case 'IDLE':  // idle response resived
                waitingType = 'IDLE';
                image = this.getProtection(this.choosingResponse) ? 'waitingRollProtection' : 'waitingRoll';
                break;
        }
        let posX = typeof this.choosingResponse.choice !== 'undefined' ?
            this.choosing[this.choosingResponse.choice].x + 55 : this.waitingSettings[waitingType].x;
        posX = this.choosingResponse.step >= this.superBonusStep ?
            this.waitingSettings['SUPER_BONUS_IDLE'].x : posX;
        const posY = this.waitingSettings[waitingType].y;
        const width = this.waitingSettings[waitingType].w;
        const height = this.waitingSettings[waitingType].h;
        const frameCount = this.waitingSettings[waitingType].frameCount;
        const colCount = this.waitingSettings[waitingType].colCount;
        const speed = this.waitingSettings[waitingType].animationSpeed;

        const waiting = new AnimatedSprite(this.getSpriteTextures({
            width, height,
            image, colCount, toFrame: frameCount
        }));

        waiting.name = 'waiting';
        waiting.animationSpeed = 0.001 * speed;
        waiting.position.set(posX, posY);
        waiting.loop = true;
        waiting.visible = true;
        waiting.play();
        parentContainer.addChild(waiting);

        this.waitingType = waitingType;
        this.protection = protection;
    }

    /**
     * Get current Game state
     * @returns {string|*}s
     */
    getWaitingState() {
        let state = this.getState();
        switch (state) {
            case 'CHOOSING_IDLE':
                break;
            case 'DOUBLING':
                break;
            case 'SUPER_BONUS_IDLE':
                break;
            case 'SHOW_WIN_LINES':
                break;
            case 'SCATTER_FEATURE':
                break;
            case 'RESPONSE_RECEIVED':
                break;
            case 'DRAW_GAME':
            case 'GAMBLE_ANIMATION':
            case 'CHOOSING_ANIMATION':
            case 'ANIMATE_MULTIPLIER':
                state = 'GAMBLE_ANIMATION';
                break;
            default:  // idle response resived
                state = 'IDLE';
                break;
        }
        return state;
    }

    getWinPicture = multiplier => multiplier ? 0 : Math.round(1 + Math.random(3));
}

class GambleUnique extends GambleIgrosoft {
    constructor(imageResources) {
        super(imageResources);
        this.cloudx = 150;
        this.cloudy = 350;
        this.riskStepX = 468;
        this.riskStepY = 100;
        this.cardWeigth = 132;
        this.cardHeight = 198;
        this.cardPositions = [
            [60, 140],
            [200, 140],
            [341, 140],
            [481, 140],
            [622, 140]
        ];
        this.dealerY = 203;
    }

    drawGambleTexts = ctx => {
        ctx.fillStyle = '#ffff00';
        ctx.strokeStyle = '#000';
        ctx.lineWidth = 2;
        ctx.textAlign = 'center';
        ctx.font = '28px Arial';
        ctx.strokeText(this.step + 1, 470, 125);
        ctx.fillText(this.step + 1, 470, 125);
    };
}
