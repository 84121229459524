import App from './../../../index';
import GameDeluxe from './../../deluxe/game';
import Lines10 from './../../deluxe/lines10';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';
import bonusFont from './img/font/bonusFont';

export default class ColumbusDeluxe extends GameDeluxe {
    constructor() {
        super();
        this.id = 'columbus-deluxe';
        this.name = 'Columbus Deluxe';
        this.scatter = 9;
        this.scatters = [9, 10, 11];
        this.reelFilter = [[10, 11], [9, 10, 11], [9, 11], [9, 10, 11], [9, 10]];
        this.buttonsPanelShadow = 'strong';

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 150, y: 150},
            bonusInBonusFrame: {x: 25, y: 120},
            endBonusFrame: {x: 25, y: 120}
        };

        this.symbols = [
            {regularDelay: 150, payment: [0, 0, 0, 5, 20, 100]},                 // 0 - 10
            {regularDelay: 150, payment: [0, 0, 0, 5, 20, 100]},                 // 1 - J
            {regularDelay: 150, payment: [0, 0, 0, 10, 40, 150]},                // 2 - Q
            {regularDelay: 150, payment: [0, 0, 0, 10, 40, 150]},                // 3 - K
            {regularDelay: 150, payment: [0, 0, 0, 10, 40, 150]},                // 4 - A
            {regularDelay: 100, payment: [0, 0, 5, 15, 75, 250]},                // 5 - Sextant
            {regularDelay: 100, payment: [0, 0, 5, 25, 100, 500]},               // 6 - Necklace
            {regularDelay: 100, payment: [0, 0, 5, 50, 200, 1000]},              // 7 - Queen
            {regularDelay: 100, payment: [0, 0, 10, 100, 1000, 5000]},           // 8 - Saylor
            {regularDelay: 100, scatterDelay: 120, payment: [0, 0, 0, 0, 0, 0]}, // 9 - Ship (scatter)
            {regularDelay: 100, scatterDelay: 120, payment: [0, 0, 0, 0, 0, 0]}, // 10 - Ship (scatter)
            {regularDelay: 100, scatterDelay: 120, payment: [0, 0, 0, 0, 0, 0]}  // 11 - Ship (scatter)
        ];

        this.imageResources = {
            main: this.mergePath({mainArea: 'area/main.png'}),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                frame: 'bonus/bonus1.png',
                frame2: 'bonus/bonus2.png',
                bonusArea: 'area/bonus.png',
                bonusFont: bonusFont['imageResource']
            }),
            atlas: this.mergePath([
                'bonus/bonusSymbols.json',
                'bonus/bonusSymbols2.json',
                'regularLongSymbols.json'
            ])
        };

        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'bonus-game-end', alias: 'bonusGameEnd'},
                {name: 'bonus-background', loop: true}
            ],
            path: `/game/games/${this.id}/audio/`
        };

        this.Lines = new Lines10();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 4}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.strokeStyle = '#000';
        ctx.fillStyle = '#fff';
        ctx.lineWidth = 2;
        ctx.textAlign = 'center';

        switch (page) {
            case 1:
                ctx.font = '18pt Times New Roman';
                // Columbus
                this.strokeFillText(ctx, bet * this.symbols[8].payment[5], 475, 90);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[4], 475, 118);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[3], 475, 148);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[2], 475, 177);
                // Queen
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], 200, 80);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], 200, 110);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 200, 141);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[2], 200, 170);
                // Necklace
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 740, 80);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 740, 110);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 740, 141);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[2], 740, 170);
                // Sextant
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 190, 251);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 190, 282);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 190, 310);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[2], 190, 339);
                // AKQ
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 470, 262);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 470, 294);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 470, 324);
                // J10
                this.strokeFillText(ctx, bet * this.symbols[1].payment[5], 730, 262);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[4], 730, 294);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[3], 730, 324);
                break;
            case 2:
                ctx.font = '22pt Times New Roman';
                ctx.textAlign = 'right';
                this.strokeFillText(ctx, 'Substitutes for all', 470, 90);
                this.strokeFillText(ctx, 'symbols except', 470, 120);

                this.strokeFillText(ctx, '10 FREE GAMES', 370, 280);
                this.strokeFillText(ctx, 'triggered by:', 370, 310);

                ctx.textAlign = 'left';
                this.strokeFillText(ctx, 'Substitutes for all symbols', 400, 420);
                this.strokeFillText(ctx, 'during Free Games ', 400, 450);

                ctx.textAlign = 'center';
                this.strokeFillText(ctx, '3 scatter symbols retrigger Free Games.', 420, 530);
                break;
            case 4:
                ctx.font = '22pt Times New Roman';
                ctx.textAlign = 'center';
                this.strokeFillText(ctx, 'RULES', 400, 100);
                this.strokeFillText(ctx, 'All prizes are for combinations of a kind. All prizes are', 400, 160);
                this.strokeFillText(ctx, 'for combinations left to right, except scatters. All', 400, 200);
                this.strokeFillText(ctx, 'prizes are on selected lines, except scatters. Scatter', 400, 240);
                this.strokeFillText(ctx, 'symbols  pay at any position on screen. Highest win only', 400, 280);
                this.strokeFillText(ctx, 'paid per selected line. Scatter wins are added to line', 400, 320);
                this.strokeFillText(ctx, 'wins. Free Games are played at trigger bet and lines.', 400, 360);
                this.strokeFillText(ctx, 'All prizes shown in credits. Malfunction voids all pays', 400, 400);
                this.strokeFillText(ctx, 'and plays.', 400, 440);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    /**
     * Drawing  the table of bonus game
     */
    showStartBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y);
        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 1,
            fontInterval: -3 // px between symbols
        };
        this.showBonusFrame(parentContainer, x, y);
        this.drawCustomFont('10 Free Games', 400, 210, textProps);
    }

    /**
     *  Drawing  the table of bonus in bonus game
     */
    showBonusInBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y, 'frame2');
        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 1,
            fontInterval: -3 // px between symbols
        };
        this.drawCustomFont('10 MORE FREE GAMES', 400, 250, textProps);
    }

    /**
     * Drawing  the table of end of bonus in bonus game
     */
    showEndBonusFrame(parentContainer, {x, y}, status) {
        this.showBonusFrame(parentContainer, x, y, 'frame2');
        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 1,
            fontInterval: -3 // px between symbols
        };
        this.drawCustomFont(`FEATURE WIN ${this.bonusStatus.win}`, 400, 170, textProps);
        this.drawCustomFont('CREDITS', 400, 220, textProps);
        this.drawCustomFont(`${this.bonusStatus.total} FREE GAMES`, 400, 290, textProps);
        this.drawCustomFont('PLAYED', 400, 340, textProps);
    }

    /**
     * используем чтобы поменять символы скатеров на соответствующих рилах
     */
    additionalFilter = reelMas => {
        reelMas.forEach((vector, reelIndex) => {
            vector.forEach((symbol, rowIndex) => {
                if (reelIndex === 0 && symbol === this.scatters[0]) {
                    reelMas[reelIndex][rowIndex] = this.scatters[0];
                }
                if (reelIndex === 2 && symbol === this.scatters[0]) {
                    reelMas[reelIndex][rowIndex] = this.scatters[1];
                }
                if (reelIndex === 4 && symbol === this.scatters[0]) {
                    reelMas[reelIndex][rowIndex] = this.scatters[2];
                }
            });
        });
        return reelMas;
    };

    setScatterSprite(scatterFeature) {
        scatterFeature.positions.forEach(position => {
            const {reel, row} = position;
            const symbolObj = this.reelMatrix[reel][row];
            symbolObj.image = this.gameFlag.bonusStart ? 'bonus' : 'regular';
            symbolObj.loop = false;
            this.Roll.updateSymbolSprite(symbolObj);
            symbolObj.sprite.play();
        });
    }

    getSymbolImageType = symbolIndex =>
        this.scatters.includes(symbolIndex) &&
        this.gameFlag.bonusStart || this.getState() === 'IDLE_BONUS' ?
            'bonus' : 'static';

    /**
     * Process reels response from server.
     * @param response - Socket response 'ROLL'
     */
    processReelResponse(response) {
        response.screen = this.additionalFilter(response.screen);
        this.latestResponse = response;
        this.setState('RESPONSE_RECEIVED');
        this.setBonusRollSymbol(); // for bonus game roll symbol
        this.prepareToRollAnimation(response);
    }
}
