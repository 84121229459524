const bonusFont = {
    imgWidth: 2612,
    fontWidthArray: [
        75, 75, 75, 78, 75, 75, 75, 78, 70, 70, 72, 72,
        85, 82, 78, 78, 80, 75, 75, 75, 75, 77, 94, 78, 72, 75,
        72, 72, 72, 72, 71, 62, 72, 70, 60, 60, 70, 60,
        87, 76, 73, 73, 73, 65, 65, 65, 71, 71, 85, 72, 72, 72,
        72, 72, 74, 74, 74, 74, 74, 74, 74, 74, 60, 60, 60, 60, 70, 70
    ],
    fontHeight: 68,
    symbolsOrder: [
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L',
        'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
        'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l',
        'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
        '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', ',', '.', '-', ' ', '=', '+'
    ],
    imageResource: 'font/bonusFont.png',
    letterIndex: 34
};

export default bonusFont;
