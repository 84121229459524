import React from 'react';

export default function SupportRules({lang}) {
    const mobileNumber = '380960585127';
    return (
        <section className='contacts profile-data'>
            <h2>{lang.payRules}</h2>
            <div className='rules'> {lang.payRulesText}
            </div>
            <div className='links'></div>
            <h2>{lang.connectWithUs}</h2>
            <div className='links'>
                <a href='tg://resolve?domain=MaxBetGames777' draggable={false}>
                    <img src={'/img/social/tg.png'} alt='telegram' draggable={false}/>
                </a>
                <a href={`viber://chat?number=%2B${mobileNumber}`} draggable={false}>
                    <img src={'/img/social/viber.png'} alt='viber' draggable={false}/>
                </a>
                <a href={`https://wa.me/+${mobileNumber}`} draggable={false}>
                    <img src={'/img/social/whatsapp.png'} alt='whatsapp' draggable={false}/>
                </a>
            </div>
            <div className='phone-div'>
                <a href={`tel:+${mobileNumber}`} className='phone' draggable={false}>+{`${mobileNumber}`}</a>
            </div>
        </section>
    );
}
