import {JL} from 'jsnlog';

import Lines10 from './../../amatic/lines10';

export default class Lines extends Lines10 {
    constructor(imageResources) {
        super(imageResources);
        this.imageResources = {
            ...this.imageResources,
            ...imageResources // redefine base images from game
        };

        this.linesColor = [
            '#ff0100',
            '#ff0100',
            '#ff0100',
            '#2323d2',
            '#2323d2',
            '#0f5532',
            '#0f5532',
            '#9f2a5f',
            '#9f2a5f',
            '#198c9b'
        ];

        // redefine boxes positions on X axis for left & right side
        Object.keys(this.lines).forEach((key, i) => {
            this.lines[key].boxes.forEach((item, index) => item.x += index === 0 ? 7 : 2);
            this.lines[key].color = this.linesColor[i];
        });
        this.linesSettings.horizontalLineLength = 30;

        this.boxSettings = {
            firstRightBox: 10,
            boxMargin: 0,
            lineBoxWidth: 26,
            lineBoxHeight: 27,
            typeBoxes: 'AllBoxesWithInactive'
        };
        JL().debug('-- Lines10 initialized');
    }
}
