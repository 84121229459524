import React from 'react';
import App from './../../index';

export default function Bonus({bonuses, lang}) {
    const drawAmount = (amount) => {
        const mapping = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.', ',', 'u'];

        amount = amount.toString().split('');
        return amount.map((item, i) => {
            const index = mapping.findIndex(number => item === number);
            const width = 80, height = 100;
            let margin = '';
            if (index === 12) {
                margin = '0px 21px';
            } else if (index === 10 || index === 11) {
                margin = '0 -39px 0 -14px';
            } else {
                margin = '0 -10px';
            }
            return (
                <div key={i} style={{
                    backgroundImage: `url(../../img/registration/numbers.png)`,
                    backgroundPosition: `-${width * index}px 0`,
                    backgroundRepeat: 'no-repeat',
                    width: index !== 12 ? width : 240 + 'px', height: height + 'px',
                    margin: margin
                }}/>
            );
        });
    };
    return (

        <div id='bonus'>
            <div className='bonus-root'>
                {bonuses && bonuses.map(
                    ({type, amount, created, expiration, transfer, status, id}, index) => {
                        type = `${type.toLowerCase()}_bonus`;
                        const statusOfBonus = status.toLowerCase();
                        return <div key={index}>
                            <section className={statusOfBonus}>
                                <div className={`icon icon-${statusOfBonus}`}
                                    onClick={() => App.User.takeBonus(statusOfBonus, id, lang, type)}>
                                    <img src={`./../../img/registration/anim.png`} alt='bonus'/>
                                    <img src={`./../../img/registration/anim.png`} alt='bonus'/>
                                    <img src={`./../../img/registration/${type}.png`} alt='bonus'/>

                                    {statusOfBonus === 'used' && <>
                                        <img style={{transform: 'rotate(-31deg)'}}
                                            src={`./../../img/registration/frame.png`} alt='bonus'/>
                                        <img style={{transform: 'rotate(-31deg)'}}
                                            src={`./../../img/registration/received.png`} alt='bonus'/>
                                    </>
                                    }

                                    {statusOfBonus === 'active' &&
                                    <div className='active-block'>
                                        <img src={`./../../img/registration/frame.png`} alt='bonus'/>
                                        <img src={`./../../img/registration/take.png`} alt='bonus'/>
                                    </div>
                                    }

                                </div>

                                <div className={'text-bonus-' + statusOfBonus}>
                                    <span className='bonus-description'>
                                        {lang[type].title}
                                    </span>
                                    <div style={{
                                        display: 'flex',
                                        transform: 'scale(0.4)',
                                        height: '10px'
                                    }}>
                                        {amount !== 0 && drawAmount(amount + 'u')}
                                    </div>
                                </div>
                            </section>
                            <div className='info'>
                                <button
                                    style={{backgroundImage: `url(${App.getUrl('/img/registration/info.png')})`}}
                                    onClick={() => App.Modal.showInfoBonus(type, lang)}
                                />
                            </div>
                        </div>;
                    }
                )}
            </div>
        </div>

    );
}
