import {JL} from 'jsnlog';
import * as PIXI from 'pixi.js-legacy';

import Lines from './../lines';
import App from './../../index';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Texture = PIXI.Texture,
    Text = PIXI.Text;

export default class Lines9 extends Lines {
    constructor() {
        super();
        this.imageResources = {
            lines: '/img/lines/Lines9/lines.png',
            winLines: '/img/lines/Lines9/winlines.png',
            boxes: '/img/lines/Lines9/boxes.png'
        };
        this.lineImageWidth = 152; // width of line image in sprite, magic number for each game
        this.lineImageHeight = 161; // height of line image in sprite, magic number for each game

        this.lines = {
            0: {
                coordinates: [1, 1, 1, 1, 1],
                boxes: [
                    {x: 0, y: 267},
                    {x: 763, y: 267}
                ],
                path: [
                    {x: 35, y: 202},
                    {x: 187, y: 202},
                    {x: 330, y: 202},
                    {x: 470, y: 202},
                    {x: 611, y: 202}
                ]
            },
            1: {
                coordinates: [0, 0, 0, 0, 0],
                boxes: [
                    {x: 0, y: 138},
                    {x: 763, y: 138}
                ],
                path: [
                    {x: 35, y: 80},
                    {x: 187, y: 80},
                    {x: 328, y: 80},
                    {x: 468, y: 80},
                    {x: 610, y: 80}
                ]
            },
            2: {
                coordinates: [2, 2, 2, 2, 2],
                boxes: [
                    {x: 0, y: 398},
                    {x: 763, y: 398}
                ],
                path: [
                    {x: 35, y: 330},
                    {x: 187, y: 330},
                    {x: 329, y: 330},
                    {x: 470, y: 330},
                    {x: 612, y: 330}
                ]
            },
            3: {
                coordinates: [0, 1, 2, 1, 0],
                boxes: [
                    {x: 0, y: 95},
                    {x: 763, y: 95}
                ],
                path: [
                    {x: 36, y: 76},
                    {x: 186, y: 195},
                    {x: 328, y: 324},
                    {x: 470, y: 195},
                    {x: 611, y: 77}
                ]
            },
            4: {
                coordinates: [2, 1, 0, 1, 2],
                boxes: [
                    {x: 0, y: 440},
                    {x: 763, y: 440}
                ],
                path: [
                    {x: 36, y: 327},
                    {x: 187, y: 202},
                    {x: 328, y: 79},
                    {x: 470, y: 203},
                    {x: 611, y: 331}
                ]
            },
            5: {
                coordinates: [1, 2, 2, 2, 1],
                boxes: [
                    {x: 0, y: 225},
                    {x: 763, y: 225}
                ],
                path: [
                    {x: 36, y: 215},
                    {x: 187, y: 330},
                    {x: 328, y: 330},
                    {x: 470, y: 330},
                    {x: 611, y: 217}
                ]
            },
            6: {
                coordinates: [1, 0, 0, 0, 1],
                boxes: [
                    {x: 0, y: 311},
                    {x: 763, y: 311}
                ],
                path: [
                    {x: 36, y: 190},
                    {x: 187, y: 75},
                    {x: 328, y: 75},
                    {x: 470, y: 75},
                    {x: 611, y: 190}
                ]
            },
            7: {
                coordinates: [2, 2, 1, 0, 0],
                boxes: [
                    {x: 0, y: 355},
                    {x: 763, y: 182}
                ],
                path: [
                    {x: 36, y: 329},
                    {x: 187, y: 329},
                    {x: 328, y: 200},
                    {x: 470, y: 80},
                    {x: 611, y: 80}
                ]
            },
            8: {
                coordinates: [0, 0, 1, 2, 2],
                boxes: [
                    {x: 0, y: 182},
                    {x: 763, y: 355}
                ],
                path: [
                    {x: 36, y: 88},
                    {x: 187, y: 88},
                    {x: 328, y: 208},
                    {x: 470, y: 335},
                    {x: 611, y: 335}
                ]
            }
        };

        JL().debug('-- Lines9 initialized');
    }

    drawLineImages(lines, winReels, parentContainer) {
        lines.forEach(lineIndex => {
            const line = this.lines[lineIndex];

            line.coordinates.forEach((rowIndex, reelIndex) => {
                const image = winReels.includes(reelIndex) ? 'winLines' : 'lines';
                this.lineImageWidth = 152;
                this.lineImageHeight = 161;

                if (reelIndex !== 4 && reelIndex !== 0) this.lineImageWidth = 144;

                const spritePosX = (reelIndex !== 0) ? 40 + 144 * reelIndex : 32;
                const spritePosY = this.lineImageHeight * lineIndex;

                const sprite = new Sprite(new Texture(App.Game.getTexture(image), {
                    x: spritePosX,
                    y: spritePosY,
                    width: this.lineImageWidth,
                    height: this.lineImageHeight
                }));

                sprite.position.set(line.path[reelIndex].x, line.path[reelIndex].y);
                sprite.name = lineIndex;
                parentContainer.addChild(sprite);
            });
        });
    }

    drawBoxes(parentContainer) {
        parentContainer.removeChildren(); // clear all previous boxes

        const currentLines = App.Game.gameSettings.getCurrentLineMas();
        const boxWidth = 37; // width of winline boxes
        const boxHeight = 32; // height of winline boxes

        currentLines.forEach(lineIndex => {
            lineIndex--;
            this.lines[lineIndex].boxes.forEach(box => {
                const spritePosX = box.x ? boxWidth : box.x;  // if box.x==0 this is left box else right
                const spritePosY = boxHeight * lineIndex;

                // create box sprite
                const sprite = new Sprite(new Texture(App.Game.getTexture('boxes'), {
                    x: spritePosX,
                    y: spritePosY,
                    width: boxWidth,
                    height: boxHeight
                }));
                sprite.position.set(box.x, box.y);
                sprite.name = lineIndex;
                this.addBoxEvents(sprite);
                parentContainer.addChild(sprite);

                const text = new Text(+lineIndex + 1, {
                    fontFamily: 'Arial',
                    fontSize: 19,
                    fill: '#000',
                    lineJoin: 'round'
                });
                text.anchor.set(0.5);
                text.position.set(box.x + boxWidth / 2, box.y + boxHeight / 2);
                parentContainer.addChild(text);
            });
        });
    }
}
