const fontNumbers = {
    fontWidthArray: [80.95, 80.95, 80.95, 80.95, 80.95, 80.95, 80.95, 80.95, 80.95, 80.95, 80.95, 80.95, 50],
    fontHeight: 95,
    symbolsOrder: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '=', 'x', ' '],
    red: 'font/red.png',
    lightBlue: 'font/lightBlue.png',
    blue: 'font/blue.png'
};

export default fontNumbers;
