import React from 'react';

import Game from './../Game';
import App from './../../index';

export default class GameNovomatic extends Game {
    constructor() {
        super();
        this.DOMComponents.gambleComponent = buttons => <div className='gamble open'>
            {App.Game.DOMComponents.gambleCanvas}
            <button
                className={`gamble-button gamble-button-red ${buttons.redCard.handler ? '' : 'pressed'}`}
                onClick={buttons.redCard.handler}
                style={{
                    bottom: this.Gamble.gambleButtonsSize.bottom,
                    left: this.Gamble.gambleButtonsSize.left,
                    width: this.Gamble.gambleButtonsSize.width,
                    height: this.Gamble.gambleButtonsSize.height,
                    borderRadius: this.Gamble.gambleButtonsSize.borderRadius,
                    backgroundImage: `url(${App.Game.getAdditionalImage(`${buttons.redCard.img}Red`).src})`
                }}
            />

            <button
                className={`gamble-button gamble-button-black ${buttons.blackCard.handler ? '' : 'pressed'}`}
                onClick={buttons.blackCard.handler}
                style={{
                    bottom: this.Gamble.gambleButtonsSize.bottom,
                    right: this.Gamble.gambleButtonsSize.right,
                    width: this.Gamble.gambleButtonsSize.width,
                    height: this.Gamble.gambleButtonsSize.height,
                    borderRadius: this.Gamble.gambleButtonsSize.borderRadius,
                    backgroundImage: `url(${App.Game.getAdditionalImage(`${buttons.blackCard.img}Black`).src})`
                }}
            />
        </div>;
    }
}
