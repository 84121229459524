import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import CustomLines from './../CustomLines';

/* PIXI aliases */
const Text = PIXI.Text;

export default class Lines17 extends CustomLines {
    constructor() {
        super();
        this.imageResources = {
            boxes: '/img/lines/champion_boxes17.png'
        };

        this.boxSettings = {
            ...this.boxSettings,
            firstRightBox: 9,
            lineBoxWidth: 55,
            lineBoxHeight: 25,
            textOnBox: true,
            textType: 'bet',
            typeBoxes: 'TwoBoxes'
        };

        this.linesSettings = {
            ...this.linesSettings,
            lineThickness: 3,
            borderThickness: 4,
            lineColor: '#b2191a'
        };
        this.winBoxes = false;

        this.lines = {
            0: {coordinates: [1, 1, 1], boxes: [{x: 70, y: 265}]},
            1: {coordinates: [0, 0, 0], boxes: [{x: 70, y: 150}]},
            2: {coordinates: [2, 2, 2], boxes: [{x: 70, y: 380}]},
            3: {coordinates: [0, 1, 2], boxes: [{x: 70, y: 118}]},
            4: {coordinates: [2, 1, 0], boxes: [{x: 70, y: 412}]},
            5: {coordinates: [0, 1, 0], boxes: [{x: 70, y: 182}]},
            6: {coordinates: [2, 1, 2], boxes: [{x: 70, y: 348}]},
            7: {coordinates: [1, 0, 1], boxes: [{x: 70, y: 233}]},
            8: {coordinates: [1, 2, 1], boxes: [{x: 70, y: 297}]},
            9: {coordinates: [2, 1, 1], boxes: [{x: 680, y: 282}]},
            10: {coordinates: [0, 1, 1], boxes: [{x: 680, y: 249}]},
            11: {coordinates: [1, 2, 2], boxes: [{x: 680, y: 365}]},
            12: {coordinates: [1, 0, 0], boxes: [{x: 680, y: 161}]},
            13: {coordinates: [1, 1, 2], boxes: [{x: 680, y: 398}]},
            14: {coordinates: [1, 1, 0], boxes: [{x: 680, y: 129}]},
            15: {coordinates: [2, 2, 1], boxes: [{x: 680, y: 316}]},
            16: {coordinates: [0, 0, 1], boxes: [{x: 680, y: 216}]}
        };

        this.lineMap = [
            3, 1, 5, 7, 0, 8, 6, 2, 4,
            14, 12, 16, 10, 9, 15, 11, 13
        ];

        this.linesColor = {};
        Object.keys(this.lines).forEach(index => this.linesColor[index] = 0xb2191a);

        JL().debug('-- Lines17 initialized');
    }

    addLineWin = (parentContainer, payment, x, y) => {
        const text = new Text(payment, {
            fontFamily: 'Arial',
            fontSize: 12,
            fill: '#ffffff',
            stroke: '#000',
            strokeThickness: 3,
            lineJoin: 'round'
        });
        text.anchor.set(0.5, 0.75);
        text.position.set(x, y);
        parentContainer.addChild(text);
    };
}
