import React from 'react';
import {JL} from 'jsnlog';

import App from './../index';
import countryCode from './../countryCode.js';

class NewUser {
    constructor() {
        this.username = ''; // registered client code

        this.email = null; // registered client email
        this.emailConfirmed = null; // registered client email
        this.phone = null; // registered client email
        this.tCode = null; // registered client email
        this.phoneConfirmed = null; // registered client email

        this.password = null; // 'ONE-CLICK' registered client password
        this.country = ''; // current user country
        this.currency = ''; // current user currency
        this.history = null; // transactions history
        this.payment = {}; // current payments data (currency, type)
    }
}

export default class User {
    constructor() {
        this.current = null;
        this.submittedForm = {}; // form data
        this.payments = {}; // payments data (currency, type)
        this.countries = [];
        this.currencies = [];
        this.uniqueCurrencies = new Set();
    }

    init = async code => {
        this.getAdditionalData();
        code && this.isCode(code) ?
            this.connectCode(code) :
            this.request('login', 'get',
                ({data}) => this.confirmed(data, false),
                () => this.unauthorized());

        this.isNewMessage();
    };

    /**
     * Create class for new user and save data
     */
    create({username, email, phone, tCode, confirmedEmail, confirmedPhone, password, country, currency}) {
        const user = new NewUser();
        user.username = username;
        user.email = email;
        user.emailConfirmed = confirmedEmail;
        user.phone = phone ? phone.number : '';
        user.tCode = phone ? '+' + phone.code : '';
        user.phoneConfirmed = confirmedPhone;
        user.password = password;
        user.country = country;
        user.currency = currency;
        user.cards = [
            {type: 'VISA', code: '4135 1645 4354 9774', name: 'User1', expireDate: '5/22', default: true},
            {type: 'MasterCard', code: '5123 1358 1286 7812', name: 'User1', expireDate: '10/21'}
        ];
        return user;
    }

    /**
     * Login confirmed event, fade screen and init socket
     * Save user data
     * @param data
     * @param savePassword - save in browser PasswordCredential
     */
    confirmed = (data = this.submittedForm, savePassword = true) => {
        const {username, email, phone} = data;

        const number = phone ? phone.number : '';
        const code = phone ? phone.code : '';
        JL().debug(`User authorized (username: ${username}, email: '${email || 'not found'}, phone: '${code + number || 'not found'}')`);

        this.current = this.create(data);
        this.isCode(username) ? App.Cookie.set('code', username) : App.Cookie.remove('code');

        // store data in browser
        const {PasswordCredential} = window;
        if (savePassword && PasswordCredential) {
            const c = new PasswordCredential(this.submittedForm);
            navigator.credentials.store(c).then(() => {
                JL().debug('Credential stored in the user agent\'s credential manager.');
            }, (err) => {
                JL().debug('Error while storing the credential: ' + err);
            });
        }

        const fadeEndCallback = () => {
            App.Socket.init();
            App.updateButton('close', {visible: false});
        };

        // request available payment methods
        this.isRegisteredUser() && ['deposit', 'withdraw'].forEach(method =>
            this.request(`method/${method}`, 'get',
                ({providers}) => {
                    this.payments[method] = providers;
                }
            ));

        App.View.state.loader.active ?
            fadeEndCallback() :
            App.updateState('loader', {active: true, fade: 'in', fadeEndCallback});
    };

    /**
     * Logout confirmed event, fade screen and go to <Login>
     */
    unauthorized = () => {
        JL().debug('Unauthorized user');
        this.current = null;
        App.View.setState({currentState: 'NONE'});
        App.Cookie.remove('code');
        App.stopRestoring = true;
        App.Modal.reset();
        App.resetConnection(() => {
            App.View.setState({currentState: 'LOGIN'});
            App.updateState('loader', {
                active: true, fade: 'out',
                fadeEndCallback: () => App.updateState('loader', {active: false})
            });
        });
    };

    /**
     * Returns current user data
     */
    get = () => this.current ?? {username: 'unregistered'};

    /**
     * Check valid game code
     * @param value
     * @returns {boolean}
     */
    isCode = (value = this.get().username) => {
        value = value.replace(/[-]/gi, '').replace(' ', '');
        return !isNaN(value) && value.length === 12;
    };

    /**
     * Check already registered client
     * Connection type not by code and not demo mode
     * @return {boolean|boolean}
     */
    isRegisteredUser = () => !['demo', 'unregistered'].includes(this.get().username) && !this.isCode();

    /**
     * Returns is empty email for registered user
     * @return {boolean}
     */
    isEmailWarning = () => !this.get().email && this.isRegisteredUser();

    /**
     * Returns is empty email for registered user
     * @return {boolean}
     */
    isMessageWarning = () => {
        return (App.newMessage);
    };

    /**
     * Returns is empty email for registered user
     * @return {boolean}
     */
    getMessageWarning = () => {
        return App.newMessage ? App.newMessage : '';
    };

    /**
     * Returns is empty email or phone for registered user
     * return confirmation for confirmation needed
     * @return {boolean}
     */

    phoneWarning = () => {
        let mes = '';
        if (!this.get().phone) mes = 'warning';
        if (this.get().phone && !this.get().phoneConfirmed) mes = 'confirmation';
        return mes;
    };

    emailWarning = () => {
        let mes = '';
        if (!this.get().email) mes = 'warning';
        if (this.get().email && !this.get().emailConfirmed) mes = 'confirmation';
        return mes;
    };

    /**
     * Bind protocol and server to string
     * @returns {string}
     */
    getUrl = () => {
        const {SERVER_URL, NODE_ENV} = App.configs;
        const protocol = NODE_ENV === 'development' ? 'http' : 'https';
        const url = location.href.match('maxbet.tax') ? 'maxbet.tax' : SERVER_URL;
        return `${protocol}://${url}`;
    };

    /**
     * Create fetch request
     * Handle failed requests, show socket message
     * @param url - backend route
     * @param method - 'get'/'post'
     * @param success - callback on event
     * @param failed - callback on event
     * @returns {Promise<void>}
     */
    request = async(url, method, success, failed) => {
        JL().debug(`Request data (url: ${url})`);
        App.View.setState({serverRequest: true});
        const onSuccess = parsedData => success && success(parsedData);
        const onFailed = parsedData => failed ? failed(parsedData) :
            parsedData.status === 401 && !parsedData.data.code ? // check message status && error code exist
                JL().error(`Unregistered user request (url: ${url})`) :
                App.createPopupMessage(this.getMessageFromCode(parsedData.data.code));

        const requestUrl = `${this.getUrl()}/casino/${url}`;
        try {
            const response = await fetch(requestUrl, {method, credentials: 'include'});
            const parsedData = await response.json();
            this.requestLogger(parsedData) && JL().debug(`Response data: ${JSON.stringify(parsedData)}`);
            parsedData.status === 200 ? onSuccess(parsedData) : onFailed(parsedData);
        } catch (err) {
            JL().debug(`${err} - url(${requestUrl})`);
            //  App.showSocketMessage('serverNotResponded', false, 'disconnect');
            App.updateButton('close', {visible: false});
        }
        App.View.setState({serverRequest: false});
    };

    /**
     * Filter log response
     * @param data
     * @return {boolean} - access to log
     */
    requestLogger = ({data}) =>
        data && !Object.keys(data).find(key =>
            ['countries', 'currencies'].includes(key));

    /**
     * Request password credentials
     * Save file from response (png|txt)
     * @param url
     * @param name
     * @returns {Promise<void>}
     */
    downloadFile = async(url, name) => {
        const requestUrl = `${this.getUrl()}/casino/${url}`;
        try {
            const response = await fetch(requestUrl, {method: 'get', credentials: 'include'});
            const parsedData = await response.blob();
            const urlObj = URL.createObjectURL(parsedData);
            const a = document.createElement('a');
            a.href = urlObj;
            a.download = name;
            // append the element to the dom -> otherwise it will not work in firefox
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (e) {
            App.createPopupMessage(App.language['serverNotResponded']);
        }
    };

    /**
     * Request for register new user
     * Validate data
     * @param event
     * @returns {Promise<void>}
     */
    registration = async event => {
        event.preventDefault(); // prevent form submitting
        App.removePopupMessage();

        // get input data
        const country = event.target[0].value;
        const currency = event.target[1].value;
        const email = event.target[2].value;
        const password = event.target[3].value;
        const confirmPassword = event.target[5].value;

        switch (true) {
            case !this.validateEmail(email):
                App.createPopupMessage(App.language.invalidEmail);
                break;
            case !password:
                App.createPopupMessage(App.language.enterPassword);
                break;
            case !confirmPassword:
                App.createPopupMessage(App.language.confirmPassword);
                break;
            case password.length < 8 || confirmPassword.length < 8:
                App.createPopupMessage(App.language.tooShortPassword);
                break;
            case password.length > 20 || confirmPassword.length > 20:
                App.createPopupMessage(App.language.tooLongPassword);
                break;
            case password !== confirmPassword:
                App.createPopupMessage(App.language.differentPasswords);
                break;
            default:
                this.submittedForm = {id: email, password};
                await this.request(`registration/email?email=${email}&password=${password}&country=${country}&currency=${currency}`, 'post',
                    ({data}) => this.confirmed(data));
                break;
        }
    };

    /**
     * Request for register new user by 'ONE-CLICK'
     * @param event
     * @param callback - after success
     * @return {Promise<void>}
     */
    oneClickRegistration = async(event, callback) => {
        event.preventDefault(); // prevent form submitting

        // get input data
        const country = event.target[0].value;
        const currency = event.target[1].value;

        await this.request(`registration/oneclick?country=${country}&currency=${currency}`, 'post',
            ({data}) => {
                this.current = this.create(data);
                this.submittedForm = {id: data.username, ...data};
                callback?.();
            });
    };

    /**
     * Connect code or login user
     * Validate data
     * @param event
     * @returns {Promise<void>}
     */
    logIn = async event => {
        event.preventDefault(); // prevent form submitting; // prevent form submitting
        App.removePopupMessage();

        // get input data
        const username = event.target[0].value;
        const password = event.target[1].value;

        switch (true) {
            case (!username || !password || password.length < 8) && !this.isCode(username):
                App.createPopupMessage(App.language.incorrectUsername);
                break;
            case !this.validateEmail(username) && !this.isCode(username) && isNaN(+username):
                App.createPopupMessage(App.language.invalidEmail);
                break;
            default:
                this.submittedForm = {id: username, username, password};

                this.isCode(username) ?
                    await this.connectCode(username) :
                    await this.request(`login?username=${username}&password=${password}`, 'post',
                        ({data}) => this.confirmed(data));
                break;
        }
    };

    /**
     * Log out authorized user
     * @returns {Promise<void>}
     */
    logOut = async() =>
        this.request('logout', 'get',
            () => this.unauthorized());

    /**
     * Send email for restore password
     * @param event
     * @returns {Promise<void>}
     */
    restorePassword = async event => {
        event.preventDefault(); // prevent form submitting
        App.removePopupMessage();

        // get input data
        const userInfo = event.target[0].value;

        switch (true) {
            case !userInfo:
                App.createPopupMessage(App.language.passwordResetError);
                break;
            default:
                await this.request(`forgotPassword?userInfo=${userInfo}`, 'post',
                    ({data}) => App.createPopupMessage(this.getMessageFromCode(data.code)));
                break;
        }
    };

    /**
     * Send old and new password for changing
     * Validate params
     * @param event
     * @returns {Promise<void>}
     */
    changePassword = async event => {
        event.preventDefault(); // prevent form submitting
        App.removePopupMessage();

        // get input data
        const oldPassword = event.target[0].value;
        const newPassword = event.target[2].value;
        const confirmNewPassword = event.target[4].value;

        switch (true) {
            case !oldPassword:
                App.createPopupMessage(App.language.enterOldPassword);
                break;
            case !newPassword:
                App.createPopupMessage(App.language.enterNewPassword);
                break;
            case oldPassword.length < 8 || newPassword.length < 8:
                App.createPopupMessage(App.language.tooShortPassword);
                break;
            case oldPassword.length > 20 || newPassword.length > 20:
                App.createPopupMessage(App.language.tooLongPassword);
                break;
            case newPassword !== confirmNewPassword:
                App.createPopupMessage(App.language.differentPasswords);
                break;
            case newPassword === oldPassword:
                App.createPopupMessage(App.language.samePasswords);
                break;
            default:
                await this.request(`user/change-password?oldPassword=${oldPassword}&newPassword=${newPassword}`, 'post',
                    ({data}) => {
                        App.createPopupMessage(this.getMessageFromCode(data.code));
                        App.Modal.remove('changePassword');
                    });
                break;
        }
    };

    /**
     * Send old and new password for changing
     * Validate data
     * @param event
     * @returns {Promise<void>}
     */
    changeEmail = async event => {
        event.preventDefault(); // prevent form submitting
        App.removePopupMessage();

        // get input data
        const currentEmail = this.get().email;
        const confirmedPassword = event.target[1].value;
        const newEmail = event.target[0].value;

        switch (true) {
            case !newEmail:
            case !confirmedPassword:
                App.createPopupMessage(App.language.missedField);
                break;
            case !this.validateEmail(newEmail):
                App.createPopupMessage(App.language.invalidEmail);
                break;
            default:
                await this.request(`email/set?email=${newEmail}&password=${confirmedPassword}`, 'post',
                    ({data}) => {
                        App.createPopupMessage(this.getMessageFromCode(data.code));
                        App.Modal.remove('changeEmail');
                    });
                break;
        }
    };

    /**
     * Send old and new password for changing
     * Validate data
     * @param event
     * @returns {Promise<void>}
     */
    changePhone = async event => {
        event.preventDefault(); // prevent form submitting
        App.removePopupMessage();

        // get input data
        const currentPhone = this.get().phone;
        const confirmedPassword = event.target[2].value;
        let newPhone = event.target[1].value;
        const tCode = event.target[0].value;

        newPhone = newPhone.replace(/[^\d]/g, '');

        switch (true) {
            case !newPhone:
            case !confirmedPassword:
                App.createPopupMessage(App.language.missedField);
                break;
            case !this.validatePhone(newPhone, tCode):
                App.createPopupMessage(App.language.invalidPhone);
                break;
            default:
                await this.request(`phone?phone.code=${tCode}&phone.number=${newPhone}&password=${confirmedPassword}`, 'post',
                    ({data}) => {
                        // App.createPopupMessage(this.getMessageFromCode(data.code));
                        App.Modal.remove('changePhone');
                        App.Modal.confirmPhone();
                    });
                break;
        }
    };

    confirmPhone = async event => {
        event.preventDefault(); // prevent form submitting
        App.removePopupMessage();

        // get input data
        const newCode = event.target[0].value;

        switch (true) {
            default:
                await this.request(`phone?code=${newCode}`, 'put',
                    ({data}) => {
                        this.get().phone = data.phone.number;
                        this.get().tCode = '+' + data.phone.code;
                        App.createPopupMessage(App.language.phoneConfirmed);
                        App.Modal.remove('confirmPhone');
                        App.User.current.phoneConfirmed = true;
                    });
                break;
        }
    };

    /**
     * Request to exchange currency
     * @param event
     * @returns {Promise<void>}
     */
    changeCurrency = async event => {
        event.preventDefault(); // prevent form submitting

        // get input data
        const selectedCurrency = event.target.value;

        event.target.value = this.get().currency;
        await this.request(`currencies/exchange?currency=${selectedCurrency}`, 'get',
            ({data}) => App.Modal.showChangeCurrency(data));
    };

    /**
     * Request to change country
     * @param event
     * @returns {Promise<void>}
     */
    changeCountry = async event => {
        event.preventDefault(); // prevent form submitting

        // get input data
        const selectedCountry = event.target.value;

        event.target.value = this.get().country;
        await this.request(`countries?country=${selectedCountry}`, 'post',
            ({data: {newCountry}}) => {
                const name = this.countries.find(({code}) => code === newCountry).name;
                App.createPopupMessage(`${App.language.successfullyChangedTo} ${name} (${newCountry})`);
                this.get().country = newCountry;
            });
    };

    /**
     * Request to change country
     * @param event
     * @returns {Promise<void>}
     */
    changetCode = async event => {
        event.preventDefault(); // prevent form submitting

        // get input data
        const tCode = event.target.value;
        this.get().tCode = tCode;
    };

    /**
     * Send post request for accept currency exchange
     * @param event
     * @param currency
     * @return {Promise<void>}
     */
    confirmChangeCurrency = async(event, currency) => {
        event.preventDefault(); // prevent form submitting

        await this.request(`currencies/exchange?currency=${currency}`, 'post',
            () => {
                App.createPopupMessage(`${App.language.successfullyChangedTo} ${currency}`);
                App.User.get().currency = currency;
                App.Modal.remove('changeCurrency');
            });
    };

    /**
     * Redirect to payment page
     * @param amount
     * @param cardNumber
     * @param type - 'deposit' / 'withdraw'
     */
    payment = async({amount, cardNumber}, type) => {
        App.removePopupMessage();

        const {currency, method} = this.get().payment;

        const url = `${type}?amount=${amount}&id=${method.id}&code=${currency.code}`;

        switch (true) {
            case +amount <= 0:
                App.createPopupMessage(App.language.wrongInputData);
                break;
            case type === 'withdraw':
                await this.request(`${url}&card=${cardNumber.replace(/\s+/gi, '')}`, 'post',
                    () => App.createPopupMessage(App.language.successfulPayment),
                    ({data}) => App.createPopupMessage(this.getMessageFromCode(data.code)));
                App.Modal.remove('paymentConfirmation');
                break;
            default:
                App.View.setState({serverRequest: true});
                location.href = `${this.getUrl()}/casino/${url}`;
                break;
        }
    };

    /**
     * Request transaction history
     * @param page
     */
    updateHistory = (page = 1) => {
        const updateUserHistory = data => {
            if (page === 1) {
                this.get().history = data;
            } else {
                const oldPayments = this.get().history.payments;
                this.get().history = data;
                this.get().history.payments = [...oldPayments, ...data.payments];
            }
            App.historyPageLimit = data.pagesCount;
            App.updateState('profile', {history: this.get().history});
        };

        this.request(`transaction/history?page=${page}`, 'get',
            ({data}) => updateUserHistory(data),
            () => updateUserHistory({payments: []}));
    };

    /**
     * Request transaction history
     * @param page
     */
    updateMessage = (page = 1) => {
        const updateUserMessage = data => {
            if (page === 1) {
                this.get().message = data;
            } else {
                const oldBonus = this.get().message.items;
                this.get().message = data;
                this.get().message.items = [...oldBonus, ...data.items];
            }
            App.updateState('profile', {message: this.get().message});
        };

        this.request(`messages?page=${page}`, 'get',
            ({data}) => updateUserMessage(data),
            () => updateUserMessage({items: []}));
    };

    /**
     * Request transaction history
     */
    updateBonus = () => {
        const updateUserBonus = data => {
            this.get().bonuses = data;
            App.updateState('profile', {bonuses: this.get().bonuses});
        };

        this.request(`bonuses`, 'get',
            ({data}) => updateUserBonus(data),
            () => updateUserBonus({items: []}));
    };

    /**
     * Request transaction history
     * @param page
     */
    isNewMessage = (page = 1) => {
        const updateUserBonus = data => {
            App.mesPageLimit = data.total / data.limit;
            App.newMessage = data.new ? data.new : null;
        };
        this.request(`messages?page=1`, 'get',
            ({data}) => updateUserBonus(data),
            () => updateUserBonus({messages: []}));
    };

    /**
     * Connect to game code
     * Check code status
     * @param code
     * @returns {Promise<void>}
     */
    connectCode = async code => {
        JL().debug(`Connect code: ${code}`);
        App.View.setState({serverRequest: true});
        App.removePopupMessage();
        const requestUrl = `${this.getUrl()}/backend/status/${code}`;
        const response = await fetch(requestUrl, {credentials: 'include'});
        const {data} = await response.json();

        const reset = () => {
            App.createPopupMessage(App.language[data.code === 'NULL' ? 'codeNotFound' : 'codeAlreadyConnected']);
            !App.View.state.login.active && this.unauthorized();
        };

        const statuses = {
            'NULL': reset,
            'ACTIVE': reset,
            'DISABLE': () => this.confirmed({username: code.replace(/[-]/gi, '')}, false)
        };
        statuses[data.code]();
        App.View.setState({serverRequest: false});
    };

    /**
     * Get language key from message code
     * @param code
     * @returns {*}
     */
    getMessageFromCode = code => {
        const codes = {
            4: 'incorrectUsername',
            5: 'notUniqueName',
            6: 'notUniqueEmail',
            7: 'missedField',
            8: 'wrongOldPassword',
            9: 'passwordResetError',
            10: 'newPasswordSent',
            11: 'new.pass.must.be.different.from.old',
            12: 'passwordChanged',
            13: 'tooLongPassword',
            14: 'tooShortPassword',
            15: 'emailChanged',
            17: 'invalidEmail',
            18: 'wrongPassword',
            19: 'invalidCountry',
            20: 'invalidCurrency',
            21: 'wrongInputData',
            22: 'noRedirectReff',
            23: 'deserializationIssue',
            24: 'currencyPairNotExist',
            25: 'authorizationMandatory',
            26: 'birthdayFormat',
            27: 'phoneChanged',
            28: 'invalidPhone'
        };

        return App.language[codes[code]];
    };

    /**
     * Request countries and currencies
     * Show payment status
     */
    getAdditionalData() {
        this.request('countries', 'get',
            ({data}) => this.countries = data.countries);
        this.request('currencies', 'get',
            ({data}) => this.currencies = data.currencies);

        const {paymentStatus} = App.configs.query;
        paymentStatus && App.Modal.showPaymentStatus(paymentStatus);
    }

    /**
     * Luhn algorithm for checking correct card number
     * @param digits - card number without spaces
     * @return {boolean} - is correct
     */
    luhnAlgorithm(digits) {
        let sum = 0;

        for (let i = 0; i < digits.length; i++) {
            let cardNum = parseInt(digits[i]);

            if ((digits.length - i) % 2 === 0) {
                cardNum = cardNum * 2;

                if (cardNum > 9) {
                    cardNum = cardNum - 9;
                }
            }

            sum += cardNum;
        }

        return sum % 10 === 0;
    }

    /**
     * Check string value
     * @param email
     * @returns {boolean}
     */
    validateEmail = email => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
        return re.test(String(email).toLowerCase());
    };

    /**
     * Check string value
     * @param phone
     * @returns {boolean}
     */
    validatePhone = (phone, tCode) => {
        let length = 10;
        countryCode.forEach((obj) => {
            if (obj.dialCode === tCode) {
                length = obj.numberLength;
            }
        });
        let re = '';
        // const re = new RegExp('^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){' + length + '}(\s*)?$');
        // return re.test(phone);

        switch (length) {
            case 9:
                re = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){9}(\s*)?$/;
                break;
            case 10:
                re = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10}(\s*)?$/;
                break;
            case 11:
                re = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11}(\s*)?$/;
                break;
            case 12:
                re = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){12}(\s*)?$/;
                break;
        }
        return re.test(String(phone).toLowerCase());
    };

    takeBonus = (statusOfBonus, id, lang, typeOfBonus) => {
        if (statusOfBonus === 'active') {
            this.request(`bonuses/${id}`, 'put',
                () => {
                    App.createPopupMessage(lang[typeOfBonus].accept);
                    App.User.updateBonus();
                    App.Socket.send(JSON.stringify({uc: 'BONUS-BALANCE'}));
                },
                ({data}) => App.createPopupMessage(data)
            );
        }
    };
}
