import {JL} from 'jsnlog';

export default function addJsnLogger(beforeSend = () => ({})) {
    const appenders = [];

    const ajaxAppender = JL.createAjaxAppender('ajaxAppender');
    ajaxAppender.setOptions({
        bufferSize: 15,
        storeInBufferLevel: JL.getDebugLevel(),
        level: JL.getWarnLevel(),
        sendWithBufferLevel: JL.getErrorLevel(),
        url: '/api/logger',
        beforeSend
    });
    appenders.push(ajaxAppender);

    const consoleAppender = JL.createConsoleAppender('consoleAppender');
    appenders.push(consoleAppender);

    JL().setOptions({appenders});
    JL.setOptions({'requestId': location.pathname});
};
