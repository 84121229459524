import React from 'react';
import {JL} from 'jsnlog';

import './styles.less';
import App from './../../index';
import Logo from './../logo';
import ProgressBar from './../progressBar';
import ErrorMessage from './../errorMessage';
import ControlButtons from './../controlButtons';
import Devices from './../devices';

export default function Loader(props) {
    const {
        state: {
            lang,
            loader: {fade, fadeEndCallback, messages, progress, gameLogo, activeControlButtons},
            devices, error, buttons, fontsLoaded
        }
    } = props;
    const fadeClass = fade ? `fade-${fade}` : '';

    const onAnimationEnd = () => {
        try {
            fadeEndCallback && fadeEndCallback();
        } catch (e) {
            JL().debug(`Error (Loader): ${e}`);
        }

        // reset callback
        App.updateState('loader', {fadeEndCallback: null});
    };

    const reload = () => location.reload();

    return (
        <div className={`Loader ${fadeClass}`} onAnimationEnd={onAnimationEnd} onTouchStart={App.hideMobileBurger}>
            <div className='content'>
                <div className={`logo-container loaded-${fontsLoaded}`}>
                    <Logo mode={App.configs.subMode} name={App.name}/>
                </div>
                {progress && <ProgressBar progress={progress}/>}
                <div id='loader-log'>
                    {messages.map((message, index) =>
                        <div key={index} className='loader-log-message'>{message}</div>)}
                </div>
                {gameLogo && <img src={gameLogo} className='logo-image' draggable={false} alt=''/>}

                {error.message && <ErrorMessage lang={lang} error={error}/>}
                {error.reloadButton && <button className='reload red-shadow' onClick={reload}>
                    ⟲ {lang.reload}
                </button>}
                {activeControlButtons && <ControlButtons buttons={buttons}/>}
                <Devices list={devices}/>
            </div>
        </div>
    );
}
