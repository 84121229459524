import * as PIXI from 'pixi.js-legacy';

import App from './../../../index.js';
import GameDeluxe from './../../deluxe/game';
import Lines20 from './../../deluxe/lines20';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';
import bonusFont from './img/font/fontBonus';
import bonusFont1 from './img/font/fontBonus1';

/* PIXI aliases */
const AnimatedSprite = PIXI.AnimatedSprite,
    Sprite = PIXI.Sprite,
    Container = PIXI.Container,
    Text = PIXI.Text;

export default class BigChief extends GameDeluxe {
    constructor() {
        super();
        this.id = 'big-chief';
        this.name = 'Big Chief';

        // roll properties
        this.reelSettings = [14, 5, 28]; // 0 - start symbol amount, 1 - reel increase regular roll, 2 - reel increase long roll
        this.scatter = 10;
        this.reelFilter = [[10], [], [], [], [10]];
        this.allowLongRoll = true;
        this.longRollSettings = [0, 3]; // long roll from reelIndex to reelIndex

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 120, y: 90},
            bonusInBonusFrame: {x: 120, y: 90},
            endBonusFrame: {x: 120, y: 90}
        };

        this.symbols = [
            {regularDelay: 250, payment: [0, 0, 0, 10, 20, 100]},                // 0 - 10
            {regularDelay: 250, payment: [0, 0, 0, 10, 20, 100]},                // 1 - J
            {regularDelay: 250, payment: [0, 0, 0, 10, 20, 100]},                // 2 - Q
            {regularDelay: 250, payment: [0, 0, 0, 20, 40, 200]},                // 3 - K
            {regularDelay: 250, payment: [0, 0, 0, 20, 40, 200]},                // 4 - A
            {regularDelay: 100, payment: [0, 0, 0, 25, 80, 400]},                // 5 - canoe
            {regularDelay: 100, payment: [0, 0, 0, 25, 80, 400]},                // 6 - fire
            {regularDelay: 100, payment: [0, 0, 0, 30, 150, 600]},               // 7 - tomahawk
            {regularDelay: 100, payment: [0, 0, 10, 40, 200, 2000]},             // 8 - chief
            {regularDelay: 100, payment: [0, 0, 10, 40, 200, 2000], zIndex: 10}, // 9 - WILD
            {regularDelay: 100, payment: [0, 0, 0, 2, 0, 0]}                     // 10 - eagle
        ];
        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                bonusFont: bonusFont['imageResource'],
                bonusFont1: bonusFont1['imageResource']
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                roulette: 'bonus/roulette.png',
                bonusArea: 'area/bonus.png',
                frame: 'bonus/bonus1.png',
                frame2: 'bonus/bonus2.png',
                background: 'bonus/background.jpg'
            }),
            atlas: this.mergePath([
                'bonus/bonusSymbols.json',
                'bonus/scatterSymbols.json'
            ])
        };
        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'bonus-game-end', alias: 'bonusGameEnd'},
                {name: 'roulette-step', alias: 'roulette-step'},
                {name: 'scatter'},
                {name: 'bonus-background', loop: true},
                {name: 'long1'},
                {name: 'sound_info'}
            ],
            path: `/game/games/${this.id}/audio/`
        };

        this.Lines = new Lines20();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));

        this.InfoScreen = new InfoScreen({pages: 4}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines - current lines count
     * @param bet - current bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '17pt franklinFont';
        ctx.textAlign = 'center';
        ctx.fillStyle = 'white';
        ctx.shadowColor = '#000';
        ctx.shadowOffsetX = 3;
        ctx.shadowOffsetY = 3;
        ctx.shadowBlur = 5;
        let textProps = {};

        let textPropsSmall = {
            parentContainer: ctx,
            fontImageName: 'bonusFont1',
            map: bonusFont1,
            align: 'center',
            scale: 1.2,
            fontInterval: 0
        };

        switch (page) {
            case 1:
                // star
                ctx.fillText(bet * this.symbols[8].payment[5], 200, 43);
                ctx.fillText(bet * this.symbols[8].payment[4], 200, 71);
                ctx.fillText(bet * this.symbols[8].payment[3], 200, 98);
                ctx.fillText(bet * this.symbols[8].payment[2], 200, 125);

                // watermelon grape strabery
                ctx.fillText(bet * this.symbols[7].payment[5], 745, 60);
                ctx.fillText(bet * this.symbols[7].payment[4], 745, 90);
                ctx.fillText(bet * this.symbols[7].payment[3], 745, 120);

                // canoe
                ctx.fillText(bet * this.symbols[5].payment[5], 200, 193);
                ctx.fillText(bet * this.symbols[5].payment[4], 200, 220);
                ctx.fillText(bet * this.symbols[5].payment[3], 200, 247);

                // fireplace
                ctx.fillText(bet * this.symbols[6].payment[5], 745, 193);
                ctx.fillText(bet * this.symbols[6].payment[4], 745, 220);
                ctx.fillText(bet * this.symbols[6].payment[3], 745, 247);

                // A K
                ctx.fillText(bet * this.symbols[3].payment[5], 200, 330);
                ctx.fillText(bet * this.symbols[3].payment[4], 200, 356);
                ctx.fillText(bet * this.symbols[3].payment[3], 200, 381);

                // 10 Q J
                ctx.fillText(bet * this.symbols[0].payment[5], 745, 330);
                ctx.fillText(bet * this.symbols[0].payment[4], 745, 356);
                ctx.fillText(bet * this.symbols[0].payment[3], 745, 381);

                // Eagle
                ctx.fillText(bet * nLines * this.symbols[10].payment[3], 200, 518);

                ctx.font = '18pt bold franklinFont';
                ctx.fillStyle = 'black';
                ctx.shadowColor = 'white';
                // wild
                ctx.fillText(bet * this.symbols[9].payment[5], 410, 180);
                ctx.fillText(bet * this.symbols[9].payment[4], 410, 206);
                ctx.fillText(bet * this.symbols[9].payment[3], 410, 234);
                ctx.fillText(bet * this.symbols[9].payment[2], 410, 260);

                ctx.font = '32pt Bold franklinFont';
                ctx.fillStyle = 'red';
                // wield field
                ctx.fillText(nLines * bet * this.symbols[9].payment[5], 410, 345);

                textProps = {
                    parentContainer: ctx,
                    fontImageName: 'bonusFont',
                    map: bonusFont,
                    align: 'center',
                    scale: 0.68,
                    fontInterval: 0
                };

                this.drawCustomFont('30 FREE GAMES', 450, 480, textProps);
                break;
            case 2:
                textProps = {
                    parentContainer: ctx,
                    fontImageName: 'bonusFont',
                    map: bonusFont,
                    align: 'center',
                    scale: 0.80,
                    fontInterval: 0
                };
                this.drawCustomFont('30 FREE GAMES', 400, 250, textProps);

                this.drawCustomFont('TRIGGER UP TO', 500, 180, textPropsSmall);
                this.drawCustomFont('WITH BONUS', 220, 410, textPropsSmall);
                this.drawCustomFont('SYMBOLS', 560, 410, textPropsSmall);
                this.drawCustomFont('ADDED TO REELS.', 400, 490, textPropsSmall);
                break;

            case 3:
                this.drawCustomFont('SUBSTITUTE FOR ALL', 400, 300, textPropsSmall);
                this.drawCustomFont('SYMBOLS EXCEPT', 400, 340, textPropsSmall);
                break;

            case 4:
                textProps = {
                    parentContainer: ctx,
                    fontImageName: 'bonusFont',
                    map: bonusFont,
                    align: 'center',
                    scale: 0.50,
                    fontInterval: 0
                };
                textPropsSmall = {
                    parentContainer: ctx,
                    fontImageName: 'bonusFont1',
                    map: bonusFont1,
                    align: 'center',
                    scale: 1.5,
                    fontInterval: 0
                };
                this.drawCustomFont('RULES', 400, 130, textPropsSmall);

                ctx.font = '16pt Arial';
                ctx.fillStyle = 'black';
                ctx.shadowColor = 'white';
                ctx.fillText('All prizes are for combinations of a kind. All prizes are for ', 400, 220);
                ctx.fillText('combinations left to right, except scatters. All prizes are on ', 400, 250);
                ctx.fillText('selected lines,except scatters. Scatter symbols pay at any ', 400, 280);
                ctx.fillText('position on screen. Highest win only paid per selected line. ', 400, 310);
                ctx.fillText('Scatter wins are added to line wins.  All prizes shown in credits. ', 400, 340);
                ctx.fillText('Free Games can be won again during the Free Games. Free', 400, 370);
                ctx.fillText('Games are played at trigger bet and lines. Malfunction voids', 400, 400);
                ctx.fillText('all pays and plays.', 400, 430);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    /**
     * Отрисовка таблички бонусной игры
     */
    showStartBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y);
        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 0.65,
            fontInterval: 0
        };
        this.drawCustomFont('DISCOVER YOUR ', 400, 200, textProps);
        this.drawCustomFont('NUMBER OF FREE  ', 400, 260, textProps);
        this.drawCustomFont('GAMES', 400, 320, textProps);
    }

    /**
     * Отрисовка таблички бонусной игры
     */
    showStartBonusFrame2(parentContainer, x, y, win) {
        parentContainer.removeChildren();
        this.buttonsPanelShadow = null;
        this.setBackground('bonusArea');

        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 0.90,
            fontInterval: 0
        };

        const textProps1 = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 0.65,
            fontInterval: 0
        };

        const first = this.isFreeRoll(this.latestResponse.features) && !this.bonusStatus;
        this.showPressAnyButton(false);
        if (first) {
            this.showBonusFrame(parentContainer, x, y, 'frame2');
            this.drawCustomFont(`${win}`, 400, 160, textProps);
            this.drawCustomFont('FREE GAMES WON', 400, 230, textProps1);

            const richText = new Text('+ EXTRA WILD', {
                align: 'center',
                fontFamily: 'Times New Roman',
                fontSize: 26,
                fontWeight: 'bold',
                fill: '#000000',
                stroke: '#FFFFFF',
                strokeThickness: 2,
                lineJoin: 'round'
            });
            richText.x = 300;
            richText.y = 290;
            parentContainer.addChild(richText);
        } else {
            this.showBonusFrame(parentContainer, x, y);
            this.drawCustomFont(`${win}`, 400, 160, textProps);
            this.drawCustomFont('MORE FREE  ', 400, 245, textProps);
            this.drawCustomFont('GAMES', 400, 320, textProps);
        }

        setTimeout(() => {
            App.updateButton('start', {
                disabled: false,
                title: 'start',
                handler: this.gotoBonusRoll
            });
        }, 500);
    }

    /**
     * Отрисовка таблички бонус в бонусе
     */
    showBonusInBonusFrame(parentContainer, xy) {
        this.showStartBonusFrame(parentContainer, xy);
    }

    /**
     * Отрисовка таблички окончания бонусной игры
     */
    showEndBonusFrame(parentContainer, {x, y}, {win, total}) {
        this.showBonusFrame(parentContainer, x, y);
        const textProps1 = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 0.58,
            fontInterval: 0
        };
        this.drawCustomFont(' FEATURE WIN', 400, 170, textProps1);
        this.drawCustomFont(`${win}` + ' CREDITS', 400, 220, textProps1);
        this.drawCustomFont(`${total}` + ' FREE GAMES', 400, 310, textProps1);
        this.drawCustomFont(' PLAYED', 400, 360, textProps1);
    }

    /**
     * Prepare game behaviour after bonus 'press any button' message
     */
    startBonusAnimation = parentContainer => {
        const boxes = this.getStageChild('boxesContainer');
        boxes.visible = false;

        parentContainer.removeChildren();
        this.buttonsPanelShadow = 'none';
        this.createRouletteSprite(parentContainer);
        this.drawRouletteText(parentContainer);

        this.gameFlag.bonusStarted = true;
        this.clearPressAnyButton();
        this.Buttons.disableAllButtons();
        App.updateButton('start', {disabled: true});
        const freeGamesAmount = this.getFreeRollAmount(this.latestResponse.features);
        const rouletteStep = this.getRoulletePos(freeGamesAmount);
        this.drawBonusAnimation(parentContainer, Date.now(), -1, rouletteStep, freeGamesAmount, 300);
    };

    /**
     * Draw all bonus animation after bonus 'press any button' message till next reel roll
     * sprite bookAnim (book_anim.png):
     *      0-23  book animation
     *      24-32 symbols
     */
    drawBonusAnimation(parentContainer, animationStarted, step, rouletteStep, freeGamesAmmount, timeOut) {
        const currentTime = Date.now();
        const timeDiff = (Date.now() - animationStarted);

        const rotation = 36;
        const fullRouletteStep = rotation + rouletteStep;
        const fullRouletteStepBlinking = fullRouletteStep + 10;

        const sin = 1 - Math.abs(Math.sin(((3.1415 / fullRouletteStep) * step) + 3.1415));
        timeOut = 10 + Math.round(sin * 200);

        if (timeDiff > timeOut) {
            step++;
            animationStarted = currentTime;
        } else {
            requestAnimationFrame(this.drawBonusAnimation.bind(this, parentContainer, animationStarted, step, rouletteStep, freeGamesAmmount, timeOut));
            return;
        }

        if (step > fullRouletteStep) {
            timeOut = 200;
        }

        // анимация прокрута рулетки
        if (step < rotation) {
            App.Sounds.playSound('roulette-step');
            const roulette = parentContainer.getChildByName('roulette');
            roulette.gotoAndStop(step);
        }

        if (step >= rotation && step <= (fullRouletteStep)) {
            App.Sounds.playSound('roulette-step');
            const roulette = parentContainer.getChildByName('roulette');
            roulette.gotoAndStop(step);
        }
        if (step === fullRouletteStep) {
            App.Sounds.playSound('sound_info');
        }

        // анимация моргания количества игр
        if (step > (rotation + rouletteStep) && step < (fullRouletteStep + 20)) {
            parentContainer.getChildByName('roulette' + rouletteStep).visible = !(step % 2);
        }
        // отображение окна с выигрышем
        if (step >= fullRouletteStepBlinking) {
            const boxes = this.getStageChild('boxesContainer');
            boxes.visible = true;
            this.showStartBonusFrame2(parentContainer, this.coordinatesBonusFrame.startBonusFrame.x, this.coordinatesBonusFrame.startBonusFrame.y, freeGamesAmmount);
            return;
        }
        requestAnimationFrame(this.drawBonusAnimation.bind(this, parentContainer, animationStarted, step, rouletteStep, freeGamesAmmount, timeOut));
    }

    gotoBonusRoll = () => {
        this.setState('IDLE_BONUS');
        const container = this.getStageChild('bonusContainer');
        container.removeChildren();

        App.Sounds.playSound('bonus-background');
        this.gameFlag.bonusStarted = true;
        this.clearPressAnyButton();
        this.Buttons.disableAllButtons();
        App.updateButton('start', {disabled: true});
        this.startBonusRoll();
    };

    /**
     * Отрисовка открытия книжки
     */
    getFreeRollAmount(features) {
        let count = 0;
        features.forEach(el => {
            if (el.uc === 'FREE_ROLL') {
                count = el.amount;
            }
        });
        return count;
    }

    /**
     * Отрисовка открытия книжки
     * @param parentContainer
     * @param symbol
     */
    drawRouletteText(parentContainer, symbol) {
        const circleCenter = {x: 394, y: 280};
        const angle = 30;
        const r = 180;
        const bets = [15, 10, 7, 15, 7, 10, 15, 10, 7, 30, 7, 10];

        bets.forEach((bet, betIndex) => {
            const x = Math.round(circleCenter.x + r * Math.cos(angle * betIndex / 180 * Math.PI));
            const y = Math.round(circleCenter.y + r * Math.sin(angle * betIndex / 180 * Math.PI));
            const container = new Container();
            container.name = 'roulette' + betIndex;
            parentContainer.addChild(container);

            const textProps = {
                parentContainer: container,
                fontImageName: 'bonusFont',
                map: bonusFont,
                align: 'center',
                scale: 0.65,
                fontInterval: 0
            };

            this.drawCustomFont(bet, x, y, textProps);
        });
    };

    /**
     * Long roll decider return reel from with long roll started
     *  -1 no long roll
     *  0 1 2 3 4 5 number of reel
     */
    getLongRoll(screen) {
        const scatterMap = [];
        const extra = this.getExtraBet();
        screen.forEach((vector, reelIndex) => {
            vector.forEach(symbol => {
                if (symbol === this.scatter && reelIndex !== this.reels - 1 - extra && (reelIndex === 1 || reelIndex === 2)) { // do not use last reel scatter
                    scatterMap.push(reelIndex); // correct last reel with extrabet
                }
            });
        });
        const longRollType = (scatterMap.length > 1 && this.allowLongRoll) ? scatterMap[1] + 1 : -1; // get possible long roll type

        this.reelSymbol.forEach((symbolAmount, reelIndex) => {
            this.reelLong[reelIndex] =
                (reelIndex >= longRollType && longRollType !== -1 &&
                    reelIndex >= this.longRollSettings[0] && reelIndex <= this.longRollSettings[1]) ?
                    1 : 0;
        });
        return this.reelLong; // get possible long roll type
    }

    createRouletteSprite(parentContainer) {
        const background = new Sprite(this.getTexture('background'));
        background.position.set(-133, 0);
        background.name = 'background';
        parentContainer.addChild(background);

        const roulette = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 12, image: 'roulette',
            width: 475, height: 485,
            colCount: 6
        }));
        roulette.name = 'roulette';
        roulette.animationSpeed = 0.5;
        roulette.position.set(162, 70);
        parentContainer.addChild(roulette);
    }

    getRoulletePos = count => {
        const countMas = [15, 10, 7, 15, 7, 10, 15, 10, 7, 30, 7, 10];
        let random = Math.floor(Math.random() * 12);
        while (count !== countMas[random]) {
            random = Math.floor(Math.random() * 12);
        }

        return random;
    };

    drawBonusFrame(first, last, parentContainer, coordinates) {
        App.Sounds.stopSound('bonus-background');

        const {startBonusFrame, bonusInBonusFrame, endBonusFrame} = coordinates;
        if (first) {
            App.Sounds.playSound('bookFlash');
            this.setBackground('bonusArea');
            this.showStartBonusFrame(parentContainer, startBonusFrame);
            App.updateButton('start', {
                disabled: false,
                title: 'start',
                handler: () => this.startBonusAnimation(parentContainer)
            });
        }
        if (last) {
            parentContainer.removeChildren();
            this.setRegularSprite();
            App.updateButton('start', {disabled: true});
            this.setBackground('mainArea');
            this.showEndBonusFrame(parentContainer, endBonusFrame, this.bonusStatus);
            this.playEndBonusGameSound();
            setTimeout(() => this.endBonus(), 5000);
        }
        if (!first && !last) {
            this.showBonusInBonusFrame(parentContainer, bonusInBonusFrame);
            App.updateButton('start', {
                disabled: false,
                title: 'start',
                handler: () => this.startBonusAnimation(parentContainer)
            });
        }
    }
}
