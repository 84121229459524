import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import GameDeluxe5x4 from './../../deluxe/gameDeluxe5x4';
import Lines40 from './../../deluxe/lines40';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';
import bonusFont from './img/font/fontBonus';

/* PIXI aliases */
const Texture = PIXI.Texture,
    AnimatedSprite = PIXI.AnimatedSprite;

export default class BuffaloThunder extends GameDeluxe5x4 {
    constructor() {
        super();
        this.id = 'buffalo-thunder';
        this.name = 'Buffalo Thunder';
        this.transparentBackground = true;
        this.symbolEffects = true;
        this.reelTop = 88;
        this.scatter = 10;
        this.buttonsPanelShadow = 'low';

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 100, y: 120},
            bonusInBonusFrame: {x: 100, y: 120},
            endBonusFrame: {x: 100, y: 120}
        };

        this.symbols = [
            {regularDelay: 100, bonusDelay: 350, payment: [0, 0, 0, 4, 20, 100]},              // 0 - символ 9
            {regularDelay: 100, bonusDelay: 350, payment: [0, 0, 0, 4, 20, 100]},              // 1 - символ 10
            {regularDelay: 100, bonusDelay: 350, payment: [0, 0, 0, 4, 20, 100]},              // 2 - символ J
            {regularDelay: 100, bonusDelay: 350, payment: [0, 0, 0, 4, 20, 100]},              // 3 - символ Q
            {regularDelay: 100, bonusDelay: 350, payment: [0, 0, 0, 8, 40, 160]},              // 4 - символ K
            {regularDelay: 100, bonusDelay: 350, payment: [0, 0, 0, 8, 40, 160]},              // 5 - символ A
            {regularDelay: 100, bonusDelay: 150, payment: [0, 0, 0, 20, 60, 240]},             // 6 - ящерица на камне
            {regularDelay: 100, bonusDelay: 150, payment: [0, 0, 0, 20, 60, 240]},             // 7 - птица на камне
            {regularDelay: 100, bonusDelay: 150, payment: [0, 0, 0, 20, 80, 400], offsetY: 3}, // 8 - бычек белый
            {regularDelay: 100, bonusDelay: 150, payment: [0, 0, 0, 20, 80, 400], offsetY: 5}, // 9 - бычек коричневый
            {regularDelay: 100, bonusDelay: 150, payment: [0, 0, 0, 4, 0, 0], offsetY: 6},     // 10 - бычек маска
            {regularDelay: 100, bonusDelay: 150, payment: [0, 0, 0, 40, 200, 1000]}            // 11 - символ Buffalo Thunder
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                bonusAnimation1: 'bonus/bonusAnimation1.png',
                bonusAnimation2: 'bonus/bonusAnimation2.png',
                frame: 'bonus/frame.png',
                bonusFont: bonusFont['imageResource']
            }),
            atlas: this.mergePath([
                'regularLongSymbols.json',
                'regularLongSymbols2.json',
                'bonus/scatterSymbol.json'
            ])
        };

        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'bonus-game-end', alias: 'bonusGameEnd'},
                {name: 'bonus-background', loop: true},
                {name: 'reelsstop'},
                {name: 'animation'}
            ],
            path: `/game/games/${this.id}/audio/`
        };

        this.Gamble = new GambleUnique(this.mergePath({
            activeRed: 'gamble/red-active.png',
            inactiveRed: 'gamble/red-inactive.png',
            activeBlack: 'gamble/black-active.png',
            inactiveBlack: 'gamble/black-inactive.png',
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));

        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
        this.Lines = new Lines40();
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        switch (page) {
            case 1:
                ctx.font = 'bold 12pt Arial';
                ctx.fillStyle = '#fff';
                ctx.textAlign = 'center';
                ctx.strokeStyle = '#000';
                ctx.shadowColor = 'black';
                ctx.shadowOffsetX = 1;
                ctx.shadowOffsetY = 1;

                // бычек маска
                this.strokeFillText(ctx, bet * nLines * this.symbols[11].payment[5], 222, 120);
                this.strokeFillText(ctx, bet * nLines * this.symbols[11].payment[4], 222, 145);
                this.strokeFillText(ctx, bet * nLines * this.symbols[11].payment[3], 222, 170);
                // бычек коричневый, белый
                this.strokeFillText(ctx, bet * nLines * this.symbols[10].payment[3], 375, 372);

                this.strokeFillText(ctx, bet * this.symbols[9].payment[5], 200, 262);
                this.strokeFillText(ctx, bet * this.symbols[9].payment[4], 200, 287);
                this.strokeFillText(ctx, bet * this.symbols[9].payment[3], 200, 312);
                // птица, ящерица на камне
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], 584, 262);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], 584, 287);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 584, 312);

                // символ A K
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 108, 511);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 108, 536);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 108, 561);
                // символ Q J
                this.strokeFillText(ctx, bet * this.symbols[3].payment[5], 410, 511);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[4], 410, 536);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[3], 410, 561);
                // символ 9 10
                this.strokeFillText(ctx, bet * this.symbols[1].payment[5], 676, 511);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[4], 676, 536);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[3], 676, 561);

                ctx.font = '35pt Arial';
                ctx.textAlign = 'center';
                ctx.fillStyle = '#FFFFFF';
                ctx.lineWidth = 4;

                this.strokeFillText(ctx, bet * nLines * this.symbols[11].payment[5], 670, 155);
                break;
            case 2:
                ctx.textAlign = 'center';
                ctx.font = 'bold 15pt Arial';
                ctx.fillStyle = '#f7f200';
                ctx.fillText('SUBSTITUTES FOR', 325, 236);

                ctx.font = 'italic bold 16px Arial';
                ctx.fillStyle = '#f7f200';
                ctx.fillText('Scatter symbols on the screen trigger 5', 405, 425);
                ctx.fillText('Free Games', 300, 445);
                ctx.fillText('Free Games can be won again during the Free Games.', 425, 480);
                break;
            case 3:
                ctx.font = 'italic bold 16px Arial';
                ctx.fillStyle = '#f7f200';
                ctx.textAlign = 'center';

                ctx.fillText('RULES', 400, 240);
                ctx.fillText('All prizes are for combinations of a kind. All prizes are for', 400, 260);
                ctx.fillText('combination left to right, except scatters. All prizes are on selected', 400, 280);
                ctx.fillText('lines, except scatters. Scatter symbols pay at any position on screen ', 400, 300);
                ctx.fillText('Highest win only paid per selected line. Scatter wins are added to line', 400, 320);
                ctx.fillText('wins. Free Games are played at trigger bet and lines. All prizes shown in', 400, 340);
                ctx.fillText('credits. Malfunction voids all pays and plays', 400, 360);
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    /**
     * Show bonus message with bonus symbol or with bonus game win.
     * @param isFirstBonus {boolean} TRUE if this is the message for starting bonus game.
     */
    drawBonusAskButton(isFirstBonus = false) {
        const isLast = !isFirstBonus && this.bonusStatus && this.bonusStatus.remain === 0;

        this.stopAnimateFeature();
        setTimeout(() => {
            this.drawBonusFrame(isFirstBonus, isLast, this.getStageChild('bonusContainer'), this.coordinatesBonusFrame);
        }, 1000);
    }

    drawBonusFrame(first, last, parentContainer, coordinates) {
        const {startBonusFrame, bonusInBonusFrame, endBonusFrame} = coordinates;
        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 1.3,
            fontInterval: -3 // px between symbols
        };

        App.Sounds.stopSound('bonus-background');
        if (first) {
            App.Sounds.playSound('animation');
            this.drawBonusAnimation(parentContainer, startBonusFrame, textProps);
        }

        if (last) {
            this.setRegularSprite();
            App.updateButton('start', {disabled: true});
            this.showEndBonusFrame(parentContainer, endBonusFrame, textProps);
            this.playEndBonusGameSound();
            setTimeout(() => this.endBonus(), 3000);
        }

        if (!first && !last) {
            this.showBonusInBonusFrame(parentContainer, bonusInBonusFrame, textProps);
        }
    }

    /**
     * drawBonusAnimation
     */
    drawBonusAnimation(parentContainer, {x, y}, textProps) {
        const textures = [...Array(27)].map((item, index) => {
            const width = 590, height = 344;
            const imageNumber = index <= 14 ? 1 : 2;
            const colIndex = index % 3;
            const rowIndex = Math.floor(index / 3) % 5;
            return new Texture(this.getTexture(`bonusAnimation${imageNumber}`), {
                x: colIndex * width,
                y: rowIndex * height,
                width: width,
                height: height
            });
        });

        const sprite = new AnimatedSprite(textures);
        sprite.name = 'bookAnim';
        sprite.animationSpeed = 0.2;
        sprite.loop = false;
        sprite.position.set(x, y);
        parentContainer.addChild(sprite);
        sprite.play();
        sprite.onComplete = () => this.showStartBonusFrame(parentContainer, {x, y}, textProps);
    }

    /**
     * Drawing  the table of bonus game
     * @param parentContainer
     * @param x
     * @param y
     * @param textProps
     */
    showStartBonusFrame(parentContainer, {x, y}, textProps) {
        parentContainer.removeChildren();
        this.showPressAnyButton(false);

        this.showBonusFrame(parentContainer, x, y);
        this.drawCustomFont('5 FREE GAMES', 400, 240, textProps);
        this.drawCustomFont('WON', 400, 300, textProps);
        this.enableStartButton(parentContainer);
    }

    /**
     *  Drawing  the table of bonus in bonus game
     */
    showBonusInBonusFrame(parentContainer, {x, y}, textProps) {
        this.enableStartButton(parentContainer);
        this.showBonusFrame(parentContainer, x, y);
        textProps.scale = 0.9;
        this.drawCustomFont('5  MORE FREE GAMES', 400, 240, textProps);
        this.drawCustomFont('WON', 400, 300, textProps);
    }

    /**
     * Drawing  the table of end of bonus in bonus game
     */
    showEndBonusFrame(parentContainer, {x, y}, textProps) {
        this.showBonusFrame(parentContainer, x, y);
        this.drawCustomFont('FEATURE WIN', 400, 240, textProps);
        this.drawCustomFont(`${this.bonusStatus.win} CREDITS`, 400, 300, textProps);
        this.playEndBonusGameSound();
    }

    enableStartButton(parentContainer) {
        App.updateButton('start', {
            disabled: false,
            title: 'start',
            handler: () => this.startBonusAnimation(parentContainer)
        });
    }

    restoreBonusGame() {
        this.bonusWin = this.bonusStatus.win - this.latestResponse.payment;
        // Fill WIN data
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});
        this.Legends.showWinFeatures();
        this.setBonusStatusText();

        this.Buttons.disableAllButtons();
        this.gameFlag.bonusStart = true;
        this.gameFlag.bonusStarted = true;

        this.showAdditionalBonusImage(this.getStageChild('bonusContainer'));
        this.processReelResponse(this.latestResponse);
    }
}

class GambleUnique extends GambleDeluxe {
    constructor(imageResources) {
        super(imageResources);
        this.cardPos = {x: 335, y: 180};
        this.cardsQueuePos = {x: [378, 440, 502, 564, 625, 688], y: 99};
    }
}
