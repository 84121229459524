export default {
    en: {
        rulesText: 'All prizes are for combinations left to right and right to left. ' +
            'All prizes are for combinations of a kind. All prizes are on selected lines. Highest ' +
            'prize only paid per pay direction. 5 of a kind prizes paid only once. All prizes shown in credits. ' +
            'Malfunction voids all pays and plays.',
        substitutes: 'SUBSTITUTES for ALL symbols on All positions of the reel',
        holds: 'HOLDS the reel and triggers RESPIN of others reels. Appears on reels 2,3 and 4 only.'
    },
    uk: {
        rulesText: 'Усі призи зараховуються за комбінації зліва направо і справа наліво. Виграш враховує усі виграшні комбінації. ' +
            'Виграші зараховуються за комбінації на обраних лініях. Комбінація з 5 символів виплачується тільки один раз. ' +
            'Всі виграші показуються в кредитах. Несправність анулює всі виплати.',
        substitutes: 'ЗАМІНЯЄ ВСІ символи на ВСІХ позиціях на барабані',
        holds: 'ЗАТРИМУЄ барабан і викликає РЕСПІН інших барабанів. Зявляється тільки на 2,3 і 4 барабані.'

    },
    ru: {
        rulesText: 'Все призы зачисляются по комбинации слева направо и справа налево. Выигрыш учитывает все выигрышные комбинации. ' +
            'Выигрыши засчитываются за комбинации на выбранных линиях. Комбинация из 5 символов выплачивается только один раз. ' +
            'Все выигрыши показываются в кредитах. Неисправность аннулирует все выплаты.',
        substitutes: 'ЗАМЕНЯЕТ ВСЕ символы на ВСЕХ позициях на барабане',
        holds: 'ЗАДЕРЖИВАЕТ барабан и вызывает РЕСПИН других барабанов. Появляется только на 2, 3 и 4 барабане.'
    },
    de: {
        rulesText: 'Alle Preise werden durch Kombination von links nach rechts und von rechts nach links gutgeschrieben. ' +
            'Das Gewinnen berücksichtigt alle Gewinnkombinationen. ' +
            'Gewinne zählen für Kombinationen auf ausgewählten Linien. ' +
            'Eine Kombination von 5 Zeichen wird nur einmal bezahlt. Alle Gewinne werden in Krediten angezeigt. ' +
            'Fehlfunktion macht alle Spiele und Zahlungen ungültig.',
        substitutes: 'ERSATZTEILE für ALLE Symbole auf allen Positionen der Rolle',
        holds: 'Stoppt die Trommel und ruft Respin andere Trommeln auf. Erscheint nur auf den Rollen 2, 3 und 4.'

    },
    es: {
        rulesText: 'Todos los premios se acreditan mediante una combinación de izquierda a derecha y de derecha a izquierda. ' +
            'Ganar tiene en cuenta todas las combinaciones ganadoras. ' +
            'Las ganancias cuentan para combinaciones en líneas seleccionadas. Una combinación de 5 caracteres se paga solo una vez. ' +
            'Todas las ganancias se muestran en los créditos. Un error invalidará toda la victoria.',
        substitutes: 'PIEZAS DE REPUESTO para TODOS los símbolos en todas las posiciones del carrete',
        holds: 'Detiene el tambor y llama a Respin a otros tambores. Aparece solo en los carretes 2, 3 y 4.'
    },
    fr: {
        rulesText: 'Tous les prix sont crédités par une combinaison de gauche à droite et de droite à gauche. ' +
            'La victoire prend en compte toutes les combinaisons gagnantes. ' +
            'Les gains comptent pour les combinaisons sur les lignes sélectionnées. ' +
            'Une combinaison de 5 caractères est payée une seule fois. Tous les gains sont indiqués en crédits. ' +
            'Un dysfonctionnement invalidera tous les jeux et paiements.',
        substitutes: 'PIÈCES DE RECHANGE pour TOUS les symboles dans toutes les positions de la bobine',
        holds: 'Arrête le tambour et appelle Respin d\'autres tambours. Apparaît uniquement sur les rouleaux 2, 3 et 4.'
    },
    el: {
        rulesText: 'Όλα τα βραβεία είναι για συνδυασμούς από αριστερά προς τα δεξιά και από δεξιά προς τα αριστερά. ' +
            'Όλα τα βραβεία είναι για συνδυασμούς. Όλα τα βραβεία είναι σε επιλεγμένες γραμμές. Υψηλότερο ' +
            'βραβείο που καταβάλλεται μόνο ανά κατεύθυνση πληρωμής. 5 του είδους βραβεία που καταβάλλονται μόνο μία φορά. Όλα τα βραβεία που εμφανίζονται σε πιστώσεις. ' +
            'Η δυσλειτουργία ακυρώνει όλες τις πληρωμές και τα παιχνίδια.',
        substitutes: 'ΥΠΟΚΑΤΆΣΤΑΤΑ ΓΙΑ ΟΛΑ τα σύμβολα σε όλες τις θέσεις του κυλίνδρου',
        holds: 'ΚΡΑΤΕΙ τον κύλινδρο και ενεργοποιεί το ΑΠΑΝΤΗΣΗ άλλων κυλίνδρων. Εμφανίζεται μόνο στους κυλίνδρους 2,3 και 4.'
    },
    it: {
        rulesText: 'Tutti i premi sono per combinazioni da sinistra a destra e da destra a sinistra. ' +
            'Tutti i premi sono per combinazioni di un tipo. Tutti i premi sono su linee selezionate. Massimo ' +
            'premio pagato solo per direzione di pagamento. 5 premi del genere pagati una sola volta. Tutti i premi indicati in crediti. ' +
            'Il malfunzionamento annulla tutti i pagamenti e le giocate.',
        substitutes: 'SOSTITUTI per TUTTI i simboli su Tutte le posizioni della bobina',
        holds: 'TIENE la bobina e fa scattare la risposta di altre bobine. Appare solo sui rulli 2,3 e 4.'
    }
};
