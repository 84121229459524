/**
 * Check userAgent on mobile devices
 * @returns {string} - platform (desktop|mobile)
 */
export default function getPlatform() {
    if (navigator.userAgent) {
        return navigator.userAgent.match(
            /Android|BlackBerry|BB10|iPhone|iPad|iPod|IEMobile|Opera Mini/i
        ) ? 'mobile' : 'desktop';
    } else {
        return 'desktop';
    }
}
