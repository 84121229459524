import Lines from './../lines';

export default class WaysLines extends Lines {
    // There are empty functions here because ways games don`t need line There are empty function here because ways games don`t need line drawning
    createBoxesContainer(parentContainer) {
    }

    createLinesContainer = parentContainer => {
    };

    drawLineImages(lines, winReels, parentContainer) {
    }

    drawBoxes(parentContainer) {
    }

    addBoxEvents = sprite => {
    };

    updateBoxesState = () => {
    };

    addLineWin = () => {

    };
}
