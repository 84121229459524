export default {
    en: {
        avalancheMultipliers: 'Refill multipliers',
        infoRules: 'The win multiplier increases with each Spin.',
        avalanche: 'Step',
        multiplier: 'Multiplier',
        freeFallsMultiplier: 'Free Refills in Bonus Multiplier',
        firstAvalanche: 'Spin',
        secondAvalanche: '1st refill',
        thirdAvalanche: '2nd refill',
        fourthAvalanche: '3 or more refills'
    },
    uk: {
        avalancheMultipliers: 'Докрут множників',
        infoRules: 'Множник виграшів збільшується з кожним докрутом.',
        avalanche: 'Крок',
        multiplier: 'Множник',
        freeFallsMultiplier: 'Множник безкоштовних докрутів в бонусі',
        firstAvalanche: 'Прокрут',
        secondAvalanche: '1-ий докрут',
        thirdAvalanche: '2-ий докрут',
        fourthAvalanche: '3 або більше докрутів'
    },
    ru: {
        avalancheMultipliers: 'Докрут множителей',
        infoRules: 'Множитель выигрышей увеличивается с каждым докрутом.',
        avalanche: 'Шаг',
        multiplier: 'Множитель',
        freeFallsMultiplier: 'Множитель бесплатных докрутов в бонусе',
        firstAvalanche: 'Прокрут',
        secondAvalanche: '1-ий докрут',
        thirdAvalanche: '2-ой докрут',
        fourthAvalanche: '3 или больше докрутов'
    },
    de: {
        avalancheMultipliers: 'Multiplikatoren nachfüllen',
        infoRules: 'Der Gewinnmultiplikator erhöht sich mit jeder Spin.',
        avalanche: 'Schritt',
        multiplier: 'Multiplikator',
        freeFallsMultiplier: 'Kostenlose Nachfüllungen im Bonus-Multiplikator',
        firstAvalanche: 'Spin',
        secondAvalanche: 'Erste Nachfüllung',
        thirdAvalanche: 'Zweite Nachfüllung',
        fourthAvalanche: 'Dritte oder mehr Nachfüllungen'
    },
    es: {
        avalancheMultipliers: 'Recarga de multiplicadores',
        infoRules: 'El multiplicador de ganancias aumenta con cada Girar.',
        avalanche: 'Paso',
        multiplier: 'Multiplicador',
        freeFallsMultiplier: 'Recargas gratis en el multiplicador de bonificación',
        firstAvalanche: 'Girar',
        secondAvalanche: 'Primera recarga',
        thirdAvalanche: 'Segunda recarga',
        fourthAvalanche: 'Tercera o más recargas'
    },
    fr: {
        avalancheMultipliers: 'Multiplicateurs de recharge',
        infoRules: 'Le multiplicateur de gains augmente à chaque Tourner.',
        avalanche: 'Étape',
        multiplier: 'Multiplicateur',
        freeFallsMultiplier: 'Recharges gratuites dans Bonus Multiplier',
        firstAvalanche: 'Tourner',
        secondAvalanche: 'Première recharge',
        thirdAvalanche: 'Seconde recharge',
        fourthAvalanche: 'Troisième recharges ou plus'
    },
    el: {
        avalancheMultipliers: 'Επαναπλήρωση πολλαπλασιαστών',
        infoRules: 'Ο πολλαπλασιαστής νίκης αυξάνεται με κάθε Γνέθω.',
        avalanche: 'Βήμα',
        multiplier: 'πολλαπλασιαστής',
        freeFallsMultiplier: 'Δωρεάν ανατροφοδότηση σε πολλαπλασιαστή μπόνους',
        firstAvalanche: 'Γνέθω',
        secondAvalanche: 'Πρώτη ξαναγέμισμα',
        thirdAvalanche: 'Δεύτερη ξαναγέμισμα',
        fourthAvalanche: 'Τρίτη ή περισσότερες ξαναγέμισμα'
    },
    it: {
        avalancheMultipliers: 'Ricarica i moltiplicatori',
        infoRules: 'Il moltiplicatore di vincite aumenta con ogni Rotazione.',
        avalanche: 'Il passo',
        multiplier: 'Moltiplicatore',
        freeFallsMultiplier: 'Ricariche gratuite nel moltiplicatore bonus\n',
        firstAvalanche: 'Rotazione',
        secondAvalanche: 'Prima ricarica',
        thirdAvalanche: 'Seconda ricarica',
        fourthAvalanche: 'Terza o più ricariche'
    }
};
