import React from 'react';
import {JL} from 'jsnlog';

import './styles.less';
import App from './../../index';
import Tabs from './../tabs';
import ControlButtons from './../controlButtons';
import PasswordField from '../passwordField';

export default class Login extends React.Component {
    state = {
        visualization: 'NONE',
        backHandler: null,
        isCode: false
    };

    componentDidMount() {
        JL().debug('-- Login: componentDidMount');
        this.setState({visualization: 'UNAUTHORIZED'});
        App.updateButton('close', {visible: false});
        App.updateButton('user', {visible: false});
        App.updateButton('message', {visible: false});
        App.updateButton('soundVolume', {visible: false});
        document.title = `Login - ${App.name.split(' ').join('')}`;
        document.addEventListener('keydown', this.keyboardPress, false);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keyboardPress, false);
    }

    keyboardPress = event => {
        const {visualization, backHandler} = this.state;
        visualization === 'REGISTRATION' && event.keyCode === 27 && // escape
        backHandler?.();
    };

    /**
     * Show registration page
     * @param visualization
     */
    updateVisualization = visualization => this.setState({
        visualization, backHandler: () =>
            this.setState({visualization: 'UNAUTHORIZED', backHandler: null})
    });

    /**
     * Trigger
     * @param event - form submit
     * @return {Promise<void>}
     */
    oneClick = event =>
        App.User.oneClickRegistration(event, () =>
            this.setState({visualization: 'ONECLICK', backHandler: () => App.User.confirmed()}));

    /**
     * Update password field, hide if code entered
     * @param target
     */
    inputUsername = ({target}) =>
        this.setState({isCode: App.User.isCode(target.value)});

    /**
     * Return <select> default values
     * @param type
     * @return {*}
     */
    getDefaultValue = type => {
        const types = {
            country: () => App.User.countries?.find(({name}) => name === App.System.country)?.code ?? // find current from list
                'UA', // else get default UA
            currency: () => App.User.currencies?.find(({countries}) =>
                countries.includes(App.System.country))?.code
        };

        return types[type]();
    };

    getLocationSelect = lang => <div className='data'>
        <div className='data-country'>
            <label htmlFor='countries'>{lang.country}</label>
            <div className='outter-selection'>
                <div className='selection'>
                    <span className='out-selector'>
                        <span className='plain-selector'>
                            <select
                                id='countries' className='no-clear-focus'
                                defaultValue={this.getDefaultValue('country')}
                            >
                                {App.User.countries?.map(({name, code}, index) =>
                                    <option key={index} value={code}>{`${name} (${code})`}</option>)}
                            </select>
                        </span>
                    </span>
                </div>
            </div>
        </div>
        <div className='data-currency'>
            <label htmlFor='countries'>{lang.currency}</label>
            <div className='outter-selection'>
                <div className='selection'>
                    <span className='out-selector'>
                        <span className='plain-selector'>
                            <select
                                id='currencies' className='no-clear-focus'
                                defaultValue={this.getDefaultValue('currency')}
                            >
                                {App.User.currencies?.map(({code}, index) =>
                                    <option key={index} value={code}>{code}</option>)}
                            </select>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>;

    getLayout = ({isCode}, {state: {lang, serverRequest}}) => ({
        'UNAUTHORIZED': {
            title: lang.login,
            layout: <div className='content'>
                <form id='login-form' onSubmit={App.User.logIn}>
                    <div className='username-data'>
                        <input
                            type='text' id='username' name='username' className='form-field no-clear-focus '
                            onChange={this.inputUsername} autoComplete='username'
                            placeholder={lang.loginCredentials} maxLength={30}
                        />
                        <PasswordField inputName='old-password' placeholder={lang.password} isCode={isCode}/>
                    </div>
                    <div className='actions-group'>
                        <div className='actions-group-sign-in-buttons'>
                            <button
                                className='btn-login blue-button size-215x50' type='submit'
                                disabled={serverRequest}
                            >
                                <span>{lang.signIn}</span>
                            </button>
                            <button
                                className='btn-restore' type='button'
                                onClick={() => this.updateVisualization('RESTORE')}
                                disabled={serverRequest}
                            >
                                <span>{lang.restorePassword}</span>
                            </button>
                        </div>

                        <button
                            className='btn-registration green-button size-215x50'
                            onClick={() => this.updateVisualization('REGISTRATION')}
                            disabled={serverRequest}
                        >
                            <span>{lang.registration}</span>
                        </button>
                        <button
                            className='btn-demo yellow-button size-215x50' type='button'
                            onClick={() => App.User.confirmed({username: 'demo'}, false)}
                            disabled={serverRequest}
                        >
                            <span>{lang.demo}</span>
                        </button>
                    </div>
                </form>
            </div>
        },
        'REGISTRATION': {
            title: lang.registration,
            layout: <Tabs tabs={[
                {
                    title: 'oneClick',
                    layout: <form id='registration-form' onSubmit={this.oneClick}>
                        <div className='username-data select'>
                            {this.getLocationSelect(lang)}
                        </div>
                        <button
                            className='btn-one-click green-button size-215x50' style={{color: '#fff'}}
                            type='submit' disabled={serverRequest}
                        >
                            <span>{lang.signUp}</span>
                        </button>
                    </form>
                }, {
                    title: 'email',
                    layout: <form id='registration-form' onSubmit={App.User.registration}>
                        <div className='username-data select'>
                            {this.getLocationSelect(lang)}
                        </div>
                        <div className='username-data'>
                            <input
                                type='text' id='username' name='username' className='form-field no-clear-focus '
                                autoComplete='new-password' placeholder={lang.email} maxLength={30}/>
                            <PasswordField
                                inputName='password' autoComplete='new-password'
                                placeholder={lang.password}/>
                            <PasswordField
                                inputName='confirmPassword' autoComplete='new-password'
                                placeholder={lang.confirmPassword}/>
                        </div>
                        <button className='btn-create green-button' type='submit' disabled={serverRequest}>
                            <span>{lang.createAccount}</span>
                        </button>
                    </form>
                }
            ]} lang={lang}/>
        },
        'ONECLICK': {
            title: lang.successRegistration,
            layout: <div>
                <div className='username-data'>
                    <span className='subtitle'>{lang.username}</span>
                    <input id='username' type='text' readOnly value={App.User.get().username} align='center'/>
                    <button
                        className='btn-copy inner-icon' onClick={() => App.copyText(App.User.get().username)}
                        style={{backgroundImage: `url(${App.getUrl('/img/registration/copy.png')})`}}
                    />
                </div>
                <div className='password-data'>
                    <span className='subtitle'>{lang.password}</span>
                    <input id='password' type='text' readOnly value={App.User.get().password} align='center'/>
                    <button
                        className='btn-copy inner-icon' onClick={() => App.copyText(App.User.get().password)}
                        style={{backgroundImage: `url(${App.getUrl('/img/registration/copy.png')})`}}
                    />
                </div>
                <div className='saving'>
                    <button
                        className='btn-save-as'
                        onClick={() => App.User.downloadFile('registration/image', 'credentials.png')}
                        style={{backgroundImage: `url(${App.getUrl('/img/registration/image.png')})`}}
                    >
                        <span>{lang.image}</span>
                    </button>
                    <div className='save-as-title'>{lang.saveAs}</div>

                    <button
                        className='btn-save-as'
                        onClick={() => App.User.downloadFile('registration/file', 'credentials.txt')}
                        style={{backgroundImage: `url(${App.getUrl('/img/registration/text.png')})`}}
                    >
                        <span>{lang.text}</span>
                    </button>
                </div>
            </div>
        },
        'RESTORE': {
            title: lang.restoringPassword,
            layout: <form onSubmit={App.User.restorePassword}>
                <h2 className='restore-text'>{lang.restorePasswordWithEmail}</h2>
                <div className='restore-data'>
                    <input type='text' id='restore' name='restore' className='no-clear-focus' maxLength={30}/>
                </div>
                <button type='submit' className='btn-create blue-button' disabled={serverRequest}>
                    {lang.sendNewPassword}
                </button>
            </form>
        }
    });

    render() {
        const {visualization, backHandler} = this.state;
        const {state: {orientation, buttons, lang}} = this.props;
        const {layout, title} = this.getLayout(this.state, this.props)[visualization] || {};

        return (
            <div className='login' onTouchStart={App.hideMobileBurger}>
                <img className='blur-bg' src={`./../../img/registration/background-${orientation}.jpg`} alt=''/>
                <div className='form-container'>
                    <header>
                        {backHandler && (visualization === 'ONECLICK' ?
                            <button className='btn-enter blue-button size-215x50' onClick={backHandler}>
                                {App.language.login}
                            </button> :
                            <button className='btn-back' onClick={backHandler}>←</button>)}
                        <h1 className='title'>{title}</h1>
                    </header>
                    <img src={App.getUrl(`/img/registration/border.png`)} className='border border-styles' alt=''/>
                    <img src={App.getUrl(`/img/registration/border.png`)} className='border border-styles' alt=''/>
                    {layout}
                </div>
                <button className='support-btn red-shadow red-color' onClick={App.Modal.showSupport}>
                    {lang.support}
                </button>

                <ControlButtons buttons={buttons}/>
            </div>
        );
    }
}
