import * as PIXI from 'pixi.js-legacy';
import CountUp from './../../../modules/countUp';

import App from './../../../index';
import GameAmatic from './../../amatic/game';
import GambleAmatic from './../../amatic/gamble';
import Lines from './lines';
import InfoScreen from '../../infoScreen';
import BookOfFortune from './../book-of-fortune/game';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Text = PIXI.Text,
    Container = PIXI.Container;

export default class HotScatter extends GameAmatic {
    constructor() {
        super();
        this.id = 'hot-scatter';
        this.name = 'Hot Scatter';
        this.scatter = 7;
        this.buttonsPanelShadow = 'mid no-blur';

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 125, y: 150},
            bonusInBonusFrame: {x: 125, y: 150},
            endBonusFrame: {x: 125, y: 150}
        };

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 5, 10, 25, 100]},     // 0 - cherry
            {regularDelay: 100, payment: [0, 0, 0, 10, 40, 150]},     // 1 - lemon
            {regularDelay: 100, payment: [0, 0, 0, 10, 40, 150]},     // 2 - orange
            {regularDelay: 100, payment: [0, 0, 0, 20, 50, 200]},     // 3 - plum
            {regularDelay: 100, payment: [0, 0, 0, 40, 200, 400]},    // 4 - grape
            {regularDelay: 100, payment: [0, 0, 0, 40, 200, 400]},    // 5 - melon
            {regularDelay: 100, payment: [0, 0, 0, 100, 1000, 5000]}, // 6 - 7
            {regularDelay: 100, payment: [0, 0, 0, 2, 10, 50]}        // 7 - star scatter
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                minimizeSymbols: 'minimizeSymbols.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                bonusArea: 'area/bonus.png',
                frame: 'bonus/frame.png',
                frame1: 'bonus/frame1.png'
            }),
            atlas: this.mergePath(['regularShortSymbols.json'])
        };
        this.gameSounds = {
            soundClass: 'amatic',
            sounds: [
                {name: 'bonus-game-won', alias: 'bonusGameStart', path: '/audio/amatic/'},
                {name: 'bonus-game-end', alias: 'bonusGameEnd', path: '/audio/amatic/'},
                {name: 'bonus-waiting', alias: 'bonusWaiting', loop: true, path: '/audio/amatic/'},
                {name: 'add-free-spin', alias: 'addFreeSpin', loop: true, path: '/audio/amatic/'},
                {name: 'scatter', path: '/audio/amatic/'},
                {name: 'teaser_1', path: '/audio/amatic/'},
                {name: 'teaser_2', path: '/audio/amatic/'},
                {name: 'teaser_3', path: '/audio/amatic/'},
                {name: 'bonus-background', loop: true}
            ],
            path: `/game/games/${this.id}/audio/`
        };
        this.Lines = new Lines();
        this.Gamble = new GambleAmatic(this.mergePath({gambleArea: 'gamble/gamble-area.png'}));
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
        this.setRegularShortSprite = BookOfFortune.prototype.setRegularShortSprite.bind(this);
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 18pt Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#faf900';
        ctx.shadowColor = 'black';
        ctx.shadowOffsetX = ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 5;

        switch (page) {
            case 1:
                // chery
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 700, 291);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 700, 315);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 700, 340);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[2], 700, 365);

                // lemon
                this.strokeFillText(ctx, bet * this.symbols[1].payment[5], 160, 295);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[4], 160, 328);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[3], 160, 360);

                // orange
                this.strokeFillText(ctx, bet * this.symbols[2].payment[5], 700, 172);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[4], 700, 203);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[3], 700, 234);

                // plum
                this.strokeFillText(ctx, bet * this.symbols[3].payment[5], 160, 172);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[4], 160, 203);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[3], 160, 234);

                // grape
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 700, 48);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 700, 79);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 700, 110);

                // grape
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 160, 48);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 160, 79);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 160, 110);

                // seven 7
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 430, 55);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 430, 105);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 430, 157);

                // seven 7
                this.strokeFillText(ctx, bet * nLines * this.symbols[7].payment[5], 430, 285);
                this.strokeFillText(ctx, bet * nLines * this.symbols[7].payment[4], 430, 322);
                this.strokeFillText(ctx, bet * nLines * this.symbols[7].payment[3], 430, 358);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    /**
     * Отрисовка таблички бонусной игры
     */
    showStartBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y);
        const richText1 = new Text('CONGRATULATIONS YOU WIN!', {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 30,
            fontWeight: 'bold',
            fill: '#ff0000',
            lineJoin: 'round'
        });
        richText1.x = 180;
        richText1.y = 180;

        const richText3 = new Text('Bonus Multiplier: 3', {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 30,
            fontWeight: 'bold',
            fill: '#ff0000',
            strokeThickness: 5,
            lineJoin: 'round'
        });
        richText3.x = 260;
        richText3.y = 370;

        const richText2 = new Text('15 BONUSSPINS', {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 50,
            fontWeight: 'bold',
            fill: '#ff0000',
            stroke: '#ffffff',
            strokeThickness: 5,
            lineJoin: 'round'
        });
        richText2.x = 215;
        richText2.y = 250;

        parentContainer.addChild(richText1);
        parentContainer.addChild(richText3);
        parentContainer.addChild(richText2);
    }

    /**
     * Отрисовка таблички бонус в бонусе
     */
    /**
     * Отрисовка таблички бонус в бонусе
     */
    showBonusInBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y, 'frame1');
        setTimeout(() => {
            App.updateButton('start', {
                disabled: false,
                title: 'start',
                handler: () => this.drawBonusStep(this.latestResponse.features)
            });
            App.Sounds.stopSound('addFreeSpin');
            this.showPressAnyButton(false);
        }, 5300);

        const richText1 = new Text('BONUS FREE SPINS', {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 30,
            fontWeight: 'bold',
            fill: '#ff0000',
            lineJoin: 'round'
        });
        richText1.x = 260;
        richText1.y = 170;

        parentContainer.addChild(richText1);

        const props = {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 50,
            fontWeight: 'bold',
            fill: '#ff0000',
            stroke: '#ffffff',
            strokeThickness: 5,
            lineJoin: 'round'
        };
        const won = new Text(`${15}`, props);
        won.position.set(260, 285);
        const total = new Text(`${this.bonusStatus.total - 15}`, props);
        total.position.set(500, 285);
        parentContainer.addChild(won, total);

        const options = {
            values: {
                total: {start: 15, end: 0, current: 15},
                won: {
                    start: this.bonusStatus.total - 15,
                    end: this.bonusStatus.total,
                    current: this.bonusStatus.total - 15
                }
            },
            duration: 4300
        };

        setTimeout(() => {
            App.Sounds.playSound('addFreeSpin');
            const countUp = new CountUp(options);
            countUp.onTick(values => {
                won.text = values.total.current.toFixed(0);
                total.text = values.won.current.toFixed(0);
            });
            countUp.start();
        }, 1000);
    }

    /**
     * Отрисовка таблички окончания бонусной игры
     */
    showEndBonusFrame(parentContainer, {x, y}, {win, total}) {
        this.showBonusFrame(parentContainer, x, y);
        const richText1 = new Text('CONGRATULATIONS YOU WIN!', {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 30,
            fontWeight: 'bold',
            fill: '#ff0000',
            lineJoin: 'round'
        });
        richText1.x = 180;
        richText1.y = 180;

        const richText3 = new Text(`BONUSSPINS PLAYED: ${this.bonusStatus.total}`, {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 30,
            fontWeight: 'bold',
            fill: '#ff0000',
            strokeThickness: 5,
            lineJoin: 'round'
        });
        richText3.x = 220;
        richText3.y = 360;

        const richText2 = new Text(`\n${this.bonusStatus.win}`, {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 90,
            fontWeight: 'bold',
            fill: '#ff0000',
            stroke: '#ffffff',
            strokeThickness: 5,
            lineJoin: 'round'
        });
        richText2.x = 400 - this.bonusStatus.win.toString().length * 22;
        richText2.y = 130;

        parentContainer.addChild(richText1);
        parentContainer.addChild(richText3);
        parentContainer.addChild(richText2);
    }

    /**
     * Draw all bonus animation after bonus 'press any button'
     * @param parentContainer
     * @param bonusSymbol - current bonus symbol in extension
     */
    drawBonusAnimation(parentContainer, bonusSymbol) {
        setTimeout(() => this.bonusRoll(), 1000);
    }

    createFeatureInfo(feature, container, scatter) {
        let {number, symbol, reels, positions, payment, uc} = feature; // get current feature params
        const ucReels = uc === 'WIN_LINE' ? reels.length : positions.length; // check 'SCATTER' feature

        const multiplier = !(this.isBonus() && this.gameFlag.bonusStarted) ? ' ' : ' x 3';
        payment = !(this.isBonus() && this.gameFlag.bonusStarted) ? payment : payment / 3;
        if (!(scatter || uc === 'WIN_LINE')) {
            return;
        }

        const textProps = {
            font: 'Arial',
            fontSize: 16,
            fontWeight: 600,
            fill: '#f0e7cb'
        };
        const winSymbolsContainer = new Container();
        winSymbolsContainer.position.set(-100, -170);
        winSymbolsContainer.zIndex = 200;
        winSymbolsContainer.name = 'winSymbolsContainer';
        container.addChild(winSymbolsContainer);
        const statusLine = new Text(
            `${uc === 'WIN_LINE' ? App.language.line.toUpperCase() : 'SCATTERED'} ${uc === 'WIN_LINE' ? number + 1 : ''}: `,
            textProps
        );

        const statusPayment = new Text(
            `${'win'.toUpperCase()}: ${payment}` + multiplier,
            textProps
        );

        statusLine.position.set(240, 460);
        statusPayment.position.set(500, 460);

        for (let i = 0; i <= ucReels - 1; i++) {
            const minimizeSymbol = new Sprite(this.getSpriteTextures({
                image: 'minimizeSymbols',
                fromFrame: symbol,
                colCount: this.symbols.length,
                width: 22,
                height: 19
            })[0]);

            minimizeSymbol.position.x = 450 + (25 * i);
            minimizeSymbol.position.y = 630;
            winSymbolsContainer.addChild(minimizeSymbol);
        }

        container.addChild(statusLine, statusPayment);
        uc === 'WIN_LINE' && this.Lines.drawLineImages([number], reels, container, true, payment);
        this.Lines.drawBoxes(this.getStageChild('boxesContainer'), number);
    }

    /**
     * Show bonus message with bonus symbol or with bonus game win.
     * @param isFirstBonus {boolean} TRUE if this is the message for starting bonus game.
     */
    drawBonusAskButton(isFirstBonus = false) {
        let isLast = !isFirstBonus && this.bonusStatus && this.bonusStatus.remain === 0;

        this.stopAnimateFeature();
        setTimeout(() => {
            this.drawBonusFrame(isFirstBonus, isLast, this.getStageChild('bonusContainer'), this.coordinatesBonusFrame);
        }, 1000);

        this.gameFlag.bonusStart = true;
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});

        isLast = this.bonusStatus && this.bonusStatus.remain === 0;
    }

    drawBonusFrame(first, last, parentContainer, coordinates) {
        parentContainer.removeChildren();
        App.Sounds.stopSound('bonus-background');

        const {startBonusFrame, bonusInBonusFrame, endBonusFrame} = coordinates;
        if (first) {
            this.playBonusGameSound();
            this.setBackground('bonusArea');
            App.Sounds.playSound('bonusWaiting');
            this.showStartBonusFrame(parentContainer, startBonusFrame);
            App.updateButton('start', {
                disabled: false,
                title: 'start',
                handler: () => this.startBonusAnimation(parentContainer)
            });
        }
        if (last) {
            parentContainer.removeChildren();
            this.setRegularSprite();
            App.updateButton('start', {disabled: true});
            this.setBackground('mainArea');
            this.showEndBonusFrame(parentContainer, endBonusFrame, this.bonusStatus);
            this.playEndBonusGameSound();
            setTimeout(() => this.endBonus(), 5000);
        }
        if (!first && !last) {
            this.showBonusInBonusFrame(parentContainer, bonusInBonusFrame);
            App.updateButton('start', {disabled: true});
        }
    }

    /**
     * Prepare game behaviour after bonus 'press any button' message
     */
    startBonusAnimation = parentContainer => {
        App.Sounds.stopSound('bonusWaiting');
        this.clearPressAnyButton();
        this.Buttons.disableAllButtons();
        App.updateButton('start', {disabled: true});
        this.gameFlag.bonusStarted = true;
        this.drawBonusAnimation(parentContainer, this.bonusRollSymbol);
    };

    /**
     * Start scatter animations
     * @param features
     */
    bonusEntrance(features) {
        App.Sounds.playSound('scatter');
        this.stopAnimateFeature();
        this.drawTopAnimation(this.getStageChild('bonusContainer'));
        App.updateButton('start', {disabled: true});

        const scatterFeature = features.find(({uc}) => uc === 'SCATTER');
        this.playScatterAnimation(scatterFeature, () => // call after scatter played
            this.drawBonusAskButton(this.isFreeRoll(features) && !this.bonusStatus));
    }

    setScatterSprite(scatterFeature) {
        this.createFeatureInfo(scatterFeature, this.getStageChild('linesContainer'), true);
        scatterFeature.positions.forEach(position => {
            const {reel, row} = position;
            const symbolObj = this.reelMatrix[reel][row];
            symbolObj.image = 'regular';
            symbolObj.loop = false;
            this.Roll.updateSymbolSprite(symbolObj);
            symbolObj.sprite.play();
        });
    }

    /**
     * Check symbol image
     * Can be redefined to 'regular'/'bonus'/'additional'
     * Call for Roll.initReels() and Roll.updateFullClipMatrix()
     */
    getSymbolImageType = () => 'static';
}
