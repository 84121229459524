import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../../../index';
import GameDeluxe from './../../deluxe/game';
import Lines5 from './lines';
import InfoScreen from '../../infoScreen';
import GambleDeluxe from './../../deluxe/gamble';

/* PIXI aliases */
const Texture = PIXI.Texture,
    AnimatedSprite = PIXI.AnimatedSprite,
    Sprite = PIXI.Sprite,
    Text = PIXI.Text;

export default class HotChipRunner extends GameDeluxe {
    constructor() {
        super();
        this.id = 'hot-chip-runner';
        this.name = 'Hot Chip Runner Deluxe';
        this.reels = 3;
        this.reelRows = 3; // number of rows per reel
        this.reelXCoordinates = [61, 292, 523]; // magic numbers - x coordinates where reels starts
        this.symbolHeight = 130; // height of aa single symbol
        this.symbolWidth = 216; // width of aa single symbol
        this.scatter = 1;
        this.scatter1 = 0;
        this.buttonsPanelShadow = 'mid';

        this.stopPosition = null;
        this.rouletePosition = 0;
        this.rouleteMap = {
            0: {x: 479, y: 162, picture: 0, xb: 377, yb: 74},
            1: {x: 496, y: 237, picture: 1, xb: 425, yb: 85},  // n: номер позиции в рулетке,
            2: {x: 483, y: 313, picture: 2, xb: 470, yb: 106}, // x, y позиция на экране,
            3: {x: 436, y: 359, picture: 3, xb: 511, yb: 140}, // picture: номер елемента в спрайте
            4: {x: 358, y: 376, picture: 4, xb: 536, yb: 188},
            5: {x: 281, y: 359, picture: 5, xb: 543, yb: 240},
            6: {x: 236, y: 312, picture: 6, xb: 533, yb: 291},
            7: {x: 219, y: 237, picture: 7, xb: 511, yb: 336},
            8: {x: 233, y: 161, picture: 8, xb: 475, yb: 371},
            9: {x: 281, y: 115, picture: 9, xb: 430, yb: 396},
            10: {x: 358, y: 99, picture: 10, xb: 378, yb: 406},
            11: {x: 432, y: 113, picture: 11, xb: 326, yb: 396}
        };

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 60, y: 85},
            bonusInBonusFrame: {x: 60, y: 85},
            endBonusFrame: {x: 60, y: 85}
        };

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 0, 10]},  // 0 - red chip
            {regularDelay: 100, payment: [0, 0, 0, 10]},  // 1 - yellow chip
            {regularDelay: 100, payment: [0, 0, 0, 40]},  // 2 - chery
            {regularDelay: 100, payment: [0, 0, 0, 40]},  // 3 - plum
            {regularDelay: 100, payment: [0, 0, 0, 60]},  // 4 - pear
            {regularDelay: 100, payment: [0, 0, 0, 80]},  // 5 - bell
            {regularDelay: 100, payment: [0, 0, 0, 100]}, // 6 - watermelon
            {regularDelay: 100, payment: [0, 0, 0, 200]}, // 7 - bar
            {regularDelay: 100, payment: [0, 0, 0, 300]}  // 8 - seven
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                circle: 'bonus/circle.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                bonusArea: 'area/bonus.png',
                bonus1: 'bonus/bonus1.png',
                roulette: 'bonus/roulette.png',
                spot: 'bonus/spot.png',
                bonusAnimation: 'bonus/animation.png'
            }),
            atlas: this.mergePath(['bonus/scatterSymbols.json'])
        };

        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [
                {name: 'chip'},
                {name: 'sound_info'},
                {name: 'shot'},
                {name: 'CHI_Switchv1'},
                {name: 'startRollete'}
            ],
            path: `/game/games/${this.id}/audio/`
        };

        this.Lines = new Lines5();
        this.Gamble = new GambleDeluxe({gambleArea: `/game/games/${this.id}/img/gamble/gamble-area.png`});
        this.InfoScreen = new InfoScreen({pages: 1}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '25px Times, Times New Roman, serif';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#ffffff';

        ctx.fillText(bet * this.symbols[0].payment[3], 100, 408);
        ctx.fillText(bet * this.symbols[1].payment[3], 100, 338);
        ctx.fillText(bet * this.symbols[3].payment[3], 100, 270);
        ctx.fillText(bet * this.symbols[6].payment[3], 100, 203);
        ctx.fillText(bet * this.symbols[7].payment[3], 100, 136);

        // колокольчики
        ctx.fillText(bet * this.symbols[8].payment[3], 710, 148);
        ctx.fillText(bet * this.symbols[5].payment[3], 710, 233);
        ctx.fillText(bet * this.symbols[4].payment[3], 710, 320);
        ctx.fillText(bet * this.symbols[2].payment[3], 710, 404);

        // draw colored circle
        const image = 'circle';
        const width = 222, height = 222;
        let colIndex = (this.gameSettings.bets.length - this.gameSettings.posBet - 1);
        colIndex = colIndex > 3 ? 3 : colIndex;
        ctx.drawImage(this.getImage(image), colIndex * width, 0, width, height, 293, 174, width, height);

        // current bet
        ctx.font = 'italic bold 30pt arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#ffff00';
        ctx.fillText('x' + bet, 405, 300);

        // available bet
        ctx.font = 'bold 15pt arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#088A85';
        this.gameSettings.bets.forEach((currentBet, betIndex) => {
            const alpha = (2 * Math.PI / this.gameSettings.bets.length) * betIndex - Math.PI / 2 + Math.PI / 4;
            const r = 90;
            const cos = Math.cos(alpha) * r;
            const sin = Math.sin(alpha) * r;

            currentBet > bet &&
            ctx.fillText('x' + currentBet, 405 + cos, 295 + sin);
        });
        const circleCenter = {x: 403, y: 295};
        const angle = 30;
        const r = 142;
        const bets = [80, 10, 20, 80, 20, 10, 80, 40, 14, 120, 14, 40];

        // win posible bet
        bets.forEach((bet, betIndex) => {
            const x = (circleCenter.x + r * Math.cos(angle * betIndex / 180 * Math.PI)).toFixed(2);
            const y = (circleCenter.y + r * Math.sin(angle * betIndex / 180 * Math.PI)).toFixed(2);
            const fontSize = String(bet * nLines).length === 2 ? 20 : 16;
            bet *= nLines;

            ctx.font = `italic bold ${fontSize}pt arial`;
            ctx.textAlign = 'center';
            ctx.lineWidth = 6;
            ctx.strokeStyle = '#ff0000';
            ctx.strokeText(bet, x, y);
            ctx.lineWidth = 2;
            ctx.strokeStyle = '#000';
            ctx.strokeText(bet, x, y);
            ctx.fillStyle = '#ffff00';
            ctx.fillText(bet, x, y);
        });
    }

    isFreeRoll = () => {
        let iNumOfBonus = 0; // first chip
        let iNumOfBonus2 = 0; // second chip

        this.latestResponse.screen.forEach(reel => {
            reel.forEach((symbol, rowIndex) => {
                if ((this.scatter === symbol) && (rowIndex === 1)) {
                    iNumOfBonus++;
                }
                if ((this.scatter1 === symbol) && (rowIndex === 1)) {
                    iNumOfBonus2++;
                }
            });
        });
        return (iNumOfBonus === 3) || (iNumOfBonus2 === 3);
    };

    getFeatureDelay(currentFeature) {
        let delay = 0;

        switch (currentFeature.uc) {
            case 'WIN_LINE':
                delay = this.winLineFeatureDelay;
                break;
            case 'SCATTER':
            case 'SPECIAL_SYMBOL':
                const {regularSteps, regularDelay} = this.symbols[this.scatter];
                delay = regularSteps * regularDelay + 1000;
                break;
        }

        return delay;
    }

    drawBonusAskButton(isFirstBonus) {
        const boxesContainer = this.getStageChild('boxesContainer');
        boxesContainer.removeChild(boxesContainer.getChildByName('bonusAnimation'));
        boxesContainer.visible = false;

        const container = this.getStageChild('bonusContainer');
        container.removeChildren();
        this.setBackground('bonusArea');
        this.drawAdditionalImageRoulette(this.getStageChild('bonusContainer'));
        this.showWaitingAnimation(this.latestResponse, Date.now(), Date.now());
        App.updateButton('start', {
            handler: this.runRouletteClick,
            disabled: false,
            title: 'start'
        });
    }

    /**
     * Возвращает true, если уже идет бонус или выпал вход в бонус в прокруте
     */
    isBonus = () =>
        this.bonusStatus ||
        this.latestResponse?.features?.some(({uc}) =>
            uc === 'SPECIAL_SYMBOL');

    rouleteStep(position, parentContainer) {
        position = position % 12;
        const sprite = parentContainer.getChildByName('roulette');
        sprite.position.set(this.rouleteMap[position].x + 5, this.rouleteMap[position].y);
        sprite.gotoAndStop(position);
    }

    createRouleteSprite(parentContainer) {
        const roulette = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 12, image: 'roulette',
            width: 95, height: 86
        }));
        roulette.name = 'roulette';
        roulette.animationSpeed = 0.5;
        roulette.position.set(33, 0);
        parentContainer.addChild(roulette);
    }

    createCircleSprite(parentContainer) {
        const image = 'circle';
        const width = 222, height = 222;
        let colIndex = (this.gameSettings.bets.length - this.gameSettings.posBet - 1);
        colIndex = colIndex > 3 ? 3 : colIndex;

        const sprite = new Sprite(new Texture(this.getTexture(image), {
            x: colIndex * width, y: 0,
            width, height
        }));
        sprite.name = image;
        sprite.position.set(293, 174);
        parentContainer.addChild(sprite);
    }

    createSpotSprite(parentContainer) {
        const spot = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 2, image: 'spot',
            width: 110, height: 110
        }));
        spot.name = 'spot';
        spot.animationSpeed = 1;
        spot.position.set(349, 229);
        parentContainer.addChild(spot);
    }

    animateWinSpot(lastTime, pos, parentContainer) {
        const timeDiff = Date.now() - lastTime;
        const animateTimeout = 600;   // частота вызова функции зависит от первой и второй скорости

        if (timeDiff > animateTimeout) {
            lastTime = lastTime + timeDiff;
            pos++;
            if (pos < 6) {
                const sprite = parentContainer.getChildByName('spot');
                sprite.gotoAndStop(pos % 2);
            }
            if (pos > 6 && pos < 10) {
                const sprite1 = parentContainer.getChildByName('spot');
                sprite1.gotoAndStop(2);
                const sprite2 = parentContainer.getChildByName('centralText');
                sprite2.visible = !sprite2.visible;
            }

            if (pos > 10 && pos < 16) {
                const sprite2 = parentContainer.getChildByName('centralText');
                sprite2.visible = true;

                const {payment} = this.latestResponse.extension;
                this.Legends.setText('win', {text: 'win', value: this.roundWin + payment});
                this.Legends.setStatus('creditsWon', payment);
            }

            if (pos > 16) { // exit from bonus
                this.getStageChild('bonusContainer').removeChildren();
                this.setRegularSprite();
                this.gameFlag.bonusStart = false;
                this.endBonus();
                this.latestResponse.features = [];  // to prevent triggering isBonus() and automatic next roll after bonus
                this.takeWin(true, true);
                return;
            }
        }

        requestAnimationFrame(this.animateWinSpot.bind(this, lastTime, pos, parentContainer));
    }

    getPosition = mult => {
        const mas = {
            10: [2, 6],    // 50
            14: [9, 11],   // 70
            20: [3, 5],    // 100
            40: [0, 8],    // 200
            80: [4, 7, 1], // 400
            120: [10]      // 600
        };

        const rand = Math.round(Math.random() * (mas[mult].length - 1));
        return mas[mult][rand];
    };

    showWaitingAnimation(response, animationStarted = Date.now(), lastTime) {
        const timeDiff = Date.now() - lastTime;
        const animateTimeout = 100;   // частота вызова функции зависит от первой и второй скорости

        if (this.stopPosition != null) {
            this.rouletePosition = this.rouletePosition % 12;
            const pos = this.getPosition(this.latestResponse.extension.multiplier);
            this.showChoice(response, animationStarted, lastTime, pos, this.getStageChild('bonusContainer'));
            return;
        }

        if (timeDiff > animateTimeout) {
            lastTime = Date.now();
            this.rouletePosition++;
            this.rouleteStep(this.rouletePosition, this.getStageChild('bonusContainer'));
            App.Sounds.playSound('CHI_Switchv1');
        }
        this.waitingAnimationFrame =
            requestAnimationFrame(this.showWaitingAnimation.bind(this, response, animationStarted, lastTime));
    }

    showChoice(response, animationStarted = Date.now(), lastTime, pos, parentContainer) {
        const timeDiff = Date.now() - lastTime;
        let animateTimeout = 50;   // частота вызова функции зависит от первой и второй скорости
        const rouletteLength = 12;
        const rouletteRounds = 8;
        const stepBeforeStop = (rouletteLength * rouletteRounds) + pos;   // roll before showing win
        const stepBeforeWin = stepBeforeStop + 80;  // animate win roulette stop

        if (this.rouletePosition < stepBeforeStop) {  // animate bliking roulette and win multiplier
            animateTimeout = (this.rouletePosition * this.rouletePosition) / 50;
            if (this.rouletePosition > stepBeforeStop - 6) {
                const roulette = parentContainer.getChildByName('roulette');
                roulette.animationSpeed = 0.2;
                animateTimeout = animateTimeout + 100;
            }
        }

        if (timeDiff > animateTimeout) {
            lastTime = Date.now();

            if (this.rouletePosition < stepBeforeStop) {   // rolling roulette
                this.rouletePosition++;
                this.rouleteStep(this.rouletePosition, parentContainer);
                App.Sounds.playSound('CHI_Switchv1');
            }
            this.rouletePosition === stepBeforeStop + 5 && App.Sounds.playSound('sound_info');
            if (this.rouletePosition >= stepBeforeStop && this.rouletePosition < stepBeforeWin) {  // animate bliking roulette and win multiplier
                this.rouletePosition++;
                App.Sounds.playSound('sound_info');
                this.animateWinSpot(Date.now(), 0, parentContainer);
                return;
            }
        }
        this.waitingAnimationFrame =
            requestAnimationFrame(this.showChoice.bind(this, response, animationStarted, lastTime, pos, parentContainer));
    }

    drawTopAnimation(parentContainer) {
        const width = 415, height = 74;
        const frames = 27;
        const x = 200, y = 250;

        // create array of textures
        const textures = [...Array(frames)].map((item, index) =>
            new Texture(this.getTexture('bonusAnimation'), {
                x: 0,
                y: index * height,
                width: width,
                height: height
            }));

        const sprite = new AnimatedSprite(textures);
        sprite.animationSpeed = 0.15;
        sprite.loop = false;
        sprite.position.set(x, y);
        sprite.name = 'bonusAnimation';
        sprite.play();
        sprite.onComplete = () => this.drawBonusAskButton();

        parentContainer.addChild(sprite);
    }

    runRouletteClick = () => {
        this.stopPosition = 1;
        App.updateButton('start', {disabled: true});
        App.Sounds.playSound('shot');
    };

    drawAdditionalImageRoulette(parentContainer) {
        parentContainer.position.set(0, 0);
        this.stopAnimateFeature();
        this.createCircleSprite(parentContainer);
        this.createRouleteSprite(parentContainer);
        this.createSpotSprite(parentContainer);
        this.drawRouletteText(parentContainer);
    }

    drawRouletteText(parentContainer) {
        const circleCenter = {x: 385, y: 270};
        const angle = 30;
        const r = 142;
        const bets = [80, 10, 20, 80, 20, 10, 80, 40, 14, 120, 14, 40];
        const linesCount = this.gameSettings.getLinesNumber();

        bets.forEach((bet, betIndex) => {
            const x = (circleCenter.x + r * Math.cos(angle * betIndex / 180 * Math.PI)).toFixed(2);
            const y = (circleCenter.y + r * Math.sin(angle * betIndex / 180 * Math.PI)).toFixed(2);
            bet *= linesCount;
            const richText = new Text(bet, {
                align: 'center',
                fontFamily: 'Arial',
                fontSize: 30,
                fontWeight: 'bold',
                stroke: 'red',
                fill: 'yellow',
                strokeThickness: 5,
                lineJoin: 'round',
                fontStyle: 'italic'
            });
            richText.name = 'Roulette_win' + bet;
            richText.x = x - 5;
            richText.y = y;
            parentContainer.addChild(richText);
        });

        this.gameSettings.bets.forEach((bet, betIndex) => {
            const fontSize = String('x' + bet).length === 2 ? 24 : 20;
            if (bet > this.gameSettings.getBetLineCredit()) {
                const alpha = (2 * Math.PI / this.gameSettings.bets.length) * betIndex - Math.PI / 2 + Math.PI / 4;
                const r = 90;
                const x = circleCenter.x + Math.cos(alpha) * r;
                const y = circleCenter.y + Math.sin(alpha) * r;
                const richText3 = new Text('x' + bet, {
                    align: 'center',
                    fill: '#088A85',
                    fontFamily: 'Arial',
                    fontSize: fontSize,
                    fontWeight: 'bold',
                    lineJoin: 'round',
                    fontStyle: 'italic'
                });
                richText3.name = 'Roulette_bet' + bet;
                richText3.x = x + 5;
                richText3.y = y;
                parentContainer.addChild(richText3);
            }
        });

        const total = this.gameSettings.getBetLineCredit();
        const fontDelta = String(total).length === 1 ? 20 : String(total).length === 2 ? 10 : 0;
        const richText3 = new Text('x' + this.gameSettings.getBetLineCredit(), {
            align: 'center',
            fill: '#ffff00',
            fontFamily: 'Arial',
            fontSize: 35,
            fontWeight: 'bold',
            lineJoin: 'round',
            fontStyle: 'italic'
        });
        richText3.x = circleCenter.x - 20 + fontDelta;
        richText3.y = circleCenter.y - 3;
        richText3.name = 'centralText';
        parentContainer.addChild(richText3);

        // info text
        const fontSettings = {
            align: 'center',
            fill: '#ffffff',
            fontFamily: 'Times New Roman',
            fontSize: 25,
            fontWeight: 'bold',
            lineJoin: 'round'
        };
        let richText4 = new Text(this.symbols[0].payment[3], fontSettings);
        richText4.x = 80 + 5;
        richText4.y = 386;
        richText4.name = 'info';
        parentContainer.addChild(richText4);

        richText4 = new Text(this.symbols[1].payment[3], fontSettings);
        richText4.x = 80 + 5;
        richText4.y = 316;
        richText4.name = 'info';
        parentContainer.addChild(richText4);

        richText4 = new Text(this.symbols[3].payment[3], fontSettings);
        richText4.x = 80 + 5;
        richText4.y = 248;
        richText4.name = 'info';
        parentContainer.addChild(richText4);

        richText4 = new Text(this.symbols[6].payment[3], fontSettings);
        richText4.x = 80;
        richText4.y = 181;
        richText4.name = 'info';
        parentContainer.addChild(richText4);

        richText4 = new Text(this.symbols[7].payment[3], fontSettings);
        richText4.x = 80;
        richText4.y = 114;
        richText4.name = 'info';
        parentContainer.addChild(richText4);

        richText4 = new Text(this.symbols[8].payment[3], fontSettings);
        richText4.x = 690;
        richText4.y = 126;
        richText4.name = 'info';
        parentContainer.addChild(richText4);

        richText4 = new Text(this.symbols[5].payment[3], fontSettings);
        richText4.x = 690 + 5;
        richText4.y = 211;
        richText4.name = 'info';
        parentContainer.addChild(richText4);

        richText4 = new Text(this.symbols[4].payment[3], fontSettings);
        richText4.x = 690 + 5;
        richText4.y = 298;
        richText4.name = 'info';
        parentContainer.addChild(richText4);

        richText4 = new Text(this.symbols[2].payment[3], fontSettings);
        richText4.x = 690 + 5;
        richText4.y = 382;
        richText4.name = 'info';
        parentContainer.addChild(richText4);
    }

    endBonus() {
        JL().debug('-- End bonus');
        this.rouletePosition = 0;
        this.stopPosition = null;
        const boxes = this.getStageChild('boxesContainer');
        boxes.visible = true;
        this.setBackground('mainArea');
    }

    playFeatureSound(currentFeature) {
        const {uc, symbol} = currentFeature; // get current feature params
        let soundFile = null;
        switch (uc) {
            case 'WIN_LINE':
                soundFile = symbol === 1 || symbol === 0 ? 'chip' : 'win-line';
                break;
        }
        soundFile && App.Sounds.playSound(soundFile);
    }

    /**
     * Draw long scatter animation before bonus
     * @param scatterFeature
     * @param callback
     */
    playScatterAnimation(scatterFeature, callback) {

    }

    /**
     * Start scatter animations
     * @param features
     */
    bonusEntrance(features) {
        this.setScatterSprite();
        this.drawTopAnimation(this.getStageChild('boxesContainer'));
        App.Sounds.playSound('sound_info');
        App.updateButton('start', {disabled: true});
        this.playScatterAnimation(features, () =>
            this.drawBonusAskButton(this.isFreeRoll(features) && !this.bonusStatus));
    }

    setScatterSprite() {
        this.reelMatrix.forEach(reel => {
            reel.forEach((symbolObj, rowIndex) => {
                if (this.scatter === symbolObj.symbol && rowIndex === 1) {
                    symbolObj.image = 'scatter';
                    symbolObj.loop = false;
                    this.Roll.updateSymbolSprite(symbolObj);
                    symbolObj.sprite.play();
                }
            });
        });
    }
}
