import {JL} from 'jsnlog';

import App from './../../../index';
import GameEgt from './../../egt/game';
import Lines from './../../egt/lines10';
import GambleEgt from './../../egt/gamble';
import InfoScreen from '../../infoScreen';

export default class ZodiacWheel extends GameEgt {
    constructor() {
        super();
        this.id = 'zodiac-wheel';
        this.name = 'Zodiac Wheel';
        this.buttonsPanelShadow = 'mid';
        this.reelFilter = [[8], [10, 9], [], [10, 9], [8]];
        this.doublingFilter = [8, 9, 10];

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 120, y: 90},
            bonusInBonusFrame: {x: 120, y: 150},
            endBonusFrame: {x: 120, y: 150}
        };

        this.symbols = [
            {regularDelay: 70, payment: [0, 0, 0, 10, 30, 100]},    // 0 - Jolly
            {regularDelay: 70, payment: [0, 0, 0, 10, 30, 100]},    // 1 - Queen
            {regularDelay: 70, payment: [0, 0, 0, 10, 30, 100]},    // 2 - King
            {regularDelay: 70, payment: [0, 0, 0, 10, 30, 100]},    // 3 - Ace
            {regularDelay: 70, payment: [0, 0, 0, 20, 50, 200]},    // 4 - Planetarium
            {regularDelay: 70, payment: [0, 0, 0, 40, 100, 500]},   // 5 - Spyglass
            {regularDelay: 70, payment: [0, 0, 0, 40, 100, 500]},   // 6 - Lady with lamp
            {regularDelay: 70, payment: [0, 0, 10, 50, 200, 3000]}, // 7 - Astronomer
            {regularDelay: 70, payment: [0, 0, 0, 0, 0, 0]},        // 8 - calendar (wild)
            {regularDelay: 70, payment: [0, 0, 0, 20, 0, 0]},       // 9 - map (scatter 1)
            {regularDelay: 70, payment: [0, 0, 0, 3, 20, 100]}      // 10 - book (scatter 2)
        ];

        this.imageResources = {
            main: this.mergePath({mainArea: 'area/main.png'}),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                minimizeSymbols: 'minimizeSymbols.png',
                gambleArea: 'area/gamble.png'
            }),
            atlas: this.mergePath(['bonusSymbols.json'])
        };
        this.gameSounds = {
            soundClass: 'egt',
            sounds: [
                {name: 'scatter1', alias: 'scatter1'},  // map sound
                {name: 'scatter2', alias: 'scatter2'},  // book sound
                {name: 'scatter1Stop1', alias: 'scatter1Stop1'},  // map stop sound
                {name: 'scatter1Stop2', alias: 'scatter1Stop2'},  // map stop sound
                {name: 'scatter1Stop3', alias: 'scatter1Stop3'},  // map stop sound
                {name: 'scatter2Stop1', alias: 'scatter2Stop1'},  // book stop sound
                {name: 'scatter2Stop2', alias: 'scatter2Stop2'},  // book stop sound
                {name: 'scatter2Stop3', alias: 'scatter2Stop3'},  // book stop sound
                {name: 'scatter2Stop4', alias: 'scatter2Stop4'},  // book stop sound
                {name: 'scatter2Stop5', alias: 'scatter2Stop5'},  // book stop sound
                {name: 'wheel', alias: 'wheel'},  // calendar wheel sound
                {name: 'wheelStop', alias: 'wheelStop'}  // calendar wheel sound
            ],
            path: `/game/games/${this.id}/audio/`
        };
        this.Lines = new Lines(this.mergePath({boxes: 'lines/boxes.png'}));
        this.Gamble = new GambleEgt();
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
        this.InfoScreen.format = 'png';
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 19pt Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = 'SkyBlue';
        ctx.shadowColor = 'black';
        ctx.shadowOffsetX = ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 5;

        switch (page) {
            case 1:
                ctx.font = 'bold 12pt Arial';

                // cherry, lemon
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 220, 373);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 220, 399);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 220, 425);

                // plum, orange
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 590, 373);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 590, 399);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 590, 425);

                // wathermelon
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 220, 259);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 220, 286);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 220, 312);

                // bell
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 590, 259);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 590, 286);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 590, 312);

                // seven
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], 220, 151);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], 220, 170);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 220, 189);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[2], 220, 208);

                // grape
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 590, 155);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 590, 182);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 590, 208);

                // Dolar
                this.strokeFillText(ctx, bet * nLines * this.symbols[10].payment[5], 450, 238);
                this.strokeFillText(ctx, bet * nLines * this.symbols[10].payment[4], 450, 265);
                this.strokeFillText(ctx, bet * nLines * this.symbols[10].payment[3], 450, 293);

                // star
                this.strokeFillText(ctx, bet * nLines * this.symbols[9].payment[3], 450, 402);
                break;
            case 2:
                ctx.font = 'bold 13pt Arial';
                ctx.textAlign = 'left';
                // gamble limit
                this.strokeFillText(ctx, (this.Gamble.limit * 100) / App.Money.getCurrentDenomination(), 480, 239);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    onRotationDone() {
        JL().debug(`-- Rotation done (fps: ${App.System.statistics.fps})`);
        App.updateButton('start', {disabled: true});
        this.roundWin = 0;
        const scatterAnimation = this.decideScatterAnimation(this.latestResponse.screen);
        const {payment} = this.latestResponse;

        payment && scatterAnimation ?
            // going to x2 animation before animate feature
            this.fillReelsWithBonusSymbol(this.latestResponse.screen, () => this.regularRotationDone()) :
            this.regularRotationDone();
    }

    decideScatterAnimation(screen) {
        let scatter1 = 0;
        screen.forEach(reel =>
            reel.forEach(symbol =>
                // clover
                symbol === 8 && scatter1++));
        return scatter1;  // at least one admiral and two gun
    }

    fillReelsWithBonusSymbol(screen, callback) {
        let playSound = false;
        const winReels = this.getWinReels();
        this.reelMatrix.forEach((reel, reelIndex) => {
            if (reelIndex <= winReels && (screen[reelIndex][0] === 8 || screen[reelIndex][1] === 8 || screen[reelIndex][2] === 8)) { // admiral
                reel.forEach(symbolObj => {
                    if (symbolObj.symbol !== 8) { // admiral
                        playSound = true;
                        symbolObj.symbol = 8;
                        symbolObj.image = 'bonus';
                        symbolObj.loop = false;
                        this.Roll.updateSymbolSprite(symbolObj);
                        symbolObj.sprite.play();
                        symbolObj.sprite.onComplete = () => {
                            symbolObj.image = 'regular';
                            this.Roll.updateSymbolSprite(symbolObj);
                        };
                    }
                });
            }
        });

        playSound && App.Sounds.playSound('wheel');
        setTimeout(() => this.regularRotationDone(), 1300);
    }

    getWinReels() {
        let winReels = 0;
        this.latestResponse.features.forEach(feature => {
            if (feature.uc === 'WIN_LINE') {
                winReels = winReels < feature.reels.length ? feature.reels.length : winReels;
            }
        });
        return winReels;
    }

    regularRotationDone() {
        const {features, payment} = this.latestResponse;
        if (payment > 0 || this.isFreeRoll(features) || features.length) {
            // There is a win
            this.setState('SHOW_WIN_LINES');
            this.startAnimateFeature(features);
        } else {
            this.roundFinished();
            this.Legends.setRoundFinText();
        }
    }

    playFeatureSound(currentFeature, featureIndex, features) {
        let soundFile = null;
        switch (currentFeature.uc) {
            case 'WIN_LINE':
                soundFile = 'win-line';
                break;
            case 'SCATTER':
                soundFile = currentFeature.symbol === 10 ? 'scatter1' : 'scatter2';
                break;
        }

        soundFile && App.Sounds.stopSound(soundFile);
        soundFile && App.Sounds.playSound(soundFile);
    }

    setRegularShortSprite(clipMatrix, reelIndex, textures) {
        let scatter1 = 0;
        let scatter2 = 0;
        let wheel = 0;
        for (let reel = 0; reel <= reelIndex; reel++) {
            for (let rowIndex = 0; rowIndex < this.reelRows; rowIndex++) {
                if (clipMatrix[reel][rowIndex + 1].symbol === 9) {
                    scatter1++;
                }
                if (clipMatrix[reel][rowIndex + 1].symbol === 10) {
                    scatter2++;
                }
                if (clipMatrix[reel][rowIndex + 1].symbol === 8) {
                    wheel++;
                }
            }
        }
        if (clipMatrix[reelIndex][1].symbol === 9 || clipMatrix[reelIndex][2].symbol === 9 || clipMatrix[reelIndex][3].symbol === 9) {
            App.Sounds.playSound('scatter1Stop' + scatter1);
        }
        if (clipMatrix[reelIndex][1].symbol === 10 || clipMatrix[reelIndex][2].symbol === 10 || clipMatrix[reelIndex][3].symbol === 10) {
            App.Sounds.playSound('scatter2Stop' + scatter2);
        }
        if (clipMatrix[reelIndex][1].symbol === 8 || clipMatrix[reelIndex][2].symbol === 8 || clipMatrix[reelIndex][3].symbol === 8) {
            App.Sounds.playSound('wheelStop');
        }
    }

    stopAnimateSound = () => {
        App.Sounds.stopSound('gamble-wait');
        App.Sounds.stopSound('scatter1');
        App.Sounds.stopSound('scatter2');
        App.Sounds.stopSound('wheel');
    };
}
