import * as PIXI from 'pixi.js-legacy';

import Lines17 from './../../champion/lines17';
import App from './../../../index';

const Sprite = PIXI.Sprite;

export default class Lines extends Lines17 {
    constructor() {
        super();
        this.imageResources = {
            boxes: '/img/lines/champion_boxes17.png'
        };

        this.boxSettings = {
            firstRightBox: 9,
            boxMargin: 0,
            lineBoxWidth: 55,
            lineBoxHeight: 25,
            textOnBox: true,
            textType: 'bet',
            typeBoxes: 'TwoBoxes',
            boxFontSize: 16,
            boxTextColor: '#000'
        };
        this.linesSettings = {
            lineThickness: 3,
            borderThickness: 4,
            lineColor: '#f78000',
            borderColor: '#984f00',
            horizontalLineLength: 60
        };

        Object.keys(this.lines).forEach(lineIndex =>
            this.lines[lineIndex].boxes[0].x = lineIndex >= 9 ? 680 : 70);
    }

    drawPayments(parentContainer, payment, reelIndex, line, lineIndex) {
        const blockWidth = 35;
        const blockHeight = 12;
        const x = this.getPointPosX(this.isRightBox(lineIndex) ? 1 : 0) + App.Game.symbolWidth - 38;
        const y = (line.path[0].y + line.path[1].y) / 2 - 6;

        const canvas = document.createElement('canvas');
        canvas.width = canvas.height = 50;
        const ctx = canvas.getContext('2d');

        // borders
        ctx.fillStyle = '#f78000';
        ctx.fillRect(5, 5, blockWidth + 2, blockHeight + 2);
        ctx.fillStyle = '#984f00';
        ctx.fillRect(6, 6, blockWidth, blockHeight);
        // background
        ctx.fillStyle = '#000';
        ctx.fillRect(7, 7, blockWidth - 2, blockHeight - 2);

        ctx.font = '8pt Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#fff';
        ctx.fillText(payment, 23, 16);

        const sprite = Sprite.from(canvas);
        sprite.position.set(x - 5.5, y - 6.5);
        sprite.name = 'paymentBox';
        sprite.zIndex = 1;
        canvas.remove();
        parentContainer.addChild(sprite);
    }
}
