import React from 'react';

import './styles.less';
import App from './../../index';

export default class Gamble extends React.Component {
    componentDidMount() {
        App.Game.Gamble.componentDidMount();
    }

    render() {
        const {buttons} = this.props;
        return (
            <div className='gamble-container'>
                {App.Game.DOMComponents.gambleComponent(buttons)}
            </div>
        );
    }
}
