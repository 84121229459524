import App from './../../../index';
import GameChampion from './../../champion/game';
import Lines17 from './lines';
import GambleChampion from './../../champion/gamble';
import InfoScreen from '../../infoScreen';

export default class AlwaysCherry extends GameChampion {
    constructor() {
        super();
        this.id = 'always-cherry';
        this.name = 'Always Cherry';
        this.reels = 3;
        this.reelRows = 3; // number of rows per reel
        this.reelTop = 92; // magic number - where reel images starts
        this.reelXCoordinates = [130, 335, 540]; // magic numbers - x coordinates where reels starts
        this.symbolHeight = 130; // height of aa single symbol
        this.symbolWidth = 130; // height of aa single symbol
        this.transparentBackground = true; // use transparent symbols background
        this.symbolEffects = false;

        this.symbols = [
            {regularDelay: 70, payment: [0, 0, 0, 40]},  // 0 - cherry
            {regularDelay: 70, payment: [0, 0, 0, 40]},  // 1 - lemon
            {regularDelay: 70, payment: [0, 0, 0, 40]},  // 2 - plum
            {regularDelay: 70, payment: [0, 0, 0, 40]},  // 3 - orange
            {regularDelay: 70, payment: [0, 0, 0, 80]},  // 4 - bell
            {regularDelay: 70, payment: [0, 0, 0, 80]},  // 5 - grape
            {regularDelay: 70, payment: [0, 0, 0, 100]}, // 6 - melon
            {regularDelay: 70, payment: [0, 0, 0, 200]}, // 7 - star
            {regularDelay: 70, payment: [0, 0, 0, 300]}  // 8 - '77'
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.gameSounds = {soundClass: 'champion'};
        this.Lines = new Lines17();
        this.Gamble = new GambleUnique(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 2}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '24pt franklinFont';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#fff';
        ctx.strokeStyle = '#ac320c';
        ctx.lineWidth = 2;

        switch (page) {
            case 1:
                // 8 - '77'
                this.strokeFillText(ctx, bet * this.symbols[8].payment[3], 625, 182);
                // 7 - star
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 625, 258);
                // 6 - melon
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 625, 330);
                // 5 - grape
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 400, 409);
                // 3 - orange
                this.strokeFillText(ctx, bet * this.symbols[3].payment[3], 400, 517);
        }
    }
}

class GambleUnique extends GambleChampion {
    constructor(imageResources) {
        super(imageResources);
        this.cardsQueuePos = {x: [421, 487, 553, 619, 685], y: 80};
        this.screenCoordinates = {x: 0, y: 86};
    }
}
