import App from './../../../index';
import GameChampion from './../../champion/game';
import Lines17 from './lines';
import GambleChampion from './../../champion/gamble';
import InfoScreen from '../../infoScreen';

export default class FireRagePlus extends GameChampion {
    constructor() {
        super();
        this.id = 'fire-rage-plus';
        this.name = 'Fire Rage +';
        this.reels = 3;
        this.reelRows = 3; // number of rows per reel
        this.reelTop = 110; // magic number - where reel images starts
        this.symbolHeight = 110; // height of aa single symbol
        this.symbolWidth = 140; // height of aa single symbol
        this.transparentBackground = true;
        this.reelXCoordinates = [151, 335, 512];
        this.buttonsPanelShadow = 'low no-blur';

        this.symbols = [
            {regularDelay: 40, skipSteps: 1, payment: [0, 0, 0, 40]},  // 0 - melon
            {regularDelay: 40, skipSteps: 1, payment: [0, 0, 0, 40]},  // 1 - cherry
            {regularDelay: 40, skipSteps: 1, payment: [0, 0, 0, 40]},  // 2 - plum
            {regularDelay: 80, skipSteps: 1, payment: [0, 0, 0, 40]},  // 3 - BAR
            {regularDelay: 80, skipSteps: 1, payment: [0, 0, 0, 80]},  // 4 - BARx2
            {regularDelay: 80, skipSteps: 1, payment: [0, 0, 0, 80]},  // 5 - BARx3
            {regularDelay: 80, skipSteps: 1, payment: [0, 0, 0, 100]}, // 6 - bell
            {regularDelay: 40, skipSteps: 1, payment: [0, 0, 0, 200]}, // 7 - star
            {regularDelay: 40, skipSteps: 1, payment: [0, 0, 0, 300]}  // 8 - '7'
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.gameSounds = {soundClass: 'champion'};
        this.Gamble = new GambleChampion(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            inactiveBlack: 'gamble/black-inactive.png',
            activeRed: 'gamble/red-active.png',
            inactiveRed: 'gamble/red-inactive.png',
            activeBlack: 'gamble/black-active.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png'
        }));
        this.Lines = new Lines17();
        this.InfoScreen = new InfoScreen({pages: 1}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '14pt Franklin Gothic Medium';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#f7fa14';

        switch (page) {
            case 1:
                // '7'
                ctx.fillText(bet * this.symbols[8].payment[3], 210, 70);
                // star
                ctx.fillText(bet * this.symbols[7].payment[3], 465, 70);
                // bell
                ctx.fillText(bet * this.symbols[6].payment[3], 720, 70);
                // BARx3
                ctx.fillText(bet * this.symbols[5].payment[3], 210, 242);
                // BARx2
                ctx.fillText(bet * this.symbols[4].payment[3], 465, 242);
                // BAR
                ctx.fillText(bet * this.symbols[3].payment[3], 720, 242);
                // plum
                ctx.fillText(bet * this.symbols[2].payment[3], 210, 413);
                // melon
                ctx.fillText(bet * this.symbols[0].payment[3], 465, 413);
                // cherry
                ctx.fillText(bet * this.symbols[1].payment[3], 720, 413);
        }
    }
}
