import App from './../../../index';
import GameDeluxe from './../../deluxe/game';
import Lines27 from './../../deluxe/lines27';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';
import fontHotInfo from './img/font/fontHotInfo';

export default class Gems27 extends GameDeluxe {
    constructor() {
        super();
        this.id = 'gems-27';
        this.name = 'Gems 27';
        this.reelXCoordinates = [61, 293, 524];
        this.reelTop = 89;
        this.transparentBackground = true;
        this.reels = 3; // number of reels
        this.reelRows = 3; // number of rows per reel
        this.symbolWidth = 216;
        this.buttonsPanelShadow = 'strong';

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 0, 40]},  // 1 - cherry
            {regularDelay: 100, payment: [0, 0, 0, 40]},  // 1 - lemon
            {regularDelay: 100, payment: [0, 0, 0, 40]},  // 2 - plum
            {regularDelay: 100, payment: [0, 0, 0, 40]},  // 3 - orange
            {regularDelay: 100, payment: [0, 0, 0, 80]},  // 4 - bell
            {regularDelay: 100, payment: [0, 0, 0, 80]},  // 5 - grape
            {regularDelay: 100, payment: [0, 0, 0, 100]}, // 6 - melon
            {regularDelay: 100, payment: [0, 0, 0, 200]}, // 7 - star
            {regularDelay: 100, payment: [0, 0, 0, 400]}  // 8 - '77'
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png',
                fontHotInfo: fontHotInfo['imageResource']
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };

        this.gameSounds = {soundClass: 'deluxe'};
        this.Lines = new Lines27();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            activeRed: 'gamble/red-active.png',
            inactiveRed: 'gamble/red-inactive.png',
            activeBlack: 'gamble/black-active.png',
            inactiveBlack: 'gamble/black-inactive.png'
        }));

        this.InfoScreen = new InfoScreen({pages: 1}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        const textProps = {
            parentContainer: ctx,
            fontImageName: 'fontHotInfo',
            map: fontHotInfo,
            align: 'center',
            scale: 1.3,
            fontInterval: -3 // px between symbols
        };

        this.drawCustomFont(bet * this.symbols[8].payment[3], 625, 158, textProps);
        this.drawCustomFont(bet * this.symbols[7].payment[3], 625, 237, textProps);
        this.drawCustomFont(bet * this.symbols[6].payment[3], 625, 315, textProps);
        this.drawCustomFont(bet * this.symbols[4].payment[3], 625, 489, textProps);
        this.drawCustomFont(bet * this.symbols[3].payment[3], 400, 403, textProps);
    }
}
