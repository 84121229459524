import {JL} from 'jsnlog';

import App from './../../../index';
import GameDeluxe from './../../deluxe/game';
import Lines5 from './lines';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';

export default class UltraStar extends GameDeluxe {
    constructor() {
        super();
        this.id = 'ultra-star';
        this.name = 'Ultra Star';
        this.scatter = 8;
        this.buttonsPanelShadow = 'strong';

        this.reels = 3; // number of reels
        this.reelRows = 3; // number of rows per reel
        this.reelTop = 89; // magic number - where reel images starts
        this.reelXCoordinates = [62, 293, 524]; // magic numbers - x coordinates where reels starts
        this.symbolWidth = 216;
        this.symbolHeight = 130;
        this.reelFilter = [[8], [], [8]];
        this.freezeReel = null;

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 160, y: 150},
            bonusInBonusFrame: {x: 160, y: 150},
            endBonusFrame: {x: 160, y: 150}
        };

        this.symbols = [
            {regularDelay: 100, bonusDelay: 80, skipSteps: 3, payment: [0, 0, 0, 20]},  // 0 - cherry
            {regularDelay: 100, bonusDelay: 80, skipSteps: 3, payment: [0, 0, 0, 20]},  // 1 - lemon
            {regularDelay: 100, bonusDelay: 80, skipSteps: 3, payment: [0, 0, 0, 25]},  // 2 - orange
            {regularDelay: 100, bonusDelay: 80, skipSteps: 3, payment: [0, 0, 0, 25]},  // 3 - plum
            {regularDelay: 100, bonusDelay: 80, skipSteps: 3, payment: [0, 0, 0, 75]},  // 4 - grape
            {regularDelay: 100, bonusDelay: 80, skipSteps: 3, payment: [0, 0, 0, 100]}, // 5 - melon
            {regularDelay: 100, bonusDelay: 80, skipSteps: 3, payment: [0, 0, 0, 250]}, // 6 - bell
            {regularDelay: 100, bonusDelay: 80, skipSteps: 3, payment: [0, 0, 0, 500]}, // 7 - seven
            {regularDelay: 100, bonusDelay: 80, skipSteps: 3, payment: [0, 0, 0, 0]}    // 8 - star
        ];
        this.imageResources = {
            main: this.mergePath({
                mainArea: `area/${App.System.resolution}/main.png`,
                frame: 'bonus/frame.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {atlas: this.mergePath(['bonus/bonusSymbols.json'])};

        this.gameSounds = {
            soundClass: 'novomatic',
            sounds: [{name: 'fill-star', alias: 'fillStar'}],
            path: `/game/games/${this.id}/audio/`
        };

        this.Lines = new Lines5();
        this.Gamble = new GambleUnique(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));

        this.InfoScreen = new InfoScreen({pages: 2}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines - current lines count
     * @param bet - current bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        const y = 27;
        switch (page) {
            case 1:
                ctx.font = 'bold 36px Arial';
                ctx.fillStyle = '#f3ffbe';
                ctx.strokeStyle = '#ff0000';
                ctx.lineWidth = 2;
                ctx.textAlign = 'center';

                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 150, 179 + y);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 640, 179 + y);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 120, 310 + y);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 670, 315 + y);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[3], 120, 492 + y);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[3], 670, 492 + y);
                break;
            case 2:
                ctx.font = 'italic bold 32px Arial';
                ctx.textAlign = 'center';

                ctx.lineWidth = 6;
                ctx.strokeStyle = '#ff0000';
                ctx.strokeText('RULES', 400, 230);
                ctx.lineWidth = 3;
                ctx.strokeStyle = '#000';
                ctx.strokeText('RULES', 400, 230);
                ctx.fillStyle = '#ffff00';
                ctx.fillText('RULES', 400, 230);

                ctx.font = 'bold 24px Arial';
                ctx.fillStyle = '#fff';
                ctx.fillText('All prizes are for combinations of a kind.', 400, 270);
                ctx.fillText('All prizes are on selected lines.', 400, 300);
                ctx.fillText('All prizes shown in credits.', 400, 330);
                ctx.fillText('Malfunction void all pays and plays.', 400, 360);
                ctx.fillText('', 400, 260);
                break;
        }
    }

    onRotationDone() {
        JL().debug(`-- Rotation done (fps: ${App.System.statistics.fps})`);
        const {features, payment, extension} = this.latestResponse;
        App.updateButton('start', {disabled: true});
        this.roundWin = 0;
        this.bonusStatus = false;
        this.freezeReel = null;

        if (extension) {
            this.fillStars(extension.reel);
        } else {
            if ((payment > 0 && features.length) || this.isFreeRoll(features)) {
                this.setState('SHOW_WIN_LINES');
                this.startAnimateFeature(features);
            } else {
                this.afterBonusAction();
            }
        }
    }

    /**
     * Show additional fill star animation before respin
     * @param reelIndex
     */
    fillStars(reelIndex) {
        JL().debug(`-- Fill stars (reelIndex: ${reelIndex})`);
        const {features, payment, extension} = this.latestResponse;
        this.stopAnimateFeature();

        // prepare array of symbol substitutes
        const fillWay = [...Array(this.reelRows)].map((item, index) => index); // [0, 1, 2]

        // check star position
        this.reelMatrix.forEach(reel => {
            reel.forEach((row, rowIndex) => {
                if (row.symbol === 8) {
                    // delete existing star pos
                    fillWay.splice(fillWay.indexOf(rowIndex), 1); // example: [0, 1]
                    // reverse animation way if star in last row
                    rowIndex === 2 && fillWay.reverse(); // [1, 0]
                }
            });
        });

        // fill stars in respin reel for enable freeze roll (without substitutes)
        const respinScreen = extension.respin.screen;
        respinScreen.forEach((reel, reelIndex) => {
            reelIndex === extension.reel && reel.forEach((row, rowIndex) => {
                respinScreen[reelIndex][rowIndex] = 8;
            });
        });

        // fill stars by step in 1 second
        fillWay.forEach((row, index) => {
            setTimeout(() => {
                const symbolObj = this.reelMatrix[reelIndex][row];
                symbolObj.image = 'bonus';
                symbolObj.loop = false; // play animation only once
                this.Roll.updateSymbolSprite(symbolObj);
                symbolObj.sprite.onComplete = null;
                symbolObj.sprite.play();
                App.Sounds.playSound('fillStar');

                // after last star
                index === fillWay.length - 1 && setTimeout(() => {
                    // restore symbols animation loop
                    this.reelMatrix.forEach(reel => {
                        reel.forEach(symbolObj => {
                            symbolObj.loop = true;
                            symbolObj.sprite.gotoAndStop(0);
                            this.Roll.updateSymbolSprite(symbolObj);
                        });
                    });

                    // change symbols to regular scatter
                    fillWay.forEach(row => {
                        const symbolObj = this.reelMatrix[reelIndex][row];
                        symbolObj.symbol = this.scatter;
                        symbolObj.image = 'regular';
                        this.Roll.updateSymbolSprite(symbolObj);
                    });

                    if (payment > 0 && features.length) {
                        this.bonusStatus = true;
                        this.setState('SHOW_WIN_LINES');
                        this.startAnimateFeature(features);
                    } else {
                        this.drawBonusAskButton(true);
                    }
                }, 1000);
            }, 1000 * index);
        });
    }

    drawBonusAskButton(isFirstBonus) {
        JL().debug(`-- Set bonus ask button (isFirstBonus: ${isFirstBonus})`);
        this.stopAnimateFeature();
        this.showPressAnyButton(false);

        this.gameFlag.bonusStart = true;
        this.bonusWin = this.roundWin;
        this.roundWin && this.Legends.setText('win', {text: 'win', value: this.roundWin});

        App.updateButton('start', {
            disabled: false,
            title: 'start',
            handler: this.respin
        });
        this.showStartBonusFrame(this.getStageChild('bonusContainer'), this.coordinatesBonusFrame.startBonusFrame);
    }

    //
    // ======================== RESPIN SECTION =============================
    //

    /**
     * Возвращает true, если на последнем прокруте выпала бонусная комбинация
     */
    isFreeRoll = () => this.latestResponse.extension;

    /**
     * Start scatter animations
     * @param features
     */
    bonusEntrance(features) {
        const {extension} = this.latestResponse;
        App.updateButton('start', {disabled: true});

        if (extension) { // is respin
            this.setState('SHOW_RESPIN_FRAME');
            App.updateButton('start', {disabled: true});
            setTimeout(() => this.drawBonusAskButton(true), 1000);
        } else {
            this.latestResponse.payment = this.bonusWin;
            this.afterBonusAction();
        }
    }

    respin = () => {
        JL().debug(`-- Respin - ${JSON.stringify(this.latestResponse.extension.respin)}`);
        this.getStageChild('bonusContainer').removeChildren();
        this.clearPressAnyButton();
        this.Legends.setStatus('additionalRoll');
        App.updateButton('start', {disabled: true});
        this.freezeReel = this.latestResponse.extension.reel;

        // save total payment before respin
        const tempPayment = this.latestResponse.payment;
        this.processReelResponse(this.latestResponse.extension.respin);
        // redefine respin payment to total
        this.latestResponse.payment = tempPayment;
    };

    isReelFreezed = reel => reel === this.freezeReel;
}

class GambleUnique extends GambleDeluxe {
    constructor(imageResources) {
        super(imageResources);
        this.cardPos = {x: 335, y: 175};
        this.cardsQueuePos = {x: [378, 440, 502, 564, 625, 688], y: 94};
    }
}
