module.exports = {
    // socket
    connectionServer: 'Подключение к серверу',
    initializingGame: 'Загрузка игры',
    initializingMenu: 'Загрузка меню',
    loadingImages: 'Загрузка изображений',
    reload: 'Перезагрузить',
    redirect: 'Переход',
    exitAndReload: 'Выйдите в меню и перезагрузите страницу',
    exit: 'Выйти',
    exitAfter: 'Выход из системы через',
    serverNotResponded: 'Сервер не отвечает',
    pluginNotResponded: 'Нет связи с контроллером',
    codeAlreadyConnected: 'Игрок с таким кодом уже подключен',
    connectionLost: 'Соединение разорвано',
    gameNotFound: 'Игра не найдена',
    codeNotFound: 'Код не найден',
    cardNotFound: 'Карточка не найдена',
    terminalNotFound: 'Код терминала не найден или терминал недоступен',

    // login
    login: 'Вход',
    loginCredentials: 'имя пользователя, email или код',
    codeCredentials: 'Код счета',
    username: 'Имя пользователя',
    enterCode: 'Введите код',
    password: 'Пароль',
    email: 'Электронная почта',
    signIn: 'Войти',
    signUp: 'Зарегистрироваться',
    registration: 'Регистрация',
    oneClick: 'В один клик',
    demo: 'Демо',
    createAccount: 'Создать учетную запись',
    logout: 'Выйти',
    restorePasswordWithEmail: 'Введите email для восстановления пароля',
    sendNewPassword: 'Отправить новый пароль',
    restorePassword: 'Восстановить пароль',
    restoringPassword: 'Восстановление пароля',
    successRegistration: 'Успешная регистрация',
    incorrectUsername: 'Неверное имя пользователя или пароль',
    notUniqueName: 'Имя должно быть уникальным',
    notUniqueEmail: 'Адрес электронной почты должен быть уникальным',
    notUniquePhone: 'Номер телефона должен быть уникальным',
    missedField: 'Есть пропущенные обязательные поля',
    wrongOldPassword: 'Старый пароль введен неправильно',
    wrongPassword: 'Пароль введен неправильно',
    emailChanged: 'Подтверждение отправлено на Вашу электронную почту',
    passwordResetError: 'Неверный логин или адрес электронной почты',
    newPasswordSent: 'Новый пароль был отправлен на Ваш электронный адрес',
    invalidEmail: 'Неправильный адрес электронной почты',
    invalidPhone: 'Неправильный номер телефона \n' +
        '+38 YYY XXX XX XX',
    enterPassword: 'Введите пароль',
    differentPasswords: 'Пароли не совпадают',
    tooLongPassword: 'Пароль слишком длинный (макс. количество символов: 20)',
    tooShortPassword: 'Пароль слишком короткий (мин. количество символов: 8)',
    passwordChanged: 'Пароль изменен',
    enterOldPassword: 'Введите старый пароль',
    enterNewPassword: 'Введите новый пароль',
    samePasswords: 'Старый и новый пароль не могут быть идентичными',
    copied: 'Скопировано',
    copy: 'Копировать',

    // profile
    save: 'Сохранить',
    confirmation: 'Подтвердить',
    saveAs: 'Сохранить как',
    cancel: 'Отмена',
    changingEmail: 'Смена Email',
    changeEmail: 'Сменить Email',
    changingPhone: 'Смена телефона',
    changePhone: 'Сменить телефон',
    changingPassword: 'Смена пароля',
    changePassword: 'Сменить пароль',
    confirmCode: 'Код подтверждения',
    phoneConfirmed: 'Телефон подтвержден',
    oldPassword: 'Старый пароль',
    newPassword: 'Новый пароль',
    confirmPassword: 'Подтвердите пароль',
    connectWithUs: 'Свяжитесь с нами',
    profile: 'Профиль',
    deposit: 'Депозит',
    withdraw: 'Снятие',
    support: 'Техподдержка',
    date: 'Дата',
    type: 'Тип',
    amount: 'Сумма',
    status: 'Статус',
    transactionsHistory: 'История транзакций',
    exchange: 'Обмен валюты',
    noData: 'нет данных',
    transactionsBonus: 'История сообщений',
    message: 'Сообщение',
    ok: 'Ok',

    currencySelect: 'Валюта',
    paymentSelect: 'Выбор способа оплаты',
    amountDepositSelect: 'Сумма пополнения',
    finishOperation: 'Завершение операции',

    enterCurrency: 'Выберите валюту',
    enterCardNumber: 'Введите номер карты',
    cardNumber: 'Номер карты',
    paymentAccept: 'Подтверждение платежа',
    payoutAmount: 'Сумма выплаты',
    paymentType: 'Способ оплаты',
    enterDepositWay: 'Выберите способ оплаты',
    enterDepositAmount: 'Введите сумму для пополнения',
    enterWithdrawalWay: 'Выберите способ снятия',
    enterPayoutAmount: 'Введите сумму выплаты',
    successfulPayment: 'Платеж успешно добавлен в обработку',
    successOperation: 'Оплата прошла успешно',
    errorOperation: 'Ошибка платежа',
    confirm: 'Принять',
    country: 'Страна',
    currency: 'Валюта',
    invalidCountry: 'Неправильно выбранная страна',
    invalidCurrency: 'Неправильно выбранная валюта',
    successfullyChangedTo: 'Успешно изменено на',
    lowBalance: 'Недостаточно средств на балансе',

    accept: 'Принять',
    decline: 'Отменить',
    currencyExchange: 'Обмен валюты',
    currentBalance: 'Текущий баланс',
    from: 'с',
    to: 'на',
    exchangePercent: 'Процент за обмен',
    newBalance: 'Новый баланс',
    coefficient: 'Коэфф.',
    currencyConversion: 'Обмен валюты',
    wrongInputData: 'Неправильные входные данные',
    wrongAmountData: 'Неправильная сумма',
    wrongCreditCard: 'Неверный номер кредитной карты',
    noRedirectReff: 'Нет ссылки на перенаправление',
    deserializationIssue: 'Проблема с десериализацией',
    currencyPairNotExist: 'Валютная пара не существует',
    authorizationMandatory: 'Авторизация обязательна',
    birthdayFormat: 'День рождения должен быть ДД ММ ГГГГ',
    tooShort: 'Неправильный номер карты',
    infoPayment: 'Обработка первой выплаты за 24 часа проводится бесплатно. За каждую последующую выплату будет взиматься комиссия.',
    time: 'Время',
    period: 'До 3 дней',
    image: 'Изображение',
    text: 'Текст',

    // status
    balance: 'Баланс',
    insurance: 'Страховка',
    credits: 'кредитов',
    creditsWon: 'кредитов выиграно',
    winnerPaid: 'Оплата выигрыша',
    gotNoMoney: 'Не хватает денег',
    minBet: 'Минимальная общая ставка',
    gambleUp: 'Приумножить или забрать выигрыш',
    gambleUp5x: 'Приумножить до 5x или забрать выигрыш',
    gambleEnd: 'Попытка окончена',
    pressAnyButton: 'Нажмите старт для продолжения',
    freeGame: 'Бесплатная игра',
    of: 'с',
    freeSpinsRemaining: 'БЕСПЛАТНЫХ ПРОКРУТОВ ',
    freeGames: 'бесплатных игр',
    more: 'дополнительных ',
    freeSpinsLeft: 'БЕСПЛАТНЫХ ПРОКРУТОВ ',
    additionalRoll: 'Дополнительный прокрут',
    selectCard: 'Выберите карту',
    selectColor: 'Выберите красную или чёрную',
    selectColorOrSuit: 'Выберите цвет (x2) или масть (x4)',
    gameOverPlaceYourBet: 'Пожалуйста сделайте ставку',
    auto: 'Авто',
    linePays: 'Линия',
    scatterPays: 'За скаттер',
    win: 'Выигрыш',
    line: 'Линия',
    youWon: 'Вы выиграли',
    lowMinBet: 'Минимальная ставка на ',
    highMaxBet: 'Максимальная ставка на ',
    unSupport: 'Эта игра не поддерживается на вашем устройстве',
    urlNotFound: 'Страница не найдена',
    categories: 'Категории',
    chooseSide: 'Выберите сторону',
    makeChoice: 'Сделайте выбор',
    multiplier: 'Множитель',

    // buttons
    lines: 'Линии',
    bet: 'Ставка',
    total: 'Вместе',
    denom: 'Деном',
    gamble: 'Риск',
    paytable: 'Информация',
    bonus: 'Бонус',
    start: 'Старт',
    stop: 'Стоп',
    autoplay: 'Автоигра',
    select: 'Выбор',
    collect: 'Взять',
    red: 'Красная',
    black: 'Чёрная',
    return: 'Вернуться',
    half: 'Половина',
    payout: 'Выплата',
    swipeToStart: 'Проведите пальцем вверх, чтобы начать',
    tapToStart: 'Нажмите, чтобы начать',

    // roulette buttons
    repeatAndStart: 'Повторить ставку и вращать',
    double: 'Удвоить',
    doubleAndSpin: 'Удвоить и вращать',
    undo: 'Отменить',
    repeatBet: 'Повторная ставка',
    removeBets: 'Убрать ставки',
    results: 'Результаты',
    cover: 'Покрытие',

    // gamble
    gambleAmount: 'Выигрыш',
    gambleToWin: 'Рискни для выигрыша',
    gambleAmountAmatic: 'Сумма риска',
    gambleToWinAmatic: 'Рискнуть красная/черная',
    suitGambleToWinAmatic: 'Выбор масти',
    previousCards: 'Предыдущие карты',
    gambleAttemptsLeft: 'Осталось попыток',
    history: 'История',

    // wrap
    selectLine: 'Количество линий',
    selectBet: 'Ставка на линию',
    selectDenomination: 'Деноминация',

    // info
    rules: 'Правила',
    substitutesForAllSymbols: 'Заменяет все символы кроме скаттеров',
    combinationOfKind: 'Выигрыш учитывает все выигрышные комбинации. ',
    combinationLeftToRight: 'Выигрышные комбинации формируются слева направо, за исключением скаттеров. ',
    prizesOnSelectedLines: 'Выигрыши засчитываются за комбинации на выбранных линиях, за исключением скаттеров.  ',
    ScatterPayAtAnyPosition: 'Символы скаттера играют на всех позициях на экране. ',
    HighestWinPaid: 'На выбранной линии засчитывается наибольший из возможных выигрышей. ',
    ScatterWinsAddedToLineWins: 'Выигрыш за скаттеры прибавляется к выигрышам за линии. ',
    prizesShownInCredits: 'Все выигрыши показываются в кредитах. ',
    MalfunctionVoidsAllPays: 'Неисправность аннулирует все выигрыши. ',
    onTheScreen: 'дают',
    bonusGame: 'БОНУСНУЮ ИГРУ',
    bonusGameGive: 'Количество бесплатных прокрутов',
    freeSpins: 'определяется рулеткой',
    featureBonus: 'В бонусных прокрутах первый барабан заполнен',
    goodLuck: 'Желаем удачи!',

    // instruction IOS
    howToInstall: 'как установить приложение',
    pressTheButton: 'Нажмите  кнопку \'share\'',
    goToHomeScreen: 'Нажмите ',
    confirmInstallation: 'Подтвердите установку',

    // Rules
    payRules: 'Правила игры',
    payRulesText: '1. Принимать участие в игре может особа возраст которой достиг 18 лет; \n' +
        '2. Min. депозит для участия в игре 100 UAH; \n' +
        '3. Min. минимальная сума  вывода  300 UAH, при чем вывод осуществляется только на карту, с которой \n' +
        'произходило пополнение;\n' +
        '4. При пополнении в терминалах самообслуживания, или другим способом, вывод проиcходит только на одну \n' +
        'закрепленную за вами карту; \n' +
        '5. Каждый игрок может пользоваться только одним зарегистрированным акаунтом. При выявление дублирующего \n' +
        'акаунта, последний будет заблокирован, вместе с остатком средств на нем; \n' +
        '6. При подозрении на сомнительные операции, администрация может требовать верификацию личности;\n' +
        '7. Администрация оставляет за собой право, вносить изменения и дополнения в данные правила.\n',

    // bonuses
    congratulation: 'Вітаємо',
    registration_bonus: {
        title: 'Бонус за регистрацию!',
        description: {
            punkt1: '- Чтобы активировать бонус необходимо подтвердить почту и номер телефона.',
            punkt2: '- Внести депозит на основной счет.',
            punkt3: '- Для зачисления бонуса на основной счет нужно сделать ставок на суму в 15 раз больше от суми бонуса.'
        }
    },

    week_bonus: {
        title: 'Недельный бонус!',
        description: 'Ваш недельный бонус',
        accept: 'Ваш недельный бонус зачислен на основной счет!'
    }
};
