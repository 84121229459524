import React from 'react';

import './styles.less';
import App from './../../index';

export default function Prizes(props) {
    const {appSettings} = window;
    const {lang, buttons: {animation}, moneyParams: {jackpot, showWinFeatures}, legends: {features, win}} = props;

    const getLayout = obj => Object.keys(obj).map((winType, index) => {
        const {text, value, number} = obj[winType];
        const lineNumber = number === '' || undefined ? '' : ` ${number + 1}`;
        let title = lang[text] || winType; // feature text or jackpot
        title = `${title}${text === 'linePays' ? lineNumber : ''}`; // add line number

        return <div className={`win-panel-${winType}`} key={index}>
            {text && <>
                <span className={`win-panel-${winType}-title ${text}`}>{`${title.toUpperCase()}:`}</span>
                <span className={`win-panel-${winType}-value`}>{value}</span>
            </>}
        </div>;
    });

    const onAnimationEnd = () =>
        App.updateState('legends', {winPanelAnimation: false}); // disable animation

    return (
        <div
            className={`win-panel red-color red-shadow ${animation}`}
            onAnimationEnd={onAnimationEnd}
            style={{background: `url(${App.getUrl('/img/jackpot-bg.png')}) 0 0/ 100% no-repeat`}}
        >
            {getLayout(showWinFeatures || (appSettings.mode === 'site' || appSettings.mode === 'demo') ? {  // disable jackpot info for site and demo
                features, win
            } : jackpot)}
        </div>
    );
}
