import {JL} from 'jsnlog';

import CustomLines from './../CustomLines';

export default class Lines5 extends CustomLines {
    constructor() {
        super();
        this.imageResources = {
            boxes: '/img/lines/Lines10/amatic/boxes.png'
        };

        this.lines = {
            0: {coordinates: [1, 1, 1, 1, 1], boxes: [{x: 0, y: 248}, {x: 765, y: 248}], color: '#ff0100'},
            1: {coordinates: [0, 0, 0, 0, 0], boxes: [{x: 0, y: 139}, {x: 765, y: 139}], color: '#2900ff'},
            2: {coordinates: [2, 2, 2, 2, 2], boxes: [{x: 0, y: 375}, {x: 765, y: 375}], color: '#2bd900'},
            3: {coordinates: [0, 1, 2, 1, 0], boxes: [{x: 0, y: 103}, {x: 765, y: 103}], color: '#e8e300'},
            4: {coordinates: [2, 1, 0, 1, 2], boxes: [{x: 0, y: 410}, {x: 765, y: 410}], color: '#9c00e5'}
        };

        JL().debug('-- Lines5 initialized');
    }
}
