import {JL} from 'jsnlog';
import App from './../index';

let connectionTimeout = null; // check lost connection (without messages)
const reconnectTimeout = 10000; // 10 sec

export default class NodeSocket {
    stopRestoring = false;

    /**
     * Create WebSocket
     * Add events: open, close, message, error
     * @param restored - was server reconnection
     */
    init(restored = false) {
        const url = this.getUrl();
        JL().debug(`-- Init NodeSocket (${url})`);
        this.nodeSocket = new WebSocket(url);

        /**
         * WebSocket open handler
         * Start check connection
         * Clear all errors if restored
         */
        this.nodeSocket.addEventListener('open', () => {
            JL().debug(`-- NodeSocket opened (restored: ${restored})`);
            clearTimeout(this.connectionRestoreTimeout);
            this.checkConnection();
        });

        /**
         * WebSocket close handler
         * Show error message
         * Reset Game settings
         * Try to restore connection
         */
        this.nodeSocket.addEventListener('close', event => {
            this.nodeSocket = null;
            clearTimeout(connectionTimeout);
            // const message = event.wasClean ? 'connectionLost' : 'nodeServerNotResponded';
            JL().debug(`-- Node Socket close: ${event.code}`);

            if (!this.stopRestoring) { // don't restore after {uc:'ERROR'} message
                this.connectionRestoreTimeout = setTimeout(() =>
                    this.init(true), reconnectTimeout);
            }
        });

        /**
         * WebSocket message handler
         * Start check connection
         * Parse and collect response data
         */
        this.nodeSocket.addEventListener('message', event => {
            this.checkConnection();
            const response = JSON.parse(event.data);
            switch (response.uc) {
                case 'PING':
                    this.sendPing(response.id);
                    break;
            }
        });

        /**
         * WebSocket error handler
         */
        this.nodeSocket.addEventListener('error', () => {
            JL().debug(`-- NodeSocket error--`);
        });
    }

    /**
     * Create WebSocket url
     * @returns {string}
     */
    getUrl = () => {
        const {SERVER_URL, NODE_ENV} = App.configs;
        const protocol = NODE_ENV === 'development' ? 'ws' : 'wss';

        return `${protocol}://${SERVER_URL}/metrics`;
    };

    /**
     * Collect and send all client data
     * @param id
     */
    sendPing = id => {
        const {os, browser, browserVersion, platform, ip, city, videoDevice} = App.System;
        this.nodeSocket?.send(JSON.stringify({
            'uc': 'PING', id,
            'state': App.Game ? App.Game.getState() : 'MENU',
            'gameid': App.Game ? App.Game.id : 'MENU',
            ip, city,
            os, videoDevice, platform,
            'browser': `${browser}-${browserVersion}`,
            'language': App.settings.currentLanguage,
            'soundOn': App.Sounds.volume ? 'on' : 'off',
            'resolution': App.System.getResolution(),
            'orientation': App.Wrapper.getOrientation(),
            'fullScreen': document.fullscreenElement ? 'on' : 'off',
            'screen-size': `${window.screen.width}x${window.screen.height}`,
            ...(App.System.isPWAInstalled() ?
                {'pwa.os': App.System.os.replace(/\s+/gi, '')} :
                {})
        }));
    };

    /**
     * Start connection timeout
     * Close WebSocket if connection lost
     */
    checkConnection() {
        clearTimeout(connectionTimeout);
        connectionTimeout = setTimeout(() => {
            JL().debug('-- NodeSocket not response, connection time left, closing...');
            // this.nodeSocket.close();
        }, reconnectTimeout);
    }
}
