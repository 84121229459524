import Lines30 from './../../egt/lines30';

export default class Lines extends Lines30 {
    setBoxesStyle = () =>
        ({
            activeBox: {
                fill: '#ffe53c',
                fontSize: 12,
                stroke: '#b627ac',
                strokeThickness: 5,
                lineJoin: 'round'
            },
            inActiveBox: {
                dropShadowDistance: 1,
                fill: 'white',
                fontSize: 19,
                strokeThickness: 2,
                lineJoin: 'round'
            },
            colLines: [1, 5, 10, 20, 30],
            coordinates: {
                0: {x: 25, y: 408},
                1: {x: 774, y: 408}
            }
        })
}
