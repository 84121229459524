import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import GameDeluxe from './../../deluxe/game';
import Lines20 from './../../deluxe/lines20';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';

/* PIXI aliases */
const AnimatedSprite = PIXI.AnimatedSprite;

export default class SizzlingHotDeluxe20 extends GameDeluxe {
    constructor() {
        super();
        this.id = 'sizzling-hot-deluxe-20';
        this.name = 'Sizzling Hot Deluxe 20';
        this.buttonsPanelShadow = 'mid';

        this.symbols = [
            {regularDelay: 100, skipSteps: 4, payment: [0, 0, 5, 20, 50, 200]},     // 0 - cherry
            {regularDelay: 100, skipSteps: 4, payment: [0, 0, 0, 20, 50, 200]},     // 1 - lemon
            {regularDelay: 100, skipSteps: 4, payment: [0, 0, 0, 20, 50, 200]},     // 2 - orange
            {regularDelay: 100, skipSteps: 4, payment: [0, 0, 0, 20, 50, 200]},     // 3 - plum
            {regularDelay: 100, skipSteps: 4, payment: [0, 0, 0, 50, 200, 500]},    // 4 - grape
            {regularDelay: 100, skipSteps: 4, payment: [0, 0, 0, 50, 200, 500]},    // 5 - watermelon
            {regularDelay: 100, skipSteps: 4, payment: [0, 0, 0, 100, 1000, 5000]}, // 6 - number '7'
            {regularDelay: 100, skipSteps: 4, payment: [0, 0, 0, 2, 10, 50]}        // 7 - star
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                logo: 'area/logo.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {atlas: this.mergePath(['regularLongSymbols.json'])};

        this.gameSounds = {soundClass: 'deluxe'};
        this.Lines = new Lines20();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png'
        }));
        this.Gamble.screenCoordinates = {x: 0, y: 87}; // coordinates for gambleArea
        this.InfoScreen = new InfoScreen({pages: 2}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '17pt Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#fff';

        const textProps = {
            fontHeight: 20,
            gradientColor: {from: '#f89800', to: '#f8e800'}
        };
        ctx.font = `bold ${textProps.fontHeight}pt Arial Black`;
        ctx.lineWidth = 4;

        switch (page) {
            case 1:
                // watermelon
                this.drawGradientFont(ctx, bet * this.symbols[5].payment[5], 131, 135, textProps);
                this.drawGradientFont(ctx, bet * this.symbols[5].payment[4], 131, 165, textProps);
                this.drawGradientFont(ctx, bet * this.symbols[5].payment[3], 131, 194, textProps);
                // grape
                this.drawGradientFont(ctx, bet * this.symbols[4].payment[5], 676, 135, textProps);
                this.drawGradientFont(ctx, bet * this.symbols[4].payment[4], 676, 165, textProps);
                this.drawGradientFont(ctx, bet * this.symbols[4].payment[3], 676, 194, textProps);
                // 7
                this.drawGradientFont(ctx, bet * this.symbols[6].payment[5], 405, 310, textProps);
                this.drawGradientFont(ctx, bet * this.symbols[6].payment[4], 405, 346, textProps);
                this.drawGradientFont(ctx, bet * this.symbols[6].payment[3], 405, 381, textProps);
                // plum
                this.drawGradientFont(ctx, bet * this.symbols[3].payment[5], 96, 310, textProps);
                this.drawGradientFont(ctx, bet * this.symbols[3].payment[4], 96, 340, textProps);
                this.drawGradientFont(ctx, bet * this.symbols[3].payment[3], 96, 368, textProps);
                // orange
                this.drawGradientFont(ctx, bet * this.symbols[2].payment[5], 730, 310, textProps);
                this.drawGradientFont(ctx, bet * this.symbols[2].payment[4], 730, 340, textProps);
                this.drawGradientFont(ctx, bet * this.symbols[2].payment[3], 730, 368, textProps);
                // chery
                this.drawGradientFont(ctx, bet * this.symbols[0].payment[5], 720, 477, textProps);
                this.drawGradientFont(ctx, bet * this.symbols[0].payment[4], 720, 505, textProps);
                this.drawGradientFont(ctx, bet * this.symbols[0].payment[3], 720, 531, textProps);
                this.drawGradientFont(ctx, bet * this.symbols[0].payment[2], 720, 557, textProps);
                // Lemon
                this.drawGradientFont(ctx, bet * this.symbols[1].payment[5], 120, 493, textProps);
                this.drawGradientFont(ctx, bet * this.symbols[1].payment[4], 120, 523, textProps);
                this.drawGradientFont(ctx, bet * this.symbols[1].payment[3], 120, 553, textProps);
                // star
                this.drawGradientFont(ctx, bet * this.symbols[7].payment[5] * nLines, 457, 510, textProps);
                this.drawGradientFont(ctx, bet * this.symbols[7].payment[4] * nLines, 457, 541, textProps);
                this.drawGradientFont(ctx, bet * this.symbols[7].payment[3] * nLines, 457, 571, textProps);
                break;
            case 2:
                ctx.font = 'bold 16pt Arial Black';
                this.drawGradientFont(ctx, '--- RULES ---', 400, 235, textProps);
                this.drawGradientFont(ctx, 'All prizes are for combinations of a kind. All prizes are', 400, 275, textProps);
                this.drawGradientFont(ctx, 'for combinations left to right, except scatters. All', 400, 305, textProps);
                this.drawGradientFont(ctx, 'prizes are on selected lines, except scatters. Scatter', 400, 335, textProps);
                this.drawGradientFont(ctx, 'symbols pay at any position on screen. Highest win', 400, 365, textProps);
                this.drawGradientFont(ctx, 'only paid per selected line. Scatter wins are added to', 400, 395, textProps);
                this.drawGradientFont(ctx, 'line wins. All prizes shown in credits. Malfunction', 400, 425, textProps);
                this.drawGradientFont(ctx, 'voids all pays and plays.', 400, 455, textProps);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    addWaitingAnimationSprites(parentContainer) {
        const sprite = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 6, image: 'logo',
            width: 374, height: 80,
            colCount: 1
        }));
        sprite.name = 'animatedLogo';
        sprite.position.set(213, 0);
        sprite.animationSpeed = 0.15;
        sprite.play();
        parentContainer.addChild(sprite);
    }
}
