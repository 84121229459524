import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../../../index';
import BookGame from './../../deluxe/bookGame';
import Lines40 from './../../deluxe/lines40';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';
import bonusFont from './../age-of-pharaohs/img/font/bonusFont';
import fontBonus from './../age-of-pharaohs/img/font/fontBonus';

/* PIXI aliases */
const Texture = PIXI.Texture,
    Sprite = PIXI.Sprite,
    AnimatedSprite = PIXI.AnimatedSprite,
    Text = PIXI.Text;

export default class AgeOfPharaohs extends BookGame {
    constructor() {
        super();
        this.id = 'age-of-pharaohs';
        this.name = 'Age Of Pharaohs';
        this.scatter = 11;
        this.buttonsPanelShadow = 'mid';

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 95, y: 109},
            bonusInBonusFrame: {x: 95, y: 109},
            endBonusFrame: {x: 95, y: 109}
        };

        this.reels = 5;
        this.reelRows = 4; // number of rows per reel

        // reel properties
        this.symbolHeight = 98; // height of aa single symbol
        this.symbolWidth = 130; // height of aa single symbol

        // reel properties
        this.reelTop = 87; // magic number - where reel images starts
        this.reelXCoordinates = [51, 193, 335, 477, 619]; // magic numbers - x coordinates where reels starts
        this.transparentBackground = false; // use transparent symbols fone or not
        this.symbolEffects = true; // shadowing and none
        this.reelFilter = [[], [], [], [], []];

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 0, 4, 16, 100]},    // 0 - цифра 9
            {regularDelay: 100, payment: [0, 0, 0, 4, 16, 100]},    // 1 - буква 10
            {regularDelay: 100, payment: [0, 0, 0, 8, 20, 120]},    // 2 - буква J
            {regularDelay: 100, payment: [0, 0, 0, 8, 20, 120]},    // 3 - буква Q
            {regularDelay: 100, payment: [0, 0, 0, 12, 40, 160]},   // 4 - буква K
            {regularDelay: 100, payment: [0, 0, 0, 12, 40, 160]},   // 5 - буква A
            {regularDelay: 100, payment: [0, 0, 4, 20, 60, 240]},   // 6 - корабль
            {regularDelay: 100, payment: [0, 0, 4, 20, 60, 240]},   // 7 - колесница
            {regularDelay: 100, payment: [0, 0, 4, 20, 80, 400]},   // 8 - клеопатра
            {regularDelay: 100, payment: [0, 0, 4, 40, 200, 1000]}, // 9 - фараон
            {regularDelay: 100, payment: [0, 0, 0, 0, 0, 0]},       // 10 - wild
            {regularDelay: 100, payment: [0, 0, 0, 2, 20, 200]}     // 11 - scatter
        ];
        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                bonusArea: 'area/bonus.png',
                frame: 'bonus/frame.png',
                book: 'bonus/book.png',
                symbolBook: 'bonus/symbol-book.png',
                bonusSpin: 'bonus/bonusSpin.png',
                bonusFont: bonusFont['imageResource'],
                fontBonus: fontBonus['imageResource']
            }),
            atlas: this.mergePath([
                'regularLongSymbols.json',
                'bonus/bonusSymbols.json',
                'bonus/scatterSymbols.json',
                'bonus/additionalSymbols.json'
            ])
        };

        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'bonus-game-end', alias: 'bonusGameEnd'},
                {name: 'book-open', alias: 'bookOpen'},
                {name: 'book-select-symbol', alias: 'bookSelectSymbol'},
                {name: 'book-step-up', alias: 'bookStepUp'},
                {name: 'bonus-win-symbols-show', alias: 'bonusWinSymbols'},
                {name: 'bonus-win-lines-show', alias: 'bonusWinLines'},
                {name: 'reels-down', alias: 'bonusSpin'},
                {name: 'bonus-in-bonus-sound', alias: 'bonusInBonusSound'},
                {name: 'bonus-background', loop: true}
            ],
            path: `/game/games/${this.id}/audio/`
        };

        this.Lines = new Lines40();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png',
            activeRed: 'gamble/red-active.png',
            inactiveRed: 'gamble/red-inactive.png',
            activeBlack: 'gamble/black-active.png',
            inactiveBlack: 'gamble/black-inactive.png'
        }));
        this.Gamble.gambleButtonsSize = {
            width: '17em',
            height: '12.35em',
            borderRadius: '0',
            bottom: '12em',
            left: '4em',
            right: '4em'
        };

        this.InfoScreen = new InfoScreen({pages: 5}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '15pt Times New Roman';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#f2f7e9';
        ctx.shadowColor = 'black';
        ctx.shadowOffsetX = 2;
        ctx.strokeStyle = '#000';
        ctx.shadowBlur = 3;

        switch (page) {
            case 1:
                // scatter
                this.strokeFillText(ctx, bet * nLines * this.symbols[11].payment[5], 450, 110);
                this.strokeFillText(ctx, bet * nLines * this.symbols[11].payment[4], 450, 135);
                this.strokeFillText(ctx, bet * nLines * this.symbols[11].payment[3], 450, 160);
                // pharaoh
                this.strokeFillText(ctx, bet * this.symbols[9].payment[5], 175, 386);
                this.strokeFillText(ctx, bet * this.symbols[9].payment[4], 175, 410);
                this.strokeFillText(ctx, bet * this.symbols[9].payment[3], 175, 436);
                // kleopatra
                this.strokeFillText(ctx, bet * this.symbols[8].payment[5], 450, 386);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[4], 450, 410);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[3], 450, 436);
                // ship
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 630, 386);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 630, 410);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 630, 436);
                // AK
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 175, 509);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 175, 535);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 175, 561);
                // QJ10
                this.strokeFillText(ctx, bet * this.symbols[3].payment[5], 450, 509);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[4], 450, 535);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[3], 450, 561);
                // 9/10
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 630, 509);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 630, 535);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 630, 561);
                break;
            case 2:
                // scatter
                ctx.fillText(bet * nLines * this.symbols[11].payment[5], 450, 250);
                ctx.fillText(bet * nLines * this.symbols[11].payment[4], 450, 270);
                ctx.fillText(bet * nLines * this.symbols[11].payment[3], 450, 290);
                // pharaoh
                ctx.fillText(bet * this.symbols[9].payment[5], 175, 379);
                ctx.fillText(bet * this.symbols[9].payment[4], 175, 401);
                ctx.fillText(bet * this.symbols[9].payment[3], 175, 421);
                ctx.fillText(bet * this.symbols[9].payment[2], 175, 441);
                // Kleopatra
                ctx.fillText(bet * this.symbols[8].payment[5], 450, 379);
                ctx.fillText(bet * this.symbols[8].payment[4], 450, 401);
                ctx.fillText(bet * this.symbols[8].payment[3], 450, 421);
                ctx.fillText(bet * this.symbols[8].payment[2], 450, 441);
                // ship, horse
                ctx.fillText(bet * this.symbols[6].payment[5], 630, 379);
                ctx.fillText(bet * this.symbols[6].payment[4], 630, 401);
                ctx.fillText(bet * this.symbols[6].payment[3], 630, 421);
                ctx.fillText(bet * this.symbols[6].payment[2], 630, 441);
                // AK
                ctx.fillText(bet * this.symbols[4].payment[5], 175, 477);
                ctx.fillText(bet * this.symbols[4].payment[4], 175, 505);
                ctx.fillText(bet * this.symbols[4].payment[3], 175, 533);
                // QJ
                ctx.fillText(bet * this.symbols[3].payment[5], 450, 477);
                ctx.fillText(bet * this.symbols[3].payment[4], 450, 505);
                ctx.fillText(bet * this.symbols[3].payment[3], 450, 533);
                // 9,10
                ctx.fillText(bet * this.symbols[0].payment[5], 630, 477);
                ctx.fillText(bet * this.symbols[0].payment[4], 630, 505);
                ctx.fillText(bet * this.symbols[0].payment[3], 630, 533);
                break;
            case 4:
                ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
                ctx.font = '20pt Times New Roman';
                ctx.textAlign = 'center';
                ctx.fillStyle = '#f70812';
                ctx.strokeStyle = '#ecf798';
                ctx.lineWidth = 2;
                this.strokeFillText(ctx, 'One special expanding symbol is randomly selected at', 400, 260);
                this.strokeFillText(ctx, 'the start of Free Games', 400, 286);
                this.strokeFillText(ctx, 'During Free Games the special symbol expands to cover 4', 400, 326);
                this.strokeFillText(ctx, 'positions on the reel and pays in any positions on lines', 400, 357);
                this.strokeFillText(ctx, 'played', 400, 383);
                break;
            case 5:
                ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
                ctx.font = '19pt Times New Roman';
                ctx.textAlign = 'center';
                ctx.fillStyle = '#f70812';
                ctx.strokeStyle = '#ecf798';
                ctx.lineWidth = 1;
                this.strokeFillText(ctx, 'All prizes are for combinations of a kind. All prizes are for', 400, 250);
                this.strokeFillText(ctx, 'combinations left to right, except scatters. All prizes are on selected', 400, 276);
                this.strokeFillText(ctx, 'lines, except scatters. Scatter symbols pay at any position on screen.', 400, 302);
                this.strokeFillText(ctx, 'Highest win only paid per selected line. Scatter wins are added to line', 400, 328);
                this.strokeFillText(ctx, 'wins. Free Games can be won again during the Free Games. Free Games', 400, 354);
                this.strokeFillText(ctx, 'are played at trigger bet and lines. All prizes shown in credits.', 400, 382);
                this.strokeFillText(ctx, 'Malfunction voids all pays and plays.', 400, 4102);
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    /**
     * Отрисовка таблички бонусной игры
     */
    showStartBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y);

        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 0.9,
            fontInterval: 2 // px between symbols
        };

        this.drawCustomFont('10 FREE GAMES', 400, 215, textProps);
        this.drawCustomFont('with', 400, 255, textProps);
        this.drawCustomFont('SPECIAL EXPANDING', 400, 295, textProps);
        this.drawCustomFont('SYMBOL', 400, 335, textProps);
    }

    /**
     * Отрисовка таблички бонус в бонусе
     */
    showBonusInBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y);
        App.Sounds.stopAllSounds();
        App.Sounds.playSound('bonusInBonusSound');

        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 0.9,
            fontInterval: 2 // px between symbols
        };
        this.drawCustomFont('10 MORE FREE GAMES', 400, 255, textProps);
    }

    /**
     * Отрисовка таблички окончания бонусной игры
     */
    showEndBonusFrame(parentContainer, {x, y}, {win}) {
        this.showBonusFrame(parentContainer, x, y);

        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 0.9,
            fontInterval: 2 // px between symbols
        };
        this.drawCustomFont('FEATURE     WIN', 400, 255, textProps);

        const textProps2 = {
            parentContainer,
            fontImageName: 'fontBonus',
            map: fontBonus,
            align: 'center',
            scale: 1.3,
            fontInterval: -3 // px between symbols
        };
        this.drawCustomFont(`${win} CREDITS`, 400, 295, textProps2);
    }

    /**
     * Prepare game behaviour after bonus 'press any button' message
     */
    startBonusAnimation = parentContainer => {
        this.clearPressAnyButton();
        this.Buttons.disableAllButtons();
        App.updateButton('start', {disabled: true});
        this.gameFlag.bonusStarted = true;

        const bonusSymbol = this.bonusRollSymbol;
        ['book_base', `book_${bonusSymbol}`].forEach(key => {
            !this.Loader.resources[key] && this.resourcesSettings.total++ &&
            this.Loader.add(key, App.getUrl(this.mergePath({img: `bonus/${key}.png`}).img));
        });

        this.Loader.load(() => this.drawBonusAnimation(parentContainer, bonusSymbol));
    };

    /**
     * Draw all bonus animation after bonus 'press any button'
     * @param parentContainer
     * @param bonusSymbol - current bonus symbol in extension
     */
    drawBonusAnimation(parentContainer, bonusSymbol) {
        JL().debug(`-- Draw bonus enter animation. Bonus symbol: ${bonusSymbol}`);
        const props = {
            toFrame: 39,
            width: 588,
            height: 324,
            colCount: 6
        };
        App.Sounds.playSound('bookOpen');
        const sprite = new AnimatedSprite([
            ...this.getSpriteTextures({...props, image: 'book_base'}),
            ...this.getSpriteTextures({...props, image: `book_${bonusSymbol}`, toFrame: 49})
        ]);
        sprite.position.set(106, 118);
        sprite.animationSpeed = 0.15;
        sprite.loop = false;
        sprite.play();
        sprite.onComplete = () => {
            this.bonusRoll();
            this.drawBook(parentContainer);
        };
        parentContainer.addChild(sprite);
    }

    drawBook(parentContainer) {
        const sprite = new Sprite(this.getTexture('book'));
        sprite.name = 'bookTop';
        sprite.position.set(300, 0);
        parentContainer.addChild(sprite);
        this.drawPaymentsOnBook(parentContainer, 440, 47);
        this.drawBonusSymbol(parentContainer, this.bonusRollSymbol);
    }

    drawPaymentsOnBook(bookContainer, x = 135, y = 60) {
        let i = 0;
        const payTable = [];
        const bet = this.gameSettings.getBetLineCredit();
        this.symbols[this.bonusRollSymbol].payment.forEach((pay, key) => {
            pay !== 0 && payTable.push([key, pay]);
        });
        payTable.forEach(pay => {
            const textPayments = new Text(`${pay[0]} - ${bet * pay[1]}`, {
                align: 'left',
                fontFamily: 'Franklin Gothic Medium',
                fontSize: 16,
                fill: '#e8d42b'
            });
            textPayments.x = x;
            textPayments.y = this.symbols[this.bonusRollSymbol].payment[2] === 0 ?
                y - 20 * i :
                y - 15 * i;
            i++;
            bookContainer.addChild(textPayments);
        });
    }

    drawBonusSymbol(parentContainer, bonusSymbol) {
        const sprite = new AnimatedSprite(
            [...Array(bonusSymbol + 1)].map((item, index) => {
                const width = 90;
                const height = 69;
                return new Texture(this.getTexture('symbolBook'), {
                    x: index * width,
                    y: 0,
                    width: width,
                    height: height
                });
            })
        );
        sprite.name = 'bonusSymbol';
        sprite.scale.set(0.8);
        sprite.position.set(319, 5);
        sprite.play();
        sprite.gotoAndStop(bonusSymbol);
        parentContainer.addChild(sprite);
    }

    /**
     *  Section for special bonus roll function
     */
    prepareToRollAnimation(response) {
        this.Buttons.disableAllButtons();
        App.updateButton('start', {
            disabled: false,
            title: 'stop',
            handler: () => { // change handler to stop reels animation
                JL().debug('-- Stop roll');
                this.Roll.stopReels = [1, 1, 1, 1, 1, 1];
                App.updateButton('start', {disabled: true});
            }
        });
        this.extraBet && this.updateExtraBetButtons(false);
        this.gameFlag.bonusStart || App.restoreGameState === 'BONUS' ?
            this.bonusReelAnimation(response) : // custom reel animation
            this.Roll.startReelAnimation(response); // primary roll
    }

    /**
     * Special bonus roll animations
     * @param response
     */
    bonusReelAnimation(response) {
        const parentContainer = this.getStageChild('bonusContainer');
        App.updateButton('start', {disabled: true});
        this.createReelMatrix(this.getStageChild('reelsStage'), response.screen);
        const arrayOfSprites = this.createBonusSpinSprites(parentContainer);

        for (let i = 0; i < this.reels; i++) {
            arrayOfSprites[i].onComplete = () => {
                parentContainer.removeChild(arrayOfSprites[i]);
                if (i === arrayOfSprites.length - 1) {
                    App.Socket.webSocket && this.onRotationDone();
                } else {
                    App.Sounds.playSound('bonusSpin');
                    arrayOfSprites[i + 1].play();
                }
            };
        }

        App.Sounds.playSound('bonusSpin');
        arrayOfSprites[0].play();

        this.lastScreen = response.screen;
    }

    createBonusSpinSprites(parentContainer) {
        const frames = 11;

        return [...Array(this.reels)].map((item, index) => {
            const sprite = new AnimatedSprite(this.getSpriteTextures({
                width: this.symbolWidth,
                height: 392,
                image: 'bonusSpin',
                colCount: frames,
                toFrame: frames

            }));

            sprite.animationSpeed = 0.2;
            sprite.loop = false;
            sprite.position.set(this.reelXCoordinates[index], this.reelTop);
            parentContainer.addChild(sprite);
            return sprite;
        });
    }
}
