import {JL} from 'jsnlog';
import * as PIXI from 'pixi.js-legacy';

import CustomLines from './../CustomLines';

import App from './../../index';

// PIXI aliases
const Sprite = PIXI.Sprite,
    Texture = PIXI.Texture,
    Text = PIXI.Text;

export default class Lines27 extends CustomLines {
    constructor(imageResources) {
        super();
        this.imageResources = imageResources;

        this.lines = {
            0: {coordinates: [1, 1, 1, 1, 1], lineDeltaY: 0},
            1: {coordinates: [0, 0, 0, 0, 0], lineDeltaY: 0},
            2: {coordinates: [2, 2, 2, 2, 2], lineDeltaY: 0},
            3: {coordinates: [0, 1, 2, 1, 0], lineDeltaY: 20},
            4: {coordinates: [2, 1, 0, 1, 2], lineDeltaY: -20},
            5: {coordinates: [0, 0, 1, 2, 2], lineDeltaY: 10},
            6: {coordinates: [2, 2, 1, 0, 0], lineDeltaY: -10},
            7: {coordinates: [1, 2, 2, 2, 1], lineDeltaY: 20},
            8: {coordinates: [1, 0, 0, 0, 1], lineDeltaY: -20},
            9: {coordinates: [0, 1, 1, 1, 0], lineDeltaY: -10},
            10: {coordinates: [2, 1, 1, 1, 2], lineDeltaY: 10},
            11: {coordinates: [1, 2, 1, 0, 1], lineDeltaY: -10},
            12: {coordinates: [1, 0, 1, 2, 1], lineDeltaY: 10},
            13: {coordinates: [0, 1, 0, 1, 0], lineDeltaY: 30},
            14: {coordinates: [2, 1, 2, 1, 2], lineDeltaY: -30},
            15: {coordinates: [1, 1, 2, 1, 1], lineDeltaY: -30},
            16: {coordinates: [1, 1, 0, 1, 1], lineDeltaY: 30},
            17: {coordinates: [0, 2, 0, 2, 0], lineDeltaY: -20},
            18: {coordinates: [2, 0, 2, 0, 2], lineDeltaY: 20},
            19: {coordinates: [1, 0, 2, 0, 1], lineDeltaY: -40},
            20: {coordinates: [1, 2, 0, 2, 1], lineDeltaY: 40},
            21: {coordinates: [0, 0, 2, 0, 0], lineDeltaY: -30},
            22: {coordinates: [2, 2, 0, 2, 2], lineDeltaY: 30},
            23: {coordinates: [0, 2, 2, 2, 0], lineDeltaY: 40},
            24: {coordinates: [2, 0, 0, 0, 2], lineDeltaY: -40},
            25: {coordinates: [0, 0, 1, 0, 0], lineDeltaY: 50},
            26: {coordinates: [2, 2, 1, 2, 2], lineDeltaY: -50}
        };

        JL().debug('-- Lines27 initialized');
    }

    drawLineImages(lines, winReels, parentContainer, winLine, payment) {

    }

    /**
     * Create virtual <canvas> for draw win boxes
     * Convert canvas to sprite
     * @param lineIndex
     * @param parentContainer
     * @param reelIndex
     * @param rowIndex
     */
    drawWinBoxes(parentContainer, lineIndex, reelIndex, rowIndex) {
        if (!parentContainer.getChildByName(`${reelIndex}_${rowIndex}`)) {
            this.ctxWinBox.beginPath();
            // draw win boxes over win symbols
            this.drawWinBox(this.ctxWinBox, reelIndex, rowIndex, '#000000', 7);
            this.drawWinBox(this.ctxWinBox, reelIndex, rowIndex, '#de1800', 5);

            this.ctxWinBox.closePath();
            const sprite = Sprite.from(this.canvasWinBox);
            sprite.position.set(0, 0);
            sprite.name = `${reelIndex}_${rowIndex}`;
            parentContainer.addChild(sprite);
        }
    }

    drawBoxes(parentContainer) {
        parentContainer.removeChildren();

        // draw two boxes with title
        [{x: 17, y: 250}, {x: 758, y: 250}].forEach(({x, y}, index) => {
            const box = new Sprite(new Texture(App.Game.getTexture('boxes'), {
                x: 0, y: 0,
                width: 45, height: 104
            }));
            box.position.set(x - 10, y - 40);
            box.name = `box${index + 1}`;
            parentContainer.addChild(box);

            const text = new Text('27', {
                fontFamily: 'Arial',
                fill: '#fbe601',
                fontSize: 19,
                stroke: '#000',
                strokeThickness: 5,
                lineJoin: 'round'
            });
            text.position.set(x - 1, y - 5);
            parentContainer.addChild(text);
        });
    }
}
