import React from 'react';

import './styles.less';
import App from '../../index';

export default class Tabs extends React.Component {
    state = {activeTab: App.View.state.showedMessage ? 4 : 0};

    calback = App.View.state.showedMessage ? App.User.updateMessage() : null;

    changeTab = (newTab, layout, onSelect) => {
        onSelect?.();
        newTab !== this.state.activeTab && layout &&
        this.setState({activeTab: newTab});
    };

    render() {
        const {tabs, type = 'horizontal', lang, active} = this.props;
        const {activeTab} = this.state;
        App.View.state.showedMessage = false;

        return (
            <section className={`tabs tabs-${type} ${active ? '' : 'no-registered'}`}>
                <nav>
                    {tabs.map(({layout, active = true, title, onSelect}, index) => active && <button
                        key={index}
                        className={activeTab === index ? 'nav-button active' : 'nav-button'}
                        onClick={() => this.changeTab(index, layout, onSelect)}
                    >
                        {type === 'vertical' &&
                        <img src={`./../../img/registration/${title}.png`} alt=''/>}
                        {lang[title]}
                    </button>)}
                </nav>
                <article id={`tab-${activeTab}`}>
                    {tabs[activeTab].layout}
                </article>
            </section>
        );
    }
}
