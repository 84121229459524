import React from 'react';

import './styles.less';
import App from './../../index';

let pressInterval = null;

export default function Slider(props) {
    const {slider, refSlider} = props;

    /**
     * Get layout for one game item
     */
    const getGameItem = ({name, status, animatedLogo}) =>
        <div
            id={name}
            key={name}
            className={`game-item ${App.System.os.replace(/\s+/gi, '')} ${animatedLogo ? 'animated' : ''}`}
            onClick={() => App.chooseGame(name)}
        >

            <div className='icon-container'>
                {status === 'NEW' && <div
                    className={`game-border ${App.System.os.replace(/\s+/gi, '')}`}
                    style={{background: `url(${App.getUrl('/img/Menu/new-border.png')})`, backgroundSize: '6300px'}}
                />}
                <div
                    style={{backgroundImage: `url(${App.getUrl(`/game/games/${name}/img/icon.png`)})`}}
                    className='game-icon'
                />
            </div>
            <div className='header-container'>
                {animatedLogo && App.System.platform === 'desktop' ?
                    <div
                        className='logo-sprite'
                        style={{backgroundImage: `url(${App.getUrl(`/game/games/${name}/img/animated-logo.png`)})`}}
                    /> :
                    <div
                        className='game-icon'
                        style={{backgroundImage: `url(${App.getUrl(`/game/games/${name}/img/icon-head.png`)})`}}
                    />}
                {status === 'NEW' && <img
                    className='sticker'
                    src={App.getUrl('/img/Menu/new-sticker.png')}
                    draggable={false}
                    alt=''
                />}
            </div>
        </div>;

    /**
     * Start timer for pressing button and sliding
     * @param event
     * @param direction - left / right
     */
    const slideButtonMouseDown = (event, direction) => {
        event.stopPropagation();
        clearInterval(pressInterval);

        const callback = () => {
            const {slider} = App.View.state;
            direction === 'left' ?
                slider.buttonLeftHandler?.() :
                slider.buttonRightHandler?.();
        };

        callback();
        pressInterval = setInterval(callback, 550);
    };

    /**
     * Clear press timeout
     */
    const slideButtonMouseUp = () => {
        clearTimeout(pressInterval);
        pressInterval = null;
    };

    /**
     * Save position for restore component
     * @param target
     */
    const checkScrollPos = ({target}) =>
        App.Menu.setScrollPos(target.scrollTop, target.scrollLeft);

    const background = `url(${App.getUrl('/img/Menu/navigation-button.png')}) 0 0/100% no-repeat`;
    const categoryGames = App.games[App.settings.categoryName];
    const gamesQuantity = {
        mobile: [6, 12],
        desktop: [8, 18]
    };
    const widthClass =
        categoryGames.length <= gamesQuantity[App.System.platform][0] ? 'min' :
            categoryGames.length <= gamesQuantity[App.System.platform][1] ? 'mid' : 'max';

    return (
        <div className={`slider ${widthClass}-width ${slider.disabledSlider}`}>
            <div
                id='slide-scroll'
                className={`slider-container`}
                ref={refSlider} onScroll={checkScrollPos}
            >
                {slider.visibleGames.map(game => getGameItem(game))}
            </div>
            <div className='slider-button-container'>
                <button
                    className='slider-button-container-prev'
                    onClick={slider.buttonLeftHandler}
                    onMouseDown={event => slideButtonMouseDown(event, 'left')}
                    onMouseUp={slideButtonMouseUp}
                    onMouseOut={slideButtonMouseUp}
                    style={{background}}
                />
                <button
                    className='slider-button-container-next'
                    onClick={slider.buttonRightHandler}
                    onMouseDown={event => slideButtonMouseDown(event, 'right')}
                    onMouseUp={slideButtonMouseUp}
                    onMouseOut={slideButtonMouseUp}
                    style={{background}}
                />
            </div>
        </div>
    );
}
