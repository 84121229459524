import * as PIXI from 'pixi.js-legacy';

import CustomLines from './../CustomLines';
import App from './../../index';

// PIXI aliases
const Text = PIXI.Text,
    Graphics = PIXI.Graphics;

export default class LinesEgt extends CustomLines {
    getLineStartPos(lineIndex) {
        const line = this.lines[lineIndex], {lineDeltaY = 0} = line;
        return {
            x: this.getPointPosX(0) - 55,
            y: this.getPointPosY(line.coordinates[0]) + lineDeltaY
        };
    }

    setLinePath(lineIndex, reelIndex, rowIndex) {
        const line = this.lines[lineIndex], {lineDeltaY = 0} = line;
        line.path = line.path || [...Array(App.Game.reels)].map(() => ({}));
        line.path[reelIndex].x = this.getPointPosX(reelIndex);
        line.path[reelIndex].y = this.getPointPosY(rowIndex) + lineDeltaY;
    }

    drawBoxes(parentContainer) {
        parentContainer.removeChildren();

        const currentLines = App.Game.gameSettings.getLinesNumber();
        const {activeBoxStyle, inactiveBoxStyle, colLines, coordinates} = this.getBoxesStyle();
        parentContainer.removeChildren();

        Object.keys(coordinates).forEach(pos => {
            colLines.forEach((colLinesText, i) => {
                const y = coordinates[pos].y + 70 * -i;

                const quantityLines = new Text(colLinesText, inactiveBoxStyle);
                quantityLines.position.set(coordinates[pos].x + 0.5, y);
                quantityLines.anchor.set(0.5);

                if (colLinesText === currentLines) {
                    quantityLines.position.y -= 3;

                    const label = new Text(`${i > 0 ? 'LINES' : 'LINE'}`, activeBoxStyle);
                    label.position.set(coordinates[pos].x, y + 15);
                    label.anchor.set(0.5);
                    activeBoxStyle.fontSize = 19;
                    quantityLines.style = activeBoxStyle;
                    activeBoxStyle.fontSize = 13;

                    parentContainer.addChild(label);
                }

                parentContainer.addChild(quantityLines);
                const sprite = new Graphics();
                sprite.beginFill(0xFF3300);
                sprite.moveTo(0, 0);
                sprite.lineTo(29, 0);
                sprite.lineTo(29, 60);
                sprite.lineTo(0, 60);
                sprite.endFill();
                sprite.alpha = 0;

                sprite.position.set(coordinates[pos].x - 15, y - 33);
                sprite.name = colLinesText;
                this.addBoxEvents(sprite);
                parentContainer.addChild(sprite);
            });
        });
    }

    /**
     * Set box styles. Redefined in each lines class.
     */
    getBoxesStyle = () => ({
        activeBoxStyle: {
            dropShadow: true,
            dropShadowDistance: '',
            fill: '#f6ff02',
            fontSize: 13,
            fontWeight: 'bold',
            stroke: '#37bb1d',
            strokeThickness: 6,
            lineJoin: 'round'
        },
        inactiveBoxStyle: {
            dropShadow: true,
            dropShadowDistance: '',
            fill: '#cbc6bd',
            fontSize: 19,
            strokeThickness: 2,
            stroke: '#2c911a',
            lineJoin: 'round'
        },
        colLines: [1, 2, 3, 4, 5],
        coordinates: {
            0: {x: 26, y: 408},
            1: {x: 775, y: 408}
        }
    });

    /**
     * Create events for boxes
     * Show line and stop animate feature on 'pointerover' event
     * @param sprite
     */
    addBoxEvents = sprite => {
        const linesContainer = App.Game.getStageChild('linesContainer');

        sprite.buttonMode = true; // shows hand cursor
        sprite.interactive = true;
        sprite
            .on('pointerdown', () => {
                linesContainer.removeChildren();
                App.Game.animationFrameId && App.Game.stopAnimateFeature();
                App.Game.Legends.showJackpot();
                App.Game.changeLine(+sprite.name - 1);
            });
    };
}
