import React from 'react';

import './styles.less';
import App from './../../index';
import Logo from './../logo';

export default function SecondScreen() {
    return (
        <div className='second-screen'>
            <img src={App.getUrl('/img/Menu/menu-bg.png')} draggable={false} alt=''/>
            {!App.Game && <div className='logo-container'><Logo name={App.name}/></div>}
        </div>
    );
}
