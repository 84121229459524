import App from './../../../index';
import GambleDeluxe from './../../deluxe/gamble';

export default class Gamble extends GambleDeluxe {
    constructor(imageResources) {
        super(imageResources);
        this.gambleButtonsSize.borderRadius = '0';
        this.screenCoordinates = {x: 0, y: 70};
        this.gambleAreaSize = {w: 1024, h: 720};
        this.cardPos = {x: 420, y: 250};
        this.prizeWinPos = [{x: 250, y: 100}, {x: 650, y: 100}]; // text positions for prizeWin texts, current and doubled
        this.cardsQueuePos = {x: [398, 460, 522, 584, 645, 708], y: 137};
    }

    drawGambleTexts = ctx => {
        ctx.font = 'bold 18pt Times New Roman';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#FFFFFF';
        ctx.shadowBlur = 3;
        ctx.shadowOffsetX = 2;
        ctx.shadowOffsetY = 2;
        ctx.shadowColor = '#000';

        ctx.fillText(App.language.gambleAmount.toUpperCase(), this.prizeWinPos[0].x, this.prizeWinPos[0].y - 28);
        ctx.fillText(App.language.gambleToWin.toUpperCase(), this.prizeWinPos[1].x, this.prizeWinPos[1].y - 28);
        ctx.fillText(App.language.previousCards.toUpperCase(), 230, 180);

        ctx.fillText(this.prizeWin, this.screenCoordinates.x + this.prizeWinPos[0].x, this.offsetGambleQueue + this.prizeWinPos[0].y);
        ctx.fillText(this.prizeWin * 2, this.screenCoordinates.x + this.prizeWinPos[1].x, this.offsetGambleQueue + this.prizeWinPos[1].y);
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    };
}
