import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import GameDeluxe from './../../deluxe/game';
import Lines10 from './../../deluxe/lines10';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';
import bonusFont from './img/font/bonusFont';

/* PIXI aliases */
const AnimatedSprite = PIXI.AnimatedSprite;

export default class RapaNui extends GameDeluxe {
    constructor() {
        super();
        this.id = 'rapa-nui';
        this.name = 'Rapa Nui';
        this.positions = [];
        this.buttonsPanelShadow = 'strong';

        // roll properties
        this.reelSettings = [14, 5, 25]; // 0 - start symbol amount, 1 - reel increase regular roll, 2 - reel increase long roll
        this.scatter = 11;
        this.reelFilter = [[12, 13, 14], [12, 13, 14], [12, 13, 14], [12, 13, 14], [12, 13, 14]];
        this.reelXCoordinates = [52, 194, 336, 478, 620]; // magic numbers - x coordinates where reels starts
        this.allowLongRoll = true;
        this.containersLayers.linesContainer = 10; // change to top for overlay bonusContainer

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 84, y: 141},
            bonusInBonusFrame: {x: 84, y: 141},
            endBonusFrame: {x: 84, y: 141}
        };

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 0, 5, 20, 100]},     // 0 - 10
            {regularDelay: 100, payment: [0, 0, 0, 5, 20, 100]},     // 1 - J
            {regularDelay: 100, payment: [0, 0, 0, 5, 20, 100]},     // 2 - Q
            {regularDelay: 100, payment: [0, 0, 0, 10, 50, 200]},    // 3 - K
            {regularDelay: 100, payment: [0, 0, 0, 10, 50, 200]},    // 4 - A
            {regularDelay: 100, payment: [0, 0, 0, 20, 100, 400]},   // 5 - flower
            {regularDelay: 100, payment: [0, 0, 0, 20, 100, 600]},   // 6 - fish
            {regularDelay: 100, payment: [0, 0, 0, 30, 200, 1000]},  // 7 - mask
            {regularDelay: 100, payment: [0, 0, 0, 20, 100, 800]},   // 8 - turtle
            {regularDelay: 100, payment: [0, 0, 0, 30, 300, 2000]},  // 9 - blond
            {regularDelay: 100, payment: [0, 0, 10, 50, 500, 5000]}, // 10 - girl with tattoo
            {                                                        // 11 - coffer (scatter)
                regularDelay: 100,
                scatterDelay: 100,
                offsetY: 2,
                zIndex: 10,
                payment: [0, 0, 0, 5, 50, 500]
            },
            {regularDelay: 100, payment: [0, 0, 0, 0, 0, 0]},        // 12 - girl top
            {regularDelay: 100, payment: [0, 0, 0, 0, 0, 0]},        // 13 - girl mid
            {regularDelay: 100, payment: [0, 0, 0, 0, 0, 0]}         // 14 - girl down
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                jokerAnimation: 'bonus/jokerAnimation.png',
                bonusArea: 'area/bonus.png',
                frame1: 'bonus/frame1.png',
                frame2: 'bonus/frame2.png',
                frame3: 'bonus/frame3.png',
                bonusFont: bonusFont['imageResource']
            }),
            atlas: this.mergePath([
                'bonus/bonusSymbols1.json',
                'bonus/bonusSymbols2.json',
                'bonus/scatterSymbols.json',
                'regularLongSymbols.json'
            ])
        };

        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'bonus-game-end', alias: 'bonusGameEnd'},
                {name: 'bonus-background', loop: true},
                {name: 'joker'},
                {name: 'long1'},
                {name: 'banner-win'},
                {name: 'reels'}
            ],
            path: `/game/games/${this.id}/audio/`
        };

        this.Lines = new Lines10();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            activeRed: 'gamble/red-active.png',
            inactiveRed: 'gamble/red-inactive.png',
            activeBlack: 'gamble/black-active.png',
            inactiveBlack: 'gamble/black-inactive.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 4}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 14pt Arial';
        ctx.textAlign = 'center';
        ctx.strokeStyle = '#000';
        ctx.fillStyle = '#fff';
        ctx.lineWidth = 4;

        switch (page) {
            case 1:
                // scatter
                this.strokeFillText(ctx, bet * nLines * this.symbols[11].payment[5], 410, 278);
                this.strokeFillText(ctx, bet * nLines * this.symbols[11].payment[4], 410, 300);
                this.strokeFillText(ctx, bet * nLines * this.symbols[11].payment[3], 410, 322);
                // girl with tattoo
                this.strokeFillText(ctx, bet * this.symbols[10].payment[5], 100, 30);
                this.strokeFillText(ctx, bet * this.symbols[10].payment[4], 100, 52);
                this.strokeFillText(ctx, bet * this.symbols[10].payment[3], 100, 74);
                this.strokeFillText(ctx, bet * this.symbols[10].payment[2], 100, 96);
                // blond
                this.strokeFillText(ctx, bet * this.symbols[9].payment[5], 720, 100);
                this.strokeFillText(ctx, bet * this.symbols[9].payment[4], 720, 122);
                this.strokeFillText(ctx, bet * this.symbols[9].payment[3], 720, 144);
                // turtle
                this.strokeFillText(ctx, bet * this.symbols[8].payment[5], 170, 346);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[4], 170, 368);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[3], 170, 390);
                // mask
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], 645, 346);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], 645, 368);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 645, 390);
                // fish
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 105, 538);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 105, 560);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 105, 582);
                // flower
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 305, 538);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 305, 560);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 305, 582);
                // A K
                this.strokeFillText(ctx, bet * this.symbols[3].payment[5], 500, 538);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[4], 500, 560);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[3], 500, 582);
                // 10 J Q
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 715, 538);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 715, 560);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 715, 582);
                break;
            case 2:
                ctx.textAlign = 'left';

                this.strokeFillText(ctx, 'substitutes for all symbols', 295, 185);
                this.strokeFillText(ctx, 'except scatters', 295, 210);

                this.strokeFillText(ctx, 'During Free Games', 200, 310);
                this.strokeFillText(ctx, 'substitutes for all', 490, 310);
                this.strokeFillText(ctx, 'symbols except scatters', 490, 335);
                this.strokeFillText(ctx, 'on all positions on two', 490, 360);
                this.strokeFillText(ctx, 'randomly selected reels.', 490, 385);
                break;
            case 3:
                this.strokeFillText(ctx, '3 or more Scatters', 400, 180);
                this.strokeFillText(ctx, 'trigger 10 Free Games', 400, 420);
                this.strokeFillText(ctx, '+ Mystic Bonus', 400, 450);
                break;
            case 4:
                this.strokeFillText(ctx, 'RULES', 400, 180);

                this.strokeFillText(ctx, 'All prizes are for combinations of a kind. All prizes are for', 400, 240);
                this.strokeFillText(ctx, 'combinations left to right, except scatters. All prizes are on', 400, 265);
                this.strokeFillText(ctx, 'selected lines, except scatters. Scatter symbols pay at any', 400, 290);
                this.strokeFillText(ctx, 'position on screen. Highest win only paid per selected line.', 400, 315);
                this.strokeFillText(ctx, 'Scatter wins are added to line wins. Free Games can be won', 400, 340);
                this.strokeFillText(ctx, 'again during the Free Games. Free Games are played at', 400, 365);
                this.strokeFillText(ctx, 'trigger bet and lines. All prizes shown in credits.', 400, 390);
                this.strokeFillText(ctx, 'Malfunction voids all pays and plays.', 400, 415);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    /**
     * Отрисовка таблички бонусной игры
     */
    showStartBonusFrame(parentContainer, {x, y}) {
        App.Sounds.playSound('banner-win');
        this.showBonusFrame(parentContainer, x, y, 'frame1');
    }

    /**
     * Отрисовка таблички бонус в бонусе
     */
    showBonusInBonusFrame(parentContainer, {x, y}) {
        App.Sounds.playSound('banner-win');
        this.showBonusFrame(parentContainer, x, y, 'frame2');
    }

    /**
     * Отрисовка таблички окончания бонусной игры
     */
    showEndBonusFrame(parentContainer, {x, y}, {win, total}) {
        this.showBonusFrame(parentContainer, x, y, 'frame3');
        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'right'
        };
        this.drawCustomFont(win, 370, 299, textProps);
    }

    additionalPreparingToAnimateFeature() {
        this.isBonus() && this.addJoker(this.getStageChild('bonusContainer'));
    }

    addJoker(parentContainer) {
        const reels = this.getAnimatedJokerReels(this.latestResponse);

        reels.forEach(reelIndex => {
            const sprite = new AnimatedSprite(this.getSpriteTextures({
                toFrame: 36, image: 'jokerAnimation', colCount: 12,
                width: this.symbolWidth, height: this.symbolHeight * 3
            }));

            sprite.animationSpeed = 0.2;
            sprite.name = reelIndex;
            sprite.position.set(this.reelXCoordinates[reelIndex], this.reelTop);
            sprite.play();
            parentContainer.addChild(sprite);
        });

        reels.length && setTimeout(() => App.Sounds.playSound('joker'), 300);
    }

    getAnimatedJokerReels(response) {
        const jokerReels = [];
        const animatedJokerReels = [];

        // check all jokers
        response.screen.forEach((reel, reelIndex) => {
            reel[0] === 12 &&
            reel[1] === 13 &&
            reel[2] === 14 &&
            jokerReels.push(reelIndex);
        });

        // collect jokers for animating
        response.features.forEach(feature => {
            feature.reels && feature.reels.forEach(reelIndex => {
                jokerReels.includes(reelIndex) &&
                !animatedJokerReels.includes(reelIndex) &&
                animatedJokerReels.push(reelIndex);
            });
        });

        return animatedJokerReels;
    }

    finishBonusAnimation() {
        const timeout = this.latestResponse.features.length > 2 ? 0 : 2000;
        setTimeout(() => {
            this.getStageChild('bonusContainer').removeChildren();
            this.bonusStatus.remain === 0 ?
                this.finishBonus() :
                this.bonusRoll();
        }, timeout);
    }

    setScatterSprite(scatterFeature) {
        scatterFeature.positions.forEach(position => {
            const {reel, row} = position;
            const symbolObj = this.reelMatrix[reel][row];
            symbolObj.image = this.gameFlag.bonusStarted ? 'bonus' : 'scatter';
            symbolObj.loop = false;
            this.Roll.updateSymbolSprite(symbolObj);
            symbolObj.sprite.play();
        });
    }

    getSymbolImageType = () => this.gameFlag.bonusStarted ? 'bonus' : 'static';
}
