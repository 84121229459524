import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../../../index';
import Lines from './../../lines';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Texture = PIXI.Texture,
    Text = PIXI.Text;

export default class Lines10x6 extends Lines {
    constructor() {
        super();
        this.imageResources = {
            lines: '/img/lines/Lines10_6/lines.png',
            winLines: '/img/lines/Lines10_6/winlines.png',
            boxes: '/img/lines/Lines10_6/boxes.png'
        };
        this.lineImageWidth = 116; // width of line image in sprite, magic number for each game
        this.lineImageHeight = 162; // height of line image in sprite, magic number for each game

        this.lines = {
            0: {
                coordinates: [1, 1, 1, 1, 1, 1],
                boxes: [
                    {x: 1, y: 289},
                    {x: 764, y: 289}
                ],
                path: [
                    {x: 36, y: 204},
                    {x: 168, y: 204},
                    {x: 284, y: 204},
                    {x: 400, y: 204},
                    {x: 516, y: 204},
                    {x: 632, y: 204}
                ],
                text: [
                    {x: 115, y: 343},
                    {x: 263, y: 343},
                    {x: 405, y: 343}
                ]
            },
            1: {
                coordinates: [0, 0, 0, 0, 0, 0],
                boxes: [
                    {x: 1, y: 158},
                    {x: 764, y: 158}
                ],
                path: [
                    {x: 36, y: 93},
                    {x: 168, y: 93},
                    {x: 284, y: 93},
                    {x: 400, y: 93},
                    {x: 516, y: 93},
                    {x: 632, y: 93}
                ],
                text: [
                    {x: 115, y: 212},
                    {x: 263, y: 212},
                    {x: 405, y: 212}
                ]
            },
            2: {
                coordinates: [2, 2, 2, 2, 2, 2],
                boxes: [
                    {x: 1, y: 387},
                    {x: 764, y: 387}
                ],
                path: [
                    {x: 36, y: 318},
                    {x: 168, y: 318},
                    {x: 284, y: 318},
                    {x: 400, y: 318},
                    {x: 516, y: 318},
                    {x: 632, y: 318}
                ],
                text: [
                    {x: 115, y: 472},
                    {x: 263, y: 472},
                    {x: 405, y: 472}
                ]
            },
            3: {
                coordinates: [0, 1, 2, 1, 0, 0],
                boxes: [
                    {x: 1, y: 127},
                    {x: 764, y: 127}
                ],
                path: [
                    {x: 36, y: 79},
                    {x: 168, y: 189},
                    {x: 284, y: 299},
                    {x: 400, y: 189},
                    {x: 516, y: 79},
                    {x: 632, y: 79}
                ],
                text: [
                    {x: 115, y: 212},
                    {x: 263, y: 342},
                    {x: 405, y: 474}
                ]
            },
            4: {
                coordinates: [2, 1, 0, 1, 2, 2],
                boxes: [
                    {x: 1, y: 420},
                    {x: 764, y: 419}
                ],
                path: [
                    {x: 36, y: 326},
                    {x: 168, y: 216},
                    {x: 284, y: 106},
                    {x: 400, y: 216},
                    {x: 516, y: 326},
                    {x: 632, y: 326}
                ],
                text: [
                    {x: 115, y: 472},
                    {x: 263, y: 342},
                    {x: 405, y: 212}
                ]
            },
            5: {
                coordinates: [1, 2, 2, 2, 1, 1],
                boxes: [
                    {x: 1, y: 257},
                    {x: 764, y: 321}
                ],
                path: [
                    {x: 36, y: 177},
                    {x: 168, y: 287},
                    {x: 284, y: 287},
                    {x: 400, y: 287},
                    {x: 516, y: 177},
                    {x: 632, y: 177}
                ],
                text: [
                    {x: 115, y: 342},
                    {x: 263, y: 474},
                    {x: 405, y: 474}
                ]
            },
            6: {
                coordinates: [1, 0, 0, 0, 1, 1],
                boxes: [
                    {x: 1, y: 224},
                    {x: 764, y: 256}
                ],
                path: [
                    {x: 36, y: 193},
                    {x: 168, y: 93},
                    {x: 284, y: 93},
                    {x: 400, y: 103},
                    {x: 516, y: 203},
                    {x: 632, y: 203}
                ],
                text: [
                    {x: 115, y: 342},
                    {x: 263, y: 212},
                    {x: 405, y: 212}
                ]
            },
            7: {
                coordinates: [2, 2, 1, 0, 0, 0],
                boxes: [
                    {x: 1, y: 321},
                    {x: 764, y: 224}
                ],
                path: [
                    {x: 36, y: 308},
                    {x: 168, y: 308},
                    {x: 284, y: 197},
                    {x: 400, y: 86},
                    {x: 516, y: 85},
                    {x: 632, y: 85}
                ],
                text: [
                    {x: 115, y: 472},
                    {x: 263, y: 472},
                    {x: 405, y: 342}
                ]
            },
            8: {
                coordinates: [0, 0, 1, 2, 2, 2],
                boxes: [
                    {x: 1, y: 191},
                    {x: 764, y: 355}
                ],
                path: [
                    {x: 36, y: 89},
                    {x: 168, y: 89},
                    {x: 284, y: 199},
                    {x: 400, y: 309},
                    {x: 516, y: 309},
                    {x: 632, y: 309}
                ],
                text: [
                    {x: 115, y: 212},
                    {x: 263, y: 212},
                    {x: 405, y: 342}
                ]
            },
            9: {
                coordinates: [2, 1, 1, 1, 0, 0],
                boxes: [
                    {x: 1, y: 355},
                    {x: 764, y: 190}
                ],
                path: [
                    {x: 36, y: 317},
                    {x: 168, y: 207},
                    {x: 284, y: 207},
                    {x: 400, y: 207},
                    {x: 516, y: 97},
                    {x: 632, y: 97}
                ],
                text: [
                    {x: 115, y: 472},
                    {x: 263, y: 342},
                    {x: 405, y: 342}
                ]
            }
        };

        JL().debug('-- Lines10_6 initialized');
    }

    drawLineImages(lines, winReels, parentContainer) {
        lines.forEach(lineIndex => {
            const line = this.lines[lineIndex];

            line.coordinates.forEach((rowIndex, reelIndex) => {
                const image = winReels.includes(reelIndex) ? 'winLines' : 'lines';
                this.lineImageWidth = 116;
                const spritePosX = reelIndex !== 0 ? 52 + this.lineImageWidth * reelIndex : 36;
                const spritePosY = this.lineImageHeight * lineIndex;

                (reelIndex === 0 || reelIndex === 5) ? this.lineImageWidth = 132 : this.lineImageWidth = 116;

                // create line sprite
                const sprite = new Sprite(new Texture(App.Game.getTexture(image), {
                    x: spritePosX,
                    y: spritePosY,
                    width: this.lineImageWidth,
                    height: this.lineImageHeight
                }));
                sprite.position.set(line.path[reelIndex].x, line.path[reelIndex].y);
                sprite.name = lineIndex;
                parentContainer.addChild(sprite);
            });
        });
    }

    drawBoxes(parentContainer) {
        parentContainer.removeChildren(); // clear all previous boxes

        const currentLines = App.Game.gameSettings.getCurrentLineMas();
        const boxWidth = 36; // width of winline boxes
        const boxHeight = 22; // height of winline boxes

        Object.keys(this.lines).forEach(lineIndex => {
            this.lines[lineIndex].boxes.forEach((box, indexBox) => {
                const activeLine = currentLines.includes(+lineIndex + 1);

                // create box sprite
                const sprite = new Sprite(new Texture(App.Game.getTexture('boxes'), {
                    x: 0,
                    y: activeLine ? // if line enabled
                        indexBox === 0 ? boxHeight * lineIndex : boxHeight * (+lineIndex + 11) :
                        indexBox === 0 ? boxHeight * 10 : boxHeight * 21,
                    width: boxWidth,
                    height: boxHeight
                }));
                sprite.position.set(box.x, box.y);
                sprite.name = lineIndex;
                activeLine && this.addBoxEvents(sprite);
                parentContainer.addChild(sprite);

                // add text to active box
                if (activeLine) {
                    const text = new Text(+lineIndex + 1, {
                        fontFamily: 'Arial',
                        fontSize: 19,
                        fill: '#000',
                        lineJoin: 'round'
                    });
                    text.anchor.set(0.5);
                    text.position.set(box.x + boxWidth / 2, box.y + boxHeight / 2);
                    parentContainer.addChild(text);
                }
            });
        });
    }
}
