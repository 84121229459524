/**
 * Class for control all bets in Roulette game
 */
export default class Bets {
    stack = []; // all bets and events collection

    restoreStack = []; // temp collection after clear

    chips = []; // available chips

    posChip = 0;

    // current selected index
    limits = null; // max and min bet

    /**
     * Return bets collection
     * Unite similar bets
     * @returns {Array}
     */
    get = () => {
        let bets = [];

        this.stack.forEach(({type, idx, bet}) => {
            const index = bets.findIndex(obj => obj.idx === idx);
            const current = bets[index];
            switch (type) {
                case 'add':
                    current ? // check existing bet idx
                        current.bet += bet :
                        bets.push({idx, bet});
                    break;
                case 'remove':
                    current.bet -= bet;
                    current.bet <= 0 && bets.splice(index, 1);
                    break;
                case 'double':
                    bets.forEach(obj => (obj.bet *= 2));
                    break;
                case 'clear':
                    bets = [];
                    break;
            }
        });
        return bets;
    };

    /**
     * Return total bet
     * @returns {*|number}
     */
    getTotal = () => this.get().reduce((total, {bet}) => total + bet, 0);

    /**
     * Push new bet to collection
     * @param bet
     */
    add = bet => this.stack.push({type: 'add', ...bet});

    /**
     * Remove current chip from idx
     * @param idx
     * @param bet
     * @returns {number}
     */
    remove = (idx, bet) => this.stack.push({type: 'remove', idx, bet});

    /**
     * Double all bets in collection
     */
    double = () => this.stack.push({type: 'double'});

    /**
     * Reset collection and last action
     */
    clear = () => this.stack.push({type: 'clear'});

    /**
     * Cancel last client action
     * Check type of action
     */
    cancelLastAction() {
        const action = this.stack[this.stack.length - 1];
        this.stack.pop();
        return action;
    }

    setChips = response => this.chips = response;

    getChips = () => this.chips;

    setChipPos = pos => this.posChip = pos;

    getChipPos = () => this.posChip;

    getChip = () => this.getChips()[this.posChip];

    setLimits = limits => this.limits = limits;

    getLimit = (type, multiplier) => type === 'number' ?
        {...this.limits[type], max: this.limits[type].max * multiplier} :
        this.limits[type];
}
