import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import GameDeluxe5x4 from './../../deluxe/gameDeluxe5x4';
import Lines40 from './../../deluxe/lines40';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';
import bonusFont from './img/font/fontBonus';

/* PIXI aliases */
const Texture = PIXI.Texture,
    AnimatedSprite = PIXI.AnimatedSprite;

export default class ElToro extends GameDeluxe5x4 {
    constructor() {
        super();
        this.id = 'el-toro';
        this.name = 'El Toro';
        this.transparentBackground = true;
        this.symbolEffects = true;
        this.reelTop = 88;
        this.scatter = 10;
        this.reelFilter = [[], [10], [], [10], []];
        this.buttonsPanelShadow = 'mid';

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 120, y: 100},
            bonusInBonusFrame: {x: 120, y: 100},
            endBonusFrame: {x: 120, y: 100}
        };

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 0, 8, 20, 100]},              // 0 - 9
            {regularDelay: 100, payment: [0, 0, 0, 8, 20, 100]},              // 1 - 10
            {regularDelay: 100, payment: [0, 0, 0, 8, 20, 100]},              // 2 - J
            {regularDelay: 100, payment: [0, 0, 0, 8, 20, 100]},              // 3 - Q
            {regularDelay: 100, payment: [0, 0, 0, 12, 40, 160]},             // 4 - K
            {regularDelay: 100, payment: [0, 0, 0, 12, 40, 160]},             // 5 - A
            {regularDelay: 100, payment: [0, 0, 0, 20, 60, 240]},             // 6 - fan
            {regularDelay: 100, payment: [0, 0, 0, 20, 60, 240]},             // 7 - shawl
            {regularDelay: 100, offset: 3, payment: [0, 0, 0, 20, 80, 400]},  // 8 - man
            {regularDelay: 100, offset: 2, payment: [0, 0, 0, 20, 80, 400]},  // 9 - woman
            {                                                                 // 10 - rose (scatter)
                regularDelay: 100,
                scatterDelay: 130,
                offset: 5,
                payment: [0, 0, 0, 4, 0, 0]
            },
            {regularDelay: 100, offset: 5, payment: [0, 0, 0, 80, 200, 1000]} // 11 - bull
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png',
                bonusFont: bonusFont['imageResource']
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                bonusArea: 'area/bonus.png',
                bonusAnimation: 'bonus/bonusAnimation.png',
                frame: 'bonus/bonus1.png'
            }),
            atlas: this.mergePath(['bonus/scatterSymbols.json'])
        };

        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'bonus-game-end', alias: 'bonusGameEnd'},
                {name: 'bonus-background', loop: true}
            ],
            path: `/game/games/${this.id}/audio/`
        };

        this.Gamble = new GambleDeluxe(this.mergePath({
            activeRed: 'gamble/red-active.png',
            inactiveRed: 'gamble/red-inactive.png',
            activeBlack: 'gamble/black-active.png',
            inactiveBlack: 'gamble/black-inactive.png',
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));

        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
        this.Lines = new Lines40();
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        const textProps = {
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 0.45,
            fontInterval: -7
        };
        const titleProps = {
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 0.8,
            fontInterval: -7
        };
        switch (page) {
            case 1:
                ctx.font = 'bold 17pt Arial';
                ctx.textAlign = 'left';
                ctx.fillStyle = '#490303';

                // bull
                this.strokeFillText(ctx, bet * this.symbols[11].payment[5], 110, 210);
                this.strokeFillText(ctx, bet * this.symbols[11].payment[4], 110, 235);
                this.strokeFillText(ctx, bet * this.symbols[11].payment[3], 110, 260);
                // men woman
                this.strokeFillText(ctx, bet * this.symbols[9].payment[5], 625, 210);
                this.strokeFillText(ctx, bet * this.symbols[9].payment[4], 625, 235);
                this.strokeFillText(ctx, bet * this.symbols[9].payment[3], 625, 260);
                // shawl, aan
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], 470, 379);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], 470, 404);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 470, 429);
                // A K
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 250, 500);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 250, 525);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 250, 550);
                // 9, 10, J, Q
                this.strokeFillText(ctx, bet * this.symbols[2].payment[5], 550, 500);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[4], 550, 525);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[3], 550, 550);

                ctx.textAlign = 'center';
                ctx.fillStyle = '#eb931b';
                // rose
                this.strokeFillText(ctx, bet * nLines * this.symbols[10].payment[3], 380, 246);

                ctx.font = '35pt franklinFont';
                ctx.fillStyle = '#fff';
                ctx.shadowColor = 'black';
                ctx.shadowOffsetX = 3;
                ctx.shadowOffsetY = 3;
                ctx.shadowBlur = 5;

                this.strokeFillText(ctx, bet * nLines * this.symbols[11].payment[5], 138, 349);
                break;
            case 2:
                ctx.shadowColor = '#000';
                ctx.shadowOffsetX = 0;
                ctx.shadowOffsetY = 0;
                ctx.shadowBlur = 2;
                const numberProps = {
                    parentContainer: ctx,
                    fontImageName: 'bonusFont',
                    map: bonusFont,
                    align: 'center',
                    scale: 1,
                    fontInterval: -7
                };
                textProps.parentContainer = ctx;
                titleProps.parentContainer = ctx;

                this.drawCustomFont('substitutes for', 335, 220, textProps);
                this.drawCustomFont('Free Games', 380, 310, titleProps);
                this.drawCustomFont('scatters on the screen trigger', 460, 400, textProps);
                this.drawCustomFont('Free Games', 395, 450, textProps);
                this.drawCustomFont('3', 275, 370, numberProps);
                this.drawCustomFont('15', 280, 425, numberProps);
                break;
            case 3:
                ctx.shadowColor = '#000';
                ctx.shadowOffsetX = 0;
                ctx.shadowOffsetY = 0;
                ctx.shadowBlur = 1;
                const rulesProps = {
                    parentContainer: ctx,
                    fontImageName: 'bonusFont',
                    map: bonusFont,
                    align: 'center',
                    scale: 0.5,
                    fontInterval: -7
                };
                titleProps.parentContainer = ctx;
                this.drawCustomFont('RULES', 385, 175, titleProps);
                this.drawCustomFont('All prizes are for combinations of a kind.', 400, 260, rulesProps);
                this.drawCustomFont('All prizes are for combinations left to right,', 400, 285, rulesProps);
                this.drawCustomFont('except scatters. All prizes are on selected lines,', 400, 310, rulesProps);
                this.drawCustomFont('except scatters. Scatter symbols pay at any', 400, 335, rulesProps);
                this.drawCustomFont(' position on screen. Highest win only paid', 400, 360, rulesProps);
                this.drawCustomFont('per selected  line. Scatter wins are added', 400, 385, rulesProps);
                this.drawCustomFont('to line wins. All prizes shown in credits.', 400, 410, rulesProps);
                this.drawCustomFont(' Malfunction voids all pays and plays.', 400, 435, rulesProps);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    drawBonusFrame(first, last, parentContainer, coordinates) {
        const {startBonusFrame, bonusInBonusFrame, endBonusFrame} = coordinates;
        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 0.5,
            strokeStyle: '#000',
            strokeWidth: 12,
            fontInterval: -7 // px between symbols
        };

        App.Sounds.stopSound('bonus-background');
        if (first) {
            this.setBackground('bonusArea');
            App.Sounds.playSound('animation');
            this.drawBonusAnimation(parentContainer, startBonusFrame, textProps);
        }

        if (last) {
            this.setBackground('mainArea');
            App.updateButton('start', {disabled: true});
            this.showEndBonusFrame(parentContainer, endBonusFrame, textProps);
            this.playEndBonusGameSound();
            setTimeout(() => this.endBonus(), 5000);
        }

        if (!first && !last) {
            this.showBonusInBonusFrame(parentContainer, bonusInBonusFrame, textProps);
        }
    }

    /**
     * drawBonusAnimation
     */
    drawBonusAnimation(parentContainer, {x, y}, textProps) {
        const textures = [...Array(35)].map((item, index) => {
            const width = 513, height = 397;
            const colIndex = Math.trunc(index / 7);
            const rowIndex = index % 7;
            return new Texture(this.getTexture(`bonusAnimation`), {
                x: colIndex * width,
                y: rowIndex * height,
                width: width,
                height: height
            });
        });

        const sprite = new AnimatedSprite(textures);
        sprite.name = 'bookAnim';
        sprite.animationSpeed = 0.2;
        sprite.loop = false;
        sprite.position.set(x, y);
        parentContainer.addChild(sprite);
        sprite.play();
        sprite.onComplete = () => this.showStartBonusFrame(parentContainer, {x, y}, textProps);
    }

    /**
     * Drawing  the table of bonus game
     * @param parentContainer
     * @param x
     * @param y
     * @param textProps
     */
    showStartBonusFrame(parentContainer, {x, y}, textProps) {
        parentContainer.removeChildren();
        this.showBonusFrame(parentContainer, x, y);
        this.drawCustomFont('15 FREE GAMES', 400, 240, textProps);
        this.drawCustomFont('WON', 400, 300, textProps);
        this.enableStartButton(parentContainer);
    }

    /**
     *  Drawing  the table of bonus in bonus game
     */
    showBonusInBonusFrame(parentContainer, {x, y}, textProps) {
        this.enableStartButton(parentContainer);
        this.showBonusFrame(parentContainer, x, y);
        this.drawCustomFont('15  MORE FREE', 400, 240, textProps);
        this.drawCustomFont(' GAMES WON', 400, 300, textProps);
    }

    /**
     * Drawing  the table of end of bonus in bonus game
     */
    showEndBonusFrame(parentContainer, {x, y}, textProps) {
        this.showBonusFrame(parentContainer, x, y);
        this.drawCustomFont('FEATURE WIN', 400, 240, textProps);
        this.drawCustomFont(`${this.bonusStatus.win} CREDITS`, 400, 300, textProps);
    }

    enableStartButton(parentContainer) {
        App.updateButton('start', {
            disabled: false,
            title: 'start',
            handler: () => this.startBonusAnimation(parentContainer)
        });
    }
}
