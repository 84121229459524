import {JL} from 'jsnlog';

import App from './../../index';
import GambleMegajack from './../megajack/gamble';

export default class GambleIgrosoft extends GambleMegajack {
    constructor(imageResources) {
        super(imageResources);
        this.gambleQueue = [];
        this.screenCoordinates = {x: 0, y: 0};
        this.cloudx = 0;
        this.cloudy = 0;
        this.offsetGambleQueue = 0;
        this.imageResources = {
            ...imageResources // redefine base images from game
        };
        this.dealer = '';
        this.cards = [];
        this.step = 0;
        this.choice = 0;
        this.status = '';
        this.cardWeigth = 0;
        this.cardHeight = 0;
        this.cardPositions = [
            [61, 140],
            [200, 140],
            [342, 140],
            [480, 140],
            [620, 140]
        ];
        this.cardY = {'C': 0, 'D': 1, 'H': 2, 'S': 3, 'R': 4, 'B': 4};
        this.cardX = {
            '2': 0,
            '3': 1,
            '4': 2,
            '5': 3,
            '6': 4,
            '7': 5,
            '8': 6,
            '9': 7,
            '10': 8,
            'J': 9,
            'Q': 10,
            'K': 11,
            'A': 12,
            'Joker': 0
        };

        this.gambleAreaSize = {w: 800, h: 600};
        this.gamble_cards = {
            top: '20',
            left: '9',
            height: '33.5'
        };
        this.gamble_cards_item = {
            margin_left: '0 0 0 0.5',
            width: '16.7'
        };
    };

    drawArea = ctx => {
        ctx.clearRect(0, 0, 800, 600);
        this.drawGambleTexts(ctx);
    };

    draw = showDealerCard => {
        App.Game.getStageChild('bonusContainer').removeChildren();
        const ctx = this.ctx;
        App.Game.Legends.setText('win', {text: 'win', value: this.prizeWin});
        App.Game.Legends.setStatus('selectCard');
        App.Game.Legends.showWinFeatures();
        this.setDoublingSeed(App.Game.gambleResponse);

        this.drawArea(ctx);
        (showDealerCard || this.step !== 0) && this.drawDealerCard(ctx);
    };

    showStatus = state => {
        App.Game.setState('GAMBLE_ANIMATION');
        let imageMonkey, imageCloud, sound;

        switch (state) {
            case 'w':
                sound = 'risk-win';
                imageMonkey = this.getProtection() ? 'gambleWinProt' : 'gambleWin';
                imageCloud = 'cloudWin';
                break;
            case 'l':
                sound = 'risk-lose';
                imageMonkey = this.getProtection() ? 'gambleLoseProt' : 'gambleLose';
                imageCloud = 'cloudLose';
                break;
            case 'f':
                sound = 'risk-forward';
                imageMonkey = this.getProtection() ? 'gambleForwardProt' : 'gambleForward';
                imageCloud = 'cloudForward';
                break;
        }

        App.Sounds.playSound(sound);
        const parentContainer = App.Game.getStageChild('bonusContainer');
        const sprite1 = App.Game.createChoosingElement(parentContainer, imageMonkey,                             // monkey
            App.Game.waitingSettings['DOUBLING'].w, App.Game.waitingSettings['DOUBLING'].h, // dw, dh // sw, sh
            App.Game.waitingSettings['DOUBLING'].x, App.Game.waitingSettings['DOUBLING'].y, 0, 4, 4, true, true);
        parentContainer.addChild(sprite1);

        const sprite2 = App.Game.createChoosingElement(parentContainer, imageCloud,                             // cloud
            220, 103, // dw, dh // sw, sh
            this.cloudx, this.cloudy, 0, 3, 3, true, true);
        parentContainer.addChild(sprite2);
    };

    setDefaultGambleButtons = () => {
        JL().debug('-- Set default gamble buttons state');
        App.View.setState(prevState => ({
            buttons: {
                ...prevState.buttons,
                // main game buttons
                start: {
                    ...prevState.buttons.start,
                    disabled: false,
                    title: 'collect',
                    handler: App.Game.finishGamble
                },
                select: {
                    ...prevState.buttons.select,
                    disabled: true
                },
                info: {
                    ...prevState.buttons.info,
                    disabled: true
                },
                autoStart: {
                    ...prevState.buttons.autoStart,
                    disabled: true
                },
                cardIndex: {
                    handler: this.choiceCard
                }
            }
        }));
    };

    drawGambleTexts = ctx => {
        ctx.fillStyle = '#ffff00';
        ctx.strokeStyle = '#000';
        ctx.lineWidth = 2;
        ctx.textAlign = 'center';
        ctx.font = '25px Arial';
    };

    restoreGambleScreen(response) {
        this.setDoublingSeed(response);
        App.updateState('buttons', {visualization: 'choosing'});
        App.Game.setState('DOUBLING');
        App.Game.setBackground('gambleArea');
        App.Game.hideBoxes();
        App.Game.hideLines();
        App.Game.Buttons.disableAllButtons();
        this.createComponent();
    }

    goToGamble = () => {
        const parentContainer = App.Game.getStageChild('boxesContainer');
        clearInterval(App.Game.lineAnimateInterval);
        App.updateState('buttons', {visualization: 'choosing'});
        App.Game.setBackground('gambleArea');
        App.Game.hideBoxes();
        App.Game.Lines.stopAnimateBoxes(parentContainer);
        JL().debug('-- Go to Gamble');
        App.Game.setState('DOUBLING');
        App.Game.stopAnimateFeature();
        App.Game.resetSymbolAnimation();
        App.Game.sendGamblePacket();
        App.updateButton('select', {disabled: true});
        App.updateButton('start', {disabled: true});
        App.updateButton('autoStart', {disabled: true});
    };

    drawDealerCard = ctx => {
        ctx.drawImage(
            App.Game.getAdditionalImage('cards'),
            this.getCardX(this.dealer), this.getCardY(this.dealer),
            this.cardWeigth, this.cardHeight,
            this.cardPositions[0][0], this.cardPositions[0][1],
            this.cardWeigth, this.cardHeight
        );
        ctx.drawImage(
            App.Game.getAdditionalImage('dealer'),
            this.cardPositions[0][0],
            this.cardPositions[0][1] + 200
        );
        this.drawGambleTexts(ctx);
    };

    showAllCard = response => {
        const ctx = this.ctx;
        App.Sounds.playSound('select-card');
        App.Game.Buttons.disableAllButtons();
        ctx.clearRect(0, 0, 800, 600);
        this.drawDealerCard(ctx);
        for (let i = 0; i <= 3; i++) {
            response.choice === i && ctx.drawImage(
                App.Game.getAdditionalImage('cards'),
                this.getCardX(this.cards[i]), this.getCardY(this.cards[i]),
                this.cardWeigth, this.cardHeight,
                this.cardPositions[i + 1][0], this.cardPositions[i + 1][1],
                this.cardWeigth, this.cardHeight
            );
        }
        ctx.drawImage(
            App.Game.getAdditionalImage('pick'),
            this.cardPositions[this.choice + 1][0], this.cardPositions[0][1] + 200
        );

        setTimeout(() => {
            JL().debug(`-- Gamble response '${response.status}'`);
            this.showStatus(response.status);
            for (let i = 0; i <= 3; i++) {
                response.choice !== i && ctx.drawImage(
                    App.Game.getAdditionalImage('cards'),
                    this.getCardX(this.cards[i]), this.getCardY(this.cards[i]),
                    this.cardWeigth, this.cardHeight,
                    this.cardPositions[i + 1][0], this.cardPositions[i + 1][1],
                    this.cardWeigth, this.cardHeight
                );
            }
            if (response.status === 'l') { // lose or exit on limitation
                this.prizeWin = 0;
                this.drawGambleTexts(ctx);
                App.Game.Legends.setStatus('gambleEnd');
                App.Game.Legends.setText('win', {text: 'win', value: this.prizeWin});
                setTimeout(() => {
                    App.Game.finishGamble();
                    App.Game.getStageChild('bonusContainer').removeChildren();
                }, 2000);
            } else {
                setTimeout(() => {
                    this.draw(true);
                    if (response.stepLeft === 0) {
                        App.Game.finishGamble();
                    } else {
                        App.Game.setState('DOUBLING');
                        this.setDefaultGambleButtons();
                    }
                }, 2000);
            }
        }, 1000);
    };

    getProtection = () => App.Game.gameSettings.getBetCredit() > App.Game.ProtectionMinBet;

    activateGambleButtons() {
        this.setDefaultGambleButtons();
    }

    deactivateGambleButtons() {
        App.updateButton('cardIndex', {img: 'inactive', disabled: true, handler: null});
        App.updateButton('start', {img: 'inactive', disabled: true});
    }
}
