import React from 'react';
import {JL} from 'jsnlog';

import App from './../index';

export default class Menu {
    scrollPos = {top: 0, left: 0};

    constructor() {
        this.getScrollPos();
    }

    /**
     * Set category and update visible games
     * @param name
     */
    setCategory(name) {
        JL().debug(`-- Change category to: '${name.toUpperCase()}'`);
        App.settings.categoryName = name;
        App.Cookie.set('categoryName', name);
        this.updateVisibleGames();
        this.setScrollPos(0, 0);
    }

    /**
     * Check correct cookie values
     */
    getScrollPos() {
        const cookieValue = App.Cookie.get('menuScrollPos', '0:0');
        let top = 0, left = 0;

        if (cookieValue.match(/^\d+:\d+/g)) {
            const positions = cookieValue.split(':');
            top = positions[0];
            left = positions[1];
        }

        this.setScrollPos(top, left);
    }

    /**
     * Save slider scroll pos
     * Store value in cookies
     * @param top
     * @param left
     */
    setScrollPos(top, left) {
        this.scrollPos = {top, left};
        App.Cookie.set('menuScrollPos', `${top}:${left}`);
    };

    /**
     * Update <Slider> with new games
     */
    updateVisibleGames() {
        App.updateState('slider', {visibleGames: App.games[App.settings.categoryName]});

        const slide = direction => {
            App.Sounds.playSound('click');
            const scrollWidth = (direction === 'left' ? -166 : 166) * 4;
            const slideScroll = document.getElementById('slide-scroll');
            const behavior = 'smooth';

            // check scroll direction for platforms
            App.System.platform === 'desktop' ?
                slideScroll?.scrollTo({top: slideScroll.scrollTop + scrollWidth, behavior}) :
                slideScroll?.scrollTo({left: slideScroll.scrollLeft + scrollWidth, behavior});

            // disable buttons for animation
            App.updateState('slider', {buttonLeftHandler: null, buttonRightHandler: null});
            setTimeout(addHandlers, 500);
        };

        const addHandlers = () => App.updateState('slider', {
            buttonLeftHandler: () => slide('left'),
            buttonRightHandler: () => slide('right')
        });

        addHandlers();
    }

    /**
     * Update <slider> element scroll pos
     * @param element
     */
    restoreScrollPos(element) {
        const {top, left} = this.scrollPos;
        element.scrollTop = top;
        element.scrollLeft = left;
    }

    /**
     * Restore web socket state, disable all buttons, show internet spinner
     */
    restoreWebSocket(fastReload) {
        App.showSpinner();
        if (fastReload) location.reload();
    }

    /**
     * Restored web socket action
     */
    webSocketRestored() {
        App.hideSpinner();
    }
}
