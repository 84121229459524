import {JL} from 'jsnlog';

import LinesEgt from './../egt/lines';

export default class Lines5 extends LinesEgt {
    constructor() {
        super();
        this.boxSettings.firstRightBox = 10;
        this.lines = {
            0: {coordinates: [1, 1, 1], lineDeltaY: 0, color: '#fff905'},
            1: {coordinates: [0, 0, 0], lineDeltaY: -20, color: '#faad05'},
            2: {coordinates: [2, 2, 2], lineDeltaY: 20, color: '#1cee19'},
            3: {coordinates: [0, 1, 2], lineDeltaY: 0, color: '#b62828'},
            4: {coordinates: [2, 1, 0], lineDeltaY: 0, color: '#960202'}
        };

        JL().debug('-- Lines5 initialized');
    }
}
