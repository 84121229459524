import * as PIXI from 'pixi.js-legacy';

import App from './../../index';
import GameNovomatic from './game';

/* PIXI aliases */
const Container = PIXI.Container,
    Text = PIXI.Text;

export default class GameHot extends GameNovomatic {
    constructor() {
        super();
        // text container positions
        this.paymentsContainerCoord = {x: 755, y: 102};
        this.symbolAnimation = false;

        // reel properties
        this.reels = 3;
        this.symbolHeight = 127; // height of aa single symbol
        this.symbolWidth = 169; // height of aa single symbol
        this.reelXCoordinates = [48, 233, 419, 583]; // magic numbers - x coordinates where reels starts

        // roll properties
        this.reelSettings = [14, 5, 10]; // 0 - start symbol amount, 1 - reel increase regular roll, 2 - reel increase long roll
    }

    updateGameSettingsStates() {
        const suffix = this.extraBetActive ? '+' : '';
        App.updateButton('lines', {value: this.gameSettings.getLinesNumber()});
        App.updateButton('bet', {value: this.gameSettings.getBetLineCredit() + suffix});
        App.updateButton('total', {value: this.gameSettings.getBetCredit()});
        this.drawPayments(this.getStageChild('waitingContainer'), this.paymentsContainerCoord);
    }

    /**
     * Draw additional symbols payments
     * @param parentContainer
     * @param x
     * @param y
     */
    drawPayments(parentContainer, {x, y}) {
        parentContainer.removeChild(parentContainer.getChildByName('paymentsContainer'));

        // draw payments
        const container = new Container();
        container.name = 'paymentsContainer';
        container.position.set(x, y);
        this.symbols.forEach((symbol, index) => {
            symbol = this.symbols[this.symbols.length - index - 1]; // revert position
            const payment = this.gameSettings.getBetLineCredit() * symbol.payment[3];
            const text = new Text(payment, {
                fontFamily: 'Arial',
                fontSize: 26,
                fill: '#ffcf00'
            });
            text.name = payment;
            text.anchor.set(0.5);
            text.position.y = index * 45;
            container.addChild(text);
        });
        parentContainer.addChild(container);
    }
}
