import * as PIXI from 'pixi.js-legacy';

import Lines from './lines';
import App from './../index';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Texture = PIXI.Texture,
    Text = PIXI.Text,
    Container = PIXI.Container;

export default class CustomLines extends Lines {
    constructor() {
        super();
        this.deltaY = 0;
        this.winBoxes = true;

        this.linesSettings = {
            lineThickness: 4,
            borderThickness: 6,
            horizontalLineLength: 60,
            borderColor: '#000000',
            lineColor: '#0f5532'
        };
        this.boxSettings = {
            firstRightBox: 10,
            boxMargin: 0,
            lineBoxWidth: 32,
            lineBoxHeight: 32,
            textOnBox: false,
            textType: null,
            typeBoxes: 'AllBoxesWithInactive',
            allBoxes: true,
            boxFontSize: 14,
            boxTextColor: '#000'
        };

        this.linesCanvas = document.createElement('canvas');
        this.linesCtx = this.linesCanvas.getContext('2d');

        this.canvasWinBox = document.createElement('canvas');
        this.ctxWinBox = this.canvasWinBox.getContext('2d');

        this.lineMap = [
            0, 1, 2, 3, 4,
            5, 6, 7, 8, 9
        ];
    }

    /**
     * Create PIXI.Container for lines
     * @param parentContainer
     */
    createLinesContainer = parentContainer => {
        const container = new Container();
        container.name = 'linesContainer';
        container.zIndex = App.Game.containersLayers[container.name];
        parentContainer.addChild(container);

        this.linesCanvas.width = App.Game.gameWidth;
        this.linesCanvas.height = App.Game.gameHeight;
        this.canvasWinBox.width = App.Game.gameWidth;
        this.canvasWinBox.height = App.Game.gameHeight;
    };

    getPointPosX = reelIndex => App.Game.reelXCoordinates[reelIndex] + App.Game.symbolWidth / 2;

    getPointPosY = rowIndex => App.Game.reelTop + App.Game.symbolHeight * (rowIndex + 0.5);

    setLinePath(lineIndex, reelIndex, rowIndex) {
        const {lineBoxHeight} = this.boxSettings;
        const line = this.lines[lineIndex];
        const {boxes, coordinates, lineDeltaY = 0} = line;

        const pointPosX = this.getPointPosX(reelIndex);
        const pointPosY = this.getPointPosY(rowIndex);
        const boxCenterY = boxes[0].y + lineBoxHeight / 2;
        const revReelIndex = this.isRightBox(lineIndex) ? // reverse line drawing for right boxes
            Math.abs(reelIndex - (App.Game.reels - 1)) : reelIndex;

        this.deltaY = reelIndex === 0 ? // set delta on path start\
            this.getPointPosY(coordinates[
                this.isRightBox(lineIndex) ? App.Game.reels - 1 : 0]) - boxCenterY :
            this.deltaY;

        line.path = line.path || [...Array(App.Game.reels)].map(() => ({}));
        line.path[revReelIndex] = {
            x: pointPosX,
            y: pointPosY - this.deltaY + lineDeltaY
        };
    }

    getLineStartPos(lineIndex) {
        const line = this.lines[lineIndex];
        const {boxMargin = 0, lineBoxHeight, lineBoxWidth} = this.boxSettings;

        return {
            x: !this.isRightBox(lineIndex) ? // set box positionX
                line.boxes[0].x + lineBoxWidth + boxMargin :
                line.boxes[0].x - boxMargin,
            y: line.boxes[0].y + lineBoxHeight / 2
        };
    }

    drawLine(ctx, lineIndex, reelIndex, color, thickness) {
        ctx.strokeStyle = color;
        ctx.lineWidth = thickness;
        const line = this.lines[lineIndex];
        const {x, y} = line.path[reelIndex];
        const {horizontalLineLength} = this.linesSettings;
        const lineEndCoord = this.isRightBox(lineIndex) ? -horizontalLineLength : horizontalLineLength;
        // draw main line
        ctx.lineTo(x, y);

        // if last line -> continue horizontal line
        reelIndex === App.Game.reels - 1 && ctx.lineTo(x + lineEndCoord, y);
        ctx.stroke();
    }

    /**
     * Create virtual <canvas> for draw lines
     * Convert canvas to sprite
     * @param lines
     * @param winReels
     * @param parentContainer
     * @param winLine
     * @param payment
     */
    drawLineImages(lines, winReels, parentContainer, winLine, payment) {
        const {lineThickness, borderThickness, borderColor, lineColor} = this.linesSettings;
        const sprite = Sprite.from(this.linesCanvas);
        sprite.texture.update();
        sprite.position.set(0, 0);
        sprite.zIndex = 0;
        this.linesCtx.clearRect(0, 0, this.linesCanvas.width, this.linesCanvas.height);
        lines.forEach(lineIndex => {
            const lineKey = this.lineMap.indexOf(lineIndex);
            const line = this.lines[lineIndex];
            parentContainer.sortableChildren = true;
            const {color = lineColor} = this.lines[lineKey];

            line.coordinates.forEach((rowIndex, reelIndex) =>
                this.setLinePath(lineIndex, reelIndex, rowIndex));

            // draw line by coordinates
            const {x, y} = this.getLineStartPos(lineIndex);
            this.linesCtx.beginPath();
            this.linesCtx.moveTo(x, y);
            line.coordinates.forEach((rowIndex, reelIndex) => {
                this.drawLine(this.linesCtx, lineIndex, reelIndex, borderColor, borderThickness);
                this.drawLine(this.linesCtx, lineIndex, reelIndex, color, lineThickness);
                winLine && this.drawPayments(parentContainer, payment, reelIndex, line, lineIndex);
            });
            this.linesCtx.closePath();
            sprite.name = lineIndex;
            winLine && this.winBoxes && (sprite.mask = App.Game.getLineMask(parentContainer, line, winReels));

            this.winBoxes && winReels.length &&
            this.drawWinBoxes(lineIndex, winReels, parentContainer, winLine, payment);
        });
        parentContainer.addChild(sprite);
    }

    /**
     * Create virtual <canvas> for draw win boxes
     * Convert canvas to sprite
     * @param lineIndex
     * @param winReels
     * @param parentContainer
     * @param winLine
     * @param payment
     */
    drawWinBoxes(lineIndex, winReels, parentContainer, winLine, payment) {
        const {lineThickness, borderThickness, borderColor, lineColor} = this.linesSettings;
        const lineKey = this.lineMap.indexOf(lineIndex);
        const line = this.lines[lineIndex];
        const {color = lineColor} = this.lines[lineKey];
        this.ctxWinBox.clearRect(0, 0, this.canvasWinBox.width, this.canvasWinBox.height);

        this.ctxWinBox.beginPath();
        // draw win boxes over win symbols
        line.coordinates.forEach((rowIndex, reelIndex) => {
            if (winReels.includes(reelIndex) && this.winBoxes) {
                this.drawWinBox(this.ctxWinBox, reelIndex, rowIndex, borderColor, borderThickness);
                this.drawWinBox(this.ctxWinBox, reelIndex, rowIndex, color, lineThickness);
            }
        });
        this.ctxWinBox.closePath();
        const sprite = Sprite.from(this.canvasWinBox);
        sprite.texture.update();

        sprite.position.set(0, 0);
        sprite.name = 'winBoxes';
        parentContainer.addChild(sprite);
    }

    drawWinBox = (ctx, reelIndex, rowIndex, color, thickness) => {
        ctx.strokeStyle = color;
        ctx.lineWidth = thickness;
        ctx.rect(
            App.Game.reelXCoordinates[reelIndex] + 3,
            App.Game.reelTop + App.Game.symbolHeight * rowIndex + 2,
            App.Game.symbolWidth - 6, App.Game.symbolHeight - 4
        );
        ctx.stroke();
    };

    drawBoxes(parentContainer, currentWinLine) {
        parentContainer.removeChildren(); // clear all previous boxes
        const currentLines = App.Game.gameSettings.getCurrentLineMas();
        const {lineBoxHeight, lineBoxWidth, textOnBox, textType, allBoxes = true} = this.boxSettings;
        const currentBet = App.Game.gameSettings.getBetLineCredit();

        Object.keys(allBoxes ? this.lines : currentLines).forEach(lineIndex => {
            this.lines[lineIndex].boxes.forEach(({x, y}) => {
                let activeLine;

                // drawing box with related winLine else draw all active lines
                currentWinLine === undefined ?
                    activeLine = currentLines.includes(+lineIndex + 1) :
                    activeLine = this.lineMap[lineIndex] === currentWinLine;

                const {xPos, yPos} = this.getBoxPos(lineIndex, activeLine);

                // create box sprite
                const sprite = new Sprite(new Texture(App.Game.getTexture('boxes'), {
                    x: xPos,
                    y: yPos,
                    width: lineBoxWidth,
                    height: lineBoxHeight
                }));

                sprite.position.set(x, y);
                sprite.name = lineIndex;
                activeLine && this.addBoxEvents(sprite);
                parentContainer.addChild(sprite);
                const text = textType === 'bet' ? currentBet : +lineIndex + 1;
                // add text to active box

                activeLine && textOnBox &&
                this.drawBoxText(parentContainer, text, x, y, lineBoxWidth, lineBoxHeight);
            });
        });
    }

    drawBoxText(parentContainer, text, x, y, lineBoxWidth, lineBoxHeight) {
        const {boxFontSize, boxTextColor} = this.boxSettings;
        const inscription = new Text(text, {
            fontFamily: 'Arial',
            fontSize: boxFontSize,
            fill: boxTextColor,
            lineJoin: 'round'
        });
        inscription.anchor.set(0.5);
        inscription.position.set(x + lineBoxWidth / 2, y + lineBoxHeight / 2);
        parentContainer.addChild(inscription);
    }

    isRightBox = lineIndex =>
        this.lineMap.indexOf(lineIndex) >= this.boxSettings.firstRightBox;

    getBoxPos(lineIndex, activeLine) {
        const {
            lineBoxHeight, lineBoxWidth,
            typeBoxes
        } = this.boxSettings;
        const lineKey = this.lineMap.indexOf(+lineIndex);
        let xPos, yPos;

        switch (typeBoxes) {
            case 'drawChosenBox':
                xPos = this.isRightBox(lineIndex) ? lineBoxWidth : 0;
                yPos = lineBoxHeight * lineKey;
                break;
            case 'AllBoxesWithInactive':
                xPos = activeLine ? lineBoxWidth : 0;
                yPos = lineIndex * lineBoxHeight;
                break;
            case 'OneBox':
                xPos = 0;
                yPos = 0;
                break;
            case 'TwoBoxes':
                xPos = 0;
                yPos = activeLine ? 0 :
                    lineBoxHeight;
                break;
        }
        return {xPos, yPos};
    }

    drawPayments(lineKey, winStartPos) {

    }

    destroy() {
        this.canvasWinBox.remove();
        this.linesCanvas.remove();
    }
}
