import React from 'react';

import App from './../index';
import Payout from './../components/payout';
import Profile from './../components/profile';
import Categories from './../components/categories';
import JackpotLayout from './../components/jackpotLayout';
import PasswordField from './../components/passwordField';
import Support from './../components/profile/support';
import GameSettings from './../components/gameSettings';
import countryCode from './../countryCode.js';

export default class Modal {
    /**
     * Create new <Modal> window
     * Describe params
     * @param name
     * @param params
     */
    create(name, params) {
        App.Sounds.playSound('click');
        App.updateState('modal', {[name]: params});
    }

    /**
     * Delete from collection
     * @param name
     */
    remove(name) {
        App.View.setState(({modal}) => {
            delete modal[name];
            return {modal};
        });
    }

    /**
     * Reset all <Modal> components
     */
    reset = () => App.View.setState({modal: App.View.getInitState().modal});

    /**
     * Show special instruction for installing PWA on iOS devices
     */
    showIOSInstruction = () =>
        this.create('iosInstruction', {
            content: ({lang}, close) => <div className='instruction-iOS'>
                <h1 className='header'>
                    {lang.howToInstall}
                </h1>
                <div className='row'>
                    <p className='text'>1. {lang.pressTheButton}</p>
                    <div className='imgStep'>
                        <img src={App.getUrl('/img/pwaModal/step_01.jpg')} alt='' draggable={false}/>
                    </div>
                </div>
                <div className='row'>
                    <div className='imgStep'>
                        <img src={App.getUrl('/img/pwaModal/step_02.jpg')} alt='' draggable={false}/>
                    </div>
                    <p className='text'>2. {lang.goToHomeScreen} 'Add to Home Screen'</p>
                </div>
                <div className='row'>
                    <p className='text'>3. {lang.confirmInstallation}</p>
                    <div className='imgStep'>
                        <img src={App.getUrl('/img/pwaModal/step_03.jpg')} alt='' draggable={false}/>
                    </div>
                </div>
                <button className='blue-button' onClick={close}>
                    OK
                </button>
            </div>
        });

    /**
     * Show <Profile> component
     * Update 'user' button
     */
    showProfile = () => {
        this.create('profile', {
            content: ({lang, profile, moneyParams}) =>
                <Profile profile={profile} lang={lang} moneyParams={moneyParams}/>,
            onClose: this.hideProfile
        });
        App.updateButton('controlButtons', {disabled: true});
        App.updateButton('user', {handler: this.hideProfile});
    };

    sendReedRequest(id) {
        close;
        App.User.request(`messages?status=READ&id=${id}`, 'put');
    }

    /**
     * Show <Profile> component
     * Update 'user' button
     */
    showProfileMes = () => {
        this.create('profile', {
            content: ({lang, profile, moneyParams}) =>
                <Profile profile={profile} lang={lang} moneyParams={moneyParams}/>,
            onClose: this.hideProfile
        });
        App.View.setState({showedMessage: true});
        App.updateButton('controlButtons', {disabled: true});
        App.updateButton('user', {handler: this.hideProfile});
        App.updateButton('message', {handler: this.hideProfile});
    };

    hideProfile = () => {
        this.remove('profile');
        App.updateButton('controlButtons', {disabled: true});
        App.updateButton('user', {handler: this.showProfile});
        App.updateButton('message', {handler: this.showProfileMes});
    };

    /**
     * Create modal with information about last payment
     * @param paymentStatus - success/failed
     */
    showPaymentStatus(paymentStatus) {
        this.create('paymentStatus', {
            content: ({lang}, close) => <form
                onClick={event => event.stopPropagation()}
            >
                <h2>{lang[paymentStatus === 'success' ? 'successOperation' : 'errorOperation']}</h2>
                <img
                    className='operation-image'
                    src={App.getUrl(`/img/deposit-withdraw/${paymentStatus}.png`)} alt=''
                    draggable={false}/>
                <div className='actions'>
                    <button className='ok blue-button' onClick={close}>OK</button>
                </div>
            </form>,
            zIndex: 30
        });
    }

    /**
     * Confirmation about log out
     */
    showLogOut = () => {
        const logOut = (event, close) => {
            event.preventDefault(); // prevent form submitting
            close();
            const {profile, loader} = App.View.getInitState();

            App.updateState('loader', {
                ...loader, active: true, fade: 'in',
                fadeEndCallback: () => {
                    App.updateState('profile', profile);
                    App.User.logOut();
                }
            });
        };

        this.create('logOut', {
            content: ({lang, serverRequest}, close) => <form
                className='logout-form'
                onClick={event => event.stopPropagation()}
                onSubmit={logOut}
            >
                <h2>{lang.logout} ?</h2>
                <div className='actions'>
                    <button className='logout blue-button' onClick={event => logOut(event, close)}>
                        <span
                            className='logout-icon'
                            style={{backgroundImage: `url(${App.getUrl('/img/registration/logout.png')})`}}
                        />
                        <span>{lang.confirmation}</span>
                    </button>
                    <button className='cancel yellow-button' onClick={close} disabled={serverRequest}>
                        {lang.cancel}
                    </button>
                </div>
            </form>
        });
    };

    /**
     * Show password fields for changing
     * Enter old password and confirm new
     */
    showChangePassword = () =>
        this.create('changePassword', {
            content: ({lang, serverRequest}, close) => <form
                onClick={event => event.stopPropagation()}
                onSubmit={App.User.changePassword}
            >
                <h2>{lang.changingPassword}</h2>
                <div className='input-field'>
                    <label htmlFor='old-password'>{lang.oldPassword}</label>
                    <PasswordField inputName='old-password' autoComplete='new-password'/>
                </div>
                <div className='input-field'>
                    <label htmlFor='new-password'>{lang.newPassword}</label>
                    <PasswordField inputName='new-password' autoComplete='new-password'/>
                </div>
                <div className='input-field'>
                    <label htmlFor='confirm-password'>{lang.confirmPassword}</label>
                    <PasswordField inputName='confirm-password' autoComplete='new-password'/>
                </div>
                <div className='actions'>
                    <button className='ok blue-button' type='submit' disabled={serverRequest}>
                        {lang.save}
                    </button>
                    <button className='cancel yellow-button' onClick={close}>
                        {lang.cancel}
                    </button>
                </div>
            </form>
        });

    /**
     * Enter new email and confirm password
     */
    showChangeEmail = (email) =>
        this.create('changeEmail', {
            content: ({lang, serverRequest}, close) => <form
                autoComplete='off'
                onClick={event => event.stopPropagation()}
                onSubmit={App.User.changeEmail}>

                <h2>{lang.changingEmail}</h2>
                <div className='input-field'>
                    <label htmlFor='new-email'>{lang.changeEmail}</label>
                    <input
                        type='email'
                        name='new-email'
                        autoComplete='new-password' // preventing autocomplete
                        className='no-clear-focus input-styles'
                        maxLength={30}
                        defaultValue={email}
                    />
                </div>
                <div className='input-field'>
                    <label htmlFor='confirm-password'>{lang.confirmPassword}</label>
                    <PasswordField inputName='password' autoComplete='new-password'/>
                </div>
                <div className='actions'>
                    <button className='ok blue-button' type='submit' disabled={serverRequest}>
                        {lang.confirmation}
                    </button>
                    <button className='cancel yellow-button' onClick={close} disabled={serverRequest}>
                        {lang.cancel}
                    </button>
                </div>
            </form>
        });

    /**
     * Show password fields for changing
     * Enter old password and confirm new
     */
    showMessage = (text, id, status) =>
        this.create('changePassword', {
            content: ({lang, serverRequest}, close) => <form
                onClick={close}
                onSubmit={App.User.changePassword}
            >
                <h2>{text}</h2>

                <div className='actions'>
                    <button className='ok blue-button' onClick={function() {
                        close;
                        App.Modal.sendReedRequest(id);
                    }}>
                        {lang.ok}
                    </button>
                </div>
            </form>
        });

    /**
     * Enter new phone and confirm password
     */
    showChangePhone = (phone, dialCode) =>
        this.create('changePhone', {
            content: ({lang, serverRequest}, close) => <form
                autoComplete='off'
                onClick={event => event.stopPropagation()}
                onSubmit={App.User.changePhone}>

                <h2>{lang.changingPhone}</h2>
                <div className='input-field'>
                    <label htmlFor='new-phone'>{lang.changePhone}</label>
                    <select
                        key={dialCode}
                        className='no-clear-focus input-field tcode'
                        defaultValue={dialCode}
                        onChange={App.User.changetCode}
                    >
                        {countryCode.map(({name, code, dialCode}, index) =>
                            <option key={index} value={dialCode}>{`${dialCode} ${name}`}</option>)}
                    </select>

                    <input
                        type='tel'
                        name='new-phone'
                        autoComplete='new-password' // preventing autocomplete
                        className='input-field no-clear-focus input-styles phone'
                        maxLength={30}
                        defaultValue={phone}
                    />
                </div>
                <div className='input-field'>
                    <label htmlFor='confirm-password'>{lang.confirmPassword}</label>
                    <PasswordField inputName='password' autoComplete='new-password'/>
                </div>
                <div className='actions'>
                    <button className='ok blue-button' type='submit' disabled={serverRequest}>
                        {lang.confirm}
                    </button>
                    <button className='cancel yellow-button' onClick={close} disabled={serverRequest}>
                        {lang.cancel}
                    </button>
                </div>
            </form>
        });

    /**
     * Enter new phone and confirm password
     */
    confirmPhone = () =>
        this.create('confirmPhone', {
            content: ({lang, serverRequest}, close) => <form
                autoComplete='off'
                onClick={event => event.stopPropagation()}
                onSubmit={App.User.confirmPhone}
            >
                <h2>{lang.confirmCode}</h2>
                <div className='input-field'>
                    <label htmlFor='new-phone'>{lang.confirmCode}</label>
                    <input
                        type='number'
                        name='code'
                        autoComplete='new-password' // preventing autocomplete
                        className='no-clear-focus input-styles'
                        maxLength={6}
                    />
                </div>
                <div className='actions'>
                    <button className='ok blue-button' type='submit' disabled={serverRequest}>
                        {lang.confirm}
                    </button>
                    <button className='cancel yellow-button' onClick={close} disabled={serverRequest}>
                        {lang.cancel}
                    </button>
                </div>
            </form>
        });

    /**
     * Show currency exchange operation details
     * @param oldBalance
     * @param newBalance
     * @param from
     * @param to
     * @param course
     */
    showChangeCurrency = ({oldBalance, newBalance, from, to, course}) =>
        this.create('changeCurrency', {
            content: ({lang, serverRequest}, close) => <form
                onClick={event => event.stopPropagation()}
                onSubmit={event => App.User.confirmChangeCurrency(event, to)}
            >
                <h2>{lang.currencyConversion}</h2>
                <div className='container'>
                    <span>
                        <div>{lang.currentBalance} {oldBalance}</div>
                        <div>{lang.from} {from}</div>
                    </span>

                    <span className='blue-shadow blue-color arrow'>→</span>
                    <span>
                        <div>{lang.newBalance} {newBalance}</div>
                        <div>{lang.to} {to}</div>
                    </span>
                </div>
                <div className='coefficient'>{lang.coefficient} {course}</div>

                <div className='actions'>
                    <button className='ok blue-button' type='submit' disabled={serverRequest}>
                        {lang.confirm}
                    </button>
                    <button className='cancel yellow-button' onClick={close} disabled={serverRequest}>
                        {lang.cancel}
                    </button>
                </div>
            </form>
        });

    /**
     * Show <Support> component
     */
    showSupport = () =>
        this.create('support', {
            content: ({lang}, close) => <form onSubmit={event => event.preventDefault()}>
                <Support lang={lang}/>
                <div className='actions'>
                    <button className='blue-button' onClick={close}>
                        OK
                    </button>
                </div>
            </form>
        });

    /**
     * Show <GameSettings> component
     */
    showGameSettings = () =>
        this.create('gameSettings', {
            content: ({lang, buttons, moneyParams}) => <GameSettings
                lang={lang} buttons={buttons} moneyParams={moneyParams}
            />,
            wrapper: false,
            animationClass: 'item-up',
            zIndex: 10,
            onClose: App.Game.closeGameSettings
        });

    /**
     * Show payment details for withdraw and deposit
     * @param amount
     * @param cardNumber
     * @param type - 'deposit' / 'withdraw'
     */
    showConfirmationPayment = ({amount, cardNumber}, type) => {
        const {currency, method} = App.User.get().payment;

        this.create('paymentConfirmation', {
            content: ({lang, serverRequest}, close) =>
                <div className='payment-container'>
                    <h2>{lang.paymentAccept}</h2>
                    {type === 'deposit' ?
                        <span className='payment-container-info'>
                            {lang.amountDepositSelect}: {amount} {currency.alias}
                        </span> :
                        <>
                            <span className='payment-container-info'>
                                {lang.payoutAmount}: {amount} {currency.alias}
                            </span>
                            <span className='payment-container-info'>
                                {lang.cardNumber}: {cardNumber}
                            </span>
                        </>
                    }
                    <span className='payment-container-info'>{lang.paymentType}: {method.name}</span>

                    <div className='actions'>
                        <button
                            className='ok blue-button' type='submit' disabled={serverRequest}
                            onClick={() => App.User.payment({amount, cardNumber}, type)}
                        >
                            {lang.confirm}
                        </button>
                        <button className='cancel yellow-button' onClick={close}>
                            {lang.cancel}
                        </button>
                    </div>
                </div>,
            zIndex: 30
        });
    };

    /**
     * Show <Categories> component
     */
    showCategories = () => {
        App.Sounds.playSound('click');
        this.create('categories', {
            content: () => <Categories/>,
            wrapper: false,
            animationClass: 'item-up',
            zIndex: 21
        });
    };

    /**
     * Show <JackpotLayout> component
     */
    showJackpots = () => {
        App.Sounds.playSound('click');

        // prepare data for <JackpotLayout>, convert value
        const jackpotsData = Object.keys(App.Money.jackpot).map((key, index) =>
            ({jackpot: {type: key, amount: App.Money.jackpot[key].value * 100}, index}));

        this.create('jackpots', {
            content: () => <JackpotLayout jackpotsData={jackpotsData}/>,
            wrapper: false,
            animationClass: 'item-up'
        });
    };

    /**
     * Show <Payout> component
     */
    showPayout() {
        App.LocalSocket.updateBillAcceptor('ENABLE');
        App.updateState('slider', {activePayout: true, payoutHandler: App.LocalSocket.payout});
        this.create('payout', {
            content: ({lang, slider}) => <Payout lang={lang} slider={slider}/>,
            onClose: () => {
                App.LocalSocket.updateBillAcceptor('DISABLE');
                App.updateState('slider', {
                    activePayout: false, payoutHandler: null,
                    payoutAmount: '', payoutMessage: ''
                });
            }
        });
    }

    showInfoBonus = (typeOfBonus, lang) => {
        this.create('info-bonus', {
            content: () =>
                <div className='bonus-modal'>
                    <header>
                        <h1>{lang[typeOfBonus].title}</h1>
                    </header>
                    <section>
                        <span style={{textAlign: 'left'}}>
                            <p>{lang[typeOfBonus].description.punkt1}</p>
                            <p>{lang[typeOfBonus].description.punkt2}</p>
                            <p>{lang[typeOfBonus].description.punkt3}</p>
                        </span>
                    </section>
                </div>,

            onClose: () => {
                App.LocalSocket.updateBillAcceptor('DISABLE');
                App.updateState('slider', {
                    activePayout: false, payoutHandler: null,
                    payoutAmount: '', payoutMessage: ''
                });
            }
        });
    };

    bonusAccept = (lang, typeOfBonus) => {
        this.create('bonus-accept', {
            content: () =>
                <div className='bonus-accept'>
                    <header>
                        <h1>{lang[typeOfBonus].accept}</h1>
                    </header>
                    <section>
                        <button className='blue-button' onClick={close}>
                            OK
                        </button>

                    </section>
                </div>,

            onClose: () => {
                App.LocalSocket.updateBillAcceptor('DISABLE');
                App.updateState('slider', {
                    activePayout: false, payoutHandler: null,
                    payoutAmount: '', payoutMessage: ''
                });
            }
        });
    };
}
