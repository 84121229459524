import {JL} from 'jsnlog';
import CustomLines from './../../CustomLines';
import App from '../../../index';

export default class Lines extends CustomLines {
    constructor() {
        super();
        this.imageResources = {};
        this.boxSettings = {
            ...this.boxSettings,
            firstRightBox: 15,
            lineBoxWidth: 0,
            lineBoxHeight: 0,
            horizontalLineLength: 50
        };

        this.linesSettings = {
            ...this.linesSettings,
            borderColor: '#000000',
            lineColor: '#f6c500'
        };

        this.winBoxes = false;

        this.lines = {
            0: {coordinates: [1, 1, 1, 1, 1], boxes: [{x: 60, y: 5}]},
            1: {coordinates: [0, 0, 0, 0, 0], boxes: [{x: 60, y: 11}]},
            2: {coordinates: [2, 2, 2, 2, 2], boxes: [{x: 60, y: 16}]},
            3: {
                coordinates: [0, 1, 2, 1, 0], boxes: [{x: 60, y: -14}],
                points: {2: -50, 4: 50}
            },
            4: {
                coordinates: [2, 1, 0, 1, 2], boxes: [{x: 60, y: 21}],
                points: {2: 50, 4: -50}
            },
            5: {
                coordinates: [1, 0, 1, 2, 1], boxes: [{x: 895, y: -30}],
                points: {3: -50}
            },
            6: {
                coordinates: [1, 2, 1, 0, 1], boxes: [{x: 895, y: -19}],
                points: {3: 50}
            },
            7: {
                coordinates: [0, 0, 1, 2, 2], boxes: [{x: 895, y: -40}],
                points: {3: -50}
            },
            8: {
                coordinates: [2, 2, 1, 0, 0], boxes: [{x: 895, y: 28}],
                points: {3: 50}
            },
            9: {coordinates: [0, 1, 0, 1, 0], boxes: [{x: 895, y: 0}]},
            10: {coordinates: [2, 1, 2, 1, 2], boxes: [{x: 895, y: -5}]},
            11: {coordinates: [1, 0, 0, 0, 1], boxes: [{x: 60, y: -6}]},
            12: {coordinates: [1, 2, 2, 2, 1], boxes: [{x: 60, y: 22}]},
            13: {coordinates: [0, 1, 1, 1, 0], boxes: [{x: 60, y: 43}]},
            14: {coordinates: [2, 1, 1, 1, 2], boxes: [{x: 60, y: -19}]},
            15: {coordinates: [1, 1, 0, 1, 1], boxes: [{x: 895, y: -50}]},
            16: {coordinates: [1, 1, 2, 1, 1], boxes: [{x: 895, y: 11}]},
            17: {coordinates: [0, 2, 0, 2, 2], boxes: [{x: 895, y: -37}]},
            18: {
                coordinates: [2, 0, 2, 0, 2], boxes: [{x: 60, y: 10}]},
            19: {coordinates: [2, 0, 1, 0, 2], boxes: [{x: 895, y: 1}]},
            20: {coordinates: [0, 2, 1, 2, 0], boxes: [{x: 60, y: 53}]},
            21: {
                coordinates: [0, 2, 2, 2, 0], boxes: [{x: 895, y: -45}],
                points: {2: 0}
            },
            22: {coordinates: [2, 0, 0, 0, 2], boxes: [{x: 60, y: -55}]},
            23: {coordinates: [1, 0, 2, 0, 2], boxes: [{x: 60, y: 29}]},
            24: {coordinates: [1, 2, 0, 2, 1], boxes: [{x: 895, y: -13}]},
            25: {coordinates: [0, 0, 2, 0, 0], boxes: [{x: 895, y: 36}]},
            26: {coordinates: [2, 2, 0, 2, 2], boxes: [{x: 895, y: 40}]},
            27: {coordinates: [1, 0, 1, 0, 1], boxes: [{x: 60, y: -41}]},
            28: {coordinates: [1, 2, 1, 2, 1], boxes: [{x: 895, y: 16}]},
            29: {
                coordinates: [2, 2, 2, 1, 0], boxes: [{x: 60, y: -9}],
                points: {4: 50}
            }
        };

        this.lineMap = [
            0, 1, 2, 3, 4, 11, 12, 13, 14, 18, 20, 22, 23, 27, 29,
            5, 6, 7, 8, 9, 10, 15, 16, 17, 19, 21, 24, 25, 26, 28
        ];

        JL().debug('-- Lines30 initialized');
    }

    drawBoxes() {

    }

    /**
     * Create virtual <canvas> for draw lines
     * Convert canvas to sprite
     * @param lines
     * @param winReels
     * @param parentContainer
     * @param winLine
     * @param payment
     */
    drawLineImages(lines, winReels, parentContainer, winLine, payment) {
        parentContainer.removeChildren();
        this.linesCtx.clearRect(0, 0, this.linesCanvas.width, this.linesCanvas.height);

        lines.forEach(lineIndex => {
            parentContainer.sortableChildren = true;

            const line = this.lines[lineIndex];

            const spineLine = new window.PIXI.spine.Spine(App.Game.getJsonTextures('line'));
            spineLine.name = lineIndex;
            const typeOfAnimation = winLine ? 'animation' : 'static';
            spineLine.position.set(107, typeOfAnimation === 'animation' ? 0 : line.boxes[0].y);
            spineLine.state.setAnimation(0, typeOfAnimation, true);
            for (let i = 1; i <= 5; i++) {
                const bone = spineLine.skeleton.findBone(`LinePoint${i}`);
                bone.y = -(App.Game.reelTop + App.Game.symbolHeight * (line.coordinates[i - 1] + 0.5));
            }

            line.points && Object.keys(line.points).forEach(point => {
                const bone = spineLine.skeleton.findBone(`LinePoint${point}`);
                bone.rotation = line.points[point];
            });
            parentContainer.addChild(spineLine);
        });
    }
}
