import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import PragmaticGames from './../../pragmatic/game';
import InfoScreen from './../../infoScreen';
import App from '../../../index';
import Gamble from '../witch-hunters/gamble';
import Lines10 from './lines';

/* PIXI aliases */
const Container = PIXI.Container,
    Sprite = PIXI.Sprite;

export default class ThreeStarFortune extends PragmaticGames {
    constructor() {
        super();
        this.id = 'three-star-fortune';
        this.name = 'Three star fortune';

        this.imageResources = {
            main: this.mergePath({
                introButton: `area/introButton.png`
            }),
            atlas: this.mergePath(['staticSymbols.json']),
            jsonAnimations: this.mergePath({
                background: 'area/Background.json',
                sym9: 'Sym03.json',           // monk
                sym8: 'Sym02.json',           // monk with scroll
                sym7: 'Sym01.json',           // monk with pigtails
                sym6: 'Sym04.json',           // hat
                sym5: 'Sym06.json',           // bud
                sym4: 'Sym07.json',           // scroll
                sym3: 'Sym05.json',           // bottle
                smallSymbols: 'Sym_sign.json', // red blue pink
                winBox: 'lines/Winbox.json',
                intro: 'area/Start_Info.json',
                longRoll: 'LongRoll.json'
            })
        };

        this.reelSettings = [18, 5, 20];
        this.offsetReelMask = {
            offsetX: 0, offsetY: 0,
            offsetWidth: 0, offsetHeight: -3
        };
        this.freezeReels = [];
        this.scatter = 7;
        this.wilds = [7, 8, 9];
        this.isregularSymbols = false;
        this.gameFieldWidth = 960;
        this.gameWidth = App.System.resolution === '4x3' ? this.gameFieldWidth : 1280;
        this.gameFieldHeight = 720;
        this.gameHeight = App.configs.doubleScreen ? 1440 : 720;
        this.symbolWidth = 180;
        this.symbolHeight = 158;
        this.reelXCoordinates = [30, 210, 390, 570, 750];
        this.reelTop = 105;
        this.reelsAlradyhaveExpandedSpine = [];
        this.allowLongRoll = true;
        this.introSound = false;
        this.lastRespin = false;
        this.containersLayers = {
            reelsStage: 1,
            mainContainer: 0,
            linesContainer: 2,
            boxesContainer: 4,
            extraBetContainer: 5,
            infoContainer: 7,
            bonusContainer: 6,
            symbolInfo: 8
        };
        this.defaultFeatureDelay = 2000;
        this.symbols = [
            {regularDelay: 50, payment: [0, 0, 0, 5, 10, 25]},           // 0 - красный
            {regularDelay: 50, payment: [0, 0, 0, 5, 10, 25]},           // 1 - синий
            {regularDelay: 50, payment: [0, 0, 0, 7, 15, 40]},           // 2 - розовый
            {regularDelay: 50, payment: [0, 0, 0, 8, 20, 50]},           // 3 - флакон
            {regularDelay: 50, payment: [0, 0, 0, 10, 25, 60]},         /// 4 - свиток
            {regularDelay: 50, payment: [0, 0, 0, 25, 60, 120]},         // 5 - бутон
            {regularDelay: 50, payment: [0, 0, 0, 50, 200, 250]},       // 6 - шапка
            {regularDelay: 50, payment: [0, 0, 0, 0, 0, 0]},          // 7 - монах
            {regularDelay: 50, payment: [0, 0, 0, 0, 0, 0]},          // 8 - монах
            {regularDelay: 50, payment: [0, 0, 0, 0, 0, 0]}          // 9 - монах
        ];

        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [
                {name: 'reelsstop'},
                {name: 'background_idle', loop: true},
                {name: 'sound_fx', loop: true},
                {name: 'background', loop: true},
                {name: 'add-credit-background', loop: 'true'},
                {name: 'expand_wild_1'},
                {name: 'expand_wild_2'},
                {name: 'expand_wild_3'},
                {name: 'ony-by-one-features'}

            ],
            path: `/game/games/${this.id}/audio/`
        };
        this.Lines = new Lines10();
        this.Gamble = new Gamble();
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
        this.InfoScreen.format = 'png';
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '14pt Arial bolder';
        ctx.textAlign = 'center';
        ctx.fillStyle = 'white';

        switch (page) {
            case 1:
                // красный
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 695, 395);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 695, 412);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 695, 429);

                // синий
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 490, 395);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 490, 412);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 490, 429);

                // розовый
                this.strokeFillText(ctx, bet * this.symbols[2].payment[5], 280, 395);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[4], 280, 412);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[3], 280, 429);

                // флакон
                this.strokeFillText(ctx, bet * this.symbols[3].payment[5], 802, 226);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[4], 802, 245);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[3], 802, 262);

                // свиток
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 595, 226);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 595, 245);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 595, 262);

                // бутон
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 382, 226);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 382, 245);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 382, 262);

                // шапка
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 175, 226);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 175, 245);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 175, 262);
        }
    }

    /**
     * Start game animations
     */
    showGameIntroduction() {
        JL().debug('-- Start game intro animation');
        const ticker = this.app.ticker;
        this.setState('GAME_INTRO');
        this.Buttons.disableAllButtons();
        App.updateButton('autoStart', {disabled: true});
        App.updateState('buttons', {animation: 'hide'});

        const parentContainer = this.getStageChild('bonusContainer');
        parentContainer.sortableChildren = true;
        parentContainer.interactive = true;
        const introSpine = new window.PIXI.spine.Spine(this.getJsonTextures('intro'));
        introSpine.name = 'introSpine';
        introSpine.position.set(-(App.Game.gameWidth - App.Game.gameFieldWidth) / 2, 0);
        introSpine.state.setAnimation(0, 'animation', true);

        parentContainer.addChild(introSpine);

        const sprite2 = new Sprite(this.getTexture('introButton'));
        sprite2.name = 'introButton';
        sprite2.position.set(885, 423);
        sprite2.zIndex = 20;
        sprite2.interactive = true;
        parentContainer.addChild(sprite2);

        sprite2.on('pointerdown', () => {
            this.playIdleBackgroundSound();

            App.updateState('buttons', {animation: 'show'});
            parentContainer.interactive = false;
            parentContainer.removeChildren();
            this.roundFinished();
            const ticker = this.app.ticker;
            ticker.remove(func);
        });
        let way = 0;
        sprite2.anchor.set(0.5);

        const func = () => {
            if (sprite2.scale.x >= 1.1 && !way) {
                way = 1;
            }
            if (sprite2.scale.x <= 1 && way) {
                way = 0;
            }
            if (!way) {
                sprite2.scale.x += 0.005;
                sprite2.scale.y += 0.005;
            } else {
                sprite2.scale.x -= 0.005;
                sprite2.scale.y -= 0.005;
            }
        };
        ticker.add(func);
    }

    playIdleBackgroundSound = () => {
        this.introSound = true;
        App.Sounds.playSound('background_idle');
        App.Sounds.playSound('sound_fx');
    };

    playRollSound = () => {
    };

    /**
     * Send uc: 'ROLL' message by WebSocket
     */
    sendRoll() {
        this.setState('WAITING_RESPONSE');
        App.System.sendMetric({param: `rollCount.${this.id}`});
        App.System.sendMetric({param: `rollPlatform.${App.System.platform}`});
        App.System.sendMetric({param: `rollOrientation.${App.Wrapper.getOrientation()}`});

        if (this.introSound) {
            this.introSound = false;
            App.Sounds.stopSound('background_idle');
            this.playBackgroundSound();
        }

        const message = {
            uc: 'ROLL',
            bet: this.gameSettings.getBetLineCredit(),
            lines: this.gameSettings.getLinesNumber(),
            denomination: App.Money.getCurrentDenomination()
        };
        this.extraBet && (message.extraBet = this.extraBetActive);

        App.Socket.send(JSON.stringify(message));
    }

    restoreRoll() {
        JL().debug(`-- Restore roll - ${JSON.stringify(this.latestResponse)}`);
        this.tickerTimeout(() => this.playBackgroundSound(), 500);
        App.Sounds.playSound('sound_fx');
        this.Legends.showJackpot();
        this.processReelResponse(this.latestResponse);
    }

    playFeatureSound(currentFeature, featureIndex, features) {

    }

    /**
     * Create PIXI.Container for game background
     * Add additional sprites to container
     * @param parentContainer
     */
    createMainContainer(parentContainer) {
        const container = new Container();
        container.name = 'mainContainer';
        container.zIndex = this.containersLayers[container.name];
        parentContainer.addChild(container);

        const backgroundSpine = new window.PIXI.spine.Spine(this.getJsonTextures('background'));
        backgroundSpine.name = 'backgroundSpine';
        backgroundSpine.position.set(-(App.Game.gameWidth - App.Game.gameFieldWidth) / 2, 0);
        backgroundSpine.state.setAnimation(0, 'tsf_basegame_idle', true);
        container.addChild(backgroundSpine);

        const longRollSpine = new window.PIXI.spine.Spine(this.getJsonTextures('longRoll'));
        longRollSpine.name = 'longRollSpine';
        longRollSpine.position.set(-(App.Game.gameWidth - App.Game.gameFieldWidth) / 2, 0);
        container.addChild(longRollSpine);
    }

    /**
     * Change symbol index when it is necessary.
     * For example, in Columbus and Gladiators games
     * @param symbolIndex
     * @param reelIndex
     */
    getSymbolIndex = (symbolIndex, reelIndex) =>
        symbolIndex === this.wilds[0] && [1, 2, 3].includes(reelIndex) ?
            this.wilds[[1, 2, 3].findIndex((item) => reelIndex === item)] :
            symbolIndex;

    /**
     * Function to start logic of Animate feature first step
     * @param features
     */
    startAnimateFeature(features) {
        JL().debug('-- Start animate feature');
        // change handler to stop animation win line
        if (!this.isBonus() && !this.latestResponse.extension) {
            App.updateButton('start', {
                disabled: true, title: 'stop',
                handler: this.speedUpWinLineAnimation
            });
        }
        this.Legends.showWinFeatures();
        App.Sounds.pauseSound('bonus-background');
        this.prepareToAnimateFeature(features);
    }

    takeWin = (isTransfer = true) => {
        this.stopWaitingAnimation();
        this.stopAnimateSound();
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        JL().debug(`-- Take win (isTransfer: ${isTransfer})`);
        this.setState('TAKE_WIN');

        if (isTransfer && App.restoreGameState !== 'TRANSFER') {
            App.Socket.send(JSON.stringify({uc: 'TRANSFER-START'}));
        }
        !isTransfer && this.animateCredits(this.latestResponse.payment, isTransfer);
        App.Sounds.playSound('add-credit-background');

        this.Buttons.disableAllButtons();
        App.updateButton('start', {
            disabled: false,
            title: 'collect',
            handler: () => { // User can press 'start' twice for increasing transfer speed
                this.isSpeedUp !== 2 && this.isSpeedUp++;
                JL().debug(`-- Take win speed up x${this.isSpeedUp + 1}`);
                // TODO after repeat click -> take all win immediately
            }
        });
    };

    /**
     * Function to play animate credit sound
     */
    playCreditSound() {
    }

    /**
     * Function to stop animate credit sound
     */
    stopCreditSound = () => {
        App.Sounds.stopSound('add-credit-background');
    };

    /**
     * Function to show line for special feature
     * @param currentFeature
     */
    showFeatureLine(currentFeature) {

    }

    /**
     * Animate feature first step
     */
    prepareToAnimateFeature(features) {
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        this.features.step = 0;
        const indexesOfFeatures = {};
        const uniqueIndexes = new Map();

        // check 'WIN_LINE' feature contain
        const isWinLine = features.some(features => features.uc === 'WIN_LINE');
        this.updateExtraSymbols(isWinLine);

        // reset all animations and turn on shadows
        this.reelMatrix.forEach(reel => {
            reel.forEach(symbolObj => {
                // don't hide symbols if only scatter feature
                symbolObj.sprite.alpha = isWinLine && this.symbolEffects ? 0.5 : 1;
                symbolObj.sprite.gotoAndStop(0);
            });
        });

        // unique preparing for each game
        this.additionalPreparingToAnimateFeature(features);
        // initialize symbols on reelMatrix
        features.forEach((feature, i) => {
            indexesOfFeatures[i] = [];

            switch (feature.uc) {
                case 'WIN_LINE':
                    feature.reels.forEach((reelIndex, index) => {
                        const rowIndex = this.Lines.lines[feature.number].coordinates[reelIndex];
                        indexesOfFeatures[i].push({reelIndex, rowIndex});
                        uniqueIndexes.set(`${reelIndex}${rowIndex}`, {reelIndex, rowIndex});
                    });

                    break;
                case 'SCATTER':
                    feature.positions.forEach(({reel, row}) => {
                        indexesOfFeatures[i].push({reelIndex: reel, rowIndex: row});
                        uniqueIndexes.set(`${reel}${row}`, {reelIndex: reel, rowIndex: row});
                    });
                    break;
            }
        });
        this.tickerTimeout(() => this.playAllFeatures(uniqueIndexes, indexesOfFeatures), 100);
    }

    playAllFeatures(uniqueIndexes, indexesOfFeatures) {
        JL().debug('playAllFeatures');
        const {extension} = this.latestResponse;
        const payment = extension ? extension.totalPayment : this.latestResponse.payment;
        this.stopAnimateFeature();
        this.Legends.setText('win', {text: 'win', value: payment});

        let i = 1;
        uniqueIndexes.forEach(({reelIndex, rowIndex}) => {
            const symbolObj = this.reelMatrix[reelIndex][rowIndex];
            const smallSymbols = [0, 1, 2].includes(symbolObj.symbol);
            const monks = [7, 8, 9].includes(symbolObj.symbol);
            symbolObj.sprite.renderable = false;
            if (!monks) {
                symbolObj.animation = smallSymbols ? `sym${symbolObj.symbol}` : 'animation';
                const textures = smallSymbols ?
                    // our small symbol write in smallSymbols json so if symbol 1,2 or 3 get textures from its json
                    this.getSpineData(`smallSymbols`) :
                    this.getSpineData(`sym${this.reelMatrix[reelIndex][rowIndex].symbol}`);
                symbolObj.spine = new window.PIXI.spine.Spine(textures);
                symbolObj.symbolContainer.addChild(symbolObj.spine);
                symbolObj.spine.state.setAnimation(0, symbolObj.animation, false);
                symbolObj.spine.position.set(this.symbolWidth / 2, this.symbolHeight / 2);
            }

            if (extension) {
                const {jokerReels, screen} = extension;
                if (jokerReels.includes(reelIndex)) {
                    const spineRowPlaceIndex = this.findSpineRowPlaceIndex(reelIndex, screen);
                    const bonusContainer = this.getStageChild('bonusContainer');
                    symbolObj.spine.renderable = false;
                    symbolObj.spine = bonusContainer.getChildByName(reelIndex).getChildAt(0);
                    symbolObj.animation = `Sym_1x3_win_${spineRowPlaceIndex + 1}`;
                    if (symbolObj.spine.state.hasAnimation(symbolObj.animation)) {
                        symbolObj.spine.state.setAnimation(0, symbolObj.animation, false);
                    }

                    if (monks) {
                        symbolObj.spine.renderable = true;
                    }
                }
            }

            this.Lines.drawWinBox(symbolObj.symbolContainer);
            if (uniqueIndexes.size === i) {
                this.tickerTimeout(() => {
                    symbolObj.spine.state.clearListeners();
                    const {extension} = this.latestResponse;
                    if (extension && extension.isNextRollReSpin) {
                        this.drawBonusAskButton(true);
                    } else {
                        this.freezeReels = [];
                        this.tickerTimeout(() => {
                            this.animateFeature(this.latestResponse.features);
                            this.playOnyByOneFeatures(indexesOfFeatures, 0);
                            App.updateButton('start', {
                                disabled: false, title: 'stop',
                                handler: this.speedUpWinLineAnimation
                            });
                        }, 500);
                    }
                }, this.defaultFeatureDelay);
            }
            i++;
        });
    }

    /**
     * Function to calculate total roll win (add all features from roll)
     * @param feature
     */
    calcWinSum(feature) {
        const {uc, payment} = feature; // get current feature params
        const {extension} = this.latestResponse;

        if (!['SPECIAL_SYMBOL', 'FREE_ROLL', 'CHOOSING'].includes(uc)) {
            // ignore payments incrementing after big win
            if (this.getState() !== 'SHOW_BIG_WIN_LINES') {
                this.bonusWin += payment || 0;
                this.roundWin += payment || 0;
            }

            if (extension && !extension.isNextRollReSpin) {
                this.Legends.setText('win', {text: 'win', value: extension.totalPayment});
            } else {
                this.Legends.setText('win', {text: 'win', value: this.bonusWin});
                this.roundWin && this.Legends.setStatus('creditsWon', this.roundWin);
            }
        }
    }

    /**
     * End feature animation / take win without prompt
     */
    endAnimateFeature() {
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        App.Sounds.stopSound('win-line');
        if (this.getState() === 'SHOW_WIN_LINES' && this.latestResponse.payment) {
            this.takeWin();
        } else { // no win, just feature without payment
            this.roundFinished();
        }
    }

    playOnyByOneFeatures(indexes, i = 0) {
        JL().debug('playOnyByOneFeatures');
        indexes[i].forEach(({reelIndex, rowIndex}, index) => {
            if (this.reelMatrix && this.reelMatrix.length) {
                const symbolObj = this.reelMatrix[reelIndex][rowIndex];
                this.Lines.drawWinBox(symbolObj.symbolContainer);
                if (this.getState() === 'IDLE') {
                    App.Sounds.playSound('ony-by-one-features');
                }
                symbolObj.sprite.visble = false;
                const spine = symbolObj.spine;

                if (spine) {
                    spine.state.clearListeners();
                    const track = spine.state.setAnimation(0, symbolObj.animation, false);
                    this.defaultFeatureDelay = (track.animation.duration * 1000) >> 0;
                    this.winLineFeatureDelay = this.defaultFeatureDelay;
                    if (indexes[i].length - 1 === index) {
                        spine.state.addListener(
                            {
                                complete: () => {
                                    this.tickerTimeout(() => this.playOnyByOneFeatures(indexes, ++i % Object.keys(indexes).length), 500);
                                }
                            }
                        );
                    }
                }
            }
        });
    }

    stickSpine() {
        const reelsStage = this.getStageChild('reelsStage');
        const bonusContainer = this.getStageChild('bonusContainer');

        if (this.lastRespin && bonusContainer.children.length > 0) {
            for (const reelIndex of this.reelsAlradyhaveExpandedSpine) {
                const rowIndex = this.findSpineRowPlaceIndex(reelIndex, this.lastScreen);
                const length = reelsStage.getChildByName(`reel${reelIndex}`).children.length - 1;
                const spine = bonusContainer.getChildByName(reelIndex).getChildAt(0);

                const container = reelsStage.getChildByName(`reel${reelIndex}`).children[length - (this.reelRows - rowIndex)];
                container && container.addChild(spine);
                container.zIndex = 100;
            }

            this.tickerTimeout(() => {
                bonusContainer.removeChildren();
                this.reelsAlradyhaveExpandedSpine = [];
            }, 100);
        }
    }

    findSpineRowPlaceIndex(item, screen) {
        const monkRowIndex = [7, 8, 9];
        for (const [i, rowSymbol] of screen[item].entries()) {
            if (monkRowIndex.includes(rowSymbol)) {
                return i;
            }
        }
    }

    setMonkAnimation(extension) {
        const {jokerReels, screen, isNextRollReSpin} = extension;
        const {features, payment} = this.latestResponse;

        jokerReels.forEach((item, index) => {
            const spineRowPlaceIndex = this.findSpineRowPlaceIndex(item, screen);

            const symbolObj = this.reelMatrix[item][spineRowPlaceIndex];
            const monkSpine = this.createMonkSpine(symbolObj, item, spineRowPlaceIndex);
            symbolObj.spine = monkSpine;
            if (!this.reelsAlradyhaveExpandedSpine.includes(item)) {
                this.reelsAlradyhaveExpandedSpine.push(item);
                const expendingAnimation = `Sym_expanding_${spineRowPlaceIndex + 1}`;
                App.Sounds.playSound(`expand_wild_${spineRowPlaceIndex + 1}`);

                monkSpine.state.setAnimation(0, expendingAnimation, false);
                monkSpine.state.addListener({
                    complete: () => {
                        symbolObj.animation = `Sym_1x3_win_${spineRowPlaceIndex + 1}`;
                    }
                });
            } else {
                symbolObj.animation = `Sym_1x3_win_${spineRowPlaceIndex + 1}`;
            }

            if (jokerReels.length - 1 === index) {
                App.updateButton('start', {disabled: true});

                this.tickerTimeout(() => {
                    if (payment > 0 && features.length) {
                        if (this.getState() !== 'SHOW_WIN_LINES') {
                            this.setState('SHOW_WIN_LINES');
                            this.startAnimateFeature(features);
                        }
                    } else {
                        if (isNextRollReSpin) {
                            this.drawBonusAskButton(true);
                        } else {
                            this.afterBonusAction();
                        }
                    }
                }, 800);
            }
        });
    }

    /**
     * Called to show round results once animation is finished
     */
    rotationDone() {
        const longRollAnim = this.getStageChild('mainContainer').getChildByName('longRollSpine');

        longRollAnim.state.setEmptyAnimations(1);

        this.createReelMatrix(this.getStageChild('reelsStage'));
        this.onRotationDone();
        App.System.statistics.currentSpinNumber < 3 && App.System.collectFps();
    }

    /**
     * Function to decide next step after bonus / take win without prompt
     * lose and idle
     */
    afterBonusAction() {
        this.freezeReels = [];
        const {extension} = this.latestResponse;
        const payment = extension ? extension.totalPayment : this.latestResponse.payment;
        if (payment > 0 || this.bonusWin) {
            this.Legends.setText('win', {text: 'win', value: payment});
            this.takeWin();
        } else {
            this.Legends.showJackpot();
            this.Legends.setRoundFinText();
            this.roundFinished();
        }
    }

    createMonkSpine(symbolObj, reel, row) {
        const bonusContainer = this.getStageChild('bonusContainer');
        symbolObj.sprite.destroy();
        const textures = this.getSpineData(`sym${symbolObj.symbol}`);
        const spine = new window.PIXI.spine.Spine(textures);
        const container = new Container();
        container.position.set(reel * this.symbolWidth + this.reelXCoordinates[0], this.reelTop + row * this.symbolHeight);
        container.name = reel;
        spine.position.set(this.symbolWidth / 2, this.symbolHeight / 2);
        if (!this.reelsAlradyhaveExpandedSpine.includes(reel)) {
            container.addChild(spine);
            bonusContainer.addChild(container);
        }

        return spine;
    }

    /**
     * Create PIXI.Container for game bonus animations
     * Add additional sprites to container
     * @param parentContainer
     */
    createBonusContainer = parentContainer => {
        const container = new Container();
        container.name = 'bonusContainer';
        container.interactiveChildren = true;
        container.zIndex = this.containersLayers[container.name];
        // container.position.set(this.reelXCoordinates[0], this.reelTop);
        parentContainer.addChild(container);
    };

    drawBonusAskButton(isFirstBonus) {
        JL().debug(`-- Set bonus ask button (isFirstBonus: ${isFirstBonus})`);
        this.stopAnimateFeature();
        this.showPressAnyButton(false);
        this.gameFlag.bonusStart = true;
        this.roundWin && this.Legends.setText('win', {text: 'win', value: this.bonusWin});
        App.updateButton('start', {disabled: true});

        this.tickerTimeout(() => {
            this.stopAnimateFeature();
            this.stopWaitingAnimation();
            this.respin();
        }, 300);
    }

    onRotationDone() {
        JL().debug(`-- Rotation done (fps: ${App.System.statistics.fps})`);
        const {features, extension} = this.latestResponse;
        // check if is there respin and if is this the last one.
        const lastRollRespin = extension &&
            extension.isNextRollReSpin === false;
        this.lastRespin = lastRollRespin;
        const payment = lastRollRespin ?
            extension.totalPayment :
            this.latestResponse.payment;

        App.updateButton('start', {disabled: true});
        this.roundWin = 0;

        if (extension && App.restoreGameState === 'NONE') {
            this.setMonkAnimation(extension);
        } else {
            // check feature win
            if (payment > 0 && (features.length || this.isFreeRoll(features))) {
                // eslint-disable-next-line no-constant-condition
                if (this.BigWin.isBigWin(payment) && !this.isBonusSymbolWin(features)) {
                    this.BigWin.goToBigWin(payment, features);
                } else {
                    this.setState('SHOW_WIN_LINES');
                    this.startAnimateFeature(features);
                }
            } else {
                this.roundFinished();

                this.Legends.setRoundFinText();
            }
        }
    }

    playLongRollSound = reelIndex => {
        if (this.reelLong[reelIndex] === 1 && reelIndex < 5) {
            const longRollAnim = this.getStageChild('mainContainer').getChildByName('longRollSpine');
            longRollAnim.state.setAnimation(0, `longroll_${reelIndex + 1}`, true);
            /*            if (this.reelLong[reelIndex] === 1 && reelIndex !== this.reels - extra) { // current reel without last reel with extraBet correction
                this.stopRollSound();
                this.tickerTimeout(() => App.Sounds.playSound('long3'), 50);
            } */
        }
    };

    respin = () => {
        JL().debug(`-- Respin - ${JSON.stringify(this.latestResponse.extension.screen)}`);
        this.clearPressAnyButton();
        this.Legends.setStatus('additionalRoll');
        App.Sounds.playSound('respin');
        App.updateButton('start', {disabled: true});

        this.freezeReels = this.latestResponse.extension.jokerReels;
        this.sendRoll();
    };

    isReelFreezed = reel => this.freezeReels.includes(reel);

    additionalPreparingToAnimateFeature() {

    }

    prepareToRollAnimation(response) {
        this.Buttons.disableAllButtons();
        App.updateButton('start', {
            disabled: false,
            title: 'stop',
            handler: () => { // change handler to stop reels animation
                JL().debug('-- Stop roll');
                this.Roll.stopReels = [1, 1, 1, 1, 1, 1];
                this.Roll.fastReelsSound = 1;
                App.updateButton('start', {disabled: true});
            }
        });

        this.extraBet && this.updateExtraBetButtons(false);
        this.Roll.startReelAnimation(response);
    }

    /**
     * Animate infoContainer, slide down and update state
     */
    startInfoAnimation() {
        const open = () => {
            this.createInfoContainer(this.getStage());

            this.showAnimation({
                duration: 500,
                animations: [{sprite: this.getStageChild('infoContainer'), timeline: [{to: {y: 28}}]}],
                onComplete: () => {
                    JL().debug('-- InfoScreen opened');
                    this.setState('INFO');
                    this.InfoScreen.checkInfoButtons();
                    App.updateButton('close', {disabled: false, handler: this.InfoScreen.close});
                }
            });
            App.updateState('buttons', {animation: 'hide-panel'});
            this.onInfoStartOpen();
        };

        this.checkLoadedResources(open);
    }
}
