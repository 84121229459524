import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../../../index';
import GameDeluxe from './../../deluxe/game';
import Lines10 from './lines';
import InfoScreen from '../../infoScreen';
import langs from './langs';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Texture = PIXI.Texture,
    Container = PIXI.Container,
    Text = PIXI.Text;

export default class SkyRiders extends GameDeluxe {
    constructor() {
        super();
        this.id = 'sky-riders';
        this.name = 'Sky Riders';
        this.scatter = 9;
        this.buttonsPanelShadow = 'none';
        this.reelXCoordinates = [64, 231, 400, 570, 735]; // magic numbers - x coordinates where reels starts
        this.reelTop = 95; // magic number - where reel images starts
        this.gameFieldWidth = 960;
        this.gameWidth = App.System.resolution === '4x3' ? this.gameFieldWidth : 1280;
        this.gameFieldHeight = 720;
        this.gameHeight = App.configs.doubleScreen ? 1440 : 720;
        this.defaultFeatureDelay = 1300;

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 33, y: 107},
            bonusInBonusFrame: {x: 25, y: 120},
            endBonusFrame: {x: 25, y: 120}
        };

        this.containersLayers = {
            reelsStage: 1,
            mainContainer: 0,
            linesContainer: 2,
            boxesContainer: 3,
            extraBetContainer: 4,
            bonusContainer: 5,
            symbolInfo: 6,
            infoContainer: 7
        };

        this.symbolHeight = 154; // height of a single symbol
        this.symbolWidth = 154; // width of a single symbol
        this.gameFieldWidth = 960;
        this.gameWidth = App.System.resolution === '4x3' ? this.gameFieldWidth : 1280;
        this.gameFieldHeight = 720;
        this.gameHeight = App.configs.doubleScreen ? 1440 : 720;
        this.SymbolInfo.enabled = true;
        this.SymbolInfo.settings.paymentBorderOffset = {left: 65, right: 75};
        this.BigWin.enabled = true;

        this.symbols = [
            {regularDelay: 70, payment: [0, 0, 0, 5, 25, 100]},       // 0 -  пропеллер
            {regularDelay: 50, payment: [0, 0, 0, 5, 25, 100]},       // 1 -  двигатель
            {regularDelay: 50, payment: [0, 0, 0, 5, 25, 100]},       // 2 -  шасси
            {regularDelay: 55, payment: [0, 0, 0, 5, 25, 100]},       // 3 -  штурвал
            {regularDelay: 50, payment: [0, 0, 0, 10, 50, 125]},      // 4 -  очки и рукавицы
            {regularDelay: 60, payment: [0, 0, 0, 10, 50, 125]},      // 5 -  парашют
            {regularDelay: 50, payment: [0, 0, 0, 25, 125, 500]},     // 6 -  женщина пилот
            {regularDelay: 50, payment: [0, 0, 0, 25, 125, 500]},     // 7 -  мужчина пилот
            {regularDelay: 60, payment: [0, 0, 10, 250, 2500, 10000]},     // 8 -  дирижабль
            {regularDelay: 50, payment: [0, 0, 2, 5, 20, 500]}     // 9 -  самолет
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.jpg',
                logo: 'area/logo-sprite.png',
                bonusArea: 'area/bonus.jpg'
            }),
            jsonAnimations: this.mergePath({line: 'lines/Line.json'}),
            atlas: this.mergePath(['staticSymbols.json']),
            fonts: this.mergePath({skyRidersFont: 'font/font.ttf'})
        };

        this.additionalResources = {
            main: this.mergePath({
                symbolBorder: 'area/symbol-border.png',
                paymentBorder: 'area/payment-border.png',
                frame: 'bonus/frame.png',
                multipliers: 'bonus/multipliers.png'
            })
        };

        this.gameSounds = {soundClass: 'prefergames'};

        this.Lines = new Lines10();
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
        this.InfoScreen.format = 'jpg';
        this.InfoScreen.getResources = () => App.Game.mergePath({
            info1: 'area/info1-screen.jpg',
            info2: 'area/info2-screen.jpg',
            info3: 'area/info3-screen.jpg',
            infoSprite: 'area/infoSprite.png'
        });
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     * @param lang
     */
    drawInfoPage(ctx, page, nLines, bet, lang) {
        ctx.strokeStyle = '#000';
        ctx.fillStyle = '#00ffea';
        ctx.lineWidth = 3;
        ctx.textAlign = 'center';

        const {infoTitle, infoRules, feature, scatterRules} = langs[lang];
        const {
            combinationOfKind,
            combinationLeftToRight,
            prizesOnSelectedLines,
            ScatterPayAtAnyPosition,
            HighestWinPaid,
            ScatterWinsAddedToLineWins,
            prizesShownInCredits,
            MalfunctionVoidsAllPays
        } = App.languageCollection[lang];
        const {rules} = App.languageCollection[lang];

        const textProps = {
            font: '17pt Franklin Gothic Medium',
            textAlign: 'center',
            fillStyle: '#ffa30f',
            strokeStyle: '#000000',
            lineWidth: 3,
            lineHeight: 20,
            shadowColor: '#000',
            shadowOffsetX: 3,
            shadowOffsetY: 1,
            shadowBlur: 2
        };

        switch (page) {
            case 1:
                ctx.font = '17pt Franklin Gothic Medium';
                ctx.fillStyle = '#dce0be';
                ctx.shadowColor = '#000';
                ctx.shadowOffsetX = 3;
                ctx.shadowOffsetY = 1;
                ctx.shadowBlur = 2;

                // штурвал, шасси, пропеллер, двигатель
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 470, 386);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 470, 419);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 470, 453);
                // очки и рукавицы, парашют
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 740, 380);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 740, 414);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 740, 448);
                // женщина пилот, мужчина пилот
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 178, 385);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 178, 419);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 178, 453);
                // дирижабль
                this.strokeFillText(ctx, bet * this.symbols[8].payment[2], 450, 164);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[3], 450, 194);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[4], 450, 223);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[5], 450, 252);
                // самолет
                this.strokeFillText(ctx, bet * nLines * this.symbols[9].payment[2], 178, 151);
                this.strokeFillText(ctx, bet * nLines * this.symbols[9].payment[3], 178, 179);
                this.strokeFillText(ctx, bet * nLines * this.symbols[9].payment[4], 178, 209);
                this.strokeFillText(ctx, bet * nLines * this.symbols[9].payment[5], 178, 238);
                break;
            case 2:
                ctx.drawImage(
                    this.getAdditionalImage('infoSprite'),
                    0, 0, 154, 154,
                    95, 20, 120, 120
                );
                ctx.drawImage(
                    this.getAdditionalImage('infoSprite'),
                    154, 0, 154, 154,
                    750, 20, 120, 120
                );

                this.drawSplitText(ctx, infoTitle, 484, 90, 550, {
                    ...textProps,
                    font: '17pt Franklin Gothic Medium',
                    fillStyle: '#00ffea',
                    textAlign: 'center'
                });

                for (let i = 1; i <= 4; i++) {
                    ctx.setTransform(1, 0, 0, 1, 0, 0);

                    ctx.lineWidth = 3;
                    ctx.strokeStyle = '#000';
                    ctx.textAlign = 'center';
                    ctx.font = '20pt Franklin Gothic Medium';
                    ctx.fillStyle = '#00ffea';
                    ctx.shadowColor = '#000';
                    ctx.shadowOffsetX = 3;
                    ctx.shadowOffsetY = 1;
                    ctx.shadowBlur = 2;
                    this.strokeFillText(ctx, i, 290 + i * 140, 160);

                    ctx.shadowColor = '#000';
                    ctx.shadowOffsetX = 0;
                    ctx.shadowOffsetY = 0;
                    ctx.shadowBlur = 0;
                    ctx.drawImage(
                        this.getAdditionalImage('infoSprite'),
                        0, 0, 154, 154,
                        250 + i * 140, 170, 85, 85
                    );

                    ctx.translate(290 + i * 140, 225);
                    ctx.rotate(-10 * Math.PI / 180);
                    ctx.shadowColor = '#000';
                    ctx.shadowOffsetX = -1;
                    ctx.shadowOffsetY = 2;
                    ctx.shadowBlur = 2;
                    ctx.drawImage(
                        this.getImage('multipliers'),
                        (i - 1) * 300, 0, 300, 212,
                        0, 0, 55, 40
                    );

                    ctx.setTransform(1, 0, 0, 1, 0, 0);

                    this.drawSplitText(ctx, infoRules, 645, 300, 550, {
                        ...textProps,
                        font: '20pt Franklin Gothic Medium',
                        fillStyle: '#00ffea',
                        textAlign: 'center'
                    });
                }

                this.drawSplitText(ctx, feature, 325, 340, 550, {
                    ...textProps,
                    fillStyle: '#00ffea',
                    font: '18pt Franklin Gothic Medium',
                    textAlign: 'center'
                });
                ctx.drawImage(
                    this.getAdditionalImage('infoSprite'),
                    154, 0, 154, 154,
                    260, 350, 120, 120
                );
                this.drawSplitText(ctx, scatterRules, 405, 370, 600, {
                    ...textProps,
                    fillStyle: '#00ffea',
                    font: '12pt Franklin Gothic Medium',
                    textAlign: 'left'
                });
                break;
            case 3:
                ctx.font = '30pt Franklin Gothic Medium';
                ctx.strokeStyle = '#000';
                ctx.fillStyle = '#00ffea';
                ctx.lineWidth = 3;
                ctx.textAlign = 'center';
                ctx.shadowColor = '#000';
                ctx.shadowOffsetX = 3;
                ctx.shadowOffsetY = 1;
                ctx.shadowBlur = 2;
                const rulesText = combinationOfKind +
                    combinationLeftToRight +
                    prizesOnSelectedLines +
                    ScatterPayAtAnyPosition +
                    HighestWinPaid +
                    ScatterWinsAddedToLineWins +
                    prizesShownInCredits +
                    MalfunctionVoidsAllPays;
                this.strokeFillText(ctx, rules, 480, 115, 500);
                this.drawSplitText(ctx, rulesText, 480, 160, 665, {
                    ...textProps,
                    lineHeight: 40,
                    fillStyle: '#00ffea',
                    font: '14pt Franklin Gothic Medium'
                });
                break;
        }
    }

    /**
     * Function to stop Animate Feature animation
     */
    stopAnimateFeature() {
        this.stopFeatureTimeout();
        this.getStageChild('linesContainer').removeChildren();
        this.resetSymbolAnimation();
        this.Legends.clearText('features');
        !this.isBonus() && this.Legends.clearText('win');
    }

    /**
     * Animate infoContainer, slide down and update state
     */
    startInfoAnimation() {
        const open = () => {
            this.createInfoContainer(this.getStage());

            this.showAnimation({
                duration: 500,
                animations: [{sprite: this.getStageChild('infoContainer'), timeline: [{to: {y: 85}}]}],
                onComplete: () => {
                    JL().debug('-- InfoScreen opened');
                    this.setState('INFO');
                    this.InfoScreen.checkInfoButtons();
                    App.updateButton('close', {disabled: false, handler: this.InfoScreen.close});
                }
            });
            App.updateState('buttons', {animation: 'hide-panel'});
            this.onInfoStartOpen();
        };

        this.checkLoadedResources(open);
    }

    /**
     * Create PIXI.Container for game background
     * Add additional sprites to container
     * @param parentContainer
     */
    createMainContainer(parentContainer) {
        const container = new Container();
        container.name = 'mainContainer';
        container.zIndex = this.containersLayers[container.name];

        const sprite = new Sprite(this.getTexture('mainArea'));
        sprite.name = 'mainArea';
        sprite.anchor.set(0.5);
        sprite.position.set(this.gameFieldWidth / 2, this.gameFieldHeight / 2);
        container.addChild(sprite);
        parentContainer.addChild(container);

        const logoSprite = new Sprite(this.getTexture('logo'));
        logoSprite.name = 'logo';
        logoSprite.position.set(290, -30);
        logoSprite.scale.set(0.32, 0.32);
        container.addChild(logoSprite);
    }

    /**
     * Create texts for paymentBorder table
     * @param parentContainer
     * @param payTable
     * @param direction
     */
    drawSymbolInfoPayments(parentContainer, payTable, direction) {
        const bet = this.gameSettings.getBetLineCredit();
        const props = {
            fill: '#7f2900',
            stroke: '#000000',
            align: 'left',
            fontFamily: 'Franklin Gothic Medium',
            fontSize: 17,
            strokeThickness: 0.5,
            lineJoin: 'round'
        };

        payTable.forEach((pay, index) => {
            const quantity = new Text(`x${pay[0]}`, props);
            quantity.position.x = direction === 'left' ? -55 : -50;
            quantity.position.y = 16 + (payTable.length === 3 ? -25 * index : -17 * index);
            quantity.anchor.x = 0.5;

            const payment = new Text(` ${bet * pay[1]}`, {...props, fill: '#6c7924', stroke: '#000000'});
            payment.anchor.x = 0.5;
            payment.position.x = direction === 'left' ? -7 : 0;
            payment.position.y = 16 + (payTable.length === 3 ? -25 * index : -17 * index);
            parentContainer.addChild(quantity, payment);
        });
    }

    /**
     * Drawing  the table of bonus game
     */
    showStartBonusFrame(parentContainer, {x, y}, freeRollAmount) {
        const frameSprite = this.showBonusFrame(parentContainer, x, y);
        const props = {
            align: 'center',
            fontFamily: 'Trebuchet MS',
            fontSize: 55,
            stroke: 'white',
            fill: '#ff1407',
            strokeThickness: 3,
            lineJoin: 'round',
            fontWeight: 'bold'
        };
        const wonBonusText = new Text(`${App.language.youWon}\n${freeRollAmount}\n ${App.language.freeGames}`, props);
        wonBonusText.name = 'freeSpins';
        wonBonusText.anchor.set(0.5);
        wonBonusText.position.set(448, 196);
        frameSprite.addChild(wonBonusText);
        this.fade(frameSprite, {from: 0, to: 1}, () => {
            App.updateButton('start', {
                disabled: false,
                title: 'start',
                handler: () => this.startBonusAnimation(frameSprite)
            });
        });
    }

    showBonusInBonusFrame(parentContainer, {x, y}, freeRollAmount) {
        const frameSprite = this.showBonusFrame(parentContainer, x, y);
        const props = {
            align: 'center',
            fontFamily: 'Trebuchet MS',
            fontSize: 45,
            stroke: 'white',
            fill: '#ff1407',
            strokeThickness: 3,
            lineJoin: 'round',
            fontWeight: 'bold'
        };

        const wonBonusInBonusText = new Text(`${App.language.youWon}\n${App.language.more}${freeRollAmount}\n ${App.language.freeGames}`, props);
        wonBonusInBonusText.name = 'additionalFreeSpins';
        wonBonusInBonusText.anchor.set(0.5);
        wonBonusInBonusText.position.set(443, 195);
        frameSprite.addChild(wonBonusInBonusText);
        this.fade(frameSprite, {from: 0, to: 1});
    }

    /**
     * Отрисовка таблички окончания бонусной игры
     */
    showEndBonusFrame(parentContainer, {x, y}, {win, total}) {
        const frameSprite = this.showBonusFrame(parentContainer, x, y);

        const props = {
            align: 'center',
            fontFamily: 'Trebuchet MS',
            fontSize: 55,
            stroke: 'white',
            fill: '#ff1407',
            strokeThickness: 3,
            lineJoin: 'round',
            fontWeight: 'bold'
        };
        const wonEndBonusText = new Text(`${App.language.youWon}\n ${win}\n ${App.language.credits}`, props);
        wonEndBonusText.name = 'freeSpinsEnd';
        wonEndBonusText.anchor.set(0.5);
        wonEndBonusText.position.set(443, 195);
        frameSprite.addChild(wonEndBonusText);
        this.fade(frameSprite, {from: 0, to: 1});
        this.tickerTimeout(() => {
            this.fade(frameSprite, {from: 1, to: 0});
            this.setBackground('mainArea');
        }, 4000);
    }

    /**
     * Update PIXI stage after language change
     * @param language - current language collection
     */
    translateStage(language) {
        this.InfoScreen.update();
        const frame = this.getStageChild('bonusContainer').getChildByName('frame');
        if (frame) {
            const freeSpinText = frame.getChildByName('freeSpins');
            const additionalFreeSpinText = frame.getChildByName('additionalFreeSpins');
            const freeSpinTextEnd = frame.getChildByName('freeSpinsEnd');
            const freeRollFeature = this.latestResponse.features.find(({uc}) => uc === 'FREE_ROLL');
            if (freeSpinText) {
                freeSpinText.text = `${language.youWon}\n${freeRollFeature.amount}\n ${language.freeGames}`;
            }
            if (additionalFreeSpinText) {
                additionalFreeSpinText.text = `${App.language.youWon}\n${App.language.more}${freeRollFeature.amount}\n ${App.language.freeGames}`;
            }
            if (freeSpinTextEnd) {
                freeSpinTextEnd.text = `${App.language.youWon}\n${this.bonusStatus.win}\n${App.language.credits}`;
            }
        }
    }

    /**
     * Prepare game behaviour after bonus 'press any button' message
     */
     startBonusAnimation = frameSprite => {
         App.Sounds.stopSound('banner-win');
         this.gameFlag.bonusStarted = true;
         this.clearPressAnyButton();
         this.Buttons.disableAllButtons();
         App.updateButton('start', {disabled: true});
         this.fade(frameSprite, {from: 0, to: 1}, this.bonusRoll());
     };

     fade(sprite, {from, to}, callback) {
         this.showAnimation({
             duration: 1000,
             animations: [{
                 sprite,
                 timeline: [{from: {alpha: from}, to: {alpha: to}}]
             }],
             onComplete: () => callback?.()
         });
     }

     showBonusFrame(parentContainer, x, y, image = 'frame') {
         const sprite = new Sprite(this.getTexture(image));
         sprite.name = image;
         sprite.position.set(x, y);
         sprite.alpha = 0;
         parentContainer.addChild(sprite);
         return sprite;
     }

     setScatterSprite(scatterFeature) {
         scatterFeature.positions.forEach(position => {
             const {reel, row} = position;
             const symbolObj = this.reelMatrix[reel][row];
             symbolObj.loop = false;
             this.Roll.updateSymbolSprite(symbolObj);
             symbolObj.sprite.play();
         });
     }

     /**
     * Change mainContainer and <Buttons> background
     * @param type
     */
     setBackground(type) {
         const mainArea = this.getStageChild('mainContainer').getChildByName('mainArea');

         [mainArea].forEach(sprite => {
             const texture = sprite.name === 'mainArea' ? type :
                 type === 'mainArea' ? 'background' : 'bonusBackground'; // for reels background

             const transition = new Sprite(this.getTexture(texture));
             transition.name = 'transition';
             transition.alpha = 0;
             transition.position.set(sprite.position.x, sprite.position.y);
             sprite.parent.addChildAt(transition, 0);

             this.showAnimation({
                 duration: 2000,
                 animations: [
                     {sprite: transition, timeline: [{to: {alpha: 1}}]},
                     {sprite: sprite, timeline: [{to: {alpha: 0}}]}
                 ],
                 onComplete: () => {
                     transition.destroy();
                     sprite.texture = this.getTexture(texture);
                     sprite.alpha = 1;
                 }
             });
         });
     }

     drawBonusFrame(first, last, parentContainer, coordinates) {
         parentContainer.removeChildren();
         App.Sounds.stopSound('bonus-background');
         const freeRollFeature = this.latestResponse.features.find(({uc}) => uc === 'FREE_ROLL');

         const {startBonusFrame, bonusInBonusFrame, endBonusFrame} = coordinates;
         if (first) {
             App.Sounds.playSound('bookFlash');
             this.imageResources.main.bonusArea && this.setBackground('bonusArea');
             this.showStartBonusFrame(parentContainer, startBonusFrame, freeRollFeature.amount);
         }

         if (last) {
             parentContainer.removeChildren();
             this.setRegularSprite();
             App.updateButton('start', {disabled: true});
             this.showEndBonusFrame(parentContainer, endBonusFrame, this.bonusStatus);
             this.playEndBonusGameSound();
             this.tickerTimeout(() => this.endBonus(), 5000);
         }
         if (!first && !last) {
             App.Sounds.playSound('bookFlash');
             App.updateButton('start', {
                 disabled: false,
                 title: 'start',
                 handler: () => this.drawBonusStep(this.latestResponse.features)
             });
             this.imageResources.main.bonusArea && this.setBackground('bonusArea');
             this.showBonusInBonusFrame(parentContainer, bonusInBonusFrame, freeRollFeature.amount);
         }
     }

     drawMultipliers(number) {
         const {extension} = this.latestResponse;
         const filteredExtension = extension.filter(({line}) => line === number);

         if (extension) {
             filteredExtension.forEach(obj => {
                 const {multiplier, line, reel} = obj;
                 const rowIndex = this.Lines.lines[number].coordinates[reel];
                 const symbolObj = this.reelMatrix[reel][rowIndex];

                 if (symbolObj.symbol === 8 && number === line) {
                     const index = this.getMultiplierIndex(multiplier);
                     const airship = symbolObj.sprite;
                     const multiplierSprite = new Sprite(new Texture(this.getTexture('multipliers'), {
                         x: 300 * index, y: 0, width: 300, height: 212
                     }));
                     multiplierSprite.anchor.set(0.5);
                     multiplierSprite.scale.set(0);
                     multiplierSprite.name = multiplier;
                     airship.addChild(multiplierSprite);

                     this.showAnimation({
                         duration: 2000,
                         animations: [{
                             sprite: multiplierSprite, timeline: [
                                 {to: {scaleX: 0.6, scaleY: 0.6}, duration: {to: 500}},
                                 {
                                     from: {scaleX: 0.6, scaleY: 0.6},
                                     to: {scaleX: 0.4, scaleY: 0.4},
                                     duration: {from: 500, to: 800}
                                 },
                                 {to: {alpha: 0}, duration: {from: 800, to: 2000}}
                             ]
                         }],
                         onComplete: () => {
                             multiplierSprite.destroy();
                         }
                     });
                 }
             });
         }
     }

     /**
     * Function to show line for special feature
     * @param currentFeature
     */
     showFeatureLine(currentFeature) {
         const {extension} = this.latestResponse;
         const {number, reels, uc, payment} = currentFeature; // get current feature params
         extension && this.drawMultipliers(number);
         const container = this.getStageChild('linesContainer');
         uc !== 'SPECIAL_SYMBOL' && container.removeChildren(); // don't clear lines before special symbol (bookGame fill)
         uc === 'WIN_LINE' && this.Lines.drawLineImages([number], reels, container, true, payment);
     }

    getMultiplierIndex = multiplier => {
        const indexes = {
            2: 0,
            4: 1,
            8: 2,
            16: 3
        };
        return indexes[multiplier];
    };

    /**
     * End feature animation / take win without prompt
     * @returns {boolean} - new animation features circle

     */
    endAnimateFeature() {
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        App.Sounds.stopSound('win-line');
        if (this.getState() === 'SHOW_WIN_LINES' && this.latestResponse.payment) {
            this.takeWin();
        } else { // no win, just feature without payment
            this.roundFinished();
        }
        return true;
    }

    /**
     * Animate feature first step
     */
    prepareToAnimateFeature(features) {
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        this.features.step = 0;

        // check 'WIN_LINE' feature contain
        const isWinLine = features.some(features => features.uc === 'WIN_LINE');
        this.updateExtraSymbols(isWinLine);

        // reset all animations and turn on shadows
        this.reelMatrix.forEach(reel => {
            reel.forEach(symbolObj => {
                // don't hide symbols if only scatter feature
                symbolObj.sprite.tint = isWinLine && this.symbolEffects ? 0x6E6E6E : 0xFFFFFF;
                symbolObj.sprite.gotoAndStop(0);
            });
        });

        // unique preparing for each game
        this.additionalPreparingToAnimateFeature(features);

        // initialize symbols on reelMatrix
        features.forEach(feature => {
            switch (feature.uc) {
                case 'WIN_LINE':
                    feature.reels.forEach(reelIndex => {
                        const rowIndex = this.Lines.lines[feature.number].coordinates[reelIndex];
                        const symbolObj = this.reelMatrix[reelIndex][rowIndex];
                        symbolObj.image = this.symbolAnimation && symbolObj.image === 'static' ?
                            'regular' : symbolObj.image;
                        this.setRegularLongSprite(feature.reels.length, symbolObj);
                        this.Roll.updateSymbolSprite(symbolObj);
                        symbolObj.sprite.tint = 0xFFFFFF;
                        symbolObj.sprite.zIndex = 1;
                        symbolObj.sprite.play();
                        symbolObj.symbolBackground && symbolObj.symbolBackground.gotoAndPlay(0);
                    });
                    break;
                case 'SCATTER':
                case 'WIN_WAY':
                    feature.positions.forEach(({reel, row}) => {
                        const symbolObj = this.reelMatrix[reel][row];
                        symbolObj.image = this.symbolAnimation && symbolObj.image === 'static' ?
                            'regular' : symbolObj.image;
                        this.setRegularLongSprite(feature.uc === 'SCATTER' ? feature.positions.length : feature.count, symbolObj);
                        this.Roll.updateSymbolSprite(symbolObj);
                        symbolObj.sprite.tint = 0xFFFFFF;
                        symbolObj.sprite.play();
                        symbolObj.symbolBackground && symbolObj.symbolBackground.gotoAndPlay(0);
                    });
                    break;
            }
        });
    }
}
