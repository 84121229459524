import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../../../index';
import GameDeluxe5x4 from './../../deluxe/gameDeluxe5x4';
import Lines40 from './../../deluxe/lines40';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';

/* PIXI aliases */
const Texture = PIXI.Texture,
    AnimatedSprite = PIXI.AnimatedSprite;

export default class NorthernLight extends GameDeluxe5x4 {
    constructor() {
        super();
        this.id = 'northern-light';
        this.name = 'Northern Light';
        this.scatter = 8;
        this.scatter2 = 9;

        this.reelFilter = [[10], [10], [10], [8, 9, 10], [8, 9, 10]];
        this.reelTop = 88;
        this.bonusMatrix = [
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0]
        ];

        this.bonusVector = [];
        this.symbols = [
            {regularDelay: 350, regularLongDelay: 100, payment: [0, 0, 0, 4, 12, 100]},  // 0 - J
            {regularDelay: 350, regularLongDelay: 100, payment: [0, 0, 0, 4, 12, 100]},  // 1 - Q
            {regularDelay: 350, regularLongDelay: 100, payment: [0, 0, 0, 8, 20, 200]},  // 2 - K
            {regularDelay: 350, regularLongDelay: 100, payment: [0, 0, 0, 8, 20, 200]},  // 3 - A
            {regularDelay: 100, payment: [0, 0, 0, 5, 40, 150]},                         // 4 - компас
            {regularDelay: 100, payment: [0, 0, 0, 12, 40, 300]},                        // 5 - ледоруб с веревкой
            {regularDelay: 100, payment: [0, 0, 0, 12, 40, 300]},                        // 6 - белый медведь
            {                                                                            // 7 - девушка
                regularDelay: 100,
                payment: [0, 0, 0, 20, 200, 1000],
                offsetY: 8
            },
            {                                                                            // 8 - верхняя часть айсберга
                regularDelay: 100,
                payment: [0, 0, 0, 0, 0, 0],
                offsetY: 31
            },
            {regularDelay: 100, payment: [0, 0, 0, 0, 0, 0], zIndex: 10, offsetY: -31}, // 9 - нижняя часть айсберга
            {regularDelay: 100}                                           // 10 - technical element for bonus animation
        ];

        this.imageResources = {
            main: this.mergePath({mainArea: 'area/main.png'}),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({bonusAnimation: 'area/bonusAnimation.png'}),
            atlas: this.mergePath(['regularLongSymbols.json'])
        };
        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'bonus-game-end', alias: 'bonusGameEnd'},
                {name: 'iceberg-opening'},
                {name: 'reels'}
            ],
            path: `/game/games/${this.id}/audio/`
        };

        this.Lines = new Lines40();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            activeRed: 'gamble/red-active.png',
            inactiveRed: 'gamble/red-inactive.png',
            activeBlack: 'gamble/black-active.png',
            inactiveBlack: 'gamble/black-inactive.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '17pt franklinFont';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#f7c354';
        ctx.shadowColor = 'black';
        ctx.shadowOffsetX = 3;
        ctx.shadowOffsetY = 3;
        ctx.shadowBlur = 5;

        switch (page) {
            case 1:
                // gold bricks
                ctx.fillText(bet * this.symbols[7].payment[5], 200, 144);
                ctx.fillText(bet * this.symbols[7].payment[4], 200, 174);
                ctx.fillText(bet * this.symbols[7].payment[3], 200, 204);
                // money packs.payment
                ctx.fillText(bet * this.symbols[6].payment[5], 620, 144);
                ctx.fillText(bet * this.symbols[6].payment[4], 620, 174);
                ctx.fillText(bet * this.symbols[6].payment[3], 620, 204);
                // crown.payment
                ctx.fillText(bet * this.symbols[5].payment[5], 195, 325);
                ctx.fillText(bet * this.symbols[5].payment[4], 195, 355);
                ctx.fillText(bet * this.symbols[5].payment[3], 195, 385);
                // red blue cristal.payment
                ctx.fillText(bet * this.symbols[4].payment[5], 635, 325);
                ctx.fillText(bet * this.symbols[4].payment[4], 635, 355);
                ctx.fillText(bet * this.symbols[4].payment[3], 635, 385);
                // green and violet cristal.payment
                ctx.fillText(bet * this.symbols[3].payment[5], 195, 495);
                ctx.fillText(bet * this.symbols[3].payment[4], 195, 525);
                ctx.fillText(bet * this.symbols[3].payment[3], 195, 555);
                // green and violet cristal.payment
                ctx.fillText(bet * this.symbols[0].payment[5], 635, 495);
                ctx.fillText(bet * this.symbols[0].payment[4], 635, 525);
                ctx.fillText(bet * this.symbols[0].payment[3], 635, 555);
                break;

            case 3:
                ctx.font = 'bold 42pt Cartoon';
                ctx.strokeStyle = '#FA9359';
                ctx.fillStyle = '#FFF859';
                ctx.lineWidth = 6;
                ctx.textAlign = 'center';
                this.strokeFillText(ctx, 'RULES', 400, 260);

                ctx.font = '19pt Cartoon';
                ctx.lineWidth = 3;
                ctx.fillStyle = '#FFF859';
                this.strokeFillText(ctx, 'All prizes are for combinations of a kind. All prizes are for', 400, 300);
                this.strokeFillText(ctx, 'are for combinations left to right. All prizes are on selected lines. Highest', 400, 330);
                this.strokeFillText(ctx, 'win only paid per selected line. All prizes shown in credits', 400, 360);
                this.strokeFillText(ctx, 'Malfunction voids all pays and plays.', 400, 390);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    decideScatterAnimation(screen) {
        let scatter = false;
        screen.forEach(reel => {
            reel.forEach(row => {
                if (row === 9 || row === 8) {
                    scatter = true;
                }
            });
        });
        return scatter;
    }

    getScatterAnimation(screen) {
        // create bonus matrix

        screen.forEach((vector, reelIndex) => {
            vector.forEach((symbol, rowIndex) => {
                if (symbol === this.scatter) {
                    this.bonusMatrix[reelIndex][rowIndex] = symbol; // 8
                    if (rowIndex - 1 >= 0 && !this.isScatter(this.bonusMatrix[reelIndex][rowIndex - 1])) {
                        this.bonusMatrix[reelIndex][rowIndex - 1] = 10;  // top
                    }
                    if (reelIndex + 1 <= 4 && !this.isScatter(this.bonusMatrix[reelIndex + 1][rowIndex])) {
                        this.bonusMatrix[reelIndex + 1][rowIndex] = 12; // right
                    }
                    if (reelIndex - 1 >= 0 && !this.isScatter(this.bonusMatrix[reelIndex - 1][rowIndex])) {
                        this.bonusMatrix[reelIndex - 1][rowIndex] = 16; // left
                    }
                    if (reelIndex + 1 <= 4 && rowIndex - 1 >= 0 && !this.isScatter(this.bonusMatrix[reelIndex + 1][rowIndex - 1])) {
                        this.bonusMatrix[reelIndex + 1][rowIndex - 1] = 11;
                    }
                    if (reelIndex - 1 >= 0 && rowIndex - 1 >= 0 && !this.isScatter(this.bonusMatrix[reelIndex - 1][rowIndex - 1])) {
                        this.bonusMatrix[reelIndex - 1][rowIndex - 1] = 17;
                    }
                }

                if (symbol === this.scatter2) {
                    this.bonusMatrix[reelIndex][rowIndex] = symbol;
                    if (rowIndex + 1 >= 0 && rowIndex + 1 < this.reelRows && !this.isScatter(this.bonusMatrix[reelIndex][rowIndex + 1])) {
                        this.bonusMatrix[reelIndex][rowIndex + 1] = 14; // bottom
                    }
                    if (reelIndex + 1 <= 4 && !this.isScatter(this.bonusMatrix[reelIndex + 1][rowIndex])) {
                        this.bonusMatrix[reelIndex + 1][rowIndex] = 12;  // right
                    }
                    if (reelIndex - 1 >= 0 && !this.isScatter(this.bonusMatrix[reelIndex - 1][rowIndex])) {
                        this.bonusMatrix[reelIndex - 1][rowIndex] = 16; // left
                    }
                    if (reelIndex + 1 <= 4 && rowIndex + 1 < this.reelRows && !this.isScatter(this.bonusMatrix[reelIndex + 1][rowIndex + 1])) {
                        this.bonusMatrix[reelIndex + 1][rowIndex + 1] = 13;
                    }
                    if (reelIndex - 1 >= 0 && rowIndex + 1 < this.reelRows && !this.isScatter(this.bonusMatrix[reelIndex - 1][rowIndex + 1])) {
                        this.bonusMatrix[reelIndex - 1][rowIndex + 1] = 15;
                    }
                }
            });
        });

        // create animation vector
        this.bonusMatrix.forEach((vector, reelIndex) => {
            vector.forEach((symbol, rowIndex) => {
                if (symbol >= 10) {
                    this.bonusVector.push({reel: reelIndex, row: rowIndex, symbol: symbol});
                }
            });
        });

        function compare(a, b) {
            if (a.symbol < b.symbol) return -1;
            if (a.symbol > b.symbol) return 1;
            if (a.symbol === b.symbol) {
                if (a.symbol === 16 && (a.row < b.row)) {
                    return 1;
                }
                if (a.symbol === 12 && (a.reel < b.reel)) {
                    return -1;
                }
                return 0;
            }
        }

        this.bonusVector.sort(compare);
        this.bonusVector.forEach((symbol, index) => {
            this.createIceberg(this.getStageChild('bonusContainer'), symbol.reel, symbol.row, index, symbol.symbol);
        });
    }

    isScatter = symbol => symbol === this.scatter || symbol === this.scatter2;

    onRotationDone() {
        JL().debug(`-- Rotation done (fps: ${App.System.statistics.fps})`);
        const {features, payment, screen} = this.latestResponse;
        App.updateButton('start', {disabled: true});

        const scatterAnimation = this.decideScatterAnimation(screen);
        this.roundWin = 0;
        if (payment > 0 || this.isFreeRoll(features) || features.length) {
            if (scatterAnimation) {
                this.getScatterAnimation(screen);
                App.Sounds.playSound('iceberg-opening');
                this.fillReelsWithBonusSymbol();
            } else {
                // There is a win
                this.setState('SHOW_WIN_LINES');
                this.startAnimateFeature(features);
            }
            // There is a lost
        } else {
            if (scatterAnimation && payment && features.length) {
                this.getScatterAnimation(screen);
                App.Sounds.playSound('iceberg-opening');
                this.fillReelsWithBonusSymbol();
            } else {
                this.roundFinished();
                this.Legends.setRoundFinText();
            }
        }
    }

    fillReelsWithBonusSymbol = (animationStarted = Date.now()) => {
        const {features, payment} = this.latestResponse;
        const iStep = Math.floor((+new Date() - animationStarted) / 200);
        if (iStep >= this.bonusVector.length) {
            this.bonusVector.forEach((vector, index) => {     // прорисовка лент на канвах
                const parentContainer = this.getStageChild('bonusContainer');
                const iceberg = parentContainer.getChildByName('2iceberg' + index);
                iceberg.visible = true;
                iceberg.play();
            });

            if (iStep >= this.bonusVector.length + 3) {
                this.changeReelMatrix();
                const parentContainer = this.getStageChild('bonusContainer');
                parentContainer.removeChildren();
                if (payment) {
                    // There is a win
                    this.setState('SHOW_WIN_LINES');
                    this.startAnimateFeature(features);
                } else {
                    this.roundFinished();
                    this.Legends.setRoundFinText();
                }
                return;
            }
        }
        if (iStep < this.bonusVector.length) {
            this.bonusAnimatingStep(iStep);
        }
        requestAnimationFrame(this.fillReelsWithBonusSymbol.bind(this, animationStarted));
    };

    bonusAnimatingStep(iStep) {
        const parentContainer = this.getStageChild('bonusContainer');
        const iceberg = parentContainer.getChildByName('iceberg' + iStep);
        iceberg.visible = true;
        iceberg.play();
    }

    changeReelMatrix() {
        for (let reelIndex = 0; reelIndex < this.reels; reelIndex++) {
            for (let rowIndex = 0; rowIndex < this.reelRows; rowIndex++) {
                if (this.bonusMatrix[reelIndex][rowIndex] >= 10) {
                    const symbolObj = this.reelMatrix[reelIndex][rowIndex];
                    symbolObj.symbol = 10;
                    symbolObj.zIndex = this.symbols[10].zIndex;
                    this.Roll.updateSymbolSprite(symbolObj);
                    symbolObj.sprite.gotoAndStop(0);
                    this.latestResponse.screen[reelIndex][rowIndex] = 10;
                }
            }
        }
    }

    cleanBeforeRoll() {
        this.bonusMatrix = [
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0]
        ];
        this.bonusVector = [];

        this.stopAnimateFeature();
        App.removePopupMessage();
        App.System.resetRollStatistics();
        this.InfoScreen.update({timeout: false, page: 1});
        this.latestResponse = null;

        if (!this.isBonus()) {
            this.Legends.showJackpot();
            App.Money.withDraw(this.gameSettings.getBet());
            App.updateState('moneyParams', {
                credits: App.Money.getCredit(),
                money: App.Money.getMoney()
            });
        }
        this.Legends.clearStatus();
        this.Legends.clearText('features');
        this.Legends.clearText('win');
    }

    getRandomSymbol(length, reelIndex, symbolBefore) {
        let symbol = Math.floor(Math.random() * length);

        while (
            symbol === this.reelFilter[reelIndex][0] ||
            symbol === this.reelFilter[reelIndex][1] ||
            symbol === this.reelFilter[reelIndex][2] ||
            symbol === symbolBefore ||
            symbol === 8 || symbol === 9) {
            symbol = Math.floor(Math.random() * length);
        }
        return symbol;
    }

    additionalFilter = reelMas => {
        reelMas.forEach((vector, reelIndex) => {     // прорисовка лент на канвах
            vector.forEach((symbol, rowIndex) => {
                if ((reelIndex === 0 || reelIndex === 1 || reelIndex === 2) && (symbol === 9)) { // вклееиваем верх бокала на ленту
                    reelMas[reelIndex][rowIndex - 1] = (symbol - 1);
                }
                if ((reelIndex === 0 || reelIndex === 1 || reelIndex === 2) && (symbol === 8)) { // вклееиваем низ бокала на ленту
                    reelMas[reelIndex][rowIndex + 1] = (symbol + 1);
                }
            });
        });
        return reelMas;
    };

    /**
     * Function check the part of long symbol
     * @param reelMas
     */
    isLongSymbolOnScreen = reelMas => {
        let length, isGlasses = false;
        reelMas.forEach((vector, reelIndex) => {     // провереем есть ли стаканы на барабанах
            vector.forEach((symbol, rowIndex) => {
                length = vector.length;
                if (
                    (reelIndex === 0 || reelIndex === 1 ||
                        reelIndex === 2) && (rowIndex === 1 ||
                    rowIndex === 2 || rowIndex === 3 ||  // ищем бокалы на лентах
                    rowIndex === length - 3 ||
                    rowIndex === length - 4 ||
                    rowIndex === length - 5) &&
                    (symbol === 8 || symbol === 9)
                ) {
                    isGlasses = true;
                }
            });
        });
        return isGlasses;
    };

    /**
     * Create on reels full symbol
     * @param reelMas
     */
    addLongSymbol = reelMas => {
        const reelIndex = Math.floor(Math.random() * 6);
        const position = 5;
        const topPosition = Math.round(Math.random() * 10);

        if ((reelIndex === 0 || reelIndex === 1 || reelIndex === 2) && topPosition) { // add long symbol on 0,1,2  reel
            reelMas[reelIndex][position] = 8;
            reelMas[reelIndex][position + 1] = 9;
        }
        if ((reelIndex === 0 || reelIndex === 1 || reelIndex === 2) && !topPosition) { // add long symbol over the screen
            reelMas[reelIndex][0] = 9;
            this.Roll.beforeScreenSymbols[reelIndex] = 9;
        }
        return reelMas;
    };

    createIceberg(parentContainer, reelIndex, rowIndex, name, symbol) {
        let iceberg = new AnimatedSprite(this.getIcebergTexture(0, 5, symbol));
        iceberg.name = 'iceberg' + name;
        iceberg.animationSpeed = 0.5;
        iceberg.position.set(this.reelXCoordinates[reelIndex], this.reelTop + this.symbolHeight * rowIndex);
        iceberg.loop = false;
        iceberg.visible = false;
        parentContainer.addChild(iceberg);

        iceberg = new AnimatedSprite(this.getIcebergTexture(5, 22, symbol));
        iceberg.name = '2iceberg' + name;
        iceberg.animationSpeed = 0.5;
        iceberg.position.set(this.reelXCoordinates[reelIndex], this.reelTop + this.symbolHeight * rowIndex);
        iceberg.loop = false;
        iceberg.visible = false;
        parentContainer.addChild(iceberg);
    }

    getIcebergTexture = (frame, frame2, symbol) =>
        [...Array(frame2 - frame)].map((item, index) => {
            index = index + frame;
            const symbolIceberg = symbol - 10;
            const width = 130, height = 98;
            const colIndex = index <= 4 ? index : index - 4;
            const rowIndex = index <= 4 ? symbolIceberg : 8 + symbolIceberg;

            return new Texture(this.getTexture('bonusAnimation'), {
                x: colIndex * width,
                y: rowIndex * height,
                width: width,
                height: height
            });
        });
}
