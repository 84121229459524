import App from './../../../index';
import Buttons from './../../buttons';

export default class RouletteButtons extends Buttons {
    setDefaultGameButtons() {
        App.View.setState(prevState => ({
            buttons: {
                ...prevState.buttons,
                // control buttons
                close: {
                    ...prevState.buttons.close,
                    disabled: App.configs.subMode === 'game' || prevState.loader.active,
                    handler: App.exitGame
                },

                // main game buttons
                start: {
                    disabled: true,
                    title: 'start',
                    handler: App.Game.checkSpin
                },
                info: {
                    ...prevState.buttons.info,
                    disabled: false,
                    title: 'paytable',
                    handler: App.Game.InfoScreen.show,
                    additionalClass: ''
                },
                denomination: {
                    disabled: false,
                    title: 'denom',
                    value: App.Money.getCurrentRouletteDenomination() / 100,
                    pressed: false
                },

                // roulette buttons
                double: {
                    disabled: true,
                    title: 'double',
                    handler: App.Game.doubleBets
                },
                cancel: {
                    disabled: true,
                    title: 'undo',
                    handler: App.Game.cancelAction
                },
                clear: {
                    disabled: true,
                    title: 'removeBets',
                    handler: App.Game.clearBets
                }
            }
        }));
    }

    disableAllButtons = () => {
        App.View.setState(prevState => ({
            buttons: {
                ...prevState.buttons,
                // control buttons
                close: {
                    ...prevState.buttons.close,
                    disabled: true
                },

                // main game buttons
                start: {
                    ...prevState.buttons.start,
                    disabled: true
                },
                info: {
                    ...prevState.buttons.info,
                    disabled: true
                },

                // info buttons
                prevInfo: {
                    disabled: true
                },
                nextInfo: {
                    disabled: true
                },

                // roulette buttons
                double: {
                    ...prevState.buttons.double,
                    disabled: true
                },
                cancel: {
                    ...prevState.buttons.cancel,
                    disabled: true
                },
                clear: {
                    ...prevState.buttons.clear,
                    disabled: true
                },

                // settings button on mobile platform
                gameSettings: {
                    ...prevState.buttons.gameSettings,
                    disabled: true
                }
            }
        }));
    };

    updateGameSettings() {
        App.View.setState(prevState => ({
            buttons: {
                ...prevState.buttons,
                bet: {
                    disabled: false,
                    title: 'bet',
                    value: App.Game.Bets.getTotal(),
                    pressed: false
                },
                total: {
                    disabled: false,
                    title: 'win',
                    value: App.Game.roundWin
                },
                denomination: {
                    disabled: false,
                    title: 'denom',
                    value: App.Money.getCurrentRouletteDenomination() / 100,
                    pressed: false
                }
            }
        }));
    }
}
