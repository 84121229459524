import {JL} from 'jsnlog';
import * as PIXI from 'pixi.js-legacy';

import './../../ways-game/styles.less';
import App from './../../../index';
import GameEgt from './../../egt/game';
import Lines from './../../egt/lines27';
import GambleEgt from './../../egt/gamble';
import InfoScreen from '../../infoScreen';
import Game from './../../Game';
import GameSettingsWays from './../../ways-game/gameSettings';

// PIXI aliases
const AnimatedSprite = PIXI.AnimatedSprite,
    Text = PIXI.Text,
    Sprite = PIXI.Sprite,
    Container = PIXI.Container;

export default class SupremeHot extends GameEgt {
    constructor() {
        super();
        this.id = 'supreme-hot';
        this.name = 'Supreme Hot';
        this.buttonsPanelShadow = 'mid';

        // reel properties
        this.reelRows = 3; // number of rows per reel
        this.reels = 3; // number of rows per reel
        this.reelXCoordinates = [60, 289, 519]; // magic numbers - x coordinates where reels start

        this.symbolHeight = 126; // height of aa single symbol
        this.symbolWidth = 218; // height of aa single symbol

        this.symbols = [
            {regularDelay: 90, skipSteps: 15, payment: [0, 0, 0, 10]},  // 0 - cherry
            {regularDelay: 90, skipSteps: 15, payment: [0, 0, 0, 10]},  // 1 - apple
            {regularDelay: 90, skipSteps: 15, payment: [0, 0, 0, 10]},  // 2 - peach
            {regularDelay: 90, skipSteps: 15, payment: [0, 0, 0, 10]},  // 3 - grape
            {regularDelay: 90, skipSteps: 15, payment: [0, 0, 0, 80]},  // 4 - bell
            {regularDelay: 90, skipSteps: 15, payment: [0, 0, 0, 80]},  // 5 - star
            {regularDelay: 90, skipSteps: 15, payment: [0, 0, 0, 100]}, // 6 - bar
            {regularDelay: 90, skipSteps: 15, payment: [0, 0, 0, 200]}, // 7 - dollar
            {regularDelay: 90, skipSteps: 15, payment: [0, 0, 0, 300]}  // 8 - symbol 7
        ];

        this.imageResources = {
            main: this.mergePath({mainArea: 'area/main.png'}),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                minimizeSymbols: 'minimizeSymbols.png',
                gambleArea: 'area/gamble.png',
                multiplierAnimation: 'area/multiplier.png'
            })
        };
        this.gameSounds = {
            soundClass: 'egt',
            sounds: [
                {name: 'gamble-wait'},
                {name: 'x2', loop: true}
            ],
            path: `/game/games/${this.id}/audio/`
        };
        this.Lines = new Lines(this.mergePath({boxes: 'lines/boxes.png'}));
        this.Gamble = new GambleEgt();
        this.InfoScreen = new InfoScreen({pages: 4}); // number of game info states
        this.InfoScreen.format = 'png';
        this.gameSettings = new GameSettingsWays();
        this.prepareToAnimateFeature = Game.prototype.prepareToAnimateFeature.bind(this);
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 19pt Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#f0870b';
        ctx.shadowOffsetX = ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 5;

        switch (page) {
            case 1:
                ctx.font = 'bold 12pt Arial';

                // cherry, lemon, orange
                this.strokeFillText(ctx, bet * this.symbols[8].payment[3] / 5, 435, 130);
                // Seven 7
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3] / 5, 435, 195);
                // Grape
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3] / 5, 435, 260);
                // plum, wathermelon
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3] / 5, 395, 320);
                // Start Scatter
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3] / 5, 395, 400);
                break;
            case 3:
                ctx.font = 'bold 14pt Arial';
                ctx.textAlign = 'left';
                // gamble limit
                this.strokeFillText(ctx, (this.Gamble.limit * 100) / App.Money.getCurrentDenomination(), 495, 245);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    createFeatureInfo(feature, container) {
        const {symbol, wayLine, payment, uc} = feature; // get current feature params

        const textProps = {
            font: 'Arial',
            fontSize: 14,
            fontWeight: 600,
            fill: '#f0e7cb'
        };
        const winSymbolsContainer = new Container();
        winSymbolsContainer.position.set(-100, -170);
        winSymbolsContainer.zIndex = 200;
        winSymbolsContainer.name = 'winSymbolsContainer';
        container.addChild(winSymbolsContainer);

        const statusLine = new Text(
            wayLine > 1 ? `${uc === 'WIN_WAY' ? wayLine + ' x:' : ''}` : '', textProps);
        statusLine.position.set(270, 470);

        const statusPayment = new Text(`${App.language.win.toUpperCase()}: ${payment} `, textProps);
        statusPayment.position.set(450, 470);

        for (let i = 0; i < 3; i++) {
            const minimizeSymbol = new Sprite(this.getSpriteTextures({
                image: 'minimizeSymbols',
                fromFrame: symbol, colCount: this.symbols.length,
                width: 35, height: 19
            })[0]);

            minimizeSymbol.position.x = 420 + (35 * i);
            minimizeSymbol.position.y = 639;
            winSymbolsContainer.addChild(minimizeSymbol);
        }
        container.addChild(statusLine, statusPayment);
    }

    // WAYS MAIN LOGIC TO SHOW WAYS INSTEAD OF LINES OR SCATTERS

    /**
     * Function to show text for special feature
     * @param feature
     */
    setFeatureText(feature) {
        const {uc, payment} = feature; // get current feature params

        !['SPECIAL_SYMBOL', 'FREE_ROLL', 'CHOOSING', 'WIN_WAY'].includes(uc) &&
        this.Legends.setText('features', {
            text: uc === 'SCATTER' ? 'scatterPays' : uc === 'WIN_WAY' ? feature.count + ' x' : 'linePays',
            value: payment
        });
    }

    /**
     * Function to show line for special feature
     * @param currentFeature
     */
    showFeatureLine(currentFeature) {
        const {positions, uc} = currentFeature, // get current feature params
            container = this.getStageChild('linesContainer');
        uc !== 'SPECIAL_SYMBOL' && container.removeChildren(); // don't clear lines before special symbol (bookGame fill)
        if (['WIN_WAY'].includes(uc)) {
            positions.forEach(({row, reel}) => {
                this.Lines.drawWinBoxes(container, 0, reel, row);
            });
            this.getState() !== 'SHOW_BONUS_WIN_LINES' && this.createFeatureInfo(currentFeature, container);
        }
    }

    additionalPreparingToAnimateFeature() {
        if (this.getMultiplier()) {
            App.Sounds.playSound('x2');
            const sprite = new AnimatedSprite(this.getSpriteTextures({
                image: 'multiplierAnimation', width: 480, height: 280,
                fromFrame: 0, toFrame: 2, colCount: 2
            }));
            sprite.name = 'multiplierAnimation';
            sprite.position.set(150, 100);
            sprite.animationSpeed = 0.03;
            sprite.play();
            this.getStageChild('boxesContainer').addChild(sprite);
        }
    }

    stopWaitingAnimation() {
        App.Sounds.stopSound('x2');
        this.Lines.drawBoxes(this.getStageChild('boxesContainer'), -1);
        cancelAnimationFrame(this.waitingAnimationFrame);
        this.waitingAnimationFrame = null;
    }

    /**
     * Manually stopping win animation to get win now
     */
    speedUpWinLineAnimation = () => {
        JL().debug('-- Speed up win line animation');
        App.Sounds.stopSound('x2');
        this.Lines.drawBoxes(this.getStageChild('boxesContainer'), -1);
        App.updateButton('start', {
            disabled: false, title: 'stop',
            handler: this.stopWinLineAnimation
        });
    };

    getMultiplier() {
        const firstSymbol = this.latestResponse.screen[0][0];
        let count = 0;
        this.latestResponse.screen.forEach(reel =>
            reel.forEach(symbolObj =>
                firstSymbol === symbolObj && symbolObj < 4 && count++));
        return count === 9;
    }

    /**
     * Change lines on mobile platform
     * @param type - increase or decrease lines
     */
    changeLinesNumbers(type) {

    }

    startGamble() {
        this.setState('ASK_GAMBLE');
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        this.Gamble.prizeWin = this.latestResponse.payment;
        this.isBonus() && this.stopAnimateFeature();
        if (!this.getMultiplier()) {
            App.Sounds.playSound('gamble-wait');
        }
        App.updateButton('start', {
            disabled: false,
            title: 'collect',
            handler: this.finishGamble
        });
        App.updateButton('select', {
            disabled: false,
            title: 'gamble',
            handler: this.Gamble.goToGamble
        });
        this.Legends.setText('win', {text: 'win', value: this.latestResponse.payment});
        this.setGambleUpStatus();
    }

    /**
     * Process reels response from server.
     * @param response - Socket response 'ROLL'
     */
    processReelResponse(response) {
        this.latestResponse = response;
        this.waysCreate();
        this.setState('RESPONSE_RECEIVED');
        this.setBonusRollSymbol(); // for bonus game roll symbol
        this.prepareToRollAnimation(response);
    }

    /**
     * Rebuild object for animateFeature
     */
    waysCreate() {
        const responseNew = [{}, {}, {}, {}, {}, {}, {}, {}, {}];
        const responseTemp = [];
        this.latestResponse.features.forEach(feature => {
            if (Object.keys(responseNew[feature.symbol]).length === 0) {
                responseNew[feature.symbol] = feature;
                responseNew[feature.symbol].wayLine = 1;
            } else {
                responseNew[feature.symbol].payment += feature.payment;
                responseNew[feature.symbol].count += feature.count;
                responseNew[feature.symbol].wayLine++;
                feature.positions.forEach(position =>
                    responseNew[feature.symbol].positions.push(position));
            }
        });

        responseNew.forEach(feature =>
            Object.keys(feature).length !== 0 && responseTemp.push(feature));

        this.latestResponse.features = [...responseTemp];
    }

    getFeatureDelay(currentFeature, features) {
        let delay = 0;
        switch (currentFeature.uc) {
            case 'WIN_WAY':
            case 'WIN_LINE':
                delay = !this.gameFlag.bonusStarted ? this.winLineFeatureDelay : this.winLineFeatureDelay / 2;
                break;
            case 'SCATTER':
            case 'SPECIAL_SYMBOL':
                delay =
                    features.some(feature => feature.uc === 'FREE_ROLL') &&
                    !features.some(feature => feature.uc === 'WIN_LINE') ?
                        0 : this.winLineFeatureDelay;
                break;
        }
        if (this.getMultiplier()) delay = 5000;
        return delay;
    }
}
