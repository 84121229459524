import Lines17 from './../../champion/lines17';

export default class Lines extends Lines17 {
    constructor() {
        super();
        this.imageResources = {
            boxes: '/img/lines/champion_boxes17.png'
        };

        this.boxSettings.boxFontSize = 16;

        this.linesSettings = {
            ...this.linesSettings,
            lineThickness: 3,
            borderThickness: 4,
            lineColor: '#f78000',
            borderColor: '#984f00'
        };
        this.lines = {
            0: {coordinates: [1, 1, 1], boxes: [{x: 20, y: 273}]},
            1: {coordinates: [0, 0, 0], boxes: [{x: 20, y: 148}]},
            2: {coordinates: [2, 2, 2], boxes: [{x: 20, y: 398}]},
            3: {coordinates: [0, 1, 2], boxes: [{x: 20, y: 118}]},
            4: {coordinates: [2, 1, 0], boxes: [{x: 20, y: 428}]},
            5: {coordinates: [0, 1, 0], boxes: [{x: 20, y: 178}]},
            6: {coordinates: [2, 1, 2], boxes: [{x: 20, y: 368}]},
            7: {coordinates: [1, 0, 1], boxes: [{x: 20, y: 243}]},
            8: {coordinates: [1, 2, 1], boxes: [{x: 20, y: 303}]},
            9: {coordinates: [2, 1, 1], boxes: [{x: 725, y: 287}]},
            10: {coordinates: [0, 1, 1], boxes: [{x: 725, y: 257}]},
            11: {coordinates: [1, 2, 2], boxes: [{x: 725, y: 385}]},
            12: {coordinates: [1, 0, 0], boxes: [{x: 725, y: 159}]},
            13: {coordinates: [1, 1, 2], boxes: [{x: 725, y: 415}]},
            14: {coordinates: [1, 1, 0], boxes: [{x: 725, y: 129}]},
            15: {coordinates: [2, 2, 1], boxes: [{x: 725, y: 317}]},
            16: {coordinates: [0, 0, 1], boxes: [{x: 725, y: 227}]}
        };
    }
}
