import React from 'react';
import * as PIXI from 'pixi.js-legacy';
import 'pixi-spine';
import {JL} from 'jsnlog';

import App from './../index';
import GameSettings from './gameSettings';
import Legends from './legends';
import Buttons from './buttons';
import Roll from './roll';
import SymbolInfo from './symbolInfo';
import bigWinFont from './../img/bigWin/font/bigWinFont';
import availableGames from './../../api/availableGames';
import BigWin from './bigWin';

/* PIXI aliases */
const Application = PIXI.Application,
    Loader = PIXI.Loader,
    Texture = PIXI.Texture,
    Sprite = PIXI.Sprite,
    AnimatedSprite = PIXI.AnimatedSprite,
    Container = PIXI.Container,
    Text = PIXI.Text,
    Graphics = PIXI.Graphics;

export default class Game {
    constructor() {
        this.state = 'NONE'; // main Game state

        // create base Game classes
        this.gameSettings = new GameSettings();
        this.Legends = new Legends();
        this.Buttons = new Buttons();
        this.SymbolInfo = new SymbolInfo();

        this.gameFieldWidth = 800;
        this.gameWidth = App.System.resolution === '4x3' ? this.gameFieldWidth : 1067;
        this.gameFieldHeight = 600;
        this.gameHeight = App.configs.doubleScreen ? 1200 : 600;
        this.DOMComponents = {}; // collection of components for Game
        this.imageResources = {}; // preload img src collection (unique for each game)
        this.additionalResources = {}; // post load img src collection (unique for each game)
        this.gameSounds = {}; // sounds src collection (unique for each game)
        this.buttonsPanelShadow = null; // type of shadow on button panel or additionally reset blur (low, mid, strong, no-blur)
        this.isregularSymbols = true;
        this.id = null; // game id
        this.name = ''; // game full name

        // roll settings
        this.reelSymbol = [0, 0, 0, 0, 0, 0]; // reel symbols mas will be fielded by setReelSymbol function
        this.reelLong = [0, 0, 0, 0, 0, 0]; // reel symbols mas will be fielded by setReelSymbol function
        this.reelSettings = [0, 0, 0]; // 0 - start symbol amount, 1 - reel increase regular roll, 2 - reel increase long roll
        this.reelXCoordinates = [0, 0, 0, 0, 0, 0]; // magic numbers - x coordinates where reels start
        this.transparentBackground = false; // use transparent symbols background or not
        this.reelFilter = [[], [], [], [], [], []];
        this.symbolAnimation = true;
        this.allowLongRoll = false;
        this.longRollSettings = [0, 6]; // long roll from reelIndex to reelIndex
        this.stopOneReel = false;

        // additional unique classes for each game
        this.Lines = null;
        this.Gamble = null;
        this.InfoScreen = null;
        this.Roll = new Roll();
        this.BigWin = new BigWin(); // functionality of big win scenario;
        this.mainCanvas = React.createRef();

        // roll properties
        this.reels = 5; // number of reels
        this.reelRows = 3; // number of rows per reel
        this.symbols = null;
        this.reelMatrix = []; // current state of reels.
        this.reelTop = 0;
        this.reelSettings = [14, 5, 20]; // 0 - start symbol amount, 1 - reel increase regular roll, 2 - reel increase long roll
        this.rollProperties = {
            reelSpeed: 1.7,
            springDown: 0.3,
            springUp: 0.2
        };
        this.symbolWidth = 0;
        this.symbolHeight = 0;
        this.offsetReelMask = {
            offsetX: 0, offsetY: 0,
            offsetWidth: 0, offsetHeight: 0
        };
        this.Loader = new Loader(); // create new instance for PIXI Loader
        this.resources = {}; // loaded resources
        this.resourcesSettings = {loaded: 0, total: 0, callback: null};
        this.containersLayers = {
            reelsStage: 0,
            mainContainer: 1,
            linesContainer: 2,
            boxesContainer: 4,
            extraBetContainer: 5,
            bonusContainer: 6,
            symbolInfo: 7,
            infoContainer: 8
        };

        this.scatters = [];
        this.latestResponse = null;
        this.gambleResponse = null;
        this.choosingResponse = null;
        this.choosingQueue = [];

        this.bonusRollSymbol = null;
        this.symbolEffects = false; // symbol shadow
        this.defaultFeatureDelay = 700; // ms for show win line
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        this.features = {step: 0};
        this.lastScreen = null;
        this.isSpeedUp = 0;
        this.isAutoStart = false;
        this.extraBet = false; // existing ExtraBet feature in game
        this.extraBetActive = false; // current enabled state
        this.additionalSpineImages = 0;  // when there are one json and several atlases
        this.bonusWin = 0;
        this.roundWin = 0;
        this.currentPrizeIndex = 0;
        this.gameFlag = {
            bonusStart: false, // bonus game start button active
            bonusStarted: false
        };
        this.bigWinCoef = {
            bigWin: 20,
            megaWin: 35,
            hugeWin: 50
        };
        this.symbolBackground = false;
        this.idAnimation = null; // big win coins animation
        this.bigWinCounter = null; // big win number (win) counter
        this.waitingAnimationFrame = null; // for additional waiting animations;
        this.infoRAF = null; // for additional info animations;
        JL().debug('-- Game class initialized');
    }

    /**
     * Start image loading
     * Call after webSocket message 'GAME-SETUP'
     */
    initGame() {
        App.addLoaderMessage(`${App.language.initializingGame} '${this.name}'`);
        document.title = `${this.name} - ${App.name.split(' ').join('')}`; // set game name to title
        App.Sounds.createGameSounds(this.gameSounds);
        this.setState('GAME_IMAGES_LOADING');
        App.updateState('buttons', {visualization: App.buttonsType});
        this.updateCredits();

        App.showLoadPercent(0);
        App.addLoaderMessage(App.language.loadingImages);
        this.loadResources(this.getResourcesToPreload(App.restoreGameState),
            () => App.renderGameComponent());
    }

    /**
     * Collect all images to one object
     */
    getResourcesToPreload = restoreGameState => {
        const {main = {}, jsonAnimations = {}, atlas = [], video = {}, fonts = {}} = this.imageResources;
        const resources = {
            main: {...main, ...this.Lines.imageResources},
            jsonAnimations, atlas,
            images: {...(App.configs.doubleScreen ? this.InfoScreen.getResources() : {})},
            video, fonts
        };

        const bindResources = (res1, res2) => ({
            main: {...res1.main, ...res2.main},
            jsonAnimations: {...res1.jsonAnimations, ...res2.jsonAnimations},
            atlas: [...res1.atlas, ...res2.atlas],
            images: {...res1.images, ...res2.images},
            video: {...res1.video, ...res2.video},
            fonts: {...res1.fonts, ...res2.fonts}
        });

        // restoreGameState === 'NONE' ? resources :
        return bindResources(resources, this.getResourcesToLoad());
    };

    /**
     * Collect all images to one object
     */
    getResourcesToLoad = () => {
        const {main = {}, jsonAnimations = {}, atlas = [], video = {}, fonts = {}} = this.additionalResources;
        return {
            main: { // add default and line images
                ...main,
                ...(this.BigWin.enabled ? {
                    bigWin: '/img/bigWin/big-win.png',
                    megaWin: '/img/bigWin/mega-win.png',
                    hugeWin: '/img/bigWin/huge-win.png',
                    bigWinFont: bigWinFont.src,
                    coinsSprite: '/img/bigWin/coinsSprite.png',
                    light: '/img/bigWin/light.png'
                } : {})
            },
            jsonAnimations: {...jsonAnimations},
            atlas: [
                ...this.symbolAnimation && this.isregularSymbols ? this.mergePath(this.symbols.map((item, index) =>
                    [`regularSymbols${index >= 10 ? '' : '0'}${index}.json`])) : [],
                ...atlas
            ],
            images: {
                ...(!App.configs.doubleScreen ? this.InfoScreen.getResources() : {}),
                ...(this.Gamble ? this.Gamble.imageResources : {})
            },
            video, fonts
        };
    };

    /**
     * Load current game images
     * Create PIXI.Texture for each frame and push to collection
     * @param resources
     * @param callback - function call after loading images
     */
    loadResources(resources, callback) {
        const {main, images, jsonAnimations, atlas, video, fonts} = resources;

        // calc all images count
        const pixiResourcesLength = Object.keys(main).length + Object.keys(images).length + Object.keys(atlas).length;
        const additionalResourcesLength = Object.keys(video).length + Object.keys(fonts).length + Object.keys(jsonAnimations).length + this.additionalSpineImages;

        this.resourcesSettings = {
            loaded: 0,
            total: pixiResourcesLength + additionalResourcesLength,
            isError: false,
            errors: 0,
            problemRes: {main: [], images: [], jsonAnimations: [], atlas: [], video: [], fonts: []}
        };
        JL().debug(`-- Start loading all resources (count: ${this.resourcesSettings.total})`);

        // on load event, check all loaded resources orr some errors
        const onLoad = () => {
            const {waiting} = App.View.state;
            //  this.resourcesSettings.isError && this.resourcesLoadingError();

            if (this.isResourcesLoaded()) {
                JL().debug(`-- All resources are loaded (count: ${this.resourcesSettings.loaded})`);
                if (App.configs.mode === 'info') {
                    callback?.();
                } else {
                    App.Socket.webSocket && callback?.();
                }
                waiting.callback?.();
            } else {
                if (this.resourcesSettings.errors + this.resourcesSettings.loaded === this.resourcesSettings.total) {
                    JL().debug(`-- Filed to load images try to reload (error files count: ${this.resourcesSettings.errors})`);
                    this.Loader.onError.detachAll();
                    this.Loader.onLoad.detachAll();
                    this.Loader.reset();
                    PIXI.utils.clearTextureCache();
                    this.resourcesSettings.errors = 0;
                    this.resourcesSettings.loaded = 0;
                    setTimeout(() => {
                        // this.loadResources(this.resourcesSettings.problemRes, callback); //reload one bad resource
                        this.loadResources(resources, callback); // reload all  resources again
                    }, 1000);
                }
            }
        };

        // add load listener for each image
        this.Loader.onLoad.detachAll();
        this.Loader.onLoad.add((event, resource) => {
            // check if it's not json file
            ['png', 'jpg'].includes(resource.extension) && this.resourcesSettings.loaded++;
            this.getState() === 'GAME_IMAGES_LOADING' &&
            App.showLoadPercent(this.resourcesSettings.loaded, this.resourcesSettings.total);
        });
        // eslint-disable-next-line handle-callback-err
        this.Loader.onError.add((error, loader, resource) => {
            this.resourcesSettings.errors++;
            this.resourcesSettings.problemRes.main.push(resource.url);
        });
        this.loadImages(images, onLoad);
        this.loadVideos(video, onLoad);
        this.loadFonts(fonts, onLoad);

        // load symbols
        atlas.forEach(url =>
            this.Loader.add(url, App.getUrl(url)));

        // load additional .json resources
        Object.keys(jsonAnimations).forEach(key =>
            this.Loader.add(jsonAnimations[key], App.getUrl(jsonAnimations[key])));

        // load main images
        Object.keys(main).forEach(key =>
            this.Loader.add(key, App.getUrl(main[key])));

        // on load listener
        this.Loader.load((loader, allResources) => {
            this.parseJsonImages(jsonAnimations, allResources);
            this.Roll.parseJsonImages(atlas, allResources);
            JL().debug(`-- PixiJS images loaded (count: ${pixiResourcesLength})`);
            onLoad();
        });
    }

    /**
     * Load additional images with custom loader
     * @param resources
     * @param callback
     */
    loadImages(resources, callback) {
        const keys = Object.keys(resources);
        let loaded = 0;

        // create Image obj for each element
        keys.length ? keys.forEach(key => {
            const img = new Image();
            img.crossOrigin = 'anonymous';
            img.src = App.getUrl(resources[key]);
            img.addEventListener('load', () => {
                this.resources[key] = img;
                this.resourcesSettings.loaded++;
                App.showLoadPercent(this.resourcesSettings.loaded, this.resourcesSettings.total);
                loaded++;

                // all images loaded
                if (loaded === keys.length) {
                    JL().debug(`-- Additional images loaded (count: ${keys.length})`);
                    callback();
                }
            });
            img.addEventListener('error', () => {
                this.resourcesSettings.errors++;
                this.resourcesSettings.problemRes.images.push(img.src);
                callback();
            }, false);
        }) : callback();
    }

    /**
     * Load videos with custom loader
     * @param resources
     * @param callback
     */
    loadVideos(resources, callback) {
        const keys = Object.keys(resources);
        let loaded = 0;

        keys.length ? keys.forEach(key => {
            const video = document.createElement('video');
            video.crossOrigin = 'anonymous';
            video.preload = 'auto';
            video.autoload = true;
            video.muted = true;
            video.loop = true;
            video.playsInline = true;

            const canplaythrough = () => {
                this.resources[key] = video;
                this.resourcesSettings.loaded++;
                loaded++;

                // all videos are loaded
                if (loaded === keys.length) {
                    JL().debug(`-- Videos loaded (count: ${keys.length})`);
                    callback();
                }
                video.removeEventListener('canplaythrough', canplaythrough, false);
            };
            video.addEventListener('canplaythrough', canplaythrough, false);
            video.addEventListener('error', () => {
                this.resourcesSettings.errors++;
                this.resourcesSettings.problemRes.video.push(key);
            }, false);
            video.src = resources[key];
            video.load();

            // fix iOS loading
            document.querySelector('.logo-image')?.addEventListener('touchmove',
                () => video.load());
        }) : callback();
    }

    /**
     * Load additional font face
     * @param resources
     * @param callback
     */
    loadFonts(resources, callback) {
        const keys = Object.keys(resources);
        let loaded = 0;

        keys.length ? keys.forEach(async key => {
            try {
                const font = new FontFace(key, `url(${resources[key]})`);
                const loadedFont = await font.load();
                document.fonts.add(loadedFont);
                this.resourcesSettings.loaded++;
                loaded++;

                // all fonts loaded
                if (loaded === keys.length) {
                    JL().debug(`-- Fonts loaded (count: ${keys.length})`);
                    callback();
                }
            } catch (err) {
                this.resourcesSettings.errors++;
                this.resourcesSettings.problemRes.fonts.push(resources[key]);
            }
        }) : callback();
    }

    /**
     * Handle actions before resources total loading
     * @param callback
     */
    checkLoadedResources(callback) {
        App.View.state.waiting.firstCheck && // send before first roll || info
        App.System.sendMetric({param: `resourcesWaiting.resources.${this.isResourcesLoaded() ? 'loaded' : 'loading'}`});
        App.updateState('waiting', {firstCheck: false});

        const time = Date.now();
        this.isResourcesLoaded() ?
            callback?.() :
            App.updateState('waiting', {
                active: true,
                callback: () => {
                    App.System.sendMetric({param: 'resourcesWaiting.time', value: Date.now() - time, method: 'put'});
                    App.updateState('waiting', {active: false, callback: null}); // reset <Waiting> states
                    callback?.();
                }
            });
    }

    /**
     * Return loading state
     * @returns {boolean}
     */
    isResourcesLoaded = () => this.resourcesSettings.loaded === this.resourcesSettings.total;

    /**
     * Close socket and show 'connection lost' message
     */
    resourcesLoadingError = () => {
        App.showSocketMessage('connectionLost', false, 'disconnect');

        const onClose = () => App.updateButton('close', {disabled: true});
        App.Socket.webSocket?.addEventListener('close', onClose, true);
        App.Socket.webSocket?.close();
        App.Socket.webSocket?.removeEventListener('close', onClose);
    };

    /**
     * Save json animations to resources collection
     * @param jsonCollection
     * @param resources
     */
    parseJsonImages(jsonCollection, resources) {
        Object.keys(jsonCollection).forEach(jsonKey => {
            const json = jsonCollection[jsonKey];
            const {textures, spineData} = resources[json];
            this.resources[jsonKey] = [];

            // create Texture for each symbol image
            textures ?
                Object.keys(textures).forEach(key =>
                    this.resources[jsonKey].push(new Texture.from(key))) :
                this.resources[jsonKey] = spineData; // save spine data
        });
    }

    /**
     * Function to merge path with each collection element
     * @param path{string}
     * @param collection - Object or Array
     * @returns {string} - new collection
     */
    mergePath = (collection = '', path = `/game/games/${this.id}/img/`) => {
        collection.length ? // check array/object
            collection.forEach((obj, index) => collection[index] = path + obj) :
            Object.keys(collection).forEach(key => collection[key] = path + collection[key]);
        return collection;
    };

    /**
     * Get image source from Loader resources array
     * @param name
     */
    getImage = name => this.Loader.resources[name].data;

    /**
     * Get PIXI.Texture from Loader resources array
     * @param name
     */
    getTexture = name => this.Loader.resources[name]?.texture;

    /**
     * Get JSON PIXI.Textures from resources
     * @param name
     */
    getJsonTextures = name => this.getAdditionalImage(name);

    /**
     * Get JSON PIXI.spine.Spine from resources
     * @param name
     */
    getSpineData = name => this.getAdditionalImage(name);

    /**
     * Get image obj from resources array
     * @param name
     */
    getAdditionalImage = name => this.resources[name];

    /**
     * Get array of textures for AnimatedSprite
     * @param fromFrame - start frame in array
     * @param toFrame - last frame
     * @param image - texture name
     * @param width
     * @param height
     * @param colCount - frames in one row
     * @param rowCount - frames in one col
     * @param direction
     */
    getSpriteTextures = (
        {
            fromFrame = 0, toFrame = fromFrame + 1,
            image, width, height,
            colCount = toFrame - fromFrame,
            rowCount = toFrame - fromFrame,
            direction = 'horizontal'
        }) =>
        // create array, calc length
        [...Array(toFrame - fromFrame)].map((item, index) => {
            let colIndex, rowIndex;
            index += fromFrame; // set start frame

            switch (direction) {
                case 'horizontal':
                    colIndex = index % colCount;
                    rowIndex = Math.floor(index / colCount);
                    break;
                case 'vertical':
                    colIndex = Math.floor(index / rowCount);
                    rowIndex = index % rowCount;
                    break;
            }

            return new Texture(this.getTexture(image), {
                x: colIndex * width,
                y: rowIndex * height,
                width, height
            });
        });

    /**
     * Draw all game parts after Game DOM creating
     * Check restore game
     */
    drawGame() {
        JL().debug(`-- Draw game (restoreGameState: ${App.restoreGameState})`);
        this.setState('DRAW_GAME');
        this.Buttons.updateGameSettings();
        this.initPIXIApp();
        this.createPIXIContainers(this.getStage());

        switch (App.restoreGameState) {
            case 'WIN_LINES': // if restore win lines -> show roll
                this.restoreRoll();
                break;
            case 'TRANSFER':
                this.restoreTransfer();
                break;
            case 'GAMBLE':
                this.Gamble.restoreGambleScreen(this.gambleResponse);
                break;
            case 'BONUS':
                this.restoreBonusGame();
                break;
            case 'CHOOSING':
                this.restoreChoosingScreen(this.choosingResponse);
                break;
            case 'WIN_SPIN':
                this.restoreSpin(this.latestResponse);
                break;
            default:
                //  this.loadResources(this.getResourcesToLoad(), () => this.SymbolInfo.init());
                this.Legends.showJackpot();
                App.configs.mode !== 'info' && this.showGameIntroduction();
                break;
        }
        if (App.configs.mode === 'info') {
            // App.Info.drawInfo();
            this.hideBoxes();
            this.hideLines();
            App.Info.drawInfo();
        } else {
            this.updateKioskInfo();
        }
        App.restoreGameState = 'NONE';
        JL().debug(`-- '${this.name}' loaded`);
    }

    /**
     * Create PixiJS application
     */
    initPIXIApp() {
        const canvas = this.mainCanvas.current;

        this.app = new Application({
            width: canvas.width,
            height: canvas.height,
            view: canvas, // bind existing canvas
            transparent: true,
            antialias: true, // for custom lines
            forceCanvas: App.System.verifyWebGLSupport() // enable Canvas rendering
        });
        this.app.stage.sortableChildren = true;
        this.createStageContainer();
        this.resizeStage();
        window.addEventListener('resize', this.resizeStage);
        PIXI.settings.ROUND_PIXELS = true;
    }

    /**
     * Create all scenes
     */
    createPIXIContainers(container) {
        this.Roll.createReelsContainer(container, this.transparentBackground);
        this.createMainContainer(container);
        this.createBonusContainer(container);
        this.createWaitingContainer(container);
        this.Lines.createLinesContainer(container);
        this.Lines.createBoxesContainer(container);
        this.extraBet && this.createExtraBetContainer(container);
        this.Buttons.createContainer(container);
        App.configs.doubleScreen && this.createSecondScreenContainer(container);
        this.createAdditionalSprites(container);
        App.configs.subMode === 'test' && this.addFpsMeter(container);
    }

    /**
     * Create PIXI.Container for inner 4x3 content
     */
    createStageContainer() {
        const container = new Container();
        container.name = 'stage';
        container.sortableChildren = true;
        container.position.set(
            (this.gameWidth - this.gameFieldWidth) / 2,
            App.configs.doubleScreen ? this.gameFieldHeight : 0
        );
        this.app.stage.addChild(container);
    }

    /**
     * Function returns PIXI main stage
     * @returns {PIXI.DisplayObject}
     */
    getStage = () => this.app.stage.getChildByName('stage');

    /**
     * Function returns PIXI object from parent container
     * @param name - child name
     * @returns {PIXI.DisplayObject}
     */
    getStageChild = name => this.getStage().getChildByName(name);

    /**
     * Create PIXI.Container for game background
     * Add additional sprites to container
     * @param parentContainer
     */
    createMainContainer(parentContainer) {
        const container = new Container();
        container.name = 'mainContainer';
        container.zIndex = this.containersLayers[container.name];

        const sprite = new Sprite(this.getTexture('mainArea'));
        sprite.name = 'mainArea';
        sprite.position.set(this.gameFieldWidth / 2, this.gameFieldHeight / 2);
        sprite.anchor.set(0.5);
        container.addChild(sprite);
        parentContainer.addChild(container);
    }

    createExtraBetContainer = parentContainer => {
        const container = new Container();
        container.name = 'extraBetContainer';
        container.zIndex = this.containersLayers[container.name];
        parentContainer.addChild(container);

        this.initExtraBet(container);
    };

    /**
     * Create PIXI.Container for game bonus animations
     * Add additional sprites to container
     * @param parentContainer
     */
    createBonusContainer = parentContainer => {
        const container = new Container();
        container.name = 'bonusContainer';
        container.interactiveChildren = true;
        container.zIndex = this.containersLayers[container.name];
        parentContainer.addChild(container);
    };

    /**
     * Create PIXI.Container for game bonus animations
     * Add additional sprites to container
     * @param parentContainer
     */
    createIntroContainer = parentContainer => {
        const container = new Container();
        container.name = 'introContainer';
        container.interactiveChildren = true;
        container.zIndex = this.containersLayers[container.name];
        parentContainer.addChild(container);
    };

    /**
     * Create PIXI.Container for game bonus animations
     * Add additional sprites to container
     * @param parentContainer
     */
    createWaitingContainer = parentContainer => {
        const container = new Container();
        container.name = 'waitingContainer';
        container.interactiveChildren = true;
        container.zIndex = this.containersLayers[container.name];
        parentContainer.addChild(container);

        this.addWaitingAnimationSprites(container);
    };

    /**
     * Create PIXI.Container for info background
     * @param parentContainer
     */
    createSecondScreenContainer(parentContainer) {
        const container = new Container();
        container.name = 'secondScreen';
        container.position.set(0, -this.gameFieldHeight);
        parentContainer.addChild(container);

        if (this.Loader.resources['mainArea']) {
            const sprite = new Sprite(this.getTexture('mainArea'));
            sprite.name = 'mainArea';
            sprite.position.set(this.gameFieldWidth / 2, this.gameFieldHeight / 2);
            sprite.anchor.set(0.5);
            container.addChild(sprite);
        }

        this.createInfoContainer(parentContainer);
    }

    /**
     * Create PIXI.Container for info pages
     * @param parentContainer
     */
    createInfoContainer = parentContainer => {
        const container = new Container();
        container.name = 'infoContainer';
        container.interactiveChildren = true;
        container.position.set(-(this.gameWidth - this.gameFieldWidth) / 2, -this.gameFieldHeight);
        container.zIndex = this.containersLayers[container.name];
        parentContainer.addChild(container);

        this.InfoScreen.update();
    };

    /**
     * Create PIXI.Text with current fps, add to main stage
     * @param parentContainer
     */
    addFpsMeter(parentContainer) {
        const fpsArr = [];
        const getAverage = () => {
            fpsArr.push(this.app.ticker.FPS);
            fpsArr.length > 60 && fpsArr.shift();
            return fpsArr.reduce((prev, current) => prev + current) / 60;
        };

        const fpsMeter = new Text('0.0', {
            fontSize: 15,
            fill: '#fff'
        });
        fpsMeter.name = 'FPS_Meter';
        fpsMeter.position.set(720, 40);
        fpsMeter.zIndex = 10;
        parentContainer.addChild(fpsMeter);

        this.app.ticker.add(() => fpsMeter.text = `FPS: ${getAverage().toFixed(1)}`);
    }

    /**
     * Function to create Game matrix of reel components
     */
    createReelMatrix(parentContainer, screen = this.latestResponse.screen) {
        if (App.configs.mode === 'info') {  // create fake latest response screen to make info mode
            screen = [];
            for (let i = 0; i < this.reels; i++) {
                screen.push([]);
                for (let j = 0; j < this.reels; j++) {
                    screen[i].push(0);
                }
            }
        }
        this.Roll.removeClipMatrix(parentContainer);
        this.reelMatrix = [];

        for (let reelIndex = 0; reelIndex < this.reels; reelIndex++) {
            this.reelMatrix[reelIndex] = [];
            for (let rowIndex = 0; rowIndex < this.reelRows; rowIndex++) {
                const symbolIndex = screen[reelIndex][rowIndex];
                const symbolObj = this.createStageContainers(parentContainer, symbolIndex, rowIndex, reelIndex);

                this.reelMatrix[reelIndex][rowIndex] = symbolObj;
                this.Roll.updateSymbolSprite(symbolObj);
            }
        }
    }

    createStageContainers(parentContainer, symbolIndex, rowIndex, reelIndex) {
        const image = this.getSymbolImageType(symbolIndex, reelIndex, rowIndex);
        const symbolParams = this.symbols[symbolIndex];
        const {regularDelay, zIndex} = symbolParams;
        const delay = symbolParams[`${image}Delay`] || regularDelay;
        symbolIndex = this.getSymbolIndex(symbolIndex, reelIndex, rowIndex);

        const symbolContainer = new Container();
        symbolContainer.sortableChildren = true;
        symbolContainer.name = symbolIndex;
        symbolContainer.zIndex = zIndex || 0;

        parentContainer.addChild(symbolContainer);

        // create sprite from texture
        const sprite = new AnimatedSprite(this.Roll.textures[image][symbolIndex]);
        sprite.name = 'symbol';
        sprite.animationSpeed = 15 / delay;
        sprite.anchor.set(0.5);
        sprite.position.set(this.symbolWidth / 2, this.symbolHeight / 2);

        // create reelMatrix object
        const symbolObj = {
            symbolContainer,
            symbolBackground: this.symbolBackground ?
                this.addSymbolBackground(symbolContainer, sprite.position.x, sprite.position.y) : null,
            sprite,
            position: {
                x: this.reelXCoordinates[reelIndex] - this.reelXCoordinates[0],
                y: rowIndex * this.symbolHeight
            },
            symbol: symbolIndex,
            image,
            loop: true // playing animation mode, 'true' - loop, 'false' - only once
        };
        symbolContainer.addChild(sprite);
        return symbolObj;
    }

    addSymbolBackground(symbolContainer, x, y) {

    }

    /**
     * hiding symbols in clip Matrix that are higher reelsStage during roll
     */
    hideTopSymbols() {

    };

    /**
     * hiding symbols in clip Matrix that are below reelsStage during roll
     */
    hideBottomSymbols() {

    };

    /**
     * Create additional sprites and animations for stage
     * Call once when game loaded
     * @param parentContainer
     */
    createAdditionalSprites(parentContainer) {

    }

    /**
     * Create additional sprites and animations
     * Call once when game loaded
     * @param parentContainer
     */
    addWaitingAnimationSprites(parentContainer) {

    }

    /**
     * Start game animations
     */
    showGameIntroduction() {
        this.setState('GAME_INTRO');
        this.roundFinished();
        this.playIntroSound();
    }

    start = () => {
        App.updateButton('gameSettings', {status: false, additionalClass: ''});
        App.Modal.reset();
        this.Buttons.closeWrap();
        const bet = this.gameSettings.getBet();
        const denomination = App.Money.getCurrentDenomination();
        const cents = App.Money.getCents();
        const minBet = App.Money.getMinBet();

        // if not enough money -> reset bet or line
        if (bet > cents) {
            this.createPopup('gotNoMoney');
            this.extraBetActive && this.extraBetClick();
            this.changeBetLine(bet);
        } else {
            // if low bet & enough money -> show message
            if (bet < minBet && cents >= minBet) {
                const minPrice = App.Money.denominations.length === 1 ?
                    `${(minBet / 100).toFixed(2)} ${App.Money.getCurrency()}` :
                    minBet / denomination;
                this.createPopup('minBet', {minPrice});
            } else {
                this.cleanBeforeRoll();
                this.bonusWin = 0;
                this.roundWin = 0;
                this.Buttons.disableAllButtons();
                App.updateButton('start', {disabled: true});
                this.checkLoadedResources(() => this.sendRoll());
                this.playStartClickSound();
            }
        }
    };

    startBonusRoll = () => {
        JL().debug('-- Start bonus roll');
        this.cleanBeforeRoll();
        this.Buttons.disableAllButtons();
        App.updateButton('start', {disabled: true});
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});
        this.sendRoll();
    };

    /**
     * Send uc: 'ROLL' message by WebSocket
     */
    sendRoll() {
        this.setState('WAITING_RESPONSE');
        App.System.sendMetric({param: `rollCount.${this.id}`});
        App.System.sendMetric({param: `rollPlatform.${App.System.platform}`});
        App.System.sendMetric({param: `rollOrientation.${App.Wrapper.getOrientation()}`});
        const message = {
            uc: 'ROLL',
            bet: this.gameSettings.getBetLineCredit(),
            lines: this.gameSettings.getLinesNumber(),
            denomination: App.Money.getCurrentDenomination()
        };
        this.extraBet && (message.extraBet = this.extraBetActive);

        App.Socket.send(JSON.stringify(message));
    }

    autostart = () => {
        JL().debug(`-- Autostart click (${!this.isAutoStart})`);
        App.updateButton('gameSettings', {
            status: false,
            additionalClass: ''
        });
        App.Modal.reset();

        if (!this.isAutoStart) { // turn on
            const credits = App.Money.getCredit();
            const bet = this.gameSettings.getBetCredit();

            // call 'start' button handler (only if not disabled)
            const {start} = App.View.state.buttons;
            !start.disabled && start.handler();

            // not enough money for activate autostart
            if (credits <= 0 || bet > credits) {
                return;
            }

            this.isAutoStart = true;
            App.Sounds.playSound('auto-start-on');
            App.updateButton('autoStart', {pressed: true});
            App.View.setState({activePrizeWin: false});
        } else { // turn off
            this.isAutoStart = false;
            App.Sounds.playSound('auto-start-off');
            App.updateButton('autoStart', {pressed: false});
        }
    };

    cleanBeforeRoll() {
        this.stopAnimateFeature();
        this.stopWaitingAnimation();
        App.removePopupMessage();
        App.System.resetRollStatistics();
        this.InfoScreen.update({timeout: false, page: 1});
        this.extraBet && this.updateExtraBetButtons(false);
        this.latestResponse = null;
        this.SymbolInfo.remove(false);

        if (!this.isBonus() && this.getState() !== 'IDLE_BONUS') {
            this.Legends.showJackpot();
            App.Money.withDraw(this.gameSettings.getBet());
            App.updateState('moneyParams', {
                credits: App.Money.getCredit(),
                money: App.Money.getMoney()
            });
        }
        this.Legends.clearStatus();
        this.Legends.clearText('features');
        this.Legends.clearText('win');
    }

    /**
     * Process reels response from server.
     * @param response - Socket response 'ROLL'
     */
    processReelResponse(response) {
        this.latestResponse = response;
        this.setState('RESPONSE_RECEIVED');
        this.setBonusRollSymbol(); // for bonus game roll symbol
        this.prepareToRollAnimation(response);
    }

    /**
     * Process reels response from server.
     * @param response - Socket response 'SPIN'
     */
    processSpinResponse(response) {

    }

    /**
     * Function handle extension from 'ROLL' package response
     * @param extension
     */
    checkExtension(extension) {
        if (extension) {
            this.extraBetActive = extension.extraBet;
        }
    }

    /**
     * Возвращает true, если уже идет бонус или выпал вход в бонус в прокруте
     */
    isBonus = () =>
        this.bonusStatus ||
        this.latestResponse?.features?.some(({uc}) =>
            ['FREE_ROLL', 'CHOOSING'].includes(uc));

    setBonusRollSymbol() {
        this.bonusRollSymbol = this.latestResponse.extension ?
            this.latestResponse.extension.symbol : null;
    }

    prepareToRollAnimation(response) {
        this.Buttons.disableAllButtons();
        App.updateButton('start', {
            disabled: false,
            title: 'stop',
            handler: () => { // change handler to stop reels animation
                JL().debug('-- Stop roll');
                this.Roll.stopReels = [1, 1, 1, 1, 1, 1];
                this.Roll.fastReelsSound = 1;
                App.updateButton('start', {disabled: true});
            }
        });
        this.extraBet && this.updateExtraBetButtons(false);
        this.Roll.startReelAnimation(response);
    }

    /**
     * Called to show round results once animation is finished
     */
    rotationDone() {
        this.createReelMatrix(this.getStageChild('reelsStage'));
        this.onRotationDone();
        App.System.statistics.currentSpinNumber < 3 && App.System.collectFps();
    }

    onRotationDone() {
        JL().debug(`-- Rotation done (fps: ${App.System.statistics.fps})`);
        const {features, payment} = this.latestResponse;
        App.updateButton('start', {disabled: true});
        this.roundWin = 0;
        if (payment > 0 || this.isFreeRoll(features) || features.length) {
            if (this.BigWin.isBigWin(payment) && !this.isBonusSymbolWin(features)) {
                // There is a BIG Win
                this.BigWin.goToBigWin(payment, features);
            } else {
                // There is a win
                this.setState('SHOW_WIN_LINES');
                this.startAnimateFeature(features);
            }
        } else {
            if (this.isBonus()) {
                if (this.bonusStatus && this.bonusStatus.remain > 0) {
                    this.roundFinished(false);
                } else {
                    this.Legends.setRoundFinText();
                    this.finishBonus();
                }
            } else {
                this.roundFinished();
                this.Legends.setRoundFinText();
            }
        }
    }

    /**
     * Возвращает true, если на последнем прокруте выпала бонусная комбинация
     */
    isFreeRoll = features => features.find(({uc}) => ['FREE_ROLL', 'CHOOSING'].includes(uc));

    /**
     * Should be mandatory called at the end of every round
     * Required to process autoStart correctly
     * @param balanceRequest - can send {uc: 'BALANCE'}, disable this in bonus spins
     */
    roundFinished(balanceRequest = true) {
        this.setState(balanceRequest ? this.getIdleState() : 'IDLE_BONUS');
        this.isAutoStart && App.updateButton('start', {disabled: true});

        if (balanceRequest) {
            App.Socket.send(JSON.stringify({uc: 'BALANCE'}));
            if (App.configs.mode === 'login' && App.User.get().username !== 'demo' && App.User.get().username !== 'unregistered') {
                App.Socket.send(JSON.stringify({uc: 'BONUS-BALANCE'}));
            }
        } else {
            this.checkNextRoll();
        }
    }

    stickSpine() {

    }

    /**
     * Check bonus or auto start now -> start new roll
     * Socket response 'BALANCE'
     */
    checkNextRoll() {
        this.updateCredits();

        if (['IDLE', 'IDLE_BONUS'].includes(this.getState())) {
            this.isAutoStart || this.isBonus() ?
                // check if autostart is still on
                setTimeout(() => this.isAutoStart || this.isBonus() ?
                    this.startRoll() : this.goIdle(), 500) :
                this.goIdle();
        }
    }

    /**
     * Update balance, in depending on denomination
     */
    updateCredits() {
        App.updateState('moneyParams', {credits: App.Money.getCredit()});
    }

    startRoll() {
        this.getState() === 'IDLE' && this.start();
        this.getState() === 'IDLE_BONUS' && this.startBonusRoll();
    }

    goIdle() {
        JL().debug('-- Go idle');
        this.showWaitingAnimation();
        this.Lines.updateBoxesState();
        this.extraBet && this.updateExtraBetButtons();
        this.Buttons.setDefaultGameButtons();
        this.InfoScreen.update();
        this.SymbolInfo.init();
        this.updateKioskInfo();
    }

    /**
     * Get current game idle state
     * @returns {string}
     */
    getIdleState = () => 'IDLE';

    showWaitingAnimation() {

    }

    takeWin = (isTransfer = true) => {
        this.stopWaitingAnimation();
        this.stopAnimateSound();
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        JL().debug(`-- Take win (isTransfer: ${isTransfer})`);
        this.setState('TAKE_WIN');

        if (isTransfer && App.restoreGameState !== 'TRANSFER') {
            App.Socket.send(JSON.stringify({uc: 'TRANSFER-START'}));
        }
        !isTransfer && this.animateCredits(this.latestResponse.payment, isTransfer);

        this.Buttons.disableAllButtons();
        App.updateButton('start', {
            disabled: false,
            title: 'collect',
            handler: () => { // User can press 'start' twice for increasing transfer speed
                this.isSpeedUp !== 2 && this.isSpeedUp++;
                JL().debug(`-- Take win speed up x${this.isSpeedUp + 1}`);
                // TODO after repeat click -> take all win immediately
            }
        });
    };

    animateCredits(amount, isTransfer, animationStarted = Date.now(), lastTime = Date.now()) {
        const timeDiff = Date.now() - lastTime;

        const animateTimeout = this.isSpeedUp ? 50 : 100; // частота вызова функции зависит от первой и второй скорости

        if (timeDiff < animateTimeout) {
            this.creditsAnimationFrame = requestAnimationFrame(this.animateCredits.bind(this, amount, isTransfer, animationStarted, lastTime));
            return;
        }
        lastTime = Date.now();

        const delta = this.setCreditsTransferSpeed(amount);
        this.playCreditSound();

        if (amount >= delta) {
            amount -= delta;
            App.Money.setCents(App.Money.getCents() + (delta * App.Money.getCurrentDenomination()));
            App.updateState('moneyParams', {
                credits: App.Money.getCredit(),
                money: App.Money.getMoney()
            });
            this.Legends.setText('win', {text: 'win', value: amount});
            this.Legends.setStatus('creditsWon', amount);
            this.creditsAnimationFrame = requestAnimationFrame(this.animateCredits.bind(this, amount, isTransfer, animationStarted, lastTime));
        } else { // зачисление кредитов после выигрыша
            const {payment} = this.latestResponse;
            const multiplier = this.Gamble?.getMultiplier() ?? 1;
            let win = 0;

            if (payment * multiplier > 0) {
                win = payment * multiplier;
                this.Legends.setText('win', {text: 'win', value: win});
                this.Legends.setStatus('winnerPaid', win);
            }
            this.Gamble?.setMultiplier(1);

            this.endAnimateCredits(isTransfer);
        }
    }

    /**
     * Function to play animate credit sound
     */
    playCreditSound() {
        this.isSpeedUp === 0 ?
            App.Sounds.playSound('add-credit1') :
            App.Sounds.playSound('add-credit2');
    }

    /**
     * Function to play start button sound
     */
    playStartClickSound() {

    }

    /**
     * Function to stop animate credit sound
     */
    stopCreditSound = () => {
        App.Sounds.stopSound('add-credit1');
        App.Sounds.stopSound('add-credit2');
    };

    /**
     * Function to play reel stop sound
     */
    playReelStopSound = () => {
        App.Sounds.playSound('reelsstop');
    };

    /**
     * Function to finish animate credit
     */
    endAnimateCredits(isTransfer) {
        this.stopCreditSound();
        this.isSpeedUp = 0;
        isTransfer ?
            App.Socket.send(JSON.stringify({uc: 'TRANSFER-WIN'})) :
            this.checkNewPrize();
    }

    resetCreditAnimation() {
        cancelAnimationFrame(this.creditsAnimationFrame);
    }

    /**
     * Set transfer speed when transferring win credits to account
     * speed depends on number of digits in amount of credits that should be transferred
     */
    setCreditsTransferSpeed(amount) {
        const _amount = amount || this.latestResponse.payment * (this.Gamble?.getMultiplier() ?? 1);
        let multiplier = Math.pow(2, ((_amount - 1) + '').length - 1);
        if (this.isAutoStart || this.isSpeedUp === 1) {
            multiplier = Math.pow(6, ((_amount - 1) + '').length - 1);
        }
        if (_amount <= 0) {
            multiplier = 1;
        } // для того что бы избжать отрицательного мультиплаера

        if (this.isSpeedUp === 2) {
            multiplier = _amount;
        }

        return multiplier;
    }

    /**
     * Restore game in TRANSFER state
     **/
    restoreTransfer() {
        this.isSpeedUp = 1;
        this.Legends.showWinFeatures();
        this.latestResponse.features = []; // clear possible bonus restore
        this.takeWin();
    }

    getRandomSymbol(length, reelIndex, symbolBefore) {
        const denyScatter = Math.floor(Math.random() * 3); // decrease scatter 3 times less
        let symbol = Math.floor(Math.random() * length);

        while (
            symbol === this.reelFilter[reelIndex][0] ||
            symbol === this.reelFilter[reelIndex][1] ||
            symbol === this.reelFilter[reelIndex][2] ||
            symbol === this.reelFilter[reelIndex][3] ||
            symbol === symbolBefore ||
            (symbol === this.scatter && denyScatter)) {
            symbol = Math.floor(Math.random() * length);
        }
        return symbol;
    }

    /**
     * используем чтобы поменять символы скатеров на соответствующих рилах
     * only latest response screen
     */
    additionalFilter = reelMas => reelMas;

    /**
     * Function check the part of long symbol
     */
    isLongSymbolOnScreen = () => true;

    /**
     * Create on reels full symbol
     * @param reelMas
     */
    addLongSymbol = reelMas => reelMas;

    /**
     * Возвращает true если барабан 'заморожен' и не крутится
     */
    isReelFreezed = () => false;

    changeLine = lineIndex => {
        App.Sounds.playSound('click');
        this.Legends.showJackpot();
        this.Legends.setRoundFinText();
        this.extraBetActive && this.extraBetClick();
        this.drawLines(lineIndex);

        JL().debug(`-- Change lines to ${this.gameSettings.getLinesNumber()}`);
        this.Buttons.closeWrap();
        App.updateButton('lines', {value: this.gameSettings.getLinesNumber()});
        App.updateButton('total', {value: this.gameSettings.getBetCredit()});
        this.InfoScreen.update({page: 1});
        this.updateKioskInfo();
    };

    changeBetLine = bet => {
        // Return from autostart state
        if (this.isAutoStart) {
            this.autostart();
            this.goIdle();
        } else {
            // Try to get the max available lines
            this.getMaxLines(bet);
        }
        this.Legends.clearText('features');
        this.updateKioskInfo();
    };

    getMaxLines = bet => {
        const aLines = this.gameSettings.getLineMas();
        const aBets = this.gameSettings.bets;
        let iCounter = 0;
        while (bet > App.Money.getCents() && iCounter < aBets.length) {
            iCounter++;
            if (iCounter > aBets.length) {
                break;
            }
            this.gameSettings.setPosBetValue(aBets[aBets.length - iCounter]);
            bet = this.gameSettings.getBet();
        }

        iCounter = 0;
        // decreasing lines from current to min
        while (bet > App.Money.getCents() && iCounter < aLines.length) {
            iCounter++;
            if (iCounter > aLines.length) {
                break;
            }
            this.gameSettings.setPosLineValue(aLines[aLines.length - iCounter]);
            bet = this.gameSettings.getBet();
        }
        this.updateGameSettingsStates();
        this.drawLines(this.gameSettings.posLine);
        return bet;
    };

    setMaxLines() {
        const {lines} = this.Lines;
        lines && this.gameSettings.setPosLineValue(Object.keys(lines).length);
        this.drawLines(this.gameSettings.getLinesIndex());
        this.updateGameSettingsStates();
        this.SymbolInfo.remove(false);
        this.Legends.showJackpot();
        this.InfoScreen.update({page: 1});
        this.updateKioskInfo();
    }

    drawLines(lineIndex) {
        this.stopAnimateFeature();
        this.gameSettings.setPosLineIndex(lineIndex);

        const lines = this.gameSettings.getCurrentLineMas();
        this.Lines.drawLineImages(lines.map(index => index - 1), [], this.getStageChild('linesContainer'));
        this.Lines.drawBoxes(this.getStageChild('boxesContainer'));
    }

    /**
     * Create PIXI.Graphics area for apply as mask to lines
     * @param parentContainer
     * @param line
     * @param winReels
     * @returns {PIXI.Graphics}
     */
    getLineMask(parentContainer, line, winReels) {
        const mask = new Graphics();
        mask.name = 'lineMask';
        mask.beginFill(0xFF3300);

        // create mask on main area, around reels
        mask.drawRect(0, 0, this.gameWidth, this.reelTop);
        mask.drawRect(0, 0, this.reelXCoordinates[0], this.gameHeight);
        mask.drawRect(
            this.reelXCoordinates[this.reels - 1] + this.symbolWidth, 0,
            this.reelXCoordinates[0], this.gameHeight
        );
        mask.drawRect(
            0, this.reelTop + this.reelRows * this.symbolHeight,
            this.gameWidth, 100
        );

        this.reelMatrix.forEach((reel, reelIndex) => {
            // create mask between reels
            mask.drawRect(
                this.reelXCoordinates[reelIndex] + this.symbolWidth, 0,
                this.reelXCoordinates[1] - this.reelXCoordinates[0] - this.symbolWidth, this.gameHeight
            );

            reel.forEach((symbolObj, rowIndex) => {
                // create mask only on not win positions
                (line.coordinates[reelIndex] !== rowIndex || !winReels.includes(reelIndex)) &&
                mask.drawRect(
                    this.reelXCoordinates[reelIndex], this.reelTop + this.symbolHeight * rowIndex,
                    this.symbolWidth, this.symbolHeight
                );
            });
        });
        mask.endFill();
        this.getStageChild('linesContainer').addChild(mask);

        return mask;
    }

    /**
     * Create PIXI.Graphics area for apply as mask to different animations
     * @param name
     * @returns {PIXI.Graphics}
     */
    getReelsMask(name = 'reelMask') {
        const reelMask = this.getStageChild(name);
        if (!reelMask) {
            const mask = new Graphics();
            mask.name = name;
            const {
                offsetX, offsetY,
                offsetWidth, offsetHeight
            } = this.offsetReelMask;

            mask.beginFill(0xFF3300);
            mask.drawRect(
                this.reelXCoordinates[0] - offsetX,
                this.reelTop + offsetY,
                this.reelXCoordinates[this.reels - 1] + this.symbolWidth - offsetWidth,
                this.symbolHeight * this.reelRows + offsetHeight
            );
            mask.endFill();
            this.getStage().addChild(mask);

            return mask;
        } else {
            return reelMask;
        }
    }

    changeBet = betIndex => {
        App.Sounds.playSound('click');
        this.Legends.showJackpot();
        this.stopAnimateFeature();
        this.Legends.setRoundFinText();
        this.gameSettings.setPosBetIndex(betIndex);
        this.Lines.drawBoxes(this.getStageChild('boxesContainer'));
        JL().debug(`-- Change bet to ${this.gameSettings.getBetLineCredit()}`);
        this.Buttons.closeWrap();
        this.updateGameSettingsStates();
        this.InfoScreen.update({page: 1});
        this.updateKioskInfo();
    };

    maxBet() {
        this.SymbolInfo.remove(false);
        this.changeBet(this.gameSettings.bets.length - 1);
    }

    changeDenomination = denominationIndex => {
        App.Sounds.playSound('click');
        this.Legends.showJackpot();
        this.stopAnimateFeature();
        this.Legends.setRoundFinText();
        this.Buttons.closeWrap();
        App.Money.setPosDenominationIndex(denominationIndex);
        JL().debug(`-- Change denomination to ${App.Money.getCurrentDenomination() / 100}`);
        this.InfoScreen.update();
    };

    updateGameSettingsStates() {
        const suffix = this.extraBetActive ? '+' : '';
        App.updateButton('lines', {value: this.gameSettings.getLinesNumber()});
        App.updateButton('bet', {value: this.gameSettings.getBetLineCredit() + suffix});
        App.updateButton('total', {value: this.gameSettings.getBetCredit()});
        // call to change waiting animation according to protection
        this.app && this.addWaitingAnimationSprites(this.getStageChild('waitingContainer'));
    }

    //
    // ======================== ANIMATE FEATURE SECTION =============================
    //

    /**
     * Function to start logic of Animate feature first step
     * @param features
     */
    startAnimateFeature(features) {
        JL().debug('-- Start animate feature');
        this.Legends.showWinFeatures();
        App.Sounds.pauseSound('bonus-background');
        this.prepareToAnimateFeature(features);
        this.animateFeature(features);

        // change handler to stop animation win line
        !this.isBonus() && App.updateButton('start', {
            disabled: false, title: 'stop',
            handler: this.speedUpWinLineAnimation
        });
    }

    /**
     * Manually stopping win animation to get win now
     */
    speedUpWinLineAnimation = () => {
        JL().debug('-- Speed up win line animation');
        const {features} = this.latestResponse;
        App.updateButton('start', {
            disabled: false, title: 'stop',
            handler: this.stopWinLineAnimation
        });

        this.winLineFeatureDelay /= 5;
        this.stopFeatureTimeout();
        this.animateFeature(features, ++this.features.step);
    };

    stopWinLineAnimation = () => {
        JL().debug('-- Finish win line animation');
        const {payment} = this.latestResponse;
        this.Legends.setStatus('creditsWon', payment);
        this.Legends.setText('win', {text: 'win', value: payment});
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        this.bonusWin = payment;
        this.stopAnimateFeature();
        this.endAnimateFeature();
    };

    /**
     * Animate feature first step
     */
    prepareToAnimateFeature(features) {
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        this.features.step = 0;

        // check 'WIN_LINE' feature contain
        const isWinLine = features.some(features => features.uc === 'WIN_LINE');
        this.updateExtraSymbols(isWinLine);

        // reset all animations and turn on shadows
        this.reelMatrix.forEach(reel => {
            reel.forEach(symbolObj => {
                // don't hide symbols if only scatter feature
                symbolObj.sprite.alpha = isWinLine && this.symbolEffects ? 0.5 : 1;
                symbolObj.sprite.gotoAndStop(0);
            });
        });

        // unique preparing for each game
        this.additionalPreparingToAnimateFeature(features);

        // initialize symbols on reelMatrix
        features.forEach(feature => {
            switch (feature.uc) {
                case 'WIN_LINE':
                    feature.reels.forEach(reelIndex => {
                        const rowIndex = this.Lines.lines[feature.number].coordinates[reelIndex];
                        const symbolObj = this.reelMatrix[reelIndex][rowIndex];
                        symbolObj.image = this.symbolAnimation && symbolObj.image === 'static' ?
                            'regular' : symbolObj.image;
                        this.setRegularLongSprite(feature.reels.length, symbolObj);
                        this.Roll.updateSymbolSprite(symbolObj);
                        symbolObj.sprite.alpha = 1;
                        symbolObj.sprite.zIndex = 1;
                        symbolObj.sprite.play();
                        symbolObj.symbolBackground && symbolObj.symbolBackground.gotoAndPlay(0);
                    });
                    break;
                case 'SCATTER':
                case 'WIN_WAY':
                    feature.positions.forEach(({reel, row}) => {
                        const symbolObj = this.reelMatrix[reel][row];
                        symbolObj.image = this.symbolAnimation && symbolObj.image === 'static' ?
                            'regular' : symbolObj.image;
                        this.setRegularLongSprite(feature.uc === 'SCATTER' ? feature.positions.length : feature.count, symbolObj);
                        this.Roll.updateSymbolSprite(symbolObj);
                        symbolObj.sprite.alpha = 1;
                        symbolObj.sprite.play();
                        symbolObj.symbolBackground && symbolObj.symbolBackground.gotoAndPlay(0);
                    });
                    break;
            }
        });
    }

    /**
     * Update alpha for extra symbols
     * Call in animate feature start or stop
     * @param shadow
     */
    updateExtraSymbols(shadow) {
        const container = this.getStageChild('reelsStage').getChildByName('extraSymbols');
        container && container.children.forEach(child =>
            child.alpha = shadow ? 0.5 : 1);
    }

    additionalPreparingToAnimateFeature() {

    }

    /**
     * Function to stop Animate Feature animation
     */
    stopAnimateFeature() {
        this.stopFeatureTimeout();
        this.getStageChild('linesContainer').removeChildren();
        this.resetSymbolAnimation();
        if (!App.Socket.restoreSocket) {
            this.Legends.clearText('features');
            !this.isBonus() && this.Legends.clearText('win');
        }
    }

    /**
     * Function to stop next Animate Feature animation
     */
    stopFeatureTimeout() {
        this.app.ticker.remove(this.animationFrameId);
        this.animationFrameId = null;
    }

    /**
     * Reset all animations in reelMatrix
     */
    resetSymbolAnimation() {
        this.reelMatrix.forEach(reel => {
            reel.forEach(symbolObj => {
                const {sprite} = symbolObj;
                sprite.gotoAndStop(0);
                sprite.alpha = 1;
                sprite.tint = 0xFFFFFF;
                symbolObj.symbolBackground?.destroy();
                symbolObj.symbolBackground = null;

                this.Roll.updateSymbolSprite(symbolObj);
            });
        });
        this.updateExtraSymbols(false);
    }

    /**
     * Animate feature steps
     * @param features
     * @param step
     */
    animateFeature(features, step = 0) {
        this.features.step = step;
        const featureIndex = step % features.length;
        const currentFeature = features[featureIndex];
        const winLinesState = ['SHOW_WIN_LINES', 'SHOW_BONUS_WIN_LINES', 'SHOW_BIG_WIN_LINES'].includes(this.getState());
        const delay = this.getFeatureDelay(currentFeature, features);
        const playNextFeature = () => this.animationFrameId = // launch next feature with delay
            this.tickerTimeout(this.animateFeature.bind(this, features, ++step), delay);

        // call after last feature played (now featureIndex repeat 0)
        if (step === features.length && winLinesState) {
            this.isFreeRoll(features) || this.isBonus() ?
                this.isFreeRoll(features) ? // check free rolls
                    this.bonusEntrance(features) :
                    this.drawBonusStep(features) :
                this.endAnimateFeature() ? // check next features circle
                    playNextFeature() :
                    this.stopAnimateFeature();
            return;
        }

        this.showFeatureLine(currentFeature, features, featureIndex);
        this.setFeatureText(currentFeature);

        // calc win and play feature sound before last feature
        if (step < features.length && winLinesState) {
            this.calcWinSum(currentFeature);
            this.playFeatureSound(currentFeature, featureIndex, features);
        }

        this.stopFeatureTimeout();
        playNextFeature();
    }

    /**
     * End feature animation / take win without prompt
     * @returns {boolean} - new animation features circle
     */
    endAnimateFeature() {
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        App.Sounds.stopSound('win-line');
        if (this.getState() === 'SHOW_WIN_LINES' && this.latestResponse.payment) {
            this.isAutoStart ?
                this.takeWin() :
                this.latestResponse.payment > 0 ? // если есть анимация скатера, например, без выигрыша анимируем, а потом выходит без зачисления
                    this.gambleOrTakeWin() :
                    this.roundFinished();
        } else { // no win, just feature without payment
            this.roundFinished();
        }
        return true;
    }

    /**
     * Take win or gamble according on gamble limit
     */
    gambleOrTakeWin() {
        this.Gamble?.stepLeft ?
            this.startGamble() :
            App.updateButton('start', {
                disabled: false,
                title: 'collect',
                handler: () => this.takeWin()
            });
    }

    /**
     * Start scatter animations
     * @param features
     */
    bonusEntrance(features) {
        this.playBonusGameSound();
        this.stopAnimateFeature();
        this.drawTopAnimation(this.getStageChild('bonusContainer'));
        App.updateButton('start', {disabled: true});

        const scatterFeature = features.find(({uc}) => uc === 'SCATTER');
        this.playScatterAnimation(scatterFeature, () => // call after scatter played
            this.drawBonusAskButton(this.isFreeRoll(features) && !this.bonusStatus));
    }

    /**
     * Common function for bonus animation
     * Game entry point for animation in bonus state
     */
    drawBonusStep(features) {
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        App.updateButton('start', {disabled: true});
        this.clearPressAnyButton();
        this.isBonusSymbolWin(features) ? // bonus symbol win
            this.drawBonusAnimationAfterReels(this.bonusRollSymbol) :
            this.finishBonusAnimation();
    }

    finishBonusAnimation() {
        this.bonusStatus.remain === 0 ?
            this.finishBonus() :
            this.bonusRoll();
    }

    /**
     * End bonus round, return to bonus game.
     */
    finishBonus() {
        this.drawBonusAskButton();
        App.Sounds.stopSound('bonus-background');
        this.latestResponse.payment = this.bonusWin;
        this.Legends.setStatus('creditsWon', this.bonusWin);
        this.bonusWin = 0;
        this.gameFlag.bonusStart = false;
    }

    /**
     * Call after all book animation ended
     */
    bonusRoll() {
        const container = this.getStageChild('bonusContainer');
        container.removeChildren();
        this.showAdditionalBonusImage(container);

        App.Sounds.playSound('bonus-background');
        this.setState('IDLE_BONUS');
        this.startBonusRoll();
    }

    startGamble() {
        this.setState('ASK_GAMBLE');
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        this.Gamble.prizeWin = this.latestResponse.payment;
        this.isBonus() && this.stopAnimateFeature();
        this.playGameWaitSound();
        App.updateButton('start', {
            disabled: false,
            title: 'collect',
            handler: this.finishGamble
        });
        App.updateButton('select', {
            disabled: false,
            title: 'gamble',
            handler: this.Gamble?.goToGamble
        });
        this.Legends.setText('win', {text: 'win', value: this.latestResponse.payment});
        this.setGambleUpStatus();
    }

    startGambleAnimation() {

    }

    setGambleUpStatus() {
        this.Legends.setStatus('gambleUp5x');
    }

    finishGamble = () => {
        this.showBoxes();
        this.showLines();
        this.Gamble.resetInverting();
        this.Gamble.ctx = null;
        this.Gamble.setMultiplier(1);
        this.Gamble.disableSuitsButtons();
        this.Gamble.animateGambleAreaCompleted = false;
        this.setBackground('mainArea');

        App.updateState('buttons', {visualization: App.buttonsType});
        App.updateButton('select', {additionalClass: ''});
        App.updateButton('info', {additionalClass: ''});
        App.updateButton('redCard', {disabled: true, handler: null});
        App.updateButton('blackCard', {disabled: true, handler: null});

        cancelAnimationFrame(this.Gamble.gambleTimeout);
        this.latestResponse.payment = this.Gamble.prizeWin;
        const {features} = this.latestResponse;

        this.endGambleAnimation();
        if (this.Gamble.prizeWin === 0) {
            App.View.setState({activeGamble: false});
            this.Legends.setStatus('gambleEnd');
            this.Legends.showJackpot();
            JL().debug('-- Loose gamble');
            this.roundFinished();
        } else {
            JL().debug(`-- Win gamble. Prize: ${this.Gamble.prizeWin}`);
            App.updateButton('select', {title: 'select'});
            App.updateButton('info', {disabled: true, title: 'paytable'});
            App.View.setState({activeGamble: false});

            // restore animate feature
            features.length && this.getState() === 'GAMBLE' &&
            this.startAnimateFeature(features);

            this.takeWin();
        }
    };

    endGambleAnimation() {

    }

    sendGamblePacket = () => App.Socket.send(JSON.stringify({uc: 'PREVIOUS-DOUBLING'}));

    /**
     * Send manufacturer metric
     */
    sendCategoryMetrics() {
        const {categories} = availableGames.find(({name}) => name === this.id);
        App.System.sendMetric({param: `category.${categories[0]}`});
    }

    getFeatureDelay(currentFeature, features) {
        let delay = 0;

        switch (currentFeature.uc) {
            case 'WIN_LINE':
            case 'WIN_WAY':
            case 'CHOOSING':
                delay = this.winLineFeatureDelay;
                break;
            case 'SCATTER':
                delay =
                    features.some(({uc}) => uc === 'FREE_ROLL') &&
                    !features.some(({uc}) => uc === 'WIN_LINE') ?
                        0 : this.winLineFeatureDelay;
                break;
        }

        return delay;
    }

    /**
     * Function to calculate total roll win (add all features from roll)
     * @param feature
     */
    calcWinSum(feature) {
        const {uc, payment} = feature; // get current feature params

        if (!['SPECIAL_SYMBOL', 'FREE_ROLL', 'CHOOSING'].includes(uc)) {
            // ignore payments incrementing after big win
            if (this.getState() !== 'SHOW_BIG_WIN_LINES') {
                this.bonusWin += payment || 0;
                this.roundWin += payment || 0;
            }

            this.Legends.setText('win', {text: 'win', value: this.bonusWin});
            this.roundWin && this.Legends.setStatus('creditsWon', this.roundWin);
        }
    }

    /**
     * Function to show text for special feature
     * @param feature
     */
    setFeatureText(feature) {
        const {uc, number, payment} = feature; // get current feature params

        !['SPECIAL_SYMBOL', 'FREE_ROLL', 'CHOOSING'].includes(uc) &&
        this.Legends.setText('features', {
            text: uc === 'SCATTER' ? 'scatterPays' : 'linePays',
            value: payment, number
        });
    }

    /**
     * Function to show line for special feature
     * @param currentFeature
     */
    showFeatureLine(currentFeature) {
        const {number, reels, uc, payment} = currentFeature; // get current feature params
        const container = this.getStageChild('linesContainer');
        uc !== 'SPECIAL_SYMBOL' && container.removeChildren(); // don't clear lines before special symbol (bookGame fill)
        uc === 'WIN_LINE' && this.Lines.drawLineImages([number], reels, container, true, payment);
    }

    animateSymbolsInLine(feature) {
        const {uc, number, reels, positions} = feature;

        uc === 'SCATTER' ?
            positions.forEach(pos => {
                const {reel, row} = pos;
                const symbolObj = this.reelMatrix[reel][row];
                symbolObj.sprite.play();
            }) :
            this.reelMatrix.forEach((reel, reelIndex) => {
                reel.forEach((symbolObj, rowIndex) => {
                    const {coordinates} = this.Lines.lines[number];
                    symbolObj.image = this.getSymbolImageInLine(symbolObj, feature);
                    symbolObj.loop = false;
                    this.Roll.updateSymbolSprite(symbolObj);
                    coordinates[reelIndex] === rowIndex && reels.includes(reelIndex) ?
                        symbolObj.sprite.play() : symbolObj.sprite.gotoAndStop(0);
                    symbolObj.sprite.onComplete = null;
                });
            });
        // disable all boxes
        uc === 'SCATTER' && this.Lines.drawBoxes(this.getStageChild('boxesContainer'), -1);
    }

    getSymbolImageInLine = () => this.symbolAnimation ? 'regular' : 'static';

    //
    // ======================== BONUS SECTION =============================
    //

    drawBonusAnimationAfterReels() {

    }

    isBonusSymbolWin = () => false; // no win in the most games redefined at book of ra class

    showAdditionalBonusImage() {

    }

    setRegularSprite() {
        this.reelMatrix.forEach(reel => {
            reel.forEach(symbolObj => {
                symbolObj.image = this.symbolAnimation ? 'regular' : 'static';
                this.Roll.updateSymbolSprite(symbolObj);
            });
        });
    }

    setRegularLongSprite(featureLength, symbolObj) {
        if (
            featureLength > 3 &&
            !this.gameFlag.bonusStart &&
            this.symbols[symbolObj.symbol].regularLongSteps
        ) {
            symbolObj.image = 'regularLong';
        }
    }

    setRegularShortSprite(clipMatrix, reelIndex, textures) {

    }

    /**
     * Draw long scatter animation before bonus
     * @param scatterFeature
     * @param callback
     */
    playScatterAnimation(scatterFeature, callback) {
        this.setScatterSprite(scatterFeature);

        // get first scatter position
        const {reel, row} = scatterFeature.positions[0];
        const symbolObj = this.reelMatrix[reel][row];

        // call after first scatter played
        symbolObj.sprite.onComplete = () => {
            symbolObj.sprite.onComplete = null; // clear event
            callback();
        };

        this.Legends.setText('features', {text: 'scatterPays', value: scatterFeature.payment});
    }

    setScatterSprite(scatterFeature) {
        scatterFeature.positions.forEach(position => {
            const {reel, row} = position;
            const symbolObj = this.reelMatrix[reel][row];
            symbolObj.image = 'scatter';
            symbolObj.loop = false;
            this.Roll.updateSymbolSprite(symbolObj);
            symbolObj.sprite.play();
        });
    }

    /**
     * Check symbol image
     * Can be redefined to 'static'/'regular'/'bonus'/'additional'
     * Call for Roll.initReels() and Roll.updateFullClipMatrix()
     */
    getSymbolImageType = () => 'static';

    /**
     * Change symbol index when it is necessary.
     * For example, in Colambus and Gladiators games
     */
    getSymbolIndex = symbolIndex => symbolIndex;

    /**
     * Function to show Top animation before bonus game
     */
    drawTopAnimation() {

    }

    /**
     * Show bonus message with bonus symbol or with bonus game win.
     * @param isFirstBonus {boolean} TRUE if this is the message for starting bonus game.
     */
    drawBonusAskButton(isFirstBonus = false) {
        let isLast = !isFirstBonus && this.bonusStatus && this.bonusStatus.remain === 0;

        this.stopAnimateFeature();
        this.tickerTimeout(() => {
            this.drawBonusFrame(isFirstBonus, isLast, this.getStageChild('bonusContainer'), this.coordinatesBonusFrame);
            this.showPressAnyButton(isLast);
        }, 1000);

        this.gameFlag.bonusStart = true;
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});

        isLast = this.bonusStatus && this.bonusStatus.remain === 0;
    }

    /**
     * Start animate status text 'press any button'
     */
    showPressAnyButton(isLast) {
        let showStatus = true;
        // don't show status text on last frame
        clearInterval(this.pressAnyButtonInterval);
        !isLast && (this.pressAnyButtonInterval = setInterval(() => {
            showStatus ?
                this.Legends.setStatus('pressAnyButton') :
                this.Legends.clearStatus();
            showStatus = !showStatus;
        }, 500));
    }

    /**
     * Clear status text 'press any button'
     */
    clearPressAnyButton() {
        if (this.pressAnyButtonInterval) {
            clearInterval(this.pressAnyButtonInterval);
            this.pressAnyButtonInterval = null;
        }
        this.Legends.clearStatus();
    }

    drawBonusFrame(first, last, parentContainer, coordinates) {
        parentContainer.removeChildren();
        App.Sounds.stopSound('bonus-background');

        const {startBonusFrame, bonusInBonusFrame, endBonusFrame} = coordinates;
        if (first) {
            App.Sounds.playSound('bookFlash');
            this.getTexture('bonusArea') && this.setBackground('bonusArea');
            this.showStartBonusFrame(parentContainer, startBonusFrame);
            App.updateButton('start', {
                disabled: false,
                title: 'start',
                handler: () => this.startBonusAnimation(parentContainer)
            });
        }
        if (last) {
            parentContainer.removeChildren();
            this.setRegularSprite();
            App.updateButton('start', {disabled: true});
            this.setBackground('mainArea');
            this.showEndBonusFrame(parentContainer, endBonusFrame, this.bonusStatus);
            this.playEndBonusGameSound();
            this.tickerTimeout(() => this.endBonus(), 5000);
        }
        if (!first && !last) {
            this.showBonusInBonusFrame(parentContainer, bonusInBonusFrame);
            App.updateButton('start', {
                disabled: false,
                title: 'start',
                handler: () => this.drawBonusStep(this.latestResponse.features)
            });
        }
    }

    /**
     * Change mainContainer and <Buttons> background
     * @param type
     */
    setBackground(type) {
        const backgroundSprite = this.getStageChild('mainContainer').getChildByName('mainArea');
        backgroundSprite && (backgroundSprite.texture = this.getTexture(type));
    }

    /**
     * Change reelsBackground container image
     * @param type
     */
    setReelsBackground(type) {
        const backgroundSprite = this.getStage().getChildByName('reelsBackground');
        backgroundSprite && (backgroundSprite.texture = this.getTexture(type));
    }

    /**
     * Отрисовка таблички бонусной игры
     */
    showStartBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y);
    }

    /**
     * Отрисовка таблички бонус в бонусе
     */
    showBonusInBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y);
    }

    /**
     * Отрисовка таблички окончания бонусной игры
     */
    showEndBonusFrame(parentContainer, {x, y}, {win, total}) {
        this.showBonusFrame(parentContainer, x, y);
    }

    showBonusFrame(parentContainer, x, y, image = 'frame') {
        const sprite = new Sprite(this.getTexture(image));
        sprite.name = image;
        sprite.position.set(x, y);
        parentContainer.addChild(sprite);
    }

    /**
     * End bonus game, return to regular game.
     */
    endBonus() {
        JL().debug('-- End bonus');
        this.setState('AFTER_BONUS');
        this.getStageChild('bonusContainer').removeChildren();
        this.afterBonusAction();
        this.stopAnimateFeature();
        this.setRegularSprite();
        this.latestResponse.payment = this.bonusStatus.win; // Double whole bonus sum
        this.latestResponse.features = [];
        this.bonusRollSymbol = null;
        this.gameFlag.bonusStarted = false;
        this.bonusStatus = null;
    }

    /**
     * Function to decide next step after bonus
     * lose and idle
     */
    afterBonusAction() {
        const {payment} = this.latestResponse;

        if (payment > 0 || this.bonusWin) {
            this.Legends.setText('win', {text: 'win', value: payment});
            this.isAutoStart || !this.Gamble ?
                this.takeWin() :
                this.gambleOrTakeWin();
        } else {
            this.Legends.showJackpot();
            this.Legends.setRoundFinText();
            this.roundFinished();
        }
    }

    /**
     * Animate infoContainer, slide down and update state
     */
    startInfoAnimation() {
        const open = () => {
            this.createInfoContainer(this.getStage());

            this.showAnimation({
                duration: 500,
                animations: [{sprite: this.getStageChild('infoContainer'), timeline: [{to: {y: 0}}]}],
                onComplete: () => {
                    JL().debug('-- InfoScreen opened');
                    this.setState('INFO');
                    this.InfoScreen.checkInfoButtons();
                    App.updateButton('close', {disabled: false, handler: this.InfoScreen.close});
                }
            });
            App.updateState('buttons', {animation: 'hide-panel'});
            this.onInfoStartOpen();
        };

        this.checkLoadedResources(open);
    }

    onInfoStartOpen() {
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     * @param lang
     */
    drawInfoPage(ctx, page, nLines, bet, lang) {

    }

    closeInfoAnimation() {
        const infoContainer = this.getStageChild('infoContainer');

        this.showAnimation({
            duration: 500,
            animations: [{sprite: infoContainer, timeline: [{to: {y: -this.gameFieldHeight}}]}],
            onComplete: () => {
                JL().debug('-- InfoScreen closed');
                this.InfoScreen.reset();
                this.goIdle();
                this.setBackground('mainArea');
                infoContainer.destroy();
            }
        });
        App.updateState('buttons', {animation: 'show-panel'});
        this.onInfoStartClose();
    }

    onInfoStartClose() {

    }

    /**
     * Получить значение amount из feature FREE_ROLL последнего ответа с сервера
     * @param amount - Начальное значение параметра, по-умолчанию 0
     */
    getAmountFromLastResponse(amount = 0) {
        this.latestResponse && (amount =
            this.latestResponse.features.reduce((bonusGames, feature) =>
                feature.uc === 'FREE_ROLL' ? feature.amount : bonusGames, 0));
        return amount;
    }

    getDenominations = () => App.Money.denominations.map(key => key / 100);

    /**
     * Function to restore bonus game
     */
    restoreBonusGame() {
        this.getTexture('bonusArea') && this.setBackground('bonusArea');
        this.bonusWin = this.bonusStatus.win - this.latestResponse.payment;
        // Fill WIN data
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});
        this.Legends.showWinFeatures();
        this.setBonusStatusText();

        this.Buttons.disableAllButtons();
        this.gameFlag.bonusStart = true;
        this.gameFlag.bonusStarted = true;

        this.processReelResponse(this.latestResponse);
        this.showAdditionalBonusImage(this.getStageChild('bonusContainer'));
    }

    /**
     * Function to restore roll
     */
    restoreRoll() {
        JL().debug(`-- Restore roll - ${JSON.stringify(this.latestResponse)}`);
        this.Legends.showJackpot();
        this.processReelResponse(this.latestResponse);
    }

    restoreChoosingScreen() {

    }

    restoreSpin(response) {

    }

    /**
     * Prepare game behaviour after bonus 'press any button' message
     */
    startBonusAnimation = () => {
        App.Sounds.stopSound('banner-win');
        this.gameFlag.bonusStarted = true;
        this.clearPressAnyButton();
        this.Buttons.disableAllButtons();
        App.updateButton('start', {disabled: true});
        this.bonusRoll();
    };

    setBonusStatusText() {
        const {remain, total} = this.bonusStatus;
        const current = total - remain;
        const totalGames = total - this.getAmountFromLastResponse();
        this.Legends.setStatus('freeGame', {current, totalGames});
    }

    /**
     * Preparing to prize win, show win amount and set status text
     */
    showPrize() {
        this.setState('JACKPOT_WIN');

        const prize = this.prizeResponse.prize[this.currentPrizeIndex];
        this.stopAnimateFeature();
        JL().debug(`-- Show prize: ${JSON.stringify(prize)}`);

        !this.latestResponse && (this.latestResponse = {});
        // convert currency to credits
        this.latestResponse.payment = (prize.amount / App.Money.getCurrentDenomination() * 100).toFixed(0);
        App.updateButton('autoStart', {disabled: true});

        let statusText;

        switch (prize.type) {
            case 'INSURANCE':
                this.latestResponse.payment = (this.latestResponse.payment / 100).toFixed(0); // insurance in cents, convert
                statusText = App.language.insurance;
                break;
            case 'GOLD':
            case 'SILVER':
            case 'BRONZE':
                statusText = `${prize.type} jackpot win`;
                break;
            case 'BONUS':
                statusText = `${prize.type} win`;
                break;
        }
        if (prize.type !== 'INSURANCE') {
            App.Sounds.playSound('jackpot');
            App.System.sendMetric({param: `prize.${prize.type}`, value: prize.amount});
        }

        const {payment} = this.latestResponse;
        this.Legends.setText('win', {text: 'win', value: payment});

        // show win message
        App.View.setState({activePrizeWin: true});
        this.disableStage();

        this.Buttons.disableAllButtons();
        App.updateButton('start', {disabled: true});
        setTimeout(() => {
            App.updateButton('start', {
                disabled: false,
                title: 'collect',
                handler: () => {
                    this.isSpeedUp = 0;
                    prize.type !== 'INSURANCE' && App.Sounds.stopSound('jackpot');
                    this.takeWin(false);
                }
            });
            App.updateButton('autoStart', {disabled: false});
        }, 2000);

        this.Legends.setStatus('prizeWin', {statusText, payment});
    }

    checkNewPrize() {
        App.View.setState({activePrizeWin: false});
        // if last win -> finish round
        if (this.currentPrizeIndex === this.prizeResponse.prize.length - 1) {
            this.prizeResponse = null;
            this.currentPrizeIndex = 0;
            this.roundFinished();
        } else { // show next prize
            this.currentPrizeIndex++;
            App.updateButton('start', {
                disabled: true,
                title: 'start'
            });
            setTimeout(() => this.showPrize(), 1000);
        }
    }

    disableStage() {

    }

    stopWaitingAnimation() {
        cancelAnimationFrame(this.waitingAnimationFrame);
        this.waitingAnimationFrame = null;
    }

    clearInfoAnimation() {
        cancelAnimationFrame(this.infoRAF);
        this.infoRAF = null;
    }

    strokeFillText = (ctx, text, x, y) => {
        ctx.strokeText(text, x, y);
        ctx.fillText(text, x, y);
    };

    drawSplitText = (ctx, text, x, y, maxRowWidth, textProps) => {
        ctx.font = textProps.font;
        ctx.textAlign = textProps.textAlign;
        ctx.fillStyle = textProps.fillStyle;
        ctx.strokeStyle = textProps.strokeStyle;
        ctx.lineWidth = textProps.lineWidth;
        ctx.shadowColor = textProps.shadowColor;
        ctx.shadowOffsetX = textProps.shadowOffsetX;
        ctx.shadowOffsetY = textProps.shadowOffsetY;
        ctx.shadowBlur = textProps.shadowBlur;
        const splittedText = text.toString().split(' ');
        const rows = []; // array of rows
        let i = 0; // index of current row
        splittedText.forEach(word => {
            if (maxRowWidth - ctx.measureText(rows[i]).width < ctx.measureText(word).width) {
                i++; // next row for new word (if cannot draw in current row)
            }
            !rows[i] && (rows[i] = '');
            rows[i] += word + ' ';
        });

        rows.forEach((text, index) =>
            this.strokeFillText(ctx, text, x, y + textProps.lineHeight * index));

        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    };

    /**
     * Draw texts with gradient
     * Set default values for props
     * @param ctx
     * @param text
     * @param x
     * @param y
     * @param props
     */
    drawGradientFont(ctx, text, x, y, props = {}) {
        const {fontHeight = 12, gradientColor = {from: '#000', to: '#000'}} = props;

        const gradient = ctx.createLinearGradient(0, y - fontHeight, 0, y);
        gradient.addColorStop(0, gradientColor.from);
        gradient.addColorStop(1.0, gradientColor.to);
        ctx.fillStyle = gradient;
        this.strokeFillText(ctx, text, x, y);
    }

    /**
     * Draw text with additional font from image by mapping
     * @param text
     * @param x
     * @param y
     * @param props - {map, fontImageName, align, scale, fontInterval}
     */
    drawCustomFont = (text, x = 0, y = 0, props) => {
        const {parentContainer, map, fontImageName} = props;
        const {letterIndex = map.fontWidthArray.length - 1, imgWidth} = map;
        const align = props.align ?? 'left';
        const scale = props.scale ?? 1;
        const fontInterval = props.fontInterval || 0; // px between symbols
        let xOffset;
        text = text.toString();

        // calc text length (px) for align
        let textLength = 0;
        text.split('').forEach(symbol => {
            const symbolPosIndex = map.symbolsOrder.indexOf(symbol);
            textLength += map['fontWidthArray'][symbolPosIndex] + fontInterval;
        });

        // make text offset
        switch (align) {
            case 'left':
                xOffset = 0;
                break;
            case 'center':
                xOffset = -textLength / 2;
                break;
            case 'right':
                xOffset = -textLength;
                break;
        }

        // split text and draw each symbol
        text.split('').forEach(symbol => {
            const symbolPosIndex = map.symbolsOrder.indexOf(symbol);
            // create new array [from 0 to current symbol] for check symbol position (sx)
            const widthArr = Array.from(map['fontWidthArray']).splice(0, symbolPosIndex);
            const sx = widthArr.length ?
                widthArr.reduce((count, width) => count + width) : 0;
            let posX, posY;
            if (symbolPosIndex > letterIndex) {
                posX = sx - imgWidth;
                posY = map.fontHeight;
            } else {
                posX = sx;
                posY = 0;
            }

            if (parentContainer instanceof Container) {
                const sprite = new Sprite(new Texture(this.getTexture(fontImageName), {
                    x: posX, y: posY,
                    width: map['fontWidthArray'][symbolPosIndex], height: map.fontHeight
                }));
                sprite.name = symbol;
                sprite.position.set(x + (xOffset * scale), y);
                sprite.scale.set(scale);

                parentContainer.addChild(sprite);
            } else {
                parentContainer.drawImage(
                    this.getImage(fontImageName),
                    posX, posY,
                    map['fontWidthArray'][symbolPosIndex], map.fontHeight,
                    x + xOffset * scale, y,
                    map['fontWidthArray'][symbolPosIndex] * scale, map.fontHeight * scale
                );
            }

            // calc next symbol position
            xOffset += map['fontWidthArray'][symbolPosIndex] + fontInterval;
        });
    };

    //
    // ======================== EXTRA BET SECTION =============================
    //

    /**
     * Create PIXI.Sprite for extra bet button
     * Set position and add event
     */
    initExtraBet(parentContainer) {
        const sprite = new Sprite(this.getTexture('ex_deactivated'));
        sprite.position.set(592, 464);
        sprite.name = 'extraBetButton';
        sprite.buttonMode = true; // shows hand cursor
        sprite.on('pointerdown', this.extraBetClick);
        parentContainer.addChild(sprite);

        this.updateExtraBetButtons();
    }

    /**
     * Change extraBet image and update settings
     * Set max lines
     */
    extraBetClick = () => {
        this.extraBetActive = !this.extraBetActive;
        App.Sounds.playSound(this.extraBetActive ? 'extra_bet_activation' : 'extra_bet_deactivation');
        this.updateExtraBetButtons();
        this.extraBetActive && this.setMaxLines();
        this.updateGameSettingsStates();
        this.InfoScreen.update({page: 1});
    };

    /**
     * Function call after extraBet toggle
     * Unique changes for each game
     * @param interactive - enable button
     */
    updateExtraBetButtons(interactive = true) {
        const container = this.getStageChild('extraBetContainer');
        const button = container.getChildByName('extraBetButton');

        button.texture = this.getTexture(this.extraBetActive ? 'ex_activated' : 'ex_deactivated');
        button.interactive = interactive;
    }

    /**
     * Function to get extraBet
     */
    getExtraBet = () => this.extraBet && !this.extraBetActive ? 1 : 0;

    //
    // ======================== STATE SECTION =============================
    //

    /**
     * Set global state for Game
     * @param state
     */
    setState(state) {
        this.state = state;
    }

    /**
     * Get current Game state
     * @returns {string|*}
     */
    getState() {
        return this.state;
    }

    //
    // ======================== LONG ROLL SECTION =============================
    //

    /**
     * Long roll decider return reel from with long roll started
     *  -1 no long roll
     *  0 1 2 3 4 5 number of reel
     */
    getLongRoll(screen) {
        const scatterMap = [];
        const extra = this.getExtraBet();
        screen.forEach((vector, reelIndex) => {
            vector.forEach(symbol => {
                if (symbol === this.scatter && reelIndex !== this.reels - 1 - extra) { // do not use last reel scatter
                    scatterMap.push(reelIndex); // correct last reel with extrabet
                }
            });
        });
        const longRollType = (scatterMap.length > 1 && this.allowLongRoll) ? scatterMap[1] + 1 : -1;

        this.reelSymbol.forEach((symbolAmount, reelIndex) => {
            this.reelLong[reelIndex] =
                (reelIndex >= longRollType && longRollType !== -1 &&
                    reelIndex >= this.longRollSettings[0] && reelIndex <= this.longRollSettings[1]) ?
                    1 : 0;
        });
        return this.reelLong; // get possible long roll type
    }

    /**
     * Set long roll parameters
     *  -1 no long roll
     *  0 1 2 3 4 5 number of reel
     */
    setReelSymbol(screen) {
        const longRoll = this.getLongRoll(screen);
        const [symbolAmount, regularIncrease, longIncrease] = this.reelSettings;

        longRoll.forEach((symbol, reelIndex) => {
            const diff = symbol === 1 ? longIncrease : regularIncrease;
            this.reelSymbol[reelIndex] = reelIndex === 0 ?
                symbolAmount : this.reelSymbol[reelIndex - 1] + diff;
        });
    }

    //
    // ======================== MOBILE SECTION =============================
    //

    /**
     * Change lines on mobile platform
     * @param type - increase or decrease lines
     */
    changeLinesNumbers(type) {
        this.SymbolInfo.remove(false);
        switch (type) {
            case 'increase':
                this.gameSettings.posLine = (this.gameSettings.posLine + this.gameSettings.lines.length + 1) % this.gameSettings.lines.length;
                break;
            case 'decrease':
                this.gameSettings.posLine = (this.gameSettings.posLine + this.gameSettings.lines.length - 1) % this.gameSettings.lines.length;
                break;
        }
        this.extraBetActive && this.extraBetClick();
        this.Legends.setRoundFinText();
        this.drawLines(this.gameSettings.posLine);

        App.updateButton('lines', {value: this.gameSettings.getLinesNumber()});
        App.updateButton('total', {value: this.gameSettings.getBetCredit()});
        this.InfoScreen.update({page: 1});
        this.Legends.showJackpot();
    }

    /**
     * Change bet on mobile platform
     * @param type - increase or decrease bet
     */
    changeBetMobile(type) {
        this.SymbolInfo.remove(false);
        switch (type) {
            case 'increase':
                this.gameSettings.posBet = (this.gameSettings.posBet + this.gameSettings.bets.length + 1) % this.gameSettings.bets.length;
                break;
            case 'decrease':
                this.gameSettings.posBet = (this.gameSettings.posBet + this.gameSettings.bets.length - 1) % this.gameSettings.bets.length;
                break;
        }
        this.Lines.drawBoxes(this.getStageChild('boxesContainer'));
        this.updateGameSettingsStates();
        this.InfoScreen.update({page: 1});
    }

    /**
     * Change denomination on mobile platform
     * @param type - increase or decrease denomination
     */
    changeDenomMobile(type) {
        switch (type) {
            case 'increase':
                App.Money.posDenomination = (App.Money.getCurrentDenominationPos() + 1) % App.Money.denominations.length;
                break;
            case 'decrease':
                App.Money.posDenomination = (App.Money.getCurrentDenominationPos() + App.Money.denominations.length - 1) % App.Money.denominations.length;
                break;
        }
        App.updateState('moneyParams', {
            credits: App.Money.getCredit(),
            money: App.Money.getMoney(),
            insurance: App.Money.getInsurance().toFixed(2)
        });
        this.InfoScreen.update({page: 1});
        App.updateButton('denomination', {value: App.Money.getCurrentDenomination() / 100});
    }

    openGameSettings = () => {
        this.SymbolInfo.remove(false);
        App.Modal.showGameSettings();
        App.updateButton('gameSettings', {
            status: true,
            handler: this.closeGameSettings
        });
    };

    closeGameSettings = () => {
        this.SymbolInfo.remove(false);
        App.Modal.remove('gameSettings');
        App.updateButton('gameSettings', {
            status: false,
            handler: this.openGameSettings
        });
    };

    //
    // ======================== SOUNDS SECTION =============================
    //

    playBonusGameSound = () => App.Sounds.playSound('bonusGameStart');

    playEndBonusGameSound = () => App.Sounds.playSound('bonusGameEnd');

    playFeatureSound(currentFeature, featureIndex, features) {
        let soundFile = null;
        switch (currentFeature.uc) {
            case 'WIN_LINE':
            case 'SCATTER':
                soundFile = 'win-line';
                break;
        }

        soundFile && App.Sounds.stopSound(soundFile);
        soundFile && App.Sounds.playSound(soundFile);
    }

    playRespinSound = () => {
        App.Sounds.playSound('respin');
    };

    playRollSound = () => {
        this.gameFlag.bonusStart || App.restoreGameState === 'BONUS' ?
            App.Sounds.playSound('bonus_reels') :
            App.Sounds.playSound('reels');
    };

    stopRollSound = () => {
        App.Sounds.stopSound('bonus_reels');
        App.Sounds.stopSound('reels');
    };

    stopAnimateSound = () => {
        App.Sounds.stopSound('gamble-wait');
    };

    playLongRollSound = reelIndex => {
        const extra = this.getExtraBet();
        if (this.reelLong[reelIndex] === 1 && reelIndex !== this.reels - extra) { // current reel without last reel with extraBet correction
            this.stopRollSound();
            this.stopLongRollSound();
            setTimeout(() => App.Sounds.playSound('long1'), 50);
        }
    };

    playGameWaitSound = () => {
        App.Sounds.playSound('gamble-wait');
    };

    playIntroSound = () => {
        App.Sounds.playSound('intro');
    };

    playBackgroundSound = () => {
        App.Sounds.playSound('background');
    };

    /**
     * function to decide play scatter teaser sound based on reel
     */
    stopLongRollSound = () => {
        App.Sounds.stopSound('long1');
        App.Sounds.stopSound('long2');
        App.Sounds.stopSound('long3');
        App.Sounds.stopSound('long4');
        App.Sounds.stopSound('long5');
        App.Sounds.stopSound('long6');
    };

    /**
     * function to decide stop scatter teaser sound based on reel
     */
    stopScatterSound = () => {
        App.Sounds.stopSound('teaser_1');
        App.Sounds.stopSound('teaser_2');
        App.Sounds.stopSound('teaser_3');
        App.Sounds.stopSound('teaser_4');
        App.Sounds.stopSound('teaser_5');
        App.Sounds.stopSound('teaser_6');
    };

    /**
     * Update info page in game
     * @param message - new props
     */
    updateKioskInfo(nextPage = false, timeOut = false, currentPage = 1) {
        if (App.configs.mode === 'info') {
            return;
        }
        App.System.updateInfo({
            uc: 'GAME',
            data: {
                id: this.id,
                lineIndex: this.gameSettings.getPosLineIndex(),
                betIndex: this.gameSettings.getPosBetIndex(),
                nextPage: nextPage,
                timeout: timeOut,
                currentPage: currentPage,
                extraBetActive: this.extraBetActive,
                bets: this.gameSettings.bets,
                lines: this.gameSettings.lines,
                gambleLimit: this.Gamble ? this.Gamble.limit : 0,
                denominations: App.Money.denominations,
                denominationPos: App.Money.getCurrentDenominationPos(),
                lang: App.settings.currentLanguage
            }
        });
    }

    /**
     * Additional handler for keyboard keys press
     * @param event
     * @param buttons
     * @returns {{soundChange: boolean, handler: boolean}}
     */
    keyboardPress(event, buttons) {
        return {soundChange: true, handler: null};
    }

    /**
     * Reset all game states and animations
     * Call after WebSocket connection lost
     */
    resetGame() {
        this.app && this.stopAnimateFeature();
        this.stopWaitingAnimation();
        this.clearPressAnyButton();
        this.clearVideoResources();
        this.resetCreditAnimation();
        this.BigWin.resetCoinsAnimation();
        App.updateButton('autoStart', {pressed: false});
        this.Buttons.resetWrap();
        this.Roll.clearRoll();
        this.Legends.setRoundFinText();
        this.InfoScreen.reset();
        this.Gamble?.resetInverting();
        this.Lines.destroy();
        App.removePopupMessage();
        App.View.setState({activeGamble: false});
        App.updateState('buttons', {animation: ''});
        App.updateState('waiting', App.View.getInitState().waiting);
    }

    clearVideoResources() {
        this.imageResources.video && Object.keys(this.imageResources.video).forEach(key => {
            if (this.resources[key]) {
                this.resources[key].pause();
                this.resources[key].removeAttribute('src'); // empty source
                this.resources[key].load();
            }
        });
    }

    /**
     * Scale and resize stage from window to game size
     * @returns {number} - scale coefficient
     */
    resizeStage = (stageResize = true) => {
        if (this.app) {
            const canvas = this.app.renderer.view;
            let scale = canvas.offsetHeight < window.innerHeight ?
                window.innerWidth / this.gameWidth :
                window.innerHeight / this.gameHeight;
            scale = Math.round(scale * 1000) / 1000;

            // scale only in window height from 600 to 720
            if (scale < 600 / this.gameHeight) scale = 600 / this.gameHeight;
            if (scale > 1) scale = 1;

            stageResize && this.app.stage.scale.set(scale);
            stageResize && this.app.renderer.resize(this.gameWidth * scale, this.gameHeight * scale);

            return scale;
        }
    };

    /**
     * Update PIXI stage after language change
     * @param language - current language collection
     */
    translateStage(language) {
        this.InfoScreen.update();
        App.View.state.activeGamble && this.Gamble.draw(true);
    }

    /**
     * Show message on screen
     * @param message
     * @param value
     */
    createPopup(message, value = {}) {
        this.Legends.setStatus(message, value);
        this.playPopupMessageSound();
        App.createPopupMessage(this.Legends.getStatusText(message));
    }

    playPopupMessageSound() {

    }

    /**
     * Return array of textures for animating in symbol info container
     * @param symbolIndex
     * @returns {*}
     */
    getSymbolInfoTextures = symbolIndex => this.Roll.textures['regular'][symbolIndex];

    /**
     * Disable InfoSymbols when infoScreen is opened
     */
    disableInfoSymbols() {
        this.getStageChild('reelsStage').children.forEach(symbol => (symbol.interactive = false));
        this.SymbolInfo.remove(false);
    }

    /**
     * Open animation for symbol info
     * @param symbolBorder
     * @param paymentBorder
     * @param direction - left/right
     * @returns {Array} - ticker animations
     */
    symbolInfoOpen(symbolBorder, paymentBorder, direction) {
        const {paymentBorderOffset} = this.SymbolInfo.settings;
        const getPosX = () => direction === 'left' ?
            -paymentBorderOffset[direction] :
            this.symbolWidth + paymentBorderOffset[direction];

        return this.showAnimation({
            duration: 500, animations: [
                {sprite: symbolBorder, timeline: [{to: {scaleX: 1, scaleY: 1}, duration: {to: 250}}]},
                {sprite: paymentBorder, timeline: [{to: {x: getPosX(), alpha: 1}, duration: {from: 250}}]}
            ]
        });
    }

    /**
     * Create texts for paymentBorder table
     * @param parentContainer
     * @param payTable
     * @param direction
     */
    drawSymbolInfoPayments(parentContainer, payTable, direction) {

    }

    showAnimation(params) {
        const ticker = this.app.ticker;
        const tickerFunctions = [];
        const {duration, delay = 0, animations, onComplete} = params;
        const animationStarted = Date.now();

        // create new timeline for each sprite
        animations.forEach(({sprite, timeline}, index) => {
            const startParams = {
                x: sprite.position.x,
                y: sprite.position.y,
                scaleX: sprite.scale.x,
                scaleY: sprite.scale.y,
                alpha: sprite.alpha,
                angle: sprite.angle
            };

            const onTick = () => {
                // calc ms from start
                const timePassed = Date.now() - animationStarted;

                // check current timeline animation
                timeline.forEach(params => {
                    const {duration: {from = 0, to = duration} = {from: 0, to: duration}} = params;

                    // calc step, from 0 to 1
                    const step = (timePassed - from) / (to - from);

                    // check if current animation in timeline
                    timePassed > from && onStep(params, timePassed < to ? step : 1);
                });

                // remove function after animation
                if (timePassed > duration) {
                    ticker.remove(onTick);

                    // complete after last
                    if (index === animations.length - 1) {
                        const loop = onComplete && onComplete();
                        loop && setTimeout(() => this.showAnimation(params), delay);
                    }
                }
            };

            // update sprite params
            const onStep = ({from = startParams, to = startParams}, step) => {
                const round = (value, step = 10) => Math.round(value * step) / step;

                // don't redefine alpha without params
                if (from.alpha || to.alpha) {
                    from.alpha = from.alpha !== undefined ? from.alpha : startParams.alpha;
                    to.alpha = to.alpha !== undefined ? to.alpha : startParams.alpha;

                    sprite.alpha = round(from.alpha + (to.alpha - from.alpha) * step, 1000);
                }

                // don't redefine positions without params
                if (from.x || from.y || to.x || to.y) {
                    from.x = from.x !== undefined ? from.x : startParams.x;
                    from.y = from.y !== undefined ? from.y : startParams.y;
                    to.x = to.x !== undefined ? to.x : startParams.x;
                    to.y = to.y !== undefined ? to.y : startParams.y;

                    sprite.position.set(
                        round(from.x + (to.x - from.x) * step),
                        round(from.y + (to.y - from.y) * step)
                    );
                }

                // don't redefine scale without params
                if (from.scaleX || from.scaleY || to.scaleY || to.scaleY) {
                    from.scaleX = from.scaleX !== undefined ? from.scaleX : startParams.scaleX;
                    from.scaleY = from.scaleY !== undefined ? from.scaleY : startParams.scaleY;
                    to.scaleX = to.scaleX !== undefined ? to.scaleX : startParams.scaleX;
                    to.scaleY = to.scaleY !== undefined ? to.scaleY : startParams.scaleY;
                    sprite.scale.set(
                        round(from.scaleX + (to.scaleX - from.scaleX) * step, 1000),
                        round(from.scaleY + (to.scaleY - from.scaleY) * step, 1000)
                    );
                }

                // don't redefine angle without params
                if (from.angle || to.angle) {
                    from.angle = from.angle !== undefined ? from.angle : startParams.angle;
                    to.angle = to.angle !== undefined ? to.angle : startParams.angle;
                    sprite.angle = round(from.angle + (to.angle - from.angle) * step, 1000);
                }
            };

            tickerFunctions.push(onTick);
            ticker.add(onTick);
        });

        return tickerFunctions;
    }

    /**
     * Calc time by using PIXI Ticker
     * Instead native setTimeout
     * @param callback
     * @param delay
     * @returns {func}
     */
    tickerTimeout(callback, delay) {
        if (this.app) {
            const ticker = this.app.ticker;
            const startTime = Date.now(); // save time for calc diff
            const func = () => {
                if (Date.now() - startTime > delay) { // time spent
                    callback?.();
                    ticker.remove(func);
                }
            };
            ticker.add(func);
            return func;
        }
    }

    /**
     * Calc time by using PIXI Ticker
     * Instead native setInterval
     * @param callback
     * @param delay
     * @returns {func}
     */
    tickerInterval(callback, delay) {
        if (this.app) {
            const ticker = this.app.ticker;
            let startTime = Date.now(); // save time for calc diff
            const func = () => {
                if (Date.now() - startTime > delay) { // time spent
                    startTime = Date.now();
                    callback?.();
                }
            };
            ticker.add(func);
            return func;
        }
    }

    stopBoxAnimation = () => {

    };

    hideBoxes = () => {
        if (this.getStageChild('boxesContainer')) {
            this.getStageChild('boxesContainer').visible = false;
        }
    };

    showBoxes = () => {
        if (this.getStageChild('boxesContainer')) {
            this.getStageChild('boxesContainer').visible = true;
        }
    };

    hideLines = () => {
        if (this.getStageChild('linesContainer')) {
            this.getStageChild('linesContainer').visible = false;
        }
    };

    showLines = () => {
        if (this.getStageChild('linesContainer')) {
            this.getStageChild('linesContainer').visible = true;
        }
    };

    /**
     * Restore web socket state, disable all buttons, show internet spinner
     */
    restoreWebSocket(fastReload) {
        App.showSpinner();
        this.Buttons.disableAllButtons();
        App.updateButton('start', {disabled: true});
        App.updateButton('autoStart', {disabled: true});
        switch (this.getState()) {
            case 'ASK_GAMBLE':
                break;
            case 'DOUBLING':
            case 'GAMBLE':
                this.Gamble.deactivateGambleButtons();
                break;
            case 'TAKE_WIN':
                break;
        }
        if (fastReload) location.reload();
    }

    /**
     * Restored web socket action
     */
    webSocketRestored() {
        App.hideSpinner();
        if (this.getState() !== 'EXIT-GAME') {
            this.enableAutoStartButton();
        }
        switch (this.getState()) {
            case 'EXIT-GAME':
                break;
            case 'ASK_GAMBLE':
                this.gambleOrTakeWin();
                break;
            case 'DOUBLING':
            case 'GAMBLE':
                this.Gamble.activateGambleButtons();
                break;
            case 'TAKE_WIN':
                if (App.Socket.lostPacket === 'TRANSFER-WIN') {
                    App.Socket.send(JSON.stringify({uc: 'TRANSFER-WIN'}));
                } else {
                    App.updateButton('start', {
                        disabled: false,
                        title: 'collect',
                        handler: () => { // User can press 'start' twice for increasing transfer speed
                            this.isSpeedUp !== 2 && this.isSpeedUp++;
                            JL().debug(`-- Take win speed up x${this.isSpeedUp + 1}`);
                            // TODO after repeat click -> take all win immediately
                        }
                    });
                }
                break;
        }
    }

    enableAutoStartButton() {
        App.updateButton('autoStart', {disabled: false});
        if (this.autostart) {
            App.updateButton('autoStart', {pressed: true});
        } else { // turn off
            App.updateButton('autoStart', {pressed: false});
        }
    }
}
