import App from './../index';

export default class gameSettings {
    constructor() {
        this.lines = [];
        this.bets = [];
        this.posBet = 0;
        this.posLine = 0;
    }

    /**
     * Получить колличество выбраных линий
     */
    getLinesNumber = () => this.lines[this.posLine];

    getLinesIndex = () => this.posLine;

    getCurrentLineMas() {
        const lineMas = [];
        for (let i = 0; i <= this.posLine; i++) {
            lineMas.push(i + 1);
        }
        return lineMas;
    }

    /**
     * Получить массив линий
     */
    getLineMas() {
        return this.lines;
    }

    /**
     * Установить массив линий
     */
    setLineMas(response) {
        if (response.length <= 1) {
            for (let j = 1; j <= response[0]; j++) {
                this.lines.push(j);
            }
        } else {
            this.lines = response;
        }
    }

    /**
     * Установить позицию в массиве линий
     */
    setPosLineValue(index) {
        this.posLine = this.lines.indexOf(index, 0);
    }

    /**
     * Установить позицию в массиве линий
     */
    setPosLineIndex(index) {
        this.posLine = index;
    }

    /**
     * Установить позицию в массиве линий
     */
    getPosLineIndex = () => this.posLine;

    /**
     * Установить позицию в массиве линий
     */
    getPosBetIndex = () => this.posBet;

    /**
     * Получить текущую ставку
     */
    getBet() {
        const multiply = App.Game.extraBetActive ? 2 : 1;
        return this.getLinesNumber() * this.getBetLine() * multiply;
    }

    /**
     * Получить текущую ставку на одну линию
     */
    getBetLine() {
        return (this.bets[this.posBet] * App.Money.getCurrentDenomination());
    }

    /**
     * Получить текущую ставку на одну линию в кредитах *** для отображения на экране ***
     */
    getBetLineCredit() {
        return this.bets[this.posBet];
    }

    /**
     * Получить текущую ставку в кредитах
     */
    getBetCredit() {
        return Math.floor(this.getBet() / App.Money.getCurrentDenomination());
    }

    /**
     * Установить доступные ставки
     */
    setBets(response) {
        this.bets = response;
    }

    /**
     * Установить позицию в массиве ставок*
     */
    setPosBetValue(value) {
        this.posBet = this.bets.indexOf(value, 0);
    }

    /**
     * Установить позицию в массиве ставок
     */
    setPosBetIndex(index) {
        this.posBet = index;
    }

    /**
     * Update lines and bet game settings
     * @param response - Socket response 'GAME-SETUP'
     */
    setBetAndLine(response) {
        const {bet, lines} = response;
        const cents = App.Money.getCents();
        const minBet = App.Money.getMinBet();
        this.setPosBetValue(bet);
        this.setPosLineIndex(lines - 1);

        // check available balance
        if (cents >= minBet && App.restoreGameState === 'NONE') {
            // update total bet bigger than minBet value
            while (minBet > this.getBet()) {
                if (this.lines[this.posLine + 1]) {
                    this.posLine++; // increase lines
                } else if (this.bets[this.posBet + 1]) {
                    this.posBet++; // increase bet
                } else {
                    return;
                } // can't increase more
            }
        }
    }
}
