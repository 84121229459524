import Lines17 from './../../champion/lines17';

export default class Lines extends Lines17 {
    constructor() {
        super();
        this.imageResources = {
            boxes: '/img/lines/champion_boxes17_ultra7hot.png'
        };

        this.boxSettings = {
            ...this.boxSettings,
            lineBoxWidth: 25,
            textType: 'line',
            typeBoxes: 'OneBox',
            boxFontSize: 12,
            boxTextColor: '#368af1'
        };
        this.linesSettings = {
            ...this.linesSettings,
            borderThickness: 5,
            borderColor: '#0044a0',
            lineColor: '#6887dc'
        };
        Object.keys(this.lines).forEach(lineIndex =>
            this.lines[lineIndex].boxes[0].x = lineIndex >= 9 ? 760 : 15);
    }
}
