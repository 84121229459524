import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../../../index';
import BookGame from './../../deluxe/bookGame';
import GambleDeluxe from './../../deluxe/gamble';
import Lines10 from './../../deluxe/lines10';
import InfoScreen from '../../infoScreen';
import bonusFont from './img/font/fontBonus';

/* PIXI aliases */
const Container = PIXI.Container,
    AnimatedSprite = PIXI.AnimatedSprite;

export default class GoldenArk extends BookGame {
    constructor() {
        super();
        this.id = 'golden-ark';
        this.name = 'Golden Ark';
        this.scatter = 9;
        this.transparentBackground = true;
        this.buttonsPanelShadow = 'strong';

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 105, y: 85},
            bonusInBonusFrame: {x: 123, y: 200},
            endBonusFrame: {x: 123, y: 200}
        };

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 0, 5, 25, 100]},                  // 0 - 10
            {regularDelay: 100, payment: [0, 0, 0, 5, 25, 100]},                  // 1 - J
            {regularDelay: 100, payment: [0, 0, 0, 5, 25, 100]},                  // 2 - Q
            {regularDelay: 100, payment: [0, 0, 0, 5, 40, 150]},                  // 3 - K
            {regularDelay: 100, payment: [0, 0, 0, 5, 40, 150]},                  // 4 - A
            {regularDelay: 100, payment: [0, 0, 5, 30, 100, 750]},                // 5 - scarab
            {regularDelay: 100, payment: [0, 0, 5, 30, 100, 750]},                // 6 - eye
            {regularDelay: 100, payment: [0, 0, 5, 40, 400, 2000]},               // 7 - pharaoh
            {regularDelay: 100, payment: [0, 0, 10, 100, 1000, 5000]},            // 8 - women
            {regularDelay: 100, scatterDelay: 70, payment: [0, 0, 0, 2, 20, 200]} // 9 - book (scatter)
        ];
        this.imageResources = {
            main: this.mergePath({
                mainArea: `area/${App.System.resolution}/main.png`,
                background: 'area/background.png',
                bonusFont: bonusFont['imageResource']
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                bonusArea: 'area/bonus.png',
                frame: 'bonus/frame.png',
                bookAnim: 'bonus/book-anim.png',
                frameAnim: 'bonus/frame-anim.png',
                symbolBonusLine: 'bonus/symbol-bonus-line.png'
            }),
            atlas: this.mergePath([
                'bonus/bonusSymbols.json',
                'bonus/scatterSymbols.json',
                'bonus/additionalSymbols.json',
                'regularLongSymbols1.json',
                'regularLongSymbols2.json'
            ])
        };
        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'bonus-game-end', alias: 'bonusGameEnd'},
                {name: 'book-select-symbol', alias: 'bookSelectSymbol', loop: true},
                {
                    name: 'bonus-win-symbols-show',
                    alias: 'bonusWinSymbols',
                    path: '/game/games/book-of-ra-deluxe/audio/'
                },
                {
                    name: 'book-selected-symbol',
                    alias: 'bookSelectedSymbol',
                    path: '/game/games/book-of-ra-deluxe/audio/'
                },
                {name: 'book-flash', alias: 'bookFlash', path: '/game/games/book-of-ra-deluxe/audio/'},
                {name: 'bonus-background', loop: true}
            ],
            path: `/game/games/${this.id}/audio/`
        };

        this.Lines = new Lines10();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png',
            activeBlack: 'gamble/black-active.png',
            inactiveBlack: 'gamble/black-inactive.png',
            activeRed: 'gamble/red-active.png',
            inactiveRed: 'gamble/red-inactive.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines - current lines count
     * @param bet - current bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '17pt franklinFont';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#f7c354';
        ctx.shadowColor = 'black';
        ctx.shadowOffsetX = 3;
        ctx.shadowOffsetY = 3;
        ctx.shadowBlur = 5;

        const textProps = {
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 1.3,
            fontInterval: -5 // px between symbols
        };

        switch (page) {
            case 1:
                // scatter
                this.strokeFillText(ctx, bet * nLines * this.symbols[9].payment[5], 360, 48);
                this.strokeFillText(ctx, bet * nLines * this.symbols[9].payment[4], 360, 80);
                this.strokeFillText(ctx, bet * nLines * this.symbols[9].payment[3], 360, 112);

                // women
                this.strokeFillText(ctx, bet * this.symbols[8].payment[5], 160, 161);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[4], 160, 183);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[3], 160, 205);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[2], 160, 227);
                // pharaon
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], 660, 161);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], 660, 183);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 660, 205);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[2], 660, 227);
                // eye
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 160, 314);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 160, 336);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 160, 358);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[2], 160, 380);
                // scarab
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 660, 314);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 660, 336);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 660, 358);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[2], 660, 380);
                // AK
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 230, 487);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 230, 517);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 230, 547);
                // QJ10
                this.strokeFillText(ctx, bet * this.symbols[2].payment[5], 590, 487);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[4], 590, 517);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[3], 590, 547);
                break;
            case 2:
                textProps.parentContainer = ctx;
                this.drawCustomFont('FEATURE', 400, 260, textProps);

                ctx.font = 'bold 16pt Times New Roman';
                ctx.fillStyle = '#fff';
                ctx.shadowBlur = 3;
                ctx.shadowColor = '#000';
                ctx.textAlign = 'left';

                ctx.fillText('3 or more scatters on the screen trigger 10 Free', 250, 100);
                ctx.fillText('Games with special expanding symbol.', 250, 130);

                ctx.fillText('One special expanding symbol is randomly selected at the', 250, 440);
                ctx.fillText('start of the Free Games. During Free Games the special', 250, 470);
                ctx.fillText('symbol expand to cover 3 positions on the reel and pays', 250, 500);
                ctx.fillText('in any position on lines played.', 250, 530);
                break;
            case 3:
                textProps.parentContainer = ctx;
                this.drawCustomFont('RULES', 400, 180, textProps);

                ctx.font = 'bold 15pt Times New Roman';
                ctx.fillStyle = '#fff';
                ctx.shadowBlur = 3;
                ctx.shadowColor = '#000';
                ctx.textAlign = 'center';

                ctx.fillText('All prizes are for combinations of a kind. All prizes are for', 400, 260);
                ctx.fillText('combinations left to right, except scatters. All prizes are on selected', 400, 285);
                ctx.fillText('lines, except scatters. Scatter symbols pay at any position on screen.', 400, 310);
                ctx.fillText('Highest win only paid per selected line. Scatter wins are added to line', 400, 335);
                ctx.fillText('wins. Free Games can be won again during the Free Games. Free', 400, 360);
                ctx.fillText('Games are played at trigger bet and lines. All prizes shown in credits.', 400, 385);
                ctx.fillText('Malfunction voids all pays and plays.', 400, 410);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    /**
     * Drawing  the table of bonus game
     */
    showStartBonusFrame(parentContainer, {x, y}) {
        const bookContainer = new Container();
        bookContainer.name = 'bookContainer';
        bookContainer.position.set(x, y);
        parentContainer.addChild(bookContainer);

        this.createFrameSprite(bookContainer);
        this.createBookSprite(bookContainer);

        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            fontInterval: -5 // px between symbols
        };
        this.drawCustomFont('10 FREE GAMES WON', 400, 105, textProps);
        this.drawCustomFont('Special expanding symbol', 400, 397, textProps);
    }

    /**
     *  Drawing  the table of bonus in bonus game
     */
    showBonusInBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y);
        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            fontInterval: -3 // px between symbols
        };
        this.drawCustomFont('10 MORE FREE GAMES WON', 400, 280, textProps);
    }

    /**
     * Drawing  the table of end of bonus in bonus game
     */
    showEndBonusFrame(parentContainer, {x, y}, status) {
        this.showBonusFrame(parentContainer, x, y);
        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            fontInterval: -3 // px between symbols
        };
        this.drawCustomFont('FEATURE WIN', 400, 235, textProps);
        this.drawCustomFont(`${status.win} CREDITS`, 400, 285, textProps);
        this.drawCustomFont(`${status.total} FREE GAMES PLAYED`, 400, 335, textProps);
    }

    //
    // -------------- Special book animations ---------------------
    //

    createFrameSprite(parentContainer) {
        const image = 'frameAnim';
        const sprite = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 2, image,
            width: 590, height: 376
        }));
        sprite.name = image;
        sprite.animationSpeed = 0.1;
        sprite.loop = true;
        parentContainer.addChild(sprite);
    }

    createBookSprite(parentContainer) {
        const image = 'bookAnim';
        const sprite = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 76, image,
            width: 160, height: 160, colCount: 12
        }));
        sprite.name = image;
        sprite.animationSpeed = 0.4;
        sprite.scale.set(1.05);
        sprite.loop = false;
        sprite.position.set(210, 105);
        parentContainer.addChild(sprite);
    }

    /**
     * Draw all bonus animation after bonus 'press any button'
     * @param parentContainer
     * @param bonusSymbol - current bonus symbol in extension
     */
    drawBonusAnimation(parentContainer, bonusSymbol) {
        JL().debug(`-- Draw bonus enter animation. Bonus symbol: ${bonusSymbol}`);

        const bookContainer = parentContainer.getChildByName('bookContainer');
        const spriteBookAnim = bookContainer.getChildByName('bookAnim');
        const frameAnim = bookContainer.getChildByName('frameAnim');

        spriteBookAnim.play(); // first stage animation
        App.Sounds.stopSound('bookSelectSymbol');
        App.Sounds.playSound('bookSelectSymbol');
        spriteBookAnim.onComplete = () => {
            // frame index for each symbol in book open animation
            const openedSymbolsFrames = [68, 61, 54, 47, 40, 33, 24, 17, 10];
            spriteBookAnim.textures = this.getSpriteTextures({
                fromFrame: 9, toFrame: openedSymbolsFrames[bonusSymbol], image: 'bookAnim',
                width: 160, height: 160, colCount: 12
            });
            spriteBookAnim.play();
            spriteBookAnim.onComplete = frameAnimation;
        };

        const frameAnimation = () => {
            App.Sounds.stopSound('bookSelectSymbol');
            App.Sounds.playSound('bookSelectedSymbol');
            frameAnim.play();

            setTimeout(() => {
                frameAnim.stop();
                this.bonusRoll();
            }, 2000);
        };
    }
}
