import App from './../../../index';
import GameChampion from './../../champion/game';
import Lines20 from './../../champion/lines20';
import GambleChampion from './../../champion/gamble';
import InfoScreen from '../../infoScreen';

export default class SevensOnFire extends GameChampion {
    constructor() {
        super();
        this.id = 'sevens-on-fire';
        this.name = 'Sevens on Fire';
        this.symbolEffects = true;
        this.transparentBackground = true;
        this.reelTop = 89; // magic number - where reel images starts
        this.symbolHeight = 130; // height of a single symbol
        this.symbolWidth = 130; // width of a single symbol
        this.buttonsPanelShadow = 'low no-blur';

        this.symbols = [
            {regularDelay: 25, skipSteps: 1, payment: [0, 0, 5, 20, 50, 200]},     // 0 - вишня
            {regularDelay: 25, skipSteps: 1, payment: [0, 0, 0, 20, 50, 200]},     // 1 - лимон
            {regularDelay: 25, skipSteps: 1, payment: [0, 0, 0, 20, 50, 200]},     // 2 - апельсин
            {regularDelay: 25, skipSteps: 1, payment: [0, 0, 0, 20, 50, 200]},     // 3 - слива
            {regularDelay: 25, skipSteps: 1, payment: [0, 0, 0, 50, 200, 500]},    // 4 - арбуз
            {regularDelay: 25, skipSteps: 1, payment: [0, 0, 0, 50, 200, 500]},    // 5 - виноград
            {regularDelay: 25, skipSteps: 1, payment: [0, 0, 0, 100, 1000, 5000]}, // 6 - символ 7
            {regularDelay: 25, skipSteps: 1, payment: [0, 0, 0, 2, 10, 50]}        // 7 -  звезда
        ];
        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.gameSounds = {soundClass: 'champion'};
        this.Lines = new Lines20();
        this.Gamble = new GambleUnique({gambleArea: `/game/games/${this.id}/img/gamble/gamble-area.png`});
        this.InfoScreen = new InfoScreen({pages: 1}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 14pt Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#fc485c';
        ctx.strokeStyle = '#500430'; // text border color
        ctx.lineWidth = 4;

        // 7
        this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 410, 232);
        this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 410, 260);
        this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 410, 288);

        // wathermelon
        this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 145, 79);
        this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 145, 107);
        this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 145, 135);

        // grape
        this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 665, 79);
        this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 665, 107);
        this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 665, 135);

        // peach
        this.strokeFillText(ctx, bet * this.symbols[3].payment[5], 120, 256);
        this.strokeFillText(ctx, bet * this.symbols[3].payment[4], 120, 284);
        this.strokeFillText(ctx, bet * this.symbols[3].payment[3], 120, 312);

        // apricot
        this.strokeFillText(ctx, bet * this.symbols[2].payment[5], 690, 256);
        this.strokeFillText(ctx, bet * this.symbols[2].payment[4], 690, 284);
        this.strokeFillText(ctx, bet * this.symbols[2].payment[3], 690, 312);

        // lemon
        this.strokeFillText(ctx, bet * this.symbols[1].payment[5], 120, 423);
        this.strokeFillText(ctx, bet * this.symbols[1].payment[4], 120, 454);
        this.strokeFillText(ctx, bet * this.symbols[1].payment[3], 120, 485);

        // chery
        this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 690, 426);
        this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 690, 454);
        this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 690, 482);
        this.strokeFillText(ctx, bet * this.symbols[0].payment[2], 690, 509);

        // star
        this.strokeFillText(ctx, bet * nLines * this.symbols[7].payment[5], 410, 467);
        this.strokeFillText(ctx, bet * nLines * this.symbols[7].payment[4], 410, 495);
        this.strokeFillText(ctx, bet * nLines * this.symbols[7].payment[3], 410, 523);
    }
}

class GambleUnique extends GambleChampion {
    constructor(imageResources) {
        super(imageResources);
        this.cardsQueuePos = {x: [421, 487, 553, 619, 685], y: 80};
        this.screenCoordinates = {x: 0, y: 86};
    }
}
