import App from './../../../index';
import GameDeluxe5x4 from './../../deluxe/gameDeluxe5x4';
import Lines40 from './../../deluxe/lines40';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';

export default class JewelsDivine extends GameDeluxe5x4 {
    constructor() {
        super();
        this.id = 'jewels-divine';
        this.name = 'Jewels divine';
        this.symbolEffects = true;
        this.buttonsPanelShadow = 'mid';

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 0, 4, 40, 100]},    // 0 - square green crystal
            {regularDelay: 100, payment: [0, 0, 0, 4, 40, 100]},    // 1 - round purple crystal
            {regularDelay: 100, payment: [0, 0, 0, 4, 40, 100]},    // 2 - blue egg-like crystal
            {regularDelay: 100, payment: [0, 0, 0, 8, 60, 200]},    // 3 - red heart crystal
            {regularDelay: 100, payment: [0, 0, 0, 8, 60, 200]},    // 4 - yellow crystal
            {regularDelay: 100, payment: [0, 0, 0, 12, 80, 500]},   // 5 - ring
            {regularDelay: 100, payment: [0, 0, 0, 12, 80, 500]},   // 6 - earrings
            {regularDelay: 100, payment: [0, 0, 0, 20, 160, 1200]}, // 7 - pendant
            {regularDelay: 100, payment: [0, 0, 0, 20, 200, 2000]}, // 8 - crown
            {regularDelay: 100, payment: [0, 0, 0, 0, 0, 0]}        // 9 - chest
        ];
        this.imageResources = {
            main: this.mergePath({mainArea: 'area/main.png'}),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {atlas: this.mergePath(['regularLongSymbols.json'])};

        this.gameSounds = {soundClass: 'deluxe'};
        this.Lines = new Lines40();
        this.Gamble = new GambleDeluxe(this.mergePath({
            activeRed: 'gamble/red-active.png',
            inactiveRed: 'gamble/red-inactive.png',
            activeBlack: 'gamble/black-active.png',
            inactiveBlack: 'gamble/black-inactive.png',
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 15pt Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#ecf798';
        ctx.strokeStyle = '#000';
        ctx.shadowColor = 'black';
        ctx.shadowOffsetX = 3;
        ctx.shadowOffsetY = 3;
        ctx.shadowBlur = 5;

        switch (page) {
            case 1:
                // crown
                this.strokeFillText(ctx, bet * this.symbols[8].payment[5], 150, 185);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[4], 150, 215);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[3], 150, 245);
                // pendant
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], 675, 185);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], 675, 215);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 675, 245);
                // ring
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 405, 310);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 405, 340);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 405, 370);
                // red, yellow
                this.strokeFillText(ctx, bet * this.symbols[3].payment[5], 150, 343);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[4], 150, 373);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[3], 150, 403);
                // green, violet, blue
                this.strokeFillText(ctx, bet * this.symbols[1].payment[5], 675, 343);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[4], 675, 373);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[3], 675, 403);
                break;
            case 2:
                ctx.font = '20pt italic';
                ctx.textAlign = 'center';
                ctx.fillStyle = '#ecf798';
                ctx.strokeStyle = '#000';
                ctx.shadowColor = 'black';
                ctx.shadowOffsetX = 3;
                ctx.shadowOffsetY = 3;
                ctx.shadowBlur = 5;

                this.strokeFillText(ctx, 'substitutes for', 345, 195);
                this.strokeFillText(ctx, 'Adjacent Pays', 400, 270);
                this.strokeFillText(ctx, 'All prizes are for combinations of a adjacent symbols', 390, 430);
                this.strokeFillText(ctx, 'of a kind on selected lines', 400, 460);
                break;
            case 3:
                ctx.font = 'bold 30pt Arial';
                ctx.textAlign = 'center';
                ctx.fillStyle = '#ecf798';
                ctx.strokeStyle = '#000';
                ctx.shadowColor = 'black';
                ctx.shadowOffsetX = 3;
                ctx.shadowOffsetY = 3;
                ctx.shadowBlur = 5;
                this.strokeFillText(ctx, 'RULES', 400, 180);

                ctx.font = '20pt italic';
                this.strokeFillText(ctx, 'All prizes are for combinations of a adjacent symbols of a', 400, 240);
                this.strokeFillText(ctx, 'kind of selected lines. All prizes shown in credits.', 400, 270);
                this.strokeFillText(ctx, 'Malfunction voids all pays and plays.', 400, 300);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }
}
