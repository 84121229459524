import {JL} from 'jsnlog';

import App from './../../../index';
import GameDeluxe from './../../deluxe/game';
import Lines10 from './../../deluxe/lines10';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';

export default class PowerStars extends GameDeluxe {
    constructor() {
        super();
        this.id = 'power-stars';
        this.name = 'Power Stars';
        this.freezeReels = [];
        this.scatter = 8;
        this.reelFilter = [[8], [], [], [], [8]];
        this.buttonsPanelShadow = 'strong';
        this.reelTop = 90; // magic number - where reel images starts

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 230, y: 230},
            bonusInBonusFrame: {x: 230, y: 230},
            endBonusFrame: {x: 230, y: 230}
        };

        this.symbols = [
            {regularDelay: 100, bonusDelay: 100, payment: [0, 0, 0, 10, 20, 100]},    // 0 - cherry
            {regularDelay: 100, bonusDelay: 100, payment: [0, 0, 0, 10, 20, 100]},    // 1 - lemon
            {regularDelay: 100, bonusDelay: 100, payment: [0, 0, 0, 10, 30, 150]},    // 2 - orange
            {regularDelay: 100, bonusDelay: 100, payment: [0, 0, 0, 10, 30, 150]},    // 3 - plum
            {regularDelay: 100, bonusDelay: 100, payment: [0, 0, 0, 20, 50, 200]},    // 4 - watermelon
            {regularDelay: 100, bonusDelay: 100, payment: [0, 0, 0, 20, 50, 200]},    // 5 - grape
            {regularDelay: 100, bonusDelay: 100, payment: [0, 0, 0, 50, 200, 500]},   // 6 - bells
            {regularDelay: 100, bonusDelay: 100, payment: [0, 0, 0, 100, 500, 1000]}, // 7 - symbol '7'
            {regularDelay: 100, bonusDelay: 100, payment: [0, 0, 0, 0, 0, 0]}         // 8 - star
        ];

        this.imageResources = {
            main: this.mergePath({mainArea: `area/${App.System.resolution}/main.png`}),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({frame: 'bonus/frame.png'}),
            atlas: this.mergePath(['bonus/bonusSymbols.json'])
        };

        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [{name: 'fill-star', alias: 'fillStar'}],
            path: `/game/games/${this.id}/audio/`
        };
        this.Lines = new Lines10();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));

        this.InfoScreen = new InfoScreen({pages: 2}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '17pt franklinFont';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#FFFFFF';

        switch (page) {
            case 1:
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], 205, 128);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], 205, 158);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 205, 186);

                // scatter
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 605, 128);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 605, 158);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 605, 186);

                // watermelon
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 170, 308);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 170, 338);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 170, 366);

                // grape
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 625, 308);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 625, 338);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 625, 366);

                // plum, orange
                this.strokeFillText(ctx, bet * this.symbols[3].payment[5], 170, 477);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[4], 170, 507);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[3], 170, 536);

                // lemon, chery
                this.strokeFillText(ctx, bet * this.symbols[1].payment[5], 625, 477);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[4], 625, 507);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[3], 625, 536);
                break;
            case 2:
                ctx.font = 'italic bold 32px Arial';
                ctx.textAlign = 'center';

                ctx.lineWidth = 6;
                ctx.strokeStyle = '#ff0000';
                ctx.strokeText('RULES', 400, 210);
                ctx.lineWidth = 3;
                ctx.strokeStyle = '#000';
                ctx.strokeText('RULES', 400, 210);
                ctx.fillStyle = '#ffff00';
                ctx.fillText('RULES', 400, 210);

                ctx.font = 'bold 22px Arial';
                ctx.fillStyle = '#fff';
                ctx.fillText('All prizes are for combinations left to right and', 400, 250);
                ctx.fillText('right to left. All prizes are for combinations of a', 400, 280);
                ctx.fillText('kind. All prizes are on selected lines. Highest', 400, 310);
                ctx.fillText('prize only paid per pay direction. 5 of a kind', 400, 340);
                ctx.fillText('prizes paid only once. All prizes shown in credits.', 400, 370);
                ctx.fillText('Malfunction voids all pays and plays.', 400, 400);
                break;
        }
    }

    onRotationDone() {
        JL().debug(`-- Rotation done (fps: ${App.System.statistics.fps})`);
        const {features, extension} = this.latestResponse;
        // check if is there respin and if is this the last one.
        const lastRollRespin = extension &&
            extension.isNextRollReSpin === false;

        const payment = lastRollRespin ?
            extension.totalPayment :
            this.latestResponse.payment;

        App.updateButton('start', {disabled: true});
        this.roundWin = 0;

        if (extension && extension.isNextRollReSpin && App.restoreGameState === 'NONE') {
            // check reels for fill (is not already filled)
            const fillReels = extension.jokerReels.filter(reelIndex =>
                !this.freezeReels.includes(reelIndex));
            this.fillStars(fillReels);
        } else {
            // check feature win
            if (payment > 0 && (features.length || this.isFreeRoll(features))) {
                if (this.BigWin.isBigWin(payment)) {
                    this.BigWin.goToBigWin(payment, features);
                } else {
                    this.setState('SHOW_WIN_LINES');
                    this.startAnimateFeature(features);
                }
            } else {
                // check extension
                if (extension && extension.totalPayment) {
                    this.latestResponse.payment = extension.totalPayment;
                    App.restoreGameState !== 'NONE' && this.changeSymbolsToStars(extension.jokerReels);
                }
                this.afterBonusAction();
            }
        }
        this.freezeReels = [];
    }

    /**
     * Manually stopping win animation to get win now
     */
    speedUpWinLineAnimation = () => {
        JL().debug('-- Speed up win line animation');
        const {features, payment, extension} = this.latestResponse;

        App.updateButton('start', {
            disabled: !!extension, // disable during respins
            title: 'stop',
            handler: () => {
                JL().debug('-- Finish win line animation');
                this.Legends.setStatus('creditsWon', payment);
                this.Legends.setText('win', {text: 'win', value: payment});
                this.winLineFeatureDelay = this.defaultFeatureDelay;
                this.bonusWin = payment;
                this.endAnimateFeature();
            }
        });

        this.winLineFeatureDelay /= 5;
        this.animateFeature(features, ++this.features.step);
    };

    /**
     * Show additional fill star animation before respin
     * @param reelIndex
     */
    fillStars(reelIndex) {
        JL().debug(`-- Fill stars (reelIndex: ${reelIndex})`);
        const {features, payment, extension} = this.latestResponse;
        this.stopAnimateFeature();
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});

        // prepare array of symbol substitutes
        const fillWay = [...Array(this.reelRows)].map((item, index) => index); // [0, 1, 2]

        // check star position
        this.reelMatrix[reelIndex].forEach((row, rowIndex) => {
            if (row.symbol === 8) {
                // delete existing star pos
                fillWay.splice(fillWay.indexOf(rowIndex), 1); // example: [0, 1]
                // reverse animation way if star in last row
                [1, 2, 3].includes(rowIndex) && fillWay.reverse(); // [1, 0]
            }
        });

        // fill stars in respin reel for enable freeze roll (without substitutes)
        const respinScreen = extension.screen;
        respinScreen.forEach((reel, reelIndex) => {
            reelIndex === extension.jokerReels[0] && reel.forEach((row, rowIndex) => {
                respinScreen[reelIndex][rowIndex] = 8;
            });
        });

        // fill stars by step in 1 second
        fillWay.forEach((row, index) => {
            setTimeout(() => {
                const symbolObj = this.reelMatrix[reelIndex][row];
                symbolObj.image = 'bonus';
                symbolObj.loop = false; // play animation only once
                this.Roll.updateSymbolSprite(symbolObj);
                symbolObj.sprite.onComplete = null;
                symbolObj.sprite.play();
                App.Sounds.playSound('fillStar');

                // after last star
                index === fillWay.length - 1 && setTimeout(() => {
                    // restore symbols animation loop
                    this.reelMatrix.forEach(reel => {
                        reel.forEach(symbolObj => {
                            symbolObj.loop = true;
                            this.Roll.updateSymbolSprite(symbolObj);
                            symbolObj.sprite.gotoAndStop(0);
                        });
                    });

                    // change symbols to regular scatter
                    fillWay.forEach(row => {
                        const symbolObj = this.reelMatrix[reelIndex][row];
                        symbolObj.symbol = this.scatter;
                        symbolObj.image = 'regular';
                        this.Roll.updateSymbolSprite(symbolObj);
                    });

                    if (payment > 0 && features.length) {
                        this.setState('SHOW_WIN_LINES');
                        this.startAnimateFeature(features);
                    } else {
                        this.drawBonusAskButton(true);
                    }
                }, 1000);
            }, 1000 * index);
        });
    }

    drawBonusAskButton(isFirstBonus) {
        JL().debug(`-- Set bonus ask button (isFirstBonus: ${isFirstBonus})`);
        this.stopAnimateFeature();
        this.showPressAnyButton(false);

        this.gameFlag.bonusStart = true;
        this.roundWin && this.Legends.setText('win', {text: 'win', value: this.bonusWin});

        App.updateButton('start', {
            disabled: false,
            title: 'start',
            handler: this.respin
        });
        this.showStartBonusFrame(this.getStageChild('bonusContainer'), this.coordinatesBonusFrame.startBonusFrame);
    }

    //
    // ======================== RESPIN SECTION =============================
    //

    /**
     * Возвращает true, если на последнем прокруте выпала бонусная комбинация
     */
    isFreeRoll = features => features.find(({uc}) => uc === 'SPECIAL_SYMBOL');

    /**
     * Start scatter animations
     * @param features
     */
    bonusEntrance(features) {
        const {extension} = this.latestResponse;
        App.updateButton('start', {disabled: true});

        if (extension && extension.isNextRollReSpin) { // is respin
            this.setState('SHOW_RESPIN_FRAME');
            this.stopAnimateFeature();
            this.Legends.setText('win', {text: 'win', value: this.bonusWin});
            setTimeout(() => this.drawBonusAskButton(true), 1000);
        } else {
            this.latestResponse.payment = this.bonusWin;
            this.afterBonusAction();
        }
    }

    respin = () => {
        JL().debug(`-- Respin - ${JSON.stringify(this.latestResponse.extension.screen)}`);
        this.getStageChild('bonusContainer').removeChildren();
        this.clearPressAnyButton();
        this.Legends.setStatus('additionalRoll');
        App.updateButton('start', {disabled: true});

        this.freezeReels = this.latestResponse.extension.jokerReels;
        this.sendRoll();
    };

    /**
     * Process reels response from server.
     * @param response - Socket response 'ROLL'
     */
    processReelResponse(response) {
        this.latestResponse = response;
        const {features, payment, extension} = response;
        this.setState('RESPONSE_RECEIVED');
        this.setBonusRollSymbol(); // for bonus game roll symbol

        // don't show roll if restore with extension
        if (extension && App.restoreGameState !== 'NONE') {
            this.setPaymentDiff();
            this.Buttons.disableAllButtons();
            App.updateButton('start', {disabled: true});
            this.changeSymbolsToStars(extension.jokerReels);

            if (payment > 0 && features.length) {
                this.setState('SHOW_WIN_LINES');
                this.startAnimateFeature(features);
            } else {
                this.latestResponse.payment = extension.totalPayment;
                extension.isNextRollReSpin ?
                    this.drawBonusAskButton(true) :
                    this.afterBonusAction();
            }
        } else {
            // change screen to stars
            this.freezeReels.forEach(reelIndex => {
                for (let rowIndex = 0; rowIndex < this.reelRows; rowIndex++) {
                    this.latestResponse.screen[reelIndex][rowIndex] = this.scatter;
                }
            });
            this.prepareToRollAnimation(response);
        }
    }

    isReelFreezed = reel => this.freezeReels.includes(reel);

    changeSymbolsToStars(jokerReels) {
        jokerReels.forEach(reelIndex => {
            this.reelMatrix[reelIndex].forEach((symbolObj, rowIndex) => {
                symbolObj.symbol = this.scatter;
                this.Roll.updateSymbolSprite(symbolObj);
                this.lastScreen[reelIndex][rowIndex] = this.scatter;
            });
        });
    }

    setPaymentDiff() {
        const {payment, extension} = this.latestResponse;
        if (extension && extension.totalPayment !== payment) {
            const winDiff = extension.totalPayment - payment;
            this.latestResponse.payment = this.bonusWin = winDiff;
        }
    }
}
