import {JL} from 'jsnlog';
import Lines from './../../lines';

export default class Lines25 extends Lines {
    constructor() {
        super();

        this.lines = {
            0: {coordinates: [1, 1, 1, 1, 1], boxes: [{x: 1, y: 94}]},
            1: {coordinates: [0, 0, 0, 0, 0], boxes: [{x: 1, y: 213}]},
            2: {coordinates: [2, 2, 2, 2, 2], boxes: [{x: 1, y: 335}]},
            3: {coordinates: [0, 1, 2, 1, 0], boxes: [{x: 1, y: 459}]},
            4: {coordinates: [2, 1, 0, 1, 2], boxes: [{x: 772, y: 138}]}
        };

        JL().debug('-- Lines25 initialized');
    }

    drawWinBox = () => {

    };
}
