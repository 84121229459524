import * as PIXI from 'pixi.js-legacy';
import App from './../../../index';
import Gamble from './../sea-treasure/gamble';

import InfoScreen from './../../infoScreen';
import LuckyRanch from '../lucky-ranch/game';
import Lines from './lines';
import {TweenService} from './../../services/tweenService';
import langs from '../lucky-ranch/langs';
import numFont from '../lucky-ranch/img/font/numFont';
import {JL} from 'jsnlog';

const AnimatedSprite = PIXI.AnimatedSprite,
    Container = PIXI.Container,
    Sprite = PIXI.Sprite,
    Text = PIXI.Text,
    Graphics = PIXI.Graphics;

export default class VegasMagic extends LuckyRanch {
    constructor() {
        super();
        this.id = 'vegas-magic';
        this.name = 'Vegas Magic';
        this.scatter = 10;
        this.introSound = false;

        this.reelTop = 103;
        this.symbolWidth = 154;
        this.symbolHeight = 150;

        this.SymbolInfo.enabled = false;
        this.reelFilter = [[9], [], [], [], [9]];

        this.reelXCoordinates = [59, 235, 410, 585, 760];
        this.additionalSpineImages = 8;
        this.offsetReelMask = {
            offsetX: 11, offsetY: 0,
            offsetWidth: 48, offsetHeight: 5
        };
        this.tweenService = new TweenService();
        this.symbols = [
            {regularDelay: 50, payment: [0, 0, 0, 5, 15, 50]},                 // 0 - бубна
            {regularDelay: 50, payment: [0, 0, 0, 5, 15, 50]},                 // 1 - чирва

            {regularDelay: 50, payment: [0, 0, 0, 5, 25, 75]},                 // 2 - треф
            {regularDelay: 50, payment: [0, 0, 0, 5, 25, 75]},                 // 3 - пика

            {regularDelay: 50, payment: [0, 0, 0, 10, 50, 150]},               // 4 - кольца
            {regularDelay: 50, payment: [0, 0, 0, 10, 50, 150]},               // 5 - шляпа

            {regularDelay: 50, payment: [0, 0, 0, 20, 75, 250]},               // 6 - голубь
            {regularDelay: 50, offsetX: 5, payment: [0, 0, 0, 20, 75, 250]},   // 7 - тигр

            {regularDelay: 50, offsetX: 6, payment: [0, 0, 5, 25, 150, 3000]}, // 8 - девушка

            {regularDelay: 50, offsetX: 7, payment: [0, 0, 0, 0, 0, 0]},       // 9 - фокусник
            {regularDelay: 50, offsetX: 2, offsetY: 25, payment: [0, 0, 0, 5, 50, 500]} // 10 - $
        ];

        // use to increase coins when bonus is possible
        this.allowAnimation = {
            1: false,
            2: false,
            3: false
        };

        this.imageResources = {
            main: this.mergePath({
                introButton: `area/introButton.png`
            }),
            jsonAnimations: this.mergePath({
                bgSpine: 'area/BackGround.json',
                bang: `bang.json`
            }),
            atlas: this.mergePath([
                'staticSymbols.json',
                'additionalSymbols1.json'
            ])
        };

        this.containersLayers = {
            reelsStage: 1,
            borderContainer: 3,
            mainContainer: 0,
            linesContainer: 6,
            boxesContainer: 4,
            extraBetContainer: 5,
            infoContainer: 11,
            bonusContainer: 7,
            symbolInfo: 8,
            waitingContainer: 10
        };
        this.Gamble = new Gamble();
        this.gameSounds = {soundClass: 'prefergames'};
        this.Lines = new Lines();
        this.BigWin.enabled = true;

        this.additionalResources = {
            main: this.mergePath({})
        };

        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [
                {name: 'reelsstop'},
                {name: 'background_idle', loop: true},
                {name: 'sound_fx', loop: true},
                {name: 'background', loop: true},
                {name: 'add-credit-background', loop: 'true'},
                {name: 'expand_wild_1'},
                {name: 'expand_wild_2'},
                {name: 'expand_wild_3'},
                {name: 'ony-by-one-features'},
                {name: 'add-credit-background', loop: 'true'},
                {name: 'scatterStop1'},
                {name: 'scatterStop2'},
                {name: 'scatterSound'},
                {name: 'scatterSound2'},
                {name: 'startClick'},
                {name: 'bang'},
                {name: 'joker_change'},
                {name: 'refil'},
                {name: 'oh'},
                {name: 'win-line'}
            ],
            path: `/game/games/${this.id}/audio/`
        };
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
        this.InfoScreen.format = 'png';
        this.InfoScreen.getResources = () => this.mergePath({
            info1: 'area/info1-screen.png',
            info2: 'area/info2-screen.png',
            info3: 'area/info3-screen.png'
        });
    };

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '10pt Arial bolder';
        ctx.textAlign = 'left';
        ctx.fillStyle = 'white';

        switch (page) {
            case 1:
                // бубна
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 776, 196);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 776, 209);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 776, 222);

                // чирва
                this.strokeFillText(ctx, bet * this.symbols[1].payment[5], 636, 196);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[4], 636, 209);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[3], 636, 222);

                // треф
                this.strokeFillText(ctx, bet * this.symbols[2].payment[5], 506, 196);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[4], 506, 209);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[3], 506, 222);

                // пика
                this.strokeFillText(ctx, bet * this.symbols[2].payment[5], 376, 196);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[4], 376, 209);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[3], 376, 222);

                // свиток
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 246, 196);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 246, 209);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 246, 222);

                // Шляпа
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 748, 125);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 748, 138);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 748, 151);

                // Голубь
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 592, 125);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 592, 138);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 592, 151);

                // Тигр
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 442, 125);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 442, 138);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 442, 151);

                // Девушка
                this.strokeFillText(ctx, bet * this.symbols[8].payment[5], 283, 119);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[4], 283, 132);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[3], 283, 145);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[2], 283, 157);

                // $$$
                this.strokeFillText(ctx, nLines * bet * this.symbols[10].payment[5], 295, 361);
                this.strokeFillText(ctx, nLines * bet * this.symbols[10].payment[4], 295, 374);
                this.strokeFillText(ctx, nLines * bet * this.symbols[10].payment[3], 295, 387);
        }
    }

    onInfoStartOpen() {

    }

    onInfoStartClose() {

    }

    /**
     * Animate infoContainer, slide down and update state
     */
    startInfoAnimation() {
        const reelsContainer = this.getStageChild('reelsStage');
        reelsContainer.mask = this.getReelsMask();
        const open = () => {
            this.createInfoContainer(this.getStage());
            const infoContainer = this.getStageChild('infoContainer');
            infoContainer.x = -184;
            const infoMask = this.getReelsMask('infoMask');
            infoContainer.mask = infoMask;
            infoMask.scale.set(1.01, 1.35);
            infoMask.y = -40;
            infoMask.x = -4;
            this.showAnimation({
                duration: 500,
                animations: [{sprite: infoContainer, timeline: [{from: {y: 900}, to: {y: 42}}]}],
                onComplete: () => {
                    JL().debug('-- InfoScreen opened');
                    this.setState('INFO');
                    this.InfoScreen.checkInfoButtons();
                    App.updateButton('close', {disabled: false, handler: this.InfoScreen.close});
                }
            });
            App.updateState('buttons', {animation: 'hide-panel'});
            this.onInfoStartOpen();
        };

        this.checkLoadedResources(open);
    }

    /**
     * Start game animations
     */
    showGameIntroduction() {
        const ticker = this.app.ticker;
        this.setState('GAME_INTRO');
        this.Buttons.disableAllButtons();
        App.updateButton('autoStart', {disabled: true});
        App.updateState('buttons', {animation: 'hide'});
        const parentContainer = this.getStageChild('bonusContainer');
        parentContainer.sortableChildren = true;
        parentContainer.interactive = true;
        const introSpine = new window.PIXI.spine.Spine(this.getJsonTextures('bgSpine'));
        introSpine.name = 'introSpine';
        introSpine.position.set(-(App.Game.gameWidth - App.Game.gameFieldWidth) / 2, 0);
        introSpine.state.setAnimation(0, 'Intro_BG_animation', true);

        parentContainer.addChild(introSpine);

        const sprite2 = new Sprite(this.getTexture('introButton'));
        sprite2.name = 'introButton';
        sprite2.position.set(461, 558);
        sprite2.zIndex = 20;
        sprite2.interactive = true;
        parentContainer.addChild(sprite2);

        sprite2.on('pointerdown', () => {
            this.playIdleBackgroundSound();
            parentContainer.removeChild(introSpine);
            parentContainer.removeChild(sprite2);
            App.updateState('buttons', {animation: 'show'});
            this.roundFinished();
            const ticker = this.app.ticker;
            ticker.remove(func);
        });
        let way = 0;
        sprite2.anchor.set(0.5);

        const func = () => {
            if (sprite2.scale.x >= 1.1 && !way) {
                way = 1;
            }
            if (sprite2.scale.x <= 1 && way) {
                way = 0;
            }
            if (!way) {
                sprite2.scale.x += 0.005;
                sprite2.scale.y += 0.005;
            } else {
                sprite2.scale.x -= 0.005;
                sprite2.scale.y -= 0.005;
            }
        };
        ticker.add(func);
    }

    /**
     * Create PIXI.Container for game background
     * Add additional sprites to container
     * @param parentContainer
     */
    createMainContainer(parentContainer) {
        const container = new Container();
        container.name = 'mainContainer';
        container.zIndex = this.containersLayers[container.name];
        parentContainer.addChild(container);

        const backgroundSpine = new window.PIXI.spine.Spine(this.getJsonTextures('bgSpine'));
        backgroundSpine.name = 'backgroundSpine';
        backgroundSpine.position.set(-(App.Game.gameWidth - App.Game.gameFieldWidth) / 2, 0);
        backgroundSpine.state.setAnimation(0, 'GameFrame_static', false);
        backgroundSpine.state.setAnimation(1, 'Background_animation', true);
        backgroundSpine.state.setAnimation(2, 'Logo_Animation', true);
        this.backgroundSpine = backgroundSpine;
        this.getStageChild('reelsStage').mask = this.getReelsMask();

        container.addChild(backgroundSpine);
    }

    /**
     * Create PIXI.Container for game bonus animations
     * Add additional sprites to container
     * @param parentContainer
     */
    createBonusContainer = parentContainer => {
        const container = new Container();
        container.name = 'bonusContainer';
        container.interactiveChildren = true;
        container.zIndex = this.containersLayers[container.name];
        parentContainer.addChild(container);

        container.interactive = false;  // add multiplier spine on game load
        container.removeChildren();
        this.multiplierSpine = new window.PIXI.spine.Spine(this.getJsonTextures('bgSpine'));
        this.multiplierSpine.name = 'multiplierSpine';
        container.addChild(this.multiplierSpine);
    };

    addWaitingAnimationSprites(parentContainer) {
        /*        // prevent secondary enter in this function
                if (!parentContainer.getChildByName('multiplier')) {
                    const multiplier = new window.PIXI.spine.Spine(this.getJsonTextures('multiplier'));
                    multiplier.name = 'multiplier';
                    multiplier.position.set(905, 109);
                    parentContainer.addChild(multiplier);

                    const textContainer = new Container();
                    textContainer.name = 'textContainer';
                    multiplier.addChild(textContainer);

                    this.drawMultiplierText(1);

                    const numberBone = multiplier.skeleton.findBone('Multiplier_NUM');
                    const multiplierBone = multiplier.skeleton.findBone('Multiplier');

                    this.app.ticker.add(() => {
                        textContainer.position.set(numberBone.worldX, numberBone.worldY);
                        textContainer.rotation = (-multiplierBone.arotation - 90) * 0.02;
                        textContainer.scale.set(numberBone.scaleX, numberBone.scaleY);
                    });
                } */
    }

    additionalPreparingToAnimateFeature() {
        /*        const borderContainer = this.getStageChild('borderContainer');
        const borderSpine = borderContainer.getChildByName('borderSpine');
        borderSpine.state.setAnimation(0, 'Win', false); */
    }

    refill() {
        const mainContainer = this.getStageChild('mainContainer');
        const backgroundSpine = mainContainer.getChildByName('backgroundSpine');
        backgroundSpine.state.setAnimation(0, 'GameFrame_animation', true);
        this.multiplierSpine.visible = false;

        App.Sounds.playSound('bang');
        this.deletedSymbols.forEach((reel, reelIndex) => {
            reel.forEach((symbol, rowIndex) => {
                if (symbol === -1) {
                    const symbolObj = this.reelMatrix[reelIndex][rowIndex];
                    const {symbolContainer, sprite} = symbolObj;
                    // create bang animations
                    const bangSprite = new AnimatedSprite(this.getJsonTextures('bang'));
                    bangSprite.scale.set(0.8);
                    bangSprite.anchor.set(0.5);
                    bangSprite.name = symbolContainer.name;
                    bangSprite.position.set(this.symbolWidth / 2, this.symbolHeight / 2);
                    bangSprite.zIndex = 3;
                    bangSprite.animationSpeed = 0.5;
                    bangSprite.loop = false;
                    bangSprite.play();
                    symbolContainer.zIndex = 16;
                    bangSprite.onComplete = () => {
                        bangSprite.destroy();
                        symbolContainer.destroy();
                    };

                    symbolContainer.addChild(bangSprite);
                    // let symbol disappear
                    this.tweenService.tweenTo(sprite, 0.5, {alpha: 0});
                }
            });
        });

        // deleting win symbols from this.reelMatrix
        this.reelMatrix = this.reelMatrix.map((reel, reelIndex) =>
            reel.filter((item, rowIndex) => this.deletedSymbols[reelIndex][rowIndex] !== -1));
        this.prepareNewSprites(this.symbolToRefill);

        this.tickerTimeout(() => this.letSymbolDown(), 1300);
    }

    letSymbolDown() {
        App.Sounds.playSound('oh');
        this.playRefilSound();
        let tweenMoved = null;
        const movedReels = [];
        this.reelMatrix.forEach((reel, reelIndex) => {
            reel.forEach((symbolObj, rowIndex) => {
                if (symbolObj.symbolContainer.y !== rowIndex * this.symbolHeight && !movedReels.includes(reelIndex)) {
                    movedReels.push(
                        reelIndex
                    );
                }
            });
        });

        movedReels.forEach((reel, index) => {
            tweenMoved = this.tweenService.delayCall(index * 0.3, () => {
                this.reelMatrix[reel].forEach((symbolObj, rowIndex) => {
                    const offsetY = this.symbols[symbolObj.symbol].offsetY || 0;

                    if (symbolObj.symbolContainer.y !== rowIndex * this.symbolHeight - offsetY) {
                        this.tweenService.tweenTo(
                            symbolObj.symbolContainer,
                            0.2,
                            {
                                y: rowIndex * this.symbolHeight + 35,
                                onComplete: () => {
                                    this.tweenService.tweenTo(
                                        symbolObj.symbolContainer,
                                        0.2,
                                        {
                                            y: rowIndex * this.symbolHeight - offsetY,
                                            onComplete: () => symbolObj.position.y = rowIndex * this.symbolHeight
                                        });
                                }

                            }
                        );
                    }
                });
            });
        });
        this.tweenService.delayCall(movedReels.length * 0.5, () => {
            this.tweenService.killTween(tweenMoved);
            // reset to default refill settings
            this.symbolToRefill = [...Array(5)].map(() => []);
            this.deletedSymbols = [...Array(5)].map(() => [...Array(3)]);
            this.afterRefillActions();
        });
    }

    afterRefillActions() {
        const mainContainer = this.getStageChild('mainContainer');
        const backgroundSpine = mainContainer.getChildByName('backgroundSpine');
        backgroundSpine.state.getCurrent(0).loop = false;

        // if extension exist redefine latestResponse
        if (this.latestResponse.extension) {
            this.latestResponse.extension.refill.payment = this.latestResponse.payment;
            this.latestResponse = this.latestResponse.extension.refill;
            this.lastScreen = this.latestResponse.screen;
        }

        const {features, payment, extension} = this.latestResponse;

        const isWinLine = this.latestResponse.features?.some(features => features.uc === 'WIN_LINE' || features.uc === 'SCATTER');

        if (isWinLine && this.latestResponse.extension) {
            const {features, extension: {multiplier}} = this.latestResponse;
            this.multiplierSpine.visible = true;
            this.multiplierSpine.position.set(-150, -50);
            this.multiplierSpine.state.setAnimation(0, `Mx${multiplier}`, false);

            this.getSymbolsToRefill();

            if (this.getJoker(extension)) {
                this.fillJoker(extension.joker, features);
            } else {
                this.setState('SHOW_WIN_LINES');
                this.startAnimateFeature(features);
            }
        } else {
            // check action when there no winLine
            this.multiplierSpine.visible = false;
            if (this.isFreeRoll(this.latestResponse.features)) {
                this.bonusEntrance(this.latestResponse.features);
            } else if (this.isBonus()) {
                if (this.bonusStatus && this.bonusStatus.remain > 0) {
                    this.roundFinished(false);
                } else {
                    this.Legends.setRoundFinText();
                    this.finishBonus();
                }
            } else {
                this.takeWin();
            }
        }
    }

    getJoker(extension) {
        let joker = false;
        if (extension) {
            if (extension.joker) {
                joker = true;
            }
        }
        return joker;
    }

    // add fill joker funtion before win line animation

    onRotationDone() {
        JL().debug(`-- Rotation done (fps: ${App.System.statistics.fps})`);
        const {features, payment, extension} = this.latestResponse;
        App.updateButton('start', {disabled: true});
        this.roundWin = 0;
        if (payment > 0 || this.isFreeRoll(features) || features.length) {
            // There is a win
            this.setState('SHOW_WIN_LINES');

            // extension.joker = [{row: 1, reel: 2}, {row: 2, reel: 2}];

            if (this.getJoker(extension)) {
                this.fillJoker(extension.joker, features);
            } else {
                this.startAnimateFeature(features);
            }
        } else {
            if (this.isBonus()) {
                if (this.bonusStatus && this.bonusStatus.remain > 0) {
                    this.roundFinished(false);
                } else {
                    this.Legends.setRoundFinText();
                    this.finishBonus();
                }
            } else {
                this.roundFinished();
                this.Legends.setRoundFinText();
            }
        }
    }

    /**
     * Show additional fill star animation before respin
     * @param features
     * @param joker
     */
    fillJoker(joker, features) {
        let index = 0;
        App.Sounds.playSound('joker_change');
        joker.forEach((object) => {
            index++;
            setTimeout(() => {
                const symbolObj = this.reelMatrix[object.reel][object.row];
                const {symbolContainer, sprite} = symbolObj;

                // create bang animations
                const bangSprite = new AnimatedSprite(this.getJsonTextures('bang'));
                bangSprite.scale.set(0.8);
                bangSprite.anchor.set(0.5);
                bangSprite.name = symbolContainer.name;
                bangSprite.position.set(this.symbolWidth / 2, this.symbolHeight / 2);
                bangSprite.zIndex = 3;
                bangSprite.animationSpeed = 0.5;
                bangSprite.loop = false;
                bangSprite.play();
                symbolContainer.zIndex = 16;
                bangSprite.onComplete = () => {
                    bangSprite.destroy();
                };

                symbolContainer.addChild(bangSprite);
                // let symbol disappear
                this.tweenService.tweenTo(sprite, 0.5, {alpha: 0});
                this.showAnimation({
                    duration: 500, animations: [
                        {sprite, timeline: [{to: {alpha: 0}}]}
                    ]
                });

                setTimeout(() => {
                    symbolObj.symbol = 9;
                    this.tweenService.tweenTo(sprite, 0.5, {alpha: 1});
                    this.Roll.updateSymbolSprite(symbolObj);
                }, 300);
            }, 200 * index);
        });

        setTimeout(() => {
            this.startAnimateFeature(features);
        }, 1500);
    }

    setRegularShortSprite(clipMatrix, reelIndex, textures) {
        for (let i = 0; i < this.reelRows; i++) {
            const symbolObj = clipMatrix[reelIndex][i + 1];
            if (symbolObj.symbol === 9) {
                App.Sounds.playSound('scatterStop2');

                setTimeout(() => {
                    symbolObj.image = 'additional';
                    symbolObj.sprite.textures = this.Roll.textures['additional'][symbolObj.symbol];
                    symbolObj.sprite.loop = false;
                    symbolObj.sprite.play();
                }, 300);
            }

            if (symbolObj.symbol === this.scatter) {
                if (!this.gameFlag.bonusStarted) {
                    if (reelIndex === 1) {
                        this.allowAnimation[reelIndex] = true;
                        App.Sounds.playSound('scatterStop1');
                        // symbolObj.image = 'additional';
                        // symbolObj.sprite.textures = this.Roll.textures['additional'][symbolObj.symbol];
                        //  symbolObj.sprite.loop = false;
                        //  symbolObj.sprite.play();
                    } else if (this.allowAnimation[reelIndex - 1] === true) {
                        this.allowAnimation[reelIndex] = true;
                        App.Sounds.playSound('scatterStop1');
                        //  symbolObj.image = 'additional';
                        //   symbolObj.sprite.textures = this.Roll.textures['additional'][symbolObj.symbol];
                        //   symbolObj.sprite.loop = false;
                        //  symbolObj.sprite.play();
                    }
                }
            }
        }
    }

    playIdleBackgroundSound = () => {
        this.introSound = true;
        App.Sounds.playSound('background_idle');
        App.Sounds.playSound('sound_fx');
    };

    playRollSound = () => {
    };

    playRefilSound = () => {
        App.Sounds.playSound('refil');
    };

    /**
     * Function to play start button sound
     */
    playStartClickSound() {
        App.Sounds.playSound('startClick');
    }

    /**
     * Send uc: 'ROLL' message by WebSocket
     */
    sendRoll() {
        this.setState('WAITING_RESPONSE');
        App.System.sendMetric({param: `rollCount.${this.id}`});
        App.System.sendMetric({param: `rollPlatform.${App.System.platform}`});
        App.System.sendMetric({param: `rollOrientation.${App.Wrapper.getOrientation()}`});

        if (this.introSound) {
            this.introSound = false;
            App.Sounds.stopSound('background_idle');
            this.playBackgroundSound();
        }

        const message = {
            uc: 'ROLL',
            bet: this.gameSettings.getBetLineCredit(),
            lines: this.gameSettings.getLinesNumber(),
            denomination: App.Money.getCurrentDenomination()
        };
        this.extraBet && (message.extraBet = this.extraBetActive);

        App.Socket.send(JSON.stringify(message));
    }

    takeWin = (isTransfer = true) => {
        this.stopWaitingAnimation();
        this.stopAnimateSound();
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        JL().debug(`-- Take win (isTransfer: ${isTransfer})`);
        this.setState('TAKE_WIN');

        if (isTransfer && App.restoreGameState !== 'TRANSFER') {
            App.Socket.send(JSON.stringify({uc: 'TRANSFER-START'}));
        }
        !isTransfer && this.animateCredits(this.latestResponse.payment, isTransfer);
        App.Sounds.playSound('add-credit-background');

        this.Buttons.disableAllButtons();
        App.updateButton('start', {
            disabled: false,
            title: 'collect',
            handler: () => { // User can press 'start' twice for increasing transfer speed
                this.isSpeedUp !== 2 && this.isSpeedUp++;
                JL().debug(`-- Take win speed up x${this.isSpeedUp + 1}`);
                // TODO after repeat click -> take all win immediately
            }
        });
    };

    /**
     * Function to play animate credit sound
     */
    playCreditSound() {
    }

    checkBonusFeatures(response = this.latestResponse) {
        const isBonusFeature = response.features.some(features => features.uc === 'SCATTER');
        let bonusFeatures = [];
        if (isBonusFeature) {
            const indexes = [];
            bonusFeatures = response.features.filter((feature, i) => {
                if (feature.uc === 'FREE_ROLL') {
                    indexes.push(i);
                    return true;
                }
            });
            indexes.reverse().forEach(value => response.features.splice(value, 1));

            return response.extension ? this.checkBonusFeatures(response.extension.refill) :
                bonusFeatures.forEach(feature => response.features.push(feature));
        }
    }

    /**
     * Comparing root screen and refill screen and define symbols that have to refill
     * this.symbolToRefill - here we store symbols to refill
     * this.deletedSymbols - marking symbols that have to be destroyed by -1
     */
    getSymbolsToRefill() {
        this.getStageChild('reelsStage').mask = this.getReelsMask();
        const {features, extension} = this.latestResponse;
        let screen = this.latestResponse.screen.map(reel => reel.map(item => item));

        const newScreen = extension.refill.screen;

        features.forEach(feature => {
            if (feature.uc === 'WIN_LINE') {
                feature.reels.forEach(reelIndex => {
                    const rowIndex = this.Lines.lines[feature.number].coordinates[reelIndex];
                    screen[reelIndex][rowIndex] = -1;
                    this.deletedSymbols[reelIndex][rowIndex] = -1;
                });
            }

            if (feature.uc === 'SCATTER') {
                feature.positions.forEach(position => {
                    screen[position.reel][position.row] = -1;
                    this.deletedSymbols[position.reel][position.row] = -1;
                });
            }
        });

        screen = screen.map(reel => reel.filter(item => item !== -1));
        screen.forEach((reel, reelIndex) => {
            const screenLength = screen[reelIndex].length;
            const newScreenLength = newScreen[reelIndex].length;
            const different = newScreenLength - screenLength;

            for (let i = different - 1; i >= 0; i--) {
                screen[reelIndex].push(newScreen[reelIndex][i]);
                this.symbolToRefill[reelIndex].push(newScreen[reelIndex][i]);
            }
        });
    }

    /**
     * Function to stop animate credit sound
     */
    stopCreditSound = () => {
        App.Sounds.stopSound('add-credit-background');
    };

    /*        movedSprites.forEach((props, i) => {
                    const {symbolObj} = props;
                    this.showAnimation({
                        duration: 300, animations: [
                            {
                                sprite: symbolObj.symbolContainer,
                                timeline: [{to: {y: props.rowIndex * this.symbolHeight}}]
                            }
                        ],
                        onComplete: () => {
                            symbolObj.position.y = props.rowIndex * this.symbolHeight;
                            if (movedSprites.length - 1 === i) {
                                // this.bounceSprites(movedSprites);
                            }
                        }
                    });
                }); */
}
