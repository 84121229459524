module.exports = {
    // socket
    connectionServer: 'Verbindung zum Server',
    initializingGame: 'Spiel Laden',
    initializingMenu: 'Menü Laden',
    loadingImages: 'Bilder Laden',
    reload: 'Neustarten',
    redirect: 'Übergang',
    exitAndReload: 'Gehen zum Menü und neu laden Seite',
    exit: 'Aussteigen',
    exitAfter: 'Aussteigen aus dem System nach',
    serverNotResponded: 'Server antwortet nicht',
    pluginNotResponded: 'Keine Verbindung zum Controller',
    codeAlreadyConnected: 'Ein Spieler mit diesem Code ist bereits verbunden',
    connectionLost: 'Verbindung abgebrochen',
    gameNotFound: 'Spiel nicht gefunden',
    codeNotFound: 'Сode nicht gefunden',
    cardNotFound: 'Karte nicht gefunden',
    terminalNotFound: 'Terminalcode wird nicht gefunden oder Terminal ist nicht verfügbar',

    // login
    login: 'Einloggen',
    loginCredentials: 'Benutzername, E-Mail oder Code',
    codeCredentials: 'Konto Code',
    username: 'Nutzername',
    enterCode: 'Code eingeben',
    password: 'Passwort',
    email: 'Email',
    signIn: 'Betreten',
    signUp: 'Anmelden',
    registration: 'Registrierung',
    oneClick: 'Mit einem Klick',
    demo: 'Demo',
    createAccount: 'Konto erstellen',
    logout: 'Ausloggen',
    restorePasswordWithEmail: 'Gib deine Email ein, um nach deinem Passwort zu wiederherstellen.',
    sendNewPassword: 'Neues Passwort senden',
    restorePassword: 'Passwort wiederherstellen',
    restoringPassword: 'Passwort Wiederherstellung',
    successRegistration: 'Erfolgreiche Registrierung',
    incorrectUsername: 'Falscher Benutzername oder falsches Passwort',
    notUniqueName: 'Der Name muss eindeutig sein',
    notUniqueEmail: 'E-Mail muss eindeutig sein',
    notUniquePhone: 'Die Telefonnummer sollte eindeutig sein',
    missedField: 'Erforderliches Feld verpasst',
    wrongOldPassword: 'Altes Passwort falsch eingegeben',
    wrongPassword: 'Passwort falsch eingegeben',
    emailChanged: 'Die Bestätigung wurde an Ihre E-Mail gesendet',
    passwordResetError: 'Falscher Benutzername oder falsche E-Mail-Adresse',
    newPasswordSent: 'Ein neues Passwort wurde an Ihre E-Mail gesendet',
    invalidEmail: 'Ungültige E-Mail',
    invalidPhone: 'Ungültige phone \n ' +
        '+38 YYY XXX XX XX',
    enterPassword: 'Passwort eingeben',
    differentPasswords: 'Die Passwörter stimmen nicht überein',
    tooLongPassword: 'Zu langes Passwort (max. Zeichen: 20)',
    tooShortPassword: 'Zu kurzes Passwort (min Zeichen: 8)',
    passwordChanged: 'Passwort geändert',
    enterOldPassword: 'Geben Sie das alte Passwort ein',
    enterNewPassword: 'Geben Sie das neues Passwort ein',
    samePasswords: 'Altes und neues Passwort können nicht identisch sein',
    copied: 'Kopiert',
    copy: 'Kopieren',

    // profile
    save: 'Sparen',
    confirmation: 'Bestätigen Sie',
    saveAs: 'Speichern als',
    cancel: 'Stornieren',
    changingEmail: 'Email ändern',
    changeEmail: 'Email ändern',
    changingPhone: 'Phone ändern',
    changePhone: 'Phone ändern',
    changingPassword: 'Passwort ändern',
    changePassword: 'Ändere das Passwort',
    confirmCode: 'Bestätigungscode',
    phoneConfirmed: 'Telefon bestätigt',
    oldPassword: 'Altes Passwort',
    newPassword: 'Nouveau mot de passe',
    confirmPassword: 'Bestätige das Passwort',
    connectWithUs: 'Verbinde dich mit uns',
    profile: 'Profil',
    deposit: 'Deposit',
    withdraw: 'Abheben',
    support: 'Unterstützung',
    date: 'Datum',
    type: 'Type',
    amount: 'Menge',
    status: 'Status',
    transactionsHistory: 'Transaktionsverlauf',
    exchange: 'Geldwechsel',
    noData: 'keine Daten',
    transactionsBonus: 'Nachrichtenverlauf',
    message: 'Botschaft',
    ok: 'Ok',

    currencySelect: 'Währung',
    paymentSelect: 'Bezahlverfahren',
    amountDepositSelect: 'Einzahlungsbetrag',
    finishOperation: 'Endbearbeitungsvorgang',

    enterCurrency: 'Währung wählen',
    enterCardNumber: 'Kartennummer eingeben',
    cardNumber: 'Kartennummer',
    paymentAccept: 'Zahlungsbestätigung',
    payoutAmount: 'Zahlungsbetrag',
    paymentType: 'Bezahlverfahren',
    enterDepositWay: 'Wählen Sie eine Zahlungsmethode',
    enterDepositAmount: 'Geben Sie den abzuhebenden Betrag ein',
    enterWithdrawalWay: 'Wählen Sie Methode zurückziehen',
    enterPayoutAmount: 'Geben Sie den Auszahlungsbetrag ein',
    successfulPayment: 'Ihre Zahlung wurde erfolgreich zur Verarbeitung hinzugefügt',
    successOperation: 'Erfolgreiche Zahlung',
    errorOperation: 'Zahlungsfehler',
    confirm: 'Bestätigen',
    country: 'Land',
    currency: 'Währung',
    invalidCountry: 'Ungültiges Land',
    invalidCurrency: 'Ungültige Währung',
    successfullyChangedTo: 'Erfolgreich geändert zu',
    lowBalance: 'Nicht genug Geld auf dem Kontostand',

    accept: 'Akzeptieren',
    decline: 'Ablehnen',
    currencyExchange: 'Geldwechsel',
    currentBalance: 'Aktueller Saldo',
    from: 'von',
    to: 'zu',
    exchangePercent: 'Prozent austauschen',
    newBalance: 'Neues Gleichgewicht',
    coefficient: 'Koeffizient',
    currencyConversion: 'Währungsumrechnung',
    wrongInputData: 'Falsche Eingabedaten',
    wrongAmountData: 'Falscher Betrag',
    wrongCreditCard: 'Falsche Kreditkartennummer',
    noRedirectReff: 'Es gibt keinen Hinweis auf die Umleitung',
    deserializationIssue: 'Problem mit der Deserialisierung',
    currencyPairNotExist: 'Währungspaar existiert nicht',
    authorizationMandatory: 'Die Autorisierung ist obligatorisch',
    birthdayFormat: 'Geburtstag sollte TT MM JJJJ sein',
    tooShort: 'Falsche Kartennummer',
    infoPayment: 'Die Bearbeitung der ersten Zahlung innerhalb von 24 Stunden ist kostenlos. Für jede weitere Zahlung wird eine Provision berechnet.',
    time: 'Zeit',
    period: 'Bis zu 3 Tage',
    image: 'Bild',
    text: 'Text',

    // status
    balance: 'Balance',
    insurance: 'Versicherung',
    credits: 'credite',
    creditsWon: 'credite gewinnen',
    winnerPaid: 'Auszahlung Gewinne',
    gotNoMoney: 'Nicht genug Geld',
    minBet: 'Minimal Gesamteinsatz',
    gambleUp: 'Gamble bis oder gewinnen',
    gambleUp5x: 'Gamble bis zu 5x oder gewinnen',
    gambleEnd: 'Versuch ist vorbei',
    pressAnyButton: 'Drücken Start für Fortsetzung',
    freeGame: 'Freie Spiel',
    of: 'von',
    freeSpinsRemaining: 'FREIE SPINS BLEIBEN ',
    freeGames: 'gratis Spiele',
    more: 'zusätzlich ',
    freeSpinsLeft: 'FREE SPINS UBRIG ',
    additionalRoll: 'Extra Spin',
    selectCard: 'Wählen Sie ihre Karte',
    selectColor: 'Wählen Sie rot oder schwarz',
    selectColorOrSuit: 'Wählen Sie Farbe (x2) oder Anzug (x4)',
    gameOverPlaceYourBet: 'Bitte deine Wette',
    auto: 'Auto',
    linePays: 'Linie',
    scatterPays: 'pro Scatter',
    win: 'Gewinn',
    line: 'Linie',
    youWon: 'Sie gewannen',
    lowMinBet: 'Minimal Einsatz auf ',
    highMaxBet: 'Maximal Einsatz auf ',
    unSupport: 'Dieses Spiel ist auf Ihrem Gerät nicht unterstützt.',
    urlNotFound: 'Seite nicht gefunden',
    categories: 'Kategorien',
    chooseSide: 'Wähle eine Seite',
    makeChoice: 'Treffen Sie eine Wahl',
    multiplier: 'Multiplikator',

    // buttons
    lines: 'Linien',
    bet: 'Einsatz',
    total: 'Gesamt',
    denom: 'Denom',
    gamble: 'Risiko',
    paytable: 'Die Info',
    bonus: 'Bonus',
    start: 'Start',
    stop: 'Stop',
    autoplay: 'Auto-Spiel',
    select: 'Auswahl',
    collect: 'Nehmen',
    red: 'Rot',
    black: 'Schwarz',
    return: 'wiederkommen',
    half: 'Hälfte',
    payout: 'Auszahlung',
    swipeToStart: 'Wischen Sie nach oben, um zu beginnen',
    tapToStart: 'Tippe, um zu starten',

    // roulette buttons
    repeatAndStart: 'Einsatz wiederholen  und drehen',
    double: 'Verdoppeln',
    doubleAndSpin: 'Verdoppeln und drehen',
    undo: 'Zurückpfeifen',
    repeatBet: 'Erneut Einsatz',
    removeBets: 'Einsatz entfernen',
    results: 'Ergebnisse',
    cover: 'Deckung',

    // gamble
    gambleAmount: 'Gewinn',
    gambleToWin: 'Riskieren für gewinnen',
    gambleAmountAmatic: 'Risiko',
    gambleToWinAmatic: 'Chance nutzen Schwarz/Rot',
    suitGambleToWinAmatic: 'Anzugauswahl',
    previousCards: 'Vorherige Karten',
    gambleAttemptsLeft: 'Versuche verbleiben',
    history: 'Geschichte',

    // wrap
    selectLine: 'Anzahl der Linien',
    selectBet: 'Einsatz Wählen',
    selectDenomination: 'Denomination',

    // info
    rules: 'Regeln',
    substitutesForAllSymbols: 'Ersetzt alle Symbole mit Ausnahme von Streusymbolen',
    combinationOfKind: 'Alle Preise sind für Kombinationen gleicher Art ',
    combinationLeftToRight: 'Alle Preise sind für Kombinationen  von links nach rechts, ausgenommen Scatter. ',
    prizesOnSelectedLines: 'Alle Priese sind auf den gewählten Linien, ausgenommen Scatter. ',
    ScatterPayAtAnyPosition: 'Scattersymbole zahlen an jeder BildSchirmposition. ',
    HighestWinPaid: 'Nur der höchste Gewinn pro gewählter Linie zahlt. ',
    ScatterWinsAddedToLineWins: 'Scattergewinne werden zu den Liniengewinnen addiert. ',
    prizesShownInCredits: 'Alle Gewinne werden in Krediten angezeigt. ',
    MalfunctionVoidsAllPays: 'Fehlfunktion macht alle Spiele und Zahlungen ungültig. ',
    onTheScreen: 'geben',
    bonusGame: 'BONUS SPIEL',
    bonusGameGive: 'Anzahl der Freispiele',
    freeSpins: 'FREIE SPINS',
    featureBonus: 'In Bonusspielen ist die erste Rolle voll',
    goodLuck: 'Viel glück!',

    // instruction IOS
    howToInstall: 'So installieren Sie die Anwendung',
    pressTheButton: 'Klicken Sie auf die Schaltfläche Teilen',
    goToHomeScreen: 'Knopf ',
    confirmInstallation: 'Installieren Sie die Installation',

    // bonuses
    congratulation: 'Glückwunsch',
    registration_bonus: {
        title: 'Bonus für die Registrierung!',
        description: {
            punkt1: '- Um den Bonus zu aktivieren, müssen Sie Ihre E-Mail-Adresse und Telefonnummer bestätigen.',
            punkt2: '- Machen Sie eine Einzahlung auf das Hauptkonto.',
            punkt3: '- Um den Bonus dem Hauptkonto gutzuschreiben, müssen Sie Wetten auf die Summe platzieren, die 15-mal höher ist als die Summe des Bonus.'
        },
        accept: 'Ihr Bonus für die Registrierung hinterlegt!'
    },

    week_bonus: {
        title: 'Wöchentlicher Bonus!',
        description: 'Wöchentlich',
        accept: 'Ihr wöchentlicher Bonus eingezahlt!'
    }
};
