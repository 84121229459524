import AdmiralNelson from './games/admiral-nelson/game';
import AgeOfPharaohs from './games/age-of-pharaohs/game';
import AlwaysAmerican17 from './games/always-american-17/game';
import AlwaysCherry from './games/always-cherry/game';
import AlwaysHot from './games/always-hot/game';
import AlwaysHotDeluxe17 from './games/always-hot-deluxe-17/game';
import AmazonsBattle from './games/amazons-battle/game';
import AztecGold from './games/aztec-gold/game';
import BeeWild from './games/bee-wild/game';
import BigChief from './games/big-chief/game';
import BookOfFire from './games/book-of-fire/game';
import BookOfFortune from './games/book-of-fortune/game';
import BookOfMaya from './games/book-of-maya/game';
import BookOfRa from './games/book-of-ra/game';
import BookOfRa6 from './games/book-of-ra-6/game';
import BookOfRaDeluxe from './games/book-of-ra-deluxe/game';
import BuffaloThunder from './games/buffalo-thunder/game';
import BurningHot from './games/burning-hot/game';
import BlueHeart from './games/blue-heart/game';
import CaramelHot from './games/caramel-hot/game';
import ColumbusDeluxe from './games/columbus-deluxe/game';
import Champagne from './games/champagne/game';
import CrazyMonkey from './games/crazy-monkey-1/game';
import ElToro from './games/el-toro/game';
import EuropeanRoulette from './games/european-roulette/game';
import FireRagePlus from './games/fire-rage-plus/game';
import FlamingHot from './games/flaming-hot/game';
import FruitCocktail from './games/fruit-cocktail-1/game';
import FruitKing from './games/fruit-king/game';
import GateOfRa from './games/gate-of-ra/game';
import GemsMania from './games/gems-mania/game';
import Gems27 from './games/gems-27/game';
import GladiatorsRising from './games/gladiators-rising/game';
import GoldenArk from './games/golden-ark/game';
import GoldRush from './games/gold-rush/game';
import HerculesSonOfZeus from './games/hercules-son-of-zeus/game';
import HitJewels from './games/hit-jewels/game';
import HotCherry from './games/hot-cherry/game';
import HotChipRunner from './games/hot-chip-runner/game';
import HotScatter from './games/hot-scatter/game';
import HotSeven from './games/hot-seven/game';
import HotSevens from './games/hot-sevens/game';
import HotSlot from './games/hot-slot/game';
import JewelsDivine from './games/jewels-divine/game';
import JokerFruits from './games/joker-fruits/game';
import JollyPays from './games/jolly-pays/game';
import Keks from './games/keks-1/game';
import Lions from './games/lions/game';
import LordOfTheOcean from './games/lord-of-the-ocean/game';
import LuckyLady from './games/lucky-lady/game';
import LuckyRanch from './games/lucky-ranch/game';
import MagicJoker from './games/magic-joker/game';
import NeonCity from './games/neon-city/game';
import NightCity from './games/night-city/game';
import NightCitySevens from './games/night-city-sevens/game';
import NorthernLight from './games/northern-light/game';
import PlentyOfFruit from './games/plenty-of-fruit/game';
import PlentyOnTwentyHot from './games/plenty-on-twenty-hot/game';
import PharaohsRing from './games/pharaohs-ring/game';
import PharaohsTomb from './games/pharaohs-tomb/game';
import PowerStars from './games/power-stars/game';
import RapaNui from './games/rapa-nui/game';
import RoaringForties from './games/roaring-forties/game';
import SeaTreasure from './games/sea-treasure/game';
import SevensOnFire from './games/sevens-on-fire/game';
import SevensOnFirePlus from './games/sevens-on-fire-plus/game';
import SizzlingGems20 from './games/sizzling-gems-20/game';
import SizzlingGold from './games/sizzling-gold/game';
import Sizzling6 from './games/sizzling-6/game';
import SizzlingHotDeluxe20 from './games/sizzling-hot-deluxe-20/game';
import SkyRiders from './games/sky-riders/game';
import SlotOPol from './games/slot-o-pol/game';
import SparkyFruits from './games/sparky-fruits/game';
import SparkyHot from './games/sparky-hot/game';
import SparkySeven from './games/sparky-seven/game';
import Spin from './games/spin/game';
import StarBurst from './games/star-burst/game';
import SuperHot20 from './games/20-super-hot/game';
import SuperHot40 from './games/40-super-hot/game';
import SupremeHot from './games/supreme-hot/game';
import Super7s from './games/super-7s/game';
import TheBigCatch from './games/the-big-catch/game';
import ThreeeDeluxe27 from './games/threee-deluxe-27/game';
import TikiIsland from './games/tiki-island/game';
import TreasureGate from './games/treasure-gate/game';
import TropicalFruit from './games/tropical-fruit/game';
import ThreeStarFortune from './games/three-star-fortune/game';
import UltimateHot from './games/ultimate-hot/game';
import UltraHot from './games/ultra-hot/game';
import UltraStar from './games/ultra-star/game';
import Ultra7Hot from './games/ultra-7-hot/game';
import VegasMagic from './games/vegas-magic/game';
import WildSeven from './games/wild-seven/game';
import WitchHunters from './games/witch-hunters/game';
import Zeus from './games/zeus/game';
import ZodiacWheel from './games/zodiac-wheel/game';

export default {
    // Amatic
    'AdmiralNelson': AdmiralNelson,
    'BookOfFortune': BookOfFortune,
    'HotScatter': HotScatter,
    'HotSeven': HotSeven,
    'WildSeven': WildSeven,

    // Champion
    'AlwaysCherry': AlwaysCherry,
    'FireRagePlus': FireRagePlus,
    'HitJewels': HitJewels,
    'HotSevens': HotSevens,
    'HotSlot': HotSlot,
    'SevensOnFire': SevensOnFire,
    'SevensOnFirePlus': SevensOnFirePlus,
    'TropicalFruit': TropicalFruit,
    'Ultra7Hot': Ultra7Hot,

    // Novomatic
    'AgeOfPharaohs': AgeOfPharaohs,
    'AlwaysAmerican17': AlwaysAmerican17,
    'AlwaysHot': AlwaysHot,
    'AlwaysHotDeluxe17': AlwaysHotDeluxe17,
    'BeeWild': BeeWild,
    'BigChief': BigChief,
    'BookOfMaya': BookOfMaya,
    'BookOfRa': BookOfRa,
    'BookOfRa6': BookOfRa6,
    'BookOfRaDeluxe': BookOfRaDeluxe,
    'BuffaloThunder': BuffaloThunder,
    'ColumbusDeluxe': ColumbusDeluxe,
    'ElToro': ElToro,
    'FruitKing': FruitKing,
    'GateOfRa': GateOfRa,
    'GemsMania': GemsMania,
    'Gems27': Gems27,
    'GoldenArk': GoldenArk,
    'HotCherry': HotCherry,
    'HotChipRunner': HotChipRunner,
    'JewelsDivine': JewelsDivine,
    'JokerFruits': JokerFruits,
    'JollyPays': JollyPays,
    'Lions': Lions,
    'LordOfTheOcean': LordOfTheOcean,
    'LuckyLady': LuckyLady,
    'MagicJoker': MagicJoker,
    'NorthernLight': NorthernLight,
    'PlentyOfFruit': PlentyOfFruit,
    'PlentyOnTwentyHot': PlentyOnTwentyHot,
    'PharaohsRing': PharaohsRing,
    'PharaohsTomb': PharaohsTomb,
    'PowerStars': PowerStars,
    'RapaNui': RapaNui,
    'RoaringForties': RoaringForties,
    'SizzlingGems20': SizzlingGems20,
    'SizzlingGold': SizzlingGold,
    'Sizzling6': Sizzling6,
    'SizzlingHotDeluxe20': SizzlingHotDeluxe20,
    'ThreeeDeluxe27': ThreeeDeluxe27,
    'TheBigCatch': TheBigCatch,
    'TikiIsland': TikiIsland,
    'TreasureGate': TreasureGate,
    'UltraHot': UltraHot,
    'UltraStar': UltraStar,

    // Prefer Games
    'BookOfFire': BookOfFire,
    'EuropeanRoulette': EuropeanRoulette,
    'GladiatorsRising': GladiatorsRising,
    'LuckyRanch': LuckyRanch,
    'NeonCity': NeonCity,
    'NightCity': NightCity,
    'NightCitySevens': NightCitySevens,
    'SeaTreasure': SeaTreasure,
    'SkyRiders': SkyRiders,
    'SparkyFruits': SparkyFruits,
    'SparkyHot': SparkyHot,
    'SparkySeven': SparkySeven,
    'VegasMagic': VegasMagic,
    'WitchHunters': WitchHunters,

    // Igrosoft
    'CrazyMonkey1': CrazyMonkey,
    'Keks1': Keks,
    'FruitCocktail1': FruitCocktail,

    // EGT
    '20SuperHot': SuperHot20,
    '40SuperHot': SuperHot40,
    'AmazonsBattle': AmazonsBattle,
    'BlueHeart': BlueHeart,
    'BurningHot': BurningHot,
    'CaramelHot': CaramelHot,
    'FlamingHot': FlamingHot,
    'SupremeHot': SupremeHot,
    'UltimateHot': UltimateHot,
    'ZodiacWheel': ZodiacWheel,

    // Other
    'AztecGold': AztecGold, // Megajack
    'Champagne': Champagne,
    'SlotOPol': SlotOPol,
    'Spin': Spin,
    'Super7s': Super7s,
    'ThreeStarFortune': ThreeStarFortune,
    'GoldRush': GoldRush, // Pragmatic
    'HerculesSonOfZeus': HerculesSonOfZeus,

    'Zeus': Zeus, // SG Williams

    'StarBurst': StarBurst
};
