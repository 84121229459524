import {JL} from 'jsnlog';

import App from './../../../index';
import GameEgt from './../../egt/game';
import Lines from './../../egt/lines20';
import GambleEgt from './../../egt/gamble';
import InfoScreen from '../../infoScreen';

export default class BurningHot extends GameEgt {
    constructor() {
        super();
        this.id = 'burning-hot';
        this.name = 'Burning Hot';
        this.buttonsPanelShadow = 'mid';
        this.reelFilter = [[8], [10], [], [10], [8]];
        this.doublingFilter = [8, 9, 10];

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 120, y: 90},
            bonusInBonusFrame: {x: 120, y: 150},
            endBonusFrame: {x: 120, y: 150}
        };

        this.symbols = [
            {regularDelay: 50, skipSteps: 15, payment: [0, 0, 0, 10, 30, 100]},    // 0 - cherry
            {regularDelay: 50, skipSteps: 15, payment: [0, 0, 0, 10, 30, 100]},    // 1 - lemon
            {regularDelay: 50, skipSteps: 15, payment: [0, 0, 0, 10, 30, 100]},    // 2 - orange
            {regularDelay: 50, skipSteps: 15, payment: [0, 0, 0, 10, 30, 100]},    // 3 - plum
            {regularDelay: 50, skipSteps: 15, payment: [0, 0, 0, 20, 50, 200]},    // 4 - bell
            {regularDelay: 50, skipSteps: 15, payment: [0, 0, 0, 40, 100, 500]},   // 5 - wathermelon
            {regularDelay: 50, skipSteps: 15, payment: [0, 0, 0, 40, 100, 500]},   // 6 - grape
            {regularDelay: 50, skipSteps: 15, payment: [0, 0, 10, 50, 200, 3000]}, // 7 - 7 seven
            {regularDelay: 80, skipSteps: 15, payment: [0, 0, 0, 0, 0, 0]},        // 8 - clover
            {regularDelay: 50, skipSteps: 15, payment: [0, 0, 0, 3, 20, 100]},     // 9 - dolar
            {regularDelay: 50, skipSteps: 15, payment: [0, 0, 0, 20, 0, 0]}        // 10 - star
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                minimizeSymbols: 'minimizeSymbols.png',
                gambleArea: 'area/gamble.png'
            }),
            atlas: this.mergePath([
                'staticSymbols.json',
                'bonusSymbols.json'
            ])
        };
        this.gameSounds = {
            soundClass: 'egt',
            sounds: [],
            path: `/game/games/${this.id}/audio/`
        };
        this.Lines = new Lines(this.mergePath({boxes: 'lines/boxes.png'}));
        this.Gamble = new GambleEgt();
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
        this.InfoScreen.format = 'png';
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 19pt Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = 'white';
        ctx.shadowColor = 'black';
        ctx.shadowOffsetX = ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 5;

        switch (page) {
            case 1:
                ctx.font = 'bold 12pt Arial';

                // cherry, lemon
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 205, 353);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 205, 376);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 205, 399);

                // plum, orange
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 620, 355);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 620, 378);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 620, 401);

                // wathermelon
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 205, 253);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 205, 276);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 205, 299);

                // bell
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 620, 255);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 620, 278);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 620, 301);

                // seven
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], 205, 153);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], 205, 170);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 205, 188);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[2], 205, 205);

                // grape
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 620, 155);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 620, 178);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 620, 201);

                // Dolar
                this.strokeFillText(ctx, bet * nLines * this.symbols[9].payment[5], 430, 290);
                this.strokeFillText(ctx, bet * nLines * this.symbols[9].payment[4], 430, 313);
                this.strokeFillText(ctx, bet * nLines * this.symbols[9].payment[3], 430, 336);

                // star
                this.strokeFillText(ctx, bet * nLines * this.symbols[10].payment[3], 430, 413);
                break;
            case 2:
                ctx.font = 'bold 13pt Arial';
                ctx.textAlign = 'left';
                // gamble limit
                this.strokeFillText(ctx, (this.Gamble.limit * 100) / App.Money.getCurrentDenomination(), 480, 242);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    onRotationDone() {
        JL().debug(`-- Rotation done (fps: ${App.System.statistics.fps})`);
        App.updateButton('start', {disabled: true});
        this.roundWin = 0;
        const {payment, screen} = this.latestResponse;
        const scatterAnimation = this.decideScatterAnimation(screen);

        payment && scatterAnimation ?
            // going to x2 animation before animate feature
            this.fillReelsWithBonusSymbol(screen) :
            this.regularRotationDone();
    }

    decideScatterAnimation(screen) {
        let scatter1 = 0;
        screen.forEach(reel =>
            reel.forEach(symbol =>
                // clover
                symbol === 8 && scatter1++));
        return scatter1;  // at least one admiral and two gun
    }

    fillReelsWithBonusSymbol(screen) {
        const winReels = this.getWinReels();
        this.reelMatrix.forEach((reel, reelIndex) => {
            if (reelIndex <= winReels && (screen[reelIndex][0] === 8 || screen[reelIndex][1] === 8 || screen[reelIndex][2] === 8)) { // admiral
                reel.forEach(symbolObj => {
                    if (symbolObj.symbol !== 8) { // admiral
                        symbolObj.symbol = 8;
                        symbolObj.image = 'bonus';
                        symbolObj.loop = false;
                        this.Roll.updateSymbolSprite(symbolObj);
                        symbolObj.sprite.play();
                        symbolObj.sprite.onComplete = () => {
                            symbolObj.image = 'regular';
                            this.Roll.updateSymbolSprite(symbolObj);
                        };
                    }
                });
            }
        });

        setTimeout(() => this.regularRotationDone(), 1300);
    }

    getWinReels() {
        let winReels = 0;
        this.latestResponse.features.forEach(feature => {
            if (feature.uc === 'WIN_LINE') {
                winReels = winReels < feature.reels.length ? feature.reels.length : winReels;
            }
        });
        return winReels;
    }

    regularRotationDone() {
        const {features, payment} = this.latestResponse;
        if (payment > 0 || this.isFreeRoll(features) || features.length) {
            // There is a win
            this.setState('SHOW_WIN_LINES');
            this.startAnimateFeature(features);
        } else {
            this.roundFinished();
            this.Legends.setRoundFinText();
        }
    }
}
