import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../../../index';
import PragmaticGames from './../../pragmatic/game';
import Lines from './lines';
import InfoScreen from '../../infoScreen';
import bonusFont from './img/font/fontBonus';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    AnimatedSprite = PIXI.AnimatedSprite,
    Container = PIXI.Container,
    Text = PIXI.Text;

export default class HerculesSonOfZeus extends PragmaticGames {
    constructor() {
        super();
        this.id = 'hercules-son-of-zeus';
        this.name = 'Hercules son of Zeus';
        // reel properties
        this.reels = 5;
        this.reelRows = 4; // number of rows per reel
        this.reelTop = 50;

        this.reelXCoordinates = [20, 209, 395, 582, 770];
        this.symbolHeight = 133; // height of a single symbol
        this.symbolWidth = 183; // width of a single symbol
        this.allowLongRoll = true;
        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: -30, y: 50},
            bonusInBonusFrame: {x: 330, y: 45},
            endBonusFrame: {x: -30, y: 50}
        };

        this.offsetReelMask = {
            offsetX: 10, offsetY: 0,
            offsetWidth: 0, offsetHeight: 0
        };

        this.symbols = [
            {regularDelay: 500, payment: [0, 0, 0, 2, 10, 50]},                           // 0 - J
            {regularDelay: 500, payment: [0, 0, 0, 2, 10, 50]},                           // 1 - Q
            {regularDelay: 500, payment: [0, 0, 0, 5, 25, 100]},                          // 2 - K
            {regularDelay: 500, payment: [0, 0, 0, 5, 25, 100]},                          // 3 - A
            {regularDelay: 40, payment: [0, 0, 0, 10, 50, 150]},                          // 4 - орел
            {regularDelay: 40, payment: [0, 0, 0, 20, 80, 300]},                          // 5 - баран
            {regularDelay: 40, payment: [0, 0, 0, 20, 80, 300]},                          // 6 - кабан
            {regularDelay: 40, payment: [0, 0, 0, 20, 80, 300]},                          // 7 - бык
            {regularDelay: 40, payment: [0, 0, 0, 25, 120, 400]},                         // 8 - собаки
            {regularDelay: 40, payment: [0, 0, 10, 50, 200, 800], zIndex: 1, offsetX: 3}, // 9 - Hercules
            {regularDelay: 40, payment: [0, 0, 0, 0, 0, 0], zIndex: 2, offsetX: 3},       // 10 - WILD
            {regularDelay: 40, payment: [0, 0, 0, 2, 0, 0], zIndex: 3, offsetX: 3}        // 11 - ZEUS
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png',
                intro: 'area/intro.png',
                introButton: 'area/introButton.png',
                bang: 'area/bang.png',
                border: 'area/border.png',
                waitingAnimation: 'waitingAnimation.png',
                longRollEffect: 'area/longRoll.png',
                longRollFire: 'area/longRollFire.png',
                bonusFont: bonusFont['imageResource']
            }),
            atlas: this.mergePath(['staticSymbols.json']),
            fonts: this.mergePath({bonusFrame: 'font/bonusFrame.ttf'})
        };

        this.additionalResources = {
            main: this.mergePath({
                bonusArea: 'area/bonus.png',
                bonusBackground: 'area/background_bonus.png',
                frame1: 'bonus/bonus1.png',
                frame2: 'bonus/bonus2.png'
            }),
            video: this.mergePath({info_respin: 'area/info_respin.mp4'}),
            atlas: this.mergePath([
                'bonus/bonusSymbols.json',
                'bonus/bonusSymbols1.json',
                'bonus/bonusSymbols2.json',
                'bonus/bonusSymbols3.json'
            ])
        };

        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [
                {name: 'background', loop: true},
                {name: 'bonusBackground', loop: true},
                {name: 'reelsStop'},
                {name: 'reelsStopBonus'},
                {name: 'bonusEntrance'},
                {name: 'win-line'},
                {name: 'add-credit-background', loop: 'true'},
                {name: 'scatterStop1'},
                {name: 'scatterStop2'},
                {name: 'scatterStop3'},
                {name: 'scatterSound'},
                {name: 'lightning'},
                {name: 'long1'},
                {name: 'hercules'},
                {name: 'freeSpin'},
                {name: 'bonusInBonus'},
                {name: 'bonusEnd'}
            ],
            path: `/game/games/${this.id}/audio/`
        };
        this.Lines = new Lines(this.mergePath({winLinesBox: 'lines/winBox.png'}));
        this.InfoScreen = new InfoScreen({pages: 4}); // number of game info states
        this.InfoScreen.format = 'png';
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '13pt Arial bold';
        ctx.textAlign = 'center';
        ctx.fillStyle = 'white';
        const video = this.resources.info_respin;

        switch (page) {
            case 1:
                // Hercules
                this.strokeFillText(ctx, bet * this.symbols[9].payment[5], 135, 300);
                this.strokeFillText(ctx, bet * this.symbols[9].payment[4], 135, 319);
                this.strokeFillText(ctx, bet * this.symbols[9].payment[3], 135, 338);
                this.strokeFillText(ctx, bet * this.symbols[9].payment[2], 135, 358);

                // Cerbк (tiple head dog)
                this.strokeFillText(ctx, bet * this.symbols[8].payment[5], 283, 290);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[4], 283, 311);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[3], 283, 329);

                // Cow
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], 433, 290);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], 433, 311);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 433, 329);

                // goat
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 580, 290);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 580, 311);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 580, 329);

                // pig
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 730, 290);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 730, 311);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 730, 329);

                // bird
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 876, 290);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 876, 311);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 876, 329);
                break;
            case 2:
                // A
                this.strokeFillText(ctx, bet * this.symbols[3].payment[5], 120, 255);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[4], 120, 274);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[3], 120, 294);

                // K
                this.strokeFillText(ctx, bet * this.symbols[2].payment[5], 237, 255);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[4], 237, 274);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[3], 237, 294);

                // Q
                this.strokeFillText(ctx, bet * this.symbols[1].payment[5], 355, 255);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[4], 355, 274);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[3], 355, 294);

                // J
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 470, 255);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 470, 274);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 470, 294);
                break;
            case 3:
                video.play();
                const sprite = Sprite.from(video);
                sprite.name = 'info_respin';
                sprite.zIndex = 2;
                sprite.scale.set(0.69);
                sprite.position.set(435, 280);
                const page1 = this.getStageChild('infoContainer').getChildByName(`page-${page}`);
                page1.addChild(sprite);
                break;
        }
    }

    setScatterSprite(scatterFeature) {
        scatterFeature.positions.forEach(position => {
            const {reel, row} = position;
            const symbolObj = this.reelMatrix[reel][row];
            symbolObj.image = 'regular';
            symbolObj.loop = false;
            this.Roll.updateSymbolSprite(symbolObj);
            symbolObj.sprite.onComplete = null;
            symbolObj.sprite.play();
        });
    }

    /**
     * Create additional sprites and animations for stage
     * Call once when game loaded
     * @param parentContainer
     */
    createAdditionalSprites(parentContainer) {
        const sprite = this.getStageChild('reelsBackground');
        sprite.position.set(-24, 40);
    }

    drawBonusFrame(first, last, parentContainer, coordinates) {
        parentContainer.removeChildren();
        this.getStageChild('reelsStage').mask = this.getReelsMask();

        const {startBonusFrame, bonusInBonusFrame, endBonusFrame} = coordinates;
        if (first) {
            this.gameFlag.bonusStarted = true;
            App.Sounds.playSound('bonusStart');
            this.drawBonusAnimation(parentContainer, startBonusFrame);
        }
        if (last) {
            App.Sounds.playSound('bonusEnd');
            this.drawBonusEndAnimation(parentContainer, endBonusFrame, this.bonusStatus);
        }
        if (!first && !last) {
            App.Sounds.playSound('freeSpin');
            this.drawBonusInBonusAnimation(parentContainer, bonusInBonusFrame);
        }
    }

    /**
     * drawBonusAnimation
     */
    drawBonusAnimation(parentContainer, {x, y}) {
        // get scatters
        let i = 0;
        const steps = 20;
        const scatterStack = [];

        App.Sounds.playSound('bonusEntrance');
        this.reelMatrix.forEach((reel, reelIndex) => {
            reel.forEach((symbolObj, rowIndex) => {
                if (symbolObj.symbol === this.scatter) {
                    symbolObj.sprite.visible = false;
                    const sprite = new AnimatedSprite(this.Roll.textures['regular'][this.scatter]);
                    sprite.position.set(this.reelXCoordinates[reelIndex] - 23, this.reelTop + this.symbolHeight * rowIndex - 40);
                    sprite.zIndex = 1;
                    sprite.name = i++;
                    scatterStack.push(sprite);
                    parentContainer.addChild(sprite);
                }
            });
        });

        // scaling symbols reel by reel
        i = 0;
        const interval = this.tickerInterval(() => {
            i++;
            if (i > steps - 1) {
                const ticker = this.app.ticker;
                ticker.remove(interval);
                symbolsAnimation();
                this.reelMatrix.forEach((reel) => {
                    reel.forEach((symbolObj) => {
                        const {x, y} = symbolObj.sprite.position;

                        symbolObj.sprite.scale.x = 1;
                        symbolObj.sprite.scale.y = 1;
                    });
                });
            } else {
                this.reelMatrix.forEach((reel) => {
                    reel.forEach((symbolObj) => {
                        if (i < 10) {
                            symbolObj.sprite.scale.x += 0.02;
                            symbolObj.sprite.scale.y += 0.02;
                        } else {
                            symbolObj.sprite.scale.x -= 0.02;
                            symbolObj.sprite.scale.y -= 0.02;
                        }
                    });
                });
            }
        }, 30);

        // Zeus coin flight to the center
        const zeusAnimation = () => {
            let i = 0;
            const steps = 20;
            const interval2 = this.tickerInterval(() => {
                i++;
                if (i > steps - 1) {
                    const ticker = this.app.ticker;
                    ticker.remove(interval2);
                    zeusRotationScaling();
                } else {
                    scatterStack.forEach(sprite => {
                        sprite.position.x += (370 - sprite.position.x) / (steps - i);
                        sprite.position.y += (110 - sprite.position.y) / (steps - i);
                    });
                }
            }, 50);
        };

        // Zeus rotation with scalling
        const zeusRotationScaling = () => {
            const ticker = this.app.ticker;
            let i = 0;
            const steps = 55;
            const sprite = scatterStack[0];
            scatterStack.forEach((sprite, spriteIndex) => {
                if (spriteIndex !== 0) sprite.visible = false;
            });
            const {x, y} = sprite.position;
            sprite.anchor.set(0.5);
            sprite.position.set(x + 222 / 2, y + 214 / 2);  // correction on extra size
            const interval5 = this.tickerInterval(() => {
                i++;
                if (i > steps - 1) {
                    ticker.remove(interval5);
                    textAnimation();
                    sprite.visible = false;
                } else {
                    if (i < 20) {
                        sprite.scale.x += 0.03;
                        sprite.scale.y += 0.03;
                    }
                    if (i > 20) {
                        sprite.gotoAndStop(i - 20);
                    }
                    if (i > 30) {
                        sprite.scale.x -= 0.065;
                        sprite.scale.y -= 0.065;
                    }
                }
            }, 40);
        };

        // Symbols falling down
        const symbolsAnimation = () => {
            const ticker = this.app.ticker;
            let i = 0;
            const steps = 29;
            const interval4 = this.tickerInterval(() => {
                i++;
                if (i >= steps) {
                    ticker.remove(interval4);
                    this.showStartBonusFrame(parentContainer, {x, y});
                    this.setReelsBackground('bonusBackground');
                    zeusAnimation();
                } else {
                    this.reelMatrix.forEach(reel => {
                        reel.forEach(symbolObj => {
                            symbolObj.sprite.position.y += 30;
                        });
                    });
                }
            }, 20);
        };

        // Text frame animation
        const textAnimation = () => {
            const container = new Container();
            container.name = 'textContainer';
            parentContainer.addChild(container);
            this.showStartBonusFrameText(container, {x, y});
            this.setBackground('bonusArea');
            App.Sounds.stopSound('background');
            App.Sounds.playSound('bonusBackground');
            container.alpha = 0;
            const ticker = this.app.ticker;

            const func = () => {
                container.alpha += 0.01;
                if (container.alpha >= 1) {
                    ticker.remove(func);
                    this.tickerTimeout(() => {
                        parentContainer.interactive = true;
                        App.updateButton('start', {
                            disabled: false, title: 'start',
                            handler: this.startBonusButton
                        });
                        parentContainer.on('pointerdown', () => {
                            parentContainer.interactive = false;
                            this.startBonusButton();
                        });
                    }, 20);
                }
            };
            ticker.add(func);
        };
    }

    /**
     * drawBonusAnimation
     */
    drawBonusInBonusAnimation(parentContainer, {x, y}) {
        // scaling sybols reel by reel
        let i = 0;

        this.reelMatrix.forEach(reel => {
            reel.forEach(symbolObj => {
                if (symbolObj.symbol === this.scatter) {
                    const sprite = new AnimatedSprite(this.Roll.textures['bonus'][this.scatter]);
                    sprite.position.set(-20, 8);
                    sprite.zIndex = 1;
                    sprite.animationSpeed = 15 / 50;
                    i++;
                    sprite.name = 'sprite' + i;
                    sprite.play();
                    sprite.loop = false;
                    symbolObj.symbolContainer.addChild(sprite);
                    sprite.onComplete = () => {
                        if (sprite.name === 'sprite1') {
                            App.Sounds.playSound('bonusInBonus');
                            this.showBonusInBonusFrame(parentContainer, {x, y}, 'frame2', i); // call function only once
                        }
                        sprite.destroy();
                    };
                }
            });
        });
    }

    /**
     * End feature animation / take win without prompt
     */
    endAnimateFeature() {
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        App.Sounds.stopSound('win-line');
        if (this.getState() === 'SHOW_WIN_LINES' && this.latestResponse.payment) {
            this.takeWin();
        } else { // no win, just feature without payment
            this.roundFinished();
        }
    }

    showBonusFrame(parentContainer, x, y, image = 'frame1') {
        parentContainer.sortableChildren = true;
        const sprite = new Sprite(this.getTexture(image));
        sprite.name = image;
        sprite.position.set(x, y);
        sprite.zIndex = 0;
        parentContainer.addChild(sprite);
    }

    /**
     * Function to start logic of Animate feature first step
     * @param features
     */
    startAnimateFeature(features) {
        JL().debug('-- Start animate feature');
        this.Legends.showWinFeatures();
        App.Sounds.pauseSound('bonus-background');
        this.prepareToAnimateFeature(features);
        App.Sounds.playSound('win-line');
    }

    /**
     * Animate feature first step
     */
    prepareToAnimateFeature(features) {
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        this.features.step = 0;

        // check 'WIN_LINE' feature contain
        const isWinLine = features.some(features => features.uc === 'WIN_LINE');
        this.updateExtraSymbols(isWinLine);

        // unique preparing for each game
        this.additionalPreparingToAnimateFeature(features);
    }

    showFeatureLine(currentFeature, features, allSymbols = false) {
        const {number, reels, payment, uc} = currentFeature; // get current feature params

        const container = this.getStageChild('linesContainer');

        if (['WIN_LINE', 'SCATTER'].includes(uc)) {
            uc === 'WIN_LINE' && this.Lines.drawLineImages([number], reels, container, true, payment, allSymbols);
            this.animateSymbolsInLine(currentFeature, allSymbols);
        }
    }

    animateSymbolsInLine(feature, allSymbols) {
        !allSymbols && this.setHerculesStaticSprite();

        const {uc, number, reels, positions} = feature;
        const increasedSymbols = [4, 5, 6, 7, 8];
        const blinkingSymbols = [0, 1, 2, 3];
        uc === 'SCATTER' ?
            positions.forEach(pos => {
                const {reel, row} = pos;
                const symbolObj = this.reelMatrix[reel][row];
                symbolObj.sprite.play();
            }) :
            this.reelMatrix.forEach((reel, reelIndex) => {
                reel.forEach((symbolObj, rowIndex) => {
                    const {coordinates} = this.Lines.lines[number];
                    if (coordinates[reelIndex] === rowIndex && reels.includes(reelIndex)) {
                        if (increasedSymbols.includes(symbolObj.symbol)) {
                            this.increaseSymbolAnimation(symbolObj.sprite);
                        } else if (blinkingSymbols.includes(symbolObj.symbol)) {
                            this.blinkSymbols(symbolObj.sprite);
                        } else {
                            symbolObj.loop = false;
                            symbolObj.image = this.symbolAnimation && symbolObj.image === 'static' ?
                                'regular' : symbolObj.image;
                            this.Roll.updateSymbolSprite(symbolObj);
                            symbolObj.sprite.play();
                            symbolObj.sprite.onComplete = null;
                        }
                    }
                });
            });
    }

    /**
     * Function to stop Animate Feature animation
     */
    stopAnimateFeature() {
        this.setHerculesStaticSprite();
        this.stopFeatureTimeout();
        this.getStageChild('linesContainer').removeChildren();
        this.getStageChild('boxesContainer').removeChildren();
        this.Legends.clearText('features');
        !this.isBonus() && this.Legends.clearText('win');
    }

    increaseSymbolAnimation(sprite) {
        if (sprite.scale.x === 1) {
            this.showAnimation({
                duration: 500, animations: [
                    {
                        sprite: sprite,
                        timeline: [{
                            from: {scaleX: 1, scaleY: 1},
                            to: {scaleX: 1.1, scaleY: 1.1},
                            duration: {to: 250}
                        }]
                    },
                    {
                        sprite: sprite,
                        timeline: [{
                            from: {scaleX: 1.1, scaleY: 1.1},
                            to: {scaleX: 1, scaleY: 1},
                            duration: {from: 250}
                        }]
                    }
                ],
                onComplete: () => {
                    sprite.onComplete && sprite.onComplete();
                }
            });
        }
    }

    blinkSymbols(sprite) {
        this.showAnimation({
            duration: 900, animations: [
                {sprite, timeline: [{from: {alpha: 1}, to: {alpha: 0}, duration: {from: 200, to: 300}}]},
                {sprite, timeline: [{from: {alpha: 0}, to: {alpha: 1}, duration: {from: 300, to: 400}}]},
                {sprite, timeline: [{from: {alpha: 1}, to: {alpha: 0}, duration: {from: 500, to: 600}}]},
                {sprite, timeline: [{from: {alpha: 0}, to: {alpha: 1}, duration: {from: 600, to: 700}}]}
            ]
        });
    }

    /**
     * Process reels response from server.
     * @param response - Socket response 'ROLL'
     */
    processReelResponse(response) {
        this.latestResponse = response;
        this.setState('RESPONSE_RECEIVED');
        this.setBonusRollSymbol(); // for bonus game roll symbol
        this.prepareToRollAnimation(response);
    }

    /**
     * Called to show round results once animation is finished
     */

    setRegularShortSprite(clipMatrix, reelIndex, textures) {
        for (let i = 0; i < this.reelRows; i++) {
            const symbolObj = clipMatrix[reelIndex][i + 1];
            if (symbolObj.symbol === this.scatter) {
                if (!this.gameFlag.bonusStarted) {
                    if (reelIndex === 1) {
                        this.increaseSymbolAnimation(symbolObj.sprite);
                        this.allowAnimation[reelIndex] = true;
                    } else if (this.allowAnimation[reelIndex - 1] === true) {
                        this.allowAnimation[reelIndex] = true;
                        App.Sounds.playSound('scatterStop' + reelIndex);
                        this.increaseSymbolAnimation(symbolObj.sprite);
                    }
                }
            }
        }
    }

    /**
     * Show bonus message with bonus symbol or with bonus game win.
     * @param isFirstBonus {boolean} TRUE if this is the message for starting bonus game.
     */
    drawBonusAskButton(isFirstBonus = false) {
        let isLast = !isFirstBonus && this.bonusStatus && this.bonusStatus.remain === 0;

        this.stopAnimateFeature();
        this.tickerTimeout(() => {
            this.app && this && this.drawBonusFrame(isFirstBonus, isLast, this.getStageChild('bonusContainer'), this.coordinatesBonusFrame);
        }, 1000);

        this.gameFlag.bonusStart = true;
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});

        isLast = this.bonusStatus && this.bonusStatus.remain === 0;
    }

    /**
     * Function to get free roll count before bonusStatus will be set.
     */
    getFreeGames() {
        let amount = 0;
        this.latestResponse.features.forEach(feature => {
            if (feature.uc === 'FREE_ROLL') amount = feature.amount;
        });
        return amount;
    }

    /**
     * Отрисовка таблички бонус в бонусе
     */
    showBonusInBonusFrame(parentContainer, {x, y}, image, freeGames) {
        const container = new Container();
        container.interactive = false;
        container.position.x = 0;
        container.position.y = 0;
        parentContainer.addChild(container);

        this.showBonusFrame(container, x, y, image);
        const richText = new Text('+' + freeGames, {
            fontFamily: 'bonusFont',
            fontSize: 85,
            align: 'center',
            fill: ['#fffe2d', '#ffff81', '#fffe2d'], // gradient
            stroke: '#d04b23',
            strokeThickness: 2
        });
        richText.x = 430;
        richText.y = 90;
        container.addChild(richText);

        const richText2 = new Text('FREE SPINS', {
            fontFamily: 'bonusFont',
            fontSize: 36,
            align: 'center',
            fill: ['#fffe2d', '#ffff81', '#fffe2d'], // gradient
            stroke: '#d04b23',
            strokeThickness: 2
        });
        richText2.x = 385;
        richText2.y = 190;
        container.addChild(richText2);

        container.scale.x = 0.01;
        container.scale.y = 0.01;
        container.alpha = 0;
        container.position.x = 480 * container.scale.x;
        container.position.y = 150 * container.scale.y;
        const ticker = this.app.ticker;
        const func = () => {
            container.scale.x += 0.01;
            container.scale.y += 0.01;
            container.alpha += 0.01;
            container.position.x = 480 - 480 * container.scale.x;
            container.position.y = 150 - 150 * container.scale.y;
            if (container.scale.x >= 1) {
                ticker.remove(func);
                this.tickerTimeout(() => {
                    parentContainer.removeChild(container);
                    this.startBonusAnimation(parentContainer);
                }, 1500);
            }
        };
        ticker.add(func);
    }

    setRegularSprite() {
        this.reelMatrix.forEach(reel => {
            reel.forEach(symbolObj => {
                symbolObj.image = (this.symbolAnimation && symbolObj.symbol !== 9) ? 'regular' : 'static';
                this.Roll.updateSymbolSprite(symbolObj);
            });
        });
    }

    /**
     * Отрисовка таблички окончания бонусной игры
     */
    showEndBonusFrame(container, {x, y}, {win, total}) {
        this.showBonusFrame(container, x, y);

        const richText = new Text('CONGRATULATIONS! \n YOU HAVE WON', {
            fontFamily: 'bonusFrame',
            fontSize: 60,
            align: 'center',
            fill: ['#fffe2d', '#ffff81', '#fffe2d'], // gradient
            stroke: '#d04b23',
            strokeThickness: 6
        });
        richText.x = 170;
        richText.y = 100;
        container.addChild(richText);

        const textProps = {
            parentContainer: container,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 0.9,
            fontInterval: 2 // px between symbolss
        };
        this.drawCustomFont(win, 470, 250, textProps);

        const richText3 = new Text('IN ' + total + ' FREE SPINS!', {
            fontFamily: 'bonusFrame',
            fontSize: 60,
            align: 'center',
            fill: ['#fffe2d', '#ffff81', '#fffe2d'], // gradient
            stroke: '#d04b23',
            strokeThickness: 6
        });
        richText3.x = 250;
        richText3.y = 390;
        container.addChild(richText3);

        const richText4 = new Text('PRESS ANYWHERE TO CONTINUE', {
            fontFamily: 'bonusFrame',
            fontSize: 25,
            align: 'center',
            fill: ['#fffe2d', '#ffff81', '#fffe2d'], // gradient
            stroke: '#d04b23',
            strokeThickness: 6
        });
        richText4.x = 280;
        richText4.y = 465;
        container.addChild(richText4);
        container.interactive = true;
        container.on('pointerdown', () => {
            container.interactive = false;
        });
    }

    /**
     * drawBonusAnimation
     */
    drawBonusEndAnimation(parentContainer, {x, y}, {win, total}) {
        // Symbols falling down
        let i = 0;
        const steps = 30;
        const interval4 = this.tickerInterval(() => {
            i++;
            if (i >= steps - 1) {
                const ticker = this.app.ticker;
                ticker.remove(interval4);
                textAnimation();
            } else {
                this.reelMatrix.forEach(reel => {
                    reel.forEach(symbolObj => {
                        symbolObj.sprite.position.y += 30;
                    });
                });
            }
        }, 20);

        // Text frame animation
        const textAnimation = () => {
            const container = new Container();
            container.name = 'endBonusFrame';
            parentContainer.addChild(container);
            this.showEndBonusFrame(container, {x, y}, {win, total});
            App.Sounds.stopSound('bonusBackground');
            App.Sounds.playSound('background');
            container.alpha = 0;
            const ticker = this.app.ticker;
            const func = () => {
                container.alpha += 0.01;
                if (container.alpha >= 1) {
                    this.setReelsBackground('background');
                    const ticker = this.app.ticker;
                    ticker.remove(func);
                    this.tickerTimeout(() => {
                        App.updateButton('start', {
                            disabled: false, title: 'start',
                            handler: this.endBonusButton
                        });
                        container.interactive = true;
                        container.on('pointerdown', () => {
                            container.interactive = false;
                            container.visible = false;
                            this.endBonusButton();
                        });
                    }, 20);
                }
            };
            ticker.add(func);
        };
    }

    dropDownSymbols(func) {
        let i = 0;
        const steps = 29;
        this.reelMatrix.forEach((reel) => {
            reel.forEach((symbolObj) => {
                symbolObj.sprite.position.y -= 1680;
                symbolObj.sprite.visible = true;
                symbolObj.sprite.scale.x = 1;
                symbolObj.sprite.scale.y = 1;
            });
        });
        const interval = this.tickerInterval(() => {
            i++;
            if (i >= steps) {
                const ticker = this.app.ticker;
                ticker.remove(interval);
                this.tickerTimeout(() => {
                    func();
                }, 300);
            } else {
                this.reelMatrix.forEach(reel => {
                    reel.forEach(symbolObj => {
                        symbolObj.sprite.position.y += 30;
                    });
                });
            }
        }, 20);
    }

    /**
     * Отрисовка таблички бонусной игры
     */
    showStartBonusFrameText(container, {x, y}) {
        const richText = new Text('CONGRATULATIONS! \n YOU HAVE WON', {
            fontFamily: 'bonusFrame',
            fontSize: 60,
            align: 'center',
            fill: ['#fffe2d', '#ffff81', '#fffe2d'], // gradient
            stroke: '#d04b23',
            strokeThickness: 6
        });
        richText.x = 170;
        richText.y = 100;
        container.addChild(richText);

        const textProps = {
            parentContainer: container,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 0.9,
            fontInterval: 2 // px between symbolss
        };
        this.drawCustomFont(this.getFreeGames(), 470, 250, textProps);

        const richText3 = new Text('FREE SPINS!', {
            fontFamily: 'bonusFrame',
            fontSize: 60,
            align: 'center',
            fill: ['#fffe2d', '#ffff81', '#fffe2d'], // gradient
            stroke: '#d04b23',
            strokeThickness: 6
        });
        richText3.x = 320;
        richText3.y = 390;
        container.addChild(richText3);

        const richText4 = new Text('PRESS ANYWHERE TO CONTINUE', {
            fontFamily: 'bonusFrame',
            fontSize: 25,
            align: 'center',
            fill: ['#fffe2d', '#ffff81', '#fffe2d'], // gradients
            stroke: '#d04b23',
            strokeThickness: 6
        });
        richText4.x = 280;
        richText4.y = 465;
        container.addChild(richText4);
        container.interactive = true;

        container.alpha = 0;
        const ticker = this.app.ticker;
        const func = () => {
            container.alpha += 0.01;
            if (container.alpha >= 1) {
                const ticker = this.app.ticker;
                ticker.remove(func);
            }
        };
        ticker.add(func);
    }

    setHerculesStaticSprite() {
        this.reelMatrix.forEach(reel => {
            reel.forEach(symbolObj => {
                if (symbolObj.symbol === 9) {
                    symbolObj.image = 'static';
                    this.Roll.updateSymbolSprite(symbolObj);
                }
            });
        });
    }

    animationBeforeBonusRoll = () => {
        let i = 0;
        const steps = 3;
        const parentContainer = this.getStageChild('bonusContainer');
        this.stopAnimateFeature();
        this.setHerculesStaticSprite();

        const interval2 = this.tickerInterval(() => {
            i++;
            if (i <= steps) {
                const sprite = new AnimatedSprite(this.Roll.textures['bonus'][10]);
                sprite.position.set(this.reelXCoordinates[i] - 28, 0);
                sprite.zIndex = 1;
                sprite.animationSpeed = 15 / 55;
                sprite.name = 'wild' + i;
                parentContainer.addChild(sprite);
                sprite.play();
                App.Sounds.playSound('lightning');
                sprite.loop = false;
                sprite.onComplete = () => {
                    sprite.destroy();
                };
            } else {
                const ticker = this.app.ticker;
                ticker.remove(interval2);
                this.tickerTimeout(() => {
                    this.setState('IDLE_BONUS');
                    this.startBonusRoll();
                }, 2000);
            }
        }, 1500);
    };

    addWaitingAnimationSprites(parentContainer, state) {
        const waitingType = 'IDLE';
        let sprite;
        if (this.waitingType === '') {
            parentContainer.removeChildren();
            sprite = new AnimatedSprite(this.getSpriteTextures({
                toFrame: 8, image: 'waitingAnimation',
                width: 943, height: 94, colCount: 2
            }));
            sprite.name = 'waitingAnimation';
            sprite.position.set(50, 0);
            sprite.animationSpeed = 0.001;
            sprite.gotoAndStop(Math.round(Math.random() * 8));
            sprite.play();
            parentContainer.addChild(sprite);
        }
        parentContainer.getChildByName('waitingAnimation').visible = state !== 'GAME_INTRO';
        this.waitingType = waitingType;
    }

    /**
     * Set global state for Game
     * @param state
     */
    setState(state) {
        this.state = state;
        this.app && this && this.addWaitingAnimationSprites(this.getStageChild('waitingContainer'), this.state);
    }

    /**
     * Long roll decider return reel from with long roll started
     *  -1 no long roll
     *  0 1 2 3 4 5 number of reel
     */
    getLongRoll(screen) {
        const scatterMap = [];
        screen.forEach((vector, reelIndex) =>
            vector.forEach(symbol =>
                symbol === this.scatter && scatterMap.push(reelIndex)
            ));

        this.reelSymbol.forEach((symbolAmount, reelIndex) =>
            this.reelLong[reelIndex] =
                (scatterMap.includes(1) && scatterMap.includes(2) && // 2st and 3rd reel filled
                    reelIndex === this.reels - 2) ? 1 : 0);

        return this.reelLong; // get possible long roll type
    }

    playLongRollSound = reelIndex => {
        if (this.reelLong[reelIndex] === 1) {
            this.stopRollSound();
            this.stopLongRollSound();
            this.tickerTimeout(() => {
                App.Sounds.playSound('long1');
            }, 50);
            const longRollEffect = new AnimatedSprite(this.getSpriteTextures({
                toFrame: 48, image: 'longRollEffect',
                width: 113, height: 302, colCount: 16
            }));
            longRollEffect.name = 'longRollEffect';
            longRollEffect.position.set(this.reelXCoordinates[reelIndex] - 10, this.reelTop - 14);
            longRollEffect.scale.set(1.7, 1.85);
            longRollEffect.alpha = 0;
            longRollEffect.animationSpeed = 0.23;
            longRollEffect.blendMode = 3;
            longRollEffect.loop = false;
            longRollEffect.play();
            longRollEffect.onComplete = () => longRollEffect.destroy();

            this.getStageChild('bonusContainer').addChild(longRollEffect);

            const longRollFire = new AnimatedSprite(this.getSpriteTextures({
                toFrame: 30, image: 'longRollFire',
                width: 85, height: 687, colCount: 15
            }));
            longRollFire.name = 'longRollFire';
            longRollFire.position.set(this.reelXCoordinates[reelIndex] + 139, this.reelTop + 262);
            longRollFire.scale.set(1, 0.8);
            longRollFire.anchor.set(0.5);
            longRollFire.alpha = 0;
            longRollFire.blendMode = 3;
            longRollFire.loop = false;
            longRollFire.animationSpeed = 0.3;
            longRollFire.play();
            longRollFire.onComplete = () => longRollFire.destroy();

            const longRollFire2 = new AnimatedSprite(longRollFire.textures);
            longRollFire2.name = 'longRollFire2';
            longRollFire2.position.set(longRollFire.x - 99, longRollFire.y);
            longRollFire2.scale.set(-1, 0.8);
            longRollFire2.anchor.set(0.5);
            longRollFire2.alpha = 0;
            longRollFire2.loop = false;
            longRollFire2.animationSpeed = 0.3;
            longRollFire2.blendMode = 3;
            longRollFire2.play();
            longRollFire2.onComplete = () => longRollFire2.destroy();

            this.getStageChild('bonusContainer').addChild(longRollFire2, longRollFire);

            this.showAnimation({
                duration: 500,
                animations: [
                    {sprite: longRollEffect, timeline: [{to: {alpha: 1}}]},
                    {sprite: longRollFire, timeline: [{to: {alpha: 1}}]},
                    {sprite: longRollFire2, timeline: [{to: {alpha: 1}}]}
                ]
            });
        }
    };

    /**
     * Function to play animate credit sound
     */
    playCreditSound() {
    }

    /**
     * Function to play rool sound
     */
    playRollSound = () => {
    };

    /**
     * Function to play reel stop sound
     */
    playReelStopSound = () => {
        this.getStageChild('bonusContainer').removeChildren();
        if (this.isBonus() && this.gameFlag.bonusStart) {
            App.Sounds.playSound('reelsStopBonus');
        } else {
            App.Sounds.playSound('reelsStop');
        }
    };

    /**
     * Start game animations
     */
    showGameIntroduction() {
        JL().debug('-- Start game intro animation');
        const ticker = this.app.ticker;
        this.setState('GAME_INTRO');
        this.Buttons.disableAllButtons();
        App.updateButton('autoStart', {disabled: true});
        App.updateState('buttons', {animation: 'hide'});

        const parentContainer = this.getStageChild('bonusContainer');
        parentContainer.sortableChildren = true;
        parentContainer.interactive = true;
        const sprite = new Sprite(this.getTexture('intro'));
        sprite.name = 'intro';
        sprite.position.set(-150, 0);
        sprite.zIndex = 20;
        parentContainer.addChild(sprite);

        const sprite2 = new Sprite(this.getTexture('introButton'));
        sprite2.name = 'introButton';
        sprite2.position.set(385, 459);
        sprite2.zIndex = 20;
        sprite2.interactive = true;
        parentContainer.addChild(sprite2);

        sprite2.on('pointerdown', () => {
            App.updateState('buttons', {animation: 'show'});
            parentContainer.interactive = false;
            this.playBackgroundSound();
            parentContainer.removeChildren();
            this.roundFinished();
            const ticker = this.app.ticker;
            ticker.remove(func);
        });
        let way = 0;
        sprite2.anchor.set(0.5);
        sprite2.position.set(385 + 167 / 2, 459 + 163 / 2);  // correction on extra size

        const func = () => {
            if (sprite2.scale.x >= 1.1 && !way) {
                way = 1;
            }
            if (sprite2.scale.x <= 1 && way) {
                way = 0;
            }
            if (!way) {
                sprite2.scale.x += 0.005;
                sprite2.scale.y += 0.005;
            } else {
                sprite2.scale.x -= 0.005;
                sprite2.scale.y -= 0.005;
            }
        };
        ticker.add(func);
    }

    takeWin = (isTransfer = true) => {
        this.stopWaitingAnimation();
        this.stopAnimateSound();
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        JL().debug(`-- Take win (isTransfer: ${isTransfer})`);
        this.setState('TAKE_WIN');

        if (isTransfer && App.restoreGameState !== 'TRANSFER') {
            App.Socket.send(JSON.stringify({uc: 'TRANSFER-START'}));
        }
        !isTransfer && this.animateCredits(this.latestResponse.payment, isTransfer);
        App.Sounds.playSound('add-credit-background');

        this.Buttons.disableAllButtons();
        App.updateButton('start', {
            disabled: false,
            title: 'collect',
            handler: () => { // User can press 'start' twice for increasing transfer speed
                this.isSpeedUp !== 2 && this.isSpeedUp++;
                JL().debug(`-- Take win speed up x${this.isSpeedUp + 1}`);
                // TODO after repeat click -> take all win immediately
            }
        });
    };

    /**
     * Function to stop animate credit sound
     */
    stopCreditSound = () => {
        App.Sounds.stopSound('add-credit-background');
    };

    /**
     * Function to check if long symbol needed
     */
    isLongSymbolOnScreen = () => false;

    /**
     * Create on reels long symbol. Add wilds as we have bonus spin done - wilds on the screen from response
     * @param reelMas
     */
    addLongSymbol = reelMas => {
        const wildCount = 4; // this.bonusStatus.total - this.bonusStatus.remain;
        let noOtherLongSymbols = 0;

        // add wild stack for bonus game
        if (this.isBonus()) {
            reelMas.forEach((reel, reelIndex) => {
                let wildOnTop = 0, wildOnButtom = 0;
                reel.forEach((symbol, rowIndex) => {
                    if (symbol === 10 && reelIndex > 0 && reelIndex < 4 && rowIndex > 0 && rowIndex < 4) {
                        wildOnTop++;
                    }
                    if (symbol === 10 && reelIndex > 0 && reelIndex < 4 && rowIndex > reel.length - 6 && rowIndex < reel.length - 1) {
                        wildOnButtom++;
                    }
                });

                if (reelMas[reelIndex][4] === 10) { // compliment ready symbols on the top
                    for (let i = 5; i < 5 + 4 - wildOnTop; i++) {
                        reelMas[reelIndex][i] = 10;
                        noOtherLongSymbols = 1;
                    }
                }

                if (reelMas[reelIndex][reel.length - 5] === 10) { // compliment ready symbols on the buttom
                    for (let i = reel.length - 6; i > reel.length - 6 - (4 - wildOnButtom); i--) {
                        reelMas[reelIndex][i] = 10;
                        noOtherLongSymbols = 1;
                    }
                }

                if (!(wildOnTop + wildOnButtom)) {             // no wilds on screen add them
                    const startPos = Math.round(Math.random() * (reel.length - 10 - wildCount) + 5);
                    if (reelIndex > 0 && reelIndex < 4) {
                        for (let i = startPos; i < startPos + 4; i++) {
                            reelMas[reelIndex][i] = 10;
                            noOtherLongSymbols = 1;
                        }
                    }
                }
            });
        }
        // add hercules symbols 4 elements to reel
        reelMas.forEach((reel, reelIndex) => {
            let wildOnTop = 0, wildOnButtom = 0;
            reel.forEach((symbol, rowIndex) => {
                if (symbol === 9 && rowIndex >= 0 && rowIndex < 5) {
                    wildOnTop++;
                }
                if (symbol === 9 && rowIndex > (reel.length - 6) && rowIndex < (reel.length - 1)) {
                    wildOnButtom++;
                }
            });

            if (reelMas[reelIndex][4] === 9) { // compliment ready symbols on the top
                for (let i = 5; i < 5 + 4 - wildOnTop; i++) {
                    reelMas[reelIndex][i] = 9;
                }
            }

            if (reelMas[reelIndex][reel.length - 5] === 9) { // compliment ready symbols on the buttom
                for (let i = reel.length - 6; i > reel.length - 6 - (4 - wildOnButtom); i--) {
                    reelMas[reelIndex][i] = 9;
                }
            }

            if (!(wildOnTop + wildOnButtom) && !noOtherLongSymbols) {             // no wilds on screen add them
                const startPos = Math.round(Math.random() * (reel.length - 10 - wildCount) + 5);
                if (reelIndex > 0 && reelIndex < 4) {
                    for (let i = startPos; i < startPos + 4; i++) {
                        reelMas[reelIndex][i] = 9;
                    }
                }
            }
        });
        return reelMas;
    };

    cleanBeforeRoll() {
        this.stopAnimateFeature();
        this.stopWaitingAnimation();
        App.removePopupMessage();
        App.System.resetRollStatistics();
        this.InfoScreen.update({timeout: false, page: 1});
        this.extraBet && this.updateExtraBetButtons(false);
        this.latestResponse = null;
        this.SymbolInfo.remove(false);

        if (!this.isBonus() && this.getState() !== 'IDLE_BONUS') {
            this.Legends.setStatus('goodLuck');
            this.Legends.showJackpot();
            App.Money.withDraw(this.gameSettings.getBet());
            App.updateState('moneyParams', {
                credits: App.Money.getCredit(),
                money: App.Money.getMoney()
            });
        }
        this.Legends.clearText('features');
        this.Legends.clearText('win');
    }

    setBonusStatusText() {
        let {remain} = this.bonusStatus;
        remain -= this.getAmountFromLastResponse();
        this.Legends.setStatus('freeSpinsLeft', {remain});
    }

    getRandomSymbol(length, reelIndex, symbolBefore) {
        const denyRepeat = 1;
        Math.floor(Math.random() * 4); // decrease repeat 5 times less
        let symbol = Math.floor(Math.random() * length);
        if (denyRepeat === 0 && this.doublingFilter.indexOf(symbolBefore) === -1) {
            symbol = symbolBefore;
        } else {
            while (
                this.isBonus() && symbol === 10 ||
                symbol === this.reelFilter[reelIndex][0] ||
                symbol === this.reelFilter[reelIndex][1] ||
                symbol === this.reelFilter[reelIndex][2] ||
                symbol === this.reelFilter[reelIndex][3] ||
                symbol === symbolBefore) {
                symbol = Math.floor(Math.random() * length);
            }
        }
        return symbol;
    }

    restoreBonusGame() {
        this.setReelsBackground('bonusBackground');
        this.setBackground('bonusArea');
        App.Sounds.playSound('bonusBackground');
        this.gameFlag.bonusStarted = true;
        this.bonusWin = this.bonusStatus.win - this.latestResponse.payment;
        // Fill WIN data
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});
        this.Legends.showWinFeatures();
        this.setBonusStatusText();

        this.Buttons.disableAllButtons();
        this.gameFlag.bonusStart = true;
        this.gameFlag.bonusStarted = true;

        this.processReelResponse(this.latestResponse);
        this.showAdditionalBonusImage(this.getStageChild('bonusContainer'));
    }

    startBonusButton = () => {
        if (this.setState() === 'BEFORE_BONUS') return;  // to prevent call this function by mouse and keyboard
        this.setState('BEFORE_BONUS');
        App.updateButton('start', {disabled: true});
        this.getStageChild('bonusContainer').interactive = false;
        this.getStageChild('bonusContainer').removeChildren();
        this.dropDownSymbols(this.bonusRoll.bind(this));
    };

    endBonusButton = () => {
        this.setBackground('mainArea');
        this.getStageChild('bonusContainer').removeChildren();
        App.updateButton('start', {disabled: true});
        this.dropDownSymbols(this.endBonus.bind(this));
    };

    playFeatureSound(currentFeature, featureIndex, features) {
        let soundFile = null;
        switch (currentFeature.uc) {
            case 'WIN_LINE':
                soundFile = 'win-line';
                break;
            case 'SCATTER':
                soundFile = 'scatterSound';
                break;
        }

        soundFile && App.Sounds.stopSound(soundFile);
        soundFile && App.Sounds.playSound(soundFile);
    }

    /**
     * Call after all book animation ended
     */
    bonusRoll() {
        App.updateButton('start', {disabled: true});
        const container = this.getStageChild('bonusContainer');
        container.removeChildren();
        this.showAdditionalBonusImage(container);
        this.animationBeforeBonusRoll();  // instead of this.startBonusRoll();
    }

    startRoll() {
        this.getState() === 'IDLE' && this.start();
        this.getState() === 'IDLE_BONUS' && this.bonusRoll();  // instead of this.startBonusRoll();
    }

    restoreRoll() {
        JL().debug(`-- Restore roll - ${JSON.stringify(this.latestResponse)}`);
        App.Sounds.playSound('background');
        this.Legends.showJackpot();
        this.processReelResponse(this.latestResponse);
    }
}
