import React from 'react';

import './styles.less';
import App from './../../index';

export default function Waiting({progress}) {
    return (
        <div className='anticipation'>
            {progress ?
                <>
                    <span className='anticipation-percentage'>{progress}%</span>
                    <span className='anticipation-text'>{App.language.loadingImages}</span>
                </> :
                <img className='anticipation-spinner' src={App.images[0].src} alt='loader picture'/>

            }
        </div>
    );
}
