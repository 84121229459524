import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';
import CountUp from './../../../modules/countUp';

import App from './../../../index';
import GameAmatic from './../../amatic/game';
import Lines from './lines';
import GambleAmatic from './../../amatic/gamble';
import InfoScreen from '../../infoScreen';

/* PIXI aliases */
const Text = PIXI.Text,
    AnimatedSprite = PIXI.AnimatedSprite;

export default class AdmiralNelson extends GameAmatic {
    constructor() {
        super();
        this.id = 'admiral-nelson';
        this.name = 'Admiral Nelson';
        this.scatter = 8;
        this.scatters = [9, 10, 11];
        this.buttonsPanelShadow = 'none no-blur';
        this.reelFilter = [[10, 11], [9, 10, 11], [9, 11], [9, 10, 11], [9, 10]];

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 120, y: 90},
            bonusInBonusFrame: {x: 120, y: 150},
            endBonusFrame: {x: 120, y: 150}
        };

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 0, 5, 20, 100]},                       // 0 - 'J'
            {regularDelay: 100, payment: [0, 0, 0, 10, 40, 150]},                      // 1 - 'Q'
            {regularDelay: 100, payment: [0, 0, 0, 10, 40, 150]},                      // 2 - 'K'
            {regularDelay: 100, payment: [0, 0, 0, 10, 40, 150]},                      // 3 - 'A'
            {regularDelay: 100, payment: [0, 0, 5, 15, 75, 250]},                      // 4 - medal
            {regularDelay: 100, payment: [0, 0, 5, 25, 100, 500]},                     // 5 - anchor
            {regularDelay: 100, payment: [0, 0, 5, 50, 200, 1000]},                    // 6 - girl
            {regularDelay: 100, payment: [0, 0, 10, 100, 1000, 5000], bonusDelay: 70}, // 7 - admiral (wild)
            {regularDelay: 100, payment: [0, 0, 2, 5, 20, 100]},                       // 8 - gun (scatter)
            {regularDelay: 100, payment: [0, 0, 0, 100, 0, 0], bonusDelay: 75},        // 9 - ship left
            {regularDelay: 100, payment: [0, 0, 0, 100, 0, 0], bonusDelay: 50},        // 10 - ship center
            {regularDelay: 100, payment: [0, 0, 0, 100, 0, 0], bonusDelay: 50}         // 11 - ship right
        ];

        this.imageResources = {
            main: this.mergePath({mainArea: 'area/main.png'}),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                minimizeSymbols: 'minimizeSymbols.png',
                bonusArea: 'area/bonus.png',
                frame: 'bonus/frame.png',
                frame1: 'bonus/frame1.png',
                frame3: 'bonus/frame3.png',
                x2: 'bonus/x2.png'
            }),
            atlas: this.mergePath([
                'bonus/scatterSymbols.json',
                'bonus/bonusSymbols1.json',
                'bonus/bonusSymbols2.json',
                'bonus/bonusSymbols3.json',
                'regularLongSymbols.json'
            ])
        };
        this.gameSounds = {
            soundClass: 'amatic',
            sounds: [
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'bonus-game-end', alias: 'bonusGameEnd', path: '/audio/amatic/'},
                {name: 'bonus-waiting', alias: 'bonusWaiting', loop: true, path: '/audio/amatic/'},
                {name: 'add-free-spin', alias: 'addFreeSpin', loop: true, path: '/audio/amatic/'},
                {name: 'scatter'},
                {name: 'scatter2'},
                {name: 'nelson'},
                {name: 'teaser_1', path: '/audio/amatic/'},
                {name: 'teaser_2', path: '/audio/amatic/'},
                {name: 'teaser_3', path: '/audio/amatic/'},
                {name: 'bonus-background', loop: true}
            ],
            path: `/game/games/${this.id}/audio/`
        };
        this.Lines = new Lines(this.mergePath({boxes: 'lines/boxes.png'}));

        this.Gamble = new GambleAmatic(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            activeBlack: 'gamble/black-active.png',
            activeRed: 'gamble/red-active.png',
            inactiveBlack: 'gamble/black-inactive.png',
            inactiveRed: 'gamble/red-inactive.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 4}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 18pt Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#fffe00';
        ctx.shadowColor = 'black';
        ctx.shadowOffsetX = ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 5;

        switch (page) {
            case 1:
                ctx.font = 'bold 12pt Arial';

                // A, K, Q
                this.strokeFillText(ctx, bet * this.symbols[2].payment[5], 695, 195);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[4], 695, 215);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[3], 695, 235);

                // J
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 610, 320);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 610, 340);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 610, 360);

                // scatter (gun)
                this.strokeFillText(ctx, bet * nLines * this.symbols[8].payment[5], 300, 310);
                this.strokeFillText(ctx, bet * nLines * this.symbols[8].payment[4], 300, 330);
                this.strokeFillText(ctx, bet * nLines * this.symbols[8].payment[3], 300, 350);
                this.strokeFillText(ctx, bet * nLines * this.symbols[8].payment[2], 300, 370);

                // WILD (admiral)
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], 420, 178);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], 420, 200);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 420, 222);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[2], 420, 245);

                // girl
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 200, 60);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 200, 80);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 200, 100);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[2], 200, 120);

                // medal
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 200, 185);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 200, 205);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 200, 225);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[2], 200, 245);

                // anchor
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 695, 60);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 695, 80);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 695, 100);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[2], 695, 120);

                ctx.font = 'bold 12pt Arial';
                this.strokeFillText(ctx, '3 SHIP symbols on any', 270, 430);
                this.strokeFillText(ctx, ' position win 10', 270, 450);
                this.strokeFillText(ctx, 'BONUSSPINS.', 270, 470);
                this.strokeFillText(ctx, 'BONUS ENTRY WIN: 100', 270, 490);

                ctx.font = 'bold 10pt Arial';
                this.strokeFillText(ctx, 'Each Admiral Nelson symbol will double scatter win!', 590, 485);

                ctx.fillStyle = '#fff';
                this.strokeFillText(ctx, 'MALFUNCTION VOIDS ALL PAYS AND PLAYS', 400, 530);
                break;
            case 2:
                this.strokeFillText(ctx, 'Wins pay only from left to right!', 385, 477);
                break;
            case 3:
                ctx.font = 'bold 14pt Arial';

                this.strokeFillText(ctx, '3 SHIP symbols on any position win 10', 510, 95);
                this.strokeFillText(ctx, 'BONUSSPINS.', 510, 135);
                this.strokeFillText(ctx, 'BONUS ENTRY WIN: 100', 510, 170);

                this.strokeFillText(ctx, '2, 3, 4 or 5 SCATTER symbols pay on any', 510, 235);
                this.strokeFillText(ctx, 'position. Each Admiral Nelson symbol will double ', 510, 265);
                this.strokeFillText(ctx, 'scatter win!', 510, 295);

                this.strokeFillText(ctx, 'ADMIRAL NELSON substitutes all symbols', 510, 385);
                this.strokeFillText(ctx, 'except SCATTER and SHIP symbols', 510, 430);
                break;
            case 4:
                ctx.font = 'bold 14pt Arial';
                this.strokeFillText(ctx, 'Play the Bonus until you have no BONUSSPINS', 510, 80);
                this.strokeFillText(ctx, 'left. BONUSSPINS can be re-triggered during the ', 510, 115);
                this.strokeFillText(ctx, 'Bonus', 510, 150);

                this.strokeFillText(ctx, 'SHIP symbol substitutes all symbols except', 510, 245);
                this.strokeFillText(ctx, 'SCATTER and ADMIRAL symbols during Bonus', 510, 285);

                this.strokeFillText(ctx, 'ADMIRAL NELSON substitutes all symbols', 510, 385);
                this.strokeFillText(ctx, 'except SCATTER and SHIP symbols', 510, 430);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    /**
     * Отрисовка таблички бонусной игры
     */
    showStartBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y);
        const richText1 = new Text('CONGRATULATIONS YOU WIN!', {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 30,
            fontWeight: 'bold',
            fill: '#200f00',
            lineJoin: 'round'
        });
        richText1.x = 180;
        richText1.y = 140;

        const richText3 = new Text('SHIP symbol substitutes all \n symbols except SCATTER \n and ADMIRAL symbols \n during Bonus.', {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 22,
            fontWeight: 'bold',
            fill: '#200f00',
            lineJoin: 'round'
        });
        richText3.x = 340;
        richText3.y = 320;

        const richText2 = new Text(`10 BONUSSPINS`, {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 55,
            fontWeight: 'bold',
            fill: '#200f00',
            stroke: '#ab936c',
            strokeThickness: 5,
            lineJoin: 'round'
        });
        richText2.x = 180;
        richText2.y = 220;

        parentContainer.addChild(richText1);
        parentContainer.addChild(richText3);
        parentContainer.addChild(richText2);
    }

    /**
     * Отрисовка таблички бонус в бонусе
     */
    showBonusInBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y, 'frame1');
        setTimeout(() => {
            App.updateButton('start', {
                disabled: false,
                title: 'start',
                handler: () => this.drawBonusStep(this.latestResponse.features)
            });
            App.Sounds.stopSound('addFreeSpin');
            this.showPressAnyButton(false);
        }, 5300);

        const richText1 = new Text('BONUSSPINS', {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 30,
            fontWeight: 'bold',
            fill: '#200f00',
            lineJoin: 'round'
        });
        richText1.x = 300;
        richText1.y = 170;

        parentContainer.addChild(richText1);

        const props = {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 55,
            fontWeight: 'bold',
            fill: '#200f00',
            stroke: '#ab936c',
            strokeThickness: 5,
            lineJoin: 'round'
        };
        const won = new Text(`${10}`, props);
        won.position.set(250, 285);
        const total = new Text(`${this.bonusStatus.total - 10}`, props);
        total.position.set(490, 285);
        parentContainer.addChild(won, total);

        const options = {
            values: {
                total: {start: 10, end: 0, current: 10},
                won: {
                    start: this.bonusStatus.total - 10,
                    end: this.bonusStatus.total,
                    current: this.bonusStatus.total - 10
                }
            },
            duration: 4300
        };

        setTimeout(() => {
            App.Sounds.playSound('addFreeSpin');
            const countUp = new CountUp(options);
            countUp.onTick(values => {
                won.text = values.total.current.toFixed(0);
                total.text = values.won.current.toFixed(0);
            });
            countUp.start();
        }, 1000);
    }

    /**
     * Отрисовка таблички окончания бонусной игры
     */
    showEndBonusFrame(parentContainer, {x, y}, {win, total}) {
        this.showBonusFrame(parentContainer, x, y, 'frame3');
        const richText1 = new Text('CONGRATULATIONS YOU WIN!', {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 30,
            fontWeight: 'bold',
            fill: '#200f00',
            lineJoin: 'round'
        });
        richText1.x = 180;
        richText1.y = 180;

        const richText3 = new Text(`BONUSSPINS PLAYED: ${this.bonusStatus.total}`, {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 30,
            fontWeight: 'bold',
            fill: '#200f00',
            lineJoin: 'round'
        });
        richText3.x = 220;
        richText3.y = 360;

        const richText2 = new Text(`\n${this.bonusStatus.win}`, {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 90,
            fontWeight: 'bold',
            fill: '#200f00',
            stroke: '#ab936c',
            strokeThickness: 5,
            lineJoin: 'round'
        });
        richText2.x = 400 - (this.bonusStatus.win.toString().length * 22);
        richText2.y = 130;

        parentContainer.addChild(richText1);
        parentContainer.addChild(richText3);
        parentContainer.addChild(richText2);
    }

    /**
     * Draw all bonus animation after bonus 'press any button'
     * @param parentContainer
     * @param bonusSymbol - current bonus symbol in extension
     */
    drawBonusAnimation(parentContainer, bonusSymbol) {
        setTimeout(() => this.bonusRoll(), 1000);
    }

    /**
     * Show bonus message with bonus symbol or with bonus game win.
     * @param isFirstBonus {boolean} TRUE if this is the message for starting bonus game.
     */
    drawBonusAskButton(isFirstBonus = false) {
        let isLast = !isFirstBonus && this.bonusStatus && this.bonusStatus.remain === 0;

        this.stopAnimateFeature();
        this.setBonusSprite();
        setTimeout(() => {
            this.drawBonusFrame(isFirstBonus, isLast, this.getStageChild('bonusContainer'), this.coordinatesBonusFrame);
        }, 1000);

        this.gameFlag.bonusStart = true;
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});

        isLast = this.bonusStatus && this.bonusStatus.remain === 0;
    }

    drawBonusFrame(first, last, parentContainer, coordinates) {
        parentContainer.removeChildren();
        App.Sounds.stopSound('bonus-background');

        const {startBonusFrame, bonusInBonusFrame, endBonusFrame} = coordinates;
        if (first) {
            this.setBonusSprite();
            this.playBonusGameSound();
            this.setBackground('bonusArea');
            App.Sounds.playSound('bonusWaiting');
            this.showStartBonusFrame(parentContainer, startBonusFrame);
            App.updateButton('start', {
                disabled: false,
                title: 'start',
                handler: () => this.startBonusAnimation(parentContainer)
            });
        }
        if (last) {
            parentContainer.removeChildren();
            this.setRegularSprite();
            App.updateButton('start', {disabled: true});
            this.setBackground('mainArea');
            this.showEndBonusFrame(parentContainer, endBonusFrame, this.bonusStatus);
            this.playEndBonusGameSound();
            setTimeout(() => this.endBonus(), 5000);
        }
        if (!first && !last) {
            this.showBonusInBonusFrame(parentContainer, bonusInBonusFrame);
            App.updateButton('start', {disabled: true});
        }
    }

    /**
     * Prepare game behaviour after bonus 'press any button' message
     */
    startBonusAnimation = parentContainer => {
        App.Sounds.stopSound('bonusWaiting');
        this.clearPressAnyButton();
        this.Buttons.disableAllButtons();
        App.updateButton('start', {disabled: true});
        this.gameFlag.bonusStarted = true;
        this.drawBonusAnimation(parentContainer, this.bonusRollSymbol);
    };

    /**
     * Start scatter animations
     * @param features
     */
    bonusEntrance(features) {
        App.Sounds.playSound('scatter');
        this.stopAnimateFeature();
        this.drawTopAnimation(this.getStageChild('bonusContainer'));
        App.updateButton('start', {disabled: true});

        const scatterFeature = features.find(({uc}) => uc === 'SPECIAL_SYMBOL');
        this.playScatterAnimation(scatterFeature, () => // call after scatter played
            this.drawBonusAskButton(this.isFreeRoll(features) && !this.bonusStatus));
    }

    setScatterSprite(scatterFeature) {
        let featureIndex = 0;
        this.latestResponse.features.forEach((item, index) => {
            if (item.uc === 'SPECIAL_SYMBOL') {
                featureIndex = index;
            }
        });
        const container = this.getStageChild('linesContainer');
        scatterFeature.positions = [];
        this.createFeatureInfo(this.latestResponse.features[featureIndex], container, true);

        this.latestResponse.screen.forEach((reel, reelIndex) => {
            reel.forEach((symbolObj, rowIndex) => {
                if (symbolObj === 9 || symbolObj === 10 || symbolObj === 11) {
                    scatterFeature.positions.push({reel: reelIndex, row: rowIndex});
                }
            });
        });

        scatterFeature.positions.forEach(position => {
            const {reel, row} = position;
            const symbolObj = this.reelMatrix[reel][row];
            symbolObj.image = 'scatter';
            symbolObj.loop = false;
            symbolObj.symbol = 9;
            this.Roll.updateSymbolSprite(symbolObj);
            symbolObj.sprite.play();
            symbolObj.symbol = reel === 0 ? 9 : reel === 2 ? 10 : reel === 4 ? 11 : 11;
            symbolObj.image = 'bonus';
        });
    }

    /**
     * Draw long scatter animation before bonus
     * @param scatterFeature
     * @param callback
     */
    playScatterAnimation(scatterFeature, callback) {
        this.setScatterSprite(scatterFeature);
        // get first scatter position
        const {reel, row} = scatterFeature.positions[0];
        const symbolObj = this.reelMatrix[reel][row];

        // call after first scatter played
        symbolObj.sprite.onComplete = () => {
            symbolObj.sprite.onComplete = null; // clear event
            callback();
        };

        this.Legends.setText('features', {text: 'scatterPays', value: scatterFeature.payment});

        this.bonusWin += scatterFeature.payment || 0;
        this.roundWin += scatterFeature.payment || 0;

        this.Legends.setText('win', {text: 'win', value: this.bonusWin});
        this.roundWin && this.Legends.setStatus('creditsWon', this.roundWin);
    }

    additionalFilter = reelMas => {
        reelMas.forEach((vector, reelIndex) => {     // прорисовка лент на канвах
            vector.forEach((symbol, rowIndex) => {
                if (reelIndex === 2 && symbol === 9) {
                    reelMas[reelIndex][rowIndex] = 10;
                }
                if (reelIndex === 4 && symbol === 9) {
                    reelMas[reelIndex][rowIndex] = 11;
                }
            });
        });
        return reelMas;
    };

    isBonusSymbolWin = () => false; // no win in the most games redefined at book of ra class

    animateSymbolsInLine(feature) {
        const {uc, number, reels, positions} = feature;

        uc === 'SCATTER' ?
            positions.forEach(pos => {
                const {reel, row} = pos;
                const symbolObj = this.reelMatrix[reel][row];
                symbolObj.image = 'regular';
                symbolObj.loop = false;
                symbolObj.symbol = 8;
                symbolObj.sprite.onComplete = null;
                this.Roll.updateSymbolSprite(symbolObj);
                symbolObj.sprite.play();
            }) :
            this.reelMatrix.forEach((reel, reelIndex) => {
                reel.forEach((symbolObj, rowIndex) => {
                    const {coordinates} = this.Lines.lines[number];
                    symbolObj.image = this.getSymbolImageInLine(symbolObj.symbol);
                    feature.symbol === 7 && this.setRegularLongSprite(feature.reels.length, symbolObj);
                    symbolObj.loop = false;
                    this.Roll.updateSymbolSprite(symbolObj);
                    symbolObj.sprite.onComplete = null;
                    coordinates[reelIndex] === rowIndex && reels.includes(reelIndex) ?
                        symbolObj.sprite.play() : symbolObj.sprite.gotoAndStop(0);
                });
            });
        // disable all boxes
        uc === 'SCATTER' && this.Lines.drawBoxes(this.getStageChild('boxesContainer'), -1);
    }

    setRegularLongSprite(featureLength, symbolObj) {
        if (
            (featureLength > 3 &&
                !this.gameFlag.bonusStart &&
                this.symbols[symbolObj.symbol].regularLongSteps) || symbolObj.symbol === 7
        ) {
            symbolObj.image = 'regularLong';
        }
    }

    /**
     * Process reels response from server.
     * @param response - Socket response 'ROLL'
     */
    processReelResponse(response) {
        this.latestResponse = response;
        this.latestResponse.screen = this.additionalFilter(this.latestResponse.screen);
        this.setState('RESPONSE_RECEIVED');
        this.setBonusRollSymbol(); // for bonus game roll symbol
        this.prepareToRollAnimation(response);
    }

    onRotationDone() {
        JL().debug(`-- Rotation done (fps: ${App.System.statistics.fps})`);
        App.updateButton('start', {disabled: true});
        this.roundWin = 0;
        const scatterAnimation = this.decideScatterAnimation(this.latestResponse.screen);

        scatterAnimation ?
            // going to x2 animation before animate feature
            this.fillReelsWithBonusSymbol(this.latestResponse.screen, () => this.regularRotationDone()) :
            this.regularRotationDone();
    }

    regularRotationDone() {
        const {features, payment} = this.latestResponse;
        if (payment > 0 || this.isFreeRoll(features) || features.length) {
            // There is a win
            this.setState('SHOW_WIN_LINES');
            this.startAnimateFeature(features);
        } else {
            if (this.isBonus()) {
                if (this.bonusStatus && this.bonusStatus.remain > 0) {
                    this.roundFinished(false);
                } else {
                    this.Legends.setRoundFinText();
                    this.finishBonus();
                }
            } else {
                this.roundFinished();
                this.Legends.setRoundFinText();
            }
        }
    }

    decideScatterAnimation(screen) {
        let scatter1 = 0;
        let scatter2 = 0;
        screen.forEach(reel => {
            reel.forEach(symbol => {
                if (symbol === 8) { // gun
                    scatter1++;
                }
                if (symbol === 7) { // admiral
                    scatter2++;
                }
            });
        });
        return scatter1 >= 2 && scatter2;  // at least one admiral and two gun
    }

    fillReelsWithBonusSymbol(screen, callback) {
        App.Sounds.playSound('nelson');
        const positions = [];
        const positions2 = [];
        screen.forEach((reel, reelIndex) => {
            reel.forEach((symbol, rowIndex) => {
                if (symbol === 7) { // admiral
                    positions.push({reel: reelIndex, row: rowIndex});
                }
            });
        });

        screen.forEach((reel, reelIndex) => {
            reel.forEach((symbol, rowIndex) => {
                if (symbol === 8) { // admiral
                    positions2.push({reel: reelIndex, row: rowIndex});
                }
            });
        });

        this.latestResponse.features.sort((a, b) => {
            if (a.uc > b.uc || a.uc === 'SCATTER') return -1;
            if (a.uc < b.uc) return 1;
            return 0;
        });

        const container = this.getStageChild('bonusContainer');
        positions.forEach((pos, index) => {
            const {reel, row} = pos;
            const sprite = new AnimatedSprite(this.getSpriteTextures({
                toFrame: 20, fromFrame: 0, image: 'x2',
                width: 136, height: 120, colCount: 10
            }));
            sprite.animationSpeed = 0.15;
            sprite.loop = false;
            sprite.position.set(this.reelXCoordinates[reel], this.reelTop + row * this.symbolHeight);
            sprite.play();
            container.addChild(sprite);

            if (index === 0) {
                sprite.onComplete = () => {
                    App.Sounds.stopSound('nelson');
                    this.getStageChild('bonusContainer').removeChildren();
                    callback();
                    positions2.forEach((pos, index) => {
                        const {reel, row} = pos;
                        const sprite = new AnimatedSprite(this.Roll.textures['regular'][8]);
                        sprite.animationSpeed = 0.15;
                        sprite.loop = false;
                        sprite.position.set(this.reelXCoordinates[reel], this.reelTop + row * this.symbolHeight);
                        sprite.play();
                        container.addChild(sprite);
                        if (index === 0) {
                            sprite.onComplete = () => {
                                this.getStageChild('bonusContainer').removeChildren();
                            };
                        }
                    });
                };
            }
        });
    }

    playFeatureSound(currentFeature, featureIndex, features) {
        let soundFile = null;
        switch (currentFeature.uc) {
            case 'WIN_LINE':
                soundFile = 'win-line';
                break;
            case 'SCATTER':
                soundFile = 'scatter2';
                break;
        }

        soundFile && App.Sounds.stopSound(soundFile);
        soundFile && App.Sounds.playSound(soundFile);
    }

    getFeatureDelay(currentFeature, features) {
        let delay = 0;

        switch (currentFeature.uc) {
            case 'WIN_LINE':
                delay = !this.gameFlag.bonusStarted ? this.winLineFeatureDelay : this.winLineFeatureDelay / 2;
                break;
            case 'SCATTER':
            case 'SPECIAL_SYMBOL':
                delay =
                    features.some(({uc}) => uc === 'FREE_ROLL') &&
                    !features.some(({uc}) => uc === 'WIN_LINE') ?
                        0 : this.winLineFeatureDelay;
                break;
        }
        return delay;
    }

    setBonusSprite() {
        this.reelMatrix.forEach(reel => {
            reel.forEach(symbolObj => {
                if (symbolObj.symbol === 9 || symbolObj.symbol === 10 || symbolObj.symbol === 11) {
                    symbolObj.image = 'bonus';
                    this.Roll.updateSymbolSprite(symbolObj);
                }
            });
        });
    }

    getSymbolImageInLine = symbolObj =>
        this.scatters.includes(symbolObj) && this.gameFlag.bonusStarted ? 'bonus' : 'regular';

    /**
     * Check symbol image
     * Can be redefined to 'static'/'regular'/'bonus'/'additional'
     * Call for Roll.initReels() and Roll.updateFullClipMatrix()
     */
    getSymbolImageType = symbolIndex =>
        this.scatters.includes(symbolIndex) &&
        this.gameFlag.bonusStart || this.getState() === 'IDLE_BONUS' ?
            'bonus' : 'static';

    cleanBeforeRoll() {
        this.stopAnimateFeature();
        this.stopWaitingAnimation();
        App.removePopupMessage();
        App.System.resetRollStatistics();
        this.InfoScreen.update({timeout: false, page: 1});
        this.extraBet && this.updateExtraBetButtons(false);
        this.latestResponse = null;
        this.SymbolInfo.remove(false);

        if (!this.isBonus() && this.getState() !== 'IDLE_BONUS') {
            this.getStageChild('bonusContainer').removeChildren();
            this.Legends.showJackpot();
            App.Money.withDraw(this.gameSettings.getBet());
            App.updateState('moneyParams', {
                credits: App.Money.getCredit(),
                money: App.Money.getMoney()
            });
        }
        this.Legends.clearStatus();
        this.Legends.clearText('features');
        this.Legends.clearText('win');
    }
}
