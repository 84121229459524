import App from './../../../index';
import GameDeluxe5x4 from './../../deluxe/gameDeluxe5x4';
import Lines40 from './../../deluxe/lines40';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';

export default class SparkyFruits extends GameDeluxe5x4 {
    constructor() {
        super();
        this.id = 'sparky-fruits';
        this.name = 'Sparky Fruits';
        this.buttonsPanelShadow = 'mid';
        this.transparentBackground = true;
        this.BigWin.enabled = true;

        this.symbols = [
            {regularDelay: 50, payment: [0, 0, 0, 8, 40, 100]},    // 0 - cherry
            {regularDelay: 50, payment: [0, 0, 0, 8, 40, 100]},    // 1 - lemon
            {regularDelay: 50, payment: [0, 0, 0, 8, 40, 100]},    // 2 - orange
            {regularDelay: 50, payment: [0, 0, 0, 8, 40, 100]},    // 3 - plum
            {regularDelay: 50, payment: [0, 0, 0, 20, 80, 200]},   // 4 - grape
            {regularDelay: 50, payment: [0, 0, 0, 20, 80, 200]},   // 5 - melon
            {regularDelay: 50, payment: [0, 0, 0, 40, 100, 300]},  // 6 - bell
            {regularDelay: 50, payment: [0, 0, 4, 60, 200, 1000], offset: 20}, // 7 - digit '7'
            {regularDelay: 50, payment: [0, 0, 0, 2, 20, 500]},    // 8 - star
            {regularDelay: 50, payment: [0, 0, 0, 0, 0, 0]}        // 9 - WILD
        ];
        this.imageResources = {
            main: this.mergePath({
                mainArea: `area/${App.System.resolution}/main.png`,
                background: 'area/background.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.gameSounds = {soundClass: 'prefergames'};

        this.Lines = new Lines40();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            smallCard: 'gamble/card-small.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            inactiveBlack: 'gamble/black-active.png',
            inactiveRed: 'gamble/red-active.png',
            activeBlack: 'gamble/black-inactive.png',
            activeRed: 'gamble/red-inactive.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 2}); // number of game info states

        // changed border radius to 0 for 'gamble-button'
        this.Gamble.gambleButtonsSize = {
            width: '17em',
            height: '12.35em',
            borderRadius: '0',
            bottom: '12.5em',
            left: '4em',
            right: '4em'
        };
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     * @param lang
     */
    drawInfoPage(ctx, page, nLines, bet, lang) {
        const {
            combinationOfKind,
            combinationLeftToRight,
            prizesOnSelectedLines,
            ScatterPayAtAnyPosition,
            HighestWinPaid,
            ScatterWinsAddedToLineWins,
            prizesShownInCredits,
            MalfunctionVoidsAllPays
        } = App.languageCollection[lang];
        const textProps = {
            font: 'bold 15pt Franklin Gothic Medium',
            textAlign: 'center',
            fillStyle: '#fff',
            strokeStyle: '#000',
            lineWidth: 3,
            shadowColor: '#000',
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 2,
            lineHeight: 30
        };
        switch (page) {
            case 1:
                ctx.font = textProps.font;
                ctx.textAlign = 'left';
                ctx.fillStyle = textProps.fillStyle;
                ctx.strokeStyle = textProps.strokeStyle;
                ctx.lineWidth = textProps.lineWidth;
                ctx.shadowColor = textProps.shadowColor;
                ctx.shadowOffsetX = textProps.shadowOffsetX;
                ctx.shadowOffsetY = textProps.shadowOffsetY;
                ctx.shadowBlur = textProps.shadowBlur;

                // cherry, lemon
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 660, 412);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 660, 436);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 660, 461);
                // orange, plum
                this.strokeFillText(ctx, bet * this.symbols[1].payment[5], 120, 412);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[4], 120, 436);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[3], 120, 461);

                // melon
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 600, 223);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 600, 246);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 600, 270);
                // grape
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 180, 223);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 180, 246);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 180, 270);

                // bell
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 600, 93);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 600, 119);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 600, 145);
                // 7
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], 180, 85);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], 180, 110);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 180, 133);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[2], 180, 156);
                // star
                this.strokeFillText(ctx, bet * nLines * this.symbols[8].payment[5], 435, 119);
                this.strokeFillText(ctx, bet * nLines * this.symbols[8].payment[4], 435, 145);
                this.strokeFillText(ctx, bet * nLines * this.symbols[8].payment[3], 435, 170);
                break;
            case 2:
                const rulesTitle = 'RULES';
                const rules = combinationOfKind +
                    combinationLeftToRight +
                    prizesOnSelectedLines +
                    ScatterPayAtAnyPosition +
                    HighestWinPaid +
                    ScatterWinsAddedToLineWins +
                    prizesShownInCredits +
                    MalfunctionVoidsAllPays;

                ctx.font = 'italic bold 24pt Arial';
                ctx.strokeStyle = '#000';
                ctx.fillStyle = '#ff0000';
                ctx.lineWidth = 3;
                ctx.textAlign = 'center';
                this.strokeFillText(ctx, rulesTitle, 400, 205, 500);
                this.drawSplitText(ctx, rules, 400, 230, 600, textProps);
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }
}
