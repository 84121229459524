import * as PIXI from 'pixi.js-legacy';

import AppConstructor from './App';
import './modules/events';
import './css/layout.css';

// Polyfills
import 'dom4';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

const {appSettings} = window;
const App = new AppConstructor(appSettings);
export default App;

// bing application for access from console
window.App = App;
window.PIXI = PIXI;

// Start Application
// Start Application
appSettings.mode === 'info' ?
    App.initInfo() :
    App.init();
