import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import GameEgt from './../../egt/game';
import Lines from './../../egt/lines5';
import GambleEgt from './../../egt/gamble';
import InfoScreen from '../../infoScreen';

/* PIXI aliases */
const Text = PIXI.Text,
    Sprite = PIXI.Sprite,
    Container = PIXI.Container;

export default class UltimateHot extends GameEgt {
    constructor() {
        super();
        this.id = 'ultimate-hot';
        this.name = 'Ultimate Hot';
        this.buttonsPanelShadow = 'mid';
        this.reelFilter = [[], [], []];
        this.reelXCoordinates = [60, 291, 522]; // magic numbers - x coordinates where reels start
        this.symbolHeight = 126; // height of aa single symbol
        this.symbolWidth = 218; // height of aa single symbol
        this.reels = 3;
        this.reelRows = 3; // number of rows per reel

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 120, y: 90},
            bonusInBonusFrame: {x: 120, y: 150},
            endBonusFrame: {x: 120, y: 150}
        };

        this.symbols = [
            {regularDelay: 90, skipSteps: 15, payment: [0, 0, 0, 40]},  // 0 - cherry
            {regularDelay: 90, skipSteps: 15, payment: [0, 0, 0, 40]},  // 1 - lemon
            {regularDelay: 90, skipSteps: 15, payment: [0, 0, 0, 40]},  // 2 - orange
            {regularDelay: 90, skipSteps: 15, payment: [0, 0, 0, 40]},  // 3 - plum
            {regularDelay: 90, skipSteps: 15, payment: [0, 0, 0, 80]},  // 4 - grape
            {regularDelay: 90, skipSteps: 15, payment: [0, 0, 0, 80]},  // 5 - bell
            {regularDelay: 90, skipSteps: 15, payment: [0, 0, 0, 100]}, // 6 - wathermelon
            {regularDelay: 90, skipSteps: 15, payment: [0, 0, 0, 200]}, // 7 - star
            {regularDelay: 90, skipSteps: 15, payment: [0, 0, 0, 300]}  // 8 - 77
        ];

        this.imageResources = {
            main: this.mergePath({mainArea: 'area/main.png'}),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                minimizeSymbols: 'minimizeSymbols.png',
                gambleArea: 'area/gamble.png'
            })
        };

        this.gameSounds = {
            soundClass: 'egt',
            sounds: [{name: 'win-line'}],
            path: `/game/games/${this.id}/audio/`
        };
        this.Lines = new Lines(this.mergePath({boxes: 'lines/boxes.png'}));
        this.Gamble = new GambleEgt();
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
        this.InfoScreen.format = 'png';
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 19pt Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = 'white';
        ctx.shadowColor = 'black';
        ctx.shadowOffsetX = ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 5;

        switch (page) {
            case 1:
                ctx.font = 'bold 12pt Arial';

                // cherry, lemon
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 400, 375);
                // wathermelon
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 120, 247);
                // bell
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 675, 247);
                // seven
                this.strokeFillText(ctx, bet * this.symbols[8].payment[3], 120, 149);
                // grape
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 670, 149);
                break;
            case 2:
                ctx.font = 'bold 13pt Arial';
                ctx.textAlign = 'left';
                // gamble limit
                this.strokeFillText(ctx, (this.Gamble.limit * 100) / App.Money.getCurrentDenomination(), 500, 242);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    createFeatureInfo(feature, container) {
        const {number, symbol, reels, positions, payment, uc} = feature, // get current feature params
            ucReels = uc === 'WIN_LINE' ? reels.length : positions.length, // check 'SCATTER' feature

            textProps = {
                font: 'Arial',
                fontSize: 14,
                fontWeight: 600,
                fill: '#f0e7cb'
            },
            winSymbolsContainer = new Container();
        winSymbolsContainer.position.set(-100, -170);
        winSymbolsContainer.zIndex = 200;
        winSymbolsContainer.name = 'winSymbolsContainer';
        container.addChild(winSymbolsContainer);
        const statusLine = new Text(
            `${uc === 'WIN_LINE' ? App.language.line.toUpperCase() : 'SCATTERED'} ${uc === 'WIN_LINE' ? number + 1 : ''}: `,
            textProps
        );

        const statusPayment = new Text(
            `${App.language.win.toUpperCase()}: ${payment} `,
            textProps
        );

        statusLine.position.set(210, 470);
        statusPayment.position.set(480, 470);

        for (let i = 0; i <= ucReels - 1; i++) {
            const minimizeSymbol = new Sprite(this.getSpriteTextures({
                image: 'minimizeSymbols',
                fromFrame: symbol,
                colCount: this.symbols.length,
                width: 35,
                height: 20
            })[0]);

            minimizeSymbol.position.x = 420 + (32 * i);
            minimizeSymbol.position.y = 639;
            winSymbolsContainer.addChild(minimizeSymbol);
        }

        container.addChild(statusLine, statusPayment);
    }

    playFeatureSound(currentFeature, featureIndex, features) {
        let soundFile = null;
        if (featureIndex === 0) {
            switch (currentFeature.uc) {
                case 'WIN_LINE':
                case 'SCATTER':
                    soundFile = 'win-line';
                    break;
            }

            soundFile && App.Sounds.stopSound(soundFile);
            soundFile && App.Sounds.playSound(soundFile);
        }
    }
}
