import {JL} from 'jsnlog';
import * as PIXI from 'pixi.js-legacy';

import CustomLines from './../../CustomLines';
import App from './../../../index';

/* PIXI aliases */
const AnimatedSprite = PIXI.AnimatedSprite;

export default class Lines extends CustomLines {
    constructor(imageResources) {
        super(imageResources);
        this.imageResources = imageResources;

        this.boxSettings = {
            lineBoxWidth: 0,
            lineBoxHeight: 0,
            firstRightBox: 20
        };

        this.linesSettings = {
            ...this.linesSettings,
            horizontalLineLength: 150,
            lineColor: '#ff0045'
        };

        this.lines = {
            0: {coordinates: [1, 1, 1, 1, 1], boxes: [{x: 10, y: 268}]},
            1: {coordinates: [2, 2, 2, 2, 2], boxes: [{x: 10, y: 411}]},
            2: {coordinates: [0, 0, 0, 0, 0], boxes: [{x: 10, y: 144}]},
            3: {coordinates: [3, 3, 3, 3, 3], boxes: [{x: 10, y: 536}]},
            4: {coordinates: [1, 2, 3, 2, 1], boxes: [{x: 10, y: 278}]},
            5: {coordinates: [2, 1, 0, 1, 2], boxes: [{x: 10, y: 420}]},
            6: {coordinates: [0, 0, 1, 2, 3], boxes: [{x: 10, y: 154}]},
            7: {coordinates: [3, 3, 2, 1, 0], boxes: [{x: 10, y: 526}]},
            8: {coordinates: [2, 3, 3, 3, 2], boxes: [{x: 10, y: 400}]},
            9: {coordinates: [1, 0, 0, 0, 1], boxes: [{x: 10, y: 260}]},
            10: {coordinates: [0, 1, 2, 3, 3], boxes: [{x: 10, y: 134}]},
            11: {coordinates: [3, 2, 1, 0, 0], boxes: [{x: 10, y: 546}]},
            12: {coordinates: [2, 3, 2, 1, 2], boxes: [{x: 10, y: 390}]},
            13: {coordinates: [1, 0, 1, 2, 1], boxes: [{x: 10, y: 248}]},
            14: {coordinates: [0, 1, 0, 1, 0], boxes: [{x: 10, y: 164}]},
            15: {coordinates: [3, 2, 3, 2, 3], boxes: [{x: 10, y: 556}]},
            16: {coordinates: [1, 2, 1, 0, 1], boxes: [{x: 10, y: 288}]},
            17: {coordinates: [2, 1, 2, 3, 2], boxes: [{x: 10, y: 380}]},
            18: {coordinates: [0, 1, 1, 1, 0], boxes: [{x: 10, y: 180}]},
            19: {coordinates: [3, 2, 2, 2, 3], boxes: [{x: 10, y: 516}]},
            20: {coordinates: [1, 1, 2, 3, 3], boxes: [{x: 950, y: 550}]},
            21: {coordinates: [2, 2, 1, 0, 0], boxes: [{x: 950, y: 112}]},
            22: {coordinates: [0, 1, 2, 2, 3], boxes: [{x: 950, y: 477}]},
            23: {coordinates: [3, 2, 1, 1, 0], boxes: [{x: 950, y: 106}]},
            24: {coordinates: [1, 2, 2, 2, 3], boxes: [{x: 950, y: 486}]},
            25: {coordinates: [2, 1, 1, 1, 0], boxes: [{x: 950, y: 189}]},
            26: {coordinates: [0, 0, 1, 0, 0], boxes: [{x: 950, y: 118}]},
            27: {coordinates: [3, 3, 2, 3, 3], boxes: [{x: 950, y: 495}]},
            28: {coordinates: [2, 2, 3, 2, 2], boxes: [{x: 950, y: 395}]},
            29: {coordinates: [1, 1, 0, 1, 1], boxes: [{x: 950, y: 273}]},
            30: {coordinates: [0, 0, 0, 1, 2], boxes: [{x: 950, y: 360}]},
            31: {coordinates: [3, 3, 3, 2, 1], boxes: [{x: 950, y: 225}]},
            32: {coordinates: [2, 3, 3, 2, 1], boxes: [{x: 950, y: 230}]},
            33: {coordinates: [1, 0, 0, 1, 2], boxes: [{x: 950, y: 352}]},
            34: {coordinates: [0, 1, 1, 2, 3], boxes: [{x: 950, y: 502}]},
            35: {coordinates: [3, 2, 2, 1, 0], boxes: [{x: 950, y: 92}]},
            36: {coordinates: [2, 3, 2, 1, 0], boxes: [{x: 950, y: 98}]},
            37: {coordinates: [1, 0, 1, 2, 3], boxes: [{x: 950, y: 470}]},
            38: {coordinates: [0, 1, 2, 3, 2], boxes: [{x: 950, y: 345}]},
            39: {coordinates: [3, 2, 1, 0, 1], boxes: [{x: 950, y: 254}]}
        };
        this.lineMap = [
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
            10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
            30, 31, 32, 33, 34, 35, 36, 37, 38, 39
        ];

        JL().debug('-- Lines40 initialized');
    }

    drawWinBoxes(lineIndex, winReels, parentContainer) {
        parentContainer.sortableChildren = true;
        const line = this.lines[lineIndex];

        line.coordinates.forEach((rowIndex, reelIndex) => {
            if (winReels.includes(reelIndex) && this.winBoxes) {
                const winLinesBoxSprite = new AnimatedSprite(App.Game.getSpriteTextures({
                    image: 'winLinesBox', colCount: 5,
                    toFrame: 32, width: 200, height: 200
                }));
                winLinesBoxSprite.name = `${reelIndex}:${rowIndex}`;
                winLinesBoxSprite.position.x = App.Game.reelXCoordinates[reelIndex] - 30;
                winLinesBoxSprite.position.y = App.Game.symbolHeight * (rowIndex) + App.Game.reelTop - 40;
                winLinesBoxSprite.animationSpeed = 0.385;
                winLinesBoxSprite.zIndex = 1;
                winLinesBoxSprite.play();
                parentContainer.addChild(winLinesBoxSprite);
            }
        });
    }

    drawBoxes() {

    }
}
