import {JL} from 'jsnlog';

import LinesEgt from './../egt/lines';

export default class Lines10 extends LinesEgt {
    constructor() {
        super();
        this.boxSettings = {
            ...this.boxSettings,
            firstRightBox: 10,
            horizontalLineLength: 60
        };

        this.lines = {
            0: {coordinates: [1, 1, 1, 1, 1], lineDeltaY: 0, color: '#ffd8ff'},
            1: {coordinates: [0, 0, 0, 0, 0], lineDeltaY: 0, color: '#de1800'},
            2: {coordinates: [2, 2, 2, 2, 2], lineDeltaY: 0, color: '#009200'},
            3: {coordinates: [0, 1, 2, 1, 0], lineDeltaY: 20, color: '#ce8f63'},
            4: {coordinates: [2, 1, 0, 1, 2], lineDeltaY: -20, color: '#647100'},
            5: {coordinates: [0, 0, 1, 2, 2], lineDeltaY: 10, color: '#ff24ff'},
            6: {coordinates: [2, 2, 1, 0, 0], lineDeltaY: -10, color: '#ffffad'},
            7: {coordinates: [1, 2, 2, 2, 1], lineDeltaY: -20, color: '#0096bd'},
            8: {coordinates: [1, 0, 0, 0, 1], lineDeltaY: 20, color: '#7321bd'},
            9: {coordinates: [0, 1, 1, 1, 0], lineDeltaY: 30, color: '#00ffff'}
        };

        JL().debug('-- Lines10 initialized');
    }

    getBoxesStyle = () => ({
        activeBoxStyle: {
            fill: '#ffcc33',
            fontSize: 12,
            stroke: '#3065e9',
            strokeThickness: 5,
            lineJoin: 'round'
        },
        inactiveBoxStyle: {
            dropShadowDistance: 1,
            fill: '#fafafa',
            fontSize: 19,
            strokeThickness: 2,
            lineJoin: 'round'
        },
        colLines: [1, 3, 5, 7, 10],
        coordinates: {
            0: {x: 26, y: 408},
            1: {x: 775, y: 408}
        }
    })
}
