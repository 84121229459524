import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../../../index';
import GameHot from './../../novomatic/gameHot';
import Lines5 from './../../novomatic/lines5';
import GambleNovomatic from './../../novomatic/gamble';
import InfoScreen from '../../infoScreen';

/* PIXI aliases */
const AnimatedSprite = PIXI.AnimatedSprite;

export default class UltraHot extends GameHot {
    constructor() {
        super();
        this.id = 'ultra-hot';
        this.name = 'Ultra Hot';
        this.reelTop = 95;
        this.buttonsPanelShadow = 'strong';

        // text container positions
        this.paymentsContainerCoord = {x: 755, y: 141};

        this.symbols = [
            {regularDelay: 500, payment: [0, 0, 0, 5]},   // 0 - symbol 'X'
            {regularDelay: 500, payment: [0, 0, 0, 40]},  // 1 - cherry
            {regularDelay: 500, payment: [0, 0, 0, 40]},  // 2 - orange
            {regularDelay: 500, payment: [0, 0, 0, 40]},  // 3 - lemon
            {regularDelay: 500, payment: [0, 0, 0, 40]},  // 4 - plum
            {regularDelay: 500, payment: [0, 0, 0, 60]},  // 5 - BAR
            {regularDelay: 500, payment: [0, 0, 0, 200]}, // 6 - star
            {regularDelay: 500, payment: [0, 0, 0, 750]}  // 7 - '77'
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: `area/${App.System.resolution}/main.png`,
                logo: 'area/logo.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.gameSounds = {soundClass: 'novomatic'};
        this.Lines = new Lines5();
        this.Gamble = new GambleNovomatic({gambleArea: `/game/games/${this.id}/img/gamble/gamble-area.png`});
        this.InfoScreen = new InfoScreen({pages: 1}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines - current lines count
     * @param bet - current bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 28pt Arial';
        ctx.strokeStyle = '#000';
        ctx.fillStyle = '#ff9a00';
        ctx.lineWidth = 3;
        ctx.textAlign = 'center';

        this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 630, 207);
        this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 630, 285);
        this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 630, 363);
        this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 405, 460);
        this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 540, 550);
    }

    addWaitingAnimationSprites(parentContainer) {
        const sprite = new AnimatedSprite(this.getLogoAnimationTextures({toFrame: 10}));
        sprite.name = 'animatedLogo';
        sprite.position.set(72, 0);
        sprite.animationSpeed = 0.15;
        sprite.play();
        parentContainer.addChild(sprite);

        this.drawPayments(parentContainer, this.paymentsContainerCoord);
    }

    /**
     * Function to start logic of Animate feature first step
     */
    startAnimateFeature(features) {
        JL().debug('-- Start animate feature');

        // check is doubled win
        const multiplier = features.find(({payment, symbol}) =>
            payment === this.symbols[symbol].payment[3] * this.gameSettings.getBetLineCredit() * 2);

        // if doubled, divide on multiplier (2)
        multiplier && features.forEach((feature, featureIndex) => {
            this.latestResponse.features[featureIndex].payment /= 2;

            // set additional logo animation
            const sprite = this.getStageChild('waitingContainer').getChildByName('animatedLogo');
            sprite.loop = false;
            sprite.textures = this.getLogoAnimationTextures({toFrame: 21});
            sprite.play();
            sprite.onComplete = () => {
                sprite.loop = true;
                sprite.textures = this.getLogoAnimationTextures({fromFrame: 19, toFrame: 21});
                sprite.play();
            };
        });

        this.Legends.showWinFeatures();
        this.prepareToAnimateFeature(features);
        this.animateFeature(features);

        // change handler to stop animation win line
        !this.isBonus() && App.updateButton('start', {
            disabled: false, title: 'stop',
            handler: this.speedUpWinLineAnimation
        });
    }

    getLogoAnimationTextures = ({fromFrame = 0, toFrame}) =>
        this.getSpriteTextures({
            fromFrame, toFrame, image: 'logo',
            width: 656, height: 70, colCount: 1
        });

    goIdle() {
        // reset logo animation
        const sprite = this.getStageChild('waitingContainer').getChildByName('animatedLogo');
        sprite.textures = this.getLogoAnimationTextures({toFrame: 10});
        sprite.play();

        JL().debug('-- Go idle');
        this.showWaitingAnimation();
        this.Lines.updateBoxesState();
        this.extraBet && this.updateExtraBetButtons();
        this.Buttons.setDefaultGameButtons();
    }
}
