import React from 'react';
import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import './styles.less';
import App from './../../../index';
import Game from './../../Game';
import InfoScreen from './info';
import RouletteButtons from './buttons';
import Bets from './bets';
import Grid from './grid';
import createCells from './cells';
import rouletteNumbers from './rouletteNumbers';

import ball from './ball.json';
import spriteBall from './ball_sprite.json';
import soundsSprite from './audio/sprite.json';

/* PIXI aliases */
const Texture = PIXI.Texture,
    Sprite = PIXI.Sprite,
    AnimatedSprite = PIXI.AnimatedSprite,
    Container = PIXI.Container,
    Text = PIXI.Text,
    Graphics = PIXI.Graphics;

export default class EuropeanRoulette extends Game {
    constructor() {
        super();
        this.id = 'european-roulette';
        this.name = 'European Roulette';
        this.buttonsPanelShadow = 'none no-blur';
        this.numberHistory = null;
        this.numberStatistics = null;
        this.ballTextures = {};
        this.Bets = new Bets();
        this.Buttons = new RouletteButtons();

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                wheel1: 'area/wheel_1.png',
                wheel2: 'area/wheel_2.png',
                turret: 'area/turret_sprite.png',
                center: 'area/center.png',
                ball: 'area/ball.png',
                table_atlas: 'area/table_atlas.png',
                mainAtlas: 'area/main_atlas.png',
                graphics: 'area/graphics.png',
                newChips: 'area/chips.png',
                zoomResult: 'area/result_zoom_all.png',
                win_nameplate: 'area/win_table.png',
                info_sections: 'area/info_sections.png',
                betInfo: 'area/betInfo.png'
            })
        };

        this.gameSounds = {
            soundClass: 'roulette',
            sounds: [this.getSoundSprite()],
            path: `/game/games/${this.id}/audio/`
        };
        this.InfoScreen = new InfoScreen({pages: 1}); // number of game info states

        App.updateState('buttons', {visualization: 'roulette'});
        App.View.setState({activePrizes: false});
        this.createCells = createCells.bind(this);
    }

    /**
     * Start image loading
     * Call after webSocket message 'ROULETTE-SETUP'
     */
    initGame() {
        if (App.configs.mode === 'info') {         // fake data to make info mode work
            this.Bets.chips = [1, 5, 10, 25, 100];
            this.Bets.limits = {
                chance: {min: 10, max: 1000},
                number: {min: 1, max: 100}
            };
            this.numberHistory = {uc: 'NUMBER-HISTORY', numbers: Array(0)};
            this.numberStatistics = {
                black: 50,
                even: 50,
                high: 50,
                low: 50,
                numbers: [{number: 0, amount: 0},
                    {number: 1, amount: 0},
                    {number: 2, amount: 0},
                    {number: 3, amount: 0},
                    {number: 4, amount: 0},
                    {number: 5, amount: 0},
                    {number: 6, amount: 0},
                    {number: 7, amount: 0},
                    {number: 8, amount: 0},
                    {number: 9, amount: 0},
                    {number: 10, amount: 0},
                    {number: 11, amount: 0},
                    {number: 12, amount: 0},
                    {number: 13, amount: 0},
                    {number: 14, amount: 0},
                    {number: 15, amount: 0},
                    {number: 16, amount: 0},
                    {number: 17, amount: 0},
                    {number: 18, amount: 0},
                    {number: 19, amount: 0},
                    {number: 20, amount: 0},
                    {number: 21, amount: 0},
                    {number: 22, amount: 0},
                    {number: 23, amount: 0},
                    {number: 24, amount: 0},
                    {number: 25, amount: 0},
                    {number: 26, amount: 0},
                    {number: 27, amount: 0},
                    {number: 28, amount: 0},
                    {number: 29, amount: 0},
                    {number: 30, amount: 0},
                    {number: 31, amount: 0},
                    {number: 32, amount: 0},
                    {number: 33, amount: 0},
                    {number: 34, amount: 0},
                    {number: 35, amount: 0},
                    {number: 36, amount: 0}],
                odd: 50,
                red: 50,
                uc: 'NUMBER-STATISTICS'
            };
        }

        if (App.System.platform !== 'mobile') {
            App.addLoaderMessage(`${App.language.initializingGame} '${this.name}'`);
            document.title = `${this.name} - ${App.name.split(' ').join('')}`; // set game name to title
            App.Sounds.createGameSounds(this.gameSounds);
            this.setState('GAME_IMAGES_LOADING');
            this.updateCredits();

            App.showLoadPercent(0);
            App.addLoaderMessage(App.language.loadingImages);
            this.loadResources(this.getResourcesToPreload(), () => {
                App.renderGameComponent();
                App.updateButton('close', {visible: true});
            });
        } else {
            this.resetGame();
            App.showSocketMessage('unSupport', false, 'lock');
            App.updateButton('close', {
                disabled: false, visible: true,
                handler: () => {
                    App.exitGame();
                    App.resetErrorMessage();
                }
            });
            App.updateState('error', {reloadButton: false});
        }
    }

    /**
     * Collect all images to one object
     */
    getResourcesToPreload = () => {
        const {main = {}, jsonAnimations = {}, atlas = [], video = {}, fonts = {}} = this.imageResources;

        return {
            main, jsonAnimations, atlas,
            images: {...(App.configs.doubleScreen ? this.InfoScreen.getResources() : {})},
            video, fonts
        };
    };

    /**
     * Collect all images to one object
     */
    getResourcesToLoad = () => ({main: {}, jsonAnimations: {}, atlas: [], images: {}, video: {}, fonts: {}});

    /**
     * Create all scenes
     */
    createPIXIContainers(container) {
        const sprite = new Sprite(this.getTexture('mainArea'));
        sprite.name = 'mainArea';
        sprite.interactive = true;
        sprite.position.set(-(this.gameWidth - this.gameFieldWidth) / 2, 0);
        sprite
            .on('pointerover', this.setDefaultHoverAlpha)
            .on('pointerout', this.setDefaultHoverAlpha);
        container.addChild(sprite);

        this.createRoulette(container);
        this.createBetInfo(container);
        this.createCells(container);
        this.createChips(container);
        this.calcCovering();
    }

    stopAnimateFeature() {

    }

    createRoulette(parentContainer) {
        const container = new Container();
        container.position.x = 1;
        container.name = 'roulette';

        // create background image
        const backgroundSprite = new Sprite(new Texture(this.getTexture('center'), {
            x: 0, y: 453, width: 335, height: 248
        }));
        backgroundSprite.position.set(-6, 3);
        container.addChild(backgroundSprite);

        // create spinning wheel with numbers
        const props = {width: 234, height: 140, direction: 'vertical'};
        const wheelSprite = new AnimatedSprite([
            ...this.getSpriteTextures({...props, toFrame: 112, image: 'wheel1', rowCount: 14}),
            ...this.getSpriteTextures({...props, toFrame: 48, image: 'wheel2', rowCount: 8})
        ]);
        wheelSprite.position.set(47, 45);
        wheelSprite.name = 'wheel';
        wheelSprite.animationSpeed = 0.5;
        wheelSprite.play();
        container.addChild(wheelSprite);

        // create spinning center
        const centerSprite = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 20, image: 'center',
            width: 150, height: 90, rowCount: 5, direction: 'vertical'
        }));
        centerSprite.position.set(89, 70);
        centerSprite.name = 'center';
        centerSprite.animationSpeed = 0.5;
        centerSprite.play();
        container.addChild(centerSprite);

        // create spinning turret
        const turretSprite = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 160, image: 'turret',
            width: 38, height: 74, rowCount: 9, direction: 'vertical'
        }));
        turretSprite.position.set(144, 39);
        turretSprite.name = 'turret';
        turretSprite.animationSpeed = 0.5;
        turretSprite.play();
        container.addChild(turretSprite);

        parentContainer.addChild(container);

        // create ball textures
        const {slices} = ball.resources;
        Object.keys(slices).forEach(key => {
            const {size, pos} = slices[key];
            this.ballTextures[key] = new Texture(this.getTexture('ball'), {
                x: pos[0], y: pos[1], width: size[0], height: size[1]
            });
        });
    }

    createBetInfo(parentContainer) {
        const betInfoContainer = new Container();
        betInfoContainer.alpha = 0;
        betInfoContainer.name = 'betInfoContainer';
        betInfoContainer.position.set(424, 44);
        betInfoContainer.zIndex = 1;

        parentContainer.addChild(betInfoContainer);

        const betInfoSprite = new Sprite(this.getTexture('betInfo'));
        betInfoSprite.name = 'betInfoSprite';
        betInfoContainer.addChild(betInfoSprite);

        const props = {
            fontFamily: 'Arial',
            fontSize: 10,
            fill: '#fbf4ff',
            lineJoin: 'round'
        };
        const betText = new Text('BET:', props);
        betText.name = 'betText';
        betText.position.set(5, 3);
        betInfoContainer.addChild(betText);

        const typeOfBet = new Text(Grid[0].typeOfBet, {...props, fontSize: 10});
        typeOfBet.name = 'typeOfBet';
        typeOfBet.position.set(5, 24);
        betInfoContainer.addChild(typeOfBet);

        const pays = new Text('Pays', props);
        pays.position.set(5, 38);
        pays.name = 'pays';
        betInfoContainer.addChild(pays);

        const min = new Text('MIN.:', props);
        min.position.set(5, 56);
        min.name = 'min';
        betInfoContainer.addChild(min);

        const max = new Text('MAX.:', props);
        max.position.set(5, 70);
        max.name = 'max';
        betInfoContainer.addChild(max);
    }

    createChips(parentContainer) {
        const chipsContainer = new Container(); // main container is for all chips stuff
        const shadowContainer = new Container(); // container is for shadow of chips
        const containerForChips = new Container(); // container is for containers with chips and text on it

        chipsContainer.name = 'chips';
        chipsContainer.position.set(49, 66);
        parentContainer.addChild(chipsContainer);

        containerForChips.name = 'containerForChips';
        containerForChips.scale.set(0.8);
        containerForChips.position.set(32, 50);

        shadowContainer.position.set(49, 66);
        shadowContainer.name = 'shadowContainer';

        chipsContainer.addChild(shadowContainer, containerForChips);

        const ticker = this.app.ticker;
        const chips = this.Bets.getChips();

        const resetDefaultChips = () => {
            const chipSprite = chipsContainer.getChildByName('containerForChips').getChildByName(this.Bets.getChip().toString());
            if (chipSprite) {
                chipSprite.position.y += 8;
                chipSprite.scale.set(0.35);
                ticker.remove(this.chipAnimations);
            }
        };

        const chipsTextures = this.getSpriteTextures({
            toFrame: chips.length, image: 'newChips',
            width: 118.7, height: 86, colCount: 7
        });

        ticker.add(this.chipAnimations);
        chipsTextures.forEach((chip, index) => {
            const dibsContainer = new Container(); // container for chip and its text
            dibsContainer.name = `${chips[index]}`;
            dibsContainer.sortableChildren = true;
            dibsContainer.scale.set(0.35);
            dibsContainer.position.set(164 + 35 * index, 268 + 26 * index);
            containerForChips.addChild(dibsContainer);
            // set default chip animation
            index === this.Bets.getChipPos() && (dibsContainer.position.y -= 8);

            const chipSprite = new Sprite(chip);
            chipSprite.interactive = true;
            chipSprite.buttonMode = true;
            chipSprite.anchor.set(0.5);

            const shadowSprite = new Sprite(new Texture(this.getTexture('graphics'), {
                x: 135, y: 233,
                width: 97, height: 83
            }));

            shadowSprite.anchor.set(0.35);
            shadowSprite.position.set(110 + 28 * index, 195 + 20.5 * index);
            shadowSprite.scale.set(0.33);
            shadowSprite.name = `${chips[index]}`;

            const chipsText = new Text(chips[index], {
                fontFamily: 'Arial',
                fontWeight: 'bold',
                fontSize: 43,
                fill: '#000000',
                lineJoin: 'round'
            });
            chipsText.anchor.set(0.5);
            chipsText.name = `${chips[index]}`;
            chipsText.position.set(1 - 0.009 * index, -5 + 0.3 * index);

            chipSprite
                .on('pointerover', () => dibsContainer.scale.set(0.37))
                .on('pointerout', () =>
                    chips[index] !== this.Bets.getChip() && dibsContainer.scale.set(0.35))
                .on('pointerdown', () => {
                    if (chips[index] !== this.Bets.getChip()) {
                        App.Sounds.playSound('sprite', 'select_chip');
                        resetDefaultChips();
                        ticker.remove(this.chipAnimations);

                        this.Bets.setChipPos(index);
                        dibsContainer.position.y -= 8;
                        ticker.add(this.chipAnimations);
                    }
                });

            dibsContainer.addChild(chipSprite, chipsText);
            shadowContainer.addChild(shadowSprite);
        });
    }

    /**
     * function for chips animation.
     * @param animatedStarted
     */
    chipAnimations = (animatedStarted = Date.now()) => {
        const chip = this.Bets.getChip().toString();
        const parentContainer = this.getStageChild('chips');
        const shadowSprite = parentContainer.getChildByName('shadowContainer').getChildByName(chip);
        const dibsContainer = parentContainer.getChildByName('containerForChips').getChildByName(chip);
        const time = Date.now() - animatedStarted;

        dibsContainer.scale.y = 0.035 * Math.sin(time * 0.01) + 0.35;
        shadowSprite.scale.y = -0.035 * Math.sin(time * 0.01) + 0.35;
    };

    stopWaitingAnimation() {
        if (this.app) {
            const ticker = this.app.ticker;
            ticker.remove(this.chipAnimations);
        }
    }

    goIdle() {
        JL().debug('-- Go idle');
        this.Buttons.setDefaultGameButtons();
        this.InfoScreen.update();
        this.getStageChild('cells').interactiveChildren = true;
        this.getStageChild('chips').interactiveChildren = true;
        this.getStageChild('chips').alpha = 1;
        this.hideWin();
        this.updateButtons();
        this.enableRestoreBetsButton();
    }

    /**
     * Get current game idle state
     * @returns {string}
     */
    getIdleState = () => 'ROULETTE_IDLE';

    checkSpin = () => {
        const bets = this.Bets.get();
        const denomination = App.Money.getCurrentRouletteDenomination();
        const total = this.Bets.getTotal() * denomination;
        const limits = [];

        bets.forEach(obj => {
            const {idx, bet} = obj;
            const {typeOfBet, limit, numbers} = Grid[idx];
            const {min} = this.Bets.getLimit(limit, numbers.length);
            bet < min && limits.push({typeOfBet, min});
        });

        // if not enough money
        total > App.Money.getCents() ?
            this.createPopup('gotNoMoney') :
            limits.length ?
                this.createPopup('lowMinBet', {typeOfBet: limits[0].typeOfBet, min: limits[0].min}) :
                this.spin(bets);
    };

    spin(bets) {
        this.setState('WAITING_RESPONSE');
        this.disableStage();
        const denomination = App.Money.getCurrentRouletteDenomination();

        App.Money.withDraw(this.Bets.getTotal() * denomination);
        App.updateState('moneyParams', {
            credits: App.Money.getRouletteCredit(),
            money: App.Money.getMoney()
        });

        App.Sounds.playSound('sprite', 'Spin_button');
        App.System.sendMetric({param: `rollCount.${this.id}`});
        App.Socket.send(JSON.stringify({uc: 'SPIN', bets, denomination}));
        this.Bets.restoreStack = this.Bets.get();
        App.updateButton('cancel', {disabled: true});
    };

    /**
     * Process spin response from server.
     * @param response - Socket response 'SPIN'
     */
    processSpinResponse(response) {
        const {number, totalWin} = response;

        this.ballAnimation(number, this.getStageChild('roulette'), () => {
            App.updateButton('cancel', {disabled: true});
            App.updateButton('info', {disabled: false});
            this.showZoomResult(number);
            setTimeout(() => this.takeChipsAnimation(response), totalWin ? 3000 : 0);
            totalWin && this.showWin(response);
            this.Bets.clear();
            this.updateWin(totalWin);
        });
    }

    restoreSpin(response) {
        JL().debug(`-- Restore spin - ${JSON.stringify(response)}`);
        this.disableStage();
        response.bets.forEach(bet => this.Bets.stack.push({...bet, type: 'add'}));
        setTimeout(() => this.updateChipsOnGrid(), 100); // async for wait PIXI stage render
        this.calcCovering();
        App.updateState('moneyParams', {
            credits: App.Money.getRouletteCredit(),
            money: App.Money.getMoney(),
            insurance: App.Money.getInsurance().toFixed(2)
        });
        this.processSpinResponse(response);
    }

    getDenominations = () => App.Money.rouletteDenominations.map(key => key / 100);

    disableStage() {
        const betInfoContainer = this.getStageChild('betInfoContainer');
        betInfoContainer.alpha = 0;
        this.Buttons.disableAllButtons();
        this.getStageChild('cells').interactiveChildren = false;
        this.getStageChild('chips').interactiveChildren = false;
        this.getStageChild('chips').alpha = 0.5;
        this.InfoScreen.active && this.InfoScreen.disable();
        this.hideZoomResult();
        App.removePopupMessage();
    }

    ballAnimation(number, parentContainer, callback) {
        const old = parentContainer.getChildByName('ballContainer');
        old && old.destroy(); // reset previous container
        const wheel = parentContainer.getChildByName('wheel');

        const {ui} = spriteBall;
        const {ball_circle, ball_lay} = ui;

        const fallIndex = Math.floor(Math.random() * 6); // random animation index
        const fallNumbers = { // roulette number where fall animation ended
            ball_fall: this.getWinBallPosition(13) + 1,
            ball_fall_1: this.getWinBallPosition(27),
            ball_fall_2: this.getWinBallPosition(33) + 1,
            ball_fall_3: this.getWinBallPosition(4),
            ball_fall_4: this.getWinBallPosition(12) + 2,
            ball_fall_5: this.getWinBallPosition(1) + 2
        };
        const fallType = Object.keys(fallNumbers)[fallIndex];

        let animationSteps = 160 - // wheel spin
            this.getWinBallPosition(number) - // steps to wheel end
            wheel.currentFrame - 2 +
            fallNumbers[fallType]; // steps to fall position
        animationSteps < 160 && (animationSteps += 160); // add one wheel circle, prevent short spin
        const {frames} = ui[fallType];
        const fallStep = animationSteps - frames.length;

        const animation = [...Array(animationSteps)].map((item, index) => {
            return index > fallStep ? // check for paste fall frames
                {index, ...frames[index - fallStep]} :
                // add circle animation, 39 is last frame before fall, example: [21-39,0-39,0-39]
                {index, ...ball_circle.frames[(index + 40 - (fallStep + 1) % 40) % 40]};
        });

        const container = new Container();
        container.scale.set(0.445);
        container.position.set(-7, 0);
        container.name = 'ballContainer';

        const setBallPos = (frame, positions, sound = false) => {
            const {offset = [-100, -100]} = positions[frame];

            frame === 3 && sound && App.Sounds.playSound('sprite', 'ball_circle');

            if (frame === fallStep && sound) {
                App.Sounds.stopSound('sprite');
                App.Sounds.playSound('sprite', `ball_stop_${fallIndex}`);
            }
            ballSprite.position.set(offset[0], offset[1]);
        };

        const ballSprite = new AnimatedSprite(animation.map(({id}) => this.ballTextures[id]));
        ballSprite.loop = false;
        ballSprite.name = 'ball';
        ballSprite.play();
        ballSprite.onFrameChange = frame => frame !== 0 && setBallPos(frame, animation, true);
        ballSprite.onComplete = () => {
            const lay = [...ball_lay.frames];
            const newLay = lay.map((item, index) =>
                lay[(index + 160 - 1 + fallNumbers[fallType] - this.getWinBallPosition(13)) % lay.length]);

            ballSprite.loop = true;
            ballSprite.textures = newLay.map(({id}) => this.ballTextures[id]);
            ballSprite.onFrameChange = frame => setBallPos(frame, newLay);
            ballSprite.play();
            callback();
        };
        ballSprite.animationSpeed = 0.5;
        container.addChild(ballSprite);
        parentContainer.addChild(container);
    }

    getWinBallPosition = number => Math.round(rouletteNumbers.indexOf(number) * 160 / 37);

    takeChipsAnimation({bets, totalWin}) {
        const ticker = this.app.ticker;
        const container = this.getStageChild('cells').getChildByName('chipsContainer');
        const winPos = {x: -90, y: 420};
        const losePos = {x: 510, y: -30};
        const steps = 60;
        totalWin && App.Sounds.playSound('sprite', 'you_win');

        // add animation for each chip
        container.children.forEach((child, index) => {
            const {name, localTransform: {tx, ty}} = child;
            const start = Date.now();

            const getStep = () => Math.round((Date.now() - start) / 20);
            const getPos = ({x, y}, step) => [
                Math.round(tx - (tx - x) / steps * step),
                Math.round(ty + (y - ty) / steps * step)
            ];

            const winWay = () => {
                const step = getStep();
                child.position.set(...getPos(winPos, step));
                if (step >= steps) {
                    ticker.remove(winWay);
                    index === container.children.length - 1 && this.takeChipsAnimationEnd(container, totalWin);
                }
            };

            const loseWay = () => {
                const step = getStep();
                child.position.set(...getPos(losePos, step));
                if (step >= steps) {
                    ticker.remove(loseWay);
                    index === container.children.length - 1 && this.takeChipsAnimationEnd(container, totalWin);
                }
            };

            ticker.add(bets.find(({idx}) => idx === name) ? winWay : loseWay);
        });
    }

    takeChipsAnimationEnd(container, totalWin) {
        container.destroy();
        this.Bets.clear();
        this.calcCovering();
        App.Sounds.playSound('sprite', 'chips_draging');
        totalWin ?
            App.Socket.send(JSON.stringify({uc: 'TRANSFER-START'})) :
            this.roundFinished();
    }

    /**
     * Check idle state -> update number statistics
     * Socket response 'BALANCE'
     */
    checkNextRoll() {
        this.updateCredits();

        if (this.getState() === this.getIdleState()) {
            this.goIdle();
            App.Socket.send(JSON.stringify({uc: 'NUMBER-STATISTICS'}));
        }
    }

    /**
     * Update balance, in depending on denomination
     */
    updateCredits() {
        App.updateState('moneyParams', {credits: App.Money.getRouletteCredit()});
    }

    changeDenomination = denominationIndex => {
        App.Sounds.playSound('click');
        this.Legends.showJackpot();
        this.stopAnimateFeature();
        this.Legends.setRoundFinText();
        this.Buttons.closeWrap();
        App.Money.setPosRouletteDenominationIndex(denominationIndex);
        this.InfoScreen.update();
    };

    animateCredits(amount, isTransfer) {
        const chipsContainer = this.getStageChild('chips');
        const parentContainer = chipsContainer.parent;
        chipsContainer.destroy();
        this.createChips(parentContainer);
        this.endAnimateCredits(isTransfer);
    }

    addBet = item => {
        const bet = this.Bets.getChip();
        const {idx, typeOfBet, limit, numbers} = item;
        const {max} = this.Bets.getLimit(limit, numbers.length);
        const betObj = this.Bets.get().find(obj => obj.idx === idx);
        const canPlaceMax = (betObj ? betObj.bet : 0) + bet <= max;
        const canPlaceMoney = this.Bets.getTotal() + bet <= App.Money.getRouletteCredit();

        if (canPlaceMax) {
            if (canPlaceMoney) {
                JL().debug(`-- Bet: ${JSON.stringify({idx, typeOfBet, numbers, bet})}`);
                this.Bets.add({idx, bet});
                this.updateTotalBet();
                App.removePopupMessage();
            } else {
                this.createPopup('gotNoMoney');
            }
        } else {
            this.createPopup('highMaxBet', {typeOfBet, max});
        }
    };

    removeBet = idx => {
        const betObj = this.Bets.get().find(obj => obj.idx === idx);
        if (betObj) {
            this.Bets.remove(idx, this.Bets.getChip());
            this.updateTotalBet();
        }
    };

    doubleBets = () => {
        const canPlace = this.Bets.get().every(({idx, bet}) => {
            const {typeOfBet, limit, numbers} = Grid[idx];
            const {max} = this.Bets.getLimit(limit, numbers.length);
            const canDouble = bet * 2 <= max;
            !canDouble && this.createPopup('highMaxBet', {typeOfBet, max});
            return canDouble;
        });

        if (canPlace) {
            if (this.Bets.getTotal() * 2 <= App.Money.getRouletteCredit()) {
                this.Bets.double();
                App.Sounds.playSound('sprite', 'button_click');
                JL().debug(`-- Double bets. Total: ${JSON.stringify(this.Bets.getTotal())}`);
                this.updateTotalBet();
            } else {
                this.createPopup('gotNoMoney');
            }
        }
    };

    cancelAction = () => {
        const action = this.Bets.cancelLastAction();
        if (action) {
            JL().debug(`-- Cancel action: ${action.type}`);
            App.Sounds.playSound('sprite', 'button_click');
            this.updateTotalBet();
        }
    };

    clearBets = () => {
        JL().debug('-- Clear bets');
        this.Bets.clear();
        App.Sounds.playSound('sprite', 'button_click');
        this.updateTotalBet();
    };

    restoreBets = () => {
        const restoredBets = this.Bets.restoreStack.map(item => ({type: 'add', ...item}));
        const total = restoredBets.reduce((total, {bet}) => total + bet, 0);

        if (total <= App.Money.getRouletteCredit()) {
            this.Bets.stack = restoredBets;
            App.Sounds.playSound('sprite', 'button_click');
            this.updateTotalBet();
        } else {
            this.createPopup('gotNoMoney');
        }
    };

    setDefaultHoverAlpha = () => {
        const cells = this.getStageChild('cells');
        const gridContainer = cells.getChildByName('gridContainer');
        const trackContainer = cells.getChildByName('trackContainer');
        [...gridContainer.children, ...trackContainer.children].forEach(child => child.alpha = 0);

        const info = this.getStageChild('info');
        if (info) {
            const hot = info.getChildByName('HOT NUMBERS');
            const cold = info.getChildByName('COLD NUMBERS');
            const history = info.getChildByName('NUMBERS HISTORY');
            [...hot.children, ...cold.children, ...history.children].forEach(child =>
                child.name === 'item' && (child.alpha = 1));

            const sections = info.getChildByName('sections');
            sections && sections.children.forEach(child => child.alpha = 1);

            const stat = info.getChildByName('stat');
            const children = []; // unite all inserted children
            stat.children.forEach(child => children.push(...child.children));
            children.forEach(child => child.alpha = 1);
        }
    };

    updateTotalBet() {
        const winContainer = this.getStageChild('winContainer');
        winContainer && winContainer.destroy();
        const oldMarker = this.getStageChild('marker');
        oldMarker && oldMarker.destroy();

        this.calcCovering();
        this.updateChipsOnGrid();
        this.updateWin(0);
        this.updateButtons();
    }

    updateButtons() {
        App.updateButton('double', {disabled: !this.Bets.get().length});
        App.updateButton('start', {disabled: !this.Bets.get().length});
        this.Bets.stack.length ? App.updateButton('cancel', { // check actions or restore bets
            disabled: false,
            title: 'undo',
            handler: this.cancelAction
        }) : this.enableRestoreBetsButton();
        App.updateButton('clear', {disabled: !this.Bets.get().length});
    }

    updateChipsOnGrid() {
        const cells = this.getStageChild('cells');
        const old = cells.getChildByName('chipsContainer');
        old && old.destroy(); // reset previous container

        const chipsContainer = new Container();
        chipsContainer.name = 'chipsContainer';
        chipsContainer.sortableChildren = true;

        this.Bets.get().forEach(({idx, bet}) => {
            const {width, height, polygon} = Grid[idx];
            const chipsArr = this.Bets.getChips();

            const gridContainer = this.getStageChild('cells').getChildByName('gridContainer');
            const {localTransform: {tx, ty}} = gridContainer.getChildByName(idx);
            const x = Math.round(tx + width / 2);
            const y = Math.round(ty + polygon[idx === 0 ? 5 : 3] + height / 2);

            const betContainer = new Container();
            betContainer.name = idx;
            betContainer.position.set(x, y);
            betContainer.zIndex = y;
            chipsContainer.addChild(betContainer);

            // fill chips array with max possible values
            const chips = [];
            if (bet > 0) {
                while (bet !== 0) {
                    let chip = chipsArr[0];
                    chipsArr.forEach(availableBet => availableBet <= bet && (chip = availableBet));
                    bet -= chip;
                    chips.push(chip);
                }
            }

            chips.forEach((value, index) => {
                const offset = -index * 2; // offset for each chip

                const sprite = new Sprite(this.getSpriteTextures({
                    fromFrame: chipsArr.indexOf(value), image: 'newChips',
                    width: 118.7, height: 86, colCount: 7
                })[0]);
                sprite.name = `chip_${value}`;
                sprite.position.set(0, offset);
                sprite.anchor.set(0.5);
                sprite.scale.set(0.25);

                const text = new Text(value, {
                    fontFamily: 'Arial',
                    fontWeight: 'bold',
                    fontSize: 11,
                    fill: '#000000',
                    lineJoin: 'round'
                });
                text.name = `text_${value}`;
                text.anchor.set(0.5);
                text.name = `${chips[index]}`;
                text.position.set(-0.5, offset - 1.5);

                betContainer.addChild(sprite, text);
            });
        });

        cells.addChild(chipsContainer);
    }

    updateWin(value) {
        this.roundWin = value;
        this.Buttons.updateGameSettings();
    }

    calcCovering = () => {
        const old = this.getStageChild('covering');
        old && old.destroy(); // reset previous container

        const width = 120, height = 35;

        const container = new Container();
        container.name = 'covering';
        container.position.set(677, 464);

        const background = new Graphics();
        background.beginFill(0xb9b9b9, 0.25);
        background.drawRoundedRect(0, 0, width, height, 8);
        background.endFill();
        container.addChild(background);

        const fullField = [...Array(37)].map((item, index) => index);

        this.Bets.get().forEach(({idx}) => {
            Grid[idx].numbers.forEach(number => {
                const index = fullField.indexOf(number);
                index !== -1 && fullField.splice(index, 1);
            });
        });

        const percent = Math.round((37 - fullField.length) / 37 * 100);
        const cover = new Text(`${App.language.cover}: ${percent}%`.toUpperCase(), {
            fontFamily: 'Arial',
            fontSize: 10,
            fill: '#fff'
        });
        cover.name = 'coveredPercentage';
        cover.anchor.set(0.5);
        cover.position.set(width / 2, 7);
        container.addChild(cover);

        const progressCoordinates = {
            width: width - 10,
            height: 15,
            x: 5,
            y: 17
        };

        // drawing progress bar of covering area
        const progressOuter = new Graphics();
        progressOuter.beginFill(0x000000, 0.25);
        progressOuter.lineStyle(0);
        progressOuter.name = 'progressOuter';
        progressOuter.drawRoundedRect(progressCoordinates.x, progressCoordinates.y, progressCoordinates.width, progressCoordinates.height, 5);
        progressOuter.endFill();
        container.addChild(progressOuter);

        const progressInner = new Container();
        progressInner.name = 'progress';
        progressInner.position.set(3, 3);
        container.addChild(progressInner);

        const cellCount = 20;
        [...Array(cellCount)].forEach((item, index) => {
            const cell = new Graphics();
            cell.beginFill(0x00ff00);
            cell.drawRect(progressCoordinates.x + (progressCoordinates.width - 3) / cellCount * index, progressCoordinates.y, 2, 9);
            cell.endFill();
            progressInner.addChild(cell);
        });

        const mask = new Graphics();
        mask.beginFill(0xff0000);
        mask.drawRect(0, 0, width / 100 * percent, height);
        mask.endFill();
        progressInner.mask = mask;
        container.addChild(mask);

        this.getStage().addChild(container);
    };

    showZoomResult(number) {
        const ticker = this.app.ticker;
        const posX = Math.floor(number / 7);
        const posY = number % 7;
        const width = 174;
        const height = 181;

        // show roulette win number
        const zoomResult = new Sprite(new Texture(this.getTexture('zoomResult'), {
            x: posX * width, y: posY * height,
            width, height
        }));
        zoomResult.name = 'zoomResult';
        zoomResult.position.set(500, 10);
        zoomResult.scale.set(0.65);
        zoomResult.alpha = 0;
        this.getStage().addChild(zoomResult);
        App.Sounds.playSound('sprite', `num_${number}`);

        const fadeIn = () => {
            zoomResult.alpha += 0.02;
            zoomResult.alpha >= 1 && ticker.remove(fadeIn);
        };
        ticker.add(fadeIn);

        this.numberHistory.numbers.unshift(number);
        this.createMarker(number);
    }

    createMarker(idx) {
        const old = this.getStageChild('marker');
        old && old.destroy(); // reset previous marker

        const marker = new Sprite(new Texture(this.getTexture('mainAtlas'), {
            x: 208, y: 560,
            width: 122.5, height: 177
        }));
        marker.name = 'marker';

        // paste marker to the Grid
        const {width, height, polygon} = Grid[idx];
        const gridContainer = this.getStageChild('cells').getChildByName('gridContainer');
        let {worldTransform: {tx, ty}} = gridContainer.getChildByName(idx); // get global position and scale x y
        tx -= this.getStage().worldTransform.tx; // calc 16x9 offset
        ty -= this.getStage().worldTransform.ty; // calc double screen offset
        const scale = this.resizeStage();

        // round coordinates
        tx = Math.round(tx) / scale;
        ty = Math.round(ty) / scale;

        marker.position.set(tx + width / 2, ty + polygon[idx === 0 ? 5 : 3] + height / 2 - 5);
        marker.scale.set(0.25);
        marker.anchor.set(0.5);
        this.getStage().addChild(marker);
    }

    hideZoomResult() {
        // error 'off' of null
        // const ticker = this.app.ticker;
        const sprite = this.getStageChild('zoomResult');
        // const fadeOut = () => {
        //     sprite.alpha = Math.round((sprite.alpha - 0.02) * 100) / 100;
        //     if (sprite.alpha <= 0) {
        //         ticker.remove(fadeOut);
        sprite && sprite.destroy();
        //     }
        // };
        // sprite && ticker.add(fadeOut);
    }

    showWin({totalWin}) {
        const winContainer = new Container();
        winContainer.name = 'winContainer';
        this.getStage().addChild(winContainer);

        const winSprite = new Sprite(this.getTexture('win_nameplate'));
        winSprite.anchor.set(0.5);
        winSprite.position.set(400, 246);
        const props = {
            align: 'center',
            fontFamily: 'Times New Roman',
            fontSize: 40,
            fontWeight: 'bold',
            fill: ['#fbf4ff'],
            lineJoin: 'round'
        };
        const winText = new Text(App.language.youWon.toUpperCase(), props);
        props.fontSize = 55;
        const winValue = new Text(totalWin, props);
        winText.position.set(400, 222);
        winText.anchor.set(0.5);
        winValue.position.set(400, 268);
        winValue.anchor.set(0.5);
        winContainer.addChild(winSprite, winText, winValue);
    }

    hideWin() {
        const container = this.getStageChild('winContainer');
        container && container.destroy();
    }

    showBetInfo(cell, item) {
        const {idx, typeOfBet, limit, numbers, pays} = item;
        const {bet = 0} = this.Bets.get().find(obj => obj.idx === idx) || {};
        const {min, max} = this.Bets.getLimit(limit, numbers.length);
        const betInfoContainer = this.getStageChild('betInfoContainer');
        const fields = betInfoContainer.children;
        const type = ['STRAIGHT', 'SPLIT'].includes(typeOfBet) ?
            `${typeOfBet} ${numbers.join(', ')}` :
            typeOfBet;

        let {worldTransform: {tx, ty}} = cell; // get global position and scale x y
        tx -= this.getStage().worldTransform.tx; // calc 16x9 offset
        ty -= this.getStage().worldTransform.ty; // calc double screen offset
        const scale = this.resizeStage();

        // round coordinates
        tx = Math.round(tx) / scale;
        ty = Math.round(ty) / scale;

        typeOfBet !== 'DOZEN' ?
            betInfoContainer.position.set(tx + 50, ty - 20) :
            betInfoContainer.position.set(tx + 90, ty);

        fields[1].text = `${App.language.bet.toUpperCase()}: ${bet}`;
        fields[2].text = type;
        fields[3].text = `Pays ${pays}`;
        fields[4].text = `MIN.: ${min}`;
        fields[5].text = `MAX.: ${max}`;
    }

    enableRestoreBetsButton() {
        App.updateButton('cancel', {
            disabled: !this.Bets.restoreStack.length,
            title: 'repeatBet',
            handler: this.restoreBets
        });
    }

    playPopupMessageSound() {
        App.Sounds.playSound('sprite', 'max_bet');
    }

    parseNumberStatistics(response) {
        this.numberStatistics = {
            ...response,
            numbers: [...Array(37)].map((item, index) =>
                response.numbers.find(({number}) => number === index) || {number: index, amount: 0})
        };
    }

    /**
     * Reset all game states and animations
     * Call after WebSocket connection lost
     */
    resetGame() {
        this.stopWaitingAnimation();
        this.clearPressAnyButton();
        this.resetCreditAnimation();
        this.BigWin.resetCoinsAnimation();
        App.updateButton('autoStart', {pressed: false});
        this.Buttons.resetWrap();
        this.Legends.setRoundFinText();
        this.InfoScreen.reset();
        App.updateState('buttons', {visualization: App.buttonsType});
        App.removePopupMessage();
        App.View.setState({activePrizes: true});
    }

    /**
     * Scale and resize stage from window to game size
     * @returns {number} - scale coefficient
     */
    resizeStage = () => {
        if (this.app) {
            const canvas = this.app.renderer.view;
            let scale = canvas.offsetHeight < window.innerHeight ?
                window.innerWidth / this.gameWidth :
                window.innerHeight / this.gameHeight;
            scale = Math.round(scale * 1000) / 1000;

            this.app.stage.scale.set(scale);
            this.app.renderer.resize(this.gameWidth * scale, this.gameHeight * scale);

            return scale;
        }
    };

    /**
     * Update PIXI stage after language change
     * @param language - current language collection
     */
    translateStage(language) {
        this.calcCovering();
        this.InfoScreen.update();
    }

    getSoundSprite() {
        const {sounds} = soundsSprite.resources.audio;
        const sprite = {};

        Object.keys(sounds).forEach(key =>
            sprite[key] = sounds[key].frame);
        return {name: 'sprite', sprite};
    }
}
