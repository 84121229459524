import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../../../index';
import GameMegajack from './../../megajack/game';
import Lines21 from './../../megajack/lines21';
import GambleMegajack from './../../megajack/gamble';
import InfoScreen from '../../infoScreen';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Text = PIXI.Text;

export default class Champagne extends GameMegajack {
    constructor() {
        super();
        this.id = 'champagne';
        this.name = 'Champagne';
        this.scatter = 11;
        this.buttonsPanelShadow = 'mid';
        this.screenMultiplier = {win: [-1, -1, -1, -1, -1], mult: [-1, -1, -1, -1, -1]};
        this.screenUnused = [-1, -1, -1, -1, -1];
        this.superBonusStep = 4;
        this.winMas = [150, 5, 10, 100, 20, 50, 100, 150];
        this.waitingTimeout = null;
        this.textBonusBlack = {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 26,
            fontWeight: 'bold',
            stroke: '#282828',
            strokeThickness: 2,
            lineJoin: 'round'
        };

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 3, 5, 20, 100]},        // 0 - черешни
            {regularDelay: 100, payment: [0, 0, 3, 5, 20, 100]},        // 1 - сливы
            {regularDelay: 100, payment: [0, 0, 0, 5, 20, 100]},        // 2 - арбуз
            {regularDelay: 100, payment: [0, 0, 0, 5, 20, 100]},        // 3 - виноград
            {regularDelay: 100, payment: [0, 0, 0, 5, 20, 100]},        // 4 - кокосы
            {regularDelay: 100, payment: [0, 0, 0, 5, 20, 100]},        // 5 - лимон
            {regularDelay: 100, payment: [0, 0, 0, 5, 20, 100]},        // 6 - коктейль
            {regularDelay: 100, payment: [0, 0, 5, 10, 20, 1000]},      // 7 - остров с пальмой
            {regularDelay: 100, payment: [0, 0, 7, 10, 20, 1000]},      // 8 - круизный лайнер
            {regularDelay: 100, payment: [0, 0, 10, 100, 2000, 10000]}, // 9 - символ 'e'      // JOKER
            {regularDelay: 100, payment: [0, 0, 0, 0, 0, 0]},           // 10 - шампанское     // BONUS
            {regularDelay: 100, payment: [0, 0, 0, 0, 0, 1000]}         // 11 - символ '$'     // SCATTER
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                waiting_roll1: 'area/waiting1.png',
                waiting_roll2: 'area/waiting2.png',
                label: 'bonus/label.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                bonusArea: 'area/bonus_area.png',
                bonusArea1: 'area/bonus_area1.png',
                gambleArea: 'area/gamble.png',

                box: 'bonus/box.png',
                boxUsed: 'bonus/box_used.png',
                minimizeSymbols: 'minimizeSymbols.png'
            }),
            atlas: this.mergePath([
                'scatterSymbols.json',
                'additionalSymbols.json'
            ])
        };
        this.imageResources.main.waiting_doubling = '/img/gamble/mega-jack/gamble_waiting.png';

        this.gameSounds = {
            soundClass: 'megajack',
            sounds: [
                {name: 'reels'},
                {name: 'reelsstop'},
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'choosing-select', alias: 'choosingSelect'},
                {name: 'choosing-background', alias: 'choosingBackground', loop: true},
                {name: 'choosing-end', alias: 'choosingEnd'}
            ],
            path: `/game/games/${this.id}/audio/`
        };
        this.Lines = new Lines21();
        this.Gamble = new GambleMegajack();
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states

        // choosing image size, box coordinates for animation
        this.choosing = [      // choosing image size, box coordinates for animation
            {w: 278, h: 350, x: 87, y: 114, textPosition: {x: 137, y: 350}, frameCount: 32, colCount: 6},  // 0 hold
            {w: 278, h: 350, x: 215, y: 114, textPosition: {x: 265, y: 350}, frameCount: 32, colCount: 6}, // 1 hold
            {w: 278, h: 350, x: 343, y: 114, textPosition: {x: 393, y: 350}, frameCount: 32, colCount: 6}, // 2 hold
            {w: 278, h: 350, x: 470, y: 114, textPosition: {x: 520, y: 350}, frameCount: 32, colCount: 6}, // 3 hold
            {w: 278, h: 350, x: 598, y: 114, textPosition: {x: 648, y: 350}, frameCount: 32, colCount: 6}, // 4 hold
            {w: 173, h: 169, x: 95, y: 330, textPosition: {x: 200, y: 350}, frameCount: 8, colCount: 6}, // 5
            {w: 173, h: 169, x: 534, y: 330, textPosition: {x: 640, y: 350}, frameCount: 8, colCount: 6}, // 6
            {w: 172, h: 233, frameCount: 32, colCount: 6}, // 7 win
            {w: 278, h: 550, frameCount: 36, colCount: 6}  // 8 lose
        ];
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 28px Arial';
        ctx.fillStyle = '#a67800';
        ctx.textAlign = 'center';

        switch (page) {
            case 1:
                ctx.fillText(bet * this.symbols[9].payment[5], 60, 87);     // 'e'
                ctx.fillText(bet * this.symbols[9].payment[4], 60, 124);
                ctx.fillText(bet * this.symbols[9].payment[3], 60, 162);
                ctx.fillText(bet * this.symbols[9].payment[2], 60, 198);

                ctx.fillText(bet * this.symbols[7].payment[5], 60, 260);    // island
                ctx.fillText(bet * this.symbols[7].payment[4], 60, 296);
                ctx.fillText(bet * this.symbols[7].payment[3], 60, 335);
                ctx.fillText(bet * this.symbols[7].payment[2], 60, 371);

                ctx.fillText(bet * this.symbols[5].payment[5], 60, 438);     // lemon
                ctx.fillText(bet * this.symbols[5].payment[4], 60, 475);
                ctx.fillText(bet * this.symbols[5].payment[3], 60, 511);

                ctx.fillText(bet * this.symbols[8].payment[5], 454, 89);     // ship
                ctx.fillText(bet * this.symbols[8].payment[4], 454, 125);
                ctx.fillText(bet * this.symbols[8].payment[3], 454, 164);
                ctx.fillText(bet * this.symbols[8].payment[2], 454, 200);

                ctx.fillText(bet * this.symbols[6].payment[5], 454, 260);    // cocktail
                ctx.fillText(bet * this.symbols[6].payment[4], 454, 296);
                ctx.fillText(bet * this.symbols[6].payment[3], 454, 335);

                ctx.fillText(bet * this.symbols[4].payment[5], 454, 438);    // pineapple
                ctx.fillText(bet * this.symbols[4].payment[4], 454, 475);
                ctx.fillText(bet * this.symbols[4].payment[3], 454, 511);
                break;
            case 2:
                ctx.fillText(bet * this.symbols[3].payment[5], 60, 45);      // grapes
                ctx.fillText(bet * this.symbols[3].payment[4], 60, 81);
                ctx.fillText(bet * this.symbols[3].payment[3], 60, 117);

                ctx.fillText(bet * this.symbols[1].payment[5], 60, 184);     // plum
                ctx.fillText(bet * this.symbols[1].payment[4], 60, 220);
                ctx.fillText(bet * this.symbols[1].payment[3], 60, 256);
                ctx.fillText(bet * this.symbols[1].payment[2], 60, 292);

                ctx.fillText(bet * nLines * this.symbols[11].payment[5], 60, 386);    // '$'

                ctx.fillText(bet * this.symbols[2].payment[5], 454, 45);     // watermelon
                ctx.fillText(bet * this.symbols[2].payment[4], 454, 81);
                ctx.fillText(bet * this.symbols[2].payment[3], 454, 117);

                ctx.fillText(bet * this.symbols[0].payment[5], 454, 184);    // cherry
                ctx.fillText(bet * this.symbols[0].payment[4], 454, 220);
                ctx.fillText(bet * this.symbols[0].payment[3], 454, 256);
                ctx.fillText(bet * this.symbols[0].payment[2], 454, 292);
                break;
        }
    }

    getFeatureDelay(currentFeature, features) {
        let delay = 0;

        switch (currentFeature.uc) {
            case 'WIN_LINE':
            case 'FREE_ROLL':
            case 'CHOOSING':
                delay = this.winLineFeatureDelay;
                break;
            case 'SCATTER':
                delay = this.winLineFeatureDelay + 500;
                break;
        }

        return delay;
    }

    /**
     * Функция отображения предыдущего выбора или супербонуса
     */
    showChosedItem(parentContainer) {
        let x, y;
        parentContainer.removeChildren();
        parentContainer.interactiveChildren = true;
        this.Legends.setStatus('PRESS HOLDS TO SELECT CHAMPAGNE');

        this.screenMultiplier.win.forEach((multiplier, choiceIndex) => {
            if (multiplier > 0) {    // если не -1 значит выбор был сделан- отключаем кнопку, отображеем выигрыш и сумму
                x = this.choosing[choiceIndex].x;
                y = this.choosing[choiceIndex].y;
                const sprite = new Sprite(this.getTexture('boxUsed'));
                sprite.name = 'choiceIndex' + (choiceIndex + 5);
                sprite.position.set(x, y);
                sprite.interactive = true;
                sprite.buttonMode = true;
                parentContainer.addChild(sprite);
                const richText = new Text(multiplier, this.textBonus);
                x = this.choosing[choiceIndex].textPosition.x - (multiplier.toString().length - 1) * 10;
                y = this.choosing[choiceIndex].textPosition.y;
                richText.name = 'choiceMultiplier' + choiceIndex;
                richText.position.set(x, y);
                parentContainer.addChild(richText);
            }
            if (multiplier === -1) {    // выбор еще не сделан, // отображаем первый кадр анимации                        }
                x = this.choosing[choiceIndex].x;
                y = this.choosing[choiceIndex].y;
                const sprite = new Sprite(this.getTexture('box'));
                sprite.name = 'choiceIndex' + (choiceIndex + 5);
                sprite.position.set(x, y);
                sprite.interactive = true;
                sprite.buttonMode = true;
                sprite.on('pointerdown', () => this.newChoice(choiceIndex));
                parentContainer.addChild(sprite);
            }
        });
    }

    showOtherWin(parentContainer) {
        App.Sounds.playSound('choosingEnd');
        this.screenMultiplier.win.forEach((multiplier, choiceIndex) => {
            if (multiplier === -1) {    // выбор еще не сделан показываем бутылку  с возможным выигрышем
                const multUnopened = this.screenUnused[choiceIndex];
                let x = this.choosing[choiceIndex].x;
                let y = this.choosing[choiceIndex].y;
                const sprite = new Sprite(this.getTexture('boxUsed'));
                sprite.name = 'choiceIndex' + (choiceIndex + 5);
                sprite.position.set(x, y);
                sprite.interactive = true;
                sprite.buttonMode = true;
                parentContainer.addChild(sprite);
                const richText = new Text(multUnopened, this.textBonusBlack);
                x = this.choosing[choiceIndex].textPosition.x - (multUnopened.toString().length - 1) * 10;
                y = this.choosing[choiceIndex].textPosition.y;
                richText.name = 'unused' + choiceIndex;
                richText.position.set(x, y);
                parentContainer.addChild(richText);
            }
        });
    }

    /**
     * Функция анимации самого выбора в чузинге
     */
    showChoiceAnimation(response) {
        const parentContainer = this.getStageChild('bonusContainer');
        this.setState('CHOOSING_ANIMATION');
        const choiceIndex = response.choice;
        const multiplier = response.multiplier;
        parentContainer.interactiveChildren = false;
        App.Sounds.pauseSound('choosingBackground');
        App.Sounds.playSound('choosingSelect');

        this.screenMultiplier.win[choiceIndex] = multiplier;  // show opened botles
        this.screenMultiplier.mult[choiceIndex] = multiplier;

        let x = this.choosing[choiceIndex].x;
        let y = this.choosing[choiceIndex].y;
        const sprite = new Sprite(this.getTexture('boxUsed'));
        sprite.name = 'choiceIndex' + (choiceIndex + 5);
        sprite.position.set(x, y);
        sprite.interactive = true;
        sprite.buttonMode = true;
        parentContainer.addChild(sprite);
        const richText = new Text(multiplier, this.textBonus);
        x = this.choosing[choiceIndex].textPosition.x - (multiplier.toString().length - 1) * 10;
        y = this.choosing[choiceIndex].textPosition.y;
        richText.name = 'choiceMultiplier' + choiceIndex;
        richText.position.set(x, y);
        parentContainer.addChild(richText);
        setTimeout(() => this.nextChoosing(response), 1000);
    }

    nextChoosing(response) {
        if (this.superBonusStep <= response.step || response.stop) {
            this.showChosedItem(this.getStageChild('bonusContainer'), false);
            this.showOtherWin(this.getStageChild('bonusContainer'));
            setTimeout(() => this.animateMultiplier(response), 3000);
        } else {
            this.setState('CHOOSING_IDLE');
            App.Sounds.playSound('choosingBackground');
            this.showChosedItem(this.getStageChild('bonusContainer'));
        }
    }

    /**
     * Функция востановления выбора после чузинга
     */
    restoreChoosingScreen(response) {
        if (this.choosingQueue.length) {
            const featureStart = this.choosingQueue.pop();
            this.choosingWin = featureStart.win;
            this.choosingGame = featureStart.kind;
            this.disableChooseButtons();
        } else {                                        // no feature start but bonus so this is bonus in bonus
            this.choosingGame = 'FREE_ROLL';
        }

        App.Sounds.stopSound('bonusGameStart');
        this.hideBoxes();
        this.setBackground('bonusArea');
        this.Legends.clearStatus();
        this.Legends.showWinFeatures();

        this.screenMultiplier.win = this.getMultiplier(response);
        this.screenMultiplier.mult = this.getMultiplier(response);
        this.choosingStep = response.step;
        this.Legends.setText('win', {text: 'win', value: this.choosingWin});

        this.screenUnused.forEach((element, index) => {
            this.screenUnused[index] = this.winMas[Math.floor(Math.random() * 7)];
        });
        response.stop ?
            this.finishChoosing(response) :      // stop finish shoosing take win
            setTimeout(() => this.nextChoosing(response), 500);        // go next choosing step
    }

    /**
     * Функция окончания чузинга и выхода
     */
    finishChoosing(response) {
        this.setBackground('mainArea');
        this.showBoxes();
        this.getStageChild('bonusContainer').removeChildren();
        App.updateState('buttons', {visualization: App.buttonsType});
        this.Buttons.setDefaultGameButtons();

        this.choosingResponse = [];
        this.win = response.win;
        this.latestResponse = {payment: this.win, features: []};
        this.choosingWin = 0;
        this.gameFlag.bonusStart = false;
        this.screenMultiplier = {win: [-1, -1, -1, -1, -1], mult: [-1, -1, -1, -1, -1]};
        this.superScreenMultiplier = {win: [-1, -1], mult: [-1, -1]};
        this.win ?
            this.takeWin(true, true) :
            this.roundFinished();
    }

    drawBonusFrame(first, last, parentContainer, coordinates) {
        App.Sounds.stopSound('bonus-background');

        if (first) {
            App.Sounds.playSound('bookFlash');
            App.updateButton('start', {
                disabled: false,
                title: 'start',
                handler: () => this.startBonusAnimation(parentContainer)
            });
        }
        if (last) {
            parentContainer.removeChildren();
            this.setRegularSprite();
            App.updateButton('start', {disabled: true});
            this.setBackground('mainArea');
            this.playEndBonusGameSound();
            setTimeout(() => this.endBonus(), 5000);
        }
        if (!first && !last) {
            App.updateButton('start', {
                disabled: false,
                title: 'start',
                handler: () => this.drawBonusStep(this.latestResponse.features)
            });
        }
    }

    drawBonusText(parentContainer, delta) {
        parentContainer.removeChild(parentContainer.getChildByName('blinking'));

        parentContainer.removeChild(parentContainer.getChildByName('bonusWin'));
        let remain = (this.bonusStatus) ? this.bonusStatus.remain : 15;

        remain = remain + delta;

        const richText = new Text(remain, this.textBonus);
        const x = 458 - (remain.toString().length - 1) * 10;
        const y = 22;
        richText.name = 'bonusWin';
        richText.position.set(x, y);
        parentContainer.addChild(richText);
    }

    drawTopAnimation(parentContainer) {
        const x = (this.getBonusType() === 'FREE_ROLL') ? 405 : 223;
        const sprite = this.createChoosingElement(parentContainer, 'label',  // monkey sprite
            181, 25, // w h                                                                       //223 -50
            x, 50, 0, 2, 2, true, true);
        sprite.loop = true;
        sprite.name = 'blinking';
    }

    keyboardPress(event, buttons) {
        let soundChange = true;
        let handler = null;

        if (this.getState() !== 'CHOOSING_IDLE') {
            return {soundChange, handler};
        }

        const container = this.getStageChild('bonusContainer');
        const multiplier = this.screenMultiplier.win;

        // choosing buttons
        if (container && container.interactiveChildren) {
            switch (event.keyCode) {
                case 49: // 'digit 1'
                case 97: // 'num 1'
                case 'b04': // 'h1'
                    soundChange = false; // pressed same button, disable additional handler
                    multiplier[0] === -1 && (handler = () => this.newChoice(0));
                    break;
                case 50: // 'digit 2'
                case 98: // 'num 2'
                case 'b05': // 'h2'
                    multiplier[1] === -1 && (handler = () => this.newChoice(1));
                    break;
                case 51: // 'digit 3'
                case 99: // 'num 3'
                case 'b07': // 'h3'
                    multiplier[2] === -1 && (handler = () => this.newChoice(2));
                    break;
                case 52: // 'digit 4'
                case 100: // 'num 4'
                case 'b06': // 'h4'
                    multiplier[3] === -1 && (handler = () => this.newChoice(3));
                    break;
                case 53: // 'digit 5'
                case 101: // 'num 5'
                case 'b03': // 'h5'
                    multiplier[4] === -1 && (handler = () => this.newChoice(4));
                    break;
            }
        }

        return {soundChange, handler};
    }

    /**
     /**
     * Start scatter animations
     * @param features
     */
    bonusEntrance(features) {
        this.setState('SCATTER_FEATURE');
        this.playBonusGameSound();
        this.stopAnimateFeature();
        App.updateButton('start', {disabled: true});

        if (this.choosingQueue.length) {
            const featureStart = this.choosingQueue.pop();
            this.choosingWin = featureStart.win;
            this.choosingGame = featureStart.kind;
        } else {                                        // no feature start but bonus so this is bonus in bonus
            this.choosingGame = 'FREE_ROLL';
        }

        if (this.choosingGame === 'FREE_ROLL') {
            //  this.bonusStatus.total = featureStart.count;
            if (!this.gameFlag.bonusStart) this.drawTopAnimation(this.getStageChild('bonusContainer'));
            setTimeout(() => { // call after scatter played
                this.reelSettings = [12, 8, 8];
                this.setBackground('bonusArea1');
                this.drawBonusText(this.getStageChild('bonusContainer'), 0);
                const scatterFeature = this.latestResponse.features.find(({uc}) => uc === 'SCATTER');
                this.playScatterAnimation(scatterFeature, () => // call after scatter played
                    this.drawBonusAskButton(this.isFreeRoll(this.latestResponse.features) && !this.bonusStatus));
            }, 1000);
        } else {
            this.drawTopAnimation(this.getStageChild('bonusContainer'));
            this.setState('CHOOSING_IDLE');
            this.disableChooseButtons();
            App.Sounds.playSound('choosingBackground');
            this.setBackground('bonusArea');
            this.stopAnimateFeature();

            this.screenUnused.forEach((element, index) => {
                this.screenUnused[index] = this.winMas[Math.floor(Math.random() * 7)];
            });

            setTimeout(() => // call after scatter played
                this.showChosedItem(this.getStageChild('bonusContainer')), 2000);
            this.Legends.setText('win', {text: 'win', value: this.bonusWin});
        }
    }

    restoreBonusGame() {
        const featureStart = this.choosingQueue.shift();
        this.choosingGame = featureStart.symbol;
        this.setBackground('bonusArea1');
        this.drawBonusText(this.getStageChild('bonusContainer'), 0);
        this.bonusWin = this.bonusStatus.win - this.latestResponse.payment;
        // Fill WIN data
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});
        this.Legends.showWinFeatures();
        this.setBonusStatusText();

        this.Buttons.disableAllButtons();
        this.gameFlag.bonusStart = true;
        this.gameFlag.bonusStarted = true;

        this.showAdditionalBonusImage(this.getStageChild('bonusContainer'));
        this.processReelResponse(this.latestResponse);
    }

    startBonusRoll = () => {
        this.reelSettings = [12, 8, 8];
        JL().debug('-- Start bonus roll');
        this.drawBonusText(this.getStageChild('bonusContainer'), -1);
        this.cleanBeforeRoll();
        this.Buttons.disableAllButtons();
        App.updateButton('start', {disabled: true});
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});
        this.sendRoll();
    };

    playRollSound = () => {
        App.Sounds.playSound('reels');
    };

    /**
     * End bonus round, return to bonus game.
     */
    finishBonus() {
        if (this.choosingQueue.length) {
            this.Legends.setText('win', {text: 'win', value: this.bonusWin});
            this.getStageChild('bonusContainer').removeChildren();
            this.choosingResponse = null;
            this.bonusStatus = null;
            this.bonusEntrance();      // go next choosing
            return;
        }

        this.reelSettings = [12, 5, 5];
        this.drawBonusAskButton();
        App.Sounds.stopSound('bonus-background');
        this.latestResponse.payment = this.bonusWin;
        this.Legends.setStatus('creditsWon', this.bonusWin);
        this.bonusWin = 0;
        this.gameFlag.bonusStart = false;
    }

    /**
     * Static animation.
     * Play unique additional sounds
     * It plays once in 5 seconds if not Animate feature
     */
    showWaitingAnimation() {
        this.waitingTimeout = setTimeout(() => {
            this.stopWaitingAnimation();
            this.reelMatrix.forEach((reel, reelIndex) => {
                reel.forEach((symbolObj, rowIndex) => {
                    if ((symbolObj.symbol === 9 || symbolObj.symbol === 11) && this.getState() === 'IDLE') {
                        const symbolObj = this.reelMatrix[reelIndex][rowIndex];
                        symbolObj.image = 'additional';
                        symbolObj.loop = false;
                        symbolObj.animationSpeed = 0.10;
                        this.Roll.updateSymbolSprite(symbolObj);
                        symbolObj.sprite.play();
                    }
                });
            });
        }, 5000);
    }

    stopWaitingAnimation() {
        if (this.waitingTimeout) {
            clearTimeout(this.waitingTimeout);
        }
    }
}
