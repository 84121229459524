import {JL} from 'jsnlog';

import App from './../index';
import keyboardPress from './keyboard';

/**
 * Window error handler
 */
window.addEventListener('error', event => {
    const {message, filename, lineno, colno} = event;

    App.View.setState(prevState => {
        const {clientErrors} = prevState;
        clientErrors.push({message, filename, lineno, colno});
        return {...prevState, clientErrors};
    });
});

/**
 * Block context menu
 */
window.addEventListener('contextmenu', event => event.preventDefault());

/**
 * Handle device orientation
 */
window.addEventListener('orientationchange', () => App.View && App.System.updateOrientation());

/**
 * Custom scenario for a pwa installation
 */
window.addEventListener('beforeinstallprompt', event => {
    JL().debug('PWA detected');

    // Prevent Chrome <= 67 from automatically showing the prompt
    event.preventDefault();

    // Wait for the user to respond to the prompt
    event && App.updateButton('installPWA', {handler: () => event.prompt()});
});

/**
 * PWA install event
 * Send metrics
 */
window.addEventListener('appinstalled', event => {
    JL().debug('PWA installed');
    App.System.sendMetric({param: 'pwa.installed'});
    App.System.os && App.System.sendMetric({param: `pwa.os.${App.System.os.replace(/\s+/gi, '')}`});
});

/**
 * Handle device full screen mode
 */
document.addEventListener('fullscreenchange', () => App.fullScreenChanged());

/**
 * Add keyboard shortcuts
 * Check main React Component
 */
document.addEventListener('keydown', event => {
    JL().debug(`Pressed ${event.code} (${event.keyCode})`);
    App.View && keyboardPress(event)?.();
});

/**
 * Blur latest clicked element (disable focus)
 * Prevent call from 'Space'/'Enter' press
 */
const clearFocus = () =>
    !document.activeElement.classList.contains('no-clear-focus') &&
    document.activeElement.blur();
document.addEventListener('click', clearFocus);
document.addEventListener('contextmenu', clearFocus);
