import en from './en.js';
import uk from './uk.js';
import ru from './ru.js';
import de from './de.js';
import es from './es.js';
import fr from './fr.js';
import el from './el.js';
import it from './it.js';
import './styles.less';

export default {en, uk, ru, de, es, fr, el, it};
