import {JL} from 'jsnlog';
import * as PIXI from 'pixi.js-legacy';

import Lines from './../../lines';
import App from './../../../index';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Texture = PIXI.Texture,
    Text = PIXI.Text;

export default class Lines9 extends Lines {
    constructor(imageResources) {
        super();
        this.imageResources = {
            ...imageResources // redefine base images from game
        };
        this.lineImageWidth = 151; // width of line image in sprite, magic number for each game
        this.lineImageHeight = 151; // height of line image in sprite, magic number for each game

        this.lines = {
            0: {                                 // light blue
                coordinates: [1, 1, 1, 1, 1],
                boxes: [
                    {x: 15, y: 253},
                    {x: 739, y: 253}
                ],
                path: [
                    {x: 51, y: 194},
                    {x: 188, y: 194},
                    {x: 324, y: 194},
                    {x: 461, y: 194},
                    {x: 598, y: 194}
                ]
            },
            1: {                                 // red
                coordinates: [0, 0, 0, 0, 0],
                boxes: [
                    {x: 15, y: 153},
                    {x: 739, y: 153}
                ],
                path: [
                    {x: 51, y: 69},
                    {x: 188, y: 69},
                    {x: 324, y: 69},
                    {x: 461, y: 69},
                    {x: 598, y: 69}
                ]
            },
            2: {                                 // green
                coordinates: [2, 2, 2, 2, 2],
                boxes: [
                    {x: 15, y: 356},
                    {x: 739, y: 356}
                ],
                path: [
                    {x: 51, y: 319},
                    {x: 188, y: 319},
                    {x: 324, y: 319},
                    {x: 461, y: 319},
                    {x: 598, y: 319}
                ]
            },
            3: {                                // yellow
                coordinates: [0, 1, 2, 1, 0],
                boxes: [
                    {x: 15, y: 118},
                    {x: 739, y: 118}
                ],
                path: [
                    {x: 51, y: 69},
                    {x: 188, y: 194},
                    {x: 324, y: 319},
                    {x: 461, y: 194},
                    {x: 598, y: 69}
                ]
            },
            4: {                                // purple
                coordinates: [2, 1, 0, 1, 2],
                boxes: [
                    {x: 15, y: 391},
                    {x: 739, y: 391}
                ],
                path: [
                    {x: 51, y: 319},
                    {x: 188, y: 194},
                    {x: 324, y: 69},
                    {x: 461, y: 194},
                    {x: 598, y: 319}
                ]
            },
            5: {                               // light green
                coordinates: [1, 2, 2, 2, 1],
                boxes: [
                    {x: 15, y: 221},
                    {x: 739, y: 221}
                ],
                path: [
                    {x: 51, y: 194},
                    {x: 188, y: 319},
                    {x: 324, y: 319},
                    {x: 461, y: 319},
                    {x: 598, y: 194}
                ]
            },
            6: {                               // white
                coordinates: [1, 0, 0, 0, 1],
                boxes: [
                    {x: 15, y: 288},
                    {x: 739, y: 288}
                ],
                path: [
                    {x: 51, y: 194},
                    {x: 188, y: 69},
                    {x: 324, y: 69},
                    {x: 461, y: 69},
                    {x: 598, y: 194}
                ]
            },
            7: {                              // orange
                coordinates: [2, 2, 1, 0, 0],
                boxes: [
                    {x: 15, y: 323},
                    {x: 739, y: 185}
                ],
                path: [
                    {x: 51, y: 319},
                    {x: 188, y: 319},
                    {x: 324, y: 194},
                    {x: 461, y: 69},
                    {x: 598, y: 69}
                ]
            },
            8: {                               // cyan
                coordinates: [0, 0, 1, 2, 2],
                boxes: [
                    {x: 15, y: 185},
                    {x: 739, y: 323}
                ],
                path: [
                    {x: 51, y: 69},
                    {x: 188, y: 69},
                    {x: 324, y: 194},
                    {x: 461, y: 319},
                    {x: 598, y: 319}
                ]
            }
        };
        JL().debug('-- Lines9 initialized');
    }

    drawLineImages(lines, winReels, parentContainer) {
        lines.forEach(lineIndex => {
            const line = this.lines[lineIndex];

            line.coordinates.forEach((rowIndex, reelIndex) => {
                const spritePosX = this.lineImageWidth * reelIndex;
                const spritePosY = this.lineImageHeight * lineIndex;
                const sprite = new Sprite(new Texture(App.Game.getTexture(winReels.includes(reelIndex) ? 'winLines' : 'lines'), {
                    x: spritePosX,
                    y: spritePosY,
                    width: this.lineImageWidth,
                    height: this.lineImageHeight
                }));
                sprite.name = lineIndex;

                sprite.position.set(line.path[reelIndex].x, line.path[reelIndex].y);
                parentContainer.addChild(sprite);
            });
        });
    }

    drawBoxes(parentContainer) {
        parentContainer.removeChildren(); // clear all previous boxes

        const currentLines = App.Game.gameSettings.getCurrentLineMas();
        const boxWidth = 38; // width of winline boxes
        const boxHeight = 29; // height of winline boxes

        currentLines.forEach(lineIndex => {
            lineIndex--;
            this.lines[lineIndex].boxes.forEach(box => {
                const spritePosX = box.x ? boxWidth : box.x;
                const spritePosY = boxHeight * lineIndex;

                // create box sprite
                const sprite = new Sprite(new Texture(App.Game.getTexture('boxes'), {
                    x: spritePosX,
                    y: spritePosY, // sx, sy,
                    width: boxWidth,
                    height: boxHeight
                }));
                sprite.position.set(box.x, box.y);
                sprite.name = lineIndex;
                this.addBoxEvents(sprite);
                parentContainer.addChild(sprite);

                const text = new Text(lineIndex + 1, {
                    fontFamily: 'Arial',
                    fontSize: 19,
                    fill: '#000'
                });
                text.anchor.set(0.5);
                text.position.set(box.x + boxWidth / 2, box.y + boxHeight / 2);
                parentContainer.addChild(text);
            });
        });
    }
}
