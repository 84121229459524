import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import GameIgrosoft from './../../igrosoft/game';
import Lines9 from './../../igrosoft/lines9';
import GambleIgrosoft from './../../igrosoft/gamble';
import InfoScreen from '../../infoScreen';
import Game from './../../Game';

/* PIXI aliases */
const AnimatedSprite = PIXI.AnimatedSprite,
    Text = PIXI.Text;

export default class FruitCocktail extends GameIgrosoft {
    constructor() {
        super();
        this.id = 'fruit-cocktail-1';
        this.name = 'Fruit Cocktail';
        this.buttonsPanelShadow = 'none';
        this.superBonusStep = 4; // шаг чузинга поле которого начинается супер бонус
        this.scatter = 8;
        this.choosingResponse = []; // последний пакет чузинга
        this.attempsText = [[130, 166], [652, 166]];
        this.roulettePosition = 0;
        this.slot = [{}, {}, {}];
        this.screenMultiplier = {win: [-1, -1, -1], mult: [-1, -1, -1]};  // only last multiplier is important;
        this.superBonusStep = 99;
        this.slotStoped = false;
        this.slotPos = [
            {x: 201, y: 231},
            {x: 340, y: 231},
            {x: 478, y: 231},
            {w: 120, h: 96}
        ];
        this.soundFlag = 0;
        this.rouletteMap = {
            0: {x: 0, y: 76, picture: 7},
            1: {x: 100, y: 76, picture: 4},  // n: номер позиции в рулетке,
            2: {x: 198, y: 76, picture: 1}, //  x, y позиция на экране,
            3: {x: 298, y: 76, picture: 3}, //  picture: номер елемента в спрайте
            4: {x: 398, y: 76, picture: 0},
            5: {x: 498, y: 76, picture: 2},
            6: {x: 598, y: 76, picture: 5},
            7: {x: 698, y: 76, picture: 7}, // exit

            8: {x: 698, y: 143, picture: 0},
            9: {x: 698, y: 206, picture: 1},
            10: {x: 698, y: 273, picture: 3},
            11: {x: 698, y: 336, picture: 2},
            12: {x: 698, y: 401, picture: 0},

            13: {x: 698, y: 466, picture: 7}, // exit
            14: {x: 598, y: 466, picture: 4},
            15: {x: 498, y: 466, picture: 1},
            16: {x: 398, y: 466, picture: 0},
            17: {x: 298, y: 466, picture: 2},
            18: {x: 198, y: 466, picture: 1},
            19: {x: 98, y: 466, picture: 6},
            20: {x: 0, y: 466, picture: 7},

            21: {x: 0, y: 401, picture: 0}, // exit
            22: {x: 0, y: 336, picture: 1},
            23: {x: 0, y: 273, picture: 3},
            24: {x: 0, y: 206, picture: 2},
            25: {x: 0, y: 143, picture: 0}
        };

        this.winMap = {
            0: {img: 7},
            2: {img: 0},  // n: номер картинки в спрайте на основе множетеля
            5: {img: 1},
            10: {img: 2},
            20: {img: 3},
            50: {img: 4},
            70: {img: 5},
            100: {img: 6}
        };

        // waiting image size, box coordinates for animation
        this.waitingSettings = {
            IDLE: {w: 423, h: 132, x: 0, y: 470, frameCount: 17, animationSpeed: 100, colCount: 5},
            RESPONSE_RECEIVED: {w: 423, h: 132, x: 0, y: 470, frameCount: 5, animationSpeed: 100, colCount: 5},
            SHOW_WIN_LINES: {w: 423, h: 132, x: 0, y: 470, frameCount: 5, animationSpeed: 120, colCount: 5},
            DOUBLING: {w: 423, h: 132, y: 470, frameCount: 10, animationSpeed: 100, colCount: 5},
            CHOOSING_IDLE: {w: 423, h: 132, x: 0, y: 470, frameCount: 17, animationSpeed: 100, colCount: 5}
        };

        this.symbols = [
            {regularDelay: 500, payment: [0, 0, 0, 2, 3, 10]},        // 0 - cherry
            {regularDelay: 500, payment: [0, 0, 0, 3, 5, 20]},        // 1 - peach
            {regularDelay: 500, payment: [0, 0, 0, 5, 10, 50]},       // 2 - lemon
            {regularDelay: 500, payment: [0, 0, 0, 10, 30, 100]},     // 3 - apple
            {regularDelay: 500, payment: [0, 0, 0, 20, 50, 200]},     // 4 - pear
            {regularDelay: 500, payment: [0, 0, 0, 30, 100, 500]},    // 5 - watermelon
            {regularDelay: 500, payment: [0, 0, 0, 200, 1000, 5000]}, // 6 - Fruit Cocktail
            {regularDelay: 500, payment: [0, 0, 0, 100, 500, 2000]},  // 7 - pineapple
            {regularDelay: 150, payment: [0, 0, 0, 0, 0, 0], skipSteps: 1}   // 8 - strawberry
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                waitingRoll: 'area/waiting_roll.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                gambleArea: 'area/gamble.png',

                // waiting
                roll: 'area/roll.png',
                win: 'area/win.png',
                waitingDoubling: 'gamble/waiting_doubling.png',
                waitingChoosing: 'bonus/waiting_choosing.png',

                // bonus
                bonusArea: 'area/bonus.png',
                roulette: 'bonus/bonus_sprite1.png',
                slotElement: 'bonus/bonus_sprite2.png',
                slotAnimation: 'bonus/bonus_sprite3.png',
                slotWinBox: 'bonus/winbox.png',

                // gamble
                gambleWin: 'gamble/doubling_win.png',
                gambleLose: 'gamble/doubling_lose.png',
                gambleForward: 'gamble/doubling_forward.png',

                cloudWin: 'gamble/win.png', // usually redefined
                cloudLose: 'gamble/lose.png', // usually redefined
                cloudForward: 'gamble/forward.png' // usually redefined
            })
        };

        this.Gamble = new GambleUnique(this.mergePath({
            cards: 'gamble/cards.png', // usually redefined
            dealer: 'gamble/dealer.png', // usually redefined
            pick: 'gamble/pick.png' // usually redefined
        }));
        this.Gamble.riskStepX = 460;
        this.Gamble.riskStepY = 102;
        this.InfoScreen = new InfoScreen({pages: 5}); // number of game info states
        this.Lines = new Lines9();

        this.gameSounds = {
            soundClass: 'igrosoft',
            sounds: [
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'bonus-reel-stop', alias: 'bonusReelStop'},
                {name: 'bonus-frame', alias: 'bonusFrame'},
                {name: 'roulette_step', alias: 'rouletteStep'}
            ],
            path: `/game/games/${this.id}/audio/`
        };
        this.keyboardPress = Game.prototype.keyboardPress.bind(this);
    }

    getWinPicture = multiplier => multiplier ? 0 : Math.round(1 + Math.random(3));

    /**
     * Функция отображения предыдущего выбора
     */
    showChosedItem(parentContainer, interactive = true) {
        parentContainer.removeChild(parentContainer.getChildByName('choiceMultiplier0'));
        parentContainer.removeChild(parentContainer.getChildByName('choiceMultiplier1'));
        parentContainer.removeChild(parentContainer.getChildByName('choiceMultiplier2'));

        this.setState('CHOOSING_IDLE');
        App.Sounds.stopSound('bonusGameStart');
        this.Legends.setStatus('pressAnyButton');
        parentContainer.interactiveChildren = interactive;
        const slot1 = parentContainer.getChildByName('slotElement1');
        const slot2 = parentContainer.getChildByName('slotElement2');
        const slot3 = parentContainer.getChildByName('slotElement3');
        slot1.gotoAndStop(this.slot[0].img);
        slot2.gotoAndStop(this.slot[1].img);
        slot3.gotoAndStop(this.slot[2].img);
        slot1.visible = true;
        slot2.visible = true;
        slot3.visible = true;

        this.screenMultiplier = {win: [-1, -1, -1], mult: [-1, -1, -1]};

        this.showProtections(parentContainer, this.protections);
        App.updateButton('start', {
            disabled: false,
            title: 'start',
            handler: () => this.newChoice()
        });
    }

    /**
     * Функция анимации прокрута трех барабанов.
     * position - помер элемета который нужно показать в рутетке
     * iStep шаг анимации
     **/
    rouletteStep(position, iStep) {
        const parentContainer = this.getStageChild('bonusContainer');
        const roulette = parentContainer.getChildByName('roulette');
        let step;
        if (iStep < 26 - this.roulettePosition + position + 26) {  // два круга рулетки 25 + показываем выпавший елемент
            this.playRouletteSound();
            step = (iStep + this.roulettePosition) % 26;
            roulette.position.set(this.rouletteMap[step].x, this.rouletteMap[step].y);
            roulette.gotoAndStop(this.rouletteMap[step].picture);
        } else {
            roulette.position.set(this.rouletteMap[position].x, this.rouletteMap[position].y);
            roulette.gotoAndStop(this.rouletteMap[position].picture);
        }
    }

    playRouletteSound() {
        if (this.slotStoped) {
            App.Sounds.playSound('bonusReelStop');
        }
    }

    /**
     * Функция анимации прокрута трех барабанов.
     * Slot.а,б,с - помера элеметов которые должны выпасть после прокрута
     * iStep шаг анимации
     **/
    slotStep(slot, iStep, parentContainer) {
        const slotElement1 = parentContainer.getChildByName('slotElement1');
        const slotElement2 = parentContainer.getChildByName('slotElement2');
        const slotElement3 = parentContainer.getChildByName('slotElement3');

        if (iStep > 4 && iStep <= 9) {
            this.stopReelSound(1);
            slotElement1.gotoAndStop(slot[0].img);
            slotElement1.visible = true;
        }
        if (iStep > 9 && iStep <= 14) {
            this.stopReelSound(2);
            slotElement1.gotoAndStop(slot[0].img);
            slotElement2.gotoAndStop(slot[1].img);
            slotElement1.visible = true;
            slotElement2.visible = true;
        }
        if (iStep > 14) {
            this.slotStoped = true;
            this.stopRollSound();
            this.stopReelSound(3);
            slotElement1.gotoAndStop(slot[0].img);
            slotElement2.gotoAndStop(slot[1].img);
            slotElement3.gotoAndStop(slot[2].img);
            slotElement1.visible = true;
            slotElement2.visible = true;
            slotElement3.visible = true;
        }
    }

    stopReelSound(slot) {
        if (slot > this.soundFlag) {
            this.soundFlag = slot;
            App.Sounds.playSound('bonusReelStop');
        }
    }

    /**
     * Функция потображения рамки выигрышного елемента
     */
    showWinBox(slot, parentContainer) {
        App.Sounds.playSound('bonusFrame');
        slot.forEach((slotItem, index) => {
            if (slotItem.win) {
                const slotWinBox = parentContainer.getChildByName('slotWinBox' + index);
                slotWinBox.position.set(this.slotPos[index].x, this.slotPos[index].y);
                slotWinBox.visible = true;
            }
        });
    }

    /**
     * Функция отображения чузинга
     */
    showChoiceAnimation(response, position, slot, animationStarted, iStep = 0) {
        if (typeof animationStarted === 'undefined' || animationStarted == null) animationStarted = new Date();
        const newDate = new Date();

        if (Math.floor((newDate - animationStarted) / 100) < 1) {
            requestAnimationFrame(this.showChoiceAnimation.bind(this, response, position, slot, animationStarted, iStep));
            return;
        }

        iStep++;
        animationStarted = newDate;
        const multiplier = response.multiplier;
        const parentContainer = this.getStageChild('bonusContainer');

        if (iStep < 25 - this.roulettePosition + position + 26 + 10) {
            this.rouletteStep(position, iStep);
            this.slotStep(slot, iStep, parentContainer);
            this.protections = response.protections;
        } else {
            this.rouletteStep(position, iStep);
            this.slotStep(slot, iStep, parentContainer);
            this.protections = response.protections;   // update attempts count

            if (multiplier) {
                this.showWinBox(slot, parentContainer);
                this.showWinText(slot, parentContainer);
            }

            setTimeout(() => {
                this.animateMultiplier(response, null, 0, slot);
                this.roulettePosition = position;
            }, 2000); // Close bonus results after 2 sec.
            return;
        }

        requestAnimationFrame(this.showChoiceAnimation.bind(this, response, position, slot, animationStarted, iStep));
    }

    showWinText(slot, parentContainer) {
        slot.forEach((slotItem, index) => {
            if (slotItem.win) {
                const richText = new Text(slotItem.win * this.gameSettings.getBetCredit(), this.textBonus);
                richText.name = 'choiceMultiplier' + index;
                richText.position.set(this.slotPos[index].x, this.slotPos[index].y);
                parentContainer.addChild(richText);

                this.screenMultiplier.win[index] = slotItem.win * this.gameSettings.getBetCredit();
                this.screenMultiplier.mult[index] = slotItem.win * this.gameSettings.getBetCredit();
            }
        });
    }

    showProtections(parentContainer, protections) {
        parentContainer.removeChild(parentContainer.getChildByName('protectionsLeft'));
        parentContainer.removeChild(parentContainer.getChildByName('protectionsRight'));

        const richText = new Text(protections + 1, this.textBonus);
        richText.name = 'protectionsLeft';
        richText.position.set(this.attempsText[0][0], this.attempsText[0][1]);
        parentContainer.addChild(richText);

        const richText2 = new Text(protections + 1, this.textBonus);
        richText2.name = 'protectionsRight';
        richText2.position.set(this.attempsText[1][0], this.attempsText[1][1]);
        parentContainer.addChild(richText2);
    }

    /**
     * Функция анимации зачисления кредитов в простой бонусной игре (5 множителей)
     */
    animateBonusMultiplier(choosing, response, animationStarted = Date.now(), lastTime = animationStarted) {
        const time = Date.now();
        const timeDiff = time - lastTime;
        const animateTimeout = 50;
        if (timeDiff < animateTimeout) {
            requestAnimationFrame(this.animateBonusMultiplier.bind(this, choosing, response, animationStarted, lastTime));
            return;
        }
        lastTime = Date.now();
        const parentContainer = this.getStageChild('bonusContainer');
        this.playCreditSound();
        let delta = this.setMultiplierTransferSpeed(response.win);
        this.screenMultiplier.mult.forEach((multiplier, choiceIndex) => {
            parentContainer.removeChild(parentContainer.getChildByName('choiceMultiplier' + choiceIndex));
            if (multiplier > 0 && delta !== 0) {
                if (delta > multiplier) {
                    delta = multiplier;
                }
                this.screenMultiplier.mult[choiceIndex] -= delta;
                this.choosingWin = this.choosingWin + delta;

                delta = 0;
            }
            if (multiplier >= 0) {
                multiplier = this.screenMultiplier.mult[choiceIndex];
                const x = this.slotPos[choiceIndex].x;
                const y = this.slotPos[choiceIndex].y;
                const richText = new Text(multiplier, this.textBonus);
                richText.position.set(x, y);
                richText.name = 'choiceMultiplier' + choiceIndex;
                parentContainer.addChild(richText);
            }
        });

        this.Legends.setText('win', {text: 'win', value: this.choosingWin});
        this.Legends.setStatus('creditsWon', this.choosingWin);

        const sumScreenMultiplier = this.screenMultiplier.mult.reduce((sum, x) =>
            sum + (x === -1 ? 0 : x), 0);

        if (sumScreenMultiplier > 0) {
            requestAnimationFrame(this.animateBonusMultiplier.bind(this, choosing, response, animationStarted, lastTime));
        } else {
            this.roundWin = this.choosingWin;
            if (response.stop) {
                setTimeout(() => this.finishChoosing(response), 2000); // Close bonus results after 2 sec.
            } else {
                this.nextChoosing(response);
            }
        }
    }

    /**
     * Функция востановления выбора после чузинга
     */
    restoreChoosingScreen(response) {
        App.Sounds.stopSound('bonusGameStart');
        this.hideBoxes();
        this.setBackground('bonusArea');
        this.Legends.clearStatus();
        this.Legends.showWinFeatures();
        this.disableChooseButtons();
        this.Buttons.disableAllButtons();
        this.choosingWin = this.latestResponse.payment + response.win;
        this.screenMultiplier.win = this.getMultiplier(response);
        this.screenMultiplier.mult = this.getMultiplier(response);
        this.choosingStep = response.step;
        this.protections = response.protections;

        this.Legends.setText('win', {text: 'win', value: this.choosingWin});
        this.Legends.setStatus('creditsWon', this.choosingWin);
        App.updateState('buttons', {visualization: 'choosing'});
        this.creteChosingStage(); // function to create all graphics objects
        this.slot[0].img = Math.floor((Math.random() * 6));
        this.slot[1].img = Math.floor((Math.random() * 6));
        this.slot[2].img = Math.floor((Math.random() * 6));

        this.slot[0].win = 0;
        this.slot[1].win = 0;
        this.slot[2].win = 0;
        this.screenMultiplier.mult[0] = response.multiplier;
        this.screenMultiplier.win[0] = response.multiplier;

        this.choosingResponse.stop ? // конец игры
            this.finishChoosing(response) : // проигрыш выходим
            setTimeout(() => this.nextChoosing(response), 500); // go next choosing step
    }

    nextChoosing(response) {
        this.superBonusStep <= response.step ?
            this.animateMultiplier(response) :
            this.showChosedItem(this.getStageChild('bonusContainer'));
    }

    /**
     * Функция получения протекции
     */
    getProtection(response) {
        return false;
    }

    /**
     * Функция получения позиции рулетки
     */
    getRouletePos(response) {
        let x = 0;
        if (response.multiplier === 0) {   // нулевой выигрыш
            if (response.stop === true) {                        // выход  конец игры
                do {
                    x = Math.floor((Math.random() * 25));       // показываем случайную позицию выхода
                }
                while (this.rouletteMap[x].picture !== 7);
            }
            if (response.stop === false && response.extension.forward === false) { // не конец игры невыигрышный символ и сработал протекшен
                do {
                    x = Math.floor((Math.random() * 25));                       // показываем выхода одну из черырех
                }
                while (this.rouletteMap[x].picture !== 7);
            }
            if (response.stop === false && response.extension.forward === true) { // не конец игры невыигрышный символ
                do {
                    x = Math.floor((Math.random() * 25));                        // показываем случайную позицию символа но не выхода
                }
                while (this.rouletteMap[x].picture === 7);
            }
        } else {                            // позитивный выигрыш
            do {
                x = Math.floor((Math.random() * 25));          // получаем произвольную позицию выигрышного елемнета
            }
            while (this.rouletteMap[x].picture !== this.winMap[response.multiplier / response.extension.amountMultiplier].img);
        }
        return x;
    }

    /**
     * Функция получения позиции трех барабанов
     */
    getSlotPos = (response, rouletteSymbol) => {
        const x1 = {}, x2 = {}, x3 = {}, slot = [];
        x1.win = 0;
        x2.win = 0;
        x3.win = 0;
        if (response.multiplier === 0) {   // нулевой выигрыш
            do {
                x1.img = Math.floor((Math.random() * 6)); // показываем произвольные разные картинки c рулеткой
                x2.img = Math.floor((Math.random() * 6));
                x3.img = Math.floor((Math.random() * 6));
            }
            while (x1.img === rouletteSymbol || x2.img === rouletteSymbol || x3.img === rouletteSymbol);
        } else {        // позитивный выигрыш
            const win = response.multiplier / response.extension.amountMultiplier;
            switch (response.extension.amountMultiplier) {
                case 1:
                    x1.img = rouletteSymbol;       // символ с рулетки
                    x1.win = win;
                    do {
                        x2.img = Math.floor((Math.random() * 6));       // два других произвольных символа
                        x3.img = Math.floor((Math.random() * 6));
                    }
                    while (x1.img === x2.img || x1.img === x3.img);
                    break;
                case 2:
                    x1.img = rouletteSymbol;       // 1 символ с рулетки
                    x2.img = rouletteSymbol;       // 2 символ с рулетки
                    x1.win = win;
                    x2.win = win;
                    do {
                        x3.img = Math.floor((Math.random() * 6));       // один другой произвольный символ
                    }
                    while (x1.img === x3.img);
                    break;
                case 3:
                    x1.img = rouletteSymbol;       // 1 символ с рулетки
                    x2.img = rouletteSymbol;       // 2 символ с рулетки
                    x3.img = rouletteSymbol;       // 3 символ с рулетки
                    x1.win = win;
                    x2.win = win;
                    x3.win = win;
                    break;
            }
        }

        const pos = Math.floor((Math.random() * 2));

        switch (pos) {      // раскидываем полученые значения по ячейкам  и запоминаем номер віигрішной ячейки
            case 0:
                slot[0] = x1;
                slot[1] = x2;
                slot[2] = x3;
                break;

            case 1: {
                slot[0] = x2;
                slot[1] = x1;
                slot[2] = x3;
                break;
            }
            case 2: {
                slot[0] = x3;
                slot[1] = x2;
                slot[2] = x1;
                break;
            }
        }
        return slot;
    };

    /**
     * Функция анимации самого выбора в чузинге
     */
    showChoice(response, animationStarted, animation) {
        this.setState('CHOOSING_ANIMATION');
        this.Legends.clearStatus();
        this.soundFlag = 0;
        const position = this.getRouletePos(response);           // find roulette position

        this.slot = this.getSlotPos(response, this.rouletteMap[position].picture);   // find slott position accurding to roulette

        this.playRollSound();
        this.slotStoped = false;
        this.showChoiceAnimation(response, position, this.slot);

        const parentContainer = this.getStageChild('bonusContainer');

        const slotElement1 = parentContainer.getChildByName('slotElement1');
        const slotElement2 = parentContainer.getChildByName('slotElement2');
        const slotElement3 = parentContainer.getChildByName('slotElement3');
        slotElement1.visible = false;
        slotElement2.visible = false;
        slotElement3.visible = false;

        const slot1 = parentContainer.getChildByName('slot1');
        const slot2 = parentContainer.getChildByName('slot2');
        const slot3 = parentContainer.getChildByName('slot3');
        slot1.play();
        slot2.play();
        slot3.play();

        const winBox0 = parentContainer.getChildByName('slotWinBox0');
        winBox0.visible = false;
        const winBox1 = parentContainer.getChildByName('slotWinBox1');
        winBox1.visible = false;
        const winBox2 = parentContainer.getChildByName('slotWinBox2');
        winBox2.visible = false;
        parentContainer.removeChild(parentContainer.getChildByName('choiceMultiplier'));
    }

    creteChosingStage() {
        const parentContainer = this.getStageChild('bonusContainer');
        const sprite = this.createChoosingElement(parentContainer, 'roulette',  // roulette sprite
            100, 65, // dw, dh // sw, sh
            this.rouletteMap[0].x, this.rouletteMap[0].y, 0, 8, 8, false, true);
        sprite.name = 'roulette';
        sprite.gotoAndStop(7);

        const slot1 = this.createChoosingElement(parentContainer, 'slotAnimation',  // slot 1 object
            121, 97, // dw, dh // sw, sh
            this.slotPos[0].x, this.slotPos[0].y, 0, 25, 5, false, true);
        slot1.name = 'slot1';
        slot1.loop = true;
        slot1.gotoAndStop(0);

        const slot2 = this.createChoosingElement(parentContainer, 'slotAnimation',  // slot 2 object
            121, 97, // dw, dh // sw, sh
            this.slotPos[1].x, this.slotPos[1].y, 0, 25, 5, false, true);
        slot2.name = 'slot2';
        slot2.loop = true;
        slot2.gotoAndStop(5);

        const slot3 = this.createChoosingElement(parentContainer, 'slotAnimation',  // slot 3 object
            121, 97, // dw, dh // sw, sh
            this.slotPos[2].x, this.slotPos[2].y, 0, 25, 5, false, true);
        slot3.name = 'slot3';
        slot3.loop = true;
        slot3.gotoAndStop(10);

        const slotElement1 = this.createChoosingElement(parentContainer, 'slotElement',  // slot element 1
            121, 97, // dw, dh // sw, sh
            this.slotPos[0].x, this.slotPos[0].y, 0, 7, 7, false, false);
        slotElement1.name = 'slotElement1';

        const slotElement2 = this.createChoosingElement(parentContainer, 'slotElement',  // slot element 1
            121, 97, // dw, dh // sw, sh
            this.slotPos[1].x, this.slotPos[1].y, 0, 7, 7, false, false);
        slotElement2.name = 'slotElement2';

        const slotElement3 = this.createChoosingElement(parentContainer, 'slotElement',  // slot element 1
            121, 97, // dw, dh // sw, sh
            this.slotPos[2].x, this.slotPos[2].y, 0, 7, 7, false, false);
        slotElement3.name = 'slotElement3';

        const winBox0 = this.createChoosingElement(parentContainer, 'slotWinBox',  // box sprite
            121, 97, // dw, dh // sw, sh
            this.slotPos[0].x, this.slotPos[0].y, 0, 1, 1, false, false);
        winBox0.name = 'slotWinBox0';

        const winBox1 = this.createChoosingElement(parentContainer, 'slotWinBox',  // box sprite
            121, 97, // dw, dh // sw, sh
            this.slotPos[1].x, this.slotPos[1].y, 0, 1, 1, false, false);
        winBox1.name = 'slotWinBox1';

        const winBox2 = this.createChoosingElement(parentContainer, 'slotWinBox',  // box sprite
            121, 97, // dw, dh // sw, sh
            this.slotPos[2].x, this.slotPos[2].y, 0, 1, 1, false, false);
        winBox2.name = 'slotWinBox2';
    }

    /**
     * Show bonus message with bonus symbol or with bonus game win.
     * @param isFirstBonus {boolean} TRUE if this is the message for starting bonus game.
     */
    drawBonusAskButton(isFirstBonus) {
        this.setState('CHOOSING_IDLE');
        this.hideBoxes();
        App.updateState('buttons', {visualization: 'choosing'});
        this.disableChooseButtons();
        this.setBackground('bonusArea');
        this.stopAnimateFeature();

        this.slot[0].img = Math.floor((Math.random() * 6));
        this.slot[1].img = Math.floor((Math.random() * 6));
        this.slot[2].img = Math.floor((Math.random() * 6));

        this.slot[0].win = 0;
        this.slot[1].win = 0;
        this.slot[2].win = 0;

        this.roulettePosition = 0;
        this.choosingStep = -1;
        if (isFirstBonus) {
            this.choosingWin = 0;
        }
        this.protections = this.getProtections(this.latestResponse);
        this.creteChosingStage(); // function to create all graphics objects

        this.screenMultiplier = {win: [-1], mult: [-1]};
        this.choosingWin = this.latestResponse.payment;
        this.showChosedItem(this.getStageChild('bonusContainer'));
    }

    getProtections(response) {
        let count = 0;
        response.screen.forEach(reel =>
            reel.forEach(row => row === 8 && count++));
        return count - 3;
    }

    /**
     * Фунция анимации зачисления кредитов в чузинге
     */
    animateMultiplier(response) {
        this.setState('ANIMATE_MULTIPLIER');
        this.isSpeedUp = 0;
        App.updateButton('start', {
            disabled: false,
            title: 'start',
            handler: () => {
                this.isSpeedUp = 1;
            }
        });
        this.superBonusStep < response.step ?
            this.animateSuperBonusMultiplier(this.superChoosing, response, Date.now()) :
            this.animateBonusMultiplier(this.choosing, response, Date.now());
    }

    /**
     * Set transfer speed when transferring win credits to account
     * speed depends on number of digits in amount of credits that should be transferred
     */
    setMultiplierTransferSpeed(amount) {
        let multiplier = 1;
        if (this.isSpeedUp === 1) {
            multiplier = Math.round(amount / 100) > 0 ? Math.round(amount / 100) : 1;
        }

        return multiplier;
    }

    /**
     * Create additional sprites and animations
     * Call once when game loaded
     * @param parentContainer
     */
    addWaitingAnimationSprites(parentContainer) {
        let waitingType = this.getWaitingState();
        if (this.waitingType === waitingType) {
            return;
        }

        parentContainer.removeChildren();
        let image;

        switch (waitingType) {
            case 'RESPONSE_RECEIVED':
                image = 'waitingRoll';
                break;
            case 'DOUBLING':
                image = 'waitingDoubling';
                break;
            case 'SUPER_BONUS_IDLE':
                image = 'waitingSuperChoosing';
                break;
            case 'SHOW_WIN_LINES':
                image = 'win';
                break;
            case 'GAMBLE_ANIMATION':
                this.waitingType = waitingType;
                return;
            case 'IDLE':
                waitingType = 'IDLE';
                image = 'waitingRoll';
                break;
        }
        const posX = this.waitingSettings[waitingType].x;
        const posY = this.waitingSettings[waitingType].y;
        const width = this.waitingSettings[waitingType].w;
        const height = this.waitingSettings[waitingType].h;
        const frameCount = this.waitingSettings[waitingType].frameCount;
        const colCount = this.waitingSettings[waitingType].colCount;
        const speed = this.waitingSettings[waitingType].animationSpeed;

        const waiting = new AnimatedSprite(this.getSpriteTextures({
            width: width,
            height: height,
            image: image,
            colCount: colCount,
            toFrame: frameCount
        }));

        waiting.name = 'waiting';
        waiting.animationSpeed = 0.001 * speed;
        waiting.position.set(posX, posY);
        waiting.loop = true;
        waiting.visible = true;
        waiting.play();
        parentContainer.addChild(waiting);
        this.waitingType = waitingType;
    }

    /**
     * Get current Game state
     * @returns {string|*}s
     */
    getWaitingState() {
        let state = this.getState();
        switch (state) {
            case 'DOUBLING':
                break;
            case 'SUPER_BONUS_IDLE':
                break;
            case 'SHOW_WIN_LINES':
                break;
            case 'RESPONSE_RECEIVED':
                break;
            case 'DRAW_GAME':
            case 'GAMBLE_ANIMATION':
            case 'CHOOSING_IDLE':
            case 'CHOOSING_ANIMATION':
            case 'ANIMATE_MULTIPLIER':
                state = 'GAMBLE_ANIMATION';
                break;
            default:  // idle response resived
                state = 'IDLE';
                break;
        }
        return state;
    }
}

class GambleUnique extends GambleIgrosoft {
    constructor(imageResources) {
        super(imageResources);
        this.cloudx = 450;
        this.cloudy = 420;
        this.riskStepX = 468;
        this.riskStepY = 100;
        this.cardWeigth = 132;
        this.cardHeight = 198;
        this.cardPositions = [
            [60, 140],
            [200, 140],
            [341, 140],
            [481, 140],
            [622, 140]
        ];
        this.dealerY = 203;
    }

    drawGambleTexts = ctx => {
        ctx.fillStyle = '#ffff00';
        ctx.strokeStyle = '#000';
        ctx.lineWidth = 2;
        ctx.textAlign = 'center';
        ctx.font = '30px Arial';
        ctx.strokeText(this.step + 1, 490, 110);
        ctx.fillText(this.step + 1, 490, 110);
    };

    getProtection = () => false;
}
