import {JL} from 'jsnlog';
import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import CustomLines from './../../CustomLines';

/* PIXI aliases */
const AnimatedSprite = PIXI.AnimatedSprite;

export default class Lines extends CustomLines {
    constructor(imageResources) {
        super(imageResources);
        this.imageResources = {...imageResources};

        this.linesSettings = {
            ...this.linesSettings,
            lineThickness: 3,
            borderThickness: 4,
            borderColor: '#b8f4fc',
            lineColor: '#ff0045'
        };

        this.boxSettings = {
            firstRightBox: 9,
            lineBoxWidth: 32,
            lineBoxHeight: 32
        };

        this.lines = {
            0: {coordinates: [1, 1, 1], boxes: [{x: 70, y: 265}]},
            1: {coordinates: [0, 0, 0], boxes: [{x: 70, y: 150}]},
            2: {coordinates: [2, 2, 2], boxes: [{x: 70, y: 380}]},
            3: {coordinates: [0, 1, 2], boxes: [{x: 70, y: 80}]},
            4: {coordinates: [2, 1, 0], boxes: [{x: 70, y: 412}]},
            5: {coordinates: [0, 1, 0], boxes: [{x: 70, y: 182}]},
            6: {coordinates: [2, 1, 2], boxes: [{x: 70, y: 348}]},
            7: {coordinates: [1, 0, 1], boxes: [{x: 70, y: 233}]},
            8: {coordinates: [1, 2, 1], boxes: [{x: 70, y: 297}]},
            9: {coordinates: [2, 1, 1], boxes: [{x: 800, y: 282}]},
            10: {coordinates: [0, 1, 1], boxes: [{x: 800, y: 249}]},
            11: {coordinates: [1, 2, 2], boxes: [{x: 850, y: 360}]},
            12: {coordinates: [1, 0, 0], boxes: [{x: 850, y: 121}]},
            13: {coordinates: [1, 1, 2], boxes: [{x: 850, y: 398}]},
            14: {coordinates: [1, 1, 0], boxes: [{x: 850, y: 129}]},
            15: {coordinates: [2, 2, 1], boxes: [{x: 850, y: 310}]},
            16: {coordinates: [0, 0, 1], boxes: [{x: 850, y: 240}]}
        };
        Object.keys(this.lines).forEach(lineIndex => this.lines[lineIndex].boxes[0].y += 50);

        this.lineMap = [
            3, 1, 5, 7, 0, 8, 6, 2, 4,
            14, 12, 16, 10, 9, 15, 11, 13
        ];

        JL().debug('-- Lines17 initialized');
    }

    drawBoxes(parentContainer) {

    }

    getPointPosY = rowIndex => App.Game.reelTop + (App.Game.symbolHeight + 27) * (rowIndex + 0.5);

    drawWinBoxes = (lineIndex, winReels, parentContainer) => {
        parentContainer.sortableChildren = true;
        const line = this.lines[lineIndex];

        line.coordinates.forEach((rowIndex, reelIndex) => {
            if (winReels.includes(reelIndex) && this.winBoxes) {
                const winLinesBoxSprite = new AnimatedSprite(App.Game.getSpriteTextures({
                    image: 'winLinesBox', colCount: 5,
                    toFrame: 35, width: 200, height: 200
                }));
                winLinesBoxSprite.name = `${reelIndex}:${rowIndex}`;
                winLinesBoxSprite.position.x = App.Game.reelXCoordinates[reelIndex] - 50;
                winLinesBoxSprite.position.y = App.Game.symbolHeight * (rowIndex) + App.Game.reelTop - 60;
                winLinesBoxSprite.animationSpeed = 0.385;
                winLinesBoxSprite.zIndex = 1;
                winLinesBoxSprite.scale.set(1.5);
                winLinesBoxSprite.play();
                parentContainer.addChild(winLinesBoxSprite);
            }
        });
    }
}
