import {JL} from 'jsnlog';
import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import GameDeluxe5x4 from './../../deluxe/gameDeluxe5x4';
import Lines50 from './../../deluxe/lines50';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';
import bonusFont from './img/font/fontBonus';
import fontNumber from './img/font/fontNumber';

/* PIXI aliases */
const AnimatedSprite = PIXI.AnimatedSprite;

export default class BeeWild extends GameDeluxe5x4 {
    constructor() {
        super();
        this.id = 'bee-wild';
        this.name = 'Bee Wild';
        this.transparentBackground = true;
        this.reelTop = 88;
        this.positions = [];
        this.allowLongRoll = true;
        this.reelFilter = [[11], [], [], [], [11]];
        this.buttonsPanelShadow = 'strong';

        // roll properties
        this.reelSettings = [20, 6, 30]; // 0 - start symbol amount, 1 - reel increase regular roll, 2 - reel increase long roll
        this.scatter = 11;
        this.symbolEffects = true;
        this.longRollSettings = [0, 3]; // long roll from reelIndex to reelIndex

        // координаты бонусных табличек
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 165, y: 150},
            endBonusFrame: {x: 165, y: 150}
        };

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 0, 4, 20, 100]},    // 0 - 9
            {regularDelay: 100, payment: [0, 0, 0, 4, 20, 100]},    // 1 - 10
            {regularDelay: 100, payment: [0, 0, 0, 8, 40, 160]},    // 2 - J
            {regularDelay: 100, payment: [0, 0, 0, 8, 40, 160]},    // 3 - Q
            {regularDelay: 100, payment: [0, 0, 0, 12, 60, 200]},   // 4 - K
            {regularDelay: 100, payment: [0, 0, 0, 12, 60, 200]},   // 5 - A
            {regularDelay: 100, payment: [0, 0, 0, 16, 200, 600]},  // 6 - горшок меда
            {regularDelay: 100, payment: [0, 0, 0, 16, 200, 600]},  // 7 - улик
            {regularDelay: 100, payment: [0, 0, 0, 20, 300, 800]},  // 8 - пчела в зеленом круге
            {regularDelay: 100, payment: [0, 0, 0, 20, 300, 800]},  // 9 - пчела в красном круге
            {regularDelay: 100, payment: [0, 0, 0, 40, 500, 1000]}, // 10 - BeeWild
            {regularDelay: 100, payment: [0, 0, 0, 2, 0, 0]}        // 11 - пчела в желтом круге
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: `area/${App.System.resolution}/main.png`,
                background: 'area/background.png',
                bonusFont: bonusFont['imageResource'],
                fontNumber: fontNumber['imageResource']
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                frame: 'bonus/frame.png',
                bonusAnimation: 'bonus/bonusAnimation.png',
                beeWild: 'bonus/bee_wild.png',
                money_1: 'bonus/money_1.png',
                money_2: 'bonus/money_2.png'
            }),
            atlas: this.mergePath([
                'regularLongSymbols.json',
                'regularLongSymbols2.json',
                'bonus/scatterSymbols.json'
            ])
        };

        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'bonus-game-end', alias: 'bonusGameEnd'},
                {name: 'bonus-background', loop: true},
                {name: 'book1', alias: 'teaser_1', path: '/audio/deluxe/'},
                {name: 'book2', alias: 'teaser_2', path: '/audio/deluxe/'},
                {name: 'book3', alias: 'teaser_3', path: '/audio/deluxe/'},
                {name: 'book4', alias: 'teaser_4', path: '/audio/deluxe/'},
                {name: 'book5', alias: 'teaser_5', path: '/audio/deluxe/'},
                {name: 'book6', alias: 'teaser_6', path: '/audio/deluxe/'},
                {name: 'long1'}
            ],
            path: `/game/games/${this.id}/audio/`
        };

        this.Lines = new Lines50();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));

        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '17pt franklinFont';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#f7c354';
        ctx.shadowColor = 'black';
        ctx.shadowOffsetX = 3;
        ctx.shadowOffsetY = 3;
        ctx.shadowBlur = 5;

        switch (page) {
            case 1:
                const textProps = {
                    parentContainer: ctx,
                    fontImageName: 'fontNumber',
                    map: fontNumber,
                    align: 'center',
                    scale: 1.3,
                    fontInterval: -15 // px between symbols
                };

                // bee wild
                this.drawCustomFont(bet * this.symbols[10].payment[5], 245, 108, textProps);
                this.drawCustomFont(bet * this.symbols[10].payment[4], 245, 131, textProps);
                this.drawCustomFont(bet * this.symbols[10].payment[3], 245, 152, textProps);

                // q j
                this.drawCustomFont(bet * this.symbols[2].payment[5], 375, 464, textProps);
                this.drawCustomFont(bet * this.symbols[2].payment[4], 375, 485, textProps);
                this.drawCustomFont(bet * this.symbols[2].payment[3], 375, 506, textProps);

                // red green bee
                this.drawCustomFont(bet * this.symbols[8].payment[5], 160, 232, textProps);
                this.drawCustomFont(bet * this.symbols[8].payment[4], 160, 252, textProps);
                this.drawCustomFont(bet * this.symbols[8].payment[3], 160, 272, textProps);

                // cup house
                this.drawCustomFont(bet * this.symbols[6].payment[5], 590, 232, textProps);
                this.drawCustomFont(bet * this.symbols[6].payment[4], 590, 252, textProps);
                this.drawCustomFont(bet * this.symbols[6].payment[3], 590, 272, textProps);

                // 9 10
                this.drawCustomFont(bet * this.symbols[0].payment[5], 650, 450, textProps);
                this.drawCustomFont(bet * this.symbols[0].payment[4], 650, 472, textProps);
                this.drawCustomFont(bet * this.symbols[0].payment[3], 650, 494, textProps);

                // a k
                this.drawCustomFont(bet * this.symbols[4].payment[5], 98, 450, textProps);
                this.drawCustomFont(bet * this.symbols[4].payment[4], 98, 472, textProps);
                this.drawCustomFont(bet * this.symbols[4].payment[3], 98, 494, textProps);

                // bonus
                this.drawCustomFont(bet * this.symbols[11].payment[3] * nLines, 375, 334, textProps);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    /**
     * Show bonus message with bonus symbol or with bonus game win.
     * @param isFirstBonus {boolean} TRUE if this is the message for starting bonus game.
     */
    drawBonusAskButton(isFirstBonus = false) {
        let isLast = !isFirstBonus && this.bonusStatus && this.bonusStatus.remain === 0;

        this.stopAnimateFeature();
        setTimeout(() => {
            this.drawBonusFrame(isFirstBonus, isLast, this.getStageChild('bonusContainer'), this.coordinatesBonusFrame);
        }, 1000);

        this.gameFlag.bonusStart = true;
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});

        isLast = this.bonusStatus && this.bonusStatus.remain === 0;
    }

    drawBonusFrame(first, last, parentContainer, coordinates) {
        const {startBonusFrame, endBonusFrame} = coordinates;

        if (first) {
            this.drawBonusAnimation(parentContainer, startBonusFrame);
            this.reelFilter = [[11], [11], [11], [11], [11]];
        }
        if (last) {
            this.resetBeeWildSymbol(parentContainer);
            this.drawMoneyAfterBonus(parentContainer, endBonusFrame, this.bonusStatus);
            this.playEndBonusGameSound();
        }
    }

    resetBeeWildSymbol(parentContainer) {
        this.positions.forEach(pos => {
            parentContainer.getChildByName(`${pos.x}:${pos.y}`).gotoAndStop(0);
            parentContainer.getChildByName(`${pos.x}:${pos.y}`).alpha = 1;
            const symbolObj = this.reelMatrix[pos.x][pos.y];
            symbolObj.symbol = 10;
            this.Roll.updateSymbolSprite(symbolObj);
            symbolObj.sprite.visible = true;
        });
    }

    drawBonusAnimation(parentContainer, {x, y}) {
        const sprite = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 24, image: 'bonusAnimation', colCount: 4,
            height: 264, width: 470
        }));
        sprite.name = 'frameAnimation';
        sprite.loop = false;
        sprite.animationSpeed = 0.15;
        sprite.position.set(x, y);
        sprite.play();
        sprite.onComplete = () => this.showStartBonusFrame(parentContainer);
        parentContainer.addChild(sprite);
    }

    /**
     * Отрисовка таблички бонусной игры
     */
    showStartBonusFrame(parentContainer) {
        this.showPressAnyButton(false);

        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            fontInterval: -7
        };

        this.drawCustomFont('10 FREE GAMES', 400, 240, textProps);
        this.drawCustomFont('WON', 400, 300, textProps);

        App.updateButton('start', {
            disabled: false,
            title: 'start',
            handler: () => {
                parentContainer.removeChildren();
                this.startBonusAnimation();
            }
        });
    }

    /**
     * Отрисовка таблички окончания бонусной игры
     */
    showEndBonusFrame(parentContainer, {x, y}, {win, total}) {
        this.showBonusFrame(parentContainer, x, y, 'frame');

        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            fontInterval: -7 // px between symbols
        };

        this.drawCustomFont('FEATURE WIN', 400, 260, textProps);
        this.drawCustomFont(`${this.bonusStatus.win} CREDITS`, 400, 300, textProps);
    }

    drawMoneyAfterBonus(parentContainer, {x, y}, {win, total}) {
        const props = {
            colCount: 3,
            width: 680,
            height: 390,
            toFrame: 15
        };
        const moneySprite = new AnimatedSprite([
            ...this.getSpriteTextures({...props, image: 'money_1'}),
            ...this.getSpriteTextures({...props, image: 'money_2'})
        ]);
        moneySprite.loop = false;
        moneySprite.name = 'moneySprite';
        moneySprite.animationSpeed = 0.25;
        moneySprite.position.set(61, 210);
        moneySprite.play();
        moneySprite.onComplete = () => {
            this.showEndBonusFrame(parentContainer, {x, y}, {win, total});
            this.hideEndBonusFrame(parentContainer);
            moneySprite.destroy();
        };
        parentContainer.addChild(moneySprite);
    }

    onRotationDone() {
        JL().debug(`-- Rotation done (fps: ${App.System.statistics.fps})`);
        const {features, payment} = this.latestResponse;
        App.updateButton('start', {disabled: true});
        this.roundWin = 0;

        if (!this.isBonus()) {
            if (payment > 0 || features.length) {
                this.setState('SHOW_WIN_LINES');
                this.startAnimateFeature(features);
            } else {
                this.setState('IDLE_BONUS');
                this.Legends.setRoundFinText();
                this.roundFinished();
            }
        } else { // bonus game
            this.drawFrozenJoker();
            if (payment > 0 || features.length) {
                this.setState('SHOW_WIN_LINES');
                this.startAnimateFeature(features);
            } else { // no win
                if (this.bonusStatus && this.bonusStatus.remain > 0) {
                    this.roundFinished(false);
                } else {
                    this.Legends.setRoundFinText();
                    this.finishBonus();
                }
            }
        }
    }

    /**
     * Call after all book animation ended
     */
    bonusRoll() {
        App.Sounds.playSound('bonus-background');
        this.setState('IDLE_BONUS');
        this.startBonusRoll();
    }

    drawFrozenJoker() {
        const bonusContainer = this.getStageChild('bonusContainer');
        this.latestResponse.extension.screen.forEach((reel, reelIndex) => {
            reel.forEach((symbol, rowIndex) => {
                if (symbol === 10) {
                    this.positions.push({x: reelIndex, y: rowIndex});
                    const sprite = new AnimatedSprite(this.getSpriteTextures({
                        image: 'beeWild',
                        width: this.symbolWidth,
                        height: this.symbolHeight,
                        colCount: 1,
                        toFrame: 18
                    }));

                    sprite.name = `${reelIndex}:${rowIndex}`;
                    sprite.loop = false;
                    sprite.position.x = this.reelXCoordinates[reelIndex];
                    sprite.position.y = this.symbolHeight * (rowIndex) + this.reelTop;
                    !bonusContainer.getChildByName(sprite.name) && bonusContainer.addChild(sprite);
                }
            });
        });
    };

    additionalPreparingToAnimateFeature() {
        const bonusContainer = this.getStageChild('bonusContainer');
        this.isBonus() && bonusContainer.children.length && this.positions.forEach((pos) => {
            bonusContainer.getChildByName(`${pos.x}:${pos.y}`).alpha = 0.5;

            const symbolObj = this.reelMatrix[pos.x][pos.y];
            symbolObj.sprite.visible = false;
        });
        this.latestResponse.features.forEach(feature => {
            feature.uc === 'WIN_LINE' && feature.reels.forEach(reelIndex => {
                const rowIndex = this.Lines.lines[feature.number].coordinates[reelIndex];
                const symbolObj = (bonusContainer.getChildByName(`${reelIndex}:${rowIndex}`));
                if (bonusContainer.getChildByName(`${reelIndex}:${rowIndex}`) !== null) {
                    symbolObj.alpha = 1;
                    symbolObj.loop = true;
                    symbolObj.animationSpeed = 0.15;
                    symbolObj.play();
                }
            });
        });
    };

    hideEndBonusFrame(parentContainer) {
        setTimeout(() => { // needed for animation
            this.endBonus(parentContainer);
            this.positions = [];
        }, 2500);
    }

    restoreBonusGame() {
        this.reelFilter = [[11], [11], [11], [11], [11]];
        this.drawFrozenJoker();
        this.bonusWin = this.bonusStatus.win - this.latestResponse.payment;
        // Fill WIN data
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});
        this.Legends.showWinFeatures();
        this.setBonusStatusText();

        this.Buttons.disableAllButtons();
        this.gameFlag.bonusStart = true;
        this.gameFlag.bonusStarted = true;

        this.processReelResponse(this.latestResponse);
    }

    stopAnimateFeature() {
        this.stopFeatureTimeout();
        this.getStageChild('linesContainer').removeChildren();
        this.resetSymbolAnimation();
        this.Legends.clearText('features');
        !this.isBonus() && this.Legends.clearText('win');
        if (this.isBonus() && (this.getState() === 'IDLE_BONUS' || this.getState() === 'SHOW_WIN_LINES')) {
            const bonusContainer = this.getStageChild('bonusContainer');
            this.resetBeeWildSymbol(bonusContainer);
        }
    }
}
