import App from './../../index';
import GameSettings from './../gameSettings';

export default class GameSettingsWays extends GameSettings {
    /**
     * Получить текущую ставку
     */
    getBet() {
        const multiply = App.Game.extraBetActive ? 2 : 1;
        return this.getBetLine() * multiply;
    }
}
