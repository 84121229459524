import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../../../index';
import GameDeluxe from './../../deluxe/game';
import Lines10 from './../../deluxe/lines10';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';
import bonusFont from './img/font/bonusFont';

/* PIXI aliases */
const AnimatedSprite = PIXI.AnimatedSprite;

export default class PharaohsTomb extends GameDeluxe {
    constructor() {
        super();
        this.id = 'pharaohs-tomb';
        this.name = `Pharaoh's Tomb`;
        this.buttonsPanelShadow = 'low';
        this.bonusVector = [4, 5, 6, 7, 8, 9];
        this.positions = [];

        // roll properties
        this.reelSettings = [15, 3, 22]; // 0 - start symbol amount, 1 - reel increase regular roll, 2 - reel increase long roll
        this.reelFilter = [[12, 13], [12, 13], [12, 13], [12, 13], [12, 13]];
        this.scatter = 11;
        this.allowLongRoll = true;
        this.reelSoundStep = 1; // reels sound

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 40, y: 75},
            bonusInBonusFrame: {x: 40, y: 75},
            endBonusFrame: {x: 40, y: 75}
        };

        this.symbols = [
            {regularDelay: 100, bonusDelay: 350, payment: [0, 0, 0, 5, 20, 100]},  // 0 - J
            {regularDelay: 100, bonusDelay: 350, payment: [0, 0, 0, 5, 20, 100]},  // 1 - Q
            {regularDelay: 100, bonusDelay: 350, payment: [0, 0, 0, 5, 20, 100]},  // 2 - K
            {regularDelay: 100, bonusDelay: 350, payment: [0, 0, 0, 5, 20, 100]},  // 3 - A
            {regularDelay: 100, bonusDelay: 350, payment: [0, 0, 0, 10, 50, 200]}, // 4 - два паруса
            {regularDelay: 100, payment: [0, 0, 0, 10, 50, 200]},                  // 5 - две волны
            {regularDelay: 100, payment: [0, 0, 0, 20, 100, 250]},                 // 6 - cobra
            {regularDelay: 100, payment: [0, 0, 0, 20, 125, 300]},                 // 7 - scarab
            {regularDelay: 100, payment: [0, 0, 0, 50, 200, 400]},                 // 8 - bird
            {regularDelay: 100, payment: [0, 0, 0, 100, 250, 500]},                // 9 - symbol
            {regularDelay: 100, payment: [0, 0, 0, 0, 0, 0]},                      // 10 - pharaoh
            {regularDelay: 100, payment: [0, 0, 0, 2, 20, 50]},                    // 11 - Pharaoh`s Tomb
            {regularDelay: 100, payment: [0, 0, 0, 0, 0, 0]},                      // 12 - pharaoh
            {regularDelay: 100, payment: [0, 0, 0, 0, 0, 0]}                       // 13 - pharaoh
        ];
        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                bonusFont: bonusFont['imageResource']
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                pharaohsFillAnimation: 'area/pharaohsFillAnimation.png',
                bonusArea: 'area/bonus.png',
                changedMatrixSymbols: 'bonus/changeMatrix.jpg',
                symbolAnimation: 'bonus/symbols-animation.png',
                pharaohsOpenAnimation: 'bonus/pharaohsOpenAnimation.png',
                frame: 'bonus/frame.png'
            }),
            atlas: this.mergePath([
                'regularLongSymbols.json',
                'bonus/scatterSymbols.json'
            ])
        };
        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'bonus-game-end', alias: 'bonusGameEnd'},
                {name: 'pharaohs-opening'},
                {name: 'wild-expand'},
                {name: 'reels1'},
                {name: 'reels2'},
                {name: 'book1', alias: 'teaser_1', path: '/audio/deluxe/'},
                {name: 'book2', alias: 'teaser_2', path: '/audio/deluxe/'},
                {name: 'book3', alias: 'teaser_3', path: '/audio/deluxe/'},
                {name: 'book4', alias: 'teaser_4', path: '/audio/deluxe/'},
                {name: 'book5', alias: 'teaser_5', path: '/audio/deluxe/'},
                {name: 'long1', alias: 'long1'},
                {name: 'banner'},
                {name: 'bonus-background', loop: true}
            ],
            path: `/game/games/${this.id}/audio/`
        };

        this.Lines = new Lines10();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            activeRed: 'gamble/red-active.png',
            inactiveRed: 'gamble/red-inactive.png',
            activeBlack: 'gamble/black-active.png',
            inactiveBlack: 'gamble/black-inactive.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));
        this.Gamble.restoreGambleScreen = this.restoreGambleScreen;

        this.InfoScreen = new InfoScreen({pages: 4}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines - current lines count
     * @param bet - current bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '17pt franklinFont';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#f7c354';
        ctx.shadowColor = 'black';
        ctx.shadowOffsetX = 3;
        ctx.shadowOffsetY = 3;
        ctx.shadowBlur = 5;

        switch (page) {
            case 1:
                // Pharaoh's Tomb
                ctx.fillText(bet * nLines * this.symbols[11].payment[5], 145, 268);
                ctx.fillText(bet * nLines * this.symbols[11].payment[4], 145, 293);
                ctx.fillText(bet * nLines * this.symbols[11].payment[3], 145, 318);

                // паруса
                ctx.fillText(bet * this.symbols[4].payment[5], 78, 488);
                ctx.fillText(bet * this.symbols[4].payment[4], 78, 513);
                ctx.fillText(bet * this.symbols[4].payment[3], 78, 538);

                // крест
                ctx.fillText(bet * this.symbols[9].payment[5], 738, 488);
                ctx.fillText(bet * this.symbols[9].payment[4], 738, 513);
                ctx.fillText(bet * this.symbols[9].payment[3], 738, 538);

                // кобра
                ctx.fillText(bet * this.symbols[6].payment[5], 345, 488);
                ctx.fillText(bet * this.symbols[6].payment[4], 345, 513);
                ctx.fillText(bet * this.symbols[6].payment[3], 345, 538);

                // скарабей
                ctx.fillText(bet * this.symbols[7].payment[5], 475, 488);
                ctx.fillText(bet * this.symbols[7].payment[4], 475, 513);
                ctx.fillText(bet * this.symbols[7].payment[3], 475, 538);

                // вода
                ctx.fillText(bet * this.symbols[5].payment[5], 208, 488);
                ctx.fillText(bet * this.symbols[5].payment[4], 208, 513);
                ctx.fillText(bet * this.symbols[5].payment[3], 208, 538);

                // птица
                ctx.fillText(bet * this.symbols[8].payment[5], 605, 488);
                ctx.fillText(bet * this.symbols[8].payment[4], 605, 513);
                ctx.fillText(bet * this.symbols[8].payment[3], 605, 538);

                // A, K, J, Q
                ctx.fillText(bet * this.symbols[1].payment[5], 738, 268);
                ctx.fillText(bet * this.symbols[1].payment[4], 738, 293);
                ctx.fillText(bet * this.symbols[1].payment[3], 738, 318);
                break;
            case 4:
                const textProps = {
                    parentContainer: ctx,
                    fontImageName: 'bonusFont',
                    map: bonusFont,
                    align: 'center',
                    fontInterval: -5 // px between symbols
                };
                this.drawCustomFont('RULES', 400, 150, textProps);

                ctx.font = '16pt Times New Roman';
                ctx.fillStyle = '#fff';
                ctx.fillText('All prizes are for combinations of a kind. All prizes are for combinations', 400, 250);
                ctx.fillText('left to right, except scatters. All prizes are on selected lines, except scatters.', 400, 280);
                ctx.fillText('Scatter symbols pay at any position on screen. Highest win only paid per', 400, 310);
                ctx.fillText('selected line. Scatter wins are added to line wins. Free Games can be won', 400, 340);
                ctx.fillText('again during the Free Games. Free Games are played at trigger bet and', 400, 370);
                ctx.fillText('lines. All prizes shown in credits. Malfunction voids all pays and plays.', 400, 400);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    /**
     * Отрисовка таблички бонусной игры
     */
    showStartBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y);
        App.Sounds.playSound('banner');
        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            fontInterval: -5 // px between symbols
        };
        this.drawCustomFont('12 FREE GAMES WON', 400, 245, textProps);
    }

    /**
     * Отрисовка таблички бонус в бонусе
     */
    showBonusInBonusFrame(parentContainer, {x, y}, freeGames = 12) {
        this.showBonusFrame(parentContainer, x, y);
        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            fontInterval: -5 // px between symbols
        };
        this.drawCustomFont(`${freeGames} MORE`, 400, 240, textProps);
        this.drawCustomFont('FREE GAMES', 400, 290, textProps);
    }

    /**
     * Отрисовка таблички окончания бонусной игры
     */
    showEndBonusFrame(parentContainer, {x, y}, {win, total}) {
        this.showBonusFrame(parentContainer, x, y);
        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            fontInterval: -5 // px between symbols
        };
        this.drawCustomFont('FEATURE WIN', 400, 200, textProps);
        this.drawCustomFont(`${win} CREDITS`, 400, 250, textProps);
        this.drawCustomFont(`${total} FREE`, 400, 300, textProps);
        this.drawCustomFont('GAMES PLAYED', 400, 350, textProps);
    }

    playRollSound = () => {
        this.reelSoundStep = this.reelSoundStep === 2 ? 1 : 2;
        this.gameFlag.bonusStart || App.restoreGameState === 'BONUS' ?
            App.Sounds.playSound('bonus_reels') :
            App.Sounds.playSound(`reels${this.reelSoundStep}`);
    };

    stopRollSound = () => {
        App.Sounds.stopSound('bonus_reels');
        App.Sounds.stopSound(`reels${this.reelSoundStep}`);
    };

    onRotationDone() {
        JL().debug(`-- Rotation done (fps: ${App.System.statistics.fps})`);
        App.updateButton('start', {disabled: true});
        this.roundWin = 0;

        // verify is pharaohs exist
        this.checkPharaohsPos(this.latestResponse.screen) ?
            this.fillPharaohOnReel(() => this.onRotationDoneCheckWin()) :
            this.onRotationDoneCheckWin();
    }

    onRotationDoneCheckWin() {
        const {features, payment} = this.latestResponse;
        if (payment > 0 || this.isFreeRoll(features) || features.length) {
            // if it's only free roll (pharaoh)
            const firstFeature = features[0];
            if (firstFeature.uc === 'FREE_ROLL') {
                this.drawPharaohsFrame(firstFeature.amount);
            } else {
                this.setState('SHOW_WIN_LINES');
                this.startAnimateFeature(features);
            }
        } else {
            if (this.isBonus()) {
                this.onRotationDoneBonus();
            } else {
                this.roundFinished();
                this.Legends.setRoundFinText();
            }
        }
    }

    onRotationDoneBonus() {
        if (this.bonusStatus && this.bonusStatus.remain > 0) {
            this.roundFinished(false);
        } else {
            this.Legends.setRoundFinText();
            this.finishBonus();
        }
    }

    /**
     * Start scatter animations
     * @param features
     */
    bonusEntrance(features) {
        this.playBonusGameSound();
        this.stopAnimateFeature();
        this.drawTopAnimation(this.getStageChild('bonusContainer'));
        App.updateButton('start', {disabled: true});

        const scatterFeature = features.find(({uc}) => uc === 'SCATTER');
        scatterFeature ?
            this.playScatterAnimation(scatterFeature, () => // call after scatter played
                this.drawBonusAskButton(this.isFreeRoll(features) && !this.bonusStatus)) :
            this.drawBonusStep(features);
    }

    startBonusRoll = () => {
        JL().debug('-- Start bonus roll');
        this.cleanBeforeRoll();
        this.getStageChild('bonusContainer').removeChildren();
        this.Buttons.disableAllButtons();
        App.updateButton('start', {disabled: true});
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});
        this.sendRoll();
    };

    /**
     * Create PIXI AnimatedSprites for pharaohs opening animation
     * @param callback - call after last animation completed
     */
    fillPharaohOnReel(callback) {
        JL().debug(`-- Fill pharaohs on reel: ${JSON.stringify(this.positions)}`);
        App.updateButton('start', {disabled: true});
        App.Sounds.stopSound('bonus-background');
        App.Sounds.playSound('pharaohs-opening');
        const container = this.getStageChild('bonusContainer');

        this.positions.forEach((pos, index) => {
            let {x, y} = pos;
            const sprite = new AnimatedSprite(this.getSpriteTextures({
                fromFrame: y * 12, toFrame: ++y * 12, image: 'pharaohsFillAnimation',
                width: this.symbolWidth, height: this.symbolHeight * this.reelRows
            }));
            sprite.animationSpeed = 0.4;
            sprite.loop = false;
            sprite.position.set(this.reelXCoordinates[x], this.reelTop);
            sprite.play();
            container.addChild(sprite);

            sprite.onComplete = () => {
                // change reelMatrix to pharaohs symbols and remove additional animations
                const pharaohsSymbols = [10, 12, 13];
                this.reelMatrix[x].forEach((symbolObj, rowIndex) => {
                    symbolObj.symbol = this.latestResponse.screen[x][rowIndex] = pharaohsSymbols[rowIndex];
                    this.Roll.updateSymbolSprite(symbolObj);
                });
                container.removeChild(sprite);
                // is last animated sprite
                index === this.positions.length - 1 && callback();
            };
        });
    }

    /**
     * Find and save pharaoh positions
     * @param screen - response
     * @returns {boolean} - is pharaoh exist
     */
    checkPharaohsPos(screen) {
        this.positions = [];
        screen.forEach((reel, reelIndex) =>
            reel.forEach((symbol, rowIndex) =>
                symbol === 10 && this.positions.push({x: reelIndex, y: rowIndex})));

        return !!this.positions.length;
    }

    drawPharaohsFrame(freeGames) {
        JL().debug('-- Draw pharaohs frame');
        const container = this.getStageChild('bonusContainer');
        const {bonusInBonusFrame} = this.coordinatesBonusFrame;

        setTimeout(() => this.showBonusInBonusFrame(container, bonusInBonusFrame, freeGames), 500);
        setTimeout(() => this.openPharaoh(container), 3000);
    }

    /**
     * Prepare game behaviour after bonus 'press any button' message
     */
    startBonusAnimation = () => {
        this.setState('IDLE_BONUS');
        App.Sounds.stopSound('banner');
        this.clearPressAnyButton();
        this.Buttons.disableAllButtons();
        App.updateButton('start', {disabled: true});

        if (this.isFreeRoll(this.latestResponse.features)) {
            this.gameFlag.bonusStarted && this.getPharaohCount() ?
                this.drawBonusAnimationAfterReels() :
                this.bonusRoll();
        }
        this.gameFlag.bonusStarted = true;
    };

    isBonusSymbolWin = () => this.getPharaohCount();

    drawBonusAnimationAfterReels() {
        App.Sounds.stopSound('bonus-background');
        this.stopAnimateFeature();
        const freeGames = this.latestResponse.features.find(({uc}) => uc === 'FREE_ROLL').amount;
        this.drawPharaohsFrame(freeGames);
    }

    /**
     * Animate pharaoh open and symbol changing
     * @param parentContainer
     */
    drawBonusAnimation(parentContainer) {
        const {extension: {symbolPrevious, symbolCurrent}} = this.latestResponse;
        const frames = 13;
        this.bonusVector = this.getBonusVector(symbolPrevious);

        // add animation for each pharaoh position
        this.positions.forEach((pos, index) => {
            const {x} = pos;
            const bonusVectorSprites = [];

            // create symbols in pharaoh
            this.bonusVector.forEach((symbol, index) => {
                const width = 61;
                const height = 61;

                // check is need animate
                let stepY = symbol < symbolCurrent ?
                    this.getAnimationSprite(symbol, symbolCurrent) :
                    this.getStaticSprite(symbol, symbolCurrent);

                const sprite = new AnimatedSprite(this.getSpriteTextures({
                    fromFrame: stepY * frames, toFrame: ++stepY * frames, image: 'symbolAnimation',
                    width, height, colCount: frames
                }));
                sprite.name = `pharaohSymbol${symbol}`;
                sprite.position.set(
                    this.reelXCoordinates[x] + 35,
                    this.reelTop + this.symbolHeight * this.reelRows - 70 - (height * index)
                );
                sprite.loop = false;
                sprite.animationSpeed = 0.15;
                parentContainer.addChild(sprite);

                bonusVectorSprites.push({symbol, sprite});
            });

            // add pharaoh open animation
            const sprite = new AnimatedSprite(this.getSpriteTextures({
                toFrame: 24, image: 'pharaohsOpenAnimation',
                width: this.symbolWidth, height: this.symbolHeight * this.reelRows,
                colCount: 6
            }));
            sprite.name = `pharaoh-${index}`;
            sprite.position.set(this.reelXCoordinates[x], this.reelTop);
            sprite.loop = false;
            sprite.animationSpeed = 0.15;
            sprite.play();
            sprite.onComplete = () => {
                this.bonusVector = this.getBonusVector(symbolCurrent);

                // animate pharaohs symbols
                bonusVectorSprites.forEach(symbolObj => {
                    const {symbol, sprite} = symbolObj;
                    symbol < symbolCurrent && sprite.play();
                });

                // animate reels symbols
                this.findSymbolOnReel().forEach(pos => {
                    const {x, y} = pos;
                    const symbolObj = this.reelMatrix[x][y];
                    let stepY = this.getAnimationSprite(symbolObj.symbol, symbolCurrent);

                    symbolObj.sprite.textures = this.getSpriteTextures({
                        fromFrame: stepY * frames, toFrame: ++stepY * frames, image: 'changedMatrixSymbols',
                        width: this.symbolWidth, height: this.symbolHeight, colCount: frames
                    });
                    symbolObj.sprite.loop = false;
                    symbolObj.sprite.play();
                    symbolObj.sprite.onComplete = () => {
                        symbolObj.symbol = symbolCurrent;
                        this.Roll.updateSymbolSprite(symbolObj);
                    };
                    this.lastScreen[x][y] = symbolCurrent;
                });

                index === this.positions.length - 1 && setTimeout(() => {
                    App.Sounds.playSound('bonus-background');
                    this.onRotationDoneBonus();
                }, 2500);
            };

            // hide symbols under pharaoh sprite
            this.reelMatrix[x].forEach(symbolObj =>
                symbolObj.sprite.visible = false);

            parentContainer.addChild(sprite);
        });
    }

    getBonusVector = symbol => {
        const bonusVector = [4, 5, 6, 7, 8, 9];
        for (let i = 0; i < bonusVector.length; i++) {
            if (bonusVector[i] < symbol) {
                bonusVector[i] = symbol;
            }
        }
        return bonusVector;
    };

    getPharaohCount() {
        let count = 0;
        this.reelMatrix.forEach(reel =>
            reel.forEach(symbolObj => symbolObj.symbol === 10 && count++));
        return count;
    }

    getAnimationSprite = (currentSymbol, newSymbol) => {
        let bonusSpriteIndex = null;
        switch (`${currentSymbol} ${newSymbol}`) {
            case '4 5':
                bonusSpriteIndex = 0;
                break;
            case '4 6':
                bonusSpriteIndex = 1;
                break;
            case '4 7':
                bonusSpriteIndex = 2;
                break;
            case '5 6':
                bonusSpriteIndex = 3;
                break;
            case '5 7':
                bonusSpriteIndex = 4;
                break;
            case '5 8':
                bonusSpriteIndex = 5;
                break;
            case '6 7':
                bonusSpriteIndex = 6;
                break;
            case '6 8':
                bonusSpriteIndex = 7;
                break;
            case '6 9':
                bonusSpriteIndex = 8;
                break;
            case '7 8':
                bonusSpriteIndex = 9;
                break;
            case '7 9':
                bonusSpriteIndex = 10;
                break;
            case '8 9':
                bonusSpriteIndex = 11;
                break;
        }
        return bonusSpriteIndex;
    };

    /**
     * Create array of symbol positions
     * Need for symbol changing during pharaoh open
     */
    findSymbolOnReel() {
        const symbolExtension = this.latestResponse.extension.symbolCurrent - 1;
        const symbolChangePositions = [];

        this.reelMatrix.forEach((reel, reelIndex) => {
            reel.forEach((symbol, rowIndex) => {
                if (symbol.symbol <= symbolExtension && symbol.symbol >= 4) {
                    symbolChangePositions.push({x: reelIndex, y: rowIndex});
                }
            });
        });
        return symbolChangePositions;
    }

    getStaticSprite = currentSymbol => {
        let bonusStaticIndex = null;
        switch (currentSymbol) {
            case 5:
                bonusStaticIndex = 3;
                break;
            case 6:
                bonusStaticIndex = 6;
                break;
            case 7:
                bonusStaticIndex = 9;
                break;
            case 8:
                bonusStaticIndex = 11;
                break;
            case 9:
                bonusStaticIndex = 12;
                break;
        }
        return bonusStaticIndex;
    };

    getRandomSymbol(length, reelIndex, symbolBefore) {
        let symbol = Math.floor(Math.random() * length);

        while (
            symbol === this.reelFilter[reelIndex][0] ||
            symbol === this.reelFilter[reelIndex][1] ||
            symbol === this.reelFilter[reelIndex][2] ||
            symbol === symbolBefore ||
            symbol === 12 || symbol === 13 || symbol === 14) {
            symbol = Math.floor(Math.random() * length);
        }
        return symbol;
    }

    additionalFilter = reelMas => {
        reelMas.forEach(vector => {
            if (
                vector[vector.length - 4] === 10 ||
                vector[vector.length - 3] === 10 ||
                vector[vector.length - 2] === 10
            ) {
                vector[vector.length - 4] = 10;
                vector[vector.length - 3] = 12;
                vector[vector.length - 2] = 13;
            }
        });

        return reelMas;
    };

    /**
     * Prepare game behaviour after bonus 'press any button' message
     */
    openPharaoh = parentContainer => {
        JL().debug('-- Open pharaoh');
        parentContainer.removeChildren();
        this.clearPressAnyButton();
        this.Buttons.disableAllButtons();
        App.updateButton('start', {disabled: true});

        const {extension} = this.latestResponse;
        if (extension && extension.symbolPrevious !== extension.symbolCurrent) {
            App.Sounds.playSound('wild-expand');
            this.drawBonusAnimation(parentContainer);
        } else {
            App.Sounds.playSound('bonus-background');
            this.onRotationDoneBonus();
        }
    };

    /**
     * End bonus round, return to bonus game.
     */
    finishBonus() {
        this.bonusVector = this.getBonusVector();
        this.positions = [];
        this.drawBonusAskButton();
        App.Sounds.stopSound('bonus-background');
        this.latestResponse.payment = this.bonusWin;
        this.Legends.setStatus('creditsWon', this.bonusWin);
        this.bonusWin = 0;
        this.gameFlag.bonusStart = false;
    }

    restoreGambleScreen(response) {
        App.Game.setState('GAMBLE');
        this.prizeWin = response.win;
        App.Game.Buttons.disableAllButtons();
        App.Game.Legends.setText('win', {text: 'win', value: response.win});
        App.Game.Legends.showWinFeatures();

        App.Game.checkPharaohsPos(App.Game.latestResponse.screen);
        App.Game.positions.forEach(pos => {
            const {x} = pos;
            const pharaohsSymbols = [10, 12, 13];
            App.Game.reelMatrix[x].forEach((symbolObj, rowIndex) => {
                symbolObj.symbol = App.Game.latestResponse.screen[x][rowIndex] = pharaohsSymbols[rowIndex];
                App.Game.Roll.updateSymbolSprite(symbolObj);
            });
        });

        this.createComponent();
    }
}
