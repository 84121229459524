import {JL} from 'jsnlog';
import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import Lines from './../../lines';

/* PIXI aliases */
const AnimatedSprite = PIXI.AnimatedSprite,
    Sprite = PIXI.Sprite;

export default class Lines50 extends Lines {
    constructor(imageResources) {
        super();
        this.imageResources = imageResources;
        // height of winLine boxes, magic number for each game
        this.lineImageWidth = 142;      // width of line image in sprite, magic number for each game
        this.lineImageHeight = 140;     // height of line image in sprite, magic number for each game

        this.lines = {
            0: {coordinates: [0, 0, 0, 0, 0], boxes: [{x: 1, y: 94}]},
            1: {coordinates: [3, 3, 3, 3, 3], boxes: [{x: 1, y: 213}]},
            2: {coordinates: [1, 1, 1, 1, 1], boxes: [{x: 1, y: 335}]},
            3: {coordinates: [2, 2, 2, 2, 2], boxes: [{x: 1, y: 459}]},
            4: {coordinates: [0, 1, 2, 1, 0], boxes: [{x: 772, y: 138}]},
            5: {coordinates: [3, 2, 1, 2, 3], boxes: [{x: 772, y: 246}]},
            6: {coordinates: [2, 1, 0, 1, 2], boxes: [{x: 772, y: 308}]},
            7: {coordinates: [1, 2, 3, 2, 1], boxes: [{x: 772, y: 418}]},
            8: {coordinates: [0, 1, 0, 1, 0], boxes: [{x: 1, y: 108}]},
            9: {coordinates: [3, 2, 3, 2, 3], boxes: [{x: 1, y: 168}]},
            10: {coordinates: [1, 0, 1, 0, 1], boxes: [{x: 1, y: 381}]},
            11: {coordinates: [2, 3, 2, 3, 2], boxes: [{x: 1, y: 443}]},
            12: {coordinates: [1, 2, 1, 2, 1], boxes: [{x: 772, y: 183}]},
            13: {coordinates: [2, 1, 2, 1, 2], boxes: [{x: 1, y: 227}]},
            14: {coordinates: [0, 1, 1, 1, 0], boxes: [{x: 1, y: 320}]},
            15: {coordinates: [3, 2, 2, 2, 3], boxes: [{x: 772, y: 368}]},
            16: {coordinates: [1, 0, 0, 0, 1], boxes: [{x: 772, y: 93}]},
            17: {coordinates: [2, 3, 3, 3, 2], boxes: [{x: 772, y: 338}]},
            18: {coordinates: [1, 2, 2, 2, 1], boxes: [{x: 772, y: 214}]},
            19: {coordinates: [2, 1, 1, 1, 2], boxes: [{x: 772, y: 461}]},
            20: {coordinates: [0, 0, 1, 0, 0], boxes: [{x: 1, y: 137}]},
            21: {coordinates: [3, 3, 2, 3, 3], boxes: [{x: 1, y: 257}]},
            22: {coordinates: [1, 1, 0, 1, 1], boxes: [{x: 1, y: 288}]},
            23: {coordinates: [2, 2, 3, 2, 2], boxes: [{x: 1, y: 413}]},
            24: {coordinates: [1, 1, 2, 1, 1], boxes: [{x: 772, y: 322}]},
            25: {coordinates: [2, 2, 1, 2, 2], boxes: [{x: 772, y: 446}]},
            26: {coordinates: [0, 0, 2, 0, 0], boxes: [{x: 772, y: 109}]},
            27: {coordinates: [3, 3, 1, 3, 3], boxes: [{x: 772, y: 231}]},
            28: {coordinates: [2, 2, 0, 2, 2], boxes: [{x: 1, y: 123}]},
            29: {coordinates: [1, 1, 3, 1, 1], boxes: [{x: 1, y: 184}]},
            30: {coordinates: [0, 2, 2, 2, 0], boxes: [{x: 1, y: 365}]},
            31: {coordinates: [3, 1, 1, 1, 3], boxes: [{x: 1, y: 397}]},
            32: {coordinates: [2, 0, 0, 0, 2], boxes: [{x: 772, y: 155}]},
            33: {coordinates: [1, 3, 3, 3, 1], boxes: [{x: 1, y: 242}]},
            34: {coordinates: [1, 0, 2, 0, 1], boxes: [{x: 1, y: 304}]},
            35: {coordinates: [2, 3, 1, 3, 2], boxes: [{x: 772, y: 432}]},
            36: {coordinates: [1, 2, 0, 2, 1], boxes: [{x: 772, y: 169}]},
            37: {coordinates: [2, 1, 3, 1, 2], boxes: [{x: 772, y: 261}]},
            38: {coordinates: [0, 2, 0, 2, 0], boxes: [{x: 772, y: 293}]},
            39: {coordinates: [3, 1, 3, 1, 3], boxes: [{x: 772, y: 385}]},
            40: {coordinates: [2, 0, 2, 0, 2], boxes: [{x: 1, y: 153}]},
            41: {coordinates: [1, 3, 1, 3, 1], boxes: [{x: 772, y: 277}]},
            42: {coordinates: [0, 2, 1, 2, 0], boxes: [{x: 1, y: 428}]},
            43: {coordinates: [3, 1, 2, 1, 3], boxes: [{x: 772, y: 124}]},
            44: {coordinates: [2, 0, 1, 0, 2], boxes: [{x: 1, y: 273}]},
            45: {coordinates: [1, 3, 2, 3, 1], boxes: [{x: 1, y: 350}]},
            46: {coordinates: [0, 0, 1, 2, 3], boxes: [{x: 772, y: 199}]},
            47: {coordinates: [3, 3, 2, 1, 0], boxes: [{x: 772, y: 400}]},
            48: {coordinates: [0, 1, 2, 3, 3], boxes: [{x: 1, y: 199}]},
            49: {coordinates: [3, 2, 1, 0, 0], boxes: [{x: 772, y: 353}]}
        };

        JL().debug('-- Lines50 initialized');
    }

    drawLineImages(lines, winReels, parentContainer, winLine, payment, allSymbols) {
        if (!allSymbols) {
            parentContainer.removeChildren();
            App.Game.getStageChild('boxesContainer').removeChildren();
        }
        lines.forEach(lineIndex => {
            const line = this.lines[lineIndex];
            line.coordinates.forEach((rowIndex, reelIndex) => {
                winReels.includes(reelIndex) &&
                this.drawWinBox(parentContainer, reelIndex, rowIndex);
            });
        });
    }

    drawBoxes(parentContainer) {

    }

    drawWinBox = (parentContainer, reelIndex, rowIndex) => {
        const container = App.Game.getStageChild('boxesContainer');
        const symbolObj = App.Game.reelMatrix[reelIndex][rowIndex];
        const bigSymbols = [10, 11];
        const increasedSymbols = [4, 5, 6, 7, 8];
        parentContainer.sortableChildren = true;
        if (!bigSymbols.includes(symbolObj.symbol) && !container.getChildByName(`${reelIndex}:${rowIndex}`)) {
            const winLinesBoxSprite = new AnimatedSprite(App.Game.getSpriteTextures({
                image: 'winLinesBox', colCount: 1,
                toFrame: 10, width: 244, height: 184
            }));
            winLinesBoxSprite.name = `${reelIndex}:${rowIndex}`;
            winLinesBoxSprite.blendMode = 3;
            winLinesBoxSprite.scale.x = 0.91;
            winLinesBoxSprite.scale.y = 0.96;
            winLinesBoxSprite.position.x = App.Game.reelXCoordinates[reelIndex] - 23;
            winLinesBoxSprite.position.y = App.Game.symbolHeight * (rowIndex) + App.Game.reelTop - 25;
            winLinesBoxSprite.animationSpeed = 0.2;
            winLinesBoxSprite.zIndex = 1;
            winLinesBoxSprite.play();

            if (symbolObj.symbol === 9) {
                const border = new Sprite(App.Game.getTexture('border'));
                border.position.set(symbolObj.symbolContainer.position.x + 20, symbolObj.symbolContainer.position.y + 50);
                border.name = `border_${reelIndex}:${rowIndex}`;
                !parentContainer.getChildByName(`border_${reelIndex}:${rowIndex}`) && parentContainer.addChild(border);
                !parentContainer.getChildByName(`${reelIndex}:${rowIndex}`) && parentContainer.addChild(winLinesBoxSprite);
            } else {
                container.addChild(winLinesBoxSprite);
            }

            if (increasedSymbols.includes(symbolObj.symbol)) {
                const bang = new AnimatedSprite(App.Game.getSpriteTextures({
                    toFrame: 18,
                    image: 'bang',
                    colCount: 3,
                    width: 256,
                    height: 240
                }));
                bang.name = `${reelIndex}:${rowIndex}`;
                bang.position.x = App.Game.reelXCoordinates[reelIndex] - 20;
                bang.position.y = App.Game.symbolHeight * (rowIndex) + App.Game.reelTop - 25;
                bang.loop = false;
                bang.onComplete = () => bang.destroy();
                bang.blendMode = 3;
                bang.scale.set(0.8);
                bang.play();
                bang.animationSpeed = 0.4;
                parentContainer.addChild(bang);
            }
        }
    };
}
