import App from './../../../index';
import GameChampion from './../../champion/game';
import Lines20 from './../../champion/lines20';
import GambleChampion from './../../champion/gamble';
import InfoScreen from '../../infoScreen';

export default class SevensOnFirePlus extends GameChampion {
    constructor() {
        super();
        this.id = 'sevens-on-fire-plus';
        this.name = 'Sevens on Fire Plus';
        this.symbolEffects = true;
        this.transparentBackground = true;
        this.reelTop = 89; // magic number - where reel images starts
        this.symbolHeight = 130; // height of a single symbol
        this.symbolWidth = 130; // width of a single symbol
        this.buttonsPanelShadow = 'low no-blur';

        this.symbols = [
            {regularDelay: 25, skipSteps: 1, payment: [0, 0, 5, 20, 50, 200]},     // 0 - вишня
            {regularDelay: 25, skipSteps: 1, payment: [0, 0, 0, 20, 50, 200]},     // 1 - лимон
            {regularDelay: 25, skipSteps: 1, payment: [0, 0, 0, 20, 50, 200]},     // 2 - апельсин
            {regularDelay: 25, skipSteps: 1, payment: [0, 0, 0, 20, 50, 200]},     // 3 - слива
            {regularDelay: 25, skipSteps: 1, payment: [0, 0, 0, 50, 200, 500]},    // 4 - арбуз
            {regularDelay: 25, skipSteps: 1, payment: [0, 0, 0, 50, 200, 500]},    // 5 - виноград
            {regularDelay: 25, skipSteps: 1, payment: [0, 0, 0, 100, 1000, 5000]}, // 6 - символ 7
            {regularDelay: 25, skipSteps: 1, payment: [0, 0, 0, 2, 10, 50]}        // 7 -  звезда
        ];
        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.gameSounds = {soundClass: 'champion'};
        this.Lines = new Lines20();
        this.Gamble = new GambleUnique({gambleArea: `/game/games/${this.id}/img/gamble/gamble-area.png`});
        this.InfoScreen = new InfoScreen({pages: 1}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '14pt Franklin Gothic Medium';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#f7fa14';

        switch (page) {
            case 1:
                // 7
                ctx.fillText(bet * this.symbols[6].payment[5], 200, 120);
                ctx.fillText(bet * this.symbols[6].payment[4], 200, 145);
                ctx.fillText(bet * this.symbols[6].payment[3], 200, 170);

                // watermelon
                ctx.fillText(bet * this.symbols[4].payment[5], 460, 120);
                ctx.fillText(bet * this.symbols[4].payment[4], 460, 145);
                ctx.fillText(bet * this.symbols[4].payment[3], 460, 170);

                // grape
                ctx.fillText(bet * this.symbols[5].payment[5], 720, 120);
                ctx.fillText(bet * this.symbols[5].payment[4], 720, 145);
                ctx.fillText(bet * this.symbols[5].payment[3], 720, 170);

                // peach
                ctx.fillText(bet * this.symbols[3].payment[5], 200, 270);
                ctx.fillText(bet * this.symbols[3].payment[4], 200, 295);
                ctx.fillText(bet * this.symbols[3].payment[3], 200, 320);

                // apricot
                ctx.fillText(bet * this.symbols[2].payment[5], 460, 270);
                ctx.fillText(bet * this.symbols[2].payment[4], 460, 295);
                ctx.fillText(bet * this.symbols[2].payment[3], 460, 320);

                // lemon
                ctx.fillText(bet * this.symbols[1].payment[5], 720, 270);
                ctx.fillText(bet * this.symbols[1].payment[4], 720, 295);
                ctx.fillText(bet * this.symbols[1].payment[3], 720, 320);

                // chery
                ctx.fillText(bet * this.symbols[0].payment[5], 330, 418);
                ctx.fillText(bet * this.symbols[0].payment[4], 330, 443);
                ctx.fillText(bet * this.symbols[0].payment[3], 330, 468);
                ctx.fillText(bet * this.symbols[0].payment[2], 330, 492);

                // star
                ctx.fillText(bet * nLines * this.symbols[7].payment[5], 590, 418);
                ctx.fillText(bet * nLines * this.symbols[7].payment[4], 590, 443);
                ctx.fillText(bet * nLines * this.symbols[7].payment[3], 590, 468);
        }
    }
}

class GambleUnique extends GambleChampion {
    constructor(imageResources) {
        super(imageResources);
        this.screenCoordinates = {x: 0, y: 85};
    }
}
