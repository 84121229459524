export default {
    en: {
        scattersTrigger: 'on reel 1, 3, 5 trigger heroes choosing.',
        heroesChoice: 'Make a choice from two heroes, hunter or witch.',
        huntersChoosing: 'Hunter choosing',
        makeWeapon: 'Make 3 weapon choices and get win multiplied by x7, x8, x9, x10, x11.',
        witchChoosing: 'Witch choosing',
        makeElixir: 'Make 3 elixir choices and get win multiplied by x12, x14, x16, x18. Choosing an elixir can be a waste.',
        youWon: 'You won'
    },
    uk: {
        scattersTrigger: 'на барабані 1, 3, 5 викликають вибір героїв.',
        heroesChoice: 'Зробіть вибір з двох героїв, мисливця чи відьми.',
        huntersChoosing: 'Вибір мисливця',
        makeWeapon: 'Виберіть 3 варіанти зброї та отримайте виграш, помножений на x7, x8, x9, x10, x11.',
        witchChoosing: 'Вибір відьом',
        makeElixir: 'Виберіть 3 еліксири і отримайте виграш, помножений на x12, x14, x16, x18. Вибір еліксиру може бути програшним.',
        youWon: 'Ви виграли'
    },
    ru: {
        scattersTrigger: 'на барабане 1, 3, 5 вызывают выбор героев.',
        heroesChoice: 'Сделайте выбор из двух героев, охотника или ведьмы.',
        huntersChoosing: 'Выбор охотника',
        makeWeapon: 'Сделайте 3 выбора оружия и получите выигрыш, умноженный на x7, x8, x9, x10, x11.',
        witchChoosing: 'Выбор ведьмы',
        makeElixir: 'Сделайте 3 выбора эликсира и получите выигрыш, умноженный на x12, x14, x16, x18. Выбор эликсира может быть проигрышным.',
        youWon: 'Вы выиграли'
    },
    de: {
        scattersTrigger: 'auf der Trommel 1, 3, 5 Trigger Helden wählen.',
        heroesChoice: 'Treffen Sie eine Auswahl aus zwei Helden, Jäger oder Hexe.',
        huntersChoosing: 'Jäger wählen',
        makeWeapon: 'Treffen Sie 3 Waffenwahlen und erhalten Sie den Gewinn multipliziert mit x7, x8, x9, x10, x11.',
        witchChoosing: 'Hexenwahl',
        makeElixir: 'Treffen Sie 3 Elixierwahlen und erhalten Sie den Gewinn multipliziert mit x12, x14, x16, x18. Die Wahl eines Elixiers kann eine Verschwendung sein.',
        youWon: 'Du hast gewonnen'
    },
    es: {
        scattersTrigger: 'en el tambor 1, 3, 5 disparar héroes elegir.',
        heroesChoice: 'Elige entre dos héroes, cazador o bruja.',
        huntersChoosing: 'Cazador eligiendo',
        makeWeapon: 'Elige 3 armas y obtén el premio multiplicado por x7, x8, x9, x10, x11.',
        witchChoosing: 'Bruja elegir',
        makeElixir: 'Haz 3 elecciones de elixir y obtén el premio multiplicado por x12, x14, x16, x18. Elegir un elixir puede ser un desperdicio.',
        youWon: 'Ganaste'
    },
    fr: {
        scattersTrigger: 'sur le tambour 1, 3, 5 déclencher le choix des héros.',
        heroesChoice: 'Faites un choix entre deux héros, chasseur ou sorcière.',
        huntersChoosing: 'Choix du chasseur',
        makeWeapon: 'Faites 3 choix d\'armes et gagnez multiplié par x7, x8, x9, x10, x11.',
        witchChoosing: 'Choix de la sorcière',
        makeElixir: 'Faites 3 choix d\'élixir et gagnez multiplié par x12, x14, x16, x18. Choisir un élixir peut être un gaspillage.',
        youWon: 'Tu as gagné'
    },
    el: {
        scattersTrigger: 'στους τροχούς 1, 3, 5 ενεργοποιούν την επιλογή των ηρώων.',
        heroesChoice: 'Κάντε μια επιλογή από δύο ήρωες, κυνηγούς ή μάγισσες.',
        huntersChoosing: 'Κυνηγός επιλέγοντας',
        makeWeapon: 'Κάντε 3 επιλογές όπλων και κερδίστε πολλαπλασιασμένη επί x7, x8, x9, x10, x11.',
        witchChoosing: 'Επιλογή μάγισσας',
        makeElixir: 'Κάντε 3 επιλογές ελιξιρίου και κερδίστε πολλαπλασιασμένη επί x12, x14, x16, x18. Η επιλογή ενός ελιξιρίου μπορεί να είναι χάσιμο.',
        youWon: 'Κέρδισες'
    },
    it: {
        scattersTrigger: 'sulla bobina 1, 3, 5 innescano eroi che scelgono.',
        heroesChoice: 'Scegli tra due eroi, un cacciatore o una strega.',
        huntersChoosing: 'La scelta di Hunter',
        makeWeapon: 'Fai 3 scelte di armi e ottieni la moltiplicazione della vincita per x7, x8, x9, x10, x11.',
        witchChoosing: 'Strega che sceglie',
        makeElixir: 'Fai 3 scelte di elisir e ottieni la vincita moltiplicata per x12, x14, x16, x18. La scelta di un elisir può essere uno spreco.',
        youWon: 'Hai vinto'
    }
};
