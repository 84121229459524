import React from 'react';

import './styles.less';

export default function ProgressBar(props) {
    const {progress} = props;

    return (
        <div className='progress'>
            <div className='progress-fill' style={{width: `${progress}%`}}/>
            <span className='progress-value'>{progress}%</span>
        </div>
    );
}
