import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import GameDeluxe5x4 from './../../deluxe/gameDeluxe5x4';
import Lines40 from './../../deluxe/lines40';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';
import bonusFont from './img/font/fontBonus';
import regularFont from './img/font/fontRegular';

/* PIXI aliases */
const Texture = PIXI.Texture,
    AnimatedSprite = PIXI.AnimatedSprite;

export default class ElToro extends GameDeluxe5x4 {
    constructor() {
        super();
        this.id = 'lions';
        this.name = 'Lions';
        this.scatter = 10;
        this.reelTop = 88;
        this.reelFilter = [[10], [], [], [], [10]];
        this.buttonsPanelShadow = 'strong';

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 0, y: 85},
            bonusInBonusFrame: {x: 0, y: 85},
            endBonusFrame: {x: 0, y: 85}
        };

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 0, 4, 16, 80]},                // 0 - Symbol '9'
            {regularDelay: 100, payment: [0, 0, 0, 4, 16, 80]},                // 1 - Symbol '10'
            {regularDelay: 100, payment: [0, 0, 0, 8, 20, 100]},               // 2 - Symbol 'J'
            {regularDelay: 100, payment: [0, 0, 0, 8, 20, 100]},               // 3 - Symbol 'Q'
            {regularDelay: 100, payment: [0, 0, 0, 16, 40, 160]},              // 4 - Symbol 'K'
            {regularDelay: 100, payment: [0, 0, 0, 16, 40, 160]},              // 5 - Symbol 'A'
            {regularDelay: 100, payment: [0, 0, 0, 20, 60, 240]},              // 6 - hunter
            {regularDelay: 100, payment: [0, 0, 0, 20, 60, 240]},              // 7 - roe
            {regularDelay: 100, offset: 18, payment: [0, 0, 0, 20, 80, 400]},  // 8 - lion
            {regularDelay: 100, offset: 18, payment: [0, 0, 0, 20, 80, 400]},  // 9 - lioness
            {
                regularDelay: 100,
                scatterDelay: 130,
                bonusDelay: 300,
                offset: 18,
                zIndex: 10,
                payment: [0, 0, 0, 4, 0, 0]
            }, // 10 - shield with spears
            {regularDelay: 100, offset: 16, payment: [0, 0, 0, 40, 200, 1000]}  // 11 - Symbol 'Wild Lions'
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: `area/${App.System.resolution}/main.png`,
                background: 'area/background.png',
                bonusFont: bonusFont['imageResource'],
                regularFont: regularFont['imageResource']
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                bonusArea: `area/${App.System.resolution}/bonus.png`,
                frame: 'bonus/bonus1.png',
                bonusAnimation1: 'bonus/bonusAnimation1.png',
                bonusAnimation2: 'bonus/bonusAnimation2.png',
                bonusAnimation3: 'bonus/bonusAnimation3.png'
            }),
            atlas: this.mergePath([
                'bonus/bonusSymbols.json',
                'regularLongSymbols.json',
                'bonus/scatterSymbols.json'
            ])
        };

        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'bonus-game-end', alias: 'bonusGameEnd'},
                {name: 'bonus-background', loop: true}
            ],
            path: `/game/games/${this.id}/audio/`
        };

        this.Lines = new Lines40();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            activeRed: 'gamble/red-active.png',
            inactiveRed: 'gamble/red-inactive.png',
            activeBlack: 'gamble/black-active.png',
            inactiveBlack: 'gamble/black-inactive.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '17pt Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#fff';
        ctx.shadowColor = '#000';
        ctx.shadowOffsetX = 3;
        ctx.shadowOffsetY = 3;
        ctx.shadowBlur = 8;
        ctx.strokeStyle = '#000';
        ctx.lineWidth = 2;
        const regularTextProps = {
            parentContainer: ctx,
            fontImageName: 'regularFont',
            map: regularFont,
            align: 'center',
            scale: 0.75,
            fontInterval: -13 // px between symbols
        };
        const bonusTextProps = {
            parentContainer: ctx,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 0.5,
            fontInterval: -40 // px between symbols
        };

        switch (page) {
            case 1:

                // Wild Lions
                this.strokeFillText(ctx, bet * this.symbols[11].payment[5], 255, 125);
                this.strokeFillText(ctx, bet * this.symbols[11].payment[4], 255, 150);
                this.strokeFillText(ctx, bet * this.symbols[11].payment[3], 255, 175);
                // shield with spears
                this.strokeFillText(ctx, bet * nLines * this.symbols[10].payment[3], 375, 374);
                // lions, lioness
                this.strokeFillText(ctx, bet * this.symbols[9].payment[5], 210, 262);
                this.strokeFillText(ctx, bet * this.symbols[9].payment[4], 210, 287);
                this.strokeFillText(ctx, bet * this.symbols[9].payment[3], 210, 312);
                // hunter, roe
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], 610, 264);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], 610, 289);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 610, 314);
                // A K
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 115, 474);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 115, 498);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 115, 522);
                // Q J
                this.strokeFillText(ctx, bet * this.symbols[2].payment[5], 410, 474);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[4], 410, 498);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[3], 410, 522);
                // 9 10
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 680, 474);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 680, 498);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 680, 522);

                ctx.font = '35pt franklinFont';
                ctx.textAlign = 'center';
                ctx.strokeStyle = '#000';
                ctx.strokeWidth = 5;

                this.strokeFillText(ctx, bet * nLines * this.symbols[11].payment[5], 700, 155);
                break;
            case 2:
                this.drawCustomFont('substitutes for', 285, 215, regularTextProps);
                this.drawCustomFont('Scatter symbols on the screen trigger', 470, 425, regularTextProps);
                this.drawCustomFont('Free Games.', 275, 455, regularTextProps);
                this.drawCustomFont('Free Games can be won again during the Free', 460, 480, regularTextProps);
                this.drawCustomFont('Games.', 247, 505, regularTextProps);

                this.drawCustomFont('FREE  GAMES', 365, 320, bonusTextProps);
                this.drawCustomFont('3', 215, 400, bonusTextProps);
                this.drawCustomFont('10', 720, 400, bonusTextProps);
                break;
            case 3:
                this.drawCustomFont('RULES', 375, 225, regularTextProps);

                this.drawCustomFont('All prizes are for combinations of a kind. All prizes are for', 395, 245, regularTextProps);
                this.drawCustomFont('combinations left to right, except scatters. All prizes are on', 400, 265, regularTextProps);
                this.drawCustomFont('selected lines, except scatters. Scatter symbols pay at any', 400, 285, regularTextProps);
                this.drawCustomFont('position on screen. Highest win only paid per selected line.', 400, 305, regularTextProps);
                this.drawCustomFont('Scatter wins are added to line wins. Free Games are played at', 400, 325, regularTextProps);
                this.drawCustomFont('trigger bet and lines. All prizes shown in credits. Malfunction', 400, 345, regularTextProps);
                this.drawCustomFont('voids all pays and plays.', 400, 365, regularTextProps);
                break;
        }

        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    drawBonusFrame(first, last, parentContainer, coordinates) {
        const {startBonusFrame, bonusInBonusFrame, endBonusFrame} = coordinates;
        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 0.5,
            strokeStyle: '#000',
            strokeWidth: 12,
            fontInterval: -7 // px between symbols
        };

        App.Sounds.stopSound('bonus-background');
        if (first) {
            App.Sounds.playSound('animation');
            this.setBackground('bonusArea');
            this.drawBonusAnimation(parentContainer, startBonusFrame, textProps);
        }

        if (last) {
            this.setBackground('mainArea');
            App.updateButton('start', {disabled: true});
            this.showEndBonusFrame(parentContainer, endBonusFrame, textProps);
            this.playEndBonusGameSound();
            setTimeout(() => this.endBonus(), 5000);
        }

        if (!first && !last) {
            this.showBonusInBonusFrame(parentContainer, bonusInBonusFrame, textProps);
        }
    }

    /**
     * drawBonusAnimation
     */
    drawBonusAnimation(parentContainer, {x, y}, textProps) {
        const textures = [...Array(25)].map((item, index) => {
            const width = 800, height = 398;
            const imageNumber = index < 10 ? 1 : index < 20 ? 2 : 3;
            const colIndex = Math.trunc((index % 10) / 5);
            const rowIndex = index % 5;

            return new Texture(this.getTexture(`bonusAnimation${imageNumber}`), {
                x: colIndex * width,
                y: rowIndex * height,
                width: width,
                height: height
            });
        });

        const sprite = new AnimatedSprite(textures);
        sprite.name = 'bookAnim';
        sprite.animationSpeed = 0.2;
        sprite.loop = false;
        sprite.position.set(x, y);
        parentContainer.addChild(sprite);
        sprite.play();
        sprite.onComplete = () => this.showStartBonusFrame(parentContainer, {x, y}, textProps);
    }

    /**
     * Drawing  the table of bonus game
     * @param parentContainer
     * @param x
     * @param y
     * @param textProps
     */
    showStartBonusFrame(parentContainer, {x, y}, textProps) {
        parentContainer.removeChildren();
        this.gameFlag.bonusStart = true;
        this.showBonusFrame(parentContainer, x, y);
        this.drawCustomFont('10 FREE GAMES', 400, 240, textProps);
        this.drawCustomFont('WON', 400, 300, textProps);
        this.enableStartButton(parentContainer);
    }

    /**
     *  Drawing  the table of bonus in bonus game
     */
    showBonusInBonusFrame(parentContainer, {x, y}, textProps) {
        this.enableStartButton(parentContainer);
        this.showBonusFrame(parentContainer, x, y);
        this.drawCustomFont('10  MORE FREE', 400, 240, textProps);
        this.drawCustomFont(' GAMES WON', 400, 300, textProps);
    }

    /**
     * Drawing  the table of end of bonus in bonus game
     */
    showEndBonusFrame(parentContainer, {x, y}, textProps) {
        this.showBonusFrame(parentContainer, x, y);
        this.drawCustomFont('FEATURE WIN', 400, 240, textProps);
        this.drawCustomFont(`${this.bonusStatus.win} CREDITS`, 400, 300, textProps);
    }

    enableStartButton(parentContainer) {
        App.updateButton('start', {
            disabled: false,
            title: 'start',
            handler: () => this.startBonusAnimation(parentContainer)
        });
    }

    getSymbolImageType = () => this.gameFlag.bonusStart ? 'bonus' : 'static';
}
