import {JL} from 'jsnlog';

import App from './../../index';
import GameDeluxe from './../deluxe/game';

export default class PragmaticGames extends GameDeluxe {
    constructor() {
        super();
        this.gameFieldWidth = 960;
        this.gameWidth = App.System.resolution === '4x3' ? this.gameFieldWidth : 1280;
        this.gameFieldHeight = 720;
        this.gameHeight = App.configs.doubleScreen ? 1440 : 720;
        this.buttonsPanelShadow = 'mid';
        this.transparentBackground = true; // use transparent symbols background or not
        this.symbolEffects = false; // shadowing and none
        this.defaultFeatureDelay = 1500;
        this.doublingFilter = [0, 1, 2, 3, 4, 5, 6, 7, 8, 10, 11]; // 9, 10  cant be doubled
        this.reelSettings = [18, 6, 40];
        this.reelFilter = [[11, 10, 9], [9], [9], [9], [11, 10, 9]];
        this.waitingType = '';

        this.rollProperties = {
            reelSpeed: 1.8,
            springDown: 0.3,
            springUp: 0.2
        };
        this.scatter = 11;

        // use to increase coins when bonus is possible
        this.allowAnimation = {
            1: false,
            2: false,
            3: false
        };

        this.containersLayers = {
            reelsStage: 3,
            mainContainer: 0,
            bonusContainer: 4,
            symbolInfo: 7,
            extraBetContainer: 5,
            infoContainer: 7,
            linesContainer: 1,
            boxesContainer: 2,
            waitingContainer: 6
        };
    }

    /**
     * Function to start logic of Animate feature first step
     * @param features
     */
    startAnimateFeature(features) {
        JL().debug('-- Start animate feature');
        this.Legends.showWinFeatures();
        App.Sounds.pauseSound('bonus-background');
        this.prepareToAnimateFeature(features);
        App.Sounds.playSound('win-line');
    }

    /**
     * End feature animation / take win without prompt
     * @returns {boolean} - new animation features circle
     */
    endAnimateFeature() {
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        App.Sounds.stopSound('win-line');
        this.getState() === 'SHOW_WIN_LINES' && this.latestResponse.payment ?
            this.takeWin() :
            this.roundFinished(); // no win, just feature without payment
        return true;
    }

    /**
     * Called to show round results once animation is finished
     */
    rotationDone() {
        App.System.statistics.currentSpinNumber < 3 && App.System.collectFps();
        // check regularShort animation
        const scatters = [];
        if (!this.gameFlag.bonusStarted) {
            for (let i = 0; i < this.reels; i++) {
                for (let j = 0; j < this.reelRows; j++) {
                    const symbolObj = this.Roll.clipMatrix[i][j + 1];
                    symbolObj.symbol === this.scatter && scatters.push(symbolObj);
                }
            }
        }

        const animationEnd = () => {
            this.createReelMatrix(this.getStageChild('reelsStage'));
            const extraSymbolsContainer = this.getStageChild('reelsStage').getChildByName('extraSymbols');
            extraSymbolsContainer && (extraSymbolsContainer.visible = false);
            this.onRotationDone();
            this.allowAnimation = {
                1: false,
                2: false,
                3: false
            };
            App.System.statistics.currentSpinNumber < 3 && App.System.collectFps();
        };
        const lastScatter = scatters[scatters.length - 1];
        // wait for last scatter played
        scatters.length && scatters.some(scatter => scatter.sprite.playing) && lastScatter.sprite.playing ?
            lastScatter.sprite.onComplete = animationEnd :
            animationEnd();
    }

    /**
     * Animate feature first step
     */
    prepareToAnimateFeature(features) {
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        this.features.step = 0;

        // check 'WIN_LINE' feature contain
        const isWinLine = features.some(features => features.uc === 'WIN_LINE');
        this.updateExtraSymbols(isWinLine);

        // unique preparing for each game
        this.additionalPreparingToAnimateFeature(features);
    }

    additionalPreparingToAnimateFeature(features) {
        let herculesSound = false;
        features.forEach((currentFeature, index) => {
            this.showFeatureLine(currentFeature, features, true);
            if (currentFeature.symbol === 9) {
                herculesSound = true;
            }

            features.length - 1 === index &&
            this.tickerTimeout(() => {
                this.animateFeature(features);
                // change handler to stop animation win line
                !this.isBonus() && App.updateButton('start', {
                    disabled: false, title: 'stop',
                    handler: this.speedUpWinLineAnimation
                });
            }, 1000);
        });
        if (herculesSound) {
            App.Sounds.playSound('hercules');
        }
    }

    showFeatureLine(currentFeature, features, allSymbols = false) {
        const {number, reels, payment, uc} = currentFeature; // get current feature params
        const container = this.getStageChild('linesContainer');

        if (['WIN_LINE', 'SCATTER'].includes(uc)) {
            uc === 'WIN_LINE' && this.Lines.drawLineImages([number], reels, container, true, payment, allSymbols);
            this.animateSymbolsInLine(currentFeature, allSymbols);
        }
    }
}
