import React from 'react';
import {JL} from 'jsnlog';

import './styles.less';
import App from './../../index';
import Slider from './../slider';
import Logo from './../logo';
import ControlButtons from './../controlButtons';
import BottomPanel from './../bottomPanel';

export default class Menu extends React.Component {
    slider = React.createRef();

    componentDidMount() {
        JL().debug('-- Menu: componentDidMount');
        const {mode, subMode} = App.configs;

        App.updateState('loader', {
            fade: 'out',
            fadeEndCallback: () => {
                App.Menu.updateVisibleGames();
                App.resetLoader();
            },
            activeControlButtons: false
        });
        App.LocalSocket.updateBillAcceptor('ENABLE');

        App.updateButton('user', App.View.getInitState().buttons.user);
        App.updateButton('message', App.View.getInitState().buttons.message);
        App.updateButton('close', {
            visible: !['kiosk2', 'game', 'vlt-primary', 'login-primary'].includes(subMode),
            disabled: ['vlt', 'player'].includes(mode),
            handler: App.closeClick
        });
        App.Menu.restoreScrollPos(this.slider.current);
        App.updateButton('soundVolume', {visible: true});
    }

    getBackLink = (subMode, isRedirect) => {
        const img = subMode !== 'cosmolot' ?
            'back-maxbet' : 'back-maxbet-cosmolot';

        return (
            (isRedirect || subMode === 'cosmolot') &&
            <button
                onClick={this.redirect}
                className={`back-link ${subMode}`}
                draggable={false}
                style={{background: `url(${App.getUrl(`/img/Menu/${img}.png`)}) 0 0/100% no-repeat`}}
            />
        );
    };

    redirect = () => {
        App.Socket.webSocket.close(); // stop connection
        App.stopRestoring = true;
        App.showSocketMessage('redirect', false);

        // wait for socket closing
        setTimeout(() => location.href = App.System.getOldSystemLink(), 2000);
    };

    render() {
        const {state: {lang, buttons, slider, moneyParams, profile}} = this.props;
        const {previousWebPageURL, oldSystemLinks} = App.System;
        const {subMode} = App.configs;
        const isRedirect = previousWebPageURL && oldSystemLinks.includes(previousWebPageURL.origin);

        return (
            <div className='Menu' onTouchStart={App.hideMobileBurger}>
                <ControlButtons buttons={buttons}/>
                {this.getBackLink(subMode, isRedirect)}

                <img className='background' draggable={false} src={App.getUrl('/img/Menu/menu-bg.png')} alt=''/>
                <Slider slider={slider} refSlider={this.slider}/>
                <BottomPanel lang={lang} moneyParams={moneyParams}/>

                <div className='logo-container'><Logo mode={subMode} name={App.name}/></div>
                <div id='version' className='version'>
                    <span style={{color: '#C70101'}}>v.</span>{App.version}
                </div>
            </div>
        );
    }
}
