import {JL} from 'jsnlog';
import React from 'react';

import App from './../index';

export default class Gamble {
    constructor() {
        this.ctx = null;
        this.stepLeft = 0;
        this.limit = 0; // money in cents
        this.gambleAreaSize = {w: 800, h: 398};
        this.suit2images = {
            spades: {small: 'spades', card: 'aceOfSpades'},
            clubs: {small: 'clubs', card: 'aceOfClubs'},
            hearts: {small: 'hearts', card: 'aceOfHearts'},
            diamonds: {small: 'diamonds', card: 'aceOfDiamonds'},
            smallCard: 'smallCard'
        };
        this.prizeWin = 0;
        this.gambleButtonsSize = {
            width: '17em',
            height: '12.35em',
            borderRadius: '50%',
            bottom: '12.5em',
            left: '4em',
            right: '4em'
        };
        this.cardPos = {x: 335, y: 175};
        this.cardsQueuePos = {x: [378, 440, 502, 564, 625, 688], y: 94};
        this.prizeWinPos = [{x: 208, y: 72}, {x: 595, y: 72}]; // text positions for prizeWin texts, current and doubled
        this.screenCoordinates = {x: 0, y: 80}; // coordinates for gambleArea
        this.offsetGambleQueue = 0;
        this.cardInvertTimeout = 100; // ms, for card inverting
        this.currentGambleMultiplier = 1;
        this.gambleQueue = [];
        this.cardInvertRAF = null;
        this.suitWon = null;
    }

    /**
     * Prepare gamble resources and build <Gamble> component
     */
    createComponent = () => {
        App.View.setState({activeGamble: true});
    };

    getCanvas = () => {
        const {gameFieldWidth, gameFieldHeight} = App.Game;
        const {w, h} = this.gambleAreaSize;
        const {x, y} = this.screenCoordinates;

        return <canvas
            id='gamble-canvas' width={w} height={h}
            style={{
                width: w / gameFieldWidth * 100 + '%', height: h / gameFieldHeight * 100 + '%',
                top: y / gameFieldHeight * 100 + '%', left: x / gameFieldWidth * 100 + '%'
            }}
            // save context for gamble drawing
            ref={canvas => {
                if (canvas) {
                    this.ctx = canvas.getContext('2d', {alpha: true});
                    this.ctx.lineJoin = 'round';
                }
            }}
        />;
    };

    /**
     * Callback after mounted <Gamble>
     * Update buttons, start gamble area animation
     */
    componentDidMount() {
        JL().debug('-- Gamble: componentDidMount');

        App.updateButton('select', {disabled: true});
        App.updateButton('start', {disabled: true});
        App.updateButton('autoStart', {disabled: true});

        // disable novomatic gamble buttons before animation
        App.updateButton('redCard', {img: 'inactive', disabled: true, handler: null});
        App.updateButton('blackCard', {img: 'inactive', disabled: true, handler: null});

        // disable megajack gamble buttons before animation
        App.updateButton('cardIndex', {handler: null});

        this.draw(false);

        App.Game.tickerTimeout(() => {
            this.setDefaultGambleButtons();
            this.draw(true);
        }, 1000);
    }

    /**
     * Set gamble limits
     * @param limit - Socket response 'GAMBLE-LIMITS'
     */
    setLimit = limit => this.limit = limit;

    getMultiplier = () => this.currentGambleMultiplier;

    setMultiplier = multiplier => this.currentGambleMultiplier = multiplier;

    drawGambleQueue = offsetY => {
        const ctx = this.ctx;

        this.gambleQueue.forEach((card, index) => {
            const sIndex = this.getCardSuite(card);
            ctx.drawImage(
                App.Game.getAdditionalImage(this.suit2images[sIndex].small),
                this.screenCoordinates.x + this.cardsQueuePos.x[index + 1],
                offsetY + this.cardsQueuePos.y
            );
        });
    };

    invertGambleCard = (animationStarted = Date.now(), odd) => {
        const timeDiff = Date.now() - animationStarted;

        if (timeDiff > this.cardInvertTimeout) {
            animationStarted = Date.now();
            odd = odd ? 0 : 1;
            App.Sounds.playSound('invert-card');
            const ctx = this.ctx;
            const image = odd ? 'redCard' : 'blackCard';
            ctx.drawImage(
                App.Game.getAdditionalImage(image),
                this.screenCoordinates.x + this.cardPos.x,
                this.offsetGambleQueue + this.cardPos.y
            );
        }
        this.cardInvertRAF = requestAnimationFrame(this.invertGambleCard.bind(this, animationStarted, odd));
    };

    restoreGambleScreen(response) {
        App.Game.setState('GAMBLE');
        this.prizeWin = response.win;
        App.Game.Buttons.disableAllButtons();
        App.Game.Legends.setText('win', {text: 'win', value: response.win});
        App.Game.Legends.showWinFeatures();
        this.createComponent();
    }

    goToGamble = () => {
        JL().debug('-- Go to Gamble');
        App.Game.setState('GAMBLE');
        App.Game.stopAnimateFeature();
        App.Game.sendGamblePacket();
        App.updateButton('select', {disabled: true});
        App.updateButton('start', {disabled: true});
        App.updateButton('autoStart', {disabled: true});
    };

    draw = startInverting => {
        App.Sounds.stopSound('gamble-wait');
        App.Game.Legends.setText('win', {text: 'win', value: this.prizeWin});
        App.Game.Legends.setStatus('selectColor');
        App.Game.Legends.showWinFeatures();
        !startInverting && App.Game.startGambleAnimation();
        this.completeAnimateGambleArea(startInverting);
    };

    completeAnimateGambleArea = startInverting => {
        const ctx = this.ctx;
        ctx.clearRect(0, 0, App.Game.gameWidth, App.Game.gameHeight);

        this.imageResources.gambleArea &&
        ctx.drawImage(App.Game.getAdditionalImage('gambleArea'), this.screenCoordinates.x, 0); // draw Gamble Area
        ctx.drawImage(
            App.Game.getAdditionalImage('redCard'),
            this.screenCoordinates.x + this.cardPos.x,
            this.offsetGambleQueue + this.cardPos.y
        );
        this.drawGambleTexts(ctx); // show 'gamble amount' and 'Gamble to win'

        App.Game.Legends.setText('win', {text: 'win', value: this.prizeWin});
        this.resetInverting();
        if (startInverting) {
            this.suitWon = null;
            this.invertGambleCard();
            App.updateButton('redCard', {img: 'inactive', disabled: false, handler: this.selectRed});
            App.updateButton('blackCard', {img: 'inactive', disabled: false, handler: this.selectBlack});
        } else if (startInverting && this.suitWon) {
            ctx.drawImage(
                App.Game.getAdditionalImage(this.suit2images[this.suitWon].card),
                this.screenCoordinates.x + this.cardPos.x,
                this.offsetGambleQueue + this.cardPos.y
            );
        }
        this.drawGambleQueue(this.offsetGambleQueue);
    };

    drawGambleTexts = ctx => {
        ctx.font = 'bold 24px Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#fff';
        ctx.shadowBlur = 3;
        ctx.shadowOffsetX = 2;
        ctx.shadowOffsetY = 2;
        ctx.shadowColor = '#000';

        ctx.fillText(this.prizeWin, this.screenCoordinates.x + this.prizeWinPos[0].x, this.offsetGambleQueue + this.prizeWinPos[0].y);
        ctx.fillText(this.prizeWin * 2, this.screenCoordinates.x + this.prizeWinPos[1].x, this.offsetGambleQueue + this.prizeWinPos[1].y);
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    };

    selectRed = () => {
        JL().debug('Red color selected');
        App.updateButton('redCard', {img: 'active', disabled: true, handler: null});
        App.updateButton('blackCard', {img: 'inactive', disabled: true, handler: null});
        App.updateButton('info', {additionalClass: 'gamble-red-active'});
        this.makeDoubling('Red');
    };

    selectBlack = () => {
        JL().debug('Black color selected');
        App.updateButton('redCard', {img: 'inactive', disabled: true, handler: null});
        App.updateButton('blackCard', {img: 'active', disabled: true, handler: null});
        App.updateButton('select', {additionalClass: 'gamble-black-active'});
        this.makeDoubling('Black');
    };

    makeDoubling = color => {
        App.updateButton('autoStart', {disabled: true});
        App.updateButton('start', {disabled: true});
        App.Game.Buttons.disableAllButtons();
        App.Socket.send(JSON.stringify({
            uc: 'MAKE-DOUBLING',
            color: color
        }));
    };

    /**
     * Draw card from response
     * @param response - Socket response 'MAKE-DOUBLING'
     */
    responseColor = response => {
        const ctx = this.ctx;
        const isStop = response.stop,
            card = response.card,
            win = response.win;

        this.prizeWin = response.win;
        this.suitWon = this.getCardSuite(card);

        ctx.clearRect(0, 0, App.Game.gameWidth, App.Game.gameHeight);
        this.imageResources.gambleArea &&
        ctx.drawImage(App.Game.getAdditionalImage('gambleArea'), this.screenCoordinates.x, 0);
        this.drawGambleTexts(ctx);

        if (App.restoreGameState !== 'GAMBLE') {
            this.drawGambleQueue(this.offsetGambleQueue);
            // draw choose card
            ctx.drawImage(
                App.Game.getAdditionalImage(this.suit2images[this.suitWon].card),
                this.screenCoordinates.x + this.cardPos.x,
                this.offsetGambleQueue + this.cardPos.y
            );

            this.drawChooseSmallCard(ctx, card);
        }

        if (win > 0) {
            App.Game.tickerTimeout(() => {
                if (isStop) {
                    App.Game.finishGamble();
                } else {
                    this.gambleQueue.unshift(card); // add choose card to array begin
                    this.gambleQueue.length > 5 && this.gambleQueue.pop(); // there could be no more the 5 images in queue
                    this.setDefaultGambleButtons();
                    this.draw(true);
                }
            }, 1000);

            // increase gamble amount and gamble to win
            if (App.restoreGameState !== 'GAMBLE') {
                this.currentGambleMultiplier *= 2;
            }

            // win
            this.prizeWin = response.win;
            App.Game.Legends.setText('win', {text: 'win', value: this.prizeWin});
            App.Sounds.playSound('gamble-win');
        } else {
            App.Game.Legends.setStatus('gambleEnd');
            App.Game.Legends.setText('win', {text: 'win', value: 0});
            this.prizeWin = 0;
            App.Game.tickerTimeout(() => App.Game.finishGamble(), 2500);
        }

        this.resetInverting();
    };

    drawChooseSmallCard(ctx, card) {
        const sIndex = this.getCardSuite(card);
        ctx.drawImage(
            App.Game.getAdditionalImage(this.suit2images[sIndex].small),
            this.screenCoordinates.x + this.cardsQueuePos.x[0],
            this.offsetGambleQueue + this.cardsQueuePos.y
        );
    }

    getCardSuite = card =>
        card[card.length - 1] === 'H' ? 'hearts' :
            card[card.length - 1] === 'D' ? 'diamonds' :
                card[card.length - 1] === 'C' ? 'clubs' :
                    'spades';

    setDefaultGambleButtons = () => {
        JL().debug('-- Set default gamble buttons state');
        App.View.setState(prevState => ({
            buttons: {
                ...prevState.buttons,
                // main game buttons
                start: {
                    ...prevState.buttons.start,
                    disabled: false,
                    title: 'collect',
                    handler: App.Game.finishGamble
                },
                select: {
                    ...prevState.buttons.select,
                    disabled: false,
                    title: 'black',
                    handler: this.selectBlack,
                    additionalClass: 'gamble-black'
                },
                info: {
                    ...prevState.buttons.info,
                    disabled: false,
                    title: 'red',
                    handler: this.selectRed,
                    additionalClass: 'gamble-red'
                },
                autoStart: {
                    ...prevState.buttons.autoStart,
                    disabled: true
                }
            }
        }));
    };

    disableSuitsButtons = () => {

    };

    resetInverting() {
        cancelAnimationFrame(this.cardInvertRAF);
    };

    activateGambleButtons() {
        this.setDefaultGambleButtons();
        App.View.setState({activeGamble: true});
        App.updateButton('redCard', {img: 'inactive', disabled: false, handler: this.selectRed});
        App.updateButton('blackCard', {img: 'inactive', disabled: false, handler: this.selectBlack});
    }

    deactivateGambleButtons() {
        App.updateButton('redCard', {img: 'inactive', disabled: true, handler: null});
        App.updateButton('blackCard', {img: 'inactive', disabled: true, handler: null});
    }
}
