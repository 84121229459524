import App from './../../../index';
import GameSlotopol from './../slot-o-pol/slot-o-pol';
import Lines21 from './../../megajack/lines21';
import GambleMegajack from './../../megajack/gamble';
import InfoScreen from '../../infoScreen';

export default class Spin extends GameSlotopol {
    constructor() {
        super();
        this.id = 'spin';
        this.name = 'Spin';
        this.scatter = 10;
        this.buttonsPanelShadow = 'mid';
        this.screenMultiplier = {win: [-1, -1, -1, -1, -1, -1, -1], mult: [-1, -1, -1, -1, -1, -1, -1]};
        this.superBonusStep = 6;
        this.choosingStep = -1;
        this.chipPos = 0;
        this.attempts = 0;

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 2, 5, 25, 100]},        // 0 - черешни
            {regularDelay: 100, payment: [0, 0, 2, 5, 25, 100]},        // 1 - сливы
            {regularDelay: 100, payment: [0, 0, 0, 5, 25, 100]},        // 2 - арбуз
            {regularDelay: 100, payment: [0, 0, 0, 5, 20, 100]},        // 3 - виноград
            {regularDelay: 100, payment: [0, 0, 0, 10, 100, 250]},      // 4 - кокосы
            {regularDelay: 100, payment: [0, 0, 0, 10, 100, 250]},      // 5 - лимон
            {regularDelay: 100, payment: [0, 0, 0, 10, 100, 250]},      // 6 - коктейль
            {regularDelay: 100, payment: [0, 0, 2, 10, 100, 500]},      // 7 - остров с пальмой
            {regularDelay: 100, payment: [0, 0, 2, 10, 100, 500]},      // 8 - круизный лайнер
            {regularDelay: 100, payment: [0, 0, 10, 200, 2000, 10000]}, // 9 - символ 'e'  // JOKER
            {regularDelay: 100, payment: [0, 0, 0, 0, 0, 0]},           // 10 - шампанское // SPIN
            {regularDelay: 100, payment: [0, 0, 0, 0, 0, 0]},           // 11 - символ '$' // DICE
            {regularDelay: 100, payment: [0, 0, 0, 2, 20, 1000]}        // 12 - символ '$' // SCATTER
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                waiting_roll1: 'area/waiting1.png',
                waiting_roll2: 'area/waiting2.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                bonusArea: 'area/bonus_area.png',
                bonusArea2: 'area/bonus_area1.png',
                gambleArea: 'area/gamble.png',

                box: 'bonus/box.png',
                boxUsed: 'bonus/box_used.png',
                button: 'bonus/button.png',
                dices: 'bonus/dices.png',
                minimizeSymbols: 'minimizeSymbols.png',
                label: 'bonus/label.png',
                chosingWin: 'bonus/bonus_sprite3.png',
                pressButton: 'bonus/bonus_sprite1.png'
            }),
            atlas: this.mergePath(['additionalSymbols.json'])
        };
        this.imageResources.main.animation2 = '/game/games/slot-o-pol/img/bonus/bonus_sprite2.png';
        this.imageResources.main.waiting_doubling = '/img/gamble/mega-jack/gamble_waiting.png';
        this.gameSounds = {
            soundClass: 'megajack',
            sounds: [
                {name: 'reels'},
                {name: 'reelsstop'},
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'choosing-select', alias: 'choosingSelect'},
                {name: 'choosing-background', alias: 'choosingBackground', loop: true},
                {name: 'choosing-end', alias: 'choosingEnd'},
                {name: 'dice-bonus', alias: 'diceBonus'},
                {name: 'spin-bonus', alias: 'spinBonus'},
                {name: 'dice-drop', alias: 'diceDrop'},
                {name: 'spin-stop', alias: 'spinStop'},
                {name: 'spin-roll', alias: 'spinRoll'}
            ],
            path: `/game/games/${this.id}/audio/`
        };
        this.Lines = new Lines21();
        this.Gamble = new GambleMegajack();
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states

        // choosing image size, box coordinates for animation
        this.choosing = [      // choosing image size, box coordinates for animation
            {w: 60, h: 61, x: 227, y: 170, textPosition: {x: 390, y: 310}, frameCount: 10, colCount: 10},  // 0 hold
            {w: 60, h: 61, x: 288, y: 170, textPosition: {x: 390, y: 310}, frameCount: 10, colCount: 10},  // 1 hold
            {w: 60, h: 61, x: 349, y: 170, textPosition: {x: 390, y: 310}, frameCount: 10, colCount: 10},  // 2 hold
            {w: 60, h: 61, x: 410, y: 170, textPosition: {x: 390, y: 310}, frameCount: 10, colCount: 10},  // 3 hold
            {w: 60, h: 61, x: 471, y: 170, textPosition: {x: 390, y: 310}, frameCount: 10, colCount: 10},  // 4 hold
            {w: 60, h: 61, x: 532, y: 170, textPosition: {x: 390, y: 310}, frameCount: 10, colCount: 10},  // 5 hold
            {w: 60, h: 61, x: 593, y: 170, textPosition: {x: 390, y: 310}, frameCount: 10, colCount: 10}   // 6 hold
        ];
        this.totalwin = {x: 390, y: 365};
        this.totalwin2 = {x: 130, y: 422};

        // choosing image size, box coordinates for animation
        this.choosing2 = [      // choosing image size, box coordinates for animation
            {w: 60, h: 61, x: 600, y: 422, textPosition: {x: 620, y: 422}, frameCount: 10, colCount: 10}  // 0 hold
        ];

        this.rouleteMap = {
            0: {x: 205, y: 71, picture: 1000, xb: 377, yb: 74},
            1: {x: 205, y: 71, picture: 10, xb: 425, yb: 85},  // n: номер позиции в рулетке,
            2: {x: 220, y: 71, picture: 25, xb: 470, yb: 106}, //  x, y позиция на экране,
            3: {x: 205, y: 71, picture: 100, xb: 511, yb: 140}, //  picture: номер елемента в спрайте
            4: {x: 205, y: 71, picture: 10, xb: 536, yb: 188},
            5: {x: 205, y: 71, picture: 50, xb: 543, yb: 240},
            6: {x: 205, y: 71, picture: 300, xb: 533, yb: 291},
            7: {x: 205, y: 71, picture: 10, xb: 511, yb: 336},

            8: {x: 205, y: 71, picture: 50, xb: 475, yb: 371},
            9: {x: 205, y: 71, picture: 200, xb: 430, yb: 396},
            10: {x: 205, y: 71, picture: 10, xb: 378, yb: 406},
            11: {x: 205, y: 71, picture: 25, xb: 326, yb: 396},
            12: {x: 205, y: 71, picture: 100, xb: 280, yb: 371},

            13: {x: 205, y: 71, picture: 25, xb: 245, yb: 336},
            14: {x: 205, y: 71, picture: 10, xb: 222, yb: 291},
            15: {x: 205, y: 71, picture: 100, xb: 211, yb: 240},
            16: {x: 205, y: 71, picture: 10, xb: 217, yb: 188},
            17: {x: 205, y: 71, picture: 50, xb: 242, yb: 140},
            18: {x: 205, y: 71, picture: 10, xb: 284, yb: 106},
            19: {x: 205, y: 71, picture: 25, xb: 328, yb: 85}

        };
        this.dicesMap = {
            0: {x: 30, y: 60},
            1: {x: 140, y: 60},  // n: номер позиции в рулетке,
            2: {x: 250, y: 60}, //  x, y позиция на экране,
            3: {x: 350, y: 60}, //
            4: {x: 465, y: 60},
            5: {x: 580, y: 60},
            6: {x: 690, y: 60},

            7: {x: 690, y: 150},
            8: {x: 690, y: 230},
            9: {x: 690, y: 320},

            10: {x: 690, y: 400},
            11: {x: 580, y: 400},
            12: {x: 470, y: 400},
            13: {x: 360, y: 400},
            14: {x: 250, y: 400},
            15: {x: 150, y: 400},
            16: {x: 30, y: 400},

            17: {x: 30, y: 325},
            18: {x: 30, y: 225},
            19: {x: 30, y: 140}
        };
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 28px Arial';
        ctx.fillStyle = '#a67800';
        ctx.textAlign = 'center';

        switch (page) {
            case 1:
                ctx.fillText(bet * this.symbols[9].payment[5], 60, 87);     // 'e'
                ctx.fillText(bet * this.symbols[9].payment[4], 60, 124);
                ctx.fillText(bet * this.symbols[9].payment[3], 60, 162);
                ctx.fillText(bet * this.symbols[9].payment[2], 60, 198);

                ctx.fillText(bet * this.symbols[7].payment[5], 60, 260);    // island
                ctx.fillText(bet * this.symbols[7].payment[4], 60, 296);
                ctx.fillText(bet * this.symbols[7].payment[3], 60, 335);
                ctx.fillText(bet * this.symbols[7].payment[2], 60, 371);

                ctx.fillText(bet * this.symbols[5].payment[5], 60, 438);     // lemon
                ctx.fillText(bet * this.symbols[5].payment[4], 60, 475);
                ctx.fillText(bet * this.symbols[5].payment[3], 60, 511);

                ctx.fillText(bet * this.symbols[8].payment[5], 454, 89);     // ship
                ctx.fillText(bet * this.symbols[8].payment[4], 454, 125);
                ctx.fillText(bet * this.symbols[8].payment[3], 454, 164);
                ctx.fillText(bet * this.symbols[8].payment[2], 454, 200);

                ctx.fillText(bet * this.symbols[6].payment[5], 454, 260);    // cocktail
                ctx.fillText(bet * this.symbols[6].payment[4], 454, 296);
                ctx.fillText(bet * this.symbols[6].payment[3], 454, 335);

                ctx.fillText(bet * this.symbols[4].payment[5], 454, 438);    // pineapple
                ctx.fillText(bet * this.symbols[4].payment[4], 454, 475);
                ctx.fillText(bet * this.symbols[4].payment[3], 454, 511);
                break;
            case 2:
                ctx.fillText(bet * this.symbols[3].payment[5], 60, 45);      // grapes
                ctx.fillText(bet * this.symbols[3].payment[4], 60, 81);
                ctx.fillText(bet * this.symbols[3].payment[3], 60, 117);

                ctx.fillText(bet * this.symbols[1].payment[5], 60, 184);     // plum
                ctx.fillText(bet * this.symbols[1].payment[4], 60, 220);
                ctx.fillText(bet * this.symbols[1].payment[3], 60, 256);
                ctx.fillText(bet * this.symbols[1].payment[2], 60, 292);

                ctx.fillText(bet * this.symbols[2].payment[5], 454, 45);     // watermelon
                ctx.fillText(bet * this.symbols[2].payment[4], 454, 81);
                ctx.fillText(bet * this.symbols[2].payment[3], 454, 117);

                ctx.fillText(bet * this.symbols[0].payment[5], 454, 184);    // cherry
                ctx.fillText(bet * this.symbols[0].payment[4], 454, 220);
                ctx.fillText(bet * this.symbols[0].payment[3], 454, 256);
                ctx.fillText(bet * this.symbols[0].payment[2], 454, 292);

                ctx.fillText(bet * nLines * this.symbols[12].payment[5], 60, 386);    // '$'
                ctx.fillText(bet * nLines * this.symbols[12].payment[4], 60, 422);    // '$'
                ctx.fillText(bet * nLines * this.symbols[12].payment[3], 60, 458);    // '$'
                ctx.fillText(bet * nLines * this.symbols[12].payment[2], 60, 494);    // '$'
                break;
        }
    }
}
