import * as PIXI from 'pixi.js-legacy';

import SymbolInfo from './../../symbolInfo';
import App from '../../../index';

const Sprite = PIXI.Sprite;
export default class SymbolInfoCustom extends SymbolInfo {
    /**
     * Draw symbol info table.
     * @param symbolInfoContainer
     * @param index - index of symbol
     * @param container
     */
    draw(symbolInfoContainer, index, container) {
        const reelsContainer = App.Game.getStageChild('reelsStage');
        reelsContainer.mask = null;
        App.Game.getReelsMask().destroy();
        const reelsStage = App.Game.getStageChild('reelsStage');
        reelsStage.zIndex = App.Game.containersLayers.bonusContainer - 1;

        const symbol = container.getChildByName('symbol');
        symbol.textures = App.Game.getSymbolInfoTextures(container.name);
        symbol.loop = true;
        const {posX, posY} = this.getInitPos();
        const symbolContainer = symbolInfoContainer.parent;
        symbolContainer.zIndex = symbolContainer.parent.children.length;

        // direction of small table animation.
        const direction = symbolContainer.x - App.Game.symbolWidth < 0 ? 'right' : 'left';

        const symbolBorder = new Sprite(App.Game.getTexture('symbolBorder'));
        symbolBorder.position.set(posX, posY);
        symbolBorder.scale.set(0.1);
        symbolBorder.anchor.set(0.5);
        symbolBorder.name = 'symbolBorder';

        const paymentBorder = new Sprite(App.Game.getTexture('paymentBorder'));
        paymentBorder.position.set(posX, posY);
        paymentBorder.alpha = 0;
        paymentBorder.anchor.set(0.5);
        paymentBorder.name = 'paymentBorder';

        const symbolSprite = symbolContainer.getChildByName('symbol');
        symbolSprite.play();
        paymentBorder.mask = this.createBorderMask(symbolInfoContainer);

        symbolInfoContainer.addChild(paymentBorder, symbolBorder);
        this.drawPayments(paymentBorder, symbolInfoContainer.parent.name, direction);

        this.openAnimation = App.Game.symbolInfoOpen(symbolBorder, paymentBorder, direction);
    }
}
