import GameSettingsWays from './gameSettings';
import GameDeluxe from './../deluxe/game';

import './styles.less';

export default class WaysGame extends GameDeluxe {
    constructor() {
        super();
        this.gameSettings = new GameSettingsWays();
    }
}
