import App from './../../../index';
import GameEgt from './../../egt/game';
import Lines from './lines';
import GambleEgt from './../../egt/gamble';
import InfoScreen from '../../infoScreen';

export default class FlamingHot extends GameEgt {
    constructor() {
        super();
        this.id = 'flaming-hot';
        this.name = 'Flaming Hot';
        this.buttonsPanelShadow = 'mid';

        // reel properties
        this.reelRows = 4; // number of rows per reel
        this.symbolHeight = 95; // height of aa single symbol
        this.reelFilter = [[8], [], [], [], [8]];
        this.doublingFilter = [9];

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 120, y: 90},
            bonusInBonusFrame: {x: 120, y: 150},
            endBonusFrame: {x: 120, y: 150}
        };

        this.symbols = [
            {regularDelay: 60, skipSteps: 13, payment: [0, 0, 0, 8, 40, 100]},    // 0 - cherry
            {regularDelay: 60, skipSteps: 13, payment: [0, 0, 0, 8, 40, 100]},    // 1 - peach
            {regularDelay: 60, skipSteps: 13, payment: [0, 0, 0, 8, 40, 100]},    // 2 - apple
            {regularDelay: 60, skipSteps: 13, payment: [0, 0, 0, 8, 40, 100]},    // 3 - grape
            {regularDelay: 60, skipSteps: 13, payment: [0, 0, 0, 20, 80, 200]},   // 4 - whatermelon
            {regularDelay: 60, skipSteps: 13, payment: [0, 0, 0, 20, 80, 200]},   // 5 - bananas
            {regularDelay: 60, skipSteps: 13, payment: [0, 0, 0, 40, 100, 300]},  // 6 - BAR
            {regularDelay: 60, skipSteps: 13, payment: [0, 0, 4, 60, 200, 1000]}, // 7 - 7
            {regularDelay: 60, skipSteps: 13, payment: [0, 0, 0, 0, 0, 0]},       // 8 - WiLD
            {regularDelay: 60, skipSteps: 13, payment: [0, 0, 0, 2, 20, 500]}     // 9 - $ dollar scatter
        ];

        this.imageResources = {
            main: this.mergePath({mainArea: 'area/main.png'}),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                minimizeSymbols: 'minimizeSymbols.png',
                gambleArea: 'area/gamble.png'
            })
        };

        this.gameSounds = {
            soundClass: 'egt',
            sounds: [
                {name: 'scatter1Stop'},
                {name: 'scatter2Stop'},
                {name: 'win-line'}
            ],
            path: `/game/games/${this.id}/audio/`
        };
        this.Lines = new Lines(this.mergePath({boxes: 'lines/boxes.png'}));
        this.Gamble = new GambleEgt();
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
        this.InfoScreen.format = 'png';
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 19pt Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = 'white';
        ctx.shadowColor = 'black';
        ctx.shadowOffsetX = ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 5;

        switch (page) {
            case 1:
                ctx.font = 'bold 12pt Arial';

                // cherry, lemon, orange
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 200, 337);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 200, 358);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 200, 379);

                // plum, wathermelon
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 605, 337);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 605, 358);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 605, 379);

                // Seven 7
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], 210, 150);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], 210, 170);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 210, 190);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[2], 210, 210);

                // BAR
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 590, 160);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 590, 180);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 590, 200);

                ctx.textAlign = 'center';
                ctx.fillStyle = '#742904';
                ctx.shadowBlur = 0;
                // $ Dolar
                this.strokeFillText(ctx, bet * nLines * this.symbols[9].payment[5], 410, 357);
                this.strokeFillText(ctx, bet * nLines * this.symbols[9].payment[4], 410, 379);
                this.strokeFillText(ctx, bet * nLines * this.symbols[9].payment[3], 410, 402);
                break;
            case 2:
                ctx.font = 'bold 14pt Arial';
                ctx.textAlign = 'left';
                // gamble limit
                this.strokeFillText(ctx, (this.Gamble.limit * 100) / App.Money.getCurrentDenomination(), 495, 249);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    setRegularShortSprite(clipMatrix, reelIndex, textures) {
        if (clipMatrix[reelIndex][1].symbol === 8 || clipMatrix[reelIndex][2].symbol === 8 || clipMatrix[reelIndex][3].symbol === 8 || clipMatrix[reelIndex][4].symbol === 8) {
            App.Sounds.playSound('scatter1Stop');
        }
        if (clipMatrix[reelIndex][1].symbol === 9 || clipMatrix[reelIndex][2].symbol === 9 || clipMatrix[reelIndex][3].symbol === 9 || clipMatrix[reelIndex][4].symbol === 9) {
            App.Sounds.playSound('scatter2Stop');
        }
    }

    playFeatureSound(currentFeature, featureIndex, features) {
        let soundFile = null;
        if (featureIndex === 0) {
            switch (currentFeature.uc) {
                case 'WIN_LINE':
                case 'SCATTER':
                    soundFile = 'win-line';
                    break;
            }

            soundFile && App.Sounds.stopSound(soundFile);
            soundFile && App.Sounds.playSound(soundFile);
        }
    }
}
