module.exports = {
    // socket
    connectionServer: 'Connessione al server',
    initializingGame: 'Caricamento del gioco',
    initializingMenu: 'Menu di caricamento',
    loadingImages: 'Caricamento delle immagine',
    reload: 'Riavviare.',
    redirect: 'Reindirizzare',
    exitAndReload: 'Esci dal menu e ricarica la pagina',
    exit: 'Uscita',
    exitAfter: 'Disconnettersi dopo',
    serverNotResponded: 'Il server non ha risposto',
    pluginNotResponded: 'Nessuna connessione al controller',
    codeAlreadyConnected: 'Il giocatore con questo codice è collegato',
    connectionLost: 'Connessione persa',
    gameNotFound: 'Gioco non trovato',
    codeNotFound: 'Codice non trovato',
    cardNotFound: 'Carta non trovata',
    terminalNotFound: 'Il codice terminale non è stato trovato o il terminale non è disponibile',

    // login
    login: 'Accedi',
    loginCredentials: 'nome utente, e-mail o codice',
    codeCredentials: 'Codice dell\'account',
    username: 'Nome utente',
    enterCode: 'Inserisci il codice',
    password: 'Parola d\'ordine',
    email: 'Email',
    signIn: 'Accedi',
    signUp: 'Registrazione',
    registration: 'Registrazione',
    oneClick: 'Un click',
    demo: 'Demo',
    createAccount: 'Creare un profilo',
    logout: 'Esci',
    restorePasswordWithEmail: 'Inserisci l\'e-mail per ripristinare la password',
    sendNewPassword: 'Invia una nuova password',
    restorePassword: 'Ripristina password',
    restoringPassword: 'Recupero della password',
    successRegistration: 'Registrazione riuscita',
    incorrectUsername: 'Nome utente o password errati',
    notUniqueName: 'Il nome deve essere unico',
    notUniqueEmail: 'L email deve essere unica',
    notUniquePhone: 'Il numero di telefono dovrebbe essere uniq',
    missedField: 'Ci sono campi obbligatori mancanti',
    wrongOldPassword: 'Vecchia password inserita in modo errato',
    wrongPassword: 'Password inserita in modo errato',
    emailChanged: 'La conferma è stata inviata alla tua email',
    passwordResetError: 'Login o nome utente errati',
    newPasswordSent: 'La nuova password è stata inviata alla tua email',
    invalidEmail: 'E-mail non valido',
    invalidPhone: 'Phone number non valido \n ' +
        '+38 YYY XXX XX XX',
    enterPassword: 'Inserire la password',
    differentPasswords: 'Mancata corrispondenza della password',
    tooLongPassword: 'La password è molto grande (max. Caratteri: 20)',
    tooShortPassword: 'Password troppo breve (caratteri min: 8)',
    passwordChanged: 'Password cambiata',
    enterOldPassword: 'Inserisci la vecchia password',
    enterNewPassword: 'Inserire una nuova password',
    samePasswords: 'La vecchia e la nuova password non possono essere identiche',
    copied: 'Сopiato',
    copy: 'Copia',

    // profile
    save: 'Salva',
    confirmation: 'Confermare',
    saveAs: 'Salva come',
    cancel: 'Annulla',
    changingEmail: 'Modifica della posta elettronica',
    changeEmail: 'Inserisci nuova email',
    changingPhone: 'Modifica della phone',
    confirmCode: 'Codice di conferma',
    changePhone: 'Inserisci nuova phone',
    phoneConfirmed: 'Telefono confermato',
    changingPassword: 'Cambio password',
    changePassword: 'Cambia la password',
    oldPassword: 'Vecchia password',
    newPassword: 'Nuova password',
    confirmPassword: 'Conferma password',
    connectWithUs: 'Connettiti con noi',
    profile: 'Profilo',
    deposit: 'Depositare',
    withdraw: 'Ritiro',
    support: 'Supporto',
    date: 'Data',
    type: 'Genere',
    amount: 'Quantità',
    status: 'Status',
    transactionsHistory: 'Cronologia delle transazioni',
    exchange: 'Cambio di valuta',
    noData: 'nessun dato',
    transactionsBonus: 'Cronologia dei messaggi',
    message: 'Messaggio',
    ok: 'Ok',

    currencySelect: 'Moneta',
    paymentSelect: 'Metodo di pagamento',
    amountDepositSelect: 'Importo del deposito',
    finishOperation: 'Fine operazione',

    enterCurrency: 'Seleziona la valuta',
    enterCardNumber: 'Inserire il numero della carta',
    cardNumber: 'Numero di carta',
    paymentAccept: 'Conferma di pagamento',
    payoutAmount: 'Preleva importo',
    paymentType: 'Metodo di pagamento',
    enterDepositWay: 'Seleziona un metodo di pagamento',
    enterDepositAmount: 'Inserisci l\'importo da prelevare',
    enterWithdrawalWay: 'Seleziona il metodo di prelievo',
    enterPayoutAmount: 'Inserisci l\'importo del prelievo',
    successfulPayment: 'Pagamento è stato aggiunto con successo all\'elaborazione',
    successOperation: 'Pagamento riuscito',
    errorOperation: 'Errore di pagamento',
    confirm: 'Confermare',
    country: 'Nazione',
    currency: 'Moneta',
    invalidCountry: 'Paese non valido',
    invalidCurrency: 'Valuta non valida',
    successfullyChangedTo: 'Modificato con successo in',
    lowBalance: 'Fondi insufficienti sul saldo',

    accept: 'Accettare',
    decline: 'Declino',
    currencyExchange: 'Cambio di valuta',
    currentBalance: 'Bilancio corrente',
    from: 'a partire dal',
    to: 'per',
    exchangePercent: 'porcentaje de cambio',
    newBalance: 'nuovo equilibrio',
    coefficient: 'Сoefficiente',
    currencyConversion: 'Conversione di valuta',
    wrongInputData: 'Dati di input errati',
    wrongAmountData: 'Importo sbagliato',
    wrongCreditCard: 'Numero di carta di credito errato',
    noRedirectReff: 'Non vi è alcun riferimento al reindirizzamento',
    deserializationIssue: 'Problema con la deserializzazione',
    currencyPairNotExist: 'La coppia di valute non esiste',
    authorizationMandatory: 'L\'autorizzazione è obbligatoria',
    birthdayFormat: 'La data di nascita deve essere GG MM AAAA',
    tooShort: 'Numero di carta errato',
    infoPayment: 'L\'elaborazione del primo pagamento entro 24 ore è gratuita. Verrà addebitata una commissione per ogni pagamento successivo.',
    time: 'Tempo',
    period: 'Fino a 3 giorni',
    image: 'Immagine',
    text: 'Testo',

    // status
    balance: 'Un saldo',
    insurance: 'Assicurazione',
    credits: 'crediti',
    creditsWon: 'crediti vinti',
    winnerPaid: 'Vincitore pagato',
    gotNoMoney: 'Non abbastanza soldi',
    minBet: 'Puntata totale minima',
    gambleUp: 'Gioca d\'azzardo o vinci',
    gambleUp5x: 'Scommetti fino a 5 volte o vinci',
    gambleEnd: 'Tentativo completato',
    pressAnyButton: 'Premi start per iniziare',
    freeGame: 'Gioco gratis',
    of: 'di',
    freeSpinsRemaining: 'GIOCHI GRATUITI RIMASTI',
    freeGames: 'giochi gratuiti',
    more: 'addizionale ',
    freeSpinsLeft: 'GIOCHI GRATUITI RIMASTI',
    additionalRoll: 'Gira di nuovo',
    selectCard: 'Scegli la carta tua',
    selectColor: 'Scegli rosso o nero',
    selectColorOrSuit: 'Scegli il colore (x2) o il seme di carte (x4)',
    gameOverPlaceYourBet: 'Scommetti per favore',
    auto: 'Auto',
    linePays: 'Linea',
    scatterPays: 'Scatter paga',
    win: 'La vincite',
    line: 'Linea',
    youWon: 'Hai vinto',
    lowMinBet: 'Puntata minima su ',
    highMaxBet: 'Puntata massima su ',
    unSupport: 'Questo gioco non è supportato sul tuo dispositivo',
    urlNotFound: 'Pagina non trovata',
    categories: 'Categorie',
    chooseSide: 'Scegli un lato',
    makeChoice: 'Fai una scelta',
    multiplier: 'Moltiplicatore',

    // buttons
    lines: 'Linee',
    bet: 'Scommessa',
    total: 'Totale',
    denom: 'Denom',
    gamble: 'Giocare',
    paytable: 'Informazione',
    bonus: 'Bonus',
    start: 'Giocare',
    stop: 'Stop',
    autoplay: 'Autoplay',
    select: 'Scegliere',
    collect: 'Ritirare',
    red: 'Rossa',
    black: 'Nera',
    return: 'Ritorno',
    half: 'Metà',
    payout: 'Pagamento',
    swipeToStart: 'Scorri verso l\'alto per iniziare',
    tapToStart: 'Tocca per iniziare',

    // roulette buttons
    repeatAndStart: 'Ripetere la scommessa e giocare',
    double: 'Doppio',
    doubleAndSpin: 'Doppio e scorrere',
    undo: 'Annulla',
    repeatBet: 'Ripeti la scommessa',
    removeBets: 'Rimuovi le scommesse',
    results: 'Risultati',
    cover: 'Copertura',

    // gamble
    gambleAmount: 'Importo della scommessa',
    gambleToWin: 'Scommettere per vincere',
    gambleAmountAmatic: 'Importo della scommessa',
    gambleToWinAmatic: 'Rossa/Nera gioca per vincere',
    suitGambleToWinAmatic: 'Completa la scommessa per vincere',
    previousCards: 'Carte precedenti',
    gambleAttemptsLeft: 'Tentativi di scommessa rimasti',
    history: 'Storia',

    // wrap
    selectLine: 'Quantità di linee',
    selectBet: 'Scommessa per linea',
    selectDenomination: 'Denominazione',

    // info
    rules: 'Regole',
    substitutesForAllSymbols: 'Sostituisce tutti i simboli tranne gli scatter',
    combinationOfKind: 'Tutti i premi sono per combinazioni di un tipo. ',
    combinationLeftToRight: 'Tutti i premi sono per combinazioni da sinistra a destra, tranne gli scatter. ',
    prizesOnSelectedLines: 'Tutti i premi sono su linee selezionate, ad eccezione degli scatter. ',
    ScatterPayAtAnyPosition: 'I simboli scatter pagano in qualsiasi posizione sullo schermo. ',
    HighestWinPaid: 'La vincite più alta è stata pagata solo per linea selezionata. ',
    ScatterWinsAddedToLineWins: 'Vincite scatter aggiunte alle vincite di linea. ',
    prizesShownInCredits: 'La vincita più alta è stata pagata solo per linea selezionata. ',
    MalfunctionVoidsAllPays: 'Il malfunzionamento annulla tutti i pagamenti e le giocate. ',
    onTheScreen: 'Il grilletto',
    bonusGame: 'GIOCO BONUS',
    bonusGameGive: 'Dà alla roulette',
    freeSpins: 'GIRI GRATUITI',
    featureBonus: 'Nel gioco bonus, la prima bobina verrà congelata e riempita da',
    goodLuck: 'Buona fortuna!',

    // instruction IOS
    howToInstall: 'come installare l\'applicazione',
    pressTheButton: 'Fai clic sul pulsante Condividi',
    goToHomeScreen: 'Clic ',
    confirmInstallation: 'Conferma l\'installazione',

    // bonuses
    congratulation: 'Сongratulazioni',
    registration_bonus: {
        title: 'Bonus per la registrazione!',
        description: {
            punkt1: '- Per attivare il bonus, devi confermare la tua email e il tuo numero di telefono.',
            punkt2: '- Effettua un deposito sul tuo conto principale.',
            punkt3: '- Per accreditare il bonus sul conto principale, è necessario piazzare scommesse sulla somma 15 volte superiore alla somma del bonus.'
        },
        accept: 'Il tuo bonus per la registrazione depositato!'
    },

    week_bonus: {
        title: 'Bonus settimanale!',
        description: 'Bonus settimanalmente',
        accept: 'Il tuo bonus settimanale depositato!'
    }
};
