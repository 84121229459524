import App from './../../../index';

export default function getArrowSettings(destinationPositions, reel, row) {
    const arrowSettings = {
        0: {
            rotation: 1.65,
            possibleReels: [2, 3, 4],
            possibleRows: [0, 1, 2]
        },

        2: {
            rotation: 4.8,
            possibleReels: [0, 1, 2],
            possibleRows: [0, 1, 2]
        }

    };
    const possibleVariants = [];
    Object.keys(arrowSettings).forEach(side => {
        if (arrowSettings[side].possibleReels.includes(reel) &&
            arrowSettings[side].possibleRows.includes(row)) {
            possibleVariants.unshift(side);
        }
    });
    const typeIndex = App.Game.getRandomIntInclusive(0, possibleVariants.length - 1);
    const typeArrow = possibleVariants[typeIndex];
    const reelDestinationIndex = arrowSettings[typeArrow].possibleReels.indexOf(reel);
    const reelDestination = arrowSettings[typeArrow].possibleReels[reelDestinationIndex];
    const sourcePosition = {
        0: {
            x: destinationPositions.x * -(reelDestination + 7),
            y: destinationPositions.y
        },
        2: {
            x: destinationPositions.x * (reelDestination + 5) * 4,
            y: destinationPositions.y
        }
    };
    return {rotation: arrowSettings[typeArrow].rotation, sourcePosition: sourcePosition[typeArrow]};
}
