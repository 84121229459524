const regularFont = {
    imgWidth: 2039,

    fontWidthArray: [
        33, 33, 33, 33, 33, 33, 33, 33, 28, 25, 33,
        33, 33, 40, 32, 32, 36, 32, 32, 32, 35, 35,
        36, 38, 33, 31, 31, 31, 30, 30, 29, 28, 28,
        33, 23, 26, 33, 25, 40, 31, 31, 30, 36, 25,
        33, 26, 33, 31, 35, 32, 32, 27, 32, 30, 33,
        29, 30, 33, 29, 33, 29, 33, 25, 24, 30
    ],
    fontHeight: 37,
    symbolsOrder: [
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K',
        'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V',
        'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g',
        'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r',
        's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '1', '2', '3',
        '4', '5', '6', '7', '8', '9', '0', ' ', ',', '.'],
    imageResource: 'font/regularFont.png'
};

export default regularFont;
