import {JL} from 'jsnlog';

import App from './../index';
import makeId from './makeId';
import keyboardPress from './keyboard';
import translateError from './translateError';

let connectionTimeout = null; // check lost connection (without messages)
const reconnectTimeout = 10000; // 10 sec

export default class LocalSocket {
    constructor() {
        this.code = null; // player card code
        this.connectionType = null; // 'CARD' / 'QR'
        this.errorConnect = false; // 'card not found', 'already connected', ...
        this.devices = {}; // scanner, keyboard, rfid ...
        this.scannerDevices = {}; // 'RFID' / 'SCANNER'
        this.highlightedButtons = {
            b02: false, // start
            b20: false, // auto
            b19: false, // exit
            b21: false, // select game
            b22: false, // info
            b23: false, // denom
            b01: false, // bet
            b08: false, // max bet
            b04: false, // h1
            b05: false, // h2
            b07: false, // h3
            b06: false, // h4
            b03: false  // h5
        };
        App.configs.mode === 'vlt' && this.checkButtons();
    }

    /**
     * Create WebSocket
     * Add events: open, close, message, error
     * @param restored - was server reconnection
     */
    init(restored = false) {
        App.clearLoaderMessages();

        const url = 'ws://127.0.0.1:9004';
        JL().debug(`-- Init Local WebSocket (${url})`);
        this.webSocket = new WebSocket(url);

        /**
         * WebSocket open handler
         * Start check connection
         */
        this.webSocket.addEventListener('open', () => {
            JL().debug(`-- Local WebSocket opened (restored: ${restored})`);
            this.checkConnection();
            this.sendPing();
            this.resetKeyboardLight();
            this.updateDevice('RFID', true); // renew devices status
            this.updateDevice('SCANNER', true);
            this.updateDevice('RFID', false);
            this.updateDevice('SCANNER', false);
            this.updateBillAcceptor('DISABLE');
            App.clearErrors();
            restored && App.resetErrorMessage();
        });

        /**
         * WebSocket close handler
         * Try to restore connection
         */
        this.webSocket.addEventListener('close', () => {
            this.webSocket = null;
            clearTimeout(connectionTimeout);
            App.Socket.stopRestoring = true;
            App.Socket.webSocket?.close();
            App.showSocketMessage('pluginNotResponded', false, 'disconnect');
            this.code = null;

            // reset View component
            App.View.setState({currentState: 'NONE', devices: {}});

            setTimeout(() => this.init(true), reconnectTimeout);
        });

        /**
         * WebSocket message handler
         * Start check connection
         * Parse and collect response data
         */
        this.webSocket.addEventListener('message', event => {
            this.checkConnection();

            const response = JSON.parse(event.data);
            const {uc, code} = response;

            switch (uc) {
                case 'CARD':
                case 'QR':
                    // check another code
                    App.Socket.webSocket && code !== this.code && this.newCode(code);
                    this.connectionType = uc;
                    break;
                case 'PING':
                    setTimeout(this.sendPing, 1000);
                    break;
                case 'RFID-DEVICE':
                case 'SCANNER-DEVICE':
                case 'PRINTER-DEVICE':
                case 'BILL-ACCEPTOR-DEVICE':
                case 'KEYBOARD-DEVICE':
                    const {status, messages} = response;
                    JL().debug(`'${uc}', ${status}, ${messages}`);
                    this.devices[uc] = {status, messages};
                    App.updateState('devices', {[uc]: {status, messages}});
                    break;
                case 'KEYBOARD-SERIAL-NUMBER':
                    JL().debug(`'${uc}', ${response.serial}`);
                    App.configs.code = response.serial;
                    const {devices} = App.View.state;

                    // check all devices statuses
                    Object.keys(devices).every(key => devices[key].status !== 'ERROR') &&
                    setTimeout(() => {
                        App.View.setState({devices: {}});
                        App.Socket.init();
                    }, 5000);
                    break;
                case 'PUSH-BUTTONS':
                    JL().debug(`Pressed keyboard (${JSON.stringify(response.buttons)})`);
                    Object.keys(response.buttons).forEach(keyCode =>
                        keyboardPress({keyCode})?.());
                    break;
                case 'SET-LIGHT-BUTTONS':
                    Object.keys(response.buttons).forEach(key =>
                        this.highlightedButtons[key.replace('l', 'b')] = response.buttons[key]);
                    break;
                case 'BILL-ACCEPTOR-PAYOUT':
                    const payoutMessage = translateError(response.messages).msg;
                    response.status === 'ERROR' && App.updateState('slider', {payoutMessage});
                    break;
            }
        });

        /**
         * WebSocket error handler
         */
        this.webSocket.addEventListener('error', () => {
            App.showSocketMessage('pluginNotResponded', false, 'disconnect');
        });
    }

    sendPing = () =>
        this.webSocket?.send(JSON.stringify({uc: 'PING', id: makeId(6)}));

    /**
     * Start connection timeout
     * Close WebSocket if connection lost
     */
    checkConnection() {
        clearTimeout(connectionTimeout);
        connectionTimeout = setTimeout(() => {
            JL().debug('-- Local WebSocket not response, connection time left, closing...');
            this.webSocket.close();
        }, reconnectTimeout);
    }

    /**
     * Check new code
     * Exit from previous
     * @param code
     */
    newCode(code) {
        JL().debug(`New code scanned: '${code}'`);
        this.code === null ? // check connected code
            this.connectPlayer(code) :
            this.quitPreviousPlayer(code);
        this.code = code;
    }

    quitPreviousPlayer(code) {
        this.errorConnect ?
            this.handleQuitPlayer(code) :
            App.Socket.webSocket.send(JSON.stringify({uc: 'QUIT-PLAYER'}));
    }

    /**
     * Send upper cased code for connecting
     * @param code
     * @returns {null|*}
     */
    connectPlayer = code => {
        const sendCode = () => {
            App.Socket.webSocket?.readyState === 1 ?
                App.Socket.webSocket.send(JSON.stringify({
                    uc: 'CONNECT-PLAYER',
                    code: code.toUpperCase()
                })) : setTimeout(sendCode, 1000);
        };

        sendCode();
    };

    /**
     * Handle errors and update scanner devices
     * @param error
     */
    handleConnectPlayer(error) {
        JL().debug(`Player connected: ${this.code}`);
        error && App.showSocketMessage(translateError(error).msg, false, 'error');
        this.errorConnect = !!error;
        this.updateDevice('RFID', this.connectionType === 'QR');
        this.updateDevice('SCANNER', this.code !== '');
    }

    /**
     * Reset all states, show <Loader>
     * Connect new player
     * @param newCode
     */
    handleQuitPlayer(newCode = this.code) {
        JL().debug('Quit player');
        this.errorConnect = false;
        App.Game && App.destroyGame();
        App.View.setState({currentState: 'NONE'});
        App.resetLoader();
        App.updateState('loader', {active: true, fade: 'in', activeControlButtons: false});
        App.resetErrorMessage();
        this.updateDevice('RFID', true);
        this.updateDevice('SCANNER', true);
        if (newCode !== null) { // check connected code
            this.connectPlayer(newCode);
        }
    }

    /**
     * Check device status and send new state
     * @param device
     * @param disabled
     */
    updateDevice = (device, disabled = false) => {
        const inhibitDevice = status =>
            this.webSocket?.send(JSON.stringify({uc: `${device}-INHIBIT`, status}));

        if (this.scannerDevices[device] !== disabled) {
            this.scannerDevices[device] = disabled;
            inhibitDevice(disabled);
        }
    };

    /**
     * Toggle bill acceptor state
     * @param command - 'ENABLE' / 'DISABLE' / 'EMPTY-ALL'
     */
    updateBillAcceptor = command =>
        this.webSocket?.send(JSON.stringify({uc: 'BILL-ACCEPTOR-DEVICE', command}));

    payout = () => {
        App.updateState('slider', {payoutHandler: null});
        const amount = +App.View.state.slider.payoutAmount;

        App.Money.getCents() / 100 > amount ?
            this.webSocket.send(JSON.stringify({uc: 'BILL-ACCEPTOR-PAYOUT', amount})) :
            App.updateState('slider', {payoutMessage: 'gotNoMoney'});
    };

    /**
     * Launch requestAnimationFrame for check and update buttons
     */
    checkButtons() {
        const buttons = {};
        App.View && Object.keys(this.highlightedButtons).forEach(keyCode =>
            buttons[keyCode] = !!keyboardPress({keyCode})); // check available handler

        this.webSocket && this.updateKeyboardLight(buttons);
        requestAnimationFrame(this.checkButtons.bind(this));
    }

    /**
     * Update buttons
     * Send light state for each updated button
     * @param newButtons
     */
    updateKeyboardLight(newButtons) {
        const buttons = {};
        Object.keys(newButtons).forEach(key => {
            // save updated buttons
            if (this.highlightedButtons[key] !== newButtons[key]) {
                buttons[key.replace('b', 'l')] = newButtons[key];
            }
        });

        // update buttons
        Object.entries(buttons).length && this.webSocket.readyState === 1 &&
        this.webSocket.send(JSON.stringify({uc: 'SET-LIGHT-BUTTONS', buttons}));
    }

    resetKeyboardLight() {
        const buttons = {};
        Object.keys(this.highlightedButtons).forEach(key =>
            buttons[key.replace('b', 'l')] = false);
        this.webSocket.send(JSON.stringify({uc: 'SET-LIGHT-BUTTONS', buttons}));
    }
}
