import React from 'react';
import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../../index';
import GameAmatic from './../amatic/game';
import Roll from './../roll';
import Game from './../Game';

// PIXI aliases
const Text = PIXI.Text,
    Sprite = PIXI.Sprite,
    Container = PIXI.Container;

export default class GameEgt extends GameAmatic {
    constructor() {
        super();
        this.reelXCoordinates = [60, 198, 336, 474, 612]; // magic numbers - x coordinates where reels start
        this.symbolHeight = 126; // height of aa single symbol
        this.symbolWidth = 126; // height of aa single symbol
        this.reels = 5;
        this.reelRows = 3; // number of rows per reel
        this.reelTop = 80; // magic number - where reel images starts
        this.doublingFilter = [];

        this.DOMComponents.gambleComponent = buttons => <div className='gamble open'>
            {App.Game.DOMComponents.gambleCanvas}
            <section className='gamble-buttons'>
                <button
                    className={`gamble-buttons-red ${buttons.redCard.handler ? '' : 'pressed'}`}
                    onClick={buttons.redCard.handler}
                    style={{backgroundImage: `url(${App.Game.getAdditionalImage(`${buttons.redCard.img}Red`).src})`}}
                />
                <button
                    className={`gamble-buttons-black ${buttons.blackCard.handler ? '' : 'pressed'}`}
                    onClick={buttons.blackCard.handler}
                    style={{backgroundImage: `url(${App.Game.getAdditionalImage(`${buttons.blackCard.img}Black`).src})`}}
                />
            </section>
        </div>;

        // roll properties
        this.soundCredit = false;
        this.defaultFeatureDelay = 900; // ms for show win line
        this.Roll = new RollUnique();
        this.reelSettings = [20, 12, 20]; // 0 - start symbol amount, 1 - reel increase regular roll, 2 - reel increase long roll
        this.rollProperties = {
            reelSpeed: 1.9,
            springDown: 0.3,
            springUp: 0.2
        };
        this.prepareToAnimateFeature = Game.prototype.prepareToAnimateFeature.bind(this);
    }

    showFeatureLine(currentFeature) {
        const {number, reels, payment, uc} = currentFeature, // get current feature params
            container = this.getStageChild('linesContainer');

        uc !== 'SPECIAL_SYMBOL' && container.removeChildren(); // don't clear lines before special symbol (bookGame fill)
        if (['WIN_LINE', 'SCATTER'].includes(uc)) {
            uc === 'WIN_LINE' && this.Lines.drawLineImages([number], reels, container, true, payment);
            this.Lines.drawBoxes(this.getStageChild('boxesContainer'), number);
            this.getState() !== 'SHOW_BONUS_WIN_LINES' && this.createFeatureInfo(currentFeature, container);
        }
    }

    createFeatureInfo(feature, container) {
        const {number, symbol, reels, positions, payment, uc} = feature, // get current feature params
            ucReels = uc === 'WIN_LINE' ? reels.length : positions.length, // check 'SCATTER' feature

            textProps = {
                font: 'Arial',
                fontSize: 14,
                fontWeight: 600,
                fill: '#f0e7cb'
            },
            winSymbolsContainer = new Container();
        winSymbolsContainer.position.set(-100, -170);
        winSymbolsContainer.zIndex = 200;
        winSymbolsContainer.name = 'winSymbolsContainer';
        container.addChild(winSymbolsContainer);
        const statusLine = new Text(
            `${uc === 'WIN_LINE' ? App.language.line.toUpperCase() : 'SCATTERED'} ${uc === 'WIN_LINE' ? number + 1 : ''}: `,
            textProps
        );

        const statusPayment = new Text(
            `${App.language.win.toUpperCase()}: ${payment} `,
            textProps
        );

        statusLine.position.set(210, 470);
        statusPayment.position.set(480, 470);

        for (let i = 0; i <= ucReels - 1; i++) {
            const minimizeSymbol = new Sprite(this.getSpriteTextures({
                image: 'minimizeSymbols',
                fromFrame: symbol,
                colCount: this.symbols.length,
                width: 22,
                height: 19
            })[0]);

            minimizeSymbol.position.x = 420 + (22 * i);
            minimizeSymbol.position.y = 639;
            winSymbolsContainer.addChild(minimizeSymbol);
        }

        container.addChild(statusLine, statusPayment);
    }

    /**
     * Function to play animate credit sound
     */
    playCreditSound() {
        if (this.soundCredit) {
            return;
        }
        this.soundCredit = true;
        this.isSpeedUp === 0 ?
            App.Sounds.playSound('add-credit1') :
            App.Sounds.playSound('add-credit2');
    }

    endAnimateCredits(isTransfer) {
        this.stopCreditSound();
        this.soundCredit = false;
        this.isSpeedUp = 0;

        isTransfer ?
            App.Socket.send(JSON.stringify({uc: 'TRANSFER-WIN'})) :
            this.checkNewPrize();
    }

    setGambleUpStatus() {
        this.Legends.setStatus('gambleUp');
    }

    getFeatureDelay(currentFeature, features) {
        let delay = 0;

        switch (currentFeature.uc) {
            case 'WIN_WAY':
            case 'WIN_LINE':
                delay = !this.gameFlag.bonusStarted ? this.winLineFeatureDelay : this.winLineFeatureDelay / 2;
                break;
            case 'SCATTER':
            case 'SPECIAL_SYMBOL':
                delay =
                    features.some(feature => feature.uc === 'FREE_ROLL') &&
                    !features.some(feature => feature.uc === 'WIN_LINE') ?
                        0 : this.winLineFeatureDelay;
                break;
        }

        return delay;
    }

    prepareToRollAnimation(response) {
        !this.isBonus() && this.Legends.setStatus('goodLuck');
        this.Buttons.disableAllButtons();
        App.updateButton('start', {
            disabled: false,
            title: 'stop',
            handler: () => { // change handler to stop reels animation
                JL().debug('-- Stop roll');
                this.Roll.stopReels = [1, 1, 1, 1, 1, 1];
                this.Roll.fastReelsSound = 1;
                App.updateButton('start', {disabled: true});
            }
        });
        this.extraBet && this.updateExtraBetButtons(false);
        this.Roll.startReelAnimation(response);
    }

    getRandomSymbol(length, reelIndex, symbolBefore) {
        const denyRepeat = Math.floor(Math.random() * 4); // decrease repeat 5 times less
        let symbol = Math.floor(Math.random() * length);
        if (denyRepeat === 0 && this.doublingFilter.indexOf(symbolBefore) === -1) {
            symbol = symbolBefore;
        } else {
            while (
                symbol === this.reelFilter[reelIndex][0] ||
                symbol === this.reelFilter[reelIndex][1] ||
                symbol === this.reelFilter[reelIndex][2] ||
                symbol === this.reelFilter[reelIndex][3] ||
                symbol === symbolBefore) {
                symbol = Math.floor(Math.random() * length);
            }
        }
        return symbol;
    }
}

class RollUnique extends Roll {
    reelStopSound(reelIndex) {
        const reelSetting = this.reelsSetting[reelIndex];
        if (!reelSetting.stopSound && !this.fastReelsSound) { // барабан уже остановился запускаем звук
            reelSetting.stopSound = true; // звук проиграли
            App.Sounds.playSound('reelsstop');
            App.Game.setRegularShortSprite(this.clipMatrix, reelIndex, this.textures);
        }
        if (this.stopReels[0] === 1 && this.stopReels[1] === 1 && this.stopReels[2] === 1 && this.stopReels[3] === 1 && this.stopReels[4] === 1 && this.fastReelsSound === 1) {
            this.fastReelsSound = 2;
            App.Sounds.playSound('reelsstop');
        }
    }
}
