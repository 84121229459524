import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import Lines from './../lines';
import App from './../../index';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Texture = PIXI.Texture,
    Text = PIXI.Text;

export default class Lines27 extends Lines {
    constructor() {
        super();
        this.imageResources = {
            lines: '/img/lines/Lines27/lines.png',
            winLines: '/img/lines/Lines27/winlines.png',
            boxes: '/img/lines/Lines27/boxes.png'
        };
        this.lineImageWidth = 690; // width of line image in sprite, magic number for each game
        this.lineImageHeight = 226; // height of line image in sprite, magic number for each game

        this.lines = {
            0: {coordinates: [1, 1, 1], boxes: [{x: 0, y: 254}], path: [{x: 32, y: 249}]},
            1: {coordinates: [0, 0, 0], boxes: [{x: 0, y: 142}], path: [{x: 32, y: 138}]},
            2: {coordinates: [2, 2, 2], boxes: [{x: 0, y: 392}], path: [{x: 32, y: 388}]},
            3: {coordinates: [0, 1, 2], boxes: [{x: 0, y: 85}], path: [{x: 32, y: 85}]},
            4: {coordinates: [2, 1, 0], boxes: [{x: 0, y: 450}], path: [{x: 32, y: 100}]},
            5: {coordinates: [0, 1, 0], boxes: [{x: 0, y: 198}], path: [{x: 32, y: 194}]},
            6: {coordinates: [2, 1, 2], boxes: [{x: 0, y: 365}], path: [{x: 32, y: 170}]},
            7: {coordinates: [1, 0, 1], boxes: [{x: 765, y: 326}], path: [{x: 75, y: 188}]},
            8: {coordinates: [1, 2, 1], boxes: [{x: 765, y: 265}], path: [{x: 75, y: 253}]},
            9: {coordinates: [2, 1, 1], boxes: [{x: 0, y: 420}], path: [{x: 32, y: 285}]},
            10: {coordinates: [0, 1, 1], boxes: [{x: 0, y: 114}], path: [{x: 32, y: 110}]},
            11: {coordinates: [1, 2, 2], boxes: [{x: 0, y: 226}], path: [{x: 32, y: 221}]},
            12: {coordinates: [1, 0, 0], boxes: [{x: 765, y: 176}], path: [{x: 75, y: 175}]},
            13: {coordinates: [1, 1, 2], boxes: [{x: 765, y: 356}], path: [{x: 75, y: 227}]},
            14: {coordinates: [1, 1, 0], boxes: [{x: 0, y: 310}], path: [{x: 32, y: 171}]},
            15: {coordinates: [2, 2, 1], boxes: [{x: 765, y: 236}], path: [{x: 75, y: 219}]},
            16: {coordinates: [0, 0, 1], boxes: [{x: 0, y: 170}], path: [{x: 32, y: 159}]},
            17: {coordinates: [0, 2, 0], boxes: [{x: 765, y: 86}], path: [{x: 75, y: 94}]},
            18: {coordinates: [2, 0, 2], boxes: [{x: 765, y: 446}], path: [{x: 75, y: 131}]},
            19: {coordinates: [0, 2, 2], boxes: [{x: 765, y: 386}], path: [{x: 75, y: 142}]},
            20: {coordinates: [2, 0, 0], boxes: [{x: 765, y: 146}], path: [{x: 75, y: 123}]},
            21: {coordinates: [0, 0, 2], boxes: [{x: 765, y: 416}], path: [{x: 75, y: 159}]},
            22: {coordinates: [2, 2, 0], boxes: [{x: 765, y: 116}], path: [{x: 75, y: 63}]},
            23: {coordinates: [0, 2, 1], boxes: [{x: 765, y: 206}], path: [{x: 75, y: 68}]},
            24: {coordinates: [2, 0, 1], boxes: [{x: 765, y: 296}], path: [{x: 75, y: 127}]},
            25: {coordinates: [1, 2, 0], boxes: [{x: 0, y: 282}], path: [{x: 32, y: 126}]},
            26: {coordinates: [1, 0, 2], boxes: [{x: 0, y: 337}], path: [{x: 32, y: 181}]}
        };

        JL().debug('-- Lines27 initialized');
    }

    drawLineImages(lines, winReels, parentContainer, winLine, payment) {
        lines.forEach(lineIndex => {
            const line = this.lines[lineIndex];

            line.coordinates.forEach(() => {
                this.lineImageWidth = 690;
                this.lineImageHeight = 226;
                const spritePosX = lineIndex > 14 ? 1 : 0;
                const newLineIndex = spritePosX === 1 ? (lineIndex % 15) : lineIndex;
                let spritePosY = this.lineImageHeight * lineIndex;

                if (lineIndex < 3) {
                    this.lineImageHeight = 25;
                    spritePosY = this.lineImageHeight * newLineIndex;
                }

                if (lineIndex >= 3 && lineIndex < 5) {
                    this.lineImageHeight = 370;
                    spritePosY = 75 + this.lineImageHeight * (newLineIndex - 3);
                }

                if (lineIndex >= 5) {
                    this.lineImageHeight = 328;
                    spritePosY = 75 + 370 * 2 + this.lineImageHeight * (newLineIndex - 5);
                }
                if (lineIndex > 14) {
                    spritePosY = this.lineImageHeight * newLineIndex;
                }

                // create line sprite
                const sprite = new Sprite(new Texture(App.Game.getTexture('lines'), {
                    x: spritePosX * this.lineImageWidth,
                    y: spritePosY,
                    width: this.lineImageWidth,
                    height: this.lineImageHeight
                }));
                sprite.position.set(line.path[0].x, line.path[0].y);
                sprite.name = lineIndex;
                parentContainer.addChild(sprite);
            });
        });
    }

    drawBoxes(parentContainer) {
        parentContainer.removeChildren(); // clear all previous boxes

        const currentLines = App.Game.gameSettings.getCurrentLineMas();
        const boxWidth = 35; // width of winline boxes
        const boxHeight = 25; // height of winline boxes

        Object.keys(this.lines).forEach(lineIndex => {
            this.lines[lineIndex].boxes.forEach(box => {
                const activeLine = currentLines.includes(+lineIndex + 1);

                // create box sprite
                const sprite = new Sprite(new Texture(App.Game.getTexture('boxes'), {
                    x: 0,
                    y: lineIndex * boxHeight,
                    width: boxWidth,
                    height: boxHeight
                }));
                sprite.position.set(box.x, box.y);
                sprite.name = lineIndex;
                activeLine && this.addBoxEvents(sprite);
                parentContainer.addChild(sprite);

                // add text to active box
                if (activeLine) {
                    const text = new Text(+lineIndex + 1, {
                        fontFamily: 'Arial',
                        fontSize: 19,
                        fill: '#000',
                        lineJoin: 'round'
                    });
                    text.anchor.set(0.5);
                    text.position.set(box.x + boxWidth / 2, box.y + boxHeight / 2);
                    parentContainer.addChild(text);
                }
            });
        });
    }
}
