import * as PIXI from 'pixi.js-legacy';

import App from './../index';

const Container = PIXI.Container,
    Sprite = PIXI.Sprite,
    Graphics = PIXI.Graphics,
    Rectangle = PIXI.Rectangle;

export default class SymbolInfo {
    constructor() {
        this.enabled = false;
        this.openAnimation = [];
        this.openedSymbolsInfo = new Map();
        this.settings = {
            symbolBorderOffset: {x: 0, y: 0},
            paymentBorderOffset: {left: 70, right: 70} // end animation offset
        };
    }

    init() {
        if (this.enabled && App.Game.isResourcesLoaded() && App.Game.getState() === 'IDLE') {
            App.Game.getStageChild('reelsStage').children.forEach((container, index) => {
                // choose symbol from Container
                container.removeAllListeners(); // remove all events of sprite for prevent errors
                container.interactive = true;
                container.buttonMode = true;
                container.hitArea = new Rectangle(
                    0, 0,
                    App.Game.symbolWidth, App.Game.symbolHeight
                );
                container
                    .on('pointerdown', () => {
                        App.Game.stopAnimateFeature();
                        App.Game.Legends.showJackpot();
                        this.remove(true);

                        if (!container.getChildByName('fadeOut') && !container.getChildByName('symbolInfo')) {
                            // remove all opened symbolInfo containers
                            const symbolInfoContainer = this.createSymbolInfoContainer(container);
                            this.draw(symbolInfoContainer, index, container);
                            this.openedSymbolsInfo.set(index, symbolInfoContainer);
                        }
                    })
                    .on('pointerout', () => this.remove(true));
            });
        }
    }

    createSymbolInfoContainer = parentContainer => {
        const container = new Container();
        container.name = 'symbolInfo';
        container.zIndex = -5;
        parentContainer.addChild(container);
        return container;
    };

    getInitPos = () => ({
        posX: App.Game.symbolWidth / 2,
        posY: App.Game.symbolHeight / 2
    });

    /**
     * Draw symbol info table.
     * @param symbolInfoContainer
     * @param index - index of symbol
     * @param container
     */
    draw(symbolInfoContainer, index, container) {
        const reelsStage = App.Game.getStageChild('reelsStage');
        reelsStage.zIndex = App.Game.containersLayers.bonusContainer - 1;

        const symbol = container.getChildByName('symbol');
        symbol.textures = App.Game.getSymbolInfoTextures(container.name);
        symbol.loop = true;
        const {posX, posY} = this.getInitPos();
        const symbolContainer = symbolInfoContainer.parent;
        symbolContainer.zIndex = symbolContainer.parent.children.length;

        // direction of small table animation.
        const direction = symbolContainer.x - App.Game.symbolWidth < 0 ? 'right' : 'left';

        const symbolBorder = new Sprite(App.Game.getTexture('symbolBorder'));
        symbolBorder.position.set(posX, posY);
        symbolBorder.scale.set(0.1);
        symbolBorder.anchor.set(0.5);
        symbolBorder.name = 'symbolBorder';

        const paymentBorder = new Sprite(App.Game.getTexture('paymentBorder'));
        paymentBorder.position.set(posX, posY);
        paymentBorder.alpha = 0;
        paymentBorder.anchor.set(0.5);
        paymentBorder.name = 'paymentBorder';

        const symbolSprite = symbolContainer.getChildByName('symbol');
        symbolSprite.play();
        paymentBorder.mask = this.createBorderMask(symbolInfoContainer);

        symbolInfoContainer.addChild(paymentBorder, symbolBorder);
        this.drawPayments(paymentBorder, symbolInfoContainer.parent.name, direction);

        this.openAnimation = App.Game.symbolInfoOpen(symbolBorder, paymentBorder, direction);
    }

    /**
     * Create PIXI.Graphics element for using as mask
     * @param parentContainer
     * @returns {PIXI.Graphics}
     */
    createBorderMask(parentContainer) {
        const width = App.Game.symbolWidth;
        const height = App.Game.symbolHeight;
        const borderMask = new Graphics()
            .beginFill(0x000000)
            .drawRect(0, 0, -width, height)
            .drawRect(width, 0, width, height)
            .endFill();
        parentContainer.addChild(borderMask);
        borderMask.name = 'borderMask';
        borderMask.position.set(0, 0);
        return borderMask;
    }

    drawPayments(parentContainer, symbolIndex, direction) {
        const payTable = []; // array of payments
        App.Game.symbols[symbolIndex].payment.forEach((pay, key) =>
            pay !== 0 && payTable.push([
                key,
                pay * (symbolIndex === App.Game.scatter ? App.Game.gameSettings.getLinesNumber() : 1)
            ]));
        App.Game.drawSymbolInfoPayments(parentContainer, payTable, direction, symbolIndex);
    }

    /**
     * Hide symbol info table.
     */
    hide() {
        this.openedSymbolsInfo.forEach((container, key) => {
            container.parent.zIndex = key;
            container.name = 'fadeOut'; // change name of container to prevent of double penetration in this function.
            this.openedSymbolsInfo.clear();

            const symbolBorder = container.getChildByName('symbolBorder');
            const paymentBorder = container.getChildByName('paymentBorder');

            const initialPosX = container.x + paymentBorder.width / 2;

            App.Game.showAnimation({
                duration: 200, animations: [
                    {sprite: paymentBorder, timeline: [{to: {x: initialPosX, alpha: 0}, duration: {to: 100}}]},
                    {sprite: symbolBorder, timeline: [{to: {scaleX: 0, scaleY: 0}, duration: {from: 100}}]}
                ],
                onComplete: () => {
                    container.destroy();
                }
            });
        });
    }

    remove(animate = true) {
        const reelsStage = App.Game.getStageChild('reelsStage');
        reelsStage.zIndex = App.Game.containersLayers.reelsStage;

        const ticker = App.Game.app.ticker;
        if (this.enabled && this.openedSymbolsInfo.size) {
            animate ?
                this.hide() :
                this.openedSymbolsInfo.forEach(container => {
                    this.openedSymbolsInfo.clear();
                    container.destroy();
                });
            App.Game.resetSymbolAnimation();
        }
        this.openAnimation.forEach(animation => ticker.remove(animation));
        this.openAnimation = [];
    }
}
