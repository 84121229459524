import {JL} from 'jsnlog';

import App from './../../index';
import Gamble from './../Gamble';

export default class GambleChampion extends Gamble {
    constructor(imageResources) {
        super();
        this.imageResources = {
            aceOfClubs: '/img/gamble/champion/ace-of-clubs.png',
            aceOfDiamonds: '/img/gamble/champion/ace-of-diamonds.png',
            aceOfHearts: '/img/gamble/champion/ace-of-hearts.png',
            aceOfSpades: '/img/gamble/champion/ace-of-spades.png',
            activeBlack: '/img/gamble/champion/black-active.png',
            inactiveBlack: '/img/gamble/champion/black-inactive.png',
            blackCard: '/img/gamble/champion/card-black.png',
            redCard: '/img/gamble/champion/card-red.png',
            smallCard: '/img/gamble/champion/card-small.png',
            clubs: '/img/gamble/champion/clubs.png',
            diamonds: '/img/gamble/champion/diamond.png',
            gambleArea: '/img/gamble/champion/gamble-area.png', // usually redefined
            hearts: '/img/gamble/champion/hearts.png',
            activeRed: '/img/gamble/champion/red-active.png',
            inactiveRed: '/img/gamble/champion/red-inactive.png',
            spades: '/img/gamble/champion/spades.png',
            ...imageResources
        };
        this.cardPos = {x: 335, y: 160};
        this.cardsQueuePos = {x: [441, 507, 573, 639, 705], y: 90};
        this.prizeWinPos = [{x: 208, y: 72}, {x: 595, y: 72}];
        this.screenCoordinates = {x: 0, y: 70};

        JL().debug('-- Gamble Champion initialized');
    }

    drawGambleQueue = offsetY => {
        const ctx = this.ctx;
        this.gambleQueue.forEach((card, index) => {
            const sIndex = this.getCardSuite(card);
            ctx.drawImage(
                App.Game.getAdditionalImage(this.suit2images[sIndex].small),
                this.screenCoordinates.x + this.cardsQueuePos.x[index],
                offsetY + this.cardsQueuePos.y
            );
        });
    };

    drawChooseSmallCard(ctx, card) {

    }
}
