import App from './../../../index';
import GameDeluxe5x4 from './../../deluxe/gameDeluxe5x4';
import Lines40 from './../../deluxe/lines40';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';

export default class RoaringForties extends GameDeluxe5x4 {
    constructor() {
        super();
        this.id = 'roaring-forties';
        this.name = 'Roaring Forties';
        this.buttonsPanelShadow = 'mid';

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 0, 8, 40, 100]},    // 0 - cherry
            {regularDelay: 100, payment: [0, 0, 0, 8, 40, 100]},    // 1 - lemon
            {regularDelay: 100, payment: [0, 0, 0, 8, 40, 100]},    // 2 - orange
            {regularDelay: 100, payment: [0, 0, 0, 8, 40, 100]},    // 3 - plum
            {regularDelay: 100, payment: [0, 0, 0, 20, 80, 200]},   // 4 - grape
            {regularDelay: 100, payment: [0, 0, 0, 20, 80, 200]},   // 5 - melon
            {regularDelay: 100, payment: [0, 0, 0, 40, 100, 300]},  // 6 - bell
            {regularDelay: 100, payment: [0, 0, 4, 60, 200, 1000]}, // 7 - digit '7'
            {regularDelay: 100, payment: [0, 0, 0, 2, 20, 500]},    // 8 - star
            {regularDelay: 100, payment: [0, 0, 0, 0, 0, 0]}        // 9 - WILD
        ];
        this.imageResources = {
            main: this.mergePath({mainArea: `area/${App.System.resolution}/main.png`}),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.gameSounds = {soundClass: 'deluxe'};

        this.Lines = new Lines40();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            smallCard: 'gamble/card-small.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        switch (page) {
            case 1:
                ctx.font = 'bold 15pt Franklin Gothic Medium';
                ctx.textAlign = 'left';
                ctx.fillStyle = '#fff';
                ctx.strokeStyle = '#000';
                ctx.lineWidth = 3;
                ctx.shadowColor = '#000';
                ctx.shadowOffsetX = 1;
                ctx.shadowOffsetY = 1;
                ctx.shadowBlur = 2;

                // cherry, lemon, orange, plum
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 660, 358);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 660, 384);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 660, 410);
                // grape, melon
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 70, 358);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 70, 384);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 70, 410);
                // bell
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 660, 67);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 660, 93);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 660, 119);
                // 7
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], 80, 51);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], 80, 75);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 80, 100);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[2], 80, 125);
                // star
                this.strokeFillText(ctx, bet * nLines * this.symbols[8].payment[5], 435, 499);
                this.strokeFillText(ctx, bet * nLines * this.symbols[8].payment[4], 435, 525);
                this.strokeFillText(ctx, bet * nLines * this.symbols[8].payment[3], 435, 551);
                break;
            case 3:
                ctx.font = 'italic bold 24pt Arial';
                ctx.strokeStyle = '#000';
                ctx.fillStyle = '#ff0000';
                ctx.lineWidth = 3;
                ctx.textAlign = 'center';
                this.strokeFillText(ctx, 'RULES', 400, 180);

                ctx.font = 'italic bold 13pt Arial';
                ctx.fillStyle = '#ffff00';
                this.strokeFillText(ctx, 'All prizes are for combinations of a kind. All prizes', 400, 240);
                this.strokeFillText(ctx, 'are for combinations left to right, except scatters.', 400, 270);
                this.strokeFillText(ctx, 'All prizes are on selected lines, except scatters.', 400, 300);
                this.strokeFillText(ctx, 'Scatter symbols pay at any position on screen.', 400, 330);
                this.strokeFillText(ctx, 'Highest win only paid per selected line. Scatter', 400, 360);
                this.strokeFillText(ctx, 'wins are added to line wins. All prizes shown in', 400, 390);
                this.strokeFillText(ctx, 'credits. Malfunction voids all pays and plays.', 400, 420);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }
}
