module.exports = {
    // socket
    connectionServer: 'Connexion au serveur',
    initializingGame: 'Chargement du jeu',
    initializingMenu: 'Chargement du menu',
    loadingImages: 'Chargement des images',
    reload: 'Recharger',
    redirect: 'La transition',
    exitAndReload: 'Sortir au menu et recharger la page',
    exit: 'Sortie',
    exitAfter: 'Sortie du système en',
    serverNotResponded: 'Le serveur ne répond pas',
    pluginNotResponded: 'Pas de connexion au contrôleur',
    codeAlreadyConnected: 'Un joueur avec ce code est déjà connecté',
    connectionLost: 'Connexion perdue',
    gameNotFound: 'Jeu non trouvé',
    codeNotFound: 'Code non trouvé',
    cardNotFound: 'Carte non trouvée',
    terminalNotFound: 'Code terminal introuvable ou terminal indisponible',

    // login
    login: 'Connexion',
    loginCredentials: 'nom d\'utilisateur, email ou code',
    codeCredentials: 'Code de compte',
    username: 'Nom d\'utilisateur',
    enterCode: 'Entrez le code',
    password: 'Le mot de passe',
    email: 'Email',
    signIn: 'Connexion',
    signUp: 'S\'inscrire',
    registration: 'Inscription',
    oneClick: 'Un clic',
    demo: 'Demo',
    createAccount: 'Créer un compte',
    logout: 'Se déconnecter',
    restorePasswordWithEmail: 'Entrez votre e-mail pour réinitialiser votre mot de passe',
    sendNewPassword: 'Envoyer un nouveau mot de passe',
    restorePassword: 'Restaurer le mot de passe',
    restoringPassword: 'Récupération le mot de passe',
    successRegistration: 'Inscription réussie',
    incorrectUsername: 'Identifiant ou mot de passe incorrect',
    notUniqueName: 'Le nom doit être unique',
    notUniqueEmail: 'L\'e-mail doit être unique',
    notUniquePhone: 'Le numéro de téléphone doit être uniq',
    missedField: 'Il manque des champs obligatoires',
    wrongOldPassword: 'Ancien mot de passe mal saisi',
    wrongPassword: 'Mot de passe mal saisi',
    emailChanged: 'La confirmation a été envoyée à votre adresse e-mail',
    passwordResetError: 'Mauvaise connexion ou e-mail',
    newPasswordSent: 'Un nouveau mot de passe a été envoyé à votre adresse e-mail',
    invalidEmail: 'Email invalide',
    invalidPhone: 'Phone invalide \n ' +
        '+38 YYY XXX XX XX',
    enterPassword: 'Entrer le mot de passe',
    differentPasswords: 'Non concordance des mots de passe',
    tooLongPassword: 'Mot de passe trop long (max caractères: 20)',
    tooShortPassword: 'Mot de passe trop court (min caractères: 8)',
    passwordChanged: 'Mot de passe changé',
    enterOldPassword: 'Entrez l\'ancien mot de passe',
    enterNewPassword: 'Entrez un nouveau mot de passe',
    samePasswords: 'L\'ancien et le nouveau mot de passe ne peuvent pas être identiques',
    copied: 'Copié',
    copy: 'Copie',

    // profile
    save: 'Sauver',
    confirmation: 'Confirmer',
    saveAs: 'Enregistrer sous',
    cancel: 'Annuler',
    changingEmail: 'Adresse Email de remplacement',
    changeEmail: 'Changer l\'e-mail',
    changingPhone: 'Phone de remplacement',
    changePhone: 'Changer phone',
    changingPassword: 'Changement de mot de passe',
    changePassword: 'Changer le mot de passe',
    confirmCode: 'Сode de confirmation',
    phoneConfirmed: 'Téléphone confirmé',
    oldPassword: 'Ancien mot de passe',
    newPassword: 'Nouveau mot de passe',
    confirmPassword: 'Confirmez le mot de passe',
    connectWithUs: 'Connecte-toi avec nous',
    profile: 'Profil',
    deposit: 'Dépôt',
    withdraw: 'Se désister',
    support: 'Soutien',
    date: 'Date',
    type: 'Type',
    amount: 'Montant',
    status: 'Statut',
    transactionsHistory: 'Historique des transactions',
    exchange: 'échange de devises',
    noData: 'pas de données',
    transactionsBonus: 'Historique des messages',
    message: 'Message',

    currencySelect: 'Devise',
    paymentSelect: 'Procédé de paiement',
    amountDepositSelect: 'Montant du dépôt',
    finishOperation: 'Finalizar operación',

    enterCurrency: 'Sélectionnez le type de devise',
    enterCardNumber: 'Intrer le numéro de carte',
    cardNumber: 'Numéro de carte',
    paymentAccept: 'Confirmation de paiement',
    payoutAmount: 'Retirer le montant',
    paymentType: 'Mode de paiement',
    enterDepositWay: 'Choisissez une méthode de paiement',
    enterDepositAmount: 'Ingrese la cantidad a retirar',
    enterWithdrawalWay: 'Sélectionnez la méthode de retrait',
    enterPayoutAmount: 'Entrez le montant du retrait',
    successfulPayment: 'Votre paiement a bien été ajouté au traitement',
    successOperation: 'Paiement réussi',
    errorOperation: 'Erreur de paiement',
    confirm: 'Confirmer',
    country: 'Pays',
    currency: 'Devise',
    invalidCountry: 'Pays invalide',
    invalidCurrency: 'Devise invalide',
    successfullyChangedTo: 'Remplacé avec succès par',
    lowBalance: 'Pas assez de fonds sur le solde',

    accept: 'J\'accepte',
    decline: 'Déclin',
    currencyExchange: 'Échange de devises',
    currentBalance: 'Solde actuel',
    from: 'de',
    to: 'à',
    exchangePercent: 'Pourcentage d\'échange',
    newBalance: 'Nouvel équilibre',
    coefficient: 'Сoefficient',
    currencyConversion: 'Conversion de devises',
    wrongInputData: 'Mauvaises données d\'entrée',
    wrongAmountData: 'Mauvais montant',
    wrongCreditCard: 'Numéro de carte de crédit incorrect',
    noRedirectReff: 'Il n\'y a aucune référence à la redirection',
    deserializationIssue: 'Problème de désérialisation',
    currencyPairNotExist: 'La paire de devises n\'existe pas',
    authorizationMandatory: 'L\'autorisation est obligatoire',
    birthdayFormat: 'L\'anniversaire doit être JJ MM AAAA',
    tooShort: 'Numéro de carte erroné',
    infoPayment: 'Le traitement du premier paiement en 24 heures est gratuit. Une commission sera facturée pour chaque paiement ultérieur.',
    time: 'Temps',
    period: 'Jusqu\'à 3 jours',
    image: 'L\'image',
    text: 'Texte',

    // status
    balance: 'Solde',
    insurance: 'Assurance',
    credits: 'crédits',
    creditsWon: 'crédits gagnés',
    winnerPaid: 'Gain payé',
    gotNoMoney: 'Pas assez d\'argent',
    minBet: 'Minimum total dépôt',
    gambleUp: 'Doubler ou prendre le gain',
    gambleUp5x: 'Doubler jusqu\'à x5 ou prendre le gain',
    gambleEnd: 'La risque est terminé',
    pressAnyButton: 'Appuyez sur start pour commencer',
    freeGame: 'Jeu gratuit',
    of: 'de',
    freeSpinsRemaining: 'TOURS GRATUITS RESTANTS ',
    freeGames: 'jeux gratuits',
    more: 'additionnelle ',
    freeSpinsLeft: 'TOURS GRATUITS RESTANTS ',
    additionalRoll: 'Respin',
    selectCard: 'Choisir votre carte',
    selectColor: 'Choisir rouge ou noir',
    selectColorOrSuit: 'Choisir la couleur (x2) ou le costume (x4)',
    gameOverPlaceYourBet: 'Veuillez votre pari',
    auto: 'Auto',
    linePays: 'Ligne',
    scatterPays: 'Scatter paie',
    win: 'Gain',
    line: 'Ligne',
    youWon: 'Tu as gagné',
    lowMinBet: 'Min dépôt sur ',
    highMaxBet: 'Max dépôt sur ',
    unSupport: 'Ce jeu n\'est pas pris en charge sur votre appareil',
    urlNotFound: 'Page non trouvée',
    categories: 'Catégories',
    chooseSide: 'Choisissez un côté',
    makeChoice: 'Faire un choix',
    multiplier: 'Multiplicateur',

    // buttons
    lines: 'Lignes',
    bet: 'Dépôt',
    total: 'Total',
    denom: 'Denom',
    gamble: 'Risque',
    paytable: 'L\'information',
    bonus: 'Prime',
    start: 'Départ',
    stop: 'Stop',
    autoplay: 'Autojeu',
    select: 'Choix',
    collect: 'Prendre',
    red: 'Rouge',
    black: 'Noir',
    return: 'Retour',
    half: 'La moitié',
    payout: 'Paiement',
    swipeToStart: 'Tippe, um zu starten',
    tapToStart: 'Appuyez pour commencer',

    // roulette buttons
    repeatAndStart: 'Répéter le dépôt et tournez',
    double: 'Doubler',
    doubleAndSpin: 'Doubler et spin',
    undo: 'Annuler',
    repeatBet: 'Répéter le dépôt',
    removeBets: 'Supprimer les dépôts',
    results: 'Résultats',
    cover: 'Couvrant',

    // gamble
    gambleAmount: 'Montant à doubler',
    gambleToWin: 'Gain au double',
    gambleAmountAmatic: 'Montant à doubler',
    gambleToWinAmatic: 'Rouge/Noir Gain au double',
    suitGambleToWinAmatic: 'Choix du costume',
    previousCards: 'Cartes avant',
    gambleAttemptsLeft: 'Tentatives laissées',
    history: 'L\'histoire',

    // wrap
    selectLine: 'Nombre de lignes',
    selectBet: 'Dépôt sur la ligne',
    selectDenomination: 'Denomination',

    // info
    rules: 'Règles',
    substitutesForAllSymbols: 'Remplace tous les symboles sauf les dispersions',
    combinationOfKind: 'Tous les gains sont pour des combinaisons de symboles pareils. ',
    combinationLeftToRight: 'Tous les gains sont pour combinaisons de symboles de gauche à droite, sauf les symboles dispersés.',
    prizesOnSelectedLines: 'Tous les gains payés pour les lignes misées, sauf les symboles dispersés. ',
    ScatterPayAtAnyPosition: 'Les symboles dispersés sont payés en toute position sur l’écran. ',
    HighestWinPaid: 'Le gain le plus haut par ligne sélectionnée compte. ',
    ScatterWinsAddedToLineWins: 'Les gains réalisés par les symboles dispersés s’ajoutent aux gains sur ligne. ',
    prizesShownInCredits: 'Tous les gains indiqués en crédits. ',
    MalfunctionVoidsAllPays: 'En cas de défaillance tous les paiements et parties sont annulés. ',
    onTheScreen: 'accordent',
    bonusGame: 'JEU DE BONUS',
    bonusGameGive: 'Nombre de jeu gratuits',
    freeSpins: 'est déterminé par la roulette',
    featureBonus: 'Dans les jeux de bonus, le premier tambour est rempli',
    goodLuck: 'Bonne chance!',

    // instruction IOS
    howToInstall: 'comment installer l\'application',
    pressTheButton: 'Cliquez sur le bouton partager',
    goToHomeScreen: 'Appuyez sur ',
    confirmInstallation: 'Confirmer l\'installation',

    // bonuses
    congratulation: 'Félicitation',
    registration_bonus: {
        title: 'Prime pour inscription!',
        description: {
            punkt1: '- Pour activer le bonus, vous devez confirmer votre email et votre numéro de téléphone.',
            punkt2: '- Faites un dépôt sur votre compte principal.',
            punkt3: '- Pour créditer le bonus sur le compte principal, vous devez placer des paris sur la somme 15 fois supérieure à la somme du bonus.'
        },
        accept: 'Your bonus for registration deposited!'
    },

    week_bonus: {
        title: 'Prime hebdomadaire!',
        description: 'Prime hebdomadaire',
        accept: 'Votre bonus hebdomadaire déposé!'
    }
};
