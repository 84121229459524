import {JL} from 'jsnlog';

import Lines from './../../egt/lines40';

export default class Lines40 extends Lines {
    constructor() {
        super();
        JL().debug('-- Lines40 initialized');
    }

    getBoxesStyle = () => ({
        activeBoxStyle: {
            fill: '#e9eb6f',
            fontSize: 12,
            stroke: '#bf0606',
            strokeThickness: 5,
            lineJoin: 'round'
        },
        inactiveBoxStyle: {
            dropShadowDistance: 1,
            fill: '310c01',
            fontSize: 19,
            stroke: '#b98244',
            strokeThickness: 5,
            lineJoin: 'round'
        },
        colLines: [1, 10, 20, 30, 40],
        coordinates: {
            0: {x: 26, y: 408},
            1: {x: 775, y: 408}
        }
    })
}
