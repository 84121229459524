import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import './styles.less';
import App from './../../../index';
import GameDeluxe from './../../deluxe/game';
import Lines from './lines';
import Gamble from './gamble';
import InfoScreen from '../../infoScreen';
import choosingFont from './img/font/choosingFont';
import langs from './langs.js';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    AnimatedSprite = PIXI.AnimatedSprite,
    Texture = PIXI.Texture,
    Container = PIXI.Container,
    Graphics = PIXI.Graphics,
    Text = PIXI.Text;

export default class WitchHunters extends GameDeluxe {
    constructor() {
        super();
        this.id = 'witch-hunters';
        this.name = 'Witch Hunters';
        this.scatter = 11;
        this.buttonsPanelShadow = 'low';
        this.gameFieldWidth = 960;
        this.gameWidth = App.System.resolution === '4x3' ? this.gameFieldWidth : 1280;
        this.gameFieldHeight = 720;
        this.gameHeight = App.configs.doubleScreen ? 1440 : 720;
        this.BigWin.enabled = true;
        this.allowLongRoll = true;
        this.reelFilter = [[], [11], [], [11], []];

        this.reelTop = 93;
        this.symbolWidth = 160;
        this.symbolHeight = 160;
        this.reelXCoordinates = [80, 240, 400, 560, 720];
        this.reelSettings = [14, 5, 30]; // 0 - start symbol amount, 1 - reel increase regular roll, 2 - reel increase long roll

        this.offsetReelMask = {
            offsetX: 18, offsetY: 2,
            offsetWidth: 0, offsetHeight: 0
        };
        this.choosingAnimations = {}; // collection of ticker functions

        this.containersLayers = {
            reelsStage: 1,
            mainContainer: 0,
            linesContainer: 2,
            boxesContainer: 4,
            extraBetContainer: 5,
            infoContainer: 6,
            bonusContainer: 7,
            symbolInfo: 8
        };
        this.SymbolInfo.enabled = true;
        this.SymbolInfo.settings.symbolBorderOffset = {x: 20, y: 20};
        this.SymbolInfo.settings.paymentBorderOffset = {left: 60, right: 75};

        this.symbols = [
            {regularDelay: 60, payment: [0, 0, 0, 5, 20, 100]},   // 0 - J
            {regularDelay: 60, payment: [0, 0, 0, 5, 20, 100]},   // 1 - Q
            {regularDelay: 60, payment: [0, 0, 0, 5, 20, 100]},   // 2 - K
            {regularDelay: 60, payment: [0, 0, 0, 5, 20, 100]},   // 3 - A
            {regularDelay: 60, payment: [0, 0, 0, 10, 50, 200]},  // 4 - cat
            {regularDelay: 60, payment: [0, 0, 0, 10, 50, 200]},  // 5 - medallion
            {regularDelay: 60, payment: [0, 0, 0, 20, 100, 250]}, // 6 - hunter 1
            {regularDelay: 60, payment: [0, 0, 0, 20, 100, 250]}, // 7 - witch 1
            {regularDelay: 60, payment: [0, 0, 0, 50, 200, 400]}, // 8 - hunter 2
            {regularDelay: 60, payment: [0, 0, 0, 50, 200, 400]}, // 9 - witch 2
            {regularDelay: 60, payment: [0, 0, 0, 0, 0, 0]},      // 10 - book (wild)
            {regularDelay: 60, payment: [0, 0, 0, 2, 0, 0]}       // 11 - vat
        ];
        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.jpg',
                areaSprite: 'area/area-sprite.png',
                logoSprite: 'area/logo-sprite.png',
                smoke: 'area/smoke.png',
                lightning: 'area/lightning.png',
                magic: 'area/magic.png',
                magicLight: 'area/magicLight.png',
                raven: 'area/raven.png',
                background: 'area/background.png'
            }),
            atlas: this.mergePath(['staticSymbols.json']),
            fonts: this.mergePath({WitchFont: 'font/font.ttf'})
        };
        this.additionalResources = {
            main: this.mergePath({
                longRollEffect: 'bonus/long-roll.png',
                longRollBoom: 'bonus/long-roll-boom.png',

                // side choosing
                heroes: 'bonus/heroes.png',
                heroesHover: 'bonus/heroes-hover.png',
                hunterFlag: 'bonus/flag-hunter.png',
                witchFlag: 'bonus/flag-witch.png',
                flagTitles: 'bonus/flag-titles.png',

                // hunter choosing
                hunterArea: 'bonus/choosing-hunter.jpg',
                boxesHunter: 'bonus/boxes-hunter.png',
                choiceLight: 'bonus/choice-light.png',
                fireEffect: 'bonus/fire-effect.png',
                fireLight: 'bonus/fire-light.png',
                windows: 'bonus/windows.png',
                weapons: 'bonus/weapons.png',
                weaponsWin: 'bonus/weapons-win.png',
                hunterFont: 'font/font-hunter.png',

                // witch choosing
                witchArea: 'bonus/choosing-witch.jpg',
                witchAreaFront: 'bonus/choosing-witch-front.png',
                witchAreaShadow: 'bonus/choosing-witch-shadow.png',
                boxesWitch: 'bonus/boxes-witch.png',
                watActive: 'bonus/wat-active.png',
                watStat: 'bonus/wat-stat.png',
                elixirs: 'bonus/elixirs.png',
                catActive: 'bonus/cat-active.png',
                catStat: 'bonus/cat-stat.png',
                catLight: 'bonus/cat-light.png',
                craftEffect: 'bonus/craft-effect.png',
                witchFont: 'font/font-witch.png',

                symbolBorder: 'area/symbol-border.png',
                paymentBorder: 'area/payment-border.png'
            }),
            atlas: this.mergePath([
                'regularShortSymbols.json',
                'scatterSymbols.json'
            ])
        };

        this.gameSounds = {soundClass: 'prefergames'};

        this.Lines = new Lines();
        this.Gamble = new Gamble(this.mergePath({
            activeBlack: 'gamble/black-active.png',
            inactiveBlack: 'gamble/black-inactive.png',
            activeRed: 'gamble/red-active.png',
            inactiveRed: 'gamble/red-inactive.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamonds.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));

        this.InfoScreen = new InfoScreen({pages: 5}); // number of game info states
        this.InfoScreen.format = 'png';
        this.InfoScreen.getResources = () => this.mergePath({
            info1: 'area/info1-screen.png',
            infoSprite: 'area/info-sprite.png'
        });
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     * @param lang
     */
    drawInfoPage(ctx, page, nLines, bet, lang) {
        ctx.font = '20pt WitchFont';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#00ff12';
        ctx.shadowColor = '00ff12';
        ctx.shadowOffsetX = ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 5;

        const {
            rules, substitutesForAllSymbols,
            combinationOfKind, combinationLeftToRight, prizesOnSelectedLines,
            HighestWinPaid, ScatterWinsAddedToLineWins, prizesShownInCredits,
            MalfunctionVoidsAllPays
        } = App.languageCollection[lang];
        const {
            scattersTrigger, heroesChoice, huntersChoosing, makeWeapon, witchChoosing, makeElixir
        } = langs[lang];

        switch (page) {
            case 1:
                ctx.font = '16pt WitchFont';
                ctx.textAlign = 'left';

                // J Q K A
                ctx.fillText(`5x ${bet * this.symbols[0].payment[5]}`, 210, 410);
                ctx.fillText(`4x ${bet * this.symbols[0].payment[4]}`, 210, 410 + 25);
                ctx.fillText(`3x ${bet * this.symbols[0].payment[3]}`, 210, 410 + 50);

                // cat medallion
                ctx.fillText(`5x ${bet * this.symbols[4].payment[5]}`, 640, 410);
                ctx.fillText(`4x ${bet * this.symbols[4].payment[4]}`, 640, 410 + 25);
                ctx.fillText(`3x ${bet * this.symbols[4].payment[3]}`, 640, 410 + 50);

                // hunter 1 witch 1
                ctx.fillText(`5x ${bet * this.symbols[6].payment[5]}`, 600, 190);
                ctx.fillText(`4x ${bet * this.symbols[6].payment[4]}`, 600, 190 + 25);
                ctx.fillText(`3x ${bet * this.symbols[6].payment[3]}`, 600, 190 + 50);

                // hunter 2 witch 2
                ctx.fillText(`5x ${bet * this.symbols[8].payment[5]}`, 250, 190);
                ctx.fillText(`4x ${bet * this.symbols[8].payment[4]}`, 250, 190 + 25);
                ctx.fillText(`3x ${bet * this.symbols[8].payment[3]}`, 250, 190 + 50);

                ctx.textAlign = 'center';

                // vat
                ctx.fillText(`3x ${bet * nLines * this.symbols[11].payment[3]}`, 480, 300);

                // wild
                ctx.fillText('WILD', 480, 465);
                ctx.font = '12pt WitchFont';
                this.drawSplitText(ctx, substitutesForAllSymbols, 480, 483, 200, {lineHeight: 15});
                break;
            case 2:
                ctx.drawImage(
                    this.getAdditionalImage('infoSprite'),
                    0, 0, 189, 185,
                    130, 90, 189, 185
                );
                this.drawSplitText(ctx, scattersTrigger, 580, 200, 550, {lineHeight: 40});
                ctx.drawImage(
                    this.getAdditionalImage('infoSprite'),
                    0, 185, 427, 285,
                    150, 250, 427, 285
                );
                this.drawSplitText(ctx, heroesChoice, 680, 360, 250, {lineHeight: 40});
                break;
            case 3:
                ctx.font = '32pt WitchFont';
                ctx.fillText(huntersChoosing, 480, 160);
                ctx.drawImage(
                    this.getAdditionalImage('infoSprite'),
                    0, 848, 435, 350,
                    100, 180, 435, 350
                );

                ctx.font = '20pt WitchFont';
                this.drawSplitText(ctx, makeWeapon, 680, 300, 290, {lineHeight: 40});
                break;
            case 4:
                ctx.font = '32pt WitchFont';
                ctx.fillText(witchChoosing, 480, 160);
                ctx.drawImage(
                    this.getAdditionalImage('infoSprite'),
                    0, 498, 433, 350,
                    100, 180, 433, 350
                );

                ctx.font = '20pt WitchFont';
                this.drawSplitText(ctx, makeElixir, 680, 210, 300, {lineHeight: 35});
                ctx.drawImage(
                    this.getAdditionalImage('infoSprite'),
                    189, 0, 250, 213,
                    560, 380, 250, 213
                );
                break;
            case 5:
                ctx.font = '32pt WitchFont';
                ctx.fillText(rules, 480, 160);

                ctx.font = '20pt WitchFont';
                const rulesText = combinationOfKind +
                    combinationLeftToRight +
                    prizesOnSelectedLines +
                    HighestWinPaid +
                    ScatterWinsAddedToLineWins +
                    prizesShownInCredits +
                    MalfunctionVoidsAllPays;
                this.drawSplitText(ctx, rulesText, 480, 230, 700, {lineHeight: 35});
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    /**
     * Create additional sprites and animations for stage
     * Call once when game loaded
     * @param parentContainer
     */
    createAdditionalSprites(parentContainer) {
        const mainContainer = parentContainer.getChildByName('mainContainer');
        this.getStageChild('reelsStage').mask = this.getReelsMask();

        this.createMainAreaSprites(mainContainer);
        this.createBonusAreaSprites(parentContainer.getChildByName('bonusContainer'));

        // update params for scaling animation
        const mainArea = mainContainer.getChildByName('mainArea');
        mainArea.anchor.x = 0.5;
        mainArea.position.x = 480;
    }

    /**
     * Create additional sprites and animations for main area
     * @param parentContainer
     */
    createMainAreaSprites(parentContainer) {
        const ticker = this.app.ticker;
        parentContainer.sortableChildren = true;
        const animationStarted = Date.now();

        const sky = this.getAreaSprite({x: 551, y: 622, width: 920, height: 597});
        sky.position.set(232, 0);
        sky.name = 'sky';
        parentContainer.addChild(sky);

        const lightning = new Sprite(this.getTexture('lightning'));
        lightning.position.set(551, 107);
        lightning.name = 'lightning';
        sky.addChild(lightning);
        ticker.add(() => lightning.alpha = 100 * Math.sin((Date.now() - animationStarted) * 0.001) - 99);

        const house = this.getAreaSprite({x: 1216, y: 151, width: 527, height: 471});
        house.position.set(625, 249);
        house.name = 'house';
        parentContainer.addChild(house);

        const houseLight = this.getAreaSprite({x: 0, y: 151, width: 261, height: 247});
        houseLight.position.set(255, 136);
        houseLight.name = 'houseLight';
        house.addChild(houseLight);
        ticker.add(() => houseLight.alpha = 0.5 * Math.sin((Date.now() - animationStarted) * 0.001) + 0.5);

        const raven = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 17, image: 'raven',
            width: 106, height: 93
        }));
        raven.position.set(265, 52);
        raven.name = 'raven';
        raven.scale.set(0.5);
        raven.loop = false;
        raven.animationSpeed = 0.2;
        raven.play();
        house.addChild(raven);
        this.tickerInterval(() => raven.gotoAndPlay(0), 10000);

        const huntersContainer = new Container();
        huntersContainer.position.set(-134, 350);
        huntersContainer.name = 'huntersContainer';
        parentContainer.addChild(huntersContainer);

        const tree2 = this.getAreaSprite({x: 261, y: 151, width: 552, height: 294});
        tree2.position.set(71, 193);
        tree2.pivot.set(47, 263);
        tree2.name = 'tree2';
        tree2.roundPixels = false;
        huntersContainer.addChild(tree2);
        ticker.add(() => tree2.angle = Math.cos((Date.now() - animationStarted) * 0.001));

        const tree1 = this.getAreaSprite({x: 0, y: 622, width: 551, height: 597});
        tree1.position.set(0, 205);
        tree1.pivot.set(26, 540);
        tree1.name = 'tree1';
        tree1.roundPixels = false;
        huntersContainer.addChild(tree1);
        ticker.add(() => tree1.angle = 0.5 * Math.sin((Date.now() - animationStarted) * 0.001) - 1);

        const target = this.getAreaSprite({x: 0, y: 0, width: 67, height: 83});
        target.position.set(198, 315);
        target.pivot.set(34, 5);
        target.name = 'tree1';
        target.roundPixels = false;
        tree1.addChild(target);
        ticker.add(() => target.angle = 4 * Math.cos((Date.now() - animationStarted) * 0.001));

        const cart = this.getAreaSprite({x: 814, y: 151, width: 402, height: 370});
        cart.position.set(-26, 0);
        cart.name = 'cart';
        huntersContainer.addChild(cart);

        const createSmoke = () => {
            const smokeHunter = new Sprite(this.getTexture('smoke'));
            smokeHunter.scale.set(0.4);
            smokeHunter.position.set(-600, 560);
            smokeHunter.name = 'smokeHunter';
            smokeHunter.roundPixels = false;
            parentContainer.addChild(smokeHunter);
            const params = {
                duration: 10000,
                animations: [
                    {sprite: smokeHunter, timeline: [{from: {alpha: 0}, to: {alpha: 1}, duration: {to: 1000}}]},
                    {sprite: smokeHunter, timeline: [{from: {x: -600}, to: {x: -500}}]},
                    {sprite: smokeHunter, timeline: [{from: {alpha: 1}, to: {alpha: 0}, duration: {from: 9000}}]}
                ],
                onComplete: () => true
            };
            this.showAnimation(params);

            const smokeWitch = new Sprite(this.getTexture('smoke'));
            smokeWitch.scale.set(-0.4, 0.4);
            smokeWitch.tint = 0x09ff07; // green tint
            smokeWitch.alpha = 0.7;
            smokeWitch.position.set(1620, 560);
            smokeWitch.name = 'smokeWitch';
            smokeWitch.roundPixels = false;
            parentContainer.addChild(smokeWitch);

            this.showAnimation({
                ...params,
                animations: [
                    {sprite: smokeWitch, timeline: [{from: {alpha: 0}, to: {alpha: 1}, duration: {to: 1000}}]},
                    {sprite: smokeWitch, timeline: [{from: {x: 1620}, to: {x: 1520}}]},
                    {sprite: smokeWitch, timeline: [{from: {alpha: 1}, to: {alpha: 0}, duration: {from: 9000}}]}
                ]
            });
        };

        // double smoke
        createSmoke();
        this.tickerTimeout(createSmoke, 5000);

        const background = new Sprite(this.getTexture('background'));
        background.position.set(43, 85);
        background.name = 'background';
        background.mask = this.getReelsMask('backgroundMask');
        parentContainer.addChild(background);
    }

    createBonusAreaSprites(parentContainer) {
        if (App.restoreGameState === 'NONE') {
            const loader = new Graphics()
                .beginFill(0x000000)
                .drawRect(0, App.configs.doubleScreen ? -this.gameFieldHeight : 0, this.gameWidth, this.gameHeight)
                .endFill();
            loader.name = 'loader';
            loader.position.set(-(this.gameWidth - this.gameFieldWidth) / 2, 0);
            parentContainer.addChild(loader);
        }

        const borderTop = this.getAreaSprite({x: 907, y: 0, width: 915, height: 151});
        borderTop.anchor.set(0.5);
        borderTop.position.set(this.gameFieldWidth / 2 + 0.5, 100.5);
        borderTop.name = 'borderTop';
        parentContainer.addChild(borderTop);

        const magic = this.createMagic(borderTop);
        magic.position.set(-3.5, -27.5);

        const borderBottom = this.getAreaSprite({x: 67, y: 0, width: 840, height: 149});
        borderBottom.anchor.set(0.5);
        borderBottom.position.set(this.gameFieldWidth / 2 - 3, 570);
        borderBottom.name = 'borderBottom';
        parentContainer.addChild(borderBottom);

        const logo1 = this.getAreaSprite({x: 0, y: 0, width: 1012, height: 376}, 'logoSprite');
        logo1.position.set(205, -15);
        logo1.scale.set(0.3);
        logo1.name = 'logo1';
        parentContainer.addChild(logo1);

        const logo2 = this.getAreaSprite({x: 0, y: 376, width: 1345, height: 368}, 'logoSprite');
        logo2.position.set(415, -15);
        logo2.scale.set(0.3);
        logo2.name = 'logo2';
        parentContainer.addChild(logo2);
    }

    getAreaSprite = (options, image = 'areaSprite') =>
        new Sprite(new Texture(this.getTexture(image), options));

    /**
     * Start game animations
     */
    showGameIntroduction() {
        JL().debug('-- Start game intro animation');
        this.setState('GAME_INTRO');
        this.Buttons.disableAllButtons();
        App.updateButton('autoStart', {disabled: true});
        App.updateState('buttons', {animation: 'hide'});
        const mainContainer = this.getStageChild('mainContainer');
        const bonusContainer = this.getStageChild('bonusContainer');
        const reelsContainer = this.getStageChild('reelsStage');
        reelsContainer.position.y = this.reelTop + this.symbolHeight * this.reelRows + 50;

        const loader = bonusContainer.getChildByName('loader');
        const borderTop = bonusContainer.getChildByName('borderTop');
        borderTop.alpha = 0;
        const borderBottom = bonusContainer.getChildByName('borderBottom');
        borderBottom.alpha = 0;
        const logo1 = bonusContainer.getChildByName('logo1');
        logo1.position.set(70, 190);
        logo1.scale.set(0.5);
        const logo2 = bonusContainer.getChildByName('logo2');
        logo2.position.set(280, 300);
        logo2.scale.set(0.5);
        const background = mainContainer.getChildByName('background');
        background.position.y = this.symbolHeight * this.reelRows;
        background.alpha = 0;

        // default duration for most animations
        const duration = {from: 1000, to: 3000};
        // duration for reels show
        const duration2 = {from: 3000, to: 4000};

        const params = {
            duration: 4000,
            animations: [
                {
                    sprite: loader,
                    timeline: [{to: {alpha: 0}, duration: {from: 1000, to: 2000}}]
                }, {
                    sprite: mainContainer.getChildByName('mainArea'),
                    timeline: [{from: {y: -360, scaleX: 1.5, scaleY: 1.5}, duration}]
                }, {
                    sprite: mainContainer.getChildByName('huntersContainer'),
                    timeline: [{from: {x: -734, y: 500}, duration}]
                }, {
                    sprite: mainContainer.getChildByName('house'),
                    timeline: [{from: {x: 1225, y: 399}, duration}]
                }, {
                    sprite: mainContainer.getChildByName('sky'),
                    timeline: [{from: {x: 1000}, duration}]
                }, {
                    sprite: background,
                    timeline: [{to: {y: 85, alpha: 1}, duration: duration2}]
                }, {
                    sprite: reelsContainer,
                    timeline: [{to: {y: this.reelTop}, duration: duration2}]
                }, {
                    sprite: borderTop,
                    timeline: [{from: {y: 500.5}, to: {alpha: 1}, duration: duration2}]
                }, {
                    sprite: borderBottom,
                    timeline: [{to: {alpha: 1}, duration: duration2}]
                }, {
                    sprite: logo1,
                    timeline: [{to: {x: 205, y: -15, scaleX: 0.3, scaleY: 0.3}, duration}]
                }, {
                    sprite: logo2,
                    timeline: [{to: {x: 415, y: -15, scaleX: 0.3, scaleY: 0.3}, duration}]
                }
            ],
            onComplete: () => {
                loader.destroy();
                App.updateState('buttons', {animation: 'show'});
                this.roundFinished();
            }
        };

        this.tickerTimeout(() => this.showAnimation(params), 700);
    }

    /**
     * Start scatter animations
     * @param features
     */
    bonusEntrance(features) {
        this.playBonusGameSound();
        this.stopAnimateFeature();
        App.updateButton('start', {disabled: true});
        App.updateButton('autoStart', {disabled: true});

        const scatterFeature = features.find(({uc}) => uc === 'SCATTER');
        this.playScatterAnimation(scatterFeature, () => // call after scatter played
            this.showChoosingIntroduction());
    }

    getFeatureDelay(currentFeature, features) {
        let delay = 0;

        switch (currentFeature.uc) {
            case 'WIN_LINE':
                delay = this.winLineFeatureDelay;
                break;
            case 'SCATTER':
                delay =
                    features.some(({uc}) => uc === 'CHOOSING') &&
                    !features.some(({uc}) => uc === 'WIN_LINE') ?
                        0 : this.winLineFeatureDelay;
                break;
        }

        return delay;
    }

    showChoosingIntroduction() {
        JL().debug('-- Start choosing side intro animation');
        this.setState('CHOOSING_SIDE_INTRO');
        this.Legends.setText('win', {text: 'win', value: this.latestResponse.payment});
        this.Legends.setStatus('chooseSide');
        this.Legends.clearText('features');
        this.stopAnimateFeature();

        const parentContainer = this.getStage();
        const mainContainer = parentContainer.getChildByName('mainContainer');
        const bonusContainer = parentContainer.getChildByName('bonusContainer');
        const reelsContainer = parentContainer.getChildByName('reelsStage');
        const heroesContainer = this.createHeroes(parentContainer);

        // default duration for most animations
        const duration = {to: 1000};

        const params = {
            duration: 1700,
            animations: [
                {
                    sprite: mainContainer.getChildByName('mainArea'),
                    timeline: [{to: {y: -360, scaleX: 1.5, scaleY: 1.5}, duration}]
                }, {
                    sprite: mainContainer.getChildByName('huntersContainer'),
                    timeline: [{to: {x: -734, y: 500}, duration}]
                }, {
                    sprite: mainContainer.getChildByName('house'),
                    timeline: [{to: {x: 1225, y: 399}, duration}]
                }, {
                    sprite: mainContainer.getChildByName('sky'),
                    timeline: [{to: {x: 1000}, duration}]
                }, {
                    sprite: mainContainer.getChildByName('background'),
                    timeline: [{to: {y: this.reelTop + this.symbolHeight * this.reelRows + 5}, duration}]
                }, {
                    sprite: reelsContainer,
                    timeline: [{to: {y: this.reelTop + this.symbolHeight * this.reelRows + 5}, duration}]
                },
                {sprite: bonusContainer.getChildByName('logo1'), timeline: [{to: {y: -200}, duration}]},
                {sprite: bonusContainer.getChildByName('logo2'), timeline: [{to: {y: -200}, duration}]},
                {sprite: heroesContainer, timeline: [{to: {y: 170}, duration: {from: 1000}}]}
            ],
            onComplete: () => this.startHeroesChoosing(heroesContainer)
        };

        this.showAnimation(params);
    }

    createHeroes(parentContainer) {
        const height = 403;

        const heroesContainer = new Container();
        heroesContainer.position.set(100, 600);
        heroesContainer.name = 'heroesContainer';
        heroesContainer.mask = this.getReelsMask('heroesMask');
        heroesContainer.zIndex = 5; // show under bonus container
        parentContainer.addChild(heroesContainer);

        const hunterFlag = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 19, image: 'hunterFlag',
            width: 320, height: 269, colCount: 3
        }));
        hunterFlag.visible = false;
        hunterFlag.loop = false;
        hunterFlag.position.set(-60, -77);
        hunterFlag.name = 'hunterFlag';
        hunterFlag.animationSpeed = 0.3;
        heroesContainer.addChild(hunterFlag);
        const hunterTitle = new Sprite(new Texture(this.getTexture('flagTitles'), {
            x: 0, y: 0, width: 178, height: 61
        }));
        hunterTitle.name = 'hunterTitle';
        hunterTitle.anchor.set(0.5);
        hunterTitle.scale.set(1.2);
        hunterTitle.position.set(169, 51);
        hunterFlag.addChild(hunterTitle);

        const witchFlag = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 19, image: 'witchFlag',
            width: 320, height: 269, colCount: 3
        }));
        witchFlag.visible = false;
        witchFlag.loop = false;
        witchFlag.position.set(500, -77);
        witchFlag.name = 'witchFlag';
        witchFlag.animationSpeed = 0.3;
        heroesContainer.addChild(witchFlag);
        const witchTitle = new Sprite(new Texture(this.getTexture('flagTitles'), {
            x: 178, y: 0, width: 178, height: 61
        }));
        witchTitle.name = 'witchTitle';
        witchTitle.anchor.set(0.5);
        witchTitle.scale.set(1.2);
        witchTitle.position.set(159, 51);
        witchFlag.addChild(witchTitle);

        const hunter = new Sprite(new Texture(this.getTexture('heroes'), {
            x: 0, y: 0, width: 320, height
        }));
        hunter.position.set(160, height);
        hunter.name = 'hunter';
        hunter.interactive = false;
        hunter.buttonMode = true;
        hunter.anchor.set(0.5, 1);
        hunter.on('pointerdown', () => {
            hunterHover.alpha = 1;
            this.showHeroChoiceAnimation(hunter, 1);
        });
        hunter.on('pointerover', () => {
            hunterTitle.scale.set(1.3);
            hunterHover.alpha = 1;
        });
        hunter.on('pointerout', () => {
            hunterTitle.scale.set(1.2);
            hunterHover.alpha = 0;
        });
        heroesContainer.addChild(hunter);
        const hunterHover = new Sprite(new Texture(this.getTexture('heroesHover'), {
            x: 0, y: 0, width: 350, height: 408
        }));
        hunterHover.alpha = 0;
        hunterHover.anchor.set(0.5, 1);
        hunter.addChild(hunterHover);

        const witch = new Sprite(new Texture(this.getTexture('heroes'), {
            x: 320, y: 0, width: 390, height
        }));
        witch.position.set(615, height);
        witch.name = 'witch';
        witch.interactive = false;
        witch.buttonMode = true;
        witch.anchor.set(0.5, 1);
        witch.on('pointerdown', () => {
            witchHover.alpha = 1;
            this.showHeroChoiceAnimation(witch, 2);
        });
        witch.on('pointerover', () => {
            witchTitle.scale.set(1.3);
            witchHover.alpha = 1;
        });
        witch.on('pointerout', () => {
            witchTitle.scale.set(1.2);
            witchHover.alpha = 0;
        });
        heroesContainer.addChild(witch);
        const witchHover = new Sprite(new Texture(this.getTexture('heroesHover'), {
            x: 350, y: 0, width: 376, height: 408
        }));
        witchHover.position.x = 10;
        witchHover.alpha = 0;
        witchHover.anchor.set(0.5, 1);
        witch.addChild(witchHover);

        return heroesContainer;
    }

    startHeroesChoosing(parentContainer) {
        JL().debug('-- Heroes choosing');
        this.setState('CHOOSING_SIDE');

        const hunterFlag = parentContainer.getChildByName('hunterFlag');
        hunterFlag.visible = true;
        hunterFlag.play();
        const witchFlag = parentContainer.getChildByName('witchFlag');
        witchFlag.visible = true;
        witchFlag.play();
        witchFlag.onComplete = () =>
            parentContainer.children.forEach(child => child.interactive = true);
    }

    showHeroChoiceAnimation(sprite, choice) {
        const {name, parent} = sprite;
        JL().debug(`-- Choose: ${name}`);
        this.setState('CHOOSING_INTRO');
        this.Legends.clearStatus();
        this.showMagicBoom();
        sprite.children[0].alpha = 1; // enable hover effect
        parent.children.forEach(child => child.interactive = false);
        const unselected = parent.getChildByName(name === 'hunter' ? 'witch' : 'hunter');

        const onFadeIn = () => {
            parent.destroy(); // destroy heroesContainer
            this.getStageChild('heroesMask').destroy();
            App.updateState('buttons', {visualization: 'choosing'});
            this.sendMakeChoosing(choice);
        };

        const params = {
            duration: 1000,
            animations: [
                {sprite, timeline: [{to: {x: 400, scaleX: 1.1, scaleY: 1.1}}]},
                {sprite: unselected, timeline: [{to: {y: unselected.height * 2}, duration: {to: 500}}]}
            ],
            onComplete: () => {
                App.resetLoader();
                App.updateState('loader', {
                    active: true,
                    fade: 'in', fadeEndCallback: onFadeIn
                });
            }
        };

        this.showAnimation(params);
    }

    /**
     * Фукнция отображения выбора Чузинга на ответ сервера
     */
    showChoice(response) {
        JL().debug(`-- Show choice: ${JSON.stringify(response)}`);
        const {extension, prevChoosings} = response;

        prevChoosings.length ?
            extension.type === 'WEAPONS' ?
                this.showWeaponChoice(response) :
                this.showElixirChoice(response) :
            this.createChoosingScene(response);
    }

    restoreChoosingScreen(response) {
        JL().debug(`-- Restore choosing: ${JSON.stringify(response)}`);
        this.setState('CHOOSING_IDLE');
        this.Legends.clearStatus();
        this.Legends.showWinFeatures();
        this.Buttons.disableAllButtons();
        App.updateButton('autoStart', {disabled: true});
        App.updateState('buttons', {visualization: 'choosing'});

        response.stop ?
            this.finishChoosing(response) :
            this.createChoosingScene(response);
    }

    createChoosingScene(response) {
        const {win, extension} = response;
        this.setState('CHOOSING_IDLE');
        this.Legends.setText('win', {text: 'win', value: win});
        this.Legends.setStatus('makeChoice');
        App.updateState('loader', {fade: 'out', fadeEndCallback: () => App.updateState('loader', {active: false})});
        const name = extension.type === 'WEAPONS' ? 'hunter' : 'witch';

        const parentContainer = this.getStage();
        const mainContainer = parentContainer.getChildByName('mainContainer');
        const bonusContainer = parentContainer.getChildByName('bonusContainer');
        const reelsContainer = parentContainer.getChildByName('reelsStage');
        mainContainer.visible = false;
        bonusContainer.visible = false;
        reelsContainer.position.y = this.reelTop + this.symbolHeight * this.reelRows + 5;

        const container = new Container();
        container.name = 'choosingContainer';
        container.sortableChildren = true;
        parentContainer.addChild(container);

        const sprite = new Sprite(this.getTexture(`${name}Area`));
        sprite.name = `${name}Area`;
        sprite.position.set(-160, 0);
        container.addChild(sprite);

        // 'WEAPONS' / 'FLASK'
        extension.type === 'WEAPONS' ?
            this.createHunterChoosingScene(container, response) :
            this.createWitchChoosingScene(container, response);
    }

    createHunterChoosingScene(parentContainer, response) {
        const {choice, prevChoosings, step} = response;
        const ticker = this.app.ticker;
        const animationStarted = Date.now();

        this.createFire(parentContainer, -88, 432, 'fire1');
        this.createFire(parentContainer, 55, 55, 'fire2', 0.5);
        this.createFire(parentContainer, 91, 58, 'fire3', 0.5, 10);
        this.createFire(parentContainer, 126, 61, 'fire4', 0.5, 20);
        this.createFire(parentContainer, 336, 203, 'fire5', 0.3);
        this.createFire(parentContainer, 388, 203, 'fire6', 0.3, 10);
        this.createFire(parentContainer, 617, 113, 'fire7', 0.5);
        this.createFire(parentContainer, 690, 119, 'fire8', 0.5, 10);
        this.createFire(parentContainer, 888, 211, 'fire9', 0.3);
        this.createFire(parentContainer, 904, 211, 'fire10', 0.3, 10);
        this.createFire(parentContainer, 919, 211, 'fire11', 0.3, 20);

        let windowIndex = 0;
        const createWindow = (x, width, height, pos, delay = 0) => {
            const sprite = new Sprite(new Texture(this.getTexture('windows'), {
                x, y: 0, width, height
            }));
            sprite.name = `window${windowIndex}`;
            sprite.position.set(pos.x, pos.y);
            parentContainer.addChild(sprite);
            ticker.add(this.choosingAnimations[name] = () =>
                sprite.alpha = 0.5 * Math.sin((Date.now() - animationStarted + delay) * 0.001) + 0.5);
            windowIndex++;
        };
        createWindow(0, 166, 213, {x: 140, y: 238});
        createWindow(166, 170, 202, {x: 407, y: 248}, 1000);
        createWindow(336, 133, 209, {x: 686, y: 247}, 2000);

        const positions = [ // create weapons
            {y: 0, width: 299, height: 425, pos: {x: -12, y: 185}, boxPos: {x: 95, y: 235}},
            {y: 425, width: 248, height: 280, pos: {x: 243, y: 270}, boxPos: {x: 360, y: 305}},
            {y: 705, width: 244, height: 243, pos: {x: 525, y: 215}, boxPos: {x: 655, y: 265}},
            {y: 948, width: 292, height: 221, pos: {x: 502, y: 418}, boxPos: {x: 655, y: 440}},
            {y: 1169, width: 169, height: 209, pos: {x: 800, y: 246}, boxPos: {x: 885, y: 285}}
        ];

        // check active elixirs
        const weapons = [];
        positions.forEach((item, index) => {
            !(prevChoosings.some(prev => prev.choice === index) || choice === index && step !== 0) &&
            weapons.push({...item, index});
        });

        weapons.forEach(({y, width, height, pos, boxPos, index}) => {
            const sprite = new Sprite(new Texture(this.getTexture('weapons'), {
                x: width, y, width, height
            }));
            sprite.name = `choice${index}`;
            sprite.position.set(pos.x, pos.y);
            sprite.interactive = true;
            sprite.buttonMode = true;
            sprite.on('pointerover', () => {
                boxLight.alpha = 1;
                light.alpha = 0.5;
            });
            sprite.on('pointerout', () => {
                boxLight.alpha = 0;
                light.alpha = 0;
            });
            sprite.on('pointerdown', () => this.sendMakeChoosing(index));
            parentContainer.addChild(sprite);

            const light = new Sprite(new Texture(this.getTexture('weapons'), {
                x: 0, y, width, height
            }));
            light.name = 'light';
            light.alpha = 0;
            sprite.addChild(light);

            // create box
            const box = new Sprite(new Texture(this.getTexture('boxesHunter'), {
                x: (index * 2 + 1) * 70, y: 0, width: 70, height: 70
            }));
            box.name = `box${index}`;
            box.anchor.set(0.5);
            box.position.set(boxPos.x, boxPos.y);
            parentContainer.addChild(box);

            const boxLightNumber = new Sprite(new Texture(this.getTexture('boxesHunter'), {
                x: (index * 2) * 70, y: 0, width: 70, height: 70
            }));
            boxLightNumber.anchor.set(0.5);
            ticker.add(this.choosingAnimations[`boxLightNumber${index}`] = () =>
                boxLightNumber.alpha = 0.5 * Math.sin((Date.now() - animationStarted) * 0.002) + 0.5);
            box.addChild(boxLightNumber);

            const boxLight = new Sprite(new Texture(this.getTexture('boxesHunter'), {
                x: 0, y: 70, width: 80, height: 80
            }));
            boxLight.name = 'light';
            boxLight.alpha = 0;
            boxLight.scale.set(0.9);
            boxLight.anchor.set(0.5);
            boxLight.position.set(-4, 0);
            box.addChild(boxLight);
        });
    }

    createWitchChoosingScene(parentContainer, response) {
        const {choice, prevChoosings, step} = response;
        const ticker = this.app.ticker;
        const animationStarted = Date.now();

        const positions = [
            {x: 300, y: 185, numberPos: {x: 0, y: 34}},
            {x: 475, y: 265, numberPos: {x: 37, y: 42}},
            {x: 475, y: 465, numberPos: {x: 12, y: 61}},
            {x: 640, y: 360, numberPos: {x: 49, y: 42}},
            {x: 640, y: 530, numberPos: {x: 10, y: 57}}
        ];

        // check active elixirs
        const elixirs = [];
        positions.forEach((item, index) => {
            !(prevChoosings.some(prev => prev.choice === index) || choice === index && step !== 0) &&
            elixirs.push({...item, index});
        });

        elixirs.forEach(({x, y, numberPos, index}) => {
            const sprite = new Sprite(new Texture(this.getTexture('elixirs'), {
                x: index * 300, y: 600, width: 300, height: 300
            }));
            sprite.name = `choice${index}`;
            sprite.position.set(x, y);
            sprite.anchor.set(0.5);
            sprite.scale.set(0.45);
            sprite.buttonMode = true;
            sprite.interactive = true;
            sprite.on('pointerover', () => light.alpha = 1);
            sprite.on('pointerout', () => light.alpha = 0);
            sprite.on('pointerdown', () => this.sendMakeChoosing(index));
            parentContainer.addChild(sprite);

            const light = new Sprite(new Texture(this.getTexture('elixirs'), {
                x: index * 300, y: 0, width: 300, height: 300
            }));
            light.name = 'light';
            light.anchor.set(0.5);
            light.alpha = 0;
            sprite.addChild(light);

            const number = new Sprite(new Texture(this.getTexture('boxesWitch'), {
                x: index * 56, y: 0, width: 56, height: 80
            }));
            number.name = 'number';
            number.anchor.set(0.5);
            number.scale.set(0.8);
            number.position.set(numberPos.x, numberPos.y);
            sprite.addChild(number);

            ticker.add(this.choosingAnimations[`choice${index}`] = () =>
                sprite.position.y = y + 10 * Math.sin((Date.now() - animationStarted + 1500 * index) * 0.001) - 1);
        });

        const shadowArea = new Sprite(this.getTexture('witchAreaShadow'));
        shadowArea.name = 'shadowArea';
        shadowArea.anchor.set(0.5, 1);
        shadowArea.position.set(this.gameFieldWidth / 2, this.gameFieldHeight);
        parentContainer.addChild(shadowArea);
        ticker.add(this.choosingAnimations['shadowArea'] = () =>
            shadowArea.alpha = 0.5 * Math.sin((Date.now() - animationStarted) * 0.001) + 0.5);

        const wat = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 30, image: 'watActive',
            width: 400, height: 220, colCount: 5
        }));
        wat.name = 'wat';
        wat.scale.set(0.8);
        wat.anchor.set(0.5, 1);
        wat.position.set(this.gameFieldWidth / 2 + 10, this.gameFieldHeight + 10);
        wat.loop = false;
        wat.animationSpeed = 0.2;
        parentContainer.addChild(wat);

        const watStat = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 40, image: 'watStat',
            width: 220, height: 50, colCount: 1
        }));
        watStat.name = 'watStat';
        watStat.position.set(-106, -136);
        watStat.loop = true;
        watStat.animationSpeed = 0.2;
        watStat.play();
        wat.addChild(watStat);

        // create mask for prevent drawing neighbours pixels in sprite
        const watMask = new Graphics()
            .beginFill(0x000000)
            .drawRect(-200, -218, 400, 218)
            .endFill();
        wat.mask = watMask;
        wat.addChild(watMask);

        const frontArea = new Sprite(this.getTexture('witchAreaFront'));
        frontArea.name = 'frontArea';
        frontArea.position.set(-160, 0);
        parentContainer.addChild(frontArea);

        const magic = this.createMagic(parentContainer, 'magicChoosing');
        magic.position.set(922, 483);
        magic.scale.set(1.3);
        const magicMask = new Graphics()
            .beginFill(0x000000).drawPolygon([
                -36, -36, 36, -36, 36, 36,
                0, 36, -10, 5, -15, 36, // corner for staff
                -36, 36
            ]).endFill();
        magic.addChild(magicMask);
        magic.mask = magicMask;

        const cat = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 30, image: 'catActive',
            width: 150, height: 150, colCount: 3
        }));
        cat.name = 'cat';
        cat.position.set(697, 521);
        cat.loop = false;
        cat.animationSpeed = 0.25;
        parentContainer.addChild(cat);

        const catStat = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 25, image: 'catStat',
            width: 75, height: 38
        }));
        catStat.name = 'catStat';
        catStat.position.set(54, 71);
        catStat.loop = false;
        catStat.animationSpeed = 0.2;
        cat.addChild(catStat);
        this.choosingAnimations['cat'] = this.tickerInterval(() =>
            catStat.gotoAndPlay(0), 10000);

        const catLight = new Sprite(this.getTexture('catLight'));
        catLight.name = 'catLight';
        catLight.anchor.set(0.5);
        catLight.position.set(106, 86);
        cat.addChild(catLight);
        ticker.add(this.choosingAnimations['catLight'] = () =>
            catLight.alpha = 0.5 * Math.sin((Date.now() - animationStarted) * 0.001) + 0.5);

        this.createFire(parentContainer, 905, 120, 'fire1');
        this.createFire(parentContainer, 40, 404, 'fire2');
    }

    createFire(parentContainer, x, y, name, scale = 0.8, startFrame = 0) {
        const ticker = this.app.ticker;
        const animationStarted = Date.now();

        const container = new Container();
        container.name = name;
        container.position.set(x, y);
        container.scale.set(scale);
        parentContainer.addChild(container);

        const light = new Sprite(this.getTexture('fireLight'));
        light.name = 'light';
        light.anchor.set(0.5);
        light.position.set(4, 26);
        ticker.add(this.choosingAnimations[name] = () =>
            light.alpha = 0.5 * Math.sin((Date.now() - animationStarted) * 0.005) + 0.5);
        container.addChild(light);

        const sprite = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 33, image: 'fireEffect',
            width: 105, height: 139, colCount: 4
        }));
        sprite.name = 'fireEffect';
        sprite.anchor.set(0.5);
        sprite.animationSpeed = 0.2;
        sprite.gotoAndPlay(startFrame);
        container.addChild(sprite);
        return sprite;
    }

    sendMakeChoosing = choice => {
        this.setState('CHOOSING_RESPONSE');
        const choosingContainer = this.getStageChild('choosingContainer');
        choosingContainer && (choosingContainer.interactiveChildren = false);
        this.Legends.clearStatus();
        App.Socket.send(JSON.stringify({uc: 'MAKE-CHOOSING', choice}));
    };

    /**
     * Remove ticker functions
     */
    clearChoosingAnimations() {
        const ticker = this.app.ticker;
        Object.keys(this.choosingAnimations).forEach(key =>
            ticker.remove(this.choosingAnimations[key]));
        this.choosingAnimations = {};
    }

    showElixirChoice(response) {
        const {choice, win, multiplier} = response;
        const name = `choice${choice}`;
        const ticker = this.app.ticker;
        ticker.remove(this.choosingAnimations[name]);
        delete this.choosingAnimations[name];

        const choosingContainer = this.getStageChild('choosingContainer');
        const container = new Container();
        container.name = 'choiceContainer';
        choosingContainer.addChild(container);

        const elixirMask = new Graphics()
            .beginFill(0x000000)
            .drawRect(0, 0, this.gameFieldWidth, this.gameFieldHeight - 90)
            .endFill();
        elixirMask.name = 'elixirMask';
        container.mask = elixirMask;
        choosingContainer.addChild(elixirMask);

        const light1 = this.createLight(container, choice, 'light1');
        const light2 = this.createLight(container, choice, 'light2');

        const wat = choosingContainer.getChildByName('wat');
        const elixir = choosingContainer.getChildByName(name);
        container.position.set(elixir.position.x, elixir.position.y);
        elixir.position.set(0, 0);
        container.addChild(elixir);
        elixir.getChildByName('light').alpha = 1;
        const number = elixir.getChildByName('number');

        const craftEffect = new AnimatedSprite(this.getSpriteTextures({
            image: 'craftEffect', toFrame: 21,
            width: 652, height: 280, colCount: 3
        }));
        craftEffect.name = 'craftEffect';
        craftEffect.alpha = 0;
        craftEffect.anchor.set(0.5);
        craftEffect.position.set(this.gameFieldWidth / 2, multiplier ? 560 : 350);
        craftEffect.animationSpeed = 0.3;
        craftEffect.loop = false;
        // craftEffect.tint = multiplier ? 0x09ff07 : craftEffect.tint; // green tint
        choosingContainer.addChild(craftEffect);

        const winContainer = new Container();
        winContainer.name = 'winContainer';
        winContainer.position.set(465, 650);
        winContainer.mask = elixirMask;
        choosingContainer.addChild(winContainer);
        const textProps = {
            parentContainer: winContainer, fontImageName: 'witchFont',
            map: choosingFont, align: 'center', fontInterval: -30 // px between symbols
        };
        this.drawCustomFont(`x ${multiplier}`, 0, 0, textProps);

        // step 1
        const duration = {to: 1000};
        // step 2
        const duration2 = {from: 1000, to: 2000};
        // step 3
        const duration3 = {from: 2000, to: 2500};

        const winAnimation = multiplier ? [
            {sprite: container, timeline: [{from: {x: 480, y: 360}, to: {x: 480, y: 720}, duration: duration3}]},
            {
                sprite: elixir,
                timeline: [{from: {scaleX: 1, scaleY: 1}, to: {scaleX: 0.5, scaleY: 0.5}, duration: duration3}]
            }, {
                sprite: wat,
                timeline: [{from: {y: 730}, to: {scaleX: 1, scaleY: 1, y: 720}, duration: duration3}]
            },

            {
                sprite: wat,
                timeline: [{
                    from: {scaleX: 1, scaleY: 1, y: 720}, to: {scaleX: 0.8, scaleY: 0.8, y: 730},
                    duration: {from: 2500, to: 3000}
                }]
            }, {
                sprite: wat,
                timeline: [{
                    from: {scaleX: 0.8, scaleY: 0.8, y: 730}, to: {scaleX: 1, scaleY: 1, y: 720},
                    duration: {from: 4500, to: 5000}
                }]
            }, {
                sprite: wat,
                timeline: [{
                    from: {scaleX: 1, scaleY: 1, y: 720}, to: {scaleX: 0.8, scaleY: 0.8, y: 730},
                    duration: {from: 5000, to: 5500}
                }]
            }, {
                sprite: winContainer,
                timeline: [{
                    from: {scaleX: 0.5, scaleY: 0.5},
                    to: {y: 250, scaleX: 1.4, scaleY: 1.4},
                    duration: {from: 5000, to: 5500}
                }]
            },
            {sprite: winContainer, timeline: [{from: {alpha: 1}, to: {alpha: 0}, duration: {from: 7000, to: 7500}}]}
        ] : [
            {
                sprite: container,
                timeline: [{from: {x: 480, y: 360, alpha: 1}, to: {x: 480, y: 360, alpha: 0}, duration: {from: 4500}}]
            }
        ];

        const params = {
            duration: multiplier ? 7500 : 5000,
            animations: [
                {sprite: light1, timeline: [{to: {alpha: 1}, duration}]},
                {sprite: light2, timeline: [{to: {alpha: 1}, duration}]},
                {sprite: container, timeline: [{to: {x: 480, y: 360}, duration}]},
                {sprite: elixir, timeline: [{to: {scaleX: 1, scaleY: 1}, duration}]},

                {sprite: light1, timeline: [{from: {alpha: 1}, to: {alpha: 0}, duration: duration2}]},
                {sprite: light2, timeline: [{from: {alpha: 1}, to: {alpha: 0}, duration: duration2}]},
                {sprite: number, timeline: [{to: {alpha: 0, scaleX: 0.4, scaleY: 0.4}, duration: duration2}]},

                ...winAnimation
            ],
            onComplete: () => {
                container.destroy();
                winContainer.destroy();
                elixirMask.destroy();
                this.endChoice(response);
            }
        };

        this.showAnimation(params);

        this.tickerTimeout(() => {
            craftEffect.alpha = 1;
            craftEffect.play();
            craftEffect.onComplete = () => craftEffect.destroy();
            elixir.texture = new Texture(this.getTexture('elixirs'), {
                x: choice * 300, y: 300, width: 300, height: 300
            });
            elixir.getChildByName('light').alpha = 0;
        }, 2300);

        // additional wat animation
        multiplier && this.tickerTimeout(() => {
            wat.play();
            wat.children[0].alpha = 0;
            wat.onComplete = () => {
                wat.gotoAndPlay(0);
                wat.onComplete = () => {
                    wat.gotoAndStop(0);
                    this.showAnimation({
                        duration: 200, animations: [{sprite: wat.children[0], timeline: [{to: {alpha: 1}}]}]
                    });
                };
            };
        }, 2000);

        // additional cat animation
        multiplier && this.tickerTimeout(() => {
            const cat = choosingContainer.getChildByName('cat');
            cat.play();
            cat.children[0].alpha = 0;
            cat.onComplete = () => {
                cat.gotoAndStop(0);
                this.showAnimation({
                    duration: 200, animations: [{sprite: cat.children[0], timeline: [{to: {alpha: 1}}]}]
                });
            };
        }, 2300);

        this.tickerTimeout(() => {
            this.Legends.setText('win', {text: 'win', value: win});
            this.Legends.setStatus('creditsWon', multiplier * this.gameSettings.getBetCredit());
        }, multiplier ? 5000 : 2500);
    }

    showWeaponChoice(response) {
        const {choice, win, multiplier} = response;

        const choosingContainer = this.getStageChild('choosingContainer');
        const container = new Container();
        container.name = 'choiceContainer';
        choosingContainer.addChild(container);

        const light1 = this.createLight(container, choice, 'light1');
        const light2 = this.createLight(container, choice, 'light2');

        const multipliers = [7, 8, 9, 10, 11];
        const weaponWin = new AnimatedSprite(this.getSpriteTextures({
            image: 'weaponsWin', fromFrame: multipliers.indexOf(multiplier) * 10,
            toFrame: multipliers.indexOf(multiplier) * 10 + 10,
            width: 200, height: 200, colCount: 10
        }));
        weaponWin.alpha = 0;
        weaponWin.anchor.set(0.5);
        weaponWin.scale.set(0.7);
        weaponWin.animationSpeed = 0.15;
        weaponWin.play();
        container.addChild(weaponWin);

        const winContainer = new Container();
        winContainer.name = 'winContainer';
        winContainer.alpha = 0;
        winContainer.scale.set(0.5);
        winContainer.position.set(0, 100);
        container.addChild(winContainer);
        const textProps = {
            parentContainer: winContainer, fontImageName: 'hunterFont',
            map: choosingFont, align: 'center', fontInterval: -30 // px between symbols
        };
        this.drawCustomFont(`x ${multiplier}`, 0, 0, textProps);

        const box = choosingContainer.getChildByName(`box${choice}`);
        box.getChildByName('light').alpha = 1;
        container.position.set(box.position.x, box.position.y);
        box.position.set(0, 0);
        container.addChild(box);

        const weapons = choosingContainer.getChildByName(`choice${choice}`);
        weapons.getChildByName('light').alpha = 1;

        // step 1
        const duration = {to: 1000};
        // step 2
        const duration2 = {from: 2000, to: 3000};
        // step 3
        const duration3 = {from: 4000, to: 5000};
        // step 4
        const duration4 = {from: 8000};

        const params = {
            duration: 9000,
            animations: [
                {sprite: light1, timeline: [{to: {alpha: 1}, duration}]},
                {sprite: light2, timeline: [{to: {alpha: 1}, duration}]},
                {sprite: container, timeline: [{to: {x: 480, y: 360}, duration}]},
                {sprite: box, timeline: [{to: {scaleX: 2, scaleY: 2}, duration}]},
                {sprite: weapons, timeline: [{to: {alpha: 0}, duration}]},

                {
                    sprite: box,
                    timeline: [{
                        from: {alpha: 1, scaleX: 2, scaleY: 2}, to: {alpha: 0, scaleX: 1, scaleY: 1},
                        duration: duration2
                    }]
                },
                {sprite: weaponWin, timeline: [{to: {alpha: 1, scaleX: 1.3, scaleY: 1.3}, duration: duration2}]},

                {sprite: winContainer, timeline: [{to: {alpha: 1, scaleX: 1, scaleY: 1}, duration: duration3}]},

                {
                    sprite: container,
                    timeline: [{
                        from: {x: 480, y: 360, alpha: 1},
                        to: {x: 480, y: 360, alpha: 0},
                        duration: duration4
                    }]
                }
            ],
            onComplete: () => {
                container.destroy();
                weapons.destroy();
                this.endChoice(response);
            }
        };

        this.showAnimation(params);
        this.tickerTimeout(() => {
            this.Legends.setText('win', {text: 'win', value: win});
            this.Legends.setStatus('creditsWon', multiplier * this.gameSettings.getBetCredit());
        }, 4000);
    }

    endChoice(response) {
        const {stop} = response;
        this.setState('CHOOSING_IDLE');
        this.getStageChild('choosingContainer').interactiveChildren = !stop;
        this.Legends.setStatus('makeChoice');

        if (stop) {
            this.setState('SHOW_WIN');
            this.Legends.clearStatus();
            App.resetLoader();
            App.updateState('loader', {
                active: true,
                fade: 'in',
                fadeEndCallback: () => {
                    this.clearChoosingAnimations();
                    this.setBackground('mainArea');
                    this.showBoxes();
                    App.updateState('buttons', {visualization: App.buttonsType});
                    this.Legends.setStatus('creditsWon', response.win);
                    this.showChoosingEndFrame(response);

                    // hide and remove loader
                    this.tickerTimeout(() => App.updateState('loader', {
                        fade: 'out', fadeEndCallback: () => App.updateState('loader', {active: false})
                    }), 50);
                }
            });
        }
    }

    showChoosingEndFrame(response) {
        const choosingContainer = this.getStageChild('choosingContainer');
        choosingContainer && choosingContainer.destroy();

        // set default pos
        const mainContainer = this.getStageChild('mainContainer');
        mainContainer.visible = true;
        mainContainer.getChildByName('mainArea').scale.set(1);
        mainContainer.getChildByName('mainArea').position.y = 0;
        mainContainer.getChildByName('house').position.set(625, 249);
        mainContainer.getChildByName('huntersContainer').position.set(-134, 350);
        mainContainer.getChildByName('sky').position.set(232, 0);
        mainContainer.getChildByName('background').position.set(43, 85);
        const bonusContainer = this.getStageChild('bonusContainer');
        bonusContainer.visible = true;
        bonusContainer.getChildByName('logo1').position.set(220, -15);
        bonusContainer.getChildByName('logo2').position.set(435, -15);
        this.getStageChild('reelsStage').position.y = this.reelTop + this.symbolHeight * this.reelRows + 5;

        const container = new Container();
        container.name = 'winContainer';
        bonusContainer.addChild(container);

        const props = {
            fontFamily: 'WitchFont',
            fontSize: 72,
            dropShadow: true,
            dropShadowAngle: 0,
            dropShadowBlur: 5,
            dropShadowColor: '#00ff07',
            dropShadowDistance: 0,
            lineJoin: 'round'
        };
        const youWon = new Text(langs[App.settings.currentLanguage].youWon,
            {...props, fill: '#6bf2ff', dropShadowColor: '#1149ab'});
        youWon.position.set(this.gameFieldWidth / 2, 240);
        youWon.anchor.set(0.5);
        container.addChild(youWon);

        const credits = new Text(`${response.win} ${App.View.state.lang.credits}`,
            {...props, fill: '#00ff07', dropShadowColor: '#00ff07'});
        credits.position.set(this.gameFieldWidth / 2, 340);
        credits.anchor.set(0.5);
        container.addChild(credits);

        this.tickerTimeout(() => {
            this.showAnimation({
                duration: 500, animations: [{sprite: container, timeline: [{to: {alpha: 0}}]}],
                onComplete: () => container.destroy()
            });
            this.showReels();
            this.finishChoosing(response);
        }, 4000);
    }

    finishChoosing(response) {
        App.updateState('buttons', {visualization: App.buttonsType});
        this.Buttons.setDefaultGameButtons();

        this.choosingResponse = [];
        // redefine only for take win, prevent roll after choosing
        this.latestResponse = {payment: response.win, features: []};
        this.gameFlag.bonusStart = false;
        App.updateButton('autoStart', {disabled: false});

        response.win ?
            this.takeWin() :
            this.roundFinished();
    }

    additionalPreparingToAnimateFeature() {
        this.getStageChild('linesContainer').zIndex = 0;
        this.showMagicBoom();
    }

    showMagicBoom() {
        const ticker = this.app.ticker;
        const borderTop = this.getStageChild('bonusContainer').getChildByName('borderTop');
        const magicBoom = this.createMagic(borderTop, 'magicBoom');
        magicBoom.position.set(-3.5, -27.5);

        const magicLight = new Sprite(this.getTexture('magicLight'));
        magicLight.anchor.set(0.5);
        magicLight.position.set(-3.5, -25.5);
        magicLight.name = 'magicLight';
        magicLight.scale.set(0.3);
        borderTop.addChild(magicLight);

        const animationStarted = Date.now();
        const magicBoomAnimation = () => {
            const timeSpent = Date.now() - animationStarted;
            magicBoom.alpha = 1 - timeSpent * 0.001;
            magicBoom.scale.set(1 + timeSpent * 0.002);
            magicLight.alpha = 1 - timeSpent * 0.001;
            magicLight.scale.set(0.3 + timeSpent * 0.0005);
            if (magicBoom.alpha < 0) {
                magicBoom.destroy();
                magicLight.destroy();
                ticker.remove(magicBoomAnimation);
            }
        };
        ticker.add(magicBoomAnimation);
    }

    /**
     * Create magic ball, animate and add to parent container
     * @param parentContainer
     * @param name
     * @returns {PIXI.AnimatedSprite}
     */
    createMagic(parentContainer, name = 'magic') {
        const magic = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 13, image: 'magic',
            width: 72, height: 72
        }));
        magic.anchor.set(0.5);
        magic.name = name;
        magic.animationSpeed = 0.2;
        magic.play();
        parentContainer.addChild(magic);

        return magic;
    }

    createLight(parentContainer, choice, name) {
        const ticker = this.app.ticker;
        let x = 0, width, height, deg;

        switch (name) {
            case 'light1':
                width = 229;
                height = 233;
                deg = 0.5;
                break;
            case 'light2':
                x = 229;
                width = 400;
                height = 401;
                deg = -0.5;
                break;
        }

        const sprite = new Sprite(new Texture(this.getTexture('choiceLight'), {
            x, y: 0, width, height
        }));
        sprite.name = name;
        sprite.alpha = 0;
        sprite.anchor.set(0.5);
        sprite.scale.set(2);
        parentContainer.addChild(sprite);
        ticker.add(this.choosingAnimations[`${name}-${choice}`] = () => sprite.angle += deg);
        return sprite;
    }

    /**
     * Function to stop Animate Feature animation
     */
    stopAnimateFeature() {
        this.stopFeatureTimeout();
        this.getStageChild('linesContainer').zIndex = 2;
        this.getStageChild('linesContainer').removeChildren();
        this.resetSymbolAnimation();
        this.Legends.clearText('features');
        !this.isBonus() && this.Legends.clearText('win');
    }

    startGambleAnimation() {
        this.hideReels();
    }

    endGambleAnimation() {
        this.showReels();
    }

    onInfoStartOpen() {
        this.hideReels();
    }

    onInfoStartClose() {
        this.showReels();
    }

    hideReels() {
        this.showAnimation({
            duration: 500, animations: [
                {
                    sprite: this.getStageChild('reelsStage'),
                    timeline: [{to: {y: this.reelTop + this.symbolHeight * this.reelRows + 5}}]
                }, {
                    sprite: this.getStageChild('linesContainer'),
                    timeline: [{to: {y: this.symbolHeight * this.reelRows}}]
                }
            ]
        });
    }

    showReels() {
        const reelsStage = this.getStageChild('reelsStage');
        reelsStage.interactiveChildren = false;

        this.showAnimation({
            duration: 500, animations: [
                {sprite: reelsStage, timeline: [{to: {y: this.reelTop}}]},
                {sprite: this.getStageChild('linesContainer'), timeline: [{to: {y: 0}}]}
            ], onComplete: () => {
                reelsStage.interactiveChildren = true;
            }
        });
    }

    /**
     * Create texts for paymentBorder table
     * @param parentContainer
     * @param payTable
     * @param direction
     */
    drawSymbolInfoPayments(parentContainer, payTable, direction) {
        const bet = this.gameSettings.getBetLineCredit();
        const props = {
            fill: '#00ff07',
            stroke: '#007318',
            align: 'left',
            fontFamily: 'WitchFont',
            fontSize: 22,
            strokeThickness: 3,
            lineJoin: 'round'
        };

        if (payTable.length) {
            payTable.forEach((pay, index) => {
                const quantity = new Text(`x${pay[0]}`, props);
                quantity.position.x = direction === 'left' ? -65 : -45;
                quantity.position.y = 15 + (payTable.length === 1 ? -30 : -30 * index);

                const payment = new Text(` ${bet * pay[1]}`, {...props, fill: '#6bf2ff', stroke: '#1149ab'});
                payment.position.x = direction === 'left' ? -34 : -15;
                payment.position.y = 15 + (payTable.length === 1 ? -30 : -30 * index);

                parentContainer.addChild(quantity, payment);
            });
        } else {
            const wild = new Text('WILD', {...props, fontSize: 30});
            wild.position.x = direction === 'left' ? -50 : -25;
            wild.position.y = -17;
            parentContainer.addChild(wild);
        }
    }

    /**
     * Long roll decider return reel from with long roll started
     *  -1 no long roll
     *  0 1 2 3 4 5 number of reel
     */
    getLongRoll(screen) {
        const scatterMap = [];
        screen.forEach((vector, reelIndex) =>
            vector.forEach(symbol =>
                symbol === this.scatter && scatterMap.push(reelIndex)));

        this.reelSymbol.forEach((symbolAmount, reelIndex) =>
            this.reelLong[reelIndex] =
                (scatterMap.includes(0) && scatterMap.includes(2) && // 1st and 3rd reel filled
                    reelIndex === this.reels - 1) ? 1 : 0);

        return this.reelLong; // get possible long roll type
    }

    playLongRollSound = reelIndex => {
        if (this.reelLong[reelIndex] === 1) {
            this.stopRollSound();
            this.stopLongRollSound();
            this.tickerTimeout(() => App.Sounds.playSound('long1'), 50);

            const longRollEffect = new AnimatedSprite(this.getSpriteTextures({
                toFrame: 15, image: 'longRollEffect',
                width: 200, height: 490, colCount: 5
            }));
            longRollEffect.name = 'longRollEffect';
            longRollEffect.position.set(this.reelXCoordinates[reelIndex] - 21, this.reelTop - 14);
            longRollEffect.scale.set(1.05);
            longRollEffect.alpha = 0;
            longRollEffect.animationSpeed = 0.3;
            longRollEffect.play();
            this.getStageChild('bonusContainer').addChild(longRollEffect);

            this.showAnimation({
                duration: 500,
                animations: [{sprite: longRollEffect, timeline: [{to: {alpha: 1}}]}]
            });
        }
    };

    /**
     * Called to show round results once animation is finished
     */
    rotationDone() {
        const ticker = this.app.ticker;
        App.updateButton('start', {disabled: true});

        const longRollEffect = this.getStageChild('bonusContainer').getChildByName('longRollEffect');
        if (longRollEffect) {
            this.showAnimation({
                duration: 500,
                animations: [{sprite: longRollEffect, timeline: [{to: {alpha: 0}}]}],
                onComplete: () => longRollEffect.destroy()
            });

            // last reel scatter
            if (this.latestResponse.screen[this.reels - 1].includes(this.scatter)) {
                const longRollBoom = new Sprite(this.getTexture('longRollBoom'));
                longRollBoom.name = 'longRollBoom';
                longRollBoom.position.set(796, 331);
                longRollBoom.scale.set(1.05);
                longRollBoom.anchor.set(0.5);
                longRollBoom.alpha = 0;
                this.getStageChild('bonusContainer').addChild(longRollBoom);

                this.showAnimation({
                    duration: 500,
                    animations: [{sprite: longRollBoom, timeline: [{to: {alpha: 1}}]}],
                    onComplete: () => {
                        const animationStarted = Date.now();
                        const magicBoomAnimation = () => {
                            const timeSpent = Date.now() - animationStarted;
                            longRollBoom.alpha = 1 - timeSpent * 0.001;
                            longRollBoom.scale.set(1.05 + timeSpent * 0.0003);
                            if (longRollBoom.alpha < 0) {
                                longRollBoom.destroy();
                                ticker.remove(magicBoomAnimation);
                            }
                        };
                        ticker.add(magicBoomAnimation);
                    }
                });
            }
        }

        // check regularShort animation
        const scatters = [];
        for (let i = 0; i < this.reels; i++) {
            for (let j = 0; j < this.reelRows; j++) {
                const symbolObj = this.Roll.clipMatrix[i][j + 1];
                symbolObj.symbol === this.scatter && scatters.push(symbolObj);
            }
        }

        const animationEnd = () => {
            this.createReelMatrix(this.getStageChild('reelsStage'));
            this.onRotationDone();
            App.System.statistics.currentSpinNumber < 3 && App.System.collectFps();
        };

        // wait for last scatter played
        scatters.length && scatters.some(scatter => scatter.sprite.playing) ?
            scatters[scatters.length - 1].sprite.onComplete = animationEnd :
            animationEnd();
    }

    setRegularShortSprite(clipMatrix, reelIndex, textures) {
        for (let i = 0; i < this.reelRows; i++) {
            const symbolObj = clipMatrix[reelIndex][i + 1];
            if (symbolObj.symbol === this.scatter) {
                symbolObj.image = 'regularShort';
                symbolObj.sprite.animationSpeed = 0.4;
                symbolObj.sprite.loop = false;
                symbolObj.sprite.textures = textures[symbolObj.image][this.scatter];
                symbolObj.sprite.play();
            }
        }
    }

    /**
     * Additional handler for keyboard keys press
     * @param event
     * @param buttons
     * @returns {{soundChange: boolean, handler: boolean}}
     */
    keyboardPress(event, buttons) {
        let soundChange = true;
        let handler = null;

        // choosing buttons
        if (this.getState() === 'CHOOSING_SIDE') {
            switch (event.keyCode) {
                case 50: // 'digit 2'
                case 98: // 'num 2'
                case 'b05': // 'h2'
                    handler = () => this.showHeroChoiceAnimation(this.getStageChild('heroesContainer').getChildByName('hunter'), 1);
                    break;
                case 52: // 'digit 4'
                case 100: // 'num 4'
                case 'b06': // 'h4'
                    handler = () => this.showHeroChoiceAnimation(this.getStageChild('heroesContainer').getChildByName('witch'), 2);
                    break;
            }
        }

        // super choosing buttons
        if (this.getState() === 'CHOOSING_IDLE') {
            const sendChoosing = index => {
                this.getStageChild('choosingContainer').getChildByName(`choice${index}`) &&
                (handler = () => this.sendMakeChoosing(index));
            };

            switch (event.keyCode) {
                case 49: // 'digit 1'
                case 97: // 'num 1'
                case 'b04': // 'h1'
                    soundChange = false; // pressed same button, disable additional handler
                    sendChoosing(0);
                    break;
                case 50: // 'digit 2'
                case 98: // 'num 2'
                case 'b05': // 'h2'
                    sendChoosing(1);
                    break;
                case 51: // 'digit 3'
                case 99: // 'num 3'
                case 'b07': // 'h3'
                    sendChoosing(2);
                    break;
                case 52: // 'digit 4'
                case 100: // 'num 4'
                case 'b06': // 'h4'
                    sendChoosing(3);
                    break;
                case 53: // 'digit 5'
                case 101: // 'num 5'
                case 'b03': // 'h5'
                    sendChoosing(4);
                    break;
            }
        }

        return {soundChange, handler};
    }
}
