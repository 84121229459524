module.exports = {
    // socket
    connectionServer: 'Підключення до сервера',
    initializingGame: 'Завантаження гри',
    initializingMenu: 'Завантаження меню',
    loadingImages: 'Завантаження зображень',
    reload: 'Перезавантажити',
    redirect: 'Переспрямування',
    exitAndReload: 'Вийдіть в меню і перезавантажте сторінку',
    exit: 'Вийти',
    exitAfter: 'Вихід із системи через',
    serverNotResponded: 'Сервер не відповідає',
    pluginNotResponded: 'Немає зв\'язку з контролером',
    codeAlreadyConnected: 'Гравець з таким кодом вже підключений',
    connectionLost: 'З\'єднання розірвано',
    gameNotFound: 'Гру не знайдено',
    codeNotFound: 'Код не знайдено',
    cardNotFound: 'Карточку не знайдено',
    terminalNotFound: 'Код терміналу не знайдено або термінал недоступний',

    // login
    login: 'Вхід',
    loginCredentials: 'ім\'я користувача, email або код',
    codeCredentials: 'Код рахунку',
    username: 'Ім\'я користувача',
    enterCode: 'Введіть код',
    password: 'Пароль',
    email: 'Електронна пошта',
    signIn: 'Увійти',
    signUp: 'Зареєструватися',
    registration: 'Реєстрація',
    oneClick: 'В один клік',
    demo: 'Демо',
    createAccount: 'Створити акаунт',
    logout: 'Вийти',
    restorePasswordWithEmail: 'Введіть email для відновлення паролю',
    sendNewPassword: 'Відправити новий пароль',
    restorePassword: 'Відновити пароль',
    restoringPassword: 'Відновлення паролю',
    successRegistration: 'Успішна реєстрація',
    incorrectUsername: 'Неправильне ім\'я користувача або пароль',
    notUniqueName: 'Ім\'я повинно бути унікальним',
    notUniqueEmail: 'Адреса електронної пошти повинна бути унікальною',
    notUniquePhone: 'Номер телефону повинен бути унікальним',
    missedField: 'Є пропущенні обов\'язкові поля',
    wrongOldPassword: 'Старий пароль введено неправильно',
    wrongPassword: 'Пароль введено неправильно',
    emailChanged: 'Підтвердження надіслано на Вашу електронну пошту',
    passwordResetError: 'Неправильне ім\'я або адреса електронної пошти',
    newPasswordSent: 'Новий пароль був відправлений на вашу електронну адресу',
    invalidEmail: 'Невірна адреса электронної пошти',
    invalidPhone: 'Невірний номер телефону \n' +
        '+38 YYY XXX XX XX',
    enterPassword: 'Введіть пароль',
    differentPasswords: 'Паролі не співпадають',
    tooLongPassword: 'Пароль занадто довгий (макс. кількість символів: 20)',
    tooShortPassword: 'Пароль занадто короткий (мін. кількість символів: 8)',
    passwordChanged: 'Пароль змінено',
    enterOldPassword: 'Введіть старий пароль',
    enterNewPassword: 'Введіть новий пароль',
    samePasswords: 'Старий і новий пароль не можуть бути ідентичні',
    copied: 'Скопійовано',
    copy: 'Копіювати',

    // profile
    save: 'Зберегти',
    confirmation: 'Підтвердити',
    saveAs: 'Зберегти як',
    cancel: 'Відміна',
    changingEmail: 'Зміна Email',
    changeEmail: 'Змінити Email',
    changingPhone: 'Зміна телефона',
    changePhone: 'Змінити телефон',
    changingPassword: 'Зміна паролю',
    changePassword: 'Змінити пароль',
    confirmCode: 'Код підтвердження',
    phoneConfirmed: 'Телефон підтверджено',
    oldPassword: 'Старий пароль',
    newPassword: 'Новий пароль',
    confirmPassword: 'Підтвердіть пароль',
    connectWithUs: 'Зв\'яжіться з нами',
    profile: 'Профіль',
    deposit: 'Депозит',
    withdraw: 'Зняття',
    support: 'Техпідтримка',
    date: 'Дата',
    type: 'Тип',
    amount: 'Сума',
    status: 'Статус',
    transactionsHistory: 'Історія транзакцій',
    exchange: 'Обмін валюты',
    noData: 'немає даних',
    transactionsBonus: 'Історія повідомлень',
    message: 'Повідомлення',
    ok: 'Ок',

    currencySelect: 'Валюта',
    paymentSelect: 'Вибір способу оплати',
    amountDepositSelect: 'Сума поповнення',
    finishOperation: 'Завершення операції',

    enterCurrency: 'Виберіть валюту',
    enterCardNumber: 'Введіть номер картки',
    cardNumber: 'Номер картки',
    paymentAccept: 'Підтвердження платежу',
    payoutAmount: 'Сума виплати',
    paymentType: 'Cпосіб оплати',
    enterDepositWay: 'Виберіть спосіб оплати',
    enterDepositAmount: 'Введіть суму для поповнення',
    enterWithdrawalWay: 'Виберіть спосіб оплати',
    enterPayoutAmount: 'Введіть свої реквізити',
    successfulPayment: 'Платіж успішно добавлений в обробку',
    successOperation: 'Оплата пройшла успішно!',
    errorOperation: 'Помилка платежу',
    confirm: 'Прийняти',
    country: 'Країна',
    currency: 'Валюта',
    invalidCountry: 'Неправильно вибрана країна',
    invalidCurrency: 'Неправильно вибрана валюта',
    successfullyChangedTo: 'Успішно змінено на',
    lowBalance: 'Недостатньо коштів на балансі',

    accept: 'Прийняти',
    decline: 'Відмінити',
    currencyExchange: 'Обмін  валюти',
    currentBalance: 'Поточний баланс',
    from: 'з',
    to: 'на',
    exchangePercent: 'Процент за обмін',
    newBalance: 'Новий баланс',
    coefficient: 'Коефіцієнт',
    currencyConversion: 'Конвертація валюти',
    wrongInputData: 'Неправильно введені дані',
    wrongAmountData: 'Неправильна сума',
    wrongCreditCard: 'Неправильний номер кредитної картки',
    noRedirectReff: 'Немає посилань на переспрямування',
    deserializationIssue: 'Проблема з десериалізацією',
    currencyPairNotExist: 'Валютна пара не існує',
    authorizationMandatory: 'Дозвіл є обов’язковим',
    birthdayFormat: 'День народження повинен бути ДД ММ РРРР',
    tooShort: 'Неправильний номер карти',
    infoPayment: 'Обробка першої виплати за 24 години проводиться безкоштовно. За кожну наступну виплату буде стягуватися комісія.',
    time: 'Час',
    period: 'До 3 днів',
    image: 'Зображення',
    text: 'Текст',

    // status
    balance: 'Баланс',
    insurance: 'Страхування',
    credits: 'кредитів',
    creditsWon: 'кредитів виграно',
    winnerPaid: 'Оплата виграшу',
    gotNoMoney: 'Не вистачає коштів',
    minBet: 'Мінімальна загальна ставка',
    gambleUp: 'Примножити або забрати виграш',
    gambleUp5x: 'Примножити до 5x або забрати виграш',
    gambleEnd: 'Спробу завершено',
    pressAnyButton: 'Натисніть старт для продовження',
    freeGame: 'Безкоштовна гра',
    of: 'з',
    freeSpinsRemaining: 'БЕЗКОШТОВНИХ ПРОКРУТІВ ',
    freeGames: 'безкоштовних ігор',
    more: 'додаткових ',
    freeSpinsLeft: 'БЕЗКОШТОВНИХ ПРОКРУТІВ ',
    additionalRoll: 'Додатковий прокрут',
    selectCard: 'Оберіть карту',
    selectColor: 'Оберіть червону або чорну',
    selectColorOrSuit: 'Виберіть колір (x2) або масть (x4)',
    gameOverPlaceYourBet: 'Будь ласка зробіть ставку',
    auto: 'Авто',
    linePays: 'Лінія',
    scatterPays: 'За скатер',
    win: 'Виграш',
    line: 'Лінія',
    youWon: 'Ви виграли',
    lowMinBet: 'Мінімальна ставка на ',
    highMaxBet: 'Максимальна ставка на ',
    unSupport: 'Ця гра не підтримується на вашому пристрої',
    urlNotFound: 'Сторінка не знайдена',
    categories: 'Категорії',
    chooseSide: 'Виберіть сторону',
    makeChoice: 'Зробіть вибір',
    multiplier: 'Множник',

    // buttons
    lines: 'Лінії',
    bet: 'Ставка',
    total: 'Разом',
    denom: 'Деном',
    gamble: 'Ризик',
    paytable: 'Інформація',
    bonus: 'Бонус',
    start: 'Старт',
    stop: 'Стоп',
    autoplay: 'Автогра',
    select: 'Вибір',
    collect: 'Взяти',
    red: 'Червона',
    black: 'Чорна',
    return: 'Повернутися',
    half: 'Половина',
    payout: 'Виплата',
    swipeToStart: 'Проведіть пальцем вгору, щоб почати',
    tapToStart: 'Торкніться, щоб почати',

    // roulette buttons
    repeatAndStart: 'Повторити ставку і крутити',
    double: 'Подвоїти',
    doubleAndSpin: 'Подвоїти і крутити',
    undo: 'Відмінити',
    repeatBet: 'Повторна ставка',
    removeBets: 'Забрати ставки',
    results: 'Результати',
    cover: 'Покриття',

    // gamble
    gambleAmount: 'Виграш',
    gambleToWin: 'Ризикни для виграшу',
    gambleAmountAmatic: 'Сума ризику',
    gambleToWinAmatic: 'Ризикнути червона/чорна',
    suitGambleToWinAmatic: 'Вибір масті',
    previousCards: 'Попередні карти',
    gambleAttemptsLeft: 'Залишилось спроб',
    history: 'Історія',

    // wrap
    selectLine: 'Кількість ліній',
    selectBet: 'Ставка на лінію',
    selectDenomination: 'Деномінація',

    // info
    rules: 'Правила',
    substitutesForAllSymbols: 'Заміняє всі символи крім скатерів',
    combinationOfKind: 'Виграш враховує усі виграшні комбінації. ',
    combinationLeftToRight: 'Виграшні комбінації формуються зліва направо, за винятком скатерів. ',
    prizesOnSelectedLines: 'Виграші зараховуються за комбінації на обраних лініях, за винятком скатерів. ',
    ScatterPayAtAnyPosition: 'Символи скатеру грають на всіх позиціях на екрані. ',
    HighestWinPaid: 'На обраній лінії зараховується найбільший із можливих виграшів. ',
    ScatterWinsAddedToLineWins: 'Виграш за скатер додається до виграшу за лінії. ',
    prizesShownInCredits: 'Всі виграші показуються у кредитах. ',
    MalfunctionVoidsAllPays: 'Несправність анулює всі виграші. ',
    onTheScreen: 'дають',
    bonusGame: 'БОНУСНУ ГРУ',
    bonusGameGive: 'Кількість безкоштовних прокрутів ',
    freeSpins: 'визначається рулеткою',
    featureBonus: 'У бонусних прокрутах перший барабан заповнений',
    goodLuck: 'Бажаємо удачі!',

    // instruction IOS
    howToInstall: 'як встановити додаток',
    pressTheButton: 'Натисніть кнопку \'share\'',
    goToHomeScreen: 'Перейдіть до ',
    confirmInstallation: 'Підтвердіть установку',

    // Rules

    payRules: 'Правила гри',
    payRulesText: '1. Участь у грі може приймати особа яка досягла 18 років; \n' +
        '2. Min. депозит для участі складає 100 UAH; \n' +
        '3. Min. сума для виводу складає 300 UAH, при цьому вивід здійснюється виключно на карту, з якої \n' +
        'відбувалось поповнення;\n' +
        '4. При поповненні з терміналів самообслуговування, або іншим шляхом, вивід лише на одну закріплену\n' +
        'за Вами карту; \n' +
        '5. Кожен гравець може користуватися тільки одним зареєстрованим акаунтом. При виявленні дублюючого \n' +
        'акаунта, останній буде заблоковано, включно із залишком коштів на балансі; \n' +
        '6. При підозрі на сумнівні операції, адміністрація може вимагати проходження верифікації особи;\n' +
        '7. Адміністрація залишає за собою право, вносити зміни та доповнення до даних правил та умов. \n',

    // bonuses
    congratulation: 'Вітаємо',

    registration_bonus: {
        title: 'Бонус за реєстрацію!',
        description: {
            punkt1: '- Щоб активувати бонус необхідно підтвердити пошту і номер телефону.',
            punkt2: '- Внести депозит на основний рахунок.',
            punkt3: '- Для зарахування бонусу на основний рахунок потрібно зробити ставок на суму в 15 разів більше від суми бонусу.'
        },
        accept: 'Ваш бонус за реєстрацію зарахований на бонусний рахунок!'
    },

    week_bonus: {
        title: 'Тижневий бонус!',
        description: 'Ваш тижневий бонус',
        accept: 'Ваш тижневий бонус зарахований на основний рахунок!'
    }
};
