import LinesEgt from './../../egt/lines';
import {JL} from 'jsnlog';

export default class Lines extends LinesEgt {
    constructor() {
        super();

        this.boxSettings.firstRightBox = 30;

        this.lines = {
            0: {
                coordinates: [1, 1, 1, 1, 1],
                boxes: [{x: 14, y: 402}, {x: 763, y: 402}],
                lineDeltaY: 0,
                color: '#ffa407'
            },
            1: {
                coordinates: [0, 0, 0, 0, 0],
                boxes: [{x: 14, y: 330}, {x: 763, y: 330}],
                lineDeltaY: 0,
                color: '#ff6827'
            },
            2: {
                coordinates: [2, 2, 2, 2, 2],
                boxes: [{x: 14, y: 260}, {x: 763, y: 260}],
                lineDeltaY: 0,
                color: '#fcf01d'
            },
            3: {
                coordinates: [0, 1, 2, 1, 0],
                boxes: [{x: 14, y: 191}, {x: 763, y: 191}],
                lineDeltaY: 10,
                color: '#ff0000'
            },
            4: {
                coordinates: [2, 1, 0, 1, 2],
                boxes: [{x: 14, y: 119}, {x: 763, y: 119}],
                lineDeltaY: -10,
                color: '#f2ff57'
            },
            5: {
                coordinates: [1, 0, 1, 2, 1],
                boxes: [{x: 772, y: 250}],
                lineDeltaY: 10,
                color: '#fb6900'
            },
            6: {
                coordinates: [1, 2, 1, 0, 1],
                boxes: [{x: 772, y: 310}],
                lineDeltaY: -10,
                color: '#f0bb00'
            },
            7: {
                coordinates: [0, 0, 1, 2, 2],
                boxes: [{x: 772, y: 430}],
                lineDeltaY: -10,
                color: '#df1a08'
            },
            8: {
                coordinates: [2, 2, 1, 0, 0],
                boxes: [{x: 0, y: 110}],
                lineDeltaY: 10,
                color: '#bfc03e'
            },
            9: {
                coordinates: [0, 1, 0, 1, 0],
                boxes: [{x: 0, y: 170}],
                lineDeltaY: 20,
                color: '#9a0000'
            },
            10: {
                coordinates: [2, 1, 2, 1, 2],
                boxes: [{x: 0, y: 390}],
                lineDeltaY: -20,
                color: '#d4ea13'
            },
            11: {
                coordinates: [1, 0, 0, 0, 1],
                boxes: [{x: 0, y: 450}],
                lineDeltaY: -20,
                color: '#d25600'
            },
            12: {
                coordinates: [1, 2, 2, 2, 1],
                boxes: [{x: 772, y: 190}],
                lineDeltaY: 20,
                color: '#e2b417'
            },
            13: {
                coordinates: [0, 1, 1, 1, 0],
                boxes: [{x: 0, y: 230}],
                lineDeltaY: 30,
                color: '#ee6800'
            },
            14: {
                coordinates: [2, 1, 1, 1, 2],
                boxes: [{x: 0, y: 330}],
                lineDeltaY: -30,
                color: '#cea90c'
            },
            15: {
                coordinates: [1, 1, 0, 1, 1],
                boxes: [{x: 772, y: 370}],
                lineDeltaY: -30,
                color: '#41f5fa'
            },
            16: {
                coordinates: [1, 1, 2, 1, 1],
                boxes: [{x: 772, y: 90}],
                lineDeltaY: 30,
                color: '#adcab5'
            },
            17: {
                coordinates: [0, 2, 0, 2, 0],
                boxes: [{x: 772, y: 210}],
                lineDeltaY: -20,
                color: '#76ff31'
            },
            18: {
                coordinates: [2, 0, 2, 0, 2],
                boxes: [{x: 772, y: 350}],
                lineDeltaY: 20,
                color: '#e101aa'
            },
            19: {
                coordinates: [2, 0, 1, 0, 2],
                boxes: [{x: 0, y: 130}],
                lineDeltaY: 30,
                color: '#6cf704'
            },
            20: {
                coordinates: [0, 2, 1, 2, 0],
                boxes: [{x: 772, y: 470}],
                lineDeltaY: -30,
                color: '#f201a8'
            },
            21: {
                coordinates: [0, 2, 2, 2, 0],
                boxes: [{x: 0, y: 270}],
                lineDeltaY: -40,
                color: '#5dfa0e'
            },
            22: {
                coordinates: [2, 0, 0, 0, 2],
                boxes: [{x: 0, y: 290}],
                lineDeltaY: 40,
                color: '#ee25ff'
            },
            23: {
                coordinates: [1, 0, 2, 0, 1],
                boxes: [{x: 0, y: 430}],
                lineDeltaY: -10,
                color: '#1dd3dd'
            },
            24: {
                coordinates: [1, 2, 0, 2, 1],
                boxes: [{x: 772, y: 330}],
                lineDeltaY: 10,
                color: '#2063e5'
            },
            25: {
                coordinates: [0, 0, 2, 0, 0],
                boxes: [{x: 772, y: 450}],
                lineDeltaY: -50,
                color: '#8beb05'
            },
            26: {
                coordinates: [2, 2, 0, 2, 2],
                boxes: [{x: 772, y: 110}],
                lineDeltaY: 50,
                color: '#c013d4'
            },
            27: {
                coordinates: [1, 0, 1, 0, 1],
                boxes: [{x: 772, y: 230}],
                lineDeltaY: -40,
                color: '#0cf3c9'
            },
            28: {
                coordinates: [1, 2, 1, 2, 1],
                boxes: [{x: 0, y: 150}],
                lineDeltaY: 40,
                color: '#7b12d9'
            },
            29: {
                coordinates: [2, 2, 2, 1, 0],
                boxes: [{x: 0, y: 190}],
                lineDeltaY: -30,
                color: '#36ca87'
            }
        };
        this.lineMap = [...Array(30)].map((item, i) => i);

        JL().debug('-- Lines30 initialized');
    }

    drawBoxes(parentContainer) {

    }
}
