import {JL} from 'jsnlog';
import CustomLines from './../CustomLines';

export default class Lines10 extends CustomLines {
    constructor() {
        super();
        this.imageResources = {
            boxes: '/img/lines/Lines10/amatic/boxes.png'
        };

        this.lines = {
            0: {coordinates: [1, 1, 1, 1, 1], boxes: [{x: 0, y: 268}, {x: 765, y: 268}]},
            1: {coordinates: [0, 0, 0, 0, 0], boxes: [{x: 0, y: 159}, {x: 765, y: 159}]},
            2: {coordinates: [2, 2, 2, 2, 2], boxes: [{x: 0, y: 375}, {x: 765, y: 375}]},
            3: {coordinates: [0, 1, 2, 1, 0], boxes: [{x: 0, y: 123}, {x: 765, y: 123}], lineDeltaY: 45},
            4: {coordinates: [2, 1, 0, 1, 2], boxes: [{x: 0, y: 410}, {x: 765, y: 410}]},
            5: {coordinates: [0, 0, 1, 0, 0], boxes: [{x: 0, y: 195}, {x: 765, y: 195}], lineDeltaY: -45},
            6: {coordinates: [2, 2, 1, 2, 2], boxes: [{x: 0, y: 339}, {x: 765, y: 339}]},
            7: {coordinates: [1, 0, 0, 0, 1], boxes: [{x: 0, y: 232}, {x: 765, y: 232}]},
            8: {coordinates: [1, 2, 2, 2, 1], boxes: [{x: 0, y: 303}, {x: 765, y: 303}]},
            9: {coordinates: [0, 1, 1, 1, 0], boxes: [{x: 0, y: 87}, {x: 765, y: 87}], lineDeltaY: 55}
        };

        JL().debug('-- Lines10 initialized');
    }
}
