import App from './../../../index';
import GameChampion from './../../champion/game';
import Lines20 from './../../champion/lines20';
import GambleChampion from './../../champion/gamble';
import InfoScreen from '../../infoScreen';

export default class HitJewels extends GameChampion {
    constructor() {
        super();
        this.id = 'hit-jewels';
        this.name = 'Hit Jewels';
        this.symbolEffects = true;
        this.transparentBackground = true;
        this.reelTop = 89; // magic number - where reel images starts
        this.symbolHeight = 130; // height of a single symbol
        this.symbolWidth = 130; // width of a single symbol
        this.buttonsPanelShadow = 'strong';

        this.symbols = [
            {regularDelay: 120, payment: [0, 0, 0, 10, 25, 150]},   // 0 - yellow crystal
            {regularDelay: 120, payment: [0, 0, 0, 10, 25, 150]},   // 1 - green crystal
            {regularDelay: 120, payment: [0, 0, 0, 15, 50, 200]},   // 2 - blue crystal
            {regularDelay: 120, payment: [0, 0, 0, 15, 50, 200]},   // 3 - red crystal
            {regularDelay: 120, payment: [0, 0, 0, 30, 150, 500]},  // 4 - money
            {regularDelay: 120, payment: [0, 0, 0, 30, 150, 500]},  // 5 - gold
            {regularDelay: 120, payment: [0, 0, 0, 50, 500, 5000]}, // 6 - crown
            {regularDelay: 120, payment: [0, 0, 0, 2, 10, 50]}      // 7 - euro
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.gameSounds = {soundClass: 'champion'};
        this.Lines = new Lines20();
        this.Gamble = new GambleUnique(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 1}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '14pt franklinFont';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#fff';

        // 7 - euro
        ctx.fillText(bet * nLines * this.symbols[7].payment[5], 455, 260);
        ctx.fillText(bet * nLines * this.symbols[7].payment[4], 455, 295);
        ctx.fillText(bet * nLines * this.symbols[7].payment[3], 455, 330);

        // 6 - crown
        ctx.fillText(bet * this.symbols[6].payment[5], 455, 128);
        ctx.fillText(bet * this.symbols[6].payment[4], 455, 163);
        ctx.fillText(bet * this.symbols[6].payment[3], 455, 198);

        // 5 - gold
        ctx.fillText(bet * this.symbols[4].payment[5], 195, 134);
        ctx.fillText(bet * this.symbols[4].payment[4], 195, 168);
        ctx.fillText(bet * this.symbols[4].payment[3], 195, 202);

        // 4 - money
        ctx.fillText(bet * this.symbols[5].payment[5], 620, 134);
        ctx.fillText(bet * this.symbols[5].payment[4], 620, 168);
        ctx.fillText(bet * this.symbols[5].payment[3], 620, 202);

        // 2 - blue crystal // 3 - red crystal
        ctx.fillText(bet * this.symbols[2].payment[5], 195, 268);
        ctx.fillText(bet * this.symbols[2].payment[4], 195, 302);
        ctx.fillText(bet * this.symbols[2].payment[3], 195, 336);

        // 0 - yellow crystal // 1 - green crystal
        ctx.fillText(bet * this.symbols[0].payment[5], 620, 268);
        ctx.fillText(bet * this.symbols[0].payment[4], 620, 302);
        ctx.fillText(bet * this.symbols[0].payment[3], 620, 336);
    }
}

class GambleUnique extends GambleChampion {
    constructor(imageResources) {
        super(imageResources);
        this.cardsQueuePos = {x: [421, 487, 553, 619, 685], y: 80};
        this.screenCoordinates = {x: 0, y: 86};
    }
}
