import React from 'react';
import {detect} from 'detect-browser';

import './styles.less';
import getUrl from './../../modules/getUrl';
import getPlatform from './../../modules/getPlatform';

const platform = getPlatform();
const os = detect().os;

export default function JackpotLayout(props) {
    const {jackpotsData, jackpotsParams: {jackpotWin, jackpotWinValue, adv} = {}} = props;

    const getJackpotLayout = ({type, amount, minBet, lowerBound, upperBound, current, range, filledRange, currency}, index) =>
        <div
            className={type}
            key={index}
            style={{background: `url(${getUrl('/img/jackpot/border.png) 0 0 / 5.65em no-repeat')}, url(${getUrl('/img/jackpot/background.png) 0.25em 0.2em / 5.20em no-repeat')}`}}>
            <span className={`${type}-title`}>
                <span className={`${type}-title-shiny`}>
                    {type.toUpperCase()}
                </span>
            </span>
            <div className={`${type}-value`}>
                {(amount / 100).toFixed(2)}
            </div>
            {!!jackpotWinValue && <div className={`${type}-value-win`}>{jackpotWinValue.toFixed(2)}</div>}
            {!!minBet && <h2 className='min-bet'>Min bet: {(minBet / 100).toFixed(2)} {currency}</h2>}
            {!!upperBound && <div className={`${type}-progress`}>
                <span
                    className={`range ${range <= 15 ? 'short' : ''}`} // align values by right side if range < 10%
                    style={{width: `${range}%`}}>
                    {`${lowerBound / 100} - ${upperBound / 100}`}
                </span>
                <div className={`${type}-progress-fill`}> {/* change this random value */}
                    <div className={`${type}-progress-fill-inner`} style={{width: `${current}%`}}>
                        {[...Array(5)].map((item, index) => <span
                            key={index}
                            className={os.replace(/\s+/gi, '')}
                            style={{background: `url(${getUrl('/img/jackpot/progress.png) 0 0 / 8em  no-repeat')}`}}/>
                        )}
                        <span className='filled' style={{width: `${filledRange}%`}}/>
                    </div>
                    <span className='fire'>
                        <span
                            className={os.replace(/\s+/gi, '')}
                            style={{background: `url(${getUrl('/img/jackpot/fire.png) 0 0 / 10em  no-repeat')}`}}
                        />
                    </span>
                </div>
            </div>}
        </div>;

    return (
        <section className={`jackpot-layout winning-${jackpotWin} ${platform === 'desktop' ? adv : ''}`}>
            {jackpotsData.map(({jackpot, index}) => getJackpotLayout(jackpot, index))}
        </section>
    );
}
