import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import Lines from './../lines';
import App from './../../index';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Texture = PIXI.Texture;

export default class Lines9 extends Lines {
    constructor() {
        super();
        this.imageResources = {
            lines: '/img/lines/Lines9_igrosoft/lines.png',
            winLines: '/img/lines/Lines9_igrosoft/winLines.png',
            boxes: '/img/lines/Lines9_igrosoft/boxes.png'
        };
        this.lineBoxWidth = 28; // width of winline boxes, magic number for each game
        this.lineBoxHeight = 33; // height of winline boxes, magic number for each game
        this.lineImageWidth = 800; // width of line image in sprite, magic number for each game
        this.lineImageHeight = 110; // height of line image in sprite, magic number for each game

        this.lines = {
            0: {
                coordinates: [1, 1, 1, 1, 1],
                boxes: [
                    {x: 12, y: 264},
                    {x: 758, y: 264}
                ],
                path: [{x: 0, y: 278}],
                width: 8,
                spriteY: 0
            },
            1: {
                coordinates: [0, 0, 0, 0, 0],
                boxes: [
                    {x: 12, y: 84},
                    {x: 758, y: 84}
                ],
                path: [{x: 0, y: 97}],
                width: 7,
                spriteY: 8
            },
            2: {
                coordinates: [2, 2, 2, 2, 2],
                boxes: [
                    {x: 12, y: 445},
                    {x: 758, y: 445}
                ],
                path: [{x: 0, y: 457}],
                width: 7,
                spriteY: 15
            },
            3: {
                coordinates: [0, 1, 2, 1, 0],
                boxes: [
                    {x: 14, y: 164},
                    {x: 758, y: 164}
                ],
                path: [{x: 0, y: 178}],
                width: 235,
                spriteY: 23
            },
            4: {
                coordinates: [2, 1, 0, 1, 2],
                boxes: [
                    {x: 14, y: 364},
                    {x: 758, y: 364}
                ],
                path: [{x: 0, y: 150}],
                width: 235,
                spriteY: 259
            },
            5: {
                coordinates: [0, 0, 1, 0, 0],
                boxes: [
                    {x: 13, y: 126},
                    {x: 759, y: 126}
                ],
                path: [{x: 0, y: 137}],
                width: 116,
                spriteY: 495
            },
            6: {
                coordinates: [2, 2, 1, 2, 2],
                boxes: [
                    {x: 13, y: 404},
                    {x: 759, y: 404}
                ],
                path: [{x: 0, y: 312}],
                width: 116,
                spriteY: 611
            },
            7: {
                coordinates: [1, 2, 2, 2, 1],
                boxes: [
                    {x: 14, y: 301},
                    {x: 758, y: 301}
                ],
                path: [{x: 0, y: 312}],
                width: 83,
                spriteY: 727
            },
            8: {
                coordinates: [1, 0, 0, 0, 1],
                boxes: [
                    {x: 14, y: 221},
                    {x: 758, y: 221}
                ],
                path: [{x: 0, y: 165}],
                width: 83,
                spriteY: 810
            }
        };
        JL().debug('-- Lines21 initialized');
    }

    drawLineImages(lines, winReels, parentContainer, winLine, payment) {
        lines.forEach(lineIndex => {
            const line = this.lines[lineIndex];
            const image = winLine ? 'winLines' : 'lines';

            line.coordinates.forEach(() => {
                const spritePosX = 0;
                const spritePosY = line.spriteY;

                // create line sprite
                const sprite = new Sprite(new Texture(App.Game.getTexture(image), {
                    x: spritePosX,
                    y: spritePosY,
                    width: this.lineImageWidth,
                    height: line['width']
                }));
                sprite.position.set(line.path[0].x, line.path[0].y);
                sprite.name = lineIndex;
                parentContainer.addChild(sprite);
            });
        });
    }

    drawBoxes(parentContainer) {
        parentContainer.removeChildren(); // clear all previous boxes

        const currentLines = App.Game.gameSettings.getCurrentLineMas();
        const boxWidth = this.lineBoxWidth; // width of winline boxes
        const boxHeight = this.lineBoxHeight; // height of winline boxes

        currentLines.forEach(lineIndex => {
            lineIndex--;
            this.lines[lineIndex].boxes.forEach(box => {
                const spritePosX = box.x ? boxWidth : box.x;  // if box.x==0 this is left box else right
                const spritePosY = boxHeight * lineIndex;

                // create box sprite
                const sprite = new Sprite(new Texture(App.Game.getTexture('boxes'), {
                    x: spritePosX,
                    y: spritePosY,
                    width: boxWidth,
                    height: boxHeight
                }));
                const leftRight = box.x < 400 ? 0 : 1;
                sprite.position.set(box.x, box.y);
                sprite.name = lineIndex + '' + leftRight;
                this.addBoxEvents(sprite);
                parentContainer.addChild(sprite);
            });
        });
    }

    animateBoxes(parentContainer, winLines) {
        const currentLines = App.Game.gameSettings.getCurrentLineMas();

        currentLines.forEach(lineIndex => {
            lineIndex--;
            this.lines[lineIndex].boxes.forEach(box => {
                // Анимация номеров линий, после 1го круга анимации
                const leftRight = box.x < 400 ? 0 : 1;

                if (App.Game.getState() === 'ASK_GAMBLE' && winLines.includes(lineIndex)) {
                    const boxObj = parentContainer.getChildByName(lineIndex + '' + leftRight);
                    boxObj.visible = !boxObj.visible;
                }
            });
        });
    }

    stopAnimateBoxes(parentContainer) {
        const currentLines = App.Game.gameSettings.getCurrentLineMas();

        currentLines.forEach(lineIndex => {
            lineIndex--;
            this.lines[lineIndex].boxes.forEach(box => {
                const leftRight = box.x < 400 ? 0 : 1;
                const boxObj = parentContainer.getChildByName(lineIndex + '' + leftRight);
                boxObj.visible = true;
            });
        });
    }

    /**
     * Create events for boxes
     * Show line and stop animate feature on 'pointerover' event
     * @param sprite
     */
    addBoxEvents = sprite => {
        const linesContainer = App.Game.getStageChild('linesContainer');

        sprite.buttonMode = true; // shows hand cursor
        sprite.interactive = true;
        sprite
            .on('pointerover', () => {
                linesContainer.removeChildren();
                App.Game.animationFrameId && App.Game.stopAnimateFeature();
                App.Game.Legends.showJackpot();
                this.drawLineImages([sprite.name.slice(0, 1)], [], linesContainer);
            })
            .on('pointerout', () => {
                linesContainer.removeChildren();
            });
    };
}
