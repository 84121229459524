import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import SymbolInfoCustom from './../../symbolInfo';

export default class SymbolInfoUnique extends SymbolInfoCustom {
    constructor() {
        super();
        this.settings = {
            symbolBorderOffset: {x: 20, y: 20},
            paymentBorderOffset: {left: 70, right: 80} // end animation offset
        };
    }

    /**
     * Create PIXI.Graphics element for using as mask
     * @param parentContainer
     * @returns {PIXI.Graphics}
     */
    createBorderMask(parentContainer) {

    }

    drawPayments(parentContainer, symbolIndex, direction) {
        const payTable = []; // array of payments
        App.Game.symbols[symbolIndex].payment.forEach((pay, key) =>
            pay !== 0 && payTable.push([key, pay]));
        App.Game.drawSymbolInfoPayments(parentContainer, payTable, direction);
    }
}
