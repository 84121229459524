import React from 'react';

import './styles.less';
import App from './../../index';

export default function Popup({params}) {
    return (
        <div className='popup red-shadow red-color' onAnimationEnd={App.removePopupMessage}>
            {params.message}
        </div>
    );
}
