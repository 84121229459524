import React from 'react';

import './styles.less';
import App from './../../index';

export default function Wrap(props) {
    let {lang, wrap: {items, title, handler, animationClass}} = props;
    const brickBackground = <div
        className='brick-bg'
        onClick={event => event.stopPropagation()}
        style={{background: `url(${App.getUrl('/img/wrap-background.png')})`}}/>;

    animationClass = animationClass ? `wrap-panel-${animationClass}` : '';
    const onAnimationEnd = ({animationName}) =>
        animationName === 'fade-screen-out' && App.Game.Buttons.resetWrap();

    return (
        <div className={`wrap ${animationClass}`} onAnimationEnd={onAnimationEnd}>
            <div className='wrap-panel' onClick={event => event.stopPropagation()}>
                {brickBackground}
                <div className='wrap-panel-items'>
                    {items.map((value, index) =>
                        <button className='red-color' key={index} onClick={() => handler && handler(index)}>
                            {value}
                        </button>
                    )}
                </div>
            </div>
            <div className='wrap-title' onClick={event => event.stopPropagation()}>
                {brickBackground}
                <span>{lang[title].toUpperCase()}</span>
            </div>
        </div>
    );
}
