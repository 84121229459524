import {JL} from 'jsnlog';
import * as PIXI from 'pixi.js-legacy';

import App from './../../../index.js';
import GameDeluxe from './../../deluxe/game';
import Lines10 from './../../deluxe/lines10';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';

/* PIXI aliases */
const AnimatedSprite = PIXI.AnimatedSprite;

export default class MagicJoker extends GameDeluxe {
    constructor() {
        super();
        this.id = 'magic-joker';
        this.name = 'Magic Joker';
        this.symbolEffects = true;
        this.frizenJoker = [];
        this.jokerCard = [];
        this.reelFilter = [[12, 13, 14], [12, 13, 14], [11, 12, 13, 14], [12, 13, 14], [12, 13, 14]];
        this.buttonsPanelShadow = 'mid';

        this.symbols = [
            {regularDelay: 100, bonusDelay: 60, payment: [0, 0, 0, 5, 20, 100]},    // 0 - card 9
            {regularDelay: 100, bonusDelay: 60, payment: [0, 0, 0, 5, 20, 100]},    // 1 - card 10
            {regularDelay: 100, bonusDelay: 60, payment: [0, 0, 0, 5, 20, 100]},    // 2 - card J
            {regularDelay: 100, bonusDelay: 60, payment: [0, 0, 0, 5, 20, 100]},    // 3 - card Q
            {regularDelay: 100, bonusDelay: 60, payment: [0, 0, 0, 10, 50, 200]},   // 4 - card K
            {regularDelay: 100, bonusDelay: 60, payment: [0, 0, 0, 10, 50, 200]},   // 5 - card A
            {regularDelay: 100, bonusDelay: 60, payment: [0, 0, 0, 10, 100, 250]},  // 6 - браслет
            {regularDelay: 100, bonusDelay: 60, payment: [0, 0, 0, 10, 100, 250]},  // 7 - кольцо
            {regularDelay: 100, bonusDelay: 60, payment: [0, 0, 0, 10, 100, 250]},  // 8 - ожерелье
            {regularDelay: 100, bonusDelay: 60, payment: [0, 0, 0, 20, 150, 500]},  // 9 - Queen
            {regularDelay: 100, bonusDelay: 60, payment: [0, 0, 0, 50, 250, 1000]}, // 10 - King
            {regularDelay: 80, payment: [0, 0, 10, 100, 500, 2000]},                // 11 - Joker
            {regularDelay: 100, payment: [0, 0, 10, 100, 500, 2000]},               // 12 - Joker top
            {regularDelay: 100, payment: [0, 0, 10, 100, 500, 2000]},               // 13 - Joker middle
            {regularDelay: 100, payment: [0, 0, 10, 100, 500, 2000]}                // 14 - Joker bottom
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                joker: 'area/joker.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            atlas: this.mergePath([
                'bonus/bonusSymbols.json',
                'bonus/bonusSymbols2.json',
                'bonus/additionalSymbols.json',
                'bonus/additionalSymbols2.json'
            ])
        };
        this.gameSounds = {soundClass: 'deluxe'};
        this.Lines = new Lines10();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 15pt Arial';
        ctx.textAlign = 'left';
        ctx.fillStyle = '#ecf798';
        ctx.strokeStyle = '#000';
        ctx.shadowColor = 'black';
        ctx.shadowOffsetX = 3;
        ctx.shadowOffsetY = 3;
        ctx.shadowBlur = 5;

        switch (page) {
            case 1:
                // crown
                this.strokeFillText(ctx, bet * this.symbols[11].payment[5], 540, 110);
                this.strokeFillText(ctx, bet * this.symbols[11].payment[4], 540, 140);
                this.strokeFillText(ctx, bet * this.symbols[11].payment[3], 540, 170);
                this.strokeFillText(ctx, bet * this.symbols[11].payment[2], 540, 200);

                // King
                this.strokeFillText(ctx, bet * this.symbols[10].payment[5], 120, 337);
                this.strokeFillText(ctx, bet * this.symbols[10].payment[4], 120, 367);
                this.strokeFillText(ctx, bet * this.symbols[10].payment[3], 120, 397);

                // Ring Necklace
                this.strokeFillText(ctx, bet * this.symbols[8].payment[5], 415, 337);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[4], 415, 367);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[3], 415, 397);

                // Queen
                this.strokeFillText(ctx, bet * this.symbols[9].payment[5], 685, 337);
                this.strokeFillText(ctx, bet * this.symbols[9].payment[4], 685, 367);
                this.strokeFillText(ctx, bet * this.symbols[9].payment[3], 685, 397);

                // A, K
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 240, 480);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 240, 510);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 240, 540);

                // J, Q, 9, 10
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 550, 480);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 550, 510);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 550, 540);

                ctx.font = 'bold 12pt Arial';
                this.strokeFillText(ctx, 'substitutes on', 225, 130);
                this.strokeFillText(ctx, 'all positions', 225, 155);
                this.strokeFillText(ctx, 'on the reel', 225, 180);
                this.strokeFillText(ctx, 'and places', 225, 205);
                this.strokeFillText(ctx, '1 to 11', 225, 230);
                this.strokeFillText(ctx, 'on the screen', 225, 255);
                this.strokeFillText(ctx, 'SUBSTITUTES', 350, 230);

                ctx.drawImage(this.getImage('joker'), 0, 0, 26, 26, 290, 210, 26, 26);
                break;
            case 2:
                ctx.font = 'bold 15pt Arial';
                ctx.textAlign = 'left';
                ctx.fillStyle = '#ecf798';
                ctx.strokeStyle = '#000';
                ctx.shadowColor = 'black';
                ctx.shadowOffsetX = 3;
                ctx.shadowOffsetY = 3;
                ctx.shadowBlur = 5;

                this.strokeFillText(ctx, 'substitutes on', 290, 130);
                this.strokeFillText(ctx, 'all positions  on', 290, 155);
                this.strokeFillText(ctx, 'the reel and', 290, 180);
                this.strokeFillText(ctx, 'places', 290, 205);
                this.strokeFillText(ctx, '1 to 11', 290, 230);
                this.strokeFillText(ctx, 'on the screen', 290, 255);
                this.strokeFillText(ctx, 'turn into', 290, 360);
                this.strokeFillText(ctx, 'substitutes', 290, 510);
                ctx.drawImage(this.getImage('joker'), 0, 0, 26, 26, 375, 200, 35, 35);
                break;
            case 3:
                ctx.font = 'bold 18pt Arial';
                ctx.textAlign = 'center';
                ctx.fillStyle = '#ecf798';
                ctx.strokeStyle = '#000';
                ctx.shadowColor = 'black';
                ctx.shadowOffsetX = 3;
                ctx.shadowOffsetY = 3;
                ctx.shadowBlur = 5;

                ctx.font = 'bold 22pt Arial';
                this.strokeFillText(ctx, 'RULES', 400, 180);

                ctx.font = 'bold 18pt Arial';
                this.strokeFillText(ctx, 'All prizes are for combinations of a kind. All prizes are for', 400, 240);
                this.strokeFillText(ctx, 'combinations left to right. All prizes are on selected lines. All', 400, 270);
                this.strokeFillText(ctx, 'prizes shown in credits.', 400, 300);
                this.strokeFillText(ctx, 'Malfunction void all pays and plays.', 400, 330);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    decideScatterAnimation(extension) {
        if (extension == null) {
            return false;
        }
        const positions = [];
        const positionsXY = [];
        let leftAnimation = false;
        let rightAnimation = false;
        extension.screen.forEach((reel, reelIndex) => {
            reel.forEach((row, rowIndex) => {
                if (row === 11 && reelIndex !== 2) {
                    positions.push(reelIndex * 3 + rowIndex);
                    positionsXY.push({reelIndex, rowIndex});
                    if ((reelIndex * 3 + rowIndex) < 6) {
                        leftAnimation = true;
                    }
                    if ((reelIndex * 3 + rowIndex) > 8) {
                        rightAnimation = true;
                    }
                }
            });
        });

        this.latestResponse.extension.positions = positions;
        this.latestResponse.extension.positionsXY = positionsXY;
        this.latestResponse.extension.leftAnimation = leftAnimation;
        this.latestResponse.extension.rightAnimation = rightAnimation;

        return extension != null;
    }

    drawBonusAnimation(animationStarted, lastTime, animatePos, iStep) {
        const {features, payment, extension: {leftAnimation, rightAnimation}} = this.latestResponse;
        let exit = 50 + 30 + 20 + 20;
        const timeDiff = Date.now() - lastTime;
        if (timeDiff < 50) {
            requestAnimationFrame(this.drawBonusAnimation.bind(this, animationStarted, lastTime, animatePos, iStep));
            return;
        }

        iStep++;
        const animationLength = 75;
        lastTime = Date.now();

        if (!this.frizenJoker.length) {
            this.frizenJoker[0] = this.createJoker(0); // left
            this.frizenJoker[1] = this.createJoker(1); // right

            for (let i = 0; i < 6; i++) {   // flying card mass
                this.jokerCard[i] = this.createCard();
                this.jokerCard[i].visible = false;
            }
        }

        this.buildTrajectories();

        if (!rightAnimation && !leftAnimation) {  // no left no right
            exit = 0; // no right and left animation decrease exit to zero steps
        }

        if (!leftAnimation && rightAnimation) {  // no left but right
            this.bonusAnimationStepRight(iStep, animatePos);
            exit = animationLength;
        }

        if (!rightAnimation && leftAnimation) { // no right but left
            this.bonusAnimationStepLeft(iStep, animatePos);
            exit = animationLength;
        }

        if (rightAnimation && leftAnimation) { // both left and right animation
            exit = animationLength * 2;
            iStep > animationLength ?
                this.bonusAnimationStepRight(iStep - animationLength, animatePos) :
                this.bonusAnimationStepLeft(iStep, animatePos);
        }

        if (iStep > exit) {
            this.changeDansingJokerToJoker(iStep - exit);
        }

        if (iStep >= exit + 5) {
            if (payment > 0 || features.length) {
                this.setState('SHOW_WIN_LINES');
                this.startAnimateFeature(features);
            } else {
                this.roundFinished();
                this.Legends.setRoundFinText();
            }
            return;
        }

        requestAnimationFrame(this.drawBonusAnimation.bind(this, animationStarted, lastTime, animatePos, iStep));
    }

    buildTrajectories() {
        const aPositions = this.latestResponse.extension;
        this.trajectories.left = [];
        this.trajectories.right = [];
        this.trajectories.positionsLeft = [];
        this.trajectories.positionsRight = [];
        for (let iCounter = 0; iCounter < aPositions.positions.length; iCounter++) {
            const trajectory = [];
            const iReel = aPositions.positionsXY[iCounter].reelIndex,
                iRow = aPositions.positionsXY[iCounter].rowIndex;
            const delta = (iReel > 2) ? 1 : -1;
            trajectory.push({x: this.reelXCoordinates[2], y: this.reelTop + 50});
            trajectory.push({x: this.reelXCoordinates[2] + 10 * delta, y: this.reelTop + 30});
            trajectory.push({x: this.reelXCoordinates[2] + 10 * delta, y: this.reelTop + 10});
            trajectory.push({x: this.reelXCoordinates[2] + 20 * delta, y: this.reelTop});
            trajectory.push({x: this.reelXCoordinates[2] + 60 * delta, y: this.reelTop - 10});

            trajectory.push({x: this.reelXCoordinates[2], y: this.reelTop});
            trajectory.push({x: this.reelXCoordinates[2] + 80 * delta, y: this.reelTop + 10});
            trajectory.push({x: this.reelXCoordinates[2] + 100 * delta, y: this.reelTop + 20});
            trajectory.push({x: this.reelXCoordinates[2] + 120 * delta, y: this.reelTop + 30});
            trajectory.push({x: this.reelXCoordinates[2] + 140 * delta, y: this.reelTop + 40});

            trajectory.push({
                x: this.reelXCoordinates[iReel] - 10 * delta,
                y: this.reelTop + this.symbolHeight * iRow - 80
            });
            trajectory.push({
                x: this.reelXCoordinates[iReel] - 15 * delta,
                y: this.reelTop + this.symbolHeight * iRow - 75
            });
            trajectory.push({
                x: this.reelXCoordinates[iReel] - 20 * delta,
                y: this.reelTop + this.symbolHeight * iRow - 70
            });
            trajectory.push({
                x: this.reelXCoordinates[iReel] - 25 * delta,
                y: this.reelTop + this.symbolHeight * iRow - 65
            });
            trajectory.push({
                x: this.reelXCoordinates[iReel] - 30 * delta,
                y: this.reelTop + this.symbolHeight * iRow - 60
            });
            trajectory.push({
                x: this.reelXCoordinates[iReel] - 35 * delta,
                y: this.reelTop + this.symbolHeight * iRow - 55
            });
            trajectory.push({
                x: this.reelXCoordinates[iReel] - 30 * delta,
                y: this.reelTop + this.symbolHeight * iRow - 50
            });

            trajectory.push({
                x: this.reelXCoordinates[iReel] - 25 * delta,
                y: this.reelTop + this.symbolHeight * iRow - 45
            });

            trajectory.push({
                x: this.reelXCoordinates[iReel] - 20 * delta,
                y: this.reelTop + this.symbolHeight * iRow - 40
            });

            trajectory.push({x: this.reelXCoordinates[iReel] - 10, y: (this.reelTop + this.symbolHeight * iRow) - 10});

            trajectory.push({x: this.reelXCoordinates[iReel], y: this.reelTop + this.symbolHeight * iRow});

            if (delta === -1) {
                this.trajectories.left.push(trajectory);
                this.trajectories.positionsLeft.push({
                    reel: aPositions.positionsXY[iCounter].reelIndex,
                    row: aPositions.positionsXY[iCounter].rowIndex
                });
            } else {
                this.trajectories.right.push(trajectory);
                this.trajectories.positionsRight.push({
                    reel: aPositions.positionsXY[iCounter].reelIndex,
                    row: aPositions.positionsXY[iCounter].rowIndex
                });
            }
        }
    }

    bonusAnimationStepLeft(iStep) {     // time line:  jokerAnimation - card fly - change symbol
        const dropCard = 33;
        const cardDroped = 45;
        const changeJoker = 27;
        // card animation left
        if (iStep === 1) {
            this.frizenJoker[0].visible = true;
            this.frizenJoker[1].visible = false;
            this.frizenJoker[0].play();
        }
        if (iStep > dropCard && iStep < cardDroped) {  // drop card
            for (let i = 0; i < this.trajectories.left.length; i++) {
                const cardPos = iStep - 30;
                const dx = this.trajectories.left[i][cardPos % 15].x - 51;
                const dy = this.trajectories.left[i][cardPos % 15].y - 90;
                this.jokerCard[i].position.x = dx;
                this.jokerCard[i].position.y = dy;
                this.jokerCard[i].visible = true;
                this.jokerCard[i].play();
            }
        }

        if (iStep === cardDroped) { // cad on reel start joker convert animation
            for (let i = 0; i < this.trajectories.positionsLeft.length; i++) {
                const reel = this.trajectories.positionsLeft[i].reel;
                const row = this.trajectories.positionsLeft[i].row;
                this.jokerCard[i].visible = false;
                this.jokerCard[i].stop();
                this.changeSymbolToJoker(reel, row);
            }
        }
        if (iStep === cardDroped + changeJoker) { // change joker to joker
            this.changeJokerToJoker();
        }
    }

    bonusAnimationStepRight(iStep) {     // time line:  jokerAnimation - card fly - change symbol
        const dropCard = 33;
        const cardDroped = 45;
        const changeJoker = 27;
        // card animation left
        if (iStep === 1) {
            this.frizenJoker[1].visible = true;
            this.frizenJoker[1].play();
            this.frizenJoker[0].visible = false;
        }
        if (iStep > dropCard && iStep < cardDroped) {  // drop card
            for (let i = 0; i < this.trajectories.right.length; i++) {
                const cardPos = iStep - 30;
                const dx = this.trajectories.right[i][cardPos % 15].x - 51;
                const dy = this.trajectories.right[i][cardPos % 15].y - 90;
                this.jokerCard[i].position.x = dx;
                this.jokerCard[i].position.y = dy;
                this.jokerCard[i].visible = true;
                this.jokerCard[i].play();
            }
        }

        if (iStep === cardDroped) { // cad on reel start joker convert animation
            for (let i = 0; i < this.trajectories.positionsRight.length; i++) {
                const reel = this.trajectories.positionsRight[i].reel;
                const row = this.trajectories.positionsRight[i].row;
                this.jokerCard[i].visible = false;
                this.jokerCard[i].stop();
                this.changeSymbolToJoker(reel, row);
            }
        }
        if (iStep === cardDroped + changeJoker) { // change joker to joker
            this.changeJokerToJoker();
        }
    }

    changeDansingJokerToJoker() {
        this.frizenJoker[0].visible = false;
        this.frizenJoker[1].visible = false;

        this.reelMatrix.forEach((reel, reelIndex) => {
            reel.forEach((symbolObj, rowIndex) => {
                if (symbolObj.symbol > 11) {
                    symbolObj.symbol = 11;
                    symbolObj.image = 'bonus';
                    this.Roll.updateSymbolSprite(symbolObj);
                    symbolObj.sprite.gotoAndStop(0);

                    this.lastScreen[reelIndex][rowIndex] = 11;  // to update clip matrix changes for next roll
                    this.latestResponse.screen[reelIndex][rowIndex] = 11;  // to update clip matrix changes for next roll
                }
            });
        });

        this.changeJokerToJoker();
    }

    onRotationDone() {
        JL().debug(`-- Rotation done (fps: ${App.System.statistics.fps})`);
        const {features, payment, extension} = this.latestResponse;
        this.trajectories = [];

        const scatterAnimation = this.decideScatterAnimation(extension);
        if (payment > 0 || features.length) {
            // There is a win
            if (scatterAnimation) {
                this.drawBonusAnimation(Date.now(), Date.now(), scatterAnimation, 0);
            } else {
                if (this.decideJokerToChange(0) || this.decideJokerToChange(1) || this.decideJokerToChange(2)) {
                    this.changeJokerBeforeWinLine();
                } else {
                    this.setState('SHOW_WIN_LINES');
                    this.startAnimateFeature(features);
                }
            }
            // There is a lost
        } else {
            if (scatterAnimation) {
                this.drawBonusAnimation(Date.now(), Date.now(), scatterAnimation, 0);
            } else {
                this.roundFinished();
                this.Legends.setRoundFinText();
            }
        }
    }

    changeJokerToJoker() {
        this.reelMatrix.forEach((reel, reelIndex) => {
            reel.forEach((symbolObj, rowIndex) => {
                if (symbolObj.image === 'bonus') {
                    symbolObj.symbol = 11;
                    symbolObj.image = 'regular';
                    this.Roll.updateSymbolSprite(symbolObj);
                    symbolObj.sprite.gotoAndStop(0);

                    this.lastScreen[reelIndex][rowIndex] = 11;  // to update clip matrix changes for next roll
                    this.latestResponse.screen[reelIndex][rowIndex] = 11;  // to update clip matrix changes for next roll
                }
            });
        });
    }

    decideJokerToChange(pos) {
        const {features} = this.latestResponse;
        const masJoker = [];
        for (let i = 0; i < features.length; i++) {
            this.reelMatrix[2][pos].symbol > 10 &&
            masJoker.push(this.Lines.lines[features[i].number].coordinates[2]);
        }
        return masJoker.indexOf(pos, 0) !== -1;
    }

    changeJokerBeforeWinLine() {
        const {features, payment} = this.latestResponse;
        if (this.decideJokerToChange(0)) {
            this.changeSymbolToJoker(2, 0);
        }
        if (this.decideJokerToChange(1)) {
            this.changeSymbolToJoker(2, 1);
        }
        if (this.decideJokerToChange(2)) {
            this.changeSymbolToJoker(2, 2);
        }

        setTimeout(() => {
            this.changeJokerToJoker();
            if (payment > 0 || features.length) {
                this.setState('SHOW_WIN_LINES');
                this.startAnimateFeature(features);
            } else {
                this.roundFinished();
                this.Legends.setRoundFinText();
            }
        }, 1500);
    }

    changeSymbolToJoker(reelIndex, rowIndex) { // animation with symbol changing by crd to joker
        const symbolObj = this.reelMatrix[reelIndex][rowIndex];
        symbolObj.image = 'bonus';
        this.Roll.updateSymbolSprite(symbolObj);
        symbolObj.sprite.play();

        this.lastScreen[reelIndex][rowIndex] = 11;  // to update clip matrix changes for next roll
        this.latestResponse.screen[reelIndex][rowIndex] = 11;  // to update clip matrix changes for next roll
    }

    // ************************filtration section

    getRandomSymbol(length, reelIndex, symbolBefore) {
        let symbol = Math.floor(Math.random() * length);

        while (
            symbol === this.reelFilter[reelIndex][0] ||
            symbol === this.reelFilter[reelIndex][1] ||
            symbol === this.reelFilter[reelIndex][2] ||
            symbol === symbolBefore ||
            symbol === 12 || symbol === 13 || symbol === 14) {
            symbol = Math.floor(Math.random() * length);
        }
        return symbol;
    }

    additionalFilter = reelMas => {
        reelMas.forEach((vector, reelIndex) => {     // прорисовка лент на канвах
            vector.forEach((symbol, rowIndex) => {
                if (reelIndex === 2) {
                    if (symbol === 14) {
                        if (rowIndex - 1 >= 0 && reelMas[reelIndex][rowIndex - 1] !== 11) reelMas[reelIndex][rowIndex - 1] = (symbol - 1);
                        if (rowIndex - 2 >= 0 && reelMas[reelIndex][rowIndex - 2] !== 11) reelMas[reelIndex][rowIndex - 2] = (symbol - 2);
                    }
                    if (symbol === 13) {
                        if (rowIndex - 1 >= 0 && reelMas[reelIndex][rowIndex - 1] !== 11) reelMas[reelIndex][rowIndex - 1] = (symbol - 1);
                        if (rowIndex + 1 < reelMas[reelIndex].length && reelMas[reelIndex][rowIndex + 1] !== 11) reelMas[reelIndex][rowIndex + 1] = (symbol + 1);
                    }
                    if (symbol === 12) {
                        if (rowIndex + 1 < reelMas[reelIndex].length && reelMas[reelIndex][rowIndex + 1] !== 11) reelMas[reelIndex][rowIndex + 1] = (symbol + 1);
                        if (rowIndex + 2 < reelMas[reelIndex].length && reelMas[reelIndex][rowIndex + 2] !== 11) reelMas[reelIndex][rowIndex + 2] = (symbol + 2);
                    }
                }
            });
        });
        return reelMas;
    };

    /**
     * Create on reels full symbol
     * @param reelMas
     */
    addLongSymbol = reelMas => {
        const reelIndex = Math.floor(1 + Math.random() * 6);
        if (reelIndex === 2) {
            reelMas[reelIndex][this.reelRows + 3] = 12;
            reelMas[reelIndex][this.reelRows + 4] = 13;
            reelMas[reelIndex][this.reelRows + 5] = 14;
        }
        return reelMas;
    };

    createJoker(number) {
        const textures = this.Roll.textures.additional[number];
        const parentContainer = this.getStageChild('bonusContainer');
        const sprite = new AnimatedSprite(textures);

        sprite.animationSpeed = 0.28;
        sprite.position.x = this.reelXCoordinates[2];
        sprite.position.y = this.reelTop;
        sprite.stop();
        parentContainer.addChild(sprite);

        return sprite;
    }

    createCard() {
        const textures = this.Roll.textures.bonus[11];
        const parentContainer = this.getStageChild('bonusContainer');
        const sprite = new AnimatedSprite(textures);

        sprite.animationSpeed = 0.3;
        sprite.position.x = this.reelXCoordinates[2] - 51;
        sprite.position.y = this.reelTop;
        sprite.stop();
        parentContainer.addChild(sprite);

        return sprite;
    }

    cleanBeforeRoll() {
        if (this.frizenJoker.length) {
            this.frizenJoker[0].visible = false;
            this.frizenJoker[1].visible = false;
        }
        this.frizenJoker = [];
        this.stopAnimateFeature();
        App.removePopupMessage();
        App.System.resetRollStatistics();
        this.InfoScreen.update({timeout: false, page: 1});
        this.latestResponse = null;

        if (!this.isBonus() && this.getState() !== 'IDLE_BONUS') {
            this.Legends.showJackpot();
            App.Money.withDraw(this.gameSettings.getBet());
            App.updateState('moneyParams', {
                credits: App.Money.getCredit(),
                money: App.Money.getMoney()
            });
        }
        this.Legends.clearStatus();
        this.Legends.clearText('features');
        this.Legends.clearText('win');
    }
}
