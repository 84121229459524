import GameNovomatic from './../novomatic/game';

export default class GameChampion extends GameNovomatic {
    constructor() {
        super();
        this.reelXCoordinates = [65, 200, 335, 470, 605]; // magic numbers - x coordinates where reels starts

        // roll properties
        this.reelSettings = [15, 3, 3]; // 0 - start symbol amount, 1 - reel increase regular roll, 2 - reel increase long roll
        this.rollProperties = {
            reelSpeed: 1.6,
            springDown: 0.1,
            springUp: 0.1
        };
    }
}
