import {JL} from 'jsnlog';
import * as PIXI from 'pixi.js-legacy';

import App from './../../index';
import Lines from './../lines';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Texture = PIXI.Texture,
    Text = PIXI.Text;

export default class Lines20 extends Lines {
    constructor() {
        super();
        this.imageResources = {
            lines: '/img/lines/Lines20_champion/lines20.png',
            winLines: '/img/lines/Lines20_champion/winlines20.png',
            winLinesBox: '/img/lines/Lines20_champion/winlines20_boxes.png',
            boxes: '/img/lines/Lines20_champion/boxes20.png'
        };

        this.lineImageWidth = 1396; // width of line image in sprite, magic number for each game
        this.lineImageHeight = 381; // height of line image in sprite, magic number for each game

        this.lines = {
            0: {
                coordinates: [1, 1, 1, 1, 1],
                boxes: [{x: 18, y: 287}],
                path: [
                    {x: 52, y: 162},
                    {x: 197, y: 162},
                    {x: 333, y: 162},
                    {x: 469, y: 162},
                    {x: 605, y: 162}
                ],
                text: [
                    {x: 108, y: 235},
                    {x: 244, y: 236},
                    {x: 380, y: 236}
                ]
            },
            1: {
                coordinates: [0, 0, 0, 0, 0],
                boxes: [{x: 18, y: 133}],
                path: [
                    {x: 52, y: 36},
                    {x: 197, y: 36},
                    {x: 333, y: 36},
                    {x: 469, y: 36},
                    {x: 605, y: 36}
                ],
                text: [
                    {x: 108, y: 106},
                    {x: 244, y: 107},
                    {x: 380, y: 107}
                ]
            },
            2: {
                coordinates: [2, 2, 2, 2, 2],
                boxes: [{x: 18, y: 395}],
                path: [
                    {x: 52, y: 298},
                    {x: 197, y: 298},
                    {x: 333, y: 298},
                    {x: 469, y: 298},
                    {x: 605, y: 298}
                ],
                text: [
                    {x: 108, y: 362},
                    {x: 244, y: 363},
                    {x: 380, y: 363}
                ]
            },
            3: {
                coordinates: [0, 1, 2, 1, 0],
                boxes: [{x: 18, y: 100}],
                path: [
                    {x: 52, y: 15},
                    {x: 197, y: 143},
                    {x: 333, y: 271},
                    {x: 469, y: 143},
                    {x: 605, y: 15}
                ],
                text: [
                    {x: 108, y: 106},
                    {x: 244, y: 236},
                    {x: 380, y: 363}
                ]
            },
            4: {
                coordinates: [2, 1, 0, 1, 2],
                boxes: [{x: 18, y: 430}],
                path: [
                    {x: 52, y: 291},
                    {x: 197, y: 163},
                    {x: 333, y: 35},
                    {x: 469, y: 163},
                    {x: 605, y: 291}
                ],
                text: [
                    {x: 108, y: 362},
                    {x: 244, y: 236},
                    {x: 380, y: 107}
                ]
            },
            5: {
                coordinates: [1, 0, 0, 0, 1],
                boxes: [{x: 18, y: 225}],
                path: [
                    {x: 52, y: 134},
                    {x: 197, y: 6},
                    {x: 333, y: 6},
                    {x: 469, y: 48},
                    {x: 605, y: 134}
                ],
                text: [
                    {x: 108, y: 235},
                    {x: 244, y: 107},
                    {x: 380, y: 107}
                ]
            },
            6: {
                coordinates: [1, 2, 2, 2, 1],
                boxes: [{x: 18, y: 318}],
                path: [
                    {x: 52, y: 154},
                    {x: 197, y: 282},
                    {x: 333, y: 282},
                    {x: 469, y: 282},
                    {x: 605, y: 154}
                ],
                text: [
                    {x: 108, y: 235},
                    {x: 244, y: 363},
                    {x: 380, y: 363}
                ]
            },
            7: {
                coordinates: [0, 0, 1, 2, 2],
                boxes: [{x: 18, y: 166}],
                path: [
                    {x: 52, y: 39},
                    {x: 197, y: 39},
                    {x: 333, y: 167},
                    {x: 469, y: 295},
                    {x: 605, y: 295}
                ],
                text: [
                    {x: 108, y: 106},
                    {x: 244, y: 107},
                    {x: 380, y: 236}
                ]
            },
            8: {
                coordinates: [2, 2, 1, 0, 0],
                boxes: [{x: 18, y: 363}],
                path: [
                    {x: 52, y: 274},
                    {x: 197, y: 274},
                    {x: 333, y: 146},
                    {x: 469, y: 18},
                    {x: 605, y: 18}
                ],
                text: [
                    {x: 108, y: 362},
                    {x: 244, y: 363},
                    {x: 380, y: 236}
                ]
            },
            9: {
                coordinates: [1, 0, 1, 2, 2],
                boxes: [{x: 18, y: 256}],
                path: [
                    {x: 52, y: 208},
                    {x: 197, y: 80},
                    {x: 333, y: 208},
                    {x: 469, y: 336},
                    {x: 605, y: 336}
                ],
                text: [
                    {x: 108, y: 235},
                    {x: 244, y: 107},
                    {x: 380, y: 236}
                ]
            },
            10: {
                coordinates: [1, 2, 1, 2, 1],
                boxes: [{x: 753, y: 285}],
                path: [
                    {x: 51, y: 155},
                    {x: 196, y: 283},
                    {x: 332, y: 154},
                    {x: 468, y: 283},
                    {x: 604, y: 156}
                ],
                text: [
                    {x: 108, y: 235},
                    {x: 244, y: 363},
                    {x: 380, y: 236}
                ]
            },
            11: {
                coordinates: [1, 0, 1, 0, 1],
                boxes: [{x: 753, y: 253}],
                path: [
                    {x: 51, y: 187},
                    {x: 196, y: 59},
                    {x: 332, y: 187},
                    {x: 468, y: 59},
                    {x: 604, y: 187}
                ],
                text: [
                    {x: 108, y: 235},
                    {x: 244, y: 107},
                    {x: 380, y: 236}
                ]
            },
            12: {
                coordinates: [0, 1, 0, 1, 0],
                boxes: [{x: 753, y: 130}],
                path: [
                    {x: 51, y: 31},
                    {x: 196, y: 159},
                    {x: 332, y: 31},
                    {x: 468, y: 159},
                    {x: 604, y: 31}
                ],
                text: [
                    {x: 108, y: 106},
                    {x: 244, y: 236},
                    {x: 380, y: 107}
                ]
            },
            13: {
                coordinates: [2, 1, 2, 1, 2],
                boxes: [{x: 753, y: 400}],
                path: [
                    {x: 51, y: 321},
                    {x: 196, y: 193},
                    {x: 332, y: 321},
                    {x: 468, y: 193},
                    {x: 604, y: 321}
                ],
                text: [
                    {x: 108, y: 362},
                    {x: 244, y: 236},
                    {x: 380, y: 363}
                ]
            },
            14: {
                coordinates: [1, 1, 0, 1, 1],
                boxes: [{x: 753, y: 222}],
                path: [
                    {x: 51, y: 187},
                    {x: 196, y: 187},
                    {x: 332, y: 59},
                    {x: 468, y: 187},
                    {x: 604, y: 187}
                ],
                text: [
                    {x: 108, y: 235},
                    {x: 244, y: 236},
                    {x: 380, y: 107}
                ]
            },
            15: {
                coordinates: [1, 1, 2, 1, 1],
                boxes: [{x: 753, y: 315}],
                path: [
                    {x: 51, y: 170},
                    {x: 196, y: 170},
                    {x: 332, y: 298},
                    {x: 468, y: 170},
                    {x: 604, y: 170}
                ],
                text: [
                    {x: 108, y: 235},
                    {x: 244, y: 236},
                    {x: 380, y: 363}
                ]
            },
            16: {
                coordinates: [0, 0, 1, 0, 0],
                boxes: [{x: 753, y: 94}],
                path: [
                    {x: 51, y: 30},
                    {x: 196, y: 30},
                    {x: 332, y: 158},
                    {x: 468, y: 30},
                    {x: 604, y: 30}
                ],
                text: [
                    {x: 108, y: 106},
                    {x: 244, y: 107},
                    {x: 380, y: 236}
                ]
            },
            17: {
                coordinates: [2, 2, 1, 2, 2],
                boxes: [{x: 753, y: 433}],
                path: [
                    {x: 51, y: 297},
                    {x: 196, y: 297},
                    {x: 332, y: 168},
                    {x: 468, y: 297},
                    {x: 604, y: 297}
                ],
                text: [
                    {x: 108, y: 362},
                    {x: 244, y: 363},
                    {x: 380, y: 236}
                ]
            },
            18: {
                coordinates: [0, 1, 1, 1, 0],
                boxes: [{x: 753, y: 164}],
                path: [
                    {x: 51, y: 43},
                    {x: 196, y: 171},
                    {x: 332, y: 171},
                    {x: 468, y: 171},
                    {x: 604, y: 43}
                ],
                text: [
                    {x: 108, y: 106},
                    {x: 244, y: 236},
                    {x: 380, y: 236}
                ]
            },
            19: {
                coordinates: [2, 1, 1, 1, 2],
                boxes: [{x: 753, y: 363}],
                path: [
                    {x: 51, y: 295},
                    {x: 196, y: 167},
                    {x: 332, y: 167},
                    {x: 468, y: 167},
                    {x: 604, y: 295}
                ],
                text: [
                    {x: 108, y: 362},
                    {x: 244, y: 236},
                    {x: 380, y: 236}
                ]
            }
        };

        JL().debug('-- Lines20 initialized');
    }

    drawLineImages(lines, winReels, parentContainer, winLine, payment) {
        lines.forEach(lineIndex => {
            const line = this.lines[lineIndex];
            const winStartPos = winReels === 0 ? 0 : winReels[0];

            line.coordinates.forEach((rowIndex, reelIndex) => {
                const image = winReels.includes(reelIndex) ?
                    (winStartPos === reelIndex) ?
                        'winLinesBox' : 'winLines' :
                    'lines';

                this.lineImageWidth = 136; // width of line image in sprite, magic number for each game
                this.lineImageHeight = 212; // height of line image in sprite, magic number for each game
                let spritePosX = this.lineImageWidth * reelIndex;
                const spritePosY = this.lineImageHeight * (lineIndex % 10);

                this.lineImageWidth = (reelIndex === 0 || reelIndex === 4) ? 145 : this.lineImageWidth;
                spritePosX += reelIndex !== 0 ? 9 : 0;
                const newLineIndex = lineIndex > 9 ? (spritePosX + 698) : spritePosX;

                const sprite = new Sprite(new Texture(App.Game.getTexture(image), {
                    x: newLineIndex,
                    y: spritePosY,
                    width: this.lineImageWidth,
                    height: this.lineImageHeight
                }));
                sprite.position.set(line.path[reelIndex].x, line.path[reelIndex].y);
                sprite.name = lineIndex;
                parentContainer.addChild(sprite);
                if (winLine && winStartPos < 3) {
                    this.addLineWin(parentContainer, payment, line.text[winStartPos].x, line.text[winStartPos].y);
                }
            });
        });
    }

    drawBoxes(parentContainer) {
        const currentBet = App.Game.gameSettings.getBetLineCredit(),
            currentLines = App.Game.gameSettings.getCurrentLineMas();
        const boxWidth = 28; // width of winline boxes
        const boxHeight = 28; // height of winline boxes

        currentLines.forEach(lineIndex => {
            lineIndex--;
            this.lines[lineIndex].boxes.forEach(box => {
                const spritePosY = lineIndex * boxHeight;
                const screenPosX = box.x;
                const screenPosY = box.y;

                // create box sprite
                const sprite = new Sprite(new Texture(App.Game.getTexture('boxes'), {
                    x: 0,
                    y: spritePosY, // sx, sy,
                    width: boxWidth,
                    height: boxHeight
                }));
                sprite.position.set(screenPosX, screenPosY);
                sprite.name = lineIndex;
                this.addBoxEvents(sprite);
                parentContainer.addChild(sprite);

                const text = new Text(currentBet, {
                    fontFamily: 'Arial',
                    fontSize: 11,
                    fill: '#fbf4ff',
                    lineJoin: 'round'
                });
                text.anchor.set(0.5);
                text.position.set(box.x + boxWidth / 2, box.y + boxHeight / 2);
                parentContainer.addChild(text);
            });
        });
    }
}
