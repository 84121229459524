const wrapper = document.getElementById('wrapper');

export default class Wrapper {
    constructor(resolution) {
        this.resolution = resolution; // '16x9', '4x3'
        this.platform = 'desktop';
        this.browser = null;
        this.doubleScreen = false;
        this.resizeRAF = null; // saved requestAnimationFrame
        this.resizeCallback = null; // on resize() event
        this.lastSize = {}; // width and height of last resizing
    }

    init() {
        this.resize();
        window.addEventListener('resize', this.resize);
        window.addEventListener('scroll', this.resize);
        window.addEventListener('orientationchange', () => {
            window.scrollTo(0, 0);
            this.resize();

            // fix bug with iOS rotating
            const display = getComputedStyle(wrapper).getPropertyValue('display');
            wrapper.style.display = 'none';
            setTimeout(() => {
                wrapper.style.display = display;
                this.resize();
            }, 100);
        });
    }

    getSize = () => {
        const {innerWidth, innerHeight} = window;
        const orientation = this.getOrientation();
        const resolution = orientation === 'portrait' ? '9x16' : this.resolution;
        const round = (value, step = 1) => Math.round(value * step) / step;
        const resolutions = {
            '16x9': {w: 1280, h: this.doubleScreen ? 1440 : 720},
            '4x3': {w: 960, h: this.doubleScreen ? 1440 : 720},
            '9x16': {w: 960, h: 960 / 9 * 16}
        };
        const {w, h} = resolutions[resolution];

        let scale = innerWidth / w;
        if (h * scale > innerHeight) scale = innerHeight / h;
        return {w, h, scale, scaledW: round(scale * w), scaledH: round(scale * h)};
    };

    /**
     * Get device orientation
     */
    getOrientation = () => {
        const {orientation, screen} = window;
        return this.platform === 'mobile' || !screen.orientation ?
            orientation === 0 || orientation === 180 ? 'portrait' : 'landscape' : // iOS values
            screen.orientation.type.replace('-primary', '');
    };

    resize = () => {
        cancelAnimationFrame(this.resizeRAF);
        const {w, h, scale, scaledW, scaledH} = this.getSize();
        const {scrollY} = window;

        if (scaledW !== this.lastSize.scaledW || scaledH !== this.lastSize.scaledH || scrollY !== this.lastSize.scrollY) {
            wrapper.style.transform = `translate(-50%, -50%) scale(${scale.toFixed(3)})`;
            wrapper.style.top = wrapper.style.left = '50%';
            wrapper.style.width = `${w}px`;
            wrapper.style.height = `${h}px`;
            wrapper.style.fontSize = '16px';
            this.resizeCallback && this.resizeCallback();
        }

        this.lastSize = {scaledW, scaledH, scrollY};
        this.resizeRAF = requestAnimationFrame(this.resize);
    };
};
