import App from './../../../index';
import GameChampionX2 from './../../champion/gameX2';
import Lines17 from './lines';
import GambleChampion from './../../champion/gamble';
import InfoScreen from '../../infoScreen';

export default class HotSlot extends GameChampionX2 {
    constructor() {
        super();
        this.id = 'hot-slot';
        this.name = 'Hot Slot';
        this.reels = 3;
        this.reelRows = 3; // number of rows per reel
        this.reelTop = 110; // magic number - where reel images starts
        this.reelXCoordinates = [166, 347, 528]; // magic numbers - x coordinates where reels starts
        this.symbolHeight = 110; // height of aa single symbol
        this.symbolWidth = 110; // height of aa single symbol
        this.transparentBackground = true; // use transparent symbols background
        this.symbolEffects = true;
        this.multiplier = false;
        this.buttonsPanelShadow = 'low no-blur';

        this.symbols = [
            {regularDelay: 70, skipSteps: 2, payment: [0, 0, 0, 5]},   // 0 - XXX
            {regularDelay: 70, skipSteps: 2, payment: [0, 0, 0, 40]},  // 1 - lemon
            {regularDelay: 70, skipSteps: 2, payment: [0, 0, 0, 40]},  // 2 - plum
            {regularDelay: 70, skipSteps: 2, payment: [0, 0, 0, 40]},  // 3 - orange
            {regularDelay: 70, skipSteps: 2, payment: [0, 0, 0, 40]},  // 4 - cherry
            {regularDelay: 70, skipSteps: 2, payment: [0, 0, 0, 60]},  // 5 - BAR
            {regularDelay: 70, skipSteps: 2, payment: [0, 0, 0, 200]}, // 6 - stars
            {regularDelay: 70, skipSteps: 2, payment: [0, 0, 0, 750]}  // 7 - '77'
        ];
        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png',
                x2: 'x2.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.gameSounds = {soundClass: 'champion'};
        this.Lines = new Lines17();
        this.Gamble = new GambleChampion(this.mergePath({gambleArea: 'gamble/gamble-area.png'}));
        this.InfoScreen = new InfoScreen({pages: 2}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '16pt Arial';
        ctx.textAlign = 'center';
        ctx.strokeStyle = '#660000';
        ctx.fillStyle = '#fff';
        ctx.lineWidth = 2;

        switch (page) {
            case 1:
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 625, 160); // '77'
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 625, 235); // stars
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 625, 310); // BAR
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 460, 401); // cherry
                this.strokeFillText(ctx, bet * this.symbols[3].payment[3], 340, 401); // orange
                this.strokeFillText(ctx, bet * this.symbols[2].payment[3], 340, 370); // plum
                this.strokeFillText(ctx, bet * this.symbols[1].payment[3], 460, 370); // lemon
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 625, 460); // XXX
                break;
        }
    }
}
