const bonusFont = {
    fontWidthArray: [29.56, 25.56, 27.63, 30.00, 22.50, 23.50, 28.44, 29.94, 16.38, 21.62,
        28.35, 24.47, 32.22, 28.35, 28.97, 23.20, 32.33, 23.88, 23.25, 27.19,
        27.75, 27.75, 34.56, 27.00, 29.81, 27.88,

        22.19, 23.94, 22.38, 25.69, 21.88, 20.19, 23.56, 22.75, 14.25, 19.56,
        24.63, 15.00, 32.88, 24.31, 23.50, 22.56, 25.44, 19.81, 22.56, 19.50,
        25.06, 23.30, 28.50, 21.81, 23.56, 20,

        18.94, 21.41, 21.28, 23.44, 21.57, 24.98, 23.13, 23.06, 22.88, 23.78,
        22.75, 17.50, 19.38, 21],
    fontHeight: 46,
    symbolsOrder: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
        'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',
        'U', 'V', 'W', 'X', 'Y', 'Z',

        'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j',
        'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't',
        'u', 'v', 'w', 'x', 'y', 'z',

        '1', '2', '3', '4', '5', '6', '7', '8', '9', '0',
        ' ', ',', '.', '-'],
    imageResource: 'font/bonusFont.png'
};

export default bonusFont;
