import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import Grid from './grid';
import Track from './track';
import rouletteNumbers from './rouletteNumbers';

/* PIXI aliases */
const Texture = PIXI.Texture,
    Sprite = PIXI.Sprite,
    Container = PIXI.Container,
    Graphics = PIXI.Graphics,
    Polygon = PIXI.Polygon;

export default function createCells(parentContainer) {
    const betInfoContainer = this.getStageChild('betInfoContainer');
    const container = new Container();
    container.position.set(190, 130);
    container.name = 'cells';
    container.zIndex = 0;

    // create grid
    const gridBackground = new Sprite(new Texture(this.getTexture('table_atlas'), {
        x: 0, y: 247,
        width: 538, height: 361
    }));
    gridBackground.name = 'gridBackground';
    gridBackground.position.set(22, 43);
    const gridContainer = new Container();
    gridContainer.name = 'gridContainer';
    gridContainer.interactive = true;
    gridContainer.on('pointerout', () => (betInfoContainer.alpha = 0));
    container.addChild(gridBackground, gridContainer);

    let cellsX = 15, cellsY = 28;
    let
        A = {x: 0, y: 145},
        B = {x: 170, y: 35},
        C = {x: 560, y: 271},
        D = {x: 395, y: 425};

    const getCoordinates = (posX, posY) => {
        const [stepX, stepY] = getPosProjection(posX, posY);

        const getPosBetweenPoints = (point1, point2, steps, pos) =>
            Math.abs(point1 - point2) / steps * pos;

        const x1 = A.x + getPosBetweenPoints(A.x, B.x, cellsX, stepX);
        const y1 = A.y - getPosBetweenPoints(A.y, B.y, cellsX, stepX);

        const x2 = D.x + getPosBetweenPoints(C.x, D.x, cellsX, stepX);
        const y2 = D.y - getPosBetweenPoints(C.y, D.y, cellsX, stepX);

        return [
            x1 + getPosBetweenPoints(x1, x2, cellsY, stepY),
            y1 + getPosBetweenPoints(y1, y2, cellsY, stepY)
        ];
    };

    const xProjection = 0.04;
    let yProjection = 0.18;
    const getPosProjection = (posX, posY) => ([
        posX * (1 + xProjection - xProjection / cellsX * posX),
        posY * (1 - yProjection + yProjection / cellsY * posY)
    ]);

    Grid.forEach((item, index) => {
        const {idx, numbers, size: {w, h}, pos: {x, y}, fill} = item;

        const cell = new Graphics();
        cell.position.set(...getCoordinates(x, y));
        let polygon = index === 0 ? [
            // custom polygon for zero
            ...getCoordinates(x, y + h / 5 * 3), ...getCoordinates(x + w / 2, y + h / 4), ...getCoordinates(x + w, y + h / 5 * 3),
            ...getCoordinates(x + w, y + h), ...getCoordinates(x, y + h)
        ] : [
            ...getCoordinates(x, y), ...getCoordinates(x + w, y),
            ...getCoordinates(x + w, y + h), ...getCoordinates(x, y + h)
        ];

        // subtract start pos
        polygon = polygon.map((item, index) => item - getCoordinates(x, y)[index % 2]);

        item.polygon = polygon;
        item.width = Math.round(polygon[index === 0 ? 6 : 4] * 100) / 100;
        item.height = Math.round(Math.abs(polygon[index === 0 ? 5 : 3] - polygon[index === 0 ? 9 : 7]) * 100) / 100;

        const pointerDown = () => {
            App.Sounds.playSound('sprite', 'chip_on_chip');
            this.addBet(item);
            this.showBetInfo(cell, item);
        };

        cell.beginFill(fill && 0xFFFFFF);
        cell.drawPolygon(new Polygon(polygon));
        cell.endFill();
        cell.alpha = 0;
        cell.buttonMode = true;
        cell.interactive = true;
        cell.name = idx;
        cell
            .on('rightdown', () => {
                App.Sounds.playSound('sprite', 'chip_on_chip');
                this.removeBet(idx);
                this.showBetInfo(cell, item);
            })
            .on('click', pointerDown)
            .on('tap', pointerDown)
            .on('pointerover', () => {
                this.setDefaultHoverAlpha();
                betInfoContainer.alpha = 1;
                setTimeout(() => {
                    numbers.forEach(number => gridContainer.getChildByName(number).alpha = 0.3);
                    cell.alpha = fill ? 0.3 : 0;
                }, 1);
                this.showBetInfo(cell, item);
            })
            .on('pointerout', this.setDefaultHoverAlpha);

        gridContainer.addChild(cell);
    });

    // create track
    const trackBackground = new Sprite(new Texture(this.getTexture('table_atlas'), {
        x: 395, y: 0,
        width: 404, height: 245
    }));
    trackBackground.position.set(213, 1);
    trackBackground.name = 'trackBackground';
    const trackContainer = new Container();
    trackContainer.name = 'trackContainer';
    container.addChild(trackBackground, trackContainer);

    yProjection = 0.1;
    cellsX = 9;
    cellsY = 38;
    A = {x: 189, y: 39};
    B = {x: 260, y: -10};
    C = {x: 614, y: 189};
    D = {x: 545, y: 250};

    Track.forEach((item, index) => {
        const {typeOfBet, numbers, size: {w, h}, pos: {x, y}} = item;

        const cell = new Graphics();
        cell.position.set(...getCoordinates(x, y));
        let polygon = [];

        switch (typeOfBet) {
            case 'TIER':
                polygon = [
                    ...getCoordinates(x, y + 1.2),
                    ...getCoordinates(x + w / 4 - 0.4, y + 0.6),
                    ...getCoordinates(x + w / 2, y - 0),
                    ...getCoordinates(x + w / 4 * 3 + 0.2, y + 0.4),
                    ...getCoordinates(x + w, y + 1),
                    ...getCoordinates(x + w, y + h - 4), ...getCoordinates(x, y + h)
                ];
                break;
            case 'ORPHELINS':
                polygon = [
                    ...getCoordinates(x, y), ...getCoordinates(x + w, y - 4),
                    ...getCoordinates(x + w, y + h), ...getCoordinates(x, y + h)
                ];
                break;
            case 'VOISINS':
                polygon = [
                    ...getCoordinates(x, y), ...getCoordinates(x + w, y),
                    ...getCoordinates(x + w, y + h),

                    ...getCoordinates(x + w / 4 * 3, y + h - 0.8),
                    ...getCoordinates(x + w / 2, y + h - 1),
                    ...getCoordinates(x + w / 4, y + h - 0.8),

                    ...getCoordinates(x, y + h)
                ];
                break;
            case 'ZERO':
                polygon = [
                    ...getCoordinates(x, y + 1),

                    ...getCoordinates(x + w / 4, y + 0.2),
                    ...getCoordinates(x + w / 2, y),
                    ...getCoordinates(x + w / 4 * 3, y + 0.2),

                    ...getCoordinates(x + w, y + 1),

                    ...getCoordinates(x + w, y + h - 1),

                    ...getCoordinates(x + w / 4 * 3 + 0.2, y + h - 0.2),
                    ...getCoordinates(x + w / 2, y + h + 0.1),
                    ...getCoordinates(x + w / 4 - 0.2, y + h - 0.2),

                    ...getCoordinates(x, y + h - 1)
                ];
                break;
            default:
                polygon = [
                    ...getCoordinates(x, y), ...getCoordinates(x + w, y),
                    ...getCoordinates(x + w, y + h), ...getCoordinates(x, y + h)
                ];
                break;
        }

        switch (rouletteNumbers[index]) {
            case 0:
                polygon = [
                    ...getCoordinates(x, y),
                    ...getCoordinates(x + w / 3 * 2, y),
                    ...getCoordinates(x + 3.5, y + 0.7),
                    ...getCoordinates(x + w, y + h / 4),

                    ...getCoordinates(x + w, y + h + 0.5),
                    ...getCoordinates(x + w / 3 * 2, y + h + 0.3),
                    ...getCoordinates(x + w / 2 - 0.2, y + h - 0.2),
                    ...getCoordinates(x + w / 4 - 0.5, y + h - 1.5),
                    ...getCoordinates(x + 0.1, y + h / 4)
                ];
                break;
            case 26:
                polygon = [
                    ...getCoordinates(x, y), ...getCoordinates(x + w / 3, y - 0.2),
                    ...getCoordinates(x + w + 0.3, y + h - 0.9),
                    ...getCoordinates(x + w / 2, y + h + 0.2),
                    ...getCoordinates(x, y + h + 0.5)
                ];
                break;
            case 3:
                polygon = [
                    ...getCoordinates(x, y), ...getCoordinates(x + w, y),
                    ...getCoordinates(x + w - 0.2, y + h / 2),
                    ...getCoordinates(x + 2.2, y + h - 0.5),
                    ...getCoordinates(x + w - 1.2, y + h + 0.2), ...getCoordinates(x - 0.6, y + 0.8)
                ];
                break;
            case 8:
                polygon = [
                    ...getCoordinates(x + w / 2 - 0.1, y + 0.2), ...getCoordinates(x + w + 0.3, y + h / 2 + 0.4),
                    ...getCoordinates(x + w, y + h), ...getCoordinates(x, y + h),
                    ...getCoordinates(x + 0.2, y + h / 2 + 0.1)
                ];
                break;
            case 23:
                polygon = [
                    ...getCoordinates(x - 0.1, y + 1.7), ...getCoordinates(x + w / 2, y + 0.8),
                    ...getCoordinates(x + w, y + 0.5),
                    ...getCoordinates(x + w, y + 3), ...getCoordinates(x + 1.7, y + h + 0.2)
                ];
                break;
            case 10:
                polygon = [
                    ...getCoordinates(x, y + 0.5), ...getCoordinates(x + w / 2, y + 0.8),
                    ...getCoordinates(x + w + 0.2, y + 1.9),
                    ...getCoordinates(x + w / 2 - 0.2, y + h + 0.2), ...getCoordinates(x, y + 3)
                ];
                break;
            case 5:
                polygon = [
                    ...getCoordinates(x - 0.2, y + h / 2 + 0.5), ...getCoordinates(x + w / 2 + 0.2, y + 0.4),
                    ...getCoordinates(x + w - 0.3, y + h / 2),
                    ...getCoordinates(x + w + 0.1, y + h), ...getCoordinates(x, y + h)
                ];
                break;
        }

        // subtract start pos
        polygon = polygon.map((item, index) => item - getCoordinates(x, y)[index % 2]);

        item.polygon = polygon;
        item.width = Math.round(polygon[4] * 100) / 100;
        item.height = Math.round(Math.abs(polygon[3] - polygon[7]) * 100) / 100;

        const pointerDown = () => {
            App.Sounds.playSound('sprite', 'chip_on_chip');
            numbers.forEach(idx => this.addBet(Grid[idx]));
        };

        cell.beginFill(0xFFFFFF);
        cell.drawPolygon(new Polygon(polygon));
        cell.endFill();
        cell.alpha = 0;
        cell.buttonMode = true;
        cell.interactive = true;
        cell.name = numbers[2];
        cell
            .on('rightdown', () => {
                App.Sounds.playSound('sprite', 'chip_on_chip');
                numbers.forEach(idx => this.removeBet(idx));
            })
            .on('click', pointerDown)
            .on('tap', pointerDown)
            .on('pointerover', () => {
                this.setDefaultHoverAlpha();
                setTimeout(() => {
                    numbers.forEach(idx => {
                        Grid[idx].numbers.forEach(number => {
                            gridContainer.getChildByName(number).alpha = 0.3;
                            trackContainer.getChildByName(number).alpha = 0.3;
                        });
                    });
                    cell.alpha = 0.3;
                }, 1);
            })
            .on('pointerout', this.setDefaultHoverAlpha);

        trackContainer.addChild(cell);
    });

    parentContainer.addChild(container);
}
