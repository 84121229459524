import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../../../index';
import BookGame from './../../deluxe/bookGame';
import GambleDeluxe from './../../deluxe/gamble';
import Lines10 from './../../deluxe/lines10';
import InfoScreen from '../../infoScreen';
import bonusFont from './../book-of-ra-deluxe/img/font/bonusFont';

/* PIXI aliases */
const AnimatedSprite = PIXI.AnimatedSprite,
    Container = PIXI.Container;

export default class LordOfTheOcean extends BookGame {
    constructor() {
        super();
        this.id = 'lord-of-the-ocean';
        this.name = 'Lord Of The Ocean';
        this.scatter = 9;
        this.buttonsPanelShadow = 'strong';

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 130, y: 80},
            bonusInBonusFrame: {x: 130, y: 80},
            endBonusFrame: {x: 130, y: 80}
        };

        this.symbols = [
            {regularDelay: 80, payment: [0, 0, 0, 5, 25, 100]},       // 0 - 10
            {regularDelay: 80, payment: [0, 0, 0, 5, 25, 100]},       // 1 - J
            {regularDelay: 80, payment: [0, 0, 0, 5, 25, 100]},       // 2 - Q
            {regularDelay: 80, payment: [0, 0, 0, 5, 40, 150]},       // 3 - K
            {regularDelay: 80, payment: [0, 0, 0, 5, 40, 150]},       // 4 - A
            {regularDelay: 80, payment: [0, 0, 5, 30, 100, 750]},     // 5 - statue
            {regularDelay: 80, payment: [0, 0, 5, 30, 100, 750]},     // 6 - treasure
            {regularDelay: 80, payment: [0, 0, 5, 40, 400, 2000]},    // 7 - women
            {regularDelay: 80, payment: [0, 0, 10, 100, 1000, 5000]}, // 8 - lord
            {regularDelay: 80, payment: [0, 0, 0, 2, 20, 200]}        // 9 - circle (scatter)
        ];
        this.imageResources = {
            main: this.mergePath({mainArea: `area/${App.System.resolution}/main.png`}),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                bonusArea: `area/${App.System.resolution}/bonus.png`,
                frame: 'bonus/frame.png',
                book: 'bonus/book.png',
                bonusSpin: 'bonus/bonusSpin.png'
            }),
            atlas: this.mergePath([
                'bonus/bonusSymbols.json',
                'bonus/scatterSymbols.json',
                'bonus/additionalSymbols.json',
                'regularLongSymbols.json',
                'regularLongSymbols2.json'
            ])
        };
        this.imageResources.main.bonusFont = `/game/games/book-of-ra-deluxe/img/${bonusFont['imageResource']}`;

        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'bonus-game-end', alias: 'bonusGameEnd'},
                {name: 'book-open', loop: true},
                {name: 'book-open-end'},
                {name: 'book-select-symbol', alias: 'bookSelectSymbol'},
                {name: 'bonus-win-symbols-show', alias: 'bonusWinSymbols'},
                {name: 'bonus-win-lines-show', alias: 'bonusWinLines'},
                {name: 'bonus-background', loop: true}
            ],
            path: `/game/games/${this.id}/audio/`
        };

        this.Lines = new Lines10();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            activeRed: 'gamble/red-active.png',
            inactiveRed: 'gamble/red-inactive.png',
            activeBlack: 'gamble/black-active.png',
            inactiveBlack: 'gamble/black-inactive.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines - current lines count
     * @param bet - current bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 15pt Arial';
        ctx.textAlign = 'left';
        ctx.fillStyle = '#00e4ff';
        ctx.shadowColor = '#000';
        ctx.shadowBlur = 5;

        switch (page) {
            case 1:
                // scatter
                this.strokeFillText(ctx, bet * nLines * this.symbols[9].payment[5], 535, 55);
                this.strokeFillText(ctx, bet * nLines * this.symbols[9].payment[4], 535, 80);
                this.strokeFillText(ctx, bet * nLines * this.symbols[9].payment[3], 535, 105);

                // lord
                this.strokeFillText(ctx, bet * this.symbols[8].payment[5], 95, 176);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[4], 95, 202);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[3], 95, 227);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[2], 95, 252);
                // women
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], 620, 176);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], 620, 202);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 620, 227);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[2], 620, 252);
                // treasure
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 150, 345);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 150, 370);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 150, 395);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[2], 150, 420);
                // statue
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 610, 345);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 610, 370);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 610, 394);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[2], 610, 418);
                // AK
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 150, 492);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 150, 519);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 150, 546);
                // QJ10
                this.strokeFillText(ctx, bet * this.symbols[2].payment[5], 550, 492);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[4], 550, 519);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[3], 550, 546);
                break;
            case 2:
                ctx.font = '18pt Times New Roman';
                ctx.fillStyle = '#ffdf0a';
                ctx.strokeStyle = '#000';
                ctx.lineWidth = 2;
                ctx.shadowBlur = 5;
                ctx.shadowColor = '#000';
                ctx.textAlign = 'left';

                this.strokeFillText(ctx, '3 or more scatters on the screen', 250, 110);
                this.strokeFillText(ctx, 'trigger 10 Free Games with special', 250, 140);
                this.strokeFillText(ctx, 'expanding symbol.', 250, 170);

                this.strokeFillText(ctx, 'One special expanding symbol is', 350, 380);
                this.strokeFillText(ctx, 'randomly selected at the start of the', 350, 410);
                this.strokeFillText(ctx, 'Free Games. During Free Games the', 350, 440);
                this.strokeFillText(ctx, 'special symbol expand to cover 3', 350, 470);
                this.strokeFillText(ctx, 'positions on the reel and pays in', 350, 500);
                this.strokeFillText(ctx, 'any position on lines played.', 350, 530);
                break;
            case 3:
                ctx.font = '18pt Times New Roman';
                ctx.fillStyle = '#ffdf0a';
                ctx.shadowBlur = 5;
                ctx.shadowColor = '#000';
                ctx.textAlign = 'center';

                this.strokeFillText(ctx, 'All prizes are for combinations of a kind. All', 400, 230);
                this.strokeFillText(ctx, 'prizes are for combinations left to right,', 400, 260);
                this.strokeFillText(ctx, 'except scatters. All prizes are on selected', 400, 290);
                this.strokeFillText(ctx, 'lines, except scatters. Scatter symbols pay at', 400, 320);
                this.strokeFillText(ctx, 'any position on screen. Highest win only paid', 400, 350);
                this.strokeFillText(ctx, 'per selected line. Scatter wins are added to', 400, 380);
                this.strokeFillText(ctx, 'line wins. Free Games can be won again', 400, 410);
                this.strokeFillText(ctx, 'during the Free Games. Free Games are played', 400, 440);
                this.strokeFillText(ctx, 'at trigger bet and lines. All prizes shown in', 400, 470);
                this.strokeFillText(ctx, 'credits. Malfunction voids all pays and plays.', 400, 500);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    /**
     * Prepare game behaviour after bonus 'press any button' message
     */
    startBonusAnimation = parentContainer => {
        this.clearPressAnyButton();
        this.Buttons.disableAllButtons();
        App.updateButton('start', {disabled: true});
        this.gameFlag.bonusStarted = true;

        const bonusSymbol = this.bonusRollSymbol;
        ['book_base', `book_${bonusSymbol}`].forEach(key => {
            !this.Loader.resources[key] && this.resourcesSettings.total++ &&
            this.Loader.add(key, App.getUrl(this.mergePath({img: `bonus/${key}.png`}).img));
        });

        this.Loader.load(() => this.drawBonusAnimation(parentContainer, bonusSymbol));
    };

    /**
     * Отрисовка таблички бонусной игры
     */
    showStartBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y);

        const textContainer = new Container();
        parentContainer.addChild(textContainer);
        textContainer.name = 'textContainer';

        const textProps = {
            parentContainer: textContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            fontInterval: -3 // px between symbols
        };
        this.drawCustomFont('10 FREE GAMES WON', 400, 250, textProps);
    }

    /**
     * Отрисовка таблички бонус в бонусе
     */
    showBonusInBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y);

        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            fontInterval: -3 // px between symbols
        };
        this.drawCustomFont('10 MORE FREE GAMES', 400, 250, textProps);
    }

    /**
     * Отрисовка таблички окончания бонусной игры
     */
    showEndBonusFrame(parentContainer, {x, y}, {win, total}) {
        this.showBonusFrame(parentContainer, x, y);

        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            fontInterval: -3 // px between symbols
        };
        App.Sounds.stopSound('bonus-background');
        this.drawCustomFont('FEATURE WIN', 400, 200, textProps);
        this.drawCustomFont(`${win} CREDITS`, 400, 240, textProps);
        this.drawCustomFont(`${total} FREE GAMES`, 400, 310, textProps);
        this.drawCustomFont('PLAYED', 400, 350, textProps);
    }

    drawBonusAnimation(parentContainer, bonusSymbol) {
        parentContainer.getChildByName('textContainer').destroy();
        const props = {
            toFrame: 45,
            width: 290, height: 290,
            colCount: 7
        };
        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            fontInterval: -3 // px between symbols
        };

        const sprite = new AnimatedSprite([
            ...this.getSpriteTextures({...props, image: 'book_base', colCount: 6, toFrame: 30}),
            ...this.getSpriteTextures({...props, image: `book_${bonusSymbol}`})
        ]);

        sprite.position.set(258, 173);
        sprite.animationSpeed = 0.25;
        sprite.loop = false;
        sprite.play();
        sprite.onComplete = () =>
            setTimeout(() => this.bonusRoll(), 1000);

        sprite.onFrameChange = (frame) => {
            frame >= 13 && frame <= 35 ?
                sprite.animationSpeed -= 0.0075 :
                sprite.animationSpeed = 0.25;
            switch (frame) {
                case 1:
                    App.Sounds.playSound('book-open');
                    break;
                case 30:
                    App.Sounds.stopSound('book-open');
                    App.Sounds.playSound('book-open-end');
                    break;
            }
        };
        parentContainer.addChild(sprite);
        this.drawCustomFont('Special expanding symbol', 400, 130, textProps);
    }

    /**
     *  Section for special bonus roll function
     */
    prepareToRollAnimation(response) {
        this.Buttons.disableAllButtons();
        App.updateButton('start', {
            disabled: false,
            title: 'stop',
            handler: () => { // change handler to stop reels animation
                JL().debug('-- Stop roll');
                this.Roll.stopReels = [1, 1, 1, 1, 1, 1];
                App.updateButton('start', {disabled: true});
            }
        });
        this.extraBet && this.updateExtraBetButtons(false);
        this.gameFlag.bonusStart || App.restoreGameState === 'BONUS' ?
            this.bonusReelAnimation(response) : // custom reel animation
            this.Roll.startReelAnimation(response); // primary roll
    }

    /**
     * Специфическая функция прокрута в бонусе (окртытие карт, кристалы, сдвиг елементов и тд)
     */
    bonusReelAnimation(response) {
        const parentContainer = this.getStageChild('bonusContainer');
        App.updateButton('start', {disabled: true});
        this.createReelMatrix(this.getStageChild('reelsStage'), response.screen);
        const arrayOfSprites = this.createBonusSpinSprites(parentContainer);

        for (let i = 0; i < this.reels; i++) {
            arrayOfSprites[i].onComplete = () => {
                parentContainer.removeChild(arrayOfSprites[i]);
                if (i === arrayOfSprites.length - 1) {
                    App.Socket.webSocket && this.onRotationDone();
                } else {
                    App.Sounds.playSound('bonusSpin');
                    arrayOfSprites[i + 1].play();
                }
            };

            setTimeout(() => arrayOfSprites[i].play(), 500 * i);
        }

        App.Sounds.playSound('bonusSpin');
    }

    createBonusSpinSprites = parentContainer =>
        [...Array(this.reels)].map((item, index) => {
            const sprite = new AnimatedSprite(this.getSpriteTextures({
                toFrame: 36, image: 'bonusSpin',
                width: this.symbolWidth, height: this.symbolHeight * this.reelRows,
                colCount: 12
            }));
            sprite.animationSpeed = 0.2;
            sprite.loop = false;
            sprite.position.set(this.reelXCoordinates[index], this.reelTop);

            parentContainer.addChild(sprite);
            return sprite;
        });
}
