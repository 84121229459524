import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import InfoScreen from '../../infoScreen';
import Grid from './grid';
import rouletteNumbers from './rouletteNumbers';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Container = PIXI.Container,
    Text = PIXI.Text,
    Graphics = PIXI.Graphics;

const offset = 15;
const textStyle = {fill: '#fff', fontSize: 10};

export default class InfoScreenRoulette extends InfoScreen {
    active = false;

    /**
     * Prepare img collection to load
     */
    getResources = () => ({});

    show = () => {
        if (App.configs.mode !== 'info') {
            return;
        }
        const old = App.Game.getStageChild('info');
        old && old.destroy(); // reset previous container

        this.active = true;
        App.Sounds.playSound('sprite', 'button_click');

        const container = new Container();
        container.name = 'info';
        container.zIndex = 10;
        App.Game.getStage().addChild(container);
        this.draw(container);
        App.updateButton('info', {handler: this.close});
    };

    close = () => {
        this.active = false;
        App.Sounds.playSound('sprite', 'button_click');
        App.Game.getStageChild('info').destroy();
        App.updateButton('info', {handler: this.show});
    };

    toggle() {
        this.active ? this.close() : this.show();
    }

    disable = () => {
        const container = App.Game.getStageChild('info');
        container.interactiveChildren = false;

        const sections = container.getChildByName('sections');
        sections && sections.destroy();
    };

    draw(parentContainer) {
        if (App.configs.mode !== 'info') {
            return;
        }
        const gridContainer = App.Game.getStageChild('cells').getChildByName('gridContainer');

        const addHandlers = (item, idx) => {
            const pointerDown = () => {
                App.Sounds.playSound('sprite', 'chip_on_chip');
                App.Game.addBet(Grid[idx]);
            };

            item.interactive = true;
            item.buttonMode = true;
            item
                .on('rightdown', () => {
                    App.Sounds.playSound('sprite', 'chip_on_chip');
                    App.Game.removeBet(idx);
                })
                .on('click', pointerDown)
                .on('tap', pointerDown)
                .on('pointerover', () => {
                    App.Game.setDefaultHoverAlpha();
                    setTimeout(() => {
                        Grid[idx].numbers.forEach(number => gridContainer.getChildByName(number).alpha = 0.3);
                        item.alpha = 0.5;
                    }, 1);
                })
                .on('pointerout', App.Game.setDefaultHoverAlpha);
        };

        const stat = new Container();
        stat.name = 'stat';
        stat.position.set(615, 25);
        parentContainer.addChild(stat);

        const createCorrelation = (percents, x, y, props) => {
            const width = 45, height = 160;
            const {top, bottom, colors} = props;

            const container = new Container();
            container.position.set(x, y);
            container.sortableChildren = true;

            const textTop = new Text(top, textStyle);
            textTop.anchor.set(0.5);
            textTop.position.set(width / 2, offset);
            container.addChild(textTop);

            const textBottom = new Text(bottom, textStyle);
            textBottom.anchor.set(0.5);
            textBottom.position.set(width / 2, height - offset);
            container.addChild(textBottom);

            let stepY = 0; // offset for next column
            percents.forEach((percent, index) => {
                percent = Math.round(percent * 10) / 10;
                const h = (height - offset * 4) / 100 * percent;
                const column = new Graphics();
                column.beginFill(colors[index]);
                column.drawRect(0, offset * 2 + stepY, width, h);
                column.endFill();
                container.addChild(column);

                if (h > offset) {
                    const text = new Text(`${percent}%`, {...textStyle, fontSize: 12});
                    text.anchor.set(0.5);
                    text.position.set(width / 2, offset * 2 + h / 2 + stepY);
                    text.zIndex = 1;
                    container.addChild(text);
                }
                stepY += h;

                let idx = 0;
                switch (index) {
                    case 0:
                        idx = Grid.findIndex(item => item.typeOfBet === top);
                        break;
                    case 1:
                        idx = 0;
                        break;
                    case 2:
                        idx = Grid.findIndex(item => item.typeOfBet === bottom);
                        break;
                }
                addHandlers(column, idx);
            });

            stat.addChild(container);
        };

        const createNumbers = (numbers, x, y, props) => {
            const {text, width = 160, height = 38} = props;
            const w = 15, h = 20;
            const itemOffset = 63, itemPadding = 2;
            const numbersPerRow = Math.floor((width - itemOffset - offset / 2) / (w + itemPadding));
            const rows = Math.ceil(numbers.length / numbersPerRow);

            const container = new Container();
            container.name = text;
            container.position.set(x, y);

            const background = new Graphics();
            background.beginFill(0x777777, 0.2);
            background.drawRoundedRect(0, 0, width, height, offset);
            background.endFill();
            container.addChild(background);

            const title = new Text(text, {...textStyle, wordWrap: true, wordWrapWidth: 50});
            title.name = 'title';
            title.position.set(7, rows === 1 ? 7 : 4);
            container.addChild(title);

            numbers.forEach(({number, amount}, index) => {
                const rowIndex = Math.floor(index / numbersPerRow);

                let color = 0x499500; // default color for zero
                switch (true) {
                    case Grid.find(item => item.typeOfBet === 'RED').numbers.includes(number):
                        color = 0x9a2927;
                        break;
                    case Grid.find(item => item.typeOfBet === 'BLACK').numbers.includes(number):
                        color = 0x333333;
                        break;
                }

                const item = new Container();
                item.name = 'item';
                item.position.set(
                    itemOffset + (index % numbersPerRow) * (w + itemPadding) + rowIndex * w / 2,
                    rowIndex * (h + 4) + 4
                );
                addHandlers(item, number);

                const background = new Graphics();
                background.beginFill(color);
                background.drawRect(0, 0, w, h);
                background.endFill();
                item.addChild(background);

                const title = new Text(number, textStyle);
                title.anchor.set(0.5);
                title.position.set(w / 2, h / 2);
                item.addChild(title);

                if (amount !== undefined) {
                    const count = new Text(amount, textStyle);
                    count.anchor.set(0.5);
                    count.position.set(w / 2, h / 2 + 15);
                    item.addChild(count);
                }

                container.addChild(item);
            });

            parentContainer.addChild(container);
        };

        const createCircle = statistic => {
            const sections = new Container();
            sections.name = 'sections';
            sections.position.set(offset * 2, offset);
            const sprite = new Sprite(App.Game.getTexture('info_sections'));
            sprite.name = 'wheel';
            sprite.interactive = true;
            sprite.on('pointerover', () => sections.children.forEach(child => child.alpha = 1));
            sections.addChild(sprite);

            let maxValue = 0;
            statistic.numbers.forEach(number => maxValue = Math.max(number.amount, maxValue));

            const circleOffset = 32, size = 272;
            const angle = 360 / 37;

            const calcAngle = (r, angle) => ([
                size / 2 - 1 + r * Math.cos(angle),
                size / 2 + 1 + r * Math.sin(angle)
            ]);

            statistic.numbers.forEach(({number, amount}) => {
                const circleAngle = -9.8;
                const r = (size - circleOffset * 2) / 2 / maxValue * amount;
                const pos = rouletteNumbers.indexOf(number);

                const cell = new Graphics();
                addHandlers(cell, number);
                cell.beginFill(rouletteNumbers.indexOf(number) === 0 ? 0x499500 :
                    rouletteNumbers.indexOf(number) % 2 === 1 ? 0x9a2927 : 0x353535);
                cell.drawPolygon([
                    size / 2 - 1, size / 2 + 1,
                    ...calcAngle(r, angle * (pos + circleAngle) / 180 * Math.PI),
                    ...calcAngle(r, angle * (pos + 1 + circleAngle) / 180 * Math.PI)
                ]);
                cell.endFill();
                sections.addChild(cell);
            });

            parentContainer.addChild(sections);
        };

        createCircle(App.Game.numberStatistics);

        const {red, black, even, odd, low, high} = App.Game.numberStatistics;
        createCorrelation([red, 100 - red - black, black], offset, offset, {
            top: 'RED', bottom: 'BLACK',
            colors: [0x9a2927, 0x499500, 0x333333]
        });
        createCorrelation([odd, 100 - odd - even, even], offset * 2 + 35, offset, {
            top: 'ODD', bottom: 'EVEN',
            colors: [0x333333, 0x499500, 0x6d6d6d]
        });
        createCorrelation([low, 100 - low - high, high], offset * 3 + 35 * 2, offset, {
            top: 'LOW', bottom: 'HIGH',
            colors: [0x6d6d6d, 0x499500, 0x333333]
        });

        const sorted = App.Game.numberStatistics.numbers.sort((a, b) => {
            if (a.amount > b.amount) return -1;
            if (a.amount < b.amount) return 1;
            return 0;
        });

        createNumbers(sorted.slice(0, 5), 10, 320, {text: 'HOT NUMBERS'});
        createNumbers(sorted.slice(sorted.length - 5, sorted.length), 10, 360, {text: 'COLD NUMBERS'});
        createNumbers(App.Game.numberHistory.numbers.slice(0, 39).map(number => ({number})), 10, 400, {
            text: 'NUMBERS HISTORY',
            width: 303, height: 75
        });
    }
}
