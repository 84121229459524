const bonusFont = {
    fontWidthArray: [
        56, 56, 52, 56, 47, 78, 56, 69, 57,
        58, 52, 46, 70, 61, 55, 56, 57, 59,
        66, 68, 52, 58, 68, 69, 64, 64,
        39, 29, 30, 35, 30, 33, 37, 34, 27,
        26, 38, 28, 45, 37, 30, 42, 38, 32,
        29, 26, 33, 32, 40, 39, 30, 35,
        30, 37, 37, 40, 40, 41, 36, 36, 38,
        39, 25, 30, 23
    ],
    fontHeight: 84,
    symbolsOrder: [
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I',
        'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R',
        'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
        'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i',
        'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r',
        's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
        '1', '2', '3', '4', '5', '6', '7', '8', '9',
        '0', ',', '.', ' '
    ],
    imageResource: 'font/bonusFont.png'
};

export default bonusFont;
