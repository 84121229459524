import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../../../index';
import BookGame from './../../deluxe/bookGame';
import Lines10 from './../../deluxe/lines10';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';
import fontBonus from './img/font/fontBonus';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    AnimatedSprite = PIXI.AnimatedSprite;

export default class GateOfRa extends BookGame {
    constructor() {
        super();
        this.id = 'gate-of-ra';
        this.name = 'Gate of Ra';
        this.scatter = 9;
        this.playSound = true;
        this.buttonsPanelShadow = 'mid';

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 51, y: 89},
            bonusInBonusFrame: {x: 51, y: 89},
            endBonusFrame: {x: 51, y: 89}
        };

        this.symbols = [
            {regularDelay: 100, bonusDelay: 120, payment: [0, 0, 0, 5, 25, 100]},       // 0 - цифра 10
            {regularDelay: 100, bonusDelay: 120, payment: [0, 0, 0, 5, 25, 100]},       // 1 - буква J
            {regularDelay: 100, bonusDelay: 120, payment: [0, 0, 0, 5, 25, 100]},       // 2 - буква Q
            {regularDelay: 100, bonusDelay: 120, payment: [0, 0, 0, 5, 40, 150]},       // 3 - буква K
            {regularDelay: 100, bonusDelay: 120, payment: [0, 0, 0, 5, 40, 150]},       // 4 - буква A
            {regularDelay: 100, bonusDelay: 120, payment: [0, 0, 5, 30, 100, 750]},     // 5 - жук скарабей
            {regularDelay: 100, bonusDelay: 120, payment: [0, 0, 5, 30, 100, 750]},     // 6 - статуетка
            {regularDelay: 100, bonusDelay: 120, payment: [0, 0, 5, 40, 400, 2000]},    // 7 - фараон
            {regularDelay: 100, bonusDelay: 120, payment: [0, 0, 10, 100, 1000, 5000]}, // 8 - кладоискатель
            {
                regularDelay: 100, bonusDelay: 120, payment: [0, 0, 0, 2, 20, 200],     // 9 - книга scatter & joker
                scatterDelay: 120
            }
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                bonusArea: 'area/bonus.png',
                frame1: 'bonus/frame1.png',
                frame2: 'bonus/frame2.png',
                fontBonus: fontBonus['imageResource']
            }),
            atlas: this.mergePath([
                'regularLongSymbols.json',
                'regularLongSymbols1.json',
                'bonus/bonusSymbols.json',
                'bonus/scatterSymbols.json',
                'bonus/additionalSymbols.json'
            ])
        };

        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [
                {name: 'bonus-game-won3', alias: 'bonusGameStart'},
                {name: 'bonus-game-end', alias: 'bonusGameEnd'},
                {name: 'book-open', alias: 'bookOpen'},
                {name: 'book-select-symbol', alias: 'bookSelectSymbol'},
                {name: 'book-step-up', alias: 'bookStepUp'},
                {name: 'bonus-win-symbols-show', alias: 'bonusWinSymbols'},
                {name: 'bonus-win-lines-show', alias: 'bonusWinLines'},
                {name: 'dice', alias: 'dice'},
                {name: 'book1', alias: 'teaser_1', path: '/audio/deluxe/'},
                {name: 'book2', alias: 'teaser_2', path: '/audio/deluxe/'},
                {name: 'book3', alias: 'teaser_3', path: '/audio/deluxe/'},
                {name: 'book4', alias: 'teaser_4', path: '/audio/deluxe/'},
                {name: 'book5', alias: 'teaser_5', path: '/audio/deluxe/'},
                {name: 'book6', alias: 'teaser_6', path: '/audio/deluxe/'},
                {name: 'long1', alias: 'long1', path: '/audio/deluxe/'},
                {name: 'long2', alias: 'long2', path: '/audio/deluxe/'},
                {name: 'long3', alias: 'long3', path: '/audio/deluxe/'},
                {name: 'long4', alias: 'long4', path: '/audio/deluxe/'},
                {name: 'symbol-select', alias: 'symbolSelect'},
                {name: 'bonus-background', loop: true, path: '/game/games/book-of-ra-deluxe/audio/'}
            ],
            path: `/game/games/${this.id}/audio/`
        };

        this.Lines = new Lines10();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png',
            activeBlack: 'gamble/black-active.png',
            activeRed: 'gamble/red-active.png',
            inactiveBlack: 'gamble/black-inactive.png',
            inactiveRed: 'gamble/red-inactive.png'
        }));

        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 15pt Times New Roman';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#f7f2ab';
        ctx.shadowColor = 'black';
        ctx.strokeStyle = '#000';
        ctx.lineWidth = 3;

        switch (page) {
            case 1:
                // scatter
                this.strokeFillText(ctx, bet * nLines * this.symbols[9].payment[5], 350, 160);
                this.strokeFillText(ctx, bet * nLines * this.symbols[9].payment[4], 350, 184);
                this.strokeFillText(ctx, bet * nLines * this.symbols[9].payment[3], 350, 206);
                // men
                this.strokeFillText(ctx, bet * this.symbols[8].payment[5], 180, 265);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[4], 180, 289);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[3], 180, 313);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[2], 180, 337);
                // mummy
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], 630, 265);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], 630, 289);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 630, 313);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[2], 630, 337);
                // statue
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 180, 389);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 180, 413);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 180, 437);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[2], 180, 461);
                // scarab
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 630, 389);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 630, 413);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 630, 437);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[2], 630, 461);
                // AK
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 320, 508);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 320, 533);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 320, 558);
                // QJ10
                this.strokeFillText(ctx, bet * this.symbols[2].payment[5], 515, 508);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[4], 515, 533);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[3], 515, 558);
                break;

            case 2:
                ctx.font = ' 18pt Times New Roman';
                ctx.textAlign = 'left';
                ctx.fillStyle = '#f7f2ab';
                ctx.shadowColor = 'black';
                ctx.strokeStyle = '#000';
                ctx.lineWidth = 3;
                ctx.shadowBlur = 1;
                this.strokeFillText(ctx, '3 or more Scatters on the screen trigger 10', 230, 150);
                this.strokeFillText(ctx, 'FREE GAMES with special expanding symbol.', 230, 180);

                this.strokeFillText(ctx, 'One special expanding symbol is randomly', 250, 358);
                this.strokeFillText(ctx, 'selected at the start of the Free Games', 250, 388);
                this.strokeFillText(ctx, 'and replaces the original symbol on the reels.', 250, 418);
                this.strokeFillText(ctx, 'During Free Games the special symbol', 250, 448);
                this.strokeFillText(ctx, 'expands to cover 3 positions on the reel', 250, 478);
                this.strokeFillText(ctx, 'and pays in any position on lines played.', 250, 508);
                break;

            case 3:
                ctx.font = '15pt Times New Roman';
                ctx.textAlign = 'center';
                ctx.fillStyle = '#f7f2ab';
                ctx.shadowColor = 'black';
                ctx.strokeStyle = '#000';
                ctx.lineWidth = 3;
                this.strokeFillText(ctx, 'All prizes are for combination of a kind. All prizes are for', 400, 250);
                this.strokeFillText(ctx, 'combinations left to right, except scatters. All prizes are on', 400, 270);
                this.strokeFillText(ctx, 'selected lines, except scatters. Scatter symbols pay at any', 400, 290);
                this.strokeFillText(ctx, 'position on screen. Highest win only paid per selected line.', 400, 310);
                this.strokeFillText(ctx, 'Scatter wins are added to line wins. Free Games can be won', 400, 330);
                this.strokeFillText(ctx, 'again during the Free Games. Free Games are played at trigger', 400, 350);
                this.strokeFillText(ctx, 'bet and lines. All prizes shown in credits. malfunction voids', 400, 370);
                this.strokeFillText(ctx, 'all pays and plays.', 400, 390);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    drawBonusFrame(first, last, parentContainer, coordinates) {
        App.Sounds.stopSound('bonus-background');
        const {bonusInBonusFrame, endBonusFrame} = coordinates;
        if (first) {
            this.startBonusAnimation(parentContainer);
            this.setBackground('bonusArea');
            App.updateButton('start', {
                disabled: false,
                title: 'start'
            });
        }

        if (last) {
            this.setRegularSprite();
            this.setBackground('mainArea');
            App.updateButton('start', {disabled: true});
            this.showEndBonusFrame(parentContainer, endBonusFrame, this.bonusStatus);
            this.playEndBonusGameSound();
            setTimeout(() => this.endBonus(), 7000);
        }

        if (!first && !last) {
            this.showBonusInBonusFrame(parentContainer, bonusInBonusFrame);
            App.updateButton('start', {
                disabled: false,
                title: 'start',
                handler: () => this.drawBonusStep(this.latestResponse.features)
            });
        }
    }

    /**
     * Prepare game behaviour after bonus 'press any button' message
     */
    startBonusAnimation = parentContainer => {
        this.clearPressAnyButton();
        this.Buttons.disableAllButtons();
        App.updateButton('start', {disabled: true});
        this.gameFlag.bonusStarted = true;

        const bonusSymbol = this.bonusRollSymbol;
        ['book_base', `book_${bonusSymbol}`].forEach(key => {
            !this.Loader.resources[key] && this.resourcesSettings.total++ &&
            this.Loader.add(key, App.getUrl(this.mergePath({img: `bonus/${key}.png`}).img));
        });

        this.Loader.load(() => this.drawBonusAnimation(parentContainer, bonusSymbol));
    };

    /**
     * Draw all bonus enter animations
     * @param parentContainer
     * @param bonusSymbol - current bonus symbol in extension
     */
    drawBonusAnimation(parentContainer, bonusSymbol) {
        JL().debug(`-- Draw bonus enter animation. Bonus symbol: ${bonusSymbol}`);
        const {startBonusFrame} = this.coordinatesBonusFrame;

        const frameSprite = new Sprite(this.getTexture('frame1'));
        frameSprite.name = 'frame1';
        frameSprite.position.set(startBonusFrame.x, startBonusFrame.y);
        parentContainer.addChild(frameSprite);

        const sprite = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 9, image: 'book_base',
            width: 400, height: 390,
            colCount: 5
        }));
        sprite.name = 'roulette';
        sprite.position.set(200, 89);
        sprite.animationSpeed = 0.03;
        sprite.loop = true;
        sprite.onFrameChange = () => App.Sounds.playSound('dice');
        sprite.play();
        parentContainer.addChild(sprite);

        // actions after "Start" was pressed
        const spinTheWheel = currentFrame => {
            App.updateButton('start', {disabled: true});
            this.clearPressAnyButton();
            sprite.animationSpeed = 0.4;
            const finalFrames = this.getSpriteTextures({
                toFrame: 9 - bonusSymbol, image: 'book_base',
                width: 400, height: 390,
                colCount: 5
            });
            sprite.textures = [...sprite.textures, ...sprite.textures, ...sprite.textures, ...sprite.textures, ...sprite.textures, ...finalFrames];
            sprite.loop = false;
            sprite.gotoAndPlay(currentFrame);

            // function to call after sprite`s frame was changed
            sprite.onFrameChange = frame => {
                App.Sounds.playSound('dice');
                if (frame > sprite.totalFrames - 22 && frame <= sprite.totalFrames - 5) {
                    Math.round(sprite.animationSpeed -= 0.02);  // decrease speed of animation (22 frames to the end)
                }
                if (frame === sprite.totalFrames - 1) {
                    sprite.onFrameChange = null;
                    bonusSymbolAnimation();
                    App.Sounds.playSound('symbolSelect');
                }
            };
        };

        const bonusSymbolAnimation = () => {
            const props = {
                toFrame: 37,
                width: 400,
                height: 390,
                colCount: 7
            };
            sprite.animationSpeed = 0.2;
            sprite.textures = [
                ...this.getSpriteTextures({...props, image: `book_${bonusSymbol}`})
            ];
            sprite.play();
            sprite.onComplete = () => this.bonusRoll();
        };

        App.updateButton('start', {handler: () => spinTheWheel(sprite.currentFrame)});
    }

    /**
     * Отрисовка таблички бонус в бонусе
     */
    showBonusInBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y, 'frame2');
        this.showPressAnyButton(false);

        const textProps = {
            parentContainer,
            fontImageName: 'fontBonus',
            map: fontBonus,
            align: 'center',
            scale: 1.3,
            fontInterval: -3 // px between symbols
        };
        this.drawCustomFont('10 MORE', 400, 200, textProps);
        this.drawCustomFont('FREE GAMES WON', 400, 260, textProps);
    }

    /**
     * Отрисовка таблички окончания бонусной игры
     */
    showEndBonusFrame(parentContainer, {x, y}, {win, total}) {
        this.showBonusFrame(parentContainer, x, y, 'frame2');

        const textProps = {
            parentContainer,
            fontImageName: 'fontBonus',
            map: fontBonus,
            align: 'center',
            scale: 1.3,
            fontInterval: -3 // px between symbols
        };
        this.drawCustomFont(`FEATURE WIN ${win}`, 400, 170, textProps);
        this.drawCustomFont('CREDITS', 400, 220, textProps);
        this.drawCustomFont(`${total} FREE GAMES PLAYED`, 400, 290, textProps);
    }
}
