import GameDeluxe from './game';

export default class GameDeluxe5x4 extends GameDeluxe {
    constructor() {
        super();
        // reel properties
        this.reels = 5;
        this.reelRows = 4; // number of rows per reel
        this.symbolHeight = 98; // height of aa single symbol
        this.symbolWidth = 130; // height of aa single symbol
        this.reelXCoordinates = [51, 193, 335, 477, 619]; // magic numbers - x coordinates where reels start
        this.reelTop = 88; // magic number - where reel images starts

        // roll properties
        this.reelSettings = [20, 6, 20]; // 0 - start symbol amount, 1 - reel increase regular roll, 2 - reel increase long roll
        this.transparentBackground = false; // use transparent symbols background or not
        this.reelFilter = [[], [], [], [], [], []];
    }
}
