import {JL} from 'jsnlog';
import Lines from './../../lines';
import App from '../../../index';

export default class Lines10 extends Lines {
    constructor() {
        super();

        this.lines = {
            0: {coordinates: [1, 1, 1, 1, 1], boxes: [{x: 1, y: 94}]},
            1: {coordinates: [0, 0, 0, 0, 0], boxes: [{x: 1, y: 213}]},
            2: {coordinates: [2, 2, 2, 2, 2], boxes: [{x: 1, y: 335}]},
            3: {coordinates: [0, 1, 2, 1, 0], boxes: [{x: 1, y: 459}]},
            4: {coordinates: [2, 1, 0, 1, 2], boxes: [{x: 772, y: 138}]},
            5: {coordinates: [0, 0, 1, 0, 0], boxes: [{x: 1, y: 94}]},
            6: {coordinates: [2, 2, 1, 2, 2], boxes: [{x: 1, y: 213}]},
            7: {coordinates: [1, 2, 2, 2, 1], boxes: [{x: 1, y: 335}]},
            8: {coordinates: [1, 0, 0, 0, 1], boxes: [{x: 1, y: 459}]},
            9: {coordinates: [1, 0, 1, 0, 1], boxes: [{x: 772, y: 138}]}
        };

        JL().debug('-- Lines10 initialized');
    }

    drawWinBox = container => {
        const dx = App.Game.symbolWidth / 2;
        const dy = App.Game.symbolHeight / 2;
        const spineLine = new window.PIXI.spine.Spine(App.Game.getSpineData('winBox'));
        spineLine.position.set(dx, dy);

        spineLine.state.addAnimation(0, 'tsf_win_frame', true);
        container.addChildAt(spineLine, 0);
        App.Game.tickerTimeout(() => spineLine.destroy(), App.Game.defaultFeatureDelay + 500);
    };
}
