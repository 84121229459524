import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import Lines from './../../lines';
import App from './../../../index';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Texture = PIXI.Texture;

export default class Lines5ChipRunner extends Lines {
    constructor() {
        super();
        this.imageResources = {
            lines: '/img/lines/Lines5/chip_runner/lines.png',
            boxes: '/img/lines/Lines5/chip_runner/boxes.png'
        };
        this.lineImageWidth = 714; // width of line image in sprite, magic number for each game
        this.lineImageHeight = 226; // height of line image in sprite, magic number for each game

        this.lines = {
            0: {
                coordinates: [1, 1, 1],
                boxes: [{x: 0, y: 265}, {x: 750, y: 265}],
                path: [{x: 43, y: 269}]
            },
            1: {
                coordinates: [0, 0, 0],
                boxes: [{x: 0, y: 150}, {x: 750, y: 150}],
                path: [{x: 43, y: 153}]
            },
            2: {
                coordinates: [2, 2, 2],
                boxes: [{x: 0, y: 400}, {x: 750, y: 400}],
                path: [{x: 43, y: 403}]
            },
            3: {
                coordinates: [0, 1, 2],
                boxes: [{x: 0, y: 100}, {x: 750, y: 450}],
                path: [{x: 43, y: 107}]
            },
            4: {
                coordinates: [2, 1, 0],
                boxes: [{x: 0, y: 450}, {x: 750, y: 100}],
                path: [{x: 43, y: 110}]
            }
        };

        JL().debug('-- Lines5_deluxe  initialized');
    }

    drawLineImages(lines, winReels, parentContainer, winLine, payment) {
        lines.forEach(lineIndex => {
            const line = this.lines[lineIndex];

            line.coordinates.forEach(() => {
                let spritePosY = this.lineImageHeight * lineIndex;

                if (lineIndex < 3) {
                    this.lineImageHeight = 25;
                    spritePosY = this.lineImageHeight * lineIndex;
                } else {
                    this.lineImageHeight = 370;
                    spritePosY = 75 + this.lineImageHeight * (lineIndex - 3);
                }
                // create line sprite
                const sprite = new Sprite(new Texture(App.Game.getTexture('lines'), {
                    x: 0,
                    y: spritePosY,
                    width: this.lineImageWidth,
                    height: this.lineImageHeight
                }));
                sprite.position.set(line.path[0].x, line.path[0].y);
                sprite.name = lineIndex;
                parentContainer.addChild(sprite);
            });
        });
    }

    drawBoxes(parentContainer) {
        parentContainer.removeChildren(); // clear all previous boxes

        const currentLines = App.Game.gameSettings.getCurrentLineMas();
        const boxWidth = 48; // width of winline boxes
        const boxHeight = 36; // height of winline boxes

        Object.keys(this.lines).forEach(lineIndex => {
            this.lines[lineIndex].boxes.forEach(box => {
                const activeLine = currentLines.includes(+lineIndex + 1);
                // create box sprite
                const sprite = new Sprite(new Texture(App.Game.getTexture('boxes'), {
                    x: box.x === 0 ? 0 : boxWidth,
                    y: !activeLine ? 100 : +lineIndex === 0 ? 0 : boxHeight,
                    width: boxWidth,
                    height: boxHeight
                }));

                sprite.position.set(box.x, box.y);
                sprite.name = lineIndex;
                activeLine && this.addBoxEvents(sprite);
                parentContainer.addChild(sprite);
            });
        });
    }
}
