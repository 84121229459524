import {JL} from 'jsnlog';
import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import BookGame from './../../deluxe/bookGame';
import Lines9 from './lines';
import GambleNovomatic from './../../novomatic/gamble';
import InfoScreen from '../../infoScreen';
import bonusFont from './img/font/fontBonus';
import BigWinUnique from './bigWin';

/* PIXI aliases */
const Container = PIXI.Container,
    Texture = PIXI.Texture,
    Sprite = PIXI.Sprite,
    AnimatedSprite = PIXI.AnimatedSprite,
    Text = PIXI.Text;

export default class BookOfFire extends BookGame {
    constructor() {
        super();
        this.id = 'book-of-fire';
        this.name = 'Book of Fire';
        this.scatter = 9;
        this.symbolEffects = false;
        this.transparentBackground = true;
        this.buttonsPanelShadow = 'strong';
        this.BigWin = new BigWinUnique();
        this.BigWin.enabled = true;

        // reel properties
        this.reelTop = 85; // magic number - where reel images starts
        this.reelXCoordinates = [65, 203, 339, 477, 610]; // magic numbers - x coordinates where reels starts
        this.symbolHeight = 126; // height of a single symbol
        this.symbolWidth = 126; // width of a single symbol
        this.buttonsPanelShadow = 'strong';

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 150, y: 105},
            bonusInBonusFrame: {x: 150, y: 105},
            endBonusFrame: {x: 150, y: 105}
        };

        this.symbols = [
            {regularDelay: 150, payment: [0, 0, 0, 5, 25, 100]},      // 0 - 10
            {regularDelay: 150, payment: [0, 0, 0, 5, 25, 100]},      // 1 - J
            {regularDelay: 150, payment: [0, 0, 0, 5, 25, 100]},      // 2 - Q
            {regularDelay: 150, payment: [0, 0, 0, 5, 40, 150]},      // 3 - K
            {regularDelay: 150, payment: [0, 0, 0, 5, 40, 150]},      // 4 - A
            {regularDelay: 50, payment: [0, 0, 5, 30, 100, 750]},     // 5 - scarab
            {regularDelay: 50, payment: [0, 0, 5, 30, 100, 750]},     // 6 - statue
            {regularDelay: 50, payment: [0, 0, 5, 40, 400, 2000]},    // 7 - pharaoh
            {regularDelay: 50, payment: [0, 0, 10, 100, 1000, 5000]}, // 8 - men
            {
                regularDelay: 65, payment: [0, 0, 0, 2, 20, 200],     // 9 - book (scatter)
                scatterDelay: 100
            }
        ];
        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                book_base: 'bonus/book_base.png',
                frame: 'bonus/frame.png',
                bonus: 'bonus/bonus1.png',
                bonus2: 'bonus/bonus2.png',
                bonus3: 'bonus/bonus3.png',
                book: 'bonus/book.png',
                symbolBook: 'bonus/symbol-book.png',
                bonusFont: bonusFont['imageResource']
            }),
            atlas: this.mergePath([
                'bonus/bonusSymbols.json',
                'bonus/scatterSymbols.json',
                'bonus/additionalSymbols.json'
            ])
        };

        this.gameSounds = {
            soundClass: 'prefergames',
            sounds: [
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'bonus-game-end', alias: 'bonusGameEnd'},
                {name: 'book-open', alias: 'bookOpen'},
                {name: 'book-flip-page', alias: 'bookFlip'},
                {name: 'book-select-symbol', alias: 'bookSelectSymbol', loop: true},
                {name: 'book-step-up', alias: 'bookStepUp'},
                {name: 'bonus-win-symbols-show', alias: 'bonusWinSymbols'}
            ],
            path: `/game/games/${this.id}/audio/`
        };

        this.Lines = new Lines9(this.mergePath({
            lines: 'lines/lines1.png',
            winLines: 'lines/winlines.png',
            boxes: 'lines/boxes.png'
        }));
        this.Gamble = new GambleUnique(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            aceOfClubs: 'gamble/blue-card-light.png',
            aceOfDiamonds: 'gamble/red-card-light.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            blackCard: 'gamble/blue-card.png',
            redCard: 'gamble/red-card.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 1}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines - current lines count
     * @param bet - current bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 14pt Arial';
        ctx.fillStyle = '#fff';
        ctx.textAlign = 'center';

        // scatter
        ctx.fillText(bet * nLines * this.symbols[9].payment[5], 282, 146);
        ctx.fillText(bet * nLines * this.symbols[9].payment[4], 282, 167);
        ctx.fillText(bet * nLines * this.symbols[9].payment[3], 282, 190);
        // men
        ctx.fillText(bet * this.symbols[8].payment[5], 226, 250);
        ctx.fillText(bet * this.symbols[8].payment[4], 226, 272);
        ctx.fillText(bet * this.symbols[8].payment[3], 226, 294);
        ctx.fillText(bet * this.symbols[8].payment[2], 226, 316);
        // mummy
        ctx.fillText(bet * this.symbols[7].payment[5], 570, 250);
        ctx.fillText(bet * this.symbols[7].payment[4], 570, 272);
        ctx.fillText(bet * this.symbols[7].payment[3], 570, 294);
        ctx.fillText(bet * this.symbols[7].payment[2], 570, 316);
        // statue
        ctx.fillText(bet * this.symbols[6].payment[5], 215, 365);
        ctx.fillText(bet * this.symbols[6].payment[4], 215, 387);
        ctx.fillText(bet * this.symbols[6].payment[3], 215, 409);
        ctx.fillText(bet * this.symbols[6].payment[2], 215, 431);
        // scarab
        ctx.fillText(bet * this.symbols[5].payment[5], 570, 365);
        ctx.fillText(bet * this.symbols[5].payment[4], 570, 387);
        ctx.fillText(bet * this.symbols[5].payment[3], 570, 409);
        ctx.fillText(bet * this.symbols[5].payment[2], 570, 431);
        // AK
        ctx.fillText(bet * this.symbols[4].payment[5], 347, 487);
        ctx.fillText(bet * this.symbols[4].payment[4], 347, 510);
        ctx.fillText(bet * this.symbols[4].payment[3], 347, 532);
        // QJ10
        ctx.fillText(bet * this.symbols[2].payment[5], 450, 487);
        ctx.fillText(bet * this.symbols[2].payment[4], 450, 510);
        ctx.fillText(bet * this.symbols[2].payment[3], 450, 532);
    }

    /**
     * Drawing  the table of bonus game
     */
    showStartBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y);

        const richText = new Text('10 FREE GAMES \n WITH SPECIAL EXPANDING\n SYMBOL', {
            align: 'center',
            fontFamily: 'Times New Roman',
            fontSize: 24,
            fontWeight: 'bold',
            fill: ['#1ca315', '#fbf4ff'], // gradient
            stroke: '#633201',
            strokeThickness: 5,
            lineJoin: 'round'
        });
        richText.x = 230;
        richText.y = 160;
        parentContainer.addChild(richText);

        // create sprite of bookAnim and show first frame
        this.createBookSprite(parentContainer);
    }

    /**
     *  Drawing  the table of bonus in bonus game
     */
    showBonusInBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y);

        const richText = new Text('10 MORE FREE GAMES', {
            align: 'center',
            fontFamily: 'Times New Roman',
            fontSize: 24,
            fontWeight: 'bold',
            fill: ['#1ca315', '#fbf4ff'], // gradient
            stroke: '#633201',
            strokeThickness: 5,
            lineJoin: 'round'
        });
        richText.x = 261;
        richText.y = 205;
        parentContainer.addChild(richText);
    }

    /**
     * Drawing  the table of end of bonus in bonus game
     */
    showEndBonusFrame(parentContainer, {x, y}, status) {
        this.showBonusFrame(parentContainer, x, y);
        const richText = new Text(`FEATURE WIN\n${status.win} CREDITS\n${status.total} FREE GAMES PLAYED`, {
            align: 'center',
            fontFamily: 'Times New Roman',
            fontSize: 24,
            fontWeight: 'bold',
            fill: ['#1ca315', '#fbf4ff'], // gradient
            stroke: '#633201',
            strokeThickness: 5,
            lineJoin: 'round'
        });
        richText.x = 253;
        richText.y = 204;
        parentContainer.addChild(richText);
    }

    //
    // -------------- Book of fire special book animations ---------------------
    //

    createBookSprite(parentContainer) {
        // create container for book animations
        const bookContainer = new Container();
        bookContainer.name = 'bookContainer';
        parentContainer.addChild(bookContainer);

        const sprite = new AnimatedSprite(this.getSpriteTextures({
            fromFrame: 1, image: 'book_base',
            width: 129, height: 80
        }));

        sprite.name = 'bookAnim';
        sprite.position.set(340, 282);
        sprite.loop = false;
        sprite.play();
        bookContainer.addChild(sprite);
    }

    /**
     * Prepare game behaviour after bonus 'press any button' message
     */
    startBonusAnimation = parentContainer => {
        this.clearPressAnyButton();
        this.Buttons.disableAllButtons();
        App.updateButton('start', {disabled: true});
        this.gameFlag.bonusStarted = true;

        const bonusSymbol = this.bonusRollSymbol;
        [`book_${bonusSymbol}`].forEach(key => {
            !this.Loader.resources[key] && this.resourcesSettings.total++ &&
            this.Loader.add(key, App.getUrl(this.mergePath({img: `bonus/${key}.png`}).img));
        });

        this.Loader.load(() => this.drawBonusAnimation(parentContainer, bonusSymbol));
    };

    /**
     * Draw all bonus animation after bonus 'press any button'
     * @param parentContainer
     * @param bonusSymbol - current bonus symbol in extension
     */

    drawBonusAnimation(parentContainer, bonusSymbol) {
        JL().debug(`-- Draw bonus enter animation. Bonus symbol: ${bonusSymbol}`);

        const props = {
            toFrame: 48,
            width: 129, height: 80,
            colCount: 7
        };

        const sprite = new AnimatedSprite([
            ...this.getSpriteTextures({...props, image: 'book_base'}),
            ...this.getSpriteTextures({...props, toFrame: 53, image: `book_${bonusSymbol}`})
        ]);

        sprite.loop = false;
        sprite.animationSpeed = 0.5;
        sprite.position.set(340, 282);
        sprite.play();
        parentContainer.getChildByName('bookContainer').destroy();

        sprite.onComplete = () => bookGoToTopAnimation(sprite);
        const bookGoToTopAnimation = sprite => {
            const ticker = this.app.ticker;
            App.Sounds.playSound('bookStepUp');
            const bookIsOnTheTop = () => {
                ticker.remove(letBookUp);
                this.bonusRoll();
            };

            const letBookUp = () => {
                sprite.position.y -= 3;
                sprite.position.y <= 0 && bookIsOnTheTop();
            };

            ticker.add(letBookUp);
        };
        App.Sounds.playSound('bookOpen');
        parentContainer.addChild(sprite);
    }

    /**
     * Draw open book at logo
     * @param parentContainer
     */
    /**
     * Draw open book at logo
     * @param parentContainer
     */
    drawBookTop(parentContainer) {
        const x = 0;
        const y = 0;
        const bookContainer = new Container();
        bookContainer.name = 'bookContainer';
        bookContainer.position.set(340, 0);
        parentContainer.addChild(bookContainer);

        const sprite = new Sprite(this.getTexture('book'));
        sprite.name = 'bookTop';
        sprite.position.set(x, y);
        bookContainer.addChild(sprite);

        const width = 47;
        const height = 60;
        const symbolSprite = new Sprite(new Texture(this.getTexture('symbolBook'), {
            x: this.bonusRollSymbol * width,
            y: 0,
            width: width,
            height: height
        }));
        symbolSprite.name = 'symbolBookTop';
        symbolSprite.position.set(68, 8);
        bookContainer.addChild(symbolSprite);
    }

    /**
     * Function to show Top animation before bonus game
     * @param parentContainer
     */
    drawTopAnimation(parentContainer) {

    }
}

class GambleUnique extends GambleNovomatic {
    constructor(imageResources) {
        super(imageResources);
        this.gambleAreaSize = {w: 800, h: 600};
        this.cardPos = {x: 254, y: 160};
        this.cardsQueuePos = {x: [378, 444, 515, 582, 650], y: 375};
        this.screenCoordinates = {x: 0, y: 0};
        this.gambleButtonsSize = {
            bottom: '20.5em',
            width: '15em',
            height: '10em',
            borderRadius: '50%',
            left: '5em',
            right: '5em'
        };
    }

    getCardSuite = card =>
        ['S', 'C'].includes(card[card.length - 1]) ? 'clubs' : 'diamonds';
}
