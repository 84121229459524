import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../../../index';
import PragmaticGames from './../../pragmatic/game';
import Lines from './lines';
import InfoScreen from '../../infoScreen';
import bonusFont from './img/font/fontBonus';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    AnimatedSprite = PIXI.AnimatedSprite,
    Container = PIXI.Container,
    Text = PIXI.Text;

export default class GoldRush extends PragmaticGames {
    constructor() {
        super();
        this.id = 'gold-rush';
        this.name = 'Gold Rush';
        this.defaultFeatureDelay = 2000;
        this.longRoll = false;
        this.reelSettings = [18, 6, 40];
        this.rollProperties = {
            reelSpeed: 1.9,
            springDown: 0.3,
            springUp: 0.2
        };

        // reel properties
        this.reels = 5;
        this.reelRows = 3; // number of rows per reel
        this.reelTop = 59;

        this.reelXCoordinates = [10, 199, 389, 581, 773];
        this.symbolHeight = 180; // height of a single symbol
        this.symbolWidth = 180; // width of a single symbol
        this.allowLongRoll = true;
        this.level = 1;
        this.reelFilter = [[12, 11], [12], [12], [12], [12, 11]];
        this.reelSettings = [18, 6, 40];

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: -30, y: 50},
            bonusInBonusFrame: {x: 330, y: 45},
            endBonusFrame: {x: -30, y: 50}
        };

        this.symbols = [
            {regularDelay: 50, payment: [0, 0, 0, 5, 20, 50]},               // 0 - 10
            {regularDelay: 50, payment: [0, 0, 0, 5, 20, 50]},               // 1 - J
            {regularDelay: 50, payment: [0, 0, 0, 5, 20, 50]},               // 2 - Q
            {regularDelay: 50, payment: [0, 0, 0, 10, 20, 50]},              // 3 - K
            {regularDelay: 50, payment: [0, 0, 0, 10, 20, 75]},              // 4 - A
            {regularDelay: 50, payment: [0, 0, 0, 20, 50, 100]},             // 5 - лопата
            {regularDelay: 50, payment: [0, 0, 0, 20, 100, 200]},            // 6 - фонарь
            {regularDelay: 50, payment: [0, 0, 0, 25, 125, 300]},            // 7 - ослик
            {regularDelay: 50, payment: [0, 0, 0, 25, 150, 400], zIndex: 1}, // 8 - тележка
            {regularDelay: 50, payment: [0, 0, 0, 25, 200, 500], zIndex: 2}, // 9 - Digger
            {regularDelay: 50, payment: [0, 0, 0, 0, 0, 0], zIndex: 3},      // 10 - Wild Динамит
            {regularDelay: 50, payment: [0, 0, 0, 0, 0, 0], zIndex: 3},      // 11 - Scatter
            {regularDelay: 70, payment: [0, 0, 0, 0, 0, 0], zIndex: 5}       // 12 - Gold
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.jpg',
                intro: 'area/intro.jpg',
                introButton: 'area/introButton.png',
                waitingAnimation: 'waitingAnimation.png',
                waitingAnimationBonus: 'waitingAnimationBonus.png',
                longRollEffect: 'area/longRoll.png',
                bonusFont: bonusFont['imageResource']
            }),
            atlas: this.mergePath(['staticSymbols.json']),
            fonts: this.mergePath({bonusFrame: 'font/bonusFrame.ttf'})
        };

        this.additionalResources = {
            main: this.mergePath({
                bonusArea: 'area/bonus.png',
                bonusBackground: 'area/background_bonus.jpg',
                frame1: 'bonus/bonus1.png',
                frame2: 'bonus/bonus2.png',
                explosion2: 'bonus/explosion2.jpg',
                frameAnimation: 'bonus/frameAnimation.jpg', // left frame animation increase points
                frameBurning: 'bonus/frameBurning.png' // right frame animation increase level, add digger to the screen
            }),
            atlas: this.mergePath([
                'additionalSymbols.json',
                'bonus/bonusSymbols1.json',
                'bonus/bonusSymbols2.json',
                'bonus/bonusSymbols3.json'
            ])
        };

        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [
                {name: 'background', loop: true},
                {name: 'bonusBackground', loop: true},
                {name: 'reelsStop'},
                {name: 'reelsStopBonus'},
                {name: 'bonusInBonus'},
                {name: 'bonusEntrance'},
                {name: 'win-line'},
                {name: 'add-credit-background', loop: 'true'},
                {name: 'scatterStop1'},
                {name: 'scatterSound'},
                {name: 'scatterSound2'},
                {name: 'long4'},
                {name: 'long3'},
                {name: 'freeSpin'},
                {name: 'addNuggets'},
                {name: 'bonusEnd'},
                {name: 'startClick'},
                {name: 'goldAnimation'},
                {name: 'bonusFrameLoop', loop: true},
                {name: 'bonusFrameEnd'}
            ],
            path: `/game/games/${this.id}/audio/`
        };
        this.Lines = new Lines(this.mergePath({winLinesBox: 'lines/winBox.png'}));
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
        this.InfoScreen.format = 'png';
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '13pt Arial bold';
        ctx.textAlign = 'center';
        ctx.fillStyle = 'white';

        switch (page) {
            case 1:
                // Digger
                this.strokeFillText(ctx, bet * this.symbols[9].payment[5], 145, 296);
                this.strokeFillText(ctx, bet * this.symbols[9].payment[4], 145, 314);
                this.strokeFillText(ctx, bet * this.symbols[9].payment[3], 145, 332);

                // Golg roller
                this.strokeFillText(ctx, bet * this.symbols[8].payment[5], 315, 296);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[4], 315, 314);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[3], 315, 332);

                // Donkey
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], 485, 296);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], 485, 314);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 485, 332);

                // Gas Lamp
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 655, 296);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 655, 314);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 655, 332);

                // Shufle
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 830, 290);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 830, 306);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 830, 324);

                // A
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 142, 475);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 142, 493);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 142, 510);

                // K
                this.strokeFillText(ctx, bet * this.symbols[3].payment[5], 315, 475);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[4], 315, 493);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[3], 315, 510);

                // Q
                this.strokeFillText(ctx, bet * this.symbols[2].payment[5], 485, 475);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[4], 485, 493);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[3], 485, 510);

                // J
                this.strokeFillText(ctx, bet * this.symbols[1].payment[5], 655, 475);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[4], 655, 493);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[3], 655, 510);

                // 10
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 826, 475);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 826, 493);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 826, 510);
                break;
        }
    }

    setScatterSprite(scatterFeature) {
        scatterFeature.positions.forEach(position => {
            const {reel, row} = position;
            const symbolObj = this.reelMatrix[reel][row];
            symbolObj.image = 'regular';
            symbolObj.loop = false;
            this.Roll.updateSymbolSprite(symbolObj);
            symbolObj.sprite.onComplete = null;
            symbolObj.sprite.play();
        });
    }

    /**
     * Create additional sprites and animations for stage
     * Call once when game loaded
     * @param parentContainer
     */
    createAdditionalSprites(parentContainer) {
        const sprite = this.getStageChild('reelsBackground');
        sprite.position.set(-24, 40);
    }

    drawBonusFrame(first, last, parentContainer, coordinates) {
        this.getStageChild('reelsStage').mask = this.getReelsMask();
        parentContainer.removeChildren();
        const {startBonusFrame, bonusInBonusFrame, endBonusFrame} = coordinates;
        if (first) {
            this.gameFlag.bonusStarted = true;
            App.Sounds.playSound('bonusStart');
            this.drawBonusAnimation(parentContainer, startBonusFrame);
        }
        if (last) {
            App.Sounds.playSound('bonusEnd');
            this.drawBonusEndAnimation(parentContainer, endBonusFrame, this.bonusStatus);
        }
        if (!first && !last) {
            App.Sounds.playSound('freeSpin');
            this.drawBonusInBonusAnimation(parentContainer, bonusInBonusFrame);
        }
    }

    /**
     * drawBonusAnimation
     */
    drawBonusAnimation(parentContainer, {x, y}) {
        App.Sounds.stopSound('background');
        App.Sounds.playSound('bonusEntrance');

        App.updateButton('start', {disabled: true});
        const sprite = new AnimatedSprite(this.Roll.textures['bonus'][this.scatter]);
        sprite.position.set(-160, -50);
        sprite.zIndex = 1;
        sprite.scale.x = 1.6;
        sprite.scale.y = 1.7;
        sprite.animationSpeed = 15 / 50;
        sprite.name = 'bonusAnimation';
        sprite.play();
        sprite.loop = false;
        parentContainer.addChild(sprite);
        sprite.onComplete = () => {
            parentContainer.removeChild(sprite);
            sprite.destroy();
            this.showStartBonusFrame(parentContainer, {x, y});
            this.showStartBonusFrameText(parentContainer, {x, y});
            this.showLevelText(this.getStageChild('waitingContainer'), 0, 1);
            this.setReelsBackground('bonusBackground');
            this.setBackground('bonusArea');
            App.Sounds.playSound('bonusFrameLoop');
            this.addWaitingAnimationSprites(this.getStageChild('waitingContainer'), 'BONUS_START');

            parentContainer.interactive = true;
            App.updateButton('start', {
                disabled: false, title: 'start',
                handler: this.startBonusButton
            });

            parentContainer.on('pointerdown', () => {
                parentContainer.interactive = false;
                parentContainer.removeAllListeners();
                this.startBonusButton();
            });
        };
    }

    /**
     * drawBonusAnimation
     */
    drawBonusInBonusAnimation(parentContainer, bonusInBonusFrame) {
        this.showBonusInBonusFrame(parentContainer, bonusInBonusFrame); // call function only once
    }

    /**
     * End feature animation / take win without prompt
     */
    endAnimateFeature() {
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        App.Sounds.stopSound('win-line');
        if (this.getState() === 'SHOW_WIN_LINES' && this.latestResponse.payment) {
            this.takeWin();
        } else { // no win, just feature without payment
            this.roundFinished();
        }
    }

    showBonusFrame(parentContainer, x, y, image = 'frame1') {
        parentContainer.sortableChildren = true;
        const sprite = new Sprite(this.getTexture(image));
        sprite.name = image;
        sprite.position.set(x, y);
        sprite.zIndex = 0;
        parentContainer.addChild(sprite);
    }

    showFeatureLine(currentFeature, features, allSymbols) {
        const {uc} = currentFeature; // get current feature params
        if (['WIN_LINE', 'SCATTER'].includes(uc)) {
            this.animateSymbolsInLine(currentFeature, allSymbols);
        }
    }

    animateSymbolsInLine(feature, allSymbols) {
        const {uc, number, reels, positions} = feature;
        const increaseAnimationSymbols = [0, 1, 2, 3, 4, 5];

        if (!allSymbols) {
            this.reelMatrix.forEach((reel) => {
                reel.forEach((symbolObj) => {
                    symbolObj.sprite.stop();
                });
            });
        }

        switch (uc) {
            case 'SCATTER':
                positions.forEach(pos => {
                    const {reel, row} = pos;
                    const symbolObj = this.reelMatrix[reel][row];
                    symbolObj.sprite.play();
                });
                break;
            case 'WIN_LINE':
                this.reelMatrix.forEach((reel, reelIndex) => {
                    reel.forEach((symbolObj, rowIndex) => {
                        const {coordinates} = this.Lines.lines[number];
                        if (coordinates[reelIndex] === rowIndex && reels.includes(reelIndex)) {
                            symbolObj.sprite.loop = false;
                            symbolObj.sprite.textures = this.Roll.textures['regular'][symbolObj.symbol];
                            symbolObj.sprite.play();
                            symbolObj.sprite.onComplete = null;
                            if (increaseAnimationSymbols.includes(symbolObj.symbol)) {              // increase symbols 9-A
                                this.increaseSymbolAnimation(symbolObj.sprite);
                            }
                        }
                    });
                });
                break;
        }
    }

    /**
     * Function to stop Animate Feature animation
     */
    stopAnimateFeature() {
        this.stopFeatureTimeout();
        this.getStageChild('linesContainer').removeChildren();
        this.getStageChild('boxesContainer').removeChildren();
        this.Legends.clearText('features');
        !this.isBonus() && this.Legends.clearText('win');
    }

    increaseSymbolAnimation(sprite) {
        if (sprite.scale.x === 1) {
            sprite.loop = false;
            this.showAnimation({
                duration: 1500, animations: [
                    {
                        sprite: sprite,
                        timeline: [{
                            from: {scaleX: 1, scaleY: 1},
                            to: {scaleX: 0.9, scaleY: 0.9},
                            duration: {to: 200}
                        }]
                    },
                    {
                        sprite: sprite,
                        timeline: [{
                            from: {scaleX: 0.9, scaleY: 0.9},
                            to: {scaleX: 1.2, scaleY: 1.2},
                            duration: {from: 200, to: 1000}
                        }]
                    },
                    {
                        sprite: sprite,
                        timeline: [{
                            from: {scaleX: 1.2, scaleY: 1.2},
                            to: {scaleX: 1, scaleY: 1},
                            duration: {from: 1000}
                        }]
                    }
                ],
                onComplete: () => {
                    sprite.onComplete && sprite.onComplete();
                    sprite.onComplete = null;
                }
            });
        }
    }

    /**
     * Process reels response from server.
     * @param response - Socket response 'ROLL'
     */
    processReelResponse(response) {
        this.latestResponse = response;
        this.setState('RESPONSE_RECEIVED');
        this.setBonusRollSymbol(); // for bonus game roll symbol
        this.prepareToRollAnimation(response);
    }

    setRegularShortSprite(clipMatrix, reelIndex, textures) {
        for (let i = 0; i < this.reelRows; i++) {
            const symbolObj = clipMatrix[reelIndex][i + 1];
            if (symbolObj.symbol === this.scatter) {
                if (!this.gameFlag.bonusStarted) {
                    if (reelIndex === 1) {
                        this.allowAnimation[reelIndex] = true;
                        App.Sounds.playSound('scatterStop1');
                        symbolObj.image = 'additional';
                        symbolObj.sprite.textures = this.Roll.textures['additional'][symbolObj.symbol];
                        symbolObj.sprite.loop = false;
                        symbolObj.sprite.play();
                    } else if (this.allowAnimation[reelIndex - 1] === true) {
                        this.allowAnimation[reelIndex] = true;
                        App.Sounds.playSound('scatterStop1');
                        symbolObj.image = 'additional';
                        symbolObj.sprite.textures = this.Roll.textures['additional'][symbolObj.symbol];
                        symbolObj.sprite.loop = false;
                        symbolObj.sprite.play();
                    }
                }
            }
        }
    }

    /**
     * Show bonus message with bonus symbol or with bonus game win.
     * @param isFirstBonus {boolean} TRUE if this is the message for starting bonus game.
     */
    drawBonusAskButton(isFirstBonus = false) {
        let isLast = !isFirstBonus && this.bonusStatus && this.bonusStatus.remain === 0;

        this.stopAnimateFeature();
        this.tickerTimeout(() => {
            this.app && this && this.drawBonusFrame(isFirstBonus, isLast, this.getStageChild('bonusContainer'), this.coordinatesBonusFrame);
        }, 1000);

        this.gameFlag.bonusStart = true;
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});

        isLast = this.bonusStatus && this.bonusStatus.remain === 0;
    }

    /**
     * Function to get free roll count before bonusStatus will be set.
     * @return {string}
     */
    getFreeGames() {
        let amount = 0;
        this.latestResponse.features.forEach(feature => {
            if (feature.uc === 'FREE_ROLL') amount = feature.amount;
        });
        return amount;
    }

    /**
     * Отрисовка таблички бонус в бонусе
     */
    showBonusInBonusFrame(parentContainer, {x, y}) {
        const container = new Container();

        let freeGames = 0;
        this.reelMatrix.forEach(reel =>
            reel.forEach(symbolObj =>
                symbolObj.symbol === this.scatter && freeGames++));

        freeGames = freeGames === 0 ? 1 : freeGames * 2;  // if no scatter but new level +1
        App.Sounds.playSound('bonusInBonus');

        container.interactive = false;
        container.position.x = 0;
        container.position.y = 0;
        parentContainer.addChild(container);

        this.showBonusFrame(container, x, y, 'frame2');
        const richText = new Text('+' + freeGames, {
            fontFamily: 'bonusFrame',
            fontSize: 70,
            align: 'center',
            fill: ['#fe8a02', '#fed700', '#fe8a02'], // gradient
            stroke: '#970205',
            strokeThickness: 6
        });
        richText.x = 445;
        richText.y = 90;
        container.addChild(richText);

        const richText2 = new Text('FREE SPINS', {
            fontFamily: 'bonusFrame',
            fontSize: 50,
            align: 'center',
            fill: ['#fe8a02', '#fed700', '#fe8a02'], // gradient
            stroke: '#970205',
            strokeThickness: 6
        });
        richText2.x = 355;
        richText2.y = 160;
        container.addChild(richText2);

        container.scale.x = 0.01;
        container.scale.y = 0.01;
        container.alpha = 0;
        container.position.x = 480 * container.scale.x;
        container.position.y = 150 * container.scale.y;
        const ticker = this.app.ticker;
        const func = () => {
            container.scale.x += 0.03;
            container.scale.y += 0.03;
            container.alpha += 0.03;
            container.position.x = 480 - 480 * container.scale.x;
            container.position.y = 150 - 150 * container.scale.y;
            if (container.scale.x >= 1) {
                ticker.remove(func);
                this.tickerTimeout(() => {
                    parentContainer.removeChild(container);
                    this.startBonusAnimation(parentContainer);
                }, 1500);
            }
        };
        ticker.add(func);
    }

    /**
     * Отрисовка таблички окончания бонусной игры
     */
    showEndBonusFrame(container, {x, y}, {win, total}) {
        this.showBonusFrame(container, x, y);

        const richText = new Text('CONGRATULATIONS', {
            fontFamily: 'bonusFrame',
            fontSize: 55,
            align: 'center',
            fill: ['#fe8a02', '#fed700', '#fe8a02'], // gradient
            stroke: '#970205',
            strokeThickness: 6
        });
        richText.x = 230;
        richText.y = 90;
        container.addChild(richText);

        const richText2 = new Text('YOU HAVE WON', {
            fontFamily: 'bonusFrame',
            fontSize: 75,
            align: 'center',
            fill: ['#fffefe'], // gradient
            stroke: '#490000',
            strokeThickness: 6
        });
        richText2.x = 230;
        richText2.y = 180;
        container.addChild(richText2);

        const richText6 = new Text(win, {
            fontFamily: 'bonusFrame',
            fontSize: 120,
            align: 'center',
            fill: ['#d8fe00', '#01631b'], // gradient
            stroke: '#edebe8',
            strokeThickness: 3
        });
        richText6.x = 490;
        richText6.y = 314;
        richText6.anchor.set(0.5);
        container.addChild(richText6);

        const richText3 = new Text('IN ', {
            fontFamily: 'bonusFrame',
            fontSize: 60,
            align: 'center',
            fill: ['#fffefe'], // gradient
            stroke: '#490000',
            strokeThickness: 6
        });
        richText3.x = 260;
        richText3.y = 380;
        container.addChild(richText3);

        const richText31 = new Text(total, {
            fontFamily: 'bonusFrame',
            fontSize: 60,
            align: 'center',
            fill: ['#fe8a02', '#fed700', '#fe8a02'], // gradient
            stroke: '#970205',
            strokeThickness: 6
        });
        richText31.x = 335;
        richText31.y = 380;
        richText6.anchor.set(0.5);
        container.addChild(richText31);

        const delta = total < 100 ? 0 : 25;

        const richText32 = new Text('FREE SPINS', {
            fontFamily: 'bonusFrame',
            fontSize: 60,
            align: 'center',
            fill: ['#fffefe'], // gradient
            stroke: '#490000',
            strokeThickness: 6
        });
        richText32.x = 410 + delta;
        richText32.y = 380;
        container.addChild(richText32);

        const richText4 = new Text('PRESS ANYWHERE TO CONTINUE', {
            fontFamily: 'Arial',
            fontSize: 25,
            align: 'center',
            fill: ['#9cf19c'], // gradient
            stroke: '#2b0817',
            strokeThickness: 1
        });
        richText4.x = 280;
        richText4.y = 530;
        container.addChild(richText4);
        container.interactive = true;
    }

    /**
     * drawBonusAnimation
     */
    drawBonusEndAnimation(parentContainer, {x, y}, {win, total}) {
        App.updateButton('start', {disabled: true});
        this.showEndBonusFrame(parentContainer, {x, y}, {win, total});
        App.Sounds.stopSound('bonusBackground');
        App.Sounds.playSound('bonusFrameEnd');
        this.removeLevelText(this.getStageChild('waitingContainer'));
        this.addWaitingAnimationSprites(this.getStageChild('waitingContainer'), 'BONUS_END');

        this.tickerTimeout(() => {
            App.updateButton('start', {
                disabled: false, title: 'start',
                handler: this.endBonusButton
            });
            parentContainer.interactive = true;
            parentContainer.on('pointerdown', () => {
                parentContainer.interactive = false;
                parentContainer.removeAllListeners();
                this.endBonusButton();
            });
        }, 300);
    }

    /**
     * Отрисовка таблички бонусной игры
     */
    showStartBonusFrameText(container, {x, y}) {
        const richText = new Text('CONGRATULATIONS', {
            fontFamily: 'bonusFrame',
            fontSize: 55,
            align: 'center',
            fill: ['#fe8a02', '#fed700', '#fe8a02'], // gradient
            stroke: '#970205',
            strokeThickness: 6
        });
        richText.x = 240;
        richText.y = 90;
        container.addChild(richText);

        const richText2 = new Text('YOU HAVE WON', {
            fontFamily: 'bonusFrame',
            fontSize: 75,
            align: 'center',
            fill: ['#fffefe'], // gradient
            stroke: '#490000',
            strokeThickness: 6
        });
        richText2.x = 230;
        richText2.y = 180;
        container.addChild(richText2);

        const richText6 = new Text(this.getFreeGames(), {
            fontFamily: 'bonusFrame',
            fontSize: 90,
            align: 'center',
            fill: ['#fe8a02', '#fed700', '#fe8a02'], // gradient
            stroke: '#970205',
            strokeThickness: 6
        });
        richText6.x = 440;
        richText6.y = 270;
        container.addChild(richText6);

        const richText3 = new Text('FREE SPINS ', {
            fontFamily: 'bonusFrame',
            fontSize: 60,
            align: 'center',
            fill: ['#fffefe'], // gradient
            stroke: '#490000',
            strokeThickness: 6
        });
        richText3.x = 330;
        richText3.y = 380;
        container.addChild(richText3);

        const richText4 = new Text('PRESS ANYWHERE TO CONTINUE', {
            fontFamily: 'Arial',
            fontSize: 25,
            align: 'center',
            fill: ['#9cf19c'], // gradient
            stroke: '#2b0817',
            strokeThickness: 1
        });
        richText4.x = 280;
        richText4.y = 530;
        container.addChild(richText4);
        container.interactive = true;
    }

    /**
     * Отрисовка текста в бонусном окне
     */
    showLevelText(container, points = 0, level = 1) {
        if (level !== this.level) {
            this.longRoll = true;
            this.level = level;
        }
        let child = container.getChildByName('level');
        container.removeChild(child);
        child = container.getChildByName('points');
        container.removeChild(child);
        const pointsText = points < 15 ? points + '/' : '';
        const pointOf = points < 5 ? '5' : points > 4 && points < 10 ? '10' : points > 10 && points < 15 ? '15' : '15';

        const richText = new Text(level + '', {
            fontFamily: 'bonusFrame',
            fontSize: 36,
            align: 'center',
            fill: ['#ffff00', '#ffde00', '#ff8902'], // gradient
            stroke: '#7f001d',
            strokeThickness: 3,
            dropShadowColor: 'black',
            dropShadowDistance: 3,
            dropShadowBlur: 2,
            dropShadow: true
        });
        richText.x = 242;
        richText.y = 8;
        richText.name = 'level';
        container.addChild(richText);

        const richText2 = new Text('POINTS ' + pointsText + pointOf, {
            fontFamily: 'bonusFrame',
            fontSize: 36,
            align: 'center',
            fill: ['#ffff00', '#ffde00', '#ff8902'], // gradient
            stroke: '#7f001d',
            strokeThickness: 3,
            dropShadowColor: 'black',
            dropShadowDistance: 3,
            dropShadowBlur: 2,
            dropShadow: true
        });
        richText2.x = 754;
        richText2.y = 29;
        richText2.anchor.set(0.5);
        richText2.name = 'points';
        container.addChild(richText2);

        let i = 0;

        const ticker = this.app.ticker;
        const interval2 = this.tickerInterval(() => {
            i++;
            if (i < 5) {
                richText2.scale.x += 0.02;
                richText2.scale.y += 0.02;
            } else {
                richText2.scale.x += 0.02;
                richText2.scale.y += 0.02;
            }
            if (i === 10) {
                ticker.remove(interval2);
                richText2.scale.x = 1;
                richText2.scale.y = 1;
            }
        }, 30);
    }

    /**
     * Очистка текста в бонусном окне
     */
    removeLevelText = container => {
        let child = container.getChildByName('level');
        container.removeChild(child);
        child = container.getChildByName('points');
        container.removeChild(child);
    };

    animationBeforeBonusRoll = () => {
        // get gold possitions
        let i = 0;
        const scatterStack = [];
        const parentContainer = this.getStageChild('bonusContainer');

        this.reelMatrix.forEach((reel, reelIndex) => {
            reel.forEach((symbolObj, rowIndex) => {
                if (symbolObj.symbol === 12) {
                    const sprite = new AnimatedSprite(this.Roll.textures['regular'][12]);
                    sprite.position.set(this.reelXCoordinates[reelIndex] - 47, this.reelTop + (this.symbolHeight * rowIndex) - 43);
                    sprite.animationSpeed = 15 / this.symbols[12].regularDelay;
                    sprite.zIndex = 1;
                    sprite.name = i++;
                    sprite.loop = true;
                    scatterStack.push(sprite);
                    const sprite2 = new AnimatedSprite(this.Roll.textures['regular'][12]);
                    sprite2.position.set(this.reelXCoordinates[reelIndex] - 47, this.reelTop + (this.symbolHeight * rowIndex) - 43);
                    sprite2.animationSpeed = 15 / this.symbols[12].regularDelay;
                    sprite2.loop = false;
                    sprite2.play();
                    sprite.play();
                    parentContainer.addChild(sprite);
                    parentContainer.addChild(sprite2);
                }
            });
        });
        if (!scatterStack.length) {
            this.startBonusRoll();
        } else {
            // Gold elements  flight to the top
            App.Sounds.playSound('goldAnimation');
            const goldAnimation = () => {
                let i = 0;
                const steps = 23;

                const frameAnimation = () => {
                    this.showLevelText(this.getStageChild('waitingContainer'), this.latestResponse.extension.score, this.latestResponse.extension.level, true);
                    const frame = new AnimatedSprite(this.getSpriteTextures({
                        toFrame: 14, image: 'frameAnimation',
                        width: 633, height: 193, colCount: 3
                    }));

                    frame.name = 'frameAnimation';
                    frame.position.set(522, -39);
                    frame.scale.set(0.74, 0.76);
                    frame.loop = false;
                    frame.animationSpeed = 0.4;
                    frame.blendMode = 3;
                    frame.play();
                    this.getStageChild('bonusContainer').addChild(frame);
                };

                const interval2 = this.tickerInterval(() => {
                    i++;
                    if (i > steps - 1) {
                        App.Sounds.playSound('addNuggets');
                        const ticker = this.app.ticker;
                        ticker.remove(interval2);
                        this.setState('IDLE_BONUS');
                        frameAnimation();
                        this.tickerTimeout(() => {
                            this.getStageChild('bonusContainer').removeChildren();
                            this.startBonusRoll();
                        }, 900);
                    } else {
                        scatterStack.forEach(sprite => {
                            sprite.play();
                            sprite.position.x += (780 - sprite.position.x) / (steps - i);
                            sprite.position.y += (20 - sprite.position.y) / (steps - i);
                            sprite.scale.x -= 0.03;
                            sprite.scale.y -= 0.03;
                            if (i > 18) {
                                sprite.visible = false;
                            }
                        });
                    }
                }, 30);
            };
            goldAnimation();
        }
    };

    addWaitingAnimationSprites(parentContainer, state = 'IDLE') {
        let sprite = parentContainer.getChildByName('waitingAnimation');
        let sprite2 = parentContainer.getChildByName('waitingAnimationBonus');
        if (this.waitingType === '') {
            parentContainer.removeChildren();
            sprite = new AnimatedSprite(this.getSpriteTextures({
                toFrame: 4, image: 'waitingAnimation',
                width: 943, height: 94, colCount: 1
            }));
            sprite.name = 'waitingAnimation';
            sprite.position.set(50, 0);
            sprite.animationSpeed = 0.001;
            sprite.gotoAndStop(Math.round(Math.random() * 3));
            sprite.play();
            sprite.visible = true;
            parentContainer.addChild(sprite);

            sprite2 = new AnimatedSprite(this.getSpriteTextures({
                toFrame: 1, image: 'waitingAnimationBonus',
                width: 943, height: 94, colCount: 1
            }));
            sprite2.name = 'waitingAnimationBonus';
            sprite2.position.set(50, 0);
            sprite2.animationSpeed = 0.001;
            sprite2.visible = false;
            parentContainer.addChild(sprite2);
        }
        switch (state) {
            case 'BONUS_START':
                sprite.visible = false;
                sprite2.visible = true;
                break;
            case 'BONUS_END':
                sprite2.visible = false;
                sprite.visible = true;
                break;
            case 'GAME_INTRO':
                sprite2.visible = false;
                sprite.visible = false;
                break;
        }

        this.waitingType = 'IDLE';
    }

    /**
     * Set global state for Game
     * @param state
     */
    setState(state) {
        this.state = state;
        this.app && this && this.addWaitingAnimationSprites(this.getStageChild('waitingContainer'), this.state);
    }

    /**
     * Long roll decider return reel from with long roll started
     *  -1 no long roll
     *  0 1 2 3 4 5 number of reel
     */
    getLongRoll(screen) {
        const scatterMap = [];
        screen.forEach((vector, reelIndex) =>
            vector.forEach(symbol =>
                symbol === this.scatter && scatterMap.push(reelIndex)
            ));

        this.reelSettings = this.longRoll ? [70, 6, 6] : [18, 6, 40];
        this.reelSymbol.forEach((symbolAmount, reelIndex) =>
            this.reelLong[reelIndex] =
                (scatterMap.includes(1) && scatterMap.includes(2) && // 2st and 3rd reel filled
                    reelIndex === this.reels - 2) ? 1 : 0);

        return this.reelLong; // get possible long roll type
    }

    playLongRollAnimation = reelIndex => {
        const longRollEffect = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 14, image: 'longRollEffect',
            width: 113, height: 302, colCount: 14
        }));
        longRollEffect.name = 'longRollEffect';
        longRollEffect.position.set(this.reelXCoordinates[reelIndex] - 10, this.reelTop - 14);
        longRollEffect.scale.set(1.7, 1.85);
        longRollEffect.alpha = 1;
        longRollEffect.animationSpeed = 0.33;
        longRollEffect.blendMode = 3;
        longRollEffect.loop = false;
        longRollEffect.play();
        longRollEffect.onComplete = () => longRollEffect.destroy();

        this.getStageChild('bonusContainer').addChild(longRollEffect);

        this.showAnimation({
            duration: 500,
            animations: [{sprite: longRollEffect, timeline: [{to: {alpha: 1}}]}]
        });
    };

    /**
     * Function to play animate credit sound
     */
    playCreditSound() {
    }

    /**
     * Function to play rool sound
     */
    playRollSound = () => {
    };

    /**
     * Function to play reel stop sound
     */
    playReelStopSound = () => {
        this.getStageChild('bonusContainer').removeChildren();
        App.Sounds.playSound('reelsStop');
    };

    /**
     * Start game animations
     */
    showGameIntroduction() {
        JL().debug('-- Start game intro animation');
        const ticker = this.app.ticker;
        this.setState('GAME_INTRO');
        this.waitingType = 'GAME_INTRO';
        this.addWaitingAnimationSprites(this.getStageChild('waitingContainer'), 'GAME_INTRO');
        this.Buttons.disableAllButtons();
        App.updateButton('autoStart', {disabled: true});
        App.updateState('buttons', {animation: 'hide'});

        const parentContainer = this.getStageChild('bonusContainer');
        parentContainer.interactive = true;
        const sprite = new Sprite(this.getTexture('intro'));
        sprite.name = 'intro';
        sprite.position.set(-160, 0);
        sprite.zIndex = 20;
        parentContainer.addChild(sprite);

        const sprite2 = new Sprite(this.getTexture('introButton'));
        sprite2.name = 'introButton';
        sprite2.position.set(375, 459);
        sprite2.zIndex = 20;
        sprite2.interactive = true;
        parentContainer.addChild(sprite2);

        sprite2.on('pointerdown', () => {
            sprite2.removeAllListeners();
            App.updateState('buttons', {animation: 'show'});
            this.addWaitingAnimationSprites(this.getStageChild('waitingContainer'), 'BONUS_END');
            parentContainer.interactive = false;
            this.playBackgroundSound();
            parentContainer.removeChildren();
            this.roundFinished();
            const ticker = this.app.ticker;
            ticker.remove(func);
        });
        let way = 0;
        sprite2.anchor.set(0.5);
        sprite2.position.set(379 + 167 / 2, 426 + 163 / 2);  // correction on extra size

        const func = () => {
            if (sprite2.scale.x >= 1.05 && !way) {
                way = 1;
            }
            if (sprite2.scale.x < 0.95 && way) {
                way = 0;
            }
            if (!way) {
                sprite2.scale.x += 0.005;
                sprite2.scale.y += 0.005;
            } else {
                sprite2.scale.x -= 0.005;
                sprite2.scale.y -= 0.005;
            }
        };
        ticker.add(func);
    }

    takeWin = (isTransfer = true) => {
        this.stopWaitingAnimation();
        this.stopAnimateSound();
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        JL().debug(`-- Take win (isTransfer: ${isTransfer})`);
        this.setState('TAKE_WIN');

        if (isTransfer && App.restoreGameState !== 'TRANSFER') {
            App.Socket.send(JSON.stringify({uc: 'TRANSFER-START'}));
        }
        !isTransfer && this.animateCredits(this.latestResponse.payment, isTransfer);
        App.Sounds.playSound('add-credit-background');

        this.Buttons.disableAllButtons();
        App.updateButton('start', {
            disabled: false,
            title: 'collect',
            handler: () => { // User can press 'start' twice for increasing transfer speed
                this.isSpeedUp !== 2 && this.isSpeedUp++;
                JL().debug(`-- Take win speed up x${this.isSpeedUp + 1}`);
                // TODO after repeat click -> take all win immediately
            }
        });
    };

    /**
     * Function to stop animate credit sound //Override function from Game.js
     */
    stopCreditSound = () => {
        App.Sounds.stopSound('add-credit-background');
    };

    /**
     * Function to check if long symbol needed
     */
    isLongSymbolOnScreen = () => false;

    /**
     * Create on reels long symbol. Add wilds as we have bonus spin done - wilds on the screen from response
     * @param reelMas
     */
    addLongSymbol = reelMas => {
        const wildCount = 4; // this.bonusStatus.total - this.bonusStatus.remain;
        let noOtherLongSymbols = 0;

        // add wild stack for bonus game
        if (this.isBonus()) {
            reelMas.forEach((reel, reelIndex) => {
                let wildOnTop = 0, wildOnButtom = 0;
                reel.forEach((symbol, rowIndex) => {
                    if (symbol === 10 && reelIndex > 0 && reelIndex < 4 && rowIndex > 0 && rowIndex < 4) {
                        wildOnTop++;
                    }
                    if (symbol === 10 && reelIndex > 0 && reelIndex < 4 && rowIndex > reel.length - 6 && rowIndex < reel.length - 1) {
                        wildOnButtom++;
                    }
                });

                if (reelMas[reelIndex][4] === 10) { // compliment ready symbols on the top
                    for (let i = 5; i < 5 + 4 - wildOnTop; i++) {
                        reelMas[reelIndex][i] = 10;
                        noOtherLongSymbols = 1;
                    }
                }

                if (reelMas[reelIndex][reel.length - 5] === 10) { // compliment ready symbols on the buttom
                    for (let i = reel.length - 6; i > reel.length - 6 - (4 - wildOnButtom); i--) {
                        reelMas[reelIndex][i] = 10;
                        noOtherLongSymbols = 1;
                    }
                }

                if (!(wildOnTop + wildOnButtom)) {             // no wilds on screen add them
                    const startPos = Math.round(Math.random() * (reel.length - 10 - wildCount) + 5);
                    if (reelIndex > 0 && reelIndex < 4) {
                        for (let i = startPos; i < startPos + 4; i++) {
                            reelMas[reelIndex][i] = 10;
                            noOtherLongSymbols = 1;
                        }
                    }
                }
            });
        }
        // add hercules symbols 4 elements to reel
        reelMas.forEach((reel, reelIndex) => {
            let wildOnTop = 0, wildOnButtom = 0;
            reel.forEach((symbol, rowIndex) => {
                if (symbol === 9 && rowIndex >= 0 && rowIndex < 5) {
                    wildOnTop++;
                }
                if (symbol === 9 && rowIndex > (reel.length - 6) && rowIndex < (reel.length - 1)) {
                    wildOnButtom++;
                }
            });

            if (reelMas[reelIndex][4] === 9) { // compliment ready symbols on the top
                for (let i = 5; i < 5 + 4 - wildOnTop; i++) {
                    reelMas[reelIndex][i] = 9;
                }
            }

            if (reelMas[reelIndex][reel.length - 5] === 9) { // compliment ready symbols on the buttom
                for (let i = reel.length - 6; i > reel.length - 6 - (4 - wildOnButtom); i--) {
                    reelMas[reelIndex][i] = 9;
                }
            }

            if (!(wildOnTop + wildOnButtom) && !noOtherLongSymbols) {             // no wilds on screen add them
                const startPos = Math.round(Math.random() * (reel.length - 10 - wildCount) + 5);
                if (reelIndex > 0 && reelIndex < 4) {
                    for (let i = startPos; i < startPos + 4; i++) {
                        reelMas[reelIndex][i] = 9;
                    }
                }
            }
        });
        return reelMas;
    };

    cleanBeforeRoll() {
        this.stopAnimateFeature();
        this.stopWaitingAnimation();
        App.removePopupMessage();
        App.System.resetRollStatistics();
        this.InfoScreen.update({timeout: false, page: 1});
        this.extraBet && this.updateExtraBetButtons(false);
        this.latestResponse = null;
        this.SymbolInfo.remove(false);

        if (!this.isBonus() && this.getState() !== 'IDLE_BONUS') {
            this.Legends.setStatus('goodLuck');
            this.Legends.showJackpot();
            App.Money.withDraw(this.gameSettings.getBet());
            App.updateState('moneyParams', {
                credits: App.Money.getCredit(),
                money: App.Money.getMoney()
            });
        }
        this.Legends.clearText('features');
        this.Legends.clearText('win');
    }

    setBonusStatusText() {
        let {remain} = this.bonusStatus;
        remain -= this.getAmountFromLastResponse();
        this.Legends.setStatus('freeSpinsLeft', {remain});
    }

    getRandomSymbol(length, reelIndex, symbolBefore) {
        const denyRepeat = 1;
        Math.floor(Math.random() * 4); // decrease repeat 5 times less
        let symbol = Math.floor(Math.random() * length);
        if (denyRepeat === 0 && this.doublingFilter.indexOf(symbolBefore) === -1) {
            symbol = symbolBefore;
        } else {
            while (
                this.isBonus() && symbol === 10 ||
                symbol === this.reelFilter[reelIndex][0] ||
                symbol === this.reelFilter[reelIndex][1] ||
                symbol === this.reelFilter[reelIndex][2] ||
                symbol === this.reelFilter[reelIndex][3] ||
                symbol === symbolBefore) {
                symbol = Math.floor(Math.random() * length);
            }
        }
        return symbol;
    }

    restoreBonusGame() {
        this.setReelsBackground('bonusBackground');
        this.setBackground('bonusArea');
        this.addWaitingAnimationSprites(this.getStageChild('waitingContainer'), 'BONUS_START');
        this.level = this.latestResponse.extension.level;
        this.showLevelText(this.getStageChild('waitingContainer'), this.latestResponse.extension.score, this.latestResponse.extension.level);
        App.Sounds.playSound('bonusBackground');
        this.gameFlag.bonusStarted = true;
        this.bonusWin = this.bonusStatus.win - this.latestResponse.payment;
        // Fill WIN data
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});
        this.Legends.showWinFeatures();
        this.setBonusStatusText();

        this.Buttons.disableAllButtons();
        this.gameFlag.bonusStart = true;
        this.gameFlag.bonusStarted = true;

        this.processReelResponse(this.latestResponse);
        this.showAdditionalBonusImage(this.getStageChild('bonusContainer'));
    }

    startBonusButton = () => {
        if (this.getState() === 'BEFORE_BONUS') return;  // to prevent call this function by mouse and keyboard
        App.Sounds.stopSound('bonusFrameLoop');
        App.Sounds.playSound('bonusBackground');
        this.setState('BEFORE_BONUS');
        App.updateButton('start', {disabled: true});
        this.getStageChild('bonusContainer').removeChildren();
        this.getStageChild('bonusContainer').removeAllListeners();
        this.bonusRoll();
    };

    endBonusButton = () => {
        this.setBackground('mainArea');
        App.Sounds.stopSound('bonusFrameEnd');
        App.Sounds.playSound('background');
        App.updateButton('start', {disabled: true});
        this.getStageChild('bonusContainer').removeChildren();
        this.getStageChild('bonusContainer').removeAllListeners();
        this.endBonus();
        this.level = 1;
    };

    playFeatureSound(currentFeature) {
        let soundFile = null;
        switch (currentFeature.uc) {
            case 'WIN_LINE':
                soundFile = 'win-line';
                break;
            case 'SCATTER':
                soundFile = this.isBonus() && this.gameFlag.bonusStart ? '' : 'scatterSound';
                break;
        }

        soundFile && App.Sounds.stopSound(soundFile);
        soundFile && App.Sounds.playSound(soundFile);
    }

    /**
     * Call after all book animation ended
     */
    bonusRoll() {
        App.updateButton('start', {disabled: true});
        const container = this.getStageChild('bonusContainer');
        container.removeChildren();
        this.showAdditionalBonusImage(container);
        this.animationBeforeBonusRoll();  // instead of this.startBonusRoll();
    }

    startRoll() {
        this.getState() === 'IDLE' && this.start();
        this.getState() === 'IDLE_BONUS' && this.bonusRoll();  // instead of this.startBonusRoll();
    }

    restoreRoll() {
        JL().debug(`-- Restore roll - ${JSON.stringify(this.latestResponse)}`);
        App.Sounds.playSound('background');
        this.Legends.showJackpot();
        this.processReelResponse(this.latestResponse);
    }

    /**
     * It should be animation instead of bonus roll with digger flight and fire
     */
    prepareToRollAnimation(response) {
        this.Buttons.disableAllButtons();
        App.updateButton('start', {
            disabled: false,
            title: 'stop',
            handler: () => { // change handler to stop reels animation
                JL().debug('-- Stop roll');
                this.Roll.stopReels = [1, 1, 1, 1, 1, 1];
                this.Roll.fastReelsSound = 1;
                App.updateButton('start', {disabled: true});
            }
        });
        this.extraBet && this.updateExtraBetButtons(false);
        this.Roll.startReelAnimation(response);

        if (this.longRoll) {
            App.Sounds.pauseSound('bonusBackground');
            const maping = [[1, 2, 3], [0, 0, 1, 2, 3, 4, 4, 4, 4], [1, 2, 3, 3, 4], [0, 0, 2, 2, 2, 3, 3, 4]];
            const level = this.latestResponse.extension.level - 1;
            App.updateButton('start', {disabled: true});
            const ticker = this.app.ticker;
            // get scatters
            let i = 0;
            const diggerCount = maping[level].length;
            const scatterStack = [];
            const showedStack = [];
            this.tickerTimeout(() => {
                App.Sounds.playSound('long4');
            }, 400);

            const frameBurning = () => {
                const frame = new AnimatedSprite(this.getSpriteTextures({
                    toFrame: 16, image: 'frameBurning',
                    width: 613, height: 206, colCount: 3
                }));
                frame.name = 'frameBurning';
                frame.position.set(-40, -46);
                frame.scale.set(0.75, 0.75);
                frame.loop = true;
                frame.animationSpeed = 0.4;
                frame.play();
                this.getStageChild('mainContainer').addChild(frame);
            };

            const explosion2 = new AnimatedSprite(this.getSpriteTextures({
                toFrame: 16, image: 'explosion2',
                width: 627, height: 295, colCount: 3
            }));
            explosion2.name = 'explosion2';  // explosion on frame
            explosion2.position.set(-70, -95);
            explosion2.scale.set(1, 1);
            explosion2.alpha = 1;
            explosion2.animationSpeed = 0.23;
            explosion2.blendMode = 3;
            explosion2.loop = false;
            explosion2.play();
            explosion2.onComplete = () => {
                explosion2.destroy();
                frameBurning();
            };
            this.getStageChild('waitingContainer').addChild(explosion2);

            App.Sounds.playSound('bonusEntrance');

            // Digger flight to the center
            const diggerIntergation = () => {
                const interval6 = this.tickerInterval(() => {
                    let symbolIndex = Math.floor(Math.random() * scatterStack.length);
                    while (showedStack.indexOf(symbolIndex) !== -1) {
                        symbolIndex = Math.floor(Math.random() * scatterStack.length);
                    }
                    showedStack.push(symbolIndex);
                    scatterStack[symbolIndex].gotoAndStop(3);
                    scatterStack[symbolIndex].y += 100;
                    this.tickerTimeout(() => {
                        scatterStack[symbolIndex].visible = false;
                    }, 100);
                    this.playLongRollAnimation(maping[level][symbolIndex]);
                    if (showedStack.length === maping[level].length) {
                        ticker.remove(interval6);
                        const frameBurning = this.getStageChild('mainContainer').getChildByName('frameBurning');
                        this.getStageChild('mainContainer').removeChild(frameBurning);
                        this.longRoll = false;
                        this.tickerTimeout(() => {
                            App.Sounds.playSound('bonusBackground');
                        }, 800);
                    }
                }, 450);
            };

            // Digger flight to each reel
            const diggerFlightAnimation = () => {
                let exit = 0;
                const steps = 60;
                const interval2 = this.tickerInterval(() => {
                    i++;
                    scatterStack.forEach((sprite, spriteIndex) => {
                        if (sprite.position.x < this.reelXCoordinates[maping[level][spriteIndex]] - 40) {
                            sprite.position.x += 10 + spriteIndex * 3;
                        } else {
                            sprite.position.x = this.reelXCoordinates[maping[level][spriteIndex]] - 40;
                        }
                        if (i >= steps && !exit) {
                            exit = 1;
                            ticker.remove(interval2);
                            diggerIntergation();
                        }
                    });
                }, 6);
            };

            // function to add diggers on first reel
            const addDiggerOnScreen = () => {
                let i = 0;
                const interval4 = this.tickerInterval(() => {
                    i++;
                    const sprite = new AnimatedSprite(this.Roll.textures['additional'][9]);
                    sprite.position.set(this.reelXCoordinates[0] - 100 + (Math.round(Math.random() * 15) * 5), this.reelTop - 100 + (Math.round(Math.random() * 10) * 2) + 40 * i);
                    sprite.zIndex = 1;
                    sprite.name = 'digger' + i;
                    sprite.loop = false;
                    sprite.animationSpeed = 0.4;
                    sprite.visible = true;
                    sprite.play();
                    scatterStack.push(sprite);
                    this.getStageChild('waitingContainer').addChild(sprite);
                    if (i >= diggerCount) {
                        ticker.remove(interval4);
                        diggerFlightAnimation();
                    }
                }, 20);
            };
            addDiggerOnScreen();
        }
    }

    /**
     * Function to play start button sound
     */
    playStartClickSound() {
        App.Sounds.playSound('startClick');
    }

    playLongRollSound = reelIndex => {
        if (this.reelLong[reelIndex] === 1) {
            this.stopRollSound();
            this.stopLongRollSound();
            this.tickerTimeout(() => App.Sounds.playSound('long3'), 50);
            this.tickerTimeout(() => App.Sounds.stopSound('long3'), 4000);
        }
    };

    additionalPreparingToAnimateFeature(features) {
        let herculesSound = false;
        features.forEach((currentFeature, index) => {
            this.showFeatureLine(currentFeature, features, true);
            if (currentFeature.symbol === 9) {
                herculesSound = true;
            }

            features.length - 1 === index &&
            this.animateFeature(features);
            // change handler to stop animation win line
            !this.isBonus() && App.updateButton('start', {
                disabled: false, title: 'stop',
                handler: this.speedUpWinLineAnimation
            });
        });
        if (herculesSound) {
            App.Sounds.playSound('hercules');
        }
    }

    /**
     * Draw long scatter animation before bonus
     * @param scatterFeature
     * @param callback
     */
    playScatterAnimation(scatterFeature, callback) {
        this.setScatterSprite(scatterFeature);
        App.Sounds.playSound('scatterSound2');

        // get first scatter position
        const {reel, row} = scatterFeature.positions[0];
        const symbolObj = this.reelMatrix[reel][row];

        // call after first scatter played
        symbolObj.sprite.onComplete = () => {
            symbolObj.sprite.onComplete = null; // clear event
            callback();
        };

        this.Legends.setText('features', {text: 'scatterPays', value: scatterFeature.payment});
    }
}
