/**
 * Load sounds by array
 * Create new FontFace object, set font name and url, add additional styles
 * Save loaded font in document.fonts collection
 * @param resources
 * @param styles
 * @param callback
 */
const loadFonts = (resources, styles, callback) => {
    resources.forEach(async item => {
        const fontFamily = item.replace(/\.[^.]*$/, '');
        try {
            const font = new FontFace(fontFamily, `url(/font/${item})`, styles);
            await font.load();
            document.fonts.add(font);
            callback?.(); // load done callback
        } catch (error) {
            console.log(`Load font error: ${error}`); // eslint-disable-line
        }
    });
};

export default loadFonts;
