import React from 'react';
import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../../index';
import BookGame from './../deluxe/bookGame';

/* PIXI aliases */
const Text = PIXI.Text,
    Sprite = PIXI.Sprite,
    Container = PIXI.Container;

export default class GameAmatic extends BookGame {
    constructor() {
        super();

        // reel properties
        this.reelTop = 89; // magic number - where reel images starts
        this.reelXCoordinates = [38, 187, 332, 480, 626];
        this.symbolHeight = 120; // height of a single symbol
        this.symbolWidth = 136; // width of a single symbol
        this.symbolEffects = false; // shadowing and none
        this.defaultFeatureDelay = 2000; // ms for show win line
        this.stopOneReel = true;

        this.DOMComponents.gambleComponent = buttons => <div className='gamble open'>
            {App.Game.DOMComponents.gambleCanvas}
            <section className='red'>
                <button
                    className={`red-color ${buttons.redCard.handler ? '' : 'pressed'}`}
                    onClick={buttons.redCard.handler}
                    style={{backgroundImage: `url(${App.Game.getAdditionalImage(`${buttons.redCard.img}Red`).src})`}}
                />
                <div className='red-suit'>
                    <button
                        className={`red-suit-item ${buttons.heartsSuit.handler ? '' : 'pressed'}`}
                        onClick={buttons.heartsSuit.handler}
                        style={{backgroundImage: `url(${App.Game.getAdditionalImage(`${buttons.heartsSuit.img}Hearts`).src})`}}
                    />
                    <button
                        className={`red-suit-item ${buttons.diamondsSuit.handler ? '' : 'pressed'}`}
                        onClick={buttons.diamondsSuit.handler}
                        style={{backgroundImage: `url(${App.Game.getAdditionalImage(`${buttons.diamondsSuit.img}Diamonds`).src})`}}
                    />
                </div>
            </section>
            <section className='black'>
                <button
                    className={`black-color ${buttons.blackCard.handler ? '' : 'pressed'}`}
                    onClick={buttons.blackCard.handler}
                    style={{backgroundImage: `url(${App.Game.getAdditionalImage(`${buttons.blackCard.img}Black`).src})`}}
                />
                <div className='black-suit'>
                    <button
                        className={`black-suit-item ${buttons.spadesSuit.handler ? '' : 'pressed'}`}
                        onClick={buttons.spadesSuit.handler}
                        style={{backgroundImage: `url(${App.Game.getAdditionalImage(`${buttons.spadesSuit.img}Spades`).src})`}}
                    />
                    <button
                        className={`black-suit-item ${buttons.clubsSuit.handler ? '' : 'pressed'}`}
                        onClick={buttons.clubsSuit.handler}
                        style={{backgroundImage: `url(${App.Game.getAdditionalImage(`${buttons.clubsSuit.img}Clubs`).src})`}}
                    />
                </div>
            </section>
            <button
                className='gamble-half red-color red-shadow disabled-styles'
                onClick={buttons.half.handler}
                disabled={buttons.half.disabled}
            >
                {App.View.state.lang[buttons.half.title]}
            </button>
        </div>;
    }

    /**
     * Function to start logic of Animate feature first step
     */
    startAnimateBonusFeature(bonusSymbol) {
        this.setState('SHOW_BONUS_WIN_LINES');
        this.winLineFeatureDelay /= 5;

        const winReels = this.getBonusWinReels();
        this.prepareBonusFeatures(winReels, bonusSymbol);
        this.prepareBonusSymbols(winReels);
        this.startAnimationBook(winReels);

        const {features} = this.latestResponse;
        JL().debug(`-- Start animate bonus feature: ${JSON.stringify(features)}`);
        this.animateFeature(features);
    }

    showFeatureLine(currentFeature) {
        const {number, reels, payment, uc} = currentFeature; // get current feature params

        const container = this.getStageChild('linesContainer');
        uc !== 'SPECIAL_SYMBOL' && container.removeChildren(); // don't clear lines before special symbol (bookGame fill)

        this.reelMatrix.forEach(reel => reel.forEach(symbolObj =>
            symbolObj.sprite.gotoAndStop(0)));

        if (['WIN_LINE', 'SCATTER'].includes(uc)) {
            uc === 'WIN_LINE' && this.Lines.drawLineImages([number], reels, container, true, payment);
            this.Lines.drawBoxes(this.getStageChild('boxesContainer'), number);
            this.getState() !== 'SHOW_BONUS_WIN_LINES' && this.createFeatureInfo(currentFeature, container);
            this.animateSymbolsInLine(currentFeature);
        }
    }

    createFeatureInfo(feature, container) {
        const {number, symbol, reels, positions, payment, uc} = feature; // get current feature params
        const ucReels = uc === 'WIN_LINE' ? reels.length : positions.length; // check 'SCATTER' feature

        const textProps = {
            font: 'Arial',
            fontSize: 16,
            fontWeight: 600,
            fill: '#f0e7cb'
        };
        const winSymbolsContainer = new Container();
        winSymbolsContainer.position.set(-100, -170);
        winSymbolsContainer.zIndex = 200;
        winSymbolsContainer.name = 'winSymbolsContainer';
        container.addChild(winSymbolsContainer);
        const statusLine = new Text(
            `${uc === 'WIN_LINE' ? App.language.line.toUpperCase() : 'SCATTERED'} ${uc === 'WIN_LINE' ? number + 1 : ''}: `,
            textProps
        );

        const statusPayment = new Text(
            `${'win'.toUpperCase()}: ${payment} `,
            textProps
        );

        statusLine.position.set(240, 460);
        statusPayment.position.set(500, 460);

        for (let i = 0; i <= ucReels - 1; i++) {
            const minimizeSymbol = new Sprite(this.getSpriteTextures({
                image: 'minimizeSymbols',
                fromFrame: symbol,
                colCount: this.symbols.length,
                width: 22,
                height: 19
            })[0]);

            minimizeSymbol.position.x = 450 + (25 * i);
            minimizeSymbol.position.y = 630;
            winSymbolsContainer.addChild(minimizeSymbol);
        }

        container.addChild(statusLine, statusPayment);
    }

    /**
     * Animate feature first step
     */
    prepareToAnimateFeature(features) {
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        this.features.step = 0;

        // check 'WIN_LINE' feature contain
        const isWinLine = features.some(features => features.uc === 'WIN_LINE');
        this.updateExtraSymbols(isWinLine);

        // reset all animations and turn on shadows
        this.reelMatrix.forEach(reel => {
            reel.forEach(symbolObj => {
                // don't hide symbols if only scatter feature
                symbolObj.sprite.alpha = isWinLine && this.symbolEffects ? 0.5 : 1;
                symbolObj.sprite.gotoAndStop(0);
            });
        });

        // unique preparing for each game
        this.additionalPreparingToAnimateFeature(features);
    }

    stopAnimateFeature() {
        this.stopFeatureTimeout();
        this.getStageChild('linesContainer').removeChildren();
        this.resetSymbolAnimation();
        this.Legends.clearText('features');
        !this.isBonus() && this.Legends.clearText('win');
        this.Lines.drawBoxes(this.getStageChild('boxesContainer'));
    }

    getFeatureDelay(currentFeature, features) {
        let delay = 0;

        switch (currentFeature.uc) {
            case 'WIN_LINE':
                delay = !this.gameFlag.bonusStarted ? this.winLineFeatureDelay : this.winLineFeatureDelay / 2;
                break;
            case 'SCATTER':
                delay =
                    features.some(({uc}) => uc === 'FREE_ROLL') &&
                    !features.some(({uc}) => uc === 'WIN_LINE') ?
                        0 : this.winLineFeatureDelay;
                break;
        }
        return delay;
    }
}
