// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../img/registration/verdanab.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  touch-action: pan-x pan-y;\n}\nhtml {\n  height: 110%;\n  /* bigger size for iOS scrolling (hide address bar) */\n}\nbody {\n  width: 100vw;\n  height: 100vh;\n  overflow: hidden;\n  /*fix for Edge*/\n}\nbutton {\n  font-family: arialNova, sans-serif;\n  font-weight: 600;\n  background: transparent;\n  border: none;\n}\nbutton:hover,\nbutton:active {\n  cursor: pointer;\n}\nbutton:focus {\n  outline: 0;\n}\nbutton:disabled {\n  pointer-events: none;\n}\n#wrapper {\n  position: fixed;\n  font-family: arialNova, sans-serif;\n  cursor: default;\n  overflow: hidden;\n}\n.screen {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  background-color: #03030a;\n  z-index: 99;\n  -webkit-user-select: none;\n  /* Safari */\n  -moz-user-select: none;\n  /* Firefox */\n  -ms-user-select: none;\n  /* IE10+/Edge */\n  user-select: none;\n  /* Standard */\n}\n.buttonClose {\n  position: relative;\n  bottom: 0.25em;\n  left: 6em;\n  width: 5em;\n  height: 3em;\n  font-size: 0.8em;\n}\n@font-face {\n  font-family: userBonus;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\n}\n", ""]);
// Exports
module.exports = exports;
