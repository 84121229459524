import {JL} from 'jsnlog';
import Gamble from './../Gamble';

export default class GambleNovomatic extends Gamble {
    constructor(imageResources) {
        super();
        this.imageResources = {
            aceOfClubs: '/img/gamble/novomatic/ace-of-clubs.png',
            aceOfDiamonds: '/img/gamble/novomatic/ace-of-diamonds.png',
            aceOfHearts: '/img/gamble/novomatic/ace-of-hearts.png',
            aceOfSpades: '/img/gamble/novomatic/ace-of-spades.png',
            clubs: '/img/gamble/novomatic/clubs.png',
            diamonds: '/img/gamble/novomatic/diamond.png',
            hearts: '/img/gamble/novomatic/hearts.png',
            spades: '/img/gamble/novomatic/spades.png',
            blackCard: '/img/gamble/novomatic/blue-card.png',
            redCard: '/img/gamble/novomatic/red-card.png',
            activeBlack: '/img/gamble/novomatic/black-active.png',
            activeRed: '/img/gamble/novomatic/red-active.png',
            inactiveBlack: '/img/gamble/novomatic/black-inactive.png',
            inactiveRed: '/img/gamble/novomatic/red-inactive.png',
            ...imageResources // redefine base images from game
        };
        this.cardPos = {x: 334, y: 166};
        this.cardsQueuePos = {x: [375, 441, 507, 573, 639, 705], y: 99};
        this.prizeWinPos = [{x: 208, y: 85}, {x: 595, y: 85}];
        this.screenCoordinates = {x: 0, y: 82};

        JL().debug('-- Gamble Novomatic initialized');
    }
}
