import React from 'react';
import ReactDOM from 'react-dom';
import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';
import packageJson from './../package.json';

import './css/mobile.less';
import './css/portrait.less';
import './css/common.less';
import './css/resolutions/4x3.less';
import './css/resolutions/16x9.less';
import View from './components/View';
import Socket from './modules/socket';
import LocalSocket from './modules/localSocket';
import NodeSocket from './modules/nodeSocket';
import User from './modules/user';
import Money from './modules/money';
import Sounds from './modules/sounds';
import System from './modules/system';
import Menu from './modules/menu';
import Info from './modules/info';
import Cookie from './modules/cookie';
import CountUp from './modules/countUp';
import gameCollection from './game/gamesCollection';
import Wrapper from './modules/wrapper';
import Modal from './modules/modal';
import addJsnLogger from './modules/jsnlog.config';
import convertGameId from './modules/convertGameId';
import toggleFullScreen from './modules/fullscreen';
import loadFonts from './modules/loadFonts';
import getUrl from './modules/getUrl';
import languageCollection from './language/collection';
import App from './index';

export default class AppConstructor {
    constructor(configs) {
        addJsnLogger(this.beforeSend); // configure logger
        this.configs = configs;
        this.version = packageJson.version;
        this.name = location.href.match('maxbet.tax') ? 'MaxBet Casino' : 'Prefer Games';
        document.title = this.name.split(' ').join('');

        this.Cookie = new Cookie();
        this.Game = null;

        // Client settings (save in cookies)
        this.settings = {
            categoryName: this.Cookie.get('categoryName', 'all'), // current slider category
            currentLanguage: this.Cookie.get('language', configs.mode === 'login' ? 'uk' : 'en') // default language
        };
        this.buttonsType = ['kiosk', 'kiosk2'].includes(configs.subMode) ? 'kiosk' : 'base';

        this.language = languageCollection[this.settings.currentLanguage]; // current language texts, default 'en'
        this.languageCollection = languageCollection;
        this.loaderMessages = []; // loader log ('connecting', 'initializing' ...)
        this.getUrl = getUrl; // bind function to this

        this.stopRestoring = false;  //
        this.gameId = null;
        this.newMessage = null;
        this.mesPageLimit = 1;
        this.historyPageLimit = 1;
        this.bonus = 0;
        this.bonusTotal = 0;
        this.optionsPage = 0; // init state no info about bonus or insurnse
    }

    /**
     * Initialize App
     * Create main classes and settings
     * Get base system information and send metrics
     * Establishing WebSocket connection
     */
    init() {
        JL().debug('-- Init App');

        // Main classes
        this.System = new System();
        this.LocalSocket = new LocalSocket();
        this.NodeSocket = new NodeSocket();
        this.User = new User();
        this.Socket = new Socket();
        this.Sounds = new Sounds(this.Cookie.get('soundVolume', 0.5));
        this.Sounds.onLoad = this.soundsLoaded;
        this.Money = new Money();
        this.Menu = new Menu();
        this.Modal = new Modal();

        this.language = this.getLang(this.settings.currentLanguage, true);
        this.renderViewComponent(this.language);
        this.System.getSystemInfo();
        this.Wrapper = new Wrapper(this.System.resolution);
        this.Wrapper.platform = this.System.platform;
        this.Wrapper.browser = this.System.browser;
        this.Wrapper.doubleScreen = this.configs.doubleScreen;
        this.Wrapper.resizeCallback = this.checkSize;
        this.Wrapper.init();
        this.System.updateOrientation();
        this.System.createServiceWorker();
        this.System.sendMetric({param: 'enter'});
        this.System.sendMetric({param: `mode.${this.configs.subMode}`});
        this.System.isPWAInstalled() && this.System.sendMetric({param: 'pwa.launch'});
        this.System.getVideoDevice();
        this.System.getPreviousWebPage();
        this.System.getIp();
        this.System.initBroadcastChannel();
        this.System.updateInstallButton();
        this.loadSounds();
        loadFonts(['arialNova.woff2', 'Myriad-Pro-Light.woff2'], {}, () => this.View.setState({fontsLoaded: true}));
        loadFonts(['OpenSans-ExtraBold.woff'], {weight: 'normal', style: 'normal'});
        loadFonts(['st_microsquare.woff2']);
        this.updateState('loader', {active: true});
        this.configs.NODE_ENV !== 'development' && this.NodeSocket.init();

        switch (this.configs.mode) {
            case 'vlt':
                this.LocalSocket.init();
                break;
            case 'login':
                this.User.init(this.Cookie.get('code'));
                break;
            default:
                this.Socket.init();
                break;
        }
    }

    initInfo() {
        JL().debug('-- Init App');

        // Main classes
        this.System = new System();
        this.LocalSocket = null;
        this.Socket = null;
        this.NodeSocket = new NodeSocket();
        this.User = new User();
        this.Sounds = new Sounds(this.Cookie.get('soundVolume', 0.5));
        this.Sounds.onLoad = this.soundsLoaded;
        this.Money = new Money();
        this.Menu = new Menu();
        this.Modal = new Modal();
        this.Info = new Info();

        this.language = this.getLang(this.settings.currentLanguage, true);
        this.renderViewComponent(this.language, 'INFO');
        this.System.getSystemInfo();
        this.Wrapper = new Wrapper(this.System.resolution);
        this.Wrapper.platform = this.System.platform;
        this.Wrapper.browser = this.System.browser;
        this.Wrapper.doubleScreen = this.configs.doubleScreen;
        this.Wrapper.resizeCallback = this.checkSize;
        this.Wrapper.init();
        this.System.updateOrientation();
        this.System.createServiceWorker();
        this.System.sendMetric({param: 'enter'});
        this.System.sendMetric({param: `mode.${this.configs.subMode}`});
        this.System.isPWAInstalled() && this.System.sendMetric({param: 'pwa.launch'});
        this.System.getVideoDevice();
        this.System.getPreviousWebPage();
        this.System.getIp();
        this.System.updateInstallButton();
        this.loadSounds();
        loadFonts(['arialNova.woff2', 'Myriad-Pro-Light.woff2'], {}, () => this.View.setState({fontsLoaded: true}));
        loadFonts(['OpenSans-ExtraBold.woff'], {weight: 'normal', style: 'normal'});
        loadFonts(['st_microsquare.woff2']);
        this.updateState('loader', {active: false});
        this.configs.NODE_ENV !== 'development' && this.NodeSocket.init();
    }

    /**
     * Render React View component after Socket initialization
     * Bind React component to App
     * @param language
     * @param state - init View state
     */
    renderViewComponent(language, state = 'NONE') {
        JL().debug('-- Render View component');
        this.View = ReactDOM.render(
            <View lang={language} initState={state}/>, // main component, entry point for others
            document.getElementById('wrapper') // container for React.Component
        );
    }

    /**
     * Update View states
     * @param target - state block
     * @param state - return new states
     */
    updateState(target, state) {
        state && this.View.setState(prevState => ({
            [target]: {
                ...prevState[target],
                ...state
            }
        }));
    }

    /**
     * Function call before send JSNLogger error
     * @param xhr
     */
    beforeSend = xhr => {
        const {os, browser, browserVersion, platform, ip, city, videoDevice, previousWebPageURL} = this.System;
        xhr.setRequestHeader('Info', JSON.stringify({
            State: this.Game ? `Game - ${this.Game.name} (${this.Game.getState()})` : 'Other state',
            Referrer: previousWebPageURL ? previousWebPageURL.href : null,
            ViewState: this.View.state.currentState,
            OS: os,
            Browser: `${browser} v.${browserVersion}`,
            Platform: platform,
            IP: ip,
            City: city,
            Renderer: videoDevice,
            Resolution: `${window.screen.width}x${window.screen.height}`,
            Balance: this.Money.getCents(),
            Date: new Date()
        }));
    };

    /**
     * Create Game object and bind to App
     * Choose game class from id
     * Socket response 'START-GAME'
     * @param gameId
     */
    createGame(gameId) {
        const gameName = convertGameId(gameId);

        JL().debug(`-- Create game - '${gameName}'`);

        // check game in collection
        if (gameCollection[gameName]) {
            this.updateState('loader', {
                active: true,
                gameLogo: this.getUrl(`/game/games/${gameId}/img/icon-head.png`)
            });
            this.Game = new gameCollection[gameName]();
            this.Game.setState('INIT');
        } else {
            JL().debug(`-- Game not found - '${gameName}'`);
            this.Socket.webSocket.close(); // stop connection
            this.stopRestoring = true;
            this.showSocketMessage('exitAndReload');
            this.updateState('error', {iFrame: 'about:blank'}); // mount <iframe> without src

            // wait for socket closing
            setTimeout(() => this.updateState('error', {
                exitButton: true,
                timer: null,
                iFrame: this.System.getOldSystemLink()
            }), 2000);
        }
    }

    /**
     * Function called after all images loaded
     * Update View state to 'GAME'
     * Show START button for full screen on mobile platforms (except iOS)
     */
    renderGameComponent() {
        JL().debug('-- Render Game component');
        this.Game.setState('CREATE_DOM');
        this.Game.DOMComponents.gambleCanvas = this.Game.Gamble?.getCanvas();
        this.updateButton('close', {visible: !['kiosk', 'kiosk2', 'game', 'vlt-primary'].includes(this.configs.subMode)});
        this.updateButton('autoStart', {handler: this.Game.autostart});
        this.View.state.currentState !== 'MENU' && // from menu
        this.View.setState({currentState: 'GAME'});

        if (this.configs.mode === 'info') {
            this.Game.setState('INFO');
            App.updateState('buttons', {animation: 'hide-panel'});
            App.updateState('controlButtons', {animation: 'hide-panel'});
        }
    }

    /**
     * Update View state to 'MENU'
     * Show START button for full screen on mobile platforms (except iOS)
     */
    renderMenuComponent() {
        JL().debug('-- Render Menu component');
        this.addLoaderMessage(this.language.initializingMenu);
        document.title = `${this.name.split(' ').join('')}, choose game to play`;

        this.View.setState({currentState: 'MENU'}); // last message in Menu state
    }

    /**
     * Games separation by status and system
     * @param games - Socket response 'AVAILABLE-GAMES'
     */
    parseGameData(games = []) {
        const {availableGames} = this.configs;
        const {categoryName} = this.settings;

        // filter available games
        games = games.filter(gameObj => {
            let enabled = false;
            availableGames.forEach(game => {
                if (game.name === gameObj.name) { // verify existing in available games
                    enabled = true;
                    gameObj.status = game.status || 'ACTIVE'; // set status
                    gameObj.categories = [...game.categories]; // create new array instance
                    gameObj.animatedLogo = game.animatedLogo || false;
                    game.status && gameObj.categories.push(game.status);
                }
            });
            return enabled;
        });

        const sortedGames = games.sort((game1, game2) => game1.name > game2.name ? 1 : -1);
        this.games = {all: sortedGames};

        // collect games by categories
        sortedGames.forEach(gameObj => {
            gameObj.categories.forEach(categoryName => {
                const category = categoryName.toLowerCase();
                !this.games[category] && (this.games[category] = []); // check category array
                this.games[category].push(gameObj);
            });
        });

        // check available category in collection, prevent incorrect category (from cookie)
        !Object.keys(this.games).includes(categoryName) ?
            this.Menu.setCategory('all') :
            this.Menu.updateVisibleGames();
    }

    /**
     * Refresh balance/insurance
     * Updating View only if response updating
     * @param response - Socket response 'BALANCE'
     */
    updateBalance(response) {
        if (
            this.Money.getCents() !== response.value ||
            this.Money.getInsurance() !== response.insurance
        ) {
            this.Money.setCents(response.value);
            this.Money.setInsurance(response.insurance);

            // update View component with new state
            this.updateState('moneyParams', {
                credits: this.Money.getCredit(),
                money: this.Money.getMoney(),
                insurance: this.Money.getInsurance().toFixed(2)
            });
        }
    }

    /**
     * Refresh balance/insurance
     * Updating View only if response updating
     * @param response - Socket response 'BALANCE'
     */
    updateBonusBalance(response) {
        if (response.amount > 0) {
            this.optionsPage = 1;
        } else {
            this.optionsPage = 0;
        }

        this.bonus = response.amount;
        this.bonusTotal = response.totalBet;

        // update View component with new state
        this.updateState('moneyParams', {
            bonus: this.bonus,
            bonusTotal: this.bonusTotal
        });
    }

    /**
     * Create available jackpots in Money.jackpot (gold/silver/bronze)
     * Set text and default values
     * @param response - Socket response 'AVAILABLE-INFO'
     */
    createJackpot(response) {
        JL().debug(`-- Create jackpots: ${JSON.stringify(response)}`);
        Object.keys(response).forEach(jackpotType => {
            response[jackpotType] ? // update global money jackpots
                this.Money.setJackpot(jackpotType, {
                    text: jackpotType,
                    value: this.Money.jackpot[jackpotType]?.value ?? '0.00'
                }) :
                delete this.Money.jackpot[jackpotType];
            response[jackpotType] = +this.Money.jackpot[jackpotType]?.value ?? 0; // reset value for update
        });
        this.updateState('moneyParams', {jackpot: this.Money.jackpot});
        this.refreshJackpots(response);
    }

    /**
     * Refresh all jackpots
     * Updating View only if response updating
     * @param response - Socket response 'PING'
     */
    refreshJackpots(response) {
        let isUpdated = false;
        const values = {}; // collection of will updated jackpots

        // check if response contains new values
        Object.keys(this.Money.jackpot).forEach(jackpotType => {
            const prevValue = +this.Money.jackpot[jackpotType].value;
            const nextValue = response[jackpotType];

            if (prevValue !== nextValue) {
                isUpdated = true;
                // create object for each updated jackpot
                values[jackpotType] = {start: prevValue, end: nextValue, current: prevValue};
            }
        });
        this.Money.countUpFrames.forEach(countUp => countUp.reset()); // reset previous animations

        // check and start numbers animation
        if (isUpdated) {
            const onTick = values => {
                const jackpots = {};
                Object.keys(values).forEach(key => {
                    jackpots[key] = {text: key, value: values[key].current.toFixed(2)};
                    this.Money.setJackpot(key, jackpots[key]); // update global money jackpots
                });
                this.updateJackpots(jackpots);
            };

            const countUp = new CountUp({
                values,
                duration: 2000,
                notAnimate: 'decrease'
            });
            countUp.onTick(onTick);
            countUp.start();
            this.Money.countUpFrames.push(countUp);
        }
    }

    /**
     * Update jackpot states
     * @param jackpots - {gold, silver, bronze}
     */
    updateJackpots(jackpots) {
        this.View.setState(({moneyParams}) => ({
            moneyParams: {
                ...moneyParams,
                jackpot: {...moneyParams.jackpot, ...jackpots}
            }
        }));
    }

    /**
     * Remove all jackpots
     */
    clearJackpots() {
        this.Money.jackpot = {};
        this.updateState('moneyParams', {jackpot: {}});
    }

    /**
     * Show message on <Loader>
     * @param errorMessage
     * @param logError - if need send log
     * @param errorIcon - special error icon name
     */
    showSocketMessage(errorMessage, logError = true, errorIcon) {
        JL().debug(`-- Show socket message: '${errorMessage}'`);
        this.clearLoaderMessages();
        const checkPrevMessage = ({message, icon}) => {
            if (message) { // don't change message if not empty
                return {message, icon};
            } else {
                logError && this.System.logError(errorMessage);
                return {message: errorMessage, icon: errorIcon};
            }
        };

        this.View.setState(prevState => ({
            login: this.View.getInitState().login,
            buttons: {
                ...prevState.buttons,
                close: {
                    ...prevState.buttons.close,
                    disabled: ['vlt', 'player'].includes(this.configs.mode),
                    handler: this.closeClick
                },
                user: {...prevState.buttons.user, visible: false},
                message: {...prevState.buttons.message, visible: false}
            },
            loader: {
                ...prevState.loader,
                active: true,
                fade: 'in',
                activeControlButtons: this.configs.platform !== 'mobile',
                progress: null,
                gameLogo: null
            },
            error: {
                ...prevState.error,
                reloadButton: true,
                timer: null,
                ...checkPrevMessage(prevState.error)
            }
        }));
    }

    /**
     * Reset WebSocket connection
     * Reset states
     * @param callback
     */
    resetConnection(callback) {
        const onClose = () => {
            this.resetLoader();
            this.updateState('error', this.View.getInitState().error);
            this.updateState('loader', {active: true});
            callback?.();
        };

        if (this.Socket.webSocket) {
            this.Socket.webSocket.addEventListener('close', onClose);
            this.Socket.webSocket.close();
        } else {
            callback?.();
        }
    }

    /**
     * Reset <Loader> states
     */
    resetLoader = () => {
        this.updateState('loader', this.View.getInitState().loader);
        this.clearLoaderMessages();
    };

    /**
     * Reset <ErrorMessage> states
     */
    resetErrorMessage = () =>
        this.updateState('error', this.View.getInitState().error);

    /**
     * Clear all showed errors
     */
    clearErrors = () => {
        this.View.setState({clientErrors: []});
    };

    /**
     * Show message on screen
     * @param message
     */
    createPopupMessage(message) {
        JL().debug(`-- Create popup message: ${message}`);
        this.removePopupMessage();
        setTimeout(() => this.updateState('popup', {message}), 10);
    }

    /**
     * Reset <Popup> component
     */
    removePopupMessage = () => this.updateState('popup', {message: null});

    /**
     * Update states for button
     * @param button - name, example: 'start'
     * @param props - obj, new states, example: {disabled: false, title: 'start'}
     */
    updateButton = (button, props) =>
        this.View.setState(({buttons}) => ({
            buttons: {
                ...buttons, // don't redefine other buttons
                [button]: {
                    ...buttons[button], // save other button state
                    ...props // new button states
                }
            }
        }));

    /**
     * Enable timer to exit in error message
     * Show 'Exit now' button
     */
    redirectTimer() {
        if (!['vlt', 'player'].includes(this.configs.mode)) {
            let counter = 60;

            const redirect = setInterval(() => {
                counter--;
                counter <= 0 && this.closeClick();
                counter <= 0 && clearInterval(redirect);
                this.updateState('error', {exitButton: true, timer: counter});
            }, 1000);
        }
    }

    /**
     * Clear Game resources
     */
    clearGameResources() {
        this.updateButton('start', {disabled: true});
        this.updateButton('autoStart', {disabled: true});
        this.updateButton('close', {visible: !['kiosk2', 'game', 'vlt-primary'].includes(this.configs.subMode)});
        this.Game.Loader.reset(); // clear all resources and textures from loader
        window.removeEventListener('resize', this.Game.resizeStage);

        // check created PIXI.Application
        if (this.Game.app) {
            this.Game.app.stage.destroy({children: true, baseTexture: true});
            this.Game.app.destroy(true); // true - remove canvas from DOM
        }

        PIXI.Ticker.shared.stop(); // will stop Application rendering
        PIXI.utils.clearTextureCache(); // clear alle resources and textures from cache
        this.Sounds.clearResources();
        this.Game = null;
    }

    /**
     * Game item handler in menu
     * Send WebSocket request 'START-GAME'
     * @param gameId
     */
    chooseGame = gameId => {
        if (this.Socket.restoreSocket) {
            return;
        }
        JL().debug(`-- Choose game: '${gameId}'`);
        this.gameId = gameId;
        this.Sounds.playSound('click');
        this.Socket.gameId = gameId;
        this.Socket.send(JSON.stringify({uc: 'START-GAME', game: gameId}));
        this.LocalSocket.updateBillAcceptor('DISABLE');
        this.updateState('slider', {
            disabledSlider: 'lockSlider',
            buttonLeftHandler: null,
            buttonRightHandler: null
        });
        this.updateButton('close', {disabled: true});
    };

    /**
     * Close button handler (only in 'GAME' state)
     * Send WebSocket request 'EXIT-GAME'
     * Reset Game class
     */
    exitGame = () => {
        if (this.configs.mode === 'site' || this.configs.mode === 'demo') {
            window.parent.location.href = 'https://toplay.bet';
            return;
        }
        JL().debug(`-- Exit from game: '${this.Game.name}'`);
        this.Sounds.playSound('click');
        this.Game.setState('EXIT-GAME');
        this.updateState('slider', {disabledSlider: ''});
        this.Game.Buttons.disableAllButtons();
        this.updateButton('start', {disabled: true});
        this.updateButton('autoStart', {disabled: true}, {handler: null});
        this.updateButton('gameSettings', {status: false, additionalClass: ''});
        this.Socket.send(JSON.stringify({uc: 'EXIT-GAME'}));
        this.System.updateInfo({uc: 'MENU'});
    };

    /**
     * Reset and clear all game resources and classes
     * Stop additional animations
     */
    destroyGame() {
        this.Game.resetGame();
        this.Sounds.stopAllSounds();
        this.View.setState({activePrizeWin: false});
        this.clearGameResources();
    }

    /**
     * Change the volume of music when range button is using.
     * Save volume to cookie
     * Dynamic sounds loading
     * Change button icon
     * @param volume - position of input[type='range']
     * @param saveVolume - false -> prevent saving (on each step in input.onChange)
     */
    soundChange = (volume, saveVolume = true) => {
        if (saveVolume && this.Sounds.volume !== 0) {
            this.Sounds.oldVolume = this.Sounds.volume;
        }
        const imgPosition = volume > 0 ? '50% 33%' : '0 33%';
        this.Sounds.setVolume(volume);
        this.updateButton('soundVolume', {
            defaultVolume: volume,
            imgSrc: `url(${this.getUrl('/img/controls.png')}) ${imgPosition} / 300% no-repeat`
        });
    };

    /**
     * Click on sound button
     * Set master volume to 0 or old value
     */
    soundClick = () => {
        const {volume, oldVolume} = this.Sounds;
        const newVolume = volume > 0 ? 0 :
            oldVolume === 0 ? 0.5 : oldVolume;
        this.soundChange(newVolume);
        this.Sounds.playSound('click');
    };

    /**
     * Update <SwipeUp> component
     */
    checkSize = () => {
        const {innerHeight, outerHeight} = window;
        const {scaledW, scaledH} = this.Wrapper.getSize();
        document.getElementById('wrapper').style.setProperty('display', '');
        const {os, browser, platform} = this.System;

        const active = !(
            os === 'iOS' && innerHeight === outerHeight || // swipe up for Safari
            browser === 'crios' || // hide on iOS Chrome
            platform === 'desktop' || // show only on mobile
            (browser === 'ios' ? // available swipe up for iPhone Safari
                this.Wrapper.getOrientation() === 'portrait' : // disable for portrait mode on iOS
                scaledW >= screen.width || scaledH >= screen.height) || // max value for one side
            document.fullscreenElement || // already in full screen mode
            this.System.isPWAInstalled() // hide on installed application
        );
        this.updateState('swipeUp', {active});
    };

    /**
     * Click on full screen button and change buttons icon
     */
    fullScreenClick = () => {
        this.checkSize();
        this.Sounds.playSound('click');
        toggleFullScreen(err => JL().debug(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`));
    };

    /**
     * Handle 'fullscreenchange' event
     * Update button and resize Wrapper
     */
    fullScreenChanged = () => {
        JL().debug(`-- Full screen click (${!!document.fullscreenElement})`);
        this.updateButton('fullScreen', {img: !document.fullscreenElement ? '0 0' : '50% 0'});
        this.Wrapper.resize();
    };

    /**
     * Close button handler (only in 'MENU' state)
     * Exit to check code panel (only in 'club' mode)
     * Cut code from current url (as example: from '/club/:code' to '/club/')
     */
    closeClick = () => {
        if (this.configs.mode === 'site' || this.configs.mode === 'demo') {
            window.parent.location.href = 'https://toplay.bet';
            return;
        }
        this.Sounds.playSound('click');
        switch (this.configs.subMode) {
            case 'cosmolot':
            case 'kiosk':
                location.href = 'http://*closekiosk*';
                break;
            case 'player-primary':
            case 'vlt':
                break;
            case 'new-game':
                location.href = this.System.getOldSystemLink().replace(this.configs.code, '');
                break;
            case 'login-primary':
                this.User.unauthorized();
                break;
            default:
                location.href = location.href.replace(this.configs.code, '');
                break;
        }
    };

    /**
     * Collapse mobile control buttons
     */
    hideMobileBurger = () =>
        this.updateButton('controlButtons', {disabled: true});

    /**
     * Add to load log new message
     * @param text - message
     */
    addLoaderMessage = text => {
        this.loaderMessages.push(text);
        this.updateState('loader', {messages: this.loaderMessages});
    };

    /**
     * Clear all load content
     */
    clearLoaderMessages = () => {
        this.loaderMessages = [];
        this.updateState('loader', {messages: []});
    };

    /**
     * Language changing
     * @param lang - it can be 'en' - english localization, or 'uk' - ukrainian localization.
     */
    setLang(lang) {
        JL().debug(`-- Change language to '${lang}'`);
        this.Sounds.playSound('click');
        this.settings.currentLanguage = lang;
        this.Cookie.set('language', lang);
        this.language = this.getLang(lang);
        this.updateLanguage(lang);
    }

    /**
     * Get exist language from collection
     * @param lang
     * @param sendMetric
     */
    getLang(lang, sendMetric = false) {
        // check is current language exist
        if (!Object.keys(this.languageCollection).includes(lang)) {
            lang = 'en'; // redefined for return
            this.setLang(lang); // set default
        }

        sendMetric && this.System.sendMetric({param: `language.${lang}`});
        return this.languageCollection[lang];
    }

    /**
     * Update main <View> component language
     * Translate PIXI.Application elements
     * @param lang - current language
     */
    updateLanguage(lang) {
        this.View?.setState({lang: this.getLang(lang)});
        this.Game && this.Game.app && this.Game.translateStage(this.language);
    }

    /**
     * Prepare main system sounds to loading
     */
    loadSounds(collection = 'mainSounds') {
        this.updateButton('soundVolume', {
            imgSrc: `url(${this.getUrl('/img/sound-spinner.png')}) 0 0 / 100% no-repeat`,
            soundSpinner: 'sound-spinner',
            disabled: true
        });
        this.Sounds.loadSounds(this.Sounds.soundsCollection[collection], this.soundsLoaded);
    }

    /**
     * Main system sounds are loaded
     */
    soundsLoaded = () => {
        const imgPosition = this.Sounds.volume > 0 ? '50% 33%' : '0 33%';
        this.updateButton('soundVolume', {
            imgSrc: `url(${this.getUrl('/img/controls.png')}) ${imgPosition} / 300% no-repeat`,
            soundSpinner: '',
            disabled: false
        });
    };

    showLoadPercent(resourcesLoaded, resourcesLength = 1) {
        if (this.configs.mode !== 'info') {
            const percent = resourcesLoaded / resourcesLength * 100;
            this.Socket.webSocket && this.updateState('loader', {progress: percent.toFixed()});
        }
    }

    /**
     * Change language on mobile platform
     */
    changeLanguageMobile = () => {
        const availableLanguages = Object.keys(this.languageCollection);
        const indexLang = availableLanguages.indexOf(this.settings.currentLanguage);
        const newLang = availableLanguages[(indexLang + 1) % availableLanguages.length];
        this.setLang(newLang);
    };

    /**
     * Copy selected to clipboard
     * Create input and append to body, remove after command 'copy'
     * @param text
     */
    copyText = text => {
        const input = document.createElement('input');
        input.style.opacity = '0';
        input.value = text;
        document.body.appendChild(input);
        input.select();
        input.setSelectionRange(0, 99999); // for mobile devices
        document.execCommand('copy');
        this.createPopupMessage(`${this.language.copied}: ${input.value}`);
        document.body.removeChild(input);
    };

    showSpinner = () => {
        JL().debug('-- Show spinner');
        this.updateState('waiting', {active: true});
    };

    hideSpinner = () => {
        JL().debug('-- Hide spinner');
        this.updateState('waiting', {active: false});
    };

    nextBonus = () => {
        this.optionsPage = (this.optionsPage + 1) % 2;
    };
}
