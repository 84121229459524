import App from './../index';

export default class Buttons {
    createContainer() {

    }

    update() {

    }

    disableAllButtons = () => {
        App.Game.Lines.updateBoxesState(false);
        App.View.setState(prevState => ({
            buttons: {
                ...prevState.buttons,
                // control buttons
                close: {
                    ...prevState.buttons.close,
                    disabled: true
                },

                // main game buttons
                select: {
                    ...prevState.buttons.select,
                    subTitle: '',
                    disabled: true
                },
                info: {
                    ...prevState.buttons.info,
                    disabled: true
                },

                // game settings buttons
                lines: {
                    ...prevState.buttons.lines,
                    disabled: App.System.platform !== 'mobile' // enable on mobile platform
                },
                bet: {
                    ...prevState.buttons.bet,
                    disabled: App.System.platform !== 'mobile' // enable on mobile platform
                },
                total: {
                    ...prevState.buttons.total,
                    disabled: App.System.platform !== 'mobile' // enable on mobile platform
                },
                denomination: {
                    ...prevState.buttons.denomination,
                    disabled: App.System.platform !== 'mobile' // enable on mobile platform
                },
                options: {
                    ...prevState.buttons.options,
                    disabled: true
                },
                // info buttons
                prevInfo: {disabled: true},
                nextInfo: {disabled: true},

                // settings button on mobile platform
                gameSettings: {
                    ...prevState.buttons.gameSettings,
                    disabled: true,
                    handler: null
                }
            }
        }));
    };

    setDefaultGameButtons() {
        this.updateGameSettings();
        App.View.setState(prevState => ({
            buttons: {
                ...prevState.buttons,
                // control buttons
                close: {
                    ...prevState.buttons.close,
                    disabled: App.configs.subMode === 'game',
                    handler: App.exitGame
                },

                // main game buttons
                start: {
                    disabled: false,
                    title: 'start',
                    handler: App.Game.start
                },
                select: {
                    disabled: (App.configs.subMode === 'game' && App.configs.gameId !== ''),
                    title: 'select',
                    subTitle: '',
                    handler: App.exitGame,
                    additionalClass: ''
                },
                info: {
                    ...prevState.buttons.info,
                    disabled: false,
                    title: 'paytable',
                    handler: App.Game.InfoScreen.show,
                    additionalClass: ''
                },
                autoStart: {
                    ...prevState.buttons.autoStart,
                    disabled: false
                },
                options: {
                    ...prevState.buttons.options,
                    handler: App.nextBonus,
                    disabled: false
                },

                gameSettings: {
                    ...prevState.buttons.gameSettings,
                    disabled: false,
                    handler: App.Game.openGameSettings
                }
            }
        }));
    }

    updateGameSettings() {
        const suffix = App.Game.extraBetActive ? '+' : '';
        App.View.setState(prevState => ({
            buttons: {
                ...prevState.buttons,
                lines: {
                    disabled: false,
                    title: 'lines',
                    value: App.Game.gameSettings.getLinesNumber(),
                    pressed: false
                },
                bet: {
                    disabled: false,
                    title: 'bet',
                    value: App.Game.gameSettings.getBetLineCredit() + suffix,
                    pressed: false
                },
                total: {
                    disabled: false,
                    title: 'total',
                    value: App.Game.gameSettings.getBetCredit()
                },
                denomination: {
                    disabled: false,
                    title: 'denom',
                    value: App.Money.getCurrentDenomination() / 100,
                    pressed: false
                }
            }
        }));
    }

    toggleWrap(event, toggleButton) {
        event && event.stopPropagation();
        const state = {
            lines: {
                items: App.Game.gameSettings.getLineMas(),
                title: 'selectLine',
                handler: App.Game.changeLine
            },
            bet: {
                items: App.Game.gameSettings.bets,
                title: 'selectBet',
                handler: App.Game.changeBet
            },
            denomination: {
                items: App.Game.getDenominations(),
                title: 'selectDenomination',
                handler: App.Game.changeDenomination
            }
        };

        // reset 'pressed' property for all game settings buttons
        App.updateButton('bet', {pressed: false});
        App.updateButton('lines', {pressed: false});
        App.updateButton('denomination', {pressed: false});

        App.View.setState(prevState => {
            const {active, type} = prevState.wrap; // get prev states
            const newActive = // don't change active state if
                type !== toggleButton && // ... click on the same button (get close control to close animation end)
                type !== '' ? // ... from other settings (lines/bet/denomination)
                    active : !active;

            // if next 'active' state true
            const newState = newActive ? {
                animationClass: 'open',
                type: toggleButton,
                active: newActive
            } : {
                animationClass: 'close',
                type: '',
                active: active,
                handler: null // reset handler if click on pressed button
            };

            return {
                wrap: {
                    ...prevState.wrap,
                    ...state[toggleButton],
                    ...newState
                },
                buttons: {
                    ...prevState.buttons,
                    [toggleButton]: {
                        ...prevState.buttons[toggleButton],
                        pressed: newActive
                    }
                }
            };
        });
    }

    /**
     * Reset <Wrap> states
     */
    resetWrap = () =>
        App.updateState('wrap', App.View.getInitState().wrap);

    closeWrap = event => {
        event && event.stopPropagation();

        // reset 'pressed' property for all game settings buttons
        App.updateButton('bet', {pressed: false});
        App.updateButton('lines', {pressed: false});
        App.updateButton('denomination', {pressed: false});

        App.View.setState(prevState => ({
            wrap: {
                ...prevState.wrap,
                animationClass: prevState.wrap.active ? 'close' : prevState.wrap.animationClass,
                handler: null
            }
        }));
    };
}
