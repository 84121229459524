export default {
    en: {
        bonusTitle: '15 Bonus Games',
        bonusRules: '3 or more Treasure symbols scattered on the screen trigger 15 Free Games. ' +
            'Free games wins are multiplied by 3. ' +
            'Free games can be won again during the feature. '
    },
    uk: {
        bonusTitle: '15 Бонусних Ігор',
        bonusRules: '3 або більше символів Скарбів на екрані викликають 15 безкоштовних ігор. ' +
            'Виграші в бонусних іграх множаться на 3. ' +
            'Безкоштовні ігри можуть бути знову виграні під час бонусу.'
    },
    ru: {
        bonusTitle: '15 Бонусных Игр',
        bonusRules: '3 или более символов сокровищ на экране вызывают 15 бесплатных игр. ' +
            'Выигрыши в бонусных играх умножаются на 3. ' +
            'Бесплатные игры могут быть выиграны снова во время бонуса. '
    },
    de: {
        bonusTitle: '15 Bonusspiele',
        bonusRules: '3 oder mehr auf dem Bildschirm verteilte Schatzsymbole lösen 15 Freispiele aus.' +
            'Gewinne bei Freispielen werden mit 3 multipliziert. ' +
            'Während des Features können wieder kostenlose Spiele gewonnen werden. '
    },
    es: {
        bonusTitle: '15 Juegos de bonificación',
        bonusRules: '3 o más símbolos del tesoro dispersos en la pantalla activan 15 juegos gratis. ' +
            'Las victorias en juegos gratis se multiplican por 3. ' +
            'Se pueden volver a ganar juegos gratis durante la función. '
    },
    fr: {
        bonusTitle: '15 Jeux bonus',
        bonusRules: '3 symboles Treasure ou plus dispersés sur l\'écran déclenchent 15 jeux gratuits. ' +
            'Les gains des jeux gratuits sont multipliés par 3. ' +
            'Les jeux gratuits peuvent être gagnés à nouveau pendant la fonctionnalité. '
    },
    el: {
        bonusTitle: '15 παιχνίδια μπόνους',
        bonusRules: '3 ή περισσότερα σύμβολα θησαυρού που είναι διασκορπισμένα στην οθόνη ενεργοποιούν 15 δωρεάν παιχνίδια. ' +
            'Οι δωρεάν νίκες στα παιχνίδια πολλαπλασιάζονται επί 3. ' +
            'Μπορείτε να κερδίσετε ξανά δωρεάν παιχνίδια κατά τη διάρκεια της λειτουργίας. '
    },
    it: {
        bonusTitle: '15 giochi bonus',
        bonusRules: '3 o più simboli del tesoro sparsi sullo schermo attivano 15 giochi gratuiti. ' +
            'Le vincite delle partite gratis vengono moltiplicate per 3. ' +
            'I giochi gratuiti possono essere vinti di nuovo durante la funzione. '
    }
};
