import App from './../../../index';
import GameDeluxe from './../../deluxe/game';
import Lines20 from './../../deluxe/lines20';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';
import fontNumber from './img/font/fontNumber';

export default class JollyPays extends GameDeluxe {
    constructor() {
        super();
        this.id = 'jolly-pays';
        this.name = 'Jolly Pays';
        this.buttonsPanelShadow = 'strong';

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 0, 10, 20, 100]},   // 0 - cherry
            {regularDelay: 100, payment: [0, 0, 0, 10, 20, 100]},   // 1 - lemon
            {regularDelay: 100, payment: [0, 0, 0, 10, 20, 100]},   // 2 - plum
            {regularDelay: 100, payment: [0, 0, 0, 20, 40, 200]},   // 3 - orange
            {regularDelay: 100, payment: [0, 0, 0, 20, 40, 200]},   // 4 - bell
            {regularDelay: 100, payment: [0, 0, 0, 20, 80, 400]},   // 5 - grape
            {regularDelay: 100, payment: [0, 0, 0, 50, 500, 2000]}, // 6 - melon
            {regularDelay: 100, payment: [0, 0, 0, 5, 20, 500]}     // 7 - star
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                fontNumber: fontNumber['imageResource']
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.gameSounds = {soundClass: 'deluxe'};
        this.Lines = new Lines20();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            activeBlack: 'gamble/black-active.png',
            activeRed: 'gamble/red-active.png',
            inactiveBlack: 'gamble/black-inactive.png',
            inactiveRed: 'gamble/red-inactive.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 4}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '17pt Franklin Gothic Medium';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#FFFFFF';
        ctx.shadowColor = 'black';
        ctx.shadowOffsetX = 3;
        ctx.shadowOffsetY = 3;
        ctx.shadowBlur = 5;

        switch (page) {
            case 1:
                // joker
                ctx.fillText(bet * this.symbols[6].payment[5], 225, 137);
                ctx.fillText(bet * this.symbols[6].payment[4], 225, 167);
                ctx.fillText(bet * this.symbols[6].payment[3], 225, 197);

                ctx.fillText(bet * nLines * this.symbols[6].payment[5], 570, 200);

                // bell
                ctx.fillText(bet * this.symbols[5].payment[5], 410, 262);
                ctx.fillText(bet * this.symbols[5].payment[4], 410, 292);
                ctx.fillText(bet * this.symbols[5].payment[3], 410, 322);

                // melon, plum
                ctx.fillText(bet * this.symbols[3].payment[5], 175, 370);
                ctx.fillText(bet * this.symbols[3].payment[4], 175, 400);
                ctx.fillText(bet * this.symbols[3].payment[3], 175, 430);

                // lemon, chery, orange
                ctx.fillText(bet * this.symbols[0].payment[5], 650, 370);
                ctx.fillText(bet * this.symbols[0].payment[4], 650, 400);
                ctx.fillText(bet * this.symbols[0].payment[3], 650, 430);

                // scatter
                ctx.fillText(nLines * bet * this.symbols[7].payment[5], 410, 475);
                ctx.fillText(nLines * bet * this.symbols[7].payment[4], 410, 505);
                ctx.fillText(nLines * bet * this.symbols[7].payment[3], 410, 535);
                break;
            case 2:
                const textProps = {
                    parentContainer: ctx,
                    fontImageName: 'fontNumber',
                    map: fontNumber,
                    align: 'center',
                    scale: 1.3,
                    fontInterval: -15 // px between symbols
                };

                ctx.fillText('on active line pays', 400, 320);
                this.drawCustomFont(bet * this.symbols[6].payment[5], 390, 340, textProps);
                ctx.fillText('times bet per line', 400, 400);
                break;
            case 3:
                ctx.font = 'bold 35pt Times New Roman';
                ctx.strokeStyle = '#ff0000';
                ctx.fillStyle = '#fbff98';
                ctx.lineWidth = 2;
                ctx.textAlign = 'center';

                this.strokeFillText(ctx, 'SUBSTITUTES', 400, 300);
                this.strokeFillText(ctx, 'FOR', 400, 360);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }
}
