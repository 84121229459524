import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../../index';
import GameChampion from './game';

/* PIXI aliases */
const Sprite = PIXI.Sprite;

export default class GameChampionX2 extends GameChampion {
    constructor() {
        super();
        this.multiplier = false;
    }

    checkX2() {
        const {features} = this.latestResponse;
        this.multiplier = false;
        // check is doubled win
        this.multiplier = features.some(({payment, symbol}) =>
            payment === this.symbols[symbol].payment[3] * this.gameSettings.getBetLineCredit() * 2);

        // if doubled, divide on multiplier (2)
        this.multiplier && features.forEach(feature => feature.payment /= 2);
    }

    /**
     * Function to start logic of Animate feature first step
     */
    startAnimateFeature(features) {
        JL().debug('-- Start animate feature');
        this.checkX2();

        this.Legends.showWinFeatures();
        this.prepareToAnimateFeature(features);
        this.animateFeature(features);

        // change handler to stop animation win line
        !this.isBonus() && App.updateButton('start', {
            disabled: false, title: 'stop',
            handler: this.speedUpWinLineAnimation
        });
    }

    startGamble() {
        this.setState('ASK_GAMBLE');
        const parentContainer = this.getStageChild('bonusContainer');
        const sprite = new Sprite(this.getTexture('x2'));

        const {payment} = this.latestResponse;
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        this.Gamble.prizeWin = payment;
        App.updateButton('start', {disabled: true});

        // draw multiplier
        if (this.multiplier) {
            const sprite = new Sprite(this.getTexture('x2'));
            sprite.name = 'x2';
            sprite.alpha = 0;

            const increaseAlpha = () => {
                if (sprite.alpha === 0) {
                    this.stopAnimateFeature();
                    // continue animate symbols
                    this.reelMatrix.forEach(reel => reel.forEach(symbolObj =>
                        symbolObj.sprite.play()));
                }
                sprite.alpha < 1 ?
                    sprite.alpha += 0.05 :
                    this.app.ticker.remove(increaseAlpha);
            };

            this.app.ticker.add(increaseAlpha);
            sprite.position.set(100, 60);
            parentContainer.addChild(sprite);
        }

        this.Legends.setText('win', {text: 'win', value: payment});
        this.Legends.setStatus('creditsWon', payment);

        setTimeout(() => {
            App.Sounds.playSound('gamble-wait');
            App.updateButton('start', {
                disabled: false,
                title: 'collect',
                handler: this.finishGamble
            });
            App.updateButton('select', {
                disabled: false,
                title: 'gamble',
                handler: this.Gamble.goToGamble
            });
            const decreaseAlpha = () => {
                if (sprite.alpha >= 0) {
                    sprite.alpha -= 0.03;
                } else {
                    this.app.ticker.remove(decreaseAlpha);
                    sprite.destroy();
                }
            };
            sprite && this.app.ticker.add(decreaseAlpha);
        }, this.multiplier ? 3000 : 0); // wait for multiplier image
    }

    endGambleAnimation() {
        const parentContainer = this.getStageChild('bonusContainer');

        const x2 = parentContainer.getChildByName('x2');
        if (x2) {
            const decreaseAlpha = () => {
                if (x2.alpha >= 0) {
                    x2.alpha -= 0.03;
                } else {
                    this.app.ticker.remove(decreaseAlpha);
                    x2.destroy();
                }
            };
            this.app.ticker.add(decreaseAlpha);
        }
    }
}
