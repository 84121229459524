import App from './../../../index';
import GameDeluxe from './../../deluxe/game';
import Lines20 from './../../deluxe/lines20';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';

export default class SizzlingGold extends GameDeluxe {
    constructor() {
        super();
        this.id = 'sizzling-gold';
        this.name = 'Sizzling Gold';
        this.buttonsPanelShadow = 'mid';

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 5, 20, 50, 200]},     // 0 - cherry
            {regularDelay: 100, payment: [0, 0, 5, 20, 50, 200]},     // 1 - lemon
            {regularDelay: 100, payment: [0, 0, 5, 20, 50, 200]},     // 2 - orange
            {regularDelay: 100, payment: [0, 0, 5, 20, 50, 200]},     // 3 - plum
            {regularDelay: 100, payment: [0, 0, 10, 50, 200, 500]},   // 4 - grape
            {regularDelay: 100, payment: [0, 0, 20, 50, 200, 500]},   // 5 - watermelon
            {regularDelay: 100, payment: [0, 0, 40, 50, 200, 500]},   // 6 - strawberry
            {regularDelay: 100, payment: [0, 0, 0, 100, 1000, 5000]}, // 7 - number '7'
            {regularDelay: 100, payment: [0, 0, 0, 2, 10, 50]}        // 8 - star
        ];

        this.imageResources = {
            main: this.mergePath({mainArea: 'area/main.png'}),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {atlas: this.mergePath(['regularLongSymbols.json'])};

        this.gameSounds = {soundClass: 'deluxe'};
        this.Lines = new Lines20();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            activeRed: 'gamble/red-active.png',
            inactiveRed: 'gamble/red-inactive.png',
            activeBlack: 'gamble/black-active.png',
            inactiveBlack: 'gamble/black-inactive.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));

        this.InfoScreen = new InfoScreen({pages: 2}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '17pt Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#fff';

        switch (page) {
            case 1:
                // star
                this.strokeFillText(ctx, bet * nLines * this.symbols[8].payment[5], 161, 193);
                this.strokeFillText(ctx, bet * nLines * this.symbols[8].payment[4], 161, 220);
                this.strokeFillText(ctx, bet * nLines * this.symbols[8].payment[3], 161, 247);

                // watermelon grape strawberry
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 665, 193);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 665, 220);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 665, 247);

                // orange plum lemon
                this.strokeFillText(ctx, bet * this.symbols[1].payment[5], 150, 368);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[4], 150, 393);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[3], 150, 418);

                // 7
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], 405, 290);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], 405, 316);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 405, 342);

                // cherry
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 650, 342);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 650, 368);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 650, 393);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[2], 650, 418);
                break;
            case 2:
                ctx.font = 'bold 50pt Franklin Gothic Medium, sans-serif';
                ctx.fillText('RULES', 400, 225);

                ctx.font = 'bold 15pt Franklin Gothic Medium, sans-serif';
                ctx.fillText('All prizes are for combinations of a kind. All prizes are for combinations', 400, 275);
                ctx.fillText('left to right, except scatters. 7 pays on adjacent positions. All prizes', 400, 295);
                ctx.fillText('are on selected lines, except scatters. Scatter symbols pay at any', 400, 315);
                ctx.fillText('position on screen. Highest win only paid per selected line for each', 400, 335);
                ctx.fillText('symbol. Scatter wins are added to line wins. All games are played on', 400, 355);
                ctx.fillText('maximum lines as long as sufficient credits are available. All prizes', 400, 375);
                ctx.fillText('shown in credits. Malfunction voids all pays and plays.', 400, 395);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }
}
