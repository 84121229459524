import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import BookOfRaDeluxe from './../book-of-ra-deluxe/game';
import Lines10x6 from './lines';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Text = PIXI.Text;

export default class BookOfRa6 extends BookOfRaDeluxe {
    constructor() {
        super();
        this.id = 'book-of-ra-6';
        this.name = 'Book of Ra 6 Deluxe';
        this.allowLongRoll = true;
        this.containersLayers.linesContainer = 10; // change to top for overlay bonusContainer

        // reel properties
        this.reels = 6;
        this.reelXCoordinates = [55, 171, 287, 403, 519, 635];
        this.symbolHeight = 110; // height of aa single symbol
        this.symbolWidth = 110; // height of aa single symbol
        this.reelTop = 119; // magic number - where reel images starts

        // roll properties
        this.reelSettings = [18, 4, 30]; // 0 - start symbol amount, 1 - reel increase regular roll, 2 - reel increase long roll

        this.extraBet = true; // existing ExtraBet feature in game
        this.extraBetActive = false; // current enabled state

        this.symbols = [
            {regularDelay: 100, bonusDelay: 150, payment: [0, 0, 0, 5, 25, 100, 500]},  // 0 - 10
            {regularDelay: 100, bonusDelay: 150, payment: [0, 0, 0, 5, 25, 100, 500]},  // 1 - J
            {regularDelay: 100, bonusDelay: 150, payment: [0, 0, 0, 5, 25, 100, 500]},  // 2 - Q
            {regularDelay: 100, bonusDelay: 150, payment: [0, 0, 0, 5, 40, 150, 750]},  // 3 - K
            {regularDelay: 100, bonusDelay: 150, payment: [0, 0, 0, 5, 40, 150, 750]},  // 4 - A
            {regularDelay: 100, payment: [0, 0, 5, 30, 100, 750, 2000]},                // 5 - scarab
            {regularDelay: 100, payment: [0, 0, 5, 30, 100, 750, 2000]},                // 6 - statue
            {regularDelay: 100, payment: [0, 0, 5, 40, 400, 2000, 5000]},               // 7 - pharaoh
            {regularDelay: 100, payment: [0, 0, 10, 100, 1000, 5000, 15000]},           // 8 - men
            {regularDelay: 100, scatterDelay: 120, payment: [0, 0, 0, 2, 20, 200, 400]} // 9 - book (scatter)
        ];
        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png',
                reelPanel: 'bonus/extra.png',
                panelText: 'bonus/extra-text.png',
                ex_deactivated: 'bonus/ex-deactivated.png',
                ex_activated: 'bonus/ex-activated.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: {
                ...this.additionalResources.main,
                ...this.mergePath({bonusArea: 'area/bonus.png'})
            },
            atlas: this.mergePath([
                'bonus/bonusSymbols.json',
                'bonus/scatterSymbols.json',
                'bonus/additionalSymbols.json',
                'regularLongSymbols.json',
                'regularLongSymbols2.json'
            ])
        };

        this.gameSounds = {
            ...this.gameSounds,
            sounds: [
                ...this.gameSounds.sounds,
                {name: 'extra_bet_activation', path: '/audio/deluxe/'},
                {name: 'extra_bet_deactivation', path: '/audio/deluxe/'},
                {name: 'book1', alias: 'teaser_1', path: '/audio/deluxe/'},
                {name: 'book2', alias: 'teaser_2', path: '/audio/deluxe/'},
                {name: 'book3', alias: 'teaser_3', path: '/audio/deluxe/'},
                {name: 'book4', alias: 'teaser_4', path: '/audio/deluxe/'},
                {name: 'book5', alias: 'teaser_5', path: '/audio/deluxe/'},
                {name: 'book6', alias: 'teaser_6', path: '/audio/deluxe/'},
                {name: 'long1', alias: 'long1', path: '/audio/deluxe/'},
                {name: 'long2', alias: 'long2', path: '/audio/deluxe/'},
                {name: 'long3', alias: 'long3', path: '/audio/deluxe/'},
                {name: 'long4', alias: 'long4', path: '/audio/deluxe/'}
            ]
        };

        this.Lines = new Lines10x6();
        this.Gamble = new GambleUnique(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 4}, {
            info1_deactive: 'area/info1-screen-deactivated.jpg'
        }); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines - current lines count
     * @param bet - current bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        const y = 12;

        switch (page) {
            case 1:
                const image = this.getAdditionalImage(this.extraBetActive ? 'info1' : 'info1_deactive');
                ctx.drawImage(image, -(image.width - this.gameFieldWidth) / 2, 0);

                ctx.font = '14pt Times New Roman';
                ctx.strokeStyle = '#ff0000';
                ctx.fillStyle = '#fbff98';
                ctx.lineWidth = 2;
                ctx.textAlign = 'center';

                // scatter
                this.strokeFillText(ctx, bet * nLines * this.symbols[9].payment[5], 450, y + 148);
                this.strokeFillText(ctx, bet * nLines * this.symbols[9].payment[4], 450, y + 168);
                this.strokeFillText(ctx, bet * nLines * this.symbols[9].payment[3], 450, y + 188);
                // men
                this.strokeFillText(ctx, bet * this.symbols[8].payment[5], 175, 139 + y);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[4], 175, 157 + y);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[3], 175, 175 + y);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[2], 175, 193 + y);
                // mummy
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], 660, 139 + y);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], 660, 157 + y);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 660, 175 + y);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[2], 660, 193 + y);
                // statue
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 175, 264 + y);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 175, 282 + y);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 175, 300 + y);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[2], 175, 318 + y);
                // scarab
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 660, 264 + y);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 660, 282 + y);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 660, 300 + y);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[2], 660, 318 + y);
                // AK
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 175, 390 + y);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 175, 408 + y);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 175, 426 + y);
                // QJ10
                this.strokeFillText(ctx, bet * this.symbols[2].payment[5], 660, 390 + y);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[4], 660, 408 + y);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[3], 660, 426 + y);

                // extraBet Payment
                ctx.font = 'bold 14pt Times New Roman';
                ctx.fillStyle = this.extraBetActive ? '#ff0000' : '#484d50';
                ctx.shadowColor = 'black';
                ctx.shadowOffsetX = 3;
                ctx.shadowOffsetY = 3;
                ctx.shadowBlur = 5;

                ctx.fillText(bet * nLines * this.symbols[9].payment[6], 450, 128 + y);
                ctx.fillText(bet * this.symbols[8].payment[6], 175, 121 + y);
                ctx.fillText(bet * this.symbols[7].payment[6], 660, 121 + y);
                ctx.fillText(bet * this.symbols[6].payment[6], 175, 245 + y);
                ctx.fillText(bet * this.symbols[5].payment[6], 660, 246 + y);
                ctx.fillText(bet * this.symbols[4].payment[6], 175, 372 + y);
                ctx.fillText(bet * this.symbols[2].payment[6], 660, 372 + y);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    isReelFreezed = reel => reel === 5 && !this.extraBetActive;

    //
    // ======================== EXTRA BET SECTION =============================
    //

    /**
     * Create PIXI.Sprite for extra bet button
     * Create PIXI.Sprite for extra bet reel panel
     * Set position and add event
     */
    initExtraBet(parentContainer) {
        let sprite = new Sprite(this.getTexture('ex_deactivated'));
        sprite.position.set(605, 450);
        sprite.name = 'extraBetButton';
        sprite.buttonMode = true; // shows hand cursor
        sprite.on('pointerdown', this.extraBetClick);
        parentContainer.addChild(sprite);

        // additional reel button (panel)
        sprite = new Sprite(this.getTexture('panelText'));
        sprite.position.set(635, this.reelTop);
        sprite.scale.set(0.5);
        sprite.name = 'extraBetPanel';
        sprite.buttonMode = true; // shows hand cursor
        sprite.on('pointerdown', this.extraBetClick);
        parentContainer.addChild(sprite);

        const text = new Text('Press\nto activate', {
            align: 'center',
            fontFamily: 'Times New Roman',
            fontSize: 16,
            fill: '#f2f7f5',
            stroke: '#282828',
            strokeThickness: 3,
            lineJoin: 'round',
            letterSpacing: 2
        });
        text.position.set(690, 370);
        text.anchor.set(0.5);
        text.name = 'extraBetText';
        parentContainer.addChild(text);

        this.updateExtraBetButtons();
    }

    /**
     * Function call after extraBet toggle
     * Unique changes for each game
     * @param interactive - enable button
     */
    updateExtraBetButtons(interactive = true) {
        const container = this.getStageChild('extraBetContainer');
        const button = container.getChildByName('extraBetButton');
        const panel = container.getChildByName('extraBetPanel');
        const text = container.getChildByName('extraBetText');

        button.texture = this.getTexture(this.extraBetActive ? 'ex_activated' : 'ex_deactivated');
        button.interactive = interactive;

        panel.texture = this.getTexture('panelText');
        panel.interactive = interactive;
        panel.alpha = this.extraBetActive ? 0 : 1;

        text.visible = !this.extraBetActive && interactive;
    }

    additionalPreparingToAnimateFeature() {
        const container = this.getStageChild('extraBetContainer');
        const panel = container.getChildByName('extraBetPanel');
        const text = container.getChildByName('extraBetText');

        panel.texture = this.getTexture('reelPanel');
        text.visible = false;
    }

    /**
     * Check is current symbol is bonusSymbol
     * @param reelIndex
     * @param symbol
     * @returns {boolean}
     */
    isBonusSymbol = (reelIndex, symbol) =>
        symbol === this.bonusRollSymbol && !this.isReelFreezed(reelIndex);
}

class GambleUnique extends GambleDeluxe {
    constructor(imageResources) {
        super(imageResources);
        this.cardPos = {x: 335, y: 185};
        this.cardsQueuePos = {x: [378, 440, 502, 564, 625, 688], y: 100};
    }
}
