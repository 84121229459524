import * as PIXI from 'pixi.js-legacy';

import PragmaticGames from './../../pragmatic/game';
import InfoScreen from '../../infoScreen';
import Lines from './lines';
import App from '../../../index';
import Gamble from '../witch-hunters/gamble';
import {JL} from 'jsnlog';

/* PIXI aliases */
const Container = PIXI.Container;

export default class Super7S extends PragmaticGames {
    constructor() {
        super();
        this.id = 'super-7s';
        this.name = 'Super 7s';

        this.imageResources = {
            atlas: this.mergePath(['staticSymbols.json']),
            jsonAnimations: this.mergePath({
                sym7: 'Sym01.json',
                sym6: 'Sym03.json',
                sym5: 'Sym04.json',
                sym4: 'Sym05.json',
                sym3: 'Sym06.json',
                sym2: 'Sym07.json',
                sym1: 'Sym08.json',
                sym0: 'Sym09.json',
                border: 'area/Reels.json',
                background: 'area/Frame.json',
                logo: 'area/Logo.json'
            })
        };

        this.reelSettings = [18, 6, 40];
        this.offsetReelMask = {
            offsetX: 0, offsetY: 0,
            offsetWidth: 0, offsetHeight: -3
        };
        this.scatter = 7;
        this.isregularSymbols = false;
        this.gameFieldWidth = 960;
        this.gameWidth = App.System.resolution === '4x3' ? this.gameFieldWidth : 1280;
        this.gameFieldHeight = 720;
        this.gameHeight = App.configs.doubleScreen ? 1440 : 720;
        this.symbolWidth = 158;
        this.symbolHeight = 158;
        this.reelXCoordinates = [17, 210, 403, 596, 789];
        this.reelTop = 105;

        this.allowLongRoll = true;

        this.containersLayers = {
            reelsStage: 1,
            mainContainer: 0,
            linesContainer: 2,
            boxesContainer: 4,
            extraBetContainer: 5,
            infoContainer: 6,
            bonusContainer: 7,
            symbolInfo: 8
        };
        this.defaultFeatureDelay = 1500;
        this.symbols = [
            {regularDelay: 50, payment: [0, 0, 5, 20, 40, 200]},           // 0 - слива
            {regularDelay: 50, payment: [0, 0, 0, 20, 40, 200]},           // 1 - груша
            {regularDelay: 50, payment: [0, 0, 0, 20, 50, 200]},           // 2 - апельсин
            {regularDelay: 50, payment: [0, 0, 0, 20, 50, 200]},           // 3 - виноград
            {regularDelay: 50, payment: [0, 0, 0, 50, 200, 1000]},         // 4 - вишни
            {regularDelay: 50, payment: [0, 0, 0, 50, 200, 1000]},         // 5 - колокол
            {regularDelay: 50, payment: [0, 0, 0, 100, 1000, 5000]},       // 6 - цифра '7'
            {regularDelay: 50, payment: [0, 0, 0, 10, 50, 250]}            // 7 - звезда
        ];

        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [
                {name: 'background', loop: true},
                {name: 'reels'},
                {name: 'reelsstop'},
                {name: 'reelsStopBonus'},
                {name: 'win-line'},
                {name: 'add-credit-background', loop: 'true'},
                {name: 'scatterStop1'},
                {name: 'scatterSound'},
                {name: 'scatterSound2'},
                {name: 'long4'},
                {name: 'long3'},
                {name: 'startClick'}
            ],
            path: `/game/games/${this.id}/audio/`
        };
        this.Lines = new Lines();
        this.Gamble = new Gamble();

        this.InfoScreen = new InfoScreen({pages: 2}); // number of game info states
        this.InfoScreen.format = 'png';
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '13pt Arial bold';
        ctx.textAlign = 'center';
        ctx.fillStyle = 'white';

        switch (page) {
            case 1:
                // Plum
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 695, 329);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 695, 345);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 695, 362);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[2], 695, 378);

                // Pear
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 500, 327);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 500, 343);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 500, 360);

                // Orange
                this.strokeFillText(ctx, bet * this.symbols[2].payment[5], 310, 327);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[4], 310, 343);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[3], 310, 360);

                // Grape
                this.strokeFillText(ctx, bet * this.symbols[2].payment[5], 790, 190);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[4], 790, 207);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[3], 790, 223);

                // Cherry
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 590, 190);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 590, 207);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 590, 223);

                // Bell
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 400, 190);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 400, 207);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 400, 223);

                // 7
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 200, 190);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 200, 207);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 200, 223);

                // Q
                this.strokeFillText(ctx, bet * this.symbols[2].payment[5], 400, 438);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[4], 400, 455);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[3], 400, 472);
        }
    }

    /**
     * Create PIXI.Container for game background
     * Add additional sprites to container
     * @param parentContainer
     */
    createMainContainer(parentContainer) {
        const container = new Container();
        container.name = 'mainContainer';
        container.zIndex = this.containersLayers[container.name];
        parentContainer.addChild(container);

        const backgroundSpine = new window.PIXI.spine.Spine(this.getJsonTextures('background'));
        backgroundSpine.name = 'backgroundSpine';
        backgroundSpine.position.set(backgroundSpine.width / 2 - 160, backgroundSpine.height / 2);

        backgroundSpine.state.setAnimation(1, 'animation', true);
        container.addChild(backgroundSpine);

        const borderReels = new window.PIXI.spine.Spine(this.getJsonTextures('border'));
        borderReels.name = 'borderReels';
        borderReels.position.set(backgroundSpine.width / 2 - 160, backgroundSpine.height / 2);

        container.addChild(borderReels);

        const logoSpine = new window.PIXI.spine.Spine(this.getJsonTextures('logo'));
        logoSpine.position.set(489, 360);
        logoSpine.state.setAnimation(0, 'animation_ENG', true);
        container.addChild(logoSpine);
    }

    /**
     * Animate feature first step
     */
    prepareToAnimateFeature(features) {
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        this.features.step = 0;
        const indexesOfFeatures = {};
        const uniqueIndexes = new Map();

        // check 'WIN_LINE' feature contain
        const isWinLine = features.some(features => features.uc === 'WIN_LINE');
        this.updateExtraSymbols(isWinLine);

        // reset all animations and turn on shadows
        this.reelMatrix.forEach(reel => {
            reel.forEach(symbolObj => {
                // don't hide symbols if only scatter feature
                symbolObj.sprite.alpha = isWinLine && this.symbolEffects ? 0.5 : 1;
                symbolObj.sprite.gotoAndStop(0);
            });
        });

        // unique preparing for each game
        this.additionalPreparingToAnimateFeature(features);
        // initialize symbols on reelMatrix
        features.forEach((feature, i) => {
            indexesOfFeatures[i] = [];

            switch (feature.uc) {
                case 'WIN_LINE':
                    feature.reels.forEach((reelIndex, index) => {
                        const rowIndex = this.Lines.lines[feature.number].coordinates[reelIndex];
                        indexesOfFeatures[i].push({reelIndex, rowIndex});
                        uniqueIndexes.set(`${reelIndex}${rowIndex}`, {reelIndex, rowIndex});
                    });

                    break;
                case 'SCATTER':
                    feature.positions.forEach(({reel, row}) => {
                        indexesOfFeatures[i].push({reelIndex: reel, rowIndex: row});
                        uniqueIndexes.set(`${reel}${row}`, {reelIndex: reel, rowIndex: row});
                    });
                    break;
            }
        });
        this.tickerTimeout(() => this.playAllFeatures(uniqueIndexes, indexesOfFeatures), 100);
    }

    playAllFeatures(uniqueIndexes, indexesOfFeatures) {
        const {reelIndex, rowIndex} = uniqueIndexes.values().next().value;
        const textures = App.Game.getSpineData(`sym${this.reelMatrix[reelIndex][rowIndex].symbol}`);
        let i = 1;
        uniqueIndexes.forEach(({reelIndex, rowIndex}) => {
            const symbolObj = this.reelMatrix[reelIndex][rowIndex];
            symbolObj.sprite.destroy();
            symbolObj.spine = new window.PIXI.spine.Spine(textures);

            symbolObj.spine.state.setAnimation(0, 'animation', false);
            symbolObj.spine.position.set(this.symbolWidth / 2, this.symbolHeight / 2);
            symbolObj.symbolContainer.addChild(symbolObj.spine);

            if (uniqueIndexes.size === i) {
                symbolObj.spine.state.addListener(
                    {
                        complete: () => {
                            this.playOnyByOneFeatures(indexesOfFeatures, 0);
                        }

                    }
                );
            }
            i++;
        });
    }

    additionalPreparingToAnimateFeature() {

    }

    playOnyByOneFeatures(indexes, i = 0) {
        indexes[i].forEach(({reelIndex, rowIndex}, index) => {
            if (this.reelMatrix && this.reelMatrix.length) {
                const symbolObj = this.reelMatrix[reelIndex][rowIndex];
                symbolObj.sprite.alpha = 0;
                const spine = symbolObj.spine;

                spine.state.clearListeners();
                spine.state.setAnimation(0, 'animation', false);
                if (indexes[i].length - 1 === index) {
                    spine.state.addListener(
                        {
                            complete: () => {
                                this.playOnyByOneFeatures(indexes, ++i % Object.keys(indexes).length);
                            }
                        }
                    );
                }
            }
        });
    }

    /**
     * Animate infoContainer, slide down and update state
     */
    startInfoAnimation() {
        const open = () => {
            this.createInfoContainer(this.getStage());

            this.showAnimation({
                duration: 500,
                animations: [{sprite: this.getStageChild('infoContainer'), timeline: [{to: {y: 93}}]}],
                onComplete: () => {
                    JL().debug('-- InfoScreen opened');
                    this.setState('INFO');
                    this.InfoScreen.checkInfoButtons();
                    App.updateButton('close', {disabled: false, handler: this.InfoScreen.close});
                }
            });
            App.updateState('buttons', {animation: 'hide-panel'});
            this.onInfoStartOpen();
        };

        this.checkLoadedResources(open);
    }

    playLongRollSound = reelIndex => {
        const extra = this.getExtraBet();
        if (this.reelLong[reelIndex] === 1 && reelIndex < 5) {
            const longRollAnim = this.getStageChild('mainContainer').getChildByName('borderReels');
            longRollAnim.state.setAnimation(0, `longroll_${reelIndex + 1}`, true);
            if (this.reelLong[reelIndex] === 1 && reelIndex !== this.reels - extra) { // current reel without last reel with extraBet correction
                this.stopRollSound();
                this.tickerTimeout(() => App.Sounds.playSound('long3'), 50);
            }
        }
    };

    /**
     * Called to show round results once animation is finished
     */
    rotationDone() {
        const longRollAnim = this.getStageChild('mainContainer').getChildByName('borderReels');
        longRollAnim.state.setEmptyAnimations(1);

        this.createReelMatrix(this.getStageChild('reelsStage'));
        this.onRotationDone();
        App.System.statistics.currentSpinNumber < 3 && App.System.collectFps();
    }

    /**
     * End feature animation / take win without prompt
     */
    endAnimateFeature() {
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        App.Sounds.stopSound('win-line');
        if (this.getState() === 'SHOW_WIN_LINES' && this.latestResponse.payment) {
            this.takeWin();
        } else { // no win, just feature without payment
            this.roundFinished();
        }
    }

    setRegularShortSprite(clipMatrix, reelIndex, textures) {
        for (let i = 0; i < this.reelRows; i++) {
            const symbolObj = clipMatrix[reelIndex][i + 1];
            if (symbolObj.symbol === this.scatter) {
                if (!this.gameFlag.bonusStarted) {
                    if (reelIndex === 1) {
                        this.allowAnimation[reelIndex] = true;
                        App.Sounds.playSound('scatterStop1');
                    } else if (this.allowAnimation[reelIndex - 1] === true) {
                        this.allowAnimation[reelIndex] = true;
                        App.Sounds.playSound('scatterStop1');
                    }
                }
            }
        }
    }

    /**
     * Function to start logic of Animate feature first step
     * @param features
     */
    startAnimateFeature(features) {
        JL().debug('-- Start animate feature');
        this.Legends.showWinFeatures();
        App.Sounds.pauseSound('bonus-background');
        this.prepareToAnimateFeature(features);
        this.animateFeature(features);

        // change handler to stop animation win line
        !this.isBonus() && App.updateButton('start', {
            disabled: false, title: 'stop',
            handler: this.speedUpWinLineAnimation
        });
    }

    /**
     * Function to show line for special feature
     * @param currentFeature
     */
    showFeatureLine(currentFeature) {
        const {number, reels, uc, payment} = currentFeature; // get current feature params
        const container = this.getStageChild('linesContainer');
        uc !== 'SPECIAL_SYMBOL' && container.removeChildren(); // don't clear lines before special symbol (bookGame fill)
        uc === 'WIN_LINE' && this.Lines.drawLineImages([number], reels, container, true, payment);
    }

    takeWin = (isTransfer = true) => {
        this.stopWaitingAnimation();
        this.stopAnimateSound();
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        JL().debug(`-- Take win (isTransfer: ${isTransfer})`);
        this.setState('TAKE_WIN');

        if (isTransfer && App.restoreGameState !== 'TRANSFER') {
            App.Socket.webSocket.send(JSON.stringify({uc: 'TRANSFER-START'}));
        }
        !isTransfer && this.animateCredits(this.latestResponse.payment, isTransfer);
        App.Sounds.playSound('add-credit-background');

        this.Buttons.disableAllButtons();
        App.updateButton('start', {
            disabled: false,
            title: 'collect',
            handler: () => { // User can press 'start' twice for increasing transfer speed
                this.isSpeedUp !== 2 && this.isSpeedUp++;
                JL().debug(`-- Take win speed up x${this.isSpeedUp + 1}`);
                // TODO after repeat click -> take all win immediately
            }
        });
    };

    /**
     * Function to stop animate credit sound //Override function from Game.js
     */
    stopCreditSound = () => {
        App.Sounds.stopSound('add-credit-background');
    };

    /**
     * Function to play animate credit sound
     */
    playCreditSound() {
    }
}
