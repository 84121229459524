import App from './../../../index';
import GameDeluxe from './../../deluxe/game';
import Lines17 from './../../deluxe/lines17';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';

export default class AlwaysAmerican17 extends GameDeluxe {
    constructor() {
        super();
        this.id = 'always-american-17';
        this.name = 'Always American';
        this.reels = 3;
        this.reelRows = 3; // number of rows per reel
        this.buttonsPanelShadow = 'mid';

        // reel properties
        this.reelXCoordinates = [61, 292, 522]; // magic numbers - x coordinates where reels starts
        this.symbolHeight = 130;                // height of aa single symbol
        this.symbolWidth = 216;                 // height of aa single symbol

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 0, 40]},  // 0 - cherry
            {regularDelay: 100, payment: [0, 0, 0, 40]},  // 1 - lemon
            {regularDelay: 100, payment: [0, 0, 0, 40]},  // 2 - orange
            {regularDelay: 100, payment: [0, 0, 0, 40]},  // 3 - plum
            {regularDelay: 100, payment: [0, 0, 0, 80]},  // 4 - grape
            {regularDelay: 100, payment: [0, 0, 0, 80]},  // 5 - melon
            {regularDelay: 100, payment: [0, 0, 0, 100]}, // 6 - bell
            {regularDelay: 100, payment: [0, 0, 0, 200]}, // 7 - seven
            {regularDelay: 100, payment: [0, 0, 0, 400]}  // 8 - star
        ];

        this.imageResources = {
            main: this.mergePath({mainArea: 'area/main.png'}),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.gameSounds = {soundClass: 'deluxe'};
        this.Lines = new Lines17();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 1}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines - current lines count
     * @param bet - current bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 28pt Arial';
        ctx.strokeStyle = '#000';
        ctx.fillStyle = '#ff9a00';
        ctx.lineWidth = 3;
        ctx.textAlign = 'center';

        this.strokeFillText(ctx, bet * this.symbols[8].payment[3], 615, 209);
        this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 615, 282);
        this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 615, 360);

        this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 405, 438);
        this.strokeFillText(ctx, bet * this.symbols[3].payment[3], 405, 530);
    }
}
