import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import Lines from './../lines';
import App from './../../index';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Texture = PIXI.Texture,
    Text = PIXI.Text;

export default class Lines10 extends Lines {
    constructor() {
        super();
        this.imageResources = {
            lines: '/img/lines/Lines10/lines.png',
            winLines: '/img/lines/Lines10/winlines.png',
            winLinesBox: '/img/lines/Lines10/winLinesBox.png',
            boxes: '/img/lines/Lines10/boxes.png'
        };
        this.lineImageWidth = 142; // width of line image in sprite, magic number for each game
        this.lineImageHeight = 148; // height of line image in sprite, magic number for each game

        this.lines = {
            0: {
                coordinates: [1, 1, 1, 1, 1],
                boxes: [
                    {x: 0, y: 246},
                    {x: 765, y: 246}
                ],
                path: [
                    {x: 35, y: 210},
                    {x: 187, y: 210},
                    {x: 329, y: 210},
                    {x: 471, y: 210},
                    {x: 613, y: 210}
                ],
                text: [
                    {x: 115, y: 343},
                    {x: 263, y: 343},
                    {x: 405, y: 343}
                ]
            },
            1: {
                coordinates: [0, 0, 0, 0, 0],
                boxes: [
                    {x: 0, y: 129},
                    {x: 765, y: 129}
                ],
                path: [
                    {x: 35, y: 80},
                    {x: 187, y: 80},
                    {x: 329, y: 80},
                    {x: 471, y: 80},
                    {x: 613, y: 80}
                ],
                text: [
                    {x: 115, y: 212},
                    {x: 263, y: 212},
                    {x: 405, y: 212}
                ]
            },
            2: {
                coordinates: [2, 2, 2, 2, 2],
                boxes: [
                    {x: 0, y: 402},
                    {x: 765, y: 402}
                ],
                path: [
                    {x: 35, y: 340},
                    {x: 187, y: 340},
                    {x: 329, y: 340},
                    {x: 471, y: 340},
                    {x: 613, y: 340}
                ],
                text: [
                    {x: 115, y: 472},
                    {x: 263, y: 472},
                    {x: 405, y: 472}
                ]
            },
            3: {
                coordinates: [0, 1, 2, 1, 0],
                boxes: [
                    {x: 0, y: 90},
                    {x: 765, y: 90}
                ],
                path: [
                    {x: 35, y: 89},
                    {x: 187, y: 210},
                    {x: 329, y: 349},
                    {x: 471, y: 210},
                    {x: 613, y: 89}
                ],
                text: [
                    {x: 115, y: 212},
                    {x: 263, y: 342},
                    {x: 405, y: 474}
                ]
            },
            4: {
                coordinates: [2, 1, 0, 1, 2],
                boxes: [
                    {x: 0, y: 441},
                    {x: 765, y: 441}
                ],
                path: [
                    {x: 35, y: 331},
                    {x: 187, y: 210},
                    {x: 329, y: 71},
                    {x: 471, y: 210},
                    {x: 613, y: 331}
                ],
                text: [
                    {x: 115, y: 472},
                    {x: 263, y: 342},
                    {x: 405, y: 212}
                ]
            },
            5: {
                coordinates: [1, 2, 2, 2, 1],
                boxes: [
                    {x: 0, y: 207},
                    {x: 765, y: 285}
                ],
                path: [
                    {x: 35, y: 211},
                    {x: 187, y: 338},
                    {x: 329, y: 346},
                    {x: 471, y: 336},
                    {x: 613, y: 211}
                ],
                text: [
                    {x: 115, y: 342},
                    {x: 263, y: 474},
                    {x: 405, y: 474}
                ]
            },
            6: {
                coordinates: [1, 0, 0, 0, 1],
                boxes: [
                    {x: 0, y: 285},
                    {x: 765, y: 324}
                ],
                path: [
                    {x: 35, y: 208},
                    {x: 187, y: 79},
                    {x: 329, y: 74},
                    {x: 471, y: 79},
                    {x: 613, y: 206}
                ],
                text: [
                    {x: 115, y: 342},
                    {x: 263, y: 212},
                    {x: 405, y: 212}
                ]
            },
            7: {
                coordinates: [2, 2, 1, 0, 0],
                boxes: [
                    {x: 0, y: 363},
                    {x: 765, y: 168}
                ],
                path: [
                    {x: 35, y: 349},
                    {x: 187, y: 349},
                    {x: 329, y: 210},
                    {x: 471, y: 71},
                    {x: 613, y: 71}
                ],
                text: [
                    {x: 115, y: 472},
                    {x: 263, y: 472},
                    {x: 405, y: 342}
                ]
            },
            8: {
                coordinates: [0, 0, 1, 2, 2],
                boxes: [
                    {x: 0, y: 168},
                    {x: 765, y: 363}
                ],
                path: [
                    {x: 35, y: 71},
                    {x: 187, y: 71},
                    {x: 329, y: 210},
                    {x: 471, y: 349},
                    {x: 613, y: 349}
                ],
                text: [
                    {x: 115, y: 212},
                    {x: 263, y: 212},
                    {x: 405, y: 342}
                ]
            },
            9: {
                coordinates: [2, 1, 1, 1, 0],
                boxes: [
                    {x: 0, y: 324},
                    {x: 765, y: 207}
                ],
                path: [

                    {x: 35, y: 331},
                    {x: 187, y: 214},
                    {x: 329, y: 214},
                    {x: 471, y: 214},
                    {x: 613, y: 89}
                ],
                text: [
                    {x: 115, y: 472},
                    {x: 263, y: 342},
                    {x: 405, y: 342}
                ]
            }
        };

        JL().debug('-- Lines10 initialized');
    }

    drawLineImages(lines, winReels, parentContainer, winLine, payment) {
        lines.forEach(lineIndex => {
            const line = this.lines[lineIndex];
            const winStartPos = (winReels === 0) ? 0 : winReels[0];

            line.coordinates.forEach((rowIndex, reelIndex) => {
                const image = winReels.includes(reelIndex) ?
                    winStartPos === reelIndex ?
                        'winLinesBox' : 'winLines' :
                    'lines';

                this.lineImageWidth = 142; // width of line image in sprite, magic number for each game
                this.lineImageHeight = 148; // height of line image in sprite, magic number for each game

                const spritePosY = this.lineImageHeight * lineIndex;
                let spritePosX = this.lineImageWidth * reelIndex;

                if (reelIndex === 0 || reelIndex === 4) this.lineImageWidth = 152;

                spritePosX += reelIndex !== 0 ? 45 : 35;

                // create line sprite
                const sprite = new Sprite(new Texture(App.Game.getTexture(image), {
                    x: spritePosX,
                    y: spritePosY,
                    width: this.lineImageWidth,
                    height: this.lineImageHeight
                }));
                sprite.position.set(line.path[reelIndex].x, line.path[reelIndex].y);
                sprite.name = lineIndex;
                parentContainer.addChild(sprite);

                if (winLine && winStartPos < 3) {
                    this.addLineWin(parentContainer, payment, line.text[winStartPos].x, line.text[winStartPos].y);
                }
            });
        });
    }

    drawBoxes(parentContainer) {
        parentContainer.removeChildren(); // clear all previous boxes

        const currentLines = App.Game.gameSettings.getCurrentLineMas();
        const boxWidth = 35; // width of winline boxes
        const boxHeight = 34; // height of winline boxes

        Object.keys(this.lines).forEach(lineIndex => {
            this.lines[lineIndex].boxes.forEach((box, indexBox) => {
                const activeLine = currentLines.includes(+lineIndex + 1);

                const spritePosY = indexBox === 0 ?
                    boxHeight * +lineIndex :
                    boxHeight * (+lineIndex + 11);

                // create box sprite
                const sprite = new Sprite(new Texture(App.Game.getTexture('boxes'), {
                    x: 0,
                    y: activeLine ? spritePosY : // if line enabled
                        indexBox === 0 ? boxHeight * 10 : boxHeight * 21, // check left/right box
                    width: boxWidth,
                    height: boxHeight
                }));
                sprite.position.set(box.x, box.y);
                sprite.name = lineIndex;
                activeLine && this.addBoxEvents(sprite);
                parentContainer.addChild(sprite);

                // add text to active box
                if (activeLine) {
                    const text = new Text(+lineIndex + 1, {
                        fontFamily: 'Arial',
                        fontSize: 19,
                        fill: '#000'
                    });
                    text.anchor.set(0.5);
                    text.position.set(box.x + boxWidth / 2, box.y + boxHeight / 2);
                    parentContainer.addChild(text);
                }
            });
        });
    }
}
