import App from './../../../index';
import GameAmatic from './../../amatic/game';
import GambleAmatic from './../../amatic/gamble';
import Lines5 from './../../amatic/lines5';
import InfoScreen from '../../infoScreen';

export default class HotSeven extends GameAmatic {
    constructor() {
        super();
        this.id = 'hot-seven';
        this.name = 'Hot Seven';
        this.scatter = 7;
        this.buttonsPanelShadow = 'mid no-blur';

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 95, y: 113},
            bonusInBonusFrame: {x: 95, y: 113},
            endBonusFrame: {x: 95, y: 113}
        };

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 5, 20, 50, 200]},     // 0 - cherry
            {regularDelay: 100, payment: [0, 0, 5, 20, 50, 200]},     // 1 - lemon
            {regularDelay: 100, payment: [0, 0, 5, 20, 50, 200]},     // 2 - orange
            {regularDelay: 100, payment: [0, 0, 5, 20, 50, 200]},     // 3 - plum
            {regularDelay: 100, payment: [0, 0, 0, 50, 200, 500]},    // 4 - grape
            {regularDelay: 100, payment: [0, 0, 0, 50, 200, 500]},    // 5 - melon
            {regularDelay: 100, payment: [0, 0, 0, 100, 1000, 5000]}, // 6 - 7
            {regularDelay: 100, payment: [0, 0, 0, 2, 10, 50]}        // 7 - star scatter
        ];
        this.imageResources = {
            main: this.mergePath({mainArea: 'area/main.png'}),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {main: this.mergePath({minimizeSymbols: 'minimizeSymbols.png'})};

        this.gameSounds = {
            soundClass: 'amatic',
            path: `/game/games/${this.id}/audio/`
        };
        this.Lines = new Lines5();
        this.Gamble = new GambleAmatic(this.mergePath({gambleArea: 'gamble/gamble-area.png'}));
        this.InfoScreen = new InfoScreen({pages: 2}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 24pt Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#faf900';
        ctx.shadowColor = 'black';
        ctx.shadowOffsetX = ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 5;

        switch (page) {
            case 1:
                // chery
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 700, 380);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 700, 415);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 700, 445);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[2], 700, 475);

                // lemon
                this.strokeFillText(ctx, bet * this.symbols[1].payment[5], 160, 390);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[4], 160, 430);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[3], 160, 470);

                // orange
                this.strokeFillText(ctx, bet * this.symbols[2].payment[5], 700, 230);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[4], 700, 270);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[3], 700, 310);

                // plum
                this.strokeFillText(ctx, bet * this.symbols[3].payment[5], 160, 230);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[4], 160, 270);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[3], 160, 310);

                // grape
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 700, 70);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 700, 110);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 700, 150);

                // melon
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 160, 70);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 160, 110);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 160, 150);

                // seven 7
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 430, 75);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 430, 123);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 430, 173);

                // Star scatter
                this.strokeFillText(ctx, bet * nLines * this.symbols[7].payment[5], 430, 387);
                this.strokeFillText(ctx, bet * nLines * this.symbols[7].payment[4], 430, 427);
                this.strokeFillText(ctx, bet * nLines * this.symbols[7].payment[3], 430, 467);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }
}
