import SymbolInfo from './../../symbolInfo';

export default class SymbolInfoCustom extends SymbolInfo {
    /**
     * Create PIXI.Graphics element for using as mask
     * @param parentContainer
     * @param tx
     * @param ty
     */
    createBorderMask(parentContainer, tx, ty) {

    }
}
