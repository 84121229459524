module.exports = {
    // socket
    connectionServer: 'Conexión al servidor',
    initializingGame: 'Cargando juego',
    initializingMenu: 'Menú de carga',
    loadingImages: 'Cargando imágenes',
    reload: 'Recargar',
    redirect: 'Redirigir',
    exitAndReload: 'Salir al menú y volver a cargar la página',
    exit: 'Salida',
    exitAfter: 'Salida del sistema en',
    serverNotResponded: 'El servidor no respondió',
    pluginNotResponded: 'Sin conexión al controlador',
    codeAlreadyConnected: 'El jugador con este código ya está conectado',
    connectionLost: 'Conexión perdida',
    gameNotFound: 'Juego no encontrado',
    codeNotFound: 'Código no encontrado',
    cardNotFound: 'Tarjeta no encontrada',
    terminalNotFound: 'No se encuentra el código del terminal o el terminal no está disponible',

    // login
    login: 'Iniciar sesión',
    loginCredentials: 'nombre de usuario, correo electrónico o código',
    codeCredentials: 'Código de cuenta',
    username: 'Nombre de usuario',
    enterCode: 'Introduzca el código',
    password: 'Contraseña',
    email: 'Correo electrónico',
    signIn: 'Iniciar sesión',
    signUp: 'Registrarse',
    registration: 'Registro',
    oneClick: 'Un click',
    demo: 'Demo',
    createAccount: 'Crear cuenta',
    logout: 'Cerrar sesión',
    restorePasswordWithEmail: 'Ingrese el correo electrónico para restablecer su contraseña',
    sendNewPassword: 'Enviar nueva contraseña',
    restorePassword: 'Restaurar contraseña',
    restoringPassword: 'Recuperación de contraseña',
    successRegistration: 'Registro exitoso',
    incorrectUsername: 'Nombre de usuario o contraseña incorrecta',
    notUniqueName: 'El nombre debe ser único',
    notUniqueEmail: 'El correo electrónico debe ser único',
    notUniquePhone: 'El número de teléfono debe ser uniq',
    missedField: 'Hay campos obligatorios perdidos',
    wrongOldPassword: 'Contraseña anterior ingresada incorrectamente',
    wrongPassword: 'Contraseña ingresada incorrectamente',
    emailChanged: 'Se ha enviado la confirmación a su correo electrónico',
    passwordResetError: 'Inicio de sesión o correo electrónico incorrectos',
    newPasswordSent: 'Nueva contraseña ha sido enviada a su correo electrónico',
    invalidEmail: 'Email inválido',
    invalidPhone: 'Phone inválido \n ' +
        '+38 YYY XXX XX XX',
    enterPassword: 'Introducir la contraseña',
    differentPasswords: 'Contraseña no coincide',
    tooLongPassword: 'Contraseña demasiado larga (máx. Caracteres: 20)',
    tooShortPassword: 'Contraseña demasiado corta (caracteres mínimos: 8)',
    passwordChanged: 'Contraseña cambiada',
    enterOldPassword: 'Ingrese la contraseña anterior',
    enterNewPassword: 'Ingrese nueva clave',
    samePasswords: 'La contraseña antigua y la nueva no pueden ser idénticas',
    copied: 'Copiada',
    copy: 'Copiar',

    // profile
    save: 'Ahorrar',
    confirmation: 'Confirmar',
    saveAs: 'Guardar como',
    cancel: 'Cancelar',
    changingEmail: 'Cambio de correo electrónico',
    changeEmail: 'Cambiar e-mail',
    changingPhone: 'Cambio de phone',
    changePhone: 'Cambiar phone',
    changingPassword: 'Cambio de contraseña',
    changePassword: 'Cambia la contraseña',
    confirmCode: 'Сódigo de confirmación',
    phoneConfirmed: 'Teléfono confirmado',
    oldPassword: 'Anterior contraseña',
    newPassword: 'Nueva contraseña',
    confirmPassword: 'Confirmar contraseña',
    connectWithUs: 'Conecta con nosotros',
    profile: 'Perfil',
    deposit: 'Depositar',
    withdraw: 'Retirar',
    support: 'Apoyo',
    date: 'Fecha',
    type: 'Tipo',
    amount: 'Cantidad',
    status: 'Estado',
    transactionsHistory: 'Historial de transacciones',
    exchange: 'cambio de divisas',
    noData: 'sin datos',
    transactionsBonus: 'Historial de mensajes',
    message: 'Mensaje',
    ok: 'Ok',

    currencySelect: 'Moneda',
    paymentSelect: 'Método de pago',
    amountDepositSelect: 'Cantidad del depósito',
    finishOperation: 'Finalizar operación',

    enterCurrency: 'Seleccione el tipo de moneda',
    enterCardNumber: 'Ingrese el número de tarjeta',
    cardNumber: 'Número de tarjeta',
    paymentAccept: 'Confirmación de pago',
    payoutAmount: 'Retirar monto',
    paymentType: 'Forma de pago',
    enterDepositWay: 'Seleccione el método de retiro',
    enterDepositAmount: 'Ingrese el monto a recargar',
    enterWithdrawalWay: 'Seleccione el método de retiro',
    enterPayoutAmount: 'Ingrese el monto del retiro',
    successfulPayment: 'Pago se ha agregado correctamente al procesamiento',
    successOperation: 'Pago exitoso',
    errorOperation: 'Eerror en el pago',
    confirm: 'Confirmar',
    country: 'País',
    currency: 'Moneda',
    invalidCountry: 'País inválido',
    invalidCurrency: 'Moneda inválida',
    successfullyChangedTo: 'Cambiado exitosamente a',
    lowBalance: 'No hay fondos suficientes en el saldo',

    accept: 'Aceptar',
    decline: 'Disminución',
    currencyExchange: 'Cambio de divisas',
    currentBalance: 'Saldo actual',
    from: 'de',
    to: 'a',
    exchangePercent: 'porcentaje de cambio',
    newBalance: 'evo equilibrio',
    coefficient: 'Сoeficiente',
    currencyConversion: 'Conversión de moneda',
    wrongInputData: 'Datos de entrada incorrectos',
    wrongAmountData: 'Cantidad incorrecta',
    wrongCreditCard: 'Número de tarjeta de crédito incorrecto',
    noRedirectReff: 'No hay ninguna referencia a la redirección.',
    deserializationIssue: 'Problema con la deserialización',
    currencyPairNotExist: 'El par de divisas no existe',
    authorizationMandatory: 'La autorización es obligatoria',
    birthdayFormat: 'El/La cumpleaños debe ser DD MM AAAA',
    tooShort: 'Número de tarjeta incorrecto',
    infoPayment: 'Procesar el primer pago dentro de las 24 horas es gratis. Se cobrará una comisión por cada pago posterior.',
    time: 'Hora',
    period: 'Hasta 3 dias',
    image: 'Imagen',
    text: 'Texto',

    // status
    balance: 'Balance',
    insurance: 'Seguro',
    credits: 'créditos',
    creditsWon: 'créditos ganados',
    winnerPaid: 'Ganador pagado',
    gotNoMoney: 'No hay suficiente dinero',
    minBet: 'Apuesta total mínima',
    gambleUp: 'Apuesta o gana',
    gambleUp5x: 'Juega hasta 5 o gana',
    gambleEnd: 'Intento completado',
    pressAnyButton: 'Presione inicio para comenzar',
    freeGame: 'Juego gratis',
    of: 'de',
    freeSpinsRemaining: 'GIROS GRATIS RESTANTES ',
    freeGames: 'juegos gratuitos',
    more: 'adicional ',
    freeSpinsLeft: 'GIROS GRATIS A LA IZQUIERDA ',
    additionalRoll: 'Respin',
    selectCard: 'Elige tu tarjeta',
    selectColor: 'Elige rojo o negro',
    selectColorOrSuit: 'Elige el color (x2) o el traje (x4)',
    gameOverPlaceYourBet: 'Por favor tu apuesta',
    auto: 'Auto',
    linePays: 'Línea',
    scatterPays: 'La dispersión paga',
    win: 'Ganar',
    line: 'Linea',
    youWon: 'Ganaste',
    lowMinBet: 'Apuesta mínima en ',
    highMaxBet: 'Apuesta máxima en ',
    unSupport: 'Este juego no es compatible con tu dispositivo',
    urlNotFound: 'Page non trouvée',
    categories: 'Catégories',
    chooseSide: 'Elige un lado',
    makeChoice: 'Tomar una decisión',
    multiplier: 'Multiplicador',

    // buttons
    lines: 'Líneas',
    bet: 'Bet',
    total: 'Total',
    denom: 'Denom',
    gamble: 'Jugar',
    paytable: 'Información',
    bonus: 'Prima',
    start: 'Start',
    stop: 'Detener',
    autoplay: 'Autoplay',
    select: 'Elige',
    collect: 'Recoger',
    red: 'Rojo',
    black: 'Negro',
    return: 'Regreso',
    half: 'Mitad',
    payout: 'Pagar',
    swipeToStart: 'Desliza hacia arriba para comenzar',
    tapToStart: 'Toca para comenzar',

    // roulette buttons
    repeatAndStart: 'Repetir apuesta y comenzar',
    double: 'Doble',
    doubleAndSpin: 'Doblar y girar',
    undo: 'Deshacer',
    repeatBet: 'Repetir apuesta',
    removeBets: 'Eliminar apuestas',
    results: 'Resultados',
    cover: 'Cubierta',

    // gamble
    gambleAmount: 'Importe a doblar',
    gambleToWin: 'Premio del doblar',
    gambleAmountAmatic: 'Cantidad a riesgo',
    gambleToWinAmatic: 'Seleccione rojo o negro ',
    suitGambleToWinAmatic: 'Juego de apuesta para ganar',
    previousCards: 'Tarjetas anteriores',
    gambleAttemptsLeft: 'Tentatives de jeu laissées',
    history: 'lhistoire',

    // wrap
    selectLine: 'Recuento de líneas',
    selectBet: 'Apuesta por linea',
    selectDenomination: 'Denominación',

    // info
    rules: 'Reglas',
    substitutesForAllSymbols: 'Sustituye a todos los símbolos excepto a los dispersos',
    combinationOfKind: 'Todos los premios son para combinaciones de simbolos iguales. ',
    combinationLeftToRight: 'Todos los premios son para combinaciones de izquierda a derecha, excepto dispersiones. ',
    prizesOnSelectedLines: 'Todos los premios están en líneas seleccionadas, excepto los dispersos. ',
    ScatterPayAtAnyPosition: 'Los símbolos dispersos son pagados en  cualquier posicion en la pantala. ',
    HighestWinPaid: 'La ganancia más alta solo se paga por línea seleccionada. ',
    ScatterWinsAddedToLineWins: 'Los premios de los simbolos dispersos son anadidos a los de linea. ',
    prizesShownInCredits: 'Premios mostrados en créditos ',
    MalfunctionVoidsAllPays: 'Una falla anula todos los pagos y jugadas. ',
    onTheScreen: 'en la pantalla',
    bonusGame: 'JUEGO DE BONIFICACIÓN',
    bonusGameGive: 'Da ruleta con',
    freeSpins: 'TIRADAS GRATIS',
    featureBonus: 'En el juego de bonificación, el primer carrete será congelado y llenado por',
    goodLuck: 'Buena suerte!',

    // instruction IOS
    howToInstall: 'como instalar la aplicación',
    pressTheButton: 'Haz clic en el botón compartir',
    goToHomeScreen: 'Presiona el ',
    confirmInstallation: 'Confirmar instalación',

    // bonuses
    congratulation: 'Felicitaciones',
    registration_bonus: {
        title: 'Bonificación por registro!',
        description: {
            punkt1: '- Para activar el bono, debe confirmar su correo electrónico y número de teléfono.',
            punkt2: '- Realice un depósito en su cuenta principal.',
            punkt3: '- Para acreditar el bono en la cuenta principal, debe realizar apuestas en la suma 15 veces más que la suma del bono.'
        },
        accept: 'Su bono por registro depositado!'
    },

    week_bonus: {
        title: 'Bono semanal!',
        description: ' Bonosemanal',
        accept: 'Tu bono semanal depositado!'
    }
};
