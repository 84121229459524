import {Howl, Howler} from 'howler';
import {JL} from 'jsnlog';
import Cookies from 'js-cookie/src/js.cookie';

export default class Sounds {
    constructor(volume) {
        volume = this.checkVolume(volume);
        this.volume = volume; // default state of enabling sound
        this.oldVolume = volume;

        this.soundsCollection = {
            /**
             * App main sounds
             */
            mainSounds: {
                sounds: [
                    {name: 'click'},
                    {name: 'auto-start-on'},
                    {name: 'auto-start-off'},
                    {name: 'jackpot'},
                    {name: 'big-win-start'},
                    {name: 'change-type-big-win'}
                ],
                path: '/audio/main/',
                soundClass: 'main'
            },

            /**
             * Sounds classes
             * =============
             */
            deluxeSounds: {
                sounds: [
                    {name: 'reels'},
                    {name: 'reelsstop'},
                    {name: 'add-credit1'},
                    {name: 'add-credit2'},
                    {name: 'win-line', path: '/audio/novomatic/'},
                    {name: 'gamble-wait', loop: true},
                    {name: 'gamble-win'},
                    {name: 'select-card'},
                    {name: 'invert-card'},
                    {name: 'bonus-reels', path: '/audio/novomatic/'}
                ],
                path: '/audio/deluxe/',
                soundClass: 'deluxe'
            },
            novomaticSounds: {
                sounds: [
                    {name: 'reels'},
                    {name: 'reelsstop'},
                    {name: 'add-credit1'},
                    {name: 'add-credit2'},
                    {name: 'win-line'},
                    {name: 'gamble-wait', loop: true},
                    {name: 'gamble-win'},
                    {name: 'invert-card'},
                    {name: 'bonus-reels'}
                ],
                path: '/audio/novomatic/',
                soundClass: 'novomatic'
            },
            championSounds: {
                sounds: [
                    {name: 'reels'},
                    {name: 'reelsstop'},
                    {name: 'add-credit1'},
                    {name: 'add-credit2'},
                    {name: 'win-line'},
                    {name: 'gamble-wait', loop: true},
                    {name: 'gamble-win'},
                    {name: 'select-card'},
                    {name: 'invert-card'},
                    {name: 'bonus-reels', path: '/audio/novomatic/'}
                ],
                path: '/audio/champion/',
                soundClass: 'champion'
            },
            megajackSounds: {
                sounds: [
                    {name: 'reels'},
                    {name: 'reelsstop'},
                    {name: 'add-credit1'},
                    {name: 'add-credit2'},
                    {name: 'win-line'},
                    {name: 'select-card'}
                ],
                path: '/audio/megajack/',
                soundClass: 'megajack'
            },
            igrosoftSounds: {
                sounds: [
                    {name: 'reels'},
                    {name: 'reelsstop'},
                    {name: 'add-credit1'},
                    {name: 'add-credit2'},
                    {name: 'win-line'},
                    {name: 'select-card'},
                    {name: 'risk-forward'},
                    {name: 'risk-win'}
                ],
                path: '/audio/igrosoft/',
                soundClass: 'igrosoft'
            },
            egtSounds: {
                sounds: [
                    {name: 'reelsstop'},
                    {name: 'win-line'},
                    {name: 'add-credit1', loop: true},
                    {name: 'start-gamble'},
                    {name: 'invert-card'},
                    {name: 'gamble-win'},
                    {name: 'gamble-lose'},
                    {name: 'gamble-wait'}
                ],
                path: '/audio/egt/',
                soundClass: 'egt'
            },
            kupidonSounds: {
                sounds: [
                    {name: 'reels'},
                    {name: 'reelsstop'},
                    {name: 'add-credit1'},
                    {name: 'add-credit2', path: '/audio/deluxe/'},
                    {name: 'win-line'},
                    {name: 'gamble-wait', path: '/audio/deluxe/', loop: true},
                    {name: 'gamble-win', path: '/audio/deluxe/'},
                    {name: 'select-card', path: '/audio/deluxe/'},
                    {name: 'invert-card', path: '/audio/deluxe/'},
                    {name: 'bonus-reels', path: '/audio/novomatic/'}
                ],
                path: '/audio/kupidon/',
                soundClass: 'kupidon'
            },
            amaticSounds: {
                sounds: [
                    {name: 'reels'},
                    {name: 'reelsstop'},
                    {name: 'add-credit1'},
                    {name: 'add-credit2'},
                    {name: 'win-line'},
                    {name: 'gamble-wait', loop: true},
                    {name: 'invert-card'}
                ],
                path: '/audio/amatic/',
                soundClass: 'amatic'
            },
            rouletteSounds: {
                sounds: [],
                path: '/audio/roulette/',
                soundClass: 'roulette'
            },
            prefergamesSounds: {
                sounds: [
                    {name: 'reels'},
                    {name: 'reelsstop'},
                    {name: 'add-credit1'},
                    {name: 'add-credit2'},
                    {name: 'win-line'},
                    {name: 'gamble-wait', loop: true},
                    {name: 'gamble-win'},
                    {name: 'select-card'},
                    {name: 'invert-card'},
                    {name: 'bonus-reels'}
                ],
                path: '/audio/prefergames/',
                soundClass: 'prefergames'
            },
            jackpotSounds: {
                sounds: [
                    {name: 'jackpot-screen-win'}/* ,
                    {name: 'amatic', volume: 0.4},
                    {name: 'maxbet', volume: 0.4},
                    {name: 'neon-city', volume: 0.4} */
                ],
                path: '/audio/main/',
                soundClass: 'jackpots'
            },

            /**
             * Completed sound collection
             * With special class sounds and unique game sounds
             * Ready for game loading
             */
            readyGameSounds: {
                sounds: [],
                path: null,
                soundClass: null
            },

            /**
             * Already loaded sound collection
             */
            loadedSounds: {
                sounds: {},
                loaded: 0
            }
        };
    }

    playSound = (name, part) => this.handleSound('play', name, part);

    pauseSound = name => this.handleSound('pause', name);

    stopSound = name => this.handleSound('stop', name);

    isPlaying = name => this.handleSound('playing', name);

    handleSound(method, name, part) {
        const sound = this.soundsCollection.loadedSounds.sounds[name];

        return sound?.state() === 'loaded' ? // check loaded state
            sound?.[method](part) : // play if loaded
            sound?.once('load', () => { // add event on load
                sound?.[method](part);
                sound.off();
            });
    }

    /**
     * Stop all sounds in the game
     */
    stopAllSounds = () => Howler.stop();

    /**
     * Set master volume for all sounds
     * @param volume - from 0 to 1 (from 0% to 100%)
     */
    setVolume(volume) {
        this.volume = volume;
        Howler.volume(volume);
        Cookies.set('soundVolume', volume, {expires: 31});
    }

    /**
     * Check correct cookies value
     * @param volume
     * @returns {number}
     */
    checkVolume(volume) {
        volume = +volume;

        if (isNaN(volume) || (volume < 0 || volume > 1)) {
            volume = 0.5; // reset incorrect value to default
            this.setVolume(volume);
        }

        return volume;
    }

    /**
     * Load sound resources
     * @param soundCollection
     * @param onLoad - last sound loaded event
     */
    loadSounds(soundCollection, onLoad) {
        const {loadedSounds} = this.soundsCollection;

        let loaded = 0;
        soundCollection.sounds.forEach(({name, alias = name, sprite, loop, path}) => {
            loadedSounds.sounds[name] = new Howl({
                src: [`${path || soundCollection.path}${name}.mp3`, `${path || soundCollection.path}${name}.ogg`],
                sprite,
                loop,
                autoUnlock: true, // attempts to enable audio on mobile
                preload: 'metadata',
                format: ['mp3', 'ogg'],
                mute: false,
                volume: 1,
                onload: () => { // sound load done event
                    loaded++;
                    if (loaded === soundCollection.sounds.length) {
                        loadedSounds.loaded = loaded;
                        this.setVolume(this.volume); // updating volume value here
                        JL().debug(`Sounds Loaded (type: '${soundCollection.soundClass}', count: ${loadedSounds.loaded})`);
                        onLoad?.();
                    }
                }
            });
            loadedSounds.sounds[alias] = loadedSounds.sounds[name];
        });
    }

    /**
     * Clear game sound resources
     */
    clearResources() {
        const {loadedSounds, readyGameSounds} = this.soundsCollection;

        // remove sounds from loaded
        readyGameSounds.sounds.forEach(({name}) => {
            loadedSounds.sounds[name]?.unload();
            delete loadedSounds.sounds[name];
        });

        loadedSounds.loaded = Object.keys(loadedSounds.sounds).length;
    }

    /**
     * Create sounds for loading, concat base and game unique sounds
     * @param uniqueSounds - unique game sounds
     * @this readyGameSounds - ready for load sounds
     */
    createGameSounds(uniqueSounds) {
        let {soundClass} = uniqueSounds;

        // check base class sound
        const {sounds, path} = this.soundsCollection[soundClass + 'Sounds'];
        const readyGameSounds = [...sounds];

        // if game contains unique sounds -> replace default sound
        if (uniqueSounds) {
            const duplicateSoundsPos = [];
            uniqueSounds.sounds && uniqueSounds.sounds.forEach(uniqueSound => { // for each game sounds
                // if duplicate prepare default sound to delete
                readyGameSounds.forEach((sound, soundIndex) =>
                    sound.name === uniqueSound.name && duplicateSoundsPos.push(soundIndex));

                // if sound have not unique path -> set class path
                if (!uniqueSound.path) uniqueSound.path = uniqueSounds.path;

                // add new sound
                readyGameSounds.push(uniqueSound);
            });

            // delete unneeded default sounds
            duplicateSoundsPos.reverse().forEach(pos => readyGameSounds.splice(pos, 1));

            soundClass += ' unique';
        } else {
            soundClass += ' base';
        }

        // update collection
        this.soundsCollection.readyGameSounds = {
            sounds: readyGameSounds,
            path, soundClass
        };
    }
}
