import {JL} from 'jsnlog';

import LinesEgt from './../egt/lines';

export default class Lines30 extends LinesEgt {
    constructor() {
        super();
        this.boxSettings.firstRightBox = 30;

        this.lines = {
            0: {coordinates: [1, 1, 1, 1, 1], lineDeltaY: 0, color: '#ffd8ff'},
            1: {coordinates: [0, 0, 0, 0, 0], lineDeltaY: 0, color: '#de1800'},
            2: {coordinates: [2, 2, 2, 2, 2], lineDeltaY: 0, color: '#009200'},
            3: {coordinates: [0, 1, 2, 1, 0], lineDeltaY: 20, color: '#ce8f63'},
            4: {coordinates: [2, 1, 0, 1, 2], lineDeltaY: -20, color: '#647100'},
            5: {coordinates: [0, 0, 1, 2, 2], lineDeltaY: 10, color: '#ff24ff'},
            6: {coordinates: [2, 2, 1, 0, 0], lineDeltaY: -10, color: '#ffffad'},
            7: {coordinates: [1, 2, 2, 2, 1], lineDeltaY: 20, color: '#0096bd'},
            8: {coordinates: [1, 0, 0, 0, 1], lineDeltaY: -20, color: '#7321bd'},
            9: {coordinates: [0, 1, 1, 1, 0], lineDeltaY: -10, color: '#00ffff'},
            10: {coordinates: [2, 1, 1, 1, 2], lineDeltaY: 10, color: '#6345ff'},
            11: {coordinates: [1, 2, 1, 0, 1], lineDeltaY: -10, color: '#d6fb06'},
            12: {coordinates: [1, 0, 1, 2, 1], lineDeltaY: 10, color: '#a56e00'},
            13: {coordinates: [0, 1, 0, 1, 0], lineDeltaY: 30, color: '#949294'},
            14: {coordinates: [2, 1, 2, 1, 2], lineDeltaY: -30, color: '#de2064'},
            15: {coordinates: [1, 1, 2, 1, 1], lineDeltaY: -30, color: '#007100'},
            16: {coordinates: [1, 1, 0, 1, 1], lineDeltaY: 30, color: '#adcab5'},
            17: {coordinates: [0, 2, 0, 2, 0], lineDeltaY: -20, color: '#ff6d00'},
            18: {coordinates: [2, 0, 2, 0, 2], lineDeltaY: 20, color: '#ffff00'},
            19: {coordinates: [1, 0, 2, 0, 1], lineDeltaY: -40, color: '#00cbbe'},
            20: {coordinates: [1, 2, 0, 2, 1], lineDeltaY: 40, color: '#00cbbe'},
            21: {coordinates: [0, 0, 2, 0, 0], lineDeltaY: -30, color: '#efc704'},
            22: {coordinates: [2, 2, 0, 2, 2], lineDeltaY: 30, color: '#0045ff'},
            23: {coordinates: [0, 2, 2, 2, 0], lineDeltaY: 40, color: '#ff0000'},
            24: {coordinates: [2, 0, 0, 0, 2], lineDeltaY: -40, color: '#aeaeb5'},
            25: {coordinates: [0, 0, 1, 0, 0], lineDeltaY: 50, color: '#00cb62'},
            26: {coordinates: [2, 2, 1, 2, 2], lineDeltaY: -50, color: '#d641ff'},
            27: {coordinates: [0, 2, 1, 2, 0], lineDeltaY: -40, color: '#f792b6'},
            28: {coordinates: [2, 0, 1, 0, 2], lineDeltaY: 40, color: '#ffbf04'},
            29: {coordinates: [1, 2, 1, 2, 1], lineDeltaY: 50, color: '#a535ff'}
        };

        this.lineMap = [...Array(30)].map((item, i) => i);

        JL().debug('-- Lines30 initialized');
    }

    getBoxesStyle = () => ({
        activeBoxStyle: {
            fill: '#fffe00',
            fontSize: 12,
            stroke: '#bd0000',
            strokeThickness: 5,
            lineJoin: 'round'
        },
        inactiveBoxStyle: {
            dropShadowDistance: 1,
            fill: 'white',
            fontSize: 19,
            strokeThickness: 2,
            lineJoin: 'round'
        },
        colLines: [1, 5, 10, 20, 30],
        coordinates: {
            0: {x: 26, y: 408},
            1: {x: 775, y: 408}
        }
    });
}
