import React from 'react';

import './styles.less';
import App from './../../index';

export default function SwipeUp({state: {lang}}) {
    const cancel = event => {
        event.stopPropagation();
        App.updateState('swipeUp', {canceled: true});
    };

    // disable <SwipeUp> during swipe on iOS
    let startSwipePos = null;
    const touchStart = ({touches}) => startSwipePos = touches[0]?.clientY;
    const touchMove = ({touches}) =>
        Math.abs(touches[0]?.clientY - startSwipePos) > 50 && App.System.os === 'iOS' &&
        App.updateState('swipeUp', {active: false});
    const touchEnd = () => startSwipePos = null;

    return (
        <div
            className={`swipe-up red-color red-shadow ${App.System.os}`}
            onClick={App.fullScreenClick}
            onTouchStart={touchStart}
            onTouchMove={touchMove}
            onTouchEnd={touchEnd}
        >
            <button className='swipe-up-cancel red-color red-shadow' onClick={cancel}>
                (×) {lang.cancel.toUpperCase()}
            </button>
            <img src={App.getUrl('/img/tap.png')} draggable={false} alt=''/>
            <div className='title'>
                {lang[App.System.os === 'iOS' ? 'swipeToStart' : 'tapToStart']}
            </div>
        </div>
    );
}
