import {JL} from 'jsnlog';

import App from './../../index';
import Gamble from './../Gamble';

export default class GambleMegajack extends Gamble {
    constructor(imageResources) {
        super(imageResources);
        this.gambleQueue = [];
        this.screenCoordinates = {x: 0, y: 0};
        this.cloudx = 250;
        this.cloudy = 170;
        this.offsetGambleQueue = 0;
        this.imageResources = {
            gambleArea: '/img/gamble/mega-jack/gamble_area_modern.png',
            cards: '/img/gamble/mega-jack/cards.png',               // in all mega jack games,
            win: '/img/gamble/mega-jack/win.png',                   // so we do not redefine
            lose: '/img/gamble/mega-jack/lose.png',                 // them in each game
            dealer: '/img/gamble/mega-jack/dealer.png',
            pick: '/img/gamble/mega-jack/pick.png',
            selectCard: '/img/gamble/mega-jack/select-card.png',
            ...imageResources // redefine base images from game
        };
        this.dealer = '';
        this.cards = [];
        this.step = 0;
        this.choice = 0;
        this.status = '';
        this.cardWeigth = 126;
        this.cardHeight = 198;
        this.cardPositions = [
            [30, 270],
            [180, 270],
            [331, 270],
            [480, 270],
            [630, 270]
        ];
        this.cardY = {'C': 0, 'D': 1, 'H': 2, 'S': 3, 'R': 4, 'B': 4};
        this.cardX = {
            '2': 0,
            '3': 1,
            '4': 2,
            '5': 3,
            '6': 4,
            '7': 5,
            '8': 6,
            '9': 7,
            '10': 8,
            'J': 9,
            'Q': 10,
            'K': 11,
            'A': 12,
            'Joker': 0
        };

        this.gambleAreaSize = {w: 800, h: 600};
        this.gamble_cards = {
            top: '45',
            left: '7.5',
            height: '35'
        };
        this.gamble_cards_item = {
            margin_left: ' 0 0 0 1',
            width: '17'
        };
    };

    drawArea = ctx => {
        ctx.clearRect(0, 0, 800, 600);
        this.drawGambleTexts(ctx);
    };

    draw = showDealerCard => {
        App.Game.getStageChild('bonusContainer').removeChildren();
        const ctx = this.ctx;
        App.Game.Legends.setText('win', {text: 'win', value: this.prizeWin});
        App.Game.Legends.setStatus('selectCard');
        App.Game.Legends.showWinFeatures();
        this.setDoublingSeed(App.Game.gambleResponse);

        this.drawArea(ctx);
        (showDealerCard || this.step !== 0) && this.drawDealerCard(ctx);

        this.step === 0 && ctx.drawImage(
            App.Game.getAdditionalImage('selectCard'),
            0, 0,
            251, 41,
            this.cloudx + 20, this.cloudy + 8,
            251, 41
        );
    };

    showStatus = state => {

    };

    restoreGambleScreen(response) {
        this.setDoublingSeed(response);
        App.Game.setState('DOUBLING');
        App.Game.setBackground('gambleArea');
        App.Game.hideBoxes();
        App.Game.hideLines();
        App.Game.Buttons.disableAllButtons();
        this.createComponent();
    };

    goToGamble = () => {
        clearInterval(App.Game.lineAnimateInterval);
        App.Game.setBackground('gambleArea');
        App.Game.hideBoxes();
        JL().debug('-- Go to Gamble');
        App.Game.setState('DOUBLING');
        App.Game.stopAnimateFeature();
        App.Game.sendGamblePacket();
        App.updateButton('select', {disabled: true});
        App.updateButton('start', {disabled: true});
        App.updateButton('autoStart', {disabled: true});
    };

    setDoublingSeed = response => {
        this.step = response.step;
        this.dealer = response.dealer;
        this.inputWin = App.Game.latestResponse.payment;
        if (this.step === 0) this.prizeWin = this.inputWin;
    };

    setOneOfFour = response => {
        this.prizeWin = response.win;
        this.cards = response.cards;
        this.choice = response.choice;
        this.status = response.status;
        this.prizeWin !== 0 && App.Game.Legends.setText('win', {
            text: 'win',
            value: this.prizeWin
        });
    };

    choiceCard = cardIndex => {
        JL().debug(`-- Choice card - ${cardIndex}`);
        App.updateButton('cardIndex', {handler: null});
        App.updateButton('start', {disabled: true});
        App.Socket.send(JSON.stringify({
            uc: 'ONE-OF-FOUR-DOUBLING',
            choice: cardIndex
        }));
    };

    getCardX = cardCode => this.cardX[cardCode.substr(0, cardCode.length - 1)] * this.cardWeigth;

    getCardY = cardCode => this.cardY[cardCode.substr(-1, 1)] * this.cardHeight;

    drawDealerCard = ctx => {
        ctx.drawImage(
            App.Game.getAdditionalImage('cards'),
            this.getCardX(this.dealer), this.getCardY(this.dealer),
            this.cardWeigth, this.cardHeight,
            this.cardPositions[0][0], this.cardPositions[0][1],
            this.cardWeigth, this.cardHeight
        );
        ctx.drawImage(
            App.Game.getAdditionalImage('dealer'),
            this.cardPositions[0][0],
            this.cardPositions[0][1] - 42
        );
        this.drawGambleTexts(ctx);
    };

    showAllCard = response => {
        const ctx = this.ctx;
        App.Sounds.playSound('select_card');
        App.Game.Buttons.disableAllButtons();

        ctx.clearRect(0, 0, 800, 600);
        this.drawDealerCard(ctx);
        for (let i = 0; i <= 3; i++) {
            response.choice === i && ctx.drawImage(
                App.Game.getAdditionalImage('cards'),
                this.getCardX(this.cards[i]), this.getCardY(this.cards[i]),
                this.cardWeigth, this.cardHeight,
                this.cardPositions[i + 1][0], this.cardPositions[i + 1][1],
                this.cardWeigth, this.cardHeight
            );
        }
        ctx.drawImage(
            App.Game.getAdditionalImage('pick'),
            this.cardPositions[this.choice + 1][0], this.cardPositions[0][1] - 42
        );

        setTimeout(() => {
            JL().debug(`-- Gamble response '${response.status}'`);
            this.showStatus(response.status);
            for (let i = 0; i <= 3; i++) {
                response.choice !== i && ctx.drawImage(
                    App.Game.getAdditionalImage('cards'),
                    this.getCardX(this.cards[i]), this.getCardY(this.cards[i]),
                    this.cardWeigth, this.cardHeight,
                    this.cardPositions[i + 1][0], this.cardPositions[i + 1][1],
                    this.cardWeigth, this.cardHeight
                );
            }

            if (response.status === 'l') { // lose or exit on limitation
                this.prizeWin = 0;
                this.drawGambleTexts(ctx);
                App.Game.Legends.setStatus('gambleEnd');
                App.Game.Legends.setText('win', {text: 'win', value: this.prizeWin});
                setTimeout(() => {
                    App.Game.finishGamble();
                    App.Game.getStageChild('bonusContainer').removeChildren();
                }, 2000);
            } else {
                setTimeout(() => {
                    this.draw(true);
                    if (response.stepLeft === 0) {
                        App.Game.finishGamble();
                    } else {
                        App.Game.setState('DOUBLING');
                        this.setDefaultGambleButtons();
                    }
                }, 2000);
            }
        }, 1000);
    };

    drawGambleTexts = ctx => {
        ctx.fillStyle = '#FFFF00';
        ctx.font = '14pt Arial';
        ctx.textBaseline = 'Top';
        ctx.textAlign = 'center';
        ctx.fillText(`GAMBLE AMOUNT ${this.prizeWin}`, this.screenCoordinates.x + 208, this.screenCoordinates.y + this.offsetGambleQueue + 225);
        ctx.fillText(`GAMBLE TO WIN ${this.prizeWin * 2}`, this.screenCoordinates.x + 500, this.screenCoordinates.y + this.offsetGambleQueue + 225);
    };

    setDefaultGambleButtons = () => {
        JL().debug('-- Set default gamble buttons state');
        App.Sounds.stopSound('gamble-wait');
        App.View.setState(prevState => ({
            buttons: {
                ...prevState.buttons,
                // main game buttons
                start: {
                    ...prevState.buttons.start,
                    disabled: this.step === 0,
                    title: 'collect',
                    handler: App.Game.finishGamble
                },
                select: {
                    ...prevState.buttons.select,
                    disabled: true
                },
                info: {
                    ...prevState.buttons.info,
                    disabled: true
                },
                autoStart: {
                    ...prevState.buttons.autoStart,
                    disabled: true
                },
                cardIndex: {
                    handler: this.choiceCard
                }
            }
        }));
    };

    finishGamble = () => {
        this.setBackground('mainArea');
        this.getStageChild('bonusContainer').removeChildren();
        this.Gamble.resetInverting();
        this.showBoxes();
        this.Gamble.ctx = null;
        this.Gamble.currentGambleMultiplier = 1;
        App.updateState('buttons', {visualization: App.buttonsType});
        App.updateButton('select', {additionalClass: ''});
        App.updateButton('info', {additionalClass: ''});

        App.updateButton('redCard', {disabled: true, handler: null});
        App.updateButton('blackCard', {disabled: true, handler: null});

        cancelAnimationFrame(this.Gamble.gambleTimeout);
        this.latestResponse.payment = this.Gamble.prizeWin;
        const {features} = this.latestResponse;

        if (this.Gamble.prizeWin === 0) {
            App.View.setState({activeGamble: false});
            this.Legends.setStatus('gambleEnd');
            this.Legends.showJackpot();
            JL().debug('-- Loose gamble');
            this.roundFinished();
        } else {
            JL().debug(`-- Win gamble. Prize: ${this.Gamble.prizeWin}`);
            App.updateButton('select', {title: 'select'});
            App.updateButton('info', {disabled: true, title: 'paytable'});

            // restore animate feature
            features.length && this.getState() === 'DOUBLING' &&
            this.startAnimateFeature(features);

            App.View.setState({activeGamble: false});
            this.takeWin();
        }
    };

    activateGambleButtons() {
        this.setDefaultGambleButtons();
    }

    deactivateGambleButtons() {
        App.updateButton('cardIndex', {img: 'inactive', disabled: true, handler: null});
        App.updateButton('start', {img: 'inactive', disabled: true});
    }
}
