import {JL} from 'jsnlog';
import * as PIXI from 'pixi.js-legacy';

import App from './../../index';
import Lines from './../lines';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Texture = PIXI.Texture;

export default class Lines50 extends Lines {
    constructor() {
        super();
        this.imageResources = {
            lines1: '/img/lines/Lines50/lines-1.png',
            lines2: '/img/lines/Lines50/lines-2.png',
            winLines1: '/img/lines/Lines50/winlines-1.png',
            winLines2: '/img/lines/Lines50/winlines-2.png',
            winLinesBox1: '/img/lines/Lines50/winLinesBox-1.png',
            winLinesBox2: '/img/lines/Lines50/winLinesBox-2.png',
            boxes: '/img/lines/Lines50/boxes.png'
        };
        // height of winLine boxes, magic number for each game
        this.lineImageWidth = 142;      // width of line image in sprite, magic number for each game
        this.lineImageHeight = 140;     // height of line image in sprite, magic number for each game

        this.lines = {
            0: {
                coordinates: [0, 0, 0, 0, 0],
                boxes: [{x: 1, y: 94}],
                path: [
                    {x: 1, y: 68},
                    {x: 187, y: 68},
                    {x: 329, y: 68},
                    {x: 471, y: 68},
                    {x: 613, y: 68}
                ],
                text: [
                    {x: 115, y: 181},
                    {x: 258, y: 181},
                    {x: 400, y: 181}
                ]
            },
            1: {
                coordinates: [1, 1, 1, 1, 1],
                boxes: [{x: 1, y: 213}],
                path: [
                    {x: 0, y: 171},
                    {x: 187, y: 171},
                    {x: 329, y: 171},
                    {x: 471, y: 171},
                    {x: 613, y: 171}
                ],
                text: [
                    {x: 115, y: 278},
                    {x: 258, y: 278},
                    {x: 400, y: 278}
                ]
            },
            2: {
                coordinates: [2, 2, 2, 2, 2],
                boxes: [{x: 1, y: 335}],
                path: [
                    {x: 0, y: 261},
                    {x: 187, y: 261},
                    {x: 329, y: 261},
                    {x: 471, y: 261},
                    {x: 613, y: 261}
                ],
                text: [
                    {x: 115, y: 376},
                    {x: 258, y: 376},
                    {x: 400, y: 376}
                ]
            },
            3: {
                coordinates: [3, 3, 3, 3, 3],
                boxes: [{x: 1, y: 459}],
                path: [
                    {x: 0, y: 360},
                    {x: 187, y: 360},
                    {x: 329, y: 360},
                    {x: 471, y: 360},
                    {x: 613, y: 360}
                ],
                text: [
                    {x: 115, y: 474},
                    {x: 258, y: 474},
                    {x: 400, y: 474}
                ]
            },
            4: {
                coordinates: [0, 1, 2, 1, 0],
                boxes: [{x: 772, y: 138}],
                path: [
                    {x: 0, y: 66},
                    {x: 187, y: 164},
                    {x: 329, y: 262},
                    {x: 471, y: 164},
                    {x: 612, y: 66}
                ],
                text: [
                    {x: 115, y: 181},
                    {x: 258, y: 278},
                    {x: 400, y: 376}
                ]
            },
            5: {
                coordinates: [1, 2, 3, 2, 1],
                boxes: [{x: 772, y: 246}],
                path: [
                    {x: 0, y: 164},
                    {x: 187, y: 262},
                    {x: 329, y: 360},
                    {x: 471, y: 262},
                    {x: 613, y: 164}
                ],
                text: [
                    {x: 115, y: 278},
                    {x: 258, y: 376},
                    {x: 400, y: 474}
                ]
            },
            6: {
                coordinates: [2, 1, 0, 1, 2],
                boxes: [{x: 772, y: 308}],
                path: [
                    {x: 0, y: 262},
                    {x: 187, y: 164},
                    {x: 329, y: 66},
                    {x: 471, y: 164},
                    {x: 613, y: 262}
                ],
                text: [
                    {x: 115, y: 376},
                    {x: 258, y: 278},
                    {x: 400, y: 181}
                ]
            },
            7: {
                coordinates: [3, 2, 1, 2, 3],
                boxes: [{x: 772, y: 418}],
                path: [
                    {x: 0, y: 358},
                    {x: 187, y: 260},
                    {x: 329, y: 162},
                    {x: 471, y: 260},
                    {x: 612, y: 357}
                ],
                text: [
                    {x: 115, y: 474},
                    {x: 258, y: 376},
                    {x: 400, y: 278}
                ]
            },
            8: {
                coordinates: [0, 0, 0, 0, 1],
                boxes: [{x: 1, y: 108}],
                path: [
                    {x: 0, y: 66},
                    {x: 187, y: 66},
                    {x: 329, y: 66},
                    {x: 471, y: 66},
                    {x: 612, y: 164}
                ],
                text: [
                    {x: 115, y: 181},
                    {x: 258, y: 181},
                    {x: 400, y: 181}
                ]
            },
            9: {
                coordinates: [1, 1, 1, 1, 0],
                boxes: [{x: 1, y: 168}],
                path: [
                    {x: 0, y: 163},
                    {x: 187, y: 163},
                    {x: 329, y: 163},
                    {x: 471, y: 163},
                    {x: 613, y: 65}
                ],
                text: [
                    {x: 115, y: 278},
                    {x: 258, y: 278},
                    {x: 400, y: 278}
                ]
            },
            10: {
                coordinates: [2, 2, 2, 2, 3],
                boxes: [{x: 1, y: 381}],
                path: [
                    {x: 0, y: 262},
                    {x: 187, y: 262},
                    {x: 329, y: 262},
                    {x: 471, y: 262},
                    {x: 613, y: 360}
                ],
                text: [
                    {x: 115, y: 378},
                    {x: 258, y: 378},
                    {x: 400, y: 378}
                ]
            },
            11: {
                coordinates: [3, 3, 3, 3, 2],
                boxes: [{x: 1, y: 443}],
                path: [
                    {x: 0, y: 360},
                    {x: 187, y: 360},
                    {x: 329, y: 360},
                    {x: 471, y: 360},
                    {x: 613, y: 262}
                ],
                text: [
                    {x: 115, y: 474},
                    {x: 258, y: 474},
                    {x: 400, y: 474}
                ]
            },
            12: {
                coordinates: [0, 1, 1, 1, 1],
                boxes: [{x: 772, y: 183}],
                path: [
                    {x: 0, y: 65},
                    {x: 187, y: 163},
                    {x: 329, y: 163},
                    {x: 471, y: 163},
                    {x: 613, y: 163}
                ],
                text: [
                    {x: 115, y: 181},
                    {x: 258, y: 278},
                    {x: 400, y: 278}
                ]
            },
            13: {
                coordinates: [1, 1, 1, 1, 2],
                boxes: [{x: 1, y: 227}],
                path: [
                    {x: 0, y: 173},
                    {x: 187, y: 173},
                    {x: 329, y: 173},
                    {x: 471, y: 173},
                    {x: 613, y: 271}
                ],
                text: [
                    {x: 115, y: 278},
                    {x: 258, y: 278},
                    {x: 400, y: 278}
                ]
            },
            14: {
                coordinates: [2, 2, 2, 2, 1],
                boxes: [{x: 1, y: 320}],
                path: [
                    {x: 0, y: 258},
                    {x: 187, y: 258},
                    {x: 329, y: 258},
                    {x: 471, y: 258},
                    {x: 613, y: 160}
                ],
                text: [
                    {x: 115, y: 376},
                    {x: 258, y: 376},
                    {x: 400, y: 376}
                ]
            },
            15: {
                coordinates: [3, 2, 2, 2, 2],
                boxes: [{x: 772, y: 368}],
                path: [
                    {x: 0, y: 361},
                    {x: 187, y: 263},
                    {x: 329, y: 263},
                    {x: 471, y: 263},
                    {x: 613, y: 263}
                ],
                text: [
                    {x: 115, y: 474},
                    {x: 258, y: 376},
                    {x: 400, y: 376}
                ]
            },
            16: {
                coordinates: [1, 0, 0, 0, 0],
                boxes: [{x: 772, y: 93}],
                path: [
                    {x: 0, y: 161},
                    {x: 187, y: 63},
                    {x: 329, y: 63},
                    {x: 471, y: 63},
                    {x: 613, y: 63}
                ],
                text: [
                    {x: 115, y: 278},
                    {x: 258, y: 181},
                    {x: 400, y: 181}
                ]
            },
            17: {
                coordinates: [1, 2, 2, 2, 2],
                boxes: [{x: 772, y: 338}],
                path: [
                    {x: 0, y: 162},
                    {x: 187, y: 260},
                    {x: 329, y: 260},
                    {x: 471, y: 260},
                    {x: 613, y: 260}
                ],
                text: [
                    {x: 115, y: 278},
                    {x: 258, y: 376},
                    {x: 400, y: 376}
                ]
            },
            18: {
                coordinates: [2, 1, 1, 1, 1],
                boxes: [{x: 772, y: 214}],
                path: [
                    {x: 0, y: 262},
                    {x: 187, y: 164},
                    {x: 329, y: 164},
                    {x: 471, y: 164},
                    {x: 613, y: 164}
                ],
                text: [
                    {x: 115, y: 376},
                    {x: 258, y: 278},
                    {x: 400, y: 278}
                ]
            },
            19: {
                coordinates: [2, 3, 3, 3, 3],
                boxes: [{x: 772, y: 461}],
                path: [
                    {x: 0, y: 263},
                    {x: 187, y: 361},
                    {x: 329, y: 361},
                    {x: 471, y: 361},
                    {x: 613, y: 361}
                ],
                text: [
                    {x: 115, y: 376},
                    {x: 258, y: 474},
                    {x: 400, y: 474}
                ]
            },
            20: {
                coordinates: [0, 0, 0, 1, 2],
                boxes: [{x: 1, y: 137}],
                path: [
                    {x: 0, y: 79},
                    {x: 187, y: 79},
                    {x: 329, y: 79},
                    {x: 471, y: 176},
                    {x: 613, y: 274}
                ],
                text: [
                    {x: 115, y: 181},
                    {x: 258, y: 181},
                    {x: 400, y: 181}
                ]
            },
            21: {
                coordinates: [1, 1, 1, 2, 3],
                boxes: [{x: 1, y: 257}],
                path: [
                    {x: 0, y: 160},
                    {x: 187, y: 160},
                    {x: 329, y: 160},
                    {x: 471, y: 258},
                    {x: 613, y: 356}
                ],
                text: [
                    {x: 115, y: 278},
                    {x: 258, y: 278},
                    {x: 400, y: 278}
                ]
            },
            22: {
                coordinates: [2, 2, 2, 1, 0],
                boxes: [{x: 1, y: 288}],
                path: [
                    {x: 0, y: 263},
                    {x: 187, y: 263},
                    {x: 329, y: 263},
                    {x: 471, y: 165},
                    {x: 613, y: 67}
                ],
                text: [
                    {x: 115, y: 376},
                    {x: 258, y: 376},
                    {x: 400, y: 376}
                ]
            },
            23: {
                coordinates: [3, 3, 3, 2, 1],
                boxes: [{x: 1, y: 413}],
                path: [
                    {x: 0, y: 355},
                    {x: 187, y: 355},
                    {x: 329, y: 355},
                    {x: 471, y: 257},
                    {x: 613, y: 159}
                ],
                text: [
                    {x: 115, y: 474},
                    {x: 258, y: 474},
                    {x: 400, y: 474}
                ]
            },
            24: {
                coordinates: [0, 1, 2, 2, 2],
                boxes: [{x: 772, y: 322}],
                path: [
                    {x: 0, y: 66},
                    {x: 187, y: 164},
                    {x: 329, y: 261},
                    {x: 471, y: 261},
                    {x: 613, y: 261}
                ],
                text: [
                    {x: 115, y: 181},
                    {x: 258, y: 278},
                    {x: 400, y: 376}
                ]
            },
            25: {
                coordinates: [1, 2, 3, 3, 3],
                boxes: [{x: 772, y: 446}],
                path: [
                    {x: 0, y: 163},
                    {x: 187, y: 261},
                    {x: 329, y: 359},
                    {x: 471, y: 359},
                    {x: 613, y: 359}
                ],
                text: [
                    {x: 115, y: 278},
                    {x: 258, y: 376},
                    {x: 400, y: 474}
                ]
            },
            26: {
                coordinates: [2, 1, 0, 0, 0],
                boxes: [{x: 772, y: 109}],
                path: [
                    {x: 0, y: 258},
                    {x: 187, y: 160},
                    {x: 329, y: 63},
                    {x: 471, y: 63},
                    {x: 613, y: 63}
                ],
                text: [
                    {x: 115, y: 376},
                    {x: 258, y: 278},
                    {x: 400, y: 181}
                ]
            },
            27: {
                coordinates: [3, 2, 1, 1, 1],
                boxes: [{x: 772, y: 231}],
                path: [
                    {x: 0, y: 360},
                    {x: 187, y: 262},
                    {x: 329, y: 164},
                    {x: 471, y: 164},
                    {x: 613, y: 164}
                ],
                text: [
                    {x: 115, y: 474},
                    {x: 258, y: 376},
                    {x: 400, y: 278}
                ]
            },
            28: {
                coordinates: [0, 0, 1, 0, 0],
                boxes: [{x: 1, y: 123}],
                path: [
                    {x: 0, y: 68},
                    {x: 187, y: 68},
                    {x: 329, y: 166},
                    {x: 471, y: 68},
                    {x: 613, y: 68}
                ],
                text: [
                    {x: 115, y: 181},
                    {x: 258, y: 181},
                    {x: 400, y: 278}
                ]
            },
            29: {
                coordinates: [1, 1, 0, 1, 1],
                boxes: [{x: 1, y: 184}],
                path: [
                    {x: 0, y: 169},
                    {x: 187, y: 169},
                    {x: 329, y: 71},
                    {x: 471, y: 169},
                    {x: 613, y: 169}
                ],
                text: [
                    {x: 115, y: 278},
                    {x: 258, y: 278},
                    {x: 400, y: 181}
                ]
            },
            30: {
                coordinates: [2, 2, 3, 2, 2],
                boxes: [{x: 1, y: 365}],
                path: [
                    {x: 0, y: 262},
                    {x: 187, y: 262},
                    {x: 329, y: 360},
                    {x: 471, y: 262},
                    {x: 613, y: 262}
                ],
                text: [
                    {x: 115, y: 376},
                    {x: 258, y: 376},
                    {x: 400, y: 474}
                ]
            },
            31: {
                coordinates: [3, 3, 2, 3, 3],
                boxes: [{x: 1, y: 397}],
                path: [
                    {x: 0, y: 355},
                    {x: 187, y: 355},
                    {x: 329, y: 257},
                    {x: 471, y: 355},
                    {x: 613, y: 355}
                ],
                text: [
                    {x: 115, y: 474},
                    {x: 258, y: 474},
                    {x: 400, y: 376}
                ]
            },
            32: {
                coordinates: [0, 1, 1, 1, 0],
                boxes: [{x: 772, y: 155}],
                path: [
                    {x: 0, y: 70},
                    {x: 187, y: 168},
                    {x: 329, y: 168},
                    {x: 471, y: 168},
                    {x: 613, y: 70}
                ],
                text: [
                    {x: 115, y: 181},
                    {x: 258, y: 278},
                    {x: 400, y: 278}
                ]
            },
            33: {
                coordinates: [1, 1, 2, 1, 1],
                boxes: [{x: 1, y: 242}],
                path: [
                    {x: 0, y: 168},
                    {x: 187, y: 168},
                    {x: 329, y: 266},
                    {x: 471, y: 168},
                    {x: 614, y: 168}
                ],
                text: [
                    {x: 115, y: 278},
                    {x: 258, y: 278},
                    {x: 400, y: 376}
                ]
            },
            34: {
                coordinates: [2, 2, 1, 2, 2],
                boxes: [{x: 1, y: 304}],
                path: [
                    {x: 0, y: 261},
                    {x: 187, y: 261},
                    {x: 329, y: 163},
                    {x: 471, y: 261},
                    {x: 614, y: 261}
                ],
                text: [
                    {x: 115, y: 376},
                    {x: 258, y: 376},
                    {x: 400, y: 278}
                ]
            },
            35: {
                coordinates: [3, 2, 2, 2, 3],
                boxes: [{x: 772, y: 432}],
                path: [
                    {x: 0, y: 360},
                    {x: 187, y: 262},
                    {x: 329, y: 262},
                    {x: 471, y: 262},
                    {x: 613, y: 360}
                ],
                text: [
                    {x: 115, y: 474},
                    {x: 258, y: 376},
                    {x: 400, y: 376}
                ]
            },
            36: {
                coordinates: [1, 0, 0, 0, 1],
                boxes: [{x: 772, y: 169}],
                path: [
                    {x: 0, y: 166},
                    {x: 187, y: 68},
                    {x: 329, y: 68},
                    {x: 471, y: 68},
                    {x: 613, y: 165}
                ],
                text: [
                    {x: 115, y: 278},
                    {x: 258, y: 181},
                    {x: 400, y: 181}
                ]
            },
            37: {
                coordinates: [1, 2, 2, 2, 1],
                boxes: [{x: 772, y: 261}],
                path: [
                    {x: 0, y: 160},
                    {x: 187, y: 259},
                    {x: 329, y: 259},
                    {x: 471, y: 259},
                    {x: 613, y: 161}
                ],
                text: [
                    {x: 115, y: 278},
                    {x: 258, y: 376},
                    {x: 400, y: 376}
                ]
            },
            38: {
                coordinates: [2, 1, 1, 1, 2],
                boxes: [{x: 772, y: 293}],
                path: [
                    {x: 0, y: 257},
                    {x: 187, y: 159},
                    {x: 329, y: 159},
                    {x: 471, y: 159},
                    {x: 613, y: 257}
                ],
                text: [
                    {x: 115, y: 376},
                    {x: 258, y: 278},
                    {x: 400, y: 278}
                ]
            },
            39: {
                coordinates: [2, 3, 3, 3, 2],
                boxes: [{x: 772, y: 385}],
                path: [
                    {x: 0, y: 264},
                    {x: 187, y: 362},
                    {x: 329, y: 362},
                    {x: 471, y: 362},
                    {x: 613, y: 264}
                ],
                text: [
                    {x: 115, y: 376},
                    {x: 258, y: 474},
                    {x: 400, y: 474}
                ]
            },
            40: {
                coordinates: [1, 0, 1, 0, 1],
                boxes: [{x: 1, y: 153}],
                path: [
                    {x: 0, y: 150},
                    {x: 187, y: 51},
                    {x: 329, y: 149},
                    {x: 471, y: 51},
                    {x: 613, y: 152}
                ],
                text: [
                    {x: 115, y: 278},
                    {x: 258, y: 181},
                    {x: 400, y: 278}
                ]
            },
            41: {
                coordinates: [2, 1, 2, 1, 2],
                boxes: [{x: 772, y: 277}],
                path: [
                    {x: 0, y: 261},
                    {x: 187, y: 163},
                    {x: 329, y: 261},
                    {x: 471, y: 163},
                    {x: 613, y: 261}
                ],
                text: [
                    {x: 115, y: 376},
                    {x: 258, y: 278},
                    {x: 400, y: 376}
                ]
            },
            42: {
                coordinates: [3, 2, 3, 2, 3],
                boxes: [{x: 1, y: 428}],
                path: [
                    {x: 0, y: 363},
                    {x: 187, y: 265},
                    {x: 329, y: 363},
                    {x: 471, y: 269},
                    {x: 613, y: 366}
                ],
                text: [
                    {x: 115, y: 474},
                    {x: 258, y: 376},
                    {x: 400, y: 474}
                ]
            },
            43: {
                coordinates: [0, 1, 0, 1, 0],
                boxes: [{x: 772, y: 124}],
                path: [
                    {x: 0, y: 67},
                    {x: 187, y: 165},
                    {x: 329, y: 67},
                    {x: 471, y: 165},
                    {x: 614, y: 69}
                ],
                text: [
                    {x: 115, y: 181},
                    {x: 258, y: 278},
                    {x: 400, y: 181}
                ]
            },
            44: {
                coordinates: [1, 2, 1, 2, 1],
                boxes: [{x: 1, y: 273}],
                path: [
                    {x: 0, y: 166},
                    {x: 187, y: 264},
                    {x: 329, y: 166},
                    {x: 471, y: 267},
                    {x: 614, y: 168}
                ],
                text: [
                    {x: 115, y: 278},
                    {x: 258, y: 376},
                    {x: 400, y: 278}
                ]
            },
            45: {
                coordinates: [2, 3, 2, 3, 2],
                boxes: [{x: 1, y: 350}],
                path: [
                    {x: 0, y: 264},
                    {x: 187, y: 362},
                    {x: 329, y: 264},
                    {x: 471, y: 365},
                    {x: 613, y: 267}
                ],
                text: [
                    {x: 115, y: 376},
                    {x: 258, y: 474},
                    {x: 400, y: 376}
                ]
            },
            46: {
                coordinates: [1, 0, 1, 2, 1],
                boxes: [{x: 772, y: 199}],
                path: [
                    {x: 0, y: 163},
                    {x: 187, y: 65},
                    {x: 329, y: 163},
                    {x: 471, y: 263},
                    {x: 613, y: 166}
                ],
                text: [
                    {x: 115, y: 278},
                    {x: 258, y: 181},
                    {x: 400, y: 278}
                ]
            },
            47: {
                coordinates: [2, 1, 2, 3, 2],
                boxes: [{x: 772, y: 400}],
                path: [
                    {x: 0, y: 276},
                    {x: 187, y: 178},
                    {x: 329, y: 276},
                    {x: 471, y: 374},
                    {x: 613, y: 276}
                ],
                text: [
                    {x: 115, y: 376},
                    {x: 258, y: 278},
                    {x: 400, y: 376}
                ]
            },
            48: {
                coordinates: [1, 2, 1, 0, 1],
                boxes: [{x: 1, y: 199}],
                path: [
                    {x: 0, y: 164},
                    {x: 187, y: 262},
                    {x: 329, y: 164},
                    {x: 471, y: 69},
                    {x: 613, y: 167}
                ],
                text: [
                    {x: 115, y: 278},
                    {x: 258, y: 376},
                    {x: 400, y: 278}
                ]
            },
            49: {
                coordinates: [2, 3, 2, 1, 2],
                boxes: [{x: 772, y: 353}],
                path: [
                    {x: 0, y: 264},
                    {x: 187, y: 358},
                    {x: 329, y: 264},
                    {x: 471, y: 166},
                    {x: 613, y: 267}
                ],
                text: [
                    {x: 115, y: 376},
                    {x: 258, y: 474},
                    {x: 400, y: 376}
                ]
            }
        };

        JL().debug('-- Lines50 initialized');
    }

    drawLineImages(lines, winReels, parentContainer, winLine, payment) {
        lines.forEach(lineKey => {
            const line = this.lines[lineKey];
            const winStartPos = (winReels === 0) ? 0 : winReels[0];
            const lineIndex = (lineKey <= 24) ? 1 : 2;

            line.coordinates.forEach((rowIndex, reelIndex) => {
                const image = winReels.includes(reelIndex) ?
                    (winStartPos === reelIndex) ?
                        `winLinesBox${lineIndex}` : `winLines${lineIndex}` :
                    `lines${lineIndex}`;

                this.lineImageWidth = 142; // width of line image in sprite, magic number for each game
                this.lineImageHeight = 140; // height of line image in sprite, magic number for each game

                let spritePosX = this.lineImageWidth * reelIndex;
                const spritePosY = (lineIndex === 1) ?
                    this.lineImageHeight * lineKey :
                    this.lineImageHeight * lineKey - this.lineImageHeight * 25;

                if (reelIndex === 0 || reelIndex === 4) {
                    this.lineImageWidth = 187;
                }
                spritePosX += reelIndex !== 0 ? 45 : 0;

                const sprite = new Sprite(new Texture(App.Game.getTexture(image), {
                    x: spritePosX,
                    y: spritePosY,
                    width: this.lineImageWidth,
                    height: this.lineImageHeight
                }));

                sprite.position.set(line.path[reelIndex].x, line.path[reelIndex].y);
                sprite.name = lineIndex;
                parentContainer.addChild(sprite);

                if (winLine && winStartPos < 3) {
                    this.addLineWin(parentContainer, payment, line.text[winStartPos].x, line.text[winStartPos].y);
                }
            });
        });
    }

    drawBoxes(parentContainer) {
        parentContainer.removeChildren(); // clear all previous boxes
        const currentLines = App.Game.gameSettings.getCurrentLineMas();
        const boxWidth = 27; // width of winline boxes
        const boxHeight = 13; // height of winline boxes

        currentLines.forEach(lineIndex => {
            lineIndex--;
            this.lines[lineIndex].boxes.forEach(box => {
                // create box sprite
                const sprite = new Sprite(new Texture(App.Game.getTexture('boxes'), {
                    x: 0,
                    y: boxHeight * lineIndex, // sx, sy,
                    width: boxWidth,
                    height: boxHeight
                }));
                sprite.position.set(box.x, box.y);
                sprite.name = lineIndex;
                this.addBoxEvents(sprite);
                parentContainer.addChild(sprite);
            });
        });
    }
}
