import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../../../index';
import BookGame from './../../deluxe/bookGame';
import Lines9 from './../../novomatic/lines9';
import GambleNovomatic from './../../novomatic/gamble';
import InfoScreen from '../../infoScreen';

/* PIXI aliases */
const Container = PIXI.Container,
    Texture = PIXI.Texture,
    Sprite = PIXI.Sprite,
    AnimatedSprite = PIXI.AnimatedSprite,
    Text = PIXI.Text;

export default class BookOfRa extends BookGame {
    constructor() {
        super();
        this.id = 'book-of-ra';
        this.name = 'Book of Ra';
        this.scatter = 9;
        this.symbolEffects = false;
        this.buttonsPanelShadow = 'mid';

        // reel properties
        this.reelTop = 95; // magic number - where reel images starts
        this.reelXCoordinates = [53, 194, 337, 480, 622]; // magic numbers - x coordinates where reels starts
        this.symbolHeight = 126; // height of a single symbol
        this.symbolWidth = 126; // width of a single symbol

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 90, y: 110},
            bonusInBonusFrame: {x: 90, y: 110},
            endBonusFrame: {x: 90, y: 110}
        };

        this.symbols = [
            {regularDelay: 350, payment: [0, 0, 0, 5, 25, 100]},       // 0 - 10
            {regularDelay: 350, payment: [0, 0, 0, 5, 25, 100]},       // 1 - J
            {regularDelay: 350, payment: [0, 0, 0, 5, 25, 100]},       // 2 - Q
            {regularDelay: 350, payment: [0, 0, 0, 5, 40, 150]},       // 3 - K
            {regularDelay: 350, payment: [0, 0, 0, 5, 40, 150]},       // 4 - A
            {regularDelay: 100, payment: [0, 0, 5, 30, 100, 750]},     // 5 - scarab
            {regularDelay: 100, payment: [0, 0, 5, 30, 100, 750]},     // 6 - statue
            {regularDelay: 100, payment: [0, 0, 5, 40, 400, 2000]},    // 7 - pharaoh
            {regularDelay: 100, payment: [0, 0, 10, 100, 1000, 5000]}, // 8 - men
            {
                regularDelay: 100, payment: [0, 0, 0, 2, 20, 200],     // 9 - book (scatter)
                skipSteps: 1 // skip step count for animation, play animation from this step
            }
        ];
        this.imageResources = {
            main: this.mergePath({mainArea: `area/${App.System.resolution}/main.png`}),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                bonusArea: `area/${App.System.resolution}/bonus.png`,
                topAnimation: 'bonus/top_anim.png',
                bookAnim: 'bonus/book-anim.png',
                frame: 'bonus/frame.png',
                symbolBook: 'bonus/symbol-book.png',
                book: 'bonus/book.png'
            }),
            atlas: this.mergePath([
                'bonus/bonusSymbols.json',
                'bonus/scatterSymbols.json',
                'bonus/additionalSymbols.json'
            ])
        };

        this.gameSounds = {
            soundClass: 'novomatic',
            sounds: [
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'bonus-game-end', alias: 'bonusGameEnd'},
                {name: 'book-open', alias: 'bookOpen'},
                {name: 'book-select-symbol', alias: 'bookSelectSymbol', loop: true},
                {name: 'book-step-up', alias: 'bookStepUp'},
                {name: 'bonus-win-symbols-show', alias: 'bonusWinSymbols'}
            ],
            path: `/game/games/${this.id}/audio/`
        };

        this.Lines = new Lines9();
        this.Gamble = new GambleNovomatic(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            activeBlack: 'gamble/black-active.png',
            activeRed: 'gamble/red-active.png',
            inactiveBlack: 'gamble/black-inactive.png',
            inactiveRed: 'gamble/red-inactive.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 4}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines - current lines count
     * @param bet - current bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        const y = 12;

        switch (page) {
            case 1:
                ctx.font = 'bold 12pt Arial';
                ctx.fillStyle = '#fff';
                ctx.textAlign = 'center';

                // scatter
                ctx.fillText(bet * nLines * this.symbols[9].payment[5], 365, y + 253);
                ctx.fillText(bet * nLines * this.symbols[9].payment[4], 365, y + 280);
                ctx.fillText(bet * nLines * this.symbols[9].payment[3], 365, y + 305);
                // men
                ctx.fillText(bet * this.symbols[8].payment[5], 200, 121 + y);
                ctx.fillText(bet * this.symbols[8].payment[4], 200, 144 + y);
                ctx.fillText(bet * this.symbols[8].payment[3], 200, 167 + y);
                ctx.fillText(bet * this.symbols[8].payment[2], 200, 191 + y);
                // pharaoh
                ctx.fillText(bet * this.symbols[7].payment[5], 720, 121 + y);
                ctx.fillText(bet * this.symbols[7].payment[4], 720, 144 + y);
                ctx.fillText(bet * this.symbols[7].payment[3], 720, 167 + y);
                ctx.fillText(bet * this.symbols[7].payment[2], 720, 191 + y);
                // statue
                ctx.fillText(bet * this.symbols[6].payment[5], 200, 272 + y);
                ctx.fillText(bet * this.symbols[6].payment[4], 200, 295 + y);
                ctx.fillText(bet * this.symbols[6].payment[3], 200, 318 + y);
                ctx.fillText(bet * this.symbols[6].payment[2], 200, 341 + y);
                // scarab
                ctx.fillText(bet * this.symbols[5].payment[5], 720, 272 + y);
                ctx.fillText(bet * this.symbols[5].payment[4], 720, 295 + y);
                ctx.fillText(bet * this.symbols[5].payment[3], 720, 318 + y);
                ctx.fillText(bet * this.symbols[5].payment[2], 720, 341 + y);
                // AK
                ctx.fillText(bet * this.symbols[4].payment[5], 200, 426 + y);
                ctx.fillText(bet * this.symbols[4].payment[4], 200, 450 + y);
                ctx.fillText(bet * this.symbols[4].payment[3], 200, 475 + y);
                // QJ10
                ctx.fillText(bet * this.symbols[2].payment[5], 720, 426 + y);
                ctx.fillText(bet * this.symbols[2].payment[4], 720, 450 + y);
                ctx.fillText(bet * this.symbols[2].payment[3], 720, 475 + y);
                break;

            case 2:
                ctx.font = '21pt Arial Narrow';
                ctx.fillStyle = '#2183fd';
                ctx.strokeStyle = '#19cbe5';
                ctx.lineWidth = 2;
                ctx.textAlign = 'center';

                const payment = (bet * this.symbols[8].payment[5]).toString();
                ctx.strokeText(payment, 400, 425);
                ctx.fillText(payment, 400, 425);
                break;
        }
    }

    /**
     * Drawing  the table of bonus game
     */
    showStartBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y);

        const richText = new Text('10 FREE GAMES \n with special expanding\n symbol', {
            align: 'center',
            fontFamily: 'Times New Roman',
            fontSize: 26,
            fontWeight: 'bold',
            fill: ['#00e1ff', '#fbf4ff'], // gradient
            stroke: '#282828',
            strokeThickness: 5,
            lineJoin: 'round'
        });
        richText.x = 280;
        richText.y = 180;
        parentContainer.addChild(richText);
        // create sprite of bookAnim and show first frame
        this.createBookSprite(parentContainer);

        App.updateButton('start', {
            disabled: false,
            title: 'start',
            handler: () => this.startBonusAnimation(parentContainer)
        });
    }

    /**
     *  Drawing  the table of bonus in bonus game
     */
    showBonusInBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y);
        const richText = new Text('10 MORE FREE GAMES', {
            align: 'center',
            fontFamily: 'Times New Roman',
            fontSize: 26,
            fontWeight: 'bold',
            fill: ['#00e1ff', '#fbf4ff'], // gradient
            stroke: '#282828',
            strokeThickness: 5,
            lineJoin: 'round'
        });
        richText.x = 261;
        richText.y = 205;
        parentContainer.addChild(richText);
    }

    /**
     * Drawing  the table of end of bonus in bonus game
     */
    showEndBonusFrame(parentContainer, {x, y}, {win, freeGames}) {
        this.showBonusFrame(parentContainer, x, y);
        const richText = new Text(`FEATURE WIN\n${this.bonusStatus.win} CREDITS\n${this.bonusStatus.total} FREE GAMES PLAYED`, {
            align: 'center',
            fontFamily: 'Times New Roman',
            fontSize: 26,
            fontWeight: 'bold',
            fill: ['#00e1ff', '#fbf4ff'], // gradient
            stroke: '#282828',
            strokeThickness: 5,
            lineJoin: 'round'
        });
        richText.x = 253;
        richText.y = 204;
        parentContainer.addChild(richText);
    }

    //
    // -------------- Book of ra special book animations ---------------------
    //

    createBookSprite(parentContainer) {
        // create container for book animations
        const bookContainer = new Container();
        bookContainer.name = 'bookContainer';
        bookContainer.position.set(297, 282);
        parentContainer.addChild(bookContainer);

        const sprite = new AnimatedSprite(this.getBookTexture(1));
        sprite.name = 'bookAnim';
        sprite.animationSpeed = 0.2;
        sprite.scale.set(1 / 1.55);
        sprite.loop = false;
        bookContainer.addChild(sprite);
    }

    getBookTexture = (frame) =>
        [...Array(frame)].map((item, index) => {
            const width = 370, height = 200;
            const colIndex = index % 5;
            const rowIndex = Math.floor(index / 5) % 6;
            return new Texture(this.getTexture('bookAnim'), {
                x: colIndex * width,
                y: rowIndex * height,
                width, height
            });
        });

    /**
     * Draw all bonus animation after bonus 'press any button'
     * @param parentContainer
     * @param bonusSymbol - current bonus symbol in extension
     */
    drawBonusAnimation(parentContainer, bonusSymbol) {
        JL().debug(`-- Draw bonus enter animation. Bonus symbol: ${bonusSymbol}`);

        const bookContainer = parentContainer.getChildByName('bookContainer');
        const spriteBookAnim = bookContainer.getChildByName('bookAnim');
        spriteBookAnim.textures = this.getBookTexture(27);
        App.Sounds.playSound('bookOpen');
        spriteBookAnim.play();
        spriteBookAnim.onComplete = () => symbolAnimation();

        const getSymbolAnimationTexture = toFrame => this.getSpriteTextures({
            toFrame, image: 'symbolBook',
            width: 76, height: 76
        });

        const symbolAnimation = () => {
            App.Sounds.playSound('bookSelectSymbol');

            const symbolsSprite = new AnimatedSprite(getSymbolAnimationTexture(9));
            symbolsSprite.loop = false;
            symbolsSprite.name = 'symbolSprite';
            symbolsSprite.animationSpeed = 0.1;
            symbolsSprite.position.set(23, 16);
            symbolsSprite.play();
            symbolsSprite.onComplete = () => symbolAnimationSecondCircle(symbolsSprite);
            bookContainer.addChild(symbolsSprite);
        };

        // reiteration of  animation  symbols and stop on bonusSymbol.
        const symbolAnimationSecondCircle = symbolsSprite => {
            symbolsSprite.textures = getSymbolAnimationTexture(bonusSymbol + 1);
            symbolsSprite.play();
            symbolsSprite.onComplete = () => {
                spriteBookAnim.textures = this.getBookTexture(28);
                spriteBookAnim.gotoAndStop(27);
                App.Sounds.stopSound('bookSelectSymbol');
                bookGoToTopAnimation();
                this.drawPaymentsOnBook(bookContainer);
            };
        };

        const bookGoToTopAnimation = () => {
            const ticker = this.app.ticker;
            const top = -15;
            App.Sounds.playSound('bookStepUp');
            const bookIsOnTheTop = () => {
                ticker.remove(letBookUp);
                this.bonusRoll();
            };

            const letBookUp = () => {
                bookContainer.position.y -= 3;
                bookContainer.position.y <= top && bookIsOnTheTop();
            };

            ticker.add(letBookUp);
        };
    }

    drawPaymentsOnBook(parentContainer, x = 135, y = 60) {
        let i = 0;
        const payTable = [];
        const bet = this.gameSettings.getBetLineCredit();
        this.symbols[this.bonusRollSymbol].payment.forEach((pay, key) => {
            pay !== 0 && payTable.push([key, pay]);
        });

        payTable.forEach(pay => {
            const textPayments = new Text(`${pay[0]} - ${bet * pay[1]}`, {
                align: 'left',
                fontFamily: 'Georgia',
                fontSize: 16,
                fontWeight: 'bold',
                fill: '#bf5d00',
                lineJoin: 'round'
            });
            textPayments.name = pay[0];
            textPayments.x = x;
            textPayments.y = this.symbols[this.bonusRollSymbol].payment[2] === 0 ?
                y - 20 * i :
                y - 15 * i;
            i++;
            parentContainer.addChild(textPayments);
        });
    }

    /**
     * Draw open book at logo
     * @param parentContainer
     */
    drawBookTop(parentContainer) {
        const x = 265;
        const y = -11;
        const bookContainer = new Container();
        bookContainer.name = 'bookContainer';
        bookContainer.position.set(33, 0);
        parentContainer.addChild(bookContainer);

        const sprite = new Sprite(this.getTexture('book'));
        sprite.name = 'bookTop';
        sprite.scale.set(1 / 1.55);
        sprite.position.set(x, y);
        bookContainer.addChild(sprite);

        const width = 76, height = 76;
        const symbolSprite = new Sprite(new Texture(this.getTexture('symbolBook'), {
            x: this.bonusRollSymbol * width,
            y: 0,
            width: width,
            height: height
        }));
        symbolSprite.name = 'symbolBookTop';
        symbolSprite.position.set(288, 5);
        this.drawPaymentsOnBook(bookContainer, 400, 49);
        bookContainer.addChild(symbolSprite);
    }

    /**
     * Function to show Top animation before bonus game
     * @param parentContainer
     */
    drawTopAnimation(parentContainer) {
        const sprite = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 23, image: 'topAnimation',
            width: 800, height: 95, colCount: 1
        }));
        sprite.animationSpeed = 0.15;
        sprite.loop = false;
        sprite.play();
        sprite.onComplete = () => sprite.gotoAndStop(0);
        parentContainer.addChild(sprite);
    }

    /**
     * Book payments animation in bonus
     */
    startAnimationBook(winReels) {
        const text = this.getStageChild('bonusContainer')
            .getChildByName('bookContainer')
            .getChildByName(winReels.length);
        text.step = 0; // add new property for ticker

        this.app.ticker.add(this.paymentsFlipping);
    }

    /**
     * Reset payments animation
     */
    resetAnimationBook() {
        const bookContainer = this.getStageChild('bonusContainer').getChildByName('bookContainer');
        bookContainer && bookContainer.children.forEach(text => (text.visible = true));

        this.app.ticker.remove(this.paymentsFlipping);
    }

    paymentsFlipping = () => {
        const winReels = this.getBonusWinReels(this.bonusRollSymbol);
        const text = this.getStageChild('bonusContainer')
            .getChildByName('bookContainer')
            .getChildByName(winReels.length);

        // toggle visible if 20 frames played
        if (text.step % 20 === 0) {
            text.visible = !text.visible;
        }

        text.step++;
    };
}
