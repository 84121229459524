import * as PIXI from 'pixi.js-legacy';

import './styles.less';
import App from './../../../index';
import NightCity from './../night-city/game';
import InfoScreen from '../../infoScreen';
import Lines from './lines';
import Gamble from './../night-city/gamble';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Container = PIXI.Container,
    Text = PIXI.Text;

export default class NightCitySevens extends NightCity {
    constructor() {
        super();
        this.id = 'night-city-sevens';
        this.name = 'Night City Sevens';
        this.reels = 3;
        this.reelRows = 3; // number of rows per reel
        this.symbolWidth = 200;
        this.symbolHeight = 160;
        this.gameFieldWidth = 960;
        this.gameWidth = App.System.resolution === '4x3' ? this.gameFieldWidth : 1280;
        this.gameFieldHeight = 720;
        this.gameHeight = App.configs.doubleScreen ? 1440 : 720;
        this.defaultFeatureDelay = 1550;
        this.buttonsPanelShadow = 'mid';

        this.reels = 3;
        this.reelRows = 3; // number of rows per reel
        this.reelTop = 95;
        this.reelXCoordinates = [155, 387, 617];
        this.offsetReelMask = {
            offsetX: 0, offsetY: 5,
            offsetWidth: 0, offsetHeight: -5
        };
        this.containersLayers = {
            reelsStage: 1,
            mainContainer: 0,
            linesContainer: 2,
            boxesContainer: 4,
            extraBetContainer: 5,
            bonusContainer: 6,
            symbolInfo: 7
        };
        this.SymbolInfo.enabled = true;

        this.symbols = [
            {regularDelay: 50, payment: [0, 0, 0, 40]},  // 0 - cocktail
            {regularDelay: 50, payment: [0, 0, 0, 40]},  // 1 - beer
            {regularDelay: 50, payment: [0, 0, 0, 40]},  // 2 - coffee
            {regularDelay: 50, payment: [0, 0, 0, 40]},  // 3 - diamond
            {regularDelay: 50, payment: [0, 0, 0, 80]},  // 4 - chery
            {regularDelay: 50, payment: [0, 0, 0, 80]},  // 5 - microphone
            {regularDelay: 50, payment: [0, 0, 0, 100]}, // 6 - joker
            {regularDelay: 50, payment: [0, 0, 0, 200]}, // 7 - sevens
            {regularDelay: 50, payment: [0, 0, 0, 300]}  // 8 - night-city
        ];

        this.imageResources = {
            main: this.mergePath({
                background: 'area/background.png',
                logo: 'Logo.png'
            }),
            atlas: this.mergePath(['staticSymbols.json']),
            video: this.mergePath({main: 'area/main.mp4'}),
            fonts: this.mergePath({NeonFont: 'font/NeonFont.otf'}, '/game/games/night-city/img/')
        };
        this.additionalResources = {
            main: this.mergePath({
                symbolBorder: 'area/symbol-border.png',
                paymentBorder: 'area/payment-border.png'
            })
        };

        this.gameSounds = {soundClass: 'prefergames'};
        this.Lines = new Lines(this.mergePath({winLinesBox: 'lines/winBox.png'}));
        this.Gamble = new Gamble(this.mergePath({
            activeBlack: 'gamble/black-active.png',
            inactiveBlack: 'gamble/black-inactive.png',
            activeRed: 'gamble/red-active.png',
            inactiveRed: 'gamble/red-inactive.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }, '/game/games/night-city/img/'));

        this.Gamble.imageResources.gambleArea = '/game/games/night-city-sevens/img/gamble/gamble-area.png';

        this.InfoScreen = new InfoScreen({pages: 2}); // number of game info states
        this.InfoScreen.format = 'png';
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     * @param lang
     */
    drawInfoPage(ctx, page, nLines, bet, lang) {
        ctx.textAlign = 'center';
        ctx.fillStyle = '#19abff';
        ctx.shadowColor = '00ff12';
        ctx.shadowOffsetX = ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 5;
        const {
            rules,
            combinationOfKind, combinationLeftToRight, prizesOnSelectedLines,
            HighestWinPaid, ScatterWinsAddedToLineWins, prizesShownInCredits,
            MalfunctionVoidsAllPays
        } = App.languageCollection[lang];

        switch (page) {
            case 1:
                ctx.font = 'bold 22pt NeonFont';

                // cocktail, beer, coffee
                ctx.fillStyle = '#f3ff00';

                ctx.fillText(`${bet * this.symbols[0].payment[3]}`, 470, 525);

                // cherry, microphone
                ctx.fillStyle = '#ffb0f6';

                ctx.fillText(`${bet * this.symbols[4].payment[3]}`, 760, 415);

                // seven
                ctx.fillStyle = '#00ffc7';
                ctx.fillText(`${bet * this.symbols[6].payment[3]}`, 200, 425);

                // joker
                ctx.fillStyle = '#00f600';
                ctx.fillText(`${bet * this.symbols[7].payment[3]}`, 695, 298);

                // scatter
                ctx.fillStyle = '#00f600';
                ctx.fillText(`${bet * this.symbols[8].payment[3]}`, 275, 295);

                break;
            case 2:
                ctx.fillStyle = '#00ffc7';

                ctx.font = '40pt NeonFont';
                ctx.fillText(rules, 480, 170);

                ctx.font = 'bold 20pt NeonFont';

                const rulesText = combinationOfKind +
                    combinationLeftToRight +
                    prizesOnSelectedLines +
                    HighestWinPaid +
                    ScatterWinsAddedToLineWins +
                    prizesShownInCredits +
                    MalfunctionVoidsAllPays;
                this.drawSplitText(ctx, rulesText, 480, 210, 700, {lineHeight: 35});
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    /**
     * Create PIXI.Container for game background
     * Add additional sprites to container
     * @param parentContainer
     */
    createMainContainer(parentContainer) {
        const container = new Container();
        container.name = 'mainContainer';
        container.zIndex = this.containersLayers[container.name];

        const sprite = Sprite.from(this.resources.main);
        sprite.name = 'mainAreaVideo';
        sprite.anchor.set(0.5);
        sprite.position.set(this.gameFieldWidth / 2, this.gameFieldHeight / 2);
        container.addChild(sprite);
        parentContainer.addChild(container);

        const logoSprite = new Sprite(this.getTexture('logo'));
        logoSprite.name = 'logo';
        logoSprite.position.set(197, -49);
        logoSprite.scale.set(0.8);
        logoSprite.zIndex = 10;
        container.sortableChildren = true;
        container.addChild(logoSprite);

        const background = new Sprite(this.getTexture('background'));
        background.position.set(110, 55);
        background.name = 'background';

        container.addChild(background);
    }

    /**
     * Create texts for paymentBorder table
     * @param parentContainer
     * @param payTable
     * @param direction
     */
    drawSymbolInfoPayments(parentContainer, payTable, direction) {
        const bet = this.gameSettings.getBetLineCredit();
        const props = {
            fill: '#00ff07',
            stroke: '#007318',
            align: 'left',
            fontFamily: 'NeonFont',
            fontSize: 28,
            strokeThickness: 3,
            lineJoin: 'round'
        };

        payTable.forEach((pay, index) => {
            const quantity = new Text(`x${pay[0]}`, props);
            quantity.position.x = direction === 'left' ? -65 : -45;
            quantity.position.y = 10 + (payTable.length === 1 ? -30 : -30 * index);

            const payment = new Text(` ${bet * pay[1]}`, {...props, fill: '#6bf2ff', stroke: '#1149ab'});
            payment.position.x = direction === 'left' ? -34 : -15;
            payment.position.y = 10 + (payTable.length === 1 ? -30 : -30 * index);

            parentContainer.addChild(quantity, payment);
        });
    }
}
