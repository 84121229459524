import {JL} from 'jsnlog';

import App from './../../index';
import GambleDeluxe from './../deluxe/gamble';

export default class GambleAmatic extends GambleDeluxe {
    constructor(imageResources) {
        super(imageResources);
        this.imageResources = {
            activeBlack: '/img/gamble/amatic/black-active.png',
            inactiveBlack: '/img/gamble/amatic/black-inactive.png',
            activeSpades: '/img/gamble/amatic/spades-active.png',
            inactiveSpades: '/img/gamble/amatic/spades-inactive.png',
            activeClubs: '/img/gamble/amatic/clubs-active.png',
            inactiveClubs: '/img/gamble/amatic/clubs-inactive.png',

            blackCard: '/img/gamble/amatic/card-black.png',
            redCard: '/img/gamble/amatic/card-red.png',
            gambleQueen: '/img/gamble/amatic/queen.png',
            gambleKing: '/img/gamble/amatic/king.png',
            gambleJack: '/img/gamble/amatic/jack.png',
            smallCard: '/img/gamble/amatic/card-small.png',

            clubs: '/img/gamble/amatic/clubs.png',
            diamonds: '/img/gamble/amatic/diamond.png',
            hearts: '/img/gamble/amatic/hearts.png',
            spades: '/img/gamble/amatic/spades.png',

            activeRed: '/img/gamble/amatic/red-active.png',
            inactiveRed: '/img/gamble/amatic/red-inactive.png',
            activeHearts: '/img/gamble/amatic/hearts-active.png',
            inactiveHearts: '/img/gamble/amatic/hearts-inactive.png',
            activeDiamonds: '/img/gamble/amatic/diamonds-active.png',
            inactiveDiamonds: '/img/gamble/amatic/diamonds-inactive.png',

            ...imageResources // redefine base images from game
        };
        this.prizeWinPos = [{x: 175, y: 220}, {x: 620, y: 220}, {x: 620, y: 475}];
        this.cardsQueuePos = {x: [378, 427, 475, 523, 571, 620, 670, 720], y: 110};
        this.cardPos = {x: 335, y: 220};
        this.gambleButtonsSize = {
            width: '25%',
            height: 'auto',
            borderRadius: '10%',
            bottom: '45%',
            left: '8.5%',
            right: '8%'
        };

        this.gambleAreaSize = {w: 800, h: 600};
        this.screenCoordinates = {x: 0, y: 0};
    }

    setDefaultGambleButtons = () => {
        JL().debug('-- Set default gamble buttons state');
        App.View.setState(prevState => ({
            buttons: {
                ...prevState.buttons,
                // main game buttons
                start: {
                    ...prevState.buttons.start,
                    disabled: false,
                    title: 'collect',
                    handler: App.Game.finishGamble
                },
                select: {
                    ...prevState.buttons.select,
                    disabled: false,
                    title: 'black',
                    handler: this.selectBlack,
                    additionalClass: 'gamble-black'
                },
                info: {
                    ...prevState.buttons.info,
                    disabled: false,
                    title: 'red',
                    handler: this.selectRed,
                    additionalClass: 'gamble-red'
                },
                autoStart: {
                    ...prevState.buttons.autoStart,
                    disabled: true
                },
                heartsSuit: {
                    ...prevState.buttons.heartsSuit,
                    img: 'inactive'
                },
                diamondsSuit: {
                    ...prevState.buttons.diamondsSuit,
                    img: 'inactive'
                },
                spadesSuit: {
                    ...prevState.buttons.spadesSuit,
                    img: 'inactive'
                },
                clubsSuit: {
                    ...prevState.buttons.clubsSuit,
                    img: 'inactive'
                },
                half: {
                    disabled: this.prizeWin === 1,
                    title: 'half',
                    handler: this.half
                }
            }
        }));
    };

    /**
     * Take half of win
     * Disable buttons
     */
    half = () => {
        App.updateButton('redCard', {disabled: true, handler: null});
        App.updateButton('blackCard', {disabled: true, handler: null});
        App.updateButton('select', {disabled: true});
        App.updateButton('info', {disabled: true});
        App.updateButton('autoStart', {disabled: true});
        App.updateButton('start', {disabled: true});
        App.Game.Buttons.disableAllButtons();
        this.disableSuitsButtons();
        App.Socket.send(JSON.stringify({uc: 'HALF-TRANSFER-START'}));
    };

    halfTakeWin({win}) {
        App.Money.setCents(App.Money.getCents() + win * App.Money.getCurrentDenomination());
        App.updateState('moneyParams', {
            credits: App.Money.getCredit(),
            money: App.Money.getMoney()
        });
        this.setDefaultGambleButtons();
        this.draw(true);
    }

    draw = startInverting => {
        App.Sounds.stopSound('gamble-wait');
        this.resetInverting();
        App.Game.Legends.setText('win', {text: 'win', value: this.prizeWin});
        App.Game.Legends.setStatus('selectColorOrSuit');
        App.Game.Legends.showWinFeatures();
        this.completeAnimateGambleArea(startInverting);
    };

    completeAnimateGambleArea = startInverting => {
        const ctx = this.ctx;

        ctx.drawImage(App.Game.getAdditionalImage('gambleArea'), this.screenCoordinates.x, 0); // draw Gamble Area
        ctx.drawImage(
            App.Game.getAdditionalImage('redCard'),
            this.screenCoordinates.x + this.cardPos.x,
            this.offsetGambleQueue + this.cardPos.y
        );
        this.drawGambleTexts(ctx); // show 'gamble amount' and 'Gamble to win'

        App.Game.Legends.setText('win', {text: 'win', value: this.prizeWin});
        if (startInverting) {
            this.suitWon = null;
            this.invertGambleCard();
            App.updateButton('redCard', {img: 'inactive', disabled: false, handler: this.selectRed});
            App.updateButton('heartsSuit', {img: 'inactive', handler: () => this.selectSuit('H')});
            App.updateButton('diamondsSuit', {img: 'inactive', handler: () => this.selectSuit('D')});
            App.updateButton('blackCard', {img: 'inactive', disabled: false, handler: this.selectBlack});
            App.updateButton('spadesSuit', {img: 'inactive', handler: () => this.selectSuit('S')});
            App.updateButton('clubsSuit', {img: 'inactive', handler: () => this.selectSuit('C')});
        } else if (startInverting && this.suitWon) {
            ctx.drawImage(
                App.Game.getAdditionalImage(this.suit2images[this.suitWon].card),
                this.screenCoordinates.x + this.cardPos.x,
                this.offsetGambleQueue + this.cardPos.y
            );
        }
        this.drawGambleQueue(this.offsetGambleQueue);
    };

    drawGambleTexts = ctx => {
        ctx.font = 'bold 14pt Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#FFFFFF';
        ctx.shadowBlur = 3;
        ctx.shadowOffsetX = 2;
        ctx.shadowOffsetY = 2;
        ctx.shadowColor = '#000';

        const textProps = {
            font: ctx.font,
            textAlign: ctx.textAlign,
            fillStyle: ctx.fillStyle,
            strokeStyle: '#000',
            lineWidth: 3,
            shadowColor: ctx.shadowColor,
            shadowOffsetX: ctx.shadowOffsetX,
            shadowOffsetY: ctx.shadowOffsetY,
            shadowBlur: ctx.shadowBlur,
            lineHeight: 20
        };
        App.Game.drawSplitText(ctx, App.language.gambleAmountAmatic.toUpperCase(), this.prizeWinPos[0].x, this.prizeWinPos[0].y - 45, 300, textProps);
        App.Game.drawSplitText(ctx, App.language.gambleToWinAmatic.toUpperCase(), this.prizeWinPos[1].x, this.prizeWinPos[1].y - 45, 300, textProps);
        App.Game.drawSplitText(ctx, App.language.suitGambleToWinAmatic.toUpperCase(), this.prizeWinPos[2].x, this.prizeWinPos[2].y - 50, 300, textProps);

        ctx.font = 'bold 18pt Arial';
        ctx.fillStyle = '#fffe00';
        ctx.fillText(this.prizeWin, this.screenCoordinates.x + this.prizeWinPos[0].x, this.offsetGambleQueue + this.prizeWinPos[0].y);
        ctx.fillText(this.prizeWin * 2, this.screenCoordinates.x + this.prizeWinPos[1].x, this.offsetGambleQueue + this.prizeWinPos[1].y);

        let x4Amount = null;
        ctx.textAlign = 'left';
        switch (App.settings.currentLanguage) {
            case 'it':
                x4Amount = {x: 700, y: 446};
                break;
            case 'es':
                x4Amount = {x: 670, y: 446};
                break;
            case 'el':
                x4Amount = {x: 680, y: 446};
                break;
            default:
                ctx.textAlign = 'center';
                x4Amount = {x: 620, y: 448};
                break;
        }
        ctx.fillText(this.prizeWin * 4, this.screenCoordinates.x + x4Amount.x, this.offsetGambleQueue + x4Amount.y);
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    };

    disableSuitsButtons = () => {
        App.updateButton('half', {disabled: true, handler: null});
        App.updateButton('heartsSuit', {img: 'inactive', handler: null});
        App.updateButton('diamondsSuit', {img: 'inactive', handler: null});
        App.updateButton('spadesSuit', {img: 'inactive', handler: null});
        App.updateButton('clubsSuit', {img: 'inactive', handler: null});
    };

    selectRed = () => {
        JL().debug('Red color selected');
        App.updateButton('redCard', {img: 'active', disabled: true, handler: null});
        App.updateButton('blackCard', {img: 'inactive', disabled: true, handler: null});
        App.updateButton('info', {additionalClass: 'gamble-red-active'});
        this.disableSuitsButtons();
        this.makeDoubling('Red');
    };

    selectBlack = () => {
        JL().debug('Black color selected');
        App.updateButton('redCard', {img: 'inactive', disabled: true, handler: null});
        App.updateButton('blackCard', {img: 'active', disabled: true, handler: null});
        App.updateButton('select', {additionalClass: 'gamble-black-active'});
        this.disableSuitsButtons();
        this.makeDoubling('Black');
    };

    drawGambleQueue = offsetY => {
        const ctx = this.ctx;

        // draw empty cards
        for (let i = 0; i < 8; i++) {
            ctx.drawImage(
                App.Game.getAdditionalImage(this.suit2images.smallCard),
                this.screenCoordinates.x + this.cardsQueuePos.x[i],
                offsetY + this.cardsQueuePos.y
            );
        }

        this.gambleQueue.forEach((card, index) => {
            const sIndex = this.getCardSuite(card);
            ctx.drawImage(
                App.Game.getAdditionalImage(this.suit2images[sIndex].small),
                this.screenCoordinates.x + this.cardsQueuePos.x[index + 1],
                offsetY + this.cardsQueuePos.y
            );
        });
    };

    responseColor = response => {
        const ctx = this.ctx;
        const isStop = response.stop;
        const card = response.card;
        const win = response.win;

        const roundedRect = 5;

        this.prizeWin = response.win;
        this.prizeCard = card.slice(0, card.length - 1);
        this.suitWon = this.getCardSuite(card);
        ctx.drawImage(App.Game.getAdditionalImage('gambleArea'), this.screenCoordinates.x, 0);
        this.drawGambleTexts(ctx);

        if (App.restoreGameState !== 'GAMBLE') {
            this.drawGambleQueue(this.offsetGambleQueue);

            // draw choose card
            ctx.fillStyle = '#fff';
            ctx.lineJoin = 'round';
            ctx.lineWidth = 10;
            ctx.strokeStyle = '#fff';

            // draw rounded stroke around empty card
            ctx.strokeRect(
                this.screenCoordinates.x + this.cardPos.x + roundedRect,
                this.offsetGambleQueue + this.cardPos.y + roundedRect,
                137 - roundedRect * 2, 187 - roundedRect * 2
            );
            // draw empty card
            ctx.fillRect(
                this.screenCoordinates.x + this.cardPos.x + roundedRect / 2,
                this.offsetGambleQueue + this.cardPos.y + roundedRect / 2,
                137 - roundedRect, 187 - roundedRect
            );

            // set font color for suit on card (black for spades & clubs, red for diamonds & hearts)
            ctx.fillStyle = ['spades', 'clubs'].includes(this.suitWon) ? '#000' : '#ff0000';
            ctx.fillText(
                this.prizeCard,
                this.screenCoordinates.x + this.cardPos.x + 20,
                this.offsetGambleQueue + this.cardPos.y + 25
            );

            // draw small & big suit on card
            ctx.drawImage(
                App.Game.getAdditionalImage(this.suitWon),
                this.screenCoordinates.x + this.cardPos.x + 5,
                this.offsetGambleQueue + this.cardPos.y + 30
            );
            ctx.drawImage(
                App.Game.getAdditionalImage(this.suitWon),
                this.screenCoordinates.x + this.cardPos.x + 23,
                this.offsetGambleQueue + this.cardPos.y + 97,
                90, 90
            );

            // drawing king/queen/jack icon
            switch (this.prizeCard) {
                case 'K':
                    ctx.drawImage(
                        App.Game.getAdditionalImage('gambleKing'),
                        this.screenCoordinates.x + this.cardPos.x + 65,
                        this.offsetGambleQueue + this.cardPos.y,
                        70, 76
                    );
                    break;
                case 'Q':
                    ctx.drawImage(
                        App.Game.getAdditionalImage('gambleQueen'),
                        this.screenCoordinates.x + this.cardPos.x + 65,
                        this.offsetGambleQueue + this.cardPos.y,
                        70, 76
                    );
                    break;
                case 'J':
                    ctx.drawImage(
                        App.Game.getAdditionalImage('gambleJack'),
                        this.screenCoordinates.x + this.cardPos.x + 65,
                        this.offsetGambleQueue + this.cardPos.y,
                        70, 76
                    );
                    break;
            }

            ctx.font = '36pt Ebrima';
            ctx.fontSize = 24;
            ctx.strokeWidth = 3;
            ctx.strokeStyle = '#ffff00';
            ctx.fillStyle = '#ff0000';
            win && App.Game.strokeFillText(ctx,
                'WIN',
                this.screenCoordinates.x + this.cardPos.x + 65,
                this.offsetGambleQueue + this.cardPos.y + 170
            );
            this.drawChooseSmallCard(ctx, card);
        }

        if (win > 0) {
            setTimeout(() => {
                if (isStop) {
                    App.Game.finishGamble();
                } else {
                    this.gambleQueue.unshift(card); // add choose card to array begin
                    this.gambleQueue.length > 8 && this.gambleQueue.pop(); // there could be no more the 8 images in queue
                    this.setDefaultGambleButtons();
                    this.draw(true);
                }
            }, 1000);

            // increase gamble amount and gamble to win
            if (App.restoreGameState !== 'GAMBLE') {
                this.currentGambleMultiplier *= 2;
            }

            // win
            this.prizeWin = response.win;
            App.Game.Legends.setText('win', {text: 'win', value: this.prizeWin});
            App.Sounds.playSound('gamble-win');
        } else {
            App.Game.Legends.setStatus('gambleEnd');
            App.Game.Legends.setText('win', {text: 'win', value: 0});
            this.prizeWin = 0;
            setTimeout(() => App.Game.finishGamble(), 2500);
        }

        this.resetInverting();
    };

    selectSuit = suite => {
        const suitName = {'H': 'hearts', 'C': 'clubs', 'S': 'spades', 'D': 'diamonds'};

        this.disableSuitsButtons();
        App.updateButton('autoStart', {disabled: true});
        App.updateButton('start', {disabled: true});
        App.Game.Buttons.disableAllButtons();
        App.updateButton('redCard', {img: 'inactive', disabled: true, handler: null});
        App.updateButton('blackCard', {img: 'inactive', disabled: true, handler: null});
        App.updateButton(`${suitName[suite]}Suit`, {img: 'active', handler: null});
        App.Socket.send(JSON.stringify({uc: 'MAKE-SUITE', suite}));
    };

    activateGambleButtons() {
        this.setDefaultGambleButtons();
        App.View.setState({activeGamble: true});
        App.updateButton('redCard', {img: 'inactive', disabled: false, handler: this.selectRed});
        App.updateButton('blackCard', {img: 'inactive', disabled: false, handler: this.selectBlack});

        App.updateButton('redCard', {img: 'inactive', disabled: false, handler: this.selectRed});
        App.updateButton('heartsSuit', {img: 'inactive', handler: () => this.selectSuit('H')});
        App.updateButton('diamondsSuit', {img: 'inactive', handler: () => this.selectSuit('D')});
        App.updateButton('blackCard', {img: 'inactive', disabled: false, handler: this.selectBlack});
        App.updateButton('spadesSuit', {img: 'inactive', handler: () => this.selectSuit('S')});
        App.updateButton('clubsSuit', {img: 'inactive', handler: () => this.selectSuit('C')});
    }

    deactivateGambleButtons() {
        this.disableSuitsButtons();
        App.updateButton('redCard', {img: 'inactive', disabled: true, handler: null});
        App.updateButton('blackCard', {img: 'inactive', disabled: true, handler: null});
    }
}
