import React from 'react';
import './styles.less';

export default function Devices({list}) {
    return (
        <div className='devices'>
            {Object.keys(list).map(key =>
                <div key={key} className='devices-item'>
                    <span className='system-info'>
                        <span className='key'>DEVICE: </span>
                        <span className='value'>{key}</span>
                    </span>
                    <span className={`status status-${list[key].status.toLowerCase()}`}>
                        <span className='key'>STATUS: </span>
                        <span className='status-state value'>{list[key].status}</span>
                    </span>
                    <span className='text-message'>
                        <span className='key'>MESSAGE: </span>
                        <span className='value'>{list[key].messages}</span>
                    </span>
                </div>
            )}
        </div>
    );
}
