import Cookies from 'js-cookie/src/js.cookie';

export default class Cookie {
    /**
     * Get client info from document.cookie
     * @param name
     * @param defaultValue
     */
    get = (name, defaultValue = 0) => {
        if (document.cookie.match(name)) {
            return Cookies.get(name);
        } else {
            this.set(name, defaultValue); // set default value if not exist
            return defaultValue;
        }
    };

    /**
     * Set values to document.cookie
     * Set 31 day storage
     * @param name
     * @param value
     */
    set = (name, value) => Cookies.set(name, value, {expires: 31});

    /**
     * Remove value from document.cookie
     * @param name
     * @returns {*}
     */
    remove = name => Cookies.remove(name);
}
