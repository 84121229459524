import App from './../../../index';
import GameEgt from './../../egt/game';
import Lines from './../../egt/lines40';
import GambleEgt from './../../egt/gamble';
import InfoScreen from '../../infoScreen';

export default class SuperHot40 extends GameEgt {
    constructor() {
        super();
        this.id = '40-super-hot';
        this.name = '40 Super Hot';
        this.buttonsPanelShadow = 'mid';
        this.doublingFilter = [7];

        // reel properties
        this.reelRows = 4; // number of rows per reel
        this.symbolHeight = 95; // height of aa single symbol

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 120, y: 90},
            bonusInBonusFrame: {x: 120, y: 150},
            endBonusFrame: {x: 120, y: 150}
        };

        this.symbols = [
            {regularDelay: 90, skipSteps: 15, payment: [0, 0, 0, 10, 20, 100]},   // 0 - cherry
            {regularDelay: 90, skipSteps: 15, payment: [0, 0, 0, 10, 20, 100]},   // 1 - lemon
            {regularDelay: 90, skipSteps: 15, payment: [0, 0, 0, 10, 20, 100]},   // 2 - orange
            {regularDelay: 90, skipSteps: 15, payment: [0, 0, 0, 20, 40, 200]},   // 3 - plum
            {regularDelay: 90, skipSteps: 15, payment: [0, 0, 0, 20, 40, 200]},   // 4 - wathermelon
            {regularDelay: 90, skipSteps: 15, payment: [0, 0, 0, 20, 80, 400]},   // 5 - grape
            {regularDelay: 90, skipSteps: 15, payment: [0, 0, 0, 40, 400, 1000]}, // 6 - seven 7
            {regularDelay: 90, skipSteps: 15, payment: [0, 0, 0, 5, 20, 500]}     // 7 - star scattre
        ];

        this.imageResources = {
            main: this.mergePath({mainArea: 'area/main.png'}),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                minimizeSymbols: 'minimizeSymbols.png',
                gambleArea: 'area/gamble.png'
            })
        };

        this.gameSounds = {
            soundClass: 'egt',
            sounds: [],
            path: `/game/games/${this.id}/audio/`
        };
        this.Lines = new Lines(this.mergePath({boxes: 'lines/boxes.png'}));
        this.Gamble = new GambleEgt();
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
        this.InfoScreen.format = 'png';
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 19pt Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = 'white';
        ctx.shadowColor = 'black';
        ctx.shadowOffsetX = ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 5;

        switch (page) {
            case 1:
                ctx.font = 'bold 12pt Arial';

                // cherry, lemon, orange
                this.strokeFillText(ctx, bet * this.symbols[3].payment[5], 233, 318);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[4], 233, 341);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[3], 233, 365);

                // plum, wathermelon
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 670, 313);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 670, 336);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 670, 360);

                // Start Scatter
                this.strokeFillText(ctx, bet * nLines * this.symbols[7].payment[5], 455, 369);
                this.strokeFillText(ctx, bet * nLines * this.symbols[7].payment[4], 455, 392);
                this.strokeFillText(ctx, bet * nLines * this.symbols[7].payment[3], 455, 416);

                // Grape
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 435, 210);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 435, 232);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 435, 254);

                // Seven 7
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 290, 111);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 290, 137);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 290, 165);
                break;
            case 2:
                ctx.font = 'bold 14pt Arial';
                ctx.textAlign = 'left';
                // gamble limit
                this.strokeFillText(ctx, (this.Gamble.limit * 100) / App.Money.getCurrentDenomination(), 480, 237);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }
}
