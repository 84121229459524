import {JL} from 'jsnlog';
import React from 'react';
import * as PIXI from 'pixi.js-legacy';

import './styles.less';
import App from './../../../index';
import WaysGame from './../../ways-game/game';
import WaysLines from './../../ways-game/lines';
import Gamble from './gamble';
import InfoScreen from '../../infoScreen';
import SymbolInfoCustom from './symbolInfo';
import getArrowSetting from './arrow_settings';
import font from './img/font/numbers';
import langs from './langs.js';
import BigWinUnique from './bigWin';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    AnimatedSprite = PIXI.AnimatedSprite,
    Text = PIXI.Text,
    Container = PIXI.Container,
    Graphics = PIXI.Graphics;

export default class GladiatorsRising extends WaysGame {
    constructor() {
        super();
        this.id = 'gladiators-rising';
        this.name = 'Gladiators Rising';
        this.scatter = 11;
        this.scatters = [13, 12, 11];
        this.reelTop = 90;
        this.reelXCoordinates = [50, 228, 398, 568, 738];
        this.reelFilter = [[11, 12], [11, 12, 13], [12, 13], [11, 12, 13], [13, 11]];
        this.BigWin = new BigWinUnique(); // functionality of big win scenario;
        this.BigWin.enabled = true;

        this.symbolHeight = 160; // height of a single symbol
        this.symbolWidth = 160; // width of a single symbol
        this.gameFieldWidth = 960;
        this.gameWidth = App.System.resolution === '4x3' ? this.gameFieldWidth : 1280;
        this.gameFieldHeight = 720;
        this.gameHeight = App.configs.doubleScreen ? 1440 : 720;
        this.buttonsPanelShadow = 'none';
        this.animationWays = null;
        this.symbolBackground = true;
        this.symbolEffects = true; // shadowing and none

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 218, y: 140},
            bonusInBonusFrame: {x: 315, y: 214},
            endBonusFrame: {x: 223, y: 151}
        };
        this.buttonsPanelShadow = 'mid no-blur';

        this.typeOfBonus = null;
        this.symbols = [
            {regularDelay: 40, payment: [0, 0, 0, 5, 20, 50]},           // 0 - меч
            {regularDelay: 40, payment: [0, 0, 0, 5, 20, 50]},           // 1 - тризуб
            {regularDelay: 40, payment: [0, 0, 0, 5, 20, 50]},           // 2 - булава
            {regularDelay: 40, payment: [0, 0, 0, 5, 20, 50]},           // 3 - щит
            {regularDelay: 40, payment: [0, 0, 0, 5, 20, 50]},           // 4 - топор
            {regularDelay: 40, payment: [0, 0, 0, 10, 25, 100]},         // 5 - шлем
            {regularDelay: 70, payment: [0, 0, 0, 10, 25, 200]},         // 6 - колесница
            {regularDelay: 70, payment: [0, 0, 0, 10, 25, 200]},         // 7 - лев
            {regularDelay: 40, payment: [0, 0, 0, 10, 25, 300]},         // 8 - флаг
            {regularDelay: 70, payment: [0, 0, 0, 10, 25, 400]},         // 9 - цезарь
            {regularDelay: 40, payment: [0, 0, 0, 20, 50, 500]},         // 10 - монета
            {regularDelay: 40, payment: [0, 0, 0, 50, 0, 0], zIndex: 1}, // 11 - гладиатор
            {regularDelay: 40, payment: [0, 0, 0, 50, 0, 0], zIndex: 1}, // 10 - гладиатор
            {regularDelay: 40, payment: [0, 0, 0, 50, 0, 0], zIndex: 1}  // 10 - гладиатор
        ];
        this.containersLayers = {
            reelsStage: 1,
            mainContainer: 0,
            bonusContainer: 6,
            symbolInfo: 7,
            infoContainer: 8
        };
        this.offsetReelMask = {
            offsetX: 20, offsetY: -9,
            offsetWidth: 10, offsetHeight: 18
        };

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                arch: 'area/arch.png',
                lightImg: 'area/light.png',
                reelsBorder: 'area/reelsBorder.png',
                ribbon: 'area/ribbon.png',
                logo: 'area/logo.png',
                ray: 'area/ray.png',
                smoke: 'area/smoke.png',
                borderMultiplier: 'bonus/border-multiplier.png'
            }),
            jsonAnimations: this.mergePath({borderSymbols: 'borderSymbols.json'}),
            atlas: this.mergePath([
                'staticSymbols.json',
                'regularShortSymbols.json'
            ]),
            fonts: this.mergePath({
                gladiators: 'font/gladiators.ttf',
                uniqueFont: 'font/font.ttf'
            }),
            video: this.mergePath({
                up: 'gamble/up.mp4',
                down: 'gamble/down.mp4'
            })
        };

        this.additionalResources = {
            main: this.mergePath({
                panel: 'gamble/panel.png',
                historyPanel: '/gamble/history-panel.png',
                up: 'gamble/up.png',
                down: 'gamble/down.png',

                frame: 'bonus/frame.png',
                arrow: 'bonus/arrow.png',
                arrow2: 'bonus/arrow2.png',
                font: 'font/numbers.png',
                symbolBorder: 'area/symbol-border.png',
                paymentBorder: 'area/payment-border.png'
            }),
            jsonAnimations: this.mergePath({
                archer: 'bonus/archer.json',
                shield: 'bonus/shield.json',
                sword: 'bonus/sword.json',
                reelBorder: 'bonus/reelBorder.json',
                wildSubstituteSprite: 'bonus/wildSubstituteSprite.json'
            })
        };

        this.gameSounds = {soundClass: 'prefergames'};

        this.Lines = new WaysLines();
        this.InfoScreen = new InfoScreen({pages: 4}); // number of game info states
        this.InfoScreen.format = 'png';
        this.InfoScreen.getResources = () => this.mergePath({
            info1: 'area/info1-screen.png',
            infoSprite: 'area/info-sprite.png'
        });
        this.SymbolInfo = new SymbolInfoCustom();
        this.SymbolInfo.enabled = true;

        this.Gamble = new Gamble(this.mergePath({
            inactiveBlack: 'gamble/black-active.png',
            inactiveRed: 'gamble/red-active.png',
            activeBlack: 'gamble/black-inactive.png',
            activeRed: 'gamble/red-inactive.png'
        }, '/game/games/gladiators-rising/img/'));
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     * @param lang
     */
    drawInfoPage(ctx, page, nLines, bet, lang) {
        ctx.font = '35pt uniqueFont';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#ffd044';
        ctx.shadowColor = '#b83811';
        ctx.shadowOffsetX = 5;
        ctx.shadowOffsetY = 5;
        ctx.shadowBlur = 0;
        ctx.shadowOffsetX = ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 25;
        ctx.strokeStyle = '#02011a';
        ctx.lineWidth = 2;

        const {
            scattersTrigger, archerBonus, swordBonus, shieldBonus,
            rules, pays, combination, position, leftToRight, wild, malfunction
        } = langs[lang];

        switch (page) {
            case 1:
                ctx.font = '20pt uniqueFont';
                ctx.textAlign = 'center';

                // J Q K A
                this.strokeFillText(ctx, `${bet * this.symbols[0].payment[5]}`, 155, 305);
                this.strokeFillText(ctx, `${bet * this.symbols[0].payment[4]}`, 155, 337);
                this.strokeFillText(ctx, `${bet * this.symbols[0].payment[3]}`, 155, 369);

                // helmet
                this.strokeFillText(ctx, `${bet * this.symbols[5].payment[5]}`, 355, 425);
                this.strokeFillText(ctx, `${bet * this.symbols[5].payment[4]}`, 355, 457);
                this.strokeFillText(ctx, `${bet * this.symbols[5].payment[3]}`, 355, 489);

                // lion chariot
                this.strokeFillText(ctx, `${bet * this.symbols[6].payment[5]}`, 800, 305);
                this.strokeFillText(ctx, `${bet * this.symbols[6].payment[4]}`, 800, 337);
                this.strokeFillText(ctx, `${bet * this.symbols[6].payment[3]}`, 800, 369);

                // flag
                this.strokeFillText(ctx, `${bet * this.symbols[8].payment[5]}`, 605, 425);
                this.strokeFillText(ctx, `${bet * this.symbols[8].payment[4]}`, 605, 457);
                this.strokeFillText(ctx, `${bet * this.symbols[8].payment[3]}`, 605, 489);

                // Caesar
                this.strokeFillText(ctx, `${bet * this.symbols[9].payment[5]}`, 485, 425);
                this.strokeFillText(ctx, `${bet * this.symbols[9].payment[4]}`, 485, 457);
                this.strokeFillText(ctx, `${bet * this.symbols[9].payment[3]}`, 485, 489);

                // Coin
                this.strokeFillText(ctx, `${bet * this.symbols[10].payment[5]}`, 700, 160);
                this.strokeFillText(ctx, `${bet * this.symbols[10].payment[4]}`, 700, 192);
                this.strokeFillText(ctx, `${bet * this.symbols[10].payment[3]}`, 700, 224);

                // scatters
                this.strokeFillText(ctx, `${bet * this.symbols[11].payment[3]}`, 320, 220);
                break;
            case 2:
                ctx.font = '35pt uniqueFont';

                ctx.drawImage(
                    this.getAdditionalImage('infoSprite'),
                    0, 0, 264, 264,
                    600, 90, 200, 200
                );
                ctx.drawImage(
                    this.getAdditionalImage('infoSprite'),
                    264, 0, 264, 264,
                    400, 90, 200, 200
                );
                ctx.drawImage(
                    this.getAdditionalImage('infoSprite'),
                    528, 0, 264, 264,
                    200, 90, 200, 200
                );
                this.drawSplitText(ctx, scattersTrigger, 480, 330, 550, {lineHeight: 40});
                break;
            case 3:

                ctx.strokeStyle = '#02011a';
                ctx.font = '20pt uniqueFont';
                ctx.textAlign = 'left';

                ctx.drawImage(
                    this.getAdditionalImage('infoSprite'),
                    528, 0, 264, 264,
                    30, 10, 200, 200
                );

                this.drawSplitText(ctx, archerBonus, 210, 80, 550, {lineHeight: 40});
                ctx.textAlign = 'right';

                ctx.drawImage(
                    this.getAdditionalImage('infoSprite'),
                    264, 0, 264, 264,
                    710, 150, 200, 200
                );
                ctx.shadowBlur = 25;

                this.drawSplitText(ctx, swordBonus, 750, 200, 550, {lineHeight: 40});
                ctx.textAlign = 'left';

                ctx.drawImage(
                    this.getAdditionalImage('infoSprite'),
                    0, 0, 264, 264,
                    30, 320, 200, 200
                );
                ctx.shadowBlur = 25;

                this.drawSplitText(ctx, shieldBonus, 210, 380, 550, {lineHeight: 30});

                break;
            case 4:
                ctx.font = '25pt uniqueFont';

                ctx.fillText(rules, 480, 50);
                ctx.font = '15pt uniqueFont';

                const rulesText = pays + combination + position + leftToRight + wild + malfunction;
                this.drawSplitText(ctx, rulesText, 480, 80, 800, {lineHeight: 35});
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    /**
     * Change symbol index when it is necessary.
     * For example, in Columbus and Gladiators games
     * @param symbolIndex
     * @param reelIndex
     */
    getSymbolIndex = (symbolIndex, reelIndex) =>
        symbolIndex === this.scatters[2] ?
            this.scatters[[0, 2, 4].findIndex((item) => reelIndex === item)] :
            symbolIndex;

    onInfoStartOpen() {
        this.hideReels();
        this.getStageChild('reelsStage').mask = this.getReelsMask();
    }

    onInfoStartClose() {
        this.showReels();
    }

    hideReels() {
        this.showAnimation({
            duration: 500, animations: [
                {
                    sprite: this.getStageChild('reelsStage'),
                    timeline: [{to: {y: this.reelTop + this.symbolHeight * this.reelRows + 5}}]
                }
            ]
        });
    }

    showReels() {
        const reelsStage = this.getStageChild('reelsStage');
        const reelMask = this.getStageChild('reelMask');
        reelsStage.interactiveChildren = false;

        this.showAnimation({
            duration: 500, animations: [
                {sprite: reelsStage, timeline: [{to: {y: this.reelTop}}]}
            ], onComplete: () => {
                reelsStage.interactiveChildren = true;
                reelMask.destroy();
                reelsStage.mask = null;
            }
        });
    }

    /**
     * Animate infoContainer, slide down and update state
     */
    startInfoAnimation() {
        const open = () => {
            this.createInfoContainer(this.getStage());
            this.getStageChild('infoContainer').mask = this.getReelsMask();
            this.showAnimation({
                duration: 500,
                animations: [{sprite: this.getStageChild('infoContainer'), timeline: [{to: {y: 60}}]}],
                onComplete: () => {
                    JL().debug('-- InfoScreen opened');
                    this.setState('INFO');
                    this.InfoScreen.checkInfoButtons();
                    App.updateButton('close', {disabled: false, handler: this.InfoScreen.close});
                }
            });
            App.updateState('buttons', {animation: 'hide-panel'});
            this.onInfoStartOpen();
        };

        this.checkLoadedResources(open);
    }

    /**
     * Create additional sprites and animations for stage
     * Call once when game loaded
     * @param parentContainer
     */
    createAdditionalSprites(parentContainer) {
        const stage = this.app.stage;
        const background = new Graphics()
            .beginFill(0xFFFFFF)
            .drawRect(0, 0, 1280, 720)
            .endFill();
        background.zIndex = -1;
        stage.addChild(background);
        const mainContainer = parentContainer.getChildByName('mainContainer');

        this.createMainAreaSprites(mainContainer);

        // update params for scaling animation
        const mainArea = mainContainer.getChildByName('mainArea');
        mainArea.anchor.x = 0.5;
        mainArea.position.x = 480;
    }

    /**
     * Create PIXI.Container for info pages
     * @param parentContainer
     */
    createInfoContainer = parentContainer => {
        const container = new Container();
        container.name = 'infoContainer';
        container.interactiveChildren = true;
        container.position.set(-(this.gameWidth - this.gameFieldWidth) / 2, -this.gameFieldHeight);
        container.zIndex = this.containersLayers[container.name];
        parentContainer.addChild(container);
        this.InfoScreen.update();
    };

    /**
     * Create additional sprites and animations for main area
     * @param parentContainer
     */
    createMainAreaSprites(parentContainer) {
        const ticker = this.app.ticker;
        const animationStarted = Date.now();

        const light = new Sprite(this.getTexture('lightImg'));
        light.name = 'light';
        light.position.set(-160, -269);
        parentContainer.addChild(light);

        const arch = new Sprite(this.getTexture('arch'));
        arch.name = 'arch';
        arch.position.set(-160, -580);
        parentContainer.addChild(arch);

        const leftRaySprite = new Sprite(this.getTexture('ray'));
        leftRaySprite.name = 'leftRay';
        leftRaySprite.position.set(373, 290);
        leftRaySprite.angle = 160;
        leftRaySprite.scale.set(1.5);
        leftRaySprite.anchor.set(0.5, 0);
        leftRaySprite.alpha = 0;
        arch.addChild(leftRaySprite);

        const rightRaySprite = new Sprite(leftRaySprite.texture);
        rightRaySprite.name = 'rightRay';
        rightRaySprite.position.set(864, 290);
        rightRaySprite.angle = -135;
        rightRaySprite.scale.set(1.5);
        rightRaySprite.anchor.set(0.5, 0);
        rightRaySprite.alpha = 0.6;
        arch.addChild(rightRaySprite);

        const background = new Graphics()
            .beginFill(0x000000)
            .drawRect(0, 0, 1280, 720)
            .endFill();
        background.zIndex = -1;
        background.position.y = -720;
        arch.addChild(background);

        const rightRibbon = new Sprite(this.getTexture('ribbon'));
        rightRibbon.name = 'rightRibbon';
        rightRibbon.alpha = 1;
        rightRibbon.position.set(949, 69);
        rightRibbon.roundPixels = false;
        parentContainer.addChild(rightRibbon);
        ticker.add(() => rightRibbon.angle = 1.5 * Math.cos((Date.now() - animationStarted) * 0.001) - 1);

        const leftRibbon = new Sprite(rightRibbon.texture);
        leftRibbon.name = 'leftRibbon';
        leftRibbon.scale.set(-1, 1);
        leftRibbon.alpha = 1;
        leftRibbon.position.set(3, 69);
        leftRibbon.roundPixels = false;
        parentContainer.addChild(leftRibbon);
        ticker.add(() => leftRibbon.angle = -1.5 * Math.cos((Date.now() - animationStarted) * 0.001) - 1);

        const reelsBorderSprite = new Sprite(this.getTexture('reelsBorder'));
        reelsBorderSprite.name = 'reelsBorder';
        reelsBorderSprite.position.set(-129, -46);
        reelsBorderSprite.scale.x = 0.95;

        reelsBorderSprite.alpha = 1;
        parentContainer.addChild(reelsBorderSprite);

        const logoContainer = new Container();
        logoContainer.name = 'logoContainer';
        parentContainer.addChild(logoContainer);

        const logoSprite = new Sprite(this.getTexture('logo'));
        logoSprite.name = 'logo';
        logoSprite.position.set(481, 36);
        logoSprite.scale.set(App.System.resolution === '4x3' ? 0.45 : 0.75);
        logoSprite.anchor.set(0.5);
        logoContainer.addChild(logoSprite);

        const createSmoke = () => {
            const smokeHunter = new Sprite(this.getTexture('smoke'));
            const mainArea = parentContainer.getChildByName('arch');
            smokeHunter.scale.set(0.4);
            smokeHunter.alpha = 0;
            smokeHunter.position.set(-1000, 1140);
            smokeHunter.name = 'smokeHunter';
            mainArea.addChild(smokeHunter);
            const params = {
                duration: 3000,
                animations: [
                    {sprite: smokeHunter, timeline: [{from: {alpha: 0}, to: {alpha: 0.5}, duration: {to: 500}}]},
                    {sprite: smokeHunter, timeline: [{from: {x: -1000}, to: {x: -500}}]},
                    {sprite: smokeHunter, timeline: [{from: {alpha: 0.5}, to: {alpha: 0}, duration: {from: 1000}}]}
                ],
                onComplete: () => true
            };
            this.showAnimation(params);

            const smokeWitch = new Sprite(this.getTexture('smoke'));
            smokeWitch.scale.set(-0.4, 0.4);
            smokeWitch.alpha = 0;
            smokeWitch.position.set(2234, 1140);
            smokeWitch.name = 'smokeWitch';
            mainArea.addChild(smokeWitch);

            this.showAnimation({
                ...params,
                animations: [
                    {sprite: smokeWitch, timeline: [{from: {alpha: 0}, to: {alpha: 0.5}, duration: {to: 500}}]},
                    {sprite: smokeWitch, timeline: [{from: {x: 2234}, to: {x: 1520}}]},
                    {sprite: smokeWitch, timeline: [{from: {alpha: 0.5}, to: {alpha: 0}, duration: {from: 1000}}]}
                ]
            });
        };

        // double smoke
        createSmoke();
        this.tickerTimeout(() => createSmoke(), 1000);
    }

    /**
     * Start game animations
     * @param parentContainer
     */
    showGameIntroduction(parentContainer) {
        JL().debug('-- Start game intro animation');
        this.setState('GAME_INTRO');
        this.Buttons.disableAllButtons();
        App.updateButton('autoStart', {disabled: true});
        App.updateState('buttons', {animation: 'hide'});

        const mainContainer = this.getStageChild('mainContainer');
        const reelsContainer = this.getStageChild('reelsStage');
        const logoContainer = mainContainer.getChildByName('logoContainer');
        const logoSprite = logoContainer.getChildByName('logo');
        logoSprite.position.y = 481;
        const reelsBorder = mainContainer.getChildByName('reelsBorder');
        reelsBorder.alpha = 0;
        const leftRibbon = mainContainer.getChildByName('leftRibbon');
        leftRibbon.alpha = 0;
        const rightRibbon = mainContainer.getChildByName('rightRibbon');
        rightRibbon.alpha = 0;
        const light = mainContainer.getChildByName('light');

        const blackBackground = new Graphics()
            .beginFill(0x000000)
            .drawRect(0, 0, 1280, 720)
            .endFill();
        blackBackground.name = 'blackBackground';
        blackBackground.position.x = -160;
        logoContainer.addChild(blackBackground);
        reelsContainer.position.y = this.reelTop + 720;

        const params = {
            duration: 5000,
            animations: [
                {
                    sprite: mainContainer.getChildByName('mainArea'),
                    timeline: [{from: {y: 720, scaleX: 0.8, scaleY: 0.8}, duration: {from: 500, to: 3500}}]
                },
                {
                    sprite: mainContainer.getChildByName('arch'),
                    timeline: [{from: {y: 720}, duration: {from: 500, to: 3500}}]
                },
                {
                    sprite: light,
                    timeline: [{from: {y: 720}, duration: {from: 500, to: 4000}}]
                }, {
                    sprite: reelsContainer,
                    timeline: [{to: {y: this.reelTop}, duration: {from: 3500, to: 4500}}]
                },
                {
                    sprite: reelsBorder,
                    timeline: [{from: {alpha: 0}}, {to: {alpha: 1}, duration: {from: 4500}}]
                },
                {
                    sprite: rightRibbon,
                    timeline: [{from: {alpha: 0}}, {to: {alpha: 1}, duration: {from: 4500}}]
                },
                {
                    sprite: leftRibbon,
                    timeline: [{from: {alpha: 0}}, {to: {alpha: 1}, duration: {from: 4500}}]
                },
                {
                    sprite: logoContainer.getChildByName('blackBackground'),
                    timeline: [{to: {alpha: 0}, duration: {from: 500, to: 0}}]
                },
                {
                    sprite: logoSprite,
                    timeline: [
                        {to: {scaleX: 1, scaleY: 1}, duration: {from: 300, to: 2000}},
                        {
                            from: {y: 481, scaleX: 1, scaleY: 1},
                            to: {
                                y: 36,
                                scaleX: App.System.resolution === '4x3' ? 0.45 : 0.75,
                                scaleY: 0.75
                            },
                            duration: {from: 2500, to: 3500}
                        }
                    ]
                },
                {
                    sprite: mainContainer.getChildByName('arch').getChildByName('leftRay'),
                    timeline: [
                        {from: {alpha: 0}, to: {alpha: 0.6}, duration: {from: 800, to: 1000}},
                        {from: {alpha: 0.5}, to: {angle: 100, alpha: -1}, duration: {from: 1000, to: 5000}}
                    ]
                },
                {
                    sprite: mainContainer.getChildByName('arch').getChildByName('rightRay'),
                    timeline: [
                        {from: {alpha: 0}, to: {alpha: 0.6}, duration: {from: 800, to: 1000}},
                        {from: {alpha: 0.5}, to: {angle: -100, alpha: -1}, duration: {from: 1000, to: 5000}}
                    ]
                }
            ],
            onComplete: () => {
                this.roundFinished();
                App.updateState('buttons', {animation: 'show'});
            }
        };
        this.tickerTimeout(() => this.showAnimation(params), 700);
    }

    /**
     * Function to stop Animate Feature animation
     */
    stopAnimateFeature() {
        this.stopWaysAnimation();
        this.resetSymbolAnimation();
        this.Legends.clearText('features');
        !this.isBonus() && this.Legends.clearText('win');
    }

    /**
     * Function to start logic of Animate feature first step
     * @param features
     */
    startAnimateFeature(features) {
        JL().debug('-- Start animate feature');
        this.Legends.showWinFeatures();
        App.Sounds.pauseSound('bonus-background');
        this.prepareToAnimateFeature(features);
    }

    /**
     * Function to show line for special feature
     * @param currentFeature
     */
    showFeatureLine(currentFeature) {

    }

    /**
     * Function to show text for special feature
     * @param feature
     */
    setFeatureText(feature) {
        const {uc, payment} = feature; // get current feature params

        !['SPECIAL_SYMBOL', 'FREE_ROLL', 'CHOOSING'].includes(uc) &&
        this.Legends.setText('features', {
            text: uc === 'SCATTER' ? 'scatterPays' : 'linePays',
            value: payment
        });
    }

    prepareToAnimateFeature(features) {
        const uniquePos = [];
        const animatedFeatures = features.filter(feature => feature.uc !== 'FREE_ROLL' && feature);
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        this.features.step = 0;

        // map is for filter all combinations and write unique only
        const map = new Map();
        animatedFeatures.forEach((feature) => feature.positions.forEach(positions => {
            map.set(`${positions.row}_${positions.reel}`, positions);
        }));
        map.forEach(pos => uniquePos.push(pos));

        // check 'WIN_LINE' feature contain
        const isWinWay = features.some(features => features.uc === 'WIN_WAY');
        this.updateExtraSymbols(isWinWay);

        // unique preparing for each game
        this.additionalPreparingToAnimateFeature(features);
        // initialize symbols on reelMatrix
        this.hideSymbols();
        this.animateAllWays(features, uniquePos, animatedFeatures);
    }

    prepareSymbolToPlay = (symbolObj) => {
        const {symbolBackground} = symbolObj;
        const reelStage = this.getStageChild('reelsStage');
        const lengthOfReelStage = reelStage.children.length;
        // Setting sprite tint to default. Getting rid from darkness on sprite.
        symbolObj.sprite.tint = 0xFFFFFF;
        symbolObj.image = 'regular';

        // set zIndex for win symbols to they will be higher than scatters.
        symbolObj.symbolContainer.zIndex = this.isScatterSymbol(symbolObj.symbolContainer.name) ? lengthOfReelStage : lengthOfReelStage - 1;
        symbolBackground && (symbolBackground.tint = 0xFFFFFF);

        symbolObj.loop = false;
        symbolObj.sprite.loop = false;
        this.Roll.updateSymbolSprite(symbolObj);
        symbolObj.sprite.onComplete = null;
    };

    animateAllWays(features, uniquePos, animatedFeatures) {
        if (this.getState() === 'GAMBLE') {
            this.hideSymbols();
            this.animateEachWinWay(animatedFeatures);
            return;
        }

        const ticker = this.app.ticker;
        let animateStartTime = Date.now(); // save time for calc diff
        let posIndex = 0;
        const objArray = [];
        const {payment} = this.latestResponse;

        this.Legends.setText('win', {
            text: 'win',
            value: !this.isBonus() ? this.latestResponse.payment : this.bonusWin
        });

        ticker.add(this.animationWays = () => {
            if (Date.now() - animateStartTime > 70) {
                animateStartTime = Date.now();
                if (posIndex <= uniquePos.length - 1) {
                    const {row, reel} = uniquePos[posIndex];
                    const symbolObj = this.reelMatrix[reel][row];
                    this.prepareSymbolToPlay(symbolObj);
                    posIndex++;
                    objArray.push(symbolObj);
                } else {
                    this.stopWaysAnimation();
                    if (this.BigWin.isBigWin(payment)) {
                        this.playWinWays(objArray);
                    } else {
                        this.bonusWin += payment || 0;
                        !this.isBonus() && this.endAnimateFeature();
                        this.playWinWays(objArray, () => this.checkAfterRoll(features));
                    }
                }
            }
        });
    }

    animateEachWinWay(winWays) {
        const ticker = this.app.ticker;
        let animateStartTime = Date.now(); // save time for calc diff
        let posIndex = 0;
        let wayIndex = 0;
        let objArray = [];
        ticker.add(this.animationWays = () => {
            if (Date.now() - animateStartTime > 70) {
                animateStartTime = Date.now();
                const currentWay = winWays[wayIndex].positions;
                if (currentWay.length !== posIndex) {
                    const {row, reel} = winWays[wayIndex].positions[posIndex];
                    const symbolObj = this.reelMatrix[reel][row];
                    this.prepareSymbolToPlay(symbolObj);
                    posIndex++;

                    objArray.push(symbolObj);
                    this.setFeatureText(winWays[wayIndex]);
                } else {
                    this.stopWaysAnimation();
                    posIndex = 0;
                    this.playWinWays(objArray, () => {
                        this.hideSymbols();
                        animateStartTime = Date.now();
                        // show only win way
                        ticker.add(this.animationWays);
                    });
                    wayIndex++;
                    wayIndex %= winWays.length;
                    objArray = [];
                }
            }
        });
    }

    playWinWays = (symbolObj, callBack) => {
        symbolObj.forEach(feature => {
            feature.sprite.play();
            feature.symbolBackground && (feature.symbolBackground.gotoAndPlay(0));

            feature.sprite.onComplete = () => {
                if (callBack) {
                    const isSymbolPlaying = symbolObj.find(feature => feature.sprite.playing);
                    isSymbolPlaying === undefined && callBack();
                }
            };
        });
    };

    checkAfterRoll(features) {
        if (this.isFreeRoll(features) || this.isBonus()) {
            this.isFreeRoll(features) ? // check free rolls
                this.bonusEntrance(features) :
                this.drawBonusStep(features);
        } else {
            this.hideSymbols();
            this.animateEachWinWay(features);
        }
    }

    prepareToRotationDone() {
        if (this.gameFlag.bonusStarted) {
            const {joker = {}, multiplier} = this.latestResponse.extension;
            const {payment} = this.latestResponse;
            const {reel, image, index} = this.typeOfBonus;

            if (image === 'archer' && joker && joker.length) {
                this.goArcherBonus(joker);
            } else if (image === 'sword' && this.checkScatterOnReel(reel, index)) {
                this.drawMultiplier(multiplier, index);
            } else if (image === 'shield' && payment <= 0 && this.checkScatterOnReel(reel)) {
                const {bonusInBonusFrame} = this.coordinatesBonusFrame;
                const parentContainer = this.getStageChild('bonusContainer');
                this.showBonusInBonusFrame(parentContainer, bonusInBonusFrame);
            } else {
                this.onRotationDone();
            }
        } else {
            this.onRotationDone();
        }
    }

    prepareScatters() {
        this.reelMatrix.forEach((reels) => {
            reels.forEach((symbolObj) => {
                if (this.isScatterSymbol(symbolObj.symbol)) {
                    symbolObj.image = 'regularShort';
                    symbolObj.sprite.loop = false;
                    symbolObj.loop = false;
                    this.Roll.updateSymbolSprite(symbolObj);
                    symbolObj.sprite.play();
                    symbolObj.sprite.onComplete = null;
                }
            });
        });

        this.tickerTimeout(() => this.prepareToRotationDone(), 250);
    }

    /**
     * Start scatter animations
     * @param features
     */
    bonusEntrance(features) {
        this.playBonusGameSound();
        App.updateButton('start', {disabled: true});
        this.drawBonusAskButton(this.isFreeRoll(features) && !this.bonusStatus);
    }

    /**
     * Called to show round results once animation is finished
     */
    rotationDone() {
        const reelsStage = this.getStageChild('reelsStage');
        this.createReelMatrix(this.getStageChild('reelsStage'));

        reelsStage.children.forEach(extraSymbol => {
            extraSymbol.name === 'extraSymbols' && (extraSymbol.visible = false);
        });
        this.prepareScatters();

        App.System.statistics.currentSpinNumber < 3 && App.System.collectFps();
    }

    stopWaysAnimation() {
        const ticker = this.app.ticker;
        ticker.remove(this.animationWays);
    }

    hideSymbols() {
        this.reelMatrix.forEach((reel) => {
            reel.forEach(symbolObj => {
                const {sprite, symbolBackground, symbol} = symbolObj;

                // set darkness on sprite.
                sprite.tint = 0x2C2A1A;
                symbolBackground && (symbolBackground.tint = 0x2C2A1A);
                this.isBonus() && this.isScatterSymbol(symbol) && (sprite.tint = 0xFFFFFF);
            });
        });
    }

    // BONUS SECTION

    /**
     * Show bonus message with bonus symbol or with bonus game win.
     * @param isFirstBonus {boolean} TRUE if this is the message for starting bonus game.
     */
    drawBonusAskButton(isFirstBonus = false) {
        let isLast = !isFirstBonus && this.bonusStatus && this.bonusStatus.remain === 0;
        this.tickerTimeout(() => {
            this.drawBonusFrame(isFirstBonus, isLast);
        }, 1000);

        this.gameFlag.bonusStart = true;
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});

        isLast = this.bonusStatus && this.bonusStatus.remain === 0;
    }

    drawBonusFrame(first, last) {
        const parentContainer = this.getStageChild('bonusContainer');
        const {bonusInBonusFrame, endBonusFrame} = this.coordinatesBonusFrame;
        App.Sounds.stopSound('bonus-background');
        if (first) {
            this.startBonusAnimation();
        }
        if (last) {
            App.updateButton('start', {disabled: true});
            this.showEndBonusFrame(parentContainer, endBonusFrame, this.bonusStatus);
            this.playEndBonusGameSound();
        }

        if (!first && !last) {
            this.showBonusInBonusFrame(parentContainer, bonusInBonusFrame);
        }
    }

    showBonusFrame(parentContainer, x, y, scale = 1, image = 'frame') {
        const sprite = new Sprite(this.getTexture(image));
        sprite.name = image;
        sprite.position.set(x, y);
        sprite.alpha = 0;
        sprite.scale.set(scale);
        parentContainer.addChild(sprite);

        this.fade(sprite);
    }

    /**
     * Отрисовка таблички бонус в бонусе
     */
    showBonusInBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y, 0.7);
        const frame = parentContainer.getChildByName('frame');
        this.clearPressAnyButton();

        const props = {
            align: 'center',
            dropShadow: true,
            dropShadowDistance: 0,
            dropShadowBlur: 14,
            fill: ['#ffd044'],
            fontFamily: 'uniqueFont',
            fontSize: 60,
            fontVariant: 'small-caps',
            fontWeight: 'bold',
            lineHeight: 75,
            miterLimit: 500,
            stroke: '#c40502',
            strokeThickness: 2,
            whiteSpace: 'normal',
            wordWrap: true,
            wordWrapWidth: 290
        };
        const colSpins = new Text(`+1`, props);

        colSpins.name = 'colSpins';
        colSpins.position.set(221, 80);
        const freeSpin = new Text(App.language.freeGame, props);
        freeSpin.anchor.set(0.5);
        freeSpin.name = 'freeSpin';
        freeSpin.position.set(263, 220);

        frame.addChild(colSpins, freeSpin);

        this.tickerTimeout(() => this.showAnimation({
            duration: 1000,
            animations: [{sprite: frame, timeline: [{from: {alpha: 1}, to: {alpha: 0}}]}],
            onComplete: () => this.drawBonusStep(this.latestResponse.features)
        }), 2000);
    }

    /**
     * Отрисовка таблички окончания бонусной игры
     */
    showEndBonusFrame(parentContainer, {x, y}, {win, total}) {
        this.showBonusFrame(parentContainer, x, y);
        const frame = parentContainer.getChildByName('frame');
        const container = new Container();
        container.position.set(253, 139);
        frame.addChild(container);
        const props = {
            align: 'center',
            dropShadow: true,
            dropShadowDistance: 0,
            dropShadowBlur: 14,
            fill: ['#ffd044'],

            fontFamily: 'uniqueFont',
            fontSize: 60,
            fontVariant: 'small-caps',
            fontWeight: 'bold',
            lineHeight: 75,
            miterLimit: 500,
            stroke: '#c40502',
            strokeThickness: 2,
            whiteSpace: 'normal'
        };
        const textProps = {
            parentContainer: container, fontImageName: 'font',
            map: font, align: 'center', fontInterval: -30 // px between symbols
        };

        this.drawCustomFont(win, 0, 0, textProps);

        const youWon = new Text(App.language.youWon, props);
        youWon.name = 'youWon';
        youWon.anchor.set(0.5);
        youWon.position.set(264, 99);

        const credits = new Text(App.language.credits, props);
        credits.anchor.set(0.5);
        credits.position.set(268, 262);
        credits.name = 'credits';
        frame.addChild(youWon, credits);

        this.tickerTimeout(() => this.showAnimation({
            duration: 1000,
            animations: [{sprite: frame, timeline: [{from: {alpha: 1}, to: {alpha: 0}}]}],
            onComplete: () => this.endBonus()
        }), 4000);
    }

    /**
     * End bonus game, return to regular game.
     */
    endBonus() {
        JL().debug('-- End bonus');
        this.setState('AFTER_BONUS');
        this.getStageChild('bonusContainer').removeChildren();
        this.afterBonusAction();
        this.stopAnimateFeature();
        this.typeOfBonus = null;
        this.latestResponse.payment = this.bonusStatus.win; // Double whole bonus sum
        this.latestResponse.features = [];
        this.bonusRollSymbol = null;
        this.gameFlag.bonusStarted = false;
        this.bonusStatus = null;
    }

    /**
     * Update PIXI stage after language change
     * @param language - current language collection
     */
    translateStage(language) {
        this.InfoScreen.update();
        App.View.state.activeGamble && this.Gamble.draw(true);
        const frame = this.getStageChild('bonusContainer').getChildByName('frame');
        if (frame) {
            const freeSpinText = frame.getChildByName('freeSpin');
            freeSpinText && (freeSpinText.text = language.freeGame);

            const creditsText = frame.getChildByName('credits');
            creditsText && (creditsText.text = language.credits);

            const youWon = frame.getChildByName('youWon');
            youWon && (youWon.text = language.youWon);
        }
    }

    /**
     * End bonus round, return to bonus game.
     */
    finishBonus() {
        this.drawBonusAskButton();
        App.Sounds.stopSound('bonus-background');
        this.latestResponse.payment = this.bonusWin;
        this.Legends.setStatus('creditsWon', this.bonusWin);
        this.bonusWin = 0;
        this.gameFlag.bonusStart = false;
        this.bonusRollSymbol = null;
    }

    /**
     * Reset all animations in reelMatrix
     */
    resetSymbolAnimation() {
        this.reelMatrix.forEach(reel => {
            reel.forEach(symbolObj => {
                symbolObj.sprite.gotoAndStop(0);
                symbolObj.sprite.tint = 0xFFFFFF;
                symbolObj.symbolBackground && (symbolObj.symbolBackground.tint = 0xFFFFFF);
                this.Roll.updateSymbolSprite(symbolObj);
            });
        });
    }

    /**
     * Prepare game behaviour after bonus 'press any button' message
     */
    startBonusAnimation = () => {
        App.Sounds.stopSound('banner-win');
        const gladiators = [];
        this.reelMatrix.forEach(reel => {
            reel.forEach(symbolObj => {
                this.isScatterSymbol(symbolObj.symbol) ?
                    gladiators.push(symbolObj) :
                    this.showAnimation({
                        duration: 500,
                        animations: [{
                            sprite: symbolObj.symbolContainer,
                            timeline: [{to: {alpha: 0}}]
                        }]
                    });
            });
        });

        gladiators.forEach((symbolObj, i) => {
            if (this.isScatterSymbol(symbolObj.symbol)) {
                this.showAnimation({
                    duration: 1000,
                    animations: [{
                        sprite: symbolObj.symbolContainer,
                        timeline: [{to: {y: this.reelTop + this.symbolHeight / 2 - 10}}]
                    }],
                    onComplete: () => {
                        if (i === gladiators.length - 1) {
                            this.showPressAnyButton();
                            App.updateButton('start', {
                                disabled: false,
                                title: 'start',
                                handler: () => this.choseGladiator(gladiators)
                            });
                        }
                    }
                });
            }
        });
    };

    choseGladiator(gladiators) {
        let gladiatorIndex = 0;
        const {reel} = this.latestResponse.extension.scatter;
        const props = this.getGladiatorProps(reel);
        App.updateButton('start', {disabled: true});
        this.clearPressAnyButton();

        gladiators.forEach(symbolObj => {
            if (this.isScatterSymbol(symbolObj.symbol)) {
                symbolObj.sprite.zIndex = 1;
            }
        });

        let timer = 0;
        const choosing = this.tickerInterval(() => {
            const ticker = this.app.ticker;
            const totalDuration = 300;
            const duration = {to: 150};
            const duration2 = {from: 150, to: 300};
            this.showAnimation({
                duration: totalDuration,
                animations: [{
                    sprite: gladiators[gladiatorIndex].sprite,
                    timeline: [
                        {to: {scaleX: 1.3, scaleY: 1.3}, duration: duration},
                        {from: {scaleX: 1.3, scaleY: 1.3}, to: {scaleX: 1, scaleY: 1}, duration: duration2}
                    ]
                }],
                onComplete: () => {
                    if (gladiators[gladiatorIndex].symbol === props.index && timer > 5000) {
                        const sprite = gladiators[gladiatorIndex].sprite;
                        ticker.remove(choosing);
                        this.drawBonusAnimation(sprite, props, gladiators);
                    }

                    gladiatorIndex += 1;
                    gladiatorIndex %= 3;
                }
            });
            timer += 1000;
        }, 400);
    }

    getGladiatorProps = reel => {
        const gladiators = {
            0: {
                index: 13,
                image: 'archer',
                position: {x: 79, y: 81},
                reel
            },
            2: {
                index: 12,
                image: 'sword',
                position: {x: -71, y: -229},
                reel
            },
            4: {
                index: 11,
                image: 'shield',
                position: {x: -70, y: -230},
                reel
            }
        };
        return gladiators[reel];
    };

    drawBonusAnimation(gladiator, gladiatorProps) {
        const {position} = gladiatorProps;
        this.typeOfBonus = gladiatorProps;
        gladiator.visible = false;
        const gladiatorSprite = new AnimatedSprite(this.getJsonTextures(this.typeOfBonus.image));
        gladiatorSprite.position.set(position.x, position.y);
        gladiatorSprite.zIndex = 4;
        gladiatorSprite.name = 'gladiatorSprite';
        gladiatorSprite.play();
        gladiatorSprite.loop = false;
        gladiatorSprite.animationSpeed = 0.3;

        gladiator.parent.addChild(gladiatorSprite);
        gladiatorSprite.onComplete = () => this.showStartBonusFrame(gladiatorSprite, gladiator, this.coordinatesBonusFrame.startBonusFrame);
    }

    fade(sprite, callback, duration = 1500) {
        const from = sprite.alpha;
        const to = from ^ 1;
        this.showAnimation({
            duration: duration,
            animations: [
                {
                    sprite: sprite,
                    timeline: [{from: {alpha: from}, to: {alpha: to}}]
                }
            ],
            onComplete: () => callback?.()
        });
    };

    hideTopSymbols(reelIndex) {
        const container = this.getStageChild('reelsStage').getChildByName(`reel${reelIndex}`);
        container.children.forEach(sprite => {
            if (sprite.position.y < -34 && sprite.visible) {
                sprite.visible = false;
            }
        });
    };

    hideBottomSymbols(reelIndex) {
        const container = this.getStageChild('reelsStage').getChildByName(`reel${reelIndex}`);
        container.children.forEach(sprite => {
            if (sprite.position.y > 320 && sprite.visible) {
                sprite.visible = false;
            }
        });
    };

    addSymbolBackground(parentContainer, x, y) {
        const symbolsWithBackground = [0, 1, 2, 3, 4, 5];
        if (symbolsWithBackground.includes(parentContainer.name)) {
            const symbolBackgroundAnimation = new AnimatedSprite(this.getJsonTextures('borderSymbols'));
            symbolBackgroundAnimation.name = 'SymbolBackground';
            symbolBackgroundAnimation.anchor.set(0.5);
            symbolBackgroundAnimation.loop = false;
            symbolBackgroundAnimation.position.set(x, y);
            symbolBackgroundAnimation.zIndex = -1;
            parentContainer.addChild(symbolBackgroundAnimation);
            return symbolBackgroundAnimation;
        }
    }

    checkScatterOnReel(reel) {
        const {screen} = this.latestResponse;
        return screen[reel].includes(11);
    };

    drawMultiplier(multiplier, scatter) {
        const scatterSprite = this.reelMatrix[2].find(symbolObj => symbolObj.symbol === scatter).sprite;
        const scatterContainer = scatterSprite.parent;
        const container = new Container();
        const offset = {x: 31, y: 8};
        container.name = 'multipliersContainer';
        container.position.set(this.symbolWidth / 2 + offset.x, this.symbolHeight / 2 + offset.y);
        container.zIndex = 3;

        scatterContainer.addChild(container);

        const textProps = {
            parentContainer: container, fontImageName: 'font',
            map: font, align: 'center', fontInterval: -30 // px between symbols
        };

        this.drawCustomFont(`x${multiplier}`, 0, 0, textProps);
        container.children.forEach((letter, i) => {
            if (i === 0) {
                letter.anchor.x = 0.7;
                letter.x += 15;
            } else {
                letter.anchor.x = 0.3;
                letter.x -= 15;
            }

            letter.anchor.y = 0.5;
            letter.scale.set(0);

            this.showAnimation({
                duration: 1800, animations: [
                    {
                        sprite: letter,
                        timeline: [{
                            to: {scaleX: 2, scaleY: 2},
                            duration: {to: 500}
                        }]
                    },
                    {
                        sprite: letter,
                        timeline: [{
                            from: {scaleX: 2, scaleY: 2},
                            to: {scaleX: 1.6, scaleY: 1.6},
                            duration: {from: 500, to: 800}
                        }]
                    }
                ],
                onComplete: () => {
                    if (i === 1) {
                        this.fade(container, () => {
                            container.destroy();
                            const bonusContainer = this.getStageChild('bonusContainer');
                            bonusContainer.getChildByName('multipliers').getChildByName('Multiplier').text = `X${multiplier}`;
                            this.onRotationDone();
                        }, 500);
                    }
                }
            });
        });
    }

    /**
     * Function to restore bonus game
     */
    restoreBonusGame() {
        this.imageResources.main.bonusArea && this.setBackground('bonusArea');
        this.bonusWin = this.bonusStatus.win - this.latestResponse.payment;
        // Fill WIN data
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});
        this.Legends.showWinFeatures();
        this.setBonusStatusText();
        this.Buttons.disableAllButtons();
        this.gameFlag.bonusStart = true;
        this.gameFlag.bonusStarted = true;
        const {scatter: {reel}} = this.latestResponse.extension;
        this.typeOfBonus = this.getGladiatorProps(reel);
        this.typeOfBonus.image === 'sword' && this.drawCurrentMultiplier();
        this.typeOfBonus.reel !== 0 && this.drawReelBorder(reel);

        this.processReelResponse(this.latestResponse);
        this.showAdditionalBonusImage(this.getStageChild('bonusContainer'));
    }

    goArcherBonus(jokerPositions) {
        let index = 0;
        const drawArrow = this.tickerInterval(() => {
            const {row, reel} = jokerPositions[index];
            const symbolObj = this.reelMatrix[reel][row];
            const destinationPositions = {
                x: this.symbolWidth / 2,
                y: this.symbolHeight / 2
            };

            const arrowSettings = getArrowSetting(destinationPositions, reel, row);
            const {rotation, sourcePosition} = arrowSettings;
            const arrow = new Sprite(this.getTexture('arrow'));
            arrow.name = 'arrow';
            arrow.zIndex = 5;
            arrow.scale.set(0.6);
            arrow.rotation = rotation + Math.random() * 0.1;
            arrow.position.set(sourcePosition.x, sourcePosition.y);
            symbolObj.symbolContainer.addChild(arrow);
            symbolObj.symbolContainer.zIndex = 20 + index;
            const lastFeature = jokerPositions.length - 1 === index;
            this.showAnimation({
                duration: 250, animations: [
                    {
                        sprite: arrow,
                        timeline: [{to: {x: destinationPositions.x, y: destinationPositions.y}}]
                    }
                ],
                onComplete: () => {
                    arrow.texture = this.getTexture('arrow2');
                    lastFeature && this.app.ticker.remove(drawArrow);
                    this.drawWildSubstituteSprite(symbolObj, lastFeature, jokerPositions, arrow);
                    index++;
                }
            });
        }, 600);
    }

    drawWildSubstituteSprite(symbolObj, lastFeature, jokerPositions, arrow) {
        const parentSprite = symbolObj.sprite;
        const parentContainer = parentSprite.parent;
        const coinSprite = new AnimatedSprite(this.Roll.textures['static'][10]);

        coinSprite.anchor.set(0.5);
        coinSprite.position.set(parentSprite.x, parentSprite.y);

        coinSprite.scale.set(0);
        coinSprite.name = 'coinSprite';
        const {symbolBackground, sprite} = symbolObj;
        this.fade(parentSprite, null, 500);
        this.fade(arrow, () => arrow.destroy(), 700);
        symbolBackground && this.fade(symbolBackground, () => symbolBackground.destroy(), 500);
        this.showAnimation({
            duration: 500, animations: [
                {
                    sprite: coinSprite,
                    timeline: [{to: {scaleX: 1, scaleY: 1}, duration: {to: 500}}]
                }
            ],
            onComplete: () => {
                symbolObj.symbol = 10;
                sprite.alpha = 1;
                this.Roll.updateSymbolSprite(symbolObj);
                wildSubstituteSprite.destroy();
                coinSprite.destroy();
                if (lastFeature) {
                    const screen = this.latestResponse.screen;
                    jokerPositions.forEach(positions => {
                        screen[positions.reel][positions.row] = 10;
                    });

                    this.onRotationDone();
                }
            }
        });

        const wildSubstituteSprite = new AnimatedSprite(this.getJsonTextures('wildSubstituteSprite'));
        wildSubstituteSprite.name = 'wildSubstituteSprite';
        wildSubstituteSprite.play();
        const offsetWildSubstituteSprite = 36;
        wildSubstituteSprite.position.set(-this.symbolWidth + offsetWildSubstituteSprite, -this.symbolHeight + offsetWildSubstituteSprite);
        wildSubstituteSprite.loop = false;
        wildSubstituteSprite.animationSpeed = 0.5;
        wildSubstituteSprite.blendMode = 3;
        parentContainer.addChild(wildSubstituteSprite, coinSprite);
    }

    getRandomIntInclusive = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    /** R
     * Отрисовка таблички бонусной игры
     */
    showStartBonusFrame(gladiator, symbol, {x, y}) {
        this.gameFlag.bonusStarted = true;
        this.reelMatrix.forEach(reel => {
            reel.forEach(symbolObj => {
                !this.isScatterSymbol(symbolObj.symbol) && this.fade(symbolObj.symbolContainer);
            });
        });
        this.fade(gladiator, () => {
            this.bonusRoll();
        });
        this.tickerTimeout(() => {
            this.resetSymbolAnimation();
            symbol.visible = true;
        }, 1000);
    }

    isScatterSymbol = symbolIndex => this.scatters.includes(symbolIndex);

    drawReelBorder(reel) {
        const reelBorder = new AnimatedSprite(this.getJsonTextures('reelBorder'));
        const posX = {
            2: 350,
            4: 690
        };
        reelBorder.name = 'reelBorder';
        reelBorder.blendMode = 1;
        reelBorder.animationSpeed = 0.5;
        reelBorder.y = 35;
        reelBorder.x = posX[reel];
        reelBorder.scale.set(1.1, 1.05);
        reelBorder.play();
        this.getStageChild('bonusContainer').addChild(reelBorder);
    }

    /**
     * Call after all book animation ended
     */
    bonusRoll() {
        const container = this.getStageChild('bonusContainer');
        container.removeChildren();
        this.showAdditionalBonusImage(container);
        this.typeOfBonus.image === 'sword' && this.drawCurrentMultiplier();

        App.Sounds.playSound('bonus-background');
        this.setState('IDLE_BONUS');
        this.typeOfBonus.reel !== 0 && this.drawReelBorder(this.typeOfBonus.reel);
        this.startBonusRoll();
    }

    drawCurrentMultiplier() {
        const bonusContainer = this.getStageChild('bonusContainer');
        const container = new Container();
        container.name = 'multipliers';
        container.position.set(-(this.gameWidth - this.gameFieldWidth) / 2 - 11, 86);
        bonusContainer.addChild(container);

        const multiplier = this.latestResponse.extension.multiplier;
        const props = {
            align: 'center',
            fontFamily: 'ST MicroSquare, sans-serif',
            fontSize: 25,
            fill: '#f8f2c5',
            stroke: '#f83427',
            strokeThickness: 1,
            lineJoin: 'round',
            dropShadow: true,
            dropShadowColor: '#ff571a',
            dropShadowBlur: 5,
            dropShadowDistance: 0,
            fontVariant: 'small-caps'
        };
        const borderMultiplier = new Sprite(this.getTexture('borderMultiplier'));
        borderMultiplier.scale.y = 0.5;

        const text = new Text(`X${multiplier}`, props);

        text.name = 'Multiplier';
        const text2 = new Text(`Multiplier: `, props);
        text.position.set(195, 5);
        text2.position.set(33, 5);
        container.addChild(borderMultiplier, text, text2);
    }

    hideBoxes = () => {
    };

    showBoxes = () => {
    };

    hideLines = () => {
    };

    showLines = () => {
    };

    /**
     * Change lines on mobile platform
     * @param type - increase or decrease lines
     */
    changeLinesNumbers(type) {

    }

    // GAMBLE SECTION

    startGambleAnimation() {
        const video = this.resources.up;
        video.loop = false;
        const parentContainer = this.getStage().getChildByName('bonusContainer');
        const gambleContainer = new Container();
        gambleContainer.name = 'gambleContainer';
        gambleContainer.position.set(1120, 0);
        // App.updateState('buttons', {visualization: 'choosing'});
        App.View.setState({activePrizes: false});

        parentContainer.addChild(gambleContainer);
        const panel = new Sprite(this.getTexture('panel'));
        panel.name = 'panel';
        if (App.System.resolution === '4x3') {
            panel.position.set(712, 37);
            panel.scale.set(0.75, 0.5);
        } else {
            panel.position.set(711, -13);
            panel.scale.set(0.7);
        }

        const historyPanel = new Sprite(this.getTexture('historyPanel'));
        historyPanel.name = 'historyPanel';
        historyPanel.scale.set(0.53, 0.5);
        historyPanel.position.set(186, 4);

        const queueHistory = new Container();
        queueHistory.name = 'queueHistory';
        queueHistory.sortableChildren = true;

        this.Gamble.gambleQueue.forEach((card, i) => {
            const sIndex = this.Gamble.getCardSuite(card);

            const sprite = new Sprite(this.getTexture(sIndex));

            sprite.scale.set(0.5);
            sprite.zIndex = 1;
            sprite.position.set(this.Gamble.cardsQueuePos.x[i], this.Gamble.cardsQueuePos.y);
            queueHistory.addChild(sprite);
        });

        const videoSprite = Sprite.from(video);

        videoSprite.name = 'video';

        gambleContainer.addChild(videoSprite);
        gambleContainer.addChild(panel);
        gambleContainer.addChild(historyPanel);
        gambleContainer.addChild(queueHistory);

        video.loop = false;
        video.pause();
        const offsetGambleArea = App.System.resolution === '4x3' ? -139 : 0;
        this.showAnimation({
            duration: 500, animations: [
                {
                    sprite: gambleContainer,
                    timeline: [{to: {x: -(this.gameWidth - this.gameFieldWidth) / 2 + offsetGambleArea}}]
                }
            ]
        });

        this.Gamble.drawGambleTexts();
    }

    endGambleAnimation() {
        App.View.setState({activePrizes: true});
        const parentContainer = this.getStage().getChildByName('bonusContainer').getChildByName('gambleContainer');
        parentContainer && (this.showAnimation({
            duration: 500, animations: [
                {sprite: parentContainer, timeline: [{to: {x: 1120}}]}
            ],
            onComplete: () => parentContainer.destroy()
        }));

        this.Buttons.setDefaultGameButtons();
    }

    /**
     * Create texts for paymentBorder table
     * @param parentContainer
     * @param payTable
     * @param direction
     */
    drawSymbolInfoPayments(parentContainer, payTable, direction) {
        const bet = this.gameSettings.getBetLineCredit();
        const props = {
            fill: '#ff0019',
            stroke: '#000231',
            align: 'left',
            fontFamily: 'uniqueFont',
            fontSize: 25,
            strokeThickness: 1,
            lineJoin: 'round'
        };

        if (payTable.length) {
            payTable.forEach((pay, index) => {
                const quantity = new Text(`x${pay[0]}`, props);
                quantity.position.x = direction === 'left' ? -65 : -45;
                quantity.position.y = 15 + (payTable.length === 1 ? -30 : -30 * index);

                const payment = new Text(` ${bet * pay[1]}`, {...props, fill: '#ffd044', stroke: '#ff0019'});
                payment.position.x = direction === 'left' ? -34 : -15;
                payment.position.y = 15 + (payTable.length === 1 ? -30 : -30 * index);
                parentContainer.addChild(quantity, payment);
            });
        }
    }
}
