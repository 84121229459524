import React from 'react';

import App from './../../index';
import Steps from './../steps';

export default function PaymentMethod({type, lang, serverRequest}) {
    const inputValidation = event => {
        const targetValue = event.target.value.replace(/[^\d0-9]/g, '');
        const length = event.target.value.length;
        let position = event.target.selectionEnd;

        switch (event.target.name) {
            case 'cardNumber':
                event.target.value = targetValue.replace(/(.{4})/g, '$1 ').trim();
                event.target.selectionEnd = position += event.target.value.charAt(position - 1) === ' ' && event.target.value.charAt(length - 1) === ' ';
                break;
            case 'deposit':
                event.target.value = targetValue.trim();
                break;
            case 'amount':
                event.target.value = targetValue.trim();
                break;
        }
    };

    const validationForm = event => {
        event.preventDefault();

        // update error class for input
        const highlightErrorField = target => {
            target.className = 'wrong no-clear-focus';
            setTimeout(() => target.className = 'no-clear-focus', 4500);
        };

        const {payment} = App.User.get();
        const {min, max} = findMinMax(payment);

        if (type === 'deposit') {
            const amountInput = event.target[0];
            const amount = amountInput.value;
            switch (true) {
                case amount < 1:
                    App.createPopupMessage(App.language.wrongAmountData);
                    highlightErrorField(amountInput);
                    break;
                case amount < min || amount > max:
                    App.createPopupMessage(App.language.wrongAmountData);
                    highlightErrorField(amountInput);
                    break;
                default:
                    App.Modal.showConfirmationPayment({amount}, type);
                    break;
            }
        } else {
            const amountInput = event.target[1];
            const amount = amountInput.value;
            const cardNumberInput = event.target[0];
            const cardNumber = event.target[0].value;
            const validCardNumber = cardNumberInput.value.replace(/\s/g, '');

            switch (true) {
                case validCardNumber.length < 13 || validCardNumber.length > 16 || !App.User.luhnAlgorithm(validCardNumber):
                    App.createPopupMessage(App.language.wrongCreditCard);
                    highlightErrorField(cardNumberInput);
                    break;
                case amount < min || amount > max:
                    App.createPopupMessage(App.language.wrongAmountData);
                    highlightErrorField(amountInput);
                    break;
                case amount > App.Money.getCents() / 100:
                    App.createPopupMessage(App.language.lowBalance);
                    highlightErrorField(amountInput);
                    break;
                default:
                    App.Modal.showConfirmationPayment({amount, cardNumber}, type);
                    break;
            }
        }
    };

    const findMinMax = payment => {
        let min = null, max = null;
        payment.method.currency.forEach(obj => {
            if (obj.alias === App.User.get().payment.currency.alias) {
                min = obj.min;
                max = obj.max;
            }
        });
        return {min, max};
    };

    const hasCurrency = currencies => currencies.some(obj => obj.alias === App.User.get().payment.currency.alias);

    return (
        <Steps key={type} steps={[
            {
                title: lang.currencySelect,
                layout: updateStep =>
                    <div className='select-currency'>
                        <header>{lang.enterCurrency}</header>
                        <main>
                            {App.User.payments[type]?.map(obj => obj.currency.map((currency, index) => {
                                if (!App.User.uniqueCurrencies.has(currency.alias)) {
                                    App.User.uniqueCurrencies.add(currency.alias);
                                    return <div margin-left='auto'>
                                        <button
                                            className='blue-button'
                                            key={index}
                                            onClick={() => {
                                                App.User.get().payment.type = type;
                                                App.User.get().payment.currency = currency;
                                                updateStep(1);
                                            }}
                                        >{currency.alias}</button>
                                    </div>;
                                }
                            })) ?? lang.urlNotFound}
                        </main>
                    </div>
            }, {
                title: lang.paymentSelect,
                layout: updateStep =>

                    <div className='select-payment'>
                        <header>{lang[type === 'deposit' ? 'enterDepositWay' : 'enterWithdrawalWay']} ({App.User.get().payment.currency.alias})</header>
                        <main>
                            <div className='column'>
                                {App.User.payments[type].map((method, index) => {
                                    return hasCurrency(method.currency) && <button
                                        key={index}
                                        className='payment-btn'
                                        onClick={() => {
                                            App.User.get().payment.method = method;
                                            updateStep(1);
                                        }}
                                    >
                                        <img
                                            src={App.getUrl(`/img/deposit-withdraw/${method.name.split('/').join('')}.jpg`)}
                                            alt=''/>
                                    </button>;
                                })}
                                <button
                                    key={4}
                                    className='payment-btn'
                                    onClick={() => {
                                        window.open('viber://chat?number=%2B380960585127');
                                    }}>
                                    <img
                                        src={App.getUrl(`/img/deposit-withdraw/cashier.jpg`)}
                                        alt=''/>
                                </button>
                            </div>
                        </main>
                    </div>
            }, {
                title: lang[type === 'deposit' ? 'amountDepositSelect' : 'payoutAmount'],
                layout: () => {
                    const {payment} = App.User.get();

                    const {min, max} = findMinMax(payment);

                    return <div className='select-amount'>
                        <form onSubmit={validationForm}>
                            <header>
                                <h1>{lang[type === 'deposit' ? 'enterDepositAmount' : 'enterPayoutAmount']}</h1>
                                <img className='selected-payment'
                                    src={App.getUrl(`/img/deposit-withdraw/${payment.method.name.split('/').join('')}.jpg`)}
                                    alt=''
                                    draggable={false}/>
                            </header>
                            <main className={`navigation-container ${type}`}>
                                {type === 'deposit' ?
                                    <div className='amount-data'>
                                        <input
                                            className='no-clear-focus ' name='deposit'
                                            type='text'
                                            maxLength={7}
                                            onChange={inputValidation}/>
                                        <div className='depositAmount'>
                                            {`min: ${min} - max: ${max}`}
                                        </div>
                                        <span className='currency'>{payment.currency.alias}</span>
                                    </div> :
                                    <>
                                        <div className='card-number'>
                                            <label htmlFor='cardNumber'>{lang.enterCardNumber}</label>
                                            <input
                                                type='text'
                                                name='cardNumber' className='no-clear-focus'
                                                maxLength={19}
                                                onChange={inputValidation}/>
                                        </div>
                                        <div className='payout-amount'>
                                            <label htmlFor='amount'>{lang.payoutAmount}</label>
                                            <div className='amount-data'>
                                                <input
                                                    type='text'
                                                    name='amount' className='no-clear-focus'
                                                    min={min}
                                                    max={max}
                                                    maxLength={7} onChange={inputValidation}/>
                                                <div className='payout-amount-data-range'>
                                                    {`min: ${min} - max: ${max}`}
                                                </div>
                                                <span className='currency'>{payment.currency.alias}</span>
                                            </div>
                                        </div>

                                        <div className='time'>
                                            <div className='label'>{lang.time}:</div>
                                            <div>{lang.period}</div>
                                        </div>
                                    </>
                                }
                                <button className='green-button confirm-payment' type='submit' disabled={serverRequest}>
                                    {lang.confirm}
                                </button>
                            </main>
                            {type === 'withdraw' && <footer>
                            </footer>}
                        </form>
                    </div>;
                }
            }
        ]}/>
    );
}
