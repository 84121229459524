import App from './../../../index';
import GameDeluxe5x4 from './../../deluxe/gameDeluxe5x4';
import Lines40 from './../../deluxe/lines40';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';

export default class TikiIsland extends GameDeluxe5x4 {
    constructor() {
        super();
        this.id = 'tiki-island';
        this.name = 'Tiki Island';
        this.transparentBackground = true;
        this.scatter = 10;
        this.buttonsPanelShadow = 'mid';

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 0, 4, 20, 100]},   // 0 - 9
            {regularDelay: 100, payment: [0, 0, 0, 4, 20, 100]},   // 1 - 10
            {regularDelay: 100, payment: [0, 0, 0, 4, 20, 100]},   // 2 - J
            {regularDelay: 100, payment: [0, 0, 0, 4, 20, 100]},   // 3 - Q
            {regularDelay: 100, payment: [0, 0, 0, 8, 40, 160]},   // 4 - K
            {regularDelay: 100, payment: [0, 0, 0, 8, 40, 160]},   // 5 - A
            {regularDelay: 100, payment: [0, 0, 0, 20, 60, 240]},  // 6 - drums
            {regularDelay: 100, payment: [0, 0, 0, 20, 60, 240]},  // 7 - guitar
            {regularDelay: 100, payment: [0, 0, 0, 20, 80, 400]},  // 8 - blond lady
            {regularDelay: 100, payment: [0, 0, 0, 20, 80, 400]},  // 9 - brunette lady
            {regularDelay: 100, payment: [0, 0, 0, 4, 20, 400]},   // 10 - cocktail (scatter)
            {regularDelay: 100, payment: [0, 0, 0, 40, 200, 2000]} // 11 - statuette
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: `area/${App.System.resolution}/main.png`,
                background: 'area/background.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            atlas: this.mergePath([
                'regularLongSymbols.json',
                'bonus/scatterSymbols.json'
            ])
        };

        this.gameSounds = {soundClass: 'deluxe'};
        this.Lines = new Lines40();
        this.Gamble = new GambleUnique(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '17pt Comic Sans MS';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#f7c354';
        ctx.shadowColor = '#000';
        ctx.shadowOffsetX = 3;
        ctx.shadowOffsetY = 3;
        ctx.shadowBlur = 5;
        ctx.strokeStyle = '#000';
        ctx.lineWidth = 2;

        switch (page) {
            case 1:
                ctx.font = ' 17pt Comic Sans MS';
                ctx.shadowOffsetX = 0;
                ctx.shadowOffsetY = 0;
                ctx.strokeStyle = '#000';
                ctx.shadowColor = '#00ff00';
                ctx.lineWidth = 5;
                ctx.fontWeight = 400;
                // statuette
                this.strokeFillText(ctx, bet * this.symbols[11].payment[5], 417, 130);
                this.strokeFillText(ctx, bet * this.symbols[11].payment[4], 417, 155);
                this.strokeFillText(ctx, bet * this.symbols[11].payment[3], 417, 180);
                // cocktail (scatter)
                this.strokeFillText(ctx, bet * nLines * this.symbols[10].payment[5], 460, 518);
                this.strokeFillText(ctx, bet * nLines * this.symbols[10].payment[4], 460, 543);
                this.strokeFillText(ctx, bet * nLines * this.symbols[10].payment[3], 460, 568);
                // blond & brunette
                this.strokeFillText(ctx, bet * this.symbols[9].payment[5], 120, 172);
                this.strokeFillText(ctx, bet * this.symbols[9].payment[4], 120, 197);
                this.strokeFillText(ctx, bet * this.symbols[9].payment[3], 120, 222);
                // guitar, drums
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], 677, 172);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], 677, 197);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 677, 222);
                //  A K
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 120, 492);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 120, 517);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 120, 542);
                //  Q J 9 10
                this.strokeFillText(ctx, bet * this.symbols[3].payment[5], 680, 492);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[4], 680, 517);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[3], 680, 542);

                ctx.font = '14pt Comic Sans MS';
                ctx.textAlign = 'center';
                ctx.shadowColor = '#000';
                ctx.lineWidth = 5;
                this.strokeFillText(ctx, '- SUBSTITUTES -', 415, 205);

                ctx.font = '30pt Comic Sans MS';
                this.strokeFillText(ctx, bet * nLines * this.symbols[11].payment[5], 417, 277);
                break;

            case 2:
                ctx.font = '20pt Comic Sans MS';
                ctx.textAlign = 'center';
                ctx.strokeStyle = '#000';
                this.strokeFillText(ctx, 'SUBSTITUTES FOR', 400, 260);
                break;

            case 3:
                this.strokeFillText(ctx, 'All prizes are for combinations of a kind. All prizes are for', 400, 260);
                this.strokeFillText(ctx, 'combinations left to right, except scatters. All prizes are on', 400, 285);
                this.strokeFillText(ctx, 'selected lines, except scatters. Scatter symbols pay at any', 400, 310);
                this.strokeFillText(ctx, 'position on screen. Highest win only paid per selected line.', 400, 335);
                this.strokeFillText(ctx, 'Scatter wins are added to line wins. All prizes shown in credits.', 400, 360);
                this.strokeFillText(ctx, 'Malfunction voids all pays and plays', 400, 385);

                ctx.font = 'italic bold 28px Comic Sans MS';
                ctx.lineWidth = 5;
                this.strokeFillText(ctx, 'RULES', 400, 200);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }
}

class GambleUnique extends GambleDeluxe {
    constructor(imageResources) {
        super(imageResources);
        this.cardPos = {x: 335, y: 180};
        this.cardsQueuePos = {x: [378, 440, 502, 564, 625, 688], y: 99};
        this.screenCoordinates = {x: 0, y: 87}; // coordinates for gambleArea
    }
}
