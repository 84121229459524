import App from './../../../index';
import GameChampionX2 from './../../champion/gameX2';
import Lines17 from './lines';
import GambleChampion from './../../champion/gamble';
import InfoScreen from '../../infoScreen';

export default class Ultra7Hot extends GameChampionX2 {
    constructor() {
        super();
        this.id = 'ultra-7-hot';
        this.name = 'Ultra 7 Hot';
        this.reels = 3;
        this.reelRows = 3; // number of rows per reel
        this.reelTop = 110; // magic number - where reel images starts
        this.reelXCoordinates = [95, 320, 545]; // magic numbers - x coordinates where reels starts
        this.symbolHeight = 110; // height of aa single symbol
        this.symbolWidth = 160; // height of aa single symbol
        this.transparentBackground = true; // use transparent symbols background
        this.symbolEffects = false;
        this.buttonsPanelShadow = 'low';

        this.symbols = [
            {regularDelay: 60, payment: [0, 0, 0, 5]},   // 0 - cherry
            {regularDelay: 60, payment: [0, 0, 0, 40]},  // 1 - BAR
            {regularDelay: 60, payment: [0, 0, 0, 40]},  // 2 - BAR BAR
            {regularDelay: 60, payment: [0, 0, 0, 40]},  // 3 - melon
            {regularDelay: 60, payment: [0, 0, 0, 40]},  // 4 - plum
            {regularDelay: 60, payment: [0, 0, 0, 60]},  // 5 - bell
            {regularDelay: 60, payment: [0, 0, 0, 200]}, // 6 - star
            {regularDelay: 60, payment: [0, 0, 0, 750]}  // 7 - '7'
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png',
                x2: 'x2.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.gameSounds = {soundClass: 'champion'};
        this.Lines = new Lines17();
        this.Gamble = new GambleUnique({gambleArea: `/game/games/${this.id}/img/gamble/gamble-area.png`});
        this.InfoScreen = new InfoScreen({pages: 1}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '18pt franklinFont';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#10b6fc';

        ctx.fillText(bet * this.symbols[7].payment[3], 450, 169); // '7'
        ctx.fillText(bet * this.symbols[6].payment[3], 450, 262); // star
        ctx.fillText(bet * this.symbols[5].payment[3], 450, 357); // bell
        ctx.fillText(bet * this.symbols[4].payment[3], 367, 499); // plum
        ctx.fillText(bet * this.symbols[3].payment[3], 367, 442); // melon
        ctx.fillText(bet * this.symbols[2].payment[3], 300, 499); // BAR BAR
        ctx.fillText(bet * this.symbols[1].payment[3], 300, 442); // BAR
        ctx.fillText(bet * this.symbols[0].payment[3], 490, 575); // cherry
    }
}

class GambleUnique extends GambleChampion {
    constructor(imageResources) {
        super(imageResources);
        this.gambleAreaSize = {w: 800, h: 414};
    }
}
