import {JL} from 'jsnlog';

import App from './../index';
import translateError from './translateError';

let connectionTimeout = null; // check lost connection (without messages)
let messageTimeout = 10000; // 10 sec

export default class Socket {
    restoreSocket = false;

    pairCommand = [];

    /**
     * Create WebSocket
     * Add events: open, close, message, error
     * @param restored - was server reconnection
     */
    init(restored = false) {
        const url = this.getUrl();
        JL().debug(`-- Init WebSocket (${url})`);
        this.webSocket = new WebSocket(url);

        /**
         * WebSocket open handler
         * Start check connection
         * Clear all errors if restored
         */
        this.webSocket.addEventListener('open', () => {
            JL().debug(`-- WebSocket opened (restored: ${restored})`);
            clearTimeout(this.connectionRestoreTimeout);
            this.checkConnection();
            App.clearErrors();
            restored && App.LocalSocket.code !== null && App.LocalSocket.connectPlayer(App.LocalSocket.code);
            restored && App.resetErrorMessage();

            if (App.Game) App.Game.webSocketRestored(); else App.Menu.webSocketRestored();
        });

        /**
         * WebSocket close handler
         * Show error message
         * Reset Game settings
         * Try to restore connection
         */
        this.webSocket.addEventListener('close', event => {
            this.webSocket = null;
            clearTimeout(connectionTimeout);
            JL().debug(`-- Web Socket close: ${event.code}`);
            this.restoreSocket = true;     // restore socket !!

            if (!App.stopRestoring) { // don't restore after {uc:'ERROR'} message
                this.connectionRestoreTimeout = setTimeout(() =>
                    this.init(true), 10000);
            }
        });

        /**
         * WebSocket message handler
         * Start check connection
         * Parse and collect response data
         *
         * we get next 9 packet after init game or menu without prompt:
         *   'CHANNEL-INITIALIZED',
         *   'INFO', 'AVAILABLE-GAMES', 'BALANCE', 'CURRENCY', 'AVAILABLE-DENOMINATIONS', 'AVAILABLE-INFO', 'TYPE-REELS', 'PLAYER-SETUP'
         *
         * + we get next 5 packets after start new game:
         *  'START-GAME',
         *  ''AVAILABLE-BETS', 'AVAILABLE-LINES', 'GAMBLE-LIMITS', 'GAME-SETUP'
         */
        this.webSocket.addEventListener('message', event => {
            this.checkConnection();
            const response = JSON.parse(event.data);
            this.checkPairCommand(response);

            if (App.stopRestoring) {      // do not restore socket start init all game
                App.stopRestoring = false;
                this.restoreSocket = false;
            }

            switch (response.uc) {
                case 'CHANNEL-INITIALIZED':
                    JL().debug('-- Channel initialized');
                    if (!this.restoreSocket) {
                        App.gameId = response.game ? response.game : App.gameId = 'menu';
                    }

                    messageTimeout = response.timeout ?? 10000;
                    App.restoreGameState = response.restore;
                    break;
                case 'CURRENCY':
                    App.Money.setCurrency(response.value);
                    App.updateState('moneyParams', {currency: App.Money.getCurrency()});
                    break;
                case 'AVAILABLE-GAMES':
                    App.parseGameData(response.games);
                    break;
                case 'AVAILABLE-DENOMINATIONS':
                    App.Money.setDenominations(response.values); // set AVAILABLE denominations
                    App.Money.setPosDenominationIndex(0); // set current denomination to 0
                    break;
                case 'AVAILABLE-ROULETTE-DENOMINATIONS':
                    App.Money.setRouletteDenominations(response.values); // set AVAILABLE denominations
                    App.Money.setPosRouletteDenominationIndex(0); // set current denomination to 0
                    break;
                case 'AVAILABLE-INFO':
                    delete response.uc;
                    delete response.info;
                    if (!this.restoreSocket) {
                        App.createJackpot(response);
                    }
                    break;
                case 'TYPE-REELS':
                    if (!this.restoreSocket && App.gameId === 'menu') {
                        App.renderMenuComponent(); // if not game restore
                    }
                    break;
                case 'PLAYER-SETUP':
                    const {minTotalBet} = response;
                    App.Money.setMinBet(minTotalBet);
                    App.Money.setPosDenominationValue(response.denomination);
                    App.updateState('moneyParams', {
                        credits: App.Money.getCredit(),
                        money: App.Money.getMoney(),
                        insurance: App.Money.getInsurance().toFixed(2)
                    });
                    break;
                case 'CONNECT-PLAYER':
                    App.LocalSocket.handleConnectPlayer(response.error);
                    break;
                case 'QUIT-PLAYER':
                    App.LocalSocket.handleQuitPlayer();
                    break;
                case 'ERROR':
                    this.showErrorAndRedirect(response.message);
                    break;
                case 'BALANCE':
                    // if it's Menu or not Prize win
                    if (!App.Game || App.Game.getState() !== 'JACKPOT_WIN') {
                        App.updateBalance(response);
                        App.Game?.checkNextRoll();
                    }
                    break;
                case 'BONUS-BALANCE':
                    // if it's Menu or not Prize win
                    if (!App.Game || App.Game.getState() !== 'JACKPOT_WIN') {
                        App.updateBonusBalance(response);
                    }
                    break;
                case 'PING':
                    this.send(JSON.stringify({uc: 'PING', id: response.id, ver: App.version}));
                    if (this.restoreSocket) {
                        this.restoreSocket = false;
                        App.restoreGameState = 'NONE';
                        // call game
                        if (App.Game) App.Game.webSocketRestored(); else App.Menu.webSocketRestored();
                        // send lost packets
                        if (this.pairCommand.length) {
                            const mes = this.pairCommand.pop();
                            this.send(mes);
                            JL().debug(`-- Send lost packets: ${mes}`);
                        }
                    }
                    App.refreshJackpots(response);
                    break;
                case 'INFO':
                    break;
                case 'START-GAME':
                    if (!this.restoreSocket) {
                        App.View.setState({currentState: 'NONE'});
                        App.createGame(App.gameId);
                    }
                    break;
                case 'AVAILABLE-BETS':
                    App.Game.gameSettings.setBets(response.values);
                    break;
                case 'AVAILABLE-LINES':
                    App.Game.gameSettings.setLineMas(response.values);
                    break;
                case 'AVAILABLE-CHIPS':
                    App.Game.Bets.setChips(response.values);
                    break;
                case 'GAME-SETUP':
                    App.Game.lastScreen = response.screen;
                    App.Game.gameSettings.setBetAndLine(response);
                    if (!this.restoreSocket) {
                        App.Game.initGame();
                    }
                    break;
                case 'ROULETTE-SETUP':
                    const {bets, limits, denomination} = response;
                    App.Game.Bets.restoreStack = bets;
                    App.Game.Bets.setLimits(limits);

                    App.Money.setPosRouletteDenominationValue(denomination);
                    App.updateState('moneyParams', {
                        credits: App.Money.getCredit(),
                        money: App.Money.getMoney(),
                        insurance: App.Money.getInsurance().toFixed(2)
                    });
                    if (!this.restoreSocket) {
                        App.Game.initGame();
                    }
                    break;
                case 'EXIT-GAME':
                    App.Modal.reset();
                    App.gameId = 'menu';
                    App.addLoaderMessage(App.language.initializingMenu);
                    if (!this.restoreSocket) {
                        App.updateState('loader', {
                            active: true,
                            fade: 'in',
                            fadeEndCallback: () => {
                                App.View.setState({currentState: 'MENU'});
                                App.destroyGame();
                            },
                            gameLogo: null
                        });
                    }
                    break;
                case 'ROLL':
                    App.Game.latestResponse = response;
                    App.Game.checkExtension(response.extension);

                    // if not restored game -> show roll
                    if (App.restoreGameState === 'NONE' && !this.restoreSocket) {
                        JL().debug(`-- Roll response - ${JSON.stringify(response)}`);
                        App.Game.sendCategoryMetrics();
                        App.Game.processReelResponse(response);
                    }
                    break;
                case 'SPIN':
                    App.Game.latestResponse = response;
                    // if not restored game -> show spin
                    if (App.restoreGameState === 'NONE' && !this.restoreSocket) {
                        JL().debug(`-- Spin response - ${JSON.stringify(response)}`);
                        App.Game.sendCategoryMetrics();
                        App.Game.processSpinResponse(response);
                    }
                    break;
                case 'TRANSFER-START':
                    if (!this.restoreSocket) {
                        JL().debug(`-- Transfer start (restored: ${App.restoreGameState === 'TRANSFER'})`);
                        App.Game.latestResponse.payment = response.win;
                        App.Game.animateCredits(response.win, true);
                    }
                    break;
                case 'TRANSFER-WIN':
                    App.Game.roundFinished();
                    break;
                case 'HALF-TRANSFER-START':
                    if (!this.restoreSocket) {
                        App.Socket.send(JSON.stringify({uc: 'HALF-TRANSFER-WIN'}));
                    }
                    break;
                case 'HALF-TRANSFER-WIN':
                    App.Game.Gamble.prizeWin = App.Game.Gamble.prizeWin - response.win;
                    App.Game.Gamble.halfTakeWin(response);
                    break;
                case 'REFRESH':
                    location.reload();
                    break;
                case 'REDIRECT':
                    location.href = response.url;
                    break;
                case 'PREVIOUS-DOUBLING':
                    if (!this.restoreSocket) {
                        JL().debug(`-- Previous doubling: ${response.cards}`);
                        App.Game.Gamble.gambleQueue = response.cards;
                        App.restoreGameState !== 'GAMBLE' && !this.restoreSocket && App.Game.Gamble.createComponent();
                    }
                    break;
                case 'DOUBLING-SEED':
                    App.Game.gambleResponse = response;
                    App.restoreGameState !== 'GAMBLE' && !this.restoreSocket && App.Game.Gamble.createComponent();
                    break;
                case 'ONE-OF-FOUR-DOUBLING':
                    App.Game.gambleResponse = response;
                    App.Game.Gamble.setOneOfFour(response);
                    App.restoreGameState !== 'GAMBLE' && !this.restoreSocket && App.Game.Gamble.showAllCard(response);
                    break;
                case 'GAMBLE-LIMITS':
                    App.Game.Gamble?.setLimit(response.limit);
                    break;
                case 'ALLOWED-DOUBLING':
                    App.Game.Gamble.stepLeft = response.stepLeft;
                    break;
                case 'MAKE-DOUBLING':
                    if (!this.restoreSocket) {
                        App.Game.Gamble.step = response.stepLeft;
                        App.Game.gambleResponse = response;
                        App.restoreGameState !== 'GAMBLE' && !this.restoreSocket && App.Game.Gamble.responseColor(response);
                    }
                    break;
                case 'MAKE-SUITE':
                    App.Game.gambleResponse = response;
                    App.restoreGameState !== 'GAMBLE' && !this.restoreSocket && App.Game.Gamble.responseColor(response);
                    break;
                case 'FEATURE-START':
                    App.Game.choosingQueue.push(response);
                    break;
                case 'BONUS-STATUS':
                    App.Game.bonusStatus = {
                        remain: response.remain,
                        total: response.total,
                        win: response.win
                    };
                    App.Game.setBonusStatusText();
                    break;
                case 'MAKE-CHOOSING':
                    App.Game.choosingResponse = response;
                    App.restoreGameState !== 'CHOOSING' && !this.restoreSocket && App.Game.showChoice(response);
                    break;
                case 'BONUS':
                    response.prize = response;
                    response.prize.ammount = response.value;
                    break;
                case 'PRIZE':
                    JL().debug(`-- Prize response: ${JSON.stringify(response)}`);
                    App.Game.prizeResponse = response;
                    App.Game.currentPrizeIndex = 0;
                    App.Game.showPrize();
                    break;
                case 'NUMBER-HISTORY':
                    JL().debug(`-- Roulette history: ${JSON.stringify(response)}`);
                    App.Game.numberHistory = response;
                    break;
                case 'NUMBER-STATISTICS':
                    App.Game.parseNumberStatistics(response);
                    App.Game.InfoScreen.active && App.Game.InfoScreen.show();
                    break;
            }
        });

        /**
         * WebSocket error handler
         */
        this.webSocket.addEventListener('error', () => {
            // App.showSocketMessage('serverNotResponded', false, 'disconnect');
        });
    }

    /**
     * Create WebSocket url for different modes
     * @returns {string}
     */
    getUrl = () => {
        const {mode, subMode, SERVER_URL, NODE_ENV, code, gameId, token} = App.configs;
        const {username} = App.User.get();
        const protocol = NODE_ENV === 'development' ? 'ws' : 'wss';
        let type = '';

        // unique type for each mode
        switch (mode) {
            case 'vlt':
                type = `backend/channel/vlt/${code}`;
                break;
            case 'login':
                type = 'casino/channel';
                break;
            case 'site':
                type = `casino/play/${gameId}/${token}`;
                break;
            case 'club':
            case 'player':
                type = `backend/channel/player/ver:${App.version}/${code}`;
                break;
            case 'demo':
                type = gameId ? `backend/channel/demo/${gameId}` : 'backend/channel/demo';
                break;
        }

        // check sub modes
        switch (subMode) {
            case 'game':
                type = gameId ? `backend/channel/demo/${gameId}` : 'backend/channel/demo';
                break;
        }

        // check demo
        switch (username) {
            case 'demo':
                type = 'backend/channel/demo';
                break;
        }

        const url = location.href.match('maxbet.tax') ? 'maxbet.tax' : SERVER_URL;
        return `${protocol}://${url}/${type}`;
    };

    /**
     * Start connection timeout
     * Close WebSocket if connection lost
     */
    checkConnection() {
        let fastReload = false;
        clearTimeout(connectionTimeout);
        connectionTimeout = setTimeout(() => {
            JL().debug('-- WebSocket not response, connection time left, closing...');

            fastReload = App.User.get().username === 'demo'; // if demo do not restore game, just reload
            this.restoreSocket = true;
            if (App.Game) {
                App.Game.restoreWebSocket(fastReload);
            } else {
                App.Menu.restoreWebSocket(fastReload);
            }
            this.webSocket.close();
        }, messageTimeout);
    }

    checkPairCommand(mes) {
        if (mes.uc !== 'PING') {
            if (this.webSocket && !this.restoreSocket) {              // to prevent pair command check on restore
                let pos = -1;
                this.pairCommand.forEach((packet, index) => {
                    packet = JSON.parse(packet);
                    if (packet.uc === mes.uc) pos = index;
                });
                if (pos !== -1) {
                    this.pairCommand.splice(pos, 1);
                }
            }
        }
    }

    /**
     * Packet sender
     */
    send(mes) {
        const response = JSON.parse(mes);
        if (response.uc !== 'PING') {
            if (this.webSocket && !this.restoreSocket) {              // to prevent send if socket is not responding
                this.pairCommand.push(mes);
                this.webSocket.send(mes);
            } else {                                                 // lost packet send to null socket
                this.pairCommand.push(mes);
                JL().debug(`-- Lost packet: ${mes}`);
            }
        } else {
            this.webSocket.send(mes); // just send PING without cheking pair command
        }
    }

    showErrorAndRedirect(message) {
        JL().error(`-- Error Message from web socket: ${message})`);
        const errObj = translateError(message);

        errObj.translated ?
            App.System.logError(errObj.msg) : // use custom logger for translated errors
            JL().fatal(errObj.msg); // log other with JSNLogger

        const map = new Map();
        map.set('Код не найден', 'not_found');
        map.set('Игрок с таким кодом уже подключен', 'already_connected');
        // Unparseable message " "
        // Command " " is not applicable in state " "
        // 404 page not found

        if (message === 'Игрок с таким кодом уже подключен' || message === 'Код не найден') {   // wait 60 sec and go to calculator
            App.stopRestoring = true;
            App.clearLoaderMessages();
            App.showSocketMessage(errObj.msg, false, map.get(message) || 'error');
            App.redirectTimer();
        } else {
            setTimeout(() => {
                JL().debug('-- On server error refresh game fluently');
                location.reload();
            }, 2000);
        }
    }
}
