import {JL} from 'jsnlog';

import App from './../../index';
import GambleDeluxe from './../deluxe/gamble';

export default class GambleEgt extends GambleDeluxe {
    constructor(imageResources) {
        super(imageResources);
        this.imageResources = {
            activeBlack: '/img/gamble/egt/black-active.png',
            activeRed: '/img/gamble/egt/red-active.png',
            inactiveBlack: '/img/gamble/egt/black-inactive.png',
            inactiveRed: '/img/gamble/egt/red-inactive.png',
            blackCard: '/img/gamble/egt/card-black.png',
            redCard: '/img/gamble/egt/card-red.png',
            smallCard: '/img/gamble/egt/card-small.png',
            clubs: '/img/gamble/egt/clubs.png',
            diamonds: '/img/gamble/egt/diamond.png',
            hearts: '/img/gamble/egt/hearts.png',
            spades: '/img/gamble/egt/spades.png',
            aceOfClubs: '/img/gamble/egt/ace-of-clubs.png',
            aceOfDiamonds: '/img/gamble/egt/ace-of-diamonds.png',
            aceOfHearts: '/img/gamble/egt/ace-of-hearts.png',
            aceOfSpades: '/img/gamble/egt/ace-of-spades.png',
            win: '/img/gamble/egt/win.png',

            ...imageResources // redefine base images from game
        };
        this.prizeWinPos = [{x: 170, y: 140}, {x: 620, y: 140}, {x: 350, y: 470}];
        this.cardsQueuePos = {x: [280, 325, 370, 415, 460], y: 160};
        this.cardPos = {x: 335, y: 250};
        this.cardPositions = [{x: 50, y: 250}, {x: 125, y: 250}, {x: 145, y: 250}, {x: 165, y: 250}, {x: 185, y: 250},
            {x: 335, y: 250}];

        this.gambleAreaSize = {w: 800, h: 600};
        this.screenCoordinates = {x: 0, y: 0};
        this.gambleButtonsSize = {
            width: '50%',
            height: 'auto',
            borderRadius: '10%',
            bottom: '45%',
            left: '8.5%',
            right: '8%'
        };
    }

    /**
     * Callback after mounted <Gamble>
     * Update buttons, start gamble area animation
     */
    componentDidMount() {
        JL().debug('-- Gamble: componentDidMount');

        App.updateButton('select', {disabled: true});
        App.updateButton('start', {disabled: true});
        App.updateButton('autoStart', {disabled: true});

        // disable novomatic gamble buttons before animation
        App.updateButton('redCard', {img: 'inactive', disabled: true, handler: null});
        App.updateButton('blackCard', {img: 'inactive', disabled: true, handler: null});

        // disable megajack gamble buttons before animation
        App.updateButton('cardIndex', {handler: null});

        this.draw(false);
    }

    setDefaultGambleButtons = () => {
        JL().debug('-- Set default gamble buttons state');
        App.View.setState(prevState => ({
            buttons: {
                ...prevState.buttons,
                // main game buttons
                start: {
                    ...prevState.buttons.start,
                    disabled: false,
                    title: 'collect',
                    handler: App.Game.finishGamble
                },
                select: {
                    ...prevState.buttons.select,
                    disabled: false,
                    title: 'black',
                    handler: this.selectBlack,
                    additionalClass: 'gamble-black'
                },
                info: {
                    ...prevState.buttons.info,
                    disabled: false,
                    title: 'red',
                    handler: this.selectRed,
                    additionalClass: 'gamble-red'
                },
                autoStart: {
                    ...prevState.buttons.autoStart,
                    disabled: true
                },
                heartsSuit: {
                    ...prevState.buttons.heartsSuit,
                    img: 'inactive'
                },
                diamondsSuit: {
                    ...prevState.buttons.diamondsSuit,
                    img: 'inactive'
                },
                spadesSuit: {
                    ...prevState.buttons.spadesSuit,
                    img: 'inactive'
                },
                clubsSuit: {
                    ...prevState.buttons.clubsSuit,
                    img: 'inactive'
                }
            }
        }));
    };

    draw = startInverting => {
        if (!startInverting) {
            this.animateGambleArea(Date.now());
        } else {
            App.Sounds.stopSound('gamble-wait');
            this.resetInverting();
            App.Game.Legends.setText('win', {text: 'win', value: this.prizeWin});
            App.Game.Legends.setStatus('selectColor');
            App.Game.Legends.showWinFeatures();
            this.setDefaultGambleButtons();
            this.completeAnimateGambleArea(startInverting);
        }
    };

    completeAnimateGambleArea = startInverting => {
        const ctx = this.ctx;
        if (App.Game.getState() !== 'GAMBLE') {
            return;
        }

        ctx.drawImage(
            App.Game.getAdditionalImage('redCard'),
            this.screenCoordinates.x + this.cardPos.x,
            this.offsetGambleQueue + this.cardPos.y
        );

        ctx.drawImage(
            App.Game.getAdditionalImage('redCard'),
            this.screenCoordinates.x + this.cardPos.x - 150,
            this.offsetGambleQueue + this.cardPos.y
        );
        ctx.drawImage(
            App.Game.getAdditionalImage('redCard'),
            this.screenCoordinates.x + this.cardPos.x - 170,
            this.offsetGambleQueue + this.cardPos.y
        );
        ctx.drawImage(
            App.Game.getAdditionalImage('redCard'),
            this.screenCoordinates.x + this.cardPos.x - 190,
            this.offsetGambleQueue + this.cardPos.y
        );

        ctx.drawImage(
            App.Game.getAdditionalImage('redCard'),
            this.screenCoordinates.x + this.cardPos.x - 210,
            this.offsetGambleQueue + this.cardPos.y
        );
        this.drawGambleTexts(ctx); // show 'gamble amount' and 'Gamble to win'

        App.Game.Legends.setText('win', {text: 'win', value: this.prizeWin});
        if (startInverting) {
            this.suitWon = null;
            this.invertGambleCard();
            App.updateButton('redCard', {img: 'inactive', disabled: false, handler: this.selectRed});
            App.updateButton('blackCard', {img: 'inactive', disabled: false, handler: this.selectBlack});
        } else if (startInverting && this.suitWon) {
            ctx.drawImage(
                App.Game.getAdditionalImage(this.suit2images[this.suitWon].card),
                this.screenCoordinates.x + this.cardPos.x,
                this.offsetGambleQueue + this.cardPos.y
            );
        }
        this.drawGambleQueue(this.offsetGambleQueue);
    };

    drawGambleTexts = ctx => {
        ctx.font = 'bold 14pt Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#fffe00';
        ctx.shadowBlur = 3;
        ctx.shadowOffsetX = 2;
        ctx.shadowOffsetY = 2;
        ctx.shadowColor = '#000';
        ctx.clearRect(0, 0, 800, 150);
        ctx.clearRect(0, 420, 800, 460);
        ctx.fillText(App.language.gambleAmount.toUpperCase(), this.prizeWinPos[0].x, this.prizeWinPos[0].y - 28);
        ctx.fillText(App.language.gambleToWin.toUpperCase(), this.prizeWinPos[1].x, this.prizeWinPos[1].y - 28);

        ctx.font = 'bold 12pt Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#ffc425';
        ctx.shadowBlur = 3;
        ctx.shadowOffsetX = 2;
        ctx.shadowOffsetY = 2;
        ctx.shadowColor = '#000';

        ctx.fillText(`${App.language.gambleAttemptsLeft.toUpperCase()}:`, this.prizeWinPos[2].x + 40, this.prizeWinPos[2].y - 28);
        ctx.fillStyle = '#30b523';
        ctx.fillText(this.stepLeft, this.screenCoordinates.x + this.prizeWinPos[2].x + 192, this.prizeWinPos[2].y - 28);
        ctx.fillStyle = '#2fb322';
        ctx.fillText(App.language.history.toUpperCase(), 390, 143);

        ctx.font = 'bold 18pt Arial';
        ctx.fillStyle = '#f97e15';
        ctx.fillText(this.prizeWin, this.screenCoordinates.x + this.prizeWinPos[0].x, this.offsetGambleQueue + this.prizeWinPos[0].y);
        ctx.fillStyle = '#fc4e00';
        ctx.fillText(this.prizeWin * 2, this.screenCoordinates.x + this.prizeWinPos[1].x, this.offsetGambleQueue + this.prizeWinPos[1].y);

        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    };

    selectRed = () => {
        JL().debug('Red color selected');
        App.updateButton('redCard', {img: 'active', disabled: true, handler: null});
        App.updateButton('blackCard', {img: 'inactive', disabled: true, handler: null});
        App.updateButton('info', {additionalClass: 'gamble-red-active'});
        this.makeDoubling('Red');
    };

    selectBlack = () => {
        JL().debug('Black color selected');
        App.updateButton('redCard', {img: 'inactive', disabled: true, handler: null});
        App.updateButton('blackCard', {img: 'active', disabled: true, handler: null});
        App.updateButton('select', {additionalClass: 'gamble-black-active'});
        this.makeDoubling('Black');
    };

    drawGambleQueue = offsetY => {
        const ctx = this.ctx;

        // draw empty cards
        for (let i = 0; i < 5; i++) {
            ctx.drawImage(
                App.Game.getAdditionalImage(this.suit2images.smallCard),
                this.screenCoordinates.x + this.cardsQueuePos.x[i],
                offsetY + this.cardsQueuePos.y
            );
        }

        this.gambleQueue.forEach((card, index) => {
            const sIndex = this.getCardSuite(card);
            ctx.drawImage(
                App.Game.getAdditionalImage(this.suit2images[sIndex].small),
                this.screenCoordinates.x + this.cardsQueuePos.x[index + 1],
                offsetY + this.cardsQueuePos.y
            );
        });
    };

    responseColor = response => {
        const ctx = this.ctx;
        const isStop = response.stop;
        const card = response.card;
        const win = response.win;
        this.stepLeft = response.stepLeft;

        this.prizeWin = response.win;
        this.suitWon = this.getCardSuite(card);

        this.drawGambleTexts(ctx);

        if (App.restoreGameState !== 'GAMBLE') {
            this.drawGambleQueue(this.offsetGambleQueue);

            // draw choose card
            ctx.drawImage(
                App.Game.getAdditionalImage(this.suit2images[this.suitWon].card),
                this.screenCoordinates.x + this.cardPos.x,
                this.offsetGambleQueue + this.cardPos.y
            );

            this.drawChooseSmallCard(ctx, card);
        }

        if (win > 0) {
            App.Sounds.playSound('gamble-win');
            ctx.drawImage(App.Game.getAdditionalImage('win'), 260, 280);

            setTimeout(() => {
                if (isStop) {
                    App.Game.finishGamble();
                } else {
                    this.gambleQueue.unshift(card); // add choose card to array begin
                    this.gambleQueue.length > 8 && this.gambleQueue.pop(); // there could be no more the 8 images in queue
                    this.animateGambleArea(Date.now());
                }
            }, 1000);

            // increase gamble amount and gamble to win
            if (App.restoreGameState !== 'GAMBLE') {
                this.currentGambleMultiplier *= 2;
            }

            // win
            this.prizeWin = response.win;
            App.Game.Legends.setText('win', {text: 'win', value: this.prizeWin});
            App.Sounds.playSound('gamble-win');
        } else {
            App.Sounds.playSound('gamble-lose');
            App.Game.Legends.setStatus('gambleEnd');
            App.Game.Legends.setText('win', {text: 'win', value: 0});
            this.prizeWin = 0;
            setTimeout(() => App.Game.finishGamble(), 2500);
        }

        this.resetInverting();
    };

    goToGamble = () => {
        App.Sounds.stopSound('x2');
        App.Sounds.playSound('start-gamble');
        clearInterval(App.Game.lineAnimateInterval);
        App.Game.hideBoxes();
        App.Game.stopAnimateSound();
        JL().debug('-- Go to Gamble');
        App.Game.setState('GAMBLE');
        App.Game.setBackground('gambleArea');
        App.Game.stopAnimateFeature();
        App.Game.sendGamblePacket();
        App.updateButton('select', {disabled: true});
        App.updateButton('start', {disabled: true});
        App.updateButton('autoStart', {disabled: true});
    };

    restoreGambleScreen(response) {
        App.Game.setState('GAMBLE');
        this.prizeWin = response.win;
        this.stepLeft = response.stepLeft;
        App.Game.Buttons.disableAllButtons();
        App.Game.setBackground('gambleArea');
        App.Game.hideBoxes();
        App.Game.hideLines();
        App.Game.Legends.setText('win', {text: 'win', value: response.win});
        App.Game.Legends.showWinFeatures();
        this.createComponent();
    }

    animateGambleArea(animationStarted, step = 0) {
        if (App.Game.getState() !== 'GAMBLE') {
            return;
        }
        const ctx = this.ctx;
        const delay = 20;

        // check next || first step
        if (Date.now() - animationStarted > delay) {
            animationStarted = Date.now(); // reset time for next step
            step++;
        }

        ctx.clearRect(0, this.cardPositions[0].y, this.cardPositions[5].x + 180, 156);
        for (let i = 4; i > -1; i--) {
            //  let i = 4;
            let x = this.cardPositions[i].x + Math.round(((this.cardPositions[i + 1].x - this.cardPositions[i].x) / 10)) * step;

            if (x > this.cardPositions[i + 1].x) x = this.cardPositions[i + 1].x;
            ctx.drawImage(
                App.Game.getAdditionalImage('redCard'), x, this.offsetGambleQueue + this.cardPos.y
            );
        }

        if (step >= 10) {
            if (App.Game.getState() === 'GAMBLE') {
                this.draw(true);
            }
            return;
        }
        this.animationFrameId = requestAnimationFrame(this.animateGambleArea.bind(this, animationStarted, step));
    }
}
