import App from './../index';

export default function keyboardPress(event) {
    const {login, profile, buttons, slider, modal} = App.View.state;
    const {start, autoStart, select, close, info, closeInfo, prevInfo, nextInfo, redCard, blackCard, cardIndex} = buttons;
    let soundChange = true;
    let handler = null;

    // GAME buttons
    if (App.Game) {
        const params = App.Game.keyboardPress(event, buttons);
        soundChange = params.soundChange;
        handler = params.handler;

        switch (event.keyCode) {
            case 13: // 'enter'
            case 32: // 'space'
            case 'b02': // 'start'
                !start.disabled && start.handler && (handler = start.handler);
                break;
            case 56: // 'digit 8'
            case 110: // 'num .'
            case 'b20': // 'auto'
                !autoStart.disabled && autoStart.handler && (handler = autoStart.handler);
                break;
        }

        // idle buttons
        if (App.Game.getState() === 'IDLE') {
            switch (event.keyCode) {
                case 40: // 'down arrow'
                case 54: // 'digit 6'
                    !info.disabled && info.handler && (handler = info.handler);
                    break;
                case 57: // 'digit 9'
                case 96: // 'num 0'
                case 'b23': // 'denom'
                    handler = () => App.Game.changeDenomMobile('increase');
                    break;
                case 53: // 'digit 5'
                case 99: // 'num 3'
                case 'b07': // 'h3'
                    handler = () => App.Game.changeLinesNumbers('increase');
                    break;
                case 100: // 'num 4'
                case 'b06': // 'h4'
                    handler = () => App.Game.changeLinesNumbers('decrease');
                    break;
                case 101: // 'num 5'
                case 'b03': // 'h5'
                    handler = () => App.Game.setMaxLines();
                    break;
                case 48: // 'digit 0'
                case 106: // 'num *'
                case 'b08': // 'max bet'
                    handler = () => App.Game.maxBet();
                    break;
                case 27: // 'escape'
                case 107: // 'num +'
                case 'b01': // 'bet'
                    handler = () => App.Game.changeBetMobile('increase');
                    break;
                case 109: // 'num -'
                case 'b22': // 'info'
                    handler = () => App.Game.InfoScreen.show();
                    break;
                case 55: // 'digit 7'
                case 111: // 'num /'
                case 'b21': // 'select'
                    !close.disabled && close.handler && (handler = close.handler);
                    break;
            }
        }

        // idle roulette buttons
        if (App.Game.getState() === 'ROULETTE_IDLE') {
            switch (event.keyCode) {
                case 54: // 'digit 6'
                case 109: // 'num -'
                case 'b22': // 'info'
                    handler = () => App.Game.InfoScreen.toggle();
                    break;
                case 55: // 'digit 7'
                case 111: // 'num /'
                case 'b21': // 'select'
                    !close.disabled && close.handler && (handler = close.handler);
                    break;
            }
        }

        // gamble waiting buttons
        if (App.Game.getState() === 'ASK_GAMBLE') {
            switch (event.keyCode) {
                case 27: // 'escape'
                case 106: // 'num *'
                case 107: // 'num +'
                case 'b01': // 'bet'
                case 'b08': // 'max bet'
                    !select.disabled && select.handler && (handler = select.handler);
                    break;
            }
        }

        // gamble buttons chose red or black (Novomatic)
        if (App.Game.getState() === 'GAMBLE') {
            switch (event.keyCode) {
                case 48: // 'digit 0'
                case 106: // 'num *'
                case 'b08': // 'max bet'
                    blackCard.handler && (handler = blackCard.handler);
                    break;
                case 27: // 'escape'
                case 107: // 'num +'
                case 'b01': // 'bet'
                    redCard.handler && (handler = redCard.handler);
                    break;
            }
        }

        // doubling chose 1 card from 4 (Igrosoft, Megajack)
        if (App.Game.getState() === 'DOUBLING') {
            switch (event.keyCode) {
                case 50: // 'digit 2'
                case 98: // 'num 2'
                case 'b05': // 'h2'
                    cardIndex.handler && (handler = () => cardIndex.handler(0));
                    break;
                case 51: // 'digit 3'
                case 99: // 'num 3'
                case 'b07': // 'h3'
                    cardIndex.handler && (handler = () => cardIndex.handler(1));
                    break;
                case 52: // 'digit 4'
                case 100: // 'num 4'
                case 'b06': // 'h4'
                    cardIndex.handler && (handler = () => cardIndex.handler(2));
                    break;
                case 53: // 'digit 5'
                case 101: // 'num 5'
                case 'b03': // 'h5'
                    cardIndex.handler && (handler = () => cardIndex.handler(3));
                    break;
            }
        }

        // info buttons
        if (App.Game.getState() === 'INFO') {
            switch (event.keyCode) {
                case 37: // 'left arrow'
                case 56: // 'digit 8'
                case 'b20': // 'auto'
                    !prevInfo.disabled && (handler = prevInfo.handler);
                    break;
                case 32: // 'space'
                case 39: // 'right arrow'
                case 'b02': // 'start'
                    !nextInfo.disabled && (handler = nextInfo.handler);
                    break;
                case 38: // 'up arrow'
                case 54: // 'digit 6'
                case 'b22': // 'info'
                    !closeInfo.disabled && (handler = closeInfo.handler);
                    break;
            }
        }
    } else {
        // MENU buttons
        switch (event.keyCode) {
            case 37: // 'left arrow'
            case 56: // 'digit 8'
            case 110: // 'num .'
            case 'b20': // 'auto'
                !Object.keys(modal).length && // don't slide if opened <Modal>
                slider.buttonLeftHandler && (handler = slider.buttonLeftHandler);
                break;
            case 13: // 'enter'
            case 32: // 'space'
            case 39: // 'right arrow'
            case 'b02': // 'start'
                !Object.keys(modal).length && // don't slide if opened <Modal>
                slider.buttonRightHandler && (handler = slider.buttonRightHandler);
                break;
            case 'b21':
                slider.activePayout ?
                    handler = () => App.LocalSocket.payout() :
                    App.LocalSocket.devices['BILL-ACCEPTOR-DEVICE'] && App.LocalSocket.code !== '' &&
                    (handler = () => App.Modal.showPayout());
                break;
        }
    }

    // general buttons
    switch (event.keyCode) {
        case 97: // 'num 1'
            // soundChange && (handler = () => App.soundClick());
            break;
        case 103: // 'num 7'
            !['login-primary', 'kiosk2'].includes(App.configs.subMode) &&
            (handler = () => location.href = 'http://*closekiosk*');
            break;
        case 'b19': // 'exit'
            const {code, connectionType} = App.LocalSocket;
            if (code !== '' && connectionType === 'QR') { // reset to demo code
                handler = () => {
                    App.LocalSocket.connectionType = 'CARD';
                    App.LocalSocket.code = '';
                    App.LocalSocket.quitPreviousPlayer('');
                };
            }
            break;
    }

    return handler;
}
