import App from './../../../index';
import GameDeluxe5x4 from './../../deluxe/gameDeluxe5x4';
import Lines50 from './../../deluxe/lines50';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';

export default class GemsMania extends GameDeluxe5x4 {
    constructor() {
        super();
        this.id = 'gems-mania';
        this.name = 'Gems Mania';
        this.transparentBackground = true;
        this.buttonsPanelShadow = 'mid';
        this.BigWin.enabled = true;

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 0, 8, 40, 100]},    // 0 - вишни
            {regularDelay: 100, payment: [0, 0, 0, 8, 40, 100]},    // 1 - лимон
            {regularDelay: 100, payment: [0, 0, 0, 8, 40, 100]},    // 2 - апельсин
            {regularDelay: 100, payment: [0, 0, 0, 8, 40, 100]},    // 3 - слива
            {regularDelay: 100, payment: [0, 0, 0, 20, 80, 200]},   // 4 - виноград
            {regularDelay: 100, payment: [0, 0, 0, 20, 80, 200]},   // 5 - арбуз
            {regularDelay: 100, payment: [0, 0, 0, 40, 100, 300]},  // 6 - бар
            {regularDelay: 100, payment: [0, 0, 4, 60, 200, 1000]}, // 7 - цифра '7'
            {regularDelay: 100, payment: [0, 0, 0, 2, 20, 500]},    // 8 - звезда
            {regularDelay: 100, payment: [0, 0, 0, 0, 0, 0]}        // 9 - WILD
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.gameSounds = {soundClass: 'prefergames'};

        this.Lines = new Lines50();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 1}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '17pt franklinFont';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#FFB300';
        ctx.shadowColor = 'black';
        ctx.shadowOffsetX = 3;
        ctx.shadowOffsetY = 3;
        ctx.shadowBlur = 5;

        switch (page) {
            case 1:
                // вишня
                ctx.fillText(bet * this.symbols[0].payment[5], 660, 498);
                ctx.fillText(bet * this.symbols[0].payment[4], 660, 526);
                ctx.fillText(bet * this.symbols[0].payment[3], 660, 552);
                // лимон
                ctx.fillText(bet * this.symbols[1].payment[5], 115, 502);
                ctx.fillText(bet * this.symbols[1].payment[4], 115, 536);
                ctx.fillText(bet * this.symbols[1].payment[3], 115, 568);
                // апельсин
                ctx.fillText(bet * this.symbols[2].payment[5], 660, 348);
                ctx.fillText(bet * this.symbols[2].payment[4], 660, 382);
                ctx.fillText(bet * this.symbols[2].payment[3], 660, 414);
                // слива
                ctx.fillText(bet * this.symbols[3].payment[5], 115, 348);
                ctx.fillText(bet * this.symbols[3].payment[4], 115, 382);
                ctx.fillText(bet * this.symbols[3].payment[3], 115, 414);
                // виноград
                ctx.fillText(bet * this.symbols[4].payment[5], 660, 193);
                ctx.fillText(bet * this.symbols[4].payment[4], 660, 227);
                ctx.fillText(bet * this.symbols[4].payment[3], 660, 259);
                // арбуз
                ctx.fillText(bet * this.symbols[5].payment[5], 115, 193);
                ctx.fillText(bet * this.symbols[5].payment[4], 115, 227);
                ctx.fillText(bet * this.symbols[5].payment[3], 115, 259);
                // бар
                ctx.fillText(bet * this.symbols[6].payment[5], 234, 65);
                ctx.fillText(bet * this.symbols[6].payment[4], 234, 99);
                ctx.fillText(bet * this.symbols[6].payment[3], 234, 131);
                // 7
                ctx.fillText(bet * this.symbols[7].payment[5], 555, 57);
                ctx.fillText(bet * this.symbols[7].payment[4], 555, 85);
                ctx.fillText(bet * this.symbols[7].payment[3], 555, 111);
                ctx.fillText(bet * this.symbols[7].payment[2], 555, 137);
                // Star
                ctx.fillText(bet * nLines * this.symbols[8].payment[5], 400, 502);
                ctx.fillText(bet * nLines * this.symbols[8].payment[4], 400, 536);
                ctx.fillText(bet * nLines * this.symbols[8].payment[3], 400, 568);
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }
}
