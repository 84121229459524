import {JL} from 'jsnlog';
import {detect} from 'detect-browser';

import App from './../index';
import getPlatform from './getPlatform';
import ipRequestConfig from './../../api/ipinfo_io.json';

export default class System {
    constructor() {
        this.os = null; // operating system
        this.browser = null; // current browser
        this.browserVersion = null;
        this.platform = getPlatform(); // current platform (desktop / mobile)
        this.resolution = this.getResolution();
        this.removeQueryString();

        this.ipRequest = false; // is needed request for ip and city
        this.ip = null;
        this.city = null;

        this.videoDevice = null; // device renderer
        this.clientInfo = {};
        this.sentClientInfo = false; // is sent info on the server
        this.broadcast = {
            connected: false,
            state: 'CONNECT',
            channel: null
        };

        this.previousWebPageURL = ''; // URL of redirected page
        this.oldSystemLinks = ['http://maxbet.tax', 'http://shprot.ongaso.net']; // array of links for enable come back redirect

        this.statistics = {
            time: 0, frame: 0, fps: 0, averageFps: 0,
            currentSpinNumber: 0, rolls: [],
            timeDeltaMax: 0, timeDeltaMin: 0, timeDeltaAvg: 0
        };
    }

    /**
     * Try to register Service Worker
     */
    createServiceWorker = () => {
        'serviceWorker' in navigator && // check available
        navigator.serviceWorker.register('/ServiceWorker.js')
            .then(() => JL().debug('Service Worker successfully registered'))
            .catch(error => JL().debug(`Service Worker reg failed - ${error}`));
    };

    /**
     * Create BroadcastChannel and add message handler
     * Check browser support
     */
    initBroadcastChannel() {
        if (window.BroadcastChannel) {
            JL().debug('Init broadcast channel Game App');
            const channel = new BroadcastChannel('info');

            // bind message handler
            channel.onmessage = message => {
                const response = message.data;
                JL().debug(`BroadcastChannel response: ${JSON.stringify(response)}`);

                switch (response.uc) {
                    case 'CONNECT-INFO':
                        this.broadcast.connected = true;
                        channel.postMessage(this.broadcast.state);
                        break;
                    case 'INFO':
                        App.Game.InfoScreen.currentPage = response.currentPage;
                        break;
                }
            };

            // trigger connection
            channel.postMessage({uc: 'CONNECT'});

            this.broadcast.channel = channel;
        } else {
            JL().debug(`BroadcastChannel isn't supported`);
        }
    }

    /**
     * Send message to broadcast channel
     * Updating /info/ mode
     * @param message
     */
    updateInfo(message) {
        if (App.configs.mode !== 'info') {
            this.broadcast.state = message;
            this.broadcast.channel && this.broadcast.channel.postMessage(message);
        }
    }

    /**
     * Check installed application as PWA
     * @returns {boolean}
     */
    isPWAInstalled = () => window.matchMedia('(display-mode: fullscreen)').matches;

    /**
     * Send handler to installPWA button && update content in modal component
     */
    updateInstallButton() {
        switch (true) {
            case this.os === 'iOS' && this.browser === 'ios':
                App.updateButton('installPWA', {handler: App.Modal.showIOSInstruction});
                break;
        }
    }

    /**
     * Take main system configurations
     * Send metrics
     */
    getSystemInfo() {
        this.sendMetric({param: `resolution.${this.resolution}`});
        this.sendMetric({param: `platform.${this.platform}`});

        const browser = detect();
        if (browser) {
            const {os, name, version} = browser;
            this.os = os?.replace(' ', '').replace(/[.]/gi, '-');
            this.browser = name;
            this.browserVersion = version;
            os === 'Mac OS' && name === 'safari' && this.updateIPadStyles(); // detect iPad
            this.sendMetric({param: `os.${this.os}`});
            this.sendMetric({param: `browser.${name}-${version.replace(/[.]/gi, '-')}`});
            this.sendMetric({param: `screen-size.${this.platform}.${screen.width}x${screen.height}`});
            JL().debug(`System info - OS: ${os}, browser: ${name} v.${version}, platform: ${this.platform}, resolution: ${this.resolution}`);
        }
    }

    /**
     * Check current screen ratio
     * Use only 16x9 for mobile version
     * @returns {string}
     */
    getResolution = () =>
        screen.width / screen.height > (App.configs.doubleScreen ? 0.75 : 1.5) ||
        this.platform === 'mobile' ?
            '16x9' : '4x3';

    /**
     * Update <View> state with new orientation
     */
    updateOrientation = () => App.View.setState({orientation: App.Wrapper.getOrientation()});

    /**
     * GET client ip address
     */
    getIp() {
        const {URL} = ipRequestConfig;

        this.ip = App.Cookie.get('ip');
        this.city = App.Cookie.get('city');
        this.country = App.Cookie.get('country');

        const logLocation = () =>
            JL().debug(`Current location - ${this.country}, ${this.city}, ${this.ip} (from cookie: ${!this.ipRequest})`);

        // get from remote server
        const requestLocation = async url => {
            try {
                const response = await fetch(url);
                const {ip, city, country} = await response.json();
                this.ip = ip;
                this.city = city;
                this.country = country;
                App.Cookie.set('ip', ip);
                App.Cookie.set('city', city);
                App.Cookie.set('country', country);
                logLocation();
            } catch (error) {
                JL().debug(`IP not detected, ${url} - not response`);
                this.ip = 'not detected';
                this.city = 'not detected';
            }
        };

        // check cookie, is data exist
        this.ipRequest = [this.ip, this.city, this.country].some(item => item === 'undefined' || item === '0');
        !this.ipRequest ?
            logLocation() :
            requestLocation(`${URL}`);
    }

    /**
     * Check current renderer by support WebGL
     * @param renderer - video device
     * @returns {boolean} - is force canvas
     */
    verifyWebGLSupport = (renderer = this.videoDevice) => ([
        'SwiftShader', 'noWEBGL',
        'Q33', 'Q35', 'Q43', 'Q45',
        'G33', 'G35', 'G41', 'G43', 'G45',
        '915G', '945G',
        'RS480', '780V', '780G', 'Radeon 3100'
    ].some(canvasRenderer =>
        renderer.includes(canvasRenderer)));

    /**
     * Create test canvas for getting device
     */
    getVideoDevice() {
        try {
            const canvas = document.createElement('canvas');
            const gl = canvas.getContext('experimental-webgl');
            const dbgRenderInfo = gl.getExtension('WEBGL_debug_renderer_info');
            this.videoDevice = gl.getParameter(dbgRenderInfo.UNMASKED_RENDERER_WEBGL);
            canvas.remove();
        } catch (err) {
            this.videoDevice = 'noWEBGL';
        }
        JL().debug(`Current renderer - ${this.videoDevice}`);
        this.sendMetric({param: `videoDevice.${this.videoDevice.replace(/\s+/gi, '')}`});
    }

    /**
     * Save statistic for each roll
     * Send data after third roll
     */
    collectFps() {
        this.statistics.currentSpinNumber++;

        this.statistics.rolls.push({
            gameName: App.Game.name,
            fps: this.statistics.fps,
            min: this.statistics.timeDeltaMin,
            max: this.statistics.timeDeltaMax,
            avg: this.statistics.timeDeltaAvg
        });

        if (!this.sentClientInfo && this.statistics.currentSpinNumber === 3) {
            let fps = 0;
            this.statistics.rolls.forEach(stat => fps += stat.fps);
            this.statistics.averageFps = fps / 3;
            this.sendClientInfo(`${window.location.origin}/api/client-log`);
        }
    }

    /**
     * Save statistic of current roll
     */
    collectRollStatistics(time, frame, deltaTime) {
        this.statistics.time = time;
        this.statistics.frame = frame;
        this.statistics.timeDeltaMax = Math.max(this.statistics.timeDeltaMax, deltaTime);
        this.statistics.timeDeltaMin = Math.min(this.statistics.timeDeltaMin || 100, deltaTime);
        this.statistics.timeDeltaAvg = time / frame || 0;

        this.statistics.fps = Math.round(frame / (time * 0.001));
    }

    resetRollStatistics() {
        this.statistics.fps = 0;
        this.statistics.timeDeltaMax = 0;
        this.statistics.timeDeltaMin = 100;
        this.statistics.timeDeltaAvg = 0;
    }

    /**
     * Take and send client log.
     */
    async sendClientInfo(url) {
        const requestType = this.ipRequest ? 'outerRequest' : 'cookie';
        let logMessage = `|${this.ip}|` +
            `${this.city}|` +
            `${this.previousWebPageURL ? 'redirect' : 'direct'}|` +
            `${requestType}|` +
            `${App.configs.code}|` +
            `${App.configs.subMode}|` +
            `${window.screen.width}x${window.screen.height}|` +
            `${this.statistics.averageFps.toFixed(0)}fps|` +
            `${this.videoDevice}|` +
            `${this.os}|` +
            `${this.browser} ${this.browserVersion}|` +
            `${this.platform}|`;

        this.statistics.rolls.forEach(rollData =>
            logMessage += `${rollData.min}ms|${rollData.avg.toFixed(2)}ms|${rollData.max}ms|`);
        this.statistics.rolls.forEach(rollData => logMessage += `${rollData.gameName}|`);

        this.clientInfo = {
            logMessage, // for log inserting
            ip: this.ip,
            city: this.city,
            redirect: this.previousWebPageURL,
            requestType,
            code: App.configs.code,
            mode: App.configs.mode,
            subMode: App.configs.mode,
            resolution: `${screen.width}x${screen.height}`,
            fps: +this.statistics.averageFps.toFixed(0),
            renderer: this.videoDevice,
            os: this.os,
            browser: this.browser,
            browserVersion: this.browserVersion,
            platform: this.platform,
            gameName: App.Game.name,
            rolls: this.statistics.rolls
        };

        try {
            await fetch(url, {
                method: 'post',
                headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
                body: JSON.stringify(this.clientInfo)
            });
            JL().debug(`Send client log success - ${JSON.stringify(this.clientInfo)}`);
        } catch (error) {
            JL().debug(`Send client log failed - ${error}`);
        }
        this.sentClientInfo = true;
    }

    /**
     * Send Graphite metrics (only in production mode)
     * @param param
     * @param value
     * @param method
     */
    sendMetric = ({param, value = 1, method = 'add'}) => {
        const send = async () => {
            try {
                const graphiteParams = `${param}:${value}:${method}`;
                await fetch(`/api/graphite/${graphiteParams}`);
                JL().debug(`Send metric success - ${graphiteParams}`);
            } catch (error) {
                JL().debug(`Send metric failed - ${error}`);
            }
        };

        send();
    };

    /**
     * Custom logging
     * @param message
     */
    async logError(message) {
        try {
            const {code} = App.configs;
            await fetch('/api/logger', {
                method: 'post',
                headers: {
                    'Accept': 'application/json', 'Content-Type': 'application/json',
                    'Info': JSON.stringify({ip: this.ip, city: this.city, code})
                },
                body: JSON.stringify({message})
            });
            JL().debug(`Send error log success - ${message}`);
        } catch (error) {
            JL().debug(`Send error log failed - ${error}`);
        }
    }

    /**
     * Check document.referrer
     * Create URL if exist
     */
    getPreviousWebPage() {
        if (document.referrer) {
            JL().debug(`Redirected from - ${document.referrer}`);
            this.previousWebPageURL = new URL(document.referrer);
            this.sendMetric({param: 'transitions.redirect'});
        }
    }

    /**
     * Get location to other system
     * Check production/stage mode
     */
    getOldSystemLink() {
        const linkIndex = App.configs.NODE_ENV === 'production' ? 0 : 1;
        const newOrigin = this.oldSystemLinks[linkIndex];

        return location.href
            .replace(location.origin, newOrigin)
            .replace('/player/', '/player/paysys-game/')
            .replace('.game', '');
    }

    /**
     * Remove query params from site url
     */
    removeQueryString() {
        const uri = window.location.toString();
        if (uri.indexOf('?') > 0) {
            const cleanUri = uri.substring(0, uri.indexOf('?'));
            window.history.replaceState({}, document.title, cleanUri);
        }
    }

    /**
     * Reset styles for iPad to 'mobile' platform (detects as 'desktop')
     */
    updateIPadStyles() {
        this.platform = 'mobile';
        this.resolution = '16x9';
        App.updateState('swipeUp', {canceled: true});
    }
}
