import GambleDeluxe from './../../deluxe/gamble';
import App from './../../../index';

export default class Gamble extends GambleDeluxe {
    constructor(imageResources) {
        super(imageResources);
        this.gambleButtonsSize.borderRadius = '0';
        this.screenCoordinates = {x: 0, y: 0};
        this.gambleAreaSize = {w: 960, h: 720};
        this.cardPos = {x: 400, y: 300};
        this.cardsQueuePos =
            {
                x: [200, 280, 380, 520, 620, 710],
                y: [220, 155, 125, 125, 165, 220]
            };
        this.prizeWinPos = [{x: 415, y: 560}, {x: 450, y: 560}]; // text positions for prizeWin texts, current and doubled
    }

    drawGambleTexts = ctx => {
        const font = 'NeonFont';

        ctx.font = `bold 18pt ${font}`;
        ctx.textAlign = 'right';
        ctx.fillStyle = '#ccfdff';
        ctx.shadowBlur = 3;
        ctx.shadowOffsetX = 2;
        ctx.shadowOffsetY = 2;
        ctx.shadowColor = '#000';
        ctx.fillText(`${App.language.gambleAmount.toUpperCase()}   ${this.prizeWin}`, this.prizeWinPos[0].x, this.prizeWinPos[0].y);

        ctx.textAlign = 'left';
        ctx.fillText(`${this.prizeWin * 2}   ${App.language.gambleToWin.toUpperCase()}`, this.prizeWinPos[1].x, this.prizeWinPos[1].y);

        ctx.textAlign = 'center';
        ctx.fillText(App.language.previousCards.toUpperCase(), 480, 255);

        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    };

    gambleButtonsSize = {
        width: '12em',
        height: '12em',
        borderRadius: '0',
        bottom: '15em',
        left: '10em',
        right: '10em'
    };

    completeAnimateGambleArea = startInverting => {
        const ctx = this.ctx;
        ctx.clearRect(0, 0, App.Game.gameWidth, App.Game.gameHeight);

        this.imageResources.gambleArea &&
        ctx.drawImage(App.Game.getAdditionalImage('gambleArea'), this.screenCoordinates.x, 0); // draw Gamble Area
        this.drawGambleTexts(ctx); // show 'gamble amount' and 'Gamble to win'

        App.Game.Legends.setText('win', {text: 'win', value: this.prizeWin});
        this.resetInverting();
        if (startInverting) {
            this.suitWon = null;
            this.invertGambleCard();
            App.updateButton('redCard', {img: 'inactive', disabled: false, handler: this.selectRed});
            App.updateButton('blackCard', {img: 'inactive', disabled: false, handler: this.selectBlack});
        } else if (startInverting && this.suitWon) {
            ctx.drawImage(
                App.Game.getAdditionalImage(this.suit2images[this.suitWon].card),
                this.screenCoordinates.x + this.cardPos.x,
                this.offsetGambleQueue + this.cardPos.y
            );
        }
        this.drawGambleQueue(this.offsetGambleQueue);
    };

    invertGambleCard = (animationStarted = Date.now()) => {
        const ctx = this.ctx;
        let step = Math.floor((Date.now() - animationStarted) / 50);
        const width = 260;
        const height = 246;
        ctx.drawImage(
            App.Game.getAdditionalImage('gambleArea'),
            350, 300,
            width, height - 25,
            350, 300,
            width, height - 25
        );
        step %= 55;
        const colIndex = step % 7;
        const rowIndex = Math.floor(step / 7);

        ctx.drawImage(
            App.Game.getAdditionalImage('invert'),
            colIndex * width,
            rowIndex * height,
            width, height - 25,
            350, 300,
            width, height - 25
        );
        this.cardInvertRAF = requestAnimationFrame(this.invertGambleCard.bind(this, animationStarted));
    };

    drawGambleQueue = offsetY => {
        const ctx = this.ctx;

        this.gambleQueue.forEach((card, index) => {
            const sIndex = this.getCardSuite(card);
            ctx.drawImage(
                App.Game.getAdditionalImage(this.suit2images.smallCard),
                this.screenCoordinates.x + this.cardsQueuePos.x[index] - 25,
                offsetY + this.cardsQueuePos.y[index] - 25
            );
            ctx.drawImage(
                App.Game.getAdditionalImage(this.suit2images[sIndex].small),
                this.screenCoordinates.x + this.cardsQueuePos.x[index],
                offsetY + this.cardsQueuePos.y[index], 76, 65
            );
        });
    };

    /**
     * Draw card from response
     * @param response - Socket response 'MAKE-DOUBLING'
     */
    responseColor = response => {
        const ctx = this.ctx;
        const isStop = response.stop,
            card = response.card,
            win = response.win;

        this.prizeWin = response.win;
        this.suitWon = this.getCardSuite(card);

        ctx.clearRect(0, 0, App.Game.gameWidth, App.Game.gameHeight);
        this.imageResources.gambleArea &&
        ctx.drawImage(App.Game.getAdditionalImage('gambleArea'), this.screenCoordinates.x, 0);
        this.drawGambleTexts(ctx);

        if (App.restoreGameState !== 'GAMBLE') {
            this.drawGambleQueue(this.offsetGambleQueue);
            // draw bubble (background for fish)
            ctx.drawImage(
                App.Game.getAdditionalImage('bigCard'),
                this.screenCoordinates.x + this.cardPos.x - 50,
                this.offsetGambleQueue + this.cardPos.y,
                260, 246
            );
            // draw fish
            ctx.drawImage(
                App.Game.getAdditionalImage(this.suit2images[this.suitWon].card),
                this.screenCoordinates.x + this.cardPos.x,
                this.offsetGambleQueue + this.cardPos.y + 35,
                170, 156
            );
            this.drawChooseSmallCard(ctx, card);
        }

        if (win > 0) {
            setTimeout(() => {
                if (isStop) {
                    App.Game.finishGamble();
                } else {
                    this.gambleQueue.unshift(card); // add choose card to array begin
                    this.gambleQueue.length > 5 && this.gambleQueue.pop(); // there could be no more the 5 images in queue
                    this.setDefaultGambleButtons();
                    this.draw(true);
                }
            }, 2000);

            // increase gamble amount and gamble to win
            if (App.restoreGameState !== 'GAMBLE') {
                this.currentGambleMultiplier *= 2;
            }

            // win
            this.prizeWin = response.win;
            App.Game.Legends.setText('win', {text: 'win', value: this.prizeWin});
            App.Sounds.playSound('gamble-win');
        } else {
            App.Game.Legends.setStatus('gambleEnd');
            App.Game.Legends.setText('win', {text: 'win', value: 0});
            this.prizeWin = 0;
            setTimeout(() => App.Game.finishGamble(), 2500);
        }

        this.resetInverting();
    };
}
