import React from 'react';

import './styles.less';
import App from './../../index';

export default class Steps extends React.Component {
    state = {
        step: 0
    };

    updateStep = step =>
        this.setState(prevState =>
            ({step: prevState.step + step}));

    render() {
        const {steps} = this.props;

        return (
            <div className='steps'>
                <div className='steps-progress'>
                    {steps.map((item, index) =>
                        <div className='steps-progress-item' key={index}>
                            <label htmlFor='step1'>{item.title}</label>
                            <input type='radio' checked={index <= this.state.step} readOnly/>
                        </div>)}
                </div>
                <div className='content'>
                    {steps[this.state.step].layout(this.updateStep)}
                    <div className='navigation'>
                        {this.state.step !== 0 && <button
                            className='prev'
                            style={{background: `url(${App.getUrl('/img/Menu/navigation-button.png) center center / contain no-repeat')}`}}
                            onClick={() => this.updateStep(-1)}/>}
                    </div>
                </div>
            </div>
        );
    };
}
