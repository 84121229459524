import App from './../../../index';
import GameDeluxe from './../../deluxe/game';
import Lines27 from './../../deluxe/lines27';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';
import fontPayment from './img/font/fontPayment';

export default class ThreeeDeluxe27 extends GameDeluxe {
    constructor() {
        super();
        this.id = 'threee-deluxe-27';
        this.name = 'Threee Deluxe';
        this.reelXCoordinates = [60, 291, 522];
        this.reelTop = 89;
        this.reels = 3; // number of reels
        this.reelRows = 3; // number of rows per reel
        this.symbolWidth = 216;
        this.buttonsPanelShadow = 'strong';

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 0, 40]},  // 0 - 10
            {regularDelay: 100, payment: [0, 0, 0, 40]},  // 1 - J
            {regularDelay: 100, payment: [0, 0, 0, 40]},  // 2 - Q
            {regularDelay: 100, payment: [0, 0, 0, 40]},  // 3 - K
            {regularDelay: 100, payment: [0, 0, 0, 80]},  // 4 - A
            {regularDelay: 100, payment: [0, 0, 0, 80]},  // 5 - scarab
            {regularDelay: 100, payment: [0, 0, 0, 100]}, // 6 - statue
            {regularDelay: 100, payment: [0, 0, 0, 200]}, // 7 - pharaoh
            {regularDelay: 100, payment: [0, 0, 0, 300]}  // 8 - men
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: `area/${App.System.resolution}/main.png`,
                fontPayment: fontPayment['imageResource']
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.gameSounds = {soundClass: 'deluxe'};
        this.Lines = new Lines27();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 1}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        const y = 27;

        const textProps = {
            parentContainer: ctx,
            fontImageName: 'fontPayment',
            map: fontPayment,
            align: 'center'
        };

        this.drawCustomFont(bet * this.symbols[8].payment[3], 500, 235 + y, textProps);
        this.drawCustomFont(bet * this.symbols[7].payment[3], 310, 235 + y, textProps);

        this.drawCustomFont(bet * this.symbols[4].payment[3], 200, 345 + y, textProps);
        this.drawCustomFont(bet * this.symbols[4].payment[3], 400, 345 + y, textProps);
        this.drawCustomFont(bet * this.symbols[6].payment[3], 600, 345 + y, textProps);

        this.drawCustomFont(bet * this.symbols[3].payment[3], 110, 450 + y, textProps);
        this.drawCustomFont(bet * this.symbols[3].payment[3], 300, 450 + y, textProps);
        this.drawCustomFont(bet * this.symbols[3].payment[3], 500, 450 + y, textProps);
        this.drawCustomFont(bet * this.symbols[3].payment[3], 700, 450 + y, textProps);
    }
}
