/**
 * Return object with localized message
 * @param error - message
 * @returns {Object}
 */
export default function translateError(error) {
    let translated = true;

    switch (error) {
        case 'Нет связи с сервером':
        case 'No connection to the server':
            error = 'serverNotResponded';
            break;
        case 'Игрок с таким кодом уже подключен':
            error = 'codeAlreadyConnected';
            break;
        case 'Соединение разорвано':
        case 'Connection lost':
            error = 'connectionLost';
            break;
        case 'Код не найден':
            error = 'codeNotFound';
            break;
        case 'Карточка не найдена':
            error = 'cardNotFound';
            break;
        case 'Exit to menu and reload page':
        case 'Выйдите в меню и перезагрузите страницу':
        case 'Вийдіть в меню і перезавантажте сторінку':
            error = 'exitAndReload';
            break;
        case 'Terminal code is not found or terminal is unavailable':
            error = 'terminalNotFound';
            break;
        case 'Недостаточно денег в кюпюроприемнике для выплаты':
            error = 'gotNoMoney';
            break;
        default:
            translated = false;
            break;
    }

    return {msg: error, translated};
}
