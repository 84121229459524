import App from './../../../index';
import GambleDeluxe from './../../deluxe/gamble';

export default class Gamble extends GambleDeluxe {
    constructor(imageResources) {
        super(imageResources);
        this.gambleButtonsSize.borderRadius = '0';
        this.screenCoordinates = {x: 5, y: 60};
        this.gambleAreaSize.w = 932;
        this.gambleAreaSize.h = 540;
        this.cardPos = {x: 355, y: 200};
        this.cardsQueuePos = {x: [340, 437, 524, 611, 698, 785], y: 100};
        this.prizeWinPos = [{x: 208, y: 130}, {x: 595, y: 100}];
    }

    drawGambleTexts = ctx => {
        const font = 'NeonFont';

        ctx.font = `bold 18pt ${font}`;
        ctx.textAlign = 'center';
        ctx.fillStyle = '#ccfdff';
        ctx.shadowBlur = 3;
        ctx.shadowOffsetX = 2;
        ctx.shadowOffsetY = 2;
        ctx.shadowColor = '#000';

        ctx.fillText(App.language.gambleAmount.toUpperCase(), this.prizeWinPos[0].x, this.prizeWinPos[0].y - 58);
        ctx.fillText(App.language.gambleToWin.toUpperCase(), this.prizeWinPos[1].x, this.prizeWinPos[1].y - 28);
        ctx.fillText(App.language.previousCards.toUpperCase(), 200, 155);

        ctx.fillText(this.prizeWin, this.screenCoordinates.x + this.prizeWinPos[0].x, this.offsetGambleQueue + this.prizeWinPos[0].y - 30);
        ctx.fillText(this.prizeWin * 2, this.screenCoordinates.x + this.prizeWinPos[1].x, this.offsetGambleQueue + this.prizeWinPos[1].y);
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    };

    drawGambleQueue = () => {
        const ctx = this.ctx;

        const arrayOfCards = [0, 0, 0, 0, 0, 0];
        this.gambleQueue.forEach((card, index) => arrayOfCards[index + 1] = card);

        arrayOfCards.forEach((card, index) => {
            if (card === 0) {
                ctx.drawImage(
                    App.Game.getAdditionalImage(this.suit2images.smallCard),
                    this.screenCoordinates.x + this.cardsQueuePos.x[index],
                    this.cardsQueuePos.y
                );
            } else {
                const sIndex = this.getCardSuite(card);

                ctx.drawImage(
                    App.Game.getAdditionalImage(this.suit2images[sIndex].small),
                    this.screenCoordinates.x + this.cardsQueuePos.x[index],
                    this.cardsQueuePos.y
                );
            }
        });
    };

    drawChooseSmallCard(ctx, card) {
        ctx.clearRect(this.screenCoordinates.x + this.cardsQueuePos.x[0],
            this.offsetGambleQueue + this.cardsQueuePos.y, 100, 104);

        ctx.drawImage(App.Game.getAdditionalImage('gambleArea'),
            this.screenCoordinates.x + this.cardsQueuePos.x[0],
            this.offsetGambleQueue + this.cardsQueuePos.y, 100, 104,
            this.screenCoordinates.x + this.cardsQueuePos.x[0],
            this.offsetGambleQueue + this.cardsQueuePos.y, 100, 104);

        const sIndex = this.getCardSuite(card);
        ctx.drawImage(
            App.Game.getAdditionalImage(this.suit2images[sIndex].small),
            this.screenCoordinates.x + this.cardsQueuePos.x[0],
            this.offsetGambleQueue + this.cardsQueuePos.y
        );
    }

    invertGambleCard = (animationStarted = Date.now(), odd) => {
        const timeDiff = Date.now() - animationStarted;

        if (timeDiff > this.cardInvertTimeout) {
            animationStarted = Date.now();
            odd = odd ? 0 : 1;
            App.Sounds.playSound('invert-card');
            const ctx = this.ctx;
            const image = odd ? 'redCard' : 'blackCard';
            ctx.clearRect(
                this.screenCoordinates.x + this.cardPos.x,
                this.offsetGambleQueue + this.cardPos.y,
                226, 312
            );

            ctx.drawImage(
                App.Game.getAdditionalImage('gambleArea'),
                this.screenCoordinates.x + this.cardPos.x - 5,
                this.offsetGambleQueue + this.cardPos.y,
                226, 312,
                this.screenCoordinates.x + this.cardPos.x,
                this.offsetGambleQueue + this.cardPos.y,
                226, 312
            );

            ctx.drawImage(
                App.Game.getAdditionalImage(image),
                this.screenCoordinates.x + this.cardPos.x,
                this.offsetGambleQueue + this.cardPos.y
            );
        }
        this.cardInvertRAF = requestAnimationFrame(this.invertGambleCard.bind(this, animationStarted, odd));
    };
}
