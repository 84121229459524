import React from 'react';

import App from './../../index';

export default function Info({lang}) {
    const {countries, currencies} = App.User;
    const {username = '', email, country, currency} = App.User.get();

    let {tCode = ''} = App.User.get();
    if (tCode === null || tCode === undefined) {
        tCode = '';
    }

    let {phone = ''} = App.User.get();
    if (phone === null || phone === undefined) {
        phone = '';
    }

    return (
        <div className='profile-data'>
            <div className='profile-data-group'>
                <div className='profile-data-group-field'>
                    <label htmlFor='user'>
                        {App.User.isCode(username) ? lang.codeCredentials : lang.username}
                    </label>
                    <input
                        type='text' id='username' name='user' value={username}
                        readOnly={true}/>
                    <button
                        className='inner-icon copy'
                        onClick={() => App.copyText(username)}
                        style={{backgroundImage: `url(${App.getUrl('/img/registration/copy.png')})`}}
                    ><span>{lang.copy}</span></button>
                </div>

                {App.User.isRegisteredUser() && <>
                    <div className={`profile-data-group-field ${App.User.emailWarning()}`}>
                        <label htmlFor='email'>Email</label>
                        <input type='text' name='email' value={email || ''} disabled={true}/>
                        <div
                            className='warning-icon'
                            style={{backgroundImage: `url(${App.User.emailWarning() === 'warning' ? App.getUrl('/img/registration/warning.png') : App.getUrl('/img/registration/confirmation.png')})`}}/>
                        <button
                            className='inner-icon edit' onClick={function() {
                                App.Modal.showChangeEmail(email || '');
                            }}
                            style={{backgroundImage: `url(${App.getUrl('/img/registration/edit.png')})`}}
                        ><span>{lang.changeEmail}</span></button>
                    </div>
                    {App.User.emailWarning() === 'warning' && <span className='warning-subtext'>
                        Account without email, balance and activity will be deleted in 6 months after last activity
                    </span>}

                    {App.User.emailWarning() === 'confirmation' && <span className='confirm-subtext'>
                        Confirm your Email
                    </span>}

                    <div className={`profile-data-group-field ${App.User.phoneWarning()}`}>
                        <label htmlFor='phone'>Phone</label>
                        <input type='text' name='phone' value={tCode + phone} disabled={true}/>
                        <div
                            className='warning-icon'
                            style={{backgroundImage: `url(${App.User.phoneWarning() === 'warning' ? App.getUrl('/img/registration/warning.png') : App.getUrl('/img/registration/confirmation.png')})`}}/>
                        <button
                            className='inner-icon edit' onClick={function() {
                                App.Modal.showChangePhone(phone || '', country, countries);
                            }}
                            style={{backgroundImage: `url(${App.getUrl('/img/registration/edit.png')})`}}
                        ><span>{lang.changePhone}</span></button>
                    </div>
                    {App.User.phoneWarning() === 'warning' && <span className='warning-subtext'>
                        Account without phone, balance and activity will be deleted in 6 months after last activity
                    </span>}

                    {App.User.phoneWarning() === 'confirmation' && <span className='confirm-subtext'>
                        Confirm your Phone
                    </span>}

                    <div className='profile-data-group-field country-currency'>
                        <div className='cell country'>
                            <label>{lang.country}</label>
                            <div className='outter-selection'>
                                <div className='selection'>
                                    <span className='out-selector'>
                                        <span className='plain-selector'>
                                            <select
                                                key={country}
                                                className='no-clear-focus'
                                                defaultValue={country}
                                                onChange={App.User.changeCountry}
                                            >
                                                {countries.map(({name, code}, index) =>
                                                    <option key={index} value={code}>{`${name} (${code})`}</option>)}
                                            </select>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='cell currency'>
                            <label>{lang.currency}</label>
                            <div className='outter-selection'>
                                <div className='selection'>
                                    <span className='out-selector'>
                                        <span className='plain-selector'>
                                            <select
                                                key={currency}
                                                className='no-clear-focus select-150x50'
                                                defaultValue={currency}
                                                onChange={App.User.changeCurrency}
                                            >
                                                {currencies.map(({code}, index) =>
                                                    <option key={index} value={code}>{code}</option>)}
                                            </select>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='profile-data-group-field'>
                        <label>{lang.password}</label>
                        <button className='change-password blue-button' onClick={App.Modal.showChangePassword}>
                            <span>{lang.changePassword}</span>
                        </button>
                    </div>
                </>}
            </div>
        </div>
    );
}
