import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import GameHot from './../../novomatic/gameHot';
import Lines5 from './../../novomatic/lines5';
import GambleNovomatic from './../../novomatic/gamble';
import InfoScreen from '../../infoScreen';

/* PIXI aliases */
const AnimatedSprite = PIXI.AnimatedSprite;

export default class AlwaysHot extends GameHot {
    constructor() {
        super();
        this.id = 'always-hot';
        this.name = 'Always Hot';
        this.reelTop = 92;
        this.buttonsPanelShadow = 'mid';

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 0, 40]},  // 0 - cherry
            {regularDelay: 100, payment: [0, 0, 0, 40]},  // 1 - lemon
            {regularDelay: 100, payment: [0, 0, 0, 40]},  // 2 - plum
            {regularDelay: 100, payment: [0, 0, 0, 40]},  // 3 - orange
            {regularDelay: 100, payment: [0, 0, 0, 80]},  // 4 - bell
            {regularDelay: 100, payment: [0, 0, 0, 80]},  // 5 - grape
            {regularDelay: 100, payment: [0, 0, 0, 100]}, // 6 - melon
            {regularDelay: 100, payment: [0, 0, 0, 200]}, // 7 - star
            {regularDelay: 100, payment: [0, 0, 0, 300]}  // 8 - '77'
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                logo: 'area/logo.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.gameSounds = {soundClass: 'novomatic'};
        this.Lines = new Lines5();
        this.Gamble = new GambleUnique({gambleArea: `/game/games/${this.id}/img/gamble/gamble-area.png`});
        this.InfoScreen = new InfoScreen({pages: 1}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines - current lines count
     * @param bet - current bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 28pt Arial';
        ctx.strokeStyle = '#000';
        ctx.fillStyle = '#ff9a00';
        ctx.lineWidth = 3;
        ctx.textAlign = 'center';

        this.strokeFillText(ctx, bet * this.symbols[8].payment[3], 630, 207);
        this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 630, 285);
        this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 630, 363);
        this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 405, 453);
        this.strokeFillText(ctx, bet * this.symbols[3].payment[3], 405, 535);
    }

    addWaitingAnimationSprites(parentContainer) {
        const sprite = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 5, image: 'logo',
            width: 390, height: 69
        }));
        sprite.name = 'animatedLogo';
        sprite.position.set(205, 0);
        sprite.animationSpeed = 0.1;
        sprite.play();
        parentContainer.addChild(sprite);

        this.drawPayments(parentContainer, this.paymentsContainerCoord);
    }
}

class GambleUnique extends GambleNovomatic {
    constructor(imageResources) {
        super(imageResources);
        this.screenCoordinates = {x: 0, y: 79};
        this.gambleAreaSize = {w: 800, h: 408};
    }
}
