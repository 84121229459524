import { TweenMax, TimelineMax, Power2, Elastic, Back, Bounce } from 'gsap';

export class TweenService {
    tweenTo(element, time, params) {
        return { tween: TweenMax.to(element, time, params) };
    }

    tweenFromTo(element, time, paramsFrom, paramsTo) {
        return { tween: TweenMax.fromTo(element, time, paramsFrom, paramsTo) };
    }

    killTweenOf(element) {
        TweenMax.killTweensOf(element);
    }

    delayCall(delay, func) {
        return { tween: TweenMax.delayedCall(delay, func) };
    }

    killDelayedCallsTo(func) {
        TweenMax.killDelayedCallsTo(func);
    }

    killTween(t) {
        t.tween.kill();
    }
}
