import {JL} from 'jsnlog';

import GameDeluxe from './game';
import App from './../../index';

export default class BookGame extends GameDeluxe {
    getSymbolImageType = symbolIndex =>
        [this.bonusRollSymbol, ...this.scatters].includes(symbolIndex) && this.gameFlag.bonusStarted ?
            'bonus' : 'static';

    /**
     * Function to start logic of Animate feature first step
     */
    startAnimateBonusFeature(bonusSymbol) {
        const winReels = this.getBonusWinReels();

        this.setState('SHOW_BONUS_WIN_LINES');
        this.prepareBonusFeatures(winReels, bonusSymbol);
        this.prepareBonusSymbols(winReels);
        this.startAnimationBook(winReels);
        this.latestResponse.paymentSpecialSymbol = this.latestResponse.features.reduce((sum, current) =>
            sum + current.payment, 0);

        const {features, payment, paymentSpecialSymbol} = this.latestResponse;
        JL().debug(`-- Start animate bonus feature: ${JSON.stringify(features)}`);

        // check if is there big win before after fill reels with bonus symbol
        this.BigWin.isBigWin(payment) ?
            this.BigWin.goToBigWin(paymentSpecialSymbol, features) :
            this.animateFeature(features);
    }

    finishBonusAnimation() {
        this.resetAnimationBook();
        this.bonusStatus.remain === 0 ?
            this.finishBonus() :
            this.bonusRoll();
    }

    startAnimationBook() {

    }

    resetAnimationBook() {

    }

    /**
     * Start drawing bonus animation after reels stopped
     */
    drawBonusAnimationAfterReels(bonusSymbol) {
        const {screen} = this.latestResponse;

        this.getStageChild('bonusContainer').removeChildren();
        this.showAdditionalBonusImage();
        this.prepareReelsWithAdditionalSymbol();
        this.stopAnimateFeature();

        const positions = this.getBonusSymbolFillPositions(screen);
        JL().debug(`-- Fill reels: ${JSON.stringify(positions)}`);
        this.fillReelsWithBonusSymbol(positions, bonusSymbol);
    }

    /**
     * function to return massive of symbols to change for bonus win line animation
     */
    getBonusSymbolFillPositions(screen) {
        const positions = [];

        screen.forEach((reel, reelIndex) => {
            const symbolIndex = reel.find(symbol => this.isBonusSymbol(reelIndex, symbol));

            typeof symbolIndex !== 'undefined' &&
            reel.forEach((symbol, rowIndex) => {
                // push to collection symbols for fill (except bonus symbol)
                !this.isBonusSymbol(reelIndex, symbol) && positions.push({reelIndex, rowIndex, symbolIndex});
            });
        });
        return positions;
    }

    /**
     * Prepare game behaviour after bonus 'press any button' message
     */
    startBonusAnimation = parentContainer => {
        this.clearPressAnyButton();
        this.Buttons.disableAllButtons();
        App.updateButton('start', {disabled: true});
        this.gameFlag.bonusStarted = true;
        this.drawBonusAnimation(parentContainer, this.bonusRollSymbol);
    };

    /**
     * Drawing additional bonus elements on area: Book and startBonusFrame
     */
    showAdditionalBonusImage() {
        const container = this.getStageChild('bonusContainer');
        this.drawBookTop(container);
    }

    /**
     * Draw open book at logo
     * @param parentContainer
     */
    drawBookTop(parentContainer) {

    }

    /**
     * Fill reels with bonus symbol for bonus animation.
     */
    fillReelsWithBonusSymbol(positions, bonusSymbol, animationStarted, fillStep = 0) {
        this.stopAnimateFeature();

        // check next || first step
        if (Date.now() - animationStarted > 400 || !animationStarted) {
            animationStarted = Date.now(); // reset time for next step

            if (fillStep >= positions.length) {
                this.startAnimateBonusFeature(bonusSymbol);
                this.stopWaitingAnimation();
                return;
            }
            App.Sounds.playSound('bonusWinSymbols'); // play bonus symbol fill sound
            this.setSymbolToBonusSprite(positions[fillStep], false);

            fillStep++;
        }

        this.waitingAnimationFrame = requestAnimationFrame(this.fillReelsWithBonusSymbol.bind(this, positions, bonusSymbol, animationStarted, fillStep));
    }

    setSymbolToBonusSprite(position, animate) {
        const {reelIndex, rowIndex, symbolIndex} = position;

        const symbolObj = this.reelMatrix[reelIndex][rowIndex];
        symbolObj.symbol = symbolIndex;
        symbolObj.image = 'bonus';
        this.Roll.updateSymbolSprite(symbolObj);
        animate ? symbolObj.sprite.play() : symbolObj.sprite.gotoAndStop(0);
    }

    /**
     * Prepare bonus animation before win lines, after bonus reels was filled with bonus symbol.
     */
    prepareBonusFeatures(winReels, bonusSymbol) {
        // create new features to show bonus win line like ordinar win line
        this.latestResponse.features = this.gameSettings.getCurrentLineMas().map(lineNumber => ({
            number: lineNumber - 1,
            payment: this.symbols[bonusSymbol].payment[winReels.length] * this.gameSettings.getBetLineCredit(),
            uc: 'WIN_LINE',
            reels: winReels
        }));
    }

    /**
     * Animate bonus symbols in winReels
     * @param winReels
     */
    prepareBonusSymbols(winReels) {
        winReels.forEach(reelIndex => {
            this.reelMatrix[reelIndex].forEach(symbolObj => {
                symbolObj.loop = true;
                this.Roll.updateSymbolSprite(symbolObj);
                symbolObj.sprite.play();
            });
        });
    }

    /**
     * function to show dark symbols before fill reels with bonus symbols
     */
    prepareReelsWithAdditionalSymbol() {
        this.reelMatrix.forEach((reel, reelIndex) => {
            reel.forEach(symbolObj => {
                if (!this.isBonusSymbol(reelIndex, symbolObj.symbol)) {
                    symbolObj.image = 'additional';
                    this.Roll.updateSymbolSprite(symbolObj);
                    symbolObj.sprite.gotoAndStop(0);
                }
            });
        });
    }

    /**
     * function to decide is bonus symbols win line
     */
    isBonusSymbolWin = features => features.some(({uc}) => uc === 'SPECIAL_SYMBOL');

    /**
     * Function to return win reels array
     */
    getBonusWinReels() {
        const winReels = [];

        this.latestResponse.screen.forEach((reel, reelIndex) =>
            reel.some(symbol => this.isBonusSymbol(reelIndex, symbol)) &&
            winReels.push(reelIndex));

        return winReels;
    }

    /**
     * Check is current symbol is bonusSymbol
     * @param reelIndex
     * @param symbol
     * @returns {boolean}
     */
    isBonusSymbol = (reelIndex, symbol) => symbol === this.bonusRollSymbol;
}
