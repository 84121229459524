import React from 'react';
import {JL} from 'jsnlog';

import './styles.less';
import App from './../../index';
import Logo from './../logo';

export default class KioskInfo extends React.Component {
    componentDidMount() {
        JL().debug('-- KioskInfo: componentDidMount');
        App.Info.initBroadcastChannel();
    }

    render() {
        const {state: {currentInfoState}} = this.props;
        return (
            <div className='kiosk-info'>
                <img src={App.getUrl('/img/Menu/menu-bg.png')} draggable={false} alt=''/>
                {currentInfoState === 'LOGO' && <div className='logo-container'><Logo mode={App.configs.subMode} name={App.name}/></div>}
            </div>
        );
    }
}
