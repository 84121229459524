import React from 'react';

import './styles.less';
import App from './../../index';
import Logo from './../logo';

export default function PrizeWin(props) {
    const {prize} = props;
    const prizeInfo = prize.prize[App.Game.currentPrizeIndex];

    let {amount, type} = prizeInfo;
    amount = type === 'INSURANCE' ?
        amount / 100 : amount;

    // write 'win' only if 'JACKPOT' or 'BONUS'
    let winText;

    switch (type) {
        case 'INSURANCE':
            winText = '';
            break;
        case 'GOLD':
        case 'SILVER':
        case 'BRONZE':
            winText = 'jackpot win!';
            break;
        case 'BONUS':
            winText = 'you get bonus!';
            break;
    }

    return (
        <div className={'win-container'}>
            <div className='win-container-logo'><Logo mode={App.configs.subMode} name={App.name}/></div>
            {type !== 'INSURANCE' && <div
                className={`firework ${App.System.os.replace(/\s+/gi, '')}`}
                style={{background: `url(${App.getUrl('/img/firework.png')})`, backgroundSize: '61em'}}
            />}
            <div className='win-container-text red-color red-shadow'>
                <div className={`win-container-text-${type.toLowerCase()}`}>{type}</div>
                <div className='win-container-text-win'>{winText}</div>
            </div>
            <div className='win-container-amount red-shadow'>{`${amount.toFixed(2)} ${App.Money.getCurrency()}`}</div>
        </div>
    );
}
