import React from 'react';

import './styles.less';

function clientErrors({clientErrors}) {
    return <div id='errors'>
        <button onClick={() => location.reload()}>⟲ Reload</button>
        {clientErrors.map(({message, filename, lineno, colno}, index) =>
            <div className='errorMessage' key={index}>
                <b style={{color: '#ff0000'}}>ERROR</b><br/>
                {message}<br/>
                <i><b>Source: {filename}, line: {lineno}, column: {colno}.</b></i><br/>
                {`${new Date()}`}
                {message}
            </div>
        )}
    </div>;
}

export default clientErrors;
