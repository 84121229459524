import App from './../../../index';
import GameHot from './../../novomatic/gameHot';
import Lines5 from './../../novomatic/lines5';
import GambleNovomatic from './../../novomatic/gamble';
import InfoScreen from '../../infoScreen';

export default class HotCherry extends GameHot {
    constructor() {
        super();
        this.id = 'hot-cherry';
        this.name = 'Hot Cherry';
        this.reelTop = 93;
        this.buttonsPanelShadow = 'strong';

        this.symbols = [
            {regularDelay: 500, payment: [0, 0, 0, 40]},  // 0 - cherry
            {regularDelay: 500, payment: [0, 0, 0, 40]},  // 1 - lemon
            {regularDelay: 500, payment: [0, 0, 0, 40]},  // 2 - plum
            {regularDelay: 500, payment: [0, 0, 0, 40]},  // 3 - orange
            {regularDelay: 500, payment: [0, 0, 0, 80]},  // 4 - bell
            {regularDelay: 500, payment: [0, 0, 0, 80]},  // 5 - grape
            {regularDelay: 500, payment: [0, 0, 0, 100]}, // 6 - melon
            {regularDelay: 500, payment: [0, 0, 0, 200]}, // 7 - star
            {regularDelay: 500, payment: [0, 0, 0, 400]}  // 8 - '77'
        ];

        this.imageResources = {
            main: this.mergePath({mainArea: 'area/main.png'}),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.gameSounds = {soundClass: 'novomatic'};
        this.Lines = new Lines5();
        this.Gamble = new GambleUnique({gambleArea: `/game/games/${this.id}/img/gamble/gamble-area.png`});
        this.InfoScreen = new InfoScreen({pages: 1}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines - current lines count
     * @param bet - current bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 28pt Arial';
        ctx.strokeStyle = '#000';
        ctx.fillStyle = '#ff9a00';
        ctx.lineWidth = 3;
        ctx.textAlign = 'center';

        this.strokeFillText(ctx, bet * this.symbols[8].payment[3], 630, 207);
        this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 630, 285);
        this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 630, 363);
        this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 405, 453);
        this.strokeFillText(ctx, bet * this.symbols[3].payment[3], 405, 535);
    }

    addWaitingAnimationSprites(parentContainer) {
        this.drawPayments(parentContainer, this.paymentsContainerCoord);
    }
}

class GambleUnique extends GambleNovomatic {
    constructor(imageResources) {
        super(imageResources);
        this.screenCoordinates = {x: 0, y: 79};
        this.gambleAreaSize = {w: 800, h: 408};
    }
}
