import {JL} from 'jsnlog';
import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import GambleDeluxe from './../../deluxe/gamble';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Texture = PIXI.Texture,
    Text = PIXI.Text;

export default class Gamble extends GambleDeluxe {
    constructor(imageResources) {
        super(imageResources);
        this.gambleButtonsSize = {
            ...this.gambleButtonsSize,
            bottom: '5.5em',
            width: App.System.resolution === '4x3' ? '11em' : '13em',
            right: App.System.resolution === '4x3' ? '4em' : '-3em',
            left: '32em',
            height: '6.35em',
            borderRadius: '10%'
        };

        this.suit2images = {
            up: 'up',
            down: 'down'
        };
        this.cardsQueuePos = {x: [202, 268, 338, 408, 478, 548], y: 15};
    }

    /**
     * Callback after mounted <Gamble>
     * Update buttons, start gamble area animation
     */
    componentDidMount() {
        JL().debug('-- Gamble: componentDidMount');

        App.updateButton('select', {disabled: true});
        App.updateButton('start', {disabled: true});
        App.updateButton('autoStart', {disabled: true});

        // disable novomatic gamble buttons before animation
        App.updateButton('redCard', {img: 'inactive', disabled: true, handler: null});
        App.updateButton('blackCard', {img: 'inactive', disabled: true, handler: null});

        // disable megajack gamble buttons before animation
        App.updateButton('cardIndex', {handler: null});

        App.Game.tickerTimeout(() => {
            this.setDefaultGambleButtons();
            this.draw();
            App.updateButton('redCard', {img: 'inactive', disabled: false, handler: this.selectRed});
            App.updateButton('blackCard', {img: 'inactive', disabled: false, handler: this.selectBlack});
        }, 1000);
    }

    getCardSuite = card => (card[card.length - 1] === 'H') ||
    card[card.length - 1] === 'D' ? 'down' : 'up';

    /**
     * Draw card from response
     * @param response - Socket response 'MAKE-DOUBLING'
     */
    responseColor = response => {
        const parentContainer = App.Game.getStage().getChildByName('bonusContainer').getChildByName('gambleContainer');
        const isStop = response.stop,
            card = response.card,
            win = response.win;

        this.prizeWin = response.win;
        this.suitWon = this.getCardSuite(card);
        const video = App.Game.resources[this.suitWon];
        video.loop = false;
        video.pause();

        parentContainer.getChildByName('video').texture = new Texture.from(video);
        video.play();
        video.playbackRate = 1.5;

        const endVideoActions = () => {
            !isStop && this.setDefaultGambleButtons();

            App.updateButton('redCard', {img: 'inactive', disabled: false, handler: this.selectRed});
            App.updateButton('blackCard', {img: 'inactive', disabled: false, handler: this.selectBlack});
            video.removeEventListener('ended', endVideoActions, false);
            video.removeEventListener('timeupdate', reposeReceive, false);
        };

        const reposeReceive = () => {
            if (Math.floor(video.currentTime) >= Math.floor(video.duration / 2)) {
                video.removeEventListener('timeupdate', reposeReceive);
                this.drawGambleTexts();
                this.gambleQueue.unshift(card); // add choose card to array begin
                this.gambleQueue.length > 6 && this.gambleQueue.pop(); // there could be no more the 5 images in queue
                this.drawGambleQueue();

                if (win > 0) {
                    isStop ? App.Game.tickerTimeout(() => App.Game.finishGamble(), 1000) : this.draw(true);

                    // increase gamble amount and gamble to win
                    if (App.restoreGameState !== 'GAMBLE') {
                        this.currentGambleMultiplier *= 2;
                    }

                    // win
                    this.prizeWin = response.win;
                    App.Game.Legends.setText('win', {text: 'win', value: this.prizeWin});
                    App.Sounds.playSound('gamble-win');
                } else {
                    App.Game.Legends.setStatus('gambleEnd');
                    App.Game.Legends.setText('win', {text: 'win', value: 0});
                    this.prizeWin = 0;
                    App.Game.tickerTimeout(() => App.Game.finishGamble(), 2500);
                }
                this.resetInverting();
            }
        };

        video.addEventListener('timeupdate', reposeReceive, false);
        video.addEventListener('ended', endVideoActions, false);
    };

    drawGambleQueue = () => {
        const parentContainer = App.Game.getStage().getChildByName('bonusContainer').getChildByName('gambleContainer');
        const queueHistory = parentContainer.getChildByName('queueHistory');
        const fingers = queueHistory.children;
        const lastFinger = fingers[fingers.length - 1];

        const moveFingers = (i, callback) => {
            fingers[i] && (
                App.Game.showAnimation({
                    duration: 500, animations: [
                        {
                            sprite: fingers[i],
                            timeline: [{to: {x: fingers[i].position.x + 66}}]
                        }
                    ],
                    onComplete: () => callback()
                })
            );
        };

        // when there is whole queue
        if (fingers.length === 6) {
            App.Game.showAnimation({
                duration: 500, animations: [
                    {sprite: lastFinger, timeline: [{to: {alpha: 0}}]}
                ],
                onComplete: () => {
                    for (let i = 0; i < 5; i++) {
                        moveFingers(i, () => {
                            if (i === 4) {
                                lastFinger.zIndex = 0;
                                lastFinger.position.x = this.cardsQueuePos.x[0];
                                lastFinger.texture = new Texture(App.Game.getTexture(this.getCardSuite(this.gambleQueue[0])));
                                App.Game.showAnimation({
                                    duration: 500, animations: [
                                        {sprite: lastFinger, timeline: [{to: {alpha: 1}}]}
                                    ]
                                });
                            }
                        });
                    }
                }
            });
        } else if (fingers.length === 0) {  // when we had not chosen anything yet
            const sIndex = this.getCardSuite(this.gambleQueue[0]);

            const sprite = new Sprite(App.Game.getTexture(sIndex));

            sprite.scale.set(0.5);
            sprite.zIndex = 1;
            sprite.alpha = 0;
            sprite.position.set(this.cardsQueuePos.x[0], this.cardsQueuePos.y);
            queueHistory.addChild(sprite);
            App.Game.showAnimation({
                duration: 1000, animations: [
                    {sprite: queueHistory.children[0], timeline: [{to: {alpha: 1}}]}
                ]
            });
        } else {
            for (let i = 0; i < fingers.length; i++) {
                moveFingers(i, () => {
                    if (i === fingers.length - 1) {
                        const sIndex = this.getCardSuite(this.gambleQueue[0]);
                        const newFinger = new Sprite(App.Game.getTexture(sIndex));
                        newFinger.zIndex = 0;
                        newFinger.position.set(this.cardsQueuePos.x[0], this.cardsQueuePos.y);
                        newFinger.alpha = 0;
                        newFinger.scale.set(0.5);
                        queueHistory.addChild(newFinger);
                        App.Game.showAnimation({
                            duration: 1500, animations: [
                                {
                                    sprite: newFinger,
                                    timeline: [{to: {alpha: 1}}]
                                }
                            ]
                        });
                    }
                });
            }
        }
    };

    draw = startInverting => {
        App.Sounds.stopSound('gamble-wait');
        App.Game.Legends.setText('win', {text: 'win', value: this.prizeWin});
        App.Game.Legends.setStatus('selectColor');
        App.Game.Legends.showWinFeatures();
        !startInverting && App.Game.startGambleAnimation();
    };

    drawGambleTexts = () => {
        const parentContainer = App.Game.getStage().getChildByName('bonusContainer').getChildByName('gambleContainer');
        const panel = parentContainer.getChildByName('panel');
        panel.removeChildren();
        const props = {
            align: 'center',
            fontFamily: 'gladiators',
            fontSize: 55,
            fill: 'grey',
            strokeThickness: 3,
            lineJoin: 'round'
        };
        const gambleToWin = new Text(this.prizeWin * 2, props);
        gambleToWin.name = 'gambleToWin';
        gambleToWin.position.set(366, 87);
        gambleToWin.anchor.set(0.5);
        panel.addChild(gambleToWin);

        const amount = new Text(this.prizeWin, props);
        amount.name = 'amount';
        amount.position.set(167, 87);
        amount.anchor.set(0.5);
        panel.addChild(amount);
    };

    drawChooseSmallCard() {

    }
}
