import {JL} from 'jsnlog';
import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import GameEgt from './../../egt/game';
import Lines30 from './../../egt/lines30';
import GambleEgt from './../../egt/gamble';
import InfoScreen from '../../infoScreen';
import fontBonus from './img/font/fontBonus';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Container = PIXI.Container,
    Text = PIXI.Text,
    AnimatedSprite = PIXI.AnimatedSprite;

export default class AmazonsBattle extends GameEgt {
    constructor() {
        super();
        this.id = 'amazons-battle';
        this.name = 'Amazons Battle';
        this.buttonsPanelShadow = 'mid';
        this.reelFilter = [[10], [], [], [], [10]];

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 240, y: 200},
            bonusInBonusFrame: {x: 200, y: 150},
            endBonusFrame: {x: 0, y: 0}
        };

        this.symbols = [
            {regularDelay: 50, payment: [0, 0, 0, 2, 8, 15]},           // 0 - Jack
            {regularDelay: 50, payment: [0, 0, 0, 2, 8, 15]},           // 1 - Queen
            {regularDelay: 50, payment: [0, 0, 0, 4, 10, 20]},          // 2 - King
            {regularDelay: 50, payment: [0, 0, 0, 4, 10, 20]},          // 3 - Ace
            {regularDelay: 50, payment: [0, 0, 0, 6, 15, 25]},          // 4 - Tiger
            {regularDelay: 50, payment: [0, 0, 0, 8, 15, 30]},          // 5 - Amazone Brown hair
            {regularDelay: 50, payment: [0, 0, 0, 8, 15, 40]},          // 6 - Amazone Ginger
            {regularDelay: 50, payment: [0, 0, 4, 10, 20, 45]},         // 7 - Amazone Brunette
            {regularDelay: 50, payment: [0, 0, 5, 10, 20, 50]},         // 8 - Amazone Blonde
            {regularDelay: 50, payment: [0, 0, 10, 50, 250, 1000]},     // 9 - Horse
            {regularDelay: 50, payment: [0, 0, 0, 0, 0, 0, 0, 2, 2, 2]} // 10 - Axe scatter
        ];

        this.imageResources = {
            main: this.mergePath({mainArea: 'area/main.png'}),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                minimizeSymbols: 'minimizeSymbols.png',
                gambleArea: 'area/gamble.png',
                frame1: 'bonus/frame1.png',
                frame2: 'bonus/frame2.png',
                frame: 'bonus/frame.png',
                start: 'bonus/start.png',
                bonusFont: fontBonus['imageResource']
            })
        };

        this.gameSounds = {
            soundClass: 'egt',
            sounds: [
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'bonus-game-won2', alias: 'bonusGameStart2'},
                {name: 'bonus-game-end', alias: 'bonusGameEnd'},
                {name: 'wait-start', alias: 'afterScatter', loop: true},
                {name: 'bonus-background', loop: true}
            ],
            path: `/game/games/${this.id}/audio/`
        };
        this.Lines = new Lines30(this.mergePath({boxes: 'lines/boxes.png'}));
        this.Gamble = new GambleEgt();
        this.InfoScreen = new InfoScreen({pages: 4}); // number of game info states
        this.InfoScreen.format = 'png';
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 19pt Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = 'white';
        ctx.shadowColor = 'black';
        ctx.shadowOffsetX = ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 5;

        switch (page) {
            case 1:
                ctx.font = 'bold 12pt Arial';

                // Queen, Jolly
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 580, 358);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 580, 378);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 580, 398);
                // Ace, King
                this.strokeFillText(ctx, bet * this.symbols[2].payment[5], 430, 383);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[4], 430, 404);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[3], 430, 423);
                // tiger
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 220, 358);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 220, 378);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 220, 398);
                // brown nair
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 580, 247);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 580, 267);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 580, 287);
                // Ginger
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 220, 247);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 220, 267);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 220, 287);
                // Blonde
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], 580, 122);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], 580, 142);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 580, 162);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[2], 580, 182);
                // Dark hair
                this.strokeFillText(ctx, bet * this.symbols[8].payment[5], 220, 122);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[4], 220, 142);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[3], 220, 162);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[2], 220, 182);

                // Seven 7
                this.strokeFillText(ctx, bet * this.symbols[9].payment[5], 430, 117);
                this.strokeFillText(ctx, bet * this.symbols[9].payment[4], 430, 134);
                this.strokeFillText(ctx, bet * this.symbols[9].payment[3], 430, 151);
                this.strokeFillText(ctx, bet * this.symbols[9].payment[2], 430, 168);
                // Star
                this.strokeFillText(ctx, bet * nLines * this.symbols[10].payment[7], 430, 260);
                break;
            case 3:
                ctx.font = 'bold 13pt Arial';
                ctx.textAlign = 'left';
                // gamble limit
                this.strokeFillText(ctx, (this.Gamble.limit * 100) / App.Money.getCurrentDenomination(), 480, 233);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    setScatterSprite(scatterFeature) {
        scatterFeature.positions.forEach(position => {
            const {reel, row} = position;
            const symbolObj = this.reelMatrix[reel][row];
            symbolObj.loop = false;
            this.Roll.updateSymbolSprite(symbolObj);
            symbolObj.sprite.play();
        });
    }

    getSymbolImageType = () => 'static';

    /**
     * Draw all bonus animation after bonus 'press any button'
     * @param parentContainer
     * @param bonusSymbol - current bonus symbol in extension
     */
    drawBonusAnimation(parentContainer, bonusSymbol) {
        App.Sounds.stopSound('afterScatter');
        App.Sounds.playSound('bonusGameStart2');
        this.hideBoxes();
        const image = 'frame1';
        const sprite = new Sprite(this.getTexture(image));
        sprite.name = image;
        sprite.position.set(0, 0);
        parentContainer.addChild(sprite);

        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: fontBonus,
            align: 'center',
            scale: 0.8,
            fontInterval: -3 // px between symbols
        };
        // this.bonusStatus.
        let amount = 10;
        this.latestResponse.features.forEach(feature => {
            if (feature.uc === 'FREE_ROLL') amount = feature.amount;
        });
        this.drawCustomFont(amount, 150, 350, textProps);

        setTimeout(() => {
            this.bonusRoll();
            this.showBoxes();
        }, 6000);
    }

    /**
     * Отрисовка таблички бонусной игры
     */
    showStartBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y, 'frame');
        App.Sounds.playSound('afterScatter');

        const sprite = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 2, fromFrame: 0, image: 'start',
            width: 91, height: 40, colCount: 2
        }));
        sprite.animationSpeed = 0.02;
        sprite.loop = false;
        sprite.position.set(425, 222);
        sprite.play();
        sprite.loop = true;
        parentContainer.addChild(sprite);
    }

    /**
     * Отрисовка таблички бонус в бонусе
     */
    showBonusInBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y);
    }

    /**
     * Отрисовка таблички окончания бонусной игры
     */
    showEndBonusFrame(parentContainer, {x, y}, {win, total}) {
        this.hideBoxes();
        this.showBonusFrame(parentContainer, x, y, 'frame2');
        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: fontBonus,
            align: 'center',
            scale: 1,
            fontInterval: -3 // px between symbols
        };
        this.drawCustomFont(win, 400, 350, textProps);
    }

    /**
     * End bonus game, return to regular game.
     */
    endBonus() {
        JL().debug('-- End bonus');
        this.showBoxes();
        this.setState('AFTER_BONUS');
        this.getStageChild('bonusContainer').removeChildren();
        this.afterBonusAction();
        this.stopAnimateFeature();
        this.setRegularSprite();
        this.latestResponse.payment = this.bonusStatus.win; // Double whole bonus sum
        this.latestResponse.features = [];
        this.bonusRollSymbol = null;
        this.gameFlag.bonusStarted = false;
        this.bonusStatus = null;
    }

    /**
     * Start scatter animations
     * @param features
     */
    bonusEntrance(features) {
        this.playBonusGameSound();
        this.stopAnimateFeature();
        this.drawTopAnimation(this.getStageChild('bonusContainer'));
        App.updateButton('start', {disabled: true});

        const scatterFeature = features.find(({uc}) => uc === 'SCATTER');
        this.createFeatureInfo(scatterFeature, this.getStageChild('bonusContainer'));
        this.playScatterAnimation(scatterFeature, () => // call after scatter played
            this.drawBonusAskButton(this.isFreeRoll(features) && !this.bonusStatus));
    }

    createFeatureInfo(feature, container) {
        const {number, symbol, reels, positions, payment, uc} = feature, // get current feature params
            ucReels = uc === 'WIN_LINE' ? reels.length : positions.length, // check 'SCATTER' feature

            textProps = {
                font: 'Arial',
                fontSize: 14,
                fontWeight: 600,
                fill: '#f0e7cb'
            },
            winSymbolsContainer = new Container();
        winSymbolsContainer.position.set(-100, -170);
        winSymbolsContainer.zIndex = 200;
        winSymbolsContainer.name = 'winSymbolsContainer';
        container.addChild(winSymbolsContainer);
        const statusLine = new Text(
            `${uc === 'WIN_LINE' ? App.language.line.toUpperCase() : 'SCATTERED'} ${uc === 'WIN_LINE' ? number + 1 : ''}: `,
            textProps
        );

        const statusPayment = new Text(
            `: ${payment} `,
            textProps
        );

        if (uc === 'WIN_LINE') {
            statusLine.position.set(210, 470);
            statusPayment.position.set(480, 470);

            for (let i = 0; i <= ucReels - 1; i++) {
                const minimizeSymbol = new Sprite(this.getSpriteTextures({
                    image: 'minimizeSymbols',
                    fromFrame: symbol,
                    colCount: this.symbols.length,
                    width: 22,
                    height: 19
                })[0]);

                minimizeSymbol.position.x = 450 + (22 * i);
                minimizeSymbol.position.y = 639;
                winSymbolsContainer.addChild(minimizeSymbol);
            }
        } else {
            statusLine.position.set(200, 470);
            statusPayment.position.set(500, 470);

            for (let i = 0; i <= ucReels - 1; i++) {
                const minimizeSymbol = new Sprite(this.getSpriteTextures({
                    image: 'minimizeSymbols',
                    fromFrame: symbol,
                    colCount: this.symbols.length,
                    width: 22,
                    height: 19
                })[0]);

                minimizeSymbol.scale.set(0.9);
                minimizeSymbol.position.x = 400 + (22 * i);
                minimizeSymbol.position.y = 639;
                winSymbolsContainer.addChild(minimizeSymbol);
            }
        }
        container.addChild(statusLine, statusPayment);
    }

    /**
     * Draw long scatter animation before bonus
     * @param scatterFeature
     * @param callback
     */
    playScatterAnimation(scatterFeature, callback) {
        this.setScatterSprite(scatterFeature);
        setTimeout(() => callback(), 10000);
    }
}
