import React from 'react';

import './style.less';
import App from './../../index';

export default function Statistic() {
    const {
        os, platform, browser, browserVersion, ip, videoDevice,
        statistics: {fps, timeDeltaMin, timeDeltaMax, timeDeltaAvg}
    } = App.System;

    const min = timeDeltaMin.toFixed();
    const max = timeDeltaMax.toFixed();
    const avg = timeDeltaAvg.toFixed();

    return (
        <div className='statistic'>
            <div className='statistic-system'>
                <span>System</span>
                <span>OS: {os}</span>
                <span>Platform: {platform}</span>
                <span>Browser: {browser} v.{browserVersion}</span>
                <span>Screen resolution: {screen.width}x{screen.height}</span>
            </div>
            <div className='statistic-game'>
                <span>Game</span>
                <span>Mode: {App.configs.mode}</span>
                <span>FPS: {fps.toFixed(0)} (min: {min}, avg: {avg}, max: {max})</span>
                <span>Full Screen: {(!!document.fullscreenElement).toString()}</span>
                <span><a>IP: {ip}</a></span>
            </div>
            <div className='statistic-render'>Renderer: {videoDevice.substr(0, 60)}</div>
        </div>
    );
}
