import {JL} from 'jsnlog';

import LinesEgt from './../egt/lines';

export default class Lines40 extends LinesEgt {
    constructor() {
        super();
        this.boxSettings.firstRightBox = 40;

        this.lines = {
            0: {coordinates: [1, 1, 1, 1, 1], lineDeltaY: 0, color: '#ffd8ff'},
            1: {coordinates: [2, 2, 2, 2, 2], lineDeltaY: 0, color: '#de1800'},
            2: {coordinates: [0, 0, 0, 0, 0], lineDeltaY: 0, color: '#009200'},
            3: {coordinates: [3, 3, 3, 3, 3], lineDeltaY: 0, color: '#ce8f63'},
            4: {coordinates: [1, 2, 3, 2, 1], lineDeltaY: -10, color: '#647100'},
            5: {coordinates: [2, 1, 0, 1, 2], lineDeltaY: 10, color: '#ff24ff'},
            6: {coordinates: [0, 0, 1, 2, 3], lineDeltaY: -10, color: '#ffffad'},
            7: {coordinates: [3, 3, 2, 1, 0], lineDeltaY: 10, color: '#0096bd'},
            8: {coordinates: [2, 3, 3, 3, 2], lineDeltaY: -40, color: '#7321bd'},
            9: {coordinates: [1, 0, 0, 0, 1], lineDeltaY: 40, color: '#00ffff'},
            10: {coordinates: [0, 1, 2, 3, 3], lineDeltaY: 10, color: '#6345ff'},
            11: {coordinates: [3, 2, 1, 0, 0], lineDeltaY: -10, color: '#d6fb06'},
            12: {coordinates: [2, 3, 2, 1, 2], lineDeltaY: -10, color: '#a56e00'},
            13: {coordinates: [1, 0, 1, 2, 1], lineDeltaY: 10, color: '#949294'},
            14: {coordinates: [0, 1, 0, 1, 0], lineDeltaY: -20, color: '#de2064'},
            15: {coordinates: [3, 2, 3, 2, 3], lineDeltaY: 20, color: '#007100'},
            16: {coordinates: [1, 2, 1, 0, 1], lineDeltaY: -20, color: '#adcab5'},
            17: {coordinates: [2, 1, 2, 3, 2], lineDeltaY: 20, color: '#ff6d00'},
            18: {coordinates: [0, 1, 1, 1, 0], lineDeltaY: 20, color: '#ffff00'},
            19: {coordinates: [3, 2, 2, 2, 3], lineDeltaY: -20, color: '#00cbbe'},
            20: {coordinates: [1, 1, 2, 3, 3], lineDeltaY: 20, color: '#00cbbe'},
            21: {coordinates: [2, 2, 1, 0, 0], lineDeltaY: -20, color: '#efc704'},
            22: {coordinates: [0, 1, 2, 2, 3], lineDeltaY: 30, color: '#0045ff'},
            23: {coordinates: [3, 2, 1, 1, 0], lineDeltaY: -30, color: '#ff0000'},
            24: {coordinates: [1, 2, 2, 2, 3], lineDeltaY: -30, color: '#aeaeb5'},
            25: {coordinates: [2, 1, 1, 1, 0], lineDeltaY: 30, color: '#00cb62'},
            26: {coordinates: [0, 0, 1, 0, 0], lineDeltaY: -10, color: '#d641ff'},
            27: {coordinates: [3, 3, 2, 3, 3], lineDeltaY: -40, color: '#f792b6'},
            28: {coordinates: [2, 2, 3, 2, 2], lineDeltaY: 40, color: '#ffbf04'},
            29: {coordinates: [1, 1, 0, 1, 1], lineDeltaY: -40, color: '#a535ff'},
            30: {coordinates: [0, 0, 0, 1, 2], lineDeltaY: -30, color: '#2039bd'},
            31: {coordinates: [3, 3, 3, 2, 1], lineDeltaY: 30, color: '#7bffff'},
            32: {coordinates: [2, 3, 3, 2, 1], lineDeltaY: -30, color: '#ae4e05'},
            33: {coordinates: [1, 0, 0, 1, 2], lineDeltaY: 30, color: '#ff50b7'},
            34: {coordinates: [0, 1, 1, 2, 3], lineDeltaY: 40, color: '#ffa200'},
            35: {coordinates: [3, 2, 2, 1, 0], lineDeltaY: 50, color: '#6345ff'},
            36: {coordinates: [2, 3, 2, 1, 0], lineDeltaY: -40, color: '#e6cab6'},
            37: {coordinates: [1, 0, 1, 2, 3], lineDeltaY: 50, color: '#d631bd'},
            38: {coordinates: [0, 1, 2, 3, 2], lineDeltaY: -40, color: '#ff6d63'},
            39: {coordinates: [3, 2, 1, 0, 1], lineDeltaY: 40, color: '#00e363'}
        };
        this.lineMap = [...Array(40)].map((item, i) => i);

        JL().debug('-- Lines40 initialized');
    }

    getBoxesStyle = () => ({
        activeBoxStyle: {
            fill: '#ffc253',
            fontSize: 12,
            stroke: '#0042c0',
            strokeThickness: 5,
            lineJoin: 'round'
        },
        inactiveBoxStyle: {
            dropShadowDistance: 1,
            fill: 'white',
            fontSize: 19,
            strokeThickness: 2,
            lineJoin: 'round'
        },
        colLines: [1, 10, 20, 30, 40],
        coordinates: {
            0: {x: 25, y: 408},
            1: {x: 774, y: 408}
        }
    })
}
