import App from './../../index';
import Gamble from './../Gamble';

export default class GambleDeluxe extends Gamble {
    constructor(imageResources) {
        super(imageResources);
        this.imageResources = {
            aceOfClubs: '/img/gamble/deluxe/ace-of-clubs.png',
            aceOfDiamonds: '/img/gamble/deluxe/ace-of-diamonds.png',
            aceOfHearts: '/img/gamble/deluxe/ace-of-hearts.png',
            aceOfSpades: '/img/gamble/deluxe/ace-of-spades.png',
            activeBlack: '/img/gamble/deluxe/black-active.png',
            inactiveBlack: '/img/gamble/deluxe/black-inactive.png',
            blackCard: '/img/gamble/deluxe/card-black.png',
            redCard: '/img/gamble/deluxe/card-red.png',
            smallCard: '/img/gamble/deluxe/card-small.png',
            clubs: '/img/gamble/deluxe/clubs.png',
            diamonds: '/img/gamble/deluxe/diamond.png',
            hearts: '/img/gamble/deluxe/hearts.png',
            activeRed: '/img/gamble/deluxe/red-active.png',
            inactiveRed: '/img/gamble/deluxe/red-inactive.png',
            spades: '/img/gamble/deluxe/spades.png',
            ...imageResources // redefine base images from game
        };
        this.prizeWinPos = [{x: 208, y: 80}, {x: 595, y: 80}];
        this.previousCardsTextPos = [200, 135];
    }

    drawGambleTexts = ctx => {
        ctx.font = 'bold 18pt Times New Roman';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#FFFFFF';
        ctx.shadowBlur = 3;
        ctx.shadowOffsetX = 2;
        ctx.shadowOffsetY = 2;
        ctx.shadowColor = '#000';

        ctx.fillText(App.language.gambleAmount.toUpperCase(), this.prizeWinPos[0].x, this.prizeWinPos[0].y - 28);
        ctx.fillText(App.language.gambleToWin.toUpperCase(), this.prizeWinPos[1].x, this.prizeWinPos[1].y - 28);
        ctx.fillText(App.language.previousCards.toUpperCase(), this.previousCardsTextPos[0], this.previousCardsTextPos[1]);

        ctx.fillText(this.prizeWin, this.screenCoordinates.x + this.prizeWinPos[0].x, this.offsetGambleQueue + this.prizeWinPos[0].y);
        ctx.fillText(this.prizeWin * 2, this.screenCoordinates.x + this.prizeWinPos[1].x, this.offsetGambleQueue + this.prizeWinPos[1].y);
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    };

    drawGambleQueue = offsetY => {
        const ctx = this.ctx;

        // draw empty cards
        for (let i = 0; i < 6; i++) {
            ctx.drawImage(
                App.Game.getAdditionalImage(this.suit2images.smallCard),
                this.screenCoordinates.x + this.cardsQueuePos.x[i],
                offsetY + this.cardsQueuePos.y
            );
        }

        this.gambleQueue.forEach((card, index) => {
            const sIndex = this.getCardSuite(card);
            ctx.drawImage(
                App.Game.getAdditionalImage(this.suit2images[sIndex].small),
                this.screenCoordinates.x + this.cardsQueuePos.x[index + 1],
                offsetY + this.cardsQueuePos.y
            );
        });
    };
}
