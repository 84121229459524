import App from './../../../index';
import CustomLines from './../../CustomLines';

export default class Lines10 extends CustomLines {
    constructor() {
        super();
        this.winBoxes = false;
        this.lines = {
            0: {coordinates: [1, 1, 1, 1, 1], boxes: [{x: 0, y: 0}]},
            1: {coordinates: [0, 0, 0, 0, 0], boxes: [{x: 0, y: 0}]},
            2: {coordinates: [2, 2, 2, 2, 2], boxes: [{x: 0, y: 0}]},
            3: {
                coordinates: [0, 1, 2, 1, 0], boxes: [{x: 0, y: -12}],
                points: {2: -45, 4: 45}
            },
            4: {
                coordinates: [2, 1, 0, 1, 2], boxes: [{x: 0, y: 12}],
                points: {2: 45, 4: -45}
            },
            5: {coordinates: [1, 2, 2, 2, 1], boxes: [{x: 922, y: 28}]},
            6: {coordinates: [1, 0, 0, 0, 1], boxes: [{x: 922, y: -28}]},
            7: {
                coordinates: [2, 2, 1, 0, 0], boxes: [{x: 922, y: -25}],
                points: {3: 45}
            },
            8: {
                coordinates: [0, 0, 1, 2, 2], boxes: [{x: 922, y: 25}],
                points: {3: -45}
            },
            9: {coordinates: [2, 1, 1, 1, 0], boxes: [{x: 922, y: 46}]}
        };
    }

    drawBoxes(parentContainer) {

    }

    /**
     * Create virtual <canvas> for draw lines
     * Convert canvas to sprite
     * @param lines
     * @param winReels
     * @param parentContainer
     * @param winLine
     * @param payment
     */
    drawLineImages(lines, winReels, parentContainer, winLine, payment) {
        parentContainer.removeChildren();
        this.linesCtx.clearRect(0, 0, this.linesCanvas.width, this.linesCanvas.height);

        lines.forEach(lineIndex => {
            parentContainer.sortableChildren = true;

            const line = this.lines[lineIndex];

            const spineLine = new window.PIXI.spine.Spine(App.Game.getSpineData('line'));
            spineLine.name = lineIndex;
            spineLine.position.set(59, line.boxes[0].y);
            const typeOfAnimation = winLine ? 'animation' : 'static';
            spineLine.state.setAnimation(0, typeOfAnimation, true);
            for (let i = 1; i <= 5; i++) {
                const bone = spineLine.skeleton.findBone(`LinePoint${i}`);
                bone.y = -(App.Game.reelTop + App.Game.symbolHeight * (line.coordinates[i - 1] + 0.5));
            }

            line.points && Object.keys(line.points).forEach(point => {
                const bone = spineLine.skeleton.findBone(`LinePoint${point}`);
                bone.rotation = line.points[point];
            });

            parentContainer.addChild(spineLine);
        });
    }
}
