import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../../../index';
import PowerStars from './../power-stars/game';
import Lines from './img/lines/lines10';
import InfoScreen from '../../infoScreen';
import bonusFont from './img/font/bonusFont';
import bonusFont2 from './img/font/bonusFont2';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Container = PIXI.Container,
    Text = PIXI.Text,
    AnimatedSprite = PIXI.AnimatedSprite;

export default class StarBurst extends PowerStars {
    constructor() {
        super();
        this.id = 'star-burst';
        this.name = 'Star Burst';
        this.scatter = 7;
        this.reelTop = 100;
        this.gameFieldWidth = 981;
        this.gameFieldHeight = 720;
        this.reelXCoordinates = [0, 200, 395, 591, 790];
        this.gameWidth = App.System.resolution === '4x3' ? this.gameFieldWidth : 1280; // text to check
        this.gameHeight = App.configs.doubleScreen ? 1440 : 720;
        this.symbolHeight = 163; // height of a single symbol
        this.symbolWidth = 186; // width of a single symbol
        this.buttonsPanelShadow = 'mid';
        this.transparentBackground = true; // use transparent symbols fone or not
        this.symbolEffects = false; // shadowing and none
        this.defaultFeatureDelay = 1500;
        this.allowLongRoll = true;
        this.doublingFilter = [7]; // 7 cant be doubled
        this.starsMas = [];
        this.bubbleMas = [];
        this.textFunc = {};
        this.animationFlameId = null;
        this.symbolBackground = true;
        this.muarFunct = true;
        this.rainbowFlag = false;

        this.reelSettings = [20, 6, 20];
        this.reelFilter = [[7], [], [], [], [7]];
        this.waitingType = '';

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 120, y: 90},
            bonusInBonusFrame: {x: 120, y: 150},
            endBonusFrame: {x: 120, y: 150}
        };

        this.containersLayers = {
            linesContainer: 2,
            rainbowContainer: 1,
            reelsStage: 5,
            mainContainer: 4,
            boxesContainer: 5,
            extraBetContainer: 6,
            infoContainer: 12,
            bonusContainer: 9,
            symbolInfo: 10,
            textContainer: 11
        };

        this.symbols = [
            {regularDelay: 40, payment: [0, 0, 0, 5, 10, 25]},                        // 0 - Violet cristal
            {regularDelay: 40, payment: [0, 0, 0, 5, 10, 25]},                        // 1 - Blue cristal
            {regularDelay: 40, payment: [0, 0, 0, 7, 15, 40]},                        // 2 - Orange cristal
            {regularDelay: 40, payment: [0, 0, 0, 8, 20, 50]},                        // 3 - Green cristal
            {regularDelay: 40, payment: [0, 0, 0, 10, 25, 60]},                       // 4 - Yellow cristal
            {regularDelay: 40, payment: [0, 0, 0, 25, 60, 120]},                      // 5 - 7
            {regularDelay: 40, payment: [0, 0, 0, 50, 200, 250]},                     // 6 - Bar
            {regularDelay: 25, offset: 18, offsetx: 17, payment: [0, 0, 0, 0, 0, 0]}  // 7 - Star wild
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.jpg',
                intro: 'area/intro.jpg',
                gambleArea: 'area/gamble.png',
                symbolWin: 'symbolWin.png',
                shadow: 'shadow.png',
                rainbow: 'rainbow.png',
                star: 'star.png',
                bubble: 'star3.png',
                blick: 'blick.png',
                additionalSymbols05: 'bonus/additionalSymbols05.png',
                additionalSymbols06: 'bonus/additionalSymbols06.png',
                additionalSprite: 'additionalSprite.png'
            }),
            jsonAnimations: this.mergePath({
                background: 'background.json',
                reels: 'reels.json'

            }),
            video: this.mergePath({info_respin: 'area/info_respin.mp4'}),
            atlas: this.mergePath(['staticSymbols.json']),
            fonts: this.mergePath({fontInfo: 'font/fontInfo.ttf'})
        };

        this.additionalResources = {
            main: this.mergePath({
                bonusFont: bonusFont['imageResource'],
                bonusFont2: bonusFont2['imageResource']
            }),
            atlas: this.mergePath(['bonus/bonusSymbols.json'])
        };

        this.gameSounds = {
            soundClass: 'egt',
            sounds: [{name: 'background', loop: true},
                {name: 'reelsstop'},
                {name: 'buttonStart'},
                {name: 'buttonStop'},
                {name: 'winline'},
                {name: 'add-credit1'},
                {name: 'buttonInfo'},
                {name: 'showRainbow'},
                {name: 'hideRainbow'},
                {name: 'flame'},
                {name: 'respin'},
                {name: 'backgroundBonus', loop: true}
            ],
            path: `/game/games/${this.id}/audio/`
        };
        this.Lines = new Lines(this.mergePath({boxes: 'lines/boxes.png'}));
        this.InfoScreen = new InfoScreen({pages: 2}); // number of game info states
        this.InfoScreen.format = 'png';
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        const video = this.resources.info_respin;
        ctx.font = '24pt fontInfo';
        ctx.textAlign = 'center';
        ctx.fillStyle = 'white';
        ctx.shadowColor = 'black';
        ctx.shadowOffsetX = ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 5;

        switch (page) {
            case 1:
                video.play();
                const sprite = Sprite.from(video);
                sprite.name = 'info_respin';
                sprite.zIndex = 2;
                sprite.scale.set(1.1);
                sprite.anchor.set(0.5);
                sprite.position.set(this.gameWidth / 2, 377);
                const page1 = this.getStageChild('infoContainer').getChildByName(`page-${page}`);
                page1.addChild(sprite);
                break;
            case 2:
                video.currentTime = 1;
                video.pause();
                // red crystal
                this.strokeFillText(ctx, bet * this.symbols[2].payment[5], 354, 310);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[4], 354, 342);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[3], 354, 375);

                // blue crystal
                this.strokeFillText(ctx, bet * this.symbols[1].payment[5], 583, 310);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[4], 583, 342);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[3], 583, 375);

                // blue crystal
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 812, 310);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 812, 342);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 812, 375);

                // bar
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 246, 181);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 246, 213);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 246, 246);

                // bar
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 470, 181);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 470, 213);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 470, 246);

                // bar
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 695, 181);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 695, 213);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 695, 246);

                // bar
                this.strokeFillText(ctx, bet * this.symbols[3].payment[5], 923, 181);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[4], 923, 213);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[3], 923, 246);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    /**
     * Show additional fill star animation before respin
     * @param reels
     */
    fillStars(reels) {
        JL().debug(`-- Fill stars (reelIndex: ${reels})`);
        const {features, payment, extension} = this.latestResponse;
        App.Sounds.stopSound('background');
        App.Sounds.stopSound('backgroundBonus');
        App.Sounds.playSound('backgroundBonus');
        this.addShadow();
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});

        reels.forEach((reel, reelIndex) => {
            this.tickerTimeout(() => {
                this.addRainbow(reel);
                // prepare array of symbol substitutes
                const fillWay = [...Array(this.reelRows)].map((item, index) => index); // [0, 1, 2]

                // check star position
                this.reelMatrix[reel].forEach((row, rowIndex) => {
                    if (row.symbol === 7) {
                        // delete existing star pos
                        fillWay.splice(rowIndex, 1); // example: [0, 1]
                        // reverse animation way if star in last row
                        if (rowIndex === 2) fillWay.reverse(); // [1, 0]
                    }
                });

                // fill stars in respin reel for enable freeze roll (without substitutes)
                const respinScreen = extension.screen;
                respinScreen.forEach((reel, reelIndex) => {
                    reelIndex === extension.jokerReels[0] && reel.forEach((row, rowIndex) => {
                        respinScreen[reelIndex][rowIndex] = 8;
                    });
                });

                // fill stars by step in 1 second
                fillWay.forEach((row, index) => {
                    this.tickerTimeout(() => {
                        const symbolObj = this.reelMatrix[reel][row];
                        symbolObj.image = 'bonus';
                        symbolObj.symbol = 7;
                        symbolObj.loop = false; // play animation only once
                        this.Roll.updateSymbolSprite(symbolObj);
                        symbolObj.sprite.onComplete = null;
                        symbolObj.sprite.play();
                        App.Sounds.playSound('fillStar');

                        // after last star
                        index === fillWay.length - 1 && this.tickerTimeout(() => {
                            // restore symbols animation loop
                            this.reelMatrix.forEach(reel => {
                                reel.forEach(symbolObj => {
                                    symbolObj.loop = true;
                                    this.Roll.updateSymbolSprite(symbolObj);
                                    symbolObj.sprite.gotoAndStop(0);
                                });
                            });
                            // change symbols to regular scatter
                            fillWay.forEach(row => {
                                const symbolObj = this.reelMatrix[reel][row];
                                symbolObj.symbol = this.scatter;
                                symbolObj.image = 'regular';
                                this.Roll.updateSymbolSprite(symbolObj);
                            });
                            this.tickerTimeout(() => {
                                if (payment > 0 && features.length && index === fillWay.length - 1) {
                                    if (this.getState() !== 'SHOW_WIN_LINES') {
                                        this.setState('SHOW_WIN_LINES');
                                        this.startAnimateFeature(features);
                                    }
                                    this.setState('SHOW_WIN_LINES');
                                } else {
                                    this.drawBonusAskButton(true);
                                }
                            }, reels.length * 900);
                        }, 400);
                    }, 400 * index);
                });
            }, 900 * reelIndex);
        });
    }

    addRainbow(reelIndex) {
        this.rainbowFlag = true;
        App.Sounds.playSound('showRainbow');

        const background = this.getStageChild('background');
        const fonUp = background.getChildByName('fonUp');
        fonUp.state.setAnimation(0, 'animation', true);
        fonUp.update(0.009); // HARDCODED FRAMERATE!

        const rainbowContainer = this.getStageChild('rainbowContainer');
        const rainbow = new Sprite(this.getSpriteTextures({
            image: 'rainbow',
            fromFrame: 0,
            width: 178,
            height: 600
        })[0]);
        rainbow.position.set(this.reelXCoordinates[reelIndex], 0);
        rainbowContainer.addChild(rainbow);

        // add bubbles
        for (let i = 1; i < 30; i++) {
            const sprite = new Sprite(this.getTexture('bubble'));
            sprite.name = 'bubble';
            sprite.scale.x = sprite.scale.y = Math.random() / 1.5;
            sprite.step = 0;
            sprite.speed = 16;
            sprite.position.set(
                Math.round(Math.random() * (this.symbolWidth - 20) + this.reelXCoordinates[reelIndex]),
                Math.round(Math.random() * (600))
            );
            rainbowContainer.addChild(sprite);
            this.bubbleMas.push(sprite);
        }

        this.app.ticker.add(this.bubblesFlyght);
    }

    addShadow() {
        const rainbowContainer = this.getStageChild('rainbowContainer');
        const shadow = new Sprite(this.getSpriteTextures({
            image: 'shadow',
            fromFrame: 0,
            width: 1280,
            height: 720
        })[0]);
        shadow.position.set(-149, 0);
        shadow.name = 'shadow';
        rainbowContainer.addChild(shadow);
    }

    /**
     * Отрисовка таблички бонусной игры
     */
    showStartBonusFrame(parentContainer, {x, y}) {
    }

    //
    // ======================== RESPIN SECTION =============================
    //

    respin = () => {
        JL().debug(`-- Respin - ${JSON.stringify(this.latestResponse.extension.screen)}`);
        this.clearPressAnyButton();
        this.Legends.setStatus('additionalRoll');
        App.Sounds.playSound('respin');
        App.updateButton('start', {disabled: true});

        this.freezeReels = this.latestResponse.extension.jokerReels;
        this.sendRoll();
    };

    /**
     * Process reels response from server.
     * @param response - Socket response 'ROLL'
     */
    processReelResponse(response) {
        this.latestResponse = response;
        const {features, payment, extension} = response;
        this.setState('RESPONSE_RECEIVED');
        this.setBonusRollSymbol(); // for bonus game roll symbol

        // don't show roll if restore with extension
        if (extension && App.restoreGameState !== 'NONE') {
            this.setPaymentDiff();
            this.Buttons.disableAllButtons();
            App.updateButton('start', {disabled: true});
            extension.jokerReels.forEach((item) => {
                this.addRainbow(item);
                this.addShadow();
            });
            this.changeSymbolsToStars(extension.jokerReels);

            if (payment > 0 && features.length) {
                this.setState('SHOW_WIN_LINES');
                this.startAnimateFeature(features);
            } else {
                this.latestResponse.payment = extension.totalPayment;
                extension.isNextRollReSpin ?
                    this.drawBonusAskButton(true) :
                    this.afterBonusAction();
            }
        } else {
            // change screen to stars
            this.freezeReels.forEach(reelIndex => {
                for (let rowIndex = 0; rowIndex < this.reelRows; rowIndex++) {
                    this.latestResponse.screen[reelIndex][rowIndex] = this.scatter;
                }
            });
            this.prepareToRollAnimation(response);
        }
    }

    createFlame(reelIndex, rowIndex, symbol, lineIndex) {
        const ticker = this.app.ticker;
        const parentContainer = this.getStageChild('bonusContainer');
        App.Sounds.playSound('flame');

        const flame = new Sprite(this.getSpriteTextures({
            image: 'symbolWin',
            fromFrame: symbol,
            colCount: this.symbols.length,
            width: 260,
            height: 260
        })[0]);

        flame.position.set(this.reelXCoordinates[reelIndex] + this.symbolWidth / 2, this.reelTop + this.symbolHeight * rowIndex + this.symbolHeight / 2);
        flame.scale.x = 0.3;
        flame.scale.y = 0.3;
        flame.way = 0;
        flame.name = 'flame' + reelIndex + rowIndex + lineIndex;
        flame.anchor.set(0.5, 0.5);
        flame.rotation = 10 * lineIndex;
        flame.posX = this.reelXCoordinates[reelIndex];
        flame.posY = this.reelTop + this.symbolHeight;
        parentContainer.addChild(flame);
        let flameFuct;

        ticker.add(
            flameFuct = () => {
                if (flame.way === 0) {
                    flame.scale.x += 0.1;
                    flame.scale.y += 0.1;
                } else {
                    flame.scale.x -= 0.03;
                    flame.scale.y -= 0.03;
                    if (flame.scale.x <= 0) {
                        parentContainer.removeChild(flame);
                        ticker.remove(flameFuct);
                    }
                }
                if (flame.scale.x >= 1.2) {
                    flame.way = 1;
                }
            }
        );
    }

    starsFlight = () => {
        this.starsMas.forEach(sprite => {
            sprite.step++;
            if (sprite.scale.x >= 1) {
                sprite.scaleWay = 2;
            }

            if (sprite.scale.x < 0) {
                sprite.scaleWay = 1;
            }

            if ((sprite.name === 'star1' && (sprite.position.x < 30 || sprite.position.x > 200)) ||
                (sprite.name === 'star2' && (sprite.position.x < 950 || sprite.position.x > 1180)) ||
                (sprite.position.y < 100 || sprite.position.y > 500)) {
                sprite.way = 6.28 / Math.round(Math.random() * (20 - 1) + 1);   // way to flyght
                sprite.step = 0;
                sprite.speed = Math.random();

                if (sprite.name === 'star1') {
                    if (sprite.position.x < 30) sprite.position.x = 30;
                    if (sprite.position.x > 200) sprite.position.x = 200;
                    if (sprite.position.y < 100) sprite.position.y = 100;
                    if (sprite.position.y > 500) sprite.position.y = 500;
                }

                if (sprite.name === 'star2') {
                    if (sprite.position.x < 950) sprite.position.x = 950;
                    if (sprite.position.x > 1180) sprite.position.x = 1180;
                    if (sprite.position.y < 100) sprite.position.y = 100;
                    if (sprite.position.y > 500) sprite.position.y = 500;
                }
            }

            if (sprite.step >= sprite.steps) {
                sprite.scaleWay = sprite.scaleWay === 1 ? 2 : 1;
                sprite.way = 6.28 / Math.round(Math.random() * (20 - 1) + 1);   // way to flyght
                sprite.steps = Math.round(Math.random() * (400 - 200) + 200); // staps to flyght
                sprite.step = 0;
                sprite.speed = Math.random();
            } else {
                if (sprite.scaleWay === 1) {
                    sprite.scale.x += 0.001;
                    sprite.scale.y += 0.001;
                } else {
                    sprite.scale.x -= 0.001;
                    sprite.scale.y -= 0.001;
                }
                sprite.position.set(Math.round(sprite.position.x + (sprite.speed) * Math.cos(sprite.way)), Math.round(sprite.position.y + (sprite.speed) * Math.sin(sprite.way)));
            }
        });
    };

    bubblesFlyght = () => {
        this.bubbleMas.forEach(sprite => {
            sprite.step++;
            if (sprite.position.y > 570) {
                sprite.position.set(sprite.position.x, -20);
                sprite.scale.x = sprite.scale.y = Math.random() / 1.5;
            }
            sprite.position.set(sprite.position.x, sprite.position.y + (sprite.speed / 2));
        });
    };

    createWinText(reelIndex, rowIndex, currentFeature, win, destinationRow, color) {
        const colorMas = [0xbc5aff, 0x39ffff, 0xffab10, 0xd2ff0f, 0xffff1c, 0x52ffff, 0xffff47];
        const ticker = this.app.ticker;
        const win1 = '' + win;
        const container = new Container();
        container.name = currentFeature + 'sum';
        container.reelIndex = reelIndex;
        container.rowIndex = rowIndex;
        container.featureIndex = currentFeature;
        container.step = 0;
        container.destinationRow = destinationRow;
        container.position.set(this.reelXCoordinates[reelIndex] + (this.symbolWidth / 2) - (win1.length - 1) * 2, this.reelTop + this.symbolHeight * rowIndex);
        container.zIndex = 10 + currentFeature;
        container.rotation = Math.random() * (1.5 - 0.5) - 0.5;
        const parentContainer = this.getStageChild('textContainer');
        parentContainer.addChild(container);

        const richText1 = new Text(win, {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 40,
            fontWeight: 'bold',
            fill: '#fff',
            stroke: colorMas[color],
            strokeThickness: 6,
            lineJoin: 'round'
        });
        richText1.x = 0;
        richText1.y = 0;
        container.addChild(richText1);

        let textFunct;
        ticker.add(
            textFunct = () => {
                container.step++;
                if (container.step < 30) {
                    container.scale.x += 0.001;
                    container.scale.y += 0.001;
                }
                if (container.step > 30 && container.step < 60) {
                    container.scale.x -= 0.001;
                    container.scale.y -= 0.001;
                }
                const iteration = 60;
                const deltaY = ((container.destinationRow - container.rowIndex) * this.symbolHeight / iteration) * container.step;
                const startYpos = this.reelTop + this.symbolHeight * container.rowIndex;

                const deltaX = container.reelIndex === 0 ? (2 * this.symbolWidth) / iteration : -(2 * this.symbolWidth) / iteration;
                const startXpos = this.reelXCoordinates[container.reelIndex] + this.symbolWidth / 2;

                const x = startXpos + deltaX * container.step;
                const y = (startYpos - Math.sin(Math.PI / iteration * container.step) * 60) + deltaY;
                container.position.set(x, y);

                if (container.step >= iteration) {
                    parentContainer.removeChild(container);
                    ticker.remove(textFunct);
                }
            }
        );
    }

    createWinText2(reelIndex, rowIndex, currentFeature, win, featureIndex) {
        const parentContainer = this.getStageChild('textContainer');
        const ticker = this.app.ticker;
        const win1 = '' + win;
        const container = new Container();
        container.name = 'text' + featureIndex;
        container.reelIndex = reelIndex;
        container.featureIndex = currentFeature;
        container.step = 0;
        container.position.set(this.reelXCoordinates[reelIndex] + (this.symbolWidth / 2) - (win1.length - 1) * 2, this.reelTop + this.symbolHeight * rowIndex + this.symbolHeight / 3);
        container.zIndex = 10 + currentFeature;
        this.getStageChild('textContainer').addChild(container);

        const textProps = {
            parentContainer: container,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 0.4
        };
        this.drawCustomFont(win, 0, 0, textProps);

        ticker.add(this.textFunc = () => {
            container.step++;
            if (container.step < 25) {
                container.scale.x += 0.04;
                container.scale.y += 0.04;
            }
            if (container.step >= 50) {
                this.getStageChild('textContainer').removeChild(container);
                parentContainer.removeChild(container);
                ticker.remove(this.textFunc);
            }
        });
    }

    /**
     * Function to start logic of Animate feature first step
     * @param features
     */
    startAnimateFeature(features) {
        JL().debug('-- Start animate feature');

        const background = this.getStageChild('background');
        const fonUp = background.getChildByName('fonUp');
        fonUp.state.setAnimation(0, 'animation', true);
        fonUp.update(0.009); // HARDCODED FRAMERATE!

        App.Sounds.playSound('winline');
        this.makeFlame(features);
        this.tickerTimeout(() => {
            this.startAnimateFeatureAfterFlame(features);
        }, this.latestResponse.features.length * 1000);
    }

    getSumPosition = rows => {
        let position = 0;
        if (rows.length === 5) {
            position = 0;
        }
        if (rows.length === 4) {
            if (rows[0] !== 0) {
                position = 4;
            }
        }
        if (rows.length === 3) {
            if (rows[0] === 0) {
                position = 0;
            }
            if (rows[2] === 4) {
                position = 4;
            }
        }
        return position;
    };

    makeFlame(features, animationStarted, featureStep = -1) {
        const winLinesState = ['SHOW_WIN_LINES', 'SHOW_BONUS_WIN_LINES', 'SHOW_BIG_WIN_LINES'].includes(this.getState());
        const delay = 550;
        let reelPosition = 0;
        if (Date.now() - animationStarted > delay || !animationStarted || featureStep === -1) {
            featureStep++;
            const featureIndex = featureStep % features.length;
            const currentFeature = features[featureIndex];
            animationStarted = Date.now(); // reset time for next step

            if (featureStep < features.length && winLinesState) {
                if (currentFeature.uc === 'WIN_LINE') {
                    this.Lines.blinkLine(currentFeature.number, currentFeature.reels.length, currentFeature.symbol);
                }

                this.reelMatrix.forEach((reel, reelIndex) => {
                    this.tickerTimeout(() => {
                        reel.forEach((symbolObj, rowIndex) => {
                            this.tickerTimeout(() => {
                                if (currentFeature.uc === 'WIN_LINE') {
                                    const {coordinates} = this.Lines.lines[currentFeature.number];
                                    if (coordinates[reelIndex] === rowIndex && currentFeature.reels.includes(reelIndex)) {
                                        this.createFlame(reelIndex, rowIndex, currentFeature.symbol, currentFeature.number, currentFeature.payment);
                                        reelPosition = this.getSumPosition(currentFeature.reels);
                                        if (reelIndex === reelPosition) {
                                            this.createWinText(reelPosition, rowIndex, currentFeature.number, currentFeature.payment, coordinates[2], currentFeature.symbol);
                                        }
                                    }
                                }
                            }, reelIndex * 50);
                        });
                    }, featureStep * 30);
                });
            }
        }
        this.animationFlameId = requestAnimationFrame(this.makeFlame.bind(this, features, animationStarted, featureStep));
    }

    cleanBeforeRoll() {
        App.Sounds.playSound('buttonStart');
        this.app.ticker.remove(this.textFunc);
        this.stopAnimateFeature();
        this.stopWaitingAnimation();
        App.removePopupMessage();
        App.System.resetRollStatistics();
        this.extraBet && this.updateExtraBetButtons(false);
        this.latestResponse = null;
        this.SymbolInfo.remove(false);
        this.Lines.cleanActiveBox();

        if (!this.isBonus() && this.getState() !== 'IDLE_BONUS') {
            this.Legends.showJackpot();
            App.Money.withDraw(this.gameSettings.getBet());
            App.updateState('moneyParams', {
                credits: App.Money.getCredit(),
                money: App.Money.getMoney()
            });
        }
        if (this.rainbowFlag) {
            this.rainbowFlag = false;
        }

        this.getStageChild('bonusContainer').removeChildren();
        this.getStageChild('textContainer').removeChildren();
        this.Legends.clearStatus();
        this.Legends.clearText('features');
        this.Legends.clearText('win');

        if (!this.rainbowFlag) {
            const background = this.getStageChild('background');
            const fonUp = background.getChildByName('fonUp');
            fonUp.state.setAnimation(0, 'rolls', true);
            fonUp.update(0.009); // HARDCODED FRAMERATE!
        }
    }

    /**
     * Function to show line for special feature
     * @param currentFeature
     * @param features
     * @param featureIndex
     */
    showFeatureLine(currentFeature, features, featureIndex) {
        const {number, reels, uc, payment} = currentFeature; // get current feature params
        const container = this.getStageChild('linesContainer');
        const reelIndex = 2;
        let rowIndex = 0;
        uc !== 'SPECIAL_SYMBOL' && container.removeChildren(); // don't clear lines before special symbol (bookGame fill)
        uc === 'WIN_LINE' && this.Lines.drawLineImages([number], reels, container, true, payment);
        if (currentFeature.uc === 'WIN_LINE') {
            rowIndex = this.Lines.lines[currentFeature.number].coordinates[reelIndex];
            this.createWinText2(reelIndex, rowIndex, currentFeature.number, currentFeature.payment, featureIndex);
        }
    }

    /**
     * Static animation.
     * Play unique additional sounds
     * It plays once in 5 seconds if not Animate feature
     */
    showWaitingAnimation() {
        const deltaMas = [{x: 96, y: 32}, {x: 65, y: 43}, {x: 109, y: 38}, {x: 130, y: 52}, {x: 62, y: 45},
            {x: 133, y: 48}, {x: 124, y: 48}, {x: 103, y: 32}];
        this.stopWaitingAnimation();
        this.waitingAnimationFrame = setInterval(() => {
            if (!this.animationFrameId) {
                const ticker = this.app.ticker;
                const parentContainer = this.getStageChild('bonusContainer');
                const reelIndex = Math.floor(Math.random() * (this.reels));
                const rowIndex = Math.floor(Math.random() * (this.reelRows));
                const symbolObj = this.reelMatrix[reelIndex][rowIndex];
                let blick;
                let blickFuct;

                if (symbolObj.symbol < 5 || symbolObj.symbol === 7) {
                    blick = new Sprite(this.getSpriteTextures({
                        image: 'blick',
                        fromFrame: symbolObj.symbol,
                        toFrame: symbolObj.symbol + 1,
                        width: 130,
                        height: 130,
                        colCount: 8
                    })[0]);

                    blick.scale.set(0.39);
                    blick.name = 'blick';
                    blick.way = 0;
                    blick.anchor.set(0.5, 0.5);
                    blick.position.set(this.reelXCoordinates[reelIndex] + deltaMas[symbolObj.symbol].x, this.reelTop + rowIndex * this.symbolHeight + deltaMas[symbolObj.symbol].y);
                    parentContainer.addChild(blick);

                    ticker.add(
                        blickFuct = () => {
                            if (blick.way === 0) {
                                blick.scale.x += 0.02;
                                blick.scale.y += 0.02;
                            } else {
                                blick.scale.x -= 0.02;
                                blick.scale.y -= 0.02;
                                if (blick.scale.x <= 0.39) {
                                    parentContainer.removeChild(blick);
                                    ticker.remove(blickFuct);
                                }
                            }
                            if (blick.scale.x >= 1) {
                                blick.way = 1;
                            }
                        }
                    );
                } else {
                    blick = new AnimatedSprite(this.getSpriteTextures({
                        width: 186,
                        height: 163,
                        image: 'additionalSymbols0' + symbolObj.symbol,
                        colCount: 5,
                        toFrame: 29
                    }));
                    blick.name = 'blick';
                    blick.animationSpeed = 0.5;
                    blick.position.set(this.reelXCoordinates[reelIndex], this.reelTop + rowIndex * this.symbolHeight);
                    blick.loop = false;
                    blick.visible = true;
                    blick.play();
                    blick.onComplete = () => {
                        blick.destroy();
                        blick = new Sprite(this.getSpriteTextures({
                            image: 'blick',
                            fromFrame: symbolObj.symbol,
                            toFrame: symbolObj.symbol + 1,
                            width: 130,
                            height: 130,
                            colCount: 8
                        })[0]);

                        blick.scale.set(0.39);
                        blick.name = 'blick';
                        blick.way = 0;
                        blick.anchor.set(0.5, 0.5);
                        blick.position.set(this.reelXCoordinates[reelIndex] + deltaMas[symbolObj.symbol].x, this.reelTop + rowIndex * this.symbolHeight + deltaMas[symbolObj.symbol].y);
                        parentContainer.addChild(blick);

                        ticker.add(
                            blickFuct = () => {
                                if (blick.way === 0) {
                                    blick.scale.x += 0.02;
                                    blick.scale.y += 0.02;
                                } else {
                                    blick.scale.x -= 0.02;
                                    blick.scale.y -= 0.02;
                                    if (blick.scale.x <= 0.39) {
                                        parentContainer.removeChild(blick);
                                        ticker.remove(blickFuct);
                                    }
                                }
                                if (blick.scale.x >= 1) {
                                    blick.way = 1;
                                }
                            }
                        );
                    };
                    parentContainer.addChild(blick);
                }
            }
        }, 3000);
    }

    /**
     * Static animation.
     * Play unique additional sounds
     * It plays once in 5 seconds if not Animate feature
     */
    showBackgroundAnimation() {
        const parentContainer = this.getStageChild('reelsBackground');
        for (let i = 1; i < 20; i++) {
            const sprite = new Sprite(this.getTexture('star'));
            sprite.name = i < 13 ? 'star1' : 'star2';
            sprite.scale.x = sprite.scale.y = Math.random();
            sprite.scaleWay = Math.round(Math.random() * (2 - 1) + 1); // scale way
            sprite.way = 6.28 / Math.round(Math.random() * (20 - 1) + 1);   // way to flyght
            sprite.steps = Math.round(Math.random() * (300 - 100)); // staps to flyght
            sprite.step = 0;
            sprite.speed = Math.random(); // speed to flyght

            i < 10 ?
                sprite.position.set(Math.round(Math.random() * (200 - 120) + 120), Math.round(Math.random() * (500 - 100) + 100)) : // +133 correction on background transfer x
                sprite.position.set(Math.round(Math.random() * (1200 - 980) + 980), Math.round(Math.random() * (500 - 100) + 100));

            parentContainer.addChild(sprite);
            this.starsMas.push(sprite);
        }

        this.app.ticker.add(this.starsFlight);
    }

    stopWaitingAnimation() {
        if (this.app) {
            const parentContainer = this.getStageChild('bonusContainer');
            parentContainer.removeChildren();
            clearInterval(this.waitingAnimationFrame);
        }
    }

    /**
     * Create PIXI.Container for lines
     * @param parentContainer
     */
    createRainbowContainer = parentContainer => {
        const container = new Container();
        container.name = 'rainbowContainer';
        container.zIndex = App.Game.containersLayers[container.name];
        parentContainer.addChild(container);
    };

    /**
     * Create PIXI.Container for lines
     * @param parentContainer
     */
    createTextContainer = parentContainer => {
        const container = new Container();
        container.name = 'textContainer';
        container.zIndex = App.Game.containersLayers[container.name];
        parentContainer.addChild(container);
    };

    prepareToRollAnimation(response) {
        this.Buttons.disableAllButtons();
        App.updateButton('start', {
            disabled: false,
            title: 'stop',
            handler: () => { // change handler to stop reels animation
                JL().debug('-- Stop roll');
                App.Sounds.playSound('buttonStop');
                App.Sounds.stopSound('respin');
                this.Roll.stopReels = [1, 1, 1, 1, 1, 1];
                this.Roll.fastReelsSound = 1;
                App.updateButton('start', {disabled: true});
            }
        });
        this.extraBet && this.updateExtraBetButtons(false);
        this.Roll.startReelAnimation(response);
    }

    onInfoStartOpen() {
        App.Sounds.playSound('buttonInfo');
    }

    /**
     * Start game animations
     */
    showGameIntroduction() {
        JL().debug('-- Start game intro animation');
        this.setState('GAME_INTRO');
        this.Buttons.disableAllButtons();
        App.updateButton('autoStart', {disabled: true});
        App.updateState('buttons', {animation: 'hide'});
        this.getStageChild('boxesContainer').visible = false;

        const parentContainer = this.getStageChild('introContainer');
        parentContainer.sortableChildren = true;
        parentContainer.interactive = true;
        const sprite = new Sprite(this.getTexture('intro'));
        sprite.name = 'intro';
        sprite.position.set(-150, 0);
        sprite.zIndex = 20;
        parentContainer.addChild(sprite);

        parentContainer.on('pointerdown', () => {
            App.updateState('buttons', {animation: 'show'});
            parentContainer.interactive = false;
            this.getStageChild('boxesContainer').visible = true;
            this.playBackgroundSound();
            parentContainer.removeChildren();
            this.roundFinished();
        });
    }

    playBackgroundSound = () => {
        App.Sounds.stopSound('background');
        App.Sounds.playSound('background');
    };

    restoreRoll() {
        JL().debug(`-- Restore roll - ${JSON.stringify(this.latestResponse)}`);
        this.playBackgroundSound();
        this.Legends.showJackpot();
        this.processReelResponse(this.latestResponse);
    }

    drawBonusAskButton(isFirstBonus) {
        JL().debug(`-- Set bonus ask button (isFirstBonus: ${isFirstBonus})`);
        this.stopAnimateFeature();
        this.showPressAnyButton(false);

        this.gameFlag.bonusStart = true;
        this.roundWin && this.Legends.setText('win', {text: 'win', value: this.bonusWin});
        this.showStartBonusFrame(this.getStageChild('bonusContainer'), this.coordinatesBonusFrame.startBonusFrame);

        this.tickerTimeout(() => {
            this.getStageChild('textContainer').removeChildren();
            this.app.ticker.remove(this.textFunc);
            this.stopAnimateFeature();
            this.stopWaitingAnimation();
            this.Lines.cleanActiveBox();
            this.respin();
        }, 300);
    }

    /**
     * Function to decide next step after bonus / take win without prompt
     * lose and idle
     */
    afterBonusAction() {
        const {payment} = this.latestResponse;
        if (payment > 0 || this.bonusWin) {
            this.Legends.setText('win', {text: 'win', value: payment});
            this.takeWin();
            this.Lines.cleanActiveBox();
            if (this.afterBonus(this.latestResponse)) {
                this.hideRaibow();
            }
        } else {
            this.Legends.showJackpot();
            this.Legends.setRoundFinText();
            this.roundFinished();
        }
    }

    getRandomSymbol(length, reelIndex, symbolBefore) {
        const denyRepeat = Math.floor(Math.random() * 4); // decrease repeat 5 times less
        let symbol = Math.floor(Math.random() * length);
        if (denyRepeat === 0 && this.doublingFilter.indexOf(symbolBefore) === -1) {
            symbol = symbolBefore;
        } else {
            while (
                symbol === this.reelFilter[reelIndex][0] ||
                symbol === this.reelFilter[reelIndex][1] ||
                symbol === this.reelFilter[reelIndex][2] ||
                symbol === symbolBefore) {
                symbol = Math.floor(Math.random() * length);
            }
        }
        return symbol;
    }

    /**
     * Function to start logic of Animate feature first step
     * @param features
     */
    startAnimateFeatureAfterFlame(features) {
        JL().debug('-- Start animate feature after flame!!!');
        this.Legends.showWinFeatures();
        App.Sounds.pauseSound('bonus-background');
        this.prepareToAnimateFeature(features);
        this.animateFeature(features);

        // change handler to stop animation win line
        App.updateButton('start', {
            disabled: false, title: 'stop',
            handler: this.stopWinLineAnimation   // finish line aniamtion by one ckick
        });
    }

    stopWinLineAnimation = () => {
        App.updateButton('start', {
            disabled: true, title: 'stop',
            handler: this.stopWinLineAnimation   // finish line aniamtion by one ckick
        });
        JL().debug('-- Finish win line animation');
        const {payment} = this.latestResponse;
        this.Legends.setStatus('creditsWon', payment);
        this.Legends.setText('win', {text: 'win', value: payment});
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        this.bonusWin = payment;
        this.endAnimateFeature();
    };

    /**
     * Function to stop Animate Feature animation
     */
    stopAnimateFeature() {
        const ticker = this.app.ticker;
        ticker.remove(this.muarFunct);
        cancelAnimationFrame(this.animationFrameId);
        cancelAnimationFrame(this.animationFlameId);
        this.stopFeatureTimeout();
        this.getStageChild('linesContainer').removeChildren();
        this.resetSymbolAnimation();
        this.Legends.clearText('features');
        !this.isBonus() && this.Legends.clearText('win');
    }

    /**
     * End feature animation
     */

    // Logic to nomatik takeWin after roll and bonus without gamble
    /**
     * End feature animation / take win without prompt
     */
    endAnimateFeature() {
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        App.Sounds.stopSound('win-line');
        const response = this.latestResponse;
        if (this.isNextrollRespin(response)) {
            this.drawBonusAskButton(true);
        } else {
            if (this.getState() === 'SHOW_WIN_LINES' && this.latestResponse.payment) {
                if (this.afterBonus(response)) {
                    this.endBonus();
                } else {
                    if (this.latestResponse.payment > 0) {
                        this.takeWin();
                        this.getStageChild('rainbowContainer').removeChildren();
                    } else {
                        this.roundFinished();
                    }
                }
            } else { // no win, just feature without payment
                this.roundFinished();
            }
        }
    }

    isNextrollRespin(response) {
        let respin = false;
        if (response.extension) {
            if (response.extension.isNextRollReSpin) {
                respin = true;
            }
        }
        return respin;
    }

    stopRollSound = () => {
        App.Sounds.stopSound('bonus_reels');
        App.Sounds.stopSound('reels');
        App.Sounds.stopSound('respin');
    };

    addSymbolBackground(parentContainer, x, y) {
        const symbolsWithBackground = [0, 1, 2, 3, 4];
        if (symbolsWithBackground.includes(parentContainer.name)) {
            const symbolBackgroundAnimation = new Sprite(this.getSpriteTextures({
                image: 'additionalSprite',
                fromFrame: parentContainer.name,
                toFrame: parentContainer.name + 1,
                width: 186,
                height: 163,
                colCount: 5
            })[0]);
            symbolBackgroundAnimation.zIndex = 3;
            symbolBackgroundAnimation.blendMode = 1;
            symbolBackgroundAnimation.visible = false;
            symbolBackgroundAnimation.alpha = 0;
            symbolBackgroundAnimation.name = 'background';
            parentContainer.addChild(symbolBackgroundAnimation);
            parentContainer.sortableChildren = true;
            return symbolBackgroundAnimation;
        }
    }

    /**
     * Animate feature first step
     */
    prepareToAnimateFeature(features) {
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        this.features.step = 0;

        // check 'WIN_LINE' feature contain
        const isWinLine = features.some(features => features.uc === 'WIN_LINE');
        this.updateExtraSymbols(isWinLine);

        // reset all animations and turn on shadows
        this.reelMatrix.forEach(reel => {
            reel.forEach(symbolObj => {
                // don't hide symbols if only scatter feature
                symbolObj.sprite.alpha = isWinLine && this.symbolEffects ? 0.5 : 1;

                symbolObj.sprite.gotoAndStop(0);
            });
        });

        // unique preparing for each game
        this.additionalPreparingToAnimateFeature(features);

        // initialize symbols on reelMatrix
        features.forEach(feature => {
            switch (feature.uc) {
                case 'WIN_LINE':
                    feature.reels.forEach(reelIndex => {
                        const rowIndex = this.Lines.lines[feature.number].coordinates[reelIndex];
                        const symbolObj = this.reelMatrix[reelIndex][rowIndex];
                        symbolObj.image = this.symbolAnimation && symbolObj.image === 'static' ?
                            'regular' : symbolObj.image;
                        this.setRegularLongSprite(feature.reels.length, symbolObj);
                        this.Roll.updateSymbolSprite(symbolObj);
                        symbolObj.sprite.alpha = 1;
                        symbolObj.sprite.zIndex = 1;
                        symbolObj.sprite.play();
                        if (symbolObj.symbol < 5) {
                            symbolObj.symbolBackground.way = 0;
                            symbolObj.symbolBackground.visible = true;
                            this.showAnimation({
                                duration: 1000, animations: [
                                    {
                                        sprite: symbolObj.symbolBackground,
                                        timeline: [{
                                            from: {alpha: 0},
                                            to: {alpha: 1},
                                            duration: {to: 500}
                                        }]
                                    },
                                    {
                                        sprite: symbolObj.symbolBackground,
                                        timeline: [{
                                            from: {alpha: 1},
                                            to: {alpha: 0},
                                            duration: {from: 500, to: 1000}
                                        }]
                                    }
                                ]
                            });
                        }
                    });
                    break;
                case 'SCATTER':
                case 'WIN_WAY':
                    feature.positions.forEach(({reel, row}) => {
                        const symbolObj = this.reelMatrix[reel][row];
                        symbolObj.image = this.symbolAnimation && symbolObj.image === 'static' ?
                            'regular' : symbolObj.image;
                        this.setRegularLongSprite(feature.uc === 'SCATTER' ? feature.positions.length : feature.count, symbolObj);
                        this.Roll.updateSymbolSprite(symbolObj);
                        symbolObj.sprite.alpha = 1;
                        symbolObj.sprite.play();
                    });
                    break;
            }
        });
        const ticker = this.app.ticker;
        ticker.add(
            this.muarFunct = () => {
                // reset all animations and turn on shadows
                this.reelMatrix.forEach(reel => {
                    reel.forEach(symbolObj => {
                        if (symbolObj.symbol < 5) {
                            if (symbolObj.symbolBackground !== null) {
                                if (symbolObj.symbolBackground.visible) {
                                    if (symbolObj.symbolBackground.way === 0) {
                                        symbolObj.symbolBackground.alpha += 0.005;
                                        symbolObj.symbolBackground.alpha += 0.005;
                                    } else {
                                        symbolObj.symbolBackground.alpha -= 0.005;
                                        symbolObj.symbolBackground.alpha -= 0.005;
                                    }
                                    if (symbolObj.symbolBackground.alpha >= 1 && symbolObj.symbolBackground.way === 0) {
                                        symbolObj.symbolBackground.way = 1;
                                    }
                                    if (symbolObj.symbolBackground.alpha <= 0 && symbolObj.symbolBackground.way === 1) {
                                        symbolObj.symbolBackground.way = 0;
                                    }
                                }
                            }
                        }
                    });
                });
            }
        );
    }

    hideRaibow() {
        this.freezeReels = [];
        this.getStageChild('rainbowContainer').removeChildren();
        App.Sounds.playSound('hideRainbow');
        App.Sounds.stopSound('backgroundBonus');
        App.Sounds.stopSound('background');
        App.Sounds.playSound('background');

        this.rainbowFlag = false;
    }

    afterBonus(response) {
        let respin = false;
        if (response.extension !== null) {
            if (!response.extension.isNextRollReSpin) {
                respin = true;
            }
        }
        return respin;
    }

    /**
     * End bonus game, return to regular game.
     */
    endBonus() {
        JL().debug('-- End bonus');
        this.setState('AFTER_BONUS');
        this.getStageChild('bonusContainer').removeChildren();
        this.afterBonusAction();
        this.stopAnimateFeature();
        this.setRegularSprite();
        this.latestResponse.features = [];
        this.bonusRollSymbol = null;
        this.gameFlag.bonusStarted = false;
        this.bonusStatus = null;
    }

    onRotationDone() {
        JL().debug(`-- Rotation done (fps: ${App.System.statistics.fps})`);
        const {features, extension} = this.latestResponse;
        // check if is there respin and if is this the last one.
        const lastRollRespin = extension &&
            extension.isNextRollReSpin === false;

        const payment = lastRollRespin ?
            extension.totalPayment :
            this.latestResponse.payment;

        App.updateButton('start', {disabled: true});
        this.roundWin = 0;

        if (extension && extension.isNextRollReSpin && App.restoreGameState === 'NONE') {
            // check reels for fill (is not already filled)
            const fillReels = extension.jokerReels.filter(reelIndex =>
                !this.freezeReels.includes(reelIndex));
            this.fillStars(fillReels);
        } else {
            // check feature win
            if (payment > 0 && (features.length || this.isFreeRoll(features))) {
                if (this.BigWin.isBigWin(payment)) {
                    this.BigWin.goToBigWin(payment, features);
                } else {
                    this.setState('SHOW_WIN_LINES');
                    this.startAnimateFeature(features);
                }
            } else {
                // check extension
                if (extension && extension.totalPayment) {
                    this.latestResponse.payment = extension.totalPayment;
                    App.restoreGameState !== 'NONE' && this.changeSymbolsToStars(extension.jokerReels);
                }
                this.roundFinished();
                if (this.afterBonus(this.latestResponse)) {
                    this.hideRaibow();
                }
                this.Legends.setRoundFinText();
            }
        }
    }

    /**
     * Create PIXI.Container for game background
     * Add additional sprites to container
     * @param parentContainer
     */
    createMainContainer(parentContainer) {
        const container = new Container();
        container.name = 'mainContainer';
        container.zIndex = this.containersLayers[container.name];
        parentContainer.addChild(container);
    }

    showAdditionalBonusImage() {
    }

    /**
     * Create all scenes
     */
    createPIXIContainers(container) {
        this.Roll.createReelsContainer(container, this.transparentBackground);
        this.createMainContainer(container);
        this.createRainbowContainer(container);
        this.createBonusContainer(container);
        this.createBackgroundContainer(container);
        this.createTextContainer(container);
        this.createWaitingContainer(container);
        this.Lines.createLinesContainer(container);
        this.Lines.createBoxesContainer(container);
        this.extraBet && this.createExtraBetContainer(container);
        App.configs.doubleScreen && this.createSecondScreenContainer(container);
        this.createBackgroundFXContainer(container);
        this.createLogoContainer(container);
        this.createIntroContainer(container);
        this.createAdditionalSprites(container);
        App.configs.subMode === 'test' && this.addFpsMeter(container);
    }

    /**
     * Create PIXI.Container for game bonus animations
     * Add additional sprites to container
     * @param parentContainer
     */
    createBackgroundFXContainer = parentContainer => {
        const container = new Container();
        container.name = 'backgroundFX';
        container.interactiveChildren = true;
        container.zIndex = this.containersLayers[container.name];
        parentContainer.addChild(container);
    };

    /**
     * Create PIXI.Container for game bonus animations
     * Add additional sprites to container
     * @param parentContainer
     */
    createLogoContainer = parentContainer => {
        const container = new Container();
        container.name = 'logo';
        container.interactiveChildren = true;
        container.zIndex = this.containersLayers[container.name];
        parentContainer.addChild(container);
    };

    /**
     * Create PIXI.Container for game bonus animations
     * Add additional sprites to container
     * @param parentContainer
     */
    createBackgroundContainer = parentContainer => {
        const container = new Container();
        container.name = 'background';
        container.interactiveChildren = true;
        container.zIndex = this.containersLayers[container.name];
        parentContainer.addChild(container);
    };

    /**
     * Create additional sprites and animations for stage
     * Call once when game loaded
     * @param parentContainer
     */
    createAdditionalSprites(parentContainer) {
        const ticker = this.app.ticker;
        const sprite = this.getStageChild('reelsBackground');
        // add background  animation
        const reelsBackground = this.getStageChild('reelsBackground');
        const fonDown = new window.PIXI.spine.Spine(this.getJsonTextures('background'));
        fonDown.position.set(-150, 0);
        fonDown.name = 'fonDown';
        fonDown.state.setAnimation(0, 'animation', true);
        reelsBackground.addChild(fonDown);
        const updateAnimationFrame1 = () => fonDown.update(0.0020); // HARDCODED FRAMERATE!
        ticker.add(updateAnimationFrame1);

        // add background  animation

        const background = this.getStageChild('background');
        const fx = new window.PIXI.spine.Spine(this.getJsonTextures('reels'));
        fx.position.set(-150, 0);
        fx.update(0);
        fx.autoUpdate = false;
        fx.name = 'fonUp';
        background.addChild(fx);
        fx.state.setAnimation(0, 'rolls', false);
        // fx.update(0.009); // HARDCODED FRAMERATE!
        //  ticker.add(updateAnimationFrame4);
    }
}
