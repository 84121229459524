import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../../../index';
import GameMegajack from './../../megajack/game';
import Lines21 from './../../megajack/lines21';
import GambleMegajack from './../../megajack/gamble';
import InfoScreen from '../../infoScreen';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    AnimatedSprite = PIXI.AnimatedSprite,
    Container = PIXI.Container,
    Text = PIXI.Text,
    Polygon = PIXI.Polygon;

export default class AztecGold extends GameMegajack {
    constructor() {
        super();
        this.id = 'aztec-gold';
        this.name = 'Aztec Gold';
        this.scatter = 10;
        this.buttonsPanelShadow = 'mid no-blur';
        this.reelFilter = [[10], [10], [], [], []];
        this.symbolAnimation = false;

        this.symbols = [
            {regularDelay: 500, payment: [0, 0, 0, 5, 10, 100]},     // 0 - tomato
            {regularDelay: 500, payment: [0, 0, 0, 5, 10, 100]},     // 1 - corn
            {regularDelay: 500, payment: [0, 0, 0, 5, 10, 100]},     // 2 - lama
            {regularDelay: 500, payment: [0, 0, 0, 5, 10, 100]},     // 3 - frog
            {regularDelay: 500, payment: [0, 0, 0, 10, 20, 100]},    // 4 - puma
            {regularDelay: 500, payment: [0, 0, 0, 20, 100, 500]},   // 5 - eagle
            {regularDelay: 500, payment: [0, 0, 0, 20, 100, 750]},   // 6 - mask to the left
            {regularDelay: 500, payment: [0, 0, 0, 20, 100, 1000]},  // 7 - mask to the right
            {regularDelay: 500, payment: [0, 0, 2, 25, 200, 10000]}, // 8 - golden head to the left
            {regularDelay: 500, payment: [0, 0, 0, 0, 0, 0]},        // 9 - head     // JOKER
            {regularDelay: 500, payment: [0, 0, 0, 0, 0, 0]},        // 10 - pyramid // BONUS
            {regularDelay: 500, payment: [0, 0, 0, 2, 10, 50]}       // 11 - bird    // SCATTER
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                waiting_roll: 'area/waiting_roll.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                bonusArea: 'area/bonus.png',
                superBonusArea: 'area/super_bonus.png',
                gambleArea: 'area/gamble.png',

                waiting_choosing: 'bonus/waiting_choosing.png',
                choose_items: 'bonus/choose_items.png',
                choice_animation: 'bonus/choice_animation.png',
                superBonus_choice_animation: 'bonus/superBonus_choice_animation.png',
                frameAnimation: 'bonus/frame_animation.png',
                frame: 'bonus/frame_super_bonus.png',
                waiting_super_bonus: 'bonus/waiting_super_bonus.png',
                minimizeSymbols: 'minimizeSymbols.png'
            }),
            atlas: this.mergePath(['scatterSymbols.json'])
        };
        this.imageResources.main.waiting_doubling = '/img/gamble/mega-jack/gamble_waiting.png';
        this.gameSounds = {soundClass: 'megajack'};
        this.Lines = new Lines21();
        this.Gamble = new GambleMegajack();
        this.InfoScreen = new InfoScreen({pages: 4}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 28px Arial';
        ctx.fillStyle = '#a67800';
        ctx.textAlign = 'center';

        switch (page) {
            case 1:
                ctx.fillText(bet * this.symbols[8].payment[5], 60, 89);   // golden head to the left
                ctx.fillText(bet * this.symbols[8].payment[4], 60, 125);
                ctx.fillText(bet * this.symbols[8].payment[3], 60, 164);
                ctx.fillText(bet * this.symbols[8].payment[2], 60, 200);

                ctx.fillText(bet * this.symbols[6].payment[5], 60, 294);  // mask to the left
                ctx.fillText(bet * this.symbols[6].payment[4], 60, 330);
                ctx.fillText(bet * this.symbols[6].payment[3], 60, 366);

                ctx.fillText(bet * this.symbols[4].payment[5], 60, 452);  // puma
                ctx.fillText(bet * this.symbols[4].payment[4], 60, 489);
                ctx.fillText(bet * this.symbols[4].payment[3], 60, 526);

                ctx.fillText(bet * this.symbols[7].payment[5], 454, 92);  // mask to the right
                ctx.fillText(bet * this.symbols[7].payment[4], 454, 128);
                ctx.fillText(bet * this.symbols[7].payment[3], 454, 167);

                ctx.fillText(bet * this.symbols[5].payment[5], 454, 294); // eagle
                ctx.fillText(bet * this.symbols[5].payment[4], 454, 330);
                ctx.fillText(bet * this.symbols[5].payment[3], 454, 366);

                ctx.fillText(bet * this.symbols[3].payment[5], 454, 452); // frog
                ctx.fillText(bet * this.symbols[3].payment[4], 454, 489);
                ctx.fillText(bet * this.symbols[3].payment[3], 454, 526);
                break;
            case 2:
                ctx.fillText(bet * this.symbols[2].payment[5], 60, 45);  // lama
                ctx.fillText(bet * this.symbols[2].payment[4], 60, 81);
                ctx.fillText(bet * this.symbols[2].payment[3], 60, 117);

                ctx.fillText(bet * this.symbols[0].payment[5], 60, 220); // tomato
                ctx.fillText(bet * this.symbols[0].payment[4], 60, 256);
                ctx.fillText(bet * this.symbols[0].payment[3], 60, 292);

                ctx.fillText(bet * this.symbols[1].payment[5], 454, 45); // corn
                ctx.fillText(bet * this.symbols[1].payment[4], 454, 81);
                ctx.fillText(bet * this.symbols[1].payment[3], 454, 117);

                ctx.fillText(bet * nLines * this.symbols[11].payment[5], 454, 220); // bird
                ctx.fillText(bet * nLines * this.symbols[11].payment[4], 454, 256);
                ctx.fillText(bet * nLines * this.symbols[11].payment[3], 454, 292);
                break;
        }
    }

    /**
     * Show bonus message with bonus symbol or with bonus game win.
     * @param isFirstBonus {boolean} TRUE if this is the message for starting bonus game.
     */
    drawBonusAskButton(isFirstBonus) {
        this.setState('CHOOSING_IDLE');
        App.Sounds.stopSound('bonusGameStart');
        this.hideBoxes();
        this.createChoosingBackground(this.getStageChild('bonusContainer'), 'bonusArea');
        this.stopAnimateFeature();
        this.disableChooseButtons();
        this.newChoice(0); // automatically send first choice
    }

    changeSymbolToJoker({number, reels, symbol}) {
        for (let reelIndex = 0; reelIndex < reels.length; reelIndex++) {
            const row = this.Lines.lines[number].coordinates[reelIndex];
            const {screen} = this.latestResponse;

            if (screen[reelIndex].includes(9)) {
                for (let rowIndex = 0; rowIndex < this.reelRows; rowIndex++) {
                    const symbolObj = this.reelMatrix[reelIndex][rowIndex];
                    symbolObj.symbol = row === rowIndex ? symbol : screen[reelIndex][rowIndex];
                    this.Roll.updateSymbolSprite(symbolObj);
                }
            }
        }
    }

    //
    //= ======================== CHOOSING =============================
    //

    restoreChoosing(response, parentContainer) {
        this.createChoosingBackground(this.getStageChild('bonusContainer'), 'bonusArea');
        setTimeout(() => this.showChoice(response, parentContainer), 500); // show choice after screen fade out
    }

    createChoosingBackground(parentContainer, imageName) {
        const sprite = new Sprite(this.getTexture(imageName));
        sprite.name = imageName;
        sprite.position.set(-(this.gameWidth - this.gameFieldWidth) / 2, 0);
        parentContainer.addChild(sprite);
        this.setBackground(imageName);
    }

    /**
     * Функция анимации самого выбора в чузинге
     */
    showChoosingChoiceAnimation(response, parentContainer) {
        JL().debug('-- Show choice animation in Choosing');
        this.setState('CHOOSING_ANIMATION');
        parentContainer.removeChild(parentContainer.getChildByName('pyramid'));
        let {step, multiplier} = response;

        const frames = 11;
        let imageIndex = this.getWinPicture(multiplier);

        const sprite = new AnimatedSprite(this.getSpriteTextures({
            fromFrame: imageIndex * frames, toFrame: ++imageIndex * frames, image: 'choice_animation',
            width: 525, height: 262,
            colCount: 7, rowCount: 10
        }));
        sprite.name = 'pyramid';
        sprite.position.set(1, 92);
        sprite.animationSpeed = 0.2;
        sprite.loop = false;
        sprite.interactive = false;
        sprite.buttonMode = true;
        sprite.hitArea = new Polygon([
            370, 8,
            550, 258,
            0, 258,
            0, 208,
            270, 8
        ]);
        sprite.play();
        sprite.onComplete = () => {
            this.showChooseItem(parentContainer, response);
            this.endChoiceAnimation(response, parentContainer);
        };
        sprite.on('pointerdown', () => {
            sprite.interactive = false;
            this.newChoice(++step);
        });
        parentContainer.addChild(sprite);

        this.Legends.clearStatus();
    }

    endChoiceAnimation(response, parentContainer) {
        let {extension, multiplier, step, stop, win} = response;

        if (stop) {
            setTimeout(() => this.finishChoosing(response, parentContainer), 2000); // Close bonus results after 2 sec.
            this.Legends.setText('win', {text: 'win', value: win});
            this.Legends.setStatus('creditsWon', win);
        } else { // продолжение чузинга или супер бонус
            if (multiplier === -2 || extension) {
                setTimeout(() => this.startSuperBonus(response, parentContainer), 2000);
            } else {
                this.setState('CHOOSING_IDLE');
                parentContainer.getChildByName('pyramid').interactive = true;
                App.updateButton('start', {
                    disabled: false,
                    title: 'start',
                    handler: () => this.newChoice(++step)
                });
                App.updateButton('select', {
                    disabled: false,
                    title: 'collect',
                    subTitle: ['kiosk', 'kiosk2'].includes(App.configs.subMode) ? 'hold 5' : '',
                    handler: () => this.finishChoosing(response, parentContainer)
                });
                App.updateButton('autoStart', {disabled: true});
                this.Legends.setText('win', {text: 'win', value: win});
                this.Legends.setStatus('creditsWon', win);
            }
        }
    }

    /**
     * Функция отображения картинки выигрыша, переопределяется в каждой игре
     */
    getWinPicture = multiplier => {
        const mapWinPicture = {
            '-2': {win: 5},
            '0': {win: 0},
            '10': {win: 0},
            '12': {win: 1},
            '15': {win: 2},
            '20': {win: 3},
            '25': {win: 4}
        };
        return mapWinPicture[multiplier].win;
    };

    /**
     * Функция отображения предыдущего выбора
     */
    showChooseItem(parentContainer, response) {
        parentContainer.removeChild(parentContainer.getChildByName('choosePyramid'));
        const winPictureIndex = this.getWinPicture(response.multiplier);

        const sprite = new Sprite(this.getSpriteTextures({
            fromFrame: winPictureIndex, image: 'choose_items',
            width: 148, height: 60
        })[0]);
        sprite.name = 'choosePyramid';
        sprite.position.set(638, 81 * (5 - winPictureIndex) + 8);
        parentContainer.addChild(sprite);

        this.Legends.setText('win', {text: 'win', value: response.win});
        this.Legends.setStatus('creditsWon', response.win);
    }

    //
    // ======================== SUPER BONUS =============================
    //

    initSuperBonusSprites(response, parentContainer) {
        parentContainer.removeChildren();
        this.createChoosingBackground(parentContainer, 'superBonusArea');
        this.createSuperBonusFrames(response, parentContainer);
    }

    /**
     * Create container for frames
     * @param response
     * @param parentContainer
     */
    createSuperBonusFrames(response, parentContainer) {
        const container = new Container();
        container.position.set(114, 54);
        container.name = 'superBonusFrames';
        container.interactiveChildren = true;
        parentContainer.addChild(container);

        const {prevChoosings} = response;
        prevChoosings.push(response);

        const width = 114;
        const height = 79;

        // create array of frames 5:5
        for (let frameIndex = 0; frameIndex < 5; frameIndex++) {
            for (let step = 0; step < 5; step++) {
                const prevChoice = prevChoosings[step];
                let sprite;
                const props = {image: 'superBonus_choice_animation', colCount: 1, width, height};

                // check opened frames
                if (prevChoice && prevChoice.extension) {
                    let {choice, extension: {cells}, multiplier} = prevChoice;
                    cells = [...cells]; // create new instance
                    cells.splice(choice, 0, multiplier); // insert choice to cells
                    let fromFrame = this.getSuperWinPicture(cells[frameIndex]);

                    // change 'diamonds' image by step
                    if ([0, 1, 2, 3].includes(fromFrame)) {
                        const diamondStep = step > 2 ? 2 : step;
                        props.colCount = 3;
                        fromFrame = fromFrame * props.colCount + diamondStep;
                    }

                    sprite = new Sprite(this.getSpriteTextures({...props, fromFrame})[0]);
                    container.addChild(sprite);

                    // draw multipliers, except 0
                    cells[frameIndex] > 0 && this.showCellsWin(frameIndex, step, width, height, cells[frameIndex], container);

                    // create selected frame
                    if (frameIndex === choice) {
                        const frameSprite = new Sprite(this.getSpriteTextures({...props, image: 'frame'})[0]);
                        frameSprite.name = `frame${step}`;
                        frameSprite.position.set(width * frameIndex, height * (4 - step));
                        container.addChild(frameSprite);
                    }
                } else {
                    sprite = new AnimatedSprite(this.getSpriteTextures({
                        ...props,
                        toFrame: 10, image: 'frameAnimation', colCount: 10
                    }));
                    sprite.interactive = true;
                    sprite.buttonMode = true;
                    sprite.on('pointerdown', () => this.superBonusFrameChoice(frameIndex, container));
                    container.addChild(sprite);
                }

                sprite.name = `${frameIndex}:${step}`;
                sprite.position.set(width * frameIndex, height * (4 - step));
                sprite.animationSpeed = 0.15;
                sprite.loop = false;
            }
        }
    }

    superBonusFrameChoice(frameIndex, container) {
        this.newChoice(frameIndex);
        container.interactiveChildren = false;
    }

    /**
     * Функция анимации самого выбора в чузинге
     */
    showSuperBonusChoiceAnimation(response, parentContainer) {
        JL().debug('-- Show choice animation in Super bonus');
        this.setState('SUPER_BONUS_ANIMATION');
        let {choice, extension: {cells}, multiplier, step, win, stop} = response;
        const container = parentContainer.getChildByName('superBonusFrames');
        cells = [...cells]; // create new instance
        cells.splice(choice, 0, multiplier); // insert choice to cells

        const animatedFrames = [];

        const playNextFrame = frameIndex => {
            const width = 114;
            const height = 79;
            const props = {image: 'superBonus_choice_animation', colCount: 1, width, height};

            if (frameIndex === choice) {
                // create selected frame
                const frameSprite = new Sprite(this.getSpriteTextures({...props, image: 'frame'})[0]);
                frameSprite.name = `frame${step}`;
                frameSprite.position.set(width * choice, height * (4 - step));
                container.addChild(frameSprite);

                // choice frame already played, skip step
                if (animatedFrames.includes(frameIndex)) {
                    playNextFrame(++frameIndex);
                    return;
                }
            }

            const sprite = container.getChildByName(`${frameIndex}:${step}`);
            sprite.play();
            sprite.onComplete = () => {
                sprite.interactive = false;
                let fromFrame = this.getSuperWinPicture(cells[frameIndex]);

                // change 'diamonds' image by step
                if ([0, 1, 2, 3].includes(fromFrame)) {
                    const diamondStep = step > 2 ? 2 : step;
                    props.colCount = 3;
                    fromFrame = fromFrame * props.colCount + diamondStep;
                }

                // change textures to choose item
                sprite.textures = this.getSpriteTextures({...props, fromFrame});

                // draw multipliers, except 0
                cells[frameIndex] > 0 && this.showCellsWin(frameIndex, step, width, height, cells[frameIndex], container);

                animatedFrames.push(frameIndex);
                frameIndex = frameIndex === choice ? 0 : frameIndex + 1;

                // last frame played
                animatedFrames.length === 5 ?
                    animationEnd() :
                    playNextFrame(frameIndex);
            };
        };

        const animationEnd = () => {
            !stop && this.setState('SUPER_BONUS_IDLE');
            container.interactiveChildren = !stop;
            this.Legends.setText('win', {text: 'win', value: win});
            this.Legends.setStatus('creditsWon', win);
            stop && setTimeout(() =>
                this.finishChoosing(response, parentContainer), 3000);
        };

        playNextFrame(choice);
    }

    /**
     * Функция отображения картинки выигрыша, переопределяется в каждой игре
     */
    getSuperWinPicture = multiplier => {
        const mapWinPicture = {
            '-3': {win: 4},  // skipp
            '-4': {win: 5},  // mask
            '-1': {win: 10}, // closed door
            '0': {win: 6},     // snake
            '5': {win: 0},     // diamond 1
            '11': {win: 0},    // diamond 1
            '19': {win: 1},    // diamond 2
            '23': {win: 1},    // diamond 2
            '27': {win: 2},    // diamond 3
            '33': {win: 3},    // diamond 4
            '39': {win: 7},    // magician
            '47': {win: 8},    // left mask
            '75': {win: 9}     // right mask
        };
        return mapWinPicture[multiplier].win;
    };

    /**
     * Draw win amount on cell
     * @param frameIndex
     * @param step
     * @param width
     * @param height
     * @param multiplier
     * @param parentContainer
     */
    showCellsWin(frameIndex, step, width, height, multiplier, parentContainer) {
        const amount = (multiplier * this.gameSettings.getBetCredit()).toString();
        const text = new Text(amount, {
            align: 'center',
            fontSize: 20,
            fill: '#ffff00',
            stroke: '#000',
            strokeThickness: 2,
            lineJoin: 'round'
        });
        text.name = `multiplier-${amount}`;
        text.anchor.set(0.5);
        text.position.set(width * frameIndex + width / 2, height * (4 - step) + 15);
        parentContainer.addChild(text);
    }

    /**
     * Additional handler for keyboard keys press
     * @param event
     * @param buttons
     * @returns {{soundChange: boolean, handler: boolean}}
     */
    keyboardPress(event, buttons) {
        const {select} = buttons;
        let soundChange = true;
        let handler = null;

        // choosing buttons
        if (this.getState() === 'CHOOSING_IDLE') {
            switch (event.keyCode) {
                case 53: // 'digit 5'
                case 101: // 'num 5'
                case 'b21': // 'select'
                    !select.disabled && select.handler && (handler = select.handler);
                    break;
            }
        }

        // super bonus buttons
        if (this.getState() === 'SUPER_BONUS_IDLE') {
            const container = this.getStageChild('bonusContainer').getChildByName('superBonusFrames');
            if (container && container.interactiveChildren) {
                switch (event.keyCode) {
                    case 49: // 'digit 1'
                    case 97: // 'num 1'
                    case 'b04': // 'h1'
                        soundChange = false; // pressed same button, disable additional handler
                        handler = () => this.superBonusFrameChoice(0, container);
                        break;
                    case 50: // 'digit 2'
                    case 98: // 'num 2'
                    case 'b05': // 'h2'
                        handler = () => this.superBonusFrameChoice(1, container);
                        break;
                    case 51: // 'digit 3'
                    case 99: // 'num 3'
                    case 'b07': // 'h3'
                        handler = () => this.superBonusFrameChoice(2, container);
                        break;
                    case 52: // 'digit 4'
                    case 100: // 'num 4'
                    case 'b06': // 'h4'
                        handler = () => this.superBonusFrameChoice(3, container);
                        break;
                    case 53: // 'digit 5'
                    case 101: // 'num 5'
                    case 'b03': // 'h5'
                        handler = () => this.superBonusFrameChoice(4, container);
                        break;
                }
            }
        }

        return {soundChange, handler};
    }

    /**
     * Create additional sprites and animations
     * Call once when game loaded
     * @param parentContainer
     */
    addWaitingAnimationSprites(parentContainer) {
        const waitingType = this.getWaitingState();
        let sprite;

        if (this.waitingType === waitingType) {
            return;
        }

        parentContainer.removeChildren();
        switch (waitingType) {
            case 'DOUBLING':
                break;
            case 'CHOOSING_IDLE':
                let sprite = new AnimatedSprite(this.getSpriteTextures({
                    toFrame: 19, image: 'waiting_choosing',
                    width: 135, height: 97
                }));
                sprite.name = 'frog';
                sprite.position.set(463, 400);
                sprite.animationSpeed = 0.1;
                sprite.play();
                parentContainer.addChild(sprite);
                break;
            case 'SUPER_BONUS_IDLE':
                sprite = new AnimatedSprite(this.getSpriteTextures({
                    toFrame: 4, image: 'waiting_super_bonus',
                    width: 764, height: 88,
                    colCount: 4,
                    rowCount: 2
                }));
                sprite.name = 'fire';
                sprite.position.set(13, 87);
                sprite.animationSpeed = 0.15;
                sprite.play();
                parentContainer.addChild(sprite);
                break;
            case 'IDLE':
                sprite = new AnimatedSprite(this.getSpriteTextures({
                    toFrame: 13, image: 'waiting_roll',
                    width: 154, height: 29
                }));
                sprite.name = 'maxWin';
                sprite.position.set(324, 64);
                sprite.animationSpeed = 0.15;
                sprite.play();
                parentContainer.addChild(sprite);
                break;
        }
        this.waitingType = waitingType;
    }

    /**
     * Фукнция отображения выбора Чузинга на ответ сервера
     */
    showChoice(response, parentContainer = this.getStageChild('bonusContainer')) {
        JL().debug(`-- Show choice: ${JSON.stringify(response)}`);

        response.extension ?
            this.showSuperBonusChoiceAnimation(response, parentContainer) :
            this.showChoosingChoiceAnimation(response, parentContainer);
    }

    /**
     * Start scatter animations
     * @param features
     */
    bonusEntrance(features) {
        this.setState('SCATTER_FEATURE');
        this.playBonusGameSound();
        this.stopAnimateFeature();
        this.drawTopAnimation(this.getStageChild('bonusContainer'));
        App.updateButton('start', {disabled: true});

        setTimeout(() => // call after scatter played
            this.drawBonusAskButton(this.isFreeRoll(features) && !this.bonusStatus), 2000);
    }

    showFeatureLine(currentFeature) {
        const {number, reels, payment, uc} = currentFeature; // get current feature params

        const container = this.getStageChild('linesContainer');
        uc !== 'SPECIAL_SYMBOL' && container.removeChildren(); // don't clear lines before special symbol (bookGame fill)

        this.reelMatrix.forEach(reel =>
            reel.forEach(symbolObj =>
                symbolObj.sprite.gotoAndStop(0)));

        if (['WIN_LINE', 'SCATTER'].includes(uc)) {
            uc === 'WIN_LINE' && this.Lines.drawLineImages([number], reels, container, true, payment);
            this.Lines.drawBoxes(this.getStageChild('boxesContainer'), number);
            this.getState() !== 'SHOW_BONUS_WIN_LINES' && this.createFeatureInfo(currentFeature, container);
            this.animateSymbolsInLine(currentFeature);
        }
        if (uc === 'WIN_LINE') {
            this.changeSymbolToJoker(currentFeature);
            const container = this.getStageChild('linesContainer');
            this.Lines.drawLineImages([number], reels, container, true, payment);
        }
    }

    createFeatureInfo(feature, container) {
        const {number, symbol, reels, positions, payment, uc} = feature; // get current feature params
        const ucReels = uc === 'WIN_LINE' ? reels.length : positions.length; // check 'SCATTER' feature

        const textProps = {
            font: 'Arial',
            fontSize: 16,
            fontWeight: 600,
            fill: '#f0e7cb'
        };
        const winSymbolsContainer = new Container();
        winSymbolsContainer.position.set(-100, -170);
        winSymbolsContainer.zIndex = 200;
        winSymbolsContainer.name = 'winSymbolsContainer';
        container.addChild(winSymbolsContainer);
        const statusLine = new Text(
            `${uc === 'WIN_LINE' ? App.language.line.toUpperCase() : 'SCATTERED'} ${uc === 'WIN_LINE' ? number + 1 : ''}: `,
            textProps
        );

        const statusPayment = new Text(
            `${App.language.win.toUpperCase()}: ${payment} `,
            textProps
        );

        statusLine.position.set(240, 468);
        statusPayment.position.set(500, 468);

        for (let i = 0; i <= ucReels - 1; i++) {
            const minimizeSymbol = new Sprite(this.getSpriteTextures({
                image: 'minimizeSymbols',
                fromFrame: symbol,
                colCount: this.symbols.length,
                width: 25,
                height: 23
            })[0]);

            minimizeSymbol.position.x = 450 + (25 * i);
            minimizeSymbol.position.y = 635;
            winSymbolsContainer.addChild(minimizeSymbol);
        }

        container.addChild(statusLine, statusPayment);
    }

    /**
     * Get current Game state
     * @returns {string|*}s
     */
    getWaitingState() {
        let state = this.getState();
        switch (this.state) {
            case 'DOUBLING':
                state = 'DOUBLING';  // no aniamtion
                break;
            case 'CHOOSING_IDLE':
            case 'CHOOSING_ANIMATION':
                state = 'CHOOSING_IDLE'; // choosing animation
                break;
            case 'SUPER_BONUS_IDLE':
            case 'SUPER_BONUS_ANIMATION':
                state = 'SUPER_BONUS_IDLE';  // super choosing animation
                break;
            case 'RESPONSE_RECEIVED':
            case 'IDLE':
            default:
                state = 'IDLE';  // idle main animation
                break;
        }
        return state;
    }
}
