import {JL} from 'jsnlog';

import Lines10 from './../../amatic/lines10';

export default class Lines extends Lines10 {
    constructor(imageResources) {
        super(imageResources);
        this.imageResources = {
            ...this.imageResources,
            ...imageResources // redefine base images from game
        };

        this.boxSettings = {
            lineBoxWidth: 28,
            lineBoxHeight: 29,
            typeBoxes: 'AllBoxesWithInactive'
        };

        this.linesColor = [
            '#ff0100',
            '#0000ff',
            '#02b41f',
            '#e8e300',
            '#aa00ff',
            '#65ffbd',
            '#ff238c',
            '#248dff',
            '#ffab03',
            '#02ff25'
        ];
        Object.keys(this.lines).forEach((index, i) => this.lines[index].color = this.linesColor[i]);

        JL().debug('-- Lines10 initialized');
    }
}
