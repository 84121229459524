import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import GameDeluxe from './../../deluxe/game';
import Lines20 from './../../deluxe/lines20';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';

/* PIXI aliases */
const AnimatedSprite = PIXI.AnimatedSprite;

export default class PlentyOnTwentyHot extends GameDeluxe {
    constructor() {
        super();
        this.id = 'plenty-on-twenty-hot';
        this.name = 'Plenty on Twenty Hot';
        this.transparentBackground = false;
        this.buttonsPanelShadow = 'mid';
        this.containersLayers.linesContainer = 10; // change to top for overlay bonusContainer

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 0, 10, 20, 100], offsetY: 43},   // 0 - square green crystal
            {regularDelay: 100, payment: [0, 0, 0, 10, 20, 100], offsetY: 43},   // 1 - round purple crystal
            {regularDelay: 100, payment: [0, 0, 0, 10, 20, 100], offsetY: 43},   // 2 - blue egg-like crystal
            {regularDelay: 100, payment: [0, 0, 0, 20, 40, 200], offsetY: 43},   // 3 - red heart crystal
            {regularDelay: 100, payment: [0, 0, 0, 20, 40, 200], offsetY: 43},   // 4 - yellow crystal
            {regularDelay: 100, payment: [0, 0, 0, 20, 80, 400], offsetY: 43},   // 5 - ring
            {regularDelay: 100, payment: [0, 0, 0, 50, 500, 2000], offsetY: 43}, // 6 - earrings
            {regularDelay: 100, payment: [0, 0, 0, 5, 20, 500], offsetY: 43}     // 7 - chest
        ];

        this.imageResources = {
            main: this.mergePath({mainArea: 'area/main.png'}),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {atlas: this.mergePath(['additionalSymbols.json'])};

        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [
                {name: 'win-line1'},
                {name: 'win-line2'},
                {name: 'win-line3'},
                {name: 'win-line4'},
                {name: 'win-line5'},
                {name: 'win-line6'},
                {name: 'win-line7'},
                {name: 'win-line8'},
                {name: 'win-line9'},
                {name: 'win-line10'},
                {name: 'win-line11'},
                {name: 'win-line12'},
                {name: 'win-line13'},
                {name: 'win-line14'},
                {name: 'win-line15'},
                {name: 'win-line16'},
                {name: 'win-line17'},
                {name: 'win-line18'},
                {name: 'win-line19'},
                {name: 'win-line20'},
                {name: 'additional-0'},
                {name: 'additional-1'},
                {name: 'additional-2'},
                {name: 'additional-3'},
                {name: 'additional-4'},
                {name: 'additional-5'},
                {name: 'additional-6'},
                {name: 'additional-7'}
            ],
            path: `/game/games/${this.id}/audio/`
        };

        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));
        this.Lines = new Lines20();
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 15pt Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#FFFFFF';
        ctx.strokeStyle = '#f74f20';
        ctx.lineWidth = 2;

        switch (page) {
            case 1:
                // scatter
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 225, 128);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 225, 158);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 225, 186);
                // scatter
                ctx.fillText(bet * 20 * this.symbols[6].payment[5], 575, 190); // поляна семерок даст..
                // watermelon
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 410, 258);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 410, 288);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 410, 320);
                // grape
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 165, 382);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 165, 412);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 165, 442);
                // grape
                this.strokeFillText(ctx, bet * this.symbols[2].payment[5], 635, 382);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[4], 635, 412);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[3], 635, 442);
                // lemon, chery
                this.strokeFillText(ctx, bet * nLines * this.symbols[7].payment[5], 400, 505);
                this.strokeFillText(ctx, bet * nLines * this.symbols[7].payment[4], 400, 535);
                this.strokeFillText(ctx, bet * nLines * this.symbols[7].payment[3], 400, 568);
                break;
            case 3:
                ctx.textAlign = 'center';
                ctx.fillStyle = '#FAFFFE';
                ctx.font = '15pt italic';
                ctx.strokeStyle = '#000';

                this.strokeFillText(ctx, 'All prizes are for combinations of a kind. All prizes are for', 400, 215);
                this.strokeFillText(ctx, 'combinations left to right, except scatters. All prizes are on selected', 400, 245);
                this.strokeFillText(ctx, 'lines, except scatters. Scatter symbols pay at any position on screen', 400, 275);
                this.strokeFillText(ctx, 'Highest win only paid per selected line. Scatter wins are added to', 400, 305);
                this.strokeFillText(ctx, 'line wins. All games are played on maximum lines as long as', 400, 335);
                this.strokeFillText(ctx, 'sufficient credits are available. All prizes shown in credits. Malfunction', 400, 365);
                this.strokeFillText(ctx, 'voids all pays and plays', 400, 395);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    playFeatureSound(currentFeature, featureIndex) {
        const {uc} = currentFeature; // get current feature params
        let soundFile = null;
        switch (uc) {
            case 'WIN_LINE':
            case 'SCATTER':
                soundFile = 'win-line';
                break;
        }
        soundFile && App.Sounds.playSound(`${soundFile}${featureIndex + 1}`);
    }

    /**
     * Static animation.
     * Play unique additional sounds
     * It plays once in 5 seconds if not Animate feature
     */
    showWaitingAnimation() {
        const parentContainer = this.getStageChild('bonusContainer');
        this.stopWaitingAnimation();

        this.waitingAnimationFrame = setInterval(() => {
            if (!this.animationFrameId && this.isResourcesLoaded()) {
                const reelIndex = Math.floor(Math.random() * this.reels);
                const rowIndex = Math.floor(Math.random() * this.reelRows);
                const symbolObj = this.reelMatrix[reelIndex][rowIndex];
                const textures = this.Roll.textures['additional'][symbolObj.symbol];

                const sprite = new AnimatedSprite(textures);
                sprite.loop = false;
                sprite.position.x = this.reelXCoordinates[reelIndex];
                sprite.position.y = this.symbolHeight * (rowIndex) + this.reelTop;
                sprite.animationSpeed = 0.15;
                sprite.play();
                sprite.onComplete = () => sprite.destroy();

                parentContainer.addChild(sprite);

                const symbolIndex = symbolObj.symbol;
                switch (symbolIndex) {
                    // cherry, bell && star
                    case 0:
                    case 5:
                    case 7:
                        setTimeout(() => App.Sounds.playSound(`additional-${symbolIndex}`), 700);
                        break;
                    // lemon, plum && seven
                    case 1:
                    case 3:
                    case 6:
                        setTimeout(() => App.Sounds.playSound(`additional-${symbolIndex}`), 300);
                        break;
                    // orange
                    case 2:
                        setTimeout(() => App.Sounds.playSound(`additional-${symbolIndex}`), 1000);
                        break;
                    // watermelon
                    case 4:
                        setTimeout(() => App.Sounds.playSound(`additional-${symbolIndex}`), 1500);
                        break;
                }
            }
        }, 5000);
    }

    stopWaitingAnimation() {
        if (this.app) {
            const parentContainer = this.getStageChild('bonusContainer');
            parentContainer.removeChildren();
            clearInterval(this.waitingAnimationFrame);
        }
    }
}
