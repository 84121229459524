module.exports = {
    // socket
    connectionServer: 'Connecting to the server',
    initializingGame: 'Loading game',
    initializingMenu: 'Loading menu',
    loadingImages: 'Loading Images',
    reload: 'Reload',
    redirect: 'Redirect',
    exitAndReload: 'Back to the menu and reload page',
    exit: 'Exit',
    exitAfter: 'Exit from the system in',
    serverNotResponded: 'Server not responding',
    pluginNotResponded: 'No connection to the controller',
    codeAlreadyConnected: 'Player with this code is already connected',
    connectionLost: 'Connection lost',
    gameNotFound: 'Game not found',
    codeNotFound: 'Code not found',
    cardNotFound: 'Card not found',
    terminalNotFound: 'Terminal code not found, or terminal is unavailable',

    // login
    login: 'Log in',
    loginCredentials: 'username, email or code',
    codeCredentials: 'Account code',
    username: 'Username',
    enterCode: 'Enter code',
    password: 'Password',
    email: 'Email',
    signIn: 'Sign In',
    signUp: 'Sign Up',
    registration: 'Registration',
    oneClick: 'One-click',
    demo: 'Demo',
    createAccount: 'Create account',
    logout: 'Log Out',
    restorePasswordWithEmail: 'Enter email to restore password',
    sendNewPassword: 'Send new password',
    restorePassword: 'Restore password',
    restoringPassword: 'Password recovery',
    successRegistration: 'Successful registration',
    incorrectUsername: 'Incorrect username or password',
    notUniqueName: 'Name must be unique',
    notUniqueEmail: 'Email must be unique',
    notUniquePhone: 'The phone number shoud be uniq',
    missedField: 'There is missed, required field',
    wrongOldPassword: 'The old password entered incorrectly',
    wrongPassword: 'Password entered incorrectly',
    emailChanged: 'The confirmation has been sent to your email',
    passwordResetError: 'Incorrect username or email',
    newPasswordSent: 'The new password has been sent to your email',
    invalidEmail: 'Invalid email',
    invalidPhone: 'Invalid phone \n' +
        '+38 YYY XXX XX XX',
    enterPassword: 'Enter password',
    differentPasswords: 'Password mismatch',
    tooLongPassword: 'Too long password (max characters: 20)',
    tooShortPassword: 'Too short password (min characters: 8)',
    passwordChanged: 'Password changed',
    enterOldPassword: 'Enter old password',
    enterNewPassword: 'Enter new password',
    samePasswords: 'Old & new password can\'t be identical',
    copied: 'Copied',
    copy: 'Copy',

    // profile
    save: 'Save',
    confirmation: 'Confirm',
    saveAs: 'Save as',
    cancel: 'Cancel',
    changingEmail: 'Email changing',
    changeEmail: 'Enter new Email',
    changingPhone: 'Phone changing',
    changePhone: 'Enter new phone',
    changingPassword: 'Password changing',
    changePassword: 'Change password',
    confirmCode: 'Confirmation code',
    phoneConfirmed: 'Phone confirmed',
    oldPassword: 'Old password',
    newPassword: 'New password',
    confirmPassword: 'Confirm password',
    connectWithUs: 'Contact us',
    profile: 'Profile',
    deposit: 'Deposit',
    withdraw: 'Withdrawal',
    support: 'Support',
    date: 'Date',
    type: 'Type',
    amount: 'Amount',
    status: 'Status',
    transactionsHistory: 'Transaction History',
    exchange: 'Currency exchange',
    noData: 'no data',
    transactionsBonus: 'Message history',
    message: 'Message',
    ok: 'Ok',

    currencySelect: 'Currency',
    paymentSelect: 'Payment method',
    amountDepositSelect: 'Deposit amount',
    finishOperation: 'Finish the operation',

    enterCurrency: 'Select currency',
    enterCardNumber: 'Enter card number',
    cardNumber: 'Card number',
    paymentAccept: 'Payment confirmation',
    payoutAmount: 'Withdrawal amount',
    paymentType: 'Payment method',
    enterDepositWay: 'Select deposit method',
    enterDepositAmount: 'Enter the amount of deposit',
    enterWithdrawalWay: 'Select withdrawal method',
    enterPayoutAmount: 'Enter withdrawal amount',
    successfulPayment: 'Payment has been successfully added to processing',
    successOperation: 'Successful payment',
    errorOperation: 'Payment error',
    confirm: 'Confirm',
    country: 'Country',
    currency: 'Currency',
    invalidCountry: 'Invalid country',
    invalidCurrency: 'Invalid currency',
    successfullyChangedTo: 'Successfully changed to',
    lowBalance: 'Not enough funds on the balance',

    accept: 'Accept',
    decline: 'Decline',
    currencyExchange: 'Currency exchange',
    currentBalance: 'Current balance',
    from: 'from',
    to: 'to',
    exchangePercent: 'Exchange percent',
    newBalance: 'New balance',
    coefficient: 'Coefficient',
    currencyConversion: 'Currency conversion',
    wrongInputData: 'Wrong input data',
    wrongAmountData: 'Wrong amount',
    wrongCreditCard: 'Wrong credit card number',
    noRedirectReff: 'There is no reference to redirection',
    deserializationIssue: 'Problem with deserialization',
    currencyPairNotExist: 'Currency pair not exist',
    authorizationMandatory: 'Authorization is required',
    birthdayFormat: 'Birthday should be DD MM YYYY',
    tooShort: 'Too Short card number',
    infoPayment: 'The processing of the first payment in 24 hours is free of charge. A commission will be charged for each subsequent payment.',
    time: 'Time',
    period: 'Up to 3 days',
    image: 'Image',
    text: 'Text',

    // status
    balance: 'Balance',
    insurance: 'Insurance',
    credits: 'credits',
    creditsWon: 'credits won',
    winnerPaid: 'Winner paid',
    gotNoMoney: 'Not enough money',
    minBet: 'Minimum total bet',
    gambleUp: 'Gamble up or take a win',
    gambleUp5x: 'Gamble up to 5x or take a win',
    gambleEnd: 'Attempt completed',
    pressAnyButton: 'Press start to begin',
    freeGame: 'Free game',
    of: 'of',
    freeSpinsRemaining: 'FREE SPINS REMAINING ',
    freeGames: 'free games',
    more: 'additional ',
    freeSpinsLeft: 'FREE SPINS LEFT ',
    additionalRoll: 'Respin',
    selectCard: 'Choose your card',
    selectColor: 'Choose red or black',
    selectColorOrSuit: 'Choose color (x2) or suit (x4)',
    gameOverPlaceYourBet: 'Please place your bet',
    auto: 'Auto',
    linePays: 'Line',
    scatterPays: 'Scatter pays',
    win: 'Win',
    line: 'Line',
    youWon: 'You won',
    lowMinBet: 'Min bet on ',
    highMaxBet: 'Max bet on ',
    unSupport: 'This game not supported on your device',
    urlNotFound: 'Page not found',
    categories: 'Categories',
    chooseSide: 'Choose a side',
    makeChoice: 'Make a choice',
    multiplier: 'Multiplier',

    // buttons
    lines: 'Lines',
    bet: 'Bet',
    total: 'Total',
    denom: 'Denom',
    gamble: 'Gamble',
    paytable: 'Info',
    bonus: 'Bonus',
    start: 'Start',
    stop: 'Stop',
    autoplay: 'Autoplay',
    select: 'Select',
    collect: 'Collect',
    red: 'Red',
    black: 'Black',
    return: 'Return',
    half: 'Half',
    payout: 'Payout',
    swipeToStart: 'Swipe up to start',
    tapToStart: 'Tap to start',

    // roulette buttons
    repeatAndStart: 'Repeat bet and start',
    double: 'Double',
    doubleAndSpin: 'Double and spin',
    undo: 'Undo',
    repeatBet: 'Repeat bet',
    removeBets: 'Remove bets',
    results: 'Results',
    cover: 'Covering',

    // gamble
    gambleAmount: 'Gamble amount',
    gambleToWin: 'Gamble to win',
    gambleAmountAmatic: 'Gamble amount',
    gambleToWinAmatic: 'Red/Black Gamble to win',
    suitGambleToWinAmatic: 'Suit gamble to win',
    previousCards: 'Previous cards',
    gambleAttemptsLeft: 'Gamble attempts left',
    history: 'History',

    // wrap
    selectLine: 'Number of lines',
    selectBet: 'Bet per line',
    selectDenomination: 'Denomination',

    // info
    rules: 'Rules',
    substitutesForAllSymbols: 'Substitutes for all symbols except scatters',
    combinationOfKind: 'All prizes are for combinations of a kind. ',
    combinationLeftToRight: 'All prizes are for combinations left to right, except scatters. ',
    prizesOnSelectedLines: 'All prizes are on selected lines, except scatters. ',
    ScatterPayAtAnyPosition: 'Scatter symbols pay at any position on screen. ',
    HighestWinPaid: 'Highest win only paid per selected line. ',
    ScatterWinsAddedToLineWins: 'Scatter wins added to line wins. ',
    prizesShownInCredits: 'Highest win only paid per selected line. ',
    MalfunctionVoidsAllPays: 'Malfunction voids all pays and plays. ',
    onTheScreen: 'trigger',
    bonusGame: 'BONUS GAME',
    bonusGameGive: 'It gives roulette with',
    freeSpins: 'FREE SPINS',
    featureBonus: 'In bonus game first reel will be frozen and filled by',
    goodLuck: 'Good luck!',

    // instruction IOS
    howToInstall: 'how to install the application',
    pressTheButton: 'Press the \'share\' button',
    goToHomeScreen: 'Go to ',
    confirmInstallation: 'Confirm an installation',

    // bonuses
    congratulation: 'Congratulation',
    registration_bonus: {
        title: 'Bonus for registration!',
        description: {
            punkt1: '- To activate the bonus, you need to confirm your email and phone number.',
            punkt2: '- Make a deposit to the main account.',
            punkt3: '- To credit the bonus to the main account, you need to place bets on the sum 15 times more than the sum of the bonus.'
        },
        accept: 'Your bonus for registration deposited to bonus account!'
    },

    week_bonus: {
        title: 'Weekly bonus!',
        description: 'Weekly bonus',
        accept: 'Your weekly bonus deposited to main account!'
    }
};
