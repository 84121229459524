import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import Lines from './../lines';
import App from './../../index';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Texture = PIXI.Texture,
    Text = PIXI.Text;

export default class Lines21 extends Lines {
    constructor() {
        super();
        this.imageResources = {
            lines: '/img/lines/Lines21/lines.png',
            boxes: '/img/lines/Lines21/boxes.png'
        };
        this.lineImageWidth = 656; // width of line image in sprite, magic number for each game
        this.lineImageHeight = 110; // height of line image in sprite, magic number for each game

        this.lines = {
            0: {
                coordinates: [1, 1, 1, 1, 1],
                boxes: [{x: 0, y: 264}],
                path: [{x: 69, y: 278}],
                width: 4,
                spriteY: 0
            },
            1: {
                coordinates: [0, 0, 0, 0, 0],
                boxes: [{x: 0, y: 181}],
                path: [{x: 69, y: 199}],
                width: 4,
                spriteY: 4
            },
            2: {
                coordinates: [2, 2, 2, 2, 2],
                boxes: [{x: 0, y: 343}],
                path: [{x: 69, y: 358}],
                width: 4,
                spriteY: 8
            },
            3: {
                coordinates: [0, 1, 2, 1, 0],
                boxes: [{x: 0, y: 111}],
                path: [{x: 69, y: 127}],
                width: 291,
                spriteY: 13
            },
            4: {
                coordinates: [2, 1, 0, 1, 2],
                boxes: [{x: 0, y: 413}],
                path: [{x: 69, y: 140}],
                width: 292,
                spriteY: 305
            },
            5: {
                coordinates: [0, 0, 1, 2, 2],
                boxes: [{x: 0, y: 146}],
                path: [{x: 69, y: 162}],
                width: 252,
                spriteY: 599
            },
            6: {
                coordinates: [2, 2, 1, 0, 0],
                boxes: [{x: 0, y: 378}],
                path: [{x: 69, y: 150}],
                width: 248,
                spriteY: 852
            },
            7: {
                coordinates: [1, 0, 1, 2, 1],
                boxes: [{x: 0, y: 299}],
                path: [{x: 69, y: 160}],
                width: 260,
                spriteY: 1102
            },
            8: {
                coordinates: [1, 2, 1, 0, 1],
                boxes: [{x: 0, y: 229}],
                path: [{x: 69, y: 135}],
                width: 264,
                spriteY: 1362
            },
            9: {
                coordinates: [0, 1, 0, 1, 0],
                boxes: [{x: 726, y: 111}],
                path: [{x: 69, y: 123}],
                width: 169,
                spriteY: 1627
            },
            10: {
                coordinates: [2, 1, 2, 1, 2],
                boxes: [{x: 726, y: 413}],
                path: [{x: 69, y: 282}],
                width: 150,
                spriteY: 1792
            },
            11: {
                coordinates: [0, 1, 1, 1, 1],
                boxes: [{x: 726, y: 229}],
                path: [{x: 69, y: 150}],
                width: 97,
                spriteY: 1942
            },
            12: {
                coordinates: [2, 1, 1, 1, 1],
                boxes: [{x: 726, y: 299}],
                path: [{x: 69, y: 320}],
                width: 47,
                spriteY: 2039
            },
            13: {
                coordinates: [1, 1, 0, 0, 0],
                boxes: [{x: 726, y: 146}],
                path: [{x: 69, y: 162}],
                width: 73,
                spriteY: 2087
            },
            14: {
                coordinates: [1, 1, 2, 2, 2],
                boxes: [{x: 726, y: 378}],
                path: [{x: 69, y: 325}],
                width: 73,
                spriteY: 2160
            },
            15: {
                coordinates: [1, 0, 0, 0, 0],
                boxes: [{x: 726, y: 181}],
                path: [{x: 69, y: 196}],
                width: 61,
                spriteY: 2234
            },
            16: {
                coordinates: [1, 2, 2, 2, 2],
                boxes: [{x: 726, y: 343}],
                path: [{x: 69, y: 300}],
                width: 60,
                spriteY: 2295
            },
            17: {
                coordinates: [0, 0, 0, 0, 1],
                boxes: [{x: 726, y: 264}],
                path: [{x: 69, y: 115}],
                width: 167,
                spriteY: 2357
            },
            18: {
                coordinates: [2, 2, 2, 2, 1],
                boxes: [{x: 0, y: 449}],
                path: [{x: 69, y: 270}],
                width: 167,
                spriteY: 2525
            },
            19: {
                coordinates: [2, 2, 1, 1, 2],
                boxes: [{x: 726, y: 449}],
                path: [{x: 69, y: 275}],
                width: 186,
                spriteY: 2692
            },
            20: {
                coordinates: [0, 0, 1, 0, 0],
                boxes: [{x: 726, y: 78}],
                path: [{x: 69, y: 115}],
                width: 143,
                spriteY: 2878
            }
        };

        JL().debug('-- Lines21 initialized');
    }

    drawLineImages(lines, winReels, parentContainer) {
        lines.forEach(lineIndex => {
            const line = this.lines[lineIndex];

            line.coordinates.forEach(() => {
                const spritePosX = 0;
                const spritePosY = line.spriteY;
                // create line sprite
                const sprite = new Sprite(new Texture(App.Game.getTexture('lines'), {
                    x: spritePosX,
                    y: spritePosY,
                    width: this.lineImageWidth,
                    height: line['width']
                }));
                sprite.position.set(line.path[0].x, line.path[0].y);
                sprite.name = lineIndex;
                parentContainer.addChild(sprite);
            });
        });
    }

    drawBoxes(parentContainer) {
        parentContainer.removeChildren(); // clear all previous boxes

        const currentLines = App.Game.gameSettings.getCurrentLineMas();
        const currentBet = App.Game.gameSettings.getBetLineCredit();
        const boxWidth = 74; // width of winline boxes
        const boxHeight = 33; // height of winline boxes

        Object.keys(this.lines).forEach(lineIndex => {
            this.lines[lineIndex].boxes.forEach(box => {
                const activeLine = currentLines.includes(+lineIndex + 1);

                // create box sprite
                const sprite = new Sprite(new Texture(App.Game.getTexture('boxes'), {
                    x: 0,
                    y: boxHeight * lineIndex,
                    width: boxWidth,
                    height: boxHeight
                }));
                sprite.position.set(box.x, box.y);
                sprite.name = lineIndex;
                activeLine && this.addBoxEvents(sprite);
                parentContainer.addChild(sprite);

                if (activeLine) {
                    const text = new Text(currentBet, {
                        fontFamily: 'Arial',
                        fontSize: 19,
                        fill: '#000'
                    });
                    text.anchor.set(0.5);
                    const pos = box.x ? 3 : 1.5;
                    text.position.set(box.x + boxWidth / pos, box.y + boxHeight / 2);
                    parentContainer.addChild(text);
                }
            });
        });
    }
}
