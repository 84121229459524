import React from 'react';

import './styles.less';
import App from './../../index';

export default function Categories() {
    return (
        <div className='categories-panel-list'>
            {Object.keys(App.games).map((categoryName, index) => <div
                key={index}
                className={`categories-panel-list-item ${categoryName}`}
                onClick={() => {
                    App.Menu.setCategory(categoryName);
                    App.Modal.remove('categories');
                }}
            >
                <div
                    style={{backgroundImage: `url(${App.getUrl(`/img/Menu/${categoryName}.png`)})`}}
                    className='category-bg'>
                </div>
                {App.settings.categoryName === categoryName && <div
                    className='item-border'
                    style={{backgroundImage: `url(${App.getUrl('/img/Menu/border.png')})`}}
                />}
            </div>)}
            <button id='close-button'>
                <div
                    className='bg-image'
                    style={{background: `url(${App.getUrl('/img/controls.png')}) 1% 66.7% / 300%`}}
                    onClick={() => App.Modal.remove('categories')}
                />
            </button>
        </div>
    );
}
