import App from './../../../index';
import GameEgt from './../../egt/game';
import Lines from './lines';
import GambleEgt from './../../egt/gamble';
import InfoScreen from '../../infoScreen';

export default class CaramelHot extends GameEgt {
    constructor() {
        super();
        this.id = 'caramel-hot';
        this.name = 'Caramel Hot';
        this.buttonsPanelShadow = 'mid';
        this.doublingFilter = [10];

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 120, y: 90},
            bonusInBonusFrame: {x: 120, y: 150},
            endBonusFrame: {x: 120, y: 150}
        };

        this.symbols = [
            {regularDelay: 50, payment: [0, 0, 0, 2, 10, 20]},      // 0 - cherry
            {regularDelay: 50, payment: [0, 0, 0, 2, 10, 20]},      // 1 - lemon
            {regularDelay: 50, payment: [0, 0, 0, 4, 15, 30]},      // 2 - orange
            {regularDelay: 50, payment: [0, 0, 0, 4, 15, 30]},      // 3 - plum
            {regularDelay: 50, payment: [0, 0, 0, 6, 20, 40]},      // 4 - wathermelon
            {regularDelay: 50, payment: [0, 0, 0, 8, 20, 50]},      // 5 - grape
            {regularDelay: 50, payment: [0, 0, 0, 8, 25, 60]},      // 6 - bananas
            {regularDelay: 50, payment: [0, 0, 4, 10, 30, 80]},     // 7 - apple
            {regularDelay: 50, payment: [0, 0, 5, 10, 40, 100]},    // 8 - bell
            {regularDelay: 50, payment: [0, 0, 10, 50, 250, 1000]}, // 9 - 7
            {regularDelay: 50, payment: [0, 0, 0, 5, 20, 200]}      // 10 - star
        ];

        this.imageResources = {
            main: this.mergePath({mainArea: 'area/main.png'}),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                minimizeSymbols: 'minimizeSymbols.png',
                gambleArea: 'area/gamble.png'
            })
        };

        this.gameSounds = {
            soundClass: 'egt',
            sounds: [],
            path: `/game/games/${this.id}/audio/`
        };
        this.Lines = new Lines(this.mergePath({boxes: 'lines/boxes.png'}));
        this.Gamble = new GambleEgt();
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
        this.InfoScreen.format = 'png';
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = 'bold 19pt Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = 'white';
        ctx.shadowColor = 'black';
        ctx.shadowOffsetX = ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 5;

        switch (page) {
            case 1:
                ctx.font = 'bold 12pt Arial';

                // cherry, lemon,
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 608, 388);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 608, 408);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 608, 426);
                // plum, orange
                this.strokeFillText(ctx, bet * this.symbols[2].payment[5], 400, 374);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[4], 400, 392);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[3], 400, 411);
                // wathermelon
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 200, 388);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 200, 408);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 200, 426);
                // Grape
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 655, 292);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 655, 310);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 655, 329);
                // Bananas
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 500, 292);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 500, 310);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 500, 329);
                // Bananas
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], 330, 284);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], 330, 301);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 330, 318);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[2], 330, 333);
                // Bananas
                this.strokeFillText(ctx, bet * this.symbols[8].payment[5], 175, 284);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[4], 175, 301);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[3], 175, 318);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[2], 175, 333);
                // Seven 7
                this.strokeFillText(ctx, bet * this.symbols[9].payment[5], 290, 137);
                this.strokeFillText(ctx, bet * this.symbols[9].payment[4], 290, 154);
                this.strokeFillText(ctx, bet * this.symbols[9].payment[3], 290, 172);
                this.strokeFillText(ctx, bet * this.symbols[9].payment[2], 290, 190);
                // Star
                this.strokeFillText(ctx, bet * nLines * this.symbols[10].payment[5], 500, 145);
                this.strokeFillText(ctx, bet * nLines * this.symbols[10].payment[4], 500, 161);
                this.strokeFillText(ctx, bet * nLines * this.symbols[10].payment[3], 500, 179);
                break;
            case 2:
                ctx.font = 'bold 13pt Arial';
                ctx.textAlign = 'left';
                // gamble limit
                this.strokeFillText(ctx, (this.Gamble.limit * 100) / App.Money.getCurrentDenomination(), 480, 235);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }
}
