import {JL} from 'jsnlog';

import Lines10 from './../../amatic/lines10';

export default class Lines extends Lines10 {
    constructor(imageResources) {
        super(imageResources);
        this.imageResources = {
            ...this.imageResources,
            ...imageResources // redefine base images from game
        };
        this.linesColor = [
            '#ff0100',
            '#2900ff',
            '#2bd900',
            '#e8e300',
            '#9c00e5',
            '#f18f4e',
            '#009ee1',
            '#97bf0d',
            '#ca9651',
            '#ff00ff'
        ];

        Object.keys(this.lines).forEach((index, i) =>
            this.lines[index].color = this.linesColor[i]);
        JL().debug('-- Lines10 initialized');
    }
}
