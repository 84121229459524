import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../../../index';
import GameDeluxe5x4 from './../../deluxe/gameDeluxe5x4';
import Lines40 from './../../deluxe/lines40';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';
import bonusFont from './img/font/bonusFont';

/* PIXI aliases */
const Container = PIXI.Container,
    AnimatedSprite = PIXI.AnimatedSprite;

export default class TheBigCatch extends GameDeluxe5x4 {
    constructor() {
        super();
        this.id = 'the-big-catch';
        this.name = 'The Big Catch';

        this.frizenJoker = [[0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0]];
        this.frameJoker = [[0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0]];
        this.oldFrameJoker = [[0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0]];
        this.shadowRoll = false;
        this.respinFlag = false;
        this.freezedReel = [0, 0, 0, 0, 0];

        // roll properties
        this.reelSettings = [14, 5, 26]; // 0 - start symbol amount, 1 - reel increase regular roll, 2 - reel increase long roll
        this.scatter = 12;
        this.scatters = [10, 11, 12];
        this.reelFilter = [[10, 11], [9, 10, 11], [10, 11], [9, 10, 11, 12], [10, 11, 12]];
        this.allowLongRoll = true;
        this.longRollSettings = [0, 2]; // long roll from reelIndex to reelIndex

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 30, y: 30},
            bonusInBonusFrame: {x: 30, y: 30},
            endBonusFrame: {x: 30, y: 30}
        };

        this.symbols = [
            {regularDelay: 100, payment: [0, 0, 0, 4, 20, 50]},   // 0 - 9
            {regularDelay: 100, payment: [0, 0, 0, 4, 20, 50]},   // 1 - 10
            {regularDelay: 100, payment: [0, 0, 0, 4, 20, 50]},   // 2 - J
            {regularDelay: 100, payment: [0, 0, 0, 6, 30, 60]},   // 3 - Q
            {regularDelay: 100, payment: [0, 0, 0, 6, 30, 60]},   // 4 - K
            {regularDelay: 100, payment: [0, 0, 0, 6, 30, 60]},   // 5 - A
            {regularDelay: 100, payment: [0, 0, 0, 10, 40, 80]},  // 6 - White flower
            {regularDelay: 100, payment: [0, 0, 0, 10, 40, 80]},  // 7 - Red flower
            {regularDelay: 100, payment: [0, 0, 0, 16, 42, 100]}, // 8 - Fish
            {regularDelay: 100, payment: [0, 0, 0, 16, 42, 100]}, // 9 - Hamster
            {regularDelay: 100, payment: [0, 0, 2, 20, 50, 150]}, // 10 - Bear (4 bear 2 respin)
            {regularDelay: 100, payment: [0, 0, 0, 0, 0, 0]},     // 11 - Wild (Lake and mountains)
            {regularDelay: 100, payment: [0, 0, 0, 0, 0, 0]}      // 12 - Scatter (WatterFall)
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                bonusFont: bonusFont['imageResource']
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                bonusArea: `area/${App.System.resolution}/bonus.png`,
                frame: 'bonus/bonus1.png'
            }),
            atlas: this.mergePath([
                'regularLongSymbols.json',
                'bonus/bonusSymbols.json',
                'bonus/additionalSymbols.json'
            ])
        };

        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'bonus-game-end', alias: 'bonusGameEnd'},
                {name: 'bonus-background', loop: true},
                {name: 'scatter-teaser', alias: 'teaser_1'},  // play after 2 scatters appeared
                {name: 'long1'},
                {name: 'respin-stop'}
            ],
            path: `/game/games/${this.id}/audio/`
        };

        this.Lines = new Lines40();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png',
            activeBlack: 'gamble/black-active.png',
            inactiveBlack: 'gamble/black-inactive.png',
            activeRed: 'gamble/red-active.png',
            inactiveRed: 'gamble/red-inactive.png'

        }));
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.strokeStyle = '#000';
        ctx.fillStyle = '#fff';
        ctx.lineWidth = 2;
        ctx.textAlign = 'center';

        switch (page) {
            case 1:
                ctx.font = '18pt Arial';

                // Bear
                this.strokeFillText(ctx, bet * this.symbols[10].payment[5], 125, 138);
                this.strokeFillText(ctx, bet * this.symbols[10].payment[4], 125, 166);
                this.strokeFillText(ctx, bet * this.symbols[10].payment[3], 125, 194);
                this.strokeFillText(ctx, bet * this.symbols[10].payment[2], 125, 222);

                // Fish Hamster
                this.strokeFillText(ctx, bet * this.symbols[8].payment[5], 125, 346);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[4], 125, 374);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[3], 125, 402);

                // AKQ
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 710, 346);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 710, 374);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 710, 402);

                // flowers
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 125, 518);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 125, 546);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 125, 574);

                // 9 10 j
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 690, 518);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 690, 546);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 690, 574);

                let textProps = {
                    parentContainer: ctx,
                    fontImageName: 'bonusFont',
                    map: bonusFont,
                    align: 'center',
                    scale: 0.6,
                    fontInterval: -5 // px between symbols
                };
                this.drawCustomFont('SUBSTITUTES for ALL', 500, 110, textProps);
                this.drawCustomFont('symbols EXCEPT', 500, 150, textProps);

                textProps = {
                    parentContainer: ctx,
                    fontImageName: 'bonusFont',
                    map: bonusFont,
                    align: 'center',
                    scale: 0.5,
                    fontInterval: -4 // px between symbols
                };
                this.drawCustomFont('4 bears appearing on reel 1', 430, 250, textProps);
                this.drawCustomFont('trigger 2 respins. During respins', 430, 290, textProps);
                this.drawCustomFont('all       and       are held.', 430, 330, textProps);
                break;
            case 2:
                textProps = {
                    parentContainer: ctx,
                    fontImageName: 'bonusFont',
                    map: bonusFont,
                    align: 'center',
                    scale: 0.9,
                    fontInterval: -4 // px between symbols
                };
                this.drawCustomFont('SUBSTITUTES for ALL', 400, 40, textProps);
                this.drawCustomFont('symbols EXCEPT', 400, 80, textProps);

                textProps = {
                    parentContainer: ctx,
                    fontImageName: 'bonusFont',
                    map: bonusFont,
                    align: 'center',
                    scale: 0.75,
                    fontInterval: -4 // px between symbols
                };
                this.drawCustomFont('3 Scatter symbols trigger', 590, 260, textProps);
                this.drawCustomFont('12 Free Games.', 590, 300, textProps);

                textProps = {
                    parentContainer: ctx,
                    fontImageName: 'bonusFont',
                    map: bonusFont,
                    align: 'center',
                    scale: 0.65,
                    fontInterval: -4 // px between symbols
                };
                this.drawCustomFont('on reels 1, 2, 3', 200, 335, textProps);

                textProps = {
                    parentContainer: ctx,
                    fontImageName: 'bonusFont',
                    map: bonusFont,
                    align: 'left',
                    scale: 0.7,
                    fontInterval: -4 // px between symbols
                };
                this.drawCustomFont('4 bears appearing on reel 1 trigger 2 respins.', 120, 400, textProps);
                this.drawCustomFont('All symbols     and     appearing in the', 120, 440, textProps);
                this.drawCustomFont('triggering spin and during the 2 respins are held.', 120, 480, textProps);
                this.drawCustomFont('Line wins are evaluated after the last respin.', 120, 520, textProps);
                break;
            case 3:
                textProps = {
                    parentContainer: ctx,
                    fontImageName: 'bonusFont',
                    map: bonusFont,
                    align: 'center',
                    scale: 0.7,
                    fontInterval: -4 // px between symbols
                };
                const textProps1 = {
                    parentContainer: ctx,
                    fontImageName: 'bonusFont',
                    map: bonusFont,
                    align: 'center',
                    scale: 1,
                    fontInterval: -4 // px between symbols
                };
                this.drawCustomFont('RULES', 400, 100, textProps1);
                this.drawCustomFont('All prizes are for combinations of a kind. All prizes are', 400, 160, textProps);
                this.drawCustomFont('for combinations left to right, except scatters. All', 400, 190, textProps);
                this.drawCustomFont('prizes are on selected lines, except scatters. Scatter', 400, 220, textProps);
                this.drawCustomFont('symbols  pay at any position on screen. Highest win only', 400, 250, textProps);
                this.drawCustomFont('paid per selected line. Scatter wins are added to line', 400, 280, textProps);
                this.drawCustomFont('wins. Free Games are played at trigger bet and lines.', 400, 310, textProps);
                this.drawCustomFont('Free Games can be won again during the Free Games', 400, 340, textProps);
                this.drawCustomFont('All prizes shown in credits. Malfunction voids all pays', 400, 370, textProps);
                this.drawCustomFont('and plays.', 400, 400, textProps);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    /**
     * Отрисовка таблички бонусной игры
     */
    showStartBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y);

        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 1.2,
            fontInterval: -3 // px between symbols
        };
        this.drawCustomFont('12 FREE GAMES', 400, 255, textProps);
    }

    /**
     * Отрисовка таблички бонусной игры
     */
    showRespinBonusFrame(parentContainer, x, y) {
        this.showBonusFrame(parentContainer, x, y);

        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 1.2,
            fontInterval: -3 // px between symbols
        };
        this.drawCustomFont('2 free respins won', 420, 255, textProps);
    }

    /**
     * Отрисовка таблички бонусной игры
     */
    showBonusInBonusFrame(parentContainer, {x, y}) {
        this.showBonusFrame(parentContainer, x, y);

        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 1.2,
            fontInterval: -3 // px between symbols
        };
        this.drawCustomFont('12 MORE FREE GAMES', 400, 255, textProps);
    }

    /**
     * Отрисовка таблички окончания бонусной игры
     */
    showEndBonusFrame(parentContainer, {x, y}, {win, total}) {
        this.showBonusFrame(parentContainer, x, y);

        const textProps = {
            parentContainer,
            fontImageName: 'bonusFont',
            map: bonusFont,
            align: 'center',
            scale: 1.2,
            fontInterval: -3 // px between symbols
        };
        this.drawCustomFont('FEATURE WIN', 400, 150, textProps);
        this.drawCustomFont(`${win} CREDITS`, 400, 200, textProps);

        this.drawCustomFont(`${total} FREE GAMES`, 400, 280, textProps);
        this.drawCustomFont('PLAYED', 400, 320, textProps);
    }

    onRotationDone() {
        JL().debug(`-- Rotation done (fps: ${App.System.statistics.fps})`);
        App.updateButton('start', {disabled: true});
        this.roundWin = 0;

        this.latestResponse.extension ?
            this.addFrozenJoker(this.latestResponse.screen) : // bonus animation and respin no win line eat
            this.isBonus() ?
                this.bonusRotationDone() :
                this.regularRotationDone();
    }

    regularRotationDone() {
        const {features, payment} = this.latestResponse;
        this.respinFlag = false; // mark no respin game
        if (payment > 0 || this.isFreeRoll(features) || features.length) {
            // There is  win
            this.setState('SHOW_WIN_LINES');
            this.startAnimateFeature(features);
        } else {
            // There is lose
            this.roundFinished();
            this.Legends.setRoundFinText();
        }
    }

    bonusRotationDone() {
        const {features, payment} = this.latestResponse;
        this.respinFlag = false; // mark no respin game
        if (payment > 0 || this.isFreeRoll(features) || features.length) {
            // There is  win
            this.setState('SHOW_WIN_LINES');
            this.startAnimateFeature(features);
        } else {
            if (this.bonusStatus && this.bonusStatus.remain > 0) {
                this.roundFinished(false);
            } else {
                this.Legends.setRoundFinText();
                this.finishBonus();
            }
        }
    }

    addFrozenJoker(screen) {
        App.Sounds.stopSound('bonus-background');
        const tepmFrameJoker = [[0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0]]; // 0 not selected, 0 joker possition, 1234- frame type

        screen.forEach((reel, reelIndex) => {
            reel.forEach((symbol, rowIndex) => {
                if (symbol === 10 || symbol === 11) { // bear and wild should be frized
                    if (this.frizenJoker[reelIndex][rowIndex] === 0) {
                        this.frizenJoker[reelIndex][rowIndex] = {};
                        this.frizenJoker[reelIndex][rowIndex].symbol = symbol;
                        this.frizenJoker[reelIndex][rowIndex].clip = this.createJoker(symbol);
                    }
                    this.frizenJoker[reelIndex][rowIndex].clip.position.x = this.reelXCoordinates[reelIndex] - this.reelXCoordinates[0];
                    this.frizenJoker[reelIndex][rowIndex].clip.position.y = rowIndex * this.symbolHeight;
                }
            });
        });

        this.frizenJoker.forEach((reel, reelIndex) => { // rebuild frame
            reel.forEach((symbol, rowIndex) => {
                if (symbol !== 0) {
                    tepmFrameJoker[reelIndex][rowIndex] = 1;
                }
            });
        });

        tepmFrameJoker.forEach((reel, reelIndex) => { // rebuild frame
            reel.forEach((symbol, rowIndex) => {
                if (symbol === 1) {
                    if (rowIndex === 0 && tepmFrameJoker[reelIndex][rowIndex + 1] !== 0 && tepmFrameJoker[reelIndex][rowIndex + 2] !== 0 && tepmFrameJoker[reelIndex][rowIndex + 3] !== 0) {
                        tepmFrameJoker[reelIndex][rowIndex + 1] = 0;
                        tepmFrameJoker[reelIndex][rowIndex + 2] = 0;
                        tepmFrameJoker[reelIndex][rowIndex + 3] = 0;
                        tepmFrameJoker[reelIndex][rowIndex] = 4;
                        this.freezedReel[reelIndex] = 1;
                    } else if (rowIndex < 2 && tepmFrameJoker[reelIndex][rowIndex + 1] !== 0 && tepmFrameJoker[reelIndex][rowIndex + 2] !== 0) {
                        tepmFrameJoker[reelIndex][rowIndex + 1] = 0;
                        tepmFrameJoker[reelIndex][rowIndex + 2] = 0;
                        tepmFrameJoker[reelIndex][rowIndex] = 3;
                    } else if (rowIndex < 3 && tepmFrameJoker[reelIndex][rowIndex + 1] !== 0) {
                        tepmFrameJoker[reelIndex][rowIndex + 1] = 0;
                        tepmFrameJoker[reelIndex][rowIndex] = 2;
                    } else if (rowIndex < 4 && tepmFrameJoker[reelIndex][rowIndex] !== 0) {
                        tepmFrameJoker[reelIndex][rowIndex] = 1;
                    }

                    if (this.oldFrameJoker[reelIndex][rowIndex] !== tepmFrameJoker[reelIndex][rowIndex]) {  // new or rebuilded farme
                        if (this.frameJoker[reelIndex][rowIndex] !== 0) {                                   // clear old frame to rebuild it
                            this.frameJoker[reelIndex][rowIndex].visible = false;
                        }
                        this.frameJoker[reelIndex][rowIndex] = this.createFrame(tepmFrameJoker[reelIndex][rowIndex] - 1);   // add new  frame
                        this.frameJoker[reelIndex][rowIndex].position.x = this.reelXCoordinates[reelIndex] - this.reelXCoordinates[0] - 6;
                        this.frameJoker[reelIndex][rowIndex].position.y = rowIndex * this.symbolHeight;

                        this.oldFrameJoker[reelIndex][rowIndex] = tepmFrameJoker[reelIndex][rowIndex];
                        this.frameJoker[reelIndex][rowIndex].play();
                    }
                }
            });
        });

        setTimeout(() => {
            this.frameJoker.forEach((reel, reelIndex) => {
                reel.forEach((symbol, rowIndex) => {
                    if (symbol !== 0) {
                        this.frameJoker[reelIndex][rowIndex].gotoAndStop(0);
                    }
                });
            });
            this.isNextRollRespin() ?
                this.prepareToRespin() :
                this.finishRespin();
        }, 2500);
    }

    finishRespin() {
        this.bonusStart = false;
        this.respinFlag = false; // mark no respin game
        this.clearFrozenJoker();
        this.clearFrameJoker();
        this.Legends.clearStatus();

        this.isBonus() ?
            this.bonusRotationDone() :
            this.regularRotationDone();
    }

    createJoker(symbol) {
        const textures = this.Roll.textures.regular[symbol];
        const sprite = new AnimatedSprite(textures);

        sprite.animationSpeed = 0.3;
        sprite.visible = true;
        const parentContainer = this.getStageChild('bonusAditional');
        parentContainer.addChild(sprite);

        return sprite;
    }

    createFrame(symbol) {
        const textures = this.Roll.textures.bonus[symbol];
        const sprite = new AnimatedSprite(textures);

        sprite.animationSpeed = 0.3;
        sprite.visible = true;
        const parentContainer = this.getStageChild('bonusAditional');
        parentContainer.addChild(sprite);

        return sprite;
    }

    isNextRollRespin() {
        let respin = false;
        if (this.latestResponse.extension) {
            if (this.latestResponse.extension.screens.length) {
                respin = true;
            }
        }
        return respin;
    }

    //
    // ======================== RESPIN SECTION =============================
    //

    prepareToRespin() {
        if (this.latestResponse.extension.screens.length === 2) {   // first respin
            this.setState('SHOW_RESPIN_FRAME');
            App.updateButton('start', {disabled: true});
            setTimeout(() => this.drawBonusAskButtonRespin(true), 1000);
        } else {
            setTimeout(() => this.respin(), 1000);              // second respin no bonus ask button
        }
    }

    respin = () => {
        JL().debug(`-- Respin - ${JSON.stringify(this.latestResponse.extension.respin)}`);
        this.clearPressAnyButton();
        const container = this.getStageChild('bonusContainer');
        container.removeChildren();
        const spin = this.latestResponse.extension.screens.length === 1 ? 2 : 1;
        this.Legends.setStatus('additionalRoll', spin);
        App.updateButton('start', {disabled: true});

        // save total payment before respin
        const tempPayment = this.latestResponse.payment;

        this.latestResponse.screen = this.latestResponse.extension.screens[0][0];  // take fisrt respin screen
        this.latestResponse.extension.screens.splice(0, 1);  // delete it from roll to prevent respin bug
        this.respinFlag = true; // flag to mark respin roll

        this.processReelResponse(this.latestResponse);

        // redefine respin payment to total
        this.latestResponse.payment = tempPayment;
    };

    isReelFreezed = reel => this.freezedReel[reel];

    drawBonusAskButtonRespin() {
        JL().debug(`-- Set bonus ask button (isFirstBonus: Respin)`);
        this.stopAnimateFeature();

        this.gameFlag.bonusStart = true;
        this.roundWin && this.Legends.setText('win', {text: 'win', value: this.roundWin});
        this.showRespinBonusFrame(this.getStageChild('bonusContainer'), this.coordinatesBonusFrame.startBonusFrame.x, this.coordinatesBonusFrame.startBonusFrame.y);

        setTimeout(() => this.respin(), 2000);
    }

    setBonusRollSymbol() {
        this.bonusRollSymbol = null;
    }

    clearFrozenJoker() {
        this.frizenJoker.forEach((reel, reelIndex) => {
            reel.forEach((symbol, rowIndex) => {
                if (symbol !== 0) {
                    const symbol = this.frizenJoker[reelIndex][rowIndex].symbol;
                    const symbolObj = this.reelMatrix[reelIndex][rowIndex];
                    symbolObj.image = 'regular';
                    symbolObj.symbol = symbol;
                    this.latestResponse.screen[reelIndex][rowIndex] = symbol;
                    this.Roll.updateSymbolSprite(symbolObj);

                    const parentContainer = this.getStageChild('bonusAditional');
                    parentContainer.removeChild(this.frizenJoker[reelIndex][rowIndex].clip);
                    this.frizenJoker[reelIndex][rowIndex] = 0;
                    this.freezedReel[reelIndex] = 0;
                    if (symbol !== 0) {
                        const parentContainer = this.getStageChild('bonusAditional');
                        parentContainer.removeChild(symbol.clip);
                    }
                }
            });
        });
    }

    clearFrameJoker() {
        this.frameJoker.forEach((reel, reelIndex) => {
            reel.forEach((symbol, rowIndex) => {
                if (symbol !== 0 && symbol !== 1) {
                    const parentContainer = this.getStageChild('bonusAditional');
                    parentContainer.removeChild(this.frameJoker[reelIndex][rowIndex]);
                }
                this.frameJoker[reelIndex][rowIndex] = 0;
                this.oldFrameJoker[reelIndex][rowIndex] = 0;
            });
        });
    }

    /**
     * Get long roll parameters
     *  -1 no long roll
     *  0 1 2 3 4 5 number of reel
     */
    getLongRoll(screen) {
        const scatterMap = [];
        screen.forEach((vector, reelIndex) => {
            vector.forEach(symbol => {
                if ((symbol === this.scatters[2]) && reelIndex < 2) { // do not use last reel scatter
                    scatterMap.push(reelIndex); // correct last reel with extrabet
                }
            });
        });

        let longRollType = scatterMap.length > 1 ? 2 : -1; // get possible long roll type

        longRollType = this.allowLongRoll ? longRollType : -1;  // enable or disable long roll by this.allowLongRoll

        if (screen[0][0] === 10 && screen[0][1] === 10 && screen[0][2] === 10 && screen[0][3] === 10 || this.respinFlag) {
            longRollType = 1;
            this.longRollSettings = [0, 5];
        } else {
            this.longRollSettings = [0, 2];
        }
        this.reelSymbol.forEach((symbolAmount, reelIndex) => {
            this.reelLong[reelIndex] =
                (reelIndex >= longRollType && longRollType !== -1 &&
                    reelIndex >= this.longRollSettings[0] && reelIndex <= this.longRollSettings[1]) ?
                    1 : 0;
        });
        return this.reelLong; // get possible long roll type
    }

    getShadowRoll(screen) {
        let shadowRoll = false;

        if (screen[0][0] === 10 && screen[0][1] === 10 && screen[0][2] === 10 && screen[0][3] === 10 || this.respinFlag) {
            shadowRoll = true;
        }
        return shadowRoll;
    }

    /**
     * Process reels response from server.
     * @param response - Socket response 'ROLL'
     */
    processReelResponse(response) {
        this.latestResponse = response;
        this.setState('RESPONSE_RECEIVED');
        this.setBonusRollSymbol(); // for bonus game roll symbol
        this.shadowRoll = this.getShadowRoll(response.screen);
        this.prepareToRollAnimation(response);
    }

    additionalFilter = reelMas => {
        const scatterMas = [10, 11];
        reelMas.forEach((vector, reelIndex) => {     // прорисовка лент на канвах
            const length = vector.length;
            vector.forEach((symbol, rowIndex) => {
                for (let i = 0; i < 2; i++) {
                    const scatter = scatterMas[i];

                    // new screen bear padding
                    if ((rowIndex === 1 && symbol === scatter) && ((reelMas[reelIndex][rowIndex + 1] !== scatter) || (reelMas[reelIndex][rowIndex + 2] !== scatter) || (reelMas[reelIndex][rowIndex + 3] !== scatter))) { // add uppe bear
                        reelMas[reelIndex][rowIndex - 1] = scatter;
                    }

                    if ((rowIndex === 2 && symbol === scatter) && (reelMas[reelIndex][rowIndex - 1] !== scatter)) {
                        reelMas[reelIndex][rowIndex + 1] = scatter;
                        reelMas[reelIndex][rowIndex + 2] = scatter;
                        reelMas[reelIndex][rowIndex + 3] = scatter;
                    }
                    if ((rowIndex === 3 && symbol === scatter) && (reelMas[reelIndex][rowIndex - 1] === scatter) && (reelMas[reelIndex][rowIndex - 2] !== scatter)) {
                        reelMas[reelIndex][rowIndex + 1] = scatter;
                        reelMas[reelIndex][rowIndex + 2] = scatter;
                    }

                    if ((rowIndex === 4 && symbol === scatter) && (reelMas[reelIndex][rowIndex - 1] !== scatter)) {
                        reelMas[reelIndex][rowIndex + 1] = scatter;
                        reelMas[reelIndex][rowIndex + 2] = scatter;
                        reelMas[reelIndex][rowIndex + 3] = scatter;
                    }

                    if ((rowIndex === 4 && symbol === scatter) && (reelMas[reelIndex][rowIndex - 1] === scatter) && (reelMas[reelIndex][rowIndex - 2] !== scatter)) {
                        reelMas[reelIndex][rowIndex + 1] = scatter;
                        reelMas[reelIndex][rowIndex + 2] = scatter;
                    }

                    if ((rowIndex === 4 && symbol === scatter) && (reelMas[reelIndex][rowIndex - 1] === scatter) && (reelMas[reelIndex][rowIndex - 2] === scatter) && (reelMas[reelIndex][rowIndex - 3] === scatter) && (reelMas[reelIndex][rowIndex - 4] !== scatter)) {
                        reelMas[reelIndex][rowIndex + 1] = scatter;
                    }

                    if ((rowIndex === length - 5 && symbol === scatter) && (reelMas[reelIndex][length - 5 + 1] !== scatter)) {
                        reelMas[reelIndex][length - 5 - 1] = scatter;
                        reelMas[reelIndex][length - 5 - 2] = scatter;
                        reelMas[reelIndex][length - 5 - 3] = scatter;
                    }
                    if ((rowIndex === length - 5 && symbol === scatter) && (reelMas[reelIndex][length - 5 + 1] === scatter) && (reelMas[reelIndex][length - 5 + 2] !== scatter)) {
                        reelMas[reelIndex][length - 5 - 1] = scatter;
                        reelMas[reelIndex][length - 5 - 2] = scatter;
                    }

                    if ((rowIndex === length - 5 && symbol === scatter) && (reelMas[reelIndex][length - 5 + 1] === scatter) && (reelMas[reelIndex][length - 5 + 2] === scatter) && (reelMas[reelIndex][length - 5 + 3] !== scatter)) {
                        reelMas[reelIndex][length - 5 - 1] = scatter;
                    }
                }
            });
        });
        for (let i = 0; i < 5; i++) {
            this.Roll.beforeScreenSymbols[i] = reelMas[i][0];
        }
        return reelMas;
    };

    playRollSound = () => {
        if (this.shadowRoll) {
            App.Sounds.stopSound('bonus-background');
            App.Sounds.playSound('long1');
        } else {
            this.gameFlag.bonusStart || App.restoreGameState === 'BONUS' ?
                App.Sounds.playSound('bonus_reels') :
                App.Sounds.playSound('reels');
        }
    };

    setRegularLongSprite(featureLength, symbolClip) {
        if (
            featureLength > 3 &&
            this.symbols[symbolClip.symbol].regularLongSteps
        ) {
            symbolClip.image = 'regularLong';
        }
    }

    /**
     * Prepare game behaviour after bonus 'press any button' message
     */
    startBonusAnimation = () => {
        this.setState('IDLE_BONUS');
        App.Sounds.stopSound('banner-win');
        App.Sounds.playSound('bonus-background');
        this.gameFlag.bonusStarted = true;
        this.clearPressAnyButton();
        this.Buttons.disableAllButtons();
        App.updateButton('start', {disabled: true});
        this.bonusRollSymbol = null; // reset bonus symbol
        const parentContainer = this.getStageChild('bonusContainer');
        parentContainer.removeChildren();
        this.startBonusRoll();
    };

    playLongRollSound = reelIndex => {
        if (reelIndex === 2 && this.reelLong[2] === 1 && this.reelLong[3] === 0) {  // current reel without last reel with extraBet correction
            this.stopRollSound();
            App.Sounds.playSound('teaser_1');
        }
    };

    playRespinSound = () => {
        App.Sounds.playSound('long1');
    };

    /**
     * function to decide play scatter teaser sound based on reel
     */
    stopLongRollSound = () => {
        this.stopScatterSound();
        App.Sounds.stopSound('long1');
        App.Sounds.stopSound('long2');
        App.Sounds.stopSound('long3');
        App.Sounds.stopSound('long4');
        App.Sounds.stopSound('long5');
        App.Sounds.stopSound('long6');
    };

    /**
     * Check symbol image
     * Can be redefined to 'regular'/'bonus'/'additional'
     * Call for Roll.initReels() and Roll.updateFullClipMatrix()
     */
    getSymbolImageType = (symbolIndex, reelIndex, rowIndex) => {
        let image = 'static';
        const reelLength = this.reelSymbol[reelIndex];
        if (
            this.shadowRoll &&
            (reelIndex > 0 && (rowIndex < reelLength - this.reelSymbol[0] + 5) || this.respinFlag) &&
            symbolIndex !== 10 && symbolIndex !== 11
        ) {
            image = 'additional';
        }
        return image;
    };

    setScatterSprite(scatterFeature) {
        scatterFeature.positions.forEach(position => {
            const {reel, row} = position;
            const symbolObj = this.reelMatrix[reel][row];
            symbolObj.image = 'regular';
            symbolObj.loop = false;
            this.Roll.updateSymbolSprite(symbolObj);
            symbolObj.sprite.play();
        });
    }

    /**
     * Create PIXI.Container for game bonus animations
     * Add additional sprites to container
     * @param parentContainer
     */
    createBonusContainer = parentContainer => {
        const additionalContainer = new Container();
        additionalContainer.name = 'bonusAditional';
        additionalContainer.zIndex = this.containersLayers[additionalContainer.name];
        parentContainer.addChild(additionalContainer);
        additionalContainer.position.x = this.reelXCoordinates[0];
        additionalContainer.position.y = this.reelTop;

        const container = new Container();
        container.name = 'bonusContainer';
        container.zIndex = this.containersLayers[container.name];
        parentContainer.addChild(container);
    };
}
