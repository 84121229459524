import {JL} from 'jsnlog';
import * as PIXI from 'pixi.js-legacy';

import Lines from './../../../../lines';
import App from './../../../../../index';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Texture = PIXI.Texture,
    Graphics = PIXI.Graphics;

export default class Lines10 extends Lines {
    constructor() {
        super();
        this.imageResources = {
            boxes: './../game/games/star-burst/img/lines/boxes.png',
            boxesActive: './../game/games/star-burst/img/lines/boxes_active.png',
            lines0: './../game/games/star-burst/img/lines/lines0.png',            // colored lines
            lines1: './../game/games/star-burst/img/lines/lines1.png',
            lines2: './../game/games/star-burst/img/lines/lines2.png',
            lines3: './../game/games/star-burst/img/lines/lines3.png',
            lines4: './../game/games/star-burst/img/lines/lines4.png',
            lines5: './../game/games/star-burst/img/lines/lines5.png',
            lines6: './../game/games/star-burst/img/lines/lines6.png'
        };

        this.containersLayers = {
            linesContainer: 1,
            boxesContainer: 2,
            reelsStage: 3,
            mainContainer: 4,
            extraBetContainer: 5,
            bonusContainer: 6,
            symbolInfo: 7
        };
        this.deltaY = 0;
        this.lineBoxWidth = 75;
        this.lineBoxHeight = 47;
        this.boxMargin = 0; // margin between box and line
        this.horizontalLineLength = 60;

        this.pointPosX = [80, 290, 500, 700, 880]; // central position X on reels
        this.pointPosY = [178, 340, 506]; // ... Y on rows

        this.lines = {
            0: {
                coordinates: [1, 1, 1, 1, 1],
                boxes: [{x: -70, y: 345}, {x: 980, y: 345}],
                path: [
                    {x: 0, y: 64},
                    {x: 187, y: 64},
                    {x: 329, y: 64},
                    {x: 471, y: 64},
                    {x: 613, y: 64}
                ],
                lineDeltaY: 0,
                color: 0xf2f0b0
            },
            1: {
                coordinates: [0, 0, 0, 0, 0],
                boxes: [{x: -70, y: 155}, {x: 980, y: 155}],
                path: [
                    {x: 0, y: 64},
                    {x: 187, y: 64},
                    {x: 329, y: 64},
                    {x: 471, y: 64},
                    {x: 613, y: 163}
                ],
                lineDeltaY: 0,
                color: 0xf2f0b0
            },
            2: {
                coordinates: [2, 2, 2, 2, 2],
                boxes: [{x: -70, y: 483}, {x: 980, y: 483}],
                path: [
                    {x: 0, y: 64},
                    {x: 187, y: 64},
                    {x: 329, y: 64},
                    {x: 471, y: 162},
                    {x: 613, y: 259}
                ],
                lineDeltaY: 0,
                color: 0xf2f0b0
            },
            3: {
                coordinates: [0, 1, 2, 1, 0],
                boxes: [{x: -70, y: 108}, {x: 980, y: 108}],
                path: [
                    {x: 0, y: 65},
                    {x: 187, y: 65},
                    {x: 329, y: 164},
                    {x: 471, y: 65},
                    {x: 613, y: 65}
                ],
                lineDeltaY: -10,
                color: 0xf2f0b0
            },
            4: {
                coordinates: [2, 1, 0, 1, 2],
                boxes: [{x: -70, y: 529}, {x: 980, y: 529}],
                path: [
                    {x: 0, y: 161},
                    {x: 187, y: 161},
                    {x: 329, y: 161},
                    {x: 471, y: 162},
                    {x: 612, y: 63}
                ],
                lineDeltaY: 0,
                color: 0xf2f0b0
            },
            5: {
                coordinates: [0, 0, 1, 0, 0],
                boxes: [{x: -70, y: 206}, {x: 980, y: 206}],
                path: [
                    {x: 0, y: 161},
                    {x: 187, y: 161},
                    {x: 329, y: 63},
                    {x: 471, y: 161},
                    {x: 613, y: 161}
                ],
                lineDeltaY: 0,
                color: 0xf2f0b0
            },
            6: {
                coordinates: [2, 2, 1, 2, 2],
                boxes: [{x: -70, y: 436}, {x: 980, y: 436}],
                path: [
                    {x: 0, y: 161},
                    {x: 187, y: 161},
                    {x: 329, y: 161},
                    {x: 471, y: 161},
                    {x: 613, y: 161}
                ],
                lineDeltaY: 0,
                color: 0xf2f0b0
            },
            7: {
                coordinates: [1, 2, 2, 2, 1],
                boxes: [{x: -70, y: 389}, {x: 980, y: 389}],
                path: [
                    {x: 0, y: 161},
                    {x: 187, y: 161},
                    {x: 329, y: 161},
                    {x: 471, y: 161},
                    {x: 612, y: 259}
                ],
                lineDeltaY: 0,
                color: 0xf2f0b0
            },
            8: {
                coordinates: [1, 0, 0, 0, 1],
                boxes: [{x: -70, y: 250}, {x: 980, y: 250}],
                path: [
                    {x: 0, y: 161},
                    {x: 187, y: 161},
                    {x: 329, y: 259},
                    {x: 471, y: 161},
                    {x: 612, y: 161}
                ],
                lineDeltaY: 0,
                color: 0xf2f0b0
            },
            9: {
                coordinates: [1, 0, 1, 0, 1],
                boxes: [{x: -70, y: 299}, {x: 980, y: 299}],
                path: [
                    {x: 0, y: 162},
                    {x: 187, y: 162},
                    {x: 329, y: 162},
                    {x: 471, y: 259},
                    {x: 613, y: 357}
                ],
                lineDeltaY: 0,
                color: 0xf2f0b0
            }
        };

        JL().debug('-- Lines40 initialized');
    }

    setLinePath(lineIndex, reelIndex, rowIndex) {
        const line = this.lines[lineIndex], {lineDeltaY = 0} = line;
        line.path[reelIndex].x = this.pointPosX[reelIndex];
        line.path[reelIndex].y = this.pointPosY[rowIndex] + lineDeltaY;
    }

    getLineStartPos(lineIndex) {
        const line = this.lines[lineIndex], {lineDeltaY = 0} = line;
        return {
            x: this.pointPosX[0] - 30,
            y: this.pointPosY[this.lines[lineIndex].coordinates[0]] + lineDeltaY
        };
    }

    drawLine(parentContainer, graphics, lineIndex, reelIndex) {
        const line = this.lines[lineIndex],
            {x, y} = line.path[reelIndex];
        graphics.lineTo(x, y);
        parentContainer.addChildAt(graphics, 0); // add under winBox
    }

    drawWinBox(parentContainer, lineIndex, reelIndex, rowIndex) {
        const lineKey = lineIndex,
            borderWinBox = new Graphics();
        borderWinBox.lineStyle(7, 0x000000);
        const colorWinBox = new Graphics();
        colorWinBox.lineStyle(5, this.lines[lineKey].color);

        // draw win boxes
        [borderWinBox, colorWinBox].forEach(graphics => {
            graphics.drawRect(
                App.Game.reelXCoordinates[reelIndex],
                App.Game.reelTop + App.Game.symbolHeight * rowIndex,
                App.Game.symbolWidth, App.Game.symbolHeight
            );
            parentContainer.addChild(graphics);
        });
    }

    drawLineImages(lines, winReels, parentContainer, winLine, payment) {
        lines.forEach(lineIndex => {
            const line = this.lines[lineIndex];
            const borderLine = new Graphics();
            borderLine.lineStyle(9, 0xdfac1f);

            const colorLine = new Graphics();
            colorLine.lineStyle(3, this.lines[lineIndex].color);

            // preparing coordinates and paths before drawing
            line.coordinates.forEach((rowIndex, reelIndex) =>
                this.setLinePath(lineIndex, reelIndex, rowIndex));
            const {x, y} = this.getLineStartPos(lineIndex);
            borderLine.moveTo(x, y);
            colorLine.moveTo(x, y);

            line.coordinates.forEach((rowIndex, reelIndex) => {
                [colorLine, borderLine].forEach(line =>
                    this.drawLine(parentContainer, line, lineIndex, reelIndex));
            });
            // draw last patch of line
            const rowIndex = this.lines[lineIndex].coordinates[4];
            colorLine.lineTo(this.pointPosX[4] + 30, this.pointPosY[rowIndex] + this.lines[lineIndex].lineDeltaY);
            parentContainer.addChildAt(colorLine, 0); // add under winBox
            borderLine.lineTo(this.pointPosX[4] + 30, this.pointPosY[rowIndex] + this.lines[lineIndex].lineDeltaY);
            parentContainer.addChildAt(borderLine, 0); // add under winBox

            if (winLine) {
                this.drawActiveBox(lineIndex);
            }
        });
    }

    drawBoxes(parentContainer) {
        parentContainer.removeChildren();
        const map = [5, 1, 8, 0, 9, 2, 7, 6, 3, 4];
        let spritePosY, spritePosX;

        Object.keys(this.lines).forEach(lineIndex => {
            this.lines[lineIndex].boxes.forEach((box, indexBox) => {
                // create box sprite
                spritePosY = this.lineBoxHeight * map[lineIndex];
                spritePosX = this.lineBoxWidth * indexBox;
                const sprite = new Sprite(new Texture(App.Game.getTexture('boxes'), {
                    x: spritePosX,
                    y: spritePosY,
                    width: this.lineBoxWidth,
                    height: this.lineBoxHeight
                }));
                sprite.position.set(box.x, box.y);
                sprite.name = lineIndex;
                sprite.leftRight = indexBox;
                this.addBoxEvents(sprite);
                parentContainer.addChild(sprite);
            });
        });
    }

    drawActiveBox = lineIndex => {
        this.cleanActiveBox();
        const boxesContainer = App.Game.getStageChild('boxesContainer');
        const map = [5, 1, 8, 0, 9, 2, 7, 6, 3, 4];
        let spritePosY = this.lineBoxHeight * map[lineIndex];
        let spritePosX = 0;
        let spriteNew = new Sprite(new Texture(App.Game.getTexture('boxesActive'), {
            x: spritePosX,
            y: spritePosY,
            width: this.lineBoxWidth,
            height: this.lineBoxHeight
        }));
        spriteNew.position.set(this.lines[lineIndex].boxes[0].x, this.lines[lineIndex].boxes[0].y);
        spriteNew.name = 'active1';
        boxesContainer.addChild(spriteNew);
        spritePosX = this.lineBoxWidth;
        spritePosY = this.lineBoxHeight * map[lineIndex];
        spriteNew = new Sprite(new Texture(App.Game.getTexture('boxesActive'), {
            x: spritePosX,
            y: spritePosY,
            width: this.lineBoxWidth,
            height: this.lineBoxHeight
        }));
        spriteNew.position.set(this.lines[lineIndex].boxes[1].x, this.lines[lineIndex].boxes[1].y);
        spriteNew.name = 'active2';
        boxesContainer.addChild(spriteNew);
    };

    cleanActiveBox = () => {
        // create box sprite
        const boxesContainer = App.Game.getStageChild('boxesContainer');
        boxesContainer.removeChild(boxesContainer.getChildByName('active1'));
        boxesContainer.removeChild(boxesContainer.getChildByName('active2'));
    };

    /**
     * Create events for boxes
     * Show line and stop animate feature on 'pointerover' event
     * @param sprite
     */
    addBoxEvents = sprite => {
        sprite.buttonMode = true; // shows hand cursor
        sprite.interactive = true;
        sprite
            .on('pointerover', () => {
                if (App.Game.getState() !== 'IDLE') {
                    return;
                }
                const linesContainer = App.Game.getStageChild('linesContainer');
                linesContainer.removeChildren();
                App.Game.animationFrameId && App.Game.stopAnimateFeature();
                App.Game.Legends.showJackpot();
                const lineIndex = +sprite.name;
                this.drawLineImages([+sprite.name], [], linesContainer);
                this.drawActiveBox(lineIndex);
            })
            .on('pointerout', () => {
                if (App.Game.getState() !== 'IDLE') {
                    return;
                }
                const linesContainer = App.Game.getStageChild('linesContainer');
                linesContainer.removeChildren();
                const boxesContainer = App.Game.getStageChild('boxesContainer');
                boxesContainer.removeChild(boxesContainer.getChildByName('active1'));
                boxesContainer.removeChild(boxesContainer.getChildByName('active2'));
            });
    };

    /**
     * Function to blink line
     */
    blinkLine(lineIndex, reelCount, color) {
        let points = [];
        const ticker = App.Game.app.ticker;
        const linesContainer = App.Game.getStageChild('boxesContainer');
        let blinkIterator = 0;

        const interval6 = App.Game.tickerInterval(() => {
            points = [];
            const {x, y} = this.getLineStartPos(lineIndex);  // start pos
            points.push(new PIXI.Point(x, y));

            this.lines[lineIndex].coordinates.forEach((rowIndex, reelIndex) => {
                if (reelIndex < 5) {
                    points.push(new PIXI.Point(this.pointPosX[reelIndex], this.pointPosY[rowIndex])); // central reel possitions
                }
            });
            const imageRect = new PIXI.Rectangle(0, 88 * blinkIterator, 961, 88);
            const newTexture = new PIXI.Texture(App.Game.getTexture('lines' + color), imageRect);
            const rope = new PIXI.SimpleRope(newTexture, points);

            rope.x = 0;
            rope.y = 0;
            rope.name = 'rope';
            linesContainer.removeChild(linesContainer.getChildByName('rope'));
            linesContainer.addChild(rope);

            const image = new PIXI.Graphics();
            image.x = rope.x;
            image.y = rope.y;
            image.name = 'image';

            linesContainer.removeChild(linesContainer.getChildByName('image'));
            linesContainer.addChild(image);

            blinkIterator++;

            if (blinkIterator >= 9 - (4 - reelCount)) {
                ticker.remove(interval6);
                linesContainer.removeChild(linesContainer.getChildByName('rope'));
                linesContainer.removeChild(linesContainer.getChildByName('image'));
            }
        }, 20);
    }
}
