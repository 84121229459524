import {JL} from 'jsnlog';
import * as PIXI from 'pixi.js-legacy';

import App from './../index';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    BaseTexture = PIXI.BaseTexture,
    Texture = PIXI.Texture;

export default class Info {
    channel = null;

    response = null;

    kioskInitialized = false;

    /**
     * Create BroadcastChannel and add message handler
     * Check browser support
     */
    initBroadcastChannel() {
        JL().debug('Init broadcast channel Info App');
        const channel = new BroadcastChannel('info');

        // bind message handler
        channel.onmessage = message => {
            const response = message.data;
            this.response = response;
            JL().debug(`BroadcastChannel response: ${JSON.stringify(response)}`);

            switch (response.uc) {
                case 'CONNECT':
                    channel.postMessage({uc: 'CONNECT-INFO'});
                    break;
                case 'MENU':
                    if (App.Game) {
                        App.View.setState({currentInfoState: 'LOGO'});
                        App.View.setState({currentState: 'INFO'});
                        App.Game.setState('EXIT-GAME');
                        App.clearGameResources();
                        App.updateState('loader', {
                            fade: 'out', fadeEndCallback: App.resetLoader,
                            activeControlButtons: false
                        });
                    }
                    App.updateButton('controlButtons', {disabled: true});
                    clearTimeout(this.nextPageTimeout);
                    break;
                case 'GAME':
                    const {id, lineIndex, betIndex, bets, lines, lang, denominations, gambleLimit, currentPage, denominationPos, extraBetActive, timeOut} = response.data;
                    this.response = response;
                    App.updateButton('controlButtons', {disabled: true});
                    if (!App.Game) {
                        App.gameId = id;
                        App.View.setState({currentState: 'NONE'});
                        App.View.setState({activePrizes: false});
                        App.createGame(App.gameId);
                        App.Game.initGame(App.gameId);
                    } else {
                        if (App.Game.getState() !== 'GAME_IMAGES_LOADING') {
                            this.drawInfo(timeOut);
                        }
                    }
                    break;
            }
        };

        // trigger connection
        channel.postMessage({uc: 'CONNECT-INFO'});
        this.channel = channel;
    }

    drawInfo(timeOut) {
        const {id, lineIndex, betIndex, bets, lines, lang, denominations, gambleLimit, currentPage, denominationPos, extraBetActive, nextPage} = this.response.data;
        App.settings.currentLanguage = lang;
        App.Money.setDenominations(denominations);
        App.Money.setPosDenominationIndex(denominationPos);
        if (App.Game.Gamble) {
            App.Game.Gamble.gambleLimit = gambleLimit;
        }
        App.Game.extraBetActive = extraBetActive;
        App.Game.gameSettings.bets = bets;
        App.Game.gameSettings.lines = lines;
        App.Game.gameSettings.setPosLineIndex(lineIndex);
        App.Game.gameSettings.setPosBetIndex(betIndex);
        App.Game.gameSettings.setPosLineIndex(lineIndex);

        clearTimeout(this.nextPageTimeout);

        if (nextPage && this.kioskInitialized) {
            App.Game.InfoScreen.slide('next');
        } else {
            App.Game.InfoScreen.currentPage = 1;
            App.Game.InfoScreen.show();
            this.kioskInitialized = true;
        }

        timeOut && (this.nextPageTimeout = setTimeout(() => {
            App.Game.InfoScreen.slide('next');
        }, 10000));
    }
}
