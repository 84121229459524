import * as PIXI from 'pixi.js-legacy';

import App from './../index';

/* PIXI aliases */
const Container = PIXI.Container,
    Text = PIXI.Text;

export default class Lines {
    /**
     * Create PIXI.Container and draw boxes
     * @param parentContainer
     */
    createBoxesContainer(parentContainer) {
        const container = new Container();
        container.name = 'boxesContainer';
        container.zIndex = App.Game.containersLayers[container.name];
        this.drawBoxes(container);
        parentContainer.addChild(container);
    }

    /**
     * Create PIXI.Container for lines
     * @param parentContainer
     */
    createLinesContainer = parentContainer => {
        const container = new Container();
        container.name = 'linesContainer';
        container.zIndex = App.Game.containersLayers[container.name];
        parentContainer.addChild(container);
    };

    drawLineImages(lines, winReels, parentContainer) {

    }

    drawBoxes(parentContainer) {

    }

    destroy() {

    }

    /**
     * Create events for boxes
     * Show line and stop animate feature on 'pointerover' event
     * @param sprite
     */
    addBoxEvents = sprite => {
        const linesContainer = App.Game.getStageChild('linesContainer');

        sprite.buttonMode = true; // shows hand cursor
        sprite.interactive = true;
        sprite
            .on('pointerover', () => {
                linesContainer.removeChildren();
                App.Game.animationFrameId && App.Game.stopAnimateFeature();
                App.Game.Legends.showJackpot();
                this.drawLineImages([+sprite.name], [], linesContainer);
            })
            .on('pointerout', () => {
                linesContainer.removeChildren();
            });
    };

    /**
     * Toggle interactive mode for boxes
     * Check PixiJS Application
     * @param interactive
     */
    updateBoxesState = (interactive = true) => {
        if (App.Game.getStageChild('boxesContainer') && App.Game.app) {
            (App.Game.getStageChild('boxesContainer').interactiveChildren = interactive);
        }
    };

    addLineWin = (parentContainer, payment, x, y) => {
        const text = new Text(payment, {
            fontFamily: 'Arial',
            fontSize: 12,
            fill: '#f7c354',
            stroke: '#000',
            strokeThickness: 3,
            lineJoin: 'round'
        });
        text.anchor.set(0.5, 0.75);
        text.position.set(x, y);
        parentContainer.addChild(text);
    };
};
