import React from 'react';
import {JL} from 'jsnlog';
import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import GameChampionX2 from './../../champion/gameX2';
import Lines from './lines';
import Gamble from './gamble';
import InfoScreen from '../../infoScreen';
import BigWinUnique from './bigWin';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Text = PIXI.Text;

export default class SparkySeven extends GameChampionX2 {
    constructor() {
        super();
        this.id = 'sparky-seven';
        this.name = 'Sparky Seven';
        this.buttonsPanelShadow = 'mid';
        this.reels = 3;
        this.transparentBackground = true;
        this.BigWin = new BigWinUnique();
        this.BigWin.enabled = true;

        this.gameFieldWidth = 960;
        this.gameWidth = App.System.resolution === '4x3' ? this.gameFieldWidth : 1280;
        this.gameFieldHeight = 720;
        this.gameHeight = App.configs.doubleScreen ? 1440 : 720;
        this.reelXCoordinates = [120, 360, 605]; // magic numbers - x coordinates where reels starts
        this.reelTop = 98;
        this.symbolWidth = 240;  // width of aa single symbol
        this.symbolHeight = 155; // height of aa single symbol
        this.SymbolInfo.enabled = true;
        this.offsetReelMask = {
            offsetX: 0, offsetY: 0,
            offsetWidth: 0, offsetHeight: 10
        };
        this.symbols = [
            {regularDelay: 50, payment: [0, 0, 0, 5]},   // 0 - cherry
            {regularDelay: 50, payment: [0, 0, 0, 40]},  // 1 - lemon
            {regularDelay: 50, payment: [0, 0, 0, 40]},  // 2 - orange
            {regularDelay: 50, payment: [0, 0, 0, 40]},  // 3 - plum
            {regularDelay: 50, payment: [0, 0, 0, 40]},  // 4 - watermelon
            {regularDelay: 50, payment: [0, 0, 0, 60]},  // 5 - bell
            {regularDelay: 50, payment: [0, 0, 0, 200]}, // 6 - digit '7'
            {regularDelay: 50, payment: [0, 0, 0, 750]}  // 7 - star
        ];
        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png',
                x2: 'x2.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                symbolBorder: 'area/symbol-border.png',
                paymentBorder: 'area/payment-border.png'
            })
        };
        this.gameSounds = {soundClass: 'prefergames'};

        this.Lines = new Lines(this.mergePath({boxes: 'lines/box.png'}));
        Object.keys(this.Lines.lines).forEach(key => {
            this.Lines.lines[key].boxes[0].y *= 1.2;
        });

        this.Gamble = new Gamble(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            smallCard: 'gamble/card-small.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            inactiveBlack: 'gamble/black-active.png',
            inactiveRed: 'gamble/red-active.png',
            activeBlack: 'gamble/black-inactive.png',
            activeRed: 'gamble/red-inactive.png'
        }));
        // changed border radius to 0 for 'gamble-button'
        this.Gamble.gambleButtonsSize = {
            width: '17em',
            height: '12.35em',
            borderRadius: '0',
            bottom: '12.5em',
            left: '4em',
            right: '4em'
        };

        this.InfoScreen = new InfoScreen({pages: 2}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     * @param lang
     */
    drawInfoPage(ctx, page, nLines, bet, lang) {
        const {
            combinationOfKind, combinationLeftToRight, prizesOnSelectedLines, ScatterPayAtAnyPosition,
            HighestWinPaid, ScatterWinsAddedToLineWins, prizesShownInCredits, MalfunctionVoidsAllPays
        } = App.languageCollection[lang];
        const textProps = {
            font: 'bold 20pt Franklin Gothic Medium',
            textAlign: 'center',
            fillStyle: '#fff',
            strokeStyle: '#000',
            lineWidth: 3,
            shadowColor: '#000',
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 2,
            lineHeight: 30
        };
        switch (page) {
            case 1:
                ctx.font = textProps.font;
                ctx.textAlign = 'center';
                ctx.fillStyle = textProps.fillStyle;
                ctx.strokeStyle = textProps.strokeStyle;
                ctx.lineWidth = textProps.lineWidth;
                ctx.shadowColor = textProps.shadowColor;
                ctx.shadowOffsetX = textProps.shadowOffsetX;
                ctx.shadowOffsetY = textProps.shadowOffsetY;
                ctx.shadowBlur = textProps.shadowBlur;

                // cherry
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 248, 610);

                // lemon, orange, plum, melon
                this.strokeFillText(ctx, bet * this.symbols[1].payment[3], 470, 347);

                // bell
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 720, 610);

                // 7
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 720, 185);

                // star
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 250, 185);
                break;
            case 2:
                const rulesTitle = 'RULES';
                const rules = combinationOfKind +
                    combinationLeftToRight + prizesOnSelectedLines + ScatterPayAtAnyPosition +
                    HighestWinPaid + ScatterWinsAddedToLineWins + prizesShownInCredits + MalfunctionVoidsAllPays;

                ctx.font = 'italic bold 24pt Arial';
                ctx.strokeStyle = '#000';
                ctx.fillStyle = '#ff0000';
                ctx.lineWidth = 3;
                ctx.textAlign = 'center';
                this.strokeFillText(ctx, rulesTitle, 480, 240, 500);
                this.drawSplitText(ctx, rules, 480, 280, 700, textProps);
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    startGamble() {
        this.setState('ASK_GAMBLE');
        const parentContainer = this.getStageChild('bonusContainer');

        const {payment} = this.latestResponse;
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        this.Gamble.prizeWin = payment;
        App.updateButton('start', {disabled: true});

        this.Legends.setText('win', {text: 'win', value: payment});
        this.Legends.setStatus('creditsWon', payment);

        parentContainer.removeChildren();
        App.Sounds.playSound('gamble-wait');
        App.updateButton('start', {
            disabled: false,
            title: 'collect',
            handler: this.finishGamble
        });
        App.updateButton('select', {
            disabled: false,
            title: 'gamble',
            handler: this.Gamble.goToGamble
        });
    }

    onRotationDone() {
        JL().debug(`-- Rotation done (fps: ${App.System.statistics.fps})`);
        const {features, payment} = this.latestResponse;
        App.updateButton('start', {disabled: true});
        this.roundWin = 0;
        this.checkX2();

        if (payment > 0 || this.isFreeRoll(features) || features.length) {
            if (this.BigWin.isBigWin(payment) && !this.isBonusSymbolWin(features)) {
                // There is a BIG Win
                if (this.multiplier) {
                    const parentContainer = this.getStageChild('bonusContainer');

                    this.stopAnimateFeature();
                    // continue animate symbols
                    this.reelMatrix.forEach(reel => reel.forEach(symbolObj =>
                        symbolObj.sprite.play()));

                    const sprite = new Sprite(this.getTexture('x2'));
                    sprite.alpha = 0;
                    sprite.name = 'x2';
                    sprite.position.set(190, 115);
                    const increaseAlpha = () => {
                        if (sprite.alpha < 1) {
                            sprite.alpha += 0.05;
                        } else {
                            this.app.ticker.remove(increaseAlpha);
                            this.BigWin.goToBigWin(payment, features);
                        }
                    };
                    this.app.ticker.add(increaseAlpha);
                    parentContainer.addChild(sprite);
                } else {
                    this.BigWin.goToBigWin(payment, features);
                }
            } else {
                // There is a win
                this.setState('SHOW_WIN_LINES');
                this.startAnimateFeature(features);
            }
        } else {
            if (this.isBonus()) {
                if (this.bonusStatus && this.bonusStatus.remain > 0) {
                    this.roundFinished(false);
                } else {
                    this.Legends.setRoundFinText();
                    this.finishBonus();
                }
            } else {
                this.roundFinished();
                this.Legends.setRoundFinText();
            }
        }
    }

    /**
     * Create texts for paymentBorder table
     * @param parentContainer
     * @param payTable
     * @param direction
     */
    drawSymbolInfoPayments(parentContainer, payTable, direction) {
        const bet = this.gameSettings.getBetLineCredit();
        const props = {
            fill: '#00ff07',
            stroke: '#007318',
            align: 'left',
            fontFamily: 'NeonFont',
            fontSize: 25,
            strokeThickness: 3,
            lineJoin: 'round'
        };

        payTable.forEach((pay, index) => {
            const quantity = new Text(`x${pay[0]}`, props);
            quantity.position.x = direction === 'left' ? -65 : -45;
            quantity.position.y = 15 + (payTable.length === 1 ? -30 : -30 * index);

            const payment = new Text(` ${bet * pay[1]}`, {...props, fill: '#6bf2ff', stroke: '#1149ab'});
            payment.position.x = direction === 'left' ? -34 : -15;
            payment.position.y = 15 + (payTable.length === 1 ? -30 : -30 * index);

            parentContainer.addChild(quantity, payment);
        });
    }
}
