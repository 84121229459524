import {JL} from 'jsnlog';

import CustomLines from './../CustomLines';

export default class Lines40 extends CustomLines {
    constructor() {
        super();
        this.imageResources = {
            boxes: '/img/lines/Lines40/boxes.png'
        };

        this.boxSettings = {
            firstRightBox: 20,
            boxMargin: 0,
            lineBoxWidth: 29,
            lineBoxHeight: 15,
            textOnBox: false,
            textType: null,
            allBoxes: false,
            activeBox: false,
            typeBoxes: 'drawChosenBox'
        };
        this.linesSettings = {
            ...this.linesSettings,
            lineThickness: 2,
            borderThickness: 4
        };

        this.lines = {
            0: {
                coordinates: [0, 0, 0, 0, 0],
                boxes: [{x: 0, y: 90}],
                color: '#ffd8ff'
            },
            1: {
                coordinates: [1, 1, 1, 1, 1],
                boxes: [{x: 0, y: 210}],
                color: '#de1800'
            },
            2: {
                coordinates: [2, 2, 2, 2, 2],
                boxes: [{x: 0, y: 350}],
                color: '#009200'
            },
            3: {
                coordinates: [3, 3, 3, 3, 3],
                boxes: [{x: 0, y: 470}],
                color: '#ce8f63'
            },
            4: {
                coordinates: [0, 1, 2, 1, 0],
                boxes: [{x: 772, y: 130}],
                color: '#647100'
            },
            5: {
                coordinates: [1, 2, 3, 2, 1],
                boxes: [{x: 772, y: 250}],
                lineDeltaY: 10,
                color: '#ff24ff'
            },
            6: {
                coordinates: [2, 1, 0, 1, 2],
                boxes: [{x: 772, y: 310}],
                lineDeltaY: -10,
                color: '#ffffad'
            },
            7: {
                coordinates: [3, 2, 1, 2, 3],
                boxes: [{x: 772, y: 430}],
                lineDeltaY: -30,
                color: '#0096bd'
            },
            8: {
                coordinates: [0, 0, 0, 0, 1],
                boxes: [{x: 0, y: 110}],
                color: '#7321bd'
            },
            9: {
                coordinates: [1, 1, 1, 1, 0],
                boxes: [{x: 0, y: 170}],
                lineDeltaY: 50,
                color: '#00ffff'
            },
            10: {
                coordinates: [2, 2, 2, 2, 3],
                boxes: [{x: 0, y: 390}],
                lineDeltaY: -30,
                color: '#6345ff'
            },
            11: {
                coordinates: [3, 3, 3, 3, 2],
                boxes: [{x: 0, y: 450}],
                color: '#d6fb06'
            },
            12: {
                coordinates: [0, 1, 1, 1, 1],
                boxes: [{x: 772, y: 190}],
                lineDeltaY: 10,
                color: '#a56e00'
            },
            13: {
                coordinates: [1, 1, 1, 1, 2],
                boxes: [{x: 0, y: 230}],
                color: '#949294'
            },
            14: {
                coordinates: [2, 2, 2, 2, 1],
                boxes: [{x: 0, y: 330}],
                color: '#de2064'
            },
            15: {
                coordinates: [3, 2, 2, 2, 2],
                boxes: [{x: 772, y: 370}],
                lineDeltaY: -50,
                color: '#007100'
            },
            16: {
                coordinates: [1, 0, 0, 0, 0],
                boxes: [{x: 772, y: 90}],
                lineDeltaY: 16,
                color: '#adcab5'
            },
            17: {
                coordinates: [1, 2, 2, 2, 2],
                boxes: [{x: 772, y: 350}],
                lineDeltaY: -10,
                color: '#ff6d00'
            },
            18: {
                coordinates: [2, 1, 1, 1, 1],
                boxes: [{x: 772, y: 210}],
                lineDeltaY: 15,
                color: '#ffff00'
            },
            19: {
                coordinates: [2, 3, 3, 3, 3],
                boxes: [{x: 772, y: 470}],
                lineDeltaY: -40,
                color: '#00cbbe'
            },
            20: {
                coordinates: [0, 0, 0, 1, 2],
                boxes: [{x: 0, y: 130}],
                lineDeltaY: -10,
                color: '#00cbbe'
            },
            21: {
                coordinates: [1, 1, 1, 2, 3],
                boxes: [{x: 0, y: 270}],
                lineDeltaY: -32,
                color: '#efc704'
            },
            22: {
                coordinates: [2, 2, 2, 1, 0],
                boxes: [{x: 0, y: 290}],
                color: '#0045ff'
            },
            23: {
                coordinates: [3, 3, 3, 2, 1],
                boxes: [{x: 0, y: 430}],
                lineDeltaY: -5,
                color: '#ff0000'
            },
            24: {
                coordinates: [0, 1, 2, 2, 2],
                boxes: [{x: 772, y: 330}],
                lineDeltaY: 5,
                color: '#aeaeb5'
            },
            25: {
                coordinates: [1, 2, 3, 3, 3],
                boxes: [{x: 772, y: 450}],
                lineDeltaY: 17,
                color: '#00cb62'
            },
            26: {
                coordinates: [2, 1, 0, 0, 0],
                boxes: [{x: 772, y: 110}],
                lineDeltaY: -10,
                color: '#d641ff'
            },
            27: {
                coordinates: [3, 2, 1, 1, 1],
                boxes: [{x: 772, y: 230}],
                lineDeltaY: -40,
                color: '#f792b6'
            },
            28: {
                coordinates: [0, 0, 1, 0, 0],
                boxes: [{x: 0, y: 150}],
                lineDeltaY: 10,
                color: '#ffbf04'
            },
            29: {
                coordinates: [1, 1, 0, 1, 1],
                boxes: [{x: 0, y: 190}],
                color: '#a535ff'
            },
            30: {
                coordinates: [2, 2, 3, 2, 2],
                boxes: [{x: 0, y: 370}],
                lineDeltaY: -25,
                color: '#2039bd'
            },
            31: {
                coordinates: [3, 3, 2, 3, 3],
                boxes: [{x: 0, y: 410}],
                color: '#7bffff'
            },
            32: {
                coordinates: [0, 1, 1, 1, 0],
                boxes: [{x: 772, y: 150}],
                color: '#ae4e05'
            },
            33: {
                coordinates: [1, 1, 2, 1, 1],
                boxes: [{x: 0, y: 250}],
                color: '#ff50b7'
            },
            34: {
                coordinates: [2, 2, 1, 2, 2],
                boxes: [{x: 0, y: 310}],
                color: '#ffa200'
            },
            35: {
                coordinates: [3, 2, 2, 2, 3],
                boxes: [{x: 772, y: 410}],
                color: '#6345ff'
            },
            36: {
                coordinates: [1, 0, 0, 0, 1],
                boxes: [{x: 772, y: 170}],
                lineDeltaY: 28,
                color: '#e6cab6'
            },
            37: {
                coordinates: [1, 2, 2, 2, 1],
                boxes: [{x: 772, y: 270}],
                lineDeltaY: -3,
                color: '#d631bd'
            },
            38: {
                coordinates: [2, 1, 1, 1, 2],
                boxes: [{x: 772, y: 290}],
                lineDeltaY: 13,
                color: '#ff6d63'
            },
            39: {
                coordinates: [2, 3, 3, 3, 2],
                boxes: [{x: 772, y: 390}],
                lineDeltaY: -50,
                color: '#00e363'
            }
        };

        this.lineMap = [
            0, 8, 20, 28, 9,
            29, 1, 13, 33, 21,
            22, 34, 14, 2, 30,
            10, 31, 23, 11, 3,

            16, 26, 4, 32, 36,
            12, 18, 27, 5, 37,
            38, 6, 24, 17, 15,
            39, 35, 7, 25, 19
        ];

        JL().debug('-- Lines40 initialized');
    }

    drawLine(ctx, lineIndex, reelIndex, color, thickness) {
        const line = this.lines[lineIndex];
        const {x, y} = line.path[reelIndex];
        ctx.strokeStyle = color;
        ctx.lineWidth = thickness;
        const {lineBoxWidth} = this.boxSettings;
        const {horizontalLineLength} = this.linesSettings;

        const lineEndCoord = !this.isRightBox(lineIndex) ?
            horizontalLineLength : -horizontalLineLength;

        reelIndex === 0 && ctx.lineTo(!this.isRightBox(lineIndex) ?
            lineBoxWidth + Math.abs(line.lineDeltaY || 0) :
            800 - lineBoxWidth - Math.abs(line.lineDeltaY || 0), y);

        // draw main line
        ctx.lineTo(x, y);

        // if last line -> continue horizontal line
        reelIndex === 4 && ctx.lineTo(x + lineEndCoord, y);
        ctx.stroke();
    }
}
