import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import Lines from './../../lines';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Texture = PIXI.Texture,
    AnimatedSprite = PIXI.AnimatedSprite,
    Text = PIXI.Text;

export default class Lines25 extends Lines {
    constructor() {
        super();
        this.imageResources = {
            lines: '/img/lines/Lines25/lines.png',
            winLinesBox: '/img/lines/Lines25/winLinesBox.png',
            boxes: '/img/lines/Lines25/boxes.png'
        };

        this.lineImageWidth = 185; // width of line image in sprite, magic number for each game
        this.lineImageHeight = 226; // height of line image in sprite, magic number for each game

        this.lines = {
            0: {
                coordinates: [1, 1, 1, 1],
                boxes: [{x: 0, y: 272}],
                path: [
                    {x: 0, y: 170},
                    {x: 214, y: 170},
                    {x: 398, y: 170},
                    {x: 583, y: 170}
                ],
                text: [
                    {x: 120, y: 340},
                    {x: 305, y: 340},
                    {x: 490, y: 340}
                ]
            },
            1: {
                coordinates: [0, 0, 0, 0],
                boxes: [{x: 770, y: 150}],
                path: [
                    {x: 1, y: 40},
                    {x: 215, y: 40},
                    {x: 399, y: 40},
                    {x: 584, y: 40}
                ],
                text: [
                    {x: 120, y: 212},
                    {x: 305, y: 212},
                    {x: 490, y: 212}
                ]
            },
            2: {
                coordinates: [2, 2, 2, 2],
                boxes: [{x: 770, y: 395}],
                path: [
                    {x: 0, y: 298},
                    {x: 214, y: 298},
                    {x: 398, y: 298},
                    {x: 583, y: 298}
                ],
                text: [
                    {x: 120, y: 471},
                    {x: 305, y: 471},
                    {x: 490, y: 471}
                ]
            },
            3: {
                coordinates: [0, 1, 1, 2],
                boxes: [{x: 0, y: 144}],
                path: [
                    {x: 1, y: 60},
                    {x: 214, y: 146},
                    {x: 398, y: 189},
                    {x: 582, y: 320}
                ],
                text: [
                    {x: 120, y: 210},
                    {x: 305, y: 340},
                    {x: 490, y: 340}
                ]
            },
            4: {
                coordinates: [2, 1, 1, 0],
                boxes: [{x: 2, y: 397}],
                path: [
                    {x: 2, y: 319},
                    {x: 213, y: 191},
                    {x: 398, y: 148},
                    {x: 583, y: 60}
                ],
                text: [
                    {x: 120, y: 470},
                    {x: 305, y: 340},
                    {x: 490, y: 340}
                ]
            },
            5: {
                coordinates: [0, 0, 1, 1],
                boxes: [{x: 2, y: 117}],
                path: [
                    {x: 1, y: 38},
                    {x: 214, y: 38},
                    {x: 398, y: 167},
                    {x: 583, y: 167}
                ],
                text: [
                    {x: 120, y: 212},
                    {x: 305, y: 212},
                    {x: 490, y: 340}
                ]
            },
            6: {
                coordinates: [1, 1, 0, 0],
                boxes: [{x: 771, y: 116}],
                path: [
                    {x: 1, y: 169},
                    {x: 215, y: 169},
                    {x: 399, y: 38},
                    {x: 584, y: 38}
                ],
                text: [
                    {x: 120, y: 340},
                    {x: 305, y: 340},
                    {x: 490, y: 210}
                ]
            },
            7: {
                coordinates: [1, 1, 2, 2],
                boxes: [{x: 771, y: 426}],
                path: [
                    {x: 1, y: 170},
                    {x: 215, y: 170},
                    {x: 399, y: 300},
                    {x: 584, y: 300}
                ],
                text: [
                    {x: 120, y: 340},
                    {x: 305, y: 340},
                    {x: 490, y: 470}
                ]
            },
            8: {
                coordinates: [2, 2, 1, 1],
                boxes: [{x: 0, y: 425}],
                path: [
                    {x: 0, y: 300},
                    {x: 214, y: 300},
                    {x: 398, y: 170},
                    {x: 583, y: 170}
                ],
                text: [
                    {x: 120, y: 470},
                    {x: 305, y: 470},
                    {x: 490, y: 340}
                ]
            },
            9: {
                coordinates: [0, 1, 1, 0],
                boxes: [{x: 771, y: 180}],
                path: [

                    {x: 1, y: 40},
                    {x: 215, y: 170},
                    {x: 399, y: 171},
                    {x: 584, y: 40}
                ],
                text: [
                    {x: 120, y: 210},
                    {x: 305, y: 340},
                    {x: 490, y: 340}
                ]
            },
            10: {
                coordinates: [1, 0, 0, 1],
                boxes: [{x: 771, y: 238}],
                path: [
                    {x: 1, y: 170},
                    {x: 215, y: 40},
                    {x: 399, y: 40},
                    {x: 584, y: 168}
                ],
                text: [
                    {x: 120, y: 340},
                    {x: 305, y: 210},
                    {x: 490, y: 210}
                ]
            },
            11: {
                coordinates: [1, 2, 2, 1],
                boxes: [{x: 771, y: 303}],
                path: [
                    {x: 1, y: 168},
                    {x: 215, y: 298},
                    {x: 399, y: 299},
                    {x: 584, y: 171}
                ],
                text: [
                    {x: 120, y: 340},
                    {x: 305, y: 470},
                    {x: 490, y: 470}
                ]
            },
            12: {
                coordinates: [2, 1, 1, 2],
                boxes: [{x: 771, y: 362}],
                path: [
                    {x: 1, y: 301},
                    {x: 215, y: 171},
                    {x: 399, y: 171},
                    {x: 584, y: 300}
                ],
                text: [
                    {x: 120, y: 470},
                    {x: 305, y: 340},
                    {x: 490, y: 340}
                ]
            },
            13: {
                coordinates: [0, 1, 0, 1],
                boxes: [{x: 0, y: 178}],
                path: [
                    {x: 1, y: 40},
                    {x: 214, y: 170},
                    {x: 398, y: 40},
                    {x: 583, y: 170}
                ],
                text: [
                    {x: 120, y: 210},
                    {x: 305, y: 340},
                    {x: 490, y: 210}
                ]
            },
            14: {
                coordinates: [1, 0, 1, 0],
                boxes: [{x: 1, y: 242}],
                path: [
                    {x: 0, y: 170},
                    {x: 214, y: 41},
                    {x: 398, y: 170},
                    {x: 582, y: 41}
                ],
                text: [
                    {x: 120, y: 340},
                    {x: 305, y: 210},
                    {x: 490, y: 340}
                ]
            },
            15: {
                coordinates: [1, 2, 1, 2],
                boxes: [{x: 0, y: 304}],
                path: [
                    {x: 0, y: 170},
                    {x: 214, y: 300},
                    {x: 398, y: 170},
                    {x: 582, y: 299}
                ],
                text: [
                    {x: 120, y: 340},
                    {x: 305, y: 470},
                    {x: 490, y: 340}
                ]
            },
            16: {
                coordinates: [2, 1, 2, 1],
                boxes: [{x: 0, y: 364}],
                path: [
                    {x: 1, y: 299},
                    {x: 214, y: 170},
                    {x: 398, y: 300},
                    {x: 581, y: 170}
                ],
                text: [
                    {x: 120, y: 470},
                    {x: 305, y: 340},
                    {x: 490, y: 470}
                ]
            },
            17: {
                coordinates: [0, 0, 1, 2],
                boxes: [{x: 0, y: 87}],
                path: [
                    {x: 0, y: 40},
                    {x: 214, y: 40},
                    {x: 398, y: 171},
                    {x: 581, y: 299}
                ],
                text: [
                    {x: 120, y: 212},
                    {x: 305, y: 212},
                    {x: 490, y: 340}
                ]
            },
            18: {
                coordinates: [2, 2, 1, 0],
                boxes: [{x: 3, y: 458}],
                path: [
                    {x: 1, y: 300},
                    {x: 215, y: 300},
                    {x: 399, y: 171},
                    {x: 581, y: 41}
                ],
                text: [
                    {x: 120, y: 470},
                    {x: 305, y: 470},
                    {x: 490, y: 340}
                ]
            },
            19: {
                coordinates: [2, 1, 0, 0],
                boxes: [{x: 771, y: 87}],
                path: [
                    {x: 1, y: 298},
                    {x: 214, y: 167},
                    {x: 399, y: 40},
                    {x: 584, y: 40}
                ],
                text: [
                    {x: 118, y: 473},
                    {x: 305, y: 343},
                    {x: 490, y: 213}
                ]
            },
            20: {
                coordinates: [0, 1, 2, 2],
                boxes: [{x: 771, y: 458}],
                path: [
                    {x: 1, y: 40},
                    {x: 215, y: 170},
                    {x: 399, y: 300},
                    {x: 584, y: 300}
                ],
                text: [
                    {x: 118, y: 212},
                    {x: 305, y: 343},
                    {x: 490, y: 472}
                ]
            },
            21: {
                coordinates: [0, 0, 2, 2],
                boxes: [{x: 0, y: 211}],
                path: [
                    {x: 1, y: 39},
                    {x: 214, y: 86},
                    {x: 398, y: 297},
                    {x: 583, y: 297}
                ],
                text: [
                    {x: 120, y: 210},
                    {x: 305, y: 210},
                    {x: 490, y: 470}
                ]
            },
            22: {
                coordinates: [2, 2, 0, 0],
                boxes: [{x: 0, y: 335}],
                path: [
                    {x: 1, y: 261},
                    {x: 214, y: 222},
                    {x: 398, y: 2},
                    {x: 583, y: 2}
                ],
                text: [
                    {x: 120, y: 470},
                    {x: 305, y: 470},
                    {x: 490, y: 210}
                ]
            },
            23: {
                coordinates: [0, 2, 2, 0],
                boxes: [{x: 771, y: 210}],
                path: [
                    {x: 1, y: 70},
                    {x: 215, y: 285},
                    {x: 399, y: 285},
                    {x: 583, y: 25}
                ],
                text: [
                    {x: 120, y: 210},
                    {x: 305, y: 470},
                    {x: 490, y: 470}
                ]
            },
            24: {
                coordinates: [2, 0, 0, 2],
                boxes: [{x: 771, y: 335}],
                path: [
                    {x: 1, y: 208},
                    {x: 215, y: 2},
                    {x: 399, y: 2},
                    {x: 584, y: 252}
                ],
                text: [
                    {x: 120, y: 470},
                    {x: 305, y: 210},
                    {x: 490, y: 210}
                ]
            }
        };
    }

    drawLineImages(lines, winReels, parentContainer, winLine, payment) {
        lines.forEach(lineIndex => {
            const line = this.lines[lineIndex];
            line.coordinates.forEach((rowIndex, reelIndex) => {
                this.lineImageWidth = 185;
                this.lineImageHeight = 226;

                let spritePosX = this.lineImageWidth * reelIndex;
                let spritePosY = this.lineImageHeight * (lineIndex % 13);

                reelIndex === 0 ?
                    this.lineImageWidth = 214 :
                    spritePosX += 29;

                if (reelIndex === 3) {
                    this.lineImageWidth = 211;
                }

                if (lineIndex > 20) {
                    this.lineImageHeight = 290;
                    spritePosY += 68 * Math.abs(lineIndex - 21);
                }

                const newLineIndex = lineIndex > 12 ? (spritePosX + 800) : spritePosX;

                // create line sprite
                const sprite = new Sprite(new Texture(App.Game.getTexture('lines'), {
                    x: newLineIndex,
                    y: spritePosY,
                    width: this.lineImageWidth,
                    height: this.lineImageHeight
                }));
                sprite.position.set(line.path[reelIndex].x, line.path[reelIndex].y);
                sprite.name = lineIndex;

                !winLine && parentContainer.addChild(sprite);

                winReels.includes(reelIndex) &&
                this.drawWinBox(parentContainer, lineIndex, reelIndex, rowIndex);
            });
        });
    }

    drawWinBox = (parentContainer, lineIndex, reelIndex, rowIndex) => {
        parentContainer.sortableChildren = true;
        const winLinesBoxSprite = new AnimatedSprite(App.Game.getSpriteTextures({
            image: 'winLinesBox',
            colCount: 4,
            toFrame: 21,
            width: 226,
            height: 185
        }));
        winLinesBoxSprite.name = `${reelIndex}:${rowIndex}`;
        winLinesBoxSprite.position.x = App.Game.reelXCoordinates[reelIndex] - 25;
        winLinesBoxSprite.position.y = App.Game.symbolHeight * (rowIndex) + App.Game.reelTop - 25;
        winLinesBoxSprite.animationSpeed = 0.5;
        winLinesBoxSprite.zIndex = 1;
        winLinesBoxSprite.play();
        parentContainer.addChild(winLinesBoxSprite);
    };

    drawBoxes(parentContainer) {
        parentContainer.removeChildren(); // clear all previous boxes

        const currentLines = App.Game.gameSettings.getCurrentLineMas();
        const boxWidth = 29; // width of winline boxes
        const boxHeight = 20; // height of winline boxes

        currentLines.forEach(lineIndex => {
            lineIndex--;
            this.lines[lineIndex].boxes.forEach(box => {
                const sprite = new Sprite(new Texture(App.Game.getTexture('boxes'), {
                    x: 0,
                    y: boxHeight * lineIndex, // sx, sy,
                    width: boxWidth,
                    height: boxHeight
                }));
                sprite.position.set(box.x, box.y);
                sprite.name = lineIndex;
                this.addBoxEvents(sprite);
                parentContainer.addChild(sprite);

                const text = new Text(lineIndex + 1, {
                    fontFamily: 'Arial',
                    fontSize: 10,
                    fill: '#000',
                    lineJoin: 'round'
                });
                text.anchor.x = 0.5;
                text.anchor.y = 0.4;
                text.position.set(box.x + boxWidth / 2, box.y + boxHeight / 2);
                parentContainer.addChild(text);
            });
        });
    }
}
