export default class CountUp {
    constructor(options) {
        const {values, duration, notAnimate} = options;
        this.values = values || {};
        this.duration = duration;
        this.notAnimate = notAnimate || 'none';
        this.tickCallback = null; // call on each ticker request
        this.endCallback = null; // call after requestAnimationFrame ended
    }

    /**
     * Launch ticker with new Time
     */
    start() {
        this.ticker(Date.now(), this.tickCallback);
    }

    /**
     * Animation frame for changing value
     * @param timeStart
     * @param callback
     */
    ticker(timeStart, callback) {
        const timeDiff = Date.now() - timeStart; // time spent from start animation

        // animation end
        if (timeDiff > this.duration) {
            // set end values
            Object.keys(this.values).forEach(key => this.values[key].current = this.values[key].end);
            callback?.(this.values);
            this.end(this.endCallback);
            this.reset();
            return;
        }

        // change current values
        Object.keys(this.values).forEach(key => {
            const obj = this.values[key];
            const step = timeDiff / this.duration; // from 0 to 1 (animation step)
            const offset = (obj.end - obj.start) * step; // calc offset
            obj.current = offset < 0 && this.notAnimate === 'decrease' ? // check decreased offset
                obj.end : // set end value
                obj.start + offset; // calc current
        });

        // send value by each ticket
        callback?.(this.values);

        this.rAF = requestAnimationFrame(this.ticker.bind(this, timeStart, callback));
    }

    /**
     * Add event for ticker
     * @param callback
     */
    onTick(callback) {
        this.tickCallback = callback;
    }

    /**
     * Add event on finish
     * @param callback
     */
    onEnd(callback) {
        this.endCallback = callback;
    }

    /**
     * requestAnimationFrame callback
     * @param callback
     */
    end(callback) {
        callback?.(this.values);
    }

    /**
     * Reset current ticker animation
     */
    reset() {
        cancelAnimationFrame(this.rAF);
    }
}
