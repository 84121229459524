import {JL} from 'jsnlog';
import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import PowerStars from './../power-stars/game';
import Gamble from './../sparky-seven/gamble';
import InfoScreen from '../../infoScreen';
import Lines10 from './lines';
import langs from './langs.js';
import BigWinUnique from './bigWin';

const AnimatedSprite = PIXI.AnimatedSprite,
    Container = PIXI.Container,
    Sprite = PIXI.Sprite,
    Text = PIXI.Text;

export default class SparkyHot extends PowerStars {
    constructor() {
        super();
        this.id = 'sparky-hot';
        this.name = 'Sparky Hot';
        this.freezeReels = [];
        this.scatter = 8;
        this.reelFilter = [[8], [], [], [], [8]];
        this.buttonsPanelShadow = 'low';

        this.reelTop = 95; // magic number - where reel images starts
        this.transparentBackground = true;
        this.BigWin = new BigWinUnique();
        this.BigWin.enabled = true;
        this.gameFieldWidth = 960;
        this.gameWidth = App.System.resolution === '4x3' ? this.gameFieldWidth : 1280;
        this.gameFieldHeight = 720;
        this.gameHeight = App.configs.doubleScreen ? 1440 : 720;
        this.symbolWidth = 170;
        this.symbolHeight = 165;

        this.reelXCoordinates = [50, 223, 396, 569, 742]; // magic numbers - x coordinates where reels starts
        this.containersLayers = {
            reelsStage: 0,
            mainContainer: 2,
            linesContainer: 3,
            boxesContainer: 4,
            extraBetContainer: 5,
            bonusContainer: 10,
            symbolInfo: 7,
            infoContainer: 8
        };
        this.SymbolInfo.enabled = true;

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 310, y: 260},
            bonusInBonusFrame: {x: 230, y: 230},
            endBonusFrame: {x: 230, y: 230}
        };

        this.symbols = [
            {regularDelay: 50, payment: [0, 0, 0, 10, 20, 100]},    // 0 - cherry
            {regularDelay: 50, payment: [0, 0, 0, 10, 20, 100]},    // 1 - lemon
            {regularDelay: 50, payment: [0, 0, 0, 10, 30, 150]},    // 2 - orange
            {regularDelay: 50, payment: [0, 0, 0, 10, 30, 150]},    // 3 - plum
            {regularDelay: 50, payment: [0, 0, 0, 20, 50, 200]},    // 4 - watermelon
            {regularDelay: 50, payment: [0, 0, 0, 20, 50, 200]},    // 5 - grape
            {regularDelay: 50, payment: [0, 0, 0, 50, 200, 500]},   // 6 - bells
            {regularDelay: 50, payment: [0, 0, 0, 100, 500, 1000]}, // 7 - symbol '7'
            {regularDelay: 50, payment: [0, 0, 0, 0, 0, 0]}         // 8 - star
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png'
            }),
            atlas: this.mergePath(['staticSymbols.json']),
            video: this.mergePath({info_respin: 'area/info_respin.mp4'})
        };
        this.additionalResources = {
            main: this.mergePath({
                star: 'area/star.png',
                frame: 'bonus/frame.png',
                newStar: 'bonus/newStar.png',
                symbolBorder: 'area/symbol-border.png',
                paymentBorder: 'area/payment-border.png'
            })
        };
        this.gameSounds = {soundClass: 'prefergames'};

        this.Lines = new Lines10(this.mergePath({boxes: 'lines/box.png'}));
        this.Gamble = new Gamble(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            smallCard: 'gamble/card-small.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            inactiveBlack: 'gamble/black-active.png',
            inactiveRed: 'gamble/red-active.png',
            activeBlack: 'gamble/black-inactive.png',
            activeRed: 'gamble/red-inactive.png'
        }, '/game/games/sparky-seven/img/'));
        this.Gamble.cardsQueuePos = {x: [398, 460, 522, 584, 645, 708], y: 170};

        this.Gamble.imageResources.gambleArea = '/game/games/sparky-hot/img/gamble/gamble-area.png';
        this.InfoScreen = new InfoScreen({pages: 2}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     * @param lang
     */
    drawInfoPage(ctx, page, nLines, bet, lang) {
        ctx.font = '17pt franklinFont';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#ff41c7';

        const {rulesText, substitutes, holds} = langs[lang];
        const {rules} = App.languageCollection[lang];
        const textProps = {
            font: 'bold 20pt Franklin Gothic Medium',
            textAlign: 'center',
            fillStyle: '#ffa30f',
            strokeStyle: '#000',
            lineWidth: 3,
            shadowColor: '#000',
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 2,
            lineHeight: 20
        };
        const video = this.resources.info_respin;

        switch (page) {
            case 1:
                // 7
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], 230, 104);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], 230, 132);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 230, 160);

                // bells
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 712, 104);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 712, 132);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 712, 160);

                // watermelon
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 230, 253);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 230, 281);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 230, 309);

                // grape
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 712, 253);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 712, 281);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 712, 309);

                // plum, orange
                this.strokeFillText(ctx, bet * this.symbols[3].payment[5], 165, 471);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[4], 165, 499);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[3], 165, 527);

                // lemon, chery
                this.strokeFillText(ctx, bet * this.symbols[1].payment[5], 783, 474);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[4], 783, 501);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[3], 783, 528);

                video.play();
                const sprite = Sprite.from(video);
                sprite.name = 'info_respin';
                sprite.zIndex = 2;
                const image = this.getAdditionalImage('info1');
                sprite.position.set(488 - (image.width - this.gameWidth) / 2, 406);
                const page1 = this.getStageChild('infoContainer').getChildByName(`page-${page}`);
                page1.addChild(sprite);
                textProps.font = '12pt Franklin Gothic Medium';
                this.drawSplitText(ctx, substitutes, 480, 380, 370, textProps);
                this.drawSplitText(ctx, holds, 480, 600, 370, textProps);

                break;
            case 2:
                video.currentTime = 0;
                video.pause();
                ctx.font = 'italic bold 32pt Arial';
                ctx.strokeStyle = '#000';
                ctx.fillStyle = '#ff0000';
                ctx.lineWidth = 3;
                ctx.textAlign = 'center';
                this.strokeFillText(ctx, rules, 480, 200, 500);
                this.drawSplitText(ctx, rulesText, 480, 280, 700, {...textProps, fillStyle: '#fff', lineHeight: 40});
        }
    }

    onInfoStartClose() {
        const video = this.resources.info_respin;
        video.pause();
        setTimeout(() => video.currentTime = 0, 500);
    }

    /**
     * Show additional fill star animation before respin
     * @param reelIndex
     */
    fillStars(reelIndex) {
        JL().debug(`-- Fill stars (reelIndex: ${reelIndex})`);
        const {features, payment, extension} = this.latestResponse;
        this.stopAnimateFeature();
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});
        let starTextures = null;
        // prepare array of symbol substitutes
        const fillWay = [...Array(this.reelRows)].map((item, index) => index); // [0, 1, 2]

        // check star position
        this.reelMatrix[reelIndex].forEach((row, rowIndex) => {
            if (row.symbol === 8) {
                starTextures = row.sprite.textures;
                // delete existing star pos
                fillWay.splice(fillWay.indexOf(rowIndex), 1); // example: [0, 1]
                // reverse animation way if star in last row
                [1, 2, 3].includes(rowIndex) && fillWay.reverse(); // [1, 0]
            }
        });

        // fill stars in respin reel for enable freeze roll (without substitutes)
        const respinScreen = extension.screen;
        respinScreen.forEach((reel, reelIndex) => {
            reelIndex === extension.jokerReels[0] && reel.forEach((row, rowIndex) => {
                respinScreen[reelIndex][rowIndex] = 8;
            });
        });

        // fill stars by step in 1 second
        fillWay.forEach((row, index) => {
            setTimeout(() => {
                const symbolObj = this.reelMatrix[reelIndex][row];
                const newStarSprite = new AnimatedSprite(this.getSpriteTextures({
                    toFrame: 19,
                    image: 'newStar',
                    width: 170,
                    height: 165,
                    colCount: 3
                }));
                newStarSprite.position.set(symbolObj.position.x + 50, symbolObj.position.y + 94);

                newStarSprite.loop = false; // play animation only once
                newStarSprite.animationSpeed = 0.4;
                newStarSprite.play();
                newStarSprite.onComplete = () => {
                    newStarSprite.textures = starTextures;
                    symbolObj.symbol = 8;
                    this.Roll.updateSymbolSprite(symbolObj);
                    newStarSprite.destroy();
                };
                this.getStageChild('bonusContainer').addChild(newStarSprite);

                App.Sounds.playSound('fillStar');

                // after last star
                index === fillWay.length - 1 && setTimeout(() => {
                    // restore symbols animation loop
                    this.reelMatrix.forEach(reel => {
                        reel.forEach(symbolObj => {
                            symbolObj.loop = true;
                            this.Roll.updateSymbolSprite(symbolObj);
                            symbolObj.sprite.gotoAndStop(0);
                        });
                    });

                    // change symbols to regular scatter
                    fillWay.forEach(row => {
                        const symbolObj = this.reelMatrix[reelIndex][row];
                        symbolObj.symbol = this.scatter;
                        symbolObj.image = 'regular';
                        this.Roll.updateSymbolSprite(symbolObj);
                    });

                    if (payment > 0 && features.length) {
                        this.setState('SHOW_WIN_LINES');
                        this.startAnimateFeature(features);
                    } else {
                        this.drawBonusAskButton(true);
                    }
                }, 1000);
            }, 1000 * index);
        });
    }

    drawBonusAskButton(isFirstBonus) {
        JL().debug(`-- Set bonus ask button (isFirstBonus: ${isFirstBonus})`);
        this.stopAnimateFeature();
        this.showPressAnyButton(false);

        this.gameFlag.bonusStart = true;
        this.roundWin && this.Legends.setText('win', {text: 'win', value: this.bonusWin});

        App.updateButton('start', {
            disabled: false,
            title: 'start',
            handler: this.respin
        });
        const textProps = {
            fontFamily: 'Comic Sans MS, cursive, sans-serif',
            fontSize: 50,
            fill: '#700e18',
            lineJoin: 'round',
            fontWeight: 'bold',
            align: 'center'
        };
        const parentContainer = this.getStage();
        const frameContainer = new Container();
        frameContainer.name = 'frameContainer';
        frameContainer.zIndex = 3;
        parentContainer.addChild(frameContainer);
        const textRespin1 = new Text('Respin', textProps);
        textRespin1.name = 'textRespin1';
        textRespin1.anchor.set(0.5);
        textRespin1.position.set(480, 310);
        this.showStartBonusFrame(frameContainer, this.coordinatesBonusFrame.startBonusFrame);
        frameContainer.addChild(textRespin1);
    }

    //
    // ======================== RESPIN SECTION =============================
    //

    respin = () => {
        const frameContainer = this.getStage().getChildByName('frameContainer');
        JL().debug(`-- Respin - ${JSON.stringify(this.latestResponse.extension.screen)}`);
        frameContainer && frameContainer.destroy();
        this.clearPressAnyButton();
        this.Legends.setStatus('additionalRoll');
        App.updateButton('start', {disabled: true});

        this.freezeReels = this.latestResponse.extension.jokerReels;
        this.sendRoll();
    };

    /**
     * Return array of textures for animating in symbol info container
     * @param symbolIndex
     * @returns {*}
     */
    getSymbolInfoTextures = symbolIndex => symbolIndex === 8 ?
        [this.getTexture('star')] : // additional texture without background
        this.Roll.textures['regular'][symbolIndex];

    /**
     * Create texts for paymentBorder table
     * @param parentContainer
     * @param payTable
     * @param direction
     */
    drawSymbolInfoPayments(parentContainer, payTable, direction) {
        const bet = this.gameSettings.getBetLineCredit();
        const props = {
            fill: '#00ff07',
            stroke: '#007318',
            align: 'left',
            fontFamily: 'WitchFont',
            fontSize: 22,
            strokeThickness: 3,
            lineJoin: 'round'
        };

        if (payTable.length) {
            payTable.forEach((pay, index) => {
                const quantity = new Text(`x${pay[0]}`, props);
                quantity.position.x = direction === 'left' ? -65 : -45;
                quantity.position.y = 15 + (payTable.length === 1 ? -30 : -30 * index);

                const payment = new Text(` ${bet * pay[1]}`, {...props, fill: '#6bf2ff', stroke: '#1149ab'});
                payment.position.x = direction === 'left' ? -34 : -15;
                payment.position.y = 15 + (payTable.length === 1 ? -30 : -30 * index);

                parentContainer.addChild(quantity, payment);
            });
        } else {
            const wild = new Text('WILD', {...props, fontSize: 30});
            wild.position.x = direction === 'left' ? -50 : -25;
            wild.position.y = -17;
            parentContainer.addChild(wild);
        }
    }
}
