import App from './../../../index';
import GameChampion from './../../champion/game';
import Lines20 from './../../champion/lines20';
import GambleChampion from './../../champion/gamble';
import InfoScreen from '../../infoScreen';

export default class HotSevens extends GameChampion {
    constructor() {
        super();
        this.id = 'hot-sevens';
        this.name = 'Hot Sevens';
        this.symbolEffects = true;
        this.transparentBackground = true;
        this.reelTop = 89; // magic number - where reel images starts
        this.symbolHeight = 130; // height of a single symbol
        this.symbolWidth = 130; // width of a single symbol

        this.symbols = [
            {regularDelay: 70, skipSteps: 4, payment: [0, 0, 5, 20, 50, 200]},     // 0 - cherry
            {regularDelay: 70, skipSteps: 4, payment: [0, 0, 0, 20, 50, 200]},     // 1 - lemon
            {regularDelay: 70, skipSteps: 4, payment: [0, 0, 0, 20, 50, 200]},     // 2 - orange
            {regularDelay: 70, skipSteps: 4, payment: [0, 0, 0, 20, 50, 200]},     // 3 - plum
            {regularDelay: 70, skipSteps: 4, payment: [0, 0, 0, 50, 200, 500]},    // 4 - melon
            {regularDelay: 70, skipSteps: 4, payment: [0, 0, 0, 50, 200, 500]},    // 5 - grape
            {regularDelay: 70, skipSteps: 4, payment: [0, 0, 0, 100, 1000, 5000]}, // 6 - '7'
            {regularDelay: 70, skipSteps: 4, payment: [0, 0, 0, 2, 10, 50]}        // 7 - star
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.gameSounds = {soundClass: 'champion'};
        this.Lines = new Lines20();
        this.Gamble = new GambleUnique(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 2}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '14pt franklinFont';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#f7fa14';

        switch (page) {
            case 1:
                // '7'
                ctx.fillText(bet * this.symbols[6].payment[5], 200, 92);
                ctx.fillText(bet * this.symbols[6].payment[4], 200, 117);
                ctx.fillText(bet * this.symbols[6].payment[3], 200, 142);

                // melon
                ctx.fillText(bet * this.symbols[4].payment[5], 460, 92);
                ctx.fillText(bet * this.symbols[4].payment[4], 460, 117);
                ctx.fillText(bet * this.symbols[4].payment[3], 460, 142);

                // grape
                ctx.fillText(bet * this.symbols[5].payment[5], 720, 92);
                ctx.fillText(bet * this.symbols[5].payment[4], 720, 117);
                ctx.fillText(bet * this.symbols[5].payment[3], 720, 142);

                // plum
                ctx.fillText(bet * this.symbols[3].payment[5], 200, 253);
                ctx.fillText(bet * this.symbols[3].payment[4], 200, 278);
                ctx.fillText(bet * this.symbols[3].payment[3], 200, 303);

                // orange
                ctx.fillText(bet * this.symbols[2].payment[5], 460, 253);
                ctx.fillText(bet * this.symbols[2].payment[4], 460, 278);
                ctx.fillText(bet * this.symbols[2].payment[3], 460, 303);

                // lemon
                ctx.fillText(bet * this.symbols[1].payment[5], 720, 253);
                ctx.fillText(bet * this.symbols[1].payment[4], 720, 278);
                ctx.fillText(bet * this.symbols[1].payment[3], 720, 303);

                // chery
                ctx.fillText(bet * this.symbols[0].payment[5], 330, 418);
                ctx.fillText(bet * this.symbols[0].payment[4], 330, 440);
                ctx.fillText(bet * this.symbols[0].payment[3], 330, 465);
                ctx.fillText(bet * this.symbols[0].payment[2], 330, 486);

                // star
                ctx.fillText(bet * nLines * this.symbols[7].payment[5], 590, 418);
                ctx.fillText(bet * nLines * this.symbols[7].payment[4], 590, 443);
                ctx.fillText(bet * nLines * this.symbols[7].payment[3], 590, 468);
                break;
        }
    }
}

class GambleUnique extends GambleChampion {
    constructor(imageResources) {
        super(imageResources);
        this.cardsQueuePos = {x: [421, 487, 553, 619, 685], y: 80};
        this.screenCoordinates = {x: 0, y: 86};
    }
}
