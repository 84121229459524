import App from './../../../index';
import GameChampion from './../../champion/game';
import Lines20 from './../../champion/lines20';
import GambleChampion from './../../champion/gamble';
import InfoScreen from '../../infoScreen';

export default class TropicalFruit extends GameChampion {
    constructor() {
        super();
        this.id = 'tropical-fruit';
        this.name = 'Tropical Fruit';
        this.symbolEffects = true;
        this.transparentBackground = true;
        this.reelTop = 89; // magic number - where reel images starts
        this.symbolHeight = 130; // height of a single symbol
        this.symbolWidth = 130; // width of a single symbol
        this.buttonsPanelShadow = 'mid';

        this.symbols = [
            {regularDelay: 50, payment: [0, 0, 0, 10, 25, 150]},                  // 0 - carambola
            {regularDelay: 50, payment: [0, 0, 0, 10, 25, 150]},                  // 1 - coconut
            {regularDelay: 100, payment: [0, 0, 0, 15, 50, 200]},                 // 2 - banana
            {regularDelay: 100, payment: [0, 0, 0, 15, 50, 200]},                 // 3 - pineapple
            {regularDelay: 100, skipSteps: 1, payment: [0, 0, 0, 30, 150, 500]},  // 4 - lemon
            {regularDelay: 100, skipSteps: 1, payment: [0, 0, 0, 30, 150, 500]},  // 5 - peach
            {regularDelay: 100, payment: [0, 0, 0, 50, 500, 5000]},               // 6 - tropical fruit
            {regularDelay: 100, skipSteps: 1, payment: [0, 0, 0, 2, 10, 50]}      // 7 - star
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: `area/${App.System.resolution}/main.png`,
                background: 'area/background.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.gameSounds = {soundClass: 'champion'};
        this.Lines = new Lines20();
        this.Gamble = new GambleUnique({gambleArea: `/game/games/${this.id}/img/gamble/gamble-area.png`});
        this.InfoScreen = new InfoScreen({pages: 1}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '18pt sans-serif';
        ctx.textAlign = 'center';
        ctx.fillStyle = 'yellow';
        ctx.shadowColor = 'black';
        ctx.shadowOffsetX = 3;
        ctx.shadowOffsetY = 3;
        ctx.shadowBlur = 5;

        // 7 - Scatter
        this.strokeFillText(ctx, bet * nLines * this.symbols[7].payment[5], 450, 244);
        this.strokeFillText(ctx, bet * nLines * this.symbols[7].payment[4], 450, 274);
        this.strokeFillText(ctx, bet * nLines * this.symbols[7].payment[3], 450, 307);

        // 6 - Tropical Fruit
        this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 450, 126);
        this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 450, 156);
        this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 450, 186);

        // 4 Lemon
        this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 590, 132);
        this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 590, 162);
        this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 590, 192);

        // 5 Peach
        this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 200, 132);
        this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 200, 162);
        this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 200, 192);

        // 2,3 - Pineapple Banana
        this.strokeFillText(ctx, bet * this.symbols[3].payment[5], 200, 250);
        this.strokeFillText(ctx, bet * this.symbols[3].payment[4], 200, 280);
        this.strokeFillText(ctx, bet * this.symbols[3].payment[3], 200, 310);

        // 0,1 - Carambola Coconut
        this.strokeFillText(ctx, bet * this.symbols[1].payment[5], 590, 250);
        this.strokeFillText(ctx, bet * this.symbols[1].payment[4], 590, 280);
        this.strokeFillText(ctx, bet * this.symbols[1].payment[3], 590, 310);

        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }
}

class GambleUnique extends GambleChampion {
    constructor(imageResources) {
        super(imageResources);
        this.cardsQueuePos = {x: [421, 487, 553, 619, 685], y: 80};
        this.screenCoordinates = {x: 0, y: 86};
    }
}
