import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import GameDeluxe from './../../deluxe/game';
import Lines30 from './lines';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';

/* PIXI aliases */
const AnimatedSprite = PIXI.AnimatedSprite,
    Container = PIXI.Container,
    Sprite = PIXI.Sprite,
    Texture = PIXI.Texture,
    Text = PIXI.Text;

export default class Zeus extends GameDeluxe {
    constructor() {
        super();
        this.id = 'zeus';
        this.name = 'Zeus';
        this.scatter = 10;
        this.reelSettings = [16, 5, 20]; // 0 - start symbol amount, 1 - reel increase regular roll, 2 - reel increase long roll
        this.reelFilter = [[8], [], [], [], [8]];
        this.transparentBackground = true; // use transparent symbols fone or not
        this.buttonsPanelShadow = 'strong';
        this.reelTop = 89; // magic number - where reel images starts
        this.reelXCoordinates = [67, 201, 335, 469, 603]; // magic numbers - x coordinates where reels starts
        this.symbolHeight = 133; // height of a single symbol
        this.symbolWidth = 133; // width of a single symbol
        this.symbolEffects = false; // shadowing and none
        this.allowLongRoll = true;
        this.waitingType = '';
        this.doublingFilter = [0, 1, 2, 3, 4, 5, 6, 7, 9, 10];
        this.bonusFrameTimeout = null;

        // bonus frames coordinates
        this.coordinatesBonusFrame = {
            startBonusFrame: {x: 67, y: 89},
            bonusInBonusFrame: {x: 67, y: 89},
            endBonusFrame: {x: 52, y: 66}
        };

        this.symbols = [
            {regularDelay: 40, bonusDelay: 40, payment: [0, 0, 0, 5, 50, 150]},   // 0 - wreath
            {regularDelay: 40, bonusDelay: 40, payment: [0, 0, 0, 15, 75, 200]},  // 1 - gold coin
            {regularDelay: 40, bonusDelay: 40, payment: [0, 0, 0, 15, 75, 200]},  // 2 - silver coin
            {regularDelay: 40, bonusDelay: 40, payment: [0, 0, 0, 20, 100, 250]}, // 3 - vase
            {regularDelay: 40, bonusDelay: 40, payment: [0, 0, 0, 20, 100, 250]}, // 4 - harp
            {regularDelay: 40, bonusDelay: 40, payment: [0, 0, 0, 30, 100, 350]}, // 5 - helmet
            {regularDelay: 40, bonusDelay: 40, payment: [0, 0, 0, 30, 100, 350]}, // 6 - boat
            {regularDelay: 40, bonusDelay: 40, payment: [0, 0, 0, 40, 150, 400]}, // 7 - pegasus
            {regularDelay: 40, bonusDelay: 40, payment: [0, 0, 4, 50, 200, 500]}, // 8 - zeus
            {regularDelay: 40, bonusDelay: 40, payment: [0, 0, 0, 0, 0, 0]},      // 9 - wild
            {regularDelay: 40, bonusDelay: 40, payment: [0, 0, 0, 0, 0, 0]}       // 10 - hand with ligtning
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png',
                waitingAnimation: 'waitingAnimation.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                bonusArea: 'area/bonus.png',
                frame1: 'bonus/frame1.png',
                frame2: 'bonus/frame2.png',
                startButton: 'bonus/startButton.png',
                longRollFrame: 'longRollFrame.png',
                bonusAnimation1: 'bonus/bonusAnimation1.png',
                bonusAnimation2: 'bonus/bonusAnimation2.png',
                bonusAnimation3: 'bonus/bonusAnimation3.png',
                bonusAnimation0: 'bonus/bonusAnimation0.png'
            }),
            atlas: this.mergePath(['bonus/bonusSymbols.json'])
        };

        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [{name: 'intro'},
                {name: 'reels'},
                {name: 'reelsstop'},
                {name: 'win-line'},
                {name: 'long1'},
                {name: 'thunder'},
                {name: 'scatterStop1'},
                {name: 'scatterStop2'},
                {name: 'scatterStop3'},
                {name: 'bonus-background'},
                {name: 'scattersound'},
                {name: 'bonusEnd'},
                {name: 'bonusFrame'},
                {name: 'bonusFrameLoop', loop: true}],
            path: `/game/games/${this.id}/audio/`
        };
        this.Lines = new Lines30();
        this.Gamble = new GambleDeluxe();
        this.InfoScreen = new InfoScreen({pages: 4}); // number of game info states
        this.InfoScreen.format = 'png';
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '13pt franklinFont';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#020200';
        ctx.shadowColor = '#ffe400';
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 7;

        switch (page) {
            case 1:
                // Zeus
                this.strokeFillText(ctx, bet * this.symbols[8].payment[5], 630, 145);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[4], 630, 168);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[3], 630, 192);
                this.strokeFillText(ctx, bet * this.symbols[8].payment[2], 630, 213);

                // boat
                this.strokeFillText(ctx, bet * this.symbols[6].payment[5], 570, 276);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[4], 570, 297);
                this.strokeFillText(ctx, bet * this.symbols[6].payment[3], 570, 320);

                // pegasus
                this.strokeFillText(ctx, bet * this.symbols[7].payment[5], 295, 276);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[4], 295, 300);
                this.strokeFillText(ctx, bet * this.symbols[7].payment[3], 295, 323);

                // helmet
                this.strokeFillText(ctx, bet * this.symbols[5].payment[5], 430, 380);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[4], 430, 404);
                this.strokeFillText(ctx, bet * this.symbols[5].payment[3], 430, 427);
                break;
            case 2:
                // harp
                this.strokeFillText(ctx, bet * this.symbols[4].payment[5], 265, 173);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[4], 265, 196);
                this.strokeFillText(ctx, bet * this.symbols[4].payment[3], 265, 219);

                // vase
                this.strokeFillText(ctx, bet * this.symbols[3].payment[5], 575, 173);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[4], 575, 196);
                this.strokeFillText(ctx, bet * this.symbols[3].payment[3], 575, 219);

                // gold coin
                this.strokeFillText(ctx, bet * this.symbols[2].payment[5], 265, 274);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[4], 265, 297);
                this.strokeFillText(ctx, bet * this.symbols[2].payment[3], 265, 320);

                // silver coin
                this.strokeFillText(ctx, bet * this.symbols[1].payment[5], 575, 274);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[4], 575, 297);
                this.strokeFillText(ctx, bet * this.symbols[1].payment[3], 575, 320);

                // wreath
                this.strokeFillText(ctx, bet * this.symbols[0].payment[5], 440, 375);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[4], 440, 397);
                this.strokeFillText(ctx, bet * this.symbols[0].payment[3], 440, 420);
                break;
        }
    }

    setScatterSprite(scatterFeature) {
        scatterFeature.positions.forEach(position => {
            const {reel, row} = position;
            const symbolObj = this.reelMatrix[reel][row];
            symbolObj.image = 'regular';
            symbolObj.loop = false;
            this.Roll.updateSymbolSprite(symbolObj);
            symbolObj.sprite.play();
        });
    }

    /**
     * Drawing  the table of bonus game
     */
    showStartBonusFrame(parentContainer, {x, y}) {
        App.Sounds.playSound('bonusFrame'); // 6400 long
        this.bonusFrameTimeout = setTimeout(() => App.Sounds.playSound('bonusFrameLoop'), 6400);

        this.setState('BONUS_IDLE');
        this.getTexture('bonusArea') && this.setBackground('bonusArea');
        this.showBonusFrame(parentContainer, x, y, 'frame1');
        const richText = new Text(this.getFreeGames() + ' FREE SPINS', {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 50,
            fontWeight: 'bold',
            fill: ['#e6c02e'], // gradient
            stroke: '#0012ff',
            strokeThickness: 4,
            lineJoin: 'round'
        });
        richText.x = 220;
        richText.y = 180;
        parentContainer.addChild(richText);

        const richText1 = new Text('AWARDED', {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 50,
            fontWeight: 'bold',
            fill: ['#e6c02e'], // gradient
            stroke: '#0012ff',
            strokeThickness: 4,
            lineJoin: 'round'
        });
        richText1.x = 260;
        richText1.y = 260;
        parentContainer.addChild(richText1);

        App.updateButton('start', {
            disabled: false,
            title: 'start',
            handler: () => this.startButtonAnimation(parentContainer)
        });

        const container = new Container();
        container.name = 'startButtonContainer';
        container.sortableChildren = true;
        container.position.set(170, 360);
        parentContainer.addChild(container);

        const sprite = new Sprite(this.getTexture('startButton'));
        sprite.name = 'startButton';
        sprite.position.set(0, 0);
        sprite.interactive = true;

        sprite.on('pointerdown', () => {
            sprite.interactive = false;
            this.startButtonAnimation(parentContainer);
        });

        container.addChild(sprite);
        const richText2 = new Text('CLICK TO START BONUS', {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 25,
            fontWeight: 'bold',
            fill: ['#e6c02e'], // gradient
            stroke: '#0012ff',
            strokeThickness: 4,
            lineJoin: 'round'
        });
        richText2.x = 90;
        richText2.y = 18;
        container.addChild(richText2);
    }

    /**
     * drawBonusAnimation
     */
    drawBonusAnimation(parentContainer, {x, y}, firstLast) {
        App.Sounds.playSound('thunder');
        const textures = [...Array(7)].map((item, index) => {
            const width = 1067, height = 600;
            const imageNumber = Math.floor(index / 2);
            const colIndex = 0;
            const rowIndex = index % 2;
            return new Texture(this.getTexture(`bonusAnimation${imageNumber}`), {
                x: colIndex * width,
                y: rowIndex * height,
                width: width,
                height: height
            });
        });

        const sprite = new AnimatedSprite(textures);
        sprite.name = 'bookAnim';
        sprite.animationSpeed = 0.10;
        sprite.loop = false;
        sprite.position.set(-133, 0);
        parentContainer.addChild(sprite);
        sprite.play();
        sprite.onComplete = () => {
            !firstLast && this.showStartBonusFrame(parentContainer, {x, y});
            sprite.destroy();
        };
    }

    /**
     *  Drawing  the table of bonus in bonus game
     */
    showBonusInBonusFrame(parentContainer, {x, y}) {
        App.Sounds.playSound('bonusFrame');
        this.showBonusFrame(parentContainer, x, y, 'frame1');
        const richText = new Text(this.getFreeGames() + ' MORE FREE SPINS', {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 50,
            fontWeight: 'bold',
            fill: ['#e6c02e'], // gradient
            stroke: '#0012ff',
            strokeThickness: 4,
            lineJoin: 'round'
        });
        richText.x = 130;
        richText.y = 180;
        parentContainer.addChild(richText);

        const richText1 = new Text('AWARDED', {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 50,
            fontWeight: 'bold',
            fill: ['#e6c02e'], // gradient
            stroke: '#0012ff',
            strokeThickness: 4,
            lineJoin: 'round'
        });
        richText1.x = 260;
        richText1.y = 260;
        parentContainer.addChild(richText1);
        setTimeout(() => this.startBonusAnimation(parentContainer), 5000);
    }

    /**
     * Drawing  the table of end of bonus in bonus game
     */
    showEndBonusFrame(parentContainer, {x, y}, {win, freeGames}) {
        App.Sounds.playSound('bonusEnd');
        parentContainer.removeChildren();
        this.setRegularSprite();
        App.updateButton('start', {disabled: true});
        this.setBackground('mainArea');
        this.playEndBonusGameSound();
        setTimeout(() => this.endBonus(), 8000);

        this.showBonusFrame(parentContainer, x, y, 'frame2');
        const richText = new Text('TOTAL WIN', {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 38,
            fontWeight: 'bold',
            fill: ['#ffffff'], // gradient
            stroke: '#c01c19',
            strokeThickness: 4,
            lineJoin: 'round'
        });
        richText.x = 165;
        richText.y = 200;
        parentContainer.addChild(richText);

        const richText2 = new Text(this.bonusStatus.win, {
            align: 'center',
            fontFamily: 'Arial',
            fontSize: 75,
            fontWeight: 'bold',
            fill: ['#ffffff'], // gradient
            stroke: '#0012ff',
            strokeThickness: 4,
            lineJoin: 'round'
        });
        richText2.x = 210;
        richText2.y = 270;
        parentContainer.addChild(richText2);
    }

    playLongRollSound = reelIndex => {
        const extra = this.getExtraBet();
        if (this.reelLong[reelIndex] === 1 && reelIndex !== this.reels - extra) { // current reel without last reel with extraBet correction
            this.stopRollSound();
            this.stopLongRollSound();
            this.getStageChild('bonusContainer').removeChildren();
            setTimeout(() => App.Sounds.playSound('long1'), 50);

            const longRollEffect = new AnimatedSprite(this.getSpriteTextures({
                toFrame: 15, image: 'longRollFrame',
                width: 166, height: 368, colCount: 15
            }));
            longRollEffect.name = 'longRollEffect' + reelIndex;
            longRollEffect.position.set(this.reelXCoordinates[reelIndex] - 32, this.reelTop - 40);
            longRollEffect.scale.x = 1.14;
            longRollEffect.scale.y = 1.29;
            longRollEffect.alpha = 0;
            longRollEffect.animationSpeed = 0.3;
            longRollEffect.play();
            this.getStageChild('bonusContainer').addChild(longRollEffect);

            this.showAnimation({
                duration: 500,
                animations: [{sprite: longRollEffect, timeline: [{to: {alpha: 1}}]}]
            });
        }
    };

    setRegularShortSprite(clipMatrix, reelIndex, textures) {
        let scatter0 = 0;
        let scatter1 = 0;
        let scatter2 = 0;
        let scatter3 = 0;
        let scatter4 = 0;
        for (let reel = 0; reel <= reelIndex; reel++) {
            for (let rowIndex = 0; rowIndex < this.reelRows; rowIndex++) {
                if (reel === 0 && clipMatrix[reel][rowIndex + 1].symbol === this.scatter) {
                    scatter0++;
                }
                if (reel === 1 && clipMatrix[reel][rowIndex + 1].symbol === this.scatter) {
                    scatter1++;
                }
                if (reel === 2 && clipMatrix[reel][rowIndex + 1].symbol === this.scatter) {
                    scatter2++;
                }
                if (reel === 3 && clipMatrix[reel][rowIndex + 1].symbol === this.scatter) {
                    scatter3++;
                }
                if (reel === 4 && clipMatrix[reel][rowIndex + 1].symbol === this.scatter) {
                    scatter4++;
                }
            }
        }
        const scatterCount = scatter0 + scatter1 + scatter2 + scatter3 + scatter4;

        if (scatterCount === 1 && (reelIndex === 0 && scatter0) || (reelIndex === 1 && scatter1) || (reelIndex === 2 && scatter2)) {
            App.Sounds.playSound('scatterStop1');
        }
        if (scatterCount === 2 && (reelIndex === 1 && scatter1) || (reelIndex === 2 && scatter2) || (reelIndex === 3 && scatter3)) {
            App.Sounds.playSound('scatterStop2');
        }
        if (scatterCount === 3 && (reelIndex === 2 && scatter2) || (reelIndex === 3 && scatter3) || (reelIndex === 4 && scatter4)) {
            App.Sounds.playSound('scatterStop3');
        }
    }

    /**
     * Called to show round results once animation is finished
     */
    rotationDone() {
        this.createReelMatrix(this.getStageChild('reelsStage'));
        this.getStageChild('bonusContainer').removeChildren();
        this.onRotationDone();
        App.System.statistics.currentSpinNumber < 3 && App.System.collectFps();
    }

    playGameWaitSound = () => {

    };

    playFeatureSound(currentFeature, featureIndex, features) {
        let soundFile = null;
        switch (currentFeature.uc) {
            case 'WIN_LINE':
                soundFile = 'win-line';
                break;
            case 'SCATTER':
                soundFile = 'scattersound';
                break;
        }

        soundFile && App.Sounds.stopSound(soundFile);
        soundFile && App.Sounds.playSound(soundFile);
    }

    addWaitingAnimationSprites(parentContainer) {
        const waitingType = this.getWaitingState();
        let sprite;
        if (this.waitingType === '') {
            parentContainer.removeChildren();
            sprite = new AnimatedSprite(this.getSpriteTextures({
                toFrame: 4, image: 'waitingAnimation',
                width: 273, height: 92
            }));
            sprite.name = 'waitingAnimation';
            sprite.position.set(505, 0);
            sprite.animationSpeed = 0.15;
            sprite.gotoAndStop(Math.round(Math.random() * 2));
            parentContainer.addChild(sprite);
        }
        if ((this.waitingType === 'INFO' && waitingType !== 'INFO') || (waitingType === 'SHOW_WIN_LINES')) {
            parentContainer.getChildByName('waitingAnimation').gotoAndStop(Math.round(Math.random() * 2));
        }
        if (waitingType === 'INFO') {
            parentContainer.getChildByName('waitingAnimation').gotoAndStop(3);
        }
        // disable waiting for bonus game
        parentContainer.getChildByName('waitingAnimation').visible =
            !(this.bonusStatus || this.gameFlag.bonusStarted || waitingType === 'BONUS_IDLE');

        this.waitingType = waitingType;
    }

    /**
     * Get current Game state
     * @returns {string|*}s
     */
    getWaitingState() {
        let state = this.getState();
        switch (state) {
            case 'CHOOSING_IDLE':
                break;
            case 'DOUBLING':
                break;
            case 'SUPER_BONUS_IDLE':
                break;
            case 'SHOW_WIN_LINES':
                state = 'SHOW_WIN_LINES';
                break;
            case 'INFO':
            case 'INFO-SLIDE':
                state = 'INFO';
                break;
            case 'SCATTER_FEATURE':
                break;
            case 'RESPONSE_RECEIVED':
                break;
            case 'DRAW_GAME':
            case 'GAMBLE_ANIMATION':
            case 'CHOOSING_ANIMATION':
            case 'ANIMATE_MULTIPLIER':
                state = 'GAMBLE_ANIMATION';
                break;
            case 'BONUS_IDLE':
                state = 'BONUS_IDLE';
                break;
            default:
                state = 'IDLE';
                break;
        }
        return state;
    }

    /**
     * Set global state for Game
     * @param state
     */
    setState(state) {
        this.state = state;
        this.app && this.addWaitingAnimationSprites(this.getStageChild('waitingContainer'));
    }

    drawBonusFrame(first, last, parentContainer, coordinates) {
        const {startBonusFrame, bonusInBonusFrame, endBonusFrame} = coordinates;
        App.Sounds.stopSound('bonus-background');
        if (first) {
            App.Sounds.playSound('animation');
            this.drawBonusAnimation(parentContainer, startBonusFrame, 0);
        }
        if (last) {
            App.Sounds.playSound('animation');
            this.showEndBonusFrame(parentContainer, endBonusFrame, this.bonusStatus);
            this.drawBonusAnimation(parentContainer, endBonusFrame, 1, this.bonusStatus);
        }
        if (!first && !last) {
            this.showBonusInBonusFrame(parentContainer, bonusInBonusFrame);
        }
    }

    getRandomSymbol(length, reelIndex, symbolBefore) {
        const denyRepeat = Math.floor(Math.random() * 4); // decrease repeat 5 times less
        let symbol = Math.floor(Math.random() * length);
        if (denyRepeat === 0 && this.doublingFilter.indexOf(symbolBefore) === -1) {
            symbol = symbolBefore;
        } else {
            while (
                symbol === this.reelFilter[reelIndex][0] ||
                symbol === this.reelFilter[reelIndex][1] ||
                symbol === this.reelFilter[reelIndex][2] ||
                symbol === this.reelFilter[reelIndex][3] ||
                symbol === symbolBefore) {
                symbol = Math.floor(Math.random() * length);
            }
        }
        return symbol;
    }

    cleanBeforeRoll() {
        this.stopAnimateFeature();
        this.stopWaitingAnimation();
        App.removePopupMessage();
        App.System.resetRollStatistics();
        this.InfoScreen.update({timeout: false, page: 1});
        this.extraBet && this.updateExtraBetButtons(false);
        this.latestResponse = null;
        this.SymbolInfo.remove(false);

        if (!this.isBonus() && this.getState() !== 'IDLE_BONUS') {
            this.Legends.setStatus('goodLuck');
            this.Legends.showJackpot();
            App.Money.withDraw(this.gameSettings.getBet());
            App.updateState('moneyParams', {
                credits: App.Money.getCredit(),
                money: App.Money.getMoney()
            });
        }
        this.Legends.clearText('features');
        this.Legends.clearText('win');
    }

    setBonusStatusText() {
        let {remain} = this.bonusStatus;
        remain -= this.getAmountFromLastResponse();
        this.Legends.setStatus('freeSpinsRemaining', {remain});
    }

    /**
     * Function to play animate credit sound
     */
    playCreditSound() {
    }

    /**
     * Function to get free roll count before bonusStatus will be set.
     */
    getFreeGames() {
        let amount = 0;
        this.latestResponse.features.forEach(feature => {
            if (feature.uc === 'FREE_ROLL') amount = feature.amount;
        });
        return amount;
    }

    /**
     * Show bonus message with bonus symbol or with bonus game win.
     * @param isFirstBonus {boolean} TRUE if this is the message for starting bonus game.
     */
    drawBonusAskButton(isFirstBonus = false) {
        let isLast = !isFirstBonus && this.bonusStatus && this.bonusStatus.remain === 0;

        this.stopAnimateFeature();
        setTimeout(() => {
            this.drawBonusFrame(isFirstBonus, isLast, this.getStageChild('bonusContainer'), this.coordinatesBonusFrame);
            this.showPressAnyButton(isLast || !isFirstBonus);
        }, 1000);

        this.gameFlag.bonusStart = true;
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});

        isLast = this.bonusStatus && this.bonusStatus.remain === 0;
    }

    /**
     * Function to select bonus sprite at bonus
     */
    getSymbolImageType = () =>
        this.gameFlag.bonusStart || this.getState() === 'IDLE_BONUS' ?
            'bonus' : 'static';

    /**
     * Animation before start bonus -> call startBonusAnimation
     */
    startButtonAnimation = (parentContainer) => {
        App.updateButton('start', {
            disabled: true,
            title: 'start',
            handler: () => this.startButtonAnimation(parentContainer)
        });
        const container = parentContainer.getChildByName('startButtonContainer');
        container.interactive = false;
        const ticker = this.app.ticker;
        const func = () => {
            container.scale.x -= 0.03;
            container.scale.y -= 0.03;
            container.position.x = 170 + 232 - 232 * container.scale.x;
            container.position.y = 360 + 36 - 36 * container.scale.y;
            if (container.scale.x <= 0) {
                ticker.remove(func);
                parentContainer.removeChild(container);
                this.startBonusAnimation(parentContainer);
            }
        };
        ticker.add(func);
    };

    /**
     * Prepare game behaviour after bonus 'press any button' message
     */
    startBonusAnimation = () => {
        clearTimeout(this.bonusFrameTimeout);
        App.Sounds.stopSound('bonusFrame');
        App.Sounds.stopSound('bonusFrameLoop');
        App.Sounds.stopSound('banner-win');

        this.gameFlag.bonusStarted = true;
        this.clearPressAnyButton();
        this.Buttons.disableAllButtons();
        App.updateButton('start', {disabled: true});
        this.bonusRoll();
    };

    /**
     * End feature animation / take win without prompt
     * @returns {boolean} - new animation features circle

     */
    endAnimateFeature() {
        this.winLineFeatureDelay = this.defaultFeatureDelay;
        App.Sounds.stopSound('win-line');
        if (this.getState() === 'SHOW_WIN_LINES' && this.latestResponse.payment) {
            this.takeWin();
        } else { // no win, just feature without payment
            this.roundFinished();
        }
        return true;
    }
}
