import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import Lines from './../lines';
import App from './../../index';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Texture = PIXI.Texture,
    Text = PIXI.Text;

export default class Lines17 extends Lines {
    constructor() {
        super();
        this.imageResources = {
            lines: '/img/lines/Lines17/lines.png',
            boxes: '/img/lines/Lines17/boxes.png'
        };
        this.lineImageWidth = 690; // width of line image in sprite, magic number for each game
        this.lineImageHeight = 226; // height of line image in sprite, magic number for each game

        this.lines = {
            0: {
                coordinates: [1, 1, 1],
                boxes: [{x: 0, y: 271}],
                path: [{x: 29, y: 268}]
            },
            1: {
                coordinates: [0, 0, 0],
                boxes: [{x: 0, y: 133}],
                path: [{x: 29, y: 131}]
            },
            2: {
                coordinates: [2, 2, 2],
                boxes: [{x: 0, y: 408}],
                path: [{x: 29, y: 409}]
            },
            3: {
                coordinates: [0, 1, 2],
                boxes: [{x: 0, y: 93}],
                path: [{x: 29, y: 95}]
            },
            4: {
                coordinates: [2, 1, 0],
                boxes: [{x: 0, y: 447}],
                path: [{x: 29, y: 105}]
            },
            5: {
                coordinates: [0, 1, 0],
                boxes: [{x: 0, y: 173}],
                path: [{x: 29, y: 170}]
            },
            6: {
                coordinates: [2, 1, 2],
                boxes: [{x: 0, y: 370}],
                path: [{x: 29, y: 182}]
            },
            7: {
                coordinates: [1, 0, 1],
                boxes: [{x: 0, y: 233}],
                path: [{x: 29, y: 102}]
            },
            8: {
                coordinates: [1, 2, 1],
                boxes: [{x: 0, y: 310}],
                path: [{x: 29, y: 262}]
            },
            9: {
                coordinates: [2, 1, 1],
                boxes: [{x: 765, y: 260}],
                path: [{x: 75, y: 250}]
            },
            10: {
                coordinates: [0, 1, 1],
                boxes: [{x: 765, y: 298}],
                path: [{x: 75, y: 178}]
            },
            11: {
                coordinates: [1, 2, 2],
                boxes: [{x: 765, y: 390}],
                path: [{x: 75, y: 270}]
            },
            12: {
                coordinates: [1, 0, 0],
                boxes: [{x: 765, y: 158}],
                path: [{x: 75, y: 163}]
            },
            13: {
                coordinates: [1, 1, 2],
                boxes: [{x: 765, y: 428}],
                path: [{x: 75, y: 304}]
            },
            14: {
                coordinates: [1, 1, 0],
                boxes: [{x: 765, y: 120}],
                path: [{x: 75, y: 113}]
            },
            15: {
                coordinates: [2, 2, 1],
                boxes: [{x: 765, y: 336}],
                path: [{x: 75, y: 320}]
            },
            16: {
                coordinates: [0, 0, 1],
                boxes: [{x: 765, y: 223}],
                path: [{x: 75, y: 65}]
            }
        };

        JL().debug('-- Lines17_deluxe initialized');
    }

    drawLineImages(lines, winReels, parentContainer, winLine, payment) {
        lines.forEach(lineIndex => {
            const line = this.lines[lineIndex];

            line.coordinates.forEach(() => {
                this.lineImageWidth = 690;
                const spritePosX = lineIndex > 8 ? 1 : 0;
                let spritePosY = this.lineImageHeight * lineIndex;
                const newLineIndex = spritePosX === 1 ? (lineIndex % 9) : lineIndex;

                if (lineIndex < 3) {
                    this.lineImageHeight = 25;
                    spritePosY = this.lineImageHeight * newLineIndex;
                }
                if (lineIndex >= 3 && lineIndex < 5) {
                    this.lineImageHeight = 370;
                    spritePosY = 75 + this.lineImageHeight * (newLineIndex - 3);
                }
                if (lineIndex >= 5) {
                    this.lineImageHeight = 328;
                    spritePosY = 75 + 370 * 2 + this.lineImageHeight * (newLineIndex - 5);
                }

                if (lineIndex >= 9) {
                    this.lineImageHeight = 328;
                    spritePosY = this.lineImageHeight * newLineIndex;
                }

                if (lineIndex === 16) {
                    spritePosY = spritePosY - 30;
                }

                // create line sprite
                const sprite = new Sprite(new Texture(App.Game.getTexture('lines'), {
                    x: spritePosX * this.lineImageWidth,
                    y: spritePosY,
                    width: this.lineImageWidth,
                    height: this.lineImageHeight
                }));
                sprite.position.set(line.path[0].x, line.path[0].y);
                sprite.name = lineIndex;
                parentContainer.addChild(sprite);
            });
        });
    }

    drawBoxes(parentContainer) {
        parentContainer.removeChildren(); // clear all previous boxes

        const currentLines = App.Game.gameSettings.getCurrentLineMas();
        const boxWidth = 35; // width of winline boxes
        const boxHeight = 34; // height of winline boxes

        Object.keys(this.lines).forEach(lineIndex => {
            this.lines[lineIndex].boxes.forEach(box => {
                const activeLine = currentLines.includes(+lineIndex + 1);

                // create box sprite
                const sprite = new Sprite(new Texture(App.Game.getTexture('boxes'), {
                    x: 0,
                    y: activeLine ? boxHeight * lineIndex : // if line enabled
                        box.x === 0 ? boxHeight * 18 : boxHeight * 17, // check left/right box
                    width: boxWidth,
                    height: boxHeight
                }));
                sprite.position.set(box.x, box.y);
                sprite.name = lineIndex;
                activeLine && this.addBoxEvents(sprite);
                parentContainer.addChild(sprite);

                // add text to active box
                if (activeLine) {
                    const text = new Text(+lineIndex + 1, {
                        fontFamily: 'Arial',
                        fontSize: 19,
                        fill: '#000',
                        lineJoin: 'round'
                    });
                    text.anchor.set(0.5);
                    text.position.set(box.x + boxWidth / 2, box.y + boxHeight / 2);
                    parentContainer.addChild(text);
                }
            });
        });
    }
}
