import * as PIXI from 'pixi.js-legacy';
import {JL} from 'jsnlog';

import App from './../index';

/* PIXI aliases */
const Sprite = PIXI.Sprite,
    Texture = PIXI.Texture,
    Container = PIXI.Container;

export default class Roll {
    constructor() {
        this.reelsContainers = []; // array of PIXI.Container for each reels
        this.reelsSetting = [];
        this.beforeScreenSymbols = [];
        this.clipMatrix = []; // array of reels
        this.textures = {}; // collection for each symbol texture types ('regular', 'bonus', ...)
        this.stopReels = [0, 0, 0, 0, 0, 0]; // 0-normal animation, 1-fastStop
        this.fastReelsSound = true;
    }

    parseJsonImages(jsonCollection, resources) {
        jsonCollection.forEach(json => {
            // create Texture for each symbol image
            resources[json]?.textures && Object.keys(resources[json].textures).forEach(key => {
                const texture = new Texture.from(key);
                const filenameParts = texture.textureCacheIds[0].split('_');

                // take symbol info from filename
                const type = filenameParts[0]; // 'regular', 'bonus', ...
                const symbolIndex = parseInt(filenameParts[1]);

                // set steps
                App.Game.symbols[symbolIndex][`${type}Steps`] = parseInt(filenameParts[2]) + 1;

                // check texture collection, create empty array for each symbol index
                if (!this.textures[type]) {
                    this.textures[type] = [...Array(App.Game.symbols.length)].map(() => []);
                }

                this.textures[type][symbolIndex].push(texture);
            });
        });
    }

    /**
     * Create PIXI.Container for reels
     * @param parentContainer
     * @param transparentReels - transparent game symbols
     */
    createReelsContainer(parentContainer, transparentReels) {
        transparentReels && this.createReelsBackground(parentContainer);

        const container = new Container();
        container.sortableChildren = true;
        container.name = 'reelsStage';
        container.zIndex = App.Game.containersLayers[container.name];
        container.position.set(App.Game.reelXCoordinates[0], App.Game.reelTop);
        parentContainer.addChild(container);

        JL().debug(`-- Create reel matrix: ${JSON.stringify(App.Game.lastScreen)}`);
        App.Game.createReelMatrix(container, App.Game.lastScreen);
    }

    /**
     * Create PIXI.Sprite with reels background
     * @param parentContainer
     */
    createReelsBackground = parentContainer => {
        const sprite = new Sprite(App.Game.getTexture('background'));
        sprite.name = 'reelsBackground';
        parentContainer.addChild(sprite);
    };

    /**
     * Create collection of PIXI.Sprite objects
     * Create containers for each reel (PIXI.Container) and append collection to it
     * Add reels containers to one stage
     */
    createClipMatrix(reelMas) {
        App.Game.getStageChild('reelsStage').removeChildren();
        App.Game.reelMatrix = [];
        this.clipMatrix = [];
        const mask = App.Game.getReelsMask();
        mask.zIndex = 20;

        reelMas.forEach((vector, reelIndex) => {
            const {reelXCoordinates, reelSymbol, reelRows, symbols, symbolHeight} = App.Game;
            const container = new Container();
            container.name = `reel${reelIndex}`;
            container.sortableChildren = true;
            container.mask = mask;
            container.position.set( // set start position
                reelXCoordinates[reelIndex] - reelXCoordinates[0],
                -(reelSymbol[reelIndex] - reelRows - 2) * symbolHeight
            );

            this.reelsContainers[reelIndex] = container;
            this.addReelEvents(container);

            this.clipMatrix[reelIndex] = [];
            vector.forEach((symbolIndex, rowIndex) => {
                const symbolParams = symbols[symbolIndex];
                const {offsetX = 0, offsetY = 0} = symbolParams;

                const symbolObj = App.Game.createStageContainers(container, symbolIndex, rowIndex, reelIndex);
                const symbolContainer = symbolObj.symbolContainer;

                // set position, check offsetY
                symbolContainer.position.set(
                    symbolContainer.position.x - offsetX,
                    symbolHeight * (rowIndex - 1) - offsetY
                );

                this.clipMatrix[reelIndex][rowIndex] = symbolObj;
            });

            // add reel to reels container
            App.Game.getStageChild('reelsStage').addChild(container);
        });
    }

    /**
     * Remove reels
     * Save extra symbols (with offset or zIndex)
     * @param parentContainer
     */
    removeClipMatrix(parentContainer) {
        const {reelXCoordinates, reelRows, symbols, symbolHeight, symbolWidth} = App.Game;
        const container = new Container();
        container.name = 'extraSymbols';
        container.zIndex = 10;

        this.clipMatrix.forEach((reel, reelIndex) => {
            reel.forEach((symbolObj, rowIndex) => {
                const {sprite, symbol} = symbolObj;
                const {offsetY = 0, offsetX = 0, zIndex = 0} = symbols[symbol];

                // move extra symbol to new stage
                if ([0, reelRows + 1].includes(rowIndex) && (offsetY || offsetX || zIndex)) {
                    sprite.position.set(
                        reelXCoordinates[reelIndex] - reelXCoordinates[0] + symbolWidth / 2 - offsetX,
                        (rowIndex - 1) * symbolHeight - offsetY + symbolHeight / 2
                    );
                    container.addChild(sprite); // save extra symbols to other container
                }
            });
        });

        this.clipMatrix = [];
        parentContainer.removeChildren();

        // add extra symbols to cleared container
        container.children.length && parentContainer.addChild(container);
    }

    /**
     * Update current symbol object
     * @param symbolObj
     */
    updateSymbolSprite(symbolObj) {
        const {symbols} = App.Game;
        const {symbolContainer, sprite, position: {x, y}, symbol, image, loop} = symbolObj;
        const symbolParams = symbols[symbol];
        const {regularDelay, offsetY = 0, offsetX = 0, skipSteps} = symbolParams;
        const delay = symbolParams[`${image}Delay`] || regularDelay;

        symbolContainer.position.set(x - offsetX, y - offsetY);
        sprite.animationSpeed = 15 / delay;
        sprite.textures = this.textures[image][symbol];
        sprite.loop = skipSteps ? false : loop; // loop if no skipSteps

        // slice textures by skipSteps after symbol played
        sprite.onComplete = () => {
            const textures = [...this.textures[image][symbol]];
            textures.splice(0, skipSteps);
            sprite.textures = textures;
            sprite.loop = true;
            sprite.play();
        };
    }

    /**
     * Function to prepare reelMas according to latestResponse.screen and oldScreen
     * @param screen
     * @param oldScreen
     */
    fillReels(screen, oldScreen = screen) {
        const lastScreen = [];
        let reelMas = [...Array(App.Game.reels)].map(() => []); // prepare collection of arrays
        App.Game.setReelSymbol(screen);

        screen.forEach((reel, reelIndex) => {
            let i = 0;

            let symbolBefore = reel[1];  // symbol before top
            let symbol = App.Game.getRandomSymbol(App.Game.symbols.length, reelIndex, symbolBefore);
            lastScreen.push(symbol);

            // 0 top reel symbol will be able to seen  by roll spring
            reelMas[reelIndex][i] = symbol;
            i++;

            // 1 2 3 position of roll screen. Those symbol will be animated on after roll
            reel.forEach(symbol => {
                symbolBefore = symbol;
                reelMas[reelIndex][i] = symbol;
                i++;
            });

            // 3-6 random symbols on screen by substitution
            for (let j = 0; j < App.Game.reelSymbol[reelIndex] - (1 + App.Game.reelRows + App.Game.reelRows + 2); j++) { // 4 5 6   random symbols
                symbol = App.Game.getRandomSymbol(App.Game.symbols.length, reelIndex, symbolBefore);
                symbolBefore = symbol;
                reelMas[reelIndex][i] = symbol;
                i++;
            }

            // 7 повтор самого верхнего елемента
            reelMas[reelIndex][i] = +this.beforeScreenSymbols[reelIndex] || App.Game.getRandomSymbol(App.Game.symbols.length, reelIndex, symbolBefore);
            i++;

            // 8 9 10  position of oldScreen screen
            oldScreen && oldScreen[reelIndex].forEach(symbol => {
                reelMas[reelIndex][i] = symbol;
                symbolBefore = symbol;
                i++;
            });

            // 11 add last reel symbol, it will be invisible at roll
            symbol = symbolBefore = symbol;
            App.Game.getRandomSymbol(App.Game.symbols.length, reelIndex, symbolBefore);
            reelMas[reelIndex][i] = symbol;
        });

        this.beforeScreenSymbols = lastScreen;
        reelMas = App.Game.additionalFilter(reelMas);  // to change symbol image depends on reel like Columbus ship image 9 10 11

        if (!App.Game.isLongSymbolOnScreen(reelMas)) {  // if no Long symbol on screen eat - add long symbols like Ice cell 9-10
            reelMas = App.Game.addLongSymbol(reelMas);
        }

        return reelMas;
    }

    /**
     * Start reel rolling animation
     */
    startReelAnimation(response) {
        this.reelsSetting = [...Array(App.Game.reels)].map(() => ({})); // create obj for each reel
        const reelMas = this.fillReels(response.screen, App.Game.lastScreen);
        this.createClipMatrix(reelMas);
        this.stopReels = [0, 0, 0, 0, 0, 0];
        App.Game.stickSpine();

        this.reelAnimationStep(Date.now(), Date.now(), 0);
        App.Game.respinFlag ?
            App.Game.playRespinSound() :
            App.Game.playRollSound();
        App.Game.lastScreen = App.Game.latestResponse.screen;
        this.fastReelsSound = false;
    }

    reelStopSound(reelIndex) {
        const reelSetting = this.reelsSetting[reelIndex];

        if (!reelSetting.stopSound) { // барабан уже остановился запускаем звук
            reelSetting.stopSound = true; // звук проиграли
            App.Game.playReelStopSound();
            App.Game.setRegularShortSprite(this.clipMatrix, reelIndex, this.textures);
        }
        if (App.Game.reelLong[reelIndex + 1] === 1 && !this.stopReels[reelIndex]) {
            App.Game.playLongRollSound(reelIndex + 1);
        }
    }

    /**
     * Bezier function
     * @param step - time step
     * @param start - all move time
     * @returns {number}
     */
    bezier = (step, start) => {
        const t = 1 - step / start;  // все время движения

        const y0 = 0.1;
        const y1 = 1.3;
        const y2 = 1.3;
        const y3 = 0.6;

        const cy = 3 * (y1 - y0);
        const by = 3 * (y2 - y1) - cy;
        const ay = y3 - y0 - cy - by;

        const yt = ay * t * t * t + by * t * t + cy * t + y0;

        return 0.1 + yt / 1.5;
    };

    /**
     * Start reel rolling one step frame
     */
    reelAnimationStep(animationStarted, lastTime, frameCount) {
        const timeSpent = Date.now() - animationStarted; // time from last action
        // calc time from last drawing
        const timeDiff = Date.now() - lastTime;

        frameCount++;
        animationStarted !== lastTime && App.System.collectRollStatistics(timeSpent, frameCount, timeDiff);

        // save time before drawing
        lastTime = Date.now();

        let iReelsSumOffset = 0;
        this.reelsSetting.forEach((reelSetting, reelIndex) => {
            reelSetting.freezed = App.Game.isReelFreezed(reelIndex);

            if (reelSetting.way !== 5 && reelSetting.way !== 6) { // не остановился, рисуем его
                iReelsSumOffset += this.drawReelNew(reelIndex, timeDiff);
            }
        });

        if (!iReelsSumOffset) {
            App.Game.stopLongRollSound();  // stop All possible roll sounds
            App.Game.stopScatterSound();
            App.Game.stopRollSound();
            App.Game.rotationDone();
        } else {
            this.reelStepAnimationFrame =
                requestAnimationFrame(this.reelAnimationStep.bind(this, animationStarted, lastTime, frameCount));
        }
    }

    /**
     * отрисовка барабанов
     * @param reelIndex
     * @param timeDiff
     */
    drawReelNew(reelIndex, timeDiff) {
        const reelSetting = this.reelsSetting[reelIndex];
        const symbolHeight = App.Game.symbolHeight;

        const springDown = symbolHeight * App.Game.rollProperties.springDown;
        const springUp = symbolHeight * App.Game.rollProperties.springUp;

        const speedDown1 = App.Game.rollProperties.reelSpeed;
        const speedDown2 = 0.7;
        const speedUp = 0.3;
        const speedDown3 = 0.2;

        let offset = 1;   // 1 -reel rolling 0 -stopped
        const stopPos = App.Game.symbolHeight;
        const springStartPosition = (App.Game.reelSymbol[reelIndex] - (App.Game.reelRows + 1)) * App.Game.symbolHeight; // top of the window  to copy from virtual canvas
        const springBaseRoll = stopPos;
        const springDownPosition = stopPos - springDown;  // spring dropping symbol down on this value
        const springUpPosition = stopPos + springUp;   // spring jumping up symbol up on this value
        const springStopPosition = stopPos;   // spring jumping up symbol up on this value

        const delta = Math.round(timeDiff * speedDown1);

        if (typeof reelSetting.position === 'undefined') {
            reelSetting.position = springStartPosition;
            reelSetting.way = 1;
        }

        let newOffset = reelSetting.position;
        let way = reelSetting.way;
        const stop = reelSetting.stop;

        switch (way) {
            case 1: // двигаем барабан вниз на пол сивола ниже чем нужно
                if (newOffset > springBaseRoll) {
                    // newOffset = newOffset - (delta * speedDown1); // linear roll
                    const bezierStep = this.bezier(newOffset, springStartPosition);
                    newOffset = newOffset - (delta * speedDown1 * bezierStep); // bezier roll
                } else {
                    newOffset = springBaseRoll;
                    way = 2;
                    this.reelStopSound(reelIndex);
                }
                break;
            case 2: // двигаем барабан вниз на пол сивола ниже чем нужно
                if (newOffset > springDownPosition) {
                    newOffset = newOffset - (delta * speedDown2);
                } else {
                    newOffset = springDownPosition;
                    way = 3;
                }
                break;
            case 3: // двигаем барабан и поднимаем на пол символа выше чем нужно
                if (newOffset < springUpPosition) {
                    newOffset = newOffset + (delta * speedUp);
                } else {
                    newOffset = springUpPosition;
                    way = 4;
                }
                break;
            case 4: // двигаем барабан до точки остановки
                if (newOffset > springStopPosition) {
                    App.Game.hideTopSymbols(reelIndex);
                    newOffset = newOffset - (delta * speedDown3);
                } else {
                    App.Game.hideBottomSymbols(reelIndex);
                    newOffset = springStopPosition;
                    way = 5;
                    offset = 0;
                }
                break;
        }

        // ускореная остановка одного барабанов, передвигаем его шаг на момент выполнения пружинки
        if (this.stopReels[reelIndex] === 1 && !stop) {
            if (newOffset > (springBaseRoll + (reelIndex * 70)) && way === 1) {
                way = 1;
                newOffset = springBaseRoll + (reelIndex * 70);
            }
        }

        // don't move freeze reel
        if (reelSetting.freezed) {
            newOffset = springStopPosition;
            way = 5;
            offset = 0;
        }

        newOffset = Math.round(newOffset);
        reelSetting.position = newOffset;
        reelSetting.way = way;
        reelSetting.stop = stop;

        this.reelsContainers[reelIndex].position.y = -newOffset + App.Game.symbolHeight;
        return offset;
    }

    /**
     * Stop reel animation
     */
    clearRoll() {
        cancelAnimationFrame(this.reelStepAnimationFrame);
        this.reelStepAnimationFrame = null;
    }

    /**
     * Create events for boxes
     * SStop reel by onclick
     * @param parentContainer
     */
    addReelEvents = parentContainer => {
        if (App.Game.stopOneReel) {
            parentContainer.buttonMode = true; // shows hand cursor
            parentContainer.interactive = true;
            parentContainer
                .on('pointerdown', () => {
                    parentContainer.interactive = false;
                    const reelIndex = parentContainer.name.slice(parentContainer.name.length - 1, parentContainer.name.length);
                    this.stopReels[reelIndex] = !this.stopReels[reelIndex] ? 1 : this.stopReels[reelIndex];
                });
        }
    };
}
