import React from 'react';
import {JL} from 'jsnlog';

import './styles.less';
import App from './../../index';
import Login from './../Login';
import Menu from './../Menu';
import Game from './../Game';
import KioskInfo from './../kioskInfo';
import SecondScreen from './../secondScreen';
import Loader from './../Loader';
import Popup from './../popup';
import Modal from './../modal';
import ClientErrors from './../clientErrors';
import SwipeUp from './../swipeUp';
import Waiting from '../waiting';

export default class View extends React.Component {
    constructor(props) {
        super(props);
        const {lang, initState} = props;

        this.state = {
            currentState: initState, // 'MENU' / 'GAME' / 'INFO'
            currentInfoState: 'LOGO', // 'LOGO' / 'GAME'
            lang,
            orientation: null,
            ...this.getInitState(),
            showedMessage: false
        };
    }

    /**
     * Get all basic states
     * Used for reset components
     */
    getInitState = () => ({
        profile: {history: null},
        buttons: {
            visualization: App.buttonsType,
            animation: '',

            // control buttons

            message: {
                visible: App.configs.subMode === 'login-primary',
                disabled: false,
                handler: App.Modal.showProfileMes
            },
            fullScreen: {
                visible: !['kiosk', 'kiosk2', 'vlt-primary'].includes(App.configs.subMode) &&
                    App.System.platform !== 'mobile' && !App.System.isPWAInstalled(),
                handler: App.fullScreenClick,
                img: App.getUrl('/img/main/full_screen.svg')
            },
            soundVolume: {
                visible: true,
                disabled: false,
                handler: App.soundClick,
                defaultVolume: App.Sounds.volume,
                imgSrc: '',
                soundSpinner: ''
            },
            langBtn: {
                visible: true,
                disabled: false
            },
            close: {
                visible: !['kiosk2', 'game', 'vlt-primary'].includes(App.configs.subMode),
                disabled: ['vlt', 'player'].includes(App.configs.mode),
                handler: App.closeClick
            },
            user: {
                visible: App.configs.subMode === 'login-primary',
                disabled: false,
                handler: App.Modal.showProfile
            },
            installPWA: {
                visible: !(App.configs.mode === 'site' || App.configs.mode === 'demo') && !App.System.isPWAInstalled() && App.System.platform === 'mobile',
                handler: null
            },

            // main game buttons
            start: {
                disabled: true,
                title: 'start',
                handler: null
            },
            select: {
                disabled: true,
                title: 'select',
                subTitle: '',
                handler: null,
                additionalClass: ''
            },
            info: {
                disabled: true,
                title: 'paytable',
                handler: null,
                additionalClass: ''
            },
            autoStart: {
                disabled: false,
                title: 'autoplay',
                handler: null,
                pressed: false
            },

            // roulette buttons
            double: {
                disabled: true,
                title: 'double',
                handler: null
            },
            cancel: {
                disabled: true,
                title: 'undo',
                handler: null
            },
            clear: {
                disabled: true,
                title: 'removeBets',
                handler: null
            },

            // game settings buttons
            lines: {
                disabled: true,
                title: 'lines',
                value: '',
                pressed: false
            },
            bet: {
                disabled: true,
                title: 'bet',
                value: '',
                pressed: false
            },
            total: {
                disabled: true,
                title: 'total',
                value: ''
            },
            denomination: {
                disabled: false,
                title: 'denom',
                value: '',
                pressed: false
            },

            options: {
                disabled: false,
                title: 'options',
                value: '',
                pressed: false
            },

            // info buttons
            closeInfo: {disabled: true, handler: null},
            prevInfo: {disabled: true, handler: null},
            nextInfo: {disabled: true, handler: null},

            // gamble buttons
            redCard: {disabled: true, img: 'inactive', handler: null},
            blackCard: {disabled: true, img: 'inactive', handler: null},
            heartsSuit: {img: 'inactive', handler: null},
            diamondsSuit: {img: 'inactive', handler: null},
            spadesSuit: {img: 'inactive', handler: null},
            clubsSuit: {img: 'inactive', handler: null},
            cardIndex: {handler: null},
            half: {disabled: true, title: 'half', handler: null},

            // settings button on mobile platform
            controlButtons: {disabled: App.System.platform !== 'desktop'},
            gameSettings: {
                status: false,
                disabled: false,
                handler: null
            }
        },
        legends: {
            status: 'gameOverPlaceYourBet',
            features: {text: '', value: 0, number: ''},
            win: {text: '', value: 0}
        },
        wrap: {
            active: false,
            animationClass: '',
            type: '', // lines/bet/denomination
            items: [],
            title: '',
            handler: null // items handler
        },
        loader: {
            active: false,
            fade: '', // animation type 'in' / 'out'
            fadeEndCallback: null, // call after fade animation
            messages: [], // messages stack, updates from App.loaderMessages
            progress: null, // loaded images ('0' - '100')
            gameLogo: null, // src to image
            activeControlButtons: false
        },
        waiting: {
            active: false,
            firstCheck: true,
            callback: null,
            progress: null
        },
        devices: {}, // vlt devices, status and messages
        error: {
            message: '', // error text
            exitButton: false,
            reloadButton: false,
            timer: null, // time to redirect
            iFrame: null, // link for <iframe> with other system
            icon: null
        },
        clientErrors: [],
        slider: {
            disabledSlider: '',
            visibleGames: [],
            activePayout: false,
            payoutHandler: null,
            payoutAmount: '',
            payoutMessage: '',
            buttonLeftHandler: null,
            buttonRightHandler: null
        },
        infoScreen: {
            active: false,
            status: '', // info screen animation class ('open'/'close')
            slideDirection: '' // 'left' or 'right'
        },
        activeGamble: false,
        activePrizes: true, // visible jackpots/prizes
        activePrizeWin: false,
        swipeUp: {
            active: false,
            canceled: false
        },
        popup: {message: null},
        modal: {}, // content, wrapper, animationClass, onClose
        fontsLoaded: false, // flag for waiting fonts
        serverRequest: false, // flag for fetch requests
        moneyParams: {
            credits: 0,
            money: 0,
            currency: '',
            insurance: 0,
            bonus: 0,
            bonusTotal: 0,
            jackpot: null,
            showWinFeatures: false // show 'features' or 'jackpot' in <Prizes>
        },
        menuJackpots: {active: false}
    });

    componentDidMount() {
        JL().debug('-- View: componentDidMount');

        // remove screen saver from DOM
        const elem = document.querySelector('.screen');
        elem.parentNode.removeChild(elem);

        const waitingImg = new Image();
        waitingImg.src = './../img/loader.svg';
        waitingImg.className = 'anticipation-spinner';
        App.images = [waitingImg];
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        nextState.buttons.start.disabled !== this.state.buttons.start.disabled &&
        App.Game.Buttons.update(nextState.buttons.start);
        return true;
    }

    render() {
        const {currentState, orientation, loader, popup, clientErrors, swipeUp, modal, waiting} = this.state;
        const {mode, doubleScreen} = App.configs;
        const {platform, resolution} = App.System;

        return (
            <div

                className={`View mode-${mode} lang-${App.settings.currentLanguage} ${doubleScreen ? 'double-screen' : ''} orientation-${orientation} platform-${platform} resolution-${resolution}`}>
                {waiting.active && <Waiting progress={waiting.progress}/>}

                {currentState === 'MENU' && <Menu state={this.state}/>}
                {currentState === 'GAME' && <Game state={this.state}/>}
                {currentState === 'LOGIN' && <Login state={this.state}/>}
                {currentState === 'INFO' && <KioskInfo state={this.state}/>}
                {doubleScreen && <SecondScreen/>}
                {loader.active && <Loader state={this.state}/>}
                {popup.message && <Popup params={popup}/>}
                {Object.keys(modal).map(key => <Modal key={key} type={key} params={modal[key]} state={this.state}/>)}
                {clientErrors.length !== 0 && <ClientErrors clientErrors={clientErrors}/>}
                {swipeUp.active && !swipeUp.canceled && <SwipeUp state={this.state}/>}
            </div>
        );
    }
}
