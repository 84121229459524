import App from './../../../index';
import GameDeluxe from './../../deluxe/game';
import Lines20 from './../../deluxe/lines20';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';

export default class PlentyOfFruit extends GameDeluxe {
    constructor() {
        super();
        this.id = 'plenty-of-fruit';
        this.name = 'Plenty Of Fruit 20';
        this.transparentBackground = true;
        this.buttonsPanelShadow = 'strong';

        this.symbols = [
            {regularDelay: 80, skipSteps: 14, payment: [0, 0, 0, 10, 20, 100]},   // 0 - cherry
            {regularDelay: 80, skipSteps: 14, payment: [0, 0, 0, 10, 20, 100]},   // 1 - lemon
            {regularDelay: 80, skipSteps: 14, payment: [0, 0, 0, 10, 20, 100]},   // 2 - orange
            {regularDelay: 80, skipSteps: 14, payment: [0, 0, 0, 20, 40, 200]},   // 3 - plum
            {regularDelay: 80, skipSteps: 14, payment: [0, 0, 0, 20, 40, 200]},   // 4 - melon
            {regularDelay: 120, skipSteps: 14, payment: [0, 0, 0, 20, 80, 400]},  // 5 - bell
            {regularDelay: 80, skipSteps: 14, payment: [0, 0, 0, 40, 400, 1000]}, // 6 - digit '7'
            {regularDelay: 80, skipSteps: 14, payment: [0, 0, 0, 5, 20, 500]}     // 7 - start
        ];
        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.gameSounds = {soundClass: 'deluxe'};
        this.Lines = new Lines20();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 2}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '17pt franklinFont';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#FFFFFF';
        ctx.shadowColor = 'black';
        ctx.shadowOffsetX = 3;
        ctx.shadowOffsetY = 3;
        ctx.shadowBlur = 5;

        switch (page) {
            case 1:
                // seven
                ctx.fillText(bet * this.symbols[6].payment[5], 225, 146);
                ctx.fillText(bet * this.symbols[6].payment[4], 225, 176);
                ctx.fillText(bet * this.symbols[6].payment[3], 225, 206);

                // bell
                ctx.fillText(bet * this.symbols[5].payment[5], 413, 260);
                ctx.fillText(bet * this.symbols[5].payment[4], 413, 290);
                ctx.fillText(bet * this.symbols[5].payment[3], 413, 320);

                // plum,  watermelon
                ctx.fillText(bet * this.symbols[3].payment[5], 135, 392);
                ctx.fillText(bet * this.symbols[3].payment[4], 135, 422);
                ctx.fillText(bet * this.symbols[3].payment[3], 135, 452);

                // lemon, cherry, orange
                ctx.fillText(bet * this.symbols[1].payment[5], 675, 392);
                ctx.fillText(bet * this.symbols[1].payment[4], 675, 422);
                ctx.fillText(bet * this.symbols[1].payment[3], 675, 452);

                // scatter star
                ctx.fillText(bet * nLines * this.symbols[7].payment[5], 413, 445);
                ctx.fillText(bet * nLines * this.symbols[7].payment[4], 413, 475);
                ctx.fillText(bet * nLines * this.symbols[7].payment[3], 413, 505);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }
}
