import {JL} from 'jsnlog';

import CustomLines from './../../CustomLines';

export default class Lines extends CustomLines {
    constructor(imageResources) {
        super(imageResources);
        this.imageResources = {...imageResources};

        this.boxSettings = {
            ...this.boxSettings,
            firstRightBox: 5,
            lineBoxWidth: 50,
            lineBoxHeight: 31,
            textOnBox: true,
            textType: 'line',
            typeBoxes: 'OneBox',
            boxFontSize: 20
        };

        this.linesSettings = {
            ...this.linesSettings,
            horizontalLineLength: 40,
            lineColor: '#e88744'
        };

        this.lines = {
            0: {coordinates: [1, 1, 1, 1, 1], boxes: [{x: -17, y: 271}]},
            1: {coordinates: [0, 0, 0, 0, 0], boxes: [{x: -17, y: 132}]},
            2: {coordinates: [2, 2, 2, 2, 2], boxes: [{x: -17, y: 410}]},
            3: {coordinates: [0, 1, 2, 1, 0], boxes: [{x: -17, y: 93}]},
            4: {coordinates: [2, 1, 0, 1, 2], boxes: [{x: -17, y: 449}]},
            5: {coordinates: [1, 2, 2, 2, 1], boxes: [{x: 927, y: 291}]},
            6: {coordinates: [1, 0, 0, 0, 1], boxes: [{x: 927, y: 252}]},
            7: {coordinates: [2, 2, 1, 0, 0], boxes: [{x: 927, y: 103}]},
            8: {coordinates: [0, 0, 1, 2, 2], boxes: [{x: 927, y: 440}]},
            9: {coordinates: [2, 1, 1, 1, 0], boxes: [{x: 927, y: 142}]}
        };

        this.lineMap = [
            1, 3, 0, 2, 4,
            7, 5, 6, 8, 9
        ];

        this.linesColor = {};
        Object.keys(this.lines).forEach(index => this.linesColor[index] = 0xe88744);

        Object.keys(this.lines).forEach(key => {
            this.lines[key].boxes[0].y *= 1.2;
        });
        JL().debug('-- Lines10 initialized');
    }
}
