import GameNovomatic from './../novomatic/game';

export default class GameDeluxe extends GameNovomatic {
    constructor() {
        super();
        // reel properties
        this.reelTop = 89; // magic number - where reel images starts
        this.reelXCoordinates = [51, 193, 335, 477, 619]; // magic numbers - x coordinates where reels starts
        this.symbolHeight = 130; // height of a single symbol
        this.symbolWidth = 130; // width of a single symbol
        this.transparentBackground = false; // use transparent symbols fone or not
        this.symbolEffects = true; // shadowing and none
    }
}
