import React from 'react';

import './styles.less';
import App from './../../index';

export default function Payout(props) {
    const {lang, slider} = props;
    return (
        <div
            className='payout-panel'
            onClick={event => event.stopPropagation()}
        >
            <input type='text' value={slider.payoutAmount} readOnly/>
            <button
                className='clear blue-color'
                onClick={() => App.View.setState(({slider}) => ({
                    slider: {
                        ...slider,
                        payoutHandler: App.LocalSocket.payout,
                        payoutAmount: slider.payoutAmount.slice(0, -1),
                        payoutMessage: ''
                    }
                }))}
            >←
            </button>
            <div className='buttons-container'>
                {['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].map((item, index) =>
                    <button
                        key={index} className={`item-${item}`}
                        disabled={!slider.payoutHandler}
                        onClick={() => App.View.setState(({slider}) => ({
                            slider: {
                                ...slider,
                                payoutAmount: slider.payoutAmount + item,
                                payoutMessage: ''
                            }
                        }))}
                    >{item}</button>)}
            </div>
            {slider.payoutHandler &&
            <button className='payout-button' onClick={slider.payoutHandler}>{lang.payout}</button>}
            {slider.payoutMessage && <span className='payout-message'>{lang[slider.payoutMessage]}</span>}
        </div>
    );
}
