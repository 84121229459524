import * as PIXI from 'pixi.js-legacy';

import App from './../../../index';
import GameDeluxe from './../../deluxe/game';
import Lines25 from './lines';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';
import fontNumbers from './img/font/Number';

/* PIXI aliases */
const AnimatedSprite = PIXI.AnimatedSprite,
    Container = PIXI.Container,
    Sprite = PIXI.Sprite,
    Text = PIXI.Text;

export default class NeonCity extends GameDeluxe {
    constructor() {
        super();
        this.id = 'neon-city';
        this.name = 'Neon City';
        this.reels = 4;
        this.reelRows = 3; // number of rows per reel
        this.animationSpeed = 800;
        this.allowLongRoll = true;
        this.longRollSettings = [3, 3]; // long roll from reelIndex to reelIndex
        this.scatter = 9;
        this.BigWin.enabled = true;

        this.buttonsPanelShadow = 'none';
        this.symbolHeight = 130; // height of aa single symbol
        this.symbolWidth = 175; // height of aa single symbol
        this.transparentBackground = true;
        this.SymbolInfo.enabled = true;
        this.SymbolInfo.settings.paymentBorderOffset = {left: 55, right: 55};

        // reel properties
        this.reelXCoordinates = [35, 220, 405, 590]; // magic numbers - x coordinates where reels starts

        this.symbols = [
            {regularDelay: 50, skipSteps: 1, payment: [0, 0, 0, 10, 25]},   // 0 - J
            {regularDelay: 50, skipSteps: 1, payment: [0, 0, 0, 10, 25]},   // 1 - Q
            {regularDelay: 50, payment: [0, 0, 0, 25, 50]},                 // 2 - K
            {regularDelay: 50, skipSteps: 1, payment: [0, 0, 0, 25, 50]},   // 3 - A
            {regularDelay: 90, payment: [0, 0, 0, 50, 100]},                // 4 - BAR
            {regularDelay: 90, payment: [0, 0, 0, 50, 100]},                // 5 - music
            {regularDelay: 50, skipSteps: 1, payment: [0, 0, 0, 100, 200]}, // 6 - car
            {regularDelay: 50, skipSteps: 1, payment: [0, 0, 0, 100, 200]}, // 7 - park
            {regularDelay: 80, payment: [0, 0, 0, 400, 1500]},              // 8 - maxbet
            {regularDelay: 80, payment: [0, 0, 0, 0, 10]}                   // 9 - neon
        ];

        this.imageResources = {
            main: this.mergePath({
                mainArea: 'area/main.png',
                background: 'area/background.png',
                logo: 'logo.png',
                red: fontNumbers['red'],
                lightBlue: fontNumbers['lightBlue'],
                blue: fontNumbers['blue'],
                backgroundAnim1: 'backgroundAnim1.png',
                backgroundAnim2: 'backgroundAnim2.png'
            }),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {
            main: this.mergePath({
                letter0: 'bonus/N1.png',
                letter1: 'bonus/E.png',
                letter3: 'bonus/N2.png',
                letter2: 'bonus/O.png',
                base: 'area/base.png',
                centerRoulette: 'bonus/centerRoulette.png',
                circles: 'bonus/circles.png',
                redCircles: 'bonus/redCircles.png',
                bonusArea: `area/${App.System.resolution}/bonus.png`,
                frame: 'area/frame.png',
                symbolBorder: 'area/symbol-border.png',
                paymentBorder: 'area/payment-border.png'
            }),
            atlas: this.mergePath(['bonus/scatterSymbols.json'])
        };
        this.gameSounds = {
            soundClass: 'prefergames',
            sounds: [
                {name: 'bonus-game-won', alias: 'bonusGameStart'},
                {name: 'dice'},
                {name: 'long1'}
            ],
            path: `/game/games/${this.id}/audio/`
        };

        this.Lines = new Lines25();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            activeRed: 'gamble/red-active.png',
            inactiveRed: 'gamble/red-inactive.png',
            activeBlack: 'gamble/black-active.png',
            inactiveBlack: 'gamble/black-inactive.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 3}, {
            rouletteInfo: 'area/infoRoulette.png',
            infoNeon: 'area/infoNeon.png',
            bonusInfo: 'area/bonusInfo.png'
        }); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     * @param lang
     */
    drawInfoPage(ctx, page, nLines, bet, lang) {
        this.clearInfoAnimation();
        const textProps = {
            parentContainer: ctx,
            fontImageName: 'blue',
            map: fontNumbers,
            align: 'left',
            scale: 0.5,
            fontInterval: -40,
            lineHeight: 40
        };
        const {
            onTheScreen,
            bonusGame,
            bonusGameGive,
            freeSpins,
            featureBonus,
            goodLuck
        } = App.languageCollection[lang];

        ctx.font = '28pt Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#0056ff';
        ctx.strokeStyle = 'white';
        ctx.lineWidth = 2;

        switch (page) {
            case 1:
                // QJ
                this.drawCustomFont(`${bet * this.symbols[0].payment[4]}`, 580, 427, textProps);
                this.drawCustomFont(`${bet * this.symbols[0].payment[3]}`, 580, 458, textProps);

                // KA
                this.drawCustomFont(`${bet * this.symbols[2].payment[4]}`, 250, 427, textProps);
                this.drawCustomFont(`${bet * this.symbols[2].payment[3]}`, 250, 458, textProps);

                // BAR
                this.drawCustomFont(`${bet * this.symbols[4].payment[4]}`, 620, 315, textProps);
                this.drawCustomFont(`${bet * this.symbols[4].payment[3]}`, 620, 350, textProps);

                // Car
                this.drawCustomFont(`${bet * this.symbols[6].payment[4]}`, 250, 265, textProps);
                this.drawCustomFont(`${bet * this.symbols[6].payment[3]}`, 250, 300, textProps);

                // neon city
                this.drawCustomFont(`${bet * nLines * this.symbols[9].payment[4]}`, 290, 128, textProps);

                textProps.scale = 0.43;
                // maxBet
                this.drawCustomFont(`${bet * this.symbols[8].payment[4]}`, 510, 198, textProps);
                this.drawCustomFont(`${bet * this.symbols[8].payment[3]}`, 510, 226, textProps);
                break;

            case 2:
                ctx.textAlign = 'left';
                this.strokeFillText(ctx, onTheScreen, 383, 180);
                ctx.textAlign = 'center';
                this.strokeFillText(ctx, bonusGameGive, 415, 330);
                ctx.fillStyle = '#f70615';
                ctx.strokeStyle = '#fbf4ff';

                this.strokeFillText(ctx, bonusGame, 300, 260);

                ctx.fillStyle = '#f70615';
                ctx.strokeStyle = '#fbf4ff';
                ctx.font = '30pt Arial';

                this.strokeFillText(ctx, freeSpins, 415, 380);

                this.drawInfoNeon(ctx, Date.now());
                break;

            case 3:
                ctx.font = '26pt Arial';
                ctx.textAlign = 'right';
                this.drawSplitText(ctx, featureBonus, 550, 360, 480, textProps);
                ctx.textAlign = 'center';
                this.strokeFillText(ctx, goodLuck, 400, 480);

                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    drawInfoRoulette(ctx, step, scale) {
        const width = 250;
        const height = 150;
        step %= 23;
        const colIndex = step % 8;
        const rowIndex = Math.floor(step / 8);

        ctx.drawImage(
            this.getAdditionalImage('rouletteInfo'),
            colIndex * width,
            rowIndex * height,
            width, height,
            315, 390,
            width * scale, height * scale
        );
    }

    drawInfoNeon(ctx, animationStarted = Date.now()) {
        const width = 227;
        const height = 125;
        const scale = 0.8;

        let step = Math.floor((Date.now() - animationStarted) / 100);
        this.drawInfoRoulette(ctx, step, scale);

        step %= 106;
        const colIndex = step % 9;
        const rowIndex = Math.floor(step / 9);

        ctx.drawImage(
            this.getAdditionalImage('infoNeon'),
            colIndex * width,
            rowIndex * height,
            width, height,
            530, 190,
            width * scale, height * scale
        );

        this.infoRAF = requestAnimationFrame(this.drawInfoNeon.bind(this, ctx, animationStarted));
    }

    addWaitingAnimationSprites(parentContainer) {
        const LogoSprite = new AnimatedSprite(this.getSpriteTextures({
            toFrame: 45, image: 'logo',
            width: 328, height: 86,
            colCount: 3
        }));
        LogoSprite.name = 'animatedLogo';
        LogoSprite.position.set(229, 0);
        LogoSprite.animationSpeed = 0.25;
        LogoSprite.play();

        const props = {
            colCount: 2,
            toFrame: 50,
            height: 80,
            width: 800
        };

        const backgroundSprite = new AnimatedSprite([
            ...this.getSpriteTextures({...props, image: 'backgroundAnim1'}),
            ...this.getSpriteTextures({...props, image: 'backgroundAnim2'})
        ]);
        backgroundSprite.name = 'backgroundAnim';
        backgroundSprite.animationSpeed = 0.2;
        backgroundSprite.position.set(0, 0);
        backgroundSprite.loop = false;
        parentContainer.addChild(backgroundSprite, LogoSprite);
    }

    showWaitingAnimation(lastTime = Date.now()) {
        this.stopWaitingAnimation();
        const timeDiff = Date.now() - lastTime;
        const animateTimeout = 30000;

        if (timeDiff > animateTimeout) {
            lastTime = Date.now();
            const parentContainer = this.getStageChild('waitingContainer');
            const backgroundSprite = parentContainer.getChildByName('backgroundAnim');
            backgroundSprite.gotoAndPlay(0);
        }

        this.waitingAnimationFrame = requestAnimationFrame(this.showWaitingAnimation.bind(this, lastTime));
    }

    /**
     * Show bonus message with bonus symbol or with bonus game win.
     * @param isFirstBonus {boolean} TRUE if this is the message for starting bonus game.
     */
    drawBonusAskButton(isFirstBonus = false) {
        const isLast = !isFirstBonus && this.bonusStatus && this.bonusStatus.remain === 0;
        this.stopAnimateFeature();
        this.resetSymbolAnimation();
        this.drawBonusFrame(isFirstBonus, isLast, this.getStageChild('bonusContainer'));
        this.gameFlag.bonusStart = true;
        this.Legends.setText('win', {text: 'win', value: this.bonusWin});
    }

    drawBonusFrame(first, last, parentContainer) {
        parentContainer.removeChildren();
        App.Sounds.stopSound('bonus-background');

        if (first) {
            this.drawFrozenJoker(parentContainer);
        }
        if (last) {
            this.setRegularSprite();
            App.updateButton('start', {disabled: true});
            this.showEndBonusFrame(parentContainer, this.bonusStatus);
            const endBonusFrame = parentContainer.getChildByName('endBonusFrame');
            let func = null;
            const ticker = this.app.ticker;

            setTimeout(() => {
                ticker.add(func = () => this.changingAlpha(
                    endBonusFrame,
                    func,
                    'decrease',
                    () => this.endBonus()
                ));
            }, 7000);
        }
    }

    showEndBonusFrame(parentContainer, {win, total}) {
        const endBonusFrame = new Container();
        endBonusFrame.name = 'endBonusFrame';
        endBonusFrame.alpha = 0;
        parentContainer.addChild(endBonusFrame);

        const frame = new Sprite(this.getTexture('frame'));
        frame.name = 'endBonusFrame';
        frame.position.set(-(this.gameWidth - this.gameFieldWidth) / 2, 0);

        endBonusFrame.addChild(frame);
        const ticker = this.app.ticker;
        let func = null;
        const props = {
            align: 'left',
            fontFamily: 'Trebuchet MS',
            fontSize: 90,

            stroke: 'white',
            fill: '#14FFFC',
            strokeThickness: 3,
            lineJoin: 'round'
        };
        ticker.add(func = () => this.changingAlpha(endBonusFrame, func, 'increase'));
        const richText = new Text(`YOU WON`, props);

        props.fill = '#F7D208';
        props.stroke = '#FF2A06';
        props.fontSize = 70;

        const richText2 = new Text(`${win} CREDITS`, props);
        richText.name = 'creditsWon';
        richText.position.set(206, 163);
        richText2.position.set(185, 275);
        endBonusFrame.addChild(richText, richText2);
        this.setBackground('mainArea');
    }

    drawFrozenJoker(bonusContainer) {
        this.stopAnimateFeature();
        this.reelMatrix.forEach((reel, reelIndex) => {
            reel.forEach((symbolObj, rowIndex) => {
                symbolObj.sprite.visible = false;
                if (symbolObj.symbol === 9) {
                    const yPosition = (this.symbolHeight * rowIndex) + this.reelTop;
                    const sprite = new AnimatedSprite(this.getSpriteTextures({
                        image: `letter${reelIndex}`,
                        width: this.symbolWidth,
                        height: this.symbolHeight,
                        colCount: 5,
                        toFrame: 40
                    }));
                    sprite.name = `letter${reelIndex}`;
                    sprite.loop = false;
                    sprite.position.x = this.reelXCoordinates[reelIndex];
                    sprite.position.y = (this.symbolHeight * rowIndex) + this.reelTop;
                    sprite.animationSpeed = 0.22;
                    sprite.play();
                    sprite.onComplete = () => this.drawRoulette(bonusContainer);

                    bonusContainer.addChild(sprite);
                    this.drawPathToCenter(yPosition, rowIndex, sprite, bonusContainer);
                }
            });
        });
    }

    drawPathToCenter(yPosition, rowIndex, sprite) {
        const ticker = this.app.ticker;

        const changePosition = () => {
            if (rowIndex === 0) sprite.position.y = yPosition += 2;
            if (rowIndex === 2) sprite.position.y = yPosition -= 2;
            yPosition === this.symbolHeight + this.reelTop && positionOk();
        };

        ticker.add(changePosition);
        const positionOk = () => ticker.remove(changePosition);
    }

    drawRoulette(bonusContainer) {
        this.showPressAnyButton();
        if (!bonusContainer.getChildByName('rouletteContainer')) {
            bonusContainer.removeChildren();
            const rouletteContainer = new Container();
            rouletteContainer.name = 'rouletteContainer';
            const baseImage = new Sprite(this.getTexture('base'));
            baseImage.position.set(-(this.gameWidth - this.gameFieldWidth) / 2, 0);

            baseImage.alpha = 0;
            bonusContainer.addChild(rouletteContainer);
            rouletteContainer.addChild(baseImage);
            let func = null;
            this.hideBoxes();
            const ticker = this.app.ticker;
            ticker.add(func = () => this.changingAlpha(baseImage, func, 'increase'));

            const centerAnimation = new AnimatedSprite(this.getSpriteTextures({
                toFrame: 21,
                image: 'centerRoulette',
                width: 375,
                height: 375,
                colCount: 5
            }));
            centerAnimation.name = 'centerAnimation';
            centerAnimation.position.set(306, 199);
            centerAnimation.scale.set(0.5, 0.5);
            centerAnimation.animationSpeed = 0.2;
            centerAnimation.play();
            rouletteContainer.addChild(centerAnimation);
            this.drawBlueCircles(rouletteContainer);
        }
    }

    changingAlpha(sprite, func, type, callback = null, speed = 0.02) {
        const ticker = this.app.ticker;

        switch (type) {
            case 'increase':
                sprite.alpha += speed;
                if (sprite.alpha >= 1) {
                    ticker.remove(func);
                    func = null;
                    callback?.();
                }
                break;
            case 'decrease':
                sprite.alpha -= speed;
                if (sprite.alpha <= 0) {
                    sprite.destroy();
                    ticker.remove(func);
                    func = null;
                    callback?.();
                }
                break;
        }
    };

    drawBlueCircles(rouletteContainer) {
        const angle = 60;
        const circleCenter = {x: 399, y: 300};
        const r = 177;
        for (let i = 0; i < 6; i++) {
            const x = (circleCenter.x + r * Math.cos((angle * i - 90) / 180 * Math.PI)).toFixed(2);
            const y = (circleCenter.y + r * Math.sin((angle * i - 90) / 180 * Math.PI)).toFixed(2);
            const blueTextures = this.getSpriteTextures({
                image: 'circles',
                width: 276,
                height: 277,
                colCount: 7,
                toFrame: 20
            });

            const circleSprite = new AnimatedSprite(blueTextures);
            circleSprite.scale.set(0.7);
            circleSprite.name = `circle${i}`;
            circleSprite.animationSpeed = 0.2;
            circleSprite.anchor.set(0.5);
            circleSprite.position.x = x;
            circleSprite.position.y = y;
            circleSprite.play();
            rouletteContainer.addChild(circleSprite);
            i === 5 && this.drawRedCircle(rouletteContainer, blueTextures, Date.now());
        }
        this.drawRouletteText(rouletteContainer);
    }

    drawRouletteText = (rouletteContainer) => {
        const textContainer = new Container();
        textContainer.name = 'textContainer';
        rouletteContainer.addChild(textContainer);
        const colFreeSpins = [5, 7, 9, 12, 15, 20];
        colFreeSpins.forEach((item, index) => {
            const xPositions = [384, 540, 538, 369, 214, 218];
            const richText = new Text(`${item}`, {
                align: 'center',
                fontFamily: 'Trebuchet MS',
                fontSize: 50,

                stroke: 'white',
                fill: '#14FFFC',
                strokeThickness: 3,
                lineJoin: 'round'
            });

            const x = rouletteContainer.getChildByName(`circle${index}`).position.x - 32;
            const y = rouletteContainer.getChildByName(`circle${index}`).position.y - 32;
            richText.name = index;
            richText.x = xPositions[index];
            richText.y = y;
            textContainer.addChild(richText);
        });

        const centerTextContainer = new Container();
        centerTextContainer.name = 'centerTextContainer';
        textContainer.addChild(centerTextContainer);

        const richText = new Text(``, {
            align: 'center',
            fontFamily: 'Trebuchet MS',
            fontSize: 100,
            dropShadow: true,
            dropShadowBlur: 15,
            dropShadowColor: '#040c60',
            dropShadowDistance: 0,
            stroke: '#1168FF',
            fill: 'white',
            strokeThickness: 3,
            lineJoin: 'round'
        });

        richText.name = 'centerText';
        centerTextContainer.addChild(richText);
    };

    drawRedCircle(rouletteContainer, blueTextures, animatedStarted, step = 0) {
        if (Date.now() - animatedStarted > this.animationSpeed) {
            App.Sounds.playSound('dice');
            animatedStarted = Date.now();
            const redTextures = this.getSpriteTextures({
                image: 'redCircles',
                width: 276,
                height: 277,
                colCount: 7,
                toFrame: 20
            });

            const currentCircle = rouletteContainer.getChildByName(`circle${step}`);
            const previousCircle = rouletteContainer.getChildByName(`circle${step - 1 < 0 ? 5 : step - 1}`);

            this.setCirclesTextures(currentCircle, previousCircle, redTextures, blueTextures);
            this.changeTextStyle(rouletteContainer, step);
            this.drawCenterText(rouletteContainer, step);
            App.updateButton('start', {
                handler: () => this.prepareToFinalSpins(rouletteContainer, redTextures, blueTextures, step, currentCircle),
                disabled: false,
                title: 'start'
            });
            step++;
            step %= 6;
        }

        this.animationId = requestAnimationFrame(this.drawRedCircle.bind(this, rouletteContainer, blueTextures, animatedStarted, step));
    }

    changeTextStyle = (rouletteContainer, step) => {
        const textContainer = rouletteContainer.getChildByName('textContainer');
        textContainer.getChildByName(step).style.fill = '#F7D208';
        textContainer.getChildByName(step).style.stroke = '#FF2A06';

        textContainer.getChildByName(step !== 0 ? step - 1 : 5).style.fill = '#14FFFC';
        textContainer.getChildByName(step !== 0 ? step - 1 : 5).style.stroke = 'white';
    };

    drawCenterText = (rouletteContainer, step) => {
        const textContainer = rouletteContainer.getChildByName('textContainer');
        const centerTextContainer = textContainer.getChildByName('centerTextContainer');
        const centerText = centerTextContainer.getChildByName('centerText');
        centerText.text = `${textContainer.getChildByName(step).text}`;
        centerText.position.x = step < 3 ? 373 : 343;
        centerText.position.y = 230;
    };

    setCirclesTextures = (currentCircle, previousCircle, redTextures, blueTextures) => {
        currentCircle.textures = redTextures;
        previousCircle.textures = blueTextures;
        currentCircle.play();
        previousCircle.play();
    };

    prepareToFinalSpins(rouletteContainer, redTextures, blueTextures, step, currentCircle) {
        cancelAnimationFrame(this.animationId);
        this.animationId = null;

        App.updateButton('start', {disabled: true});

        let colFreeSpins = null;
        this.latestResponse.features.find(({uc, amount}) => uc === 'FREE_ROLL' && (colFreeSpins = amount));
        colFreeSpins = this.colFreeSpinsTransformation(colFreeSpins);

        const freeSpinsArray = [...Array(7 * 6)].map((item, index) => index % 6);
        const endOfArray = [...Array(colFreeSpins + 1)].map((item, index) => index % 6);

        freeSpinsArray.push(...endOfArray);
        freeSpinsArray.splice(0, step);
        currentCircle.textures = blueTextures;
        this.finalSpins(rouletteContainer, redTextures, blueTextures, freeSpinsArray, Date.now());
    }

    finalSpins(rouletteContainer, redTextures, blueTextures, finaleSteps, animationStarted, step = 0, animationSpeed = 80) {
        this.clearPressAnyButton();
        this.animationSpeed = animationSpeed;
        if (Date.now() - animationStarted > this.animationSpeed) {
            animationStarted = Date.now();
            let previousCircle;
            App.Sounds.playSound('dice');
            this.drawCenterText(rouletteContainer, finaleSteps[step]);
            this.changeTextStyle(rouletteContainer, finaleSteps[step]);

            const currentCircle = rouletteContainer.getChildByName(`circle${finaleSteps[step]}`);
            if (finaleSteps[step - 1] >= 0) {
                previousCircle = rouletteContainer.getChildByName(`circle${finaleSteps[step - 1]}`);
                previousCircle.textures = blueTextures;
                previousCircle.play();
            }

            currentCircle.textures = redTextures;
            currentCircle.play();
            step >= 30 && (animationSpeed += 30);
            step++;
        }
        step <= finaleSteps.length - 1 ?
            requestAnimationFrame(this.finalSpins.bind(this, rouletteContainer, redTextures, blueTextures, finaleSteps, animationStarted, step, animationSpeed)) :
            setTimeout(() => {
                this.reelMatrix.forEach((reel, reelIndex) => {
                    reel.forEach((symbolObj) => {
                        if (reelIndex === 0) {
                            symbolObj.image = 'regular';
                            symbolObj.symbol = 8;
                        }
                        this.Roll.updateSymbolSprite(symbolObj);
                        symbolObj.sprite.visible = true;
                    });
                });

                rouletteContainer.alpha = 1;
                this.showAnimation({
                    duration: 1000,
                    animations: [{sprite: rouletteContainer, timeline: [{to: {alpha: 0}}]}],
                    onComplete: () => {
                        this.startBonusAnimation();
                        this.showBoxes();
                    }
                });
                this.setBackground('bonusArea');
            }, 2000);
    }

    isReelFreezed = reel => this.gameFlag.bonusStarted && reel === 0;

    colFreeSpinsTransformation = num => {
        const obj = {
            5: 0,
            7: 1,
            9: 2,
            12: 3,
            15: 4,
            20: 5
        };
        return obj[num];
    };

    /**
     * Create texts for paymentBorder table
     * @param parentContainer
     * @param payTable
     * @param direction
     */
    drawSymbolInfoPayments(parentContainer, payTable, direction) {
        const bet = this.gameSettings.getBetLineCredit();
        const props = {
            align: 'left',
            fontFamily: 'Trebuchet MS',
            fontSize: 22,
            strokeThickness: 3,
            lineJoin: 'round'
        };

        payTable.forEach((pay, index) => {
            props.fill = '#F7D208';
            props.stroke = '#FF2A06';
            const quantity = new Text(`x${pay[0]}`, props);
            quantity.position.x = direction === 'left' ? -60 : -51;
            quantity.position.y = payTable.length === 1 ? -20 : -30 * index;

            props.fill = '#ccfdff';
            props.stroke = '#1168FF';

            const payment = new Text(`= ${bet * pay[1]}`, props);
            payment.position.x = direction === 'left' ? -29 : -21;
            payment.position.y = payTable.length === 1 ? -20 : -30 * index;

            parentContainer.addChild(quantity, payment);
        });
    }

    getLongRoll(screen) {
        const scatterMap = [];
        const extra = this.getExtraBet();
        screen.forEach((vector, reelIndex) => {
            vector.forEach(symbol => {
                if (symbol === this.scatter && reelIndex !== this.reels - 1 - extra) { // do not use last reel scatter
                    scatterMap.push(reelIndex); // correct last reel with extrabet
                }
            });
        });
        const longRollType = (scatterMap.length > 2 && this.allowLongRoll) ? scatterMap[1] + 1 : -1;

        this.reelSymbol.forEach((symbolAmount, reelIndex) => {
            this.reelLong[reelIndex] =
                (reelIndex >= longRollType && longRollType !== -1 &&
                    reelIndex >= this.longRollSettings[0] && reelIndex <= this.longRollSettings[1]) ?
                    1 : 0;
        });
        return this.reelLong; // get possible long roll type
    }
}
