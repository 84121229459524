import App from './../../../index';
import BookGame from './../../deluxe/bookGame';
import Lines10x6 from './lines';
import GambleDeluxe from './../../deluxe/gamble';
import InfoScreen from '../../infoScreen';

export default class Sizzling6 extends BookGame {
    constructor() {
        super();
        this.id = 'sizzling-6';
        this.name = 'Sizzling 6';
        this.buttonsPanelShadow = 'low';

        // reel properties
        this.reels = 6;
        this.reelRows = 3; // number of rows per reel
        this.reelXCoordinates = [55, 171, 287, 403, 519, 635]; // magic numbers - x coordinates where reels start
        this.symbolHeight = 110; // height of aa single symbol
        this.symbolWidth = 110; // height of aa single symbol
        this.reelTop = 119; // magic number - where reel images starts

        // roll properties
        this.reelSettings = [18, 4, 30]; // 0 - start symbol amount, 1 - reel increase regular roll, 2 - reel increase long roll
        this.transparentBackground = false; // use transparent symbols background or not
        this.reelFilter = [[], [], [], [], [], []];

        this.symbols = [
            {regularDelay: 100, skipSteps: 1, payment: [0, 0, 0, 5, 10, 25, 100]},    // 0 - вишни
            {regularDelay: 100, skipSteps: 1, payment: [0, 0, 0, 5, 10, 25, 100]},    // 1 - апельсин
            {regularDelay: 100, skipSteps: 1, payment: [0, 0, 0, 5, 10, 25, 100]},    // 2 - лимон
            {regularDelay: 100, skipSteps: 1, payment: [0, 0, 0, 5, 10, 25, 100]},    // 3 - слива
            {regularDelay: 100, skipSteps: 1, payment: [0, 0, 0, 10, 20, 50, 300]},   // 4 - виноград
            {regularDelay: 100, skipSteps: 1, payment: [0, 0, 0, 20, 40, 100, 600]},  // 5 - арбуз
            {regularDelay: 100, skipSteps: 1, payment: [0, 0, 0, 40, 80, 250, 5000]}, // 6 - цифра '7'
            {regularDelay: 100, payment: [0, 0, 0, 0, 0, 0, 0]},                      // 7 - джокер
            {regularDelay: 100, skipSteps: 1, payment: [0, 0, 0, 0, 3, 10, 200]}      // 8 - звезда
        ];
        this.imageResources = {
            main: this.mergePath({mainArea: 'area/main.png'}),
            atlas: this.mergePath(['staticSymbols.json'])
        };
        this.additionalResources = {atlas: this.mergePath(['bonus/bonusSymbols.json'])};

        this.gameSounds = {
            soundClass: 'deluxe',
            sounds: [
                {name: 'reels'},
                {name: 'reelsstop'},
                {name: 'win-line'}
            ],
            path: `/game/games/${this.id}/audio/`
        };

        this.Lines = new Lines10x6();
        this.Gamble = new GambleDeluxe(this.mergePath({
            gambleArea: 'gamble/gamble-area.png',
            activeRed: 'gamble/red-active.png',
            inactiveRed: 'gamble/red-inactive.png',
            activeBlack: 'gamble/black-active.png',
            inactiveBlack: 'gamble/black-inactive.png',
            blackCard: 'gamble/card-black.png',
            redCard: 'gamble/card-red.png',
            smallCard: 'gamble/card-small.png',
            aceOfClubs: 'gamble/ace-of-clubs.png',
            aceOfDiamonds: 'gamble/ace-of-diamonds.png',
            aceOfHearts: 'gamble/ace-of-hearts.png',
            aceOfSpades: 'gamble/ace-of-spades.png',
            clubs: 'gamble/clubs.png',
            diamonds: 'gamble/diamond.png',
            hearts: 'gamble/hearts.png',
            spades: 'gamble/spades.png'
        }));
        this.InfoScreen = new InfoScreen({pages: 3}); // number of game info states
    }

    /**
     * Draw game info page
     * @param ctx
     * @param page
     * @param nLines
     * @param bet
     */
    drawInfoPage(ctx, page, nLines, bet) {
        ctx.font = '17pt franklinFont';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#f7c354';
        ctx.shadowColor = 'black';
        ctx.shadowOffsetX = 3;
        ctx.shadowOffsetY = 3;
        ctx.shadowBlur = 5;

        switch (page) {
            case 1:
                // watermelon.payment
                ctx.fillText(bet * this.symbols[5].payment[6], 161, 286);
                ctx.fillText(bet * this.symbols[5].payment[5], 161, 311);
                ctx.fillText(bet * this.symbols[5].payment[4], 161, 336);
                ctx.fillText(bet * this.symbols[5].payment[3], 161, 364);
                // grape.payment
                ctx.fillText(bet * this.symbols[4].payment[6], 657, 291);
                ctx.fillText(bet * this.symbols[4].payment[5], 657, 316);
                ctx.fillText(bet * this.symbols[4].payment[4], 657, 341);
                ctx.fillText(bet * this.symbols[4].payment[3], 657, 369);
                // 7.payment
                ctx.fillText(bet * this.symbols[6].payment[6], 161, 144);
                ctx.fillText(bet * this.symbols[6].payment[5], 161, 171);
                ctx.fillText(bet * this.symbols[6].payment[4], 161, 198);
                ctx.fillText(bet * this.symbols[6].payment[3], 161, 225);
                // chery.payment
                ctx.fillText(bet * this.symbols[0].payment[6], 657, 433);
                ctx.fillText(bet * this.symbols[0].payment[5], 657, 458);
                ctx.fillText(bet * this.symbols[0].payment[4], 657, 483);
                ctx.fillText(bet * this.symbols[0].payment[3], 657, 508);
                // Lemon.payment
                ctx.fillText(bet * this.symbols[1].payment[6], 161, 433);
                ctx.fillText(bet * this.symbols[1].payment[5], 161, 458);
                ctx.fillText(bet * this.symbols[1].payment[4], 161, 483);
                ctx.fillText(bet * this.symbols[1].payment[3], 161, 508);
                // star.payment
                ctx.fillText(bet * this.symbols[8].payment[6] * nLines, 657, 168);
                ctx.fillText(bet * this.symbols[8].payment[5] * nLines, 657, 195);
                ctx.fillText(bet * this.symbols[8].payment[4] * nLines, 657, 222);
                //
                ctx.fillText(1000 * bet * nLines, 400, 252);
                //
                ctx.fillText(100 * bet * nLines, 400, 397);
                //
                ctx.fillText(40 * bet * nLines, 400, 542);
                break;
            case 3:
                ctx.font = 'bold 48pt Arial';
                ctx.shadowColor = 'none';
                ctx.shadowBlur = 0;
                ctx.fillStyle = '#ffe990';
                ctx.lineWidth = 3;
                ctx.textAlign = 'center';
                this.strokeFillText(ctx, 'Rules', 400, 240);

                ctx.font = '18pt Times New Roman';
                ctx.fillStyle = '#f5f2ff';
                this.strokeFillText(ctx, 'All prizes are for combinations of a kind. All prizes are for', 400, 300);
                this.strokeFillText(ctx, 'combinations left to right, except scatters. All prizes are on', 400, 330);
                this.strokeFillText(ctx, 'selected lines, except scatters. Scatter symbols pay at any', 400, 360);
                this.strokeFillText(ctx, 'position on screen. Highest win only paid per selected line.', 400, 390);
                this.strokeFillText(ctx, 'Scatter wins are added to line wins. All prizes shown ', 400, 420);
                this.strokeFillText(ctx, 'in credits. Malfunction voids all pays and plays.', 400, 450);
                break;
        }
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0; // reset blur
    }

    /**
     * Function to show line for special feature
     * @param currentFeature
     * @param features
     */
    showFeatureLine(currentFeature, features) {
        const {number, reels, uc, payment} = currentFeature; // get current feature params
        const container = this.getStageChild('linesContainer');
        container.removeChildren();

        if (uc === 'WIN_LINE') {
            this.changeJokerToSymbol(currentFeature);
            this.Lines.drawLineImages([number], reels, container, true, payment);
        }
    }

    changeJokerToSymbol(currentFeature) {
        this.reelMatrix.forEach((reel, reelIndex) => {
            reel.forEach((symbolObj, rowIndex) => {
                if (
                    this.Lines.lines[currentFeature.number].coordinates[reelIndex] === rowIndex && // if current position in line
                    currentFeature.reels.includes(reelIndex) && // if contains in feature.
                    this.latestResponse.screen[reelIndex][rowIndex] === 7 // if it's joker
                ) {
                    const symbolObj = this.reelMatrix[reelIndex][rowIndex];
                    if (symbolObj.feature !== currentFeature) {
                        symbolObj.feature = currentFeature;
                        symbolObj.symbol = currentFeature.symbol;
                        symbolObj.image = 'bonus';
                        this.Roll.updateSymbolSprite(symbolObj);
                        symbolObj.sprite.play();
                    }
                    // set default symbol
                } else {
                    const symbolObj = this.reelMatrix[reelIndex][rowIndex];
                    if (this.latestResponse.screen[reelIndex][rowIndex] === 7) {
                        symbolObj.feature = null;
                        symbolObj.symbol = this.latestResponse.screen[reelIndex][rowIndex];
                        symbolObj.image = 'regular';
                        this.Roll.updateSymbolSprite(symbolObj);
                    }
                }
            });
        });
    }

    getFeatureDelay(currentFeature) {
        let delay = 0;

        switch (currentFeature.uc) {
            case 'WIN_LINE':
            case 'SCATTER':
            case 'GLADE':
                delay = this.winLineFeatureDelay + 300;
                break;
        }

        return delay;
    }
}
