const Grid = [];
let idx = 0;

[...Array(37)].forEach((item, index) => {
    const w = 3, h = 2;
    let obj = {
        idx,
        typeOfBet: 'STRAIGHT',
        limit: 'number',
        pays: '35 to 1',
        numbers: [index],
        fill: true,
        size: {w, h},
        pos: {x: 6 + ((index - 1) % 3) * 3, y: 2 + Math.floor((index - 1) / 3) * 2}
    };

    // changed size and pos for 0
    index === 0 && (obj = {...obj, size: {w: w * 3, h}, pos: {x: 6, y: 0}});

    Grid.push(obj);
    idx++;
});

[...Array(3)].forEach((item, index) => {
    const w = 3, h = 8;
    Grid.push({
        idx,
        typeOfBet: 'DOZEN',
        limit: 'chance',
        pays: '2 to 1',
        numbers: [...Array(12)].map((item, i) => i + index * 12 + 1),
        fill: true,
        size: {w, h},
        pos: {x: 3, y: 2 + index * h}
    });
    idx++;
});

['ODD', 'EVEN'].forEach(typeOfBet => {
    const w = 3, h = 4;
    Grid.push({
        idx,
        typeOfBet,
        limit: 'chance',
        pays: '1 to 1',
        numbers: [...Array(18)].map((item, i) => (typeOfBet === 'ODD' ? 1 : 2) + i * 2),
        fill: true,
        size: {w, h},
        pos: {x: 0, y: typeOfBet === 'ODD' ? 18 : 6}
    });
    idx++;
});

['RED', 'BLACK'].forEach((typeOfBet, index) => {
    const w = 3, h = 4;
    Grid.push({
        idx,
        typeOfBet,
        limit: 'chance',
        pays: '1 to 1',
        numbers: typeOfBet === 'RED' ?
            [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36] :
            [2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35],
        fill: true,
        size: {w, h},
        pos: {x: 0, y: 10 + index * h}
    });
    idx++;
});

['LOW', 'HIGH'].forEach((typeOfBet, index) => {
    const w = 3, h = 4;
    Grid.push({
        idx,
        typeOfBet,
        limit: 'chance',
        pays: '1 to 1',
        numbers: [...Array(18)].map((item, i) => index * 18 + i + 1),
        fill: true,
        size: {w, h},
        pos: {x: 0, y: typeOfBet === 'LOW' ? 2 : 22}
    });
    idx++;
});

[...Array(3)].forEach((item, index) => {
    const w = 3, h = 2;
    Grid.push({
        idx,
        typeOfBet: 'COLUMN',
        limit: 'chance',
        pays: '2 to 1',
        numbers: [...Array(12)].map((item, i) => index + i * 3 + 1),
        fill: true,
        size: {w, h},
        pos: {x: 6 + index * w, y: 26}
    });
    idx++;
});

[...Array(23)].forEach((item, index) => {
    const w = 1, h = 1;
    let obj = {
        idx,
        typeOfBet: 'CORNER',
        limit: 'number',
        pays: '8 to 1',
        numbers: [1, 2, 4, 5].map(item => item + index + Math.floor(index / 2)),
        size: {w, h},
        pos: {x: 8.5 + (index % 2 === 0 ? 0 : 3), y: 3.5 + Math.floor(index / 2) * 2}
    };

    // create combination with 0
    index === 22 && (obj = {
        ...obj,
        numbers: [0, 1, 2, 3],
        size: {w: 1, h: 0.75},
        pos: {x: 5.5, y: 1.75}
    });

    Grid.push(obj);
    idx++;
});

[...Array(12)].forEach((item, index) => {
    const w = 1, h = 1;
    Grid.push({
        idx,
        typeOfBet: 'STREET',
        limit: 'number',
        pays: '11 to 1',
        numbers: [...Array(3)].map((item, i) => i + index * 3 + 1),
        size: {w, h: index === 11 ? 1.5 : h},
        pos: {x: 5.5, y: 2.5 + index * 2}
    });
    idx++;
});

[...Array(11)].forEach((item, index) => {
    const w = 1, h = 1;
    Grid.push({
        idx,
        typeOfBet: 'LINE',
        limit: 'number',
        pays: '5 to 1',
        numbers: [...Array(6)].map((item, i) => i + index * 3 + 1),
        size: {w, h},
        pos: {x: 5.5, y: 3.5 + index * 2}
    });
    idx++;
});

[...Array(2)].forEach((item, index) => {
    const w = 1, h = 0.75;
    Grid.push({
        idx,
        typeOfBet: 'TRIO',
        limit: 'number',
        pays: '11 to 1',
        numbers: index === 0 ? [0, 1, 2] : [0, 2, 3],
        size: {w, h},
        pos: {x: index === 0 ? 8.5 : 11.5, y: 1.75}
    });
    idx++;
});

[...Array(60)].forEach((item, index) => {
    const w = 1, h = 1;
    let obj = {
        idx,
        typeOfBet: 'SPLIT',
        limit: 'number',
        pays: '17 to 1',
        numbers: [1, 2].map(item => item + index + Math.floor(index / 2)),
        size: {w, h: index > 21 ? 1.5 : h},
        pos: {x: 8.5 + (index % 2 === 0 ? 0 : 3), y: 2.5 + Math.floor(index / 2) * 2}
    };

    index > 23 && (obj = {
        ...obj,
        numbers: [1, 4].map(item => item + index - 24),
        size: {w: (index - 20) % 3 === 0 ? 2.5 : 2, h},
        pos: {x: 6.5 + ((index - 24) % 3) * 3, y: 3.5 + Math.floor((index - 24) / 3) * 2}
    });

    // create combination with 0
    index > 56 && (obj = {
        ...obj,
        numbers: [0, 1].map((item, i) => i === 1 ? index - 56 : item),
        size: {w: index === 59 ? 2.5 : 2, h: 0.75},
        pos: {x: 6.5 + (index - 57) * 3, y: 1.75}
    });

    Grid.push(obj);
    idx++;
});

export default Grid;
